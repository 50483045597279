  <!--title-->
   <title>

    Message from CIO | MHRDD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>

<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">About Us</a>
      </li>
      <li class="aht-breadcrumbs__item">
        CIO's Message
      </li>
    </ul>
  </div>
	
<section class="paddsection">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="titleheadertop">

                  <h1 class="inner-theme-heading">CIO’s Message </h1>

                </div>

            </div>

        </div>



      <div class="row marabout">

        <div class="col-md-5">

            <div class="AboutUs_Messageimg">

                <img src="assets/images/cio.jpg">

                <h4>Dr. Abhay Jere</h4>

            </div>

        </div>



        <div class="col-md-7">

            <div class="AboutUs_Message">

                <h2>The buzzword across the globe for the 21st century is 'Innovation</h2>



                <div class="imagestext">

                <p> <p>In the simplest term Innovation could be defined as converting an ideas into new or improved products, processes and services. Undoubtedly, Innovation is about taking inventions to marketplace by translating scientific knowledge into products, services and offerings for economic growth and social development. Even, Hon’ble Prime Minister of India has declared decade 2010-20 as <b>‘Decade of Innovation’,</b> to unleash the creative potential of every Indian and contribute at all layers.</p>
<p>Youth of our country, especially in higher education institutions (HEIs) need to play crucial role at every step to shape the innovation ecosystem. Hence, ideally all HEIs should have a comprehensive and function ecosystems to foster innovation and research. This ecosystem will encourage, inspire and nurture young students by exposing them to new ideas and processes resulting in innovative activities in their formative years.</p>
<p>To ensure that Research and Innovation is primary fulcrum of all HEIs, Ministry of Human Resource Development (MHRD), Govt. of India has now established <b>‘MHRD’s Innovation Cell (MIC)’</b> with the primary mandate to systematically build culture of innovation and necessary ecosystem across all HEIs in India.</p>
<p>MIC will be responsible for promoting various innovation initiatives like ranking of institutions on innovation achievements, <b>‘Smart India Hackathon’,</b> Grand and MiniChallenges, Idea Competitions, etc. for students of HEIs. MIC will also work very closely with central and state agencies to create policies for supporting and promoting culture of innovation in India.</p>


                </div>

            </div>

        </div>

      </div>



    </div>

</section>

<!-- Footer -->
<app-inner-footer></app-inner-footer>