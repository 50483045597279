<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Calender 2020-21</h1> -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Calender 2024-25</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Calender 2024-25</li>
  </ul>
</nav>

<section class="iicCalenderBoxSec">
	<div class="container">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">IIC Activities Calendar for Academic Year 2024-25</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="vc_col-md-12 iicActiTop">
				<p class="iicCalFirstText">IIC calendar plan with various new features will be introduced from September 2024. To make comprehensive impact in field of innovation, IPRs and Entrepreneurship following measures are introduced in IIC 2024-25 edition. Quarter 1 and Quarter 2 is merged as Semester 1 and Quarter 3 and Quarter 4 is merged as Semester 2. Institutes can conduct the activities accordingly.</p>
				
				<!-- <marquee behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();"><p class="iicCalFirstText1">Quarter 1 and Quarter 2 is merged and institutes can conduct the activities accordingly.</p></marquee> -->
				<!-- <ul>
					<li>quarter 1 and quarter2 will be merged and institutes can conduct the activities accordingly.</li>
					<li>Mandatory activities are introduced to support and make awareness of IIC National Innovation Contest. </li>
				</ul> -->
			</div>
		</div>
		<div class="row">
			<div class="col-md-3">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
				  <li><h3 class="tabHeadingCal">Semester 1</h3></li>
				  <li class="nav-item">
					<a class="nav-link active" data-toggle="tab" href="#quarter1" role="tab" aria-controls="home">Quarter 1</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter2" role="tab" aria-controls="profile">Quarter 2</a>
				  </li>
				  <li><h3 class="tabHeadingCal">Semester 2</h3></li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter3" role="tab" aria-controls="messages">Quarter 3</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter4" role="tab" aria-controls="settings">Quarter 4</a>
				  </li>
				</ul>
			</div>
			<div class="col-md-9">
				<div class="tab-content">
				  <div class="tab-pane active" id="quarter1" role="tabpanel">
						<div id="quarter1" class="tabcontent">
						<div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 1 </h3>
						  </div>
						  <div class="col-md-8" style="text-align:left;"><span  style="font-size: 16px;color:#252b50;font-weight: 900;">Thrust Area :</span><span style="font-size: 16px;color:#c71e23;font-weight: 900;">Conducting a Maximum Number of prescribed activities focuses on Inspiration, Motivation, and Ideation</span></div>
						 </div>
						  <!-- <p>London is the capital city of England.</p> -->
						  <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Activity</th>
								<th scope="col" class="sorting">Suggestive Mode of Conduct of Activity</th>
								<th scope="col" class="sorting">Suggestive Level of Activity</th>
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th scope="row">1</th>
								<td>Workshop on “Entrepreneurship and Innovation” as a Career Opportunity</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">2</th>
								<td>My Story - Motivational Session by Successful Innovators</td>
								<td>Offline/Online</td>
								<td>1</td>
							  </tr>
							  <tr>
								<th scope="row">3</th>
								<td>From Idea to Impact - Motivational Session by Successful Entrepreneur/Start-up Founder</td>
								<td>Offline/Online</td>
								<td>1</td>
							  </tr>
							  <tr>
								<th scope="row">4</th>
								<td>Session on Problem Solving and Ideation Workshop</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">5</th>
								<td>Session on the “Basics of Intellectual Property Rights and its Importance for Innovators and Entrepreneurs”.</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">6</th>
								<td>Exposure and Field Visits for Problem Identification: Aligning  with UN SDGs and Exploring Emerging Areas of Technologies</td>
								<td>Offline</td>
								<td>2 or 3</td>
							  </tr>
							  <tr>
								<th scope="row">7</th>
								<td>Organize an Inter/Intra Institutional Idea Competition/ Challenge/ Hackathon, and Reward the Best Ideas and deposition in the Institution’s YUKTI Innovation Repository</td>
								<td>Offline/Hybrid</td>
								<td>3 or 4</td>
							  </tr>
							  <tr>
								<th scope="row">8</th>
								<td>Idea Showcase: Demo Day/Exhibition/Poster Presentation of Ideas/PoC & linkage with Innovation Ambassadors/Experts for Mentorship Support.</td>
								<td>Offline/Hybrid</td>
								<td>3 or 4</td>
							  </tr>
							</tbody>
						  </table>
						</div>

				  </div>
				  <div class="tab-pane" id="quarter2" role="tabpanel">
					<div id="quarter2" class="tabcontent">
					  <div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 2 </h3>
						  </div>
						  <div class="col-md-8" style="text-align:left;"><span  style="font-size: 16px;color:#252b50;font-weight: 900;">Thrust Area :</span><span style="font-size: 16px;color:#c71e23;font-weight: 900;">Conducting a Maximum Number of prescribed activities focuses  on Idea/innovation Validation and Concept Development</span></div>
						 </div>
             <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Activity</th>
								<th scope="col" class="sorting">Suggestive Mode of Conduct of Activity</th>
								<th scope="col" class="sorting">Suggestive Level of Activity</th>
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th scope="row">1</th>
								<td>Workshop on Design Thinking, Critical thinking and Innovation Design</td>
								<td>Offline/Online</td>
								<td>2 or 3</td>
							  </tr>
							  <tr>
								<th scope="row">2</th>
								<td>Organizing Innovation & Entrepreneurship Outreach Program in Schools by engaging active Atal Tinkering Labs and School Innovation Council (SIC) </td>
								<td>Offline</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">3</th>
								<td>Organize an Expert talk on Process of Innovation Development, Technology Readiness Level (TRL); Commercialization of Lab Technologies & Tech-Transfer</td>
								<td>Online/Offline</td>
								<td>1</td>
							  </tr>
							  <tr>
								<th scope="row">4</th>
								<td>Workshop on Effective Sales and Marketing Strategies for Entrepreneurs /Startups</td>
								<td>Online/Offline</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">5</th>
								<td>Conduct a Session on Achieving Problem-Solution Fit and Product-Market Fit</td>
								<td>Online/Offline</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">6</th>
								<td>Field/Exposure Visit to Pre-incubation units such as AICTE Idea Lab, Fab lab, Makers Space, Design Centers, City MSME clusters, workshops etc.</td>
								<td>Offline</td>
								<td>2 or 3</td>
							  </tr>
							  <tr>
								<th scope="row">7</th>
								<td>Organize an Inter/Intra Institutional Innovation Competition/Challenge/Hackathon and Reward the Best Innovations and deposition in the Institution’s YUKTI Innovation Repository</td>
								<td>Offline/Hybrid</td>
								<td>3 or 4</td>
							  </tr>
							  <tr>
								<th scope="row">8</th>
								<td>Innovations Showcase: Demo Day/Exhibition/Poster Presentation of Innovations/Prototypes & linkage with Innovation Ambassadors/Experts for Mentorship Support</td>
								<td>Offline/Hybrid</td>
								<td>3 or 4</td>
							  </tr>
							</tbody>
						  </table>
					 </div>

				  </div>
				  <div class="tab-pane" id="quarter3" role="tabpanel">
					<div id="quarter3" class="tabcontent">
					  <div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 3 </h3>
						  </div>
						  <div class="col-md-8" style="text-align:left;"><span  style="font-size: 16px;color:#252b50;font-weight: 900;">Thrust Area :</span><span style="font-size: 16px;color:#c71e23;font-weight: 900;">Conducting a Maximum Number of prescribed activities focuses on  Prototype, Design, Process Development for Business Model/ Process/ Services</span></div>
						 </div>
             <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Activity</th>
								<th scope="col" class="sorting">Suggestive Mode of Conduct of Activity</th>
								<th scope="col" class="sorting">Suggestive Level of Activity</th>
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th scope="row">1</th>
								<td>Workshop on Prototype/Process Design and Development.</td>
								<td>Offline/Online</td>
								<td>2 or 3</td>
							  </tr>
							  <tr>
								<th scope="row">2</th>
								<td>Session/ Workshop on Business Model Canvas (BMC)</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">3</th>
								<td>Field/Exposure Visit to Incubation Unit/Patent Facilitation,<br>
									Centre/Technology Transfer Centre such as Atal Incubation Centre etc
								</td>
								<td>Offline</td>
								<td>2 or 3</td>
							  </tr>
							  <tr>
								<th scope="row">4</th>
								<td>Session on “How to plan for Start-up and legal & Ethical Steps”</td>
								<td>Offline/Online</td>
								<td>1</td>
							  </tr>
							  <tr>
								<th scope="row">5</th>
								<td>Workshop on “Raising Capital and Managing Finance for Startups”</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">6</th>
								<td>Workshop on “Protecting Intellectual Property Rights (IPRs) and IP Management for Startups”</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">7</th>
								<td>Organize an Inter/Intra Institutional Business Plan Competition and Reward the Best Innovations and deposition in the Institution’s YUKTI Innovation Repository</td>
								<td>Offline/Hybrid</td>
								<td>3 or 4</td>
							  </tr>
							  <tr>
								<th scope="row">8</th>
								<td>B-Plan Pitch: Demo Day/Exhibition/Poster Presentation of Business Plans & linkage with Innovation Ambassadors/Experts for Mentorship Support.</td>
								<td>Offline/Hybrid</td>
								<td>3 or 4</td>
							  </tr>
							</tbody>
						  </table>
					 </div>

				  </div>
				  <div class="tab-pane" id="quarter4" role="tabpanel">
					<div id="quarter4" class="tabcontent">
						<div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 4 </h3>
						  </div>
						  <div class="col-md-8" style="text-align:left;"><span  style="font-size: 16px;color:#252b50;font-weight: 900;">Thrust Area :</span><span style="font-size: 16px;color:#c71e23;font-weight: 900;">Conducting a Maximum Number of prescribed activities focuses on Awareness about Startup and related Ecosystem Support Services for Startup Development</span></div>
						 </div>
             <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Activity</th>
								<th scope="col" class="sorting">Suggestive Mode of Conduct of Activity</th>
								<th scope="col" class="sorting">Suggestive Level of Activity</th>
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th scope="row">1</th>
								<td>Session on Innovation/Prototype Validation – Converting Innovation into a Start-up or Session on Achieving “Value Proposition Fit” & “Business Fit”</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">2</th>
								<td>Session on Accelerators/Incubation -Opportunities for Students & Faculties – Early-Stage Entrepreneurs</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">3</th>
								<td>Organize Session on “Lean Start-up & Minimum Viable Product/Business”- Boot Camp (or) Mentoring Session</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">4</th>
								<td>Session on Angel Investment/VC Funding Opportunity for Early-Stage Entrepreneurs.</td>
								<td>Offline/Online</td>
								<td>1 or 2</td>
							  </tr>
							  <tr>
								<th scope="row">5</th>
								<td>Session/ Panel discussion with innovation and Start-up Ecosystem Enablers from the region/state/national level</td>
								<td>Offline/Online</td>
								<td>1</td>
							  </tr>
							  <tr>
								<th scope="row">6</th>
								<td>Organizing Innovation & Entrepreneurship Outreach   Program by involving ATLs/SICs in Schools </td>
								<td>Offline/Hybrid</td>
								<td>2 or 3</td>
							  </tr>
							  <tr>
								<th scope="row">7</th>
								<td>Organize an Inter/Intra Institutional Start-up Competition and Reward the Best Start-ups and deposition in the Institution’s YUKTI Innovation Repository</td>
								<td>Offline/Hybrid</td>
								<td>3 or 4</td>
							  </tr>
							  <tr>
								<th scope="row">8</th>
								<td>Startup Summit: Demo Day/Exhibition/Poster Presentation of Start-Ups & Linkage with Innovation Ambassadors/Experts for Mentorship Support</td>
								<td>Offline/Hybrid</td>
								<td>3 or 4</td>
							  </tr>
							</tbody>
						  </table>
					  </div>

				  </div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="vc_row-full-width vc_clearfix"></div>

<section class="iicCalenderBoxSec impDatesCeleb">
	<div class="container">
		<div class="vc_row innovationAmbassadorBox ambPadding1 impDatesCelebAct">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">Important Day Celebration Activities</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-3">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
				  <li><h3 class="tabHeadingCal">Semester 1</h3></li>
				  <li class="nav-item">
					<a class="nav-link active" data-toggle="tab" href="#quarter11" role="tab" aria-controls="home">Quarter 1</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter22" role="tab" aria-controls="profile">Quarter 2</a>
				  </li>
				  <li><h3 class="tabHeadingCal">Semester 2</h3></li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter33" role="tab" aria-controls="messages">Quarter 3</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter44" role="tab" aria-controls="settings">Quarter 4</a>
				  </li>
				</ul>
			</div>
			<div class="col-md-9">
				<div class="tab-content">
				  <div class="tab-pane active" id="quarter11" role="tabpanel">
						<div id="quarter1" class="tabcontent">
						<div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 1 </h3>
						  </div>
						 </div>
						  <!-- <p>London is the capital city of England.</p> -->
						  <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Date</th>
								<th scope="col" class="sorting">Day of Celebration</th>
								<th scope="col" class="sorting">Month</th>
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th>1</th>
								<td><strong>15th October</strong></td>
								<td><strong>Institution's Innovation Day (Dr APJ Abdul Kalam's birth anniversary)</strong></td>
								<td><strong>October</strong></td>
							  </tr>
							  <tr>
								<th>2</th>
								<td><strong>9th November</strong></td>
								<td><strong>National Entrepreneurship Day</strong></td>
								<td><strong>November</strong></td>
							  </tr>
							  <tr>
								<th>3</th>
								<td><strong>11th November</strong></td>
								<td><strong>National Education Day</strong></td>
								<td><strong>November</strong></td>
							  </tr>
							</tbody>
						  </table>
						</div>

				  </div>
				  <div class="tab-pane" id="quarter22" role="tabpanel">
					<div id="quarter2" class="tabcontent">
					  <div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 2 </h3>
						  </div>
						 </div>
						<table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Date</th>
								<th scope="col" class="sorting">Day of Celebration</th>
								<th scope="col" class="sorting">Month</th>
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th scope="row">1</th>
								<td><strong>2nd December </strong></td>
								<td><strong>National Pollution Control Day</strong></td>
								<td><strong>December</strong></td>
							  </tr>
							  <tr>
								<th scope="row">2</th>
								<td><strong>14th December</strong></td>
								<td><strong>National Energy Conservation Day (India)</strong></td>
								<td><strong>December</strong></td>
							  </tr>
							  <tr>
								<th scope="row">3</th>
								<td><strong>12th January</strong></td>
								<td><strong>National Youth Day</strong></td>
								<td><strong>January</strong></td>
							  </tr>
							  <tr>
								<th scope="row">4</th>
								<td><strong>16th January</strong></td>
								<td><strong>National Startup Day</strong></td>
								<td><strong>January</strong></td>
							  </tr>
							  <tr>
								<th scope="row">5</th>
								<td><strong>28th February</strong></td>
								<td><strong>National Science Day</strong></td>
								<td><strong>February</strong></td>
							  </tr>
							</tbody>
						  </table>
					 </div>

				  </div>
				  <div class="tab-pane" id="quarter33" role="tabpanel">
					<div id="quarter3" class="tabcontent">
					  <div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 3 </h3>
						  </div>
						 </div>
             <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Date</th>
								<th scope="col" class="sorting">Day of Celebration</th>
								<th scope="col" class="sorting">Month</th>
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th scope="row">1</th>
								<td><strong>8th March</strong></td>
								<td><strong>International Women’s Day</strong></td>
								<td><strong>March</strong></td>
							  </tr>
							  <tr>
								<th scope="row">2</th>
								<td><strong>21st April</strong></td>
								<td><strong>World Creativity and Innovation Day</strong></td>
								<td><strong>April</strong></td>
							  </tr>
							  <tr>
								<th scope="row">3</th>
								<td><strong>26th April</strong></td>
								<td><strong>World Intellectual Property Day</strong></td>
								<td><strong>April</strong></td>
							  </tr>
							  <tr>
								<th scope="row">4</th>
								<td><strong>11th May</strong></td>
								<td><strong>National Technology Day</strong></td>
								<td><strong>May</strong></td>
							  </tr>
							</tbody>
						  </table>
					 </div>

				  </div>
				  <div class="tab-pane" id="quarter44" role="tabpanel">
					<div id="quarter4" class="tabcontent">
						<div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 4 </h3>
						  </div>
						 </div>
             <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Date</th>
								<th scope="col" class="sorting">Day of Celebration</th>
								<th scope="col" class="sorting">Month</th>
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th scope="row">1</th>
								<td><strong>5th June</strong></td>
								<td><strong>World Environment Day</strong></td>
								<td><strong>June</strong></td>
							  </tr>
							  <tr>
								<th scope="row">2</th>
								<td><strong>29th July</strong></td>
								<td><strong>4th Anniversary of National Education Policy (NEP) 2020</strong></td>
								<td><strong>July</strong></td>
							  </tr>
							  <tr>
								<th scope="row">3</th>
								<td><strong>15th August</strong></td>
								<td><strong>Independence Day- Celebrating Aazadi Ka Amritkal</strong></td>
								<td><strong>August</strong></td>
							  </tr>
							  <tr>
								<th scope="row">4</th>
								<td><strong>21st August</strong></td>
								<td><strong>World Entrepreneurs Day</strong></td>
								<td><strong>August</strong></td>
							  </tr>
							</tbody>
						  </table>
					  </div>

				  </div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="vc_row-full-width vc_clearfix"></div>

<section class="ratingScoringBox">
	<div class="container">
		<div class="vc_row wpb_row vc_row-fluid vc_custom_1497359159460">
					<div class="wpb_column vc_column_container vc_col-sm-12">
					<div class="vc_row innovationAmbassadorBox ambPadding1">
						<div class="vc_col-md-12">
							<div class="headingAmbassador">
								<h2 class="incubTabHeading">Rating and Scoring System</h2>
							</div>
						</div>
					</div>
						<div class="vc_column-inner vc_custom_1499083301093">
							<div class="wpb_wrapper">
								<div class="linp-post-list  linp_post_list-75236859249a281716f5a04bfd4b0c84 horizontal">
									<div class="vc_row">
										<div class="vc_col-md-6">
											<div class="ratingContent">
												<h3>All Activities to be done by IICs are categorized in three types-</h3>
												<ul>
													<li>IIC Calendar Activity</li>
													<li>MIC Driven Activity</li>
													<li>Self- Driven Activity</li>
													<li>Celebration Day Driven Activities</li>
												</ul>
												<p><strong>IIC Calendar Activity – </strong> MoE's Innovation Cell (MIC) prescribes an annual activity calendar for IICs. In each quarter Minimum 3 activities out of scheduled activities are to be completed by an IIC to receive full score. Institutes are encouraged to do a greater number of activities from calendar beyond the prescribed minimum. It is expected that institutes which are in the emerging phase of the innovation ecosystem- will follow the calendar plan vividly. </p>
												<p><strong>MIC Driven Activity - </strong> MIC schedules various activities from time to time on its own or  in collaboration with partner organization and government departments.All MIC Driven Activities are mandatory.
                                                These activities are scheduled on IIC portal under MIC Driven Activity tab.</p>
												<p><strong>Self- Driven Activity - </strong> IICs are encouraged to plan their own activities apart from IIC calendar for promoting entrepreneurship and innovation in their campuses. They may have collaborations with different agencies, departments, and Industries for organizing seminars, competitions, workshops, training sessions relevant to entrepreneurship and innovation.To score maximum marks/star in a particular quarter, it is necessary to complete atleast 3 activities under Self Driven Category.</p>

											</div>
										</div>
										<div class="vc_col-md-6">
											<div class="ratingContent1">
												<img src="assets/assets/images/graph-image21-22.png" class="img-responsive">
											</div>
										</div>
										<div class="vc_col-md-12">
											<div class="ratingContent">
												<p><strong>Celebration Day Driven Activities - </strong> This category of activity is first time introduced for the IIC 4.0 calendar year with 10% weightage for our IIC institutions to celebrate important days dedicated to science, innovation, entrepreneurship, IPR, environment etc as important aspects for quality education and wealth generation. It is expected that each and every IIC institute should mark and celebrate at least 1 important day related to innovation, entrepreneurship, IPR in every quarter to create awareness and impact among the student and faculty members of your campus and also in your locality. A prescribed list of important days for celebration can be checked here and download. Institutes can add more related important days and celebrate as per their capability.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	</div>
</section>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
