<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Idea Submission">
        <app-institute-idea-submission></app-institute-idea-submission>
    </mat-tab>
    <mat-tab label="POC Submission">
        <app-institute-poc-submission></app-institute-poc-submission>
    </mat-tab>
    <mat-tab label="Prototype Submission">
        <app-institute-prototype-submission></app-institute-prototype-submission>
    </mat-tab>
    <mat-tab label="Nominations">
        <app-institute-nomination></app-institute-nomination>
    </mat-tab>
    <mat-tab label="Mentoring Session">
        <app-institute-mentoring-session></app-institute-mentoring-session>
    </mat-tab>
    <mat-tab label="Stage 1 Evaluation">
      <app-institute-stage-i-evaluation></app-institute-stage-i-evaluation>
    </mat-tab>
    <mat-tab label="Stage 2 Evaluation">
      <app-institute-stage-ii-evaluation></app-institute-stage-ii-evaluation>
    </mat-tab>
    <mat-tab label="Stage 3 Evaluation">
      <app-institute-stage-iii-evaluation></app-institute-stage-iii-evaluation>
    </mat-tab>
</mat-tab-group>


<!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
            aria-selected="true">Idea Submission</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
            aria-selected="false">POC Submission</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
            aria-selected="false">Prototype Submission</a>
    </li>
</ul>
<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <app-institute-idea-submission></app-institute-idea-submission>
    </div>
    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <app-institute-poc-submission></app-institute-poc-submission>
    </div>
    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
        <app-institute-prototype-submission></app-institute-prototype-submission>
    </div>
</div> -->
