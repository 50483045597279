<!-- IIC Website Header -->
<!-- <app-iic-web-navbar></app-iic-web-navbar>

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC Annual Performance 2021-22</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>At a Glance</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Annual Performance 2021-22</li>
  </ul>
</nav>
<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>Categories</h2>
				</div>
			</div>
		</div>
	</div>
</div>

<section class="rating2021Box">
	<div class="container">
	<div class="banner-img5">
		<mat-tab-group mat-align-tabs="center" class="ratingsTabMain2022" id="ratingsTabMain2022">
		  <mat-tab class="matTabOverall2022" id="matTabOverall20222">
			<ng-template mat-tab-label class="bannerIMG">
				Overall
			</ng-template>
			<app-iic-ratings2022-details1></app-iic-ratings2022-details1>

		  </mat-tab>
		  <mat-tab class="matTabOverall2022" id="matTabOverall20228">
				<ng-template mat-tab-label>
					An Affiliating<br/>University
				</ng-template>
				<app-iic-ratings2022-details7></app-iic-ratings2022-details7>
		  </mat-tab>
		  <mat-tab class="matTabOverall2022" id="matTabOverall20228">
				<ng-template mat-tab-label>
					Open<br>University
				</ng-template>
				<app-iic-ratings2022-details8></app-iic-ratings2022-details8>
		  </mat-tab>
		  <mat-tab class="matTabOverall2022" id="matTabOverall20223">
			<ng-template mat-tab-label>
				Institute of National<br/> Importance& Central<br/>Universities / CFTIs
			</ng-template>
			<app-iic-ratings2022-details2></app-iic-ratings2022-details2>
			
		  </mat-tab>

		  <mat-tab class="matTabOverall2022" id="matTabOverall20224">
			<ng-template mat-tab-label>
				University & Deemed<br/>to beUniversity<br/>(Govt. & Govt. Aided)
			</ng-template>
			<app-iic-ratings2022-details3></app-iic-ratings2022-details3>
			
		  </mat-tab>

		  <mat-tab class="matTabOverall2022" id="matTabOverall20225">
			<ng-template mat-tab-label>
				University & Deemed<br/>to be University<br/>(Private / Self Financed)
			</ng-template>
			<app-iic-ratings2022-details4></app-iic-ratings2022-details4>
			
		  </mat-tab>

		  <mat-tab class="matTabOverall2022" id="matTabOverall20226">
			<ng-template mat-tab-label>
				Colleges / Institutes<br/>(Govt. & Govt. Aided)
			</ng-template>
			<app-iic-ratings2022-details5></app-iic-ratings2022-details5>
			
		  </mat-tab>

		  <mat-tab class="matTabOverall2022" id="matTabOverall20227">
			<ng-template mat-tab-label>
				Colleges / Institutes<br/>(Private / Self Financed)
			</ng-template>
			<app-iic-ratings2022-details6></app-iic-ratings2022-details6>
			
		  </mat-tab>
		</mat-tab-group>
	</div>
  </div>
</section>
<app-iic-web-footer></app-iic-web-footer> -->




<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC Annual Performance 2021-22</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>At a Glance</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Annual Performance 2021-22</li>
  </ul>
</nav>

<!-- <div class="container mt-5">
  <div class="row">
    <div class="col-sm-4">
    	<a href="assets/doc/IIC-Annual-Performance-2020-21- Zone-Category-Wise.pdf" class="annoulLinkURL pdfLink" target="_blank">IIC Annual Performance Snapshot</a>

    </div>
    <div class="col-sm-4">
    	<a href="assets/doc/IIC Annual Performance 2020-21-University.pdf" class="annoulLinkURL pdfLink" target="_blank">IIC Annual Performance - University</a>
    </div>
    <div class="col-sm-4">
    	<a href="assets/doc/IIC Annual Performance 2020-21-College-and-standalone.pdf" class="annoulLinkURL pdfLink" target="_blank">IIC Annual Performance - College & Standalone</a>
    </div>
  </div>
</div> -->



<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>Categories</h2>
				</div>
			</div>
		</div>
	</div>
</div>

<section class="rating2021Box rating2022Box">
	<div class="container">
	<div class="banner-img5">
		<mat-tab-group mat-align-tabs="center" class="ratingsTabMain2022" id="ratingsTabMain2022">
		  <mat-tab class="matTabOverall2022" id="matTabOverall20222">
			<ng-template mat-tab-label class="bannerIMG">
				<img class="logo-img" src="assets/assets/images/overallIcon.png" alt="Overall">
				<div class="img-text5" style="position: relative;"><span>Overall</span></div>
			</ng-template>
			
			<!--  Content Start  -->
			<app-iic-ratings2022-details1></app-iic-ratings2022-details1>
			<!--  Content Start  -->

		  </mat-tab>
		  <mat-tab class="matTabOverall2022" id="matTabOverall20228">
			<ng-template mat-tab-label class="bannerIMG">
				<img class="logo-img" src="assets/assets/images/universityRating-7.png" alt="University" >
				<div class="img-text52" style="position: relative;"><span>Affiliating<br/>University</span></div>
				
			</ng-template>

			<!--  Content Start  -->
			<app-iic-ratings2022-details7></app-iic-ratings2022-details7>
			<!--  Content Start  -->
			
		  </mat-tab>	
		   <mat-tab class="matTabOverall2022" id="matTabOverall20227">
			<ng-template mat-tab-label class="bannerIMG">
				<img class="logo-img" src="assets/assets/images/universityRating-1.png" alt="University" >
				<div class="img-text53" style="position: relative;"><span>
					Open University
				</span></div>				
			</ng-template>
			<app-iic-ratings2022-details6></app-iic-ratings2022-details6>			
		  </mat-tab>

		  <mat-tab class="matTabOverall2022" id="matTabOverall20223">
			<ng-template mat-tab-label class="bannerIMG">
				<img class="logo-img" src="assets/assets/images/universityRating-2.png" alt="University" >
				<div class="img-text53" style="position: relative;"><span>
					Institute of National<br/> Importance& Central<br/>Universities / CFTIs
				</span></div>				
			</ng-template>
			<app-iic-ratings2022-details2></app-iic-ratings2022-details2>
		  </mat-tab>

		  <mat-tab class="matTabOverall2022" id="matTabOverall20224">
			<ng-template mat-tab-label class="bannerIMG">
				<img class="logo-img" src="assets/assets/images/universityRating-3.png" alt="University" >
				<div class="img-text53" style="position: relative;"><span>
					University & Deemed<br/>to beUniversity<br/>(Govt. & Govt. Aided)
				</span></div>				
			</ng-template>			
			<app-iic-ratings2022-details3></app-iic-ratings2022-details3>
		  </mat-tab>


		  <mat-tab class="matTabOverall2022" id="matTabOverall20225">
			<ng-template mat-tab-label class="bannerIMG">
				<img class="logo-img" src="assets/assets/images/universityRating-4.png" alt="University" >
				<div class="img-text53" style="position: relative;"><span>
					University & Deemed<br/>to be University<br/>(Private / Self Financed)
				</span></div>
			</ng-template>		
			<app-iic-ratings2022-details4></app-iic-ratings2022-details4>
		  </mat-tab>


		  <mat-tab class="matTabOverall2022" id="matTabOverall20226">
			<ng-template mat-tab-label class="bannerIMG">
				<img class="logo-img" src="assets/assets/images/universityRating-5.png" alt="College">
				<div class="img-text53" style="position: relative;"><span>
					Colleges / Institutes<br/>(Govt. & Govt. Aided)
				</span></div>				
			</ng-template>
			<app-iic-ratings2022-details5></app-iic-ratings2022-details5>			
		  </mat-tab>

		  <mat-tab class="matTabOverall2022" id="matTabOverall20227">
			<ng-template mat-tab-label class="bannerIMG">
				<img class="logo-img" src="assets/assets/images/universityRating-6.png" alt="College">
				<div class="img-text53" style="position: relative;"><span>
					Colleges / Institutes<br/>(Private / Self Financed)
				</span></div>				
			</ng-template>
			<app-iic-ratings2022-details6></app-iic-ratings2022-details6>			
		  </mat-tab>





		</mat-tab-group>
	</div>
  </div>
</section>





<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>

