import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { InstituteAnnouncementService } from 'src/app/Services/institute-announcement.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import {MatSortModule} from '@angular/material/sort';
import {Sort} from '@angular/material/sort';

export interface InstituteSort {
  sr_no: number;
  userId: string;
  instituteName: string;
  title: string;
  theme: string;
  expert_name: string;
  description: string;
  video: string;
}



@Component({
  selector: 'app-iic-repository-list',
  templateUrl: './iic-repository-list.component.html',
  styleUrls: ['./iic-repository-list.component.css']
})

export class IicRepositoryListComponent implements OnInit {

  public innovationCourse: FormGroup;
  type:any;
  instituteType: any;
  instituteTypeId: any;
  state: any;
  instituteCourses: any;
  changeData: any;
  currentPageNo: number = 1;
  total_pages: number;
  firstPage = true;
  lastPage: boolean = false;
  theme: any[];
  data1: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  frontCurrentPageNo: number = 0;
  StateModel2 = "";
  sortedData:any;

  title = 'Angular Charts';
  view: any[] = [600, 400];
  public showLegend = true;
  public gradient = true;
  public colorScheme = {
    domain: ['#586B7D', '#5F9EDA', '#9E9492', '#0096A6', '#F47B00', '#606060']
  };
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;
  public trimLabels = false;
  public showPieLegend = false;
  public legendPosition: string = 'below';

  //bar
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public barGradient = false;
  public showBarLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Academic Year';
  public showYAxisLabel = false;
  public yAxisLabel = 'Rating';
  public barColorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#91d494', '#0096A6', '#F47B00', '#606060']
  };
  public autoScale = true;

  //Stacked bar
  public multi2: any[];
  public xAxisStackedLabel = 'Events';
  public showYAxisStackedLabel = true;
  public yAxisStackedLabel = 'Participants';
  public stackedColorScheme = {
    domain: ['#378D3B', '#0096A6']
  };

  public single = [
    {
      "name": "China",
      "value": 2243772
    },
    {
      "name": "USA",
      "value": 1126000
    },
    {
      "name": "Norway",
      "value": 296215
    },
    {
      "name": "Japan",
      "value": 257363
    },
    {
      "name": "Germany",
      "value": 196750
    },
    {
      "name": "France",
      "value": 204617
    }
  ];

  public ratingChart1: any[];
  public evThemeChart1: any[];
  programTheme:any;

  constructor(private fb: FormBuilder,
    private activityService: InstituteActivityService,
     public dialog: MatDialog,
     private instituteannouncementservice: InstituteAnnouncementService,
     private innovationService: InstituteInnovationContestService,
   private toastr: ToastrService, private route: ActivatedRoute,
   private router: Router) {  }

  ngOnInit(): void {

    this.innovationCourse = this.fb.group({
      instituteCourses: [null],
      instituteName: [null],
      courseState: [null],
    });

    this.getInstituteCourses();

    this.type='overall';

    this.route.params.subscribe(params => {
      this.type = params['type'];
    });

    if(this.type=="overall")      { this.instituteType=""; }

    const data = {
      applicationStatus: 'Approved',
      instituteType: this.instituteType
    };

    this.instituteannouncementservice.getApprovedInstituterepositoryDetails(data, this.currentPageNo, this.per_page)
      .subscribe((data) => this.handleResponse(data));


      this.activityService.getIicActivityList("IIC").subscribe(
        (data) => this.handleResponseList(data)
      );

      this.instituteannouncementservice.getStateDetails("AllStates").subscribe(
        data => this.setState(data)
      );

  }

  handleResponseList(data) {
    this.programTheme = data['theme'];
  }

  getInstituteCourses() {
    this.instituteannouncementservice
      .getInstituteCoursesDetails()
      .subscribe((data) => this.setInstituteCourses(data));
  }

  setInstituteCourses(data) {
    this.instituteCourses = [];
    for (let i = 0; i < data.length; i++) {
      this.instituteCourses.push(data[i]);
    }
  }

  handleResponse1(data)
  {

    this.ratingChart1   = data['rating'];
    this.evThemeChart1  = data['evtheme'];

    // Object.assign(this.ratingChart1);

  }

  sortData(sort: Sort) {
    const data = this.theme.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sr_no':
          return compare(a.sr_no, b.sr_no, isAsc);
        case 'userId':
          return compare(a.userId, b.userId, isAsc);
        case 'instituteName':
          return compare(a.instituteName, b.instituteName, isAsc);
        case 'title':
          return compare(a.title, b.title, isAsc);
        case 'theme':
          return compare(a.theme, b.theme, isAsc);
        case 'expert_name':
          return compare(a.expert_name, b.expert_name, isAsc);
        case 'description':
          return compare(a.description, b.description, isAsc);
        case 'video':
          return compare(a.video, b.video, isAsc);
        default:
          return 0;
      }
    });
  }

  handleResponse(data) {
    this.theme = [];
    for (let i = 0; i < data['instituteDetails']['data'].length; i++) {
      this.theme.push(data['instituteDetails']['data'][i]);
    }
    this.total_pages = data.instituteDetails.meta.pagination.total;
    if (
      data['instituteDetails']['meta']['pagination']['current_page'] ==
      data['instituteDetails']['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['instituteDetails']['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {

    // var instituteType = this.innovation.get('instituteType').value;
    // var typeState = this.innovation.get('typeState').value;
    var instituteCourses = this.innovationCourse.get('instituteCourses').value;
    var courseState = this.innovationCourse.get('courseState').value;
    var instituteName = this.innovationCourse.get('instituteName').value;

    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    const data = {
      applicationStatus: 'Approved',
      instituteType: this.instituteType,
      // instituteType: instituteType,
      // typeState: typeState,
      instituteCourses: instituteCourses,
      courseState: courseState,
      instituteName: instituteName,
      sort_field: "",
      sort_by: "",
    };
    this.getApprInstitute(data);
  }

  previousPage() {

    // var instituteType = this.innovation.get('instituteType').value;
    // var typeState = this.innovation.get('typeState').value;
    var instituteCourses = this.innovationCourse.get('instituteCourses').value;
    var courseState = this.innovationCourse.get('courseState').value;
    var instituteName = this.innovationCourse.get('instituteName').value;

    this.currentPageNo--;

    const data = {
      applicationStatus: 'Approved',
      instituteType: this.instituteType,
      // instituteType: instituteType,
      // typeState: typeState,
      instituteCourses: instituteCourses,
      courseState: courseState,
      instituteName: instituteName,
      sort_field: "",
      sort_by: "",
    };

    this.getApprInstitute(data);

  }

  rwsShortData(fieldname, thid)
  {
    let el = document.getElementById(thid);
    var ariaSort = el.getAttribute("aria-sort");
    var sortby = "";

    var instituteCourses  = this.innovationCourse.get('instituteCourses').value;
    var courseState       = this.innovationCourse.get('courseState').value;
    var instituteName     = this.innovationCourse.get('instituteName').value;

    if(ariaSort=="none")
    {
      sortby = "ASC";
    }
    else if(ariaSort=="decending")
    {
      sortby = "ASC";
    }
    else
    {
      sortby = "DESC";
    }

    const data = {
      applicationStatus: 'Approved',
      instituteType: this.instituteType,
      instituteCourses: instituteCourses,
      courseState: courseState,
      instituteName: instituteName,
      sort_field: fieldname,
      sort_by: sortby,
    };

    this.getApprInstitute(data);

  }

  getApprInstitute(data) {
    this.changeData = data;
    this.instituteannouncementservice.getApprovedInstituterepositoryDetails(this.changeData, this.currentPageNo, this.per_page)
      .subscribe((data) => this.handleResponse(data));
  }

  getCourseState() {

    this.instituteTypeId = this.innovationCourse.get('instituteCourses').value;
    if (this.instituteTypeId == "") {
      this.StateModel2 = ""
    } else {
      if (this.instituteTypeId != null || this.instituteTypeId > 0) {
        this.instituteannouncementservice.getStateDetails("AllStates").subscribe(
          data => this.setState(data)
        );
      }
    }

  }
  setState(data) {
    this.state = [];
    for (let i = 0; i < data.length; i++) {
      this.state.push(data[i]);
    }
  }

  onSubmitCourse(data) {
    data['applicationStatus'] = 'Approved';
    this.currentPageNo = 1;
    this.getApprInstitute(data);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
