<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
<!-- <div class="cbp-container"> -->
<!-- <div class="one whole breadcrumbPadding"> -->
<!-- <h1 class="page-title">IA Ambassador Profile</h1>	-->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Session List</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
	<ul class="breadcrumb iicWebBreadcrumb">
		<li class="breadcrumb-item"><a routerLink="/">Home</a></li>
		<!-- <li class="breadcrumb-item"><a>Resources</a></li> -->
		<li class="breadcrumb-item active" aria-current="page">Session List</li>
	</ul>
</nav>

<section class="iaAmbassadorProfileBox">
	<div class="container">

		<div class="row">
			<div class="container-login100">
				<div class="col-xs-12 ">

					<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
						<div class="tab-pane fade show active" id="nav-faculty" role="tabpanel"
							aria-labelledby="nav-home-tab">

							<div class="container">
								<div class="row drop-sub-box">
									<div class="col-md-12">
										<h3 class="tableHeadingIIC">Session List</h3>
									</div>
								</div>
							</div>

							<div class="container-fluid">
								<div class="row">
									<div class="col-md-12">
										<div class="tab-box" id="newContentIaEventReport">
											<div class="dataTables_wrapper no-footer" id="myTable_ia_wrapper">
												<table
													class="table table-bordered dataTable no-footer newTablePagiIncub"
													style="color: #000;" id="myTable_ia" role="grid"
													aria-describedby="myTable_ia_info">
													<thead>
														<tr role="row">
															<th width="5%" class="sorting_asc" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-sort="ascending"
																aria-label="S.No.: activate to sort column descending">
																S.No.</th>
															<th width="60%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="Institute Name: activate to sort column ascending">
																Title</th>
															<th width="5%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="State:: activate to sort column ascending">
																Objectives</th>
															<th width="10%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="State:: activate to sort column ascending">
																Date & Time</th>

															<th width="10%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="State:: activate to sort column ascending">
                                                                Session Link</th>
                                                                

                                                                <th width="10%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="State:: activate to sort column ascending">
																Youtube Link</th>


														</tr>
													</thead>
													<tbody *ngFor="let list of sessionList; index as i;">
														<tr role="row" class="odd"
														>
															<td class="sorting_1" style="
														text-align: center;
													">{{i+1}}</td>
															<td>
																{{list?.title}}
															</td>

<td >
<p *ngFor="let lists of list.key_points; index as i;">{{i+1}}. {{lists?.key_points}}</p>
</td>
<td>{{list?.session_date}} & <br>{{list?.session_time}} </td>
<td>NA</td>
<td>NA</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>


						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
</section>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>