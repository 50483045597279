<mat-card class="mat-elevation-z6 m-4 instiNominiCard">

    <div class="row" color="primary">
        <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;"> List of Approved Prototype</h2>
    </div>

    <!--<div class="row">
        <div class="col-8 mt-2" style="text-align: center;">
            <h2>Nominate Best 5 Prototype</h2>
        </div>
        <div class="col-2" style="text-align: end;">
            <div *ngIf="!firstPage">
                <button type="button" class="mr-4" (click)="previousPage()" class="btn btn-success">
                    Previous
                </button>
            </div>
        </div>
        <div class="col-2">
            <div *ngIf="!lastPage">
                <button type="button" class="ml-4" (click)="nextPage()" class="btn btn-success">
                    Next
                </button>
            </div>
        </div>
    </div>-->

    <div class="">
        <div class="">
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <th scope="col">Select</th>
                        <th scope="col">NIC ID 2020</th>
						<th scope="col">Title</th>
                        <th scope="col">Theme</th>
                        <!-- <th scope="col">Description</th> -->
                        <th scope="col">Lead Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nomination of nominationArray">
                        <td>
                          <!-- <mat-checkbox class="example-margin" color="primary"
                          (change)="onClick(nomination.id,$event)" >
                          </mat-checkbox> -->
                          <mat-checkbox class="example-margin" color="primary"
                           disabled>
                          </mat-checkbox>
                    </td>
                        <td>{{nomination?.id}}</td>
						<td>{{nomination?.idea_title}}</td>
                        <td>{{nomination?.theamName}}</td>
                        <!-- <td>{{nomination?.idea_title}}</td> -->
                        <td>{{nomination?.team_lead_name}}</td>
                    </tr>
                </tbody>
            </table>
            <div style="text-align: center">
              <!-- <button mat-raised-button (click)="addPrototype()" color="primary" class="btn btn-success">Submit Prototype</button> -->
              <button mat-raised-button color="primary" class="btn btn-success" disabled>Submit Prototype</button>
            </div>
        </div>
		<div class="row">
			<div class="col-md-12">
				<mat-paginator #paginator
								showFirstLastButtons
								(page)="nextPage($event)"
								[length]="total_pages"
								[pageIndex]="frontCurrentPageNo"
								[pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
							</mat-paginator>

			</div>
		</div>
    </div>

    <div *ngIf="nominationArray.length <= 0">
        <h3 style="text-align: center">prototype for nomination closed</h3>
    </div>
</mat-card>

<mat-card class="mat-elevation-z6 m-4 instiNominiCard">

    <div class="row" color="primary">
        <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0 0 20px 0;padding: 10px 0;color: #fff;border-radius: 4px;"> List of Prototype Nominated </h2>
    </div>

    <div class="">
        <div class="">
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Theme</th>
                        <th scope="col">Lead Name</th>
                        <th scope="col">Withdraw Nomination</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nomination of acceptedNomination; let i = index">
                        <td>{{nomination?.idea_title}}</td>
                        <td>{{nomination?.theamName}}</td>
                        <td>{{nomination?.team_lead_name}}</td>
                        <td>
                          <!-- <button mat-raised-button color="primary"
                          (click)="withdraw(nomination?.id)" class="btn btn-success btnDownSuccess">Withdraw</button> -->
                          <button mat-raised-button color="primary"
                          class="btn btn-success btnDownSuccess" disabled>Withdraw</button>
            </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</mat-card>
