
<div class="container">
  <div class="instMyCouncilModal">
      <div class="container">
        <div class="row">
          <div class="col-md-12"
            style="background-color: #fff;color: #000 !important;font-size: 27px;">
            <p class="hdr bifuracationPara">Performance Report</p>
            <div style="padding: 0 0 20px 0; margin: 0 0 20px 0;">
              <ul>
                <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Cumulative Performance Report for the IIC Calendar Year 2020-21</b></li>
                <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">Bifurcation of Score and Reward Points</li>
                <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">
                  <div class="row">
                    <div class="col-md-12 NewScoreList">
                      <span class="mic_btn mic_totalmarks">Score: {{formData?.total_marks}}</span>
                      <span class="mic_btn mic_rewardpoints">Reward: {{formData?.totalRewards}}</span>
                      <span class="mic_btn mic_rating1">Rating: {{formData?.rating}}/4</span>
                      <span class="mic_btn mic_rating2">Fifth Star Rating: {{formData?.fifthStar}}/1</span>
                      <span class="mic_btn mic_rating">Final Star: {{formData?.final_star}}/5</span>
                    </div>
                  </div>
                </li>
              </ul>

              <table class="table table-hover" style="margin-bottom: 0px;">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="15%">Activity Type
                    </th>
                    <th class="hdr scoreTH" width="15%">Activity Submitted
                    </th>
                    <th class="hdr scoreTH" width="15%">Total Number
                      of
                      Activities Approved</th>
                    <th class="hdr scoreTH" width="15%">Total
                      Threshold
                      Number of Activities</th>
                    <th class="hdr scoreTH" width="15%">Score (for
                      minimum
                      prescribed activities)</th>
                    <th class="hdr scoreTH" width="25%">Adjusted
                      Cumulative
                      Reward Points For Additional Activities Beyond the Threshold Numbers for Each
                      Category (Reflection in multiple of 100 with activity score)</th>
                  </tr>
                  <tr>
                    <td class="hdr"
                      style="font-weight: bold;">
                      IIC Calendar Activity<br/>Score for 1 activity=5.55<br/>Minimum 9 activities</td>
                      <td class="hdr" style="color:red !important;font-weight: 900;">
                        {{formData?.iicAll}}</td>
                      <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.iicCount}}</td>

                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      9</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.iic_total_marks}}<br>
                      <span style="color:#1de9b6 !important;">(Max Score=50)</span>
                    </td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.totalRewardsIIC}}
                    </td>

                  </tr>
                  <tr>
                    <td class="hdr"
                      style="font-weight: bold;">
                      MIC driven Activity<br/>Score for 1 activity=2.5<br/>Minimum 8 activities</td>
                      <td class="hdr" style="color:red !important;font-weight: 900;">
                        {{formData?.micAll}}</td>
                      <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.micMarksCount}}</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      8</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.mic_total_marks}} <br>
                      <span style="color:#1de9b6 !important;">(Max Score=20)</span>
                    </td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.totalRewardsMIC}}

                    </td>

                  </tr>
                  <tr>
                    <td class="hdr"
                      style="font-weight: bold;">
                      Self-driven Activity<br/>Score for 1 activity 3.33<br/>Minimum 9 activities</td>
                      <td class="hdr" style="color:red !important;font-weight: 900;">
                        {{formData?.selfAll}}</td>
                      <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.selfCount}}</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      9</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.self_total_marks}}
                      <br>
                      <span style="color:#1de9b6 !important;">(Max Score=30)</span>
                    </td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.totalRewardsSelf}}
                    </td>

                  </tr>

                  <tr>
                    <td class="hdr"
                      style="font-weight: bold;">
                      Total</td>
                      <td class="hdr" style="color:red !important;font-weight: 900;">
                        {{formData?.selfAll+formData?.iicAll+formData?.micAll}}</td>
                      <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.selfCount+formData?.iicCount+formData?.micCount}}</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      26</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.self_total_marks+formData?.iic_total_marks+formData?.mic_total_marks}}
                      <br>
                      <span style="color:#1de9b6 !important;">100</span>
                    </td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.totalRewards}}
                    </td>

                  </tr>
                </tbody>
              </table>

              <div class="row">
                <div class="col-sm-5">

                  <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 5px; margin-bottom: 20px;">Effective score range for Star allocation from Quarter 1 to Quarter 4:</span>

              <table style="text-align: center;"
                class="table table-hover">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Score Range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Star allocation</th>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;"> 0<Score<=25 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">1</td>
                  </tr>
                  <!-- <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">25<Score<=35</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">One and a Half Star</td>
                  </tr> -->
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">25<Score<=50</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">2</td>
                  </tr>
                  <!-- <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">50<Score<=65</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Two and a Half Star</td>
                  </tr> -->
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">50<Score<=75</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">3</td>
                  </tr>
                  <!-- <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">75<Score<=85</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three and a Half Star</td>
                  </tr> -->
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">75<Score<=100</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">3.5</td>
                  </tr>
                </tbody>
              </table>


              <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 5px; margin-bottom: 20px;">Score range for 5th Star allocation:</span>

              <table style="text-align: center;"
                class="table table-hover">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Score Range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Star allocation</th>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;"> 0<Score<50 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">0</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;"> Score=>50 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">0.5</td>
                  </tr>
                </tbody>
              </table>

                </div>

                <div class="col-sm-7" style="font-size: 12px;">

                  <h4 class="NewScoreList" style="font-size: 18px;font-weight: 900;" ><strong>Parameters for 5th Star:</strong></h4>

                  <table style="text-align: center;"
                class="table table-hover">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="25%" style="text-align:left; width: 10%;" >S. No.</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:left; width: 55%;">Parameter</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:left; width: 15%;">Weightage</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:left; width: 20%;">Score Earned</th>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 1 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Reward Points earned</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 30 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.reward_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 2 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Submission of Annual Report of IIC Institution 2020-21</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 15 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.annual_report_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 3 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Participation in Innovation Ambassador Training Program {{fStar?.ia_training_score}}</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.ia_training_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 4 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Shortlisted Student innovation teams from IIC institutions have reached the 3rd stage (Business Plan development) as part of the National Innovation Contest 2020.</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.nic_team_bplan_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 5 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Participation and Hosting of Toycathon 2021 program of MIC</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.toycathon_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 6 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Participation in NISP</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.nisp_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 7 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Participation in ARIIA</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.ariia_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 8 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Innovation Ambassadors from the IIC institutions had engaged as Mentor to the student teams as part of the National Innovation Contest 2020.</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important;">{{fStar?.ia_nic_mentoship_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">&nbsp; </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Total</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#1de9b6 !important;"> 100 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important;">{{fStar?.total_score}}</td>
                  </tr>

                </tbody>
              </table>

                </div>

              </div>

              <p class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Weightage of each activity type:</b></p>
              <table class="table table-hover" style="margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th class="hdr scoreTH">Activity Type</th>
                    <th class="hdr scoreTH">Weightage in %</th>
                    <th class="hdr scoreTH">Q1</th>
                    <th class="hdr scoreTH">Q2</th>
                    <th class="hdr scoreTH">Q3</th>
                    <th class="hdr scoreTH">Q4</th>
                    <th class="hdr scoreTH">Total Score</th>
                    <th class="hdr scoreTH">Threshold Activities</th>
                    <th class="hdr scoreTH">Score for one Activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>IIC Annual Calendar Plan</td>
                    <td>50%</td>
                    <td>12.5</td>
                    <td>12.5</td>
                    <td>12.5</td>
                    <td>12.5</td>
                    <td>50</td>
                    <td>9</td>
                    <td>5.56</td>
                  </tr>
                  <tr>
                    <td>Self-Driven Activities</td>
                    <td>30%</td>
                    <td>7.5</td>
                    <td>7.5</td>
                    <td>7.5</td>
                    <td>7.5</td>
                    <td>30</td>
                    <td>9</td>
                    <td>3.33</td>
                  </tr>
                  <tr>
                    <td>MIC Driven Activities</td>
                    <td>20%</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>20</td>
                    <td>8</td>
                    <td>2.5</td>
                  </tr>
                  <tr>
                    <td>Total Score</td>
                    <td>100%</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>100</td>
                    <td>26</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <!--
              <p class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Following methodology has been used for calculation of quarterly score:</b></p>
              <p class="hdr" style="font-size: 17px;margin-bottom: 20px;">Scores are calculated based on the minimum prescribed activities in each “activity type”. IIC earn Reward Points for conducting more than prescribed minimum activities under each “activity type”, which is effective towards 5th star. </p><br/>
              <p class="hdr hdrScore scoreHdrNew" style="font-size: 17px;margin-bottom: 20px;">Score Matrix for IIC 2019-20 (All 4 quarters): </p>

              <div>
                <img src="assets/img/socre_formula.jpg" class="img-responsive matrixIIC" />
              </div>
              -->
              <span class="hdr" style="text-align:center;font-size: 16px; line-height: 1.2; display: block;"><b>Note:</b> IIC
                calendar year is divided in 4 quarters. IICs can earn maximum 25 marks in each
                quarter.</span>
              <hr style="margin:0px !important;">

              <div class="alert alert-danger" style="margin-top: 20px; font-size:14px; line-height: 1.4; color: #FF0000;">*For the IIC calendar year 2020-21, considering the impact of national lockdown had adversely affected all HEIs in terms of deferring and squeezing academic and IIC calendar year, it further limited HEIs in terms nature and mode of activities conducted, and quality participations in comparison to physical mode of activities, further there is drastic reduction in on-campus engagements for innovation and startup generating activities during the period, a normalization approach was applied towards star allocation. Therefore, for the 2020-21, the maximum stars any institution can obtain is 3.5 out of 4 stars allocated . Similarly, for the 5th star, considering the criteria (mentioned in the performance card), the maximum star any institution can obtain is 0.5 star score out of 1upon fulfilling majority of these requirements. Institute can check the stars obtained in respective sections and total stars obtained at the top of the performance card. This applies to all IIC institutions irrespective of categories they belong to. </div>


              <!-- <table style="width:50% !important;margin: auto;margin-bottom: 20px;text-align: center;"
                class="table table-hover">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Starting Score range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Ending Score range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Star allocation</th>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">>0</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">24.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">One Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">24.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">34.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">One and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">34.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">49. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Two Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">49.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">64. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Two and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">64.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">74.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">74.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">84. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">84.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">100</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Four Star</td>
                  </tr>
                </tbody>
              </table> -->

              <!--
              <p class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Parameters for the calculation of 5th Star:</b></p>
              <table class="table table-hover" style="margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th class="hdr scoreTH">S.No</th>
                    <th class="hdr scoreTH">5th star Parameters</th>
                    <th class="hdr scoreTH">Weightage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Regional Mentoring Session hosting</td>
                    <td>5 marks</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Innovation Ambassador Training Hosting</td>
                    <td>30 marks</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Innovation Contest 2020 Participation</td>
                    <td>12.5</td>
                  </tr>
                  <tr>
                    <td>3.1 </td>
                    <td>50- ideas</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>3.2 </td>
                    <td>25 PoCs</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>3.3 </td>
                    <td>15 prototype</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Reward points</td>
                    <td>50 marks *</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Finalist of PoC contest</td>
                    <td>10</td>
                  </tr>

                </tbody>
              </table>

              <table class="table table-hover" style="margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th class="hdr scoreTH">Score Range</th>
                    <th class="hdr scoreTH"> Star</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Score greater than equal to 50 marks</td>
                    <td>Full star</td>
                  </tr>
                  <tr>
                    <td>10 greater than equal to score greater than 49.9 marks</td>
                    <td>0.5 star</td>
                  </tr>
                  <tr>
                    <td>Score greater than 9.9marks</td>
                    <td>0 star</td>
                  </tr>

                </tbody>
              </table>

              <div>
                <img src="assets/img/5star.jpg" class="img-responsive matrixIIC" />
              </div>

            </div>
          </div>
        </div>

      </div>
  </div>
</div>
-->
<!-- <div class="container">
<div class="instMyCouncilModal">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="background-color: #fff;color: #000 !important;font-size: 27px;">
          <p class="hdr bifuracationPara">Performance Report</p>
          <div style="padding: 0 0 20px 0; margin: 0 0 20px 0;">
            <ul>
              <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Quarter 1 & Quarter 2 Cumulative Score</b></li>
              <li class="hdr" style=";font-size: 17px;margin-bottom: 20px;">Bifurcation of Score and Reward Points</li>
              <li class="hdr" style=";font-size: 17px;margin-bottom: 20px;">
                <div class="row">
                  <div class="col-md-6 scoreBifuraction">
                    <p>Score:{{formData?.total_marks}}</p>
                  </div>
                  <div class="col-md-6 rewardBifuracation">
                    <p>Reward:{{formData?.totalRewardsInDecimal}}</p>
                  </div>
                </div>
              </li>
            </ul>
            <table class="table table-hover" style="margin-bottom: 0px;">
              <tbody>
                <tr>
                  <th class="hdr" width="20%">Activity Type</th>
                  <th class="hdr" width="20%">Total Number of Activities Approved</th>
                  <th class="hdr" width="20%">Total Threshold Number of Activities</th>
                  <th class="hdr" width="20%">Score (for minimum prescribed activities)</th>
                  <th class="hdr" width="25%">Adjusted Cumulative Reward Points For Additional Activities Beyond the Threshold Numbers for Each Category (Reflection in multiple of 100 with activity score)</th>
                </tr>
                <tr>
                  <td class="hdr" tyle="font-weight: bold;"> IIC Calendar Activity (Score for 1 activity=4.16) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.iicCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.iic_total_marks}}<br><span style="color:#1de9b6 !important;">(Max Score=24.96)</span></td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.ScoreIIC}}</td>
                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;">MIC driven Activity (Score for 1 activity=1.67) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.micCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.mic_total_marks}} <br> <span style="color:#1de9b6 !important;">(Max Score=10.02)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.scoreMIC}}
                  </td>
                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;">Self-driven Activity (Score for 1 activity=2.5) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.selfCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.self_total_marks}}
                    <br>
                    <span style="color:#1de9b6 !important;">(Max Score=15)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.ScoreSelf}}
                  </td>
                </tr>
              </tbody>
            </table>
            <span class="hdr" style="text-align:center;font-size: 12px;display: block;"><b>Note:</b> IIC calendar year is divided in 4 quarters. IICs can earn maximum 25 marks in each quarter.</span>
            <hr style="margin:0px !important;">
            <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 25px; margin-bottom: 20px;">Score to star Conversion:</span>
            <table style="width:30% !important;margin: auto;margin-bottom: 20px;" class="table table-hover">
              <tbody>
                <tr>
                  <th class="hdr" width="25%" style="text-align:center;">Score</th>
                  <th class="hdr" width="25%" style="text-align:center;">Star</th>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">0-6.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Zero Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">7 – 14.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Half Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">15-24.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">One Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">25-34.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">One and a Half Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">35-50</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Two Star</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
</div> -->
