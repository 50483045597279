<div class="row m-4" *ngIf="!(declareSemesters.length > 0)">
  <button mat-raised-button color='primary' class="ml-3 mt-2" (click)='openDialog()'>Add Semesters <mat-icon>add
    </mat-icon> </button>
</div>
<div>
  <!-- <div class="mt-3">
        <table class="table table-hover">
            <thead class="semesterTable">
                <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Duration of First Semester</th>
                    <th scope="col">Semester Vacation First Semester</th>
                    <th scope="col">Duration of Second Semester</th>
                    <th scope="col">Semester Vacation Second Semester</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let semester of declareSemesters; let i = index;">
                    <th scope="row">{{i +1}}</th>
                    <td>{{semester?.duration_semester_first_from}} to {{semester?.duration_semester_first_to}}</td>
                    <td>{{semester?.semester_vacation_first_from}} to {{semester?.semester_vacation_first_to}}</td>
                    <td>{{semester?.duration_semester_sec_from}} to {{semester?.duration_semester_sec_to}}</td>
                    <td>{{semester?.semester_vacation_sec_from}} to {{semester?.semester_vacation_sec_to}}</td>
                    <td style="cursor: pointer" (click)="openDialog(semester)">
                        <mat-icon>edit_road</mat-icon>
                    </td>
                    <td style="cursor: pointer" (click)="deleteInstituteDetails()">
                        <mat-icon>close</mat-icon>
                    </td>
                </tr>
            </tbody>
        </table>
    </div> -->





  <div class="row m-4">
    <!-- <div class="col-xl-6 col-md-6 mb-4" *ngFor="let semester of declareSemesters; let i = index;">
      <div class="aheto-pricing aheto-pricing--default  mat-elevation-z8">
        <div class="aheto-pricing__content">
          <div class="aheto-pricing__description">
            <h4 class="aheto-pricing__title t-bold">Duration of First Semester</h4>
            <p style="font-size: 130% !important">{{semester?.duration_semester_first_from}} to
              {{semester?.duration_semester_first_to}}</p>
          </div>
          <div class="aheto-pricing__description">
            <h4 class="aheto-pricing__title t-bold">Semester Vacation First Semester</h4>
            <p style="font-size: 130% !important">{{semester?.semester_vacation_first_from}} to
              {{semester?.semester_vacation_first_to}}</p>
          </div>
          <div class="aheto-pricing__description">
            <h4 class="aheto-pricing__title t-bold">Duration of Second Semester</h4>
            <p style="font-size: 130% !important">{{semester?.duration_semester_sec_from}} to
              {{semester?.duration_semester_sec_to}}</p>
          </div>
          <div class="aheto-pricing__description">
            <h4 class="aheto-pricing__title t-bold">Semester Vacation Second Semester</h4>
            <p style="font-size: 130% !important">{{semester?.semester_vacation_sec_from}} to
              {{semester?.semester_vacation_sec_to}}</p>
          </div>
          <div>
            <button type="button" class="btn btn-secondary" (click)="openEditDialog(semester)">Edit</button>
          </div>
          <div>
            <button type="button" class="btn btn-secondary" (click)="deleteDialog(semester)">Delete</button>
          </div>
        </div>
      </div>
    </div> -->


    <div class="col-md-8" *ngFor="let semester of declareSemesters; let i = index;" style="margin: 0 auto;">
      <div class="CardBox">
        <img src="assets/customCard/card-gradient-img.png" class="CardBoxContentTopImg CardBoxContentTopImgMyCouncil">
		<img src="assets/customCard/card-top-banner.png" class="CardBoxContentTopBanner CardBoxContentTopBannerdeclareSem">
		<div class="CardBoxContentTopName CardBoxContentTopNameDeclareSem">
          <!-- <h3>Name</h3> -->
          <h3>Declaration of Semesters</h3>
        </div>
        <div class="CardBoxContent">
          <h3>Duration of First Semester</h3>
          <p>{{semester?.duration_semester_first_from}} to
            {{semester?.duration_semester_first_to}}</p>
        </div>

        <!-- <div class="CardBoxContent">
          <h3>Semester Vacation First Semester</h3>
          <p>{{semester?.semester_vacation_first_from}} to
            {{semester?.semester_vacation_first_to}}</p>
        </div>

        <div class="CardBoxContent">
          <h3>Study Holidays First Semester</h3>
          <p>{{semester?.study_holidays_first_from}} to
            {{semester?.study_holidays_first_to}}</p>
        </div> -->

        <div id="InstDeclearSemID" class="collapse">

          <div class="CardBoxContent">
            <h3>Duration of Second Semester</h3>
            <p>{{semester?.duration_semester_sec_from}} to
              {{semester?.duration_semester_sec_to}}</p>
          </div>

          <!-- <div class="CardBoxContent">
            <h3>Semester Vacation Second Semester</h3>
            <p>{{semester?.semester_vacation_sec_from}} to
              {{semester?.semester_vacation_sec_to}}</p>
          </div>

          <div class="CardBoxContent">
            <h3>Study Holidays Second Semester</h3>
            <p>{{semester?.study_holidays_sec_from}} to
              {{semester?.study_holidays_sec_to}}</p>
          </div> -->

        </div>

        <!-- <div class="CardBoxContent">
          <div class="row">
            <div class="col-md-6">
              <div class="cardEditIcon">
                <a class="cardEditIconEdit" (click)="openEditDialog(semester)"> <i class="fa fa-pencil-square-o"
                    aria-hidden="true"></i> Edit </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="cardEditIcon">
                <a class="cardEditIconDelete" (click)="deleteDialog(semester)"> <i class="fa fa-trash-o"
                    aria-hidden="true"></i> Delete </a>
              </div>
            </div>
          </div>
        </div> -->

        <div class="CardBoxContent">
          <div class="row">
            <div class="col-md-6">
              <div class="form-button-action">
                <a (click)="openEditDialog(semester)" data-toggle="tooltip" title=""
                  class="btn btn-link btn-primary btn-lg edit1 instDecSemCustom" data-original-title="Edit Task">
                  <i class="fa fa-edit" style="
                  margin-top: 12px;
              "> Edit</i>
                </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-button-action">
                <a (click)="deleteDialog(semester)" data-toggle="tooltip" title=""
                  class="btn btn-link btn-danger deleteInst2 instDeleDeclSemCustom" data-original-title="Remove">
                  <i class="fa fa-times" style="
                  font-size: 20px;
              "> Delete</i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="cardReadMore">

          <button data-toggle="collapse" data-target="#InstDeclearSemID" *ngIf="readMore"
            (click)="readMore=!readMore">Read More</button>
          <button data-toggle="collapse" data-target="#InstDeclearSemID" *ngIf="!readMore"
            (click)="readMore=!readMore">Read Less</button>

        </div>


      </div>
    </div>


  </div>



  <div *ngIf="declareSemesters.length<1">
    <div class="card text-center text-white bg-info mb-4 card-shadow">
      <div class="card-body">
        <p class="card-text text-white"><i>No Semesters added yet!</i></p>
      </div>
    </div>
  </div>
</div>
