import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-yukti-institute-challenges',
  templateUrl: './yukti-institute-challenges.component.html',
  styleUrls: ['./yukti-institute-challenges.component.css']
})
export class YuktiInstituteChallengesComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  baseUrl = myGlobals.baseUrl1;
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicrepository:any;
  yuktiInstituteChallenges:any;
  today:Date;

  constructor(private instituteActivityService :InstituteActivityService) { }

  ngOnInit(): void {
    this.requireField=0;
    
    this.today = new Date();

    this.instituteActivityService.getYuktiInstituteChallenges().subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.yuktiInstituteChallenges = data;
  }

  ChallengeEnd(dateString: string): boolean {
    const date = new Date(dateString);
    return date < this.today;
  }

  NominateChallenge(dateString: string): boolean {
    const date = new Date(dateString);
    return date > this.today;
  }

  onClickEvent(data,type){
    if(type=="Edit")
    {
      window.location.href="/institute/yukti-challenges-add/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.DeleteYuktiChallenges(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }
  }

  handleResponse1(data)
  {

  }

}
