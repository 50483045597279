import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';

@Component({
  selector: 'app-iic-outcome',
  templateUrl: './iic-outcome.component.html',
  styleUrls: ['./iic-outcome.component.css']
})
export class IicOutcomeComponent implements OnInit {

  public single: any[];
  public showLegend = true;
  public gradient = true;
  public colorScheme = {
    domain: ['#586B7D', '#5F9EDA', '#9E9492', '#0096A6', '#F47B00', '#606060']
  };
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;
  public trimLabels = false;
  public showPieLegend = false;
  public legendPosition: string = 'below';

  //bar
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public barGradient = false;
  public showBarLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Quarterwise count of activities';
  public showYAxisLabel = false;
  public yAxisLabel = 'Activity';
  public barColorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#91d494', '#0096A6', '#F47B00', '#606060']
  };
  public autoScale = true;

  //Stacked bar
  public multi2: any[];
  public xAxisStackedLabel = 'Events';
  public showYAxisStackedLabel = true;
  public yAxisStackedLabel = 'Participants';
  public stackedColorScheme = {
    domain: ['#378D3B', '#0096A6']
  };
  overallImpact: any;
  ForWhom: any;

  ngOnInit() {
	  
	this.ForWhom="";
	this.ForWhom=localStorage.getItem('ForWhom');

    //Pie data
    this.dashboardService.getPieData2021(localStorage.getItem('token')).subscribe(
      data => this.setPieData(data['data'])
    );

    //bar data
    this.dashboardService.getQuarterwiseBarData2021().subscribe(
      data => this.setBarData(data)
    )

    //Eventwise Bar
    this.setEventwiseBarData();

    //gauge chhart for IIC Calendar
    this.setGauge1Data();

    //gauge chhart for MIC Driven
    this.setGauge2Data();

    //gauge chhart for self-driven
    this.setGauge3Data();

    //stacked bar chart
    this.dashboardService.getOverAllImapact2021().subscribe(
      data => {
        this.multi2 = data['data'].myImpact
        this.overallImpact = data['data']
      }
    )
	
	/*------------------------------------------------------------------
   	 Scroll To Top
	 ------------------------------------------------------------------*/  
	  $(document).ready(function(){ 
		$(window).scroll(function(){ 
			if ($(this).scrollTop() > 100) { 
				$('#scroll').fadeIn(); 
			} else { 
				$('#scroll').fadeOut(); 
			} 
		}); 
		$('#scroll').click(function(){ 
			$("html, body").animate({ scrollTop: 0 }, 600); 
			return false; 
		}); 
	});

  }

  //bar and line chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel:
        {
          display: false,
          labelString: 'Events',
        }
      }], yAxes: [{
        scaleLabel:
        {
          display: true,
          labelString: 'Counts',
        }
      }]
    },
    // elements: {
    //   line: {
    //           fill: false
    //   }
    // }
  };


  public barChartLabels: Label[] = ['2018-19', '2019-20', '2020-21'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Color[] = [
    { backgroundColor: '#91d494' },
    { backgroundColor: '#0096A6' },
    { backgroundColor: '#2F3E9E' },
    { backgroundColor: '#D22E2E' },
    { backgroundColor: '#742189' }
  ]

  barChartData: ChartDataSets[] = [
    { data: [], label: 'Idea' },
    { data: [], label: 'Prototype' },
    { data: [], label: 'Venture' },
	{ data: [], "type": "line", fill: false, label: 'Student', borderColor: '#D22E2E' },
	{ data: [], "type": "line", fill: false, label: 'Faculty', borderColor: '#742189' }
    //{ data: [], "type": "line", fill: false, label: 'Highest Student', borderColor: '#2F3E9E' },
    //{ data: [], "type": "line", fill: false, label: 'Highest Faculty', borderColor: '#D22E2E' }
  ];

  barChartDataInitiate() {
    this.barChartData = [
      { data: [], label: 'Idea' },
      { data: [], label: 'Prototype' },
      { data: [], label: 'Venture' },
      { data: [], "type": "line", fill: false, label: 'Student', borderColor: '#D22E2E' },
      { data: [], "type": "line", fill: false, label: 'Faculty', borderColor: '#742189' }
	  //{ data: [], "type": "line", fill: false, label: 'Highest Student', borderColor: '#2F3E9E' },
      //{ data: [], "type": "line", fill: false, label: 'Highest Faculty', borderColor: '#D22E2E' }
    ];
  }

  selectedType = 'IIC Calendar Activity';

  setEventwiseBarData() {
    if (this.selectedType == 'IIC Calendar Activity') {

      this.barChartDataInitiate();

      //this.barChartType = 'bar';
      this.barChartData.forEach(element => {
        element.data = []
      });
      let data = 1;
      this.dashboardService.getEventWiseBarData2021(data).subscribe(
        data => {
          data['IIC Calendar Activity']['Students'].forEach(element => {
            this.barChartData[0]['data'].push(element)
          });
          data['IIC Calendar Activity']['Faculty'].forEach(element => {
            this.barChartData[1]['data'].push(element)
          });
		  /*data['IIC Calendar Activity']['Idea'].forEach(element => {
            this.barChartData[2]['data'].push(element)
          });
          data['IIC Calendar Activity']['Prototype'].forEach(element => {
            this.barChartData[3]['data'].push(element)
          });
		  data['IIC Calendar Activity']['Venture'].forEach(element => {
            this.barChartData[4]['data'].push(element)
          }); */
		  
		  
          data['IIC Calendar Activity']['Max_Students'].forEach(element => {
            this.barChartData[2]['data'].push(element)
          });
          data['IIC Calendar Activity']['Max_Faculty'].forEach(element => {
            this.barChartData[3]['data'].push(element)
          });
        }
      );
    }
    if (this.selectedType == 'MIC Driven Activity') {
      //this.barChartType = 'bar';

      this.barChartDataInitiate();

      this.barChartData.forEach(element => {
        element.data = []
      });

      let data = 2;
      this.dashboardService.getEventWiseBarData2021(data).subscribe(
        data => {
          data['MIC driven Activity']['Students'].forEach(element => {
            this.barChartData[0]['data'].push(element)
          });
          data['MIC driven Activity']['Faculty'].forEach(element => {
            this.barChartData[1]['data'].push(element)
          });
		  /*data['MIC driven Activity']['Idea'].forEach(element => {
            this.barChartData[2]['data'].push(element)
          });
          data['MIC driven Activity']['Prototype'].forEach(element => {
            this.barChartData[3]['data'].push(element)
          });
		  data['MIC driven Activity']['Venture'].forEach(element => {
            this.barChartData[4]['data'].push(element)
          }); */
		  
		  
          data['MIC driven Activity']['Max_Students'].forEach(element => {
            this.barChartData[2]['data'].push(element)
          });
          data['MIC driven Activity']['Max_Faculty'].forEach(element => {
            this.barChartData[3]['data'].push(element)
          });
        }
      );
    }
    if (this.selectedType == 'Self Driven Activity') {

      this.barChartData.forEach(element => {
        element.data = []
      });
      let data = 3;

      this.barChartData = [
        { data: [], label: 'Student' },
        { data: [], label: 'Faculty' }
      ];

      //this.barChartType = 'line';
      this.dashboardService.getEventWiseBarData2021(data).subscribe(
        data => {
          data['Self-driven Activity']['Students'].forEach(element => {
            this.barChartData[0]['data'].push(element)
          });
          data['Self-driven Activity']['Faculty'].forEach(element => {
            this.barChartData[1]['data'].push(element)
          });
		  /* data['Self-driven Activity']['Idea'].forEach(element => {
            this.barChartData[2]['data'].push(element)
          });
		  data['Self-driven Activity']['Prototype'].forEach(element => {
            this.barChartData[3]['data'].push(element)
          });
		  data['Self-driven Activity']['Venture'].forEach(element => {
            this.barChartData[4]['data'].push(element)
          });*/
		  
		  
           data['Self-driven Activity']['Max_Students'].forEach(element => {
             this.barChartData[2]['data'].push(element)
           });
           data['Self-driven Activity']['Max_Faculty'].forEach(element => {
             this.barChartData[3]['data'].push(element)
           });
        }

      );
    }

  }

  //Gauge chart
  public canvasWidth = 300;
  public needleValue: number = 0;
  public needleValue2: number = 0;
  public needleValue3: number = 0;
  public centralLabel = '';
  public bottomLabel;
  public bottomLabelFont = 30;
  public bottomLabel2 = '';
  public bottomLabel3 = '';
  public options1 = {
    hasNeedle: true,
    needleColor: 'black',
    arcPaddingColor: 'white',
    arcColors: [],
    arcDelimiters: [],
    rangeLabel: [],
    arcLabels: [],
    arcPadding: [2],
    arcOverEffect: true,
  }

  public options2 = {
    hasNeedle: true,
    needleColor: 'black',
    arcPaddingColor: 'white',
    arcColors: [],
    arcDelimiters: [],
    rangeLabel: [],
    arcLabels: [],
    arcPadding: [2],
    arcOverEffect: true,
  }

  public options3 = {
    hasNeedle: true,
    needleColor: 'black',
    arcPaddingColor: 'white',
    arcColors: [],
    arcDelimiters: [],
    rangeLabel: [],
    arcLabels: [],
    arcPadding: [2],
    arcOverEffect: true,
  }

  public setGauge1Data() {

    let data = 1;
    this.dashboardService.getEventWiseGaugeData2021(data).subscribe(
      data => {
        let endValue = data['IIC Calendar Activity'][0]['gauge_endpoint'];
        this.needleValue = 100 / endValue * data['IIC Calendar Activity'][0]['gauge_value'];
        this.options1.arcColors = data['IIC Calendar Activity'][0]['gauge_value'] > 12 ? ['green', '#c2c2a3'] : ['red', '#c2c2a3'];
        this.bottomLabel = data['IIC Calendar Activity'][0]['gauge_value'];
        this.options1.arcDelimiters = [100 / endValue * 12];
        this.options1.arcLabels = [12];
        this.options1.rangeLabel = ['0', "" + endValue + ""];
      }
    )
  }
  public setGauge2Data() {

    let data = 2;
    this.dashboardService.getEventWiseGaugeData2021(data).subscribe(
      data => {
        let endValue = data['MIC driven Activity'][0]['gauge_endpoint'];
        this.needleValue2 = 100 / endValue * data['MIC driven Activity'][0]['gauge_value'];
        this.options2.arcColors = data['MIC driven Activity'][0]['gauge_value'] > 24 ? ['green', '#c2c2a3'] : ['red', '#c2c2a3'];
        this.bottomLabel2 = data['MIC driven Activity'][0]['gauge_value'];
        this.options2.arcDelimiters = [100 / endValue * 24];
        this.options2.arcLabels = [24];
        this.options2.rangeLabel = ['0', "" + endValue + ""];
      }


    )
  }

  public setGauge3Data() {

    let data = 3;

    this.dashboardService.getEventWiseGaugeData2021(data).subscribe(
      data => {
        let endValue = data['Self-driven Activity'][0]['gauge_endpoint'];
        this.needleValue3 = 100 / endValue * data['Self-driven Activity'][0]['gauge_value'];
        //this.options3.arcColors = ['red', '#c2c2a3'];
        this.options3.arcColors = data['Self-driven Activity'][0]['gauge_value'] > 12 ? ['green', '#c2c2a3'] : ['red', '#c2c2a3'];
        this.bottomLabel3 = data['Self-driven Activity'][0]['gauge_value'];
        this.options3.arcDelimiters = [100 / endValue * 12];
        this.options3.arcLabels = [12];
        this.options3.rangeLabel = ['0', "" + endValue + ""];
      }
    )
  }

  constructor(
    private dashboardService: InstituteDashboardService
  ) {
  }

  public onSelect(event) {
  }

  setPieData(data) {
    data.forEach(function (obj) {
      obj.name = obj.theme_name;
      delete obj.theme_name;
      obj.value = obj.theme_count;
      delete obj.theme_value;
    });
    this.single = data;
  }

  setBarData(data) {

    data.forEach(element => {

      element.series.forEach(function (obj) {
        obj.name = obj.quarter;
        delete obj.quarter;
        obj.value = obj.count;
        delete obj.count;
      });
    });
    this.multi = data;
  }

}
