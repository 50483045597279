import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { AtlLoginDialogComponent } from './atl-login-dialog/atl-login-dialog.component';

@Component({
  selector: 'app-atl-user-dashboard',
  templateUrl: './atl-user-dashboard.component.html',
  styleUrls: ['./atl-user-dashboard.component.css']
})
export class AtlUserDashboardComponent implements OnInit {

  currentPageNo: number = 1;
  isDisplay: number = 0;
  myActivity: InstitueMyActivity[] = [];
  firstPage = true;
  lastPage: boolean = false;
  reportsClosed: boolean = true;
  quarter_filter: string = '';
  activity_filter:string='';
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  title1 = "";
  searched: string;
  search_keyword: string;
  eventId: any;
  cityList: any;
  districtList: any;
  MyActivityannualReport: number = 0;
  actiontype: any;
  constructor(
    private activityService: InstituteActivityService,
    public dialog: MatDialog,
    private toastr: ToastrService,

  ) { }


  ngOnInit(): void {

    this.getMyActivityDetails(this.currentPageNo);

    this.activityService.getIICCityDistrictList('all').subscribe(
      (data) => this.handleResponseList(data, 'all')
    );
    this.activityService.checkUDISENumber().subscribe(
      (data) => this.handleResponseDialog(data)
    );
  }

  handleResponseDialog(data){
    if(data['message'] == 'Error'){
      this.onOpenDialog();
    }
  }
  handleResponseList(data,type) {
    if(type=="city")
    {
      // this.cityList       = data['cities'];
    }
    else
    {
      // this.cityList       = data['cities'];
      this.districtList   = data['districts'];
    }
  }

  onSubmitClick(data, type){
    this.activityService.mentorAtalIICSelection(data.userId, type).subscribe(
      data => this.handleResponse2(data)
    );
  }

  handleResponse2(data){
    if(data['status']=='1'){
      alert(data['message']);
      window.location.href='/institute/atlsic-dashboard';
    }else{
      this.toastr.error(data['message'])

    }
  }

  nextPage() {
    this.currentPageNo++;
    this.getMyActivityDetails(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getMyActivityDetails(this.currentPageNo);
  }

  getMyActivityDetails(pageNo) {
    this.activityService
      .getIICinstitutesList(this.per_page, pageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22')
      .subscribe((data) => this.handleResponse(data));
  }
  getMyActivityDetails1() {
    this.activityService
      .getIICinstitutesList(this.per_page, this.currentPageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22')
      .subscribe((data) => this.handleResponse(data));
  }
  nextPage1(e: any) {
    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    this.getMyActivityDetails1();
  }

  reviewRequest(data){
    this.eventId=data;

    this.activityService
    .submitReviewRequest(this.eventId)
    .subscribe((data) => this.reviewResponse(data));
  }

  reviewResponse(data) {
    if(data['status']=='1'){
      this.toastr.success(data['msg']);
      this.getMyActivityDetails(this.currentPageNo);
    }else{
      this.toastr.error(data['msg']);
    }
  }

  onDeleteClick(data){
    this.eventId=data['eventId'];

    if (confirm('Are you sure you want to delete?')) {
      // Save it!
    this.activityService
    .deleteMyActivity(this.eventId)
    .subscribe((data) => this.deleteResponse(data));
    } else {
      // Do nothing!
      console.log('not confirm');
    }
  }

  deleteResponse(data){
if(data['status']=='1'){
  this.toastr.success(data['msg']);
  this.getMyActivityDetails(this.currentPageNo);
}else{
  this.toastr.error(data['msg']);
}
  }

  quarterFilter() {
    this.currentPageNo = 1;
    this.getMyActivityDetails(this.currentPageNo);
  }

  activityFilter(){
    this.currentPageNo = 1;
    this.getMyActivityDetails(this.currentPageNo);
  }

  onDistrictChange(ob){

    let district = ob.value;

    if(district!="")
    {
      this.activityService.getIICCityDistrictList(district).subscribe(
        (data) => this.handleResponseList(data,'city')
      );
    }
    else
    {
      this.activityService.getIICCityDistrictList("all").subscribe(
        (data) => this.handleResponseList(data,'all'));
    }

  }
  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searched = filterValue;
  }

  search() {
    this.search_keyword = this.searched;
    this.activityService.getIICinstitutesList(this.per_page, this.currentPageNo, this.quarter_filter, this.search_keyword,this.activity_filter,'2021-22').subscribe(
      data => this.handleResponse(data)
    )
  }

  handleResponse(data) {

    this.myActivity = data.data;
    this.total_pages = data.meta.pagination.total;
    this.MyActivityannualReport= data.meta.annualReport;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }


  }

  onOpenDialog(){
    const activity="";
    // const data1={
    //   data:activity,
    //   aptype:type
    // }

    
  const dialogRef = this.dialog.open(AtlLoginDialogComponent, {
    data: activity,disableClose: true
  });

  dialogRef.afterClosed().subscribe((result) => {
    // this.getDataTable();
    // this.getScheduleTable();
    // this.ActivityStatus();
  });
  }
  

}

export class InstitueMyActivity {
  id: number;
  title: string;
  description: string;
  userId: string;
  quarter: string;
  endDate: string;
  startDate: string;
  comments: string;
}
