<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

<link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
<link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">

<style>
	.navbar-nav>li {
		float: none !important;
		text-align: center !important;
		line-height: 24px;
	}
</style>

<div class="limiter">
	<div class="container-login100">
		<div class="wrap-login100">
			<span class="login100-form-title p-b-20">
				<img src="assets/institute/images/logoiic.png" class="loginIICLogo signupIICLogo">
			</span>
			<!-- <span class="login100-form-title loginInstiTopContent">
				<h2>Registration For IA Online Training Session Is Closed Now</h2>
			</span> -->
	<form [formGroup]="onlineRegistrationForm" (ngSubmit)="onSubmit(onlineRegistrationForm.value)"
				class="form-horizontal" style="color: black !important;">

				<div class="nomination-all-details">
					<div class="form-group">
						<div class="col-md-12"><label>State</label></div>
						<div class="col-md-12">
							<select class="form-control" formControlName="state">
								<option *ngFor="let state of stateArr" value="{{state.name}}">
									{{state.name}}</option>
							</select>
						</div>
					</div>
					<div class="form-group">
						<div class="col-md-12"><label>Institute name</label></div>
						<div class="col-md-12">
							<select class="form-control" formControlName="institute_name">
								<option *ngFor="let institute of institutes" value="{{institute.instituteName}}">
									{{institute.instituteName}}</option>
							</select>
							<mat-error *ngIf="onlineRegistrationForm.controls.institute_name.errors?.required">Institute
								Name is required
							</mat-error>
						</div>
					</div>
					<div class="form-group">
						<div class="col-md-12"><label>Name</label></div>

						<div class="col-md-12">
							<select class="form-control" formControlName="name">
								<option *ngFor="let prName of participantName" value="{{prName.id}}|{{prName.participant_name}}">
									{{prName.participant_name}}</option>
							</select>
							<mat-error *ngIf="onlineRegistrationForm.controls.name.errors?.required">Name is required
							</mat-error>
						</div>
					</div>
					<div class="form-group">
						<div class="col-md-12"><label>Email</label></div>
						<div class="col-md-12"><input type="email" class="form-control" name="email"
								formControlName="email" >

						</div>
					</div>
					<div class="form-group">
						<div class="col-md-12"><label>Phone Number</label></div>
						<div class="col-md-12"><input type="phone" class="form-control" name="phone"
								formControlName="phone_number" maxlength="10">

						</div>
					</div>
					<div class="form-group">
						<div class="col-md-12"><label>Gender</label></div>
						<div class="col-md-12">
							<input type="radio" name="gender" value="male" formControlName="gender">
							<label for="male" class="radioMale">Male</label>
							<input type="radio" name="gender" value="female" formControlName="gender">
							<label for="female" class="radioMale">Female</label>
						</div>
					</div>
					<div class="form-group">
						<div class="col-md-12"><label>Designation</label></div>
						<div class="col-md-12"><input type="text" class="form-control" name="designation"
								formControlName="designation">
							<mat-error *ngIf="onlineRegistrationForm.controls.designation.errors?.required">Designation
								is required
							</mat-error>
						</div>
					</div>
					<div class="form-group">
						<div class="col-md-12"><label >Total number of session attended - {{totalIICNo}}</label></div>
					</div>

				</div>


				<div class="col-md-12">
					<button class="nominationIICNewBut" type="submit">Submit</button>
				</div>

			</form>
		</div>
	</div>
</div>
