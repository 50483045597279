import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/app.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-atl-signup',
  templateUrl: './atl-signup.component.html',
  styleUrls: ['./atl-signup.component.css']
})

export class AtlSignupComponent implements OnInit {

  captchaResponse: any;

  public signupForm: FormGroup;
  public error = [];

  instituteDetails: any;

  institute_name: any = '';
  institute_address: any = '';
  institute_city: any = '';
  institute_state: any = '';
  uploadDiv: boolean;
  institute: any[];
  school_name:any;

  state: any[];

  constructor(private jarwis: JarwisService,
    private token: TokenService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  ngOnInit() {
    
    this.signupForm = this.fb.group({
      aishe_code: [null, Validators.required],
      name: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      mobile_number: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      institute_name: [null, Validators.required],
      institute_city: [null, Validators.required],
      institute_address: [null, Validators.required],
      institute_state: [null, Validators.required],      
      password: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/()\\[\\{}])(?=.*[A-Z])(?=.*[a-z]).*$')])],
      confirm_password: [null, Validators.compose([Validators.required, Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/])(?=.*[A-Z])(?=.*[a-z]).*$')])],
    });

    // this.signupForm.get('aicte_approved_program').valueChanges.subscribe(
    //   data => this.handleData2(data)
    // )

    this.jarwis.stateList().subscribe(
      data => this.setStates(data)
    );

    this.signupForm.get('institute_state').valueChanges.subscribe(
      data => this.setInstitute(data))
      
    this.signupForm.get('aishe_code').valueChanges.subscribe(
        data => this.getInstituteName(data))


  }

  setInstitute(data) {
    this.jarwis.getInstitute(data).subscribe(data => this.setInstitutes(data))
  }

  setInstitutes(data) {
    this.institute = [];
    for (let i = 0; i < data.length; i++) {
      this.institute.push(data[i])
    }
    
  }

  getInstituteName(data) {
    this.jarwis.getInstituteName(data).subscribe(data => this.setInstituteName(data))
  }

  setInstituteName(data) {
    this.signupForm.patchValue({
      institute_name: data['school_name'],
      institute_city: data['district'],
      email: data['email'],
      mobile_number: data['contact_no'],
      institute_address: data['address']
    });

  }



  setStates(data) {
    this.state = [];
    for (let i = 0; i < data.length; i++) {
      this.state.push(data[i])
    }
  }

  handleData2(data) {
    if (data == "Yes") {
      this.uploadDiv = true;
      this.signupForm.get('pid').setValidators(Validators.required);
      this.signupForm.get('pid').updateValueAndValidity();
    } else if (data == "No") {
      this.signupForm.get('pid').clearValidators();
      this.signupForm.get('pid').updateValueAndValidity();
      this.uploadDiv = false;
    }
  }

  // for toastr
  showSuccess(message: string) {
    this.toastr.success(message, 'Success');
  }
  showError(message: string) {
    this.toastr.error(message, 'Error!');
  }
  showWarning(message: string) {
    this.toastr.warning(message, 'Warning');
  }
  showInfo(message: string) {
    this.toastr.info(message, 'Info');
  }

  onSubmit(values) {

    if (this.captchaResponse) {
      if (this.signupForm.valid) {
        if (values.password == values.confirm_password) {
          values['role'] = 5;

          values['city']        = values['institute_city'];
          values['state']       = values['institute_state'];
          values['address']     = values['institute_address'];

          this.jarwis.AtlsignUp(values).subscribe(
            data => this.handleResponse(data)
          );
          
        } else {
          this.toastr.error('Passwords does not match');
        }
      } else {
        this.toastr.error('Kindly fill all the details');
      }
    } else {
      this.toastr.error('Kindly verify Captcha!')
    }
  }

  getAisheDetails(data) {
    this.jarwis.getAisheDetails(data).subscribe(
      data => this.getInstituteDetails(data)
    );
  }

  getInstituteDetails(data) {
    if (data['message'] == "Error") {
      this.toastr.error(data['reason']);
    }
    else {
      this.institute_name = data['aisheDetails']['0']['name'];
      this.institute_address = (data['aisheDetails']['0']['address_line1'] + ' ' + data['aisheDetails']['0']['address_line2']);
      this.institute_city = data['aisheDetails']['0']['city'];
      this.institute_state = data['aisheDetails']['0']['state'];
    }
  }

  handleResponse(data) {
    if (data['message'] == 'Success') {
      this.logout();
      this.toastr.success('You have registered successfully. Please verify your email id before login.')
    } else {
      this.toastr.error(data['reason']);
    }

  }

  handleError(error) {
    this.showError(error.error.errors);
  }

  logout() {
    this.token.remove();
    this.auth.changeStatus(false);
    this.router.navigateByUrl('/login');
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

}
