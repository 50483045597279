<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Step - 1">

    <div class="container">
      <div class="card">
        <div class="card-title"><p>Mandate Form(For institutes/colleges)</p></div>
	  </div>
      <form [formGroup]="mandateForm" (submit)="onSubmit(mandateForm.value)" class="instiBankForm">
       <table class="table table-hover">
         <tr>
           <td>
             Name of Institute
         </td>
         <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name</mat-label>

            <input matInput autocomplete="off" placeholder="Institute Name" formControlName="instname" type="text"   required>
            <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Name is required</mat-error>
        </mat-form-field>
         </td>
            </tr>
            <tr>
              <td>
                Permanent Id Type
              </td>
             <td>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Permanent Id Type</mat-label>

                <mat-select autocomplete="off" placeholder="Id type" formControlName="id_type"  required>
                  <mat-option value="AISHE Code">AISHE Code</mat-option>
                  <mat-option value="AICTE Code">AICTE Code</mat-option>
                  <mat-option value="Other">Other Unique Id</mat-option>
                </mat-select>
                  <mat-error *ngIf="mandateForm.controls.id_type.errors?.required">Gender is required</mat-error>
            </mat-form-field>
             </td>
            </tr>
            <tr>
              <td>
                Permanent Id
              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Permanent Id</mat-label>

                  <input matInput autocomplete="off" placeholder="Permanent Id" formControlName="perma_id" type="text"   required>
                  <mat-error *ngIf="mandateForm.controls.perma_id.errors?.required">Id is required</mat-error>
              </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                Institute Type
              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">

                  <mat-label> Institute Type</mat-label>

                  <mat-select autocomplete="off" placeholder="Institute type" formControlName="inst_type"  required>
                    <mat-option value="Government">Government</mat-option>
                    <mat-option value="Self Finance">Self Finance</mat-option>
                    <mat-option value="Private">Private</mat-option>
                  </mat-select>
                  <!-- <input matInput autocomplete="off" placeholder="Institute type" formControlName="inst_type" type="text"   required> -->
                  <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">Institute type is required</mat-error>
              </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                Head of The Institute
              </td>
			  <td>
              <div class="row">
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>  Head of The Institute</mat-label>
                    <mat-select autocomplete="off" placeholder="Head type" formControlName="head_type"  required>
                      <mat-option value="Director">Director</mat-option>
                      <mat-option value="Registrar">Registrar</mat-option>
                      <mat-option value="Principal">Principal</mat-option>
                      <mat-option value="Vice Chancellor">Vice Chancellor</mat-option>
                    </mat-select>
                      <mat-error *ngIf="mandateForm.controls.head_type.errors?.required">Designation is required</mat-error>
                </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name of Head of The Institute </mat-label>

                    <input matInput autocomplete="off" placeholder="head name" formControlName="head_name" type="text"  required>
                    <mat-error *ngIf="mandateForm.controls.head_name.errors?.required">Id is required</mat-error>
                </mat-form-field>
                </div>
              </div>
              </td>

            </tr>
            <th colspan="2">
                  <p class="tableInstiAddress">Institute Address</p>
              <div class="row">
                  <div class="col-12">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Institute Address</mat-label>

                   <textarea rows="2" maxlength="1000" matInput placeholder="Address" formControlName="inst_address" required></textarea>
                  <mat-error *ngIf="mandateForm.controls.inst_address.errors?.required">Address</mat-error>
              </mat-form-field>
              </div>
          </div>
          <div class="row">
                  <div class="col-4">

                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>City</mat-label>

                      <input matInput autocomplete="off" placeholder="city" formControlName="inst_city" type="text"   required>
                      <mat-error *ngIf="mandateForm.controls.inst_city.errors?.required">City is required</mat-error>
                  </mat-form-field>
                  </div>
                  <div class="col-4">

                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>State</mat-label>

                      <input matInput autocomplete="off" placeholder="state" formControlName="inst_state" type="text"   required>
                      <mat-error *ngIf="mandateForm.controls.inst_state.errors?.required">State is required</mat-error>
                  </mat-form-field>
                  </div>
                        <div class="col-4">

                              <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Pin Code</mat-label>

                    <input matInput autocomplete="off" placeholder="pin code" formControlName="inst_pin" type="text"   required>
                    <mat-error *ngIf="mandateForm.controls.inst_pin.errors?.required">Pin Code is required</mat-error>
                </mat-form-field>
                </div>
              </div>
              </th>
              <tr>
                <td>
                  PAN of the Institute
                </td>
                <td>
                   <mat-form-field appearance="outline" class="w-100">
                    <mat-label>PAN of the Institute</mat-label>

                  <input matInput autocomplete="off" placeholder="pin code" formControlName="inst_pan" type="text"  required>
                  <mat-error *ngIf="mandateForm.controls.inst_pan.errors?.required"> PAN is required</mat-error>
              </mat-form-field>
          </td>
              </tr>
              <tr>
                  <td>
                      PAN Upload
                      <mat-error>PDF,JPG(max 2MB)</mat-error>
                  </td>
                  <td>

                    <div *ngIf="!showCV">
                      <div class="row">
                        <!-- <div class="col-lg-8 col-md-10">
                          <label>Pan Upload:</label>
                          <mat-error>PDF(max 2MB)</mat-error>
                        </div> -->

                        <div class="col-lg-11 col-md-12 choseFileForm">
                          <label class="custom-file-label float-right" for="customFile" *ngIf="!externalCV">Choose file</label>
                          <label class="custom-file-label float-right" for="customFile"
                            *ngIf="externalCV">{{externalCV[0]['name']}}</label>
                          <input #externalCVRef type="file" accept="application/pdf" name="externalCV"
                            (change)="( externalCV = externalCVRef.files )" class="custom-file-input float-right" required>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="showCV">
                      <div class="row">
                        <a href="{{baseImageUrl + pan_report}}" target="_blank">
                          <button type="button" class="btn btn-danger mt-2 mb-2">Download PAN</button>
                          <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showCV = !showCV">Upload
                            PAN</button>
                        </a>
                      </div>
                    </div>
                  </td>
              </tr>
              <tr>
                  <td>
                      Telephone No. of the Head of the Institute
                  </td>
                  <td>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Telephone No. of the Head of the Institute</mat-label>
                          <input matInput autocomplete="off" placeholder="contact" formControlName="head_contact" type="text"  required>
                          <mat-error *ngIf="mandateForm.controls.head_contact.errors?.required"> Contact is required</mat-error>
                      </mat-form-field>
                  </td>
              </tr>
              <tr>
                  <td>
                      E-Mail id of the Head of the Institute
                  </td>
                  <td>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>E-Mail id of the Head of the Institute</mat-label>
                        <input matInput autocomplete="off" placeholder="email" formControlName="head_email" type="text"   required>
                          <mat-error *ngIf="mandateForm.controls.head_email.errors?.required"> Email is required</mat-error>
                      </mat-form-field>
                  </td>
              </tr>
              <tr>
                  <td>
                      Name of Bank where RTGS amount is to be sent
                  </td>
                  <td>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Name of Bank where RTGS amount is to be sent</mat-label>
                        <input matInput autocomplete="off" placeholder="bank name" formControlName="bank_name" type="text"   required>
                          <mat-error *ngIf="mandateForm.controls.bank_name.errors?.required"> Bank Name is required</mat-error>
                      </mat-form-field>
                  </td>
              </tr>
              <tr>
                  <td>
                      Branch Name
                  </td>
                  <td>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Branch Name</mat-label>
                        <input matInput autocomplete="off" placeholder="branch name" formControlName="branch_name" type="text"   required>
                          <mat-error *ngIf="mandateForm.controls.branch_name.errors?.required"> Branch Name is required</mat-error>
                      </mat-form-field>
                  </td>
              </tr>
                <th colspan="2">
                 <p class="tableInstiAddress">Branch Address</p>
                  <div class="row">
                      <div class="col-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Branch Address</mat-label>
                        <textarea rows="2" maxlength="1000" matInput placeholder="Address" formControlName="bank_address" required></textarea>
                      <mat-error *ngIf="mandateForm.controls.bank_address.errors?.required">Address is required</mat-error>
                  </mat-form-field>
                  </div>
              </div>
              <div class="row">
                      <div class="col-4">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>City</mat-label>
                          <input matInput autocomplete="off" placeholder="city" formControlName="bank_city" type="text"   required>
                          <mat-error *ngIf="mandateForm.controls.bank_city.errors?.required">City is required</mat-error>
                      </mat-form-field>
                      </div>
                      <div class="col-4">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>State</mat-label>
                          <input matInput autocomplete="off" placeholder="state" formControlName="bank_state" type="text"   required>
                          <mat-error *ngIf="mandateForm.controls.bank_state.errors?.required">State is required</mat-error>
                      </mat-form-field>
                      </div>
                            <div class="col-4">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Pin Code</mat-label>

                        <input matInput autocomplete="off" placeholder="pin code" formControlName="bank_pin" type="text"   required>
                        <mat-error *ngIf="mandateForm.controls.bank_pin.errors?.required">Pin Code is required</mat-error>
                    </mat-form-field>
                    </div>
                  </div>
                </th>
                <tr>
                  <td>
                      Telephone No. of the Bank
                      </td>
                  <td>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Telephone No. of the Bank</mat-label>

                        <input matInput autocomplete="off" placeholder="contact" formControlName="bank_contact" type="text"   required>
                          <mat-error *ngIf="mandateForm.controls.bank_contact.errors?.required">Bank Contact is required</mat-error>
                      </mat-form-field>
                  </td>
                  </tr>
                  <tr>
                      <td>
                          Name of the A/c holder
                      </td>
                      <td>
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Name of the A/c holder</mat-label>

                            <input matInput autocomplete="off" placeholder="holder name" formControlName="account_name" type="text"   required>
                              <mat-error *ngIf="mandateForm.controls.account_name.errors?.required">Account holder name is required</mat-error>
                          </mat-form-field>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          Account Type
                      </td>
                      <td>
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Account Type</mat-label>

                              <mat-select autocomplete="off" placeholder="Account type" formControlName="account_type"  required>
                                <mat-option value="Savings">Savings</mat-option>
                                <mat-option value="Current">Current</mat-option>

                              </mat-select>
                                <mat-error *ngIf="mandateForm.controls.account_type.errors?.required">Account type is required</mat-error>
                          </mat-form-field>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          Account Number(Full)
                      </td>
                      <td>
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Account Number(Full)</mat-label>

                            <input matInput autocomplete="off" placeholder="Account number" formControlName="account_number" type="text"  required>
                              <mat-error *ngIf="mandateForm.controls.account_number.errors?.required">Account number is required</mat-error>
                          </mat-form-field>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          Bank IFSC Code
                      </td>
                      <td>
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Bank IFSC Code</mat-label>

                            <input matInput autocomplete="off" placeholder="Bank IFSC" formControlName="bank_ifsc" type="text"   required>
                              <mat-error *ngIf="mandateForm.controls.bank_ifsc.errors?.required">IFSC code is required</mat-error>
                          </mat-form-field>
                      </td>
                  </tr>

       </table>

            <div class="col-md-12">
              <button class="btn btn-success" [disabled]='mandateForm.invalid' type="submit">Submit</button>

            </div>

      </form>


    </div>



  </mat-tab>


  <mat-tab label="Step - 2" [disabled]="!step1">

    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <div class="stem2Download">
            <p>Download the submitted information of Bank Mandate form and get the necessary signatures and Upload it.</p>
          </div>
        </div>
        <div class="col-md-12">
          <button class="btn btn-success downStep2But" type="button" (click)="download()">Download</button>
        </div>

      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <div class="stem2Download">
            <p>Download the Sanction Letter.</p>
          </div>
        </div>
         <div class="col-md-12" *ngIf="this.type == 'Menter-Mentee'">
          <button class="btn btn-success downStep2But" type="button" (click)="download1()">Download</button>
        </div> 

      </div> -->
    </div>

  </mat-tab>

  <mat-tab label="Step - 3" [disabled]="!step1">
	<div class="container">
		<div class="row stepDownUploadBox">
		<div class="col-md-12">
        <form [formGroup]="milestonePDFForm" (ngSubmit)="onSubmit2(milestonePDFForm.value)">
            <table class="table1" style="width: 100%;">
                <tr>
                    <th>
                        Upload Signed pdf
                    </th>
                </tr>
                <tr>
            <td>
                <!-- <div *ngIf="!showCV"> -->
                    <div class="row">
                      <div class="col-lg-4 col-md-10">
                        <label>Upload Signed Mandate PDF:</label>
                        <mat-error>PDF(max 2MB)</mat-error>
                      </div>
                      <div class="col-lg-6 col-md-2 float-right" *ngIf="!showCV1">
                        <label class="custom-file-label float-right" for="customFile" *ngIf="!externalCV1">Choose file</label>
                        <label class="custom-file-label float-right" for="customFile"
                          *ngIf="externalCV1">{{externalCV1[0]['name']}}</label>
                        <input #externalCV1Ref type="file" accept="application/pdf" name="externalCV1"
                          (change)="( externalCV1 = externalCV1Ref.files )" class="custom-file-input float-right" required>
                      </div>
                  <!-- </div> -->

                  <!-- <div *ngIf="showCV">-->
                    <div class="col-lg-6 col-md-2 float-right" *ngIf="showCV1">
                      <a href="{{baseImageUrl + signed_doc}}" target="_blank">
                        <button type="button" class="btn btn-danger mt-2 mb-2">View Signed Mandate</button>
                        <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showCV1 = !showCV1">Edit
                            Signed Mandate</button>
                      </a>
                    </div>
                  <!-- </div>  -->
            <div class="col-2">
                  <button *ngIf="show" mat-raised-button color='primary' class="float-right"
                  [disabled]='milestonePDFForm.invalid'>Update</button>
                  <button *ngIf="!show" mat-raised-button color='primary' class="float-right"
                  [disabled]='milestonePDFForm.invalid'>Submit</button>
                </div>
                </div>
              </td>

                </tr>
            </table>
            </form>
		</div>

    </div>
	</div>
  </mat-tab>

</mat-tab-group>













