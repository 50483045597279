 <!--title-->
   <title>

    Singapore India Hackathon 2019 | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>

<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Hackathon</a>
      </li>
      <li class="aht-breadcrumbs__item">
        Singapore India Hackathon 2019
      </li>
    </ul>
  </div>


<section class="paddsection">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="titleheadertop">

                  <h1 class="inner-theme-heading">Singapore India Hackathon</h1>

                </div>

            </div>

        </div>





        <div class="row rowmar">

            <div class="col-md-6">

                <div class="imagesbox si2019BoxImage">

                  <img src="{{imageBasePath}}assets/wp_images/si2019.jpg">

                </div>

            </div>



            <div class="col-md-6">

                <div class="textboxsih">

                    <p><img src="{{imageBasePath}}assets/wp_images/quat1.png"></p>

                    <div class="grediyant">

                  <h4>A joint hackathon between India and Singapore <span><img src="{{imageBasePath}}assets/wp_images/qaut2.png"></span></h4>

              </div>



                </div>

            </div>

        </div>

    </div>





     <div class="container">

        <div class="row">

            <div class="col-md-6">

                 <div class="imagestext">
                    <p><strong>ABOUT</strong></p>
<p>The success of Smart India Hackathon initiative gained a new dimension when Hon&#8217;ble Prime Minister of India, Shri. Narendra Modi announced for the first time ever Singapore &#8211; India Hackathon 2018 (a joint hackathon between India and Singapore).</p>
<p>On 12 &#8211; 14 November 2018, MHRD Innovation Cell (MIC) along with All India Council for Technical Education (AICTE) and Nanyang Technological University, Singapore (NTU Singapore) jointly organised first Singapore &#8211; India Hackathon, a fast-paced 36-hour competition to develop creative and innovative solutions focused on the theme &#8220;Smart Campus&#8221;. The hackathon had 20 teams of three university students each from India and Singapore. Teams with most innovative solutions presented with $10,000, while the second and third prize-winners won $6,000 and $4,000 each.</p>
<p>The Hon&#8217;ble Prime Minister of India, Shri Narendra Modi Ji, has agreed** to be present at IIT Madras on 30th September 2019 for the Prize Distribution ceremony of Singapore-India Hackathon 2019. **Formal confirmation is awaited!</p>
                 




                   <div class="pracewiner">


                   </div>



                 <div class="pracepack">

                  <!--  <ul>

                        <li class="bor1">

                            <h5>Complex:</h5>

                          <h2><i class="fa fa-rupee"></i></h2>  

                        </li>



                        <li class="bor1">

                             <h5>Simple:</h5>

                          <h2> <i class="fa fa-rupee"></i></h2>  

                        </li>





                        <li class="bore1">

                             <h5>Complicated:</h5>

                          <h2><i class="fa fa-rupee"></i></h2>  

                        </li>

                    </ul> -->

                   </div>

                 </div>

            </div>



            <div class="col-md-6">

                <div class="video">

                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/l0xs-L-mOpY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                </div>



                <div class="boxtext4">

                    <h2>ANNOUNCMENTS</h2>

                  <div class="boxp">

                    <p><a href="http://qa.mic.gov.in/si2019/si2019shortlistedteams.html">Shortlisted Teams for Singapore India Hackathon 2019</a></p>

                </div>

                </div>

            </div>

        </div>

     </div>

</section>



<section class="sectionbutton">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="textbotton">

                    <h4>For More Details <a href="assets/si2019/index.html" target="_blank">Vist the website</a></h4>

                </div>

            </div>

        </div>

    </div>

</section>


<!-- Footer -->
<app-inner-footer></app-inner-footer>