import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../app/app.component';
@Component({
  selector: 'app-national-technology-week',
  templateUrl: './national-technology-week.component.html',
  styleUrls: ['./national-technology-week.component.css']
})
export class NationalTechnologyWeekComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
