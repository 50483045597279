<!--title-->
   <title>

    Message from CIO | MHRDD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>


<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Innovation Council</a>
      </li>
      <li class="aht-breadcrumbs__item">
        IIC Webinar
      </li>
    </ul>
  </div>
	
<section class="paddsection">
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="titleheadertop">
				<h1 class="inner-theme-heading">MHRD's Innovation Cell - Online Session</h1>
            </div>
			<div class="clearfix"></div>
        </div>
    </div>
</div>
</section>

<section id="content-wrapper-1">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
									<div class="col-md-4 ">
						<a href="online_session516e.html?id=MjA=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb20.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Interaction with Student Innovators and Entrepreneurs Emerged from Smart India Hackathon (SIH)</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-10</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-22</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_sessiondf7b.html?id=MTc=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb17.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Frugal Innovations and Social Entrepreneurship </p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-09</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-21</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_session1ace.html?id=MTk=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb19.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Use of Market Data and Application of Marketing Research Tools and Methodology - Increasing Chances of Survivality of Innovation and Venture - Advance Level</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-08</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-20</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_sessionadc4.html?id=MTY=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb16.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Idea, Entrepreneurship Opportunities, Business Model and Business Plan</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-07</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-19</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_session6a43.html?id=MTE=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb11.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Innovation Risk Diagnostic — Product Innovation Rubric(PIR)</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-05</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-15</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_sessiona2e4.html?id=MTM=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb13.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Understanding Role and Application of Marketing Research at Idea to Start up Stage - Foundation Level</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-04</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-14</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_session2eb3.html?id=MTQ=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb14.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Innovating Self- Screen and Identify right opportunities</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-03</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-13</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_sessionc264.html?id=MTg=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb18.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">IPR and Technlogy Transfer</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Legal and Ethical Steps - Productive Entrepreneurship and Startup</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-02</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-12</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_session1af0.html?id=MTU=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb15.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Understanding Angel and Venture Capital Funding - What is there for Early Stage innovator & Entrepreneurs</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-01</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-08</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 03:30 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 03:30 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_sessionca56.html?id=MTI=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb12.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">IPR and Technlogy Transfer</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Intellectual Property (IP) Management at Early Stage of Innovation and Start-ups</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-30</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-07</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_session934f.html?id=MTA=">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb10.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Design thinking and Innovation</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">How to Ideintify Right Problem and Solution using the Double Diamond Approach in Design</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-29</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-06</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_sessionf36f.html?id=NA==">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb4.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Entrepreneurship,  Business Idea and Business Model Canavas</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-28</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-05</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_sessiondb68.html?id=Nw==">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb7.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Hangout with Successful Startup Founder</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-27</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-04</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_session05e1.html?id=OQ==">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb9.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">I&E Ecosystem</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Role of Network Enablers in driving I&E in HEIs - A Case of TiE, India</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-24</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-01</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_session2903.html?id=Ng==">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb6.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Entrepreneurship Development</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Hangout with Emerging Innovator & Entreprenuers Supported through MIC & AICTE</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-23</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-30</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_sessiond64b.html?id=NQ==">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb5.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Incubation and Pre Incubation</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">Role and Importance of Pre-Incubators, Incubators and Accelerators in HEIs - Harnesing Innovation and Entrepreneurial Potential of Students and Faculties at Early Stage </p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-22</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-29</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
									<div class="col-md-4 ">
						<a href="online_session1ff6.html?id=OA==">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{imageBasePath}}assets/wp_images/thumbnail/thumb8.jpg" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">PAST</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">Innovation, Startup and IPR</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">National Innovation and Startup Policy for Students and Faculty 2019 - A Guiding Framework for HEIs for Implementation</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-21</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> 2020-04-28</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 05:30 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 05:30 pm</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
							</div>
		</div>
	</div>
</section>


<!-- Footer -->
<app-inner-footer></app-inner-footer>