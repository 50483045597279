import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-incubation-incharge-dialog',
  templateUrl: './incubation-incharge-dialog.component.html',
  styleUrls: ['./incubation-incharge-dialog.component.css']
})
export class IncubationInchargeDialogComponent implements OnInit {


  public inchargeForm: FormGroup;

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<IncubationInchargeDialogComponent>) { }

  ngOnInit(): void {
    this.inchargeForm = this.fb.group({
      incharge_name: [null, Validators.required],
      incharge_email: [
        null,
        Validators.compose([Validators.required, Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
        ),

      ],
      incharge_designation: [null, Validators.required],
      incharge_phone: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
    });
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  onSubmit(values) {
    if (this.inchargeForm.valid) {
      this.dialogRef.close(values);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
