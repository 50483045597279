import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';
import { emailValidator } from 'src/app/app.component';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { InstituteActivityService } from '../../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';
import { Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-coordinator-dialog',
  templateUrl: './coordinator-dialog.component.html',
  styleUrls: ['./coordinator-dialog.component.css']
})
export class CoordinatorDialogComponent implements OnInit {
  public coordinatorForm: FormGroup;
  
  show: boolean;
  show1: boolean;
  id: any;
  coordinator_type:any;
  name: any;
  email: any;
  contact: any;
  gender: any;
  city: any;
  mstatusform: any;
  iicCoordinatorCity:any;
  type_id:any;
  edit_id:any;

  constructor(private fb: FormBuilder,
    private jarwis: JarwisService,
    private myCouncilService: InstituteMyCouncilService,
    private instituteActivityService :InstituteActivityService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<CoordinatorDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
    }
  }

  ngOnInit(): void {
    this.coordinatorForm = this.fb.group({
      'coordinator_type': [null, Validators.required],
      'name': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, emailValidator,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
      ],
      'id': [null],
      'contact': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      'gender': [null, Validators.required],
      'city': [null, Validators.required],
    });

    this.instituteActivityService.getIicCoordinatorCityDetails().subscribe(
      (data) => this.handleResponse(data)
    );

      if (this.data) {
      //this.fbl = this.data;
      this.id=this.data['id'];
      this.coordinator_type=this.data['nomination_type'];
      this.name = this.data['name'];
      this.email = this.data['email'];
      this.contact = this.data['contact'];
      this.gender = this.data['gender'];
      this.city = this.data['city'];
      this.coordinatorForm.controls['coordinator_type'].setValue(this.coordinator_type);
      this.coordinatorForm.controls['id'].setValue(this.id);
      this.coordinatorForm.controls['name'].setValue(this.name);
      this.coordinatorForm.controls['email'].setValue(this.email);
      this.coordinatorForm.controls['contact'].setValue(this.contact);
      this.coordinatorForm.controls['gender'].setValue(this.gender);
      this.coordinatorForm.controls['city'].setValue(this.city);
    }
  }

  onSubmit(values) {
    this.instituteActivityService.editCoordinator(values).subscribe(
      data => this.handleResponse1(data)
    );
  }

  handleResponse(data) {
    this.iicCoordinatorCity = data['data'];
  }


  handleResponse1(data) {
    if (data['message'] == "Success") {
      this.toastr.success("Nominated Member Updated Successfully !");
      this.dialogRef.close();
      window.location.href='/institute/announcement/nomination';
    } else {
      this.toastr.error(data['msg']);
    }
    this.myCouncilService.dataUpdated.next(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

}
