<section class="instIncubCenter">
  <div class="container">
    <form [formGroup]="iprTtoFacilitiesForm" (ngSubmit)="onSubmit(iprTtoFacilitiesForm.value)">
      <mat-card class="mat-elevation-z6 mt-4 mb-2">
        <marquee style="color: red;">Press Ctrl+Shift+R after every successful submission notification of Incubation Center form </marquee>
        <p style="color: red;">All Fields are Mandatory. </p>

  <div class="row">
    <div class="col-5">
      <mat-form-field class="example-full-width col-12" appearance="outline">
        <mat-label>Unit Name</mat-label>
        <input matInput formControlName="center_name" placeholder="Facility Name"
          (keypress)=isCharKey($event)>
        <mat-error *ngIf="iprTtoFacilitiesForm.controls.center_name.errors?.required">Facility Name
          is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field class="col-12 example-full-width" appearance="outline" style="
      width: 524px;">
        <mat-label> Facility Type</mat-label>
        <mat-select formControlName="ipr_tto_center" >
          <mat-option *ngFor="let role of ipr_tto_center" [value]="role" >
            {{role}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="iprTtoFacilitiesForm.controls.ipr_tto_center.errors?.required">
          Facility Type is required </mat-error>
      </mat-form-field>
    </div>

    <!-- !  -->
    <div class="col-12">
      <h1 class="incubIncHeading incubheadFirst">In-Charge Details</h1>
      <div>
        <mat-form-field class="col-3" appearance="outline">
          <mat-label>Name:</mat-label>
          <input matInput formControlName="incharge_name" placeholder="Enter Pre-Incubation Incharge Name">
          <mat-error *ngIf="iprTtoFacilitiesForm.controls.incharge_name.errors?.required">Name is
            required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="outline">
          <mat-label>Email:</mat-label>
          <input matInput formControlName="incharge_email" placeholder="Enter Pre-Incubation Incharge Email">
          <mat-error *ngIf="iprTtoFacilitiesForm.controls.incharge_email.errors?.required">Email is
            required
          </mat-error>
          <mat-error *ngIf="iprTtoFacilitiesForm.controls.incharge_email.errors?.pattern">Invalid Email
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="outline">
          <mat-label>Phone:</mat-label>
          <input matInput formControlName="incharge_phone" minlength="10" maxlength="10"
            (keypress)=isNumberKey($event)>
          <mat-error *ngIf="iprTtoFacilitiesForm.controls.incharge_phone.errors?.required">Phone number
            is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="outline">
          <mat-label>Website Url:</mat-label>
          <input matInput formControlName="incharge_url"
            placeholder="Enter Pre-Incubation Incharge Website Url">
          <mat-error *ngIf="iprTtoFacilitiesForm.controls.incharge_url.errors?.required">
            Website Url is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
          <!-- !  -->

          <div class="col-12">
            <mat-form-field class="col-12 example-full-width" appearance="outline" style="width: 1032px;">
              <mat-label>Type of Supports Available</mat-label>
              <mat-select formControlName="type_services_offered" multiple [(ngModel)]="serviceModel">
                <app-select-check-all [model]="iprTtoFacilitiesForm.get('type_services_offered')"
                  [values]="type_services_offered">
                </app-select-check-all>
                <mat-option *ngFor="let role of type_services_offered" [value]="role">
                  {{role}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="iprTtoFacilitiesForm.controls.type_services_offered.errors?.required">
                Type of Supports Available is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="col-12 example-full-width" appearance="outline" style="width: 1032px;">
              <mat-label>Range of Services Offered</mat-label>
              <mat-select formControlName="facilities_services" multiple [(ngModel)]="facilitiesModel">
                <app-select-check-all [model]="iprTtoFacilitiesForm.get('facilities_services')"
                  [values]="facilities_services_multi">
                </app-select-check-all>
                <mat-option *ngFor="let role of facilities_services" [value]="role?.id">
                  {{role?.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="iprTtoFacilitiesForm.controls.facilities_services.errors?.required">
                Range of Services Offered is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12">
            <!-- <p>Press enter or comma after each entry.</p> -->
            <mat-form-field class="example-chip-list example-full-width col-12" appearance="outline">
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let thrust of thrustAreas" [selectable]="true" [removable]="true"
                  (removed)="remove(thrust)">
                  {{thrust}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input #thrustAreaInput formControlName="thrust_areas" placeholder="Thrust Areas (Optional) (Press enter a comma after each entry)"
                  [matAutocomplete]="auto" [matChipInputFor]="chipList" required
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
                  [(ngModel)]="filterThrustAreaData" (keyup)="getThrustAreasList()">
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let filteredAreas of filteredThrustAreas | async" [value]="filteredAreas">
                  {{filteredAreas}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-12" style="text-align: center !important">
            <button type="button" class="btn btn-primary incubSubmit" type="submit">Save & Submit</button>
              <!-- [disabled]='iprTtoFacilitiesForm.invalid'>Save & Submit</button> -->
          </div>
        </div>
      </mat-card>
    </form>
  </div>
</section>
