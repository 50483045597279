<div class="instDownloadTable">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-hover" style="margin-bottom: 20px !important;">

          <thead>
            <tr>
              <td colspan="6" allign="left" class="danger"
                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                <h1 class="announcementHeadingMain">Notifications List</h1>
              </td>
            </tr>

            <tr>
              <th scope="col"> Sr. No.</th>
              <th scope="col">Subject</th>
              <th scope="col">Attachment</th>
              <th scope="col">Send Date</th>
              <th scope="col">Email Address</th>
              <th scope="col">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let notification of notifications | paginate: { itemsPerPage: 5, currentPage: page }; let i = index">
              <td>{{i + 1}}</td>
              <td>{{notification.subject}}</td>
              <td *ngIf="notification.emailAttachment">{{notification.emailAttachment}}</td>
              <td *ngIf="!notification.emailAttachment">N/A</td>
              <td>{{notification.timestamp | date: 'yyyy-MM-dd'}}</td>
              <td>{{email}}</td>
              <td><a (click)="openDialog(notification)">
                  <mat-icon style="cursor: pointer;">remove_red_eye</mat-icon>
                </a></td>
            </tr>
          </tbody>
        </table>
        <pagination-controls class="annular-pagination float-right" maxSize="2" (pageChange)="onPageChanged($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>
