<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Institute Referral Program</h2>
    </div>

	<div class="card">
        <div class="card-title"><p>Edit Institute Details ss</p></div>
	</div>

  <form class="form-horizontal" [formGroup]="signupForm" (submit)="onSubmit(signupForm.value)"
  style="color: black !important;">
  <div [hidden]="!error">
    {{error}}
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <mat-label>Name of the Head of Institute</mat-label>
      <input matInput placeholder="Name of the Head of Institute" formControlName="name" required>
      <mat-error *ngIf="signupForm.controls.name.errors?.required">Name of the Head of Institute is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">mail</mat-icon>
      <mat-label>Email Id of the Head of Institute</mat-label>
      <input matInput placeholder="Email Id of the Head of Institute" formControlName="email" required
        (keypress)="isSpace($event)">
      <mat-error *ngIf="signupForm.controls.email.errors?.required">Email Id of the Head of Institute is required
      </mat-error>
      <mat-error *ngIf="signupForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stay_current_portrait</mat-icon>
      <mat-label>Mobile Number of the Head of Institute</mat-label>
      <input matInput placeholder="Mobile Number of the Head of Institute" formControlName="mobile_number"
        required minlength="10" maxlength="10" (keypress)="isNumKey($event)">
      <mat-error *ngIf="signupForm.controls.mobile_number.errors?.required">Mobile Number of the Head of Institute
        is
        required
      </mat-error>
      <mat-error *ngIf="signupForm.controls.mobile_number.errors?.minlength">Mobile Number should be of 10 digits
      </mat-error>
    </mat-form-field>
  </div>



  <div class="col-md-6">
    <button class="btn btn-primary" mat-raised-button type="submit" [disabled]="signupForm.invalid">Update</button>
  </div>

  <br/><br/>

</form>

</div>
