<div class="container">
  <div class="row" color="primary">
      <h2 class="ss"
          style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">Manthan</h2>
  </div>
  <div class="row">
  	<div class="col-md-12">
  		<div class="d-flex w-100 justify-content-between mb-4">
  			<div class="logo-first">
  				<img class="img-fluid" src="assets/manthan/manthan1.png" alt="logo">
  			</div>
  			<!-- <div class="logo-second">
  				<img class="img-fluid" src="assets/manthan/manthan2.png" alt="logo">
  			</div> -->
  		</div>
  		<p>
  			Manthan is an initiative led by the Office of Principal Scientific Adviser, Government of India scales Science & Technology-based social impact & Industry research/innovations in India.
  		</p>
  		<p>
  			Manthan aims to empower various stakeholders to scale up their interactions with researchers and innovators, facilitate R&D/innovation share challenges focused on emerging technologies and other scientific interventions, as well as those with a social impact. It also provides focus on thematic areas, i.e. Industry Research and development, Women In STEM, Science Communication, Government Challenges, International partnerships, RUTAG, Clusters, etc.
  		</p>
  	</div>
  </div>
	<div class="row mt-3">
		<div class="col-md-12 text-center">
			<div class="card mb-3">
		  		<div class="card-body">
			    <button type="button" class="btn btn-success" (click)="onSubmit()" *ngIf="isRegister == 0">Confirm Registration On Manthan</button>
			    <button type="button" class="btn btn-success" *ngIf="isRegister == 1" style="background-color: green !important; color: white !important;">Registered Successfully</button>
			  </div>
			</div>
			<p *ngIf="isRegister == 1">
				<strong>Note:- <i>A email regarding Manthan login credentails has been sent to the registered email of the IIC president of your institute for information and necessary action.</i></strong>
      </p>
		</div>
	</div>  
</div>
