import { Component, OnInit, Input } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { InstituteSubscriptionComponent } from './institute-subscription/institute-subscription.component'
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-institute-incubation',
  templateUrl: './institute-incubation.component.html',
  styleUrls: ['./institute-incubation.component.css']
})
export class InstituteIncubationComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @Input() editInitiated = false;
  messages: any[] = [];
  subscription: Subscription;
  selectedIndex = 0;
  ForWhom :any;

  constructor(private messageService: InstituteSubscriptionComponent) {
    this.subscription = this.messageService.onMessage().subscribe(message => {
      if (message) {
        this.messages.push(message);
        // this.ngOnInit();
        if (message.text.id) {
          this.editInitiated = true;
          this.ngOnInit();

        }
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }

  ngOnInit(): void {
    this.ForWhom=localStorage.getItem('ForWhom');
    if (this.editInitiated === true) {
      this.tabGroup.selectedIndex = 0;
    }
  }
  clearMessages(): void {
    // clear messages
    this.messageService.clearMessages();
  }
  // ss(){
  //   window.
  // }

}
