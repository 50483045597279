<div class="container">
  <div class="row" color="primary">
      <h2 class="ss"
          style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;width:100%">
          Edit Evaluator</h2>
  </div>
  <form class="instiBankForm" [formGroup]="iicEvaluatorAddition" (ngSubmit)="onSubmit(iicEvaluatorAddition.value)">
  <table class="table table-hover">
    <tr>
      <td>
        Name
      </td>
      <td>
        <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name</mat-label>

        <input matInput autocomplete="off" placeholder="Name" formControlName="name" type="text" [value]="name"  required>
        <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
        </mat-form-field>
      </td>
    </tr>
        <tr>
      <td>
        Email
      </td>
      <td>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Email</mat-label>

          <input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email" type="text" [value]="email" readonly="true" required>
          <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
      </td>
    </tr>
        <tr>
      <td>
        Contact
      </td>
      <td>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Contact</mat-label>

          <input matInput autocomplete="off" placeholder="Contact" formControlName="contact" type="text" [value]="contact"  required>
          <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        &nbsp;
      </td>
      <td>
        <button mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='iicEvaluatorAddition.invalid'>Update</button>
      </td>
    </tr>
   </table>
</form>
</div>
