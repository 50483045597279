<div class="container">
  <div class="row" color="primary">
    <h2 class="ss" style="text-align: center;background-color: #f08b04;padding: 10px 0;color:#fff;border-radius: 4px; width: 100%; margin: 5px;">Institute Nominate Challenge</h2>
  </div>
  <div class="refrredBox">
    <div class="row rws-listpage">
	    <div class="col-md-12">
        <div class="tableBody">
				  <div class="table-responsive">
            <table class="table table-hover">
              <thead class="facultyTable">
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">User Id</th>
                  <th scope="col">Institute Name</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let institute of allInstitutes| paginate: { itemsPerPage: 10, currentPage: page }; let i = index;" >
                  <td>{{i+1}}</td>
                  <td>{{institute?.userId}}</td>
                  <td>{{institute?.instituteName}}</td>
                  <td>{{institute?.name}}</td>
                  <td>{{institute?.email}}</td>

                  <td>
                    <form [formGroup]="instituteNomantion" (ngSubmit)="onSubmit(instituteNomantion.value)">
                      <input [hidden]="true" [ngModel]="institute?.id" formControlName="innovation_id">
                    <mat-form-field class="instiIdeaSelectBox">
                      <mat-label>Nominate</mat-label>
                      <mat-select formControlName="challenge_id">
                        <mat-option *ngFor="let value of allChallenges" [value]="value.id">{{ value.title }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button type="submit" class="btn btn-primary">Nominate</button>
                    </form>
                  </td>
                </tr>
                <tr *ngIf="allInstitutes?.length== 0" col="7">
                    <h3 style="text-align: center">No Data Found</h3>
                </tr>
              </tbody>
            </table>
            <pagination-controls class="annular-pagination float-right" maxSize="8"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
