import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-institute-innovation-team-details',
  templateUrl: './institute-innovation-team-details.component.html',
  styleUrls: ['./institute-innovation-team-details.component.css']
})

export class InstituteInnovationTeamDetailsComponent implements OnInit {
  public iicImpactQuestions: FormGroup;
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  show_field_1:any;
  show_field_2:any;
  show_field_3:any;
  show_field_4:any;
  show_field_5:any;

  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute,
    private instituteMaster: InstituteInnovationContestService,
    private instituteActivityService :InstituteActivityService,
    private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.teamid;
    });
  }

  ngOnInit(): void {

    this.show_field_1=0;
    this.show_field_2=0;
    this.show_field_3=0;
    this.show_field_4=0;
    this.show_field_5=0;

    this.iicImpactQuestions = this.fb.group({
      'member_name_1': [null, Validators.required],
      'member_email_1': [null, Validators.required],
      'member_mobile_1': [null, Validators.required],
      'member_engagement_1': [null, Validators.required],
      'member_gender_1': [null, Validators.required],
      'member_category_1': [null, Validators.required],
      'member_name_2': [],
      'member_email_2': [],
      'member_mobile_2': [],
      'member_engagement_2': [],
      'member_gender_2': [],
      'member_category_2': [],
      'member_name_3': [],
      'member_email_3': [],
      'member_mobile_3': [],
      'member_engagement_3': [],
      'member_gender_3': [],
      'member_category_3': [],
      'member_name_4': [],
      'member_email_4': [],
      'member_mobile_4': [],
      'member_engagement_4': [],
      'member_gender_4': [],
      'member_category_4': [],
      'member_name_5': [],
      'member_email_5': [],
      'member_mobile_5': [],
      'member_engagement_5': [],
      'member_gender_5': [],
      'member_category_5': [],
      'mentor_name_1': [null, Validators.required],
      'mentor_email_1': [null, Validators.required],
      'mentor_mobile_1': [null, Validators.required],
      'mentor_pos_1': [null, Validators.required],
      'mentor_org_1': [null, Validators.required],
      'mentor_type_1': [null, Validators.required],
      'mentor_name_2': [],
      'mentor_email_2': [],
      'mentor_mobile_2': [],
      'mentor_pos_2': [],
      'mentor_org_2': [],
      'mentor_type_2': []
    });

    this.instituteActivityService.getIicInnovTeamDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

  }

  onStatusChange1(ob) {
    let StatusVal = ob.value;
    if(StatusVal == 2) {
      this.show_field_1=1;
      this.show_field_2=1;
    }
  }

  handleResponseForAutoPopulate(data) {

    // this.programTheme = data['data'];
    // if (data['data'][0]) {
    //   data['data'][0].forEach((element) => {
    //     this.programTheme.push(element.data.data.name);
    //   });
    // }

    this.programTheme = "";

  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }


  handleResponse(data) {

    if(data['totalcount'] > 0){
      //  alert(data['activity_id']);
    this.iicImpactQuestions.patchValue({
      member_name_1: data['member_name_1'],
      member_email_1: data['member_email_1'],
      member_mobile_1: data['member_mobile_1'],
      member_engagement_1: data['member_engagement_1'],
      member_gender_1: data['member_gender_1'],
      member_category_1: data['member_category_1'],
      member_name_2: data['member_name_2'],
      member_email_2: data['member_email_2'],
      member_mobile_2: data['member_mobile_2'],
      member_engagement_2: data['member_engagement_2'],
      member_gender_2: data['member_gender_2'],
      member_category_2: data['member_category_2'],
      member_name_3: data['member_name_3'],
      member_email_3: data['member_email_3'],
      member_mobile_3: data['member_mobile_3'],
      member_engagement_3: data['member_engagement_3'],
      member_gender_3: data['member_gender_3'],
      member_category_3: data['member_category_3'],
      member_name_4: data['member_name_4'],
      member_email_4: data['member_email_4'],
      member_mobile_4: data['member_mobile_4'],
      member_engagement_4: data['member_engagement_4'],
      member_gender_4: data['member_gender_4'],
      member_category_4: data['member_category_4'],
      member_name_5: data['member_name_5'],
      member_email_5: data['member_email_5'],
      member_mobile_5: data['member_mobile_5'],
      member_engagement_5: data['member_engagement_5'],
      member_gender_5: data['member_gender_5'],
      member_category_5: data['member_category_5'],
      mentor_name_1: data['mentor_name_1'],
      mentor_email_1: data['mentor_email_1'],
      mentor_mobile_1: data['mentor_mobile_1'],
      mentor_pos_1: data['mentor_pos_1'],
      mentor_org_1: data['mentor_org_1'],
      mentor_type_1: data['mentor_type_1'],
      mentor_name_2: data['mentor_name_2'],
      mentor_email_2: data['mentor_email_2'],
      mentor_mobile_2: data['mentor_mobile_2'],
      mentor_pos_2: data['mentor_pos_2'],
      mentor_org_2: data['mentor_org_2'],
      mentor_type_2: data['mentor_type_2']
    });

    //alert(this.iicImpactQuestions.get('activity_id').value);

    this.document = data['thumbnail'];

  }
    this.totalcount = data['totalcount'];

  }

  handleResponseList(data) {
    // this.iicActivity = data['data'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('rid', this.rid);
    formData.append('title', this.iicImpactQuestions.get('title').value);
    formData.append('description', this.iicImpactQuestions.get('description').value);
    formData.append('theme', this.iicImpactQuestions.get('theme').value);

    formData.append('team_lead_name', this.iicImpactQuestions.get('team_lead_name').value);
    formData.append('team_lead_email', this.iicImpactQuestions.get('team_lead_email').value);
    formData.append('team_lead_mobile', this.iicImpactQuestions.get('team_lead_mobile').value);
    formData.append('team_lead_gender', this.iicImpactQuestions.get('team_lead_gender').value);
    formData.append('innovation_type', this.iicImpactQuestions.get('innovation_type').value);
    formData.append('type', this.iicImpactQuestions.get('type').value);
    formData.append('category', this.iicImpactQuestions.get('category').value);
    formData.append('trl_level', this.iicImpactQuestions.get('trl_level').value);

    var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    if(this.totalcount>0){

      formData.append('document', cvFile);
      formData.append('document_old', this.document);

    }
    else
    {
      if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
      formData.append('document', cvFile);
    }

    this.instituteActivityService.submitIicNicRepository(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/innovation-repository';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

  onIICStatusChange(ob) {
    let iicStatus = ob.value;
    if(iicStatus == 'SELF') {
      this.requireField = 0;
    } else {
      this.requireField = 1;


      this.instituteActivityService.getIicActivityList(ob.value).subscribe(
        (data) => this.handleResponseList(data)
      );

    }

  }

}
