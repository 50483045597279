import { Component, OnInit } from '@angular/core';
import { InstituteDashboard1819Service } from 'src/app/Services/institute-dashboard1819.service';

@Component({
  selector: 'app-dashboard1819-cards',
  templateUrl: './dashboard1819-cards.component.html',
  styleUrls: ['./dashboard1819-cards.component.css']
})
export class Dashboard1819CardsComponent implements OnInit {

  cards: any;

  constructor(private dashboardService: InstituteDashboard1819Service) { }

  ngOnInit(): void {
    this.dashboardService.getCardsData().subscribe(
      data => this.cards = data['data']      
    )
  }

}
