import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-startup-policy-committee',
  templateUrl: './startup-policy-committee.component.html',
  styleUrls: ['./startup-policy-committee.component.css']
})
export class StartupPolicyCommitteeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
