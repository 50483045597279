<div class="container">
  <mat-dialog-content class="mat-typography instiFactDialoge">
    <form [formGroup]="nominationsForm" (ngSubmit)="onSubmit(nominationsForm.value)">
      <div class="row">

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" placeholder="Name" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="nominationsForm.controls['name'].hasError('required')">Faculty Name is
              required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">mail</mat-icon>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" placeholder="Email" required (keypress)=isSpace($event)>
            <mat-error *ngIf="nominationsForm.controls['email'].hasError('required')">Faculty Email is
              required</mat-error>
            <mat-error *ngIf="nominationsForm.controls['email'].hasError('invalidEmail')">Invlaid Email
              Address</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stay_current_portrait
            </mat-icon>
            <mat-label>Contact</mat-label>
            <input matInput formControlName="contact" placeholder="Contact" minlength="10" maxlength="10" required
              (keypress)=isNumberKey($event)>
            <mat-error *ngIf="nominationsForm.controls['contact'].hasError('required')">Contact Number
              is required</mat-error>
            <mat-error *ngIf="nominationsForm.controls['contact'].hasError('minlength')">Contact Number
              should be of 10 numbers </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div mat-dialog-actions>

        <button type="button" mat-raised-button color='warn' (click)='onNoClick()' style="margin-left: 280px;
            " class="btn btn-danger instituteBtnClose">Close</button>

        <button *ngIf="show" mat-raised-button color='primary' class="btn btn-success float-right"
          >Update</button>


      </div>
    </form>
  </mat-dialog-content>
</div>
