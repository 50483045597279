import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { AddDialogImpactComponent } from './add-dialog-impact/add-dialog-impact.component';
import { NominateForm2DialogComponent } from './nominate-form2/nominate-form2-dialog/nominate-form2-dialog.component';
// import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-impact-lectures',
  templateUrl: './impact-lectures.component.html',
  styleUrls: ['./impact-lectures.component.css']
})
export class ImpactLecturesComponent implements OnInit {
  nominationArray=[];
  sessionList: any[];
  delete_id: any;
  convenor_name: any;
  convenor_email: any;
  convenor_contact: any;
  convenor_designation: any;
  success: any;myCouncil: any;
  eligible_states: any;
  granted: any;
  rating: any;
  reports: any;
  type: any;
  showStatus: any;
  ReportStatus: any;
  MandateStatus: any;
  pageType:any;
  iic40:any;
  checkNomination:any;
  isEnable=0;
  sessionShow:any;

  constructor(private fb: FormBuilder,  private activityService: InstituteActivityService, public dialog: MatDialog,private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService) {
    this.getTable();
    this.getcheckSessionDetails();
    // this.checkImpactEligibility();
    this.checkNew();
    this.eligibility();
    this.CheckMandateStatus();
    // let latest = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

  }


  eligibility() {
    this.activityService.getHOI().subscribe(
      data => this.instituteData(data)
    );
  }

  instituteData(data) {
    if (data) {
      var institute_id = data['hoiDetails']['institute_id'];
      // if(institute_id == 'IC201810001'){
      //   this.isEnable=1;
      // }
      this.activityService.checkActivityButton(institute_id).subscribe(
        data => this.showActivityButton(data)
      );
    }
  }

  CheckMandateStatus() {
    this.activityService.CheckMandateStatus().subscribe(
      data => this.SetMandateStatus(data)
    );
  }

  SetMandateStatus(data) {
    if (data) {
      this.MandateStatus = data['data']['MandateStatus'];
      this.checkNomination = data['data']['checkNomination'];
      // alert(this.MandateStatus);
    }
  }


  showActivityButton(data){
    this.showStatus=data['status'];
    this.myCouncil=data['status'];
    // this.showStatus=0;
  }

  checkNew(){
    this.innovationService.checkNew().subscribe(
      data => this.setcheckNew(data)
    );
    }
    setcheckNew(data){
      // this.myCouncil=data['data']['0']['my_council'];
      this.eligible_states=data['data']['0']['eligible_states'];
      this.granted=data['data']['0']['granted'];
      this.rating=data['data']['0']['rating'];
      this.reports=data['data']['0']['reports'];
      this.type=data['data']['0']['type'];
      this.sessionShow=data['data']['0']['sessionShow'];

      this.iic40=data['data']['0']['iic40'];

      if( this.rating==1 && this.reports==1){
        this.success=1;
      }else{
        this.success=0;
      }

    }
  //    checkImpactEligibility(){
  //     this.innovationService.checkImpactEligibility().subscribe(
  //       data => this.setcheckImpactEligibility(data)
  //     );
  //   }
  //   setcheckImpactEligibility(data){

  // if(data['message']=="Success"){
  //   this.success=1;
  // }else{
  //   this.success=0;
  // }
  //   }

  ngOnInit(): void {
    this.pageType="Impact";
  }
  getcheckSessionDetails(){
    this.innovationService.getcheckSessionDetails().subscribe(
      data => this.setcheckSessionDetails(data)
    );
  }

  setcheckSessionDetails(data){
    // this.convenor_name=data['data']['0']['facultyName']
    // this.convenor_email=data['data']['0']['faculty_email']
    // this.convenor_contact=data['data']['0']['faculty_contact']
    // this.convenor_designation=data['data']['0']['faculty_designation']
  }

  getTable(){
    // this.innovationService.getImpactTable().subscribe(
    //   data => this.setImpactTableData(data)
    // );

  }
  setImpactTableData(data){
    this.sessionList=[];
    for(let i=0;i<data['sessions'].length;i++){
      this.sessionList.push(data['sessions'][i])
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(NominateForm2DialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
this.getTable();
this.getcheckSessionDetails();

    });
  }
  onDeleteClick(data){
    this.delete_id = data['id'];
    if (confirm("Are you sure you want to delete?")) {
      this.innovationService.DeleteSession(this.delete_id).subscribe(
        data => this.handleResponse1(data)
      );
    }
  }

  handleResponse1(data){
    if(data['status']=='1'){
      this.toastr.success(data['msg']);
      this.getTable();
      this.getcheckSessionDetails();

    }
    else{
      this.toastr.error(data['msg'])
    }
  }

  onEditClick(data){
    const dialogRef = this.dialog.open(NominateForm2DialogComponent, {
      data:data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getTable();
      this.getcheckSessionDetails();
    });
  }
  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }
}
