<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">IIC 2018-19</h1> -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC 2018-19</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>At a Glance</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Dashboard 2018-19</li>
  </ul>
</nav>

<section class="starPerformersBox">
<div class="container">
<div class="row">
	<div class="col-md-12 col-lg-12">
		<div class="starRatingsButBox">
			<div class="quadrat">
				<a routerLink="/iic_rating_2018_19">IIC Ratings 2018-19</a>
			</div>
		</div>
	</div>
    <div class="col-md-4 col-lg-4">
        <mat-card class="w-100 h-300p iicDashboard1819Mob">
            <h2 class="dash18-19">
                <mat-icon class="activityDashboard">star_rate</mat-icon>STAR PERFORMERS
            </h2>
            <div class="m-2">
                <marquee direction="up" scrolldelay="300" width="100%" height="200px" onmouseover="this.stop();"
                    onmouseout="this.start();">
                    <div class="row" *ngFor="let starPerformer of starPerformers">
                        <hr>
                        <div class="col-4">
                            <img width="60" height="60"
                                src="{{baseImageUrl}}/images/events/institutes/starImage/{{starPerformer.starImage}}"
                                alt="image">
                        </div>
                        <div class="col-8">
                            <label title="{{starPerformer.starName}}">{{starPerformer.starName}}</label>
                            <label title="{{starPerformer.instituteName}}">{{starPerformer.instituteName}}</label>
                            <label title="{{starPerformer.title}}">{{starPerformer.title}}</label>
                        </div>
                    </div>
                </marquee>
                <!--<a class="float-right dash18-19But" routerLink="/institute/star-performers/18-19">more...</a>-->
            </div>
        </mat-card>
    </div>
	<div class="col-md-8 col-lg-8">
        <mat-card class="w-100 h-300p">
            <h2 class="dash18-19">
                <mat-icon class="bookmarkDashboard">bookmark</mat-icon>MY IMPACT
            </h2>
            <div style="height: 100%; width: 100% !important;">
                <ngx-charts-bar-vertical-stacked
                [scheme]="colorScheme"
                [results]="multi"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [yAxisLabel]="yAxisLabel"
                (select)="onSelect($event)">
            </ngx-charts-bar-vertical-stacked>  
            </div>
        </mat-card>
    </div>
</div>

<!-- <div class="row">    
    <div class="col-md-12 col-lg-12">
        <mat-card class="w-100 h-300p">
            <h2>
                <mat-icon class="bookmarkDashboard">bookmark</mat-icon>MY IMPACT
            </h2>
            <div style="height: 100%; width: 100% !important;">
                <ngx-charts-bar-vertical-stacked
                [scheme]="colorScheme"
                [results]="multi"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [yAxisLabel]="yAxisLabel"
                (select)="onSelect($event)">
            </ngx-charts-bar-vertical-stacked>  
            </div>
        </mat-card>
    </div>
</div> -->
</div>
</section>

<section class="iic2018-19Box">
	<div class="container">
		<div class="cbp-row wh-content">
			<div class="cbp-container wh-padding">
				<div class="vc_row">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>IIC 2018-19</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="vc_col-md-12">
				<p class="iic18-19Para">The first version (IIC1.0) of IIC program was launched on 21st November 2018 at AICTE HQ, New Delhi by the then Hon’ble Minister of HRD, Shri Prakash Javadekar. Total 900 HEIs came forward to establish IICs in their campus. IICs were given tasks to organise workshops and campaigns in the area of IPR and Innovation in their campuses.  Similarly, the HEIs were also actively disseminated the India First Leadership Talk Series were conducted by MoE’s Innovation Cell. By the end of IIC1.0 calendar year, June 2019, total reach out   to more than 7.5 lakh students and 63,000+ faculties were achieved.</p>
			</div>			
		</div>
		<div class="vc_row">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2>Regional orientation and training sessions for faculties from IICs</h2>
				</div>
			</div>
			<div class="vc_col-md-6">
				<p class="iic18-19Para2">Total 14 orientation session were organised during the period from 19 July to 19 August 2019. The purpose of these training sessions were to train IIC representative from Institutes about features of IIC portal system and scoring mechanism for doing various IIC activities. The sessions also focused on orientation of faculties regarding unique IIC model and its approach to foster innovation at Institute level. </p>
			</div>
			<div class="vc_col-md-6">
				<div class="iicFacultySeassionBox"><img src="assets/assets/images/faculty-from-iic.jpg" class="img-responsive"></div>
			</div>
		</div>
		<div class="vc_row">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2>Regional Mentoring sessions for POC students</h2>
				</div>
			</div>
			<div class="vc_col-md-12">
				<div class="iicDashBottom">
					<p class="iicDashPara1">MoE’s Innovation Cell has organised 14 orientation session during the period from 19 July to 19 August 2019 at 12 cities across India. Entrepreneurship Development Institute of India (EDII) Ahmedabad and Forge Incubator, Coimbatore were sourced as knowledge partner organisations to conduct these orientation sessions. The experts from these organisations and MoE’s Innovation Cell conducted one-day long mentoring through various tools and techniques. The mentoring session was concluded with pitching round for shortlisting best POCs for National Level bootcamp. </p>
					<p>The purpose of these mentoring sessions were to mentor and guide students with their submitted POCs on various aspects of conceiving a startup. The experts also provided one on one guidance to students on how to carry forward their POCs to a startup level. </p>
				</div>
			</div>
		</div>
		
		<div class="vc_row">
			<div class="vc_col-md-12">
				<div class="iicDashBottom">
					<h3><strong>Best Performing IIC Institutes (2018-19) are felicitated at Annual Innovation Festival on 11th Sept 2020</strong></h3>
					<img src="assets/assets/images/iicMainImage.jpg" class="img-responsive">
					<p class="beforeImgText">25 Best Performing IIC Institutes (2018-19) are being felicitated by Former Education Minister During the Annual Innovation Festival Celebration held on 11th Sept 2020 at AICTE HQ, New Delhi</p>
				</div>
			</div>
		</div>
	</div>
</section>