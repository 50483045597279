import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { InstituteAnnouncementService } from 'src/app/Services/institute-announcement.service';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-mentor-mentee-program-new',
  templateUrl: './mentor-mentee-program-new.component.html',
  styleUrls: ['./mentor-mentee-program-new.component.css']
})
export class MentorMenteeProgramNewComponent implements OnInit {

  imageBasePath= myGlobals.imageBasePath;
  MMCount:any;
  MMCount2223:any;
  constructor(private IAS: InstituteAnnouncementService,) { }

  ngOnInit(): void {

    this.IAS.getIicMentorMenteeCount()
    .subscribe((data) => this.handleResponse(data));
    this.IAS.getIicMentorMenteeCount2223()
    .subscribe((data) => this.Response(data));


	  /*------------------------------------------------------------------
   	 Smooth Scroll Landing
	 ------------------------------------------------------------------*/
// Select all »a« elements with a parent class »links« and add a function that is executed on click
$( '#navbarColor01 a' ).on( 'click', function(e){

  // Define variable of the clicked »a« element (»this«) and get its href value.
  var href = $(this).attr( 'href' );

  // Run a scroll animation to the position of the element which has the same id like the href value.
  $( 'html, body' ).animate({
		scrollTop: $( href ).offset().top
  }, '300' );

  // Prevent the browser from showing the attribute name of the clicked link in the address bar
  e.preventDefault();

});

$(document).ready(function(){
    $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $('#scroll').fadeIn();
        } else {
            $('#scroll').fadeOut();
        }
    });
    $('#scroll').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });
});


  }

  handleResponse(data)
  {
    this.MMCount = data;
  }
  Response(data)
  {
    this.MMCount2223 = data;
  }

}
