import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  EmailValidator,
} from '@angular/forms';
import { InstituteSuggestionService } from 'src/app/Services/institute-suggestion.service';
import { ToastrService } from 'ngx-toastr';
import {MatPaginatorModule} from '@angular/material/paginator';
import { SuggestDialogComponent } from './suggest-dialog/suggest-dialog.component';

@Component({
  selector: 'app-institute-suggestion-new',
  templateUrl: './institute-suggestion-new.component.html',
  styleUrls: ['./institute-suggestion-new.component.css']
})
export class InstituteSuggestionNewComponent implements OnInit {
  public formdata= new FormData;
  currentPageNo: number = 1;
  reply:boolean=false;
  firstPage = true;
  lastPage: boolean = false;
frontCurrentPageNo: number = 0;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  public replyForm: FormGroup;
  submittedMessage: String[] = [];
  replies:String[]=[];
  showRepliedMessage: boolean = false;
  unread: boolean=false;
  stats: any;
  constructor(public dialog: MatDialog,
    private suggestionService: InstituteSuggestionService,
    private fb: FormBuilder,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.replyForm = this.fb.group({
     message: [null, Validators.required],
    });
    this.getSuggestion();
    this.getStats();
    
  }
  getStats() {
    this.suggestionService.getSuggestionStats().subscribe(data=>this.setStats(data));
  }
  setStats(data) {
    this.stats=data;
  }

  getSuggestion() {
    this.suggestionService
      .getSuggestion(this.currentPageNo,this.per_page)
      .subscribe((data) => this.handleMessageResponse(data));
  }

  handleMessageResponse(data) {
    this.submittedMessage = [];

    data.forEach((element) => {
      this.submittedMessage.push(element);
    });
this.total_pages = data.meta.pagination.total;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }
  
  nextPage(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	  this.per_page = e.pageSize;
    this.getSuggestion();
  }

  previousPage() {
    this.currentPageNo--;
    this.getSuggestion();
  }
  getReplies(e:any){
    var data1:any;
    this.suggestionService.readPosts(e).subscribe(data=>data1=data);
    this.suggestionService
    .getReplyList(e)
    .subscribe((data) => this.handlegetreplyResponse(data));
  }
  handlegetreplyResponse(data) {
    if(data['data'].length>='1' ){
      this.replies = [];
      data['data'].forEach((element) => {
            this.replies.push(element);
            if(element['read_status']==0){
              this.unread=true;
            }
      });

    }else{
      this.reply=false;      
    }



   
  }

    handleFileInput(files: FileList) {
      const file = files.item(0);
      this.formdata.append('suggestionFileReply', file);
    }
    onSubmit(data:any){
      this.formdata.append('Id',data.id);
      this.formdata.append('message',this.replyForm.get('message').value);
      this.formdata.append('subject',data.subject);
      this.suggestionService .submitReply(this.formdata)
      .subscribe((data) => this.handlereplyResponse(data));
    }

handlereplyResponse(data) {
if (data['status'] == '1') {
 this.toastr.success(data['msg']);

} else {
 if (data['response_code'] == '0') {
   this.toastr.error(data['response_message']);
 } else {
   this.toastr.error('Some error occured.');
 }
}

    }
    
  openDialog(): void {
    const dialogRef = this.dialog.open(SuggestDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      this.currentPageNo = 1;
      this.getSuggestion();
    });
  }
}
