import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { TokenService } from 'src/app/Services/token.service';
import { Router,ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/app.component';

@Component({
  selector: 'app-handholding-institute-request',
  templateUrl: './handholding-institute-request.component.html',
  styleUrls: ['./handholding-institute-request.component.css']
})
export class HandholdingInstituteRequestComponent implements OnInit {

  captchaResponse: any;

  public handholdingRequest: FormGroup;
  public error = [];

  instituteDetails: any;

  institute_name: any = '';
  institute_address: any = '';
  institute_city: any = '';
  institute_state: any = '';
  consortium_id: any = '';
  uploadDiv: boolean;

  id:any;
  memberId:any;
  memberDetail:any;

  constructor(private jarwis: JarwisService,
    private token: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.memberId = params.memberId;
      }
    );
    this.handholdingRequest = this.fb.group({
      accept_status: [null, Validators.required],
    });

    this.jarwis.getHandholdingRequestData(this.id,this.memberId).subscribe(
      data => this.handleResponseData(data)
    );

  }
  handleResponseData(data){
    this.memberDetail = data.data;
    console.log(this.memberDetail);
  }
  handleData2(data) {

  }

  

  onSubmit(values) {
    console.log(values);
    if (this.handholdingRequest.valid) {
        values['id'] = this.id;
        values['memberId'] = this.memberId;

        this.jarwis.acceptHandholdingRequestData(values).subscribe(
          data => this.handleResponse(data)
        );
      
    } else {
      this.toastr.error('Kindly fill all the details');
    }
  }

  handleResponse(data){

  }

}
