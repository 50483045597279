<app-online-session-navbar></app-online-session-navbar>
<link rel="stylesheet" type="text/css" href="/assets/iaProfile/css/bootstrap.min.css" />
<link rel="stylesheet" href="/assets/iaProfile/css/style.css" type="text/css">

  <!-- <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container-fluid navigation_width">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="@"><img src="assets/institute/images/logo.png" class="img-responsive"></a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav navbar-right" style="width:auto;float: left !important;right:40px; top: 0;">
          <li><span style="font-size: 27px;">MHRD’s Innovation Cell<br>Institution’s Innovation Council</span><br>Institute Portal</li>
        </ul>
      </div>
    </div>
  </nav> -->
  <div id="outer_wrapper">
<div class="container">
<mat-card class="matr">

			<div class="login100-form-title p-b-51 resetRequest" style="margin: auto;margin-left: 48px;">
			<img src="../../../../../assets/img/logoiic.png" class="iaSignLogo">
		</div>
			
			<form class="login100-form validate-form flex-sb flex-w" #ResetPasswordForm=ngForm (ngSubmit)="onSubmit()">
			
			<div style="margin-top: 20px;margin-bottom: 20px;;margin-left: 48px;">
				<h4 style="font-size: 100%;font-weight: 600;text-align: center;">
					Reset Password</h4>
			</div>
				<div class="form-group wrap-input100 validate-input m-b-16">
						<input type="email" name="email" placeholder="Enter Email Id" class="input100" [(ngModel)]="form.email" id="inputEmail" required
              autocomplete="off">
			  <mat-error *ngIf="ResetPasswordForm.controls.email.errors?.required">Email is required
            </mat-error>
				</div>
				<div class="form-group wrap-input100 validate-input m-b-16">
					<input type="password"  name="password" placeholder="Enter Password" class="input100"
        [(ngModel)]="form.password" id="inputPassword" required autocomplete="off">
		<mat-error *ngIf="ResetPasswordForm.controls.password.errors?.required">Password is required
            </mat-error>
				</div>
				<div class="form-group wrap-input100 validate-input m-b-16">
					<input type="password"  name="new_password" placeholder="Enter New Password"
        class="input100" [(ngModel)]="form.new_password" id="inputNewPassword" required autocomplete="off">
		<mat-error *ngIf="ResetPasswordForm.controls.new_password.errors?.required">New Password is required
            </mat-error>
				</div>
				<div class="form-group wrap-input100 validate-input m-b-16">
					<input type="password"  name="confirm_password" placeholder="Enter Confirm Password"
        class="input100" [(ngModel)]="form.confirm_password" id="inputConfirmPassword" required autocomplete="off">
		<mat-error *ngIf="ResetPasswordForm.controls.confirm_password.errors?.required">Confirm Password is required
            </mat-error>
				</div>
				

				
				
				
				<div class="container-login100-form-btn m-t-17 boxResetBut">
					<button type="submit" class="mat-elevation-z4 mt-3 iaButReset" [disabled]="!ResetPasswordForm.valid">
            <!-- <mat-icon class="mt-2"> arrow_forward_ios</mat-icon> -->Submit
          </button>
				</div>
			</form>

</mat-card>
</div>
</div>

  
<!-- Footer-->
<app-iic-web-footer></app-iic-web-footer>
  