import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../app.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-teaching-learning2',
  templateUrl: './teaching-learning2.component.html',
  styleUrls: ['./teaching-learning2.component.css']
})
export class TeachingLearning2Component implements OnInit {

  private baseUrl = myGlobals.baseUrl;
  private baseUrlOSIA = myGlobals.baseUrlOSIA;

  agency_name: any;
  collaborated_agency_name: any;
  matDatepicker:any;
  Perioud_Of_Collaboration:any;
  collaboration_status:any;
  //myArray:[];

  constructor(private http: HttpClient) { }

  teaching_learning2(){
    
   //this.agency_name = (<HTMLInputElement>document.getElementById("agency_name")).value;
  // this.collaborated_agency_name = (<HTMLInputElement>document.getElementById("collaborated_agency_name")).value;
  // this.matDatepicker=(<HTMLInputElement>document.getElementById("matDatepicker")).value;
   //this.Perioud_Of_Collaboration=(<HTMLInputElement>document.getElementById("Perioud_Of_Collaboration")).value;
  // this.collaboration_status=(<HTMLInputElement>document.getElementById("collaboration_status")).value;
   
  // const myArray: string[] = [];
  // myArray.push(this.agency_name);
   // alert(myArray);
  // return this.http.get(`${this.baseUrl}/osia/rem-session-list`);
  
  return this.http.get(`${this.baseUrl}/teaching_learning2`);
  
  }

  ngOnInit(): void {
  }

  

}
