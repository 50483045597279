import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-submission',
  templateUrl: './view-submission.component.html',
  styleUrls: ['./view-submission.component.css']
})
export class ViewSubmissionComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  iicrepository:any;

  constructor(private fb: FormBuilder, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.requireField=0;
    this.rid = "add";
    this.route.params.subscribe((params) => {
      if(params.rid != null && params.rid != 'undefined'){
        this.rid = params.rid;
      }
    });
    this.instituteActivityService.getNominateChallenges(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.iicrepository = data['data'];

  }

}
