<mat-dialog-content class="mat-typography">
  <div class="instMyCouncilModal" style="border: 7px solid #0d133d;border-radius: 25px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12"
          style="background-color: #fff;color: #000 !important;font-size: 27px;border-radius: 12px;">
          <table width="100%">
            <tr>

              <td style="vertical-align: top;padding-top: 30px;text-align: left;">
                <img src="assets/images/mhrd.jpg" style="width:210px;">
              </td>
              <td style="vertical-align: top;padding-top: 10px;text-align: center;">
                <img src="assets/images/iic_logo.png" style="width:190px;">
              </td>

              <td style="vertical-align: top;padding-top: 42px;text-align: right;">
                <img src="assets/images/aicte.png" style="width:70px;">
              </td>
            </tr>
          </table>
          <p class="hdr"
            style="background-color: #fff;color: #5c6bc0 !important;font-size: 27px;text-align:center;font-weight: bold;">
            Bifurcation of Score and Reward Points</p>
          <div style="border:2px solid #8c9eff;border-radius: 12px; padding: 0 0 20px 0; margin: 0 0 20px 0;">
            <ul>
              <li class="hdr" style="text-align:left;font-size: 17px;margin-bottom: 12px;"><b>Scores</b>
                are cumulative of minimum prescribed activities in each “activity type”.</li>
              <li class="hdr" style="text-align:left;font-size: 17px;margin-bottom: 12px;">IIC will earn
                <b>Reward Points</b> for conducting more than prescribed minimum activities in each
                “activity type” which will have effect towards 5th star and can be redeemed in future.
              </li>

              <li class="hdr" style="text-align:left;font-size: 17px;margin-bottom: 12px;"><b>Score Matrix
                  :</b></li>

            </ul>
            <table class="table table-responsive table-bordered" style="margin-bottom: 0px;">
              <tbody>
                <tr style="background-color:#cfd8dc !important;">
                  <th class="hdr" width="20%" style="text-align:center;border: 1px solid #000 !important">Activity Type
                  </th>
                  <th class="hdr" width="20%" style="text-align:center;border: 1px solid #000 !important">Total Number
                    of
                    Activities Approved</th>
                  <th class="hdr" width="20%" style="text-align:center;border: 1px solid #000 !important">Total
                    Threshold
                    Number of Activities</th>
                  <th class="hdr" width="20%" style="text-align:center;border: 1px solid #000 !important">Score (for
                    minimum
                    prescribed activities)</th>
                  <th class="hdr" width="25%" style="text-align:center;border: 1px solid #000 !important">Adjusted
                    Cumulative
                    Reward Points For Additional Activities Beyond the Threshold Numbers for Each
                    Category (Reflection in multiple of 100 with activity score)</th>
                </tr>
                <tr>
                  <td class="hdr"
                    style="background-color:#cfd8dc !important;font-weight: bold;border: 1px solid #000 !important">
                    IIC Calendar Activity (Score for 1 activity=4.16) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.iicCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.iic_total_marks}}<br>
                    <span style="color:#1de9b6 !important;">(Max Score=24.96)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.totalRewardsIIC}}
                  </td>

                </tr>
                <tr>
                  <td class="hdr"
                    style="background-color:#cfd8dc !important;font-weight: bold;border: 1px solid #000 !important">
                    MIC driven Activity (Score for 1 activity=1.67) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.micCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.mic_total_marks}} <br>
                    <span style="color:#1de9b6 !important;">(Max Score=10.02)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.totalRewardsMIC}}

                  </td>

                </tr>
                <tr>
                  <td class="hdr"
                    style="background-color:#cfd8dc !important;font-weight: bold;border: 1px solid #000 !important">
                    Self-driven Activity (Score for 1 activity=2.5) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.selfCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.self_total_marks}}
                    <br>
                    <span style="color:#1de9b6 !important;">(Max Score=15)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;border: 1px solid #000 !important">
                    {{formData?.totalRewardsSelf}}
                  </td>

                </tr>
              </tbody>
            </table>
            <span class="hdr" style="text-align:center;font-size: 12px;display: block;"><b>Note:</b> IIC
              calendar year is divided in 4 quarters. IICs can earn maximum 25 marks in each
              quarter.</span>
            <hr style="border:1px solid #8c9eff;margin:0px !important;">
            <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;">Score to star
              Conversion:</span>
            <table style="width:50% !important;margin: auto;margin-bottom: 20px;"
              class="table table-responsive table-bordered">
              <tbody>
                <tr style="background-color:#cfd8dc !important;">
                  <th class="hdr" width="25%" style="text-align:center;border: 1px solid #000 !important">Score</th>
                  <th class="hdr" width="25%" style="text-align:center;border: 1px solid #000 !important">Star</th>
                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">0-6.99
                  </td>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">Zero
                    Star</td>

                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">7 –
                    14.99</td>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">Half
                    Star</td>

                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">15-24.99
                  </td>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">One Star
                  </td>

                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">25-34.99
                  </td>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">One and
                    a Half Star</td>

                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">35-50
                  </td>
                  <td class="hdr" style="font-weight: bold;border: 1px solid #000 !important">Two Star
                  </td>

                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>

    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="warn">Cancel</button>
</mat-dialog-actions>

