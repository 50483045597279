<div class="container">
	<h1 class="announcementHeadingMain">IIC Testimonial 2021-22</h1>
  <form [formGroup]="iicTestimonialQuestions" (ngSubmit)="onSubmit(iicTestimonialQuestions.value)">
    <table class="table table-hover">

    <tr>
      <th><h3>1. What do you like most about IIC? <span class="starMendet">*</span></h3></th>
      <td>
        <mat-form-field appearance="outline" class="w-100">

                <mat-label> What do you like most about IIC?</mat-label>

                <mat-select autocomplete="off" placeholder="Positive" formControlName="feedback_status"  required multiple>
                  <mat-option>Select</mat-option>
                  <mat-option value="{{feedback.name}}" *ngFor="let feedback of programFeedback">{{feedback.name}}</mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
            </mat-form-field>

      </td>
    </tr>

    <tr>    
      <th><h3>2. What do you like least about IIC? <span class="starMendet">*</span></h3></th>
      <td>
        <mat-form-field appearance="outline" class="w-100">

                <mat-label> What do you like least about IIC?</mat-label>

                <mat-select autocomplete="off" placeholder="Room for improvement" formControlName="improvement_room"  required multiple>
                  <mat-option>Select</mat-option>
                  <mat-option value="{{feedback.name}}" *ngFor="let feedback of programFeedback">{{feedback.name}}</mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
            </mat-form-field>

      </td>
    </tr>
  <tr>
    <th><h3>3. What could we improve on? <span class="starMendet">*</span></h3></th>
    <td colspan="3"><textarea formControlName="suggestions" id="suggestions" cols="30" rows="10" required class="formControl"></textarea></td>
  </tr>

  <tr>
    <th><h3>4. File Upload  <span class="starMendet">*</span></h3></th>
    <td>
      <div class="col-12">
        <label class="custom-file-label float-right" for="customFile"
            *ngIf="!ipr_document">Choose file</label>

        <label class="custom-file-label float-right" for="customFile"
            *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>

        <input #ipr_documentRef type="file" accept="application/pdf"  name="ipr_document"
            (change)="( ipr_document = ipr_documentRef.files )"
            class="custom-file-input float-right" required>
          </div>

    </td>
  </tr>

  <tr>
    <th><h3>5. Video Url <span class="starMendet">*</span></h3><p class="smallFont">(Guideline)</p></th>
    <!-- <td colspan="3"><input formControlName="video_url" id="suggestions" cols="10" rows="10" required class="formControl" ></td>
   -->
    <td>
      <mat-form-field appearance="outline" class="w-100">
      <mat-label>Video Url</mat-label>

      <input matInput autocomplete="off" placeholder="video" formControlName="video_url" type="text"   required>
      <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
      </mat-form-field>
    </td>
  </tr>

<tr>
  <td colspan="4">
  <div class="row">
    <div class="col-md-5"></div>
    <div class="col-md-2">
      <div fxFlex="50" class="m-2">   <button style="
          margin-left: 603px;
      " mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicTestimonialQuestions.invalid'>Submit</button></div>
    </div>
    <div class="col-md-5"></div>
      </div>
</td>
</tr>

    </table>
    </form>
</div>
<p>&nbsp;</p>
