<div class="container-fluid" style="margin-top: 50px;">
  <div class="row" color="primary">
	<div class="col-md-12">
      <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
          Mentor of Change List</h2>
	</div>	  
  </div>

<div class="container-fluid">
<mat-card class="mat-elevation-z6 proInstCardMat">  
<div class="row">
<div class="col-md-12">
<!-- <p><a class="btn btn-primary referralBut" routerLink='/institute/repository-add/add'>Add New</a></p> -->
<div class="tableBody">
      <div class="table-responsive">
<table class="table">
  <thead class="facultyTable">
      <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Contact</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let nomination of iicrepository; index as i" >
          <td>{{i+1}}</td>
          <td>{{nomination?.name}}</td>
          <td>{{nomination?.email}}</td>
          <td>{{nomination?.contact}}</td>
      </tr>
      <tr *ngIf="iicrepository.length== 0" colspan="4">
          <h3 style="text-align: center">No Data Found</h3>
      </tr>
  </tbody>
</table>
</div>
</div>

</div>
</div>
</mat-card>
</div>
</div>



