<!-- <section class="py-5 header">
    <div class="container py-4">
        <div class="row">
            <div class="col-md-3">
                
                <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link mb-3 p-3 shadow active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i class="fa fa-user-circle-o mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Incubation Centers</span></a>

                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <i class="fa fa-calendar-minus-o mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">Pre Incubation Centers</span></a>

                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <i class="fa fa-star mr-2"></i>
                        <span class="font-weight-bold small text-uppercase">All Details</span></a>
                </div>
            </div>


            <div class="col-md-9">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade shadow rounded bg-white show active p-5" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <h4 class="font-italic mb-4 text-center">Incubation Centers</h4>
                        <app-institute-incubation-centers></app-institute-incubation-centers>
                    </div>

                    <div class="tab-pane fade shadow rounded bg-white p-5" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <h4 class="font-italic mb-4 text-center">Pre Incubation Centers</h4>
                        <app-institute-pre-incubation-centers></app-institute-pre-incubation-centers>
                    </div>

                    <div class="tab-pane fade shadow rounded bg-white p-5" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <h4 class="font-italic mb-4 text-center">All Details</h4>
                        <app-institute-all-details></app-institute-all-details>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->






<mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedIndex">
    <mat-tab label="Incubation Centers" *ngIf="ForWhom=='Institute'">
        <app-institute-incubation-centers></app-institute-incubation-centers>
    </mat-tab>
    <mat-tab label="Pre Incubation Centers" *ngIf="ForWhom=='Institute'">
        <app-institute-pre-incubation-centers></app-institute-pre-incubation-centers>
    </mat-tab>
    <mat-tab label="IPR/TTO Centers" *ngIf="ForWhom=='Institute'">
        <app-institute-ipr-tto-centers></app-institute-ipr-tto-centers>
    </mat-tab>
    <mat-tab label="View & Edit Details">
        <app-institute-all-details></app-institute-all-details>
    </mat-tab>
</mat-tab-group>
