import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iic-ratings2023',
  templateUrl: './iic-ratings2023.component.html',
  styleUrls: ['./iic-ratings2023.component.css']
})
export class IicRatings2023Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
