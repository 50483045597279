import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { emailValidator } from 'src/app/app.component';
import { OnlineRegistrationService } from 'src/app/Services/onlineSession/online-registration.service';

@Component({
  selector: 'app-online-registration',
  templateUrl: './online-registration.component.html',
  styleUrls: ['./online-registration.component.css']
})
export class OnlineRegistrationComponent implements OnInit {

  public onlineRegistrationForm: FormGroup
  institutes: any;
  stateArr: any;
  participantName: any;
  participantDetails: any;
  totalIICNo: any;

  constructor(private fb: FormBuilder,
    private onlineService: OnlineRegistrationService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.onlineRegistrationForm = this.fb.group({
      state: [null, Validators.required],
      institute_name: [null, Validators.required],
      name: [null, Validators.required],
      designation: [null, Validators.required],
      email: [null],
      phone_number: [null],
      gender: [null, Validators.required],
    });

    this.onlineService.getState().subscribe(
      data => this.setState(data));

    this.onlineRegistrationForm.get('state').valueChanges.subscribe(
      data => this.getInstitutes(data)
    );

    this.onlineRegistrationForm.get('institute_name').valueChanges.subscribe(
      data => this.getInstitutesParticipantNameOSIA(data)
    );

    this.onlineRegistrationForm.get('name').valueChanges.subscribe(
      data => this.getInstitutesParticipantDetailsOSIA(data)
    );

  }

  setState(data) {
    this.stateArr = data['instituteDetails'];
  }

  getInstitutes(data) {
    this.onlineService.getOSInstitutes(data).subscribe(
      data => this.setInstitutes(data)
    );
  }

  setInstitutes(data) {
    this.institutes = data['instituteDetails'];
  }

  getInstitutesParticipantNameOSIA(data) {
    this.onlineService.getInstitutesParticipantNameOSIA(data).subscribe(
      data => this.setParticipantName(data)
    );
  }

  setParticipantName(data) {
    this.participantName = data['instituteDetails'];
  }


  getInstitutesParticipantDetailsOSIA(data) {
    this.onlineService.getInstitutesParticipantDetailsOSIA(data).subscribe(
      data => this.setParticipantDetails(data)
    );
  }

  setParticipantDetails(data) {
    this.participantDetails = data['instituteDetails'];
    this.onlineRegistrationForm.get('email').setValue(data['instituteDetails']['0']['email']);
    this.onlineRegistrationForm.get('phone_number').setValue(data['instituteDetails']['0']['participant_phone']);
    this.totalIICNo=data['instituteDetails']['0']['participation_count'];
  }


  onSubmit(values) {
    if (this.onlineRegistrationForm.valid) {
      this.onlineService.submitOnlineRegistrationForm(values).subscribe(
        data => this.handleResponse(data)
      );
    } else {
      this.toastr.error('Kindly fill all the details properly!')
    }
  }

  handleResponse(data) {
    if (data['message'] == 'Success') {
      this.toastr.success('Registered Successfully')
    } else {
      this.toastr.error(data['reason']);
    }

  }

}
