import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JarwisService } from 'src/app/Services/jarwis.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {

  public formValues = {
    email: null,
	otp: null,
    token: null
  };

  constructor(private route: ActivatedRoute,
    private toastr: ToastrService,
    private jarwis: JarwisService,
    private router: Router) {
    route.queryParams.subscribe(params => {
      this.formValues.token = params['token']
    });
    route.queryParams.subscribe(params => {
      this.formValues.email = params['email']
    });
	route.queryParams.subscribe(params => {
      this.formValues.otp = params['otp']
    });
  }

  ngOnInit() {
    this.jarwis.emailVerification(this.formValues).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );

  }

  handleResponse(data) {
    this.toastr.success('Your IIC account is verified successfully.  Login and complete the My Council details.');
    this.router.navigateByUrl('/login');
  }

  handleError(error) {
    this.toastr.error('Email Already Verified or Incorrect token');
  }

}
