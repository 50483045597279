import { InstitutePreIncubationCentersDialogComponent } from '../../../../../components/users/institute/institute-incubation/institute-pre-incubation-centers/institute-pre-incubation-centers-dialog/institute-pre-incubation-centers-dialog.component';
import { InstituteIPRTTOFacilitiesDialogComponent } from '../../../../../components/users/institute/institute-incubation/institute-ipr-tto-centers/institute-ipr-tto-centers-dialog/institute-ipr-tto-centers-dialog.component';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { InstituteIncubationService } from '../../../../../Services/institute-incubation.service';
import { InstituteSubscriptionComponent } from '../institute-subscription/institute-subscription.component';
@Component({
  selector: 'app-institute-all-details',
  templateUrl: './institute-all-details.component.html',
  styleUrls: ['./institute-all-details.component.css']
})
export class InstituteAllDetailsComponent implements OnInit {
  @Output() editInitiated = new EventEmitter();
  private subject = new Subject<any>();
  messages: any[] = [];
  subscription: Subscription;
  incubationDetails: string[] = [];
  preIncubationDetails: string[] = [];
  ipr_tto_facilities: string[] = [];
  facilityName:any;
  name:any;
  ForWhom :any;

  constructor(
    public dialog: MatDialog,
    private instituteIncubationService: InstituteIncubationService, 
    private messageService: InstituteSubscriptionComponent
    ) {

    this.subscription = this.messageService.onMessage().subscribe(message => {
      if (message) {
        this.messages.push(message);
        this.getData();
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }

  ngOnInit(): void {
    this.ForWhom=localStorage.getItem('ForWhom');
    this.getData();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  getData() {
    this.incubationDetails = [];
    this.instituteIncubationService.getIncubationCenterDetails().subscribe((data) => this.handleResponse(data));
    this.instituteIncubationService.getPreIncubationDetails().subscribe((data) => this.handlePreIncubationResponse(data));
    this.instituteIncubationService.getIPRTTODetails().subscribe((data) => this.handleIPRTTOResponse(data));
  }

  handleResponse(data) {
    this.incubationDetails = [];
    data['incubatorDetails'].forEach((element) => {
      this.incubationDetails.push(element);
    });
  }

  handlePreIncubationResponse(data) {
    this.preIncubationDetails = [];
    data['preIncubatorDetails'].forEach((element) => {
      this.preIncubationDetails.push(element);
    });
  }
  
  handleIPRTTOResponse(data) {
    this.ipr_tto_facilities = [];
    data['ipr_tto_facilities'].forEach((element) => {
      this.ipr_tto_facilities.push(element);
    });
  }

  editIncubationRow(data) {
    this.editInitiated.emit(true);
    this.messageService.sendMessage(data);
  }
  
  editPreIncubationRow(data) {
    this.editInitiated.emit(true);
    this.messageService.sendMessage(data);
  }

  openEditDialog(data) {
    const dialogRef = this.dialog.open(InstitutePreIncubationCentersDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  editIPRTTORow(data) {
    this.editInitiated.emit(true);
    this.messageService.sendMessage(data);
  }

  openEditDialog1(data) {
    const dialogRef = this.dialog.open(InstituteIPRTTOFacilitiesDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getData();
    });
  }

  deleteIncubationRow(id) {
    var obj = {
      id: id
    };

    this.incubationDetails = [];

    this.instituteIncubationService.deleteIncubationData(obj).subscribe((data) => {
      this.getData();
    });

  }

  deletePreIncubationRow(id) {
    var obj = {
      id: id
    };

    this.preIncubationDetails = [];

    this.instituteIncubationService.deletePreIncubationData(obj).subscribe((data) => {
      this.getData();
    });

  }

  deleteRow(id) {
    var obj = {
      id: id
    };

    this.ipr_tto_facilities = [];

    this.instituteIncubationService.deleteIPRTTOData(obj).subscribe((data) => {
      this.getData();
    });

  }

}
