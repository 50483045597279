import { HttpHandler } from '@angular/common/http';
import { Component, ErrorHandler, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-institute-my-council',
  templateUrl: './institute-my-council.component.html',
  styleUrls: ['./institute-my-council.component.css'],
})
export class InstituteMyCouncilComponent implements OnInit {
  data: any;
  application_status: any;
  app_status: any;
  FacultyRolesIncomplete : any;
  StudentRolesIncomplete : any;
  constructor(private myCouncilService: InstituteMyCouncilService) {
    this.myCouncilService.dataUpdated.subscribe(data => this.checkMyCouncil());
  }

  ngOnInit(): void {
    this.checkMyCouncil();
    this.check();
  }

  checkMyCouncil() {
    this.myCouncilService.checkMyCouncil().subscribe(
      data => this.data = data
    );
  }
  check() {
    this.myCouncilService.checkMyCouncil().subscribe(
      data => this.handleResponse(data)
    );
  }
  handleResponse(data) {
    this.application_status = data['applicationStatusDisapproved'];

    this.app_status = data['app_status'];

    this.FacultyRolesIncomplete = data['facultyNotSubittedRoles'];
    this.StudentRolesIncomplete = data['studentNotSubittedRoles'];

    localStorage.setItem('FacultyRolesIncomplete',this.FacultyRolesIncomplete);
    localStorage.setItem('StudentRolesIncomplete',this.StudentRolesIncomplete);
    localStorage.setItem('FacultyRolesStatus',data['facultyStatus']);
    localStorage.setItem('StudentRolesStatus',data['studentStatus']);
  }
}
