import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { emailValidator } from 'src/app/app.component';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteFacultyDialogComponent } from '../../../institute-my-council/institute-faculty/institute-faculty-dialog/institute-faculty-dialog.component';
import * as myGlobals from '../../../../../../app.component';

@Component({
  selector: 'app-add-dialog-impact',
  templateUrl: './add-dialog-impact.component.html',
  styleUrls: ['./add-dialog-impact.component.css']
})
export class AddDialogImpactComponent implements OnInit {
  public impactAnalysis: FormGroup;
  baseImageUrl = myGlobals.baseImageUrl2;

  show: boolean;
  show1: boolean;
  fbl: any;
  fbl1: any;
  id1: any;
  name1: any;
  email1: any;
  mobile1: any;
  designation1: any;
  deaprtment1: any;
  experience_in_years1: any;
  // roles1: any;
  qualification1: any;
  facultyModel = [];
  sessionModel: any;
  sessionModeModel: any;
  themeModel:any;
  // Role = ['Vice President', 'Convener', 'Social Media', 'Innovation Activity', 'Internship Activity Coordinator', 'IPR Activity Coordinator', 'Start up Activity Coordinator', 'ARIIA Coordinator', 'NIRF Coordinator', 'Member'];
  role1: any;
  Role: any;
  theme: any[];
  head: any[];
  date: any;
  id: any;
  convenor_name: any;
  convenor_email: any;
  convenor_contact: any;
  convenor_designation: any;
  convenorSession: any;
  myDate = (new Date());latest_date: any;
  minDate = new Date();
  update: number;
  updateSession: any;
  name: any;
  externalCV: FileList | null;
  actualDate: any;
  report: any;
  showCV: boolean;

  constructor(private fb: FormBuilder,
    private jarwis: JarwisService,
    private myCouncilService: InstituteMyCouncilService,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<AddDialogImpactComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
    }

  }



  ngOnInit(): void {
    this.impactAnalysis = this.fb.group({
      'faculty': [null, Validators.required],
      'session': [null],
      'title': [null, Validators.required],
      'date': [null, Validators.required],
      'theme': [null, Validators.required],
      'mode': [null, Validators.required],
    });

    this.innovationService.getThemes().subscribe(
      data => this.setThemeDropdown(data)
    );
   
    if ((this.data != undefined) || (this.data != null)) {
    this.setUpdateData(this.data);
    }
    this.latest_date=this.changeDate(this.myDate)
  }

 
  getcheckSessionDetails(){
    this.innovationService.getcheckSessionDetails().subscribe(
      data => this.setcheckSessionDetails(data)
    );
  }

  setcheckSessionDetails(data){
    this.convenor_name=data['data']['0']['facultyName']
    this.convenor_email=data['data']['0']['faculty_email']
    this.convenor_contact=data['data']['0']['faculty_contact']
    this.convenor_designation=data['data']['0']['faculty_designation']
    this.convenorSession=data['data']['0']['session_no']

    if(this.convenor_name!=0){
      this.name=data['data']['0']['facultyName']
    }

    if(this.update!=1)
  {  
    this.sessionModel=this.convenorSession;
  }else{
this.sessionModel=this.updateSession;
  }

      if(this.convenor_name==0){
          this.innovationService.getHeads().subscribe(
          data => this.setHeadData(data)
        );
      }
      // else if(this.convenor_name==undefined){
      //   this.innovationService.getHeads().subscribe(
      //     data => this.setHeadData(data)
      //   );
      // }
      else 
      if(this.convenor_name!=0 && this.convenor_name!=undefined)
      {
        this.head=[]
        for(let i=0;i<data['data'].length;i++){
          this.head.push(data['data'][i])
        }

        this.facultyModel=this.convenor_name;
      }

  }



  setUpdateData(data){
    this.impactAnalysis.controls['faculty'].setValue(data['faculty_name']);
    this.facultyModel=data['faculty_name']
    this.impactAnalysis.controls['mode'].setValue(data['mode']);
    this.impactAnalysis.controls['title'].setValue(data['title']);
    this.impactAnalysis.controls['date'].setValue(data['date']);
    var x=data['session'].toString();
    this.updateSession=x;
    this.impactAnalysis.controls['session'].setValue(x);
    this.sessionModel=x;
    this.impactAnalysis.controls['theme'].setValue(data['theme_id']);
    this.themeModel=data['theme_id'];
    this.sessionModeModel=data['mode']
    this.id=data['id']
   this.update=1;
   this.name=data['faculty_name']


   this.report=data['file_upload']
   if(this.report!=null){
     this.showCV=true
   }
  }

  setHeadData(data){
    this.head=[];
   {
      for(let i=0;i<data['hoi'].length;i++){
        this.head.push(data['hoi'][i])
      }
    }
  }
  setThemeDropdown(data){
this.theme=[];
    for(let i=0;i<data['themes'].length;i++){
      this.theme.push(data['themes'][i])
    }
  }

  onSubmit(data){
    this.actualDate=this.changeDate(data['date']);
    data['id']=this.id;

if(this.update==1){
  data['session']=this.updateSession;
}else{
  data['session']=this.convenorSession;

}


var cvFile =
this.externalCV && this.externalCV.length ? this.externalCV[0] : null;

var formData = new FormData();



if(this.show==true){
  formData.append('file', cvFile);
  formData.append('file_upload_hidden', this.report);
  formData.append('id', this.id);
  formData.append('faculty',this.impactAnalysis.get('faculty').value);
  formData.append('session',this.impactAnalysis.get('session').value);
  formData.append('title',this.impactAnalysis.get('title').value);
  formData.append('date', this.actualDate);
  formData.append('theme',this.impactAnalysis.get('theme').value);
  formData.append('mode',this.impactAnalysis.get('mode').value);

  if(this.update==1){
    formData.append('session',this.updateSession);
  }else{
    formData.append('session',this.convenorSession);

  }
  
  this.innovationService.updateImapactSession(formData).subscribe(
    data => this.handleResponseData(data)
  );
}


if(this.show1==true){
if(this.externalCV!=null && this.externalCV!=undefined ){
  formData.append('file', cvFile);
  formData.append('faculty',this.impactAnalysis.get('faculty').value);
  formData.append('title',this.impactAnalysis.get('title').value);
  formData.append('date', this.actualDate);
  formData.append('theme',this.impactAnalysis.get('theme').value);
  formData.append('mode',this.impactAnalysis.get('mode').value);
  if(this.update==1){
    formData.append('session',this.updateSession);
  }else{
    formData.append('session',this.convenorSession);
  }
  this.innovationService.submitImpactLectures(formData).subscribe(
    data => this.handleResponseData(data));
}else{
  this.toastr.error("Please Upload File!")
}

}

  }

  handleResponseData(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg'])
      this.dialogRef.close();
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }
}
