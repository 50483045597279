<div class="container">
<div class="row">
    <div class="col-md-6 col-lg-3">
        <mat-card class="w-100 h-300p">
            <h2>
                <mat-icon class="activityDashboard">star_rate</mat-icon>STAR PERFORMERS
            </h2>
            <div class="m-2">
                <marquee direction="up" scrolldelay="300" width="100%" height="200px" onmouseover="this.stop();"
                    onmouseout="this.start();">
                    <div class="row" *ngFor="let starPerformer of starPerformers">
                        <hr>
                        <div class="col-4">
                            <img width="60" height="60"
                                src="{{baseImageUrl}}/images/events/institutes/starImage/{{starPerformer.starImage}}"
                                alt="image">
                        </div>
                        <div class="col-8">
                            <label title="{{starPerformer.starName}}">{{starPerformer.starName}}</label>
                            <label title="{{starPerformer.instituteName}}">{{starPerformer.instituteName}}</label>
                            <label title="{{starPerformer.title}}">{{starPerformer.title}}</label>
                        </div>
                    </div>
                </marquee>
                <a class="float-right" routerLink="/institute/star-performers/18-19">more...</a>
            </div>
        </mat-card>
    </div>
    <div class="col-md-6 col-lg-4">
        
        <mat-card class="w-100 h-300p">
            <h2>
                <mat-icon class="activityDashboard">leaderboard</mat-icon>MY PERFORMANCE
            </h2>
                <canvas baseChart 
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [plugins]="lineChartPlugins"
                [legend]="lineChartLegend"
                [chartType]="lineChartType">
              </canvas>
        </mat-card>
    </div>

    <div class="col-md-12 col-lg-5">
        <mat-card class="w-100 h-300p">
            <h2>
                <mat-icon class="bookmarkDashboard">bookmark</mat-icon>MY IMPACT
            </h2>
            <div style="height: 100%; width: 100% !important;">
                <ngx-charts-bar-vertical-stacked
                [scheme]="colorScheme"
                [results]="multi"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [yAxisLabel]="yAxisLabel"
                (select)="onSelect($event)">
            </ngx-charts-bar-vertical-stacked>  
            </div>
        </mat-card>
    </div>
</div>
</div>