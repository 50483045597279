import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import * as myGlobals from '../../../../../app.component';

@Component({
  selector: 'app-institute-mentor-mentee',
  templateUrl: './institute-mentor-mentee.component.html',
  styleUrls: ['./institute-mentor-mentee.component.css']
})
export class InstituteMentorMenteeComponent implements OnInit {
  baseImageUrl = myGlobals.baseImageUrl2;

  showStatus: any;
  mentorModel:any;
  mentor: any;
  public impactAnalysis: FormGroup;
  maxDate = new Date();
  type: any;
  source: any;
  ss: any;
  source1: any;
  mentorName: any;
  mentor_mobile: any;
  mentor_email: any;
  mentor_designation: any;
  disableStatus: any;
  id: any;
  status: any;
  sss: string;
  ss1: string;
  externalCV: FileList | null;
  file_upload: any;
  mandate: number;
  id1: number;
  isVisible: number =0;

  constructor(private activityService: InstituteActivityService,private router:Router,
    public dialog: MatDialog, private toastr: ToastrService,private fb: FormBuilder,) { }

  ngOnInit(): void {

    this.impactAnalysis = this.fb.group({
      'mentor': [null, Validators.required],
    });

    this.activityFormButton();
    // this.getCordinatorDropdown();
this.checkMemtorEligible();
this.getMentorDetails();
this.getMentorStatus();


// this.checkmandate();
  }
  // checkmandate(){
  //   this.activityService.getcheckmandate().subscribe(
  //     data => this.setcheckMandate(data)
  //   );
  // }

  // setcheckMandate(data){
  //   if(data['status']==1){
  //     this.mandate=1
  //   }else{
  //     this.mandate=0
  //   }
  // }

  signedForm(){
    this.id1=1;
    this.router.navigateByUrl('/institute/bank-form/1')
  }
  getMentorStatus(){
    this.activityService.getMentorStatus().subscribe(
      data => this.setMentorStatus(data)
    );
  }
setMentorStatus(data){
  this.disableStatus=data['data']['0']['nomination_status'];
  // this.getMentorDetails();
  this.getCordinatorDropdown();
}

  getMentorDetails(){
    this.activityService.getMentorDetails().subscribe(
      data => this.setMentorDetails(data)
    );
  }

  setMentorDetails(data){
    this.status=(data['mentor_details']) ? data['mentor_details']['status'] : 0;
    this.id=(data['mentor_details']) ? data['mentor_details']['id'] : 0
    this.mentorName=(data['mentor_details']) ? data['mentor_details']['mentor_name'] : ""
    this.mentor_mobile=(data['mentor_details']) ? data['mentor_details']['mentor_mobile'] : ""
    this.mentor_email=(data['mentor_details']) ? data['mentor_details']['mentor_email'] : ""
    this.mentor_designation=(data['mentor_details']) ? data['mentor_details']['mentor_designation'] : ""
    this.file_upload=(data['mentor_details']) ? data['mentor_details']['file_upload'] : ""
  }

  checkMemtorEligible(){
    this.activityService.checkMemtorEligible().subscribe(
      data => this.setcheckMemtorEligible(data)
    );
  }

  setcheckMemtorEligible(data){
    if(data['mentor_eligible']==1){
      this.type="mentor"
      this.sss="Mentor"
      this.ss1="Mentor"

    }
    if(data['mentee_eligible']==1){
      this.type="mentee"
      this.sss="Mentee"
      this.ss1="Mentor"

    }
  }

  onSubmit(data){
    data['type']=this.type;
    this.ss= this.impactAnalysis.get('mentor').value;
    this.source1 =  this.mentor.find(x => x.memberId == this.ss);
this.source=this.source1.source;
data['source']=this.source



// var cvFile =
// this.externalCV && this.externalCV.length ? this.externalCV[0] : null;
// if(this.externalCV != null && this.externalCV != undefined){
  var formData = new FormData();
  // formData.append('file',cvFile);
  formData.append('source',this.source);
  formData.append('type',this.type);
  formData.append('mentor',this.impactAnalysis.get('mentor').value);
  this.activityService.submitMentorWillingness(formData).subscribe(
    data => this.handleResponse(data));
// }else{
//   this.toastr.error("Upload of Mandate form is mandatory!")
// }
}
  handleResponse(data){
    if(data['status']=='1'){
      this.toastr.success(data['msg']);
      this.getMentorStatus();
      this.getMentorDetails();
        }else{
      this.toastr.error(data['msg'])
    }
  }


  getCordinatorDropdown(){
    this.activityService.getMentorWillingness().subscribe(
      data => this.setMentorWillingness(data)
    );
  }
  setMentorWillingness(data){

if(this.disableStatus==0){
  this.mentor=[];
  for(let i=0;i<data['hoi'].length;i++){
    this.mentor.push(data['hoi'][i])
  }
}else{
  this.mentor=[];
  //  this.mentorModel = '';

}
  }

  activityFormButton() {
    this.activityService.getHOI().subscribe(
      data => this.instituteData(data)
    );
  }

  instituteData(data) {
    if (data) {
      var institute_id = data['hoiDetails']['institute_id'];
      this.activityService.checkActivityButton(institute_id).subscribe(
        data => this.showActivityButton(data)
      );
    }
  }
  showActivityButton(data){
    this.showStatus=data['status'];  
  }

  onEditClick(){
    if (confirm("You can edit the details in Add Teaching/Non-teaching Members menu under My Council Tab. To change the coordinator, Use delete option")) {
     this.router.navigateByUrl('/institute/my-council');
    }
  }

  onDeleteClick(){
       if (confirm("Are you sure you want to delete?")) {
      this.activityService.deleteMentorMentee(this.id).subscribe(
        data => this.handleResponse1(data)
      );  
      }
  }
  handleResponse1(data){
if(data['status']=='1'){
  this.toastr.success(data['msg']);
  this.getMentorStatus();
  this.getMentorDetails();
}else{
  this.toastr.error(data['msg'])
}
}


}
