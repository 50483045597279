import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { InstituteSubscriptionComponent } from '../institute-subscription/institute-subscription.component'
import { Subscription } from 'rxjs';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { InstituteIncubationService } from 'src/app/Services/institute-incubation.service';
import { FileService } from 'src/app/Services/file.service';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as myGlobals from '../../../../../app.component';

@Component({
  selector: 'app-institute-pre-incubation-centers',
  templateUrl: './institute-pre-incubation-centers.component.html',
  styleUrls: ['./institute-pre-incubation-centers.component.css'],
})
export class InstitutePreIncubationCentersComponent implements OnInit {

  preIncubationCenterForm: FormGroup;
  public edfChecked: boolean = false;
  pre_incubation_center: any[];
  pre_incubation_value:any;
  messages: any[] = [];
  subscription: Subscription;
  fundingModel: [];
  serviceModel: [];
  facilitiesModel: [];

  type_services_offered: any[];
  facility: any[];
  facilities_services: any[];
  facilities_services_multi: any[];
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredThrustAreas: Observable<string[]>;
  thrustAreas: string[] = [];
  thrustAreasList: string[] = [];


// Change
  showCIN1:boolean;
  showregistered:boolean =true;
  registeredValue:any;
  @ViewChild('thrustAreaInput') thrustAreaInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  filterThrustAreaData;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    public instituteIncubationService: InstituteIncubationService,
    private messageService: InstituteSubscriptionComponent) {
  }

  ngOnInit(): void {
    this.preIncubationCenterForm = this.fb.group({
      center_name: [null, Validators.required],
      pre_incubation_center: [null, Validators.required],
      incharge_name: [null],
      incharge_email: [null,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      incharge_phone: [null],
      incharge_url: [null],
      thrust_areas: [null],
      type_services_offered: [null, Validators.required],
      facilities_services: [null, Validators.required],
    });
    
    this.filteredThrustAreas = this.preIncubationCenterForm
      .get('thrust_areas').valueChanges.pipe(
        startWith(null),
        map((fruit: string | null) => fruit ? this._filter(fruit) : this.thrustAreasList.slice()));

    // for auto populating data
    this.subscription = this.messageService.onMessage().subscribe(message => {
      // this.preIncubationCenterForm.reset();

      if (message) {
        // window.location.reload();

          this.facility = [];

          if (message.text['facilities_services'] != null) {
            for (let i = 0; i < message.text['facilities_services'].length; i++) {
              this.facility.push(message.text['facilities_services'][i]['id']);
            }
          }
          this.thrustAreas = message.text.thrust_area.split(",");
          this.preIncubationCenterForm.reset();
          this.preIncubationCenterForm.get('center_name').setValue(message.text.name);
          this.preIncubationCenterForm.get('pre_incubation_center').setValue(message.text.ipr_tto_center);
          this.preIncubationCenterForm.get('incharge_name').setValue(message.text.incharge_name);
          this.preIncubationCenterForm.get('incharge_email').setValue(message.text.incharge_email);
          this.preIncubationCenterForm.get('incharge_phone').setValue(message.text.incharge_phone);
          this.preIncubationCenterForm.get('incharge_url').setValue(message.text.incharge_url);
          this.preIncubationCenterForm.get('thrust_areas').setValue(this.thrustAreas);
          this.preIncubationCenterForm.get('type_services_offered').setValue(message.text.type_services_offered);
          this.preIncubationCenterForm.get('facilities_services').setValue(this.facility);
      }
    });

    // get drop down values
    this.instituteIncubationService.getDropdownListPreIncubation().subscribe(
      data => this.setDropdown1(data)
    );

    this.instituteIncubationService.getDropdownList().subscribe(
      data => this.setDropdown(data)
    );
  }

  
  setFileFlags() {
  }

  setDropdown(data) {
    this.type_services_offered = [];
    for (let i = 0; i < data['typeServicesOffered'].length; i++) {
      this.type_services_offered.push(data['typeServicesOffered'][i]['name']);
    }
    this.facilities_services = [];
    for (let i = 0; i < data['facilitiesServices'].length; i++) {
      this.facilities_services.push(data['facilitiesServices'][i]);
    }
    this.facilities_services_multi = [];
    for (let i = 0; i < data['facilitiesServices'].length; i++) {
      this.facilities_services_multi.push(data['facilitiesServices'][i]['id']);
    }
  }


  setDropdown1(data) {
    this.pre_incubation_center = [];
    for (let i = 0; i < data['pre_incubation_center'].length; i++) {
      this.pre_incubation_center.push(data['pre_incubation_center'][i]['name']);
    }
  }


  onSubmit(values) {
    values['thrust_areas'] = this.thrustAreas;
    
    this.pre_incubation_value=this.preIncubationCenterForm.get('pre_incubation_center').value;

    var formData = new FormData();
    formData.append('center_name', values['center_name']);
    formData.append('pre_incubation_center', values['pre_incubation_center']);
    formData.append('funding_schemes_support', values['funding_schemes_support']);
    formData.append('thrust_areas', values['thrust_areas']);
    formData.append('type_services_offered', values['type_services_offered']);
    formData.append('facilities_services', values['facilities_services']);
    formData.append('incharge_name', values['incharge_name']);
    formData.append('incharge_email', values['incharge_email']);
    formData.append('incharge_phone', values['incharge_phone']);
    formData.append('incharge_url', values['incharge_url']);

    this.instituteIncubationService.submitPreIncubationData(values).subscribe((data) => {
      if (data['message'] == 'Success') {
        this.toastr.success('Submitted Successfully!');
      } else {
        this.toastr.error('Some error occurred! Please check the data you have entered.');
      }
      this.messageService.sendMessage('Update');
    });
    // if (this.preIncubationCenterForm.valid) {
      
    // }
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.thrustAreas.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.preIncubationCenterForm
      .get('thrust_areas').setValue(this.thrustAreas);

  }

  remove(fruit: string): void {
    const index = this.thrustAreas.indexOf(fruit);

    if (index >= 0) {
      this.thrustAreas.splice(index, 1);
    }

    this.preIncubationCenterForm
      .get('thrust_areas').setValue(this.thrustAreas);

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.thrustAreas.push(event.option.viewValue);
    this.thrustAreaInput.nativeElement.value = '';
    this.preIncubationCenterForm
      .get('thrust_areas').setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.thrustAreasList.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

  getThrustAreasList() {
    this.thrustAreasList = [];
    this.instituteIncubationService.getThrustAreasList(this.filterThrustAreaData).subscribe(
      (data: any[]) => {
        data.forEach(element => {
          this.thrustAreasList.push(element.name);
        });
      }
    );
  }
}
