<div class="container row">
    <div class="col-12" style="text-align: center">
        <h3>Are you sure you want to delete ?</h3>
    </div>

    <div class="col-6" style="text-align: end">
        <button type="button" class="btn btn-danger" (click)="deleteDialog()">Yes</button>
    </div>
    <div class="col-6">
        <button type="button" class="btn btn-success" mat-dialog-close>No</button>
    </div>
<!-- 
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close>No</button>
        <button mat-button (click)="deleteDialog()">Yes</button>
    </mat-dialog-actions> -->


</div>