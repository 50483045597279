import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { Iic4ODialogComponent } from '../institute-dashboard2021/iic4o-dialog/iic4o-dialog.component';
import { Iic4ODialogComponent2 } from '../institute-dashboard2021/iic4o-dialog2/iic4o-dialog2.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-institute-dashboard2022',
  templateUrl: './institute-dashboard2022.component.html',
  styleUrls: ['./institute-dashboard2022.component.css']
})
export class InstituteDashboard2022Component implements OnInit {
  IicStatus4o:any;
  IicInsType:any;
  constructor(private dashboardService: InstituteDashboardService, public dialog: MatDialog) { }

  ngOnInit(): void {
    // this.getUserIic4oStatus();
  }

  getUserIic4oStatus() {
    this.dashboardService.getUserIic4oStatus().subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.IicStatus4o = data['data']['IicStatus4o'];
    this.IicInsType = data['data']['IicInsType'];
    if(this.IicStatus4o==0)
    {
      const dialogRef = this.dialog.open(Iic4ODialogComponent, {});
      dialogRef.afterClosed().subscribe(data => this.handleResponseopen(data));
    }
    else
    {
      if(this.IicInsType==0)
      {
        const dialogRef = this.dialog.open(Iic4ODialogComponent2, {});
        dialogRef.afterClosed().subscribe((result) => {
        });
      }

    }

  }

  handleResponseopen(data)
  {
    if(this.IicInsType==0)
      {
        const dialogRef = this.dialog.open(Iic4ODialogComponent2, {});
        dialogRef.afterClosed().subscribe((result) => {
        });
      }
  }

}
