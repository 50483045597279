import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IicOnlineSessionsService } from 'src/app/Services/iic-online-sessions.service';
import * as myGlobals from '../../../app.component';

@Component({
  selector: 'app-iic-online-sessions-details',
  templateUrl: './iic-online-sessions-details.component.html',
  styleUrls: ['./iic-online-sessions-details.component.css']
})
export class IicOnlineSessionsDetailsComponent implements OnInit {
 baseImageUrl = myGlobals.baseImageUrl;
  id;
  speakers = [];
  speakerCount = 0;
  sessionKeys = [];
  sessionData;
  iicOnlineSessions = [];
  constructor(private route: ActivatedRoute,private iicOnlineSessionsService: IicOnlineSessionsService) { 
  this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.iicOnlineSessionsService
        .getIICOnlineSessionData(this.id)
        .subscribe((data) => this.handleResponse(data));
    });
  }

  ngOnInit(): void {
    
  }

  handleResponse(data) {

    this.sessionData = data['online_session_data']['0'];
    this.speakers = data['online_session_speaker'];
    this.speakerCount = data['speakerCount'];
    this.sessionKeys = data['online_session_keys'];
  }

}
