<div class="container-fluid">
    <div style="text-align: center;">
        <h1>e-Learning Resources</h1>
    </div>
    
  
  <mat-tab-group mat-align-tabs="center" class="">
      <mat-tab>
        <ng-template mat-tab-label>
            MIC Sessions
        </ng-template>
        <div class="col-sm-12">
            <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;"> e-Learning Material by MoE's Innovaction Cell  </h2>
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col"> Session Title</th>
                        <th scope="col"> Speaker</th>
                        <th scope="col"> Video</th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let nomination of nominationArray; index as i">
                        <td>{{i+1}}</td>
                        <td>{{nomination?.video_title}}</td>
                        <td><p *ngFor="let speaklists of nomination.speakers; index as i;"><strong>{{speaklists?.speaker_name}}</strong><br />{{speaklists?.speaker_desig_1}} {{speaklists?.speaker_design_2}}</p></td>
                        <td class="downloadD" *ngIf="nomination?.video_id!='0'"><a href="{{nomination?.video_link_youtube}}"  class="btn btn-success btnDownSuccess" target="_blank">Watch</a>
                                </td>
                                <td class="downloadD" *ngIf="nomination?.video_id=='0'">-</td>
                    </tr>
                    <tr *ngIf="nominationArray?.length <= 0" col="4">
                        <h3 style="text-align: center">No Data Found</h3>
                    </tr>
                </tbody>
            </table>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
            Session by IIC Institutes
        </ng-template>
  
  
        <p>Updated Soon!</p>
        <!-- <app-repository></app-repository> -->
      </mat-tab>
    </mat-tab-group>
</div>