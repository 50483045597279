<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;padding: 10px 0;color:#fff;border-radius: 4px; ">Constituent - IICs in University-IIC</h2>
    </div>

	<!-- <div class="card">
        <div class="card-title"><p>Sub IIC</p></div>
	</div> -->
<div class="refrredBox">
 <div class="row rws-listpage">
	<div class="col-md-12">
  <p>
    <a class="btn btn-primary referralBut" id="createSub" routerLink='/institute/subiic-add/add'>
      Create a Constituent-IIC
    </a>
    <a class="btn btn-primary referralBut" id="createSub1" routerLink='/institute/subiic-existing/existing'>      Merge an Existing IIC
    </a>
  </p>
  <div class="tableBody">
				<div class="table-responsive">

                    <table class="table table-hover">
                        <thead class="facultyTable">
                            <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">Institute Name</th>
                                <th scope="col">Aishe Code</th>
                                <th scope="col">City</th>
                                <th scope="col">State</th>
                                <th scope="col">Status of Acceptance</th>
                                <th scope="col">IIC Registration Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                    
                        <tbody>
                            <tr *ngFor="let nomination of iicrepository; index as i" >
                                <td>{{i+1}}</td>
                                <td *ngIf="nomination?.email_verification_token=='Verified'">
                                <a style="color:#2d3091;" href ="/institute/institute-mm-dashboard/{{nomination?.userId}}" target="_blank">{{nomination?.instituteName}}</a>
                                </td>
                                <td *ngIf="nomination?.email_verification_token!='Verified'">
                                  {{nomination?.instituteName}}
                                </td>
                                <td>{{nomination?.aishe_code}}</td>
                                <td>{{nomination?.instituteCity}}</td>
                                <td>{{nomination?.stateName}}</td>
                                <td *ngIf="nomination?.email_verification_token!='Verified'">
                                  Pending
                                </td>
                                <td *ngIf="nomination?.email_verification_token=='Verified'" >
                                  Accepted
                                </td>
                                <td>
                                  <span *ngIf="nomination?.applicationStatus=='New'">Registration Initiated</span>
                                  <span *ngIf="nomination?.applicationStatus=='Reopened'">In Process</span>
                                  <span *ngIf="nomination?.applicationStatus=='Disapproved'">Disapproved</span>
                                  <span *ngIf="nomination?.applicationStatus=='Approved'">Stablished IIC</span>
                                </td>
                    
                                <td class="actionButtonIcon">
                                  <div *ngIf="nomination?.applicationStatus=='New' && nomination?.email_verification_token!='Verified'">
                                    <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')" class="btnEditIcon" data-toggle="tooltip" data-placement="top" title="Edit SubIIC">
                                      <mat-icon>edit</mat-icon>
                                      </button>
                                  </div>
                    
                                  <div *ngIf="nomination?.applicationStatus=='New' && nomination?.email_verification_token!='Verified'">
                                    <button (click)="onClickEvent(nomination?.id, 'Email')" class="btnEmailIcon" data-toggle="tooltip" data-placement="bottom" title="Send Mail to SubIIC">
                                      <mat-icon>email</mat-icon> 
                                      </button>
                                  </div>
                    
                                  <div *ngIf="nomination?.applicationStatus=='New' && nomination?.email_verification_token!='Verified'">
                                    <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')" class="btnDeleteIcon" data-toggle="tooltip" data-placement="up" title="Delete SubIIC">
                                      <mat-icon>delete</mat-icon>
                                      </button>
                                  </div>
                                </td>
                            </tr>
                            <tr *ngIf="iicrepository?.length== 0" col="7">
                                <h3 style="text-align: center">No Data Found</h3>
                            </tr>
                        </tbody>
                    </table>
  
</div>
</div>



 </div>
</div>
</div>
</div>
