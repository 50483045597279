<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">IIC Online Session</h1>							
		</div>
	</div>
</div>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>IIC Online Session</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Webinar</li>
  </ol>
</nav>


<section class="paddsection">
    <div class="container">
		<div class="vc_row innovationAmbassadorBox">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2>MoE's Innovation Cell - Online Session</h2>
				</div>
			</div>
		</div>
    </div>
</section>

<section id="content-wrapper-1">
    <div id="head-text" class="container">
        <div class="row">
            <div class="col-md-8">
                <div id="head-content">
                    <div id="heading-text">
                        <h1>Interaction with Student Innovators and Entrepreneurs Emerged from Smart India Hackathon (SIH)</h1>
                    </div>
                    <div id="breif-text" class="breif-text">
                        <p>MIC Driven Activity</p>
                    </div>
                    <div id="author">
                        <span id="sp-1">Speaker</span>
                        <span id="sp-2">
                          <span *ngFor="let speaker of speakers">{{' ' + speaker?.speaker_name + ' |'}}</span>
                        <span style="color:#fff;">{{sessionData?.video_theme}}</span>
                        </span>
                    </div>
                    <div id="lang-text">
                        <!-- <p><i class="glyphicon glyphicon-comment"></i>English<span id="sp-3">Last updated 4/2020</span></p> -->
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div id="viedo-section" class="img-thumbnail no-fix">
                    <div id="video-thumb">

                        <a href="https://youtu.be/wGNf7NkfO3I" target="_blank"><img src="assets/assets/images/thumbnail/thumb20.jpg" class="img-thumbnail imgThumbOnlineSeassion"></a>

                    </div>
                    <div id="vid-dur">
                        <p class="onlineSeassionHeading"><a href="https://youtu.be/wGNf7NkfO3I" target="_blank"><span class="join">Join e-Session through YouTube</span></a></p>
                    </div>
                    <div id="vid-dtls">
                        <p>This Video Session Includes</p>
                        <p><i class="fa fa-video-camera" aria-hidden="true" style="margin-right:5%"></i>{{sessionData?.video_duration}}</p>
                        <p><i class="fa fa-users" style="margin-right:5%"></i>{{speakerCount}} Speakers</p>
                        <p><a href="{{baseImageUrl + '/' + sessionData?.resource}}" target="_blank"><i class="fa fa-download" aria-hidden="true" style="margin-right:5%"></i>
1 Learning Material</a></p>

                        <p><a href="{{sessionData?.assisment}}" target="_blank"><i class="fa fa-comments" style="margin-right:5%"></i>E-Assessment &amp; Feedback</a></p>

                        <!-- <p><i class="fas fa-infinity" style="margin-right:4%"></i>Full lifetime access</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="content-wrapper-4">
    <div id="content-text">
        <div class="container">
            <div class="row">
                <div class="col-md-8" style="padding:0;">
                    <h3 class="onlineSeassionHeading">Learning Objectives</h3>
                    <div id="body-content">
                        <div id="learn-section">
                            <div id="learn-content" class="col-md-12">
                                <div class="col-md-12 learn-left" *ngFor="let item of sessionKeys">
                                    <p><i class="fa fa-check-circle" aria-hidden="true"></i>{{item?.key_points}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8" style="padding:0;margin-top:3%;">
                    <h3 class="onlineSeassionHeading">Speaker Profile</h3>
                    <div class="pro-dtls" *ngFor="let speaker of speakers">
                        <div class="pro-head">
                            <div class="col-md-3 spe-pic">
                                <img src="{{baseImageUrl + '/' + speaker?.speaker_profile_pic}}" alt="Speaker Profile Picture" class="img-circle">
                            </div>
                            <div id="" class="col-md-9 spe-dtls">
                                <p>{{speaker?.speaker_name}}<br>{{speaker?.speaker_desig_1}}<br></p>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="pro-body">
                            <p [innerHTML]="speaker?.speaker_profile_breif"></p>
                            <p class="btn seePro_more"><a href="{{speaker?.speaker_profile_link}}" target="_blank" *ngIf="speaker?.speaker_profile_link">See More</a></p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>