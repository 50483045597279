import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IicInnovationAmbassadorService } from 'src/app/Services/iic-innovation-ambassador.service';

@Component({
  selector: 'app-iic-contact-us',
  templateUrl: './iic-contact-us.component.html',
  styleUrls: ['./iic-contact-us.component.css']
})
export class IicContactUsComponent implements OnInit {

  public mailForm:FormGroup;
  submitted=false;
  id:any;
  catModel: any;
  ia_id:any;

  constructor(private iicAS: IicInnovationAmbassadorService, private toastr: ToastrService, private fb: FormBuilder, public dialogRef: MatDialogRef<IicContactUsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ia_id=data;
  }

  ngOnInit(): void {
    this.mailForm = this.fb.group({
      name: [null,Validators.required],
      category: [null,Validators.required],
      // email: [null,Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: [null,Validators.required]
    });

    // alert(this.ia_id);

  }

  onSubmit(data) {
    data['ia_id']=this.ia_id;
    this.iicAS.sendEmailReminder(data).subscribe((data) => this.handleResponse(data));
    }

    handleResponse(data)
    {
      if (data['status'] == "1") {
        this.toastr.success(data['msg']);
        this.dialogRef.close();
      } else {
        this.toastr.error(data['msg']);
      }
    }


}
