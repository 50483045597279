import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstitutePrototypeSubmissionDialog21Component } from 'src/app/components/users/institute/institute-yic2021/institute-prototype-submission/institute-prototype-submission-dialog/institute-prototype-submission-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { InstituteEvaluationDetailsComponent } from 'src/app/components/users/institute/institute-yic2021/institute-evaluation-details/institute-evaluation-details.component';

@Component({
  selector: 'app-yukti-rm-nomination',
  templateUrl: './yukti-rm-nomination.component.html',
  styleUrls: ['./yukti-rm-nomination.component.css']
})
export class YuktiRmNominationComponent implements OnInit {

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  prototypeSubmission = [];
  filterModel = "All";
  counts: any;
  frontCurrentPageNo: number = 0;
  rid: number = 3;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  cities : any;
  public innovationNomantion : FormGroup;

  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private innovationService: InstituteInnovationContestService) { }

  ngOnInit(): void {
    this.getPrototypeSubmission();
    this.getPrototypeCount();
    this.getRMCity();
    this.innovationNomantion = this.fb.group({
      challenge_id: [null],
      innovation_id: [null],
      city: [null],
      is_demo: [null],
      no_of_participant: [null],
    });
  }
  getRMCity(){
  this.innovationService.getRMCity().subscribe(
    data => this.cities = data
  )
}
getPrototypeCount(){
  this.innovationService.getStatusCounts21('prototype').subscribe(
    data => this.counts = data['prototype']['0']
  )
}
  getPrototypeSubmission() {
    this.innovationService.getPrototypeSubmission21(this.currentPageNo,this.per_page, 'Innovation23').subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.prototypeSubmission = [];

    data['data'].forEach((element) => {
      this.prototypeSubmission.push(element);
    });

this.total_pages = data.meta.pagination.total;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1);
	  } else {
		this.currentPageNo = (e.pageIndex+1);
	  }
	this.per_page = e.pageSize;
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.changeFilter();
    }
  }

  previousPage() {
    this.currentPageNo--;
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.changeFilter();
    }
  }


  openDialog(data) {
    const dialogRef = this.dialog.open(InstitutePrototypeSubmissionDialog21Component, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {

      this.getPrototypeSubmission();
      this.getPrototypeCount();
    });
  }

  openEvaluationViewDialog(data) {
    const dialogRef = this.dialog.open(InstituteEvaluationDetailsComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  changeFilter() {
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.innovationService.getPrototypeSubmissionFilter21(this.currentPageNo, this.filterModel).subscribe(
        data => this.handleResponse(data)
      );
    }

  }

  openFileToDownload(id)
  {
    window.open('https://api.mic.gov.in/api/DownloadInnovationDetails?proto_id='+id);
    // this.innovationService.getYuktiInnovationData(id).subscribe(
    //   data => this.downloadFile(data, 'YuktiInnovationData.pdf')
    // );
  }

  openEvaluationDialog(id)
  {
    window.open('https://api.mic.gov.in/api/DownloadInnovationDetails?proto_id='+id);
    // this.innovationService.getYuktiInnovationData(id).subscribe(
    //   data => this.downloadFile(data, 'YuktiInnovationData.pdf')
    // );
  }

  DeleteIdeaFromVerification(data) {
    this.innovationService.getDeleteIdeaFromVerification(data).subscribe(
      data => this.handleResponseDelete(data)
    );
  }

  handleResponseDelete(data) {

    if(data["status"]==1)
    {
      this.toastr.error(data["msg"]);
    }
    else
    {
      this.toastr.error(data["msg"]);
    }

  }

  nominateChallenge(id,challenge_id){
    // onSubmit(data){
    // var arr = {
    //   'challenge_id' : this.rid,
    //   'innovation_id' : id,
    // }
    if(this.innovationNomantion.get('city').value ){
    }else{
      this.toastr.error("Please choose Regional Meet City.");
      return false;
    }
    if(this.innovationNomantion.get('is_demo').value ){
    }else{
      this.toastr.error("Please choose Willing to Exhibit.");
      return false;
    }
    if(this.innovationNomantion.get('no_of_participant').value ){
    }else{
      this.toastr.error("Please choose Number of Participant.");
      return false;
    }
    var formData = new FormData();

    formData.append('challenge_id', challenge_id);
    formData.append('innovation_id', id);
    formData.append('city', this.innovationNomantion.get('city').value);
    formData.append('is_demo', this.innovationNomantion.get('is_demo').value);
    formData.append('no_of_participant', this.innovationNomantion.get('no_of_participant').value);
    this.innovationService.challengeRegionalMeetNominate(formData).subscribe(
      data => this.handleResponse12(data,id));
    
  }
  handleResponse12(data,innovation_id){
    if(data['message'] == 'Success'){
      document.getElementById('nominateSuccessForm-'+innovation_id).style.display = 'none';
      document.getElementById('nominateSuccess-'+innovation_id).style.display = 'none';
      document.getElementById('nominateRemove-'+innovation_id).style.display = 'block';
    
      this.toastr.success('Nominate Successfully');
    }else{
      this.toastr.success(data['data']);
    }
  }

  RemoveChallenge(id){
    if(confirm('Are You Sure Want To Remove Challenge Nomination') == true){
      var arr = {
        'challenge_id' : 3,
        'innovation_id' : id,
      }
      this.innovationService.challengeRegionalMeetNominateRemove(arr).subscribe(
        data => this.handleResponse1(data,id));
    }
  }

  handleResponse1(data,innovation_id){
    if(data['message'] == 'success'){
      document.getElementById('nominateSuccessForm-'+innovation_id).style.display = 'block';
      document.getElementById('nominateSuccess-'+innovation_id).style.display = 'block';
      document.getElementById('nominateRemove-'+innovation_id).style.display = 'none';
      this.toastr.success(data['data']);
    }else{
      this.toastr.error(data['data']);
    }
  }
}
