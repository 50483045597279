<mat-dialog-content class="mat-typography">
    <div class="container">
        <h3 class="ccHeadingMForAllDolouge">POC Submission</h3>
        <div class="row">
            <div class="col-md-12">
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Title</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.idea_title }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Description</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.description }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Institute Name</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.instituteName }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Lead Name</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.team_lead_name }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">POC Score</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.poc_score }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">POC Comment</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.comment }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">POC Status</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.poc_status }}</p>
                    </div>
                </div>
                <form [formGroup]="pocForm" (ngSubmit)="onSubmit(pocForm.value)">
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                                <mat-label>POC Score</mat-label>
                                <input matInput type="number" maxlength="4" formControlName="score"
                                    placeholder="POC Score" />
                                <!-- <mat-error *ngIf="pocForm.controls['name'].hasError('required')">Faculty Name is
                                                required</mat-error> -->
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                                <mat-label>Comment</mat-label>
                                <input matInput formControlName="comment" placeholder="Comment" />
                                <!-- <mat-error *ngIf="pocForm.controls['name'].hasError('required')">Faculty Name is
                                                        required</mat-error> -->
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="status">
                                    <mat-option value="Approved">Approved</mat-option>
                                    <mat-option value="Disapproved">Disapproved</mat-option>
                                    <mat-option value="Pending">Pending</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div mat-dialog-actions align="end" class="mt-3">
                        <button type="button" mat-raised-button color="warn" (click)="onNoClick()" class="btn btn-danger instituteBtnClose">
                            Close
                        </button>
                        <button type="submit" mat-raised-button color="primary" class="btn btn-success float-right"
                            [disabled]="pocForm.invalid">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</mat-dialog-content>