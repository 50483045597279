import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-activity-mentee-dialog',
  templateUrl: './activity-mentee-dialog.component.html',
  styleUrls: ['./activity-mentee-dialog.component.css']
})
export class ActivityMenteeDialogComponent implements OnInit {
  public iicImpactQuestions: FormGroup;

  activityType:any;
  activityId:any;


  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.activityId = "add";
    this.activityType = "add";
    this.route.params.subscribe((params) => {
      this.activityId = params.activityId;
      this.activityType = params.activityType;
    });



  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'participation_status': [null, Validators.required],
      'remark': [null, Validators.required],
      'learning': [null, Validators.required],
      'number_of_teachers_participated': [null, Validators.required],
      'number_of_students_participated': [null, Validators.required],
      'number_of_external_members_participated': [null, Validators.required]
    });

    this.instituteActivityService.atlActivityDetail(this.activityId).subscribe(
      (data) => this.handleResponse(data)
    );
  }



  handleResponse(data) {
    this.iicImpactQuestions.patchValue({
      participation_status: data['participation_status'],
      remark: data['remark'],
      learning: data['learning'],
      number_of_teachers_participated: data['number_of_teachers_participated'],
      number_of_students_participated: data['number_of_students_participated'],
      number_of_external_members_participated: data['number_of_external_members_participated'],
    });

  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('activity', this.activityType);
    formData.append('activity_id', this.activityId);
    formData.append('participation_status', this.iicImpactQuestions.get('participation_status').value);
    formData.append('remark', this.iicImpactQuestions.get('remark').value);
    formData.append('learning', this.iicImpactQuestions.get('learning').value);
    formData.append('number_of_teachers_participated', this.iicImpactQuestions.get('number_of_teachers_participated').value);
    formData.append('number_of_students_participated', this.iicImpactQuestions.get('number_of_students_participated').value);
    formData.append('number_of_external_members_participated', this.iicImpactQuestions.get('number_of_external_members_participated').value);

    this.instituteActivityService.submitAtlActivityRepository(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      window.location.href='/institute/atlsic-dashboard';
      this.toastr.success(data['message']);
    }
    else{
      this.toastr.error(data['message']);

    }
  }


}
