<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Add new e-Learning Sessions</h2>
    </div>

	<div class="card">
        <div class="card-title"><p>Details of e-Learning Sessions Organized</p></div>
	</div>

    <form class="instiBankForm" [formGroup]="mentorshipForm" (ngSubmit)="onSubmit(mentorshipForm.value)">

    </form>
</div>
