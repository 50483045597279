import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as myGlobals from '../app.component';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private baseUrl = myGlobals.baseUrl;
  extension: string;
  realFileName: any;
  inputField: any;
  temp_file_real_name: any;
  temp_file_name: string;

  //  application number
  application_number: string;
  temp_file: boolean;

  fileUploaded: boolean;

  constructor(private toastr: ToastrService, private http: HttpClient) { }
  headers = new HttpHeaders()
  // .set('Content-Type', 'undefined')
  .set('Authorization', 'Bearer ' + localStorage.getItem('token'));

options = { headers: this.headers };

  checkIfPdf(file) {
    if (file['name'].split('.').pop() != 'pdf' && file['name'].split('.').pop() != 'PDF') {

      return false;
    }

    return true;
  }

  checkIfImage(file) {
    let fileType = file['name'].split('.').pop();
    if (fileType != 'jpg' && fileType != 'jpeg' && fileType != 'png' && fileType != 'JPG' && fileType != 'JPEG' && fileType != 'PNG') {
      return false;
    }

    return true;
  }
  uploadFile(event, fileType) {


    let elem = event.target;

    var myFile = elem.files[0];


    this.extension = this.checkFileExtension(elem.files[0].type, event.target.id);

    if (!this.extension) {
      this.toastr.error('Invalid extension.');
      // this.messageService.sendMessage('Error');
      return;
    }

    let flag;

    flag = this.validateFile(event);
    if (flag == false) {
      this.toastr.error('Invalid File!');
    }

    if (!flag) {
      this.toastr.error('Invalid file size.');
    } else {

      this.realFileName = myFile.name;

      this.inputField = event.target.id;

      this.temp_file_real_name = this.realFileName;
      this.temp_file_name = this.application_number + '_' + this.inputField + '_' + '.' + this.extension;
      this.temp_file = true;

      if (this.extension != null) {

        if (elem.files.length > 0) {
          var formData = new FormData();

          formData.append('myfile', elem.files[0]);
          formData.append('inputField', this.inputField);
          formData.append('type', this.extension);

          return this.http.post(`${this.baseUrl}/uploadFile`, formData, { headers: this.headers });
        }
      } else {
        this.toastr.error('Invalid file format.');
      }

      elem.value = "";
    }


  }

  checkFileExtension(type, fileName) {

    var fileNames = ['incubation_file'];
    if (fileNames.includes(fileName)) {
      if (type == 'application/pdf') {
        return 'pdf';
      } else {
        return null;
      }
    }

    return null;
  }


  logout() {
    throw new Error("Method not implemented.");
  }



  saveFile(data) {
    return this.http.post(`${this.baseUrl}/uploadFile`, data);
  }


  updateFileFlags(data) {
    return this.http.post(`${this.baseUrl}/getFileFlags`, data);
  }

  deleteFile(data) {
    return this.http.post(`${this.baseUrl}/deleteFile`, data, this.options);
  }

  getFile(file, token): Observable<Blob> {

    return this.http.post<Blob>(`${this.baseUrl}/getFile?token=` + token,
      file, { headers: this.headers, responseType: 'blob' as 'json' });
  }

  getFileExtension(file, token) {
    return this.http.post(`${this.baseUrl}/getFileExtension?token=` + token, file);
  }

  exportAsExcel(token) {
    return this.http.get(`${this.baseUrl}/exportAsExcel?token=` + token);
  }


  validateFile(event): Boolean {
    let elem = event.target;

    // check file size
    if (elem.files[0].size > (1024 * 1024 * 2)) {
      this.toastr.error('File size should be less than 2Mb.');
      return false;
    }

    return true;
  }


}
