import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InstituteFacultyDialogComponent } from './institute-faculty-dialog/institute-faculty-dialog.component';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { InstitueFaculty } from './institute-faculty';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-institute-faculty',
  templateUrl: './institute-faculty.component.html',
  styleUrls: ['./institute-faculty.component.css'],
})
export class InstituteFacultyComponent implements OnInit {
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  faculties: InstitueFaculty[] = [];
  readMore: boolean = true;
  filterData;
  total_pages: any;
  president_detail: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;

  facultylistroles:any;

  facultyStatus: any;
  facultyRoles: any;
  ForWhom:any;
  constructor(
    public dialog: MatDialog,
    private instituteMyCouncilService: InstituteMyCouncilService
  ) { }

  ngOnInit(): void {
    this.getFaculties();
    this.ForWhom=localStorage.getItem('ForWhom');
    this.facultyStatus = localStorage.getItem('FacultyRolesStatus');
    this.facultyRoles = localStorage.getItem('FacultyRolesIncomplete');
    // alert(this.facultyStatus + ' >> this data');
    this.getFacultiesRoles();

    this.getMenteeInsData();

  }

  getMenteeInsData() {
    this.instituteMyCouncilService
      .getFacultiesRoles().subscribe((data) => this.handleResponseFRoles(data));
  }

  getFacultiesRoles() {
    this.instituteMyCouncilService
      .getFacultiesRoles().subscribe((data) => this.handleResponseFRoles(data));
  }

  handleResponseFRoles(data)
  {
    this.facultylistroles = data['data'];
  }

  nextPage() {
    this.currentPageNo++;
    this.getFaculties();
  }

  previousPage() {
    this.currentPageNo--;
    this.getFaculties();
  }

  search(event) {
    if (this.filterData) {
      this.instituteMyCouncilService.searchFaculty(this.filterData).subscribe(
        data => this.handleResponse(data)
      );
    } else {
      this.getFaculties();
    }
  }

  getFaculties() {
    this.instituteMyCouncilService
      .getFaculties(this.per_page,this.currentPageNo)
      .subscribe((data) => this.handleResponse(data));
  }
  nextPage1(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1);
	  } else {
		this.currentPageNo = (e.pageIndex+1);
	  }
	this.per_page = e.pageSize;
    this.getFaculties();
  }

  handleResponse(data) {
    this.faculties = [];

    data['data'].forEach((element) => {
      this.faculties.push(element);
    });
    this.total_pages = data.meta.pagination.total;
    this.president_detail = data.president_detail;

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteFacultyDialogComponent, {
      data: null
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getFaculties();
    });
  }

  openEditDialog(data) {
    const dialogRef = this.dialog.open(InstituteFacultyDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getFaculties();
    });
  }
  deleteDialog(data) {
    const dialogRef = this.dialog.open(InstituteDeleteFacultyComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getFaculties();
    });
  }


  deleteInstituteDetails() {
    alert('Are you sure you want to delete Semester Details');
  }

  getInstituteDetails() {
    this.openDialog();
  }


}



@Component({
  selector: 'app-institute-delete-faculty',
  templateUrl: './institute-delete-faculty.html',
  // styleUrls: ['./institute-declare-semesters.component.css']
})

export class InstituteDeleteFacultyComponent implements OnInit {
  id: any;

  constructor(public dialog: MatDialog,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteFacultyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.id = this.data['id'];
    // this.getDeclareSemesters(this.currentPageNo);
  }

  deleteDialog() {
    this.myCouncilService.deleteFaculty(this.id).subscribe(
      data => this.handleData(data)
    );
  }

  handleData(data) {
    if (data['status'] = "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Not able to delete !');
    }
    this.myCouncilService.dataUpdated.next(true);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}

