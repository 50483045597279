import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ExcelServiceService } from '../../../../../Services/excel-service.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-export-file',
  templateUrl: './export-file.component.html',
  styleUrls: ['./export-file.component.css']
})
export class ExportFileComponent implements OnInit {

  flag: boolean = true;
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  currentPageNo: number = 1;


  data
  = [
    {

      "CATEGORYID": 1,
      "CATEGORYNAME": "BOOKS",
      "DESCRIPTION": "It contains all types of books",
      "IMAGE": "Books",
      "STATUS": "TRUE"
    },
    {

      "CATEGORYID": 2,
      "CATEGORYNAME": "EBOOKS",
      "DESCRIPTION": "It contains all types of ebooks",
      "IMAGE": "Ebooks",
      "STATUS": "TRUE"
    },
    {

      "CATEGORYID": 3,
      "CATEGORYNAME": "Bookss",
      "DESCRIPTION": "DESCRIPTION",
      "IMAGE": "IMAGE",
      "STATUS": "TRUE"
    }
  ]
  faculties: any[];
  firstPage: boolean;
  lastPage: boolean;
  customers: any;
  Company: any[];

  constructor(private excelService: ExcelServiceService,
    private instituteMyCouncilService: InstituteMyCouncilService
  ) { }

  ngOnInit(): void {
    // this.getFaculties();
    this.getActivity();

  }

  getActivity(){
    

    this.instituteMyCouncilService
    .getAllActivity()
    .subscribe((data) => this.handleResponse1(data));
  }
  // getFaculties() {
  //   this.instituteMyCouncilService
  //     .getFaculties(this.per_page, this.currentPageNo)
  //     .subscribe((data) => this.handleResponse(data));
  // }


  fileName = 'ExcelSheet.xlsx';

  // exportexcel(): void {
  //   let element = this.Company;
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   XLSX.writeFile(wb, this.fileName);

  // }

  // handleResponse(data) {
  //   this.Company = [];
  //   for (let i = 0; i < data['data'].length; i++) {
  //     this.Company.push(data['data'][i])
  //   }
  // }



  handleResponse1(data) {
    this.Company = [];
    for (let i = 0; i < data.length; i++) {
      this.Company.push(data[i])
    }
  }
  // handleResponse(data) {
  //   this.faculties = [];

  //   data['data'].forEach((element) => {
  //     this.data.push(element);
  //   });

  //   this.customers = [];

  // for (let i = 0; i <= data['data'].length; i++) {
  //   this.data.push({
  //     Name: data['data'].name[i],
  //     email: data['data'].email[i]
  //   });
  // }

  // }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.Company, 'AllActivityReports');
  }


}




