import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iic-advisory-committee',
  templateUrl: './iic-advisory-committee.component.html',
  styleUrls: ['./iic-advisory-committee.component.css']
})
export class IicAdvisoryCommitteeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
