<div class="container" >
  <div class="row" color="primary">
    
    <h2 class="ss"
        style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
     Institute Dashboard</h2>
	 
	 <div class="col-md-12">
      <h4 class="instiName">{{iicD?.instituteName}}</h4>
    </div>
  </div>



<div class="row mt-20">
  <!-- <div class="col-md-4">
    <div class="mainBox">
      <a href="https://www.ariia.gov.in/" target="_blank">
        <div class="box p-5">
          <div class="instiLogo">
            <img src="{{imageBasePath}}assets/images/instituteDashboard/ariia_logo.jpg" class="img-responsive">
          </div>
          <div class="text">
            <p>Will be updated soon</p>
          </div>
        </div>
      </a>
    </div>
  </div> -->

  <div class="col-md-4">
    <div class="mainBox">
      <a href="https://sih.gov.in/" target="_blank">
        <div class="box p-5">
          <div class="instiLogo">
            <img src="{{imageBasePath}}assets/images/instituteDashboard/sih-logo.png" class="img-responsive-icon">
          </div>
          <div class="text">
            <p><strong>Participation in SIH:</strong> 202356</p>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div class="col-md-4">
    <div class="mainBox">
      <a href="https://nisp.mic.gov.in/" target="_blank">
        <!-- *ngIf="iicD?.nisp_status==1" -->
        <div class="box p-5 boxnormal boxactive" style="border: 1px solid #016004;">
          <div class="instiLogo">
            <h2>NISP</h2>
          </div>
          <div class="text">
            <p style="color: #016004;">Participating</p>
          </div>
          <div class="text">
            <p><strong>Policy Formulation:</strong> {{ (iicD?.nisp_status==1) ? 'Yes' : 'NO' }} </p>
            <p><strong>Policy Implementation:</strong> {{ (iicD?.nisp_status==1) ? 'Yes' : 'NO' }}</p>
          </div>
        </div>
		    <!-- <div class="box p-5 boxnormal" *ngIf="iicD?.nisp_status==0" style="border: 1px solid #ff0000;">
          <div class="instiLogo">
            <h2>NISP</h2>
          </div>
          <div class="text">            
            <p  style="color: #ff0000;">Not participated</p>
          </div>
        </div> -->
      </a>
    </div>
  </div>

  <div class="col-md-4">
    <div class="mainBox">
      <a href="https://kapila.mic.gov.in/index.php" target="_blank">
        <div class="box p-5">
          <div class="instiLogoKapila">
            <img src="{{imageBasePath}}assets/images/instituteDashboard/kapila-logo.png" class="img-responsive">
          </div>
          <div class="text">
              <p><strong>Total Submitted:</strong> {{ kapilaResponse?.Total }}</p>
              <p><strong>Rejected:</strong> {{ kapilaResponse?.Rejected }}</p>
              <p><strong>Need Revision:</strong>{{ kapilaResponse?.NeedRevision }} </p>
              <p><strong>Under Process:</strong> {{ kapilaResponse?.UnderProgress }}</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>


<section class="iicPerformance">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>IIC Performance</h2>
        </div>
      </div>
    </div>
    <div class="row mt-20">
	  <div class="col-md-12">
		<h2 class="titleSubHeading">My Council Status</h2>
	  </div>
      <div class="col-md-3">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5 iicPerformanceBox" [ngClass]="{'boxactive': iicD?.facultyStatus==1}">
              <div class="instiLogo">
                <img src="assets/images/instituteDashboard/icon/statusMycouncil-icon.png" class="img-responsive-icon">
                <h3>Status of My Council</h3>
              </div>
              <div class="text">
                <p *ngIf="iicD?.facultyStatus==1" style="color: #016004;">Complete</p>
                <p *ngIf="iicD?.facultyStatus==0" style="color: #ff0000;">Not Complete</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5 iicPerformanceBox">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/facultyMember-icon.png" class="img-responsive-icon">
                <h3>No. Faculty Members</h3>
              </div>
              <div class="text">
                <h4>{{iicD?.faculty_iic_council_members}}</h4>
                <!-- <p *ngIf="iicD?.facultyStatus==1">Complete</p>
                <p *ngIf="iicD?.facultyStatus==0">Not Complete</p> -->
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5 iicPerformanceBox">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/StudentMember-icon.png" class="img-responsive-icon">
                <h3>No. Student Members</h3>
              </div>
              <div class="text">
                <h4>{{iicD?.student_iic_council_members}}</h4>
                <!-- <p *ngIf="iicD?.studentStatus==1">Complete</p>
                <p *ngIf="iicD?.studentStatus==0">Not Complete</p> -->
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5 iicPerformanceBox">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/ExternalMember-icon.png" class="img-responsive-icon">
                <h3>No. of External Members</h3>
              </div>
              <div class="text">
                <h4>{{iicD?.external_members}}</h4>
                <!-- <p *ngIf="iicD?.externalMembersStatus==1">Complete</p>
                <p *ngIf="iicD?.externalMembersStatus==0">Not Complete</p> -->
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="iicPerformance">
  <div class="container">
    <!--<div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>Activity Status</h2>
        </div>
      </div> 
    </div>-->
    <div class="row mt-20">
	  <div class="col-md-12">
		<h2 class="titleSubHeading">Activity Status</h2>
	  </div>
      <div class="col-md-3">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/iic-calender-icon.png" class="img-responsive-icon">
                <h3>IIC calendar</h3>
              </div>
              <div class="text">
                <h4>{{iicD?.iic_activity}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/mic-driven-icon.png" class="img-responsive-icon">
                <h3>MIC Driven</h3>
              </div>
              <div class="text">
                <h4>{{iicD?.mic_activity}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/self-driven-icon.png" class="img-responsive-icon">
                <h3>Self - Driven</h3>
              </div>
              <div class="text">
                <h4>{{iicD?.self_activity}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/celebration-icon.png" class="img-responsive-icon">
                <h3>Celebration</h3>
              </div>
              <div class="text">
                <h4>{{iicD?.cele_activity}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="iicPerformance">
  <div class="container">
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>Participation in Schemes</h2>
        </div>
      </div>
    </div> -->
    <div class="row mt-20">
	  <div class="col-md-12">
		<h2 class="titleSubHeading">Participation Schemes</h2>
	  </div>
      <div class="col-md-6">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5 schemeParti boxnormal" [ngClass]="{'boxactive': iicD?.impactLecturesStatus==1}">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/impact-lecture-icon.png" class="img-responsive-icon">
                <h3>Impact Lecture</h3>
              </div>
              <!-- <div class="text">
                <p>Applied/Granted/Not Applied</p>
              </div> -->
              <div class="text">
                <p *ngIf="iicD?.impactLecturesStatus==1" style="color: #016004;">Applied</p>
                <p *ngIf="iicD?.impactLecturesStatus==0" style="color: #ff0000;">Not Applied</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5 schemeParti">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/mentor-mentee-icon.png" class="img-responsive-icon">
                <h3>Mentor Mentee</h3>
              </div>
              <div class="text">
                <p>{{iicD?.MM_Status}}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="iicPerformance">
  <div class="container">
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>Participation in Other Program</h2>
        </div>
      </div>
    </div> -->
    <div class="row mt-20">
	  <div class="col-md-12">
		<h2 class="titleSubHeading">Participation in Other Program</h2>
	  </div>
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/atl-school-mapping-icon.png" class="img-responsive-icon">
                <h3>ATL School Mapping</h3>
              </div>
              <!-- <div class="text">
                <h4>262</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.atl_school_mapping}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/repository-icon.png" class="img-responsive-icon">
                <h3>E-repository submission</h3>
              </div>
              <!-- <div class="text">
                <h4>262</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.e_repository_submitted}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/institute-referred-icon.png" class="img-responsive-icon">
                <h3>No. of institutes Referred</h3>
              </div>
              <!-- <div class="text">
                <h4>262</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.referred_institutes}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="iicPerformance">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>Infrastructure</h2>
        </div>
      </div>
    </div>
    <div class="row mt-20">
      <div class="col-md-6">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/preincubation-icon.png" class="img-responsive-icon">
                <h3>Pre Incubation</h3>
              </div>
              <!-- <div class="text">
                <h4>520</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.preincubation_centers}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/incubation-icon.png" class="img-responsive-icon">
                <h3>Incubation</h3>
              </div>
              <!-- <div class="text">
                <h4>228</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.incubation_centers}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="iicPerformance">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>Student Participation</h2>
        </div>
      </div>
    </div>
    <div class="row mt-20">
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/sih-icon.png" class="img-responsive-icon">
                <h3>Participation in SIH</h3>
              </div>
              <div class="text">
                <!-- <h4>262</h4> -->
                <p>Will be updated soon</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/poc-repository-icon.png" class="img-responsive-icon">
                <h3>Participation in Idea/PoC Repository</h3>
              </div>
              <!-- <div class="text">
                <h4>262</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.idea_poc_repository}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/iic-icon.png" class="img-responsive-icon">
                <h3>IIC Council Members</h3>
              </div>
              <!-- <div class="text">
                <h4>262</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.student_iic_council_members}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="iicPerformance">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>Faculty Participation</h2>
        </div>
      </div>
    </div>
    <div class="row mt-20">
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/iic-icon.png" class="img-responsive-icon">
                <h3>IIC Council Members</h3>
              </div>
              <!-- <div class="text">
                <h4>262</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.faculty_iic_council_members}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/innovation-ambassador-icon.png" class="img-responsive-icon">
                <h3>Innovation Ambassadors</h3>
              </div>
              <!-- <div class="text">
                <h4>262</h4>
              </div> -->
              <div class="text">
                <h4>{{iicD?.innovation_ambassadors}}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4 mb-20">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <img src="{{imageBasePath}}assets/images/instituteDashboard/icon/poc-repository-icon.png" class="img-responsive-icon">
                <h3>Participation in Idea/PoC Repository</h3>
              </div>
              <div class="text">
                <!-- <h4>262</h4> -->
                <p>Will be updated soon</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Yukti Innovation Repository -->
<section class="iicPerformance">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>Yukti Innovation Repository</h2>
        </div>
      </div>
    </div>
    <div class="row mt-20">
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <!-- <img src="assets/images/instituteDashboard/icon/iic-icon.png" class="img-responsive-icon"> -->
                <i class="fa fa-lightbulb-o fax-icons" aria-hidden="true"></i>
                <h3>Ideas</h3>
              </div>
              <div class="text">
                <h4>1234</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <!-- <img src="assets/images/instituteDashboard/icon/innovation-ambassador-icon.png" class="img-responsive-icon"> -->
                <i class="fa fa-product-hunt fax-icons-1" aria-hidden="true"></i>
                <h3>Innovation/Prototype</h3>
              </div>
              <div class="text">
                <h4>1000</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-4 mb-20">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <!-- <img src="assets/images/instituteDashboard/icon/poc-repository-icon.png" class="img-responsive-icon"> -->
                <i class="fa fa-cogs fax-icons-1" aria-hidden="true"></i>
                <h3>Startup</h3>
              </div>
              <div class="text">
                <h4>1200</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Yukti Innovation Challenge -->
<section class="iicPerformance">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="instiHeading">
          <h2>Yukti Innovation Challenge</h2>
        </div>
      </div>
    </div>
    <div class="row mt-20">
      <div class="col-md-6">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <!-- <img src="assets/images/instituteDashboard/icon/iic-icon.png" class="img-responsive-icon"> -->
                <i class="fa fa-lightbulb-o fax-icons" aria-hidden="true"></i>
                <h3>Ideas</h3>
              </div>
              <div class="text">
                <h4>1200</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mainBox">
          <a href="javascript:void(0);">
            <div class="box p-5">
              <div class="instiLogo">
                <!-- <img src="assets/images/instituteDashboard/icon/innovation-ambassador-icon.png" class="img-responsive-icon"> -->
                <i class="fa fa-product-hunt fax-icons-1" aria-hidden="true"></i>
                <h3>Innovation/Prototype</h3>
              </div>
              <div class="text">
                <h4>1000</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

</div>