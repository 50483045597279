import { Component, OnInit } from '@angular/core';
import { IicDashboard1920Service } from 'src/app/Services/iic-dashboard1920.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import * as myGlobals from '../../../../../app/app.component';

@Component({
  selector: 'app-iic-dashboard1920-charts',
  templateUrl: './iic-dashboard1920-charts.component.html',
  styleUrls: ['./iic-dashboard1920-charts.component.css']
})
export class IicDashboard1920ChartsComponent implements OnInit {

  baseUrl = myGlobals.baseUrl1;
  public single: any[];
  public showLegend = true;
  public gradient = true;
  public colorScheme = {
    domain: ['#586B7D', '#5F9EDA', '#9E9492', '#0096A6', '#F47B00', '#606060']
  };
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;
  public trimLabels = false;
  public showPieLegend = false;
  public legendPosition: string = 'below';

  //bar
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public barGradient = false;
  public showBarLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Quarterwise count of activities';
  public showYAxisLabel = false;
  public yAxisLabel = 'Activity';
  public barColorScheme = {
    domain: ['#606060', '#586B7D', '#9E9492', '#0096A6', '#F47B00', '#5F9EDA']
  };
  public autoScale = true;

  //Stacked bar
  public multi2: any[];
  public xAxisStackedLabel = 'Events';
  public showYAxisStackedLabel = true;
  public yAxisStackedLabel = 'Participants';
  public stackedColorScheme = {
    domain: ['#378D3B', '#0096A6']
  };
  overallImpact: any;


  ngOnInit() {

    //Pie data
    this.iicDashboard1920Service.getPieData().subscribe(
      data => this.setPieData(data['data'])
    );

    //bar data
    this.iicDashboard1920Service.getQuarterwiseBarData().subscribe(
      data => this.setBarData(data)
    )



    //stacked bar chart
    this.iicDashboard1920Service.getOverAllImapact().subscribe(
      data => {
        this.multi2 = data['data'].myImpact
        this.overallImpact = data['data']
      }
    )
  }

  //bar and line chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel:
        {
          display: false,
          labelString: 'Events',
        }
      }], yAxes: [{
        scaleLabel:
        {
          display: true,
          labelString: 'Counts',
        }
      }]
    },
    
  };


  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Color[] = [
    { backgroundColor: '#91d494' },
    { backgroundColor: '#0096A6' },
    { backgroundColor: '#2F3E9E' },
    { backgroundColor: '#D22E2E' }
  ]

  barChartData: ChartDataSets[] = [
    { data: [], label: 'Student' },
    { data: [], label: 'Faculty' },
    { data: [], "type": "line", fill: false, label: 'Highest Student', borderColor: '#2F3E9E' },
    { data: [], "type": "line", fill: false, label: 'Highest Faculty', borderColor: '#D22E2E' }
  ];

  barChartDataInitiate() {
    this.barChartData = [
      { data: [], label: 'Student' },
      { data: [], label: 'Faculty' },
      { data: [], "type": "line", fill: false, label: 'Highest Student', borderColor: '#2F3E9E' },
      { data: [], "type": "line", fill: false, label: 'Highest Faculty', borderColor: '#D22E2E' }
    ];
  }

  selectedType = 'IIC Calendar Activity';


  

  constructor(
    private iicDashboard1920Service: IicDashboard1920Service
  ) {
  }

  public onSelect(event) {
  }

  setPieData(data) {
    data.forEach(function (obj) {
      obj.name = obj.theme_name;
      delete obj.theme_name;
      obj.value = obj.theme_count;
      delete obj.theme_value;
    });
    this.single = data;
  }

  setBarData(data) {

    data.forEach(element => {

      element.series.forEach(function (obj) {
        obj.name = obj.quarter;
        delete obj.quarter;
        obj.value = obj.count;
        delete obj.count;
      });
    });
    this.multi = data;
  }

}
