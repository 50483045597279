<div class="container">
    <div style="text-align: center">
        <a href="https://mic.gov.in/admin/images/download/53214_download.png" class="instIICDownload">
            Click here to download IIC logo
        </a>
    </div>
</div>

<div class="container">

    <div class="m-4 row">
        <div class="col-4">
            <mat-form-field>
                <mat-label>Status Filter</mat-label>
                <mat-select (selectionChange)="changeFilter()" [(ngModel)]="filterModel">
                    <mat-option value="All" default selected>All</mat-option>
                    <mat-option value="Approved">Approved</mat-option>
                    <mat-option value="Disapproved">Disapproved</mat-option>
                    <mat-option value="Pending">Pending</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4">
            <button mat-raised-button color='primary' class="ml-3 mt-2" (click)='openDialog()'>Create Request
                Logo<mat-icon>add
                </mat-icon> </button>
        </div>
        <div class="col-2">
            <button type="button" class="btn btn-success float-left" (click)="previousPage()">Previous</button>
        </div>
        <div class="col-2">
            <button type="button" class="btn btn-success float-right" (click)="nextPage()">Next</button>
        </div>
    </div>


    <div class="mt-3">
        <table class="table table-hover">
            <thead class="semesterTable">
                <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Events Title</th>
                    <th scope="col">From Date</th>
                    <th scope="col">To Date</th>
                    <th scope="col">Events Theme</th>
                    <th scope="col">Program brief/Brochure/Banner</th>
                    <th scope="col">Target Audience </th>
                    <th scope="col">Status </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let details of requestLogoDetails; let i = index;">
                    <th scope="row">{{i +1}}</th>
                    <td>{{details?.event_title}}</td>
                    <td>{{details?.from_dt}}</td>
                    <td>{{details?.to_dt}}</td>
                    <td>{{details?.event_theme}}</td>
                    <td style="cursor: pointer">
                        <a href="{{baseImageUrl + details?.brochure}}" target="_blank">
                            <mat-icon>cloud_download</mat-icon>
                        </a>
                    </td>
                    <td>{{details?.target_audience}}</td>
                    <td *ngIf="details.verification_status == '1'">Approved </td>
                    <td *ngIf="details.verification_status == '0'">Pending </td>
                    <td *ngIf="details.verification_status == '3'">Disapproved </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>