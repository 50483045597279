import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-nominated-coordinator',
  templateUrl: './add-nominated-coordinator.component.html',
  styleUrls: ['./add-nominated-coordinator.component.css']
})
export class AddNominatedCoordinatorComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  id:any;


  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.id = "";
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
    });



  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'name': [null, Validators.required],
      'email': [null, Validators.required],
      'mobile': [null, Validators.required],
      'designation': [null, Validators.required],
      'gender': [null, Validators.required]
    });

    this.instituteActivityService.atlNominatedCoordinateDetail(this.id).subscribe(
      (data) => this.handleResponse(data)
    );
  }



  handleResponse(data) {
    this.iicImpactQuestions.patchValue({
      name: data['name'],
      email: data['email'],
      mobile: data['mobile'],
      designation: data['designation'],
      gender: data['gender']
    });

  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('name', data['name']);
    formData.append('email', data['email']);
    formData.append('mobile', data['mobile']);
    formData.append('designation', data['designation']);
    formData.append('gender', data['gender']);

    this.instituteActivityService.atlNominatedCoordinateDetailSubmit(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="Success"){
      window.location.href='/institute/atl-nominate-coordinator';
      this.toastr.success(data['message']);
    }
    else{
      this.toastr.error(data['message']);

    }
  }

}
