import { Injectable } from '@angular/core';
import * as myGlobals from '../../app.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class OnlineJarwisService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  signUp(data) {
    return this.http.post(`${this.baseUrl}/osia/registerOSIA`, data);
  }

  nominate(data) {
    return this.http.post(`${this.baseUrl}/osia/registerOnlineSessionIA`, data);
  }

  login(data) {
    return this.http.post(`${this.baseUrl}/osia/loginOSIA`, data);
  }

  remove() {
    localStorage.removeItem('token');
  }
}
