import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iic',
  templateUrl: './iic.component.html',
  styleUrls: ['./iic.component.css']
})
export class IicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
