import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iic-dashboard1819',
  templateUrl: './iic-dashboard1819.component.html',
  styleUrls: ['./iic-dashboard1819.component.css']
})
export class IicDashboard1819Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
