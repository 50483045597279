<!--title-->
   <title>

    Implementation Team | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>

<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Innovation Council</a>
      </li>
      <li class="aht-breadcrumbs__item">
        IIC Team
      </li>
    </ul>
  </div>
	
	<style>
table {
  
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
   
    background-color: #fff;
	font-size: 14px;
	align:center;
}
</style>
<h2 style="text-align:center; color:#ff5402; margin-bottom: 20px; margin-top:20px; ">IIC Team</h2>


<div>
	<div class="panel-body table-responsive">
		<table cellspacing="0" border="0" width="95%" style="margin:auto;">
	<colgroup width="184"></colgroup>
	<colgroup width="186"></colgroup>
	<colgroup width="249"></colgroup>
	<colgroup width="255"></colgroup>
	
	<tr class="btn-primary-table table_design">
		<td colspan="3" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;" height="48" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Mr. Dipan Sahu <br>National Coordinator<br>dipan.sahu@aicte-india.org<br>011 2958 1226</font></b></td>
		
	</tr>
	<tr class="btn-primary-table table_design">
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="42" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Zone</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">State</font></b></td>
	
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Zone Incharge</font></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Southern/SRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Puducherry</font></td>
		
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=14 align="center" valign=middle bgcolor="#F2F2F2"><font face="Arial" color="#000000">Ms. Selvarani<br>selva.rani@aicte-india.org<br>011 2958 1513</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Tamil Nadu</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=12 height="258" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Eastern/ERO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Andaman and Nicobar Islands</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Arunachal Pradesh</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Assam</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Jharkhand</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Manipur</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Meghalaya</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Mizoram</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Nagaland</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Odisha/Orissa</font></td>

		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Sikkim</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Tripura</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">West Bengal</font></td>
		
		</tr>
		<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="60" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Northern/NRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Bihar</font></td>
		
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=10 align="center" valign=middle bgcolor="#FFFFFF"><font face="Arial" color="#000000">Mr. Ankush Sharma<br>ankush.sharma@aicte-india.org<br>011 2958 1223<br></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Uttar Pradesh</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Uttarakhand</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=7 height="140" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#222222">North-West/NWRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Chandigarh</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Delhi</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Haryana</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Himachal Pradesh</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Jammu and Kashmir</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Punjab</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Rajasthan</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="60" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Central/CRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Chhattisgarh</font></td>
		
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=5 align="center" valign=middle bgcolor="#E7E6E6"><font face="Arial" color="#000000">Mr. Sunny Atwal<br>yp.mic@aicte-india.org<br>011 2958 1223</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Gujarat</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Madhya Pradesh</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">South-Central/SCRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Andhra Pradesh</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Telangana</font></td>
		
		</tr>
	
	
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">South-West/SWRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Karnataka</font></td>
		
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=6 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Mr. Pankaj Pandey<br>pankajpandey@aicte-india.org<br>011 2958 1239<br></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Kerala</font></td>
		
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=4 height="80" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Western/WRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Dadra and Nagar Haveli</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Daman and Diu</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Goa</font></td>
	
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Maharashtra</font></td>
		
		</tr>
</table><br><br>								
								</div>	
	</div>



<!-- Footer -->
<app-inner-footer></app-inner-footer>