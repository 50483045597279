import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  EmailValidator,
} from '@angular/forms';
import { InstituteSuggestionService } from 'src/app/Services/institute-suggestion.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteSuggestionDialogComponent } from '../institute-suggestion/institute-suggestion-dialog/institute-suggestion-dialog.component';
import { ReplyDialogComponent } from './reply-dialog/reply-dialog.component';
import { ReplyListComponent } from './reply-list/reply-list.component';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-institute-suggestion',
  templateUrl: './institute-suggestion.component.html',
  styleUrls: ['./institute-suggestion.component.css'],
})
export class InstituteSuggestionComponent implements OnInit {
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
frontCurrentPageNo: number = 0;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  public suggestionForm: FormGroup;
  submittedMessage: String[] = [];
  showRepliedMessage: boolean = false;

  constructor(
    public dialog: MatDialog,
    private suggestionService: InstituteSuggestionService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.suggestionForm = this.fb.group({
      subject: [null, Validators.required],
      message: [null, Validators.required],
    });
    this.getSuggestion();
  }

  getSuggestion() {
    this.suggestionService
      .getSuggestion(this.currentPageNo,this.per_page)
      .subscribe((data) => this.handleMessageResponse(data));
  }

  handleMessageResponse(data) {
    this.submittedMessage = [];

    data['data'].forEach((element) => {
      this.submittedMessage.push(element);
    });
this.total_pages = data.meta.pagination.total;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  replyMessage(data): void {
    const dialogRefNew = this.dialog.open(ReplyDialogComponent, {
      data: { Id: data },
    });
    dialogRefNew.afterClosed().subscribe((result) => {
      this.currentPageNo = 1;
      this.getSuggestion();
    });
  }

  nextPage(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	this.per_page = e.pageSize;
    this.getSuggestion();
  }

  previousPage() {
    this.currentPageNo--;
    this.getSuggestion();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InstituteSuggestionDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      this.currentPageNo = 1;
      this.getSuggestion();
    });
  }

  openDialogReplyList(suggestion): void {
    const dialogRef = this.dialog.open(ReplyListComponent, {
      data: { suggestion: suggestion },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.currentPageNo = 1;
      this.getSuggestion();
    });
  }
}
