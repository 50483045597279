<div class="container">
  <div *ngIf="application_status=='0'">
    <mat-card style="
  margin-right: 33px;
  margin-left: 20px;
  margin-bottom: 50px;
">
      Thank you for registering for the Institution's Innovation Council.
      Update Institute/President details under My Council Menu to proceed further.
      Based on the institute details you have submitted the application is reviewed by IIC Zonal Incharge.
      Approved Institute will see additional tabs of My Council given in Orange/Red color.

    </mat-card>
  </div>

  <p>&nbsp;</p>


  <!-- <div *ngIf="instituteDisaproved=='0'">
    <mat-card style="
  margin-right: 33px;
  margin-left: 20px;
  margin-bottom: 50px;
">
     Your Institute is not yet approved. For more details, please write to iic.mhrd@aicte-india.org

    </mat-card>
  </div> -->

  <!-- <p class="iicPrecidentZone" *ngIf="qrCode?.length > 0">
    IIC President and Convener may join the zonal IIC WhatsApp groups. This group is only for IIC President/Convener.
  <br>
  Scan the QR Code using the WhatsApp camera to join the particular group.
  </p>
  <div class="col-md-12">
  <div class="col-md-6" *ngFor="let faculty of qrCode;let i = index"  style="text-align: center !important">

    <img class="qrCodeBox" src="{{faculty}}" alt="QR Code Logo">
</div>
</div> -->



<!-- style="max-height: 400px !important; max-width: 400px !important" -->
  <div *ngIf="ForWhom=='Institute' || ForWhom=='institute_student'" class="row profileRowMargin">
    <div class="col-3">
      <div class="">
        <a class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG" routerLink="/institute/certificates">
          <p class="profilePara">Certificates</p><span class="badge badge-light ml-2">
            <mat-icon>assignment</mat-icon>
          </span>
        </a>
      </div>
    </div>
    <div class="col-3">
      <div class="">
        <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
          (click)="openDialog()">
          <p class="profilePara"> Reset Password </p><span class="badge badge-light ml-2">
            <mat-icon>vpn_key</mat-icon>
          </span>
        </button>
      </div>
    </div>
    <div class="col-3">
      <div class="">
        <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
          (click)="openDialogLastLogin()">
          <p class="profilePara">Last Login: {{data?.created_on}} </p><span class="badge badge-light mr-2">
            <mat-icon> account_circle</mat-icon>
          </span>
        </button>
      </div>
    </div>

    <div class="col-3">
      <div class="">
        <a class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG" routerLink="/institute/bifurcation">
          <p class="profilePara">My Performance </p><span class="badge badge-light mr-2">
            <mat-icon> account_circle</mat-icon>
          </span>
        </a>
      </div>
    </div>
  </div>
  
  
  
  <!--||||| For School |||||-->
  
  <div *ngIf="ForWhom=='School'" class="row profileRowMargin profileSchool">
    <div class="col-3" *ngIf="ForWhom=='School1'">
      <div class="">
        <a class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG schoolProfileBG1" routerLink="/institute/certificates">
          <span class="badge badge-light ml-2">
            <mat-icon class="schoolCerti">assignment</mat-icon>
          </span><p class="profilePara schoolPara">Certificates </p>
        </a>
      </div>
    </div>
    <div class="col-3">
      <div class="">
        <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG schoolProfileBG2"
          (click)="openDialog()">
          <span class="badge badge-light ml-2">
            <mat-icon class="schoolResetPass">password</mat-icon>
          </span><p class="profilePara schoolPara"> Reset Password </p>
        </button>
      </div>
    </div>
    <div class="col-3">
      <div class="">
        <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG schoolProfileBG3"
          (click)="openDialogLastLogin()">
          <span class="badge badge-light mr-2">
            <mat-icon class="schoolLastLogin"> account_circle</mat-icon>
          </span><p class="profilePara schoolPara">Last Login: {{data?.created_on}} </p>
        </button>
      </div>
    </div>

    <div class="col-3" *ngIf="ForWhom=='School1'">
      <div class="">
        <a class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG schoolProfileBG4" routerLink="/institute/bifurcation">
          <span class="badge badge-light mr-2">
            <mat-icon class="schoolPerformance">fact_check</mat-icon>
          </span><p class="profilePara schoolPara">My Performance </p>
        </a>
      </div>
    </div>
  </div>

  <mat-tab-group class="menteeLabelBut" mat-align-tabs="center">
    <mat-tab class="profileTab" label="HOI Details" *ngIf="ForWhom!='School'">
      <app-hoi-details></app-hoi-details>
    </mat-tab>
    <mat-tab *ngIf="ForWhom!='School'" class="profileTab" label="President Details">
      <app-president-details></app-president-details>
    </mat-tab>


    <mat-tab *ngIf="ForWhom=='School1'" class="profileTab" label="IIC Co-Ordinator">
      
      <p>Coming Soon....</p>


    </mat-tab>


  </mat-tab-group>



</div>
