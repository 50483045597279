
<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>
<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Photo Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Poster Presentation for Chennai</li>
  </ul>
</nav>


<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Poster Presentation for Chennai</h2>
				<div>
					<a class="backButton" routerLink="../poster-gallery">Back to Home</a>
				</div>
			</div>
		</div>
		</div>	  
		  
	</div>	
 </div>

<div class="container">
		<div class="row">
			<div class="gallery col-md-12">
				<div class="row">
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-1.jpeg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-1.jpeg" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-2.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-2.jpg" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-3.jpeg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-3.jpeg" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-4.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-4.jpg" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-5.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-5.jpg" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-6.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-6.jpg" alt="First image">
						</a>
					</div>
				</div>
			</div>
			
		</div>
	</div> 

<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>

<app-iic-web-footer></app-iic-web-footer>

