<!-- <div *ngIf="images.length>0" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

    <div class="carousel-item active" *ngFor="let item of images">
      <img class="d-block w-100" src="{{baseImageUrl + item}}" alt="First slide">
    </div>

  </div>

  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div> -->

<!--  -->

<div class="form-group">

  <div class="container mt-3">

    <!-- <div class="row" style="text-align: center !important">
      <div class="col-8"></div>
      <div class="col-2" *ngIf="!firstPage">
        <button type="button" class="btn btn-success" (click)="previousPage()">Previous</button>
      </div>
      <div class="col-2" *ngIf="!lastPage">
        <button type="button" class="btn btn-success" (click)="nextPage()">Next</button>
      </div>
    </div> -->

    <table class="table table-hover mt-4">
      <thead class="facultyTable">
        <tr>
          <td colspan="3" allign="left" class="danger "
            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Notifications from MIC</h1>
          </td>
        </tr>


        <tr>
          <th scope="col">Notifications from MIC</th>
          <th scope="col">More Details</th>
          <th scope="col">Date Created</th>
        </tr>
      </thead>
      <tbody>

       
		<tr *ngFor="let item of announcements| paginate: { itemsPerPage: 5, currentPage: page }; let i = index;">
		
		<td *ngIf="item?.announcementType == 'Text' && !item?.text_url">
		{{item?.announcement}}
		</td>
		<td *ngIf="item?.announcementType == 'Text' && item?.text_url">
		<a href="{{item?.text_url}}" target="_blank">
		{{item?.announcement}}
		</a>
		</td>
		<td *ngIf="item?.announcementType == 'Image'">
		{{item?.image_title}}
		</td>
		<td *ngIf="item?.attachment">
            <a href="{{baseImageUrl + item?.attachment}}" target="_blank" style="cursor: pointer;"
              class="btn btn-success btnDownSuccess">Download
            </a>
          </td>
          <td *ngIf="!item?.attachment">
            N/A
          </td>
		  <td>{{item?.dateCreated}}</td>
	</tr>

      </tbody>
	  <div class="notificationPagination"><pagination-controls class="annular-pagination float-right" maxSize="3"
    (pageChange)="onPageChanged($event)"></pagination-controls> </div>

    </table>

</div>
</div>
