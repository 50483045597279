<app-iic-web-navbar></app-iic-web-navbar>
<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">
<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Photo Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>
<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Gallery</a></li>
	<li class="breadcrumb-item"><a href="gallery/photo-gallery">Photo Gallery</a></li>
    <li class="breadcrumb-item active" aria-current="page">National Technology Week 2023</li>
  </ul>
</nav>
<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>National Technology Week 2023</h2>
				<!-- <div>
					<a class="backButton" href="gallery/photo-gallery">Back to Gallery</a>
				</div> -->
			</div>
		</div>
		</div>	  
	</div>	
 </div>
<div class="container">
	<div class="row">
		<div class="gallery col-md-12">
			<div class="row">
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/1.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/1.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/5.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/5.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/2.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/2.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/3.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/3.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/4.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/4.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/5.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/5.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/6.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/6.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/39.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/39.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/40.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/40.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/38.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/38.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/41.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/41.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/42.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/42.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/43.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/43.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/27.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/27.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/28.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/28.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/29.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/29.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/30.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/30.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/31.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/31.JPG" alt="First image">
					</a>
				</div>



				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/32.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/32.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/33.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/33.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/34.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/34.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/35.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/35.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/36.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/36.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/37.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/37.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/7.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/7.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/8.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/8.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/9.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/9.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/10.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/10.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/11.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/11.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/12.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/12.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/13.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/13.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/14.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/14.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/15.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/15.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/16.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/16.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/17.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/17.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/18.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/18.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/19.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/19.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/20.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/20.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/21.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/21.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/22.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/22.JPG" alt="First image">
					</a>
				</div>
				<div class="col-md-4">
					<a class="lightbox" href="{{imageBasePath}}assets/wp_images/ntw/23.JPG" data-caption="">
						<img src="{{imageBasePath}}assets/wp_images/ntw/23.JPG" alt="First image">
					</a>
				</div>
			</div>
		</div>	
	</div>
</div>
<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>
<app-iic-web-footer></app-iic-web-footer>
