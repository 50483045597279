import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../app.component';

@Component({
  selector: 'app-institute-navbar',
  templateUrl: './institute-navbar.component.html',
  styleUrls: ['./institute-navbar.component.css'],
})
export class InstituteNavbarComponent implements OnInit {

  imageBasePath= myGlobals.imageBasePath;
  email: any;
  public conscentForm: FormGroup;
  toggle;
  name: any;
  home: boolean;
  mactivity: boolean;
  mentorDetail: boolean;
  nominateMember: boolean;
  yuktiSub: boolean;
  atlContact: boolean;
  atlAnnouncement: boolean;
  incubation: boolean;
  innovation: boolean;
  myCouncil: boolean = true;
  activity: boolean;
  iic_impact: boolean;
  stateCouncil:boolean;
  subIIcS:boolean;
  download: boolean;
  newsCorner: boolean;
  requestLogo: boolean;
  announcement: boolean;
  contactUs: boolean;
  InnovStartup: boolean;
  suggestion: boolean;
  viewDownload: boolean;
  ideaPoc: boolean;
  userId: any;
  selectedValue: any;
  institute_name: any;
  rewardPoints: any;
  cumulativeScore: any;
  urlType: any;
  stars: number[] = [1, 2, 3, 4, 5];
  profile: boolean;
  ambassador: boolean;
  showSideNav: boolean;
  ForWhom: any;
  InsZone: any;
  Current_Academic_Year: any;
  Prev_Academic_Year: any;
  check201819data: boolean;
  admin:any;
  viewDownloads: boolean;
  check201920data:boolean;
  dashboard2020:boolean;

  collaboration:boolean;
  ieandipr:boolean;
  ses:boolean;
  eLearning:boolean;
  cdevelopment:boolean;
  aboutInsti:boolean;
  innovationStartup:boolean;
  referalLink:boolean;
  helpDesk:boolean;
  informationDiss:boolean;
  checkmentor: number;
  checkmentee: number;
  mentor: boolean;
  m1: number;
  m2: number;
  m3: number;
  JoinUs:boolean;
  JoinUs2:boolean;
  parent_id:any;
  parent_institute_name:any;

  show_mentee: number;
  show_mentor: number;

  application_status: any;
  FacultyRolesIncomplete: any;
  StudentRolesIncomplete: any;

  show_affiliate: number;
  referral:boolean;
  affiliate:boolean;
  showYC:any;
  showATLInst:any;

  checksubiic:any;

  convergence:any;
  manthan:any;
  ActivityScheduled:any;

    pageUrl:any;
    baseImageUrl2 = myGlobals.baseImageUrl2;
  constructor(
    private jarwisService: JarwisService,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private activityService: InstituteActivityService,
    private toastr: ToastrService, private fb: FormBuilder,
    private myCouncilService: InstituteMyCouncilService
  ) {
    // this.getdetails();
    this.route.params.subscribe((params) => {
      this.pageUrl = router.url;
    });

  }

  ngOnInit(): void {

    this.show_mentee=0;
    this.show_mentor=0;
    this.show_affiliate=0;
    this.checksubiic=0;
    this.parent_id='';
    this.parent_institute_name='';
    this.showYC=0;
    
    this.myCouncilService.getHOI('').subscribe((data) => this.handleData(data));
    this.myCouncilService.getUser().subscribe((data) =>{
      if(data['institute_id']== 'IC201810001'){
        this.admin = true;
      }
      else{
        this.admin = false;
      }
    });
    this.getdetails();

    //let url = ;
    let splitUrl = this.router.url.split('/');
    this.urlType = splitUrl[2] !== undefined ? splitUrl[2] : '';

    let type = this.urlType
      ? this.urlType.charAt(0).toUpperCase() +
        this.urlType.substr(1).toLowerCase()
      : '';

    this.setActiveTab(type);

    this.myCouncilService
      .checkMyCouncil()
      .subscribe((data) => this.handleStatus(data));

    this.myCouncilService.checkPerformance201819().subscribe((data) => {
      if (data['check201819data'] == '0') {
        this.check201819data = false;
      }
      if (data['check201819data'] == '1') {
        this.check201819data = true;
      }
    });


    this.myCouncilService.checkPerformance201920().subscribe((data) => {
      if (data['check201920data'] == '0') {
        this.check201920data = false;
      }
      if (data['check201920data'] == '1') {
        this.check201920data = true;
      }
    });

  this.checkMemtorEligible();

  this.conscentForm = this.fb.group({
    'conscent': [null, Validators.required],
  });


  }

  // ngAfterViewInit() {
  //       $('#scrollBarInner ul li').click(function(){
          
  //           // $('#scrollBarInner').css({'border':'5px solid red !important'});
  //           $("#scrollBarInner ul .nav-item").find('div').removeClass('show');
  //           $('nav-item.active').find('div.collapse').addClass('show');
  //       });
  // }

  getATLMentor() {
    this.activityService.menteeAtlInstitution()
      .subscribe((data) => this.handleResponseATL(data));
  }
  handleResponseATL(data){
    this.showATLInst = data.status;
  }

   checkMemtorEligible(){
    this.activityService.checkMemtorEligible().subscribe(
      data => this.setcheckMemtorEligible(data)
    );
  }

  setcheckMemtorEligible(data){
    if(data['mentor_eligible']==1){
      this.m1=1
    }else{
      this.m1=0
    }

    if(data['mentee_eligible']==1){
      this.mentor=true;
      this.m2=1
      localStorage.setItem('UserMenteeStatus', 'Mentee');
    }else{
      this.mentor=false;
      this.m2=0
      localStorage.setItem('UserMenteeStatus', 'Not A mentee');
    }




if(this.m1==1 || this.m2==1){
  this.m3=1
}
  }

  onSubmit(data){
    this.activityService.submitMentorMenteeConsent(data).subscribe(
      data => this.handleResponse1(data)
    );
  }

handleResponse1(data){
  if(data['status']=="1"){
    this.toastr.success(data['msg'])

  }else{
    this.toastr.success(data['msg'])


  }
}


  handleStatus(data) {
    if (data['applicationStatus'] == '1') {
      this.showSideNav = true;
    }

    this.application_status = data['applicationStatusDisapproved'];

    this.FacultyRolesIncomplete = data['facultyNotSubittedRoles'];
    this.StudentRolesIncomplete = data['studentNotSubittedRoles'];

    localStorage.setItem('FacultyRolesIncomplete',this.FacultyRolesIncomplete);
    localStorage.setItem('StudentRolesIncomplete',this.StudentRolesIncomplete);
    localStorage.setItem('FacultyRolesStatus',data['facultyStatus']);
    localStorage.setItem('StudentRolesStatus',data['studentStatus']);
  }

  getdata() {
    throw new Error('Method not implemented.');
  }
  getdetails() {
    this.myCouncilService
      .getInstituteRating()
      .subscribe((data) => this.handleResponse(data));
  }
  
  handleResponse(data) {
    this.userId = data['rating']['userId'];
    this.selectedValue = data['rating']['rating'];
    this.institute_name = data['rating']['instituteName'];
    this.rewardPoints = data['rating']['rewards'];
    this.cumulativeScore = data['rating']['total_marks'];
    this.ForWhom="";
    localStorage.setItem('ForWhom',data['rating']['forwhom']);
    this.ForWhom=localStorage.getItem('ForWhom');
    if(this.ForWhom==='School'){
      this.getATLMentor();
    }
    this.InsZone="";
    localStorage.setItem('InsZone',data['rating']['zone']);
    this.InsZone=localStorage.getItem('InsZone');

    this.Current_Academic_Year="";
    localStorage.setItem('Current_Academic_Year',data['rating']['Current_Academic_Year']);
    this.Current_Academic_Year=localStorage.getItem('Current_Academic_Year');

    this.Prev_Academic_Year="";
    localStorage.setItem('Prev_Academic_Year',data['rating']['Prev_Academic_Year']);
    this.Prev_Academic_Year=localStorage.getItem('Prev_Academic_Year');

    this.parent_id = data['rating']['parent_id'];
    this.parent_institute_name = data['rating']['parent_institute_name'];

    if(this.userId=="IC201810001")
    {
      this.show_affiliate=1;
      this.checksubiic=1;
      this.showYC=1;
    }
    else
    {
      this.show_affiliate=data['rating']['affiliate'];
      this.checksubiic=data['rating']['checksubiic'];
    }

    // POPUP Code

    // if(this.pageUrl=="/institute/dashboard2020-21")
    if(this.pageUrl=="/institute/dashboard2024-25")
    {
      // if(data['rating']['rating']<=3.5)
      // {
      //   this.MenteeDialog(1);
      // }
      // else
      // {
      //   this.show_mentee=0;
      // }

      if(data['rating']["MentorStatus"]>0)
      {
        const inpu = {
          fundedBy : data['rating']["fundedBy"],
          // fundedBy : data['rating']["fundedBy"],
        }
        this.MentorDialog(inpu);
      }
      else
      {
        this.show_mentor=0;
      }
      this.show_mentee=0;
      // this.show_mentor=0;

    }
    else{
      this.show_mentee=0;
      this.show_mentor=0;
    }


  }

  MenteeDialog(data) {
    const dialogRef = this.dialog.open(InstituteMenteeInfoDialogComponent, {
      data: data
    });
  }

  MentorDialog(data) {
    const dialogRef = this.dialog.open(InstituteMentorInfoDialogComponent, {
      data: data
    });
  }

  handleData(data) {
    this.email = (data['hoiDetails']) ? data['hoiDetails']['email'] : "";
    this.name = (data['hoiDetails']) ? data['hoiDetails']['name'] : "";
  }

  logout() {
    this.jarwisService.remove();
    this.ForWhom='';
    this.router.navigateByUrl('/login');
  }

  setActiveTab(value) {
    this.home = false;
    this.incubation = false;
    this.innovation = false;
    this.mactivity = false;
    this.mentorDetail = false;
    this.nominateMember = false;
    this.yuktiSub = false;
    this.atlContact = false;
    this.atlAnnouncement = false;
    this.myCouncil = false;
    this.activity = false;
    this.iic_impact = false;
    this.stateCouncil = false;
    this.subIIcS = false;
    this.download = false;
    this.referral = false;
    this.affiliate = false;
    this.newsCorner = false;
    this.requestLogo = false;
    this.announcement = false;
    this.contactUs = false;
    this.JoinUs = false;
    this.JoinUs2 = false;
    this.suggestion = false;
    this.viewDownload = false;
    this.viewDownloads=false;
    this.profile = false;
    this.ambassador = false;
    this.dashboard2020=false;
	  this.ideaPoc=false;
    this.InnovStartup=false;
    this.collaboration=false;
    this.ieandipr=false;
    this.ses=false;
    this.eLearning=false;
    this.cdevelopment=false;
    this.aboutInsti=false;
    this.innovationStartup=false;
    this.referalLink=false;
    this.helpDesk=false;
    this.informationDiss=false;
    this.convergence=false;
    this.manthan=false;
    this.ActivityScheduled=false;


    switch (value) {
      case 'Home/Profile':
      case 'Dashboard':
        this.home = true;
        break;

      case 'profile':
        this.profile = true;
        break;
      case 'mactivity':
        this.mactivity = true;
        break;
      case 'mentorDetail':
        this.mentorDetail = true;
        break;
      case 'nominateMember':
        this.nominateMember = true;
        break;
      case 'yuktiSub':
        this.yuktiSub = true;
        break;
      case 'atlContact':
        this.atlContact = true;
        break;
      case 'atlAnnouncement':
        this.atlAnnouncement = true;
        break;

      case 'collaboration':
      this.collaboration = true;
      break;
      case 'ieandipr':
      this.ieandipr = true;
      break;
      case 'ses':
      this.ses = true;
      break;
      case 'eLearning':
      this.eLearning = true;
      break;
      case 'cdevelopment':
      this.cdevelopment = true;
      break;
      case 'aboutInsti':
      this.aboutInsti = true;
      break;
      case 'innovationStartup':
      this.innovationStartup = true;
      break;
      case 'referalLink':
      this.referalLink = true;
      break; 
      case 'helpDesk':
      this.helpDesk = true;
      break;
      case 'informationDiss':
      this.informationDiss = true;
      break; 
      case 'contactUs':
      this.contactUs = true;
      break;
            
      case 'Incubation':
        this.incubation = true;
        break;

        case 'stateCouncil':
        this.stateCouncil = true;
        break;

        case 'subIIcS':
        this.subIIcS = true;
        break;

      case 'Innovation':
      case 'innovation-contest':
        this.innovation = true;
        break;

      case 'MyCouncil':
      case 'My-council':
        this.myCouncil = true;
        break;

      case 'Activity':
        this.activity = true;
        break;

      case 'IIC_Impact' :
        this.iic_impact = true;
        break;

      case 'Download':
        this.download = true;
        break;

        case 'InnovStartup':
        this.InnovStartup = true;
        break;

        case 'referral':
          this.referral = true;
          break;

          case 'affiliate':
            this.affiliate = true;
            break;

      case 'NewsCorner':
        this.newsCorner = true;
        break;

      case 'RequestLogo':
        this.requestLogo = true;
        break;

      case 'Announcement':
        this.announcement = true;
        break;

      case 'Suggestion':
        this.suggestion = true;
        break;

      case 'ViewDownload':
        this.viewDownload = true;
        break;

      case 'ViewDownloads':
        this.viewDownloads = true;
        break;

        case 'Dashboard2020':
          this.dashboard2020 = true;
          break;
      case 'JoinUs':
        this.JoinUs = true;
        break;

        case 'JoinUs2':
          this.JoinUs2 = true;
          break;

      case 'ambassador':
        this.ambassador = true;
        break;

      case 'IdeaPOC':
          this.ideaPoc = true;
          break;
      case 'convergence':
          this.convergence = true;
          break;
      case 'manthan':
          this.manthan = true;
          break;
      case 'ActivityScheduled':
          this.ActivityScheduled = true;
          break;

      default:
        break;
    }
  }

  menuToggle() {
    this.toggle = !this.toggle;
    this.myCouncilService.updateToggle(this.toggle);
  }
}

@Component({
  selector: 'app-institute-mentee-info-dialog',
  templateUrl: './institute-mentee-info-dialog.html',
})

export class InstituteMenteeInfoDialogComponent implements OnInit {
  id: any;
  
  constructor(public dialog: MatDialog,
    private myCouncilService: InstituteMyCouncilService,
    public dialogRef: MatDialogRef<InstituteNavbarComponent>,
    
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}


@Component({
  selector: 'app-institute-mentor-info-dialog',
  templateUrl: './institute-mentor-info-dialog.html',
})

export class InstituteMentorInfoDialogComponent implements OnInit {
  id: any;
  // conscent: any;
  conscentForm: FormGroup;
  constructor(public dialog: MatDialog,
    private myCouncilService: InstituteMyCouncilService,
    private formBuilder: FormBuilder,private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteNavbarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.conscentForm =  this.formBuilder.group({
      'conscent': [null, Validators.required]
    });
  }
  onSubmit(values) {
    if (this.conscentForm.valid) {
      let formData = new FormData();
      formData.append('granted', this.conscentForm.get('conscent').value);
      this.myCouncilService.submitConsentStatus(formData).subscribe(
        data => this.handleResponse(data)
      );
    }
  }
  handleResponse(data){
    if(data['status'] == 1){
      this.toastr.success("Details Updated Successfully !");
      this.dialogRef.close();
    }else{
      this.toastr.success("Something went wrong !");
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
