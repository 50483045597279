import { Component, OnInit } from '@angular/core';
import { LandingService } from 'src/app/Services/landing.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-institutes-list-dialog',
  templateUrl: './institutes-list-dialog.component.html',
  styleUrls: ['./institutes-list-dialog.component.css']
})
export class InstitutesListDialogComponent implements OnInit {

  currentPageNo: number = 1;
  institutesList = [];
  firstPage: boolean = true;
  total_pages : number;
  lastPage: boolean;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  frontCurrentPageNo: number = 0;
  data:any;

  constructor(private landing: LandingService,
    public dialogRef: MatDialogRef<InstitutesListDialogComponent>) { }

  ngOnInit(): void {
    this.landing.getInstitutesList(this.data,this.currentPageNo,this.per_page).subscribe(
      data => this.handleResponse(data)
    )
  }

  handleResponse(data) {
    this.institutesList = [];
    this.total_pages = data.meta.pagination.total;
    data['data'].forEach((element) => {
      this.institutesList.push(element);
    });
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {
    if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	this.per_page = e.pageSize;
    this.ngOnInit();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

