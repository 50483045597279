import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  EmailValidator,
  FormArray,
} from '@angular/forms';
import { InstituteDetails } from '../institute-details';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ToastrService } from 'ngx-toastr';
import { emailValidator } from 'src/app/app.component';
import * as myGlobals from '../../../../../../app.component';


@Component({
  selector: 'app-institute-details-dialog',
  templateUrl: './institute-details-dialog.component.html',
  styleUrls: ['./institute-details-dialog.component.css'],
})
export class InstituteDetailsDialogComponent implements OnInit {
  public instituteForm: FormGroup;
  baseUrl = myGlobals.baseImageUrl;

  instituteModel: any;
  institueSubTypeModel: any;
  // * dropdowns
  incubationInnovationFacilities: string[] = [];
  instituteType: string[] = [];
  instituteSubType: string[] = [];
  colleges: string[] = [];
  standalone: string[] = [];
  universities: string[] = [];
  nispNominatedFlag: boolean = false;

  // ! checked from dropdown
  incubationList: string[] = [];

  public nbaUploaded: boolean = false;
  public naacUploaded: boolean = false;
  public ariaUploaded: boolean = true;
  public nirfUploaded: boolean = true;
  public nirfChecked: boolean = false;
  public ariiaChecked: boolean = false;
  public nbaChecked: boolean = false;
  public naacChecked: boolean = false;
  public noneChecked: boolean = false;

  public CoursesModel: any;

  public Courses: string[] = [];

  ApprovalType: string[] = [];
  ApprovalModel: [];

  selected_course: [];
  selected_approval: [];
  nispDetails: any;
  nispNominated: any;
  isIAParticipated: any;


  // * files
  nba_certificate: FileList | null;
  naac_certificate: FileList | null;
  institute_letter_head: FileList | null;
  offering_academic_credit_core: FileList | null;

  uploadDiv: boolean = false;
  uploadDiv1: boolean = false;
  uploadDiv2: boolean = false;
  nba_file_flag: boolean = false;
  institute_letter_head_flag: boolean = false;
  naac_flag: boolean = false;
  academic_credit_core_flag: boolean = false;


  facilties: any;
  url: any;
  nbaAccreditatedCourses: any;
  nba: any;
  nba1: any;
  naac1: any;
  institute_letter_head1: any;
  naac: any;
  offering_academic_credit_core1: any;
  faculties_received_training: any;
  academic_credit_status: any;
  presidentContact: any;
  presidentDesignation: any;
  presidentEmail: any;
  presidentName: any;
  instituteType1: any;
  instituteSubType1: any;
  aicte_approve_program: any;
  testModel: any;
  aicte_approve: any;
  Selected_courses: any;
  pid: any;
  gender: any;


  // ! for auto population data
  incubationFacility;
  researchParkFacility: boolean;
  entrepreneurshipFacility: boolean;
  EDEkFacility: boolean;
  designFacility: boolean;
  startupFacility: boolean;
  newGenFacility: boolean;
  innovationFacility: boolean;
  tinkeringFacility: boolean;
  IPRFacility: boolean;
  industryFacility: boolean;
  ideaFacility: boolean;
  centreFacility: boolean;
  internshipFacility: boolean;
  noneFacility: boolean;
  institute_Letter_Head: any;
  offering_Academic_Credit_core: any;
  NBA_File: any;
  NAAC_File: any;
  // ! for auto population data


  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private instituteMyCouncilService: InstituteMyCouncilService,
    public dialogRef: MatDialogRef<InstituteDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.instituteMyCouncilService
      .instituteDetailsDropdown()
      .subscribe((data) => this.handleDropdownResponse(data));

    this.getData();
  }

  handleDropdownResponse(data) {

    data['incubationInnovationFacilities'].forEach((element) => {
      this.incubationInnovationFacilities.push(element.facilities);
    });

    data['approvals'].forEach((element) => {
      this.ApprovalType.push(element.approvals);
    });

    data['instituteType'].forEach((element) => {
      this.instituteType.push(element);
    });

    data['college'].forEach((element) => {
      this.colleges.push(element);
    });

    data['standalone'].forEach((element) => {
      this.standalone.push(element);
    });

    data['university'].forEach((element) => {
      this.universities.push(element);
    });
    data['coursesOffered'].forEach((element) => {
      this.Courses.push(element.courses);
    });

  }

  ngOnInit(): void {
    this.instituteMyCouncilService
      .getInstituteDetails()
      .subscribe((data) => this.handleResponse(data));


    this.instituteForm = this.fb.group({
      spoc_name: [null, Validators.required],
      spoc_email: [
        null,
        Validators.compose([Validators.required, emailValidator,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
        ),
      ],
      spoc_contact: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
      spoc_designation: [null, Validators.required],
      approvals: [null, Validators.required],
      institute_type: [null, Validators.required],
      website: [null, Validators.required],
      courses_offered: [null, Validators.required],
      institute_sub_type: [null, Validators.required],
      faculties_received_training: [null, Validators.required],
      academic_credit_status: [null, Validators.required],
      nba: [null, Validators.required],
      naac: [null, Validators.required],
      ariia: [null],
      nirf: [null, Validators.required],
      nirfField: [null],
      ariiaField: [null],
      // aicte_approved_program: [null, Validators.required],
      pid: [null],
      gender:[null, Validators.required]
    });

    this.instituteForm.get('faculties_received_training').valueChanges.subscribe(
      data => this.handleData(data)
    )

    this.instituteForm.get('academic_credit_status').valueChanges.subscribe(
      data => this.handleData1(data)
    )

    // this.instituteForm.get('aicte_approved_program').valueChanges.subscribe(
    //   data => this.handleData2(data)
    // )

    this.instituteForm.get('institute_type').valueChanges.subscribe(
      data => this.setInstituteSubType(data)
    )
    this.instituteForm.controls['ariiaField'].setValue('');
    this.instituteForm.controls['nirfField'].setValue('');
  }



  getData() {
    this.instituteMyCouncilService
      .getInstituteDetails()
      .subscribe((data) => this.dataSet(data));
  }

  dataSet(data) {

    this.url = data['instituteDetails']['website'];
    this.nba = data['instituteDetails']['nba'];
    this.institute_letter_head = data['instituteDetails']['institute_letter_head'];
    this.naac = data['instituteDetails']['naac'];
    this.offering_academic_credit_core = data['instituteDetails']['offering_academic_credit_core'];
    this.academic_credit_status = data['instituteDetails']['academic_credit_status'];
    this.faculties_received_training = data['instituteDetails']['faculties_received_training'];
    this.presidentContact = data['instituteDetails']['presidentContact'];
    this.presidentDesignation = data['instituteDetails']['presidentDesignation'];
    this.presidentEmail = data['instituteDetails']['presidentEmail'];
    this.presidentName = data['instituteDetails']['presidentName'];
    this.aicte_approve_program = data['instituteDetails']['aicte_approve_program'];
    // this.instituteType1 = data['instituteTypeAndSubType']['0']['type_subtype'];
    // this.instituteSubType1 = data['instituteTypeAndSubType']['1']['type_subtype'];
    this.aicte_approve = data['instituteDetails']['aicteApproved'];
    this.Selected_courses = data['instituteDetails']['nbaAccreditatedCourses'];
    this.pid = data['instituteDetails']['pid'];
    this.gender = data['instituteDetails']['gender'];



    if (data['instituteTypeAndSubType']['0']['type_subtype'] == "University") {
      this.instituteModel = "1";
    } else if (data['instituteTypeAndSubType']['0']['type_subtype'] == "College") {
      this.instituteModel = "2";
    } else if (data['instituteTypeAndSubType']['0']['type_subtype'] == "Standalone") {
      this.instituteModel = "3";
    }

    this.institueSubTypeModel = data['instituteTypeAndSubType']['1']['id'].toString();

    data['instituteDetails']['Facilities'].forEach(element => {
      if (element == 'Incubation & Pre-incubation Units') {
        this.incubationFacility = true;
        this.incubationList.push('Incubation & Pre-incubation Units');
      }
      if (element == 'Research Park') {
        this.researchParkFacility = true;
        this.incubationList.push('Research Park');
      }
      if (element == 'Innovation & Entrepreneurship Development Cell (IEDC') {
        this.entrepreneurshipFacility = true;
        this.incubationList.push('Innovation & Entrepreneurship Development Cell (IEDC');
      }
      if (element == 'EDE') {
        this.EDEkFacility = true;
        this.incubationList.push('EDE');
      }
      if (element == 'Design Centre/Maker\'s Space/Arts and Craft making Design') {
        this.designFacility = true;
        this.incubationList.push('Design Centre/Maker\'s Space/Arts and Craft making Design');
      }
      if (element == 'Start up cell under TEQUIP II') {
        this.startupFacility = true;
        this.incubationList.push('Start up cell under TEQUIP II');
      }
      if (element == 'New gen IEDC') {
        this.newGenFacility = true;
        this.incubationList.push('New gen IEDC');
      }
      if (element == 'Innovation Cell') {
        this.innovationFacility = true;
        this.incubationList.push('Innovation Cell');
      }
      if (element == 'Tinkering Labs/Centre') {
        this.tinkeringFacility = true;
        this.incubationList.push('Tinkering Labs/Centre');
      }
      if (element == 'IPR/Technology Transfer Cell/Unit') {
        this.IPRFacility = true;
        this.incubationList.push('IPR/Technology Transfer Cell/Unit');
      }
      if (element == 'Industry-Institute Interface Unit/Centre') {
        this.industryFacility = true;
        this.incubationList.push('Industry-Institute Interface Unit/Centre');
      }
      if (element == 'Idea Clubs/Innovation Clubs/Startup Clubs/IP Clubs lead by Student Bodies') {
        this.ideaFacility = true;
        this.incubationList.push('Idea Clubs/Innovation Clubs/Startup Clubs/IP Clubs lead by Student Bodies');
      }
      if (element == 'Centre of Excellence/Skill Development Labs at Institute for Practical Learning') {
        this.centreFacility = true;
        this.incubationList.push('Centre of Excellence/Skill Development Labs at Institute for Practical Learning');
      }
      if (element == 'Internship Centre for Students /Short-term Placement in Startups') {
        this.internshipFacility = true;
        this.incubationList.push('Internship Centre for Students /Short-term Placement in Startups');
      }
      if (element == 'None') {
        this.noneFacility = true;
        this.incubationList.push('None');
      }

    });




    if (this.nba != null && this.nba != '' && this.nba != '/') {
      this.nba_file_flag = true;
      this.nba1 = 1;
    } else {
      this.nba1 = 0;
      this.nba_file_flag = false;
    }

    if (this.naac != null && this.naac != '' && this.naac != '/') {
      this.naac_flag = true;
      this.naac1 = 1;

    } else {
      this.naac_flag = false;
      this.naac1 = 0;

    }

    if (this.institute_letter_head != null) {
      this.institute_letter_head_flag = true;
    } else {
      this.institute_letter_head_flag = false;

    }

    if (this.offering_academic_credit_core != null) {
      this.academic_credit_core_flag = true;

    } else {
      this.academic_credit_core_flag = false;

    }

    this.instituteForm.controls['spoc_name'].setValue(this.presidentName);
    this.instituteForm.controls['spoc_contact'].setValue(this.presidentContact);
    this.instituteForm.controls['spoc_email'].setValue(this.presidentEmail);
    this.instituteForm.controls['spoc_designation'].setValue(this.presidentDesignation);
    this.instituteForm.controls['website'].setValue(this.url);
    this.instituteForm.controls['academic_credit_status'].setValue(this.academic_credit_status);
    this.instituteForm.controls['faculties_received_training'].setValue(this.faculties_received_training);
    // this.instituteForm.controls['institute_type'].setValue(instituteType);
    // this.instituteForm.controls['institute_sub_type'].setValue("21");
    this.instituteForm.controls['approvals'].setValue(this.aicte_approve);
    this.instituteForm.controls['nba'].setValue(this.nba1);
    this.instituteForm.controls['naac'].setValue(this.naac1);
    this.instituteForm.controls['courses_offered'].setValue(this.Selected_courses);
    // this.instituteForm.controls['aicte_approved_program'].setValue(this.aicte_approve_program);
    this.instituteForm.controls['pid'].setValue(this.pid);
    this.instituteForm.controls['gender'].setValue(this.gender);
    if (data['instituteDetails']['registration_ranking_no']['0'] != "null" && data['instituteDetails']['registration_ranking_no']['0'] != null && data['instituteDetails']['registration_ranking_no']['0'] != "") {
      this.instituteForm.controls['nirf'].setValue(data['instituteDetails']['national_ranking']['0']);
      this.nirfChecked = data['instituteDetails']['national_ranking']['0'];
      this.instituteForm.controls['nirfField'].setValue(data['instituteDetails']['registration_ranking_no']['0']);
    } else {
      this.instituteForm.controls['nirf'].setValue(false);
      this.nirfChecked = false;
      this.instituteForm.controls['nirfField'].setValue('');
    }




    if (data['instituteDetails']['registration_ranking_no']['1'] != "null" && data['instituteDetails']['registration_ranking_no']['1'] != null && data['instituteDetails']['registration_ranking_no']['1'] != "") {
      this.instituteForm.controls['ariiaField'].setValue(data['instituteDetails']['registration_ranking_no']['1']);
      this.instituteForm.controls['ariia'].setValue(data['instituteDetails']['national_ranking']['1']);
      this.ariiaChecked = data['instituteDetails']['national_ranking']['1'];
    } else {
      this.instituteForm.controls['ariiaField'].setValue(null);
      this.instituteForm.controls['ariia'].setValue('');
      this.ariiaChecked = false;
    }


  }

  setFlag1() {
    this.nba_file_flag = false;
  }
  setFlag2() {
    this.naac_flag = false;
  }
  setFlag3() {
    this.institute_letter_head_flag = false;
  }
  setFlag4() {
    this.academic_credit_core_flag = false;
  }


  handleData2(data) {
    if (data == "Yes") {
      this.uploadDiv2 = true;
      this.instituteForm.get('pid').setValidators(Validators.required);
      this.instituteForm.get('pid').updateValueAndValidity();
    } else if (data == "No") {
      this.instituteForm.get('pid').clearValidators();
      this.instituteForm.get('pid').updateValueAndValidity();
      this.uploadDiv2 = false;
    }
  }

  handleData(data) {

    if (data == "Yes") {
      this.uploadDiv = true;
    } else if (data == "No") {
      this.uploadDiv = false;
    }

  }

  handleData1(data) {
    if (data == "Yes") {
      this.uploadDiv1 = true;
    } else if (data == "No") {
      this.uploadDiv1 = false;
    }
  }

  handleResponse(data) {

    this.nispNominated = data['nispNominated'];
    this.isIAParticipated = data['nispNominated'];

    if (data['nispNominated'] == "Yes") {
      this.nispNominatedFlag = true;
      this.nispDetails = data['nispDetails'];
    }

    this.institute_Letter_Head = data['instituteDetails']['institute_letter_head'];
    this.offering_Academic_Credit_core = data['instituteDetails']['offering_academic_credit_core'];
    this.NBA_File = data['instituteDetails']['nba'];
    this.NAAC_File = data['instituteDetails']['naac'];
  }

  getSelectedApprovalType() {
    this.selected_approval = this.instituteForm.get('approvals').value;
  }

  getSelectedCourses() {
    this.selected_course = this.instituteForm.get('courses_offered').value;
  }

  onSubmit(values) {

    if (this.instituteForm.valid) {
      // ! format data according to backend
      let instituteData = this.formatData(values);


      var formData = new FormData();

      for (let key in instituteData) {
        let value = instituteData[key];
        // Use `key` and `value`
        formData.append(key, value);
      }

      if (this.nbaChecked) {
        // var nba_certificate =
        //   this.nba_certificate && this.nba_certificate.length
        //     ? this.nba_certificate[0]
        //     : null;

        // formData.append('nba_certificate', nba_certificate);
        formData.append('nba_certificate', 'true');
      }

      if (this.naacChecked) {
        // var naac_certificate =
        //   this.naac_certificate && this.naac_certificate.length
        //     ? this.naac_certificate[0]
        //     : null;

        // formData.append('naac_certificate', naac_certificate);
        formData.append('naac_certificate', 'true');
      }

      var institute_letter_head =
        this.institute_letter_head && this.institute_letter_head.length
          ? this.institute_letter_head[0]
          : null;
      formData.append('institute_letter_head', institute_letter_head);


      var offering_academic_credit_core =
        this.offering_academic_credit_core && this.offering_academic_credit_core.length
          ? this.offering_academic_credit_core[0]
          : null;
      formData.append('offering_academic_credit_core', offering_academic_credit_core);


      this.instituteMyCouncilService
        .submitInstituteDetails(formData)
        .subscribe((data) => {
          if (data['message'] == 'Success') {
            this.toastr.success('Details Submitted Successfully!');
            this.dialogRef.close();
            this.instituteMyCouncilService.dataUpdated.next(true);
          } else if (data['message'] == 'The given data was invalid.') {
            this.toastr.error('Kindly Fill all the details.');
          } else if (data['message'] == 'Error') {
            this.toastr.error(data['reason']);
          } else {
            this.toastr.error('Kindly check all the details.');
          }
        });
    }
  }

  checkstate(type) {

    switch (type) {
      case 1:
        // this.ariiaChecked = !this.ariiaChecked;
        if (this.ariiaChecked) {
          this.instituteForm.get('ariiaField').setValidators(Validators.required);
          this.toastr.error('ARIIA Ranking is required !')
        } else {
          this.instituteForm.get('ariiaField').clearValidators();
          this.instituteForm.controls['ariiaField'].setValue('');
        }
        this.instituteForm.get('ariiaField').updateValueAndValidity();
        break;
      case 2:
        // this.nirfChecked = !this.nirfChecked;
        if (this.nirfChecked) {
          this.instituteForm.get('nirfField').setValidators(Validators.required);
          this.toastr.error('NIRF Ranking is required !')
        } else {
          this.instituteForm.get('nirfField').clearValidators();
          this.instituteForm.controls['nirfField'].setValue(null);
        }
        this.instituteForm.get('nirfField').updateValueAndValidity();
      case 3:
        // this.nirfChecked = !this.nirfChecked;
        if (this.nbaChecked) {
          this.instituteForm.get('nba_certificate').setValidators(Validators.required);
          this.toastr.error('NIRF Ranking is required !')
        } else {
          this.instituteForm.get('nba_certificate').clearValidators();
          this.instituteForm.controls['nba_certificate'].setValue(null);
        }
        this.instituteForm.get('nba_certificate').updateValueAndValidity();
      case 4:
        // this.nirfChecked = !this.nirfChecked;
        if (this.naacChecked) {
          this.instituteForm.get('naac_certificate').setValidators(Validators.required);
          this.toastr.error('NIRF Ranking is required !')
        } else {
          this.instituteForm.get('naac_certificate').clearValidators();
          this.instituteForm.controls['naac_certificate'].setValue(null);
        }
        this.instituteForm.get('naac_certificate').updateValueAndValidity();
        break;
      default:
        break;
    }


    // this.nirfChecked = !this.nirfChecked;

  }

  closeDialog() {
    this.dialogRef.close();
  }

  uploadFile(val) {
    this.nbaUploaded = true;
    this.naacUploaded = true;
    this.ariaUploaded = true;
    this.nirfUploaded = true;
  }

  deleteFile(val) {
    this.nbaUploaded = false;
    this.naacUploaded = false;
    this.ariaUploaded = false;
    this.nirfUploaded = false;
  }

  formatData(values) {

    if (this.noneChecked) {
      values['incubation_innovation_facilities'] = ['None'];
    } else {
      values['incubation_innovation_facilities'] = this.incubationList;
    }

    values['accreditation'] = [];

    if (this.nbaChecked) {
      values['accreditation'].push('NBA');
    }

    if (this.naacChecked) {
      values['accreditation'].push('NAAC');
    }

    return values;
  }

  onChangeIncubationList(event) {
    if (event.checked) {
      if (event.source.value == 'None') {
        this.incubationList = [];
        this.noneChecked = true;
      } else {
        this.incubationList.push(event.source.value);
      }
    } else {
      const index = this.incubationList.indexOf(event.source.value, 0);
      if (index > -1) {
        this.incubationList.splice(index, 1);
      }
    }
  }

  setInstituteSubType(type) {
    this.instituteSubType = [];

    switch (type) {
      case '1': {
        this.instituteSubType = this.universities;
        break;
      }
      case '2': {
        this.instituteSubType = this.colleges;
        break;
      }
      case '3': {
        this.instituteSubType = this.standalone;
        break;
      }

      default:
        break;
    }
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }
}
