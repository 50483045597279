import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.css']
})
export class RequestResetComponent implements OnInit {

  constructor(private jarwis: JarwisService,
    private toastr: ToastrService) { }

  ngOnInit() {
  }

  public form = {
    email: null,
  };

  onSubmit() {
    this.jarwis.sendPasswordResetLink(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data) {
    this.form.email = null;
    if (data['1'] == "Success") {
      this.toastr.success('Please check email for Password Resetting')
    } else {
      this.toastr.error('Error !')
    }
  }

  handleError(error) {
    alert('RequestResetComponent - Error!')
  }

}
