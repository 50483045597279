<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Invite Affiliated Institutes to Join IIC</h2>
    </div>

 <div class="row rws-listpage">
 <div class="col-md-12">
  <p><a class="btn btn-primary" routerLink='/institute/add-affiliate-institute/add'>Add and invite institutes</a></p>
  <div class="tableBody">
				<div class="table-responsive">
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th scope="col">S.No.</th>
            <th scope="col">IIC ID</th>
            <th scope="col">Institute Name</th>
            <th scope="col">Address</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Status of Acceptance by Referred Institute</th>
            <th scope="col">IIC Registration Status</th>
            <th scope="col">Action</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let nomination of iicrepository; index as i" >
            <td>{{i+1}}</td>
            <td>{{nomination?.reg_userId}}</td>
            <td>{{nomination?.name}}<br/>{{nomination?.aicte_code}}</td>
            <td>{{nomination?.address_line1}} {{nomination?.address_line2}}</td>
            <td>{{nomination?.city}}, {{nomination?.district}}</td>
            <td>{{nomination?.state}}</td>
            <td>
              <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_email_verification_token!='Verified'">Pending</span>
              <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_email_verification_token=='Verified'">Accepted</span>
            </td>
            <td>
              <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='New'">Pending</span>
              <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='Reopened'">In Process</span>
              <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='Disapproved'">Disapproved</span>
              <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='Approved'">Approved</span>
            </td>
            <td>
              <a *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='Approved'" target="_blank" style="color: black;"
              href="institute/institute-mm-dashboard/{{nomination?.reg_userId}}">
                  View Institute Performance
                </a>
              <p *ngIf="nomination?.reg_userId!='' &&  nomination?.reg_applicationStatus=='New' && nomination?.reg_email_verification_token!='Verified'">
                <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn">
                  <mat-icon>edit</mat-icon>
                  Edit</button>
              </p>
              <p *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='New' && nomination?.reg_email_verification_token!='Verified'">
                <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')"   color="warn">
                  <mat-icon>delete</mat-icon>
                  Delete</button>
              </p>

              <p *ngIf="nomination?.reg_userId==''">
                <button mat-raised-button (click)="onClickAddEvent(nomination)"   color="warn">
                  <mat-icon>add</mat-icon>
                  Send Invitation</button>
              </p>

            </td>



        </tr>
        <tr *ngIf="iicrepository.length== 0" col="7">
            <h3 style="text-align: center">No Data Found</h3>
        </tr>
    </tbody>
</table>
</div>
</div>

 </div>
 </div>

</div>
