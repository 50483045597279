<mat-card class="mat-elevation-z6 m-4 instiNominiCard">

    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            List of Approved Prototype</h2>
    </div>

    <!--<div class="row">
            <div class="col-8 mt-2" style="text-align: center;">
                <h2>Nominate Best 5 Prototype</h2>
            </div>
            <div class="col-2" style="text-align: end;">
                <div *ngIf="!firstPage">
                    <button type="button" class="mr-4" (click)="previousPage()" class="btn btn-success">
                        Previous
                    </button>
                </div>
            </div>
            <div class="col-2">
                <div *ngIf="!lastPage">
                    <button type="button" class="ml-4" (click)="nextPage()" class="btn btn-success">
                        Next
                    </button>
                </div>
            </div>
        </div>-->

    <div class="form-group">
        <div class="">
            <table class="table table-hover table-responsive">
                <thead class="facultyTable">
                    <tr>
                        <!-- <th scope="col">Select</th> -->
                        <th scope="col">NIC ID 2020</th>
                        <th scope="col">Title</th>
                        <th scope="col">Theme</th>
                        <th scope="col">Lead Name /Email /Phone</th>
                        <th scope="col">Mentor Name / Email / Phone</th>
                        <th scope="col">BMC Status</th>
                        <th scope="col">Design Validation Status</th>
                        <th scope="col">Rubric Status </th>
                        <!-- <th scope="col">Mentoring Session Report Status</th> -->
                        <th scope="col">PPT/Video</th>
                        <th scope="col">Status </th>


                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nomination of session">

                        <td>{{nomination?.team_id}}</td>
                        <td>{{nomination?.title}}</td>
                        <td>{{nomination?.theamName}}</td>
                        <!-- <td>{{nomination?.idea_title}}</td> -->


                        <td>
                            <div class="row">
                                <div class="col-12">
                                    <div class="mainDataNomini">
                                        <div class="col-md-5">
                                            <p>Name:</p>
                                        </div>
                                        <div class="col-md-7 mentorNameCol">
                                            <p>{{nomination?.team_lead_name}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="mainDataNomini">
                                        <div class="col-md-5">
                                            <p>Email:</p>
                                        </div>
                                        <div class="col-md-7 mentorNameCol">
                                            <p>{{nomination?.team_lead_email}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="mainDataNomini">
                                        <div class="col-md-5">
                                            <p>Phone:</p>
                                        </div>
                                        <div class="col-md-7 mentorNameCol">
                                            <p>{{nomination?.team_lead_phone}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>






                        <!-- <td> -->


                        <td>
                            <div class="row">
                                <div class="col-12">
                                    <!-- <div class="mainDataNomini"> -->
                                    <div class="col-md-5">
                                        <p>Name:</p>
                                    </div>
                                    <div class="col-md-7 mentorNameCol">
                                        <p>{{nomination?.ia_name}}</p>
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <div class="col-12">
                                    <!-- <div class="mainDataNomini"> -->
                                    <div class="col-md-5">
                                        <p>Email:</p>
                                    </div>
                                    <div class="col-md-7 mentorNameCol">
                                        <p>{{nomination?.ia_email}}</p>
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <div class="col-12">
                                    <!-- <div class="mainDataNomini"> -->
                                    <div class="col-md-5">
                                        <p>Phone:</p>
                                    </div>
                                    <div class="col-md-7 mentorNameCol">
                                        <p>{{nomination?.ia_contact}}</p>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </td>





                        <!-- </td> -->

                        <td *ngIf="nomination?.bmcStatus=='1'">Yes</td>
                        <td *ngIf="nomination?.bmcStatus=='0'">No</td>

                        <td *ngIf="nomination?.ideaValidationStatus=='1'">Yes</td>
                        <td *ngIf="nomination?.ideaValidationStatus=='0'">No</td>

                        <td *ngIf="nomination?.rubricStatus=='1'">Yes</td>
                        <td *ngIf="nomination?.rubricStatus=='0'">No</td>

                        <!-- <td *ngIf="nomination?.mentoringSessionReportStatus=='1'">Yes</td>
                        <td *ngIf="nomination?.mentoringSessionReportStatus=='0'">No</td> -->

                        <td *ngIf="nomination?.pptVideoReportStatus=='1'">Yes</td>
                        <td *ngIf="nomination?.pptVideoReportStatus=='0'">No</td>

                        <td *ngIf="nomination?.eval_stage1=='1'" style="color: green;font-weight: bolder;">Shortlisted
                            for Stage-2
                            evaluation</td>
                        <td *ngIf="nomination?.eval_stage1=='0'" style="color: red;font-weight: bolder;">Not Shortlisted
                        </td>


                    </tr>
                </tbody>
            </table>

        </div>
        <!-- <div class="row">
                <div class="col-md-12">
                    <mat-paginator #paginator
                                    showFirstLastButtons
                                    (page)="nextPage($event)"
                                    [length]="total_pages"
                                    [pageIndex]="frontCurrentPageNo"
                                    [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                                </mat-paginator>

                </div>
            </div> -->
    </div>

    <!-- <div *ngIf="nominationArray.length <= 0">
            <h3 style="text-align: center">prototype for nomination closed</h3>
        </div> -->
</mat-card>

<!-- <mat-card class="mat-elevation-z6 m-4 instiNominiCard">

        <div class="row" color="primary">
            <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px;"> List of Prototype Nominated </h2>
        </div>

        <div class="form-group">
            <div class="m-4">
                <table class="table table-hover">
                    <thead class="facultyTable">
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Theme</th>
                            <th scope="col">Lead Name</th>
                            <th scope="col">Withdraw Nomination</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let nomination of acceptedNomination; let i = index">
                            <td>{{nomination?.idea_title}}</td>
                            <td>{{nomination?.theamName}}</td>
                            <td>{{nomination?.team_lead_name}}</td>
                            <td>

                              <button mat-raised-button color="primary"
                              class="btn btn-success btnDownSuccess" disabled>Withdraw</button>
                </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </mat-card> -->