<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="3" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Ranking List</h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col"> Sr. No.</th>
                            <th scope="col">Event Name</th>
                            <th scope="col">Total Marks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let ranking of rankings| paginate: { itemsPerPage: 5, currentPage: page }; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{ranking.title}}</td>
                            <td>{{ranking.totalmarks}}</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls class="annular-pagination float-right" maxSize="2"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>