<app-online-session-navbar></app-online-session-navbar>
<br>
<div *ngIf="showButton==1" >
  <!-- <a href="https://ia.mic.gov.in/ambassador/sign-up" target="_blank" class="btn btn-success btnDownSuccess1" style="margin: 40px;">Register</a> -->
</div>

<section class="topCard">
	<div class="container">
		<div class="row bottomTextVideo">
		<div class="col-md-12">
		  <mat-card class="sessionVideoCardTop">
			<p><strong>Step1:</strong>View the session video thoroughly. Status of Session Video field will be
updated automatically, if you have watched entire session.</p>
		   <p> <strong>Step2:</strong>Assessment link will be enabled once you have watched entire session.
Complete the assessment.</p>
<p> <strong>Step3:</strong>Click on checkbox to update the <strong>Assessment Status</strong></p>
		   <!-- <p>Last date to complete the sessions and assessment is 15th November 2020. -->
			<!-- </p> -->
			<p> <strong>You will receive an email for the Innovation Ambassador’s Public Profile Registration
from MIC.</strong></p>
		</mat-card>
		</div>
		<div class="col-md-12">
<div class="marqueeBox"><marquee><a href="https://t.me/joinchat/PtmQ3RVJAwK99afXjDH_hA" target="_blank"><p>Join the Telegram Group for regular updates</p></a></marquee></div></div>
		</div>
	</div>
</section>
<div *ngIf="showButton==0">
<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12" >
                <table class="table table-hover table-responsive" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="7" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Detail of Sessions </h1>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" width="5%"> Sr. No.</th>
                            <th scope="col" width="15%">Session Theme</th>
                            <th scope="col" width="30%">Session Title</th>
                            <th scope="col" width="10%">Session Video</th>
                            <th scope="col" width="15%">Status of Session Video</th>
							<th scope="col" width="15%">Assessment</th>
							<th scope="col" width="10%">Assessment Status</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="list?.length">
                        <tr *ngFor="let item of list; let i = index">

                            <td>{{i + 1}}</td>
                            <!-- <td>{{item.ses_id}}</td> -->
                            <!-- <td>{{item.video_brief}}</td> -->
                            <td>{{item.video_theme}}<br>
							<span style="color:red;font-style: italic;" *ngIf="item?.ses_id==1">(Mandatory)</span>
							</td>
                            <td>{{item.video_title}}</td>
                            <td class="downloadD"><a routerLink="/video-page-IA/{{item.ses_id}}"  class="btn btn-success btnDownSuccess">Watch</a></td>
                      <td *ngIf="item.watch_status=='1'">Completed</td>
                      <td *ngIf="item.watch_status=='0'"> Not Completed</td>

                      <td *ngIf="item.watch_status=='1'">
					  <a class="btn btn-success btnDownSuccess" target="_blank" href="{{item.assisment}}">Assessment Link</a>
					  </td>
                      <td *ngIf="item.watch_status=='0'">
					  <button class="btn btn-success btnDownSuccess" disabled >Assessment Link</button>
					  </td>
					  <td *ngIf="item.watch_status=='1'">
					  <input type="checkbox" name='assessment_form_status' id="assessment_form_status" (click)="assessmentFormUpdate(item.online_session_1_id,item.assessment_form_status)" *ngIf="item.assessment_form_status=='1'" checked />

					  <input type="checkbox" name='assessment_form_status' id="assessment_form_status" (click)="assessmentFormUpdate(item.online_session_1_id,item.assessment_form_status)" *ngIf="item.assessment_form_status=='0'" />
					  </td>
                      <td *ngIf="item.watch_status=='0'">
					  <input type="checkbox" name='assessment_form_status' id="assessment_form_status" disabled />
					  </td>

                          </tr>
                    </tbody>
                    <tbody *ngIf="!list?.length">
                        <tr style="text-align: center;">
                            <td colspan="7">No videos to watch</td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>
    </div>
</div>
</div>
<app-iic-web-footer></app-iic-web-footer>
