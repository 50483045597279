import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { InstituteDetails } from './institute-details';
import { InstituteDetailsDialogComponent } from './institute-details-dialog/institute-details-dialog.component';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import * as myGlobals from '../../../../../../app/app.component';


@Component({
  selector: 'app-institute-details',
  templateUrl: './institute-details.component.html',
  styleUrls: ['./institute-details.component.css'],
})
export class InstituteDetailsComponent implements OnInit {
  instituteDetails: InstituteDetails;
  instituteData: any;
  instituteType: any;
  instituteSubType: any;
  baseUrl = myGlobals.baseImageUrl;
  readMore: boolean = true;
  data1:any;
  ForWhom:any;
  //  baseImageUrl1 =  'https://qamicapi.aicte-india.org';
  constructor(
    public dialog: MatDialog,
    private instituteMyCouncilService: InstituteMyCouncilService
  ) { }

  ngOnInit(): void {
    this.ForWhom=localStorage.getItem('ForWhom');
    this.getInstituteDetails();
	  this.instituteMyCouncilService
      .getInstituteDetails()
      .subscribe((data1) => this.handleResponse1(data1));
  }

  getInstituteDetails() {
    this.instituteMyCouncilService
      .getInstituteDetails()
      .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data) {
    this.instituteType = data['instituteTypeAndSubType'][0]['type_subtype'];
    this.instituteSubType = data['instituteTypeAndSubType'][1]['type_subtype'];
    this.instituteDetails = data['instituteDetails'];
  }
  
  handleResponse1(data1) {
    this.instituteData= data1['instituteDetails'];
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteDetailsDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      this.getInstituteDetails();
    });
  }
}
