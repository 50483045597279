import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ToastrService, Toast } from 'ngx-toastr';

@Component({
  selector: 'app-institute-declare-semesters',
  templateUrl: './institute-declare-semesters.component.html',
  styleUrls: ['./institute-declare-semesters.component.css']
})

export class InstituteDeclareSemestersComponent implements OnInit {

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  declareSemesters: DeclareSemesterModal[] = [];
  semester: any;
  id: any;
  readMore: boolean = true;


  constructor(public dialog: MatDialog,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getDeclareSemesters(this.currentPageNo);
  }

  nextPage() {
    this.currentPageNo++;
    this.getDeclareSemesters(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getDeclareSemesters(this.currentPageNo);
  }

  getDeclareSemesters(pageNo) {
    this.myCouncilService.getDeclareSemesters(pageNo).subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.declareSemesters = [];

    data['data'].forEach((element) => {
      this.declareSemesters.push(element);
    });

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

    this.myCouncilService.dataUpdated.next(true);

  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteDeclareSemesterDialog, {});

    dialogRef.afterClosed().subscribe((result) => {
      this.getDeclareSemesters(this.currentPageNo)
    });
  }
  openEditDialog(data) {
    const dialogRef = this.dialog.open(InstituteDeclareSemesterDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDeclareSemesters(this.currentPageNo)
    });
  }

  deleteDialog(data) {
    const dialogRef = this.dialog.open(InstituteDeclareSemestersDeleteDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDeclareSemesters(this.currentPageNo)
    });
  }


  deleteInstituteDetails() {
    alert('Are you sure you want to delete Semester Details');
  }

}



@Component({
  selector: 'app-institute-declare-semesters-dialog',
  templateUrl: 'institute-declare-semesters-dialog.html',
})
export class InstituteDeclareSemesterDialog implements OnInit {

  minDate1: any;
  minDate2: any;
  minDate3: any;
  minDate4: any;
  minDate5: any;
  minDate6: any;
  minDate: any;
  declareSemesterForm: FormGroup;
  testing: any;
  dialogData: any;
  show: boolean;
  show1: boolean;
  fbl: any;
  duration_semester_first_from: any;
  semester_vacation_first_from: any;
  duration_semester_sec_from: any;
  study_holidays_sec_from: any;
  study_holidays_first_from: any;
  semester_vacation_sec_from: any;
  id1: any;
  id: any;
  duration_semester_first_to: any;
  duration_semester_sec_to: any;
  semester_vacation_first_to: any;
  semester_vacation_sec_to: any;
  study_holidays_sec_to: any;
  study_holidays_first_to: any;

  constructor(
    private formBuilder: FormBuilder,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteDeclareSemestersComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
    }
  }

  ngOnInit() {
    this.declareSemesterForm = this.formBuilder.group({
      'duration_semester_first_from': ['', Validators.required],
      'duration_semester_sec_from': ['', Validators.required],
      'duration_semester_first_to': [null, Validators.required],
      'duration_semester_sec_to': [null, Validators.required],
      // 'study_holidays_first_from': [null, Validators.required],
      // 'study_holidays_sec_from': [null, Validators.required],
      // 'study_holidays_sec_to': [null, Validators.required],
      // 'study_holidays_first_to': [null, Validators.required],
      // 'semester_vacation_first_from': [null, Validators.required],
      // 'semester_vacation_sec_from': [null, Validators.required],
      // 'semester_vacation_first_to': [null, Validators.required],
      // 'semester_vacation_sec_to': [null, Validators.required],
      'id': [null],
    });

    this.declareSemesterForm.get('duration_semester_first_from').valueChanges.subscribe(
      data => {
        this.minDate1 = data
      }
    )

    // this.declareSemesterForm.get('study_holidays_first_from').valueChanges.subscribe(
    //   data => {
    //     this.minDate2 = data
    //   }
    // )

    // this.declareSemesterForm.get('semester_vacation_first_from').valueChanges.subscribe(
    //   data => {
    //     this.minDate3 = data
    //   }
    // )

    this.declareSemesterForm.get('duration_semester_sec_from').valueChanges.subscribe(
      data => {
        this.minDate4 = data
      }
    )

    // this.declareSemesterForm.get('study_holidays_sec_from').valueChanges.subscribe(
    //   data => {
    //     this.minDate5 = data
    //   }
    // )

    // this.declareSemesterForm.get('semester_vacation_sec_from').valueChanges.subscribe(
    //   data => {
    //     this.minDate6 = data
    //   }
    // )

    this.fbl = this.data;
    this.duration_semester_first_from = this.data['duration_semester_first_from'];
    this.duration_semester_sec_from = this.data['duration_semester_sec_from'];
    this.duration_semester_first_to = this.data['duration_semester_first_to'];
    this.duration_semester_sec_to = this.data['duration_semester_sec_to'];
    // this.study_holidays_first_from = this.data['study_holidays_first_from'];
    // this.semester_vacation_first_from = this.data['semester_vacation_first_from'];
    // this.study_holidays_sec_from = this.data['study_holidays_sec_from'];
    // this.semester_vacation_sec_from = this.data['semester_vacation_sec_from'];
    // this.semester_vacation_first_to = this.data['semester_vacation_first_to'];
    // this.semester_vacation_sec_to = this.data['semester_vacation_sec_to'];
    // this.study_holidays_sec_to = this.data['study_holidays_sec_to'];
    // this.study_holidays_first_to = this.data['study_holidays_first_to'];

    this.id = this.data['id'];

    this.declareSemesterForm.controls['duration_semester_first_from'].setValue(new Date(this.duration_semester_first_from));
    //this.declareSemesterForm.controls['study_holidays_first_from'].setValue(new Date(this.study_holidays_first_from));
    //this.declareSemesterForm.controls['semester_vacation_first_from'].setValue(new Date(this.semester_vacation_first_from));
    //this.declareSemesterForm.controls['study_holidays_sec_from'].setValue(new Date(this.study_holidays_sec_from));
    //this.declareSemesterForm.controls['semester_vacation_sec_from'].setValue(new Date(this.semester_vacation_sec_from));
    //this.declareSemesterForm.controls['study_holidays_first_to'].setValue(new Date(this.study_holidays_first_to));
    //this.declareSemesterForm.controls['study_holidays_sec_to'].setValue(new Date(this.study_holidays_sec_to));
    //this.declareSemesterForm.controls['semester_vacation_sec_to'].setValue(new Date(this.semester_vacation_sec_to));
    this.declareSemesterForm.controls['duration_semester_sec_to'].setValue(new Date(this.duration_semester_sec_to));
    //this.declareSemesterForm.controls['semester_vacation_first_to'].setValue(new Date(this.semester_vacation_first_to));
    //this.declareSemesterForm.controls['semester_vacation_sec_from'].setValue(new Date(this.semester_vacation_sec_from));
    this.declareSemesterForm.controls['duration_semester_first_to'].setValue(new Date(this.duration_semester_first_to));
    this.declareSemesterForm.controls['duration_semester_sec_from'].setValue(new Date(this.duration_semester_sec_from));

    this.declareSemesterForm.controls['id'].setValue(this.id);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) {
    values['duration_semester_first_from'] = this.changeDate(values['duration_semester_first_from']);
    values['duration_semester_first_to'] = this.changeDate(values['duration_semester_first_to']);
    values['duration_semester_sec_from'] = this.changeDate(values['duration_semester_sec_from']);
    values['duration_semester_sec_to'] = this.changeDate(values['duration_semester_sec_to']);
    values['study_holidays_first_to'] = this.changeDate(values['study_holidays_first_to']);
    values['study_holidays_sec_to'] = this.changeDate(values['study_holidays_sec_to']);
    values['study_holidays_first_from'] = this.changeDate(values['study_holidays_first_from']);
    values['study_holidays_sec_from'] = this.changeDate(values['study_holidays_sec_from']);
    values['semester_vacation_first_to'] = this.changeDate(values['semester_vacation_first_to']);
    values['semester_vacation_sec_to'] = this.changeDate(values['semester_vacation_sec_to']);
    values['semester_vacation_first_from'] = this.changeDate(values['semester_vacation_first_from']);
    values['semester_vacation_sec_from'] = this.changeDate(values['semester_vacation_sec_from']);

    if (this.show1) {
      this.myCouncilService.submitDeclareSemesters(values).subscribe(
        data => this.handleResponse(data)
      )
    }
    else {
      this.myCouncilService.updateDeclareSemester(values).subscribe(
        data => this.handleResponse1(data)
      );
    }
  }

  handleResponse1(data) {
    if (data['status'] == "1") {
      this.toastr.success("Details Updated Successfully !");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
  }

  handleResponse(data) {
    if (data['status'] == "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
    this.myCouncilService.dataUpdated.next(true);
  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

}


export class DeclareSemesterModal {
  id: number;
  duration_semester_first_from: any;
  duration_semester_first_to: string;
  duration_semester_sec_from: string;
  duration_semester_sec_to: string;
  semester_vacation_first_from: string;
  semester_vacation_first_to: string;
  semester_vacation_sec_from: string;
  semester_vacation_sec_to: string;
  study_holidays_first_from: string;
  study_holidays_first_to: string;
  study_holidays_sec_from: string;
  study_holidays_sec_to: string;
  userId: string;
}


@Component({
  selector: 'app-institute-declare-semesters-delete-dialog',
  templateUrl: './institute-declare-semesters-delete-dialog.html',
  // styleUrls: ['./institute-declare-semesters.component.css']
})

export class InstituteDeclareSemestersDeleteDialogComponent implements OnInit {
  id: any;

  constructor(public dialog: MatDialog,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteDeclareSemestersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.id = this.data['id'];
  }

  deleteDialog() {
    this.myCouncilService.deleteDeclareSemesters(this.id).subscribe(
      data => this.handleData(data)
    );
  }

  handleData(data) {
    if (data['status'] = "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Not able to delete !');
    }

    this.myCouncilService.dataUpdated.next(true);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
