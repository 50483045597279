import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { JarwisService } from 'src/app/Services/jarwis.service';
import * as myGlobals from '../../../../../../app.component';

@Component({
  selector: 'app-nominate-form1',
  templateUrl: './nominate-form1.component.html',
  styleUrls: ['./nominate-form1.component.css']
})
export class NominateForm1Component implements OnInit {
  head: any[];
  public impactAnalysis: FormGroup;
  baseImageUrl = myGlobals.baseImageUrl2;
  externalCV: FileList | null;
  report: any;
  convenor_name: any;
  lengths: any;
  mandate: number;
  id1: number;
  convenor_contact: any;
  convenor_designation: any;
  convenor_email: any;
  isVisible: number=0;

  constructor(private fb: FormBuilder,
    private jarwis: JarwisService,
    private activityService: InstituteActivityService,private router:Router,
    private myCouncilService: InstituteMyCouncilService,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.impactAnalysis = this.fb.group({
      'faculty': [null, Validators.required],
    });

    // this.getHeads();
    this.getTable();
    this.checkmandate();
  }

  checkmandate(){
    this.activityService.getcheckmandate().subscribe(
      data => this.setcheckMandate(data)
    );
  }

  setcheckMandate(data){
    if(data['status']==1){
      this.mandate=1
    }else{
      this.mandate=0
    }
  }

  signedForm(){
    this.id1=1;
    this.router.navigateByUrl('/institute/bank-form/2')
  }

  // setMentorWillingness(data){

  //   if(this.disableStatus==0){
  //     this.mentor=[];
  //     for(let i=0;i<data['hoi'].length;i++){
  //       this.mentor.push(data['hoi'][i])
  //     }
  //   }else{

  //     this.mentor=[];

  //   }
  //     }



  getTable(){
    this.innovationService.getSpeakerDetails().subscribe(
       data => this.setImpactTableData(data)
     );

   }
   setImpactTableData(data){

this.lengths=data.length;
     if(this.lengths!=0){
     this.convenor_name=data[0]['name'];
     this.convenor_email=data[0]['email'];
     this.convenor_contact=data[0]['mobile'];
     this.convenor_designation=data[0]['designation'];

    this.report=data['0']['file_upload']
}else{
  this.convenor_name=0

}

if(this.convenor_name!=0 && this.convenor_name!= 0 ){
  this.head=[];

      }else{
        this.head=[];
        this.innovationService.getHeads().subscribe(
          data => this.setHeadData(data) );

}
   }




  getHeads(){
    this.innovationService.getHeads().subscribe(
      data => this.setHeadData(data)
    );
  }

  setHeadData(data){
    this.head=[];
    {
       for(let i=0;i<data['hoi'].length;i++){
         this.head.push(data['hoi'][i])
       }
     }
  }



  onSubmit(data){

    var cvFile =
    this.externalCV && this.externalCV.length ? this.externalCV[0] : null;

    var formData = new FormData();

    // if(this.externalCV!=null && this.externalCV!=undefined ){
      // formData.append('file', cvFile);
      formData.append('faculty',this.impactAnalysis.get('faculty').value);
      this.innovationService.submitSpeakerDetails(formData).subscribe(
        data => this.handleResponseData(data));
//   }else{
//   this.toastr.error("Upload of Mandate form is mandatory!")
// }
}
handleResponseData(data){
  if(data['status']=="1"){
    this.toastr.success(data['msg'])
    this.getTable();

  }
  else{
    this.toastr.error(data['msg']);

  }
}


onEditClick(){

  if (confirm("To change the coordinator, Use delete option!")) {
    console.log("edit")
    }
}


onDeleteClick(){

  if (confirm("Are you sure you want to delete?")) {
    this.innovationService.deleteSpeakerDetails().subscribe(
      data => this.handleResponse1(data)
    );
    }
}

handleResponse1(data){
if(data['status']=='1'){
this.toastr.success(data['msg']);
this.getTable();
// this.getcheckSessionDetails();

}
else{
this.toastr.error(data['msg'])
}
   }






}
