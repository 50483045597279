<!--meat tags-->
<meta charset="utf-8">
<meta name="author" content="MHRD">
<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
<!--end of meat tags-->

<!--link-->
<link rel="stylesheet" href="/assets/css/bootstrap.min.css">
<script src="assets/js/jquery-1.12.4.min.js"></script>
<link rel="stylesheet" type="text/css" href="/assets/css/progressBar.css">
<!-- <link rel="stylesheet" href="/assets/css/style.css" type="text/css"> -->
<link rel="stylesheet" href="/assets/css/bootstrap.min.css" type="text/css">
<link rel="stylesheet" href="/assets/css/slick.css" type="text/css">
<link rel="stylesheet" href="/assets/css/slick-theme.css" type="text/css">
<link rel="stylesheet" href="/assets/css/fonts.css" type="text/css">
<link rel="stylesheet" href="/assets/css/reponsive.css" type="text/css">
<link rel="stylesheet" href="/assets/css/lightbox.min.css" type="text/css">

<link rel="stylesheet" href="/assets/css/font-awesome.min.css">


<link href="https://fonts.googleapis.com/css2?family=Merienda&amp;family=Oleo+Script:wght@700&amp;family=Roboto&amp;display=swap" rel="stylesheet">
<!--end of link-->

<!-- Global site tag (gtag.js) - Google Analytics -->

<script async src="https://www.googletagmanager.com/gtag/js?id=UA-168661400-3"></script>



<style type="text/css">
    img.wp-smiley,
    img.emoji {
        display: inline !important;
        border: none !important;
        box-shadow: none !important;
        height: 1em !important;
        width: 1em !important;
        margin: 0 .07em !important;
        vertical-align: -0.1em !important;
        background: none !important;
        padding: 0 !important;
    }
</style>

<style>
    button#responsive-menu-button,
    #responsive-menu-container {
        display: none;
        -webkit-text-size-adjust: 100%;
    }
    
    @media screen and (max-width: 768px) {
        #responsive-menu-container {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 99998;
            padding-bottom: 5px;
            margin-bottom: -5px;
            outline: 1px solid transparent;
            overflow-y: auto;
            overflow-x: hidden;
        }
        #responsive-menu-container .responsive-menu-search-box {
            width: 100%;
            padding: 0 2%;
            border-radius: 2px;
            height: 50px;
            -webkit-appearance: none;
        }
        #responsive-menu-container.push-left,
        #responsive-menu-container.slide-left {
            transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            -moz-transform: translateX(-100%);
        }
        .responsive-menu-open #responsive-menu-container.push-left,
        .responsive-menu-open #responsive-menu-container.slide-left {
            transform: translateX(0);
            -ms-transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
        }
        #responsive-menu-container.push-top,
        #responsive-menu-container.slide-top {
            transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            -webkit-transform: translateY(-100%);
            -moz-transform: translateY(-100%);
        }
        .responsive-menu-open #responsive-menu-container.push-top,
        .responsive-menu-open #responsive-menu-container.slide-top {
            transform: translateY(0);
            -ms-transform: translateY(0);
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
        }
        #responsive-menu-container.push-right,
        #responsive-menu-container.slide-right {
            transform: translateX(100%);
            -ms-transform: translateX(100%);
            -webkit-transform: translateX(100%);
            -moz-transform: translateX(100%);
        }
        .responsive-menu-open #responsive-menu-container.push-right,
        .responsive-menu-open #responsive-menu-container.slide-right {
            transform: translateX(0);
            -ms-transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
        }
        #responsive-menu-container.push-bottom,
        #responsive-menu-container.slide-bottom {
            transform: translateY(100%);
            -ms-transform: translateY(100%);
            -webkit-transform: translateY(100%);
            -moz-transform: translateY(100%);
        }
        .responsive-menu-open #responsive-menu-container.push-bottom,
        .responsive-menu-open #responsive-menu-container.slide-bottom {
            transform: translateY(0);
            -ms-transform: translateY(0);
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
        }
        #responsive-menu-container,
        #responsive-menu-container:before,
        #responsive-menu-container:after,
        #responsive-menu-container *,
        #responsive-menu-container *:before,
        #responsive-menu-container *:after {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }
        #responsive-menu-container #responsive-menu-search-box,
        #responsive-menu-container #responsive-menu-additional-content,
        #responsive-menu-container #responsive-menu-title {
            padding: 25px 5%;
        }
        #responsive-menu-container #responsive-menu,
        #responsive-menu-container #responsive-menu ul {
            width: 100%;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu {
            display: none;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu.responsive-menu-submenu-open {
            display: block;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu-depth-1 a.responsive-menu-item-link {
            padding-left: 10%;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu-depth-2 a.responsive-menu-item-link {
            padding-left: 15%;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu-depth-3 a.responsive-menu-item-link {
            padding-left: 20%;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu-depth-4 a.responsive-menu-item-link {
            padding-left: 25%;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu-depth-5 a.responsive-menu-item-link {
            padding-left: 30%;
        }
        #responsive-menu-container li.responsive-menu-item {
            width: 100%;
            list-style: none;
        }
        #responsive-menu-container li.responsive-menu-item a {
            width: 100%;
            display: block;
            text-decoration: none;
            padding: 0 5%;
            position: relative;
        }
        #responsive-menu-container li.responsive-menu-item a .fa {
            margin-right: 15px;
        }
        #responsive-menu-container li.responsive-menu-item a .responsive-menu-subarrow {
            position: absolute;
            top: 0;
            bottom: 0;
            text-align: center;
            overflow: hidden;
        }
        #responsive-menu-container li.responsive-menu-item a .responsive-menu-subarrow .fa {
            margin-right: 0;
        }
        button#responsive-menu-button .responsive-menu-button-icon-inactive {
            display: none;
        }
        button#responsive-menu-button {
            z-index: 99999;
            display: none;
            overflow: hidden;
            outline: none;
        }
        button#responsive-menu-button img {
            max-width: 100%;
        }
        .responsive-menu-label {
            display: inline-block;
            font-weight: 600;
            margin: 0 5px;
            vertical-align: middle;
        }
        .responsive-menu-label .responsive-menu-button-text-open {
            display: none;
        }
        .responsive-menu-accessible {
            display: inline-block;
        }
        .responsive-menu-accessible .responsive-menu-box {
            display: inline-block;
            vertical-align: middle;
        }
        .responsive-menu-label.responsive-menu-label-top,
        .responsive-menu-label.responsive-menu-label-bottom {
            display: block;
            margin: 0 auto;
        }
        button#responsive-menu-button {
            padding: 0 0;
            display: inline-block;
            cursor: pointer;
            transition-property: opacity, filter;
            transition-duration: 0.15s;
            transition-timing-function: linear;
            font: inherit;
            color: inherit;
            text-transform: none;
            background-color: transparent;
            border: 0;
            margin: 0;
            overflow: visible;
        }
        .responsive-menu-box {
            width: 25px;
            height: 19px;
            display: inline-block;
            position: relative;
        }
        .responsive-menu-inner {
            display: block;
            top: 50%;
            margin-top: -1.5px;
        }
        .responsive-menu-inner,
        .responsive-menu-inner::before,
        .responsive-menu-inner::after {
            width: 25px;
            height: 3px;
            background-color: #ffffff;
            border-radius: 4px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }
        .responsive-menu-open .responsive-menu-inner,
        .responsive-menu-open .responsive-menu-inner::before,
        .responsive-menu-open .responsive-menu-inner::after {
            background-color: #ffffff;
        }
        button#responsive-menu-button:hover .responsive-menu-inner,
        button#responsive-menu-button:hover .responsive-menu-inner::before,
        button#responsive-menu-button:hover .responsive-menu-inner::after,
        button#responsive-menu-button:hover .responsive-menu-open .responsive-menu-inner,
        button#responsive-menu-button:hover .responsive-menu-open .responsive-menu-inner::before,
        button#responsive-menu-button:hover .responsive-menu-open .responsive-menu-inner::after,
        button#responsive-menu-button:focus .responsive-menu-inner,
        button#responsive-menu-button:focus .responsive-menu-inner::before,
        button#responsive-menu-button:focus .responsive-menu-inner::after,
        button#responsive-menu-button:focus .responsive-menu-open .responsive-menu-inner,
        button#responsive-menu-button:focus .responsive-menu-open .responsive-menu-inner::before,
        button#responsive-menu-button:focus .responsive-menu-open .responsive-menu-inner::after {
            background-color: #ffffff;
        }
        .responsive-menu-inner::before,
        .responsive-menu-inner::after {
            content: "";
            display: block;
        }
        .responsive-menu-inner::before {
            top: -8px;
        }
        .responsive-menu-inner::after {
            bottom: -8px;
        }
        .responsive-menu-boring .responsive-menu-inner,
        .responsive-menu-boring .responsive-menu-inner::before,
        .responsive-menu-boring .responsive-menu-inner::after {
            transition-property: none;
        }
        .responsive-menu-boring.is-active .responsive-menu-inner {
            transform: rotate(45deg);
        }
        .responsive-menu-boring.is-active .responsive-menu-inner::before {
            top: 0;
            opacity: 0;
        }
        .responsive-menu-boring.is-active .responsive-menu-inner::after {
            bottom: 0;
            transform: rotate(-90deg);
        }
        button#responsive-menu-button {
            width: 50px;
            height: 50px;
            position: fixed;
            top: 52px;
            left: 8%;
            background: #080808
        }
        .responsive-menu-open button#responsive-menu-button {
            background: #030303
        }
        .responsive-menu-open button#responsive-menu-button:hover,
        .responsive-menu-open button#responsive-menu-button:focus,
        button#responsive-menu-button:hover,
        button#responsive-menu-button:focus {
            background: #141010
        }
        button#responsive-menu-button .responsive-menu-box {
            color: #ffffff;
        }
        .responsive-menu-open button#responsive-menu-button .responsive-menu-box {
            color: #ffffff;
        }
        .responsive-menu-label {
            color: #ffffff;
            font-size: 14px;
            line-height: 13px;
        }
        button#responsive-menu-button {
            display: inline-block;
            transition: transform 0.5s, background-color 0.5s;
        }
        #responsive-menu-container {
            width: 75%;
            left: 0;
            transition: transform 0.5s;
            text-align: left;
            background: #212121;
        }
        #responsive-menu-container #responsive-menu-wrapper {
            background: #212121;
        }
        #responsive-menu-container #responsive-menu-additional-content {
            color: #ffffff;
        }
        #responsive-menu-container .responsive-menu-search-box {
            background: #ffffff;
            border: 2px solid #dadada;
            color: #333333;
        }
        #responsive-menu-container .responsive-menu-search-box:-ms-input-placeholder {
            color: #c7c7cd;
        }
        #responsive-menu-container .responsive-menu-search-box::-webkit-input-placeholder {
            color: #c7c7cd;
        }
        #responsive-menu-container .responsive-menu-search-box:-moz-placeholder {
            color: #c7c7cd;
            opacity: 1;
        }
        #responsive-menu-container .responsive-menu-search-box::-moz-placeholder {
            color: #c7c7cd;
            opacity: 1;
        }
        #responsive-menu-container .responsive-menu-item-link,
        #responsive-menu-container #responsive-menu-title,
        #responsive-menu-container .responsive-menu-subarrow {
            transition: background-color 0.5s, border-color 0.5s, color 0.5s;
        }
        #responsive-menu-container #responsive-menu-title {
            background-color: #212121;
            color: #ffffff;
            font-size: 13px;
            text-align: left;
        }
        #responsive-menu-container #responsive-menu-title a {
            color: #ffffff;
            font-size: 13px;
            text-decoration: none;
        }
        #responsive-menu-container #responsive-menu-title a:hover {
            color: #ffffff;
        }
        #responsive-menu-container #responsive-menu-title:hover {
            background-color: #212121;
            color: #ffffff;
        }
        #responsive-menu-container #responsive-menu-title:hover a {
            color: #ffffff;
        }
        #responsive-menu-container #responsive-menu-title #responsive-menu-title-image {
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            margin-bottom: 15px;
        }
        #responsive-menu-container #responsive-menu-title #responsive-menu-title-image img {
            max-width: 100%;
        }
        #responsive-menu-container #responsive-menu>li.responsive-menu-item:first-child>a {
            border-top: 1px solid #212121;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item .responsive-menu-item-link {
            font-size: 13px;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item a {
            line-height: 40px;
            border-bottom: 1px solid #212121;
            color: #ffffff;
            background-color: #212121;
            height: 40px;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item a:hover {
            color: #ffffff;
            background-color: #3f3f3f;
            border-color: #212121;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item a:hover .responsive-menu-subarrow {
            color: #ffffff;
            border-color: #3f3f3f;
            background-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item a:hover .responsive-menu-subarrow.responsive-menu-subarrow-active {
            color: #ffffff;
            border-color: #3f3f3f;
            background-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item a .responsive-menu-subarrow {
            right: 0;
            height: 39px;
            line-height: 39px;
            width: 40px;
            color: #ffffff;
            border-left: 1px solid #212121;
            background-color: #212121;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item a .responsive-menu-subarrow.responsive-menu-subarrow-active {
            color: #ffffff;
            border-color: #212121;
            background-color: #212121;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item a .responsive-menu-subarrow.responsive-menu-subarrow-active:hover {
            color: #ffffff;
            border-color: #3f3f3f;
            background-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-item a .responsive-menu-subarrow:hover {
            color: #ffffff;
            border-color: #3f3f3f;
            background-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-current-item>.responsive-menu-item-link {
            background-color: #212121;
            color: #ffffff;
            border-color: #212121;
        }
        #responsive-menu-container #responsive-menu li.responsive-menu-current-item>.responsive-menu-item-link:hover {
            background-color: #3f3f3f;
            color: #ffffff;
            border-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item .responsive-menu-item-link {
            font-size: 13px;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item a {
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #212121;
            color: #ffffff;
            background-color: #212121;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item a:hover {
            color: #ffffff;
            background-color: #3f3f3f;
            border-color: #212121;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item a:hover .responsive-menu-subarrow {
            color: #ffffff;
            border-color: #3f3f3f;
            background-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item a:hover .responsive-menu-subarrow.responsive-menu-subarrow-active {
            color: #ffffff;
            border-color: #3f3f3f;
            background-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item a .responsive-menu-subarrow {
            right: 0;
            height: 39px;
            line-height: 39px;
            width: 40px;
            color: #ffffff;
            border-left: 1px solid #212121;
            background-color: #212121;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item a .responsive-menu-subarrow.responsive-menu-subarrow-active {
            color: #ffffff;
            border-color: #212121;
            background-color: #212121;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item a .responsive-menu-subarrow.responsive-menu-subarrow-active:hover {
            color: #ffffff;
            border-color: #3f3f3f;
            background-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-item a .responsive-menu-subarrow:hover {
            color: #ffffff;
            border-color: #3f3f3f;
            background-color: #3f3f3f;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-current-item>.responsive-menu-item-link {
            background-color: #212121;
            color: #ffffff;
            border-color: #212121;
        }
        #responsive-menu-container #responsive-menu ul.responsive-menu-submenu li.responsive-menu-current-item>.responsive-menu-item-link:hover {
            background-color: #3f3f3f;
            color: #ffffff;
            border-color: #3f3f3f;
        }
        #menu-top-menu {
            display: none !important;
        }
    }
    
    .marqInstMIC {
        font-family: calibri;
        font-weight: bold;
        height: 35px;
        color: #e51b00;
        margin-top: -7px;
        padding: 5px 0 4px 0;
        position: absolute;
        top: 0;
    }
</style>
<script>
    jQuery(document).ready(function($) {

        var ResponsiveMenu = {
            trigger: '#responsive-menu-button',
            animationSpeed: 500,
            breakpoint: 768,
            pushButton: 'off',
            animationType: 'slide',
            animationSide: 'left',
            pageWrapper: '',
            isOpen: false,
            triggerTypes: 'click',
            activeClass: 'is-active',
            container: '#responsive-menu-container',
            openClass: 'responsive-menu-open',
            accordion: 'off',
            activeArrow: '▲',
            inactiveArrow: '▼',
            wrapper: '#responsive-menu-wrapper',
            closeOnBodyClick: 'off',
            closeOnLinkClick: 'off',
            itemTriggerSubMenu: 'off',
            linkElement: '.responsive-menu-item-link',
            subMenuTransitionTime: 200,
            openMenu: function() {
                $(this.trigger).addClass(this.activeClass);
                $('html').addClass(this.openClass);
                $('.responsive-menu-button-icon-active').hide();
                $('.responsive-menu-button-icon-inactive').show();
                this.setButtonTextOpen();
                this.setWrapperTranslate();
                this.isOpen = true;
            },
            closeMenu: function() {
                $(this.trigger).removeClass(this.activeClass);
                $('html').removeClass(this.openClass);
                $('.responsive-menu-button-icon-inactive').hide();
                $('.responsive-menu-button-icon-active').show();
                this.setButtonText();
                this.clearWrapperTranslate();
                this.isOpen = false;
            },
            setButtonText: function() {
                if ($('.responsive-menu-button-text-open').length > 0 && $('.responsive-menu-button-text').length > 0) {
                    $('.responsive-menu-button-text-open').hide();
                    $('.responsive-menu-button-text').show();
                }
            },
            setButtonTextOpen: function() {
                if ($('.responsive-menu-button-text').length > 0 && $('.responsive-menu-button-text-open').length > 0) {
                    $('.responsive-menu-button-text').hide();
                    $('.responsive-menu-button-text-open').show();
                }
            },
            triggerMenu: function() {
                this.isOpen ? this.closeMenu() : this.openMenu();
            },
            triggerSubArrow: function(subarrow) {
                var sub_menu = $(subarrow).parent().siblings('.responsive-menu-submenu');
                var self = this;
                if (this.accordion == 'on') {
                    /* Get Top Most Parent and the siblings */
                    var top_siblings = sub_menu.parents('.responsive-menu-item-has-children').last().siblings('.responsive-menu-item-has-children');
                    var first_siblings = sub_menu.parents('.responsive-menu-item-has-children').first().siblings('.responsive-menu-item-has-children');
                    /* Close up just the top level parents to key the rest as it was */
                    top_siblings.children('.responsive-menu-submenu').slideUp(self.subMenuTransitionTime, 'linear').removeClass('responsive-menu-submenu-open');
                    /* Set each parent arrow to inactive */
                    top_siblings.each(function() {
                        $(this).find('.responsive-menu-subarrow').first().html(self.inactiveArrow);
                        $(this).find('.responsive-menu-subarrow').first().removeClass('responsive-menu-subarrow-active');
                    });
                    /* Now Repeat for the current item siblings */
                    first_siblings.children('.responsive-menu-submenu').slideUp(self.subMenuTransitionTime, 'linear').removeClass('responsive-menu-submenu-open');
                    first_siblings.each(function() {
                        $(this).find('.responsive-menu-subarrow').first().html(self.inactiveArrow);
                        $(this).find('.responsive-menu-subarrow').first().removeClass('responsive-menu-subarrow-active');
                    });
                }
                if (sub_menu.hasClass('responsive-menu-submenu-open')) {
                    sub_menu.slideUp(self.subMenuTransitionTime, 'linear').removeClass('responsive-menu-submenu-open');
                    $(subarrow).html(this.inactiveArrow);
                    $(subarrow).removeClass('responsive-menu-subarrow-active');
                } else {
                    sub_menu.slideDown(self.subMenuTransitionTime, 'linear').addClass('responsive-menu-submenu-open');
                    $(subarrow).html(this.activeArrow);
                    $(subarrow).addClass('responsive-menu-subarrow-active');
                }
            },
            menuHeight: function() {
                return $(this.container).height();
            },
            menuWidth: function() {
                return $(this.container).width();
            },
            wrapperHeight: function() {
                return $(this.wrapper).height();
            },
            setWrapperTranslate: function() {
                switch (this.animationSide) {
                    case 'left':
                        translate = 'translateX(' + this.menuWidth() + 'px)';
                        break;
                    case 'right':
                        translate = 'translateX(-' + this.menuWidth() + 'px)';
                        break;
                    case 'top':
                        translate = 'translateY(' + this.wrapperHeight() + 'px)';
                        break;
                    case 'bottom':
                        translate = 'translateY(-' + this.menuHeight() + 'px)';
                        break;
                }
                if (this.animationType == 'push') {
                    $(this.pageWrapper).css({
                        'transform': translate
                    });
                    $('html, body').css('overflow-x', 'hidden');
                }
                if (this.pushButton == 'on') {
                    $('#responsive-menu-button').css({
                        'transform': translate
                    });
                }
            },
            clearWrapperTranslate: function() {
                var self = this;
                if (this.animationType == 'push') {
                    $(this.pageWrapper).css({
                        'transform': ''
                    });
                    setTimeout(function() {
                        $('html, body').css('overflow-x', '');
                    }, self.animationSpeed);
                }
                if (this.pushButton == 'on') {
                    $('#responsive-menu-button').css({
                        'transform': ''
                    });
                }
            },
            init: function() {
                var self = this;
                $(this.trigger).on(this.triggerTypes, function(e) {
                    e.stopPropagation();
                    self.triggerMenu();
                });
                $(this.trigger).mouseup(function() {
                    $(self.trigger).blur();
                });
                $('.responsive-menu-subarrow').on('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    self.triggerSubArrow(this);
                });
                $(window).resize(function() {
                    if ($(window).width() > self.breakpoint) {
                        if (self.isOpen) {
                            self.closeMenu();
                        }
                    } else {
                        if ($('.responsive-menu-open').length > 0) {
                            self.setWrapperTranslate();
                        }
                    }
                });
                if (this.closeOnLinkClick == 'on') {
                    $(this.linkElement).on('click', function(e) {
                        e.preventDefault();
                        /* Fix for when close menu on parent clicks is on */
                        if (self.itemTriggerSubMenu == 'on' && $(this).is('.responsive-menu-item-has-children > ' + self.linkElement)) {
                            return;
                        }
                        old_href = $(this).attr('href');
                        old_target = typeof $(this).attr('target') == 'undefined' ? '_self' : $(this).attr('target');
                        if (self.isOpen) {
                            if ($(e.target).closest('.responsive-menu-subarrow').length) {
                                return;
                            }
                            self.closeMenu();
                            setTimeout(function() {
                                window.open(old_href, old_target);
                            }, self.animationSpeed);
                        }
                    });
                }
                if (this.closeOnBodyClick == 'on') {
                    $(document).on('click', 'body', function(e) {
                        if (self.isOpen) {
                            if ($(e.target).closest('#responsive-menu-container').length || $(e.target).closest('#responsive-menu-button').length) {
                                return;
                            }
                        }
                        self.closeMenu();
                    });
                }
                if (this.itemTriggerSubMenu == 'on') {
                    $('.responsive-menu-item-has-children > ' + this.linkElement).on('click', function(e) {
                        e.preventDefault();
                        self.triggerSubArrow($(this).children('.responsive-menu-subarrow').first());
                    });
                }
            }
        };
        ResponsiveMenu.init();
    });
</script>



<header
  class="aheto-header main-header main-header--horizontal main-header--left-logo main-header--center-nav main-header--white ">
  <div class="main-header__nav-wrap">
    <div class="main-header__toolbar-wrap main-header__toolbar-wrap--solid">
      <div class="container-fluid">
        <div class="col-md-12">
          <div class="aheto-toolbar">
            <nav class="no-menu"></nav>
            <div class="widget widget_text border-none">
              <a routerLink="/home" class="aheto-logo aheto-logo--dark main-header__logo">
                <img src="assets/img/logo.png" class="aheto-logo__image logo-blue" alt="">
                <img src="assets/img/logo.png" class="aheto-logo__image logo-white" alt="">
              </a>
            </div>
            <div class="registartion1">
              <a href="/ambassador/login">Innovation Ambassador</a>
            </div>
            <div class="registartion2">
              <a href="/incubatorstab">Incubators & Pre-incubators</a>
            </div>
            <div class="registartion2 resIICLogin">
              <a routerLink="/login">IIC login</a>
            </div>
            <!-- <div class="registartion2 resIICLogin">
              <a routerLink="/login" (click)="logout($event)">Logout</a>
            </div> -->
            <div class="registartion2 resIICinnovation">
              <a href="/iicinnovationcontest" target="_blank">IIC Innovation Contest</a>
            </div>
            <div class="aht-socials aht-socials--circle">
              <a class="aht-socials__link aht-btn--dark aht-btn--trans "
                href="https://www.facebook.com/mhrds.innovationcell">
                <i class="aht-socials__icon icon ion-social-facebook"></i>
              </a>
              <a class="aht-socials__link aht-btn--dark aht-btn--trans " href="https://twitter.com/mhrd_innovation">
                <i class="aht-socials__icon icon ion-social-twitter"></i>
              </a>
              <a class="aht-socials__link aht-btn--dark aht-btn--trans "
                href="https://www.instagram.com/mhrd.innovationcell/">
                <i class="aht-socials__icon icon ion-social-instagram"></i>
              </a>
              <a class="aht-socials__link aht-btn--dark aht-btn--trans "
                href="https://www.youtube.com/mhrdinnovationcell">
                <i class="aht-socials__icon icon ion-social-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-header__menu-wrap">
      <div class="main-header__fluid-container no-lr-padd-sm">
        <div class="main-header__main-line">
          <div class="main-header__menu-box">
            <nav class="menu-home-page-container">
              <ul
                class="main-menu main-menu--mobile-view  main-menu--dark main-menu--inline main-menu--ci-line-abow main-menu--ddt-default main-menu--ddc-white">
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <a routerLink="/home">Home</a>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                  <a routerLink='/'>About Us</a>
                  <ul class="sub-menu menu-depth-1">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/about-us/About_MHRDInnovation">About MHRD’S Innovation
                        Cell</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/about-us/About_Message">Message from CIO</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="assets/brochure/index.html" target="_blank">MIC Brochure</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/about-us/leadership_team">Leadership Team</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/about-us/contact_us">Contact Us</a></li>
                  </ul>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                  <a routerLink='/'>Innovation Council</a>
                  <ul class="sub-menu menu-depth-1">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/innovation-council/iic">Institution’s Innovation
                        Council</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/innovation-council/Why_heis">Why HEIs should Join ICs
                        Network?</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/innovation-council/iic_advisory_committee">IIC Advisory
                        Committee</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/innovation-council/innovationcontest">IIC National
                        Innovation Contest</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/innovation-council/innovation_ambassador">IIC Innovation
                        Ambassador Training Series</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="{{imageBasePath}}assets/wp_images/Recommendation of Impact Lecture Series.pdf" target="_blank">Impact
                        Lecture-Recommended Institutes</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="assets/doc/Prescribed Annual Calendar Activities for IICs - 2019-20 (1).pdf"
                        target="_blank">IIC Calendar</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/innovation-council/iic_rating_2018_19">star Rating
                        2018-19</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/innovation-council/iic_team">Implementation Team</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/innovation-council/iicwebinar">IIC Online Sessions</a></li>
                  </ul>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <a routerLink="/InnovationRanking">Innovation ranking</a>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                  <a routerLink='/'>Hackathon</a>
                  <ul class="sub-menu menu-depth-1">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/hackathon/sih">Smart India Hackathon</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/hackathon/singapore_india_hackathon_2019">Singapore India
                        Hackathon</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/hackathon/samadhan">Samadhan</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="https://innovateindia.mygov.in/ddh2020/" target="_blank">Drug
                        Discovery Hackathon</a></li>
                  </ul>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                  <a routerLink='/'>Start Up Policy</a>
                  <ul class="sub-menu menu-depth-1">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/start-up-policy/startup_policy">Innovation and Startup
                        Policy 2019</a></li>
					<li class="menu-item menu-item-type-custom menu-item-object-custom"><a
					routerLink="#">Implementation Plan</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/start-up-policy/startup_policy_committee">Start Up Policy
                        Committee 2019</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="assets/doc/startup_policy_2019.pdf" target="_blank">Download
                        Startup Policy 2019</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/start-up-policy/startup_application">Join the Campaign </a>
                    </li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/start-up-policy/nominate_faculty_for_nisp">Nominate Faculty
                        for NISP </a></li>
                  </ul>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                  <a routerLink="/">Leadership Talk</a>
                  <ul class="sub-menu menu-depth-1">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/leadership-talk/iflts_upcoming">Upcoming Episodes</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/leadership-talk/iflts_past">Past Episodes</a></li>
                  </ul>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <a routerLink="/micoutcome">MIC Outcome</a>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <a href="https://yukti.mic.gov.in/" target="_blank">YUKTI 2.0</a>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                  <a href="https://innovateindia.mygov.in/ddh2020/" target="_blank">DDH</a>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                  <a routerLink='/'>Gallery</a>
                  <ul class="sub-menu menu-depth-1">
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/gallery/gallery_video">Gallery Video</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/gallery/gallery_photo">Gallery Photo</a></li>
                    <li class="menu-item menu-item-type-custom menu-item-object-custom"><a
                        routerLink="/gallery/news">News & Events</a></li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <button class="hamburger hamburger--squeeze main-header__hamburger js-toggle-mobile-menu" type="button">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</header>