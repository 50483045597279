<div class="container row">
  <div class="col-12" style="text-align: center">
      <h3>You are eligible to apply as <strong>Mentee</strong> in Mentor-Mentee Scheme of IIC.
        <br/> For more details, please click on Mentor-Mentee under Scheme Menu</h3>
  </div>

  <div class="col-12"  style="text-align: center;">
    <button type="button" class="btn btn-danger" mat-dialog-close>Close</button>
  </div>

  <!-- <div class="col-6" style="text-align: end">
      <button type="button" class="btn btn-danger" (click)="openPage()">Yes</button>
  </div>
  <div class="col-6">
      <button type="button" class="btn btn-success" mat-dialog-close>No</button>
  </div>

  <mat-dialog-actions align="center">
      <button mat-button mat-dialog-close>No</button>
      <button mat-button (click)="deleteDialog()">Yes</button>
  </mat-dialog-actions> -->


</div>
