import { Component, OnInit } from '@angular/core';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-institute-stage-ii-evaluation',
  templateUrl: './institute-stage-ii-evaluation.component.html',
  styleUrls: ['./institute-stage-ii-evaluation.component.css']
})
export class InstituteStageIIEvaluationComponent implements OnInit {

  evaluation: any[];

  constructor(private innovationService: InstituteInnovationContestService) { }

  ngOnInit(): void {
    this.innovationService.getStage2Evaluation().subscribe(
      data => this.handleData(data)
    )
  }
  handleData(data) {
    this.evaluation = [];
    for (let i = 0; i < data['data'].length; i++) {
      this.evaluation.push(data['data'][i]);
    }
  }

}
