<mat-dialog-content class="mat-typography">
    <div class="container">
        <h3 class="ccHeadingMForAllDolouge">Innovation Contest Prototype</h3>
        <div class="row">
            <div class="col-md-12">
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Institute:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important" style="font-weight: bold !important">
                            {{formData?.instituteName}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Idea Title: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.idea_title}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Team Lead Name:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.team_lead_name}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Team Lead Email: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.team_lead_phone}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Team Lead Gender: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.team_lead_gender}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Team Lead Score:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.team_lead_score}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Description:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.description}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Theme: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.theamName}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">POC/Prototype:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{poc_prototype}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Status: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.proto_status}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Mentor Name:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.mentor_name}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Mentor Email: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.mentor_email}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Mentor Phone:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.member_phone}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Mentor Gender: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{gender}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Member Name:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.member_name}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Member Email: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.member_email}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Member Phone:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.member_phone}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Team Size: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.team_size}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Member Phone:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.member_phone}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Team Size: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.team_size}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Describe the product/process/ service and
                            write how it is innovative /
                            unique:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.describe_product}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">How is your proposed product/
                            process/service being different/ better from a
                            similar product/ process/ service, if any, in the market: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.proposed_product}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">If your Innovation is technology based then
                            specify the TRL Level
                            (Technology Readiness Level) and features of Prototype:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.innovation_technology}}
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Specific- Specify the features of
                            Innovation/ Prototype: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.feasibility_specific}}
                        </p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Measurable- Mention the approach to convert
                            prototype in to market ready
                            product with milestones:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.feasibility_measureable}}
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Attainable- Explain how you are going to
                            achieve the commercialization
                            objective with the available resources at your disposal: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.feasibility_attainable}}
                        </p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Realistic- what kind of skillset of team and
                            resources required to achieve
                            the goal in specific time period?:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.feasibility_realistic}}
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Timeline- Develop a timeline against the
                            milestones for taking prototype to
                            the commercial level/startup stage: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.feasibility_timeline}}
                        </p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Usability: what is the usability of your
                            innovation/ prototype.:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.applicable_usability}}
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Scalability: how your innovation/ prototype
                            will be scalable at market
                            level: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.applicable_scalability}}
                        </p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Economic sustainability: Explain the
                            potential of innovation/ prototype to
                            become profitable or financially viable:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.applicable_economic}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Environment Sustainability: How your
                            innovation/ prototype is environment
                            friendly or address environmental problems: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.applicable_enviroment}}
                        </p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Is there any Intellectual Property (IP)
                            Component associated with
                            innovation/ prototype? if yes, explain:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.applicable_ip}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Define the potential market size (in terms
                            of INR) and target customers:
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.market_size}}</p>
                    </div>
                </div>

                <div class="row btmLine">
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important">Prototype Comment: </p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.comment}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaTitle" style="color: black !important" style="color: black !important">Video of
                            prototype:</p>
                    </div>
                    <div class="col-md-3">
                        <p class="anaContent" style="font-weight: bold !important">{{formData?.video_link}}</p>
                    </div>
                </div>

                <div class="row btmLine">
                    <div *ngIf="showPrototypeScore" class="col-md-6">
                        <div class="col-md-3">
                            <p class="anaTitle" style="color: black !important" style="color: black !important">
                                Prototype Score:</p>
                        </div>
                        <div class="col-md-3">
                            <p class="anaContent" style="font-weight: bold !important">{{formData?.prototype_score}}</p>
                        </div>
                    </div>
                </div>






                <form [formGroup]="prototypeForm" ngSubmit="onSubmit(pocForm.value)">
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                                <mat-label>Prototype Score</mat-label>
                                <input matInput type="number" maxlength="4" formControlName="score" max="50" min="0"
                                    placeholder="Prototype Score" (keypress)=isNumberKey($event)>
                                <mat-error *ngIf="prototypeForm.controls['score'].hasError('required')">Prototype is
                                    required & should be below 50</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                                <mat-label>Comment</mat-label>
                                <input matInput formControlName="comment" placeholder="Comment">
                                <mat-error *ngIf="prototypeForm.controls['comment'].hasError('required')">Comment is
                                    required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="fill">
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="status" required>
                                    <mat-option value="Approved">Approved</mat-option>
                                    <mat-option value="Disapproved">Disapproved</mat-option>
                                    <mat-option value="Pending">Pending</mat-option>
                                </mat-select>
                                <mat-error *ngIf="prototypeForm.controls['status'].hasError('required')">Status is
                                    required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div mat-dialog-actions align="end" class="mt-3">
                        <button type="button" class="btn btn-danger instituteBtnClose mat-focus-indicator mat-raised-button mat-button-base mat-warn"
                            mat-dialog-close>Close</button>
                        <button type="submit"
                            class="btn btn-success float-right mat-focus-indicator mat-raised-button mat-button-base mat-primary"
                            [disabled]="prototypeForm.invalid" (click)="onSubmit(prototypeForm.value)">Verify</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</mat-dialog-content>