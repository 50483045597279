import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iic-incubatorstab',
  templateUrl: './iic-incubatorstab.component.html',
  styleUrls: ['./iic-incubatorstab.component.css']
})
export class IicIncubatorstabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
