<div class="container">
    <div class="card-deck instiCardDeckDashboard">
      <div class="card text-center asd" style="background-color: #b6bcbd75 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><mat-icon>account_box</mat-icon>ACTIVITY</h3>
            <!-- <p style="color: white !important;" class="card-text">My Calender Activities: activity?.my_calender_activities</p> -->
            <p class="card-text" title="Total Events By MIC: {{cards?.total_event}}"><mat-icon>calendar_today</mat-icon>Total Events By MIC: {{cards?.total_event}}</p>
            <p class="card-text" title="Accepted/Modified: {{cards?.acceptedModified}}"><mat-icon>check_box</mat-icon>Accepted/Modified: {{cards?.acceptedModified}}</p>
            <p class="card-text" title="Report Uploaded: {{cards?.reportUploaded}}"><mat-icon>thumb_up_alt</mat-icon>Report Uploaded: {{cards?.reportUploaded}}</p>
            <p class="card-text" title="Report Not Uploaded: {{cards?.reportNotploaded}}"><mat-icon>thumb_down_alt</mat-icon>Report Not Uploaded: {{cards?.reportNotploaded}}</p>
            <p class="card-text" title="Report Not Required: {{cards?.reportNotRequired}}"><mat-icon>thumb_down_alt</mat-icon>Report Not Required: {{cards?.reportNotRequired}}</p>

           
            <div class="card-footer">
              <a routerLink="/institute/activity-report/18-19" class="btn btn-success btnDashboardCard">Report
                <!-- <mat-icon>save_alt</mat-icon> --><mat-icon>arrow_forward</mat-icon>
            </a>
            </div>
          </div>
      </div>
      <div class="card text-center asd" style="background-color: #b6bcbd75 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><mat-icon>announcement</mat-icon>ANNOUNCEMENTS</h3>
            <p *ngFor = "let announcement of cards['announcement']" class="card-text" title="{{announcement.announcement}}"><mat-icon>near_me</mat-icon>{{announcement.announcement}}</p>
            <div class="card-footer">
            <a routerLink="/institute/announcements/18-19" class="btn btn-success btnDashboardCard">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div>
      <div class="card text-center asd" style="background-color:#b6bcbd75 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><!-- <mat-icon>trending_up</mat-icon> --> <i class="fa fa-line-chart" aria-hidden="true"></i>MY RANKING</h3>
            <div class="row">
              <div class="col-9">
                <p *ngFor = "let ranking of cards['myRanking']" class="card-text" title="{{ranking.title}}: {{ranking.grade}}"><mat-icon>trending_up</mat-icon>{{ranking.title}}:</p>
              </div>
              <div class="col-3">
                <p *ngFor = "let ranking of cards['myRanking']" class="card-text">{{ranking.grade}}</p>
              </div>
            </div>
          <div class="card-footer">
            <a routerLink="/institute/myRanking/18-19" class="btn btn-success btnDashboardCard">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div>
      <div class="card text-center asd" style="background-color: #b6bcbd75 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><mat-icon>notifications_active</mat-icon>NOTIFICATIONS</h3>
            <p *ngFor = "let notification of cards['notification']" class="card-text" title="{{notification.subject}}"><mat-icon>notifications_active</mat-icon>{{notification.subject}}</p>
            <div class="card-footer">
            <a routerLink="/institute/notification/18-19" class="btn btn-success btnDashboardCard">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div>  
    </div>
  </div>