<!-- IIC Website Header -->
<app-iic-web-main-navbar></app-iic-web-main-navbar>

<!-- <div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">Innovation Contest 2020</h1>							
		</div>
	</div>
</div> -->

<!-- <nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>IIC Innovation Contest</a></li>
    <li class="breadcrumb-item active" aria-current="page">Innovation Contest 2020</li>
  </ul>
</nav> -->

<div id="carouselExampleControlsMobile" class="carousel slide bannerSlideInnovation" data-ride="carousel">
  <div class="carousel-inner">
	<div class="carousel-item active">
	  <img class="d-block w-100" src="assets/assets/images/innovation-contest-2020-bg.jpg" alt="First slide">
	  <div class="carousel-caption innovationContest2020Content">
		<h1>National Innovation Contest 2020</h1>
		<h2>Institution’s Innovation Council</h2>
		<h3> MoE’s Innovation Cell, AICTE, New Delhi</h3>
		<a class="main-btn" href="https://nic.mic.gov.in/login" target="_blank">NIC Team Lead Login</a>
		<a class="main-btn1" href="https://ia.mic.gov.in" target="_blank">Innovation Ambassador Login</a>
	  </div>
	</div>
  </div>
  
  <!-- <a class="carousel-control-prev landingSliderBannerPrevMob" href="#carouselExampleControlsMobile" role="button" data-slide="prev">
	<i class="fa fa-arrow-left" aria-hidden="true"></i>
	<span class="sr-only">Previous</span>
  </a> 
  <a class="carousel-control-next landingSliderBannerNextMob" href="#carouselExampleControlsMobile" role="button" data-slide="next">
	<i class="fa fa-arrow-right" aria-hidden="true"></i>
	<span class="sr-only">Next</span>
  </a> -->
</div>
 <nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>IIC Innovation Contest</a></li>
    <li class="breadcrumb-item active" aria-current="page">Innovation Contest 2020</li>
  </ul>
</nav> 


<div class="cbp-row wh-content PoCcontest2020">
			<div class="cbp-container container wh-padding">
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador headingAmbassadorMobRes">
							<h2 class="InnoContMobRes">National Innovation Contest 2020</h2>
							<a  href="{{ baseUrl }}/uploads/institutes/mic/images/announcements/national innovation contest 2020- list of nominated prototype teams by iics.pdf" target="_blank" class="nicBut2020">NIC 2020- List of prototypes  nominated by IICs for next level of mentoring from MIC  </a>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>National Innovation Contest 2020:</h3>
							<p>The IIC National Innovation Contest 2020 was announced during the month of February 2020. The 2nd edition of IIC-National Innovation Contest (2020) is currently under progress for the students from IIC-Institutes to take part in this 360-degree innovative and experiential learning process.</p>
							
							<h3>A 360-degree approach of Learning: </h3>
							<p>The National Innovation Contest is going to provide 360-degree cycle of learning and understanding the process of innovation and start-up. It is designed in a way that, each team will traverse a path from problem identification and ideation to building business model and finally entering the phase of Enterprise development in a time period of one year.</p>
							<div class="headingAmbassadorContent">
								<img src="assets/assets/images/innovation-contest-graph.jpg">
							</div>
							
							<h3>Student Team Size and Eligibility:</h3>
							<p>Students are studying from UG to PhD in the IIC-Institutes <span
							class="redText" data-toggle="modal" data-target="#myModal" style="cursor:pointer;"
							(click)="openDialog()">(list of IIC-institutes can be referred here)</span> eligible to participate. Each team can have maximum 10 members. Only student members in team are permitted. Participation of female members are encouraged. Faculty can take the role of mentors.</p>
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Timeline & Submission Guidelines: (TRL Stages Can be Referred Here)</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>1. Idea:</h3>
							<p>Student teams can submit their ideas directly through this page or through IIC Council President/Convener. The ideas should be at Technological Readiness Level (TRL) - 0 to 2 (refer TRL levels). Once a team submits idea (s), respective IIC-Institutes need to evaluate Ideas based on the evaluation criteria provided to IIC-Institute and need to list/ upgrade them at the IIC portal as per provision by 21st June 2020 (Dead Line Extended). Each student team can submit multiple Ideas/PoCs/Prototypes upto maximum 5 numbers.</p>
							<!-- <p><a href="#">Click Here to Participate</a></p> -->
							
							<h3>2. PoC: </h3>
							<p>Student teams can submit their Proof of Concept (PoC) directly through this page or through IIC Council President/Convener. The PoC should be at Technological Readiness Level (TRL) – 3 (refer TRL). Once a team submits PoC, respective IIC-Institutes need to evaluate PoCs based on the evaluation criteria provided to IIC-Institute and need to list/upgrade them on the IIC portal as per provision on the IIC portalby 21st June 2020 (Dead Line Extended). Each student team can submit multiple Ideas/PoCs/Prototypes upto maximum 5 numbers.</p>	
							<!-- <p><a href="#">Click Here to Participate</a></p> -->

							<h3>3. Prototype/Innovation: </h3>
							<p>Student teams can submit their Prototypes directly through this page or through IIC Council President/Convener. The Prototype/Innovations should be from Technological Readiness Level (TRL) –4 to 73 (refer TRL). Once a team submits Prototype/Innovation(s), respective IIC-Institutes need to evaluate these prototypes/innovations based on the evaluation criteria provided to IIC-Institute and need to list/upgrade as per provisionon the IIC portal from 31st July to 10th August 2020.</p>
							<!-- <p><a href="#">Click Here to Participate</a></p> -->
						</div>
					</div>
				</div>
				
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<p class="fontItalic"><span>Note: *</span> Student teams participated in other idea/innovation competitions or hackathons or Challenges organised by central and state governments and non-government and academic institutions in last 6 months (Jan 2020 to till date) are also encouraged to submit their applications to IIC-Institute and take part in this contest. e-Participation certificate to all members from their respective IIC-Institutes.</p>
							<p class="fontItalic"><span>**</span> Moreover, if any student team has missed to submit at idea or PoC level and want to submit directly at PoC (or) Prototype/Innovation level, then they can do so by submitting their applications to their IIC-Institute or directly at the link provided here. e-Participation certificate to all members from their respective IIC-Institutes.</p>
						</div>
					</div>
					
					<!-- <div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<img src="assets/assets/images/innovation-contest-graph.jpg">
						</div>
					</div> -->
				</div>
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Benefits</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>Benefits for Student Participants:</h3>							
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContent">
							<ul>
								<li> Receive Mentorship and Guidance </li>
								<li> Receive Training and Exposure </li>
								<li> Receive Funding and Incubation Linkage </li>
								<li> Receive Innovation Ambassador Badge </li>
								<li> e-Participation Certificates to shortlist Idea and PoC teams and all members of shortlisted Prototype/Innovation team from IIC - MIC and AICTE.</li>
							</ul>
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContent">
							<ul>
								<li> Recognitions at Regional and National Level </li>
								<li> Receive reward points which can be redeemed at IIC-Institute or IIC-MIC and claim Gifts. </li>
								<li> The finalist of IIC National Innovation Contest will be eligible for participation in upcoming IIC-Early Stage Start-up Contest 2020, which will be open during the month of October/November 2020.</li>
							</ul>
						</div>
					</div>
					
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>Benefits For IIC Coordinators (Faculty & Student) and Innovation Ambassadors:</h3>							
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContent">
							<ul>
								<li> nnovation Ambassador (IA) will get Reward Points for the Mentorship Support as one of the post-training follow up activity for Innovation Ambassador to undertake. </li>
								<li> Best performing Innovation Ambassadors will get recognitions at National Level for their efforts. </li>								
							</ul>
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContent">
							<ul>
								<li> All IIC members (Students and Faculties) will get e-Certificate at the end of IIC2.0 Calendar Year. </li>
								<li> Respective IIC-Institutes may consider to recognise and reward IIC-Members (Students and faculties) and Innovation Ambassadors for their efforts to encourage more student innovators and student entrepreneurs to submit their Ideas, PoCs, and Prototypes/Innovations. </li>								
							</ul>
						</div>
					</div>
					
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>Benefit For IIC-Institutes:</h3>							
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContent">
							<ul>
								<li> Upon getting minimum 50 Ideas, 25 POCs and 15 Prototypes/Innovations in the IIC-MIC Portal, weightage will be given for the 5th star (*) reserved with IIC-MIC to allocate. </li>
								<li> IIC-Institutes may report this as one of MIC driven activity and claim toward IIC2.0 annual score and star (*) calculation and announcing results. </li>
								<li>Recognition to 100 numbers of top performing IIC-Institutes (Zone wise) during IIC2.0 at the national level based upon the annual score and star obtained by the IIC-Institutes</li>								
							</ul>
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContent">
							<ul>
								<li> Recognition to IIC-Institute at different level (Regional and National) as student team will make progress in the contest. </li>
								<li> Nominated Representatives from IIC-Institutes will get orientation on IIC3.0. NISP, ARIIA 2021 and other Programs related to Innovation/Startup at MIC & AICTE. </li>	
								<li>Pre-Incubation and Incubation facility details available at IIC-Institute will be listed in MIC webpage.</li>
							</ul>
						</div>
					</div>
					
				</div>
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Themes</h2>

						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>List of Themes: Student Ideas/POCS/Prototypes/Innovation on The Following Themes are Invited to Submit.</h3>							
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContentTheme">
							<ul>
								<li> <strong>1.</strong> Healthcare & Biomedical devices. </li>
								<li> <strong>2.</strong> Agriculture & Rural Development. </li>
								<li> <strong>3.</strong> Smart Vehicles/ Electric vehicle/ Electric vehicle motor and battery technology. </li>
								<li> <strong>4.</strong> Food Processing. </li>
								<li> <strong>5.</strong> Robotics and Drones.</li>
								<li> <strong>6.</strong> Waste management.</li>
							</ul>
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContentTheme">
							<ul>
								<li> <strong>7.</strong> Clean & Potable water. </li>
								<li> <strong>8.</strong> Renewable and affordable Energy. </li>
								<li> <strong>9.</strong>  IoT based technologies (e.g. Security & Surveillance systems etc.) </li>
								<li> <strong>10.</strong> ICT, cyber-physical systems, Blockchain, Cognitive computing, Cloud computing, AI & ML. </li>
								<li> <strong>11.</strong> Other emerging areas of Innovation and Startup opportunity.</li>
							</ul>
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Process Steps and Timeline</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>IIC National Innovation Contest 2020</h3>							
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContent">
							<ul>
								<li> Field Visits & Problem Identification from Society, Industry and Market </li>
								<li> Innovative Idea Scouting, Evaluation & List on IIC Portal (Extend the date to 21st June for submission by students for Idea/PoC and 30th June for Prototype and for IIC Institute to nominate by 13th July) </li>
								<li> Validation of Idea and Proof of Concept (PoC) Development </li>								
							</ul>
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContent">
							<ul>
								<li> Nomination of Upto 5 Best Prototypes/Innovations to IIC-MIC </li>
								<li> Regional Mentoring Sessions (Online) and Shortlist of Prototypes/innovation for Next Level </li>
								<li> National Level Bootcamp (Online) and Recognition at National Level </li>
								<li> International Training and Exposure Programs </li>								
							</ul>
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Downloads</h2>
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContentDownload">
							<ul>
								<li><i aria-hidden="true" class="fa fa-download"></i> <a href="assets/assets/images/innovation-dowloads/Idea-Evaluation-Form.pdf" target="_blank">Guideline and Evaluation Criteria Sheet for Idea Submission</a> </li>
								<li><i aria-hidden="true" class="fa fa-download"></i> <a href="assets/assets/images/innovation-dowloads/Prorotype-Submission-Form-PDF.pdf" target="_blank">Prototype Submission Format</a> </li>
								<li><i aria-hidden="true" class="fa fa-download"></i> <a href="assets/assets/images/innovation-dowloads/Innovation_contest_Portal_Guide.pdf" target="_blank">Portal Submission Guide</a> </li>								
							</ul>
						</div>
					</div>
					<div class="vc_col-md-6">
						<div class="headingAmbassadorContentDownload">
							<ul>
								<li><i aria-hidden="true" class="fa fa-download"></i> <a href="assets/assets/images/innovation-dowloads/POC-Evaluation-Form-PDF.pdf" target="_blank">Guideline and Evaluation Criteria Sheet for Proof of Concept (PoC) Submission</a> </li>
								<li><i aria-hidden="true" class="fa fa-download"></i> <a href="assets/assets/images/innovation-dowloads/Idea-or-Proof-of-Concept-Submisson-Format.pdf" target="_blank">Idea or Proof of Concept Submission Format</a> </li>
								<li><i aria-hidden="true" class="fa fa-download"></i> <a href="assets/assets/images/innovation-dowloads/Prototype_Evaluation_Sheet.pdf" target="_blank">Prototype Evaluation Sheet</a> </li>							
							</ul>
						</div>
					</div>
				</div>
				
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>International Exposure</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>CANADA-INDIA ACCELERATION PROGRAM</h3>
							<p>MHRD’s Innovation Cell has selected students for 2nd cohort visit to Canada under Canada-India Acceleration Program (CIAP). This joint program is managed by the All India Council for Technical Education (AICTE) and the Canada-India Centre for Excellence (CICE) at Carleton University. CIAP is aimed at enabling women entrepreneurs from Canada and India to access international markets with a focus on the Canadian and Indian markets. Students were selected from IICs through POC Contest organized during month of March - August 2019 and Singapore India Hackathon 2019. In 2018, 10 women entrepreneurs visited Canada as part of CIAP. This year, 06 women entrepreneurs visited for 10 day comprehensive bootcamp and exposure visit to Canada in January.</p>
							
							<h3>SOUTH KOREA EXPOSURE VISIT </h3>
							<p>MHRD’s Innovation Cell has selected students for 2nd exposure visit to South Korea under collaborative effort between All India Council for Technical Education (AICTE) and Daegu Centre for Creative Economy & Innovation (CCEI, Daegu). This program offers opportunities to interact with ecosystem enablers and start-ups from South Korea. Students were selected from IICs through POC Contest organized during month of March - August 2019 and Smart India Hackathon 2019. In 2018, 10 entrepreneurs visited South Korea as part of this program. Last year 05 women entrepreneurs visited for 05 days exposure visit from 31st October to 5th October where they also participated and competed in the 5th GIF (Global Innovator Festa), one of the biggest Start- up event in Korea.</p>														
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Success Stories of POC Contest</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3 class="pocResMob">SELECTED TEAM OF POC CONTEST 2019</h3>
							<h4><a href="assets/zone/selected_poc_list_2018-19.pdf" target="_blank">LIST OF TOP INNOVATIVE POCS FROM NATIONAL BOOTCAMP AND EXHIBITION 2019</a></h4>
							
							<table class="table table-bordered cd-table-striped table-responsive">
					  <thead class="thead-light">
						<tr>
						  <th scope="col" class="col1">S No.</th>
						  <th scope="col" class="col2">Team Name</th>
						  <th scope="col" class="col3">Project/PoC Title</th>
						  <th scope="col" class="col4">Institute Name</th>
						  <th scope="col" class="col5">State</th>
						  <th scope="col" class="col6">Amount Sanctioned(In Indian Rupees)</th>
						</tr>
					  </thead>
					  <tbody>
						<tr>
						  <th scope="row">1</th>
						  <td>SUVANA</td>
						  <td>Low cost residential flow meter</td>
						  <td>Muzaffarpur Institute of Technology</td>
						  <td>Bihar</td>
						  <td>4,50,000</td>
						</tr>
						<tr>
						  <th scope="row">2</th>
						  <td>Motion Sensing Glove</td>
						  <td>Motion Sensing Glove</td>
						  <td>UIET, Panjab University</td>
						  <td>Chandigarh </td>
						  <td>4,00,000</td>
						</tr>
						<tr>
						  <th scope="row">3</th>
						  <td>Cytokine</td>
						  <td>Bhangarya (Eclipta alba)-A new traditional plant to manage bleeding: From land to FIRST</td>
						  <td>L. M. College of Pharmacy</td>
						  <td>Gujarat</td>
						  <td>4,50,000</td>
						</tr>
						<tr>
						  <th scope="row">4</th>
						  <td>Mobile STP</td>
						  <td>Near Zero cost polluted water</td>
						  <td>CMR Institute of Technology </td>
						  <td>Karnataka</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">5</th>
						  <td>SAM Solutions</td>
						  <td>Automatic Puttu Making Machine</td>
						  <td>Vimal Jyothi Engineering College</td>
						  <td>Kerala</td>
						  <td>4,00,000</td>
						</tr>
						<tr>
						  <th scope="row">6</th>
						  <td>JAL</td>
						  <td>Desalination system</td>
						  <td>NIT Calicut</td>
						  <td>Kerala</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">7</th>
						  <td>Aztecs</td>
						  <td>Treatment of Seafood processing effluent using Stringed Bed suspended Bioreactor</td>
						  <td>KMEA ENGINEERING COLLEGE EDATHALA</td>
						  <td>Kerala</td>
						  <td>2,50,000</td>
						</tr>
						<tr>
						  <th scope="row">8</th>
						  <td>Novorbis</td>
						  <td>First Filter-less Air purification Unit</td>
						  <td>Acropolis Institute of Technologyand Research</td>
						  <td>Madhya Padesh </td>
						  <td>2,89,000</td>
						</tr>
						<tr>
						  <th scope="row">9</th>
						  <td>PREG-Ѷ-DET</td>
						  <td>A Kit for Visual Early Pregnancy Detection in Cattle from Urine</td>
						  <td>Institute of Chemical Technology</td>
						  <td>Maharashtra</td>
						  <td>5,00,000</td>
						</tr>
						<tr>
						  <th scope="row">10</th>
						  <td>RakDaa</td>
						  <td>Nano_Gr_Det Point of care blood group detection kit</td>
						  <td>Institute of Chemical Technology,Mumbai</td>
						  <td>Maharashtra</td>
						  <td>3,42,000</td>
						</tr>
						<tr>
						  <th scope="row">11</th>
						  <td>We-Wake IndiGreen</td>
						  <td>Bio-based Biodegradable Plastic Process and Product Development</td>
						  <td>Savitribai Phule Pune University</td>
						  <td>Maharashtra</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">12</th>
						  <td>JAPNAS</td>
						  <td>Mood analysis using facial expressions </td>
						  <td>Maharashtra Institute of Technology, Pune</td>
						  <td>Maharashtra</td>
						  <td>2,53,000</td>
						</tr>
						<tr>
						  <th scope="row">13</th>
						  <td>Fenice Tech</td>
						  <td>CRAB - clog removing autonomous bot </td>
						  <td>Dr. M.G.R. Innovation Institute Council</td>
						  <td>Tamil Nadu</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">14</th>
						  <td>Team Tested OK</td>
						  <td>Machine Vision system for Quality Control in Automobile Component Manufacturing</td>
						  <td>Kumaraguru College of Technology</td>
						  <td>Tamil Nadu</td>
						  <td>2,59,000</td>
						</tr>
						<tr>
						  <th scope="row">15</th>
						  <td>SmartMed</td>
						  <td>Optimus - Smart Pill Companion - vending machine with finger print</td>
						  <td>Sreenidhi Institute of Science and Technology</td>
						  <td>Telangana</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">16</th>
						  <td>Cipher generator</td>
						  <td>Cipher generator</td>
						  <td>I.T.S Engineering college</td>
						  <td>Uttar Pradesh</td>
						  <td>4,00,000</td>
						</tr>
						<tr>
						  <th scope="row">17</th>
						  <td>IntBotix</td>
						  <td>Recruitment Bot</td>
						  <td>IIIT-Allahabad </td>
						  <td>Uttar Pradesh</td>
						  <td>2,90,000</td>
						</tr>
					  </tbody>
					</table>
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Gallery</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>2ND COHORT TO SOUTH KOREA</h3>							
						</div>
					</div>
					
				</div>
				<div class="row row margin-md-gallery">
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal">
						<a href="#lightbox" data-slide-to="0"><img src="assets/assets/images/gallery/SK1.jpeg"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal">
						<a href="#lightbox" data-slide-to="1"><img src="assets/assets/images/gallery/SK2.jpeg"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal">
						<a href="#lightbox" data-slide-to="2"><img src="assets/assets/images/gallery/SK3.jpeg"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal">
						<a href="#lightbox" data-slide-to="3"><img src="assets/assets/images/gallery/SK4.jpeg"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal">
						<a href="#lightbox" data-slide-to="4"><img src="assets/assets/images/gallery/SK5.jpeg"
								class="img-thumbnail my-2"></a>               
					</div>
				</div>
				
				<div class="vc_col-md-12">
					<div class="headingAmbassadorContent">
						<h3>2ND COHORT OF CIAP</h3>							
					</div>
				</div>
				<div class="row row margin-md-gallery1">
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal1">
						<a href="#lightbox1" data-slide-to="0"><img src="assets/assets/images/gallery/C1.jpg"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal1">
						<a href="#lightbox1" data-slide-to="1"><img src="assets/assets/images/gallery/C2.jpg"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal1">
						<a href="#lightbox1" data-slide-to="2"><img src="assets/assets/images/gallery/C3.jpg"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal1">
						<a href="#lightbox1" data-slide-to="3"><img src="assets/assets/images/gallery/SK4.jpeg"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal1">
						<a href="#lightbox1" data-slide-to="4"><img src="assets/assets/images/gallery/SK5.jpeg"
								class="img-thumbnail my-2"></a>               
					</div>
				</div>
				
				<div class="vc_col-md-12">
					<div class="headingAmbassadorContent">
						<h3>NATIONAL LEVEL BOOTCAMP 2019</h3>							
					</div>
				</div>
				<div class="row row margin-md-gallery2">
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal2">
						<a href="#lightbox2" data-slide-to="0"><img src="assets/assets/images/gallery/RKS1.png"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal2">
						<a href="#lightbox2" data-slide-to="1"><img src="assets/assets/images/gallery/RKS2.png"
								class="img-thumbnail my-2"></a>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 galMarg" data-toggle="modal" data-target="#modal2">
						<a href="#lightbox2" data-slide-to="2"><img src="assets/assets/images/gallery/RKS3.png"
								class="img-thumbnail my-2"></a>
					</div>
				</div>
		
		<div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="Lightbox Gallery by Bootstrap 4"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div id="lightbox" class="carousel slide" data-ride="carousel" data-interval="5000"
                        data-keyboard="true" autoplay="false">
                        <!-- <ol class="carousel-indicators">
                            <li data-target="#lightbox" data-slide-to="0"></li>
                            <li data-target="#lightbox" data-slide-to="1"></li>
                            <li data-target="#lightbox" data-slide-to="2"></li>
                            <li data-target="#lightbox" data-slide-to="3"></li>
                            <li data-target="#lightbox" data-slide-to="4"></li>
                            <li data-target="#lightbox" data-slide-to="5"></li>
                        </ol> -->
                        <div class="carousel-inner">
                            <div class="carousel-item active"><img src="assets/assets/images/gallery/SK1.jpeg" class="w-100" alt="">
                            </div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/SK2.jpeg" class="w-100" alt=""></div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/SK3.jpeg" class="w-100" alt=""></div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/SK4.jpeg" class="w-100" alt=""></div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/SK5.jpeg" class="w-100" alt=""></div>
                            
                        </div>
                        <a class="carousel-control-prev" href="#lightbox" role="button" data-slide="prev"><span
                                class="carousel-control-prev-icon" aria-hidden="true"></span><span
                                class="sr-only">Previous</span></a>
                        <a class="carousel-control-next" href="#lightbox" role="button" data-slide="next"><span
                                class="carousel-control-next-icon" aria-hidden="true"></span><span
                                class="sr-only">Next</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div> 
	
	<div class="modal fade" id="modal1" tabindex="-1" role="dialog" aria-labelledby="Lightbox Gallery by Bootstrap 4"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div id="lightbox1" class="carousel slide" data-ride="carousel" data-interval="5000"
                        data-keyboard="true" autoplay="false">
                        <!-- <ol class="carousel-indicators">
                            <li data-target="#lightbox" data-slide-to="0"></li>
                            <li data-target="#lightbox" data-slide-to="1"></li>
                            <li data-target="#lightbox" data-slide-to="2"></li>
                            <li data-target="#lightbox" data-slide-to="3"></li>
                            <li data-target="#lightbox" data-slide-to="4"></li>
                            <li data-target="#lightbox" data-slide-to="5"></li>
                        </ol> -->
                        <div class="carousel-inner">
                            <div class="carousel-item active"><img src="assets/assets/images/gallery/C1.jpg" class="w-100" alt="">
                            </div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/C2.jpg" class="w-100" alt=""></div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/C3.jpg" class="w-100" alt=""></div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/SK4.jpeg" class="w-100" alt=""></div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/SK5.jpeg" class="w-100" alt=""></div>
                            
                        </div>
                        <a class="carousel-control-prev" href="#lightbox1" role="button" data-slide="prev"><span
                                class="carousel-control-prev-icon" aria-hidden="true"></span><span
                                class="sr-only">Previous</span></a>
                        <a class="carousel-control-next" href="#lightbox1" role="button" data-slide="next"><span
                                class="carousel-control-next-icon" aria-hidden="true"></span><span
                                class="sr-only">Next</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div> 
	
	
	<div class="modal fade" id="modal2" tabindex="-1" role="dialog" aria-labelledby="Lightbox Gallery by Bootstrap 4"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div id="lightbox2" class="carousel slide" data-ride="carousel" data-interval="5000"
                        data-keyboard="true" autoplay="false">
                        <!-- <ol class="carousel-indicators">
                            <li data-target="#lightbox" data-slide-to="0"></li>
                            <li data-target="#lightbox" data-slide-to="1"></li>
                            <li data-target="#lightbox" data-slide-to="2"></li>
                            <li data-target="#lightbox" data-slide-to="3"></li>
                            <li data-target="#lightbox" data-slide-to="4"></li>
                            <li data-target="#lightbox" data-slide-to="5"></li>
                        </ol> -->
                        <div class="carousel-inner">
                            <div class="carousel-item active"><img src="assets/assets/images/gallery/RKS1.png" class="w-100" alt="">
                            </div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/RKS2.png" class="w-100" alt=""></div>
                            <div class="carousel-item"><img src="assets/assets/images/gallery/RKS3.png" class="w-100" alt=""></div>                           
                            
                        </div>
                        <a class="carousel-control-prev" href="#lightbox2" role="button" data-slide="prev"><span
                                class="carousel-control-prev-icon" aria-hidden="true"></span><span
                                class="sr-only">Previous</span></a>
                        <a class="carousel-control-next" href="#lightbox2" role="button" data-slide="next"><span
                                class="carousel-control-next-icon" aria-hidden="true"></span><span
                                class="sr-only">Next</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
	
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Query and Contact</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>For any query, write us at iic.mhrd@aicte-india.org or in respective zonal WhatsApp group.</h3>
							<h4>Please contact IIC Convenor and Faculty Coordinators of your Institute for any query or detail information regarding submission.</h4>
							<!-- <h3>Zonal Coordinators</h3>													 -->
						</div>
					</div>
					
					<div class="vc_col-md-3">
						<div class="single-contact-info contact-color-3 mt-30 d-flex  wow fadeInUp" data-wow-delay="0.9s" data-wow-duration="1s">
							<div class="contact-info-icon">
								<i aria-hidden="true" class="fa fa-phone"></i>
							</div>
							<div class="contact-info-content media-body">
								<p class="text textName">Ms. Selvarani</p>
								<p class="text textName2">SRO, ERO</p>
								<p class="text">selva.rani@aicte-india.org</p>
								<p class="text">011 2958 1513</p>
							</div>
						</div>
					</div>
					<div class="vc_col-md-3">
						<div class="single-contact-info contact-color-3 mt-30 d-flex  wow fadeInUp" data-wow-delay="0.9s" data-wow-duration="1s">
							<div class="contact-info-icon">
								<i aria-hidden="true" class="fa fa-phone"></i>
							</div>
							<div class="contact-info-content media-body">
								<p class="text textName">Mr. Ankush Sharma</p>
								<p class="text textName2">Northern/NRO, North-West/NWRO</p>
								<p class="text textEmailQuery1">ankush.sharma@aicte-india.org</p>
								<p class="text">011 2958 1223</p>
							</div>
						</div>
					</div>
					<div class="vc_col-md-3">
						<div class="single-contact-info contact-color-3 mt-30 d-flex  wow fadeInUp" data-wow-delay="0.9s" data-wow-duration="1s">
							<div class="contact-info-icon">
								<i aria-hidden="true" class="fa fa-phone"></i>
							</div>
							<div class="contact-info-content media-body">
								<p class="text textName">Mr. Pankaj Pandey</p>
								<p class="text textName2">South-West/SWRO, Western/WRO</p>
								<p class="text textEmailQuery1">pankajpandey@aicte-india.org</p>
								<p class="text">011 2958 1239</p>
							</div>
						</div>
					</div>
					<div class="vc_col-md-3">
						<div class="single-contact-info contact-color-3 mt-30 d-flex  wow fadeInUp" data-wow-delay="0.9s" data-wow-duration="1s">
							<div class="contact-info-icon">
								<i aria-hidden="true" class="fa fa-phone"></i>
							</div>
							<div class="contact-info-content media-body">
								<p class="text textName">Mr. Sunny Attawal</p>
								<p class="text textName2">Central/CRO, South-Central/SCRO</p>
								<p class="text">yp.mic@aicte-india.org</p>
								<p class="text">011 2958 1240</p>
							</div>
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox ambPadding1">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<p class="paraInnovationBottom"><a href="https://mic.gov.in/innovation-council/iic_team" target="_blank">http://mic.gov.in/iic_team</a></p>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="iicContest2020Bottom">
							<p class="inqueryBottom">For Queries write us at <a href="mailto:mic-mhrd@gov.in"><strong>iic.mhrd@aicte-india.org</strong></a></p>					
							<!-- <p class="inqueryBottom">For any Specific Query please contact <strong>Mr. Dipan Sahu, National Coordinator – IIC, ARIIA, NISP</strong> Phone Number: 011 29581226</p>	-->
						</div>
					</div>					
				</div>
				
				
				<div class="vc_row-full-width vc_clearfix"></div>
				


			</div>
		</div>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>