import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-institute-inactive-iic-detail',
  templateUrl: './institute-inactive-iic-detail.component.html',
  styleUrls: ['./institute-inactive-iic-detail.component.css']
})
export class InstituteInactiveIicDetailComponent implements OnInit {

  public form2: FormGroup;
  preferenceModel:any;
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicrepository:any;

  preferenceCurrent:any;

  constructor(private fb: FormBuilder, public dialog: MatDialog,  private instituteActivityService :InstituteActivityService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.form2 = this.fb.group({
      'prefrence': [null, Validators.required]
    });
    this.requireField=0;

    

    // this.instituteActivityService.getIicInstitutesReferal('inactive-iic').subscribe(
    //   (data) => this.handleResponse(data)
    // );

    this.instituteActivityService.getAllIICUserByFilter(1).subscribe(
      data => this.handleResponseList(data)
    );
  }

  handleResponse(data) {
    this.iicrepository = data['data'];
  }

  onClickEvent(data,type){

    if(type=="Edit")
    {
      window.location.href="/institute/edit-affiliate-institute/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.deleteIicReferralDetails(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }

  }

  onSubmit(data){
    this.preferenceCurrent = data['prefrence'];
    this.instituteActivityService.getAllIICUserByFilter(data['prefrence']).subscribe(
      data => this.handleResponseList(data)
    );
    this.form2.patchValue({
      prefrence : 1
    });
  }

  handleResponseList(data){
    console.log(data);
    if(data['status'] == 'success'){
      this.iicrepository = data['data'];
      this.toastr.success(data['message']);
    }else{
      this.toastr.error(data['message']);
    }
  }

  onClickAddEvent(data)
  {
    // const dialogRef = this.dialog.open(InstituteAffiliateProgramAddDialog, {data});
    // dialogRef.afterClosed().subscribe((result) => {
    //   this.instituteActivityService.getAffiliateInsNewList('affiliate').subscribe(
    //     (data) => this.handleResponse(data)
    //   );
    // });
  }

  handleResponse1(data)
  {

  }

  onClickSend(data){
    this.instituteActivityService.nominateInActiveUser(data).subscribe(
      data => this.handleResponseNominate(data)
    );
  }

  onClickRemove(data){
    this.instituteActivityService.nominateInActiveUserRemove(data).subscribe(
      data => this.handleResponseNominate(data)
    );
  }

  handleResponseNominate(data){
    this.instituteActivityService.getAllIICUserByFilter(this.preferenceCurrent).subscribe(
      data => this.handleResponseList(data)
    );
  }

}
