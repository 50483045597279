import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-institute-evaluator-assign-dialog',
  templateUrl: './institute-evaluator-assign-dialog.component.html',
  styleUrls: ['./institute-evaluator-assign-dialog.component.css']
})
export class InstituteEvaluatorAssignDialogComponent implements OnInit {

  public ideaForm: FormGroup;
  pocScore: any;
  commentFlag: boolean = false;
  ideaScoreFlag: boolean = false;
  evalList:any;
  aCount:any;
  aCountRemain:any;

  constructor(private fb: FormBuilder,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteEvaluatorAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.ideaForm = this.fb.group({
      evaluators: [null, Validators.compose([Validators.max(50), Validators.min(2)])]
    });
    this.innovationService.getEvaluatorsList(this.data?.id).subscribe(
      data => this.handleResponse(data)
    );
  }

  onSubmit(values) {

   if(values['evaluators']==null || values['evaluators']==undefined) {
      this.toastr.error('Please select evaluators from the list.');
      return false;
    }

    if (values['evaluators'].length <= this.aCountRemain) {

    values['id'] = this.data['idea_id'];
    this.innovationService.submitEvaluatorsList(values).subscribe(
      data => this.handleResponse2(data)
    );

    } else {
    this.toastr.error('Maximum 3 evaluators can be selected!');
  }
  }

  handleResponse(data) {

    this.evalList = data['data'].concat(data['externalList']);

    this.aCount = data['aCount'];

    this.aCountRemain = data['aCountRemain'];

    // if (data['status'] == '1') {
    //   this.toastr.success(data['msg']);
    //   this.dialogRef.close();
    // } else {
    //   this.toastr.error(data['msg']);
    // }



  }

  handleResponse2(data) {

    if (data['status'] == '1') {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }



  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 46) return true;

    return false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
