 <!--title-->
   <title>

    Start Up Policy Committee 2019 | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>


<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Start Up Policy</a>
      </li>
      <li class="aht-breadcrumbs__item">
       Startup Policy Committee 2019
      </li>
    </ul>
  </div>
  
<h2 style="text-align:center; color:#ff5402; margin-bottom: 20px; margin-top:20px; ">Committee for National Innovation and Start up Policy 2019 </h2>


<table align="center" style="margin-bottom: 20px; margin-top:20px; width:800px">
 
  <tr>
    <td><b>Prof. Ashok Jhunjunwala</b> <br>
Professor, Indian Institute of Technology Madras</td>
    <td>Chairman</td>
    
  </tr>
  <tr>
    <td><b>Shri Sukhbir Singh Sandhu</b><br>
Additional Secretary (Higher Education), Ministry of Human Resource Development New Delhi
</td>
    
    <td>Member</td>
  </tr>
 <tr>
    <td><b>Prof. Anil D Sahasrabudhe</b><br>Chairman, All India Council of Technical Education New Delhi

</td>
    
    <td>Member</td>
  </tr>
  
  
  
   <tr>
    <td><b>Dr. Rajnish Jain </b><br> Secretary, University Grants Commission

</td>
    
    <td>Member</td>
  </tr>
  
  
   <tr>
    <td><b>Dr. G. Raghuram </b><br> Director, Indian Institute of Management Bangalore

</td>
    
    <td>Member</td>
  </tr>
  
  
  
   <tr>
    <td><b>Sh. Anand Deshpande </b><br> Chairman and Managing Director, Persistent Systems, Pune

</td>
    
    <td>Member</td>
  </tr>
  
  
  <tr>
    <td><b>Dr. Abhay Karandikar</b><br>Director, Indian Institute of Technology, Kanpur

</td>
    
    <td>Member</td>
  </tr>
  
  
   <tr>
    <td><b>Dr. Udai B. Desai</b><br>Director, Indian Institute of Technology Hyderabad

</td>
    
    <td>Member</td>
  </tr>
  
  
  <tr>
    <td><b>Dr. Appa Rao Podile</b><br>Vice-Chancellor, University of Hyderabad

</td>
    
    <td>Member</td>
  </tr>
  
  
    <tr>
    <td><b>Dr. Mini Shaji Thomas</b><br>Director, National Institute of Technology, Trichy

</td>
    
    <td>Member</td>
  </tr>
  
   
    <tr>
    <td><b>Dr. Sanjay H Inamdar</b><br>CEO, Flucon Industries

</td>
    
    <td>Member</td>
  </tr>
  
   <tr>
    <td><b>Dr. Uday Kumar Yaragatti</b><br>Director, MNIT, Jaipur
</td>
    
    <td>Member</td>
  </tr>
  
  
  <tr>
    <td><b>Dr. Gautam Biswas</b><br>Director, Indian Institute of Information Technology Guwahati
</td>
    
    <td>Member</td>
  </tr>
  
 <tr>
    <td><b>Sh. Hiranmay Mahanta</b><br>Director, Gujarat Technological University Innovation Council
</td>
    
    <td>Member</td>
  </tr>
  
   <tr>
    <td><b>Dr. Abhay Jere</b><br>Chief Innovation Officer, Ministry of Human Resource Development  
</td>
    
    <td>Member Secretary</td>
  </tr>
  

  
</table>


<h2 style="text-align:center; color:#ff5402; margin-bottom: 40px; margin-top:20px; ">NISP 2019 Implementation team at MHRD Innovation Cell</h2>
<table align="center" style="margin-bottom: 20px; margin-top:20px; width:800px">

  <tr>
    <td><b>Mr. Dipan Sahu</b><br> Executive Consultant</td>
    
    <td>011-29581226 <br> dipan.sahu@aicte-india.org</td>
  </tr>
  
  <tr>
    <td><b>Dr. Pooja Rawat</b><br> Innovation Officer</td>
    
    <td>011-29581509 <br> pooja.rawat1@gov.in</td>
  </tr>



</table>


<!-- Footer -->
<app-inner-footer></app-inner-footer>