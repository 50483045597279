import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
// import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/Services/auth.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-atlverification',
  templateUrl: './atlverification.component.html',
  styleUrls: ['./atlverification.component.css'],
})
export class AtlVerificationComponent implements OnInit {

  captchaResponse: any;
  public form = {
    email: null,
    password: null,
  };

  public error = null;
  vid:any;
  message:any;
  status:any;
  sData:any;

  constructor(
    private jarwis: JarwisService,
    private token: TokenService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private myCouncilService: InstituteMyCouncilService,
  ) {



  }

  ngOnInit()
  {
    this.vid = "";

    this.route.queryParams
      .subscribe(params => {
        this.vid = params.vid;
      }
    );

      this.jarwis.atlVerificationSend(this.vid).subscribe(
        (data) => this.handleResponse(data)
      )

  }

  handleResponse(data) {
    this.message = data['message'];
    this.status = data['status'];
    this.sData = data['InstituteData'];
  }


}
