  <mat-dialog-content class="mat-typography">

    <div class="container">

        <div class="row perInformation2">
            <div class="col-md-12">
                <h3 class="pInfor" style="font-weight: bold;">I give my confirmation to participate in IIC 4.0</h3>
            </div>
        </div>

        <form [formGroup]="signupForm" (ngSubmit)="onSubmit(signupForm.value)">
          <div class="form-group"  *ngIf="show_iic_4ostatus==0">
            <div>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Select</mat-label>
                <mat-select autocomplete="off" placeholder="" formControlName="iic_4ostatus"  required (selectionChange)="onIPStatusChange($event)">
                  <mat-option value="1">Yes</mat-option>
                  <mat-option value="2">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>

          <div *ngIf="show_iic_4ostatus!=0" class="form-group">
            <input type="hidden" formControlName="iic_4ostatus" value="{{show_iic_4ostatus}}">
          </div>

          <div class="form-group" *ngIf="this.requireField==1">
            <div>
              <h2>Immediate actions to be taken to participate in IIC 4.0</h2>

              <p>1. Complete the information under MY COUNCIL Menu where the RED Cross Mark is displayed.</p>

              <p>2. Update Teaching/Non-teaching members, Student Members and External Members in MY COUNCIL</p>

              <p>3. Conduct the first Council Meeting of IIC 4.0 and submit the report under Council Meeting of MY COUNCIL</p>
            </div>
          </div>
          <div *ngIf="show_affiliate==1" class="form-group">
            <p style="font-weight: bold;">Does your university give affiliation to the colleges/institutes?</p>
            <mat-radio-group aria-label="Select an option" formControlName="affiliation_status" required>
              <mat-radio-button value="1">Yes</mat-radio-button> &nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button value="2">No</mat-radio-button>
            </mat-radio-group>
          </div>

          <div *ngIf="show_affiliate==0" class="form-group">
            <input type="hidden" formControlName="affiliation_status" value="{{affiliation_status}}">
          </div>

          <mat-dialog-actions>
            <button mat-raised-button color='primary' class="float-right"
              [disabled]='signupForm.invalid'>Submit</button>
          </mat-dialog-actions>

        </form>
    </div>
    <!-- </mat-card> -->
</mat-dialog-content>
<!-- </section> -->
