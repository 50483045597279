<h1 class="instStuDetailsTitle">Quarterly Council Meeting Details </h1>
<h6 class="r">Upload Scanned Copy of following documents</h6>
<!-- <div class="container">
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="councilForm" (ngSubmit)="onSubmit(councilForm.value)">
            <div class="col-12">

                <div>
                    <div class="row">
                        <div class="col-lg-8 col-md-10">
                            <label>Resolution</label>
                            <mat-error>In the form of pdf of max. size 1MB</mat-error>
                        </div>

                        <div class="col-lg-4 col-md-2 float-right">
                            <label class="custom-file-label float-right" for="customFile">Choose file</label>
                            <input type="file" accept="application/pdf" class="custom-file-input float-right" id="resolution" name="resolution_file" multiple (change)="handleFileInput($event.target.files)" required>
                        </div>
                    </div>
                </div>



            </div>


            <div class="row">
                <div fxFlex="50" class="m-2">
                    Quaterely Plan:
                    <button mat-button color="warn">
                        <mat-icon>file_upload</mat-icon>
                        {{text}}
                    </button>
                </div>
            </div>

            <div class="row">
                <div fxFlex="50" class="m-2">
                    MOM:
                    <button mat-button color="warn">
                        <mat-icon>file_upload</mat-icon>
                        {{text}}
                    </button>
                </div>
            </div>

            <div class="row">
                <div fxFlex="50" class="m-2">
                    Photo of Council Meeting:
                    <button mat-button color="warn">
                        <mat-icon>file_upload</mat-icon>
                        {{text}}
                    </button>
                </div>
            </div>

            <div class="row">
                <div fxFlex="50" class="m-2">
                    <mat-form-field class="example-full-width">
                        <mat-label> Date of meeting conducted:</mat-label>
                        <input matInput formControlName="date_conducted_meeting" type="date" placeholder="Date of meeting conducted" required>
                        <mat-error *ngIf="councilForm.controls.date_conducted_meeting.errors?.required">Date is required
                        </mat-error>

                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div fxFlex="50" class="m-2">
                    <mat-form-field class="example-full-width">
                        <mat-label> Quaterly Plan</mat-label>
                        <input matInput formControlName="quarter" placeholder=" Quaterly Plan" required>
                        <mat-error *ngIf="councilForm.controls.quarter.errors?.required">Plan is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div style="text-align: center">
                <button class="mat-elevation-z6 mt-3" color="primary" mat-raised-button [disabled]='councilForm.invalid'>Add</button>
                <button class="ml-4" mat-raised-button [mat-dialog-close]>Close</button>
            </div>
        </form>
    </mat-dialog-content>
</div> -->





<!-- Test -->
<form (submit)="submitApplication()">

  <div class="row">
    <mat-form-field class="col-md-6 col-sm-12" *ngIf="requireField2==0">
      <mat-label>Date Meeting Conducted</mat-label>
      <input matInput [matDatepicker]="picker1" name="date_conducted_meeting" [min]="minDate" [max]="maxDate"  [(ngModel)]="form.date_conducted_meeting">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="!this.quarter">
      <mat-label>Quaterly Plan</mat-label>
      <mat-select placeholder="Quaterly Plan" name="quarter" [(ngModel)]="form.quarter" required (selectionChange)="onIICQuarterChange($event)">
        <mat-option value="Quarter I">Semester 1–Quarter I</mat-option>
        <mat-option value="Quarter II">Semester 1–Quarter II</mat-option>
        <mat-option value="Quarter III">Semester 2–Quarter III</mat-option>
        <mat-option value="Quarter IV">Semester 2–Quarter IV</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="this.quarter">
      <mat-label>Quaterly Plan</mat-label>
      <mat-select placeholder="Quaterly Plan" name="quarter" [(ngModel)]="form.quarter" required>
        <mat-option value="Quarter I">Semester 1–Quarter I</mat-option>
        <mat-option value="Quarter II">Semester 1–Quarter II</mat-option>
        <mat-option value="Quarter III">Semester 2–Quarter III</mat-option>
        <mat-option value="Quarter IV">Semester 2–Quarter IV</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <div fxFlex="50" class="m-2">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon>
                <mat-select placeholder="Qualification" formControlName="qualification" required>
                    <mat-option value="Post Graduate">Post Graduate</mat-option>
                    <mat-option value="Doctorate">Doctorate</mat-option>
                    <mat-option value="Post Doctorate">Post Doctorate</mat-option>
                    <mat-option value="Graduate">Graduate</mat-option>
                </mat-select>
                <mat-error *ngIf="facultyForm.controls['qualification'].hasError('required')">Faculty
                    Qualification is
                    required</mat-error>
            </mat-form-field>
        </div> -->





  </div>

  <div *ngIf="requireField==1" style="padding: 0 0 10px 0;">
    <mat-checkbox class="example-margin" formcontrol="same_as" name="same_as" value="1" [(ngModel)]="form.same_as" (change)="onIICSameAsChange($event)"> {{quartername}}</mat-checkbox>
    <!-- <input type="checkbox" name="same_as" [(ngModel)]="form.same_as" value="1" (change)="onIICSameAsChange($event)"> -->
  </div>

  <div *ngIf="requireField==0">
    <input type="hidden" name="same_as" [(ngModel)]="form.same_as" value="0" >
  </div>


  <div class="rws-filesection" *ngIf="requireField2==0">
  <div *ngIf="!showResolution">
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <label class="labelInstiCouncilMeeting">Resolution</label>
        <mat-error>In the form of pdf of max. size 2MB</mat-error>
      </div>

      <div class="col-lg-4 col-md-2 float-right">
        <label class="custom-file-label float-right" for="customFile" *ngIf="!form.resolution">Choose file</label>
        <label class="custom-file-label float-right" for="customFile"
        *ngIf="form.resolution">{{form.resolution[0]['name']}}</label>
        <input #resolutionRef type="file" accept="application/pdf" class="custom-file-input float-right" id="resolution"
          name="resolution" multiple (change)="( form.resolution = resolutionRef.files )" required>
      </div>

    </div>
  </div>

  <div *ngIf="showResolution">
    <div class="row">
      <a href="{{ data?.resolution }}" target="_blank">
        <button type="button" class="btn btn-danger mt-2 mb-2" style="margin-left: 350px;">Download Resolution</button>
        <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showResolution = !showResolution">Upload Resolution</button>
      </a>
    </div>
  </div>

  <div class="row">
    <a href="{{ imageBasePath }}assets/doc/Resolution_Format.pdf" style="vertical-align: -webkit-baseline-middle;
    float: left;font-size:14px;" target="_blank">
      <font color="#b13c52"><b><u style="margin-left: 650px;">Download the template</u></b></font>
    </a>
  </div>
  <!-- <div class="field">
        <label class="field__label">
         Resolution:
    </label>
        <input #resolutionRef type="file" accept="application/pdf" class="float-right" name="resolution" (change)="( form.resolution = resolutionRef.files )" />
    </div> -->


  <!-- <div class="field">
        <label class="field__label">
        Quarterly Plan:
    </label>
        <input #quarterly_planRef type="file" accept="application/pdf" name="quarterly_plan" (change)="( form.quarterly_plan = quarterly_planRef.files )" class="float-right" />
    </div> -->

  <div *ngIf="!showQuaterlyPlan">
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <label class="labelInstiCouncilMeeting">Quarterly Plan:</label>
        <mat-error>PDF(max 2MB)</mat-error>
      </div>

      <div class="col-lg-4 col-md-2 float-right">
        <label class="custom-file-label float-right" for="customFile" *ngIf="!form.quarterly_plan">Choose file</label>
        <label class="custom-file-label float-right" for="customFile"
        *ngIf="form.quarterly_plan">{{form.quarterly_plan[0]['name']}}</label>
        <input #quarterly_planRef type="file" accept="application/pdf" name="quarterly_plan"
          (change)="( form.quarterly_plan = quarterly_planRef.files )" class="custom-file-input float-right" required>
      </div>
        </div>
  </div>

  <div *ngIf="showQuaterlyPlan">
    <div class="row">
      <a href="{{ data?.quarterly_plan }}" target="_blank">
        <button type="button" class="btn btn-danger mt-2 mb-2" style="margin-left: 330px;">Download Quaterly Plan</button>
        <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showQuaterlyPlan = !showQuaterlyPlan">Upload Quaterly Plan</button>
      </a>
    </div>
  </div>


  <!-- <div class="field">
        <label class="field__label">
          Minutes of Meeting:
        </label>
        <input #minutes_of_meetingRef type="file" accept="application/pdf" name="minutes_of_meeting" (change)="( form.minutes_of_meeting = minutes_of_meetingRef.files )" class="float-right" />
    </div> -->

  <div *ngIf="!showMinutesofMeeting">
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <label class="labelInstiCouncilMeeting">Minutes of Meeting:</label>
        <mat-error>PDF(max 2MB)</mat-error>
      </div>

      <div class="col-lg-4 col-md-2 float-right">
        <label class="custom-file-label float-right" for="customFile"  *ngIf="!form.minutes_of_meeting">Choose file</label>
        <label class="custom-file-label float-right" for="customFile"
        *ngIf="form.minutes_of_meeting">{{form.minutes_of_meeting[0]['name']}}</label>
        <input #minutes_of_meetingRef type="file" accept="application/pdf" name="minutes_of_meeting"
          (change)="( form.minutes_of_meeting = minutes_of_meetingRef.files )" class="custom-file-input float-right"
          required>
      </div>
    </div>
  </div>

  <div *ngIf="showMinutesofMeeting">
    <div class="row">
      <a href="{{ data?.minutes_of_meeting }}" target="_blank">
        <button type="button" class="btn btn-danger mt-2 mb-2" style="margin-left: 300px;">Download Minutes of meeting</button>
        <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showMinutesofMeeting = !showMinutesofMeeting">Upload Minutes of Meeting</button>
      </a>
    </div>
  </div>





  <!-- <div class="field">
        <label class="field__label">
          Photo of Council Meeting
  </label>
        <input #photo_uploadRef type="file" accept="image/*" name="photo_upload" (change)="( form.photo_upload = photo_uploadRef.files )" class="float-right" />
    </div> -->

  <div *ngIf="!showCouncilMeeting">
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <label class="labelInstiCouncilMeeting">Photo of council meeting:</label>
        <mat-error>Image(max 2MB)</mat-error>
      </div>

      <div class="col-lg-4 col-md-2 float-right">
        <label class="custom-file-label float-right" for="customFile" *ngIf="!form.photo_upload">Choose file</label>
        <label class="custom-file-label float-right" for="customFile"
        *ngIf="form.photo_upload">{{form.photo_upload[0]['name']}}</label>
        <input #photo_uploadRef type="file" accept="image/*" name="photo_upload"
          (change)="( form.photo_upload = photo_uploadRef.files )" class="custom-file-input float-right" required>
      </div>
    </div>
  </div>

  <div *ngIf="showCouncilMeeting">
    <div class="row">
      <a href="{{ data?.photo_upload }}" target="_blank">
        <button type="button" class="btn btn-danger mt-2 mb-2" style="margin-left: 270px;">Download Photo of Council meeting</button>
        <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showCouncilMeeting = !showCouncilMeeting">Upload Photo of Council Meeting</button>
      </a>
    </div>
  </div>

</div>


       <!-- <div class="col-lg-4 col-md-2 float-right">
        <label class="custom-file-label float-right" for="customFile" *ngIf="!externalCV">Choose file</label>
        <label class="custom-file-label float-right" for="customFile"
        *ngIf="externalCV">{{externalCV[0]['name']}}</label>
        <input #externalCVRef type="file" accept="application/pdf" name="externalCV"
          (change)="( externalCV = externalCVRef.files )" class="custom-file-input float-right" required>
      </div> -->


  <div>

    <button type="button" mat-raised-button color='warn' (click)='onNoClick()' style="margin-right: 320px;
    " class="btn btn-danger instituteBtnClose">Close</button>
    <!-- <button mat-raised-button color='primary' class="float-right"
         >Submit</button> -->

    <button *ngIf="show1" mat-raised-button color='primary' class="btn btn-success float-right">Submit</button>

    <button *ngIf="show" mat-raised-button color='primary' class="btn btn-success float-right">Update</button>


    <!--

        <button type="submit" class="float-right" mat-raised-button
        color='primary'>Submit Application</button> -->
    <!-- <button type="button" mat-raised-button color='warn' (click)="closeDialog()">Close</button> -->
  </div>

</form>
