import { Component, OnInit } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../../../../../../app/app.component';

@Component({
  selector: 'app-activity-schedule',
  templateUrl: './activity-schedule.component.html',
  styleUrls: ['./activity-schedule.component.css']
})
export class ActivityScheduleComponent implements OnInit {

  baseUrl = myGlobals.baseUrl1;
  public addActivityForm1: FormGroup;
  public addActivityForm2: FormGroup;
  public addActivityForm3: FormGroup;
  public addActivityForm4: FormGroup;
  public addActivityForm5: FormGroup;
  public addActivityForm6: FormGroup;
  public addActivityForm7: FormGroup;
  schedules:any[];
  schedules2:any[];
  schedules3:any[];
  schedules4:any[];
  schedules5:any[];
  schedules6:any[];
  schedules2length:any;
  schedules3length:any;
  schedules4length:any;
  schedules5length:any;
  guidancevalue: any;
  handholdingvalue: any;
  mentor_userId:any;
  total_glines:any;
  glines:any;
  MmStatus_total:any;
  MmStatus:any;

  constructor(private activityService: InstituteActivityService, private toastr: ToastrService, private fb: FormBuilder) {

   }

  ngOnInit(): void {
    this.mentor_userId="";

    this.schedules2length=0;
    this.schedules3length=0;
    this.schedules4length=0;
    this.schedules5length=0;

    this.MmStatus_total=0;

    this.getScheduleTable();

    this.addActivityForm1 = this.fb.group({
      'status_1': [null,Validators.required],
      'status_2': [null],
      'session_value1': [null],
      'session_value2': [null],
      'activity_id': [null]
    });
    
    this.addActivityForm2 = this.fb.group({
      'status_1': [null,Validators.required],
      'status_2': [null],
      'status_3': [null],
      'status_4': [null],
      'activity_id': [null]
    });
    
    this.addActivityForm3 = this.fb.group({
      'status_1': [null,Validators.required],
      'status_2': [null],
      'activity_id': [null]
    });
    
    this.addActivityForm4 = this.fb.group({
      'status_1': [null,Validators.required],
      'status_2': [null],
      'activity_id': [null]
    });
    
    this.addActivityForm5 = this.fb.group({
      'status_1': [null,Validators.required],
      'status_2': [null],
      'activity_id': [null]
    });

     this.addActivityForm6 = this.fb.group({
      nisp_status: false,
      iepolicy_status: false,
      ariia_status: false
    });

    this.addActivityForm7 = this.fb.group({
      ia_status: false,
      mentoring_support: false,
      incubation_status: false
    });

  }

  getScheduleTable(){
    this.activityService.getMenteeActivity().subscribe(data=>this.setSchedule(data));
  }


  setSchedule(data){
    this.schedules=[]
    for(let i=0;i<data['Activities'].length;i++){
      this.schedules.push(data['Activities'][i])
    }


    this.mentor_userId = (data['Activities'].length != 0) ?  data['Activities'][0]['mentor_id'] : "";
if(this.mentor_userId==''){
  this.mentor_userId = (data['Activities2'].length != 0) ?  data['Activities2'][0]['mentor_id'] : "";  
}

if(this.mentor_userId==''){
  this.mentor_userId = (data['Activities3'].length != 0) ?  data['Activities3'][0]['mentor_id'] : "";  
}

    this.MmStatus_total = data['MmStatus_total'];
    this.MmStatus = data['MmStatus'];

    // alert(this.mentor_userId);

    this.schedules2=[]
    for(let i=0;i<data['Activities2'].length;i++){
      this.schedules2.push(data['Activities2'][i])
    }
    //24-7-24

    this.schedules3=[]

    for(let i=0;i<data['Activities3'].length;i++){
      this.schedules3.push(data['Activities3'][i])
    }

    this.schedules4=[]
    for(let i=0;i<data['Activities4'].length;i++){
      this.schedules4.push(data['Activities4'][i])
    }

    this.schedules5=[]
    for(let i=0;i<data['Activities5'].length;i++){
      this.schedules5.push(data['Activities5'][i])
    }

    this.total_glines=data["total_glines"];
    this.glines=data["Glines"];
    if(this.total_glines>0)
    {
      this.addActivityForm6.patchValue({
        'nisp_status'		    : data['Glines']['nisp_status'],
        'iepolicy_status'		: data['Glines']['iepolicy_status'],
        'ariia_status'		  : data['Glines']['ariia_status']
      });

      this.addActivityForm7.patchValue({
        'ia_status'		        : data['Glines']['ia_status'],
        'mentoring_support'		: data['Glines']['mentoring_support'],
        'incubation_status'		: data['Glines']['incubation_status']
      });
    }


    this.schedules2length=data['Activities2'].length;
    this.schedules3length=data['Activities3'].length;
    this.schedules4length=data['Activities4'].length;
    this.schedules5length=data['Activities5'].length;

    this.addActivityForm1.patchValue({
      'status_1'		: data['pStatus']['activity_pstatus_1_1'],
      'status_2'		: data['pStatus']['activity_pstatus_1_2']
    });

    this.addActivityForm2.patchValue({
      'status_1'		: data['pStatus']['activity_pstatus_2_1'],
      'status_2'		: data['pStatus']['activity_pstatus_2_2'],
      'status_3'		: data['pStatus']['activity_pstatus_2_3'],
      'status_4'		: data['pStatus']['activity_pstatus_2_4']
    });

    this.addActivityForm3.patchValue({
      'status_1'		: data['pStatus']['activity_pstatus_3_1'],
      'status_2'		: data['pStatus']['activity_pstatus_3_2']
    });

    this.addActivityForm4.patchValue({
      'status_1'		: data['pStatus']['activity_pstatus_4_1']
    });

    this.addActivityForm5.patchValue({
      'status_1'		: data['pStatus']['activity_pstatus_5_1']
    });
  }

onSubmit1(data){

  data['activity']="Activity 1";

  data['mentor_userId']=this.mentor_userId;

  if(this.addActivityForm1.get('status_1').value!=""
  && this.addActivityForm1.get('status_1').value!= undefined
  && this.addActivityForm1.get('status_1').value!= null)
	{
    if(this.addActivityForm1.get('status_1').value!="") { if(this.addActivityForm1.get('session_value1').value == 1){ data['session_1']="Session 1"; }else if(this.addActivityForm1.get('session_value1').value == 2){ data['session_1']="Session 2"; } }
    if(this.addActivityForm1.get('status_2').value!="") { if(this.addActivityForm1.get('session_value2').value == 1){ data['session_2']="Session 1"; }else if(this.addActivityForm1.get('session_value2').value == 2){ data['session_2']="Session 2"; } }
  }
  else
  {
    alert("Please select the status.");
    return false;
  }
  this.activityService.scheduleStatus(data).subscribe((data) => this.handleResponse(data));
}

onSubmit2(data){

  data['activity']="Activity 2";

  data['mentor_userId']=this.mentor_userId;

  if(this.addActivityForm2.get('status_1').value!=""
  && this.addActivityForm2.get('status_1').value!= undefined
  && this.addActivityForm2.get('status_1').value!= null)
	{
    if(this.addActivityForm2.get('status_1').value!="") { data['session_1']="Session 1"; }
  }
  else
  {
    alert("Please select the status.");
    return false;
  }

  if(this.addActivityForm2.get('status_2').value!=""
  && this.addActivityForm2.get('status_2').value!= undefined
  && this.addActivityForm2.get('status_2').value!= null)
	{
    if(this.addActivityForm2.get('status_2').value!="") { data['session_2']="Session 2"; }
  }

  if(this.addActivityForm2.get('status_3').value!=""
  && this.addActivityForm2.get('status_3').value!= undefined
  && this.addActivityForm2.get('status_3').value!= null)
	{
    if(this.addActivityForm2.get('status_3').value!="") { data['session_3']="Session 3"; }
  }

  if(this.addActivityForm2.get('status_4').value!=""
  && this.addActivityForm2.get('status_4').value!= undefined
  && this.addActivityForm2.get('status_4').value!= null)
	{
    if(this.addActivityForm2.get('status_4').value!="") { data['session_4']="Session 4"; }
  }

  this.activityService.scheduleStatus(data).subscribe((data) => this.handleResponse(data));
}

onSubmit3(data){

  data['activity']="Activity 3";

  data['mentor_userId']=this.mentor_userId;

  if(this.addActivityForm3.get('status_1').value!=""
  && this.addActivityForm3.get('status_1').value!= undefined
  && this.addActivityForm3.get('status_1').value!= null
  && this.addActivityForm3.get('status_2').value!=""
  && this.addActivityForm3.get('status_2').value!= undefined
  && this.addActivityForm3.get('status_2').value!= null)
	{
    if(this.addActivityForm3.get('status_1').value!="") { data['session_1']="Session 1"; }
    if(this.addActivityForm3.get('status_2').value!="") { data['session_2']="Session 2"; }
  }
  else
  {
    alert("Please select the status.");
    return false;
  }
  this.activityService.scheduleStatus(data).subscribe((data) => this.handleResponse(data));
}

onSubmit4(data){

  data['activity']="Activity 4";

  data['mentor_userId']=this.mentor_userId;

  if(this.addActivityForm4.get('status_1').value!=""
  && this.addActivityForm4.get('status_1').value!= undefined
  && this.addActivityForm4.get('status_1').value!= null)
	{
    if(this.addActivityForm4.get('status_1').value!="") { data['session_1']="Session 1"; }
  }
  else
  {
    alert("Please select the status.");
    return false;
  }
  this.activityService.scheduleStatus(data).subscribe((data) => this.handleResponse(data));
}

onSubmit5(data){

  data['activity']="Activity 5";

  data['mentor_userId']=this.mentor_userId;

  if(this.addActivityForm5.get('status_1').value!=""
  && this.addActivityForm5.get('status_1').value!= undefined
  && this.addActivityForm5.get('status_1').value!= null)
	{
    if(this.addActivityForm5.get('status_1').value!="") { data['session_1']="Session 1"; }
  }
  else
  {
    alert("Please select the status.");
    return false;
  }
  this.activityService.scheduleStatus(data).subscribe((data) => this.handleResponse(data));
}


onSubmit6(data){

  data['activity']="Activity 6";
  data['mentor_userId']=this.mentor_userId;
  this.activityService.scheduleStatus(data).subscribe(
    data => this.handleResponse(data)
  );
}

 onSubmit7(data){

    data['activity']="Activity 7";
    data['mentor_userId']=this.mentor_userId;
    this.activityService.scheduleStatus(data).subscribe(
      data => this.handleResponse(data)
    );
}

handleResponse(data){
  if(data['status']=="1"){
    this.toastr.success(data['msg']);
  }
  else
  {
    this.toastr.error(data['msg']);
  }

}



}
