<h1 *ngIf="schedule_id==1" mat-dialog-title class="text-center instStuDetailsTitle">Orientation & Mentoring sessions</h1>
<h1 *ngIf="schedule_id==2" mat-dialog-title class="text-center instStuDetailsTitle">Quarterly Progress Meetings (Q1/Semester 1)</h1>
<h1 *ngIf="schedule_id==22" mat-dialog-title class="text-center instStuDetailsTitle">Quarterly Progress Meetings (Q2/Semester 1)</h1>
<h1 *ngIf="schedule_id==23" mat-dialog-title class="text-center instStuDetailsTitle">Quarterly Progress Meetings (Q3/Semester 2)</h1>
<h1 *ngIf="schedule_id==24" mat-dialog-title class="text-center instStuDetailsTitle">Quarterly Progress Meetings (Q4/Semester 2)</h1>
<h1 *ngIf="schedule_id==3" mat-dialog-title class="text-center instStuDetailsTitle">Organise IIC calendar activity</h1>
<h1 *ngIf="schedule_id==4" mat-dialog-title class="text-center instStuDetailsTitle">I&E Training & Exposure Visit</h1>
<h1 *ngIf="schedule_id==5" mat-dialog-title class="text-center instStuDetailsTitle">Progress Monitoring & Impact Measurement</h1>
<h1 *ngIf="schedule_id==6" mat-dialog-title class="text-center instStuDetailsTitle">Guidance</h1>
<h1 *ngIf="schedule_id==7" mat-dialog-title class="text-center instStuDetailsTitle">Handholding Support</h1>

<div mat-dialog-content>
  <div class="container">
    <form [formGroup]="addActivityForm1" (submit)="onSubmit(addActivityForm1.value)">

    <div class="row">
        <div class="col-lg-4 col-md-10">{{file_upload}}
            <label>Upload Activity Report:</label>
            <mat-error>PDF(max 2MB)</mat-error>
        </div>

        <div class="col-lg-8 col-md-2 float-right"  *ngIf="!reportStatus">
            <label class="custom-file-label float-right" for="customFile" *ngIf="!report">Choose
                file</label>
            <label class="custom-file-label float-right" for="customFile"
                *ngIf="report">{{report[0]['name']}}</label>
            <input #reportRef type="file" accept="application/pdf" name="report"
                (change)="( report = reportRef.files )" class="custom-file-input float-right" >
        </div>
        <div class="col-lg-8 col-md-2 float-right"  *ngIf="reportStatus">
          <a href="{{baseImageUrl + file_upload}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2">Download Report</button>
              <button type="button" mat-raised-button color="warn" class="ml-3"
                  (click)="reportStatus = !reportStatus">Upload
                  Report</button>
          </a>
      </div>
    </div>


<!-- <div *ngIf="reportStatus"> -->

<!-- </div> -->

<div class="row">
		<div class="col-md-12">
      <!-- <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btn btn-danger instituteBtnClose">Close</button> -->
      <!-- <button *ngIf="!edit" mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='addActivityForm.invalid'>Submit</button> -->
      <button mat-raised-button color='primary' class="btn btn-success form1But"
        [disabled]='addActivityForm1.invalid'>Submit</button>
		</div>
      </div>
    </form>
  </div>

</div>
