import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { emailValidator } from 'src/app/app.component';

@Component({
  selector: 'app-yukti-challenges-add',
  templateUrl: './yukti-challenges-add.component.html',
  styleUrls: ['./yukti-challenges-add.component.css']
})
export class YuktiChallengesAddComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  stateList:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  minDate = "2022-11-15";
  maxDate = "2030-08-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });



  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'title': [null, Validators.required],
      'description':[null, Validators.required,],
      'theme': [null, Validators.required],
      'theme_other': [],
      'other_state': [],
      'website': [],
      'level': [null, Validators.required],
      'user_type': [null, Validators.required],
      'region': [null, Validators.required],
      'nominate_from': [null, Validators.required],
      'submission_start_date': [null, Validators.required],
      'submission_end_date': [null, Validators.required],
      'event_start_date': [null, Validators.required],
      'event_end_date': [null, Validators.required],
      'coordinator_name': [null, Validators.required],
      'coordinator_email': [null, Validators.compose([Validators.required, emailValidator])],
      'coordinator_phone': [null, Validators.compose([Validators.required, Validators.minLength(8)])]
    });

    this.requireField=0;
    this.activity_id=0;

    this.instituteActivityService.getIicActivityList("IIC").subscribe(
      (data) => this.handleResponseList(data)
    );

    this.instituteActivityService.getYuktiChallenges(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

    this.instituteActivityService.getAllStates().subscribe(
      (data) => this.handleResponseStateList(data)
    );

  }

  handleResponseStateList(data) {
    if(data['message'] == 'error'){
      this.toastr.error(data['message']);
    }else{
      this.stateList = data;
    }
  } 
  handleResponseForAutoPopulate(data) {

    this.programTheme = "";

  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  changeRegion (val){
    var region = this.iicImpactQuestions.get('region').value;
    if(region == 'Other'){
      document.getElementById('otherState').classList.remove("otherState");
    }else{
      document.getElementById('otherState').classList.add("otherState");
    }
  }

  handleResponse(data) {

    if(data['title']=="SELF")
    {
      this.requireField=0;
    }
    else
    {
      
    }


    if(data['totalcount'] > 0){
    this.iicImpactQuestions.patchValue({
      title: data['title'],
      description: data['description'],
      theme: data['theme'],
      other_state: data['other_state'],
      theme_other: data['theme_other'],
      website: data['website'],
      level: data['level'],
      user_type: data['user_type'],
      region: data['region'],
      nominate_from: data['nominate_from'],
      submission_start_date: data['submission_start_date'],
      submission_end_date: data['submission_end_date'],
      event_start_date: data['event_start_date'],
      event_end_date: data['event_end_date'],
      coordinator_name: data['coordinator_name'],
      coordinator_email: data['coordinator_email'],
      coordinator_phone: data['coordinator_phone']
    });
    if(data['region'] == 'Other'){
      document.getElementById('otherState').classList.remove("otherState");
    }else{
      document.getElementById('otherState').classList.add("otherState");
    }
    //alert(this.iicImpactQuestions.get('activity_id').value);

    this.document = data['brochure'];

  }
    this.totalcount = data['totalcount'];

  }

  handleResponseList(data) {
    if(data['status'] == 'error'){
      this.toastr.error(data['message']);
    }else{
      this.iicActivity = data['data'];
      this.programTheme = data['theme_yukti'];
    }
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('rid', this.rid);
    formData.append('title', this.iicImpactQuestions.get('title').value);
    formData.append('description', this.iicImpactQuestions.get('description').value);
    formData.append('theme', this.iicImpactQuestions.get('theme').value);
    formData.append('other_state', this.iicImpactQuestions.get('other_state').value);
    formData.append('theme_other', this.iicImpactQuestions.get('theme_other').value);
    formData.append('website', this.iicImpactQuestions.get('website').value);
    formData.append('level', this.iicImpactQuestions.get('level').value);
    formData.append('user_type', this.iicImpactQuestions.get('user_type').value);
    formData.append('region', this.iicImpactQuestions.get('region').value);
    formData.append('submission_start_date', this.changeDate(this.iicImpactQuestions.get('submission_start_date').value));
    formData.append('submission_end_date', this.changeDate(this.iicImpactQuestions.get('submission_end_date').value));
    formData.append('event_start_date', this.changeDate(this.iicImpactQuestions.get('event_start_date').value));
    formData.append('event_end_date', this.changeDate(this.iicImpactQuestions.get('event_end_date').value));
    formData.append('coordinator_name', this.iicImpactQuestions.get('coordinator_name').value);
    formData.append('coordinator_email', this.iicImpactQuestions.get('coordinator_email').value);
    formData.append('coordinator_phone', this.iicImpactQuestions.get('coordinator_phone').value);
    formData.append('nominate_from', this.iicImpactQuestions.get('nominate_from').value);

    var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    if(this.totalcount>0)
    {
      formData.append('document', cvFile);
      formData.append('document_old', this.document);
    }
    else
    {
      if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
      formData.append('document', cvFile);
    }

    this.instituteActivityService.submitYuktiChallenges(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/yukti-challenges';
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  onIICStatusChange(ob) {
    let iicStatus = ob.value;
    if(iicStatus == 'SELF') {
      this.requireField = 0;
    } else {
      this.requireField = 1;


      this.instituteActivityService.getIicActivityList(ob.value).subscribe(
        (data) => this.handleResponseList(data)
      );

    }

  }

}
