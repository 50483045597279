<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">
<div class="container-fluid">
  <div style="text-align: center;">
    <h1 style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;    border-radius: 4px;margin: 0 0 20px 0;">IIC Consortium</h1></div>
  
  <!-- <table class="table">
    <thead class="facultyTable">
      <tr>
        <td>Consortium Id</td>
        <td>State</td>
        <td>District</td>
      </tr>      
    </thead>
    <tbody>
      <tr *ngFor="let activity of consortiumData;let i = index">
        <td>{{ activity.consortium_id }}</td>
        <td>{{ activity.state }}</td>
        <td>{{ activity.district }}</td>
      </tr>
    </tbody>
  </table> -->
  <div class="col-12">
    <div class="consortOuter" style="width:100%;box-shadow:0 0.46875rem 2.1875rem rgb(90 97 105 / 10%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 10%), 0 0.25rem 0.53125rem rgb(90 97 105 / 12%), 0 0.125rem 0.1875rem rgb(90 97 105 / 10%) !important;margin: 15px 5px;padding: 10px;">
      <div class="row d-flex" style="align-items: center;">
      <div class="col-6">
        <strong>Consortium Id</strong> : C{{ consortiumData?.consortium_id }} <br />
        <strong>State</strong> : {{ consortiumData?.state }} <br />
        <strong>Districts</strong> : {{ consortiumData?.district }}
      </div>
      <div class="col-6">
        <div class="row">
          <div class="gallery col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="gallery-img-outer">
                <a class="lightbox" href="{{mapImageUrl}}">
                 <!--  <img style="object-fit: cover;" src="https://iic.mic.gov.in/assets/assets/images/imp-team/shubham-agrawal.png" alt="First image"> -->
                 <img style="object-fit: contain;display: block;width: 100%;height: 200px !important;" class="img-fluid" src="{{consortiumData?.Map}}" alt="Map Image">
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <img class="img-fluid mapImage" src="{{ consortiumData?.Map }}" alt="map"> -->
       <!--  <img src="https://iic.mic.gov.in/assets/assets/images/imp-team/shubham-agrawal.png" alt="img" height="200px" width="200px" style="object-fit: cover;"> -->
      </div>
    </div>
    </div>
    

  </div>
  <mat-tab-group mat-align-tabs="center" class="">
    <mat-tab>
      <ng-template mat-tab-label>
          Governing Members
      </ng-template>
      <div class="row">
        <div class="col-md-12">
          <div class="tableBody">
            <div class="table-responsive">
              <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">IIC ID</th>
                    <th width="25%">Institute Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let activity of stateExecute;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{activity?.userId}}</td>
                    <td>{{activity?.instituteName}}</td>
                  </tr>
    
                  <tr *ngIf="total_pages1==0">
                    <td colspan="7" style=" font-size:18px; text-align: center; color: #721c24;
                    background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                    There is no Institutes found.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-paginator #paginator showFirstLastButtons (page)="nextPage2($event)" [length]="total_pages1"
            [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
          </mat-paginator>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
          Participating Members
      </ng-template>

      <div class="row mt-3 mb-3" style="padding: 0 25px;">
          
        <div class="col-6">
          <mat-select placeholder="All Activity Type" [(ngModel)]="activity_filter" (ngModelChange)="activityFilter()"
            class="instiIICcalActi" (selectionChange)="onDistrictChange($event)">
            <mat-option value="">All District</mat-option>
            <mat-option value="{{district.district}}" *ngFor="let district of districtList">{{district.district}}</mat-option>
          </mat-select>
        </div>
    
           <div class="col-6 example">
            <div class="searchBox">
              <input class="searchExter" matInput placeholder="Search Title" (keyup)="filter($event)">
              <button style="margin-top: 4px;display: inline-block;position: absolute;" type="button" class="searchButton" (click)="search()"><i class="fa fa-search" style="font-size: 28px;"></i></button>
            </div>
              
        </div>
      </div>
    
      <div class="row mt-3 mb-3">
        <div class="col-md-12">
          <div class="tableBody" style="box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 10%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 10%), 0 0.25rem 0.53125rem rgb(90 97 105 / 12%), 0 0.125rem 0.1875rem rgb(90 97 105 / 10%) !important;margin: 15px 15px;padding: 15px;">
            <div class="table-responsive">
              <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <th width="5%">S.No123.</th>
                    <th width="10%">IIC ID</th>
                    <th width="20%">Institute Name</th>
                    <th width="25%">Address</th>
                    <th width="20%">District</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let activity of myActivity;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{activity?.userId}}</td>
                    <td>{{activity?.instituteName}}</td>
                    <td>{{activity?.instituteAddress}}</td>
                    <td>{{activity?.instituteCity}}</td>
                  </tr>
    
                  <tr *ngIf="total_pages==0">
                    <td colspan="7" style=" font-size:18px; text-align: center; color: #721c24;
                    background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                    There is no Institutes found.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
            [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
          </mat-paginator>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>
  
  
  
  
  
  
