<mat-dialog-content class="assignEvalutDialog mat-typography">
    <div class="container">
        <h3 class="ccHeadingMForAllDolouge" style="margin-bottom:10px !important;">Verify Team Leader Identity</h3>
        <div class="row">
            <div class="col-md-12">
              <p style="margin-bottom:15px; font-size: 16px; margin-bottom: 20px !important; text-align: center;">Please verify team leader details.</p>
              <p>TL Name: {{data?.team_lead_name}}<br/>
                TL Email: {{data?.team_lead_email}}<br/>
                TL Phone: {{data?.team_lead_phone}}<br/>
                TL Gender: {{data?.team_lead_gender}}<br/>
                TL User Type: {{data?.user_type}}<br/>
                TL Enrollment ID: {{data?.enrollment_id}} </p>
              <p><a href="{{baseUrl + data?.enrollment_copy}}" class="btn btn-warning" target="_blank">View ID</a> </p>
			</div>

              <form [formGroup]="ideaForm" (ngSubmit)="onSubmit(ideaForm.value)" class="irEvaluFor">
                <div class="row btmLine">
                    <div class="col-md-12">
                      <mat-form-field class="col-md-12 example-full-width" appearance="outline">
                        <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
                        <mat-label>Belongs to your institute</mat-label>

                        <mat-select formControlName="verify_status">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>

                      </mat-form-field>
                    </div>
                </div>

                <div class="row btmLine">
                    <div class="col-md-12">
                        <mat-form-field class="col-12 example-full-width" appearance="outline" style="
                        margin: auto;">
                                <mat-label>Remarks</mat-label>
                                <textarea matInput placeholder="Enter Remarks" formControlName="remarks"></textarea>
                            </mat-form-field>
                    </div>
                </div>

                <div mat-dialog-actions align="end" class="mt-3 irEvaluaFor" *ngIf="ForWhom=='Institute'">
                    <button type="button" mat-raised-button color="warn" (click)="closeDialog()"
                        class="btn btn-danger instituteBtnClose">
                        Close
                    </button>
                    <button type="submit" mat-raised-button class="btn btn-success" color="primary">
                        Submit
                    </button>
                </div>
            </form>



        </div>
    </div>
</mat-dialog-content>
