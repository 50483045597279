<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Incubators</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Incubators</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Resources</a></li>
    <li class="breadcrumb-item active" aria-current="page">Incubator Tab</li>
  </ul>
</nav>

<div class="cbp-row wh-content incubTabIIC">
			<div class="cbp-container wh-padding">
				
				<div class="vc_row innovationAmbassadorBox ambPadding1">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2 class="incubTabHeading">Incubation & Pre Incubation facilities in IIC Institutions</h2>
						</div>
					</div>
					<div class="container-login100">
						<div class="vc_col-md-6">
							<div class="headingAmbassadorContentIncub">
								<a routerLink="/incubators" target="_BLANK">
									<div class="incub-round2 incubTabMobRes">
										<img src="assets/assets/images/incubation.png">
										<p >Incubation</p>
									</div>
								</a>
							</div>
						</div>
						<div class="vc_col-md-6">
							<div class="headingAmbassadorContentIncub">
								<a routerLink="/preincubators" target="_BLANK">
									<div class="incub-round2 incubTabMobRes">
										<img src="assets/assets/images/preincubation.png">
										<p>Pre Incubation</p>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
				
				<div class="vc_row-full-width vc_clearfix"></div>
				


			</div>
		</div>



<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>