<link rel="stylesheet" type="text/css" href="/assets/iaProfile/css/bootstrap.min.css" />
<link rel="stylesheet" href="/assets/iaProfile/css/style.css" type="text/css" />

<div id="outer_wrapper">
  <div class="container-fluid">
    <div id="inner_wrapper" class="row">
      <div id="inner_content" class="col-sm-12">
        <div id="form_img" class="col-sm-8">
          <img src="/assets/iaProfile/img/bg-01.jpg" class="img-responsive" alt="" />
        </div>
        <div id="form_text" class="col-sm-4">
          <h2>Innovation Ambassador Online Training Session</h2>
          <div class="col-sm-12">
            <img src="/assets/iaProfile/img/logoIICOnline.png" class="img-responsive" alt="" />
            <h4 class="iaLoginText">Login to continue</h4>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
              <div class="form-group">
                <label for="email">Email address:</label>
                <input formControlName="email" type="email" class="form-control" id="email"
                  placeholder="enter email address" required="true" />
              </div>
              <div class="form-group form-groupLogin">
                <label for="pwd">Password:</label>
                <input type="password" formControlName="password" class="form-control" id="pwd"
                  placeholder="enter password" required="true" />
              </div>
              <div class="form-group form-groupLogin">
                <re-captcha (resolved)="resolved($event)" siteKey="6Lc0dcoZAAAAAGVDDIpIcXdxnFmpl_8iCDhv56hT">
                </re-captcha>
              </div>
              <button type="button" class="btn btn-success btnLogin" type="submit">
                Login
              </button>
            </form>
            <div class="clearfix"></div>
            <p>
              <a routerLink="/nomination-for-IA" class="iaLoginSignupButton">Nomination for Innovation Ambassador Program</a>
              <br/>
              <a routerLink="/registration-for-IA" class="iaLoginSignupButton">Registration of IIC Online Session Participants</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
