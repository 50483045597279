import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ActivityReportForm1Component } from '../activity-report-form1/activity-report-form1.component';
import { ActivityReportForm2Component } from '../activity-report-form2/activity-report-form2.component';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-activity-report-form-pmim',
  templateUrl: './activity-report-form-pmim.component.html',
  styleUrls: ['./activity-report-form-pmim.component.css']
})
export class ActivityReportFormPmimComponent implements OnInit {

  baseUrl = myGlobals.baseUrl1;
  
  public addActivityForm2: FormGroup;
  public addActivityForm5: FormGroup;
  public addActivityForm2_2: FormGroup;
  public addActivityForm2_3: FormGroup;
  public addActivityForm2_4: FormGroup;

  Status:any;
  activity: any[];
  schedules:any[];
  schedules2:any;
  schedules3:any[];
  schedules4:any[];
  Schedules2_2:[];
  Schedules2_3:[];
  Schedules2_4:[];
  schedules5:any;
  activityid: any;
  activity1: boolean;
  activity2: boolean;
  activity3: boolean;
  activity5: boolean;
  activity4: boolean;
  guest_name_1_2_1:any;
  guest_name_2_2_1:any;
  guest_name_3_2_1:any;
  guest_name_4_2_1:any;
  guest_name_5_2_1:any;
  guest_name_1_3_1:any;
  guest_name_2_3_1:any;
  guest_name_3_3_1:any;
  guest_name_4_3_1:any;
  guest_name_5_3_1:any;
  guest_name_1_4_1:any;
  guest_name_2_4_1:any;
  guest_name_3_4_1:any;
  guest_name_4_4_1:any;
  guest_name_5_4_1:any;
  guest_name_6_2_1:any;
  guest_name_7_2_1:any;
  guest_name_8_2_1:any;
  guest_name_9_2_1:any;
  guest_name_10_2_1:any;
  public payload: any;

guest_name_6_3_1:any;
  guest_name_7_3_1:any;
  guest_name_8_3_1:any;
  guest_name_9_3_1:any;
  guest_name_10_3_1:any;

guest_name_6_4_1:any;
  guest_name_7_4_1:any;
  guest_name_8_4_1:any;
  guest_name_9_4_1:any;
  guest_name_10_4_1:any;
  TMentee:any;
  RQ_5_file:any;

  Mentee:any;

  minDate = "2023-09-01";
  maxDate = "2024-12-31";


  constructor(private activityService: InstituteActivityService,private router:Router,
    public dialog: MatDialog, private toastr: ToastrService,private fb: FormBuilder) { }

  ngOnInit(): void {

    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

    this.Schedules2_2=[];
    this.Schedules2_3=[];
    this.Schedules2_4=[];

    this.TMentee=0;

    this.addActivityForm5= this.fb.group({
      'mentee_1_5_1': [null,Validators.required],
      'date_1_5_1': [null,Validators.required],
      'time_1_5_1': [null,Validators.required],
      'guest_1_5_1':[null,Validators.required],
      'guest_1_2':[null],
      'mentee_2_5_1': [null],
      'date_2_5_1': [null],
      'time_2_5_1': [null],
      'guest_2_5_1':[null],
      'guest_2_2':[null],
      'mentee_3_5_1': [null],
      'date_3_5_1': [null],
      'time_3_5_1': [null],
      'guest_3_5_1':[null],
      'guest_3_2':[null],
      'mentee_4_5_1': [null],
      'date_4_5_1': [null],
      'time_4_5_1': [null],
      'guest_4_5_1':[null],
      'guest_4_2':[null],
      'mentee_5_5_1': [null],
      'date_5_5_1': [null],
      'time_5_5_1': [null],
      'guest_5_5_1':[null],
      'guest_5_2':[null],
      'mentee_6_5_1': [null],
      'date_6_5_1': [null],
      'time_6_5_1': [null],
      'guest_6_5_1':[null],
      'guest_6_2':[null],
      'mentee_7_5_1': [null],
      'date_7_5_1': [null],
      'time_7_5_1': [null],
      'guest_7_5_1':[null],
      'guest_7_2':[null],
      'mentee_8_5_1': [null],
      'date_8_5_1': [null],
      'time_8_5_1': [null],
      'guest_8_5_1':[null],
      'guest_8_2':[null],
      'mentee_9_5_1': [null],
      'date_9_5_1': [null],
      'time_9_5_1': [null],
      'guest_9_5_1':[null],
      'guest_9_2':[null],
      'mentee_10_5_1': [null],
      'date_10_5_1': [null],
      'time_10_5_1': [null],
      'guest_10_5_1':[null],
      'guest_10_2':[null],
     });

  }

  getActivityStatus(){
    this.activityService.getActivityStatus().subscribe(
      data => this.setStatus(data)
    );
  }
    ActivityStatus(){
      this.activityService.activityStatus().subscribe(
        data => this.setactivityStatus(data)
      );
    }
    setactivityStatus(data){

      if(data['1']==1){
        this.activity1=true
      }else{
        this.activity1=false
      }

      if(data['2']==1){
        this.activity2=true
      }else{
        this.activity2=false
      }

      if(data['3']==1){
        this.activity3=true
      }else{
        this.activity3=false
      }

      if(data['4']==1){
        this.activity4=true
      }else{
        this.activity4=false
      }

      if(data['5']==1){
        this.activity5=true
      }else{
        this.activity5=false
      }

    }

    openReportDialog(data){

        const dialogRef = this.dialog.open(ActivityReportForm1Component, {
          data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.getScheduleTable();
        });

    }

    openReportDialog1(data){
        const dialogRef = this.dialog.open(ActivityReportForm2Component, {
          data: data,
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.getScheduleTable();
        });

    }

    onEditClick(activity_type,menteeuserid){
      const data1={
        activityId:activity_type,
        menteeId:menteeuserid
      }
      this.openReportDialog1(data1);
    }

    getScheduleTable(){
      this.activityService.schedules().subscribe(
        data => this.setScheduleTable(data)
      );
    }
    getDataTable(){
      this.activityService.activities().subscribe(
        data => this.setDataTable(data)
      );
    }

    setStatus(data){
      this.Status=data;
      //
    }
    setScheduleTable(data){
      this.schedules=[];
      for(let i=0;i<data['Schedules'].length;i++){
        this.schedules.push(data['Schedules'][i])
    }

    this.schedules3=[];
    for(let i=0;i<data['Schedules3'].length;i++){
      this.schedules3.push(data['Schedules3'][i])
    }

    this.schedules4=[];
    for(let i=0;i<data['Schedules4'].length;i++){
      this.schedules4.push(data['Schedules4'][i])
    }

    // this.schedules5=[];
    // for(let i=0;i<data['schedules5'].length;i++){
    //   this.schedules5.push(data['schedules5'][i])
    // }

    this.Mentee = data["Mentee"];

    this.TMentee=data["TMentee"];

    // this.RQ_5_file=data["RQ_5_file"];

    // alert(this.TMentee);

    if(this.TMentee>=1)
    {
      if(this.Mentee[0].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_1_5_1':this.Mentee[0].mentee_userId });
      }
    }

    if(this.TMentee>=2)
    {
      if(this.Mentee[1].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_2_5_1':this.Mentee[1].mentee_userId });
      }
    }

	if(this.TMentee>=3)
    {
      if(this.Mentee[2].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_3_5_1':this.Mentee[2].mentee_userId });
      }
    }

	if(this.TMentee>=4)
    {
      if(this.Mentee[3].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_4_5_1':this.Mentee[3].mentee_userId });
      }
    }

	if(this.TMentee>=5)
    {
      if(this.Mentee[4].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_5_5_1':this.Mentee[4].mentee_userId });
      }
    }

	if(this.TMentee>=6)
    {
      if(this.Mentee[5].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_6_5_1':this.Mentee[5].mentee_userId });
      }
    }

	if(this.TMentee>=7)
    {
      if(this.Mentee[6].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_7_5_1':this.Mentee[6].mentee_userId });
      }
    }

	if(this.TMentee>=8)
    {
      if(this.Mentee[7].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_8_5_1':this.Mentee[7].mentee_userId });
      }
    }

	if(this.TMentee>=9)
    {
      if(this.Mentee[8].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_9_5_1':this.Mentee[8].mentee_userId });
      }
    }

	if(this.TMentee>=10)
    {
      if(this.Mentee[9].mentee_userId!="")
      {
        this.addActivityForm5.patchValue({ 'mentee_10_5_1':this.Mentee[9].mentee_userId });
      }
    }

    this.schedules2=data['Schedules2'];
    this.schedules5=data['Schedules5'];

    this.Schedules2_2=[];
    this.Schedules2_3=[];
    this.Schedules2_4=[];

    this.Schedules2_2=data['Schedules2_2'];
    this.Schedules2_3=data['Schedules2_3'];
    this.Schedules2_4=data['Schedules2_4'];

     this.guest_name_1_4_1 = data['Schedules2_4']['guest_name_1_4_1'];
    this.guest_name_2_4_1 = data['Schedules2_4']['guest_name_2_4_1'];
    this.guest_name_3_4_1 = data['Schedules2_4']['guest_name_3_4_1'];
    this.guest_name_4_4_1 = data['Schedules2_4']['guest_name_4_4_1'];
    this.guest_name_5_4_1 = data['Schedules2_4']['guest_name_5_4_1'];

    this.guest_name_6_4_1 = data['Schedules2_2']['guest_name_6_4_1'];
    this.guest_name_7_4_1 = data['Schedules2_2']['guest_name_7_4_1'];
    this.guest_name_8_4_1 = data['Schedules2_2']['guest_name_8_4_1'];
    this.guest_name_9_4_1 = data['Schedules2_2']['guest_name_9_4_1'];
    this.guest_name_10_4_1 = data['Schedules2_2']['guest_name_10_4_1'];

    if(this.TMentee>=1)
    {
      if(this.Mentee[0].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
		  'mentee_1_5_1'	: this.Mentee[0].mentee_userId,
		  'date_1_5_1'		: data['Schedules5']['date_1_5_1'],
		  'time_1_5_1'		: data['Schedules5']['time_1_5_1'],
		  'guest_1_5_1'		: data['Schedules5']['guest_1_5_1'],
		  'guest_1_2'		: data['Schedules5']['guest_1_2'],
		 });
	  }
	}

	if(this.TMentee>=2)
    {
      if(this.Mentee[1].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
		  'mentee_2_5_1'	: this.Mentee[1].mentee_userId,
      'date_2_5_1'		: data['Schedules5']['date_2_5_1'],
      'time_2_5_1'		: data['Schedules5']['time_2_5_1'],
      'guest_2_5_1'		: data['Schedules5']['guest_2_5_1'],
      'guest_2_2'		: data['Schedules5']['guest_2_2'],
		 });
	  }
	}

	if(this.TMentee>=3)
    {
      if(this.Mentee[2].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
		  'mentee_3_5_1'	: this.Mentee[2].mentee_userId,
      'date_3_5_1'		: data['Schedules5']['date_3_5_1'],
      'time_3_5_1'		: data['Schedules5']['time_3_5_1'],
      'guest_3_5_1'		: data['Schedules5']['guest_3_5_1'],
      'guest_3_2'		: data['Schedules5']['guest_3_2'],
		 });
	  }
	}

	if(this.TMentee>=4)
    {
      if(this.Mentee[3].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
		  'mentee_4_5_1'	: this.Mentee[3].mentee_userId,
      'date_4_5_1'		: data['Schedules5']['date_4_5_1'],
      'time_4_5_1'		: data['Schedules5']['time_4_5_1'],
      'guest_4_5_1'		: data['Schedules5']['guest_4_5_1'],
      'guest_4_2'		: data['Schedules5']['guest_4_2'],
		 });
	  }
	}

	if(this.TMentee>=5)
    {
      if(this.Mentee[4].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
		  'mentee_5_5_1'	: this.Mentee[4].mentee_userId,
      'date_5_5_1'		: data['Schedules5']['date_5_5_1'],
      'time_5_5_1'		: data['Schedules5']['time_5_5_1'],
      'guest_5_5_1'		: data['Schedules5']['guest_5_5_1'],
      'guest_5_2'		: data['Schedules5']['guest_5_2'],
		 });
	  }
	}

	if(this.TMentee>=6)
    {
      if(this.Mentee[5].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
		  'mentee_6_5_1'	: this.Mentee[5].mentee_userId,
      'date_6_5_1'		: data['Schedules5']['date_6_5_1'],
      'time_6_5_1'		: data['Schedules5']['time_6_5_1'],
      'guest_6_5_1'		: data['Schedules5']['guest_6_5_1'],
		 });
	  }
	}

	if(this.TMentee>=7)
    {
      if(this.Mentee[6].mentee_userId!="")
      {
	  this.addActivityForm5.patchValue({
		  'mentee_7_5_1'	: this.Mentee[6].mentee_userId,
      'date_7_5_1'		: data['Schedules5']['date_7_5_1'],
      'time_7_5_1'		: data['Schedules5']['time_7_5_1'],
      'guest_7_5_1'		: data['Schedules5']['guest_7_5_1'],
		 });
	  }
	}

	if(this.TMentee>=8)
    {
      if(this.Mentee[7].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
	    'mentee_8_5_1'	: this.Mentee[7].mentee_userId,
      'date_8_5_1'		: data['Schedules5']['date_8_5_1'],
      'time_8_5_1'		: data['Schedules5']['time_8_5_1'],
      'guest_8_5_1'		: data['Schedules5']['guest_8_5_1'],
		 });
	  }
	}

	if(this.TMentee>=9)
    {
      if(this.Mentee[8].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
		  'mentee_9_5_1'	: this.Mentee[8].mentee_userId,
      'date_9_5_1'		: data['Schedules5']['date_9_5_1'],
      'time_9_5_1'		: data['Schedules5']['time_9_5_1'],
      'guest_9_5_1'		: data['Schedules5']['guest_9_5_1'],
		 });
	  }
	}

	if(this.TMentee>=10)
    {
      if(this.Mentee[9].mentee_userId!="")
      {
		this.addActivityForm5.patchValue({
		  'mentee_10_5_1'	: this.Mentee[9].mentee_userId,
      'date_10_5_1'		: data['Schedules5']['date_10_5_1'],
      'time_10_5_1'		: data['Schedules5']['time_10_5_1'],
      'guest_10_5_1'		: data['Schedules5']['guest_10_5_1'],
		 });
	  }
	}

  }

  setDataTable(data){
    this.activity=[];
    for(let i=0;i<data['Activities'].length;i++){
      this.activity.push(data['Activities'][i])
    }
  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  onSubmitForm5(data){

    data["formType"]='5';



    if(this.addActivityForm5.get('date_1_5_1').value!="" && this.addActivityForm5.get('date_1_5_1').value!= undefined  && this.addActivityForm5.get('date_1_5_1').value!= null)
    {
      if(this.addActivityForm5.get('time_1_5_1').value== undefined  || this.addActivityForm5.get('time_1_5_1').value== null)
      {
        alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_1_5_1').value);
        return false;
      }
      if(this.addActivityForm5.get('guest_1_5_1').value== undefined  || this.addActivityForm5.get('guest_1_5_1').value== null)
      {
        alert("Please fill the guest for "+this.addActivityForm5.get('mentee_1_5_1').value);
        return false;
      }

      data["date_1_5_1"]=this.changeDate(this.addActivityForm5.get('date_1_5_1').value);

    }
    else
    {
      alert("Please fill the Tentative date for "+this.addActivityForm5.get('mentee_1_5_1').value);
        return false;
    }

    if(this.addActivityForm5.get('date_2_5_1').value!="" && this.addActivityForm5.get('date_2_5_1').value!= undefined  && this.addActivityForm5.get('date_2_5_1').value!= null)
    {
      if(this.addActivityForm5.get('time_2_5_1').value== undefined  || this.addActivityForm5.get('time_2_5_1').value== null)
      {
        alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_2_5_1').value);
        return false;
      }
      if(this.addActivityForm5.get('guest_2_5_1').value== undefined  || this.addActivityForm5.get('guest_2_5_1').value== null)
      {
        alert("Please fill the guest for "+this.addActivityForm5.get('mentee_2_5_1').value);
        return false;
      }

      data["date_2_5_1"]=this.changeDate(this.addActivityForm5.get('date_2_5_1').value);

    }

	  if(this.addActivityForm5.get('date_3_5_1').value!="" && this.addActivityForm5.get('date_3_5_1').value!= undefined  && this.addActivityForm5.get('date_3_5_1').value!= null)
	  {
      if(this.addActivityForm5.get('time_3_5_1').value== undefined  || this.addActivityForm5.get('time_3_5_1').value== null)
      {
        alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_3_5_1').value);
        return false;
      }
      if(this.addActivityForm5.get('guest_3_5_1').value== undefined  || this.addActivityForm5.get('guest_3_5_1').value== null)
      {
        alert("Please fill the guest for "+this.addActivityForm5.get('mentee_3_5_1').value);
        return false;
      }

      data["date_3_5_1"]=this.changeDate(this.addActivityForm5.get('date_3_5_1').value);

    }

	if(this.addActivityForm5.get('date_4_5_1').value!="" && this.addActivityForm5.get('date_4_5_1').value!= undefined  && this.addActivityForm5.get('date_4_5_1').value!= null)
	{
		if(this.addActivityForm5.get('time_4_5_1').value== undefined  || this.addActivityForm5.get('time_4_5_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_4_5_1').value);
			return false;
		}
		if(this.addActivityForm5.get('guest_4_5_1').value== undefined  || this.addActivityForm5.get('guest_4_5_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm5.get('mentee_4_5_1').value);
			return false;
		}

    data["date_4_5_1"]=this.changeDate(this.addActivityForm5.get('date_4_5_1').value);

    }

	if(this.addActivityForm5.get('date_5_5_1').value!="" && this.addActivityForm5.get('date_5_5_1').value!= undefined  && this.addActivityForm5.get('date_5_5_1').value!= null)
	{
		if(this.addActivityForm5.get('time_5_5_1').value== undefined  || this.addActivityForm5.get('time_5_5_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_5_5_1').value);
			return false;
		}
		if(this.addActivityForm5.get('guest_5_5_1').value== undefined  || this.addActivityForm5.get('guest_5_5_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm5.get('mentee_5_5_1').value);
			return false;
		}

    data["date_5_5_1"]=this.changeDate(this.addActivityForm5.get('date_5_5_1').value);

    }

    if(this.addActivityForm5.get('date_6_5_1').value!="" && this.addActivityForm5.get('date_6_5_1').value!= undefined  && this.addActivityForm5.get('date_6_5_1').value!= null)
    {
      if(this.addActivityForm5.get('time_6_5_1').value== undefined  || this.addActivityForm5.get('time_6_5_1').value== null)
      {
        alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_6_5_1').value);
        return false;
      }
      if(this.addActivityForm5.get('guest_6_5_1').value== undefined  || this.addActivityForm5.get('guest_6_5_1').value== null)
      {
        alert("Please fill the guest for "+this.addActivityForm5.get('mentee_6_5_1').value);
        return false;
      }

      data["date_6_5_1"]=this.changeDate(this.addActivityForm5.get('date_6_5_1').value);

      }

    if(this.addActivityForm5.get('date_7_5_1').value!="" && this.addActivityForm5.get('date_7_5_1').value!= undefined  && this.addActivityForm5.get('date_7_5_1').value!= null)
    {
      if(this.addActivityForm5.get('time_7_5_1').value== undefined  || this.addActivityForm5.get('time_7_5_1').value== null)
      {
        alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_7_5_1').value);
        return false;
      }
      if(this.addActivityForm5.get('guest_7_5_1').value== undefined  || this.addActivityForm5.get('guest_7_5_1').value== null)
      {
        alert("Please fill the guest for "+this.addActivityForm5.get('mentee_7_5_1').value);
        return false;
      }

      data["date_7_5_1"]=this.changeDate(this.addActivityForm5.get('date_7_5_1').value);

      }

    if(this.addActivityForm5.get('date_8_5_1').value!="" && this.addActivityForm5.get('date_8_5_1').value!= undefined  && this.addActivityForm5.get('date_8_5_1').value!= null)
    {
      if(this.addActivityForm5.get('time_8_5_1').value== undefined  || this.addActivityForm5.get('time_8_5_1').value== null)
      {
        alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_8_5_1').value);
        return false;
      }
      if(this.addActivityForm5.get('guest_8_5_1').value== undefined  || this.addActivityForm5.get('guest_8_5_1').value== null)
      {
        alert("Please fill the guest for "+this.addActivityForm5.get('mentee_8_5_1').value);
        return false;
      }

      data["date_8_5_1"]=this.changeDate(this.addActivityForm5.get('date_8_5_1').value);

      }

      if(this.addActivityForm5.get('date_9_5_1').value!="" && this.addActivityForm5.get('date_9_5_1').value!= undefined  && this.addActivityForm5.get('date_9_5_1').value!= null)
    {
      if(this.addActivityForm5.get('time_9_5_1').value== undefined  || this.addActivityForm5.get('time_9_5_1').value== null)
      {
        alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_9_5_1').value);
        return false;
      }
      if(this.addActivityForm5.get('guest_9_5_1').value== undefined  || this.addActivityForm5.get('guest_9_5_1').value== null)
      {
        alert("Please fill the guest for "+this.addActivityForm5.get('mentee_9_5_1').value);
        return false;
      }

      data["date_9_5_1"]=this.changeDate(this.addActivityForm5.get('date_9_5_1').value);

      }

      if(this.addActivityForm5.get('date_10_5_1').value!="" && this.addActivityForm5.get('date_10_5_1').value!= undefined  && this.addActivityForm5.get('date_10_5_1').value!= null)
    {
      if(this.addActivityForm5.get('time_10_5_1').value== undefined  || this.addActivityForm5.get('time_10_5_1').value== null)
      {
        alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_10_5_1').value);
        return false;
      }
      if(this.addActivityForm5.get('guest_10_5_1').value== undefined  || this.addActivityForm5.get('guest_10_5_1').value== null)
      {
        alert("Please fill the guest for "+this.addActivityForm5.get('mentee_10_5_1').value);
        return false;
      }

      data["date_10_5_1"]=this.changeDate(this.addActivityForm5.get('date_10_5_1').value);

      }

    data["date_1_5_1"]=this.changeDate(this.addActivityForm5.get('date_1_5_1').value);

    data["quarter_1_5_1"]="Quarter 4";
    data["quarter_2_5_1"]="Quarter 4";
    data["quarter_3_5_1"]="Quarter 4";
    data["quarter_4_5_1"]="Quarter 4";
    data["quarter_5_5_1"]="Quarter 4";
    data["quarter_6_5_1"]="Quarter 4";
    data["quarter_7_5_1"]="Quarter 4";
    data["quarter_8_5_1"]="Quarter 4";
    data["quarter_9_5_1"]="Quarter 4";
    data["quarter_10_5_1"]="Quarter 4";

    this.activityService
    .submitSchedule25(data)
    .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data){
    if(data['message']=="Success"){
      this.toastr.success("Successfully Submitted");
    }

  }


}
