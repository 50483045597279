import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';
import { emailValidator } from 'src/app/app.component';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';
import { Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-nominations-dialog',
  templateUrl: './nominations-dialog.component.html',
  styleUrls: ['./nominations-dialog.component.css']
})
export class NominationsDialogComponent implements OnInit {
  public nominationsForm: FormGroup;
  show: boolean;
  show1: boolean;
  id1: any;
  name1: any;
  email1: any;
  contact1: any;
  mstatusform: any;
  type_id:any;
  edit_id:any;

  constructor(private fb: FormBuilder,
    private jarwis: JarwisService,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<NominationsDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
    }
  }

  ngOnInit(): void {
    //this.mstatusform = localStorage.getItem('UserMenteeStatus');
    this.nominationsForm = this.fb.group({
      'name': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, emailValidator,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
      ],
      'id': [null],
      'contact': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])]
    });

      if (this.data) {
      //this.fbl = this.data;
      this.id1=this.data['id'];
      this.name1 = this.data['name'];
      this.email1 = this.data['email'];
      this.contact1 = this.data['contact'];
      this.nominationsForm.controls['id'].setValue(this.id1);
      this.nominationsForm.controls['name'].setValue(this.name1);
      this.nominationsForm.controls['email'].setValue(this.email1);
      this.nominationsForm.controls['contact'].setValue(this.contact1);
    }
  }

  onSubmit(values) {
    this.myCouncilService.EditFaculty(values).subscribe(
      data => this.handleResponse1(data)
    );
  }

  handleResponse1(data) {
    if (data['message'] == "Success") {
      this.toastr.success("Nominated Member Updated Successfully !");
      this.dialogRef.close();
      window.location.href='/institute/nomination';
    } else {
      this.toastr.error(data['msg']);
    }
    this.myCouncilService.dataUpdated.next(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

}
