import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { OnlineRegistrationService } from 'src/app/Services/onlineSession/online-registration.service';

@Component({
  selector: 'app-registration-online-session',
  templateUrl: './registration-online-session.component.html',
  styleUrls: ['./registration-online-session.component.css']
})
export class RegistrationOnlineSessionComponent implements OnInit {
registrationOnlineForm: FormGroup;
inc: number = 1;
cnt:number=1;
institutes: any;
stateArr: any; 
optionValue:any;

   
  constructor(private fb:FormBuilder, private toastr: ToastrService, private onlineService: OnlineRegistrationService) {
   
    this.registrationOnlineForm = this.fb.group({
	  institute_type: [null, Validators.required],
	  state: [null, Validators.required],
	  institute_name: [null, Validators.required],
      quantities: this.fb.array([]) ,
    });
  }
  ngOnInit(): void {
	  this.onlineService.getState().subscribe(
      data => this.setState(data));

    this.registrationOnlineForm.get('state').valueChanges.subscribe(
      data => this.getInstitutes(data)
    );
this.addQuantity();
  }
  setState(data) {
    this.stateArr = data['instituteDetails'];
  }

  getInstitutes(data) {
    this.onlineService.getInstitutes(data).subscribe(
      data => this.setInstitutes(data)
    );
  }

  setInstitutes(data) {
    this.institutes = data['instituteDetails'];
  }
  quantities() : FormArray {
	  
    return this.registrationOnlineForm.get("quantities") as FormArray
  }
   
  newQuantity(): FormGroup {
    return this.fb.group({
      name: '',
      email: '',
	  phone: '',
      designation: '',
	  gender: '',
    })
  }
   
  addQuantity() {
	  
	  if(this.cnt<10){
	  this.cnt = this.inc++;
    this.quantities().push(this.newQuantity());
	  }else{
		  alert('You can add maximum 10 details.');
	  }
	
	
  }
   
  removeQuantity(i:number) {
	  this.inc = this.cnt--;
    this.quantities().removeAt(i);
  }
   
  onSubmit(values) {
    if (this.registrationOnlineForm.valid) {
      this.onlineService.submitRegistrationOnlineForm(values).subscribe(
        data => this.handleResponse(data)
      );
    } else {
      this.toastr.error('Kindly fill all the details properly!')
    }
  }
  handleResponse(data) {
    if (data['message'] == 'Success') {
      this.toastr.success('Registered Successfully')
    } else {
      alert(data['message']);
    }

  }
  
}
