<app-institute-navbar></app-institute-navbar>

<div [ngClass]="toggle ? 'main-panel-expand' : 'main-panel'">
  <div class="content">
    <div class="page-inner">

      <div class="row m-4"></div>
      <div class="row m-4"></div>

      <!-- <div class="row m-4">
		<marquee behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();"><p><a href="assets/announcements/IA_Announcement.pdf" target="_blank">MIC's Innovation Ambassador Training has started for second phase nominees from 10th August 2021.Details about training have been sent to their registered email.</a></p> </marquee>
	  </div> -->

      <!-- <div class="row text-center mt-6">
        <div class="col-sm-12 text-center">
          <h1 class="instMainHeaderInstName">{{institute_name}}</h1>
        </div>
      </div> -->

      <!-- <div class="row">
        <div class="col-sm-12 text-center">
          <h2 style="color: black">{{userId}}</h2>
          <ul class="list-inline rating-list" *ngFor="let star of stars" style="display: inline-block">
            <li [ngClass]="{'selected': (star <= selectedValue)}">
              <i class="fa fa-star"></i>
            </li>
          </ul>
        </div>
      </div> -->

      <!-- <div class="row text-center">
        <div class="col-sm-12 text-center">
          <h2 style="text-decoration: underline;color: blue;">Quarter 1 Score | Quarter 2 Score Cumulative Score:
            {{cumulativeScore}} Reward Points: {{rewardPoints}}<span class="badge badge-secondary"
              (click)="openDialog()" style="cursor: pointer">View</span>
          </h2>
        </div>
      </div> -->
	  <!--
	  <marquee scrolldelay="50" id="scroll_news_school" bgcolor="#394787" style="font-family: Book Antiqua; font-weight: bold; color: #FFFFFF; padding: 10px 0; background: #03646e;"><div onmouseover="document.getElementById('scroll_news_school').stop();" onmouseout="document.getElementById('scroll_news_school').start();"> Quarter 3 and Quarter 4 are merged.  Institutes shall conduct and submit the reports by 31st August 2022.</div></marquee> -->

	  <!-- <p style="display:block; overflow:hidden; padding:5px 10px; font-size:18px; text-align:center; width:94%; margin:0 auto;"><a href="javascript:void(0);" [routerLink]="['/institute/announcement/nominate-representative']" class="btn btn-primary">Nominate Representatives for IIC Regional Meet</a></p>

	  <div class="row m-4"></div> -->

    </div>

  </div>




  <router-outlet></router-outlet>

  <app-institute-footer></app-institute-footer>

</div>
