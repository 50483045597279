import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mentor-mentee-program',
  templateUrl: './mentor-mentee-program.component.html',
  styleUrls: ['./mentor-mentee-program.component.css']
})
export class MentorMenteeProgramComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
