<!--title-->
   <title>

    India First Leadership Talk Series | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>


<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Leadership Talk</a>
      </li>
      <li class="aht-breadcrumbs__item">
       Past Episode
      </li>
    </ul>
  </div>
<section class="bggrey2">
        <div class="container">
          <div class="row">

    <div class="col-md-12">

            <div class="titleheadertop">

              <h1 class="inner-theme-heading">India First Leadership Talk Series</h1>

            </div>

        </div>

    </div>

        <div class="row" style="margin-top: 30px;">
          <div class="col-md-12">
              <div>
                <div class='tabs maintabing1'>
                  <div class='tab-buttons'>
                    <!--<span class='content1'>Upcoming Episode</span>-->
                    <span class='content2 active'>Past Episodes</span>
                  </div>
                </div>
            </div>
        </div>
    </div>


  <div class='tab-content'>

   <div class='content2'>
     <div class="row">
        <div class="col-md-4">
            <div class="upcaming">
                    <img src="{{imageBasePath}}assets/wp_images/iflts1.jpg">

                       <div class="upcamingbox">
                          <h3><a href="https://www.youtube.com/watch?v=D9PIA53C-7I">How to deal with failure</a> </h3>
                          <p>Shri Anand Mahindra</p>

                                                   <div class="upcamingboxvideo">
                        <ul>
                            <li><a href="https://www.youtube.com/watch?v=D9PIA53C-7I"><i class="fa fa-youtube-play"></i> Watch Videos</a></li>
                            <li><a href="#"><i class="fa fa-photo"></i> Photos</a></li>
                            <li><a href="#"><i class='fas fa-award'></i> Winners</a></li>
                        </ul>
                    </div>

                       </div>


                </div>
             </div>

                     <div class="col-md-4">
            <div class="upcaming">
                    <img src="{{imageBasePath}}assets/wp_images/iflts2.jpg">

                       <div class="upcamingbox">
                          <h3><a href="https://www.youtube.com/watch?v=sbbAPNiSe1g">Talk on Planning for Career</a></h3>
                          <p>Dr. Anand Deshpande</p>

                                                   <div class="upcamingboxvideo">
                        <ul>
                            <li><a href="https://www.youtube.com/watch?v=sbbAPNiSe1g"><i class="fa fa-youtube-play"></i> Watch Videos</a></li>
                            <li><a href="#"><i class="fa fa-photo"></i> Photos</a></li>
                            <li><a href="#"><i class='fas fa-award'></i> Winners</a></li>
                        </ul>
                    </div>
                    
                       </div>


                </div>
             </div>

          <div class="col-md-4">
            <div class="upcaming">
                    <img src="{{imageBasePath}}assets/wp_images/iflts4.jpg">

                       <div class="upcamingbox">
                          <h3><a href="https://www.youtube.com/watch?v=FxQN98gy1Ec&amp;t=1334s">Art of Decision Making</a></h3>
                          <p>Shri Ajit Doval</p>

                                                   <div class="upcamingboxvideo">
                        <ul>
                            <li><a href="https://www.youtube.com/watch?v=FxQN98gy1Ec&amp;t=1334s"><i class="fa fa-youtube-play"></i> Watch Videos</a></li>
                            <li><a href="#"><i class="fa fa-photo"></i> Photos</a></li>
                            <li><a href="#"><i class='fas fa-award'></i> Winners</a></li>
                        </ul>
                    </div>
                    
                       </div>


                </div>
             </div>

             


          </div>
			<div class="row">
           <div class="col-md-4">
            <div class="upcaming">
                    <img src="{{imageBasePath}}assets/wp_images/iflts3.png">

                       <div class="upcamingbox">
                          <h3><a href="https://www.youtube.com/watch?v=38KmhJzXpC4&amp;t=86s">Future of Technical Education in India</a> </h3>
                          <p>Prof. Anil D Sahasrabudhe</p>

                                                   <div class="upcamingboxvideo">
                        <ul>
                            <li><a href="https://www.youtube.com/watch?v=38KmhJzXpC4&amp;t=86s"><i class="fa fa-youtube-play"></i> Watch Videos</a></li>
                            <li><a href="#"><i class="fa fa-photo"></i> Photos</a></li>
                            <li><a href="#"><i class='fas fa-award'></i> Winners</a></li>
                        </ul>
                    </div>
                    
                       </div>


                </div>
				
				
				
				
				
             </div>
 <div class="col-md-4">
            <div class="upcaming">
                    <img src="{{imageBasePath}}assets/wp_images/iflts5.png">

                       <div class="upcamingbox">
                          <h3><a href="https://www.youtube.com/watch?v=VEe15hhRpbQ&amp;t=19s">Innovation for Resurgent India</a></h3>
                          <p>Dr. Ramesh Pokhriyal 'Nishank', Minister of HRD, Government of India</p>

                                                   <div class="upcamingboxvideo">
                        <ul>
                            <li><a href="https://www.youtube.com/watch?v=VEe15hhRpbQ&amp;t=19s"><i class="fa fa-youtube-play"></i> Watch Videos</a></li>
                            <li><a href="#"><i class="fa fa-photo"></i> Photos</a></li>
                            <li><a href="assets/doc/winners_iflts_05.pdf"><i class='fas fa-award'></i> Winners</a></li>
                        </ul>
                    </div>
                    
                       </div>


                </div>
             </div>
			 <div class="col-md-4">
            <div class="upcaming">
                    <img src="{{imageBasePath}}assets/wp_images/leadership-talk%20-1.jpg">

                       <div class="upcamingbox">
                          <h3><a href="https://youtu.be/bRgFQ2ozosE">Innovation for Resurgent India</a></h3>
                          <p>Mr. Abhishek Suryawanshi, Director, Project SWASTHA,Wikipedia</p>

                                                   <div class="upcamingboxvideo">
                        <ul>
                            <li><a href="https://youtu.be/bRgFQ2ozosE"><i class="fa fa-youtube-play"></i> Watch Videos</a></li>
                            <!--<li><a href="#"><i class="fa fa-photo"></i> Photos</a></li>
                            <li><a href="http://qa.mic.gov.in/assets/doc/winners_iflts_05.pdf"><i class='fas fa-award'></i> Winners</a></li>-->
                        </ul>
                    </div>
                    
                       </div>


                </div>
             </div>
			 
			 </div>
				
         
                    </div>
                </div>


                </div>
       
</section>


<!-- Footer -->
<app-inner-footer></app-inner-footer>