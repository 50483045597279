<div mat-dialog-content>
    <table class="table" style="margin-bottom: 20px !important;">

        <thead>
            <tr>
                <td colspan="2" allign="left" class="danger" style="text-align:center;color:blue;">
                    <h1> Email Details</h1>
                </td>
            </tr>

            <tr>
                <th scope="col">Subject</th>
                <td scope="col">{{notification.subject}}</td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th>Body</th>
                <td>{{notification.body}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close color="primary" class="btn btn-danger instituteBtnClose">Close</button>
</div>