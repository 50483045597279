<section class="footer">

	<div class="container">

		<div class="row">

			<div class="col-md-3">

				<div class="boxfooter">

					<h3>QUICK LINKS </h3>



			<div class="menu-quick-link-container"><ul id="menu-quick-link" class="menu"><li id="menu-item-236" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-236"><a href="index.php">Home</a></li>
<li id="menu-item-237" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-237"><a href="iic.php">Innovation Council</a></li>
<li id="menu-item-238" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-238"><a href="https://www.sih.gov.in/" target="_blank">Smart India Hackathon</a></li>
<li id="menu-item-238" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-238"><a href="https://ariia.gov.in/" target="_blank">ARIIA</a></li>
<li id="menu-item-238" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-238"><a href="https://yukti.mic.gov.in/" target="_blank">Yukti 2.0</a></li>
<li id="menu-item-238" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-238"><a href="https://innovateindia.mygov.in/ddh2020/" target="_blank">DDH</a></li>
</ul></div>
				</div>

		</div>





					<div class="col-md-3">

				<div class="boxfooter">

					<h3>RELATED LINKS </h3>



					<ul>



						<li><a target="_blank" href="https://www.aicte-india.org/" target="_blank">All India Council for Technical Education</a></li>

						<li><a target="_blank" href="https://mhrd.gov.in/" target="_blank">Ministry of Human Resource Development</a></li>

					</ul>

				</div>

		</div>





					<div class="col-md-3">

				<div class="boxfooter">

					<h3>SOCIAL MEDIA LINKS </h3>



					<ul>

						<li><i class="fa fa-twitter"></i><span><a  target="_blank" href="https://twitter.com/mhrd_innovation">Twitter</a></span></li>           

						<li><i class="fa fa-facebook"></i><span><a  target="_blank" href="https://www.facebook.com/mhrdInnovation/">Facebook</a></span></li>

						<li><i class="fa fa-youtube"></i><span><a  target="_blank" href="https://www.youtube.com/mhrdinnovationcell">Youtube</a> </span></li>

            <li><i class="fa fa-instagram"></i><span><a  target="_blank" href="https://www.instagram.com/mhrd.innovationcell/">Instagram</a> </span></li>

					</ul>

				</div>

		</div>

		<div class="col-md-3">

				<div class="boxfooter">

					<h3>CONTACT </h3>
					



					<ul>

					     <li><i class="fa fa-list-alt"></i> <span><a href="#">MHRD's Innovation Cell</a></span></li>
						<li><i class="fa fa-location-arrow"></i><span><a href="#">All India Council for Technical Education (AICTE), Nelson Mandela Marg, VasantKunj, New Delhi-110070.</a></span></li>
						<!-- <li><i class="fa fa-phone"></i><span><a href="tel:011 29581316">011-29581316</a></span></li> -->
						<li><i class="fa fa-phone"></i><span><a href="tel:011 2958 1227">011 2958 1227</a></span></li>
						<li><i class="fa fa-envelope-o"></i> <span><a href="mailto:mic-mhrd@gov.in">mic-mhrd@gov.in</a></span></li>

					</ul>

				</div>

		</div>



	</div>

</div>

</section>





<!--modal-->

<!-- The Modal -->

  <div class="modal fade" id="myModal">

    <div class="modal-dialog modal-dialog-centered">

      <div class="modal-content">

      

        <!-- Modal Header -->

        <div class="modal-header">

          <h4 class="modal-title">Video Heading</h4>

          <button type="button" class="close" data-dismiss="modal">&times;</button>

        </div>

        

        <!-- Modal body -->

        <div class="modal-body">

        <iframe width="100%" height="300px;" src="https://www.youtube.com/embed/Ycvp2e1qg18" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </div>

        

        <!-- Modal footer -->

        <div class="modal-footer">

          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

        </div>

        

      </div>

    </div>

  </div>

<!--end of modal-->



<script src="/assets/js/jquery-3.3.1.min.js"></script>

<script src="/assets/js/popper.min.js"></script>

<!--<script src="wp_custom/js/bootstrap.min.js"></script>-->
<script type="text/javascript" src="/assets/js/lightbox-plus-jquery.min.js"></script>
<script src="assets/js/slick.js"></script>

<script type="text/javascript" src="/assets/js/jquery.validate.min.js"></script>


<script>


$("#registrationForm").validate({
	rules: {
						
			type:
			{
				required: true
			},
			Participate: 
			{
				required: true,				
			},
			
			bootcamp_institute_name:
			{
				required: true,
			},
			
			orientation_name:
			{
				required: true,
			},
			email:
			{
				required: true,
			},
			Phone_no:
			{
				required: true
			},
			designation:
			{
				required: true,
			},
			comment:
			{
				required: true,
			},
			upload_photo:
			{
				required: true,
			},
			agree:
			{
				required: true
			},
	},
		messages: {
			
			type: {required:'Please select type.'},
			Participate: {required: 'Please select Participate.'},
			bootcamp_institute_name: {required: 'Please select institute name.'},
			orientation_name: {required: 'Please select name.'},
			email: {required: 'Please select email.'},
			Phone_no: {required: 'Please select Phone no.'},
			designation: {required: 'Please enter email'},
			comment: {required: 'Please enter Phone no.'},
			upload_photo: {required: 'Please upload photo'},
			agree: {required: 'Please accept agree'},
			
	},
		
		highlight: function(element) {
			$(element).closest('.form-group').addClass('has-error');
		},
		unhighlight: function(element) {
			$(element).closest('.form-group').removeClass('has-error');
		},
		errorElement: 'span',
		errorClass: 'help-block',
		errorPlacement: function(error, element) 
		{
			if(element.parent('.input-group').length) 
				{
				error.insertAfter(element.parent());
				} 
			else 
				{
				error.insertAfter(element);
				}
		}
});
		//Proceed Button
$("#registrationForm").on('submit',(function(e) {
	event.preventDefault();
		if($("#registrationForm").valid())
{
			$.ajax({
			type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
			url         : 'add_ia_event_new.php', // the url where we want to POST
			data:  new FormData(this),
			contentType: false,
			cache: false,
			processData:false,
			success:function(data) {
				var reply = data.replace(/\s+/, "");
				if(reply==1){
					alert('Created Successfully.');
					location.reload();
				}else if(reply==2){
					alert('This Email already exist.');
				}else if(reply==3){
					alert('This mobile number already exist.');
				}else if(reply==4){
					alert('Expire.');
					location.reload(); 
				}else if(reply==5){
					alert('Please upload passport size photo (2 X 2 inches).JPG and PNG format Only');
					location.reload(); 
				}
				else{
				alert(reply);
				}
				
			}
		});		
		event.preventDefault();
}else{
	alert('Please fill all the fields.');
}
		
	}));
</script>
<script>

$('.multiple-items2').slick({

  

  infinite: true,

	autoplay: true,

  speed: 300,

  slidesToShow: 3,

  slidesToScroll: 1,

  responsive: [

    {

      breakpoint: 1024,

      settings: {

        slidesToShow: 2,

        slidesToScroll: 1,

        infinite: true,

        dots: true

      }

    },

    {

      breakpoint: 600,

      settings: {

        slidesToShow: 2,

        slidesToScroll: 2

      }

    },

    {

      breakpoint: 481,

      settings: {

        slidesToShow: 1,

        slidesToScroll: 1

      }

    }

    // You can unslick at a given breakpoint now by adding:

    // settings: "unslick"

    // instead of a settings object

  ]

});

	</script>


  <script>

$('.multiple-items1').slick({

  

  infinite: true,

  autoplay: true,

  speed: 300,

  slidesToShow: 3,

  slidesToScroll: 1,

  responsive: [

    {

      breakpoint: 1024,

      settings: {

        slidesToShow: 2,

        slidesToScroll: 1,

        infinite: true,

        dots: true

      }

    },

    {

      breakpoint: 600,

      settings: {

        slidesToShow: 2,

        slidesToScroll: 2

      }

    },

    {

      breakpoint: 481,

      settings: {

        slidesToShow: 1,

        slidesToScroll: 1

      }

    }

    // You can unslick at a given breakpoint now by adding:

    // settings: "unslick"

    // instead of a settings object

  ]

});

  </script>



	<script>

		$('.single-item').slick({

			autoPlay: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1

		});

	</script>
<script>

window.onload=function(){
  $('.abtMHRDSlider').slick({
  autoplay:true,
  autoplaySpeed:1500,
  arrows:true,
  prevArrow:'<button type="button" class="slick-prev"></button>',
  nextArrow:'<button type="button" class="slick-next"></button>',
  centerMode:true,
  slidesToShow:1,
  slidesToScroll:1
  });
};
</script>


	<script type="text/javascript">

$('.bannerslider').slick({

  dots: true,

  infinite: true,

  speed: 1500,

  autoplay:true,

  cssEase: 'linear'

});

</script>

<script type="text/javascript">
  $('#show').on('click', function () {
    $('.center').show();
    $(this).hide();
})

$('#close').on('click', function () {
    $('.center').hide();
    $('#show').show();
})
</script>


	

<script type="text/javascript" src="../www.gstatic.com/charts/loader.js"></script>
<script type="text/javascript">
    google.charts.load("current", {packages:["corechart"]});
      google.charts.setOnLoadCallback(drawChart);
      function drawChart() {
        
		var dataTable = new google.visualization.DataTable();
        dataTable.addColumn('string', 'Zone');
        dataTable.addColumn('number', 'Total');
        // A column for custom tooltip content
        dataTable.addColumn({type: 'string', role: 'tooltip'});
        dataTable.addRows([
          ['CRO', 274,'CRO Institutes No. - 274'],
		  ['NRO',  287,'NRO Institutes No. - 287'],
          ['ERO', 269,'ERO Institutes No. - 269'],
          ['NWRO',  451,'NWRO Institutes No. - 451'],
          ['SRO',  732,'SRO Institutes No. - 732'],
          ['SWRO',  382,'SWRO Institutes No. - 382'],
          ['SCRO', 453,'SCRO Institutes No. - 453'],
          ['WRO', 575,'WRO Institutes No. - 575']	

        ]);

      var options = {
        pieSliceText: 'label',
		pieHole: 0.4,
		  colors: ['#ccc','#ccc','#6f2da8','#6f2da8','#6f2da8','#6f2da8','#6f2da8','#ccc'],
		slices: {  1: {offset: 0.1},
                    7: {offset: 0.1},
                    0: {offset: 0.1},
          },
        // title: 'Swiss Language Use (100 degree rotation)',
        pieStartAngle: 100,
		legend: { position: 'none' },
		tooltip: {isHtml: true},
          legend: 'none'
      };

        var chart = new google.visualization.PieChart(document.getElementById('zonalDist'));
        chart.draw(dataTable, options);
      }
</script>   

<script type="text/javascript">
      google.charts.load('current', {'packages':['corechart','bar']});
      google.charts.setOnLoadCallback(drawChart1);

      function drawChart1() {
        var data = google.visualization.arrayToDataTable([
            ['Zone','Institute Count', { role: 'style' }, { role: 'annotation' }],
             
        ]);


        var options = {
            height: 400,
            title: 'Institute Zone Wise',
			bar: {groupWidth: "75%"},
            legend: {position: 'none'},
    
        };

        var chart = new google.visualization.ColumnChart(document.getElementById('instDist'));
        chart.draw(data, options);
        
      }
       </script>
	    <script src="/assets/js/jQuery-plugin-progressbar.js" type="text/javascript"></script>
     <script type="text/javascript">
   
 $(".progress-bar1").loading();
            
       
    
</script>



