<!--title-->
   <title>

    MIC Outcome | MHRDD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>


<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">MIC Outcome</a>
      </li>
    </ul>
  </div>

<section class="micoutcome">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
				<div class="woldMiddleBg">
					<img src="assets/images/micoutcome/award-image.png" class="woldMiddleBg">
					<h3>Total Ideas <br/> {{totalIdeas}}</h3>
					<div class="awardBox">
						<img src="assets/images/micoutcome/software.png" class="img-responsive">
					</div>
					<div class="awardBox1">
						<img src="assets/images/micoutcome/hardware.png" class="img-responsive">
					</div>
					<div class="awardBox2">
						<img src="assets/images/micoutcome/mic-blue.png" class="img-responsive" />
						<h3>MIC Outcome</h3>
					</div>
					<div class="awardBox3">
						<img src="assets/images/micoutcome/international.png" class="img-responsive">
					</div>
					<div class="awardBox4">
						<img src="assets/images/micoutcome/iic.png" class="img-responsive">
					</div>
				</div>
            </div>
        </div>
    </div>
	<div class="container px-5 py-5"> 
        <div class="row"> 
            <div class="col mx-1">
				<div class="mainBoxMic">
					<h3>{{themeCount}}</h3>
					<p>Themes</p>
					<img src="assets/images/theme-icon.png" class="img-responsive">
				</div> 
				<div class="mainBoxMicImg">
					<img src="assets/images/ribbonMic1.png" class="ribbonMic">
				</div> 
			</div> 
            <div class="col mx-1">
				<div class="mainBoxMic1">
					<h3>{{softwareCount}}</h3>
					<p>Software</p>
					<img src="assets/images/software-icon.png" class="img-responsive">
				</div> 
				<div class="mainBoxMicImg1">
					<img src="assets/images/ribbonMic2.png" class="ribbonMic">
				</div> 
			</div>
            <div class="col mx-1">
				<div class="mainBoxMic2">
					<h3>{{hardwareCount}}</h3>
					<p>Hardware</p>
					<img src="assets/images/hardware-icon.png" class="img-responsive">
				</div> 
				<div class="mainBoxMicImg2">
					<img src="assets/images/ribbonMic3.png" class="ribbonMic">
				</div> 
			</div> 
            <div class="col mx-1">
				<div class="mainBoxMic3">
					<h3>{{this.ministryCount}}</h3>
					<p>Ministry</p>
					<img src="assets/images/ministry-icon.png" class="img-responsive">
				</div> 
				<div class="mainBoxMicImg3">
					<img src="assets/images/ribbonMic4.png" class="ribbonMic">
				</div> 
			</div> 
            <div class="col mx-1">
				<div class="mainBoxMic4">
					<h3>{{this.industryCount}}</h3>
					<p>Industry</p>
					<img src="assets/images/insustry-icon.png" class="img-responsive">
				</div> 
				<div class="mainBoxMicImg4">
					<img src="assets/images/ribbonMic5.png" class="ribbonMic">
				</div> 
			</div> 
        </div> 
    </div> 
</section>
<br>
<br>
<div class="limiter">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="titleheadertop">

				  <h1 class="inner-theme-heading">Award Winning Ideas/Solutions</h1>

				</div>
			</div>
		</div>
	<div class="row drop-sub-box">
		<form [formGroup]="outcomeForm" (ngSubmit)="onSubmit(outcomeForm.value)">
		<div class="col-md-3">
			<div class="form-group">
				<!-- <label for="theme">Theme:</label> -->
		<mat-form-field style="margin-right: 505px;	">
			<mat-label>Theme:</mat-label>
			<mat-select formControlName="themes">
			  <mat-option *ngFor="let role of theme" [value]="role">
				{{role}}
			  </mat-option>
			</mat-select>
		  </mat-form-field>
			</div>
		</div>
		<div class="col-md-3">
			<div class="form-group">		
				  <mat-form-field style=" margin-right: 505px; ">
					<mat-label>Type</mat-label>
					<mat-select formControlName="type" >
					  <mat-option value="">Both  </mat-option>
					  <mat-option value="Software">Software </mat-option>
					  <mat-option value="Hardware">Hardware</mat-option>
					</mat-select>
				  </mat-form-field>
			</div>
		</div>
		<div class="col-md-3">
			<div class="form-group">
				  <mat-form-field style="margin-right: 505px; ">
					<mat-label>Year</mat-label>
					<mat-select formControlName="year">
					  <mat-option value="">All</mat-option>
					  <mat-option value="2017">2017 </mat-option>
					  <mat-option value="2018">2018</mat-option>
					  <mat-option value="2019">2019</mat-option>
					</mat-select>
				  </mat-form-field>
			</div>
		</div>
		<div class="col-md-3">
			<div class="form-group">
				<button type="submit" name="Submit" id="submit"class="btn btn-info">Search</button>
			</div>
		</div>
	</form>
	<!-- <div id="new-search-area" class="col-md-10"></div> -->
	</div>

<!-- <div>
	<label for="search" class="col-sm-2 col-form-label" style="text-align: right;">Search</label>
	<div class="col-sm-6">
		<input type="text" [(ngModel)]="searchValue" class="form-control" id="search"/>
	</div>
</div> -->
<!-- <mat-form-field appearance="outline">
	<mat-label>Search</mat-label>
	<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search...">
	<mat-icon matSuffix>search</mat-icon>
</mat-form-field> -->

	

	<br>
	<div class="row addTeachingTopRow">
        <div class="col-4">
          <div class="row">
            <div class="col-2" *ngIf="!firstPage">
              <button type="button" (click)='previousPage()' class="btn btn-success">Previous</button>
            </div>
            <div class="col-2" *ngIf="!lastPage" style="margin-left: 150px;">
              <button type="button" (click)='nextPage()' class="btn btn-success">Next</button>
            </div>
          </div>
        </div>
	  </div>
	  <br>
	<div class="row">
		<div class="container-login100">
			<div class="tab-box" id="newContentIncubation">
			
				<!-- Incubation Data -->
				<div id="incubators">
					<div id="NewContentIdea">
						
			<table class="table table-bordered " id="myTable">
				<thead>
				  <tr>
					<th width='2%'>S.No.</th>
					<th width='25%'>Theme Details</th>
					<th width='15%'>Ministry Industry Name</th>
					<th width='20%'>Idea Title</th>
					<th width='28%'>Desription</th>
					<th width='10%'>Team Details</th>
				  </tr>
				</thead>
				<tbody>
					<tr  *ngFor="let details of Tabledata; let i = index;">	
						<td>{{i+1}}</td>
						<td>
							<p><b>Theme Name: </b>  {{details?.theme_name}}</p>
							<p><b>Type: </b> {{details?.type}}</p>
							<p><b>Year: </b> {{details?.year}}</p>
						</td>
						<td>{{details?.ministry_industry_name}}</td>
						<td>{{details?.name_of_idea_title}}</td>
						<td>
						<button (click)="openDialog(details)" class="btn btn-info"  >View Description</button>						</td>
						<td>
							<p><b>Team Name: </b>{{details?.team_name}}</p>
							<p><b>Team Lead Name: </b>{{details?.tl_name}}</p>
							<p><b>Team Lead Email: </b>{{details?.tl_email}}</p>
						</td>
					</tr>								
				</tbody>
			  </table>
					</div>
				</div>			
			</div>
			</div>
			</div>
		</div>
	</div>


<!-- Footer -->
<app-inner-footer></app-inner-footer>