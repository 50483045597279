import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root',
})

export class InstituteActivityService {

  deleteSelfDrivenActivity() {
    throw new Error('Method not implemented.');
  }
  private baseUrl = myGlobals.baseUrl;
  private manthanUrl = myGlobals.manthanUrl;
  private manthanHost = myGlobals.manthanHost;
  public eventId;

  constructor(private http: HttpClient) { }

  getActivityCount() {
    return this.http.get(`${this.baseUrl}/get-activity-count`);
  }
  // getSuggestion(pageNo,per_page=10) {
  //   return this.http.get(
  //     `${this.baseUrl}/list-suggestion/details?per_page=`+per_page+`&page=` + pageNo
  //   );
  // }
  getIICDetails(per_page = 12, pageNo, quarter, title) {
    return this.http.get(
      `${this.baseUrl}/activity/iicdetails?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title);
  }



  getMICDetails(per_page = 10, pageNo, quarter, title) {
    return this.http.get(
      `${this.baseUrl}/activity/micdetails?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title);
  }
  

  getRatingDetails() {
    return this.http.get(
      `${this.baseUrl}/get-rating`);
  }

  getStateDetails() {
    return this.http.get(
      `${this.baseUrl}/tl/getStates`);
  }

  getDetailByIic(data) {
    return this.http.get(
      `${this.baseUrl}/get-iic-detail?iic_code=`+data);
  }

  getInstituteDetails(stateId) {

    return this.http.post(
      `${this.baseUrl}/tl/getStatesWiseInstitute` ,stateId);
  }

  getInviteSubIic(data) {

    return this.http.post(
      `${this.baseUrl}/invite-sub-iic` ,data);
  }

  getCelebrationDetails(per_page = 10, pageNo, quarter, title) {
    return this.http.get(
      `${this.baseUrl}/activity/celebrationdetails?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title);
  }

  removeMicCalendar(data) {
    return this.http.post(
      `${this.baseUrl}/activity/removeCalendar/` + data, data);
  }
  addMicCalendar(data) {
    return this.http.post(
      `${this.baseUrl}/activity/addCalendar/` + data, data);
  }

  getSelfDrivenDetails(per_page = 10, pageNo, quarter, title) {
    return this.http.get(
      `${this.baseUrl}/activity/selfdetails?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title);
  }

  getMyActivityDetails(per_page = 10, pageNo, quarter, title, activity, academic_year) {
    return this.http.get(
      `${this.baseUrl}/activity/myactivitydetails?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title + `&activityType=` + activity+ `&academic_year=` + academic_year);
  }


  getMonthlyReportOfCouncil(eventId) {
    return this.http.get(
      `${this.baseUrl}/activity/monthlyReportCouncil/` + eventId);
  }

  getReport(eventId) {
    return this.http.get(
      `${this.baseUrl}/getReport/` + eventId);
  }


  submitMyMonthlyReportCouncil(data) {
    return this.http.post(`${this.baseUrl}/activity/monthlyReportCouncil`, data);
  }

  getReportForDownload(endPoint, event_id) {
    return this.http.get(`${this.baseUrl}/activity/${endPoint}/` + event_id, { responseType: 'arraybuffer' });
  }

  getAKAMReportForDownload(event_id) {
    return this.http.get(`${this.baseUrl}/activity/monthlyReportPdfAKAM/` + event_id, { responseType: 'arraybuffer' });
  }

  checkActivityButton(institute_id) {
    return this.http.get(`${this.baseUrl}/activity/check-self-driven-active?institute_id=` + institute_id);
  }

  getHOI() {
    return this.http.get(`${this.baseUrl}/get-hoi-details`);
  }

  CheckMandateStatus() {
    return this.http.get(`${this.baseUrl}/get-mandate-status`);
  }

  deleteSelfDrivenActivity1(event, id) {
    const data = {
      eventId: event,
      id: id

    }
    return this.http.post(`${this.baseUrl}/selfDrivenDelete`, data);

  }


  deleteMyActivity(data){
    const data1={
      eventId:data
    }
    return this.http.post(`${this.baseUrl}/activity/deleteActivity`, data1);

  }

  submitReviewRequest(data){
    const data1={
      eventId:data
    }
    return this.http.post(`${this.baseUrl}/activity/submitReviewRequest`, data1);

  }

  submitInternCRequest(data){
    const data1={
      eventId:data
    }
    return this.http.post(`${this.baseUrl}/activity/submitInternCRequest`, data1);
  }


  // Mentor-Mentee

  getMentorWillingness() {
    return this.http.get(`${this.baseUrl}/getMentorWillingness`);
  }

  checkMemtorEligible(){
    return this.http.get(`${this.baseUrl}/checkMemtorEligible`);

  }

  getSelectedMantors(){
    return this.http.get(`${this.baseUrl}/getSelectedMantors`);
  }

  submitMentorWillingness(data){
    return this.http.post(`${this.baseUrl}/submitMentorWillingness`, data);
  }

  getMentorDetails(){
    return this.http.get(`${this.baseUrl}/getMentorDetails`);
  }

  getMentorStatus(){
    return this.http.get(`${this.baseUrl}/getMentorStatus`);
  }
  getcheckmandate(){
    return this.http.get(`${this.baseUrl}/checkmandate`);
  }

  activities(){
    return this.http.get(`${this.baseUrl}/activities`);
  }
  deleteMentorMentee(data){
    const data1={
      id:data
    }
    return this.http.post(`${this.baseUrl}/deleteMentorMentee`, data1);

  }

  submitMentorMenteeConsent(data){
    return this.http.post(`${this.baseUrl}/mentorMenteeConsent`, data);

  }

  getmentorMenteeConsentStatus(){
    return this.http.get(`${this.baseUrl}/mentorMenteeConsentStatus`);
  }

  submitMenteePrefrence(data){
    return this.http.post(`${this.baseUrl}/submitMenteePrefrence`, data);

  }

  submitMenteeGuidance(data){
    return this.http.post(`${this.baseUrl}/submitMenteeGuidance`, data);

  }

  submitMenteeHandholdingStatus(data){
    return this.http.post(`${this.baseUrl}/submitMenteeHandholdingStatus`, data);

  }

  mentorSelection(data){
    return this.http.post(`${this.baseUrl}/mentorSelection`, data);
  }

  mentorAtalSelection(data, type){
    const data1={
      id:data,
      type:type
    }
    return this.http.post(`${this.baseUrl}/mentorAtalSelection`, data1);
  }

  mentorInstituteAtlSelection(data, type){
    const data1={
      id:data,
      type:type
    }
    return this.http.post(`${this.baseUrl}/mentorInstituteAtlSelection`, data1);
  }
  mentorInstituteAtlSelectionFilter(institute_id,institute_name,city){
    return this.http.get(`${this.baseUrl}/atlInstituteDetails?institute_id=`+institute_id+'&institute_name='+institute_name+'&city='+city);
  }
  menteeAtlInstitution(){
    return this.http.get(`${this.baseUrl}/checkAtlInstitution`);
  }

  mentorAtalIICSelection(data, type){
    const data1={
      id:data,
      type:type
    }
    return this.http.post(`${this.baseUrl}/mentorAtalIICSelection`, data1);
  }

  menteePrefrenceStatus(){
    return this.http.get(`${this.baseUrl}/menteePrefrenceStatus`);
  }
  getMenteeTable(){
    return this.http.get(`${this.baseUrl}/menteeDisplay`);
  }
  acceptMantee(data,type){
    const data1={
      mentee_userId:data,
      Action:type
    }
    return this.http.post(`${this.baseUrl}/acceptMantee`, data1);

  }


  schedule(data){
    return this.http.post(`${this.baseUrl}/schedule`, data);
  }
  schedules() {
    return this.http.get(`${this.baseUrl}/getschedule`);
  }
  activityStatus(){
    return this.http.get(`${this.baseUrl}/get-activities-status`);

  }

  atlSchedules() {
    return this.http.get(`${this.baseUrl}/get-atl-activity-schedule-list`);
  }

  AtlSchedule(data){
    return this.http.post(`${this.baseUrl}/atl-activity-schedule`, data);
  }

  AtlScheduleData(data) {
    return this.http.post(`${this.baseUrl}/atl-activity-schedule-data`, data);
  }


  getSchedule(data){
    return this.http.get(`${this.baseUrl}/get-activities-details?activity_id=`+data);
  }

  getMembersDataValue(data){
    return this.http.get(`${this.baseUrl}/get-members-data?type=`+data);
  }

  editSchedule(data){
    return this.http.post(`${this.baseUrl}/editschedule`, data);
  }

  scheduleStatus(data){
    return this.http.post(`${this.baseUrl}/scheduleStatus`, data);
  }

  submitSchedule25(data){
    return this.http.post(`${this.baseUrl}/submitSchedule25`, data);
  }

  deleteSchedule(data){
    return this.http.post(`${this.baseUrl}/deleteschedule`, data);
  }
  getActivity(){
    return this.http.get(`${this.baseUrl}/get-iic-activities`);
  }
  getActivityStatus(){
    return this.http.get(`${this.baseUrl}/get-activities-status`);
  }
  getCheckMentee(){
    return this.http.get(`${this.baseUrl}/ismentee`);
  }

  getMentorMandateStatus(){
    return this.http.get(`${this.baseUrl}/mentor-mandate-status`);
  }

  getMenteeActivity(){
    return this.http.get(`${this.baseUrl}/getMenteeActivities`);

  }

  removeSelectedMentors(data){
    const data1={
      id:data
    }
    return this.http.post(`${this.baseUrl}/removeSelectedMentors`, data1);
  }

  removeSelectedMentee(data){
    const data1={
      id:data
    }
    return this.http.post(`${this.baseUrl}/removeSelectedMentee`, data1);
  }

  submitIicImpact(data,token) {
    return this.http.post(`${this.baseUrl}/submit-iic-impact`, {data:data, token:token});
  }

  getIicImpactDetails(){
    return this.http.get(`${this.baseUrl}/get-iic-impact-details`);
  }

  submitIicSubImpact(data) {
    return this.http.post(`${this.baseUrl}/submit-iic-impact-submission`, data);
  }

  getIicImpactSubDetails(){
    return this.http.get(`${this.baseUrl}/get-iic-impact-submission-details`);
  }

  submitTestimonialDetails(data) {
    return this.http.post(`${this.baseUrl}/submit-iic-testimonial-details`, data);
  }

  getIicTestimonialDetails(rid){
    return this.http.get(`${this.baseUrl}/get-iic-testimonial-details?rid=`+rid);
  }

  deleteIicTestimonialDetails(rid){
    return this.http.get(`${this.baseUrl}/delete-iic-testimonial?rid=`+rid);
  }

  submitIicRepository(data) {
    return this.http.post(`${this.baseUrl}/submit-iic-repository`, data);
  }

  submitTeacherlearningRepository(data) {
    return this.http.post(`${this.baseUrl}/teaching-learning`, data);
  }
  submitnonacademicaddRepository(data) {
    return this.http.post(`${this.baseUrl}/nonacademicadd`, data);
  }

  getIicRepositoryDetails(rid){
    return this.http.get(`${this.baseUrl}/get-iic-repository-details?rid=`+rid);
  }

  getIicEvaluatorDetails(rid){
    return this.http.get(`${this.baseUrl}/get-iic-evaluator-details?rid=`+rid);
  }

  getInstitute(data){
    const iicId = {
      iicId: data,
    };
    return this.http.get(`${this.baseUrl}/getInstitute/`+data);
  }

  getInstituteName(data){
    const iicId = {
      iicId: data,
    };
    return this.http.get(`${this.baseUrl}/getInstituteName/`+data);
  }

  getInstituteData(type,id,table){
    const iicId = {
      iicId: data,
    };
    return this.http.get(`${this.baseUrl}/getInstituteData?type=`+type+`&id=`+id+`&table=`+table);
  }

  getIicCoordinatorDetails(rid){
    return this.http.get(`${this.baseUrl}/get-iic-coordinator-details?rid=`+rid);
  }

  getIicCoordinatorCityDetails(){
    return this.http.get(`${this.baseUrl}/get-iic-coordinator-city-details`);
  }

  getIicEvaluatorList(type,id,table){
    return this.http.get(`${this.baseUrl}/get-evaluator-type-details?type=`+type+`&id=`+id+`&table=`+table);
  }

  getIicCoordinatorCityList(id){
    return this.http.get(`${this.baseUrl}/get-coordinator-type-details?&id=`+id);
  }

  getIicCoordinatorList(type,id,table){
    return this.http.get(`${this.baseUrl}/get-coordinator-type-details?type=`+type+`&id=`+id+`&table=`+table);
  }

  editCoordinator(data) {
    return this.http.post(`${this.baseUrl}/editCoordinator/` + data, data);
  }

  getNominatedCoordinator(){
    return this.http.get(`${this.baseUrl}/get-nominated-coordinator`);
  }
  submitNominatedCoordinator(data){
    return this.http.post(`${this.baseUrl}/submit-nominated-coordinator`, data);
  }

  deleteIicRepositoryDetails(rid){
    return this.http.get(`${this.baseUrl}/delete-iic-repository?rid=`+rid);
  }

  deleteEvaluatorDetails(rid){
    return this.http.get(`${this.baseUrl}/delete-iic-ins-evaluator?rid=`+rid);
  }

  sendEvaluatorDetails(rid){
    return this.http.get(`${this.baseUrl}/send-iic-ins-evaluator?rid=`+rid);
  }

  deleteIicCoordinatorDetails(rid){
    return this.http.get(`${this.baseUrl}/delete-iic-coordinator?rid=`+rid);
  }

  getIicActivityList(type){
    return this.http.get(`${this.baseUrl}/get-iic-activity-details?type=`+type);
  }

  getAtlCityDistrictList(district){
    return this.http.get(`${this.baseUrl}/get-atl-cities-list?district=`+district);
  }

  getIicAtalSchoolList(per_page = 10, pageNo, quarter, title, activity, academic_year) {
    return this.http.get(
      `${this.baseUrl}/atal-school-listing?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title + `&activityType=` + activity+ `&academic_year=` + academic_year);
  }

  getIicAtalSchoolList2(per_page = 10, pageNo, quarter, title, activity, academic_year,utype) {
    return this.http.get(
      `${this.baseUrl}/atal-school-listing-selected?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title + `&activityType=` + activity+ `&academic_year=` + academic_year+ `&utype=` + utype);
  }

  getATALSchoolStatus() {
    return this.http.get(`${this.baseUrl}/atal-school-selected-status`);
  }

  getAtalActivityStatus(){
    return this.http.get(`${this.baseUrl}/get-atal-activities-status`);
  }

  deleteAtalSchedule(data){
    return this.http.post(`${this.baseUrl}/deleteatalschedule`, data);
  }
  deleteAtlActivitySchedule(data){
    return this.http.post(`${this.baseUrl}/deleteAtlActivitySchedule`, data);
  }

  AtalSchedules() {
    return this.http.get(`${this.baseUrl}/getatalschedule`);
  }

  atalActivities(){
    return this.http.get(`${this.baseUrl}/atal-activities`);
  }

  AffiliateInsSubmit(data){
    return this.http.post(`${this.baseUrl}/submit-iic-affiliate`, data);
  }


  getIICCityDistrictList(district){
    return this.http.get(`${this.baseUrl}/get-atl-cities-list?district=`+district);
  }

  getIICinstitutesList(per_page = 10, pageNo, quarter, title, activity, academic_year) {
    return this.http.get(
      `${this.baseUrl}/atal-iic-listing?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title + `&activityType=` + activity+ `&academic_year=` + academic_year);
  }


  getATLYuktiList(per_page = 10, pageNo, quarter, title, activity, academic_year) {
    return this.http.get(
      `${this.baseUrl}/atal-yukti-listing?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title + `&activityType=` + activity+ `&academic_year=` + academic_year);
  }

  ReferralInsSubmit(data){
    return this.http.post(`${this.baseUrl}/submit-iic-referral`, data);
  }

  getIicInsMMDetails(data){
    return this.http.get(`${this.baseUrl}/get-iic-mm-dashboard?rid=`+data);
  }

  getIicReferralDetails(data){
    return this.http.get(`${this.baseUrl}/get-iic-referral-institutes?rid=`+data);
  }

  deleteIicReferralDetails(rid){
    return this.http.get(`${this.baseUrl}/delete-iic-referral?rid=`+rid);
  }

  sendIicReferralDetails(rid){
    return this.http.get(`${this.baseUrl}/sendemail-iic-referral?rid=`+rid);
  }

  getIicAkamSubDetails(data){
    return this.http.get(`${this.baseUrl}/get-iic-akam-details?rid=`+data);
  }

  postIicAkamSubDetails(data){
    return this.http.post(`${this.baseUrl}/submit-iic-akam-details`, data);
  }

  postIicAkamReportDetails(data){
    return this.http.post(`${this.baseUrl}/submit-iic-akam-report-details`, data);
  }

  getIicAkamReportDetails(data){
    return this.http.get(`${this.baseUrl}/get-iic-akam-report-details?rid=`+data);
  }

  postScheduleReport(data){
    return this.http.post(`${this.baseUrl}/post-schedule-report`, data);
  }

  postScheduledReport(data){
    return this.http.post(`${this.baseUrl}/post-scheduled-report`, data);
  }

  getScheduleReport(data) {
    return this.http.get(`${this.baseUrl}/get-schedule-report?schedule_id=`+data);
  }

  getScheduledReport(data) {
    return this.http.get(`${this.baseUrl}/get-scheduled-report?mentee_id=`+data);
  }

  AffiliateInsNewSubmit(data){
    return this.http.post(`${this.baseUrl}/submit-iic-new-affiliate`, data);
  }

  getAffiliateInsNewList(data){
    return this.http.get(`${this.baseUrl}/get-iic-affiliate-institutes?rid=`+data);
  }

  getIicInstitutesReferal(data){
    return this.http.get(`${this.baseUrl}/get-iic-institutes-referal?rid=`+data);
  }

  submitIicNicRepository(data) {
    return this.http.post(`${this.baseUrl}/submit-iic-nic-repository`, data);
  }

  getIicInnovTeamDetails(rid){
    return this.http.get(`${this.baseUrl}/get-iic-innov-team-details?rid=`+rid);
  }

  getIicInnovationRepositoryDetails(rid){
    return this.http.get(`${this.baseUrl}/get-iic-innovation-details?rid=`+rid);
  }

  getReferralDetails(){
    return this.http.get(`${this.baseUrl}/get-referral-details`);
  }

  getRepositoryDetails(){
    return this.http.get(`${this.baseUrl}/get-repository-details`);
  }

  submitAtlYuktiRepository(data) {
    return this.http.post(`${this.baseUrl}/submit-atl-yukti-repository`, data);
  }

  getAtlYuktiRepositoryDetails(rid){
    return this.http.get(`${this.baseUrl}/get-atl-yukti-repository-details?rid=`+rid);
  }

  deleteAtlYuktiRepositoryDetails(rid){
    return this.http.get(`${this.baseUrl}/delete-atl-yukti-repository?rid=`+rid);
  }

  getMentorofChange(){
    return this.http.get(`${this.baseUrl}/getMentorofChange`);
  }

  getStateIICinstitutesList(per_page = 10, pageNo, quarter, title, activity, academic_year) {
    return this.http.get(
      `${this.baseUrl}/get-state-iic-listing?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title + `&activityType=` + activity+ `&academic_year=` + academic_year);
  }
  getStateIICList(per_page = 10, pageNo, quarter, title, activity, academic_year) {
    return this.http.get(
      `${this.baseUrl}/get-state-iic-executive?per_page=` + per_page + `&page=` + pageNo + `&quarter=` + quarter + `&title=` + title + `&activityType=` + activity+ `&academic_year=` + academic_year);
  }
  getConsortiumIICList() {
    return this.http.get(
      `${this.baseUrl}/get-consortium-executive`);
  }

  deleteSubIICDetails(rid){
    return this.http.get(`${this.baseUrl}/delete-subiic?rid=`+rid);
  }

  submitSubIicRepository(data) {
    return this.http.post(`${this.baseUrl}/submit-subiic`, data);
  }

  getSubIicRepositoryDetails(rid){
    return this.http.get(`${this.baseUrl}/get-iic-subiic-details?rid=`+rid);
  }

  submitYuktiChallenges(data) {
    return this.http.post(`${this.baseUrl}/submit-yukti-challenges`, data);
  }

  submityuktiInnovatorRegistration(data) {
    return this.http.post(`${this.baseUrl}/yukti-innovator-registration`, data);
  }
  submitTransferInnovatorForm(data) {
    return this.http.post(`${this.baseUrl}/transfer-for-innovation-form`, data);
  }

  submityuktiIprRegistration(data) {
    return this.http.post(`${this.baseUrl}/ipr-innovator-registration`, data);
  }

  getYuktiChallenges(rid){
    return this.http.get(`${this.baseUrl}/get-yukti-challenges-details?rid=`+rid);
  }

  DeleteYuktiChallenges(rid){
    return this.http.get(`${this.baseUrl}/delete-yukti-challenges?rid=`+rid);
  }

  getAllStates(){
    return this.http.get(`${this.baseUrl}/iicstateList`);
  }

  
  getNominateChallenges(id){
    return this.http.get(`${this.baseUrl}/get-nominated-challenge?id=`+id);
  }

  submitAtlActivityRepository(data) {
    return this.http.post(`${this.baseUrl}/update-report-acitivity`, data);
  }
  getMentorList() {
    return this.http.get(`${this.baseUrl}/mentor-detail`);
  }
  getAgencyProfile() {
    return this.http.get(`${this.baseUrl}/atl-profile-detail`);
  }
  getAnnouncementDetail() {
    return this.http.get(`${this.baseUrl}/atl-announcement-detail`);
  }
  submitAtlProfileDetail(data) {
    return this.http.post(`${this.baseUrl}/atl-profile-update`,data);
  }
  getInstituteValue(activity="",quarter="",evnt_id="",title="") {
    return this.http.get(`${this.baseUrl}/get-institute-event?activity=`+activity+`&quarter=`+quarter+`&id=`+evnt_id+'&title='+title);
  }

  submitNominateMember(data) {
    return this.http.post(`${this.baseUrl}/submitNominateMember`,data);
  }

  getNominateMember() {
    return this.http.get(`${this.baseUrl}/getNominateMember`);
  }

  atlActivityDetail(data) {
    return this.http.get(`${this.baseUrl}/atlActivityDetail?activity_id=`+data);
  }

  atlNominatedCoordinateDetail(id) {
    return this.http.get(`${this.baseUrl}/atl-nominate-coordinator-detail?id=`+id);
  }
  atlNominatedCoordinateDetails() {
    return this.http.get(`${this.baseUrl}/atl-nominate-coordinator-details`);
  }
  atlInstituteDetails() {
    return this.http.get(`${this.baseUrl}/atlInstituteDetails`);
  }
  atlNominatedCoordinateDetailSubmit(data) {
    return this.http.post(`${this.baseUrl}/atl-nominate-coordinator`,data);
  }
  atlIicNominatedCoordinateDetails() {
    return this.http.get(`${this.baseUrl}/atl-iic-nominate-coordinator`);
  }
  iicNominatedDetails() {
    return this.http.get(`${this.baseUrl}/iic-nominate-detail`);
  }

  iicReportDetails() {
    return this.http.get(`${this.baseUrl}/iic-report-detail`);
  }

  getAtlSubmittedReport(activity_id) {
    return this.http.get(`${this.baseUrl}/atl-activity-submitted?activity_id=`+activity_id);
  }

  getAtlIACoordinator(per_page = 10, pageNo) {
    return this.http.get(`${this.baseUrl}/atl-ia-coordinate?per_page=` + per_page + `&page=` + pageNo );
  }

  iaMentorCoordinator(per_page = 10, pageNo) {
    return this.http.get(`${this.baseUrl}/atl-ia-coordinate-nominated?per_page=` + per_page + `&page=` + pageNo );
  }

  checkUDISENumber(){
    return this.http.get(`${this.baseUrl}/check-udise-number`);
  }
  addUDISENumber(data){
    return this.http.post(`${this.baseUrl}/add-udise-number`, data);
  }
  getAtlInviteIIC(){
    return this.http.get(`${this.baseUrl}/get-atl-invite-iic`);
  }
  getIICInviteAtl(){
    return this.http.get(`${this.baseUrl}/get-iic-invite-atl`);
  }

  getYuktiInstituteChallenges(){
    return this.http.get(`${this.baseUrl}/get-yukti-institute-challenges`);
  }

  getProtoType(id=""){
    if(id){
      return this.http.get(`${this.baseUrl}/getProtoType?id=`+id);
    }else{
      return this.http.get(`${this.baseUrl}/getProtoType`);
    }
  }

  getATLActivityTitle(activity,quarter){
    return this.http.get(`${this.baseUrl}/getATLActivityTitle?activity=`+activity+"&quarter="+quarter);
  }

  getTeamLeadData(){
    return this.http.get(`${this.baseUrl}/getTeamLeadData`);
  }
  submitYuktiInnovatorInterchange(data){
    return this.http.post(`${this.baseUrl}/yukti-innovator-interchange`,data);
  }
  
  getATLSchoolRequest(){
    return this.http.get(`${this.baseUrl}/get-ATL-school-request`);
  }
  updateATLRequestStatus(data){
    return this.http.post(`${this.baseUrl}/update-ATL-school-request`,data);
  }
  getInnovationData(challenge_id){
    return this.http.get(`${this.baseUrl}/get-innovation-data?challenge_id=`+challenge_id);
  }
  getNominatedInnovationData(challenge_id){
    return this.http.get(`${this.baseUrl}/get-nominated-innovation-data?challenge_id=`+challenge_id);
  }
  submitNominatedInnovationData(data){
    return this.http.post(`${this.baseUrl}/nominated-innovation-data`,data);
  }
  deleteNominatedChallenges(id){
    const data = {
      id:id
    }
    return this.http.post(`${this.baseUrl}/remove-nominated-innovation-data`,data);
  }
  getProtoTypeTransfer(id=""){
    return this.http.get(`${this.baseUrl}/getProtoType?innovation_id=`+id);
    
  }
  getInnovationUserData(data=""){
    if(data){
      return this.http.get(`${this.baseUrl}/getInnovationUserData?id=`+data);
    }else{
      return this.http.get(`${this.baseUrl}/getInnovationUserData`);
    }
  
  }
  getHandHoldingData(data){
    return this.http.post(`${this.baseUrl}/getHandholdingData`,data);
  }

  sendInviitationToInActiveIIC(data){
    return this.http.post(`${this.baseUrl}/sendInviitationToInActiveIIC`,data);
  }
  
  downloadLetter(type){
    return this.http.get(`${this.baseUrl}/downloadmandateLetter?type=`+type, { responseType: 'arraybuffer' });  
  }
  
  getActionByMentee(type,activity_id,session){
    if(session == 1){
      return this.http.get(`${this.baseUrl}/getActionByMentee?activity_type=`+type+`&activity_id=`+activity_id);
      }else{
        return this.http.get(`${this.baseUrl}/getActionByMentee?activity_type=`+type+`&activity_id=`+activity_id+`&session=`+session);
        }    
  }

  getUserData(){
    return this.http.get(`${this.baseUrl}/getManthanUserData`);  
  }

  updateReport(data, eventId) {
    return this.http.post(`${this.baseUrl}/updateReport/` + eventId, data);
  }

  submitActivity(data){
    return this.http.post(`${this.baseUrl}/submit-activity` , data);  
  }

  getActivities(){
    return this.http.get(`${this.baseUrl}/getScheduleActivity`);  
  }

  updateManthanStatus(manthan_institute = ''){
    if(manthan_institute){
      var data = {
        'manthan_institute_id': manthan_institute,
        'is_update': 0,
        'type': 1,
      };
    }else{
      var data = {
        'manthan_institute_id': '',
        'is_update': 1,
        'type': 2,
      };
    }

    return this.http.post(`${this.baseUrl}/updateManthanStatus`,data);
  }
  
  getAllSICMapped(){
    return this.http.get(`${this.baseUrl}/get-all-SIC-mapped`);  
  }
  
  IICSICMapped(data){
    console.log(data);
    return this.http.post(`${this.baseUrl}/submit-IIC-SIC-mapped` , data);  
  }
  
  getAllIICUserByFilter(data){
    return this.http.get(`${this.baseUrl}/get-all-IIC-user?prefrence=`+data);  
  }
  
  nominateInActiveUser(data){
    return this.http.post(`${this.baseUrl}/nominate-in-active-user`, data);  
  }
  
  nominateInActiveUserRemove(data){
    return this.http.post(`${this.baseUrl}/nominate-in-active-user-remove`, data);  
  }
}
