import { InstituteMyCouncilService } from './institute-my-council.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { JarwisService } from './jarwis.service';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class InstituteDetailsSubmittedService implements CanActivate {
  instituteDetailsFilled: boolean;
  checkStatus: any;

  constructor(private myCouncilService: InstituteMyCouncilService,
    private token: TokenService) {

    const payload = localStorage.getItem('token').split('.')[1];
    this.checkStatus = this.token.decode(payload);

    if (this.checkStatus['applicationStatus'] == '1') {
      this.instituteDetailsFilled = true;
    }

    if (this.checkStatus['applicationStatus'] == '0') {
      this.instituteDetailsFilled = false;
    }

  }

  canActivate(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ):
    | boolean
    | import('@angular/router').UrlTree
    | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    return this.instituteDetailsFilled;
  }
}
