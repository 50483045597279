<h3 class="vhead-title">{{data?.idea_title}} Verification Details</h3>
<table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Score</th>
      <th scope="col">Status</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let eval of evalData; let j = index">
      <th scope="row">{{j+1}}</th>
      <td>{{eval.first_name}} {{eval.first_lastname}}</td>
      <td>{{eval.totalScore}}</td>
      <td>{{eval.recommend_status}}</td>
      <td>
        <span *ngIf="eval.recommend_status=='Pending'">
          
          <button type="button" (click)="removeEvaluator(dataShow?.id, eval.first_name, eval.id)" class="btn btn-success verify-btn">
            Remove
          </button>

        </span>      
      </td>
    </tr>
  </tbody>
</table>

<div mat-dialog-actions align="end" class="mt-3 irEvaluaFor">
  <button type="button" mat-raised-button color="warn" (click)="closeDialog()"
      class="btn btn-danger instituteBtnClose">
      Close
  </button>
</div>
