import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../../app/app.component';
@Component({
  selector: 'app-innovation-ambassador',
  templateUrl: './innovation-ambassador.component.html',
  styleUrls: ['./innovation-ambassador.component.css']
})
export class InnovationAmbassadorComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
