import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { IaActivityReportDetailsDialogComponent } from '../ia-activity-report-details-dialog/ia-activity-report-details-dialog.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ia-activity-report-internal',
  templateUrl: './ia-activity-report-internal.component.html',
  styleUrls: ['./ia-activity-report-internal.component.css']
})
export class IaActivityReportInternalComponent implements OnInit {

  totalCount:any;
  activities:any;
  page: any;

  constructor(private fb: FormBuilder, public dialog: MatDialog, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService, private toastr: ToastrService) { }


  ngOnInit(): void {

    this.totalCount=0;

    this.instituteMaster.getIaActivityProgress('Internal').subscribe(
      (data) => this.handleResponse(data)
    );

  }

  public onPageChanged(event) {
    this.page = event;
  }
  
  handleResponse(data)
  {
    this.totalCount=data['ActivityCount'];
    this.activities=data['iaActivity'];
  }


  openEditDialog(data) {
    const dialogRef = this.dialog.open(IaActivityReportDetailsDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.instituteMaster.getIaActivityProgress('Internal').subscribe(
        (data) => this.handleResponse(data)
      );
    });
  }

}
