import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstitutePocSubmission21Component } from '../institute-poc-submission.component';

@Component({
  selector: 'app-institute-poc-submission-dialog',
  templateUrl: './institute-poc-submission-dialog.component.html',
  styleUrls: ['./institute-poc-submission-dialog.component.css']
})
export class InstitutePocSubmissionDialog21Component implements OnInit {

  public pocForm: FormGroup;
  pocScore: any;
  commentFlag: boolean = false;
  constructor(
    private fb: FormBuilder,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstitutePocSubmission21Component>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.pocForm = this.fb.group({
      status: [null],
      score: [null, Validators.compose([Validators.max(50), Validators.min(2)])],
      comment: [null],
    });

    if (this.data['comment'] != '0' && this.data['comment'] != '') {
      this.commentFlag = true;
    }
  }

  onSubmit(values) {
    values['id'] = this.data['idea_id'];
    values['type'] = 'POC';
    this.innovationService.submitPocSubmission21(values).subscribe(
      data => this.handleResponse(data)
    );

  }

  handleResponse(data) {
    if (data['message'] == 'Successfully Updated') {
      this.toastr.success(data['message']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['message']);
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 46) return true;

    return false;
  }

}
