import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { Iic4ODialogComponent } from './iic4o-dialog/iic4o-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-institute-dashboard2021',
  templateUrl: './institute-dashboard2021.component.html',
  styleUrls: ['./institute-dashboard2021.component.css']
})
export class InstituteDashboard2021Component implements OnInit {
  IicStatus4o:any;
  constructor(private dashboardService: InstituteDashboardService, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getUserIic4oStatus();
  }

  getUserIic4oStatus() {
    this.dashboardService.getUserIic4oStatus().subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.IicStatus4o = data['data']['IicStatus4o'];
    if(this.IicStatus4o==0)
    {
      const dialogRef = this.dialog.open(Iic4ODialogComponent, {});
      dialogRef.afterClosed().subscribe((result) => {
        this.router.navigateByUrl('/institute/dashboard2020-21');
      });
    }
  }

}
