import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { CoordinatorDialogComponent } from './coordinator-dialog/coordinator-dialog.component';

@Component({
  selector: 'app-coordinator',
  templateUrl: './coordinator.component.html',
  styleUrls: ['./coordinator.component.css']
})
export class CoordinatorComponent implements OnInit {

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicCoordinator:any;

  constructor(private fb: FormBuilder, public dialog: MatDialog, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.requireField=0;

    this.instituteActivityService.getIicCoordinatorDetails('all').subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.iicCoordinator = data['data'];
  }

  onClickEvent(data,type){

    if(type=="Edit")
    {
      const dialogRef = this.dialog.open(CoordinatorDialogComponent, {
            data:data
          });
      
          dialogRef.afterClosed().subscribe((result) => {
            this.getCoordinators();
      
          });
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.deleteIicCoordinatorDetails(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }

  }

  getCoordinators(){
    
  }

  handleResponse1(data){
    
  }
}
