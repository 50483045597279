import { InstituteActivityPrototypeComponent } from '../institute-my-activity/institute-activity-prototype/institute-activity-prototype.component';
import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { RepositoryDialogComponent } from '../institute-my-activity-2021-22/repository-dialog/repository-dialog.component';
import * as myGlobals from 'src/app/app.component';
import { ReferralInstitutesDialogComponent } from '../institute-my-activity-2021-22/referral-institutes-dialog/referral-institutes-dialog.component';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-institute-my-activity202324',
  templateUrl: './institute-my-activity202324.component.html',
  styleUrls: ['./institute-my-activity202324.component.css']
})
export class InstituteMyActivity202324Component implements OnInit {
  baseUrl = myGlobals.baseUrl1;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  myActivity: InstitueMyActivity[] = [];
  reportsClosed: boolean = true;
  quarter_filter: string = '';
  activity_filter:string='';
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  title1 = "";
  searched: string;
  search_keyword: string;
  eventId: any;
  showUserID:any;
  MyActivityannualReport: number = 0;
  fileName: string = '';


  isShow:boolean = false;
  isShowSubmitted:boolean = true;
  constructor(
    private activityService: InstituteActivityService,
    public dialog: MatDialog,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.getMyActivityDetails(this.currentPageNo);
  }

  nextPage() {
    this.currentPageNo++;
    this.getMyActivityDetails(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getMyActivityDetails(this.currentPageNo);
  }

  getMyActivityDetails(pageNo) {
    this.activityService
      .getMyActivityDetails(this.per_page, pageNo, this.quarter_filter, this.title1,this.activity_filter,'2023-24')
      .subscribe((data) => this.handleResponse(data));
  }
  getMyActivityDetails1() {
    this.activityService
      .getMyActivityDetails(this.per_page, this.currentPageNo, this.quarter_filter, this.title1,this.activity_filter,'2023-24')
      .subscribe((data) => this.handleResponse(data));
  }
  nextPage1(e: any) {
    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    this.getMyActivityDetails1();
  }



  // reviewRequest(data){
  //   console.log(data,"activityyy")
  // }
  reviewRequest(data){
    this.eventId=data;

    this.activityService
    .submitReviewRequest(this.eventId)
    .subscribe((data) => this.reviewResponse(data));
  }

  reviewResponse(data) {
    if(data['status']=='1'){
      this.toastr.success(data['msg']);
      this.getMyActivityDetails(this.currentPageNo);
    }else{
      this.toastr.error(data['msg']);
    }
  }

  onDeleteClick(data){
    this.eventId=data['eventId'];

    if (confirm('Are you sure you want to delete?')) {
      // Save it!
    this.activityService
    .deleteMyActivity(this.eventId)
    .subscribe((data) => this.deleteResponse(data));
    } else {
      // Do nothing!
      // console.log('not confirm');
    }
  }

  deleteResponse(data){
if(data['status']=='1'){
  this.toastr.success(data['msg']);
  this.getMyActivityDetails(this.currentPageNo);
}else{
  this.toastr.error(data['msg']);
}
  }

  quarterFilter() {
    this.currentPageNo = 1;
    this.getMyActivityDetails(this.currentPageNo);
  }

  activityFilter(){
    this.currentPageNo = 1;
 this.getMyActivityDetails(this.currentPageNo);

  }


  downloadReport(endPoint, event_id) {
    this.fileName = "IICReport_" + event_id +'.pdf';
    this.activityService
      .getReportForDownload(endPoint, event_id)
      .subscribe((data) => this.downloadFile(data, this.fileName));
  }

  downloadReportAKAM(event_id) {
    this.fileName = "IICReportAKAM_" + event_id +'.pdf';
    this.activityService
      .getAKAMReportForDownload(event_id)
      .subscribe((data) => this.downloadFile(data, this.fileName));
  }

  // downloadReportAnnual(annual_report){
  //   this.downloadFile('https://api.mic.gov.in'+annual_report, this.fileName);
  // }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }


  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searched = filterValue;
  }

  search() {
    this.search_keyword = this.searched;
    this.activityService.getMyActivityDetails(this.per_page, this.currentPageNo, this.quarter_filter, this.search_keyword,this.activity_filter,'2023-24').subscribe(
      data => this.handleResponse(data)
    )
  }



  handleResponse(data) {
    this.myActivity = data.data;
    this.total_pages = data.meta.pagination.total;
    this.MyActivityannualReport= data.meta.annualReport;

    this.showUserID = data.meta.showtoId;

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }


  }

  openDialogRepository() {
    const dialogRef1 = this.dialog.open(RepositoryDialogComponent, {
    });

    dialogRef1.afterClosed().subscribe(result => {
    });
  }

  openDialogReferral() {
    const dialogRef2 = this.dialog.open(ReferralInstitutesDialogComponent, {
    });

    dialogRef2.afterClosed().subscribe(result => {
    });
  }




  openDialog(row) {
    row.action = '';
    row.url = '';
    row.label = 'NA';
    // row.type = '';
    row.download_url = '';
    row.download_url1 = '';

    /*if (row.eventId == 'EVE5050' || row.eventId == 'EVE5713' || row.eventId == 'EVE10136' || row.eventId == 'EVE151' || row.eventId == 'EVE2890' || row.eventId == 'EVE10137') {
      if (row.eventId == 'EVE151' || row.eventId == 'EVE2890' || row.eventId == 'EVE10137') {
        row.download_url = "monthlyReportCouncilPdf";
        if (row.status == 'Submitted') {
          row.action = "edit";
          row.url = "/institute/activity/monthly-report-of-council";
          row.label = "Uploaded Successfully";
        } else if (row.status == 'Not Submitted') {
          row.action = "add";
          row.download_url = "";
          row.url = "/institute/activity/monthly-report-of-council";
          row.label = "Upload Report";
        } else if (row.status == 'Verified') {
          row.url = "";
          row.label = 'Report Verified';
        } else if (row.status == 'Disapproved') {
          row.url = "";
          row.label = 'Report Disapproved';
        }
      }
      else if (row.eventId == 'EVE5850') {
        row.download_url = "monthlyReportPdf";
        if (row.status == 'Submitted') {
          row.action = "edit";
          row.url = "/institute/activity/monthly_report";
          row.label = "Uploaded Successfully";
        }
        else if (row.status == 'Not Submitted') {
          row.action = "add";
          row.download_url = "";
          row.url = "/institute/activity/monthly_report";
          row.label = "Upload Report";
        } else if (row.status == 'Verified') {
          row.url = "";
          row.label = 'Report Verified';
        } else if (row.status == 'Disapproved') {
          row.url = "";
          row.label = 'Report Disapproved.';
        }
      }
    } else {
      row.url = "/institute/activity/monthly_report";
      row.download_url = "monthlyReportPdf";
      if (row.status == 'Submitted') {
        row.action = "edit";
        row.label = "Uploaded Successfully";
      }
      else if (row.status == 'Not Submitted') {
        row.action = "add";
        row.label = "Upload Report";
        row.download_url = "";
      } else if (row.status == 'Verified') {
        row.url = "";
        row.label = 'Report Verified';
      } else if (row.status == 'Disapproved') {
        row.url = "";
        row.label = 'Report Disapproved.';
      }
    }*/

    row.url = "monthly_report";
    row.download_url = "monthlyReportPdf";
    row.download_url1 = "monthlyReportPdfAKAM";
    if (row.status == 'Submitted') {
      row.action = "edit";
      row.label = "Uploaded Successfully";
    }
    else if (row.status == 'Not Submitted') {
      row.action = "add";
      row.label = "Upload Report";
      row.download_url = "";
      row.download_url1 = "";
    } else if (row.status == 'Verified') {
      row.url = "";
      row.label = 'Report Verified';
    } else if (row.status == 'Disapproved') {
      row.url = "";
      row.label = 'Report Disapproved.';
    }

    const dialogRef = this.dialog.open(InstituteMyActivity202324Dialog, {
      data: { activity: row },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  deleteRow() { }

  uploadPrototype() {
    const dialogRef = this.dialog.open(InstituteActivityPrototypeComponent, {});
  }
}



export class InstitueMyActivity {
  id: number;
  title: string;
  description: string;
  userId: string;
  quarter: string;
  endDate: string;
  startDate: string;
  comments: string;
}

@Component({
  selector: 'app-institute-my-activity2023-24-dialog',
  templateUrl: 'institute-my-activity202324-dialog.html',
})
export class InstituteMyActivity202324Dialog implements OnInit {
  id: any;
  activity: any;
  fileName: string = '';
  baseUrl = 'https://mic.gov.in/images/events/';

  constructor(
    private formBuilder: FormBuilder,
    private activityService: InstituteActivityService,
    public dialogRef: MatDialogRef<InstituteMyActivity202324Dialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.activity = this.data['activity'];
  }

  downloadReport(endPoint, event_id) {
    this.fileName = "IICReport_" + event_id +'.pdf';
    this.activityService
      .getReportForDownload(endPoint, event_id)
      .subscribe((data) => this.downloadFile(data, this.fileName));
  }

  downloadReportAKAM(event_id) {
    this.fileName = "IICReportAKAM_" + event_id +'.pdf';
    this.activityService
      .getAKAMReportForDownload(event_id)
      .subscribe((data) => this.downloadFile(data, this.fileName));
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) { }

}
