<div class="container">
    <h3 style="text-align: center; font-weight: bold">POC Submission</h3>
    <div class="row">
        <div class="col-md-12">
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Title</p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{data?.idea_title}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Description</p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{data?.description}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Institute Name</p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{data?.instituteName}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Lead Name </p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{data?.team_lead_name}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Type </p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{data?.type}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Category </p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{data?.category}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">POC Score </p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{data?.poc_score}}</p>
                </div>
            </div>



            <form [formGroup]="pocForm" (ngSubmit)="onSubmit(pocForm.value)">
                <div class="row btmLine">
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                            <mat-label>POC Score</mat-label>
                            <input matInput formControlName="score" placeholder="POC Score" required>

                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                            <mat-label>Comment</mat-label>
                            <input matInput formControlName="comment" placeholder="Comment" required>

                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option value="Approved">Approved</mat-option>
                                <mat-option value="Disapproved">Disapproved</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div mat-dialog-actions align="end" class="mt-3">
                    <button type="button" class="btn btn-danger" (click)="onNoClick()">Close</button>
                    <button type="submit" class="btn btn-primary" [disabled]="pocForm.invalid">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- </mat-dialog-content> -->