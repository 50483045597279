import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { JarwisService } from '../../../../Services/jarwis.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-atl-profile-detail',
  templateUrl: './atl-profile-detail.component.html',
  styleUrls: ['./atl-profile-detail.component.css']
})
export class AtlProfileDetailComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  activityType:any;
  activityId:any;
  activity_id:any;

  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  state: any[];
  institute: any[];

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService, private jarwis :JarwisService,private toastr: ToastrService)
  {
    // this.activityId = "add";
    // this.activityType = "add";
    // this.route.params.subscribe((params) => {
    //   this.activityId = params.activityId;
    //   this.activityType = params.activityType;
    // });



  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'state': [null, Validators.required],
      'aishe_code': [null, Validators.required],
      'institute_name': [null, Validators.required],
      'institute_address': [null, Validators.required],
      'institute_city': [null, Validators.required],
      'name': [null, Validators.required],
      'email': [null, Validators.required],
      'mobile_number': [null, Validators.required],
      'udise_number': [null],
    });

    this.requireField=0;
    this.activity_id=0;

    this.instituteActivityService.getAgencyProfile().subscribe(
      (data) => this.handleResponse(data)
    );
    this.jarwis.stateList().subscribe(
      data => this.setStates(data)
    );
    // var parm = JSON.stringify(["program_theme"]);
    // this.instituteMaster
    //   .getMasterDetails(parm)
    //   .subscribe((data) => this.handleResponseForAutoPopulate(data));



  }

  setStates(data) {
    this.state = [];
    for (let i = 0; i < data.length; i++) {
      this.state.push(data[i])
    }
  }
  stateChange(data){
   
    var state = this.iicImpactQuestions.get('state').value;
    this.jarwis.getInstitute(state).subscribe(data => this.setInstitutes(data));
  }
  setInstitutes(data) {
    this.institute = [];
    for (let i = 0; i < data.length; i++) {
      this.institute.push(data[i])
    }
  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }


  handleResponse(data) {
    
    this.iicImpactQuestions.patchValue({
      state: data['instituteState'],
      institute_name: data['instituteName'],
      institute_address: data['instituteAddress'],
      institute_city: data['instituteCity'],
      name: data['name'],
      email: data['email'],
      mobile_number: data['phone'],
      udise_number: data['udise_number'],
    });
    this.stateChange(data['instituteState']);
    this.iicImpactQuestions.patchValue({
      aishe_code: data['college_aicte_code']
    });
  }

  handleResponseList(data) {
    this.iicActivity = data['data'];
    this.programTheme = data['theme_NIC'];
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('instituteState',  this.iicImpactQuestions.get('state').value);
    formData.append('college_aicte_code',  this.iicImpactQuestions.get('aishe_code').value);
    formData.append('instituteName',  this.iicImpactQuestions.get('institute_name').value);
    formData.append('instituteAddress',  this.iicImpactQuestions.get('institute_address').value);
    formData.append('instituteCity',  this.iicImpactQuestions.get('institute_city').value);
    formData.append('name',  this.iicImpactQuestions.get('name').value);
    formData.append('email',  this.iicImpactQuestions.get('email').value);
    formData.append('phone',  this.iicImpactQuestions.get('mobile_number').value);

    this.instituteActivityService.submitAtlProfileDetail(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      window.location.href='/institute/atlsic-dashboard';
      this.toastr.success(data['message']);
    }
    else{
      this.toastr.error(data['message']);

    }
  }

  onIICStatusChange(ob) {
    let iicStatus = ob.value;
    if(iicStatus == 'SELF') {
      this.requireField = 0;
    } else {
      this.requireField = 1;


      this.instituteActivityService.getIicActivityList(ob.value).subscribe(
        (data) => this.handleResponseList(data)
      );

    }
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }
}
