import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { Iic4ODialogComponent } from '../../institute-dashboard2021/iic4o-dialog/iic4o-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cards2025',
  templateUrl: './cards2025.component.html',
  styleUrls: ['./cards2025.component.css']
})
export class Cards2025Component implements OnInit {

  activity: any;
  announcements: any;
  newsCorners: any;
  quarter2: any;
  quarter1: any;
  cumulative: any;
  cumulative_score: any;
  rewards_cumlative: any;
  ForWhom: any;
  IicStatus4o:any;

  constructor(private dashboardService: InstituteDashboardService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getReport();
    this.getAnnouncements();
    this.getNewsCorner();
    this.getRatings();
	  this.ForWhom="";
	  this.ForWhom=localStorage.getItem('ForWhom');

  // this.dashboardService.getUserIic4oStatus().subscribe(
  //   data => this.handleResponse(data)
  // );

  }

  handleResponse(data) {
    this.IicStatus4o = data['data']['IicStatus4o'];
    if(this.IicStatus4o==0)
    {
      const dialogRef = this.dialog.open(Iic4ODialogComponent, { width:'60%'});
      dialogRef.afterClosed().subscribe((result) => {
      });
    }
  }

  getReport() {
    this.dashboardService.getActivityData2025(localStorage.getItem('token')).subscribe(
      (data) => {
        this.activity = data['data'][0];
        ;
      }
    );
  }

  getAnnouncements() {
    this.dashboardService.getAnnouncements2025(localStorage.getItem('token')).subscribe(
      (data) => {
        this.setAnnouncements(data['data']);
      }
    );
  }

  setAnnouncements(data) {
    this.announcements = [];
    data.forEach(element => {
      this.announcements.push(element.announcement);
    });
  }

  getNewsCorner() {
    this.dashboardService.getAllNewsCornerDetails2025(localStorage.getItem('token')).subscribe(
      (data) => {
        this.setNewscorners(data['data']);
      }
    );
  }

  setNewscorners(data) {
    this.newsCorners = [];
    data.forEach(element => {
      element.forEach(news =>{
      this.newsCorners.push(news.evant_title);
      });
    });
  }

  getRatings(){
    this.dashboardService.getInstituteDashboardRating2025().subscribe(
      data => {
        this.quarter1 = data['data'][0];
        this.quarter2 = data['data'][1];
        this.cumulative = data['data'][2];
		 this.cumulative_score = data['meta']['pagination']['score_cumlative'];
		 this.rewards_cumlative = data['meta']['pagination']['rewards_cumlative'];
      }
    )
  }
}
