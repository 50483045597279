import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstitutePrototypeSubmissionDialog21Component } from 'src/app/components/users/institute/institute-yic2021/institute-prototype-submission/institute-prototype-submission-dialog/institute-prototype-submission-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { InstituteEvaluationDetailsComponent } from 'src/app/components/users/institute/institute-yic2021/institute-evaluation-details/institute-evaluation-details.component';


@Component({
  selector: 'app-innovation-contest',
  templateUrl: './innovation-contest.component.html',
  styleUrls: ['./innovation-contest.component.css']
})
export class InnovationContestComponent implements OnInit {

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  prototypeSubmission = [];
  filterModel = "All";
  counts: any;
  frontCurrentPageNo: number = 0;
  rid: number = 3;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;

  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private innovationService: InstituteInnovationContestService) { }

  ngOnInit(): void {
    this.getPrototypeSubmission();
    this.getPrototypeCount();
  }
getPrototypeCount(){
  this.innovationService.getStatusCounts21('prototype').subscribe(
    data => this.counts = data['prototype']['0']
  )
}
  getPrototypeSubmission() {
    this.innovationService.getPrototypeSubmission21(this.currentPageNo,this.per_page, 'Innovation23').subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.prototypeSubmission = [];

    data['data'].forEach((element) => {
      this.prototypeSubmission.push(element);
    });

this.total_pages = data.meta.pagination.total;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1);
	  } else {
		this.currentPageNo = (e.pageIndex+1);
	  }
	this.per_page = e.pageSize;
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.changeFilter();
    }
  }

  previousPage() {
    this.currentPageNo--;
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.changeFilter();
    }
  }


  openDialog(data) {
    const dialogRef = this.dialog.open(InstitutePrototypeSubmissionDialog21Component, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {

      this.getPrototypeSubmission();
      this.getPrototypeCount();
    });
  }

  openEvaluationViewDialog(data) {
    const dialogRef = this.dialog.open(InstituteEvaluationDetailsComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  changeFilter() {
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.innovationService.getPrototypeSubmissionFilter21(this.currentPageNo, this.filterModel).subscribe(
        data => this.handleResponse(data)
      );
    }

  }

  openFileToDownload(id)
  {
    window.open('https://api.mic.gov.in/api/DownloadInnovationDetails?proto_id='+id);
    // this.innovationService.getYuktiInnovationData(id).subscribe(
    //   data => this.downloadFile(data, 'YuktiInnovationData.pdf')
    // );
  }

  openEvaluationDialog(id)
  {
    window.open('https://api.mic.gov.in/api/DownloadInnovationDetails?proto_id='+id);
    // this.innovationService.getYuktiInnovationData(id).subscribe(
    //   data => this.downloadFile(data, 'YuktiInnovationData.pdf')
    // );
  }

  DeleteIdeaFromVerification(data) {
    this.innovationService.getDeleteIdeaFromVerification(data).subscribe(
      data => this.handleResponseDelete(data)
    );
  }

  handleResponseDelete(data) {

    if(data["status"]==1)
    {
      this.toastr.error(data["msg"]);
    }
    else
    {
      this.toastr.error(data["msg"]);
    }

  }

  nominateChallenge(id){
    var arr = {
      'challenge_id' : this.rid,
      'innovation_id' : id,
    }
    this.innovationService.challengeNominate(arr).subscribe(
      data => this.handleResponse12(data));
    
    
  }
  handleResponse12(data){
    if(data['message'] == 'Success'){
      document.getElementById('nominateSuccess').style.display = 'none';
      document.getElementById('nominateRemove').style.display = 'block';
    
      this.toastr.success('Nominate Successfully');
    }else{
      this.toastr.success(data['data']);
    }
  }

  RemoveChallenge(id){
    if(confirm('Are You Sure Want To Remove Challenge Nomination') == true){
      var arr = {
        'challenge_id' : this.rid,
        'innovation_id' : id,
      }
      this.innovationService.challengeNominateRemove(arr).subscribe(
        data => this.handleResponse1(data));
    }
  }

  handleResponse1(data){
    if(data['message'] == 'success'){
      document.getElementById('nominateSuccess').style.display = 'block';
      document.getElementById('nominateRemove').style.display = 'none';
      this.toastr.success(data['data']);
    }else{
      this.toastr.error(data['data']);
    }
  }

}
