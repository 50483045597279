<div class="container" style="margin-top: 30px;">	
    <div class="row" color="primary">
          <div class="col-md-12">
        <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
          Announcement Details
        </h2>
      </div>
      </div>
      <mat-card class="mat-elevation-z6 proInstCardMat">
        <div class="row">
          <div class="col-md-12">
          <div class="tableBody">
                <div class="table-responsive">
              <table class="table">
                  <thead class="facultyTable">
                    <tr>
                      <th width="5%">S.No.</th>
                      <th width="10%">Announcement</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let announcement of announcements;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{announcement?.announcement}}</td>
                  <!-- <td>
                  <button mat-button routerLink="/institute/atl-activity-report/1/{{ activity?.id }}" style="background-color: green; color: white;">
                      Submit Report
                  </button>
              
                  </td> -->
                  </tr>
                  <tr *ngIf="announcements?.length==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
                  background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                  There is no announcement added yet.
                  </td></tr>
              
                  </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
  </div>
  