import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-institute-idea-evaluation',
  templateUrl: './institute-idea-evaluation.component.html',
  styleUrls: ['./institute-idea-evaluation.component.css']
})
export class InstituteIdeaEvaluationComponent implements OnInit {

  public mentorshipForm: FormGroup;
  innovative: any;
  patentability: any;
  strength: any;
  sum: any;
  nicid1: any;
  nic_id: any;
  market: any;
  team_members: any;
  risk_factor: any;
  robust: any;
  understand: any;
  filled: any;
  usefull: any;
  title: any;
  title1: string;
  userid1: string;
  teamId: string;
  protoId: string;
  userId: string;
  proto_id2: string;
  team_id: string;

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;
  minDate = "2018-01-01";
  maxDate = "2022-03-31";
  score_6: any;
  score_7: any;
  score_8: any;
  score_9: any;
  score_10: any;
  buttontype: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });

    this.updateGetData();

  }

  updateGetData() {
    // this.instituteMaster
    //   .getSubmitEvaluationScoreIII(this.proto_id2, this.team_id, this.userId)
    //   .subscribe((data) => this.setupdategetdata(data));
  }

  setupdategetdata(data) {
    this.sum = 0;
    this.mentorshipForm.controls['innovation_relevance'].setValue(data['innovation_relevance'])
    this.mentorshipForm.controls['robustness'].setValue(data['robustness']);
    this.mentorshipForm.controls['uniqueness'].setValue(data['uniqueness']);
    this.mentorshipForm.controls['technological_scalability'].setValue(data['technological_scalability']);
    this.mentorshipForm.controls['business_model'].setValue(data['business_model']);

    this.sum = Number(data['total_score']);
    this.mentorshipForm.controls['remark'].setValue(data['remark']);
    if(data['recommend']!="")
    {
      this.buttontype=2;
    }
  }

  ngOnInit(): void {

    this.mentorshipForm = this.fb.group({
      'innovation_relevance': [null, Validators.required],
      'robustness': [null, Validators.required],
      'uniqueness': [null, Validators.required],
      'technological_scalability': [null, Validators.required],
      'business_model': [null, Validators.required]
    });

    this.mentorshipForm.get("innovation_relevance").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("robustness").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("uniqueness").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("technological_scalability").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("business_model").valueChanges.subscribe(data => {
      this.changeValidators();
    })

    this.sum1();


    // var parm = JSON.stringify(["program_theme"]);
    // this.instituteMaster
    //   .getMasterDetails(parm)
    //   .subscribe((data) => this.handleResponseForAutoPopulate(data));



  }

  changeValidators() {
    this.sum = 0;

    this.innovative = Number(this.mentorshipForm.get('innovation_relevance').value);
    this.patentability = Number(this.mentorshipForm.get('robustness').value);
    this.strength = Number(this.mentorshipForm.get('uniqueness').value);
    this.market = Number(this.mentorshipForm.get('technological_scalability').value);
    this.team_members = Number(this.mentorshipForm.get('business_model').value);

    if(this.innovative=="" || this.innovative == undefined)
    {
      this.innovative = 0;
    }
    if(this.patentability=="" || this.patentability == undefined)
    {
      this.patentability = 0;
    }
    if(this.strength=="" || this.strength == undefined)
    {
      this.strength = 0;
    }
    if(this.market=="" || this.market == undefined)
    {
      this.market = 0;
    }
    if(this.team_members=="" || this.team_members == undefined)
    {
      this.team_members = 0;
    }

    if(this.sum<0)
    {
      this.sum = 0;
    }
    else
    {
      this.sum1();
    }
  }

  sum1() {
    this.sum = 0;
    this.sum = Number(this.innovative) + Number(this.patentability) + Number(this.strength) + Number(this.market) + Number(this.team_members);
    // alert(this.sum);
  }



  onSubmit(data) {
    this.innovative = Number(this.mentorshipForm.get('innovation_relevance').value);
    this.patentability = Number(this.mentorshipForm.get('robustness').value);
    this.strength = Number(this.mentorshipForm.get('uniqueness').value);
    this.market = Number(this.mentorshipForm.get('technological_scalability').value);
    this.team_members = Number(this.mentorshipForm.get('business_model').value);

    if(this.innovative<0){ alert('Problem-Solution Fit: Relevance of Innovation for the Industry/Society/Market Requirement value should vary between 0 to 20.'); return false; }

    if(this.patentability<0){ alert('Product-Market Fit: TRL & Readiness of Innovation or Solution for Market Entry/Roll out value should vary between 0 to 20.'); return false; }

    if(this.strength<0){ alert('Value Proposition Fit: Appropariate Quantification of Features of Product and Benefits to Customer value should vary between 0 to 20.'); return false; }

    if(this.market<0){ alert('Potential Market Size with Target Group Identification and Addressing Right Business Opportunity value should vary between 0 to 20.'); return false; }

    if(this.team_members<0){ alert('Business Fit: Readiness of Business Model for Implementation & Startup Formation value should vary between 0 to 20.'); return false; }

    if (this.mentorshipForm.valid) {
      data['rid'] = this.rid;
    } else {
      this.toastr.error("Kindly fill the all questions score.")
    }

  }
  handleResponse(data) {
    if (data['msg'] == "Successfully created.") {
      this.buttontype=2;
      this.toastr.success("Successfully Submitted")
    }
    else {
      this.toastr.error(data['msg']);
    }
  }

  formatLabel(value: number) {
    return value;
  }

}
