<div class="container">
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="requestLogoForm" (ngSubmit)="onSubmit(requestLogoForm.value)">
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">lock</mat-icon>
                        <mat-label>Activity Title</mat-label>
                        <input matInput placeholder="Activity Title" formControlName="event_title" required>
                        <mat-error *ngIf="requestLogoForm.controls['event_title'].hasError('required')">Activity Title
                            is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
                        <mat-label>Activity Theme</mat-label>
                        <mat-select formControlName="event_theme" required>
                            <mat-option value="IPR">IPR</mat-option>
                            <mat-option value="Innovation">Innovation</mat-option>
                            <mat-option value="Startup/entrepreneurship">Startup/entrepreneurship</mat-option>
							<mat-option value="Others">Others</mat-option>
                        </mat-select>
                        <mat-error *ngIf="requestLogoForm.controls['event_theme'].hasError('required')">Activity Theme
                            is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">lock</mat-icon>
                        <mat-label>Target Audience</mat-label>
                        <input matInput placeholder="Target Audience" formControlName="Target_Aud" required>
                        <mat-error *ngIf="requestLogoForm.controls['Target_Aud'].hasError('required')">Target Audience
                            is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
                        <mat-label>Participants</mat-label>
                        <mat-select formControlName="participants" required>
                            <mat-option value="Student">Student</mat-option>
                            <mat-option value="Faculty">Faculty</mat-option>
                            <mat-option value="Both">Both</mat-option>
                        </mat-select>
                        <mat-error *ngIf="requestLogoForm.controls['participants'].hasError('required')">Participants is
                            required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">lock</mat-icon>
                        <mat-label>URL for the Event</mat-label>
                        <input matInput placeholder="URL for the Event" formControlName="event_url" required>
                        <mat-error *ngIf="requestLogoForm.controls['event_url'].hasError('required')">URL is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">lock</mat-icon>
                        <mat-label>Expected No. of Participants</mat-label>
                        <input matInput placeholder="Expected No. of Participants" formControlName="no_of_participant" (keypress)=isNumberKey($event)
                            required>
                        <mat-error *ngIf="requestLogoForm.controls['no_of_participant'].hasError('required')">No. of
                            Participants is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row ml-2">
                <h2>Date & Duration</h2>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Start Date</mat-label>
                        <input matInput readonly [matDatepicker]="picker1" placeholder="Start Date"
                            formControlName="fromDate" required>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error *ngIf="requestLogoForm.controls['fromDate'].hasError('required')">Start Date is
                            required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>End Date</mat-label>
                        <input matInput readonly [matDatepicker]="picker2" placeholder="End Date"
                            formControlName="toDate" required [min]="minDate">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error *ngIf="requestLogoForm.controls['toDate'].hasError('required')">End Date is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>



            <div>
                <div class="row">
                    <div class="col-lg-8 col-md-10">
                        <label>Program brief/Brochure/Banner</label>
                        
                            <mat-error>PDF(max 2MB)</mat-error>
                         
                        <!-- <mat-error>PDF(max 2MB)</mat-error> -->
                    </div>

                    <div class="col-lg-4 col-md-2 float-right">
                        <label class="custom-file-label float-right" for="customFile" *ngIf="!logoFile">Choose file</label>
                        <label class="custom-file-label float-right" for="customFile"
                        *ngIf="logoFile">{{logoFile[0]['name']}}</label>
                        <input #logoAttachmentsubfileRef type="file" accept="application/pdf" name="namePhotoUpload"
                            (change)="( logoFile = logoAttachmentsubfileRef.files )"
                            class="custom-file-input float-right" required>
                    </div>
                </div>
            </div>



            <!-- <div class="row">

                <div class="col-6">
                    <mat-label>Program brief/Brochure/Banner</mat-label>
                </div>
                <div class="col-5 ml-3">
                    <label class="custom-file-label float-right" for="customFile">Choose file</label>
                    <input type="file" accept="application/pdf" class="custom-file-input float-right" id="photoNameUpload"
                        name="photoNameUpload" multiple required>
                </div>
            </div> -->
            <mat-dialog-actions align="center">
                <button mat-raised-button type="button" (click)="onNoClick()" color="warn">Cancel</button>
                <button mat-raised-button type="submit" color="primary">Submit</button>
            </mat-dialog-actions>
        </form>
    </mat-dialog-content>
</div>