import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InstituteNewsCornerService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  getActivityType() {
    const data = {
      page: 'news_corner',
    };
    return this.http.post(`${this.baseUrl}/get-master-type`, data);
  }

  getAddedCalendarList(type) {
    const data = {
      type: [type],
    };
    return this.http.post(`${this.baseUrl}/get-master-type/`, data);
  }

  submitNews(data) {
    return this.http.post(`${this.baseUrl}/newsCornerSubmit`, data);
  }

  updateNews(data) {
    return this.http.post(`${this.baseUrl}/newsCornerUpdate`, data);
  }

  getAllNewsCornerDetails(per_page=10,pageNo) {
    return this.http.get(
      `${this.baseUrl}/get-allnewscorner-details?per_page=`+per_page+`&page=` + pageNo
    );
  }

  getSelfNewsCornerDetails(per_page=10,pageNo) {
    return this.http.get(
      `${this.baseUrl}/get-selfnewscorner-details?per_page=`+per_page+`&page=` + pageNo
    );
  }


  deleteNews(data) {
    return this.http.post(`${this.baseUrl}/removeNewsCorner/` + data, data);
  }


  getNewsCornerStatus(pageNo, status) {
    return this.http.get(
      `${this.baseUrl}/get-selfnewscorner-details?per_page=` + pageNo + `&filter=` + status
    );
  }

  getThemesDropdown() {
    return this.http.get(
      `${this.baseUrl}/get-newscorner-theme`
    );
  }

  getFilterThemeAll(pageNo, theme) {
    return this.http.get(`${this.baseUrl}/get-allnewscorner-details?per_page=10&page=` + pageNo + `&theme=` + theme);
  }

  getFilterThemeSelf(pageNo, theme) {
    return this.http.get(`${this.baseUrl}/get-selfnewscorner-details?per_page=10&page=` + pageNo + `&theme=` + theme);
  }

}
