<!-- IIC Website Header -->
<app-iic-web-main-navbar></app-iic-web-main-navbar>


<div id="carouselExampleControlsMobile" class="carousel slide bannerSlideInnovation" data-ride="carousel">
  <div class="carousel-inner">
	<div class="carousel-item active">
	  <img class="d-block w-100" src="assets/assets/images/iic-outcome-bg.jpg" alt="First slide">
	  <div class="carousel-caption innovationContest2020Content">
		<h2>IIC Outcomes</h2>
	  </div>
	</div>
  </div>

  <!-- <a class="carousel-control-prev landingSliderBannerPrevMob" href="#carouselExampleControlsMobile" role="button" data-slide="prev">
	<i class="fa fa-arrow-left" aria-hidden="true"></i>
	<span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next landingSliderBannerNextMob" href="#carouselExampleControlsMobile" role="button" data-slide="next">
	<i class="fa fa-arrow-right" aria-hidden="true"></i>
	<span class="sr-only">Next</span>
  </a> -->
</div>





<section class="mentorObjective" id="objectiveBox" data-scroll-index="1">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Idea to start up </h2>
				</div>
			</div>
			<div class="col-md-12">
				<div class="objContentPara">
					<mat-card>
						<mat-card-header fxLayoutAlign="center center">
							<mat-card-subtitle>
								<h2>
									<mat-icon class="myImpactDashboard">multiline_chart</mat-icon>IDEA TO START UP
								</h2>
							</mat-card-subtitle>
						</mat-card-header>

						<!-- <div class="w-100 h-300p"> -->
						<mat-form-field class="ml-2">
							<mat-label>Chart For</mat-label>
							<mat-select [(ngModel)]="selectedType" (selectionChange)="setEventwiseBarData()">
								<mat-option value="IIC Calendar Activity">IIC Calendar Activity</mat-option>
								<mat-option value="MIC Driven Activity"> MIC Driven Activity</mat-option>
								<mat-option value="Self Driven Activity"> Self Driven Activity</mat-option>
							</mat-select>
						</mat-form-field>
						<canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
							[colors]="barChartColors" [plugins]="barChartPlugins" [legend]="barChartLegend"
							[chartType]="barChartType">
						</canvas>
						<!-- </div> -->
						<!-- <a routerLink="" class="float-right mb-4">...more</a> -->
					</mat-card>
				</div>
			</div>
		</div>
	</div>
</section>



<section class="expectedOutcome" id="outcomesBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">No. of IPs generated, published and granted</h2>
				</div>
			</div>
			<div class="col-md-6">
				<div class="objContentPara">
					<mat-card>
						<mat-card-header fxLayoutAlign="center center">
							<mat-card-subtitle>
								<h2>
									<mat-icon class="myImpactDashboard">multiline_chart</mat-icon>No. of IPs generated, published and granted
								</h2>
							</mat-card-subtitle>
						</mat-card-header>

						<!-- <div class="w-100 h-300p"> -->
						<mat-form-field class="ml-2">
							<mat-label>Chart For</mat-label>
							<mat-select [(ngModel)]="selectedType" (selectionChange)="setEventwiseBarData()">
								<mat-option value="IIC Calendar Activity">IIC Calendar Activity</mat-option>
								<mat-option value="MIC Driven Activity"> MIC Driven Activity</mat-option>
								<mat-option value="Self Driven Activity"> Self Driven Activity</mat-option>
							</mat-select>
						</mat-form-field>
						<canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
							[colors]="barChartColors" [plugins]="barChartPlugins" [legend]="barChartLegend"
							[chartType]="barChartType">
						</canvas>
						<!-- </div> -->
						<!-- <a routerLink="" class="float-right mb-4">...more</a> -->
					</mat-card>
				</div>
			</div>
			<div class="col-md-6">
				<div class="objContentPara">
					<mat-card>
						<mat-card-header fxLayoutAlign="center center">
							<mat-card-subtitle>
								<h2>
									<mat-icon class="myImpactDashboard">multiline_chart</mat-icon>No. of IPs generated, published and granted
								</h2>
							</mat-card-subtitle>
						</mat-card-header>

						<!-- <div class="w-100 h-300p"> -->
						<mat-form-field class="ml-2">
							<mat-label>Chart For</mat-label>
							<mat-select [(ngModel)]="selectedType" (selectionChange)="setEventwiseBarData()">
								<mat-option value="IIC Calendar Activity">IIC Calendar Activity</mat-option>
								<mat-option value="MIC Driven Activity"> MIC Driven Activity</mat-option>
								<mat-option value="Self Driven Activity"> Self Driven Activity</mat-option>
							</mat-select>
						</mat-form-field>
						<canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
							[colors]="barChartColors" [plugins]="barChartPlugins" [legend]="barChartLegend"
							[chartType]="barChartType">
						</canvas>
						<!-- </div> -->
						<!-- <a routerLink="" class="float-right mb-4">...more</a> -->
					</mat-card>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="expectedOutcome1" id="applyBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Funding Details</h2>
				</div>
			</div>
			<div class="col-md-12">
				<div class="objContentPara">
					<img class="d-block w-100 thirdFunding" src="assets/assets/images/graph-funding-details.jpg" alt="First slide">
				</div>
			</div>
		</div>
	</div>
</section>

<section class="expectedOutcome" id="eligibilityBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Participated in National/International level hackathon/Any competition</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12"><h2 class="partiHeading">Student/Faculty Participated</h2></div>
			<div class="col-md-4">
				<div class="allParticipated">
					<div class="row">
						<div class="col-md-7 stuParti">
							<h2>4012</h2>
							<span>Student Participated</span>
						</div>
						<div class="col-md-5 teaPati">
							<h2>1025</h2>
							<span>Faculty Participated</span>
						</div>
						<div class="col-md-12 yearPartiBox">
							<p class="yearParti">2018-19</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="allParticipated">
					<div class="row">
						<div class="col-md-7 stuParti">
							<h2>4012</h2>
							<span>Student Participated</span>
						</div>
						<div class="col-md-5 teaPati">
							<h2>1025</h2>
							<span>Faculty Participated</span>
						</div>
						<div class="col-md-12 yearPartiBox">
							<p class="yearParti">2019-20</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="allParticipated">
					<div class="row">
						<div class="col-md-7 stuParti">
							<h2>4012</h2>
							<span>Student Participated</span>
						</div>
						<div class="col-md-5 teaPati">
							<h2>1025</h2>
							<span>Faculty Participated</span>
						</div>
						<div class="col-md-12 yearPartiBox">
							<p class="yearParti">2020-21</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="row">
			<div class="col-md-12"><h2 class="partiHeading">Student/Faculty Winners</h2></div>
			<div class="col-md-4">
				<div class="allParticipated">
					<div class="row">
						<div class="col-md-7 stuParti">
							<h2>4012</h2>
							<span>Student Winners</span>
						</div>
						<div class="col-md-5 teaPati">
							<h2>1025</h2>
							<span>Faculty Winners</span>
						</div>
						<div class="col-md-12 yearPartiBox">
							<p class="yearParti">2018-19</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="allParticipated">
					<div class="row">
						<div class="col-md-7 stuParti">
							<h2>4012</h2>
							<span>Student Winners</span>
						</div>
						<div class="col-md-5 teaPati">
							<h2>1025</h2>
							<span>Faculty Winners</span>
						</div>
						<div class="col-md-12 yearPartiBox">
							<p class="yearParti">2019-20</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="allParticipated">
					<div class="row">
						<div class="col-md-7 stuParti">
							<h2>4012</h2>
							<span>Student Winners</span>
						</div>
						<div class="col-md-5 teaPati">
							<h2>1025</h2>
							<span>Faculty Winners</span>
						</div>
						<div class="col-md-12 yearPartiBox">
							<p class="yearParti">2020-21</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>



<a href="#" id="scroll" style="display: none;"><span></span></a>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
