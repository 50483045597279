<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Evaluation</h2>
    </div>

	<div class="card">
        <div class="card-title"><p>Evaluate on behalf of following criteria.</p></div>
	</div>

    <form class="instiBankForm" [formGroup]="mentorshipForm" (ngSubmit)="onSubmit(mentorshipForm.value)">
      <table style="width:100%">
        <tbody>
            <tr>
                <td colspan="2" class="evaluateAssignHeading">Assign Value in the Scale 0 to 20; 0 for very low,
                  20 for Very High</td>
            </tr>
            <tr>
                <td style="padding: 10px;width: 65%;">Problem-Solution Fit: Relevance of Innovation for the Industry/Society/Market Requirement
                </td>
                <td style="padding: 10px;">
                    Score
                    <mat-slider style="
                width: 303px;
            " thumbLabel [displayWith]="formatLabel" formControlName="innovation_relevance" tickInterval="1" min=-1
                        max="20">
                    </mat-slider>
                    20
                </td>
            </tr>
            <tr>
                <td style="padding: 10px;width: 65%;">Product-Market Fit: TRL & Readiness of Innovation or Solution for Market Entry/Roll out</td>
                <td style="padding: 10px;">
                  Score
                    <mat-slider style="
                width: 303px;
            " thumbLabel [displayWith]="formatLabel" formControlName="robustness" tickInterval="1"
                        min=-1 max="20">
                    </mat-slider>
                    20
                </td>
            </tr>
            <tr>
                <td style="padding: 10px;width: 65%;">Value Proposition Fit: Appropariate Quantification of Features of Product and Benefits to Customer</td>
                <td style="padding: 10px;">
                  Score
                    <mat-slider style="
                width: 303px;
            " thumbLabel [displayWith]="formatLabel" formControlName="uniqueness" tickInterval="1" min=-1
                        max="20">
                    </mat-slider>
                    20
                </td>
            </tr>
            <tr>
                <td style="padding: 10px;width: 65%;">Potential Market Size with Target Group Identification and Addressing Right Business Opportunity</td>
                <td style="padding: 10px;">
                  Score
                    <mat-slider style="
                width: 303px;
            " thumbLabel [displayWith]="formatLabel" formControlName="technological_scalability" tickInterval="1" min=-1
                        max="20">
                    </mat-slider>
                    20
                </td>
            </tr>
            <tr>
                <td style="padding: 10px;width: 65%;">Business Fit: Readiness of Business Model for Implementation & Startup Formation</td>
                <td style="padding: 10px;">
                  Score
                    <mat-slider style="
                width: 303px;
            " thumbLabel [displayWith]="formatLabel" formControlName="business_model" tickInterval="1"
                        min=-1 max="20">
                    </mat-slider>
                    20
                </td>
            </tr>

            <tr>
                <td style="text-align:center;"><b>TOTAL</b></td>
                <td style="text-align:center;">{{this.sum}}</td>
            </tr>

        </tbody>
    </table>

	</form>
</div>
