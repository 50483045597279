<mat-dialog-content class="assignEvalutDialog mat-typography">
    <div class="container">
        <h3 class="ccHeadingMForAllDolouge" style="margin-bottom:10px !important;">Assign Verifier For</h3>
        <div class="row">
            <div class="col-md-12">
              <h4 style="margin-bottom:15px; font-size: 20px; margin-bottom: 20px !important; text-align: center;">{{data.idea_title}}</h4>
			</div>
              <form [formGroup]="ideaForm" (ngSubmit)="onSubmit(ideaForm.value)" class="irEvaluFor">
                <div class="row btmLine">
                    <div class="col-md-12">
                      <mat-form-field class="col-md-12 example-full-width" appearance="outline">
                        <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
                        <mat-label>Assign Roles</mat-label>

                        <mat-select formControlName="evaluators" multiple>
                          <mat-option *ngFor="let role of evalList" [value]="role.id">
                            {{role.first_name}}
                          </mat-option>
                        </mat-select>

                      </mat-form-field>
                    </div>
                </div>

                <div mat-dialog-actions align="end" class="mt-3 irEvaluaFor">
                    <button type="button" mat-raised-button color="warn" (click)="closeDialog()"
                        class="btn btn-danger instituteBtnClose">
                        Close
                    </button>
                    <button type="submit" mat-raised-button class="btn btn-success" color="primary">
                        Submit
                    </button>
                </div>
            </form>



        </div>
    </div>
</mat-dialog-content>
