import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-institute-download',
  templateUrl: './institute-download.component.html',
  styleUrls: ['./institute-download.component.css']
})
export class InstituteDownloadComponent implements OnInit {
  downloads: any[];
  MentorMandateStatus:any;
  conscentStatus:any;
  funded_by:any;
  usertype:any;
  userIdFile:any;
  ImpactGranted:any;
  il_mandate:any;
  mm_mandate:any;
  isEnable:number=0;
base="https://iic.mic.gov.in/";
  constructor(   private activityService: InstituteActivityService, private dashboardService: InstituteDashboardService,) { }

  ngOnInit(): void {
    this.getData();
  }
  getData(){

    this.dashboardService.getDownloadsData().subscribe(
      data => this.setDownloadsData(data)
    );

    this.activityService.getmentorMenteeConsentStatus().subscribe(
      data => this.setmentorMenteeConsentStatus(data)
    );

  }

  setDownloadsData(data){
    this.downloads=[];
    for(let i=0;i<data.length;i++){
      this.downloads.push(data[i])
    }
  }

  setmentorMenteeConsentStatus(data){
    this.conscentStatus=data['data']['0']['consent_status'];
    this.funded_by = data['data']['0']['funded_by'];
    this.usertype = data['data']['0']['type'];
    this.userIdFile = data['data']['0']['userIdFile'];

    this.il_mandate = data['il_mandate'];
    this.mm_mandate = data['mm_mandate'];
    this.ImpactGranted = data['ImpactGranted'];
  }

  download(type){
    this.activityService.downloadLetter(type).subscribe(
      data => this.downloadFile(data, 'Sanction Letter.pdf')
    )
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }
}
