import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-all-activity-scheduled',
  templateUrl: './all-activity-scheduled.component.html',
  styleUrls: ['./all-activity-scheduled.component.css']
})
export class AllActivityScheduledComponent implements OnInit {
  admin: boolean = false;
  constructor(private myCouncilService: InstituteMyCouncilService) { }

  ngOnInit(): void {
    this.myCouncilService.getUser().subscribe((data) => {
      this.admin = data['institute_id'] === 'IC201810001';
    });
  }

}
