import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteAnnouncementService } from 'src/app/Services/institute-announcement.service';

@Component({
  selector: 'app-iic-rating2023-dialog',
  templateUrl: './iic-rating2023-dialog.component.html',
  styleUrls: ['./iic-rating2023-dialog.component.css']
})
export class IicRating2023DialogComponent implements OnInit {

  type:any;
  instituteType: any;
  instituteTypeId: any;
  state: any;
  instituteCourses: any;
  changeData: any;
  currentPageNo: number = 1;
  total_pages: number;
  firstPage = true;
  lastPage: boolean = false;
  theme: any[];
  data1: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  frontCurrentPageNo: number = 0;

  title = 'Angular Charts';
  view: any[] = [250, 300];
  view2: any[] = [800, 300];
  public showLegend = true;
  public gradient = true;
  public colorScheme = {
    domain: ['#586B7D', '#5F9EDA', '#9E9492', '#0096A6', '#F47B00', '#606060']
  };
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;
  public trimLabels = false;
  public showPieLegend = false;
  public legendPosition: string = 'below';

  //bar
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public barGradient = false;
  public showBarLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Academic Year';
  public xAxisLabel1 = 'Themes';
  public showYAxisLabel = false;
  public yAxisLabel = 'Rating';
  public yAxisLabel1 = 'Verified';
  public barColorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#91d494', '#0096A6', '#F47B00', '#606060']
  };
  public autoScale = true;

  //Stacked bar
  public multi2: any[];
  public xAxisStackedLabel = 'Events';
  public showYAxisStackedLabel = true;
  public yAxisStackedLabel = 'Participants';
  public stackedColorScheme = {
    domain: ['#378D3B', '#0096A6']
  };

  public single = [
    {
      "name": "China",
      "value": 2243772
    },
    {
      "name": "USA",
      "value": 1126000
    },
    {
      "name": "Norway",
      "value": 296215
    },
    {
      "name": "Japan",
      "value": 257363
    },
    {
      "name": "Germany",
      "value": 196750
    },
    {
      "name": "France",
      "value": 204617
    }
  ];

  legendTitle = 'Legend';
  legendPosition2 = 'right';

  xAxisLabel2 = 'Country';
  showYAxisLabel2 = true;
  yAxisLabel2 = 'GDP Per Capita';
  showGridLines = true;
  innerPadding = '10%';
  animations: boolean = true;
  barChart: any[];
  lineChartSeries: any[];
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = 'Utilization';

  public ratingChart1: any[];
  public evThemeChart1: any[];
  public evThemeChart2: any[];
  public evmixdata: any[];
  userId:any;

  // options
  showXAxis3: boolean = true;
  showYAxis3: boolean = true;
  gradient3: boolean = true;
  showLegend3: boolean = false;
  showXAxisLabel3: boolean = true;
  xAxisLabel3: string = 'Theme';
  showYAxisLabel3: boolean = true;
  yAxisLabel3: string = 'No. of Activities';
  legendTitle3: string = 'Years';

  colorScheme3 = {
    domain: ['#2F3E9E', '#D22E2E', '#91d494', '#0096A6', '#F47B00', '#606060']
  };

  constructor(private fb: FormBuilder,
    private activityService: InstituteActivityService,
     public dialog: MatDialog,
     private instituteannouncementservice: InstituteAnnouncementService,
     private innovationService: InstituteInnovationContestService,
   private toastr: ToastrService, private route: ActivatedRoute,
   private router: Router, public dialogRef: MatDialogRef<IicRating2023DialogComponent>,
   @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
	    this.userId=data
   }

  ngOnInit(): void {

    this.instituteannouncementservice.getinstituteRating2023(this.userId)
      .subscribe((data) => this.handleResponse1(data));

  }

  handleResponse1(data)
  {

    this.ratingChart1   = data['rating'];
    this.evThemeChart1  = data['evtheme'];
	  this.evThemeChart2  = data['evtheme_top'];
    this.evmixdata      = data['evmixdata'];

    // Object.assign(this.ratingChart1);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
