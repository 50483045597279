import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { NominationsDialogComponent } from './nominations-dialog/nominations-dialog.component';
import { StatuscountdialogComponent } from '../statuscountdialog/statuscountdialog.component';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-nominations',
  templateUrl: './nominations.component.html',
  styleUrls: ['./nominations.component.css']
})

 
export class NominationsComponent implements OnInit {
k=1;
l=1;
  checkFlag: boolean;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  nomination = [];
  nominationArray = [];
  nominationArray2 = [];
  nominationArray3 = [];
  nominationArray4 = [];
  nominationStudentArray = [];
  nominationStudentArray2 = [];
  nominationStudentArray3 = [];
  nominationStudentArray4 = [];
  acceptedNomination = [];
  count: any;
  frontCurrentPageNo: number = 0;
  total_pages: number;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  nomination_type: any;
  teacher_type: any;
  faculty_type:any;
  public nominationForm: FormGroup;
  teacher: any[];
  id: any;
  delete_id: any;
  edit_id:any;
  basicCount: any;
  advanceCount: any;

  mySelections: string[];
  showbasic: number;
  showdiasablebuttton: boolean;
  fac_type: any;
  showadvance: number;
  showStatus: any;
  concatfaculty:any;
  type_id:any;
  ForWhom :any;
  
  constructor(private fb: FormBuilder, 
    private activityService: InstituteActivityService,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialog: MatDialog,) {
      this.getNomination();
      this.eligibility();
      
    }
    

  ngOnInit(): void {
    this.ForWhom=localStorage.getItem('ForWhom');
    

    this.innovationService.getNominatedIAUpdated('1').subscribe(
      data => this.handleResponseBasic(data)
    );

    // this.innovationService.getNominatedIAUpdated('3').subscribe(
    //   data => this.handleResponseUpskill(data)
    // );

    // this.innovationService.getNominatedIAUpdated('4').subscribe(
    //   data => this.handleResponseReskill(data)
    // );

    //16-8-24
    this.innovationService.getStudentNominatedIAUpdated('2').subscribe(
      data => this.handleStudentResponseAdvance(data)
    );

    this.innovationService.getStudentNominatedIAUpdated('1').subscribe(
      data => this.handleStudentResponseBasic(data)
    );

    this.innovationService.getStudentNominatedIAUpdated('3').subscribe(
      data => this.handleStudentResponseUpskill(data)
    );

    this.innovationService.getStudentNominatedIAUpdated('4').subscribe(
      data => this.handleStudentResponseReskill(data)
    );
    //end 16-8-24

  }

  
openDialogfacultyCount(status)
{
  const dialogRef = this.dialog.open(StatuscountdialogComponent, {
    data: { status },
  });

  dialogRef.afterClosed().subscribe((result) => { });
}
  eligibility() {
    this.activityService.getHOI().subscribe(
      data => this.instituteData(data)
    );
  }

  instituteData(data) {
    if (data) {
      var institute_id = data['hoiDetails']['institute_id'];
      this.activityService.checkActivityButton(institute_id).subscribe(
        data => this.showActivityButton(data)
      );
    }
  }
  showActivityButton(data){
    this.showStatus=data['status'];
    // this.showStatus=0;
  }

  getCount(){
    this.innovationService.getNominateCount().subscribe(
      data => this.setNominateData(data)
    );
  }

  setNominateData(data){
    this.basicCount=data['data']['basic_count'];
    this.advanceCount=data['data']['advance_count'];

    this.showbasic=Number(10 - this.basicCount);
    this.showadvance=Number(20-this.advanceCount)
  }

  getFacultyName(data) {
    if(data==1)
    {
      this.innovationService.getBasicFaculties().subscribe(
      data => this.setBasicData(data)
    );
  }
  // if(data==2){
  //   this.innovationService.getAdvancedFaculties().subscribe(
  //     data => this.setBasicData(data)
  //   );
  // }
  }

  setBasicData(data) {
    this.teacher=[];
    for(let i=0;i<data.length;i++){
      this.teacher.push(data[i])
    }
  }

  handleResponse1(data){
    if(data['message']=="Success"){
      this.toastr.success(data['comment'])
      this.getNomination();
      this.getCount();
      //document.location.href='/institute/nomination';

    }
    else{
      this.toastr.error(data['message'])
    }
  }

  getNomination() {
    this.innovationService.getNominatedIA().subscribe(
      data => this.handleResponse(data)
    );
  }



  onDeleteClick(data,type) {
    this.delete_id = data;
    this.type_id = type;

    if (confirm("Are you sure you want to delete?")) {
      this.innovationService.DeleteFaculty(this.delete_id, this.type_id).subscribe(
        data => this.handleResponse1(data)
      );    }
  }

  onEditClick(data){
    const dialogRef = this.dialog.open(NominationsDialogComponent, {
      data:data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getFaculties();

    });
  }

  getFaculties(){
    
  }
  // nextPage(e: any) {
  //   if (this.per_page != e.pageSize) {

  //     this.currentPageNo = (e.pageIndex + 1);
  //   } else {
  //     this.currentPageNo = (e.pageIndex + 1);
  //   }
  //   this.per_page = e.pageSize;
  //   this.getNomination();
  // }

  // previousPage() {
  //   this.currentPageNo--;
  //   this.getNomination();
  // }

  
  handleResponse(data) {

    // this.nominationArray = [];
    // this.nominationArray2 = [];
    // for(let i=0;i<data['data'].length;i++){

    //   if(data['data'][i].training_type == 1){
    //     this.nominationArray.push(data['data'][i])
    //   }
    //   else {
    //     this.nominationArray2.push(data['data'][i])
    //   }
    // }
    // data['data'].forEach((element) => {
    //   this.nominationArray.push(element);
    // });
  }
upskillingdata(type,ia_id){
  this.innovationService.getNominatedIAUpdated('2').subscribe(
    data => this.handleResponseBasic2(data)
    // data => this.handleResponseAdvance(data)
  );
}
  handleResponseAdvance(data) {
    
    this.nominationArray2 = data['data'];
  }

  handleResponseBasic(data) {
    this.nominationArray = data['data'];
  }
  handleResponseBasic2(data) {
    this.nominationArray2 = data['data'];
  }

  handleResponseUpskill(data) {
    this.nominationArray3 = data['data'];
  }
  handleResponseReskill(data) {
    this.nominationArray4 = data['data'];
  }

  //16-8-24
  handleStudentResponseAdvance(data) {
    
    this.nominationStudentArray2 = data['data'];
  }

  handleStudentResponseBasic(data) {
    this.nominationStudentArray = data['data'];
  }

  handleStudentResponseUpskill(data) {
    this.nominationStudentArray3 = data['data'];
  }
  handleStudentResponseReskill(data) {
    this.nominationStudentArray4 = data['data'];
  }
  // upskillingdata(type,id) 
  // {
  //   var flist = $filter('filter')(this.nominationStudentArray2, { ID: id }, true);
  //   this.nominationArray2.forEach(obj => {
  //     obj.forEach(childObj=> {
  //       id = parentChecked;
  //    });
  // });
  // }
//end 16-8-24
}
