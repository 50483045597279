<div class="container">

    <div class="m-4 row">
        <div class="col-8">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog()">
                <mat-icon>add</mat-icon>Add Social Media Details
            </button>
        </div>
    </div>
    <div class="row m-4">

        <div class="col-md-6" *ngFor="let faculty of Social;let i = index" style="margin: 0 auto;">
            <div class="CardBox">
                <img src="assets/customCard/card-gradient-img.png" class="CardBoxContentTopImg">
                <img src="assets/customCard/card-top-banner.png"
                    class="CardBoxContentTopBanner CardBoxContentTopBannersocialMedia">
                <div class="CardBoxContentTopName CardBoxContentTopNameSocialMedia" *ngIf="faculty.totalStudentCount">
                    <h3 class="totalStuSocialInsti">Social Media Details</h3>
                    <!-- <p class="totalStuInstiCount">{{faculty?.totalStudentCount}}</p> -->
                </div>
				
				<div class="CardBoxContent" *ngIf="faculty.facebookLink">
                    <h3>Facebook Link</h3>
                    <p>{{faculty?.facebookLink}}</p>
                </div>
				
				<div class="CardBoxContent" *ngIf="faculty.instagramLink">
                        <h3>Instagram Link</h3>
                        <p>{{faculty?.instagramLink}}</p>
                    </div>

                    <div class="CardBoxContent" *ngIf="faculty.twitterLink">
                        <h3>Twitter Link</h3>
                        <p>{{faculty?.twitterLink}}</p>
                    </div>
				
                <div class="CardBoxContent" *ngIf="faculty.totalFacultyCount">
                    <h3>Total Faculty</h3>
                    <p>{{faculty?.totalFacultyCount}}</p>
                </div>
				
				<div class="row">
					<div class="col-md-6">
						<div class="CardBoxContent">
							<h3>Male</h3>
							<p>{{faculty?.male}}</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="CardBoxContent">
							<h3>Female</h3>
							<p>{{faculty?.female}}</p>
						</div>
					</div>
				</div>
				
                

               <!-- <div class="CardBoxContent">
                    <h3>Boys</h3>
                    <p>{{faculty?.boys}}</p>
                </div>

                <div class="CardBoxContent">
                    <h3>Girls</h3>
                    <p>{{faculty?.girls}}</p>
                </div>

                <div class="CardBoxContent">
                    <h3>Male</h3>
                    <p>{{faculty?.male}}</p>
                </div>

                <div class="CardBoxContent">
                    <h3>Female</h3>
                    <p>{{faculty?.female}}</p>
                </div> -->

                <div id="InstSocialID" class="collapse">
					<div class="CardBoxContent" *ngIf="faculty.totalFacultyCount">
                    <h3>Total Students</h3>
                    <p>{{faculty?.totalStudentCount}}</p>
                </div>
				
				<div class="row">
					<div class="col-md-6">
						<div class="CardBoxContent">
							<h3>Boys</h3>
							<p>{{faculty?.boys}}</p>
						</div>
					</div>
					<div class="col-md-6">
						<div class="CardBoxContent">
							<h3>Girls</h3>
							<p>{{faculty?.girls}}</p>
						</div>
					</div>
				</div>
                    

                    <div class="CardBoxContent" style="text-align: center !important">
                        <img style="max-height: 400px !important; max-width: 400px !important"
                            src="{{ faculty?.collegeLogo }}" alt="Social Media Logo">
                    </div>

                </div>

                <div class="cardReadMore">

                  <button data-toggle="collapse" data-target="#InstSocialID" *ngIf="readMore"
                    (click)="readMore=!readMore">Read More</button>
                  <button data-toggle="collapse" data-target="#InstSocialID" *ngIf="!readMore"
                    (click)="readMore=!readMore">Read Less</button>

                </div>

            </div>
        </div>


    </div>


    <div *ngIf="Social.length<1">
        <div class="card text-center text-white bg-info mb-4 card-shadow">
            <div class="card-body">
                <p class="card-text text-white"><i>No account added yet!</i></p>
            </div>
        </div>
    </div>
    <!-- </div> -->

</div>
