import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../../app/app.component';
@Component({
  selector: 'app-iflts-upcoming',
  templateUrl: './iflts-upcoming.component.html',
  styleUrls: ['./iflts-upcoming.component.css']
})
export class IfltsUpcomingComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
