import { Component, OnInit } from '@angular/core';
import { InstituteDashboard1819Service } from 'src/app/Services/institute-dashboard1819.service';
import * as myGlobals from '../../../../../app.component';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-dashboard1819-activity-list',
  templateUrl: './dashboard1819-activity-list.component.html',
  styleUrls: ['./dashboard1819-activity-list.component.css']
})
export class Dashboard1819ActivityListComponent implements OnInit {
  activity: any;
  page: any;
  baseUrl = myGlobals.baseUrl;

  constructor(private dashboardService: InstituteDashboard1819Service,) {
    dashboardService.getActivityList().subscribe(
      data => {
        this.activity = data
      }
    )
  }

  public onPageChanged(event) {
    this.page = event;
    this.activity;
  }

  getActivityReport(eventId) {
    this.dashboardService.getActivityReport(eventId).subscribe(
      data => this.downloadFile(data, 'ActivityReport.pdf')
    )
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }

  ngOnInit(): void {
  }

}
