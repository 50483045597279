<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">e-Learning Resource Repository</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>


<div class="container" style="padding-top: 50px;">
  <div class="row">
    <form [formGroup]="innovationCourse"
      (ngSubmit)="onSubmitCourse(innovationCourse.value)"
      class="formIICInnoFaculty ng-pristine ng-valid ng-touched" novalidate="">
      <div class="col-md-3">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-label>Keywords</mat-label>
          <input matInput formControlName="instituteName" placeholder="Keywords">
          <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
      </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="ratingDetails1 example-full-width" appearance="outline"
          style="margin: auto;">
          <!-- <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon> -->
          <mat-label>Theme</mat-label>
          <mat-select placeholder="Theme"
            formControlName="instituteCourses">
            <mat-option value="">All</mat-option>
            <mat-option value="{{theme.name}}" *ngFor="let theme of programTheme">{{theme.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="ratingDetails1 example-full-width" appearance="outline"
          style="margin: auto;">
          <mat-label>Select State</mat-label>
          <mat-select formControlName="courseState" [(ngModel)]="StateModel2">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let stateItems of state"
              [value]="stateItems.id">
              {{stateItems.stateName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <button _ngcontent-pfa-c3="" class="sub-but" id="submitCourse"
            name="SubmitCourse" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>

<section class="ratingsTable">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="tab-box" id="newContentIaEventReport">
					<div class="dataTables_wrapper no-footer" id="myTable_ia_wrapper">
						<table matSort (matSortChange)="sortData($event)" class="table table-bordered dataTable no-footer newTablePagiIncub table-responsive" style="color: #000;" role="grid" aria-describedby="myTable_ia_info">
			<thead>
			  <tr role="row">
				  <th id="field_1" aria-sort="decending" mat-sort-header="sr_no" style="width: 74px;">S.No.</th>
				  <th id="field_2" aria-sort="decending" mat-sort-header="userId" (click)="rwsShortData('userId','field_2')" class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 70px;">IIC ID</th>
          <th id="field_3" aria-sort="decending" mat-sort-header="instituteName" (click)="rwsShortData('instituteName','field_3')" class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 70px;">Institute</th>
				  <th id="field_4" aria-sort="decending" mat-sort-header="title" (click)="rwsShortData('title','field_4')" class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 180px;">Session Title</th>
				  <th id="field_5" aria-sort="decending" mat-sort-header="theme" (click)="rwsShortData('theme','field_5')" class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 50px;">Theme</th>
				  <th id="field_6" aria-sort="decending" mat-sort-header="expert_name" (click)="rwsShortData('expert_name','field_6')" class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 60px;">Expert</th>
          <!-- <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 90px;">Organised on</th> -->
          <th id="field_7" aria-sort="decending" mat-sort-header="description" (click)="rwsShortData('description','field_7')" class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 150px;">Description</th>
				  <th id="field_8" aria-sort="decending" mat-sort-header="video" (click)="rwsShortData('video','field_8')" class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 150px;">Session Video</th>
			  </tr>
			</thead>
			<tbody>

        <tr role="row" class="odd" *ngFor="let details of theme; let i = index;">
					<td class="sorting_1">{{(i + 1)+ (10 * (currentPageNo-1))}}</td>
					<td>{{details?.userId}}</td>
          <td>{{details?.instituteName}}</td>
					<td>{{details?.title}}</td>

					<td>{{details?.theme}}</td>
          <td>{{details?.expert_name}}
            <br/>{{details?.expert_email}}
            <br/>{{details?.expert_contact}}
            <br/>{{details?.expert_designation}}
            <br/>{{details?.expert_org}}
          </td>
          <!-- <td>{{details?.organised_on}}</td> -->
          <td>{{details?.description}}</td>
          <td><a href="{{details?.video}}" target="_blank">View</a></td>
				</tr>
				</tbody>
		</table>
					</div>
				</div>
			</div>
			<div class="row">
        <div class="col-md-12" style="margin-top: 0; margin-left: 315px;">
          <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)"
            [length]="total_pages" [pageIndex]="frontCurrentPageNo"
            [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
          </mat-paginator>
        </div>
      </div>
		</div>
	</div>
</section>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>

