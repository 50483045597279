<div class="container">
  <div class="row" color="primary">
    <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Manage Nominated Challenges</h2>
  </div>
	<!-- <div class="card">
    <div class="card-title" style="text-align: center;"><p>Create and Manage Idea/ Innovation/ Startup Challenges at Institute level.</p></div>
	</div> -->
  <div class="refrredBox">
    <div class="row rws-listpage">
	    <div class="col-md-12">
        <!-- <p><a class="btn btn-primary referralBut" routerLink='/institute/yukti-challenges-add/add'>Add New</a></p> -->
        <div class="tableBody">
				  <div class="table-responsive">
            <table class="table table-hover">
              <thead class="facultyTable">
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Title</th>
                  <th scope="col">Application Start & End Date</th>
                  <th scope="col">Challenge Start & End Date</th>
                  <th scope="col">Innovation Title</th>
                  <th scope="col">Innovation Id</th>
                  <th scope="col">Level</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let nomination of yukti_challenges| paginate: { itemsPerPage: 10, currentPage: page }; let i = index;" >
                  <td>{{i+1}}</td>
                  <td>{{nomination?.title}}</td>
                  <td>
                      {{nomination?.submission_start_date}}
                      <br/>
                      {{nomination?.submission_end_date}}
                  </td>
                  <td>{{nomination?.event_start_date}}<br/>{{nomination?.event_end_date}}</td>
                  
                  <td> {{nomination?.idea_title}}  </td>
                  <td> {{nomination?.team_id}}  </td>
                  <td> {{nomination?.submission_type}}  </td>
                  <td>   
                    <p *ngIf="nomination?.status!=1"><button mat-raised-button (click)="onClickEvent(nomination?.id)"   color="warn" class="btn-tbl-delete">
                      <mat-icon>delete</mat-icon>
                      </button></p>
                  </td>
                </tr>
                <tr *ngIf="yukti_challenges?.length== 0" col="7">
                    <h3 style="text-align: center">No Data Found</h3>
                </tr>
              </tbody>
            </table>
            <pagination-controls class="annular-pagination float-right" maxSize="3"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>