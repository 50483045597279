<mat-dialog-content class="mat-typography">
    <div class="container">
        <h3 class="ccHeadingMForAllDolouge">Idea Submission</h3>
        <div class="row">
            <div class="col-md-12">
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Title</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.idea_title }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Theme Name</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.theamName }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Description</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.description }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Institute Name</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.instituteName }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Team Lead Name</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.team_lead_name }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Team Lead Contact</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.team_lead_phone }}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Team Lead Contact</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.team_lead_phone }}</p>
                    </div>
                </div>
                <div *ngIf="ideaScoreFlag" class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Idea Score</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.team_lead_score }}</p>
                    </div>
                </div>

                <div *ngIf="data?.comment" class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Comment</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.comment }}</p>
                    </div>
                </div>

                <div class="row btmLine">
                    <div class="col-md-4">
                        <p class="anaTitle">Status</p>
                    </div>
                    <div class="col-md-8">
                        <p class="anaContent">{{ data?.status }}</p>
                    </div>
                </div>
                <form [formGroup]="ideaForm" (ngSubmit)="onSubmit(ideaForm.value)">
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                                <mat-label>Idea Score</mat-label>
                                <input matInput type="number" maxlength="4" formControlName="score"
                                    placeholder="Idea Score" (keypress)="isNumberKey($event)" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                                <mat-label>Comment</mat-label>
                                <input matInput formControlName="comment" placeholder="Comment" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="status" required>
                                    <mat-option value="Approved">Approved</mat-option>
                                    <mat-option value="Disapproved">Disapproved</mat-option>
                                    <mat-option value="Pending">Pending</mat-option>
                                </mat-select>
                                <mat-error *ngIf="ideaForm.controls['status'].hasError('required')">Status is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div mat-dialog-actions align="end" class="mt-3">
                        <button type="button" mat-raised-button color="warn" (click)="closeDialog()"
                            class="btn btn-danger instituteBtnClose">
                            Close
                        </button>
                        <button type="submit" mat-raised-button class="btn btn-success float-right" color="primary"
                            [disabled]="ideaForm.invalid">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</mat-dialog-content>