<div class="row" style="padding-top: 10px;">
  <div class="col-md-12">
    <h2 class="actionPlanHeading">Progress Monitoring & Impact Measurement</h2>
  </div>
    <div class="col-sm-12">
      <p>Conduct a visit to the mentee institute for progress monitoring cum feedback/mentoring sessions in each mentee institute.</p>
        <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline3" role="button" aria-expanded="false" aria-controls="rws-guideline3">View Guidlines</a></p>
        <div class="collapse multi-collapse" id="rws-guideline3">
          <div class="card card-body">
            <ul>
              <li>One key functionary of IIC at the mentor institute shall make a one-day visit to the campus of</li>

              <li>mentee institute to observe the facility and functional process of IIC and conduct round table discussion with the IIC members.</li>

              <li>Mentor expert will check the progress and provide feedback and on spot mentoring support to the mentee institute on improvisation of I&E ecosystem, strategies and how to improve the score and star of the mentee institute.</li>

              <li>Mentor institute will take care of the TA and honorarium of mentor experts whereas mentee institute will provide local stay and organize the round table meeting for progress monitoring cum feedback/mentoring.</li>
            </ul>
          </div>
        </div>
    </div>
    <!-- <div class="col-sm-2"> -->
      <!-- <p><button mat-raised-button (click)="openDialog(5)" [disabled]='!this.activity5' color="primary">Schedule</button></p> -->
      <!-- <p *ngIf="RQ_5_file==''"><button mat-raised-button (click)="openReportDialog(5)" color="primary">Upload Report</button></p>
        <p *ngIf="RQ_5_file!=''"><button mat-raised-button (click)="openReportDialog(5)" color="primary">Update Report</button></p>
        <p *ngIf="RQ_5_file!=''"><a target="_blank" href="{{ baseUrl + RQ_5_file}}">View File</a></p>
    </div> -->
  </div>

  <form [formGroup]="addActivityForm5" (submit)="onSubmitForm5(addActivityForm5.value)">

    <table class="table table-hover">
      <thead class="facultyTable">
          <tr>
              <th scope="col" style="width: 2%;"> Sr.No.</th>
              <th scope="col">Mentee Institute Name</th>
              <th scope="col" style="width: 13%;">Date</th>
              <th scope="col" style="width: 12%;">Time</th>
              <th scope="col" style="width: 15%;">Nominee from Mentor Institute</th>
            <th scope="col">Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngIf="TMentee>=1">
              <td >1</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_1_5_1" required readonly>
                  <mat-error *ngIf="addActivityForm5.controls.mentee_1_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[0].instituteName}}</p>
                <!-- <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[0].mentee_type}}</p> -->
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker_5_1" formControlName="date_1_5_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker_5_1"></mat-datepicker-toggle>
                    <mat-datepicker #picker_5_1></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker_5_2" formControlName="time_1_5_1" >
                <ngx-material-timepicker #picker_5_2 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_1_5_1"  required >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[0].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p *ngIf="schedules5.guest_name_1_5_1!=''">{{schedules5.guest_name_1_5_1}}</p>
              </td>

              <td>
                <button *ngIf="schedules5.date_1_5_1==''"
                mat-raised-button color="primary" type="submit" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules5.date_1_5_1!=''"
                mat-raised-button color="primary" type="submit" class="mentorButEdit">Edit</button>

              <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

                 <p *ngIf="schedules5?.reportF_1_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[0].mentee_userId)" color="primary">Upload Report</button></p>

                  <p *ngIf="schedules5?.reportF_1_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[0].mentee_userId)" color="primary">Update Report</button></p>

                  <p *ngIf="schedules5?.reportF_1_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_1_5_1}}">View File</a></p>

              </div>

              </td>

          </tr>

          <tr *ngIf="TMentee>=2">
            <td >2</td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Mentee Institute Name</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input matInput placeholder="Mentee Institute Name" formControlName="mentee_2_5_1" required readonly>
                <mat-error *ngIf="addActivityForm5.controls.mentee_2_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
              </mat-form-field>
              <p style="font-size: 14px;" class="menteenamedata">{{Mentee[1].instituteName}}</p>
              <!-- <p style="font-size: 14px;" class="menteeTypeData">
                Funded By: {{Mentee[1].mentee_type}}</p> -->
            </td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Tentative date</mat-label>
                  <input matInput readonly [matDatepicker]="picker_5_21" formControlName="date_2_5_1" [min]="minDate" [max]="maxDate" >
                  <mat-datepicker-toggle matSuffix [for]="picker_5_21"></mat-datepicker-toggle>
                  <mat-datepicker #picker_5_21></mat-datepicker>
              </mat-form-field>
            </td>
            <td>

              <mat-label>Tentative time</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input readonly [ngxTimepicker]="picker_5_22" formControlName="time_2_5_1" >
              <ngx-material-timepicker #picker_5_22 placeholder="Session Time"  ></ngx-material-timepicker>

            </td>
            <td>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select</mat-label>
                <mat-select autocomplete="off" placeholder="Select" formControlName="guest_2_5_1" >
                  <mat-option>Select</mat-option>
                  <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[1].menteeFaculty">{{guest.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <p *ngIf="schedules5.guest_name_2_5_1!=''">{{schedules5.guest_name_2_5_1}}</p>
            </td>

            <td>
              <button *ngIf="schedules5.date_2_5_1==''"
              mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
              <button *ngIf="schedules5.date_2_5_1!=''"
              mat-raised-button color="primary" class="mentorButEdit">Edit</button>


              <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

                <p *ngIf="schedules5?.reportF_2_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[1].mentee_userId)" color="primary">Upload Report</button></p>

                 <p *ngIf="schedules5?.reportF_2_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[1].mentee_userId)" color="primary">Update Report</button></p>

                 <p *ngIf="schedules5?.reportF_2_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_2_5_1}}">View File</a></p>

             </div>


            </td>
        </tr>


  <tr *ngIf="TMentee>=3">
            <td >3</td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Mentee Institute Name</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input matInput placeholder="Mentee Institute Name" formControlName="mentee_3_5_1" required readonly>
                <mat-error *ngIf="addActivityForm5.controls.mentee_3_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
              </mat-form-field>
              <p style="font-size: 14px;" class="menteenamedata">{{Mentee[2].instituteName}}</p>
              <!-- <p style="font-size: 14px;" class="menteeTypeData">
                Funded By: {{Mentee[2].mentee_type}}</p> -->
            </td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Tentative date</mat-label>
                  <input matInput readonly [matDatepicker]="picker_5_31" formControlName="date_3_5_1" [min]="minDate" [max]="maxDate" >
                  <mat-datepicker-toggle matSuffix [for]="picker_5_31"></mat-datepicker-toggle>
                  <mat-datepicker #picker_5_31></mat-datepicker>
              </mat-form-field>
            </td>
            <td>

              <mat-label>Tentative time</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input readonly [ngxTimepicker]="picker_5_32" formControlName="time_3_5_1" >
              <ngx-material-timepicker #picker_5_32 placeholder="Session Time"  ></ngx-material-timepicker>

            </td>
            <td>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select</mat-label>
                <mat-select autocomplete="off" placeholder="Select" formControlName="guest_3_5_1"  >
                  <mat-option>Select</mat-option>
                  <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[2].menteeFaculty">{{guest.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <p *ngIf="schedules5.guest_name_3_5_1!=''">{{schedules5.guest_name_3_5_1}}</p>
            </td>
            <td>
              <button *ngIf="schedules5.date_3_5_1==''"
              mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
              <button *ngIf="schedules5.date_3_5_1!=''"
              mat-raised-button color="primary" class="mentorButEdit">Edit</button>

              <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

                <p *ngIf="schedules5?.reportF_3_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[2].mentee_userId)" color="primary">Upload Report</button></p>

                 <p *ngIf="schedules5?.reportF_3_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[2].mentee_userId)" color="primary">Update Report</button></p>

                 <p *ngIf="schedules5?.reportF_3_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_3_5_1}}">View File</a></p>

             </div>

            </td>
        </tr>
            <tr *ngIf="TMentee>=4">
            <td >4</td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Mentee Institute Name</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input matInput placeholder="Mentee Institute Name" formControlName="mentee_4_5_1" required readonly>
                <mat-error *ngIf="addActivityForm5.controls.mentee_4_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
              </mat-form-field>
              <p style="font-size: 14px;" class="menteenamedata">{{Mentee[3].instituteName}}</p>
              <!-- <p style="font-size: 14px;" class="menteeTypeData">
                Funded By: {{Mentee[3].mentee_type}}</p> -->
            </td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Tentative date</mat-label>
                  <input matInput readonly [matDatepicker]="picker_5_41" formControlName="date_4_5_1" [min]="minDate" [max]="maxDate" >
                  <mat-datepicker-toggle matSuffix [for]="picker_5_41"></mat-datepicker-toggle>
                  <mat-datepicker #picker_5_41></mat-datepicker>
              </mat-form-field>
            </td>
            <td>

              <mat-label>Tentative time</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input readonly [ngxTimepicker]="picker_5_42" formControlName="time_4_5_1" >
              <ngx-material-timepicker #picker_5_42 placeholder="Session Time"  ></ngx-material-timepicker>

            </td>
            <td>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select</mat-label>
                <mat-select autocomplete="off" placeholder="Select" formControlName="guest_4_5_1"  >
                  <mat-option>Select</mat-option>
                  <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[3].menteeFaculty">{{guest.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <p *ngIf="schedules5.guest_name_4_5_1!=''">{{schedules5.guest_name_4_5_1}}</p>
            </td>
            <td>
              <button *ngIf="schedules5.date_4_5_1==''"
              mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
              <button *ngIf="schedules5.date_4_5_1!=''"
              mat-raised-button color="primary" class="mentorButEdit">Edit</button>

              <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

                <p *ngIf="schedules5?.reportF_4_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[3].mentee_userId)" color="primary">Upload Report</button></p>

                 <p *ngIf="schedules5?.reportF_4_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[3].mentee_userId)" color="primary">Update Report</button></p>

                 <p *ngIf="schedules5?.reportF_4_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_4_5_1}}">View File</a></p>

              </div>
            </td>
        </tr>

  <tr  *ngIf="TMentee>=5">
            <td >5</td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Mentee Institute Name</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input matInput placeholder="Mentee Institute Name" formControlName="mentee_5_5_1" required readonly>
                <mat-error *ngIf="addActivityForm5.controls.mentee_5_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
              </mat-form-field>
              <p style="font-size: 14px;" class="menteenamedata">{{Mentee[4].instituteName}}</p>
              <!-- <p style="font-size: 14px;" class="menteeTypeData">
                Funded By: {{Mentee[4].mentee_type}}</p> -->
            </td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Tentative date</mat-label>
                  <input matInput readonly [matDatepicker]="picker_5_51" formControlName="date_5_5_1" [min]="minDate" [max]="maxDate" >
                  <mat-datepicker-toggle matSuffix [for]="picker_5_51"></mat-datepicker-toggle>
                  <mat-datepicker #picker_5_51></mat-datepicker>
              </mat-form-field>
            </td>
            <td>

              <mat-label>Tentative time</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input readonly [ngxTimepicker]="picker_5_52" formControlName="time_5_5_1" >
              <ngx-material-timepicker #picker_5_52 placeholder="Session Time"  ></ngx-material-timepicker>

            </td>
            <td>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select</mat-label>
                <mat-select autocomplete="off" placeholder="Select" formControlName="guest_5_5_1"  >
                  <mat-option>Select</mat-option>
                  <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[4].menteeFaculty">{{guest.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <p *ngIf="schedules5.guest_name_5_5_1!=''">{{schedules5.guest_name_5_5_1}}</p>
            </td>
            <td>
              <button *ngIf="schedules5.date_5_5_1==''"
              mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
              <button *ngIf="schedules5.date_5_5_1!=''"
              mat-raised-button color="primary" class="mentorButEdit">Edit</button>

              <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

                <p *ngIf="schedules5?.reportF_5_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[4].mentee_userId)" color="primary">Upload Report</button></p>

                 <p *ngIf="schedules5?.reportF_5_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[4].mentee_userId)" color="primary">Update Report</button></p>

                 <p *ngIf="schedules5?.reportF_5_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_5_5_1}}">View File</a></p>

             </div>

            </td>
        </tr>


        <tr  *ngIf="TMentee>=6">
          <td >6</td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
              <mat-label>Mentee Institute Name</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input matInput placeholder="Mentee Institute Name" formControlName="mentee_6_5_1" required value="{{Mentee[5].mentee_userId}}" readonly>
              <mat-error *ngIf="addActivityForm5.controls.mentee_6_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
            </mat-form-field>
            <p style="font-size: 14px;" class="menteenamedata">{{Mentee[5].instituteName}}</p>
            <!-- <p style="font-size: 14px;" class="menteeTypeData">
              Funded By: {{Mentee[5].mentee_type}}</p> -->
          </td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Tentative date</mat-label>
                <input matInput readonly [matDatepicker]="picker4161" formControlName="date_6_5_1" [min]="minDate" [max]="maxDate" >
                <mat-datepicker-toggle matSuffix [for]="picker4161"></mat-datepicker-toggle>
                <mat-datepicker #picker4161></mat-datepicker>
            </mat-form-field>
          </td>
          <td>

            <mat-label>Tentative time</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input readonly [ngxTimepicker]="picker4162" formControlName="time_6_5_1" >
            <ngx-material-timepicker #picker4162 placeholder="Session Time"  ></ngx-material-timepicker>

          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select</mat-label>
              <mat-select autocomplete="off" placeholder="Select" formControlName="guest_6_5_1"  >
                <mat-option>Select</mat-option>
                <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[5].menteeFaculty">{{guest.name}}</mat-option>
              </mat-select>
          </mat-form-field>
          <p *ngIf="schedules5.guest_name_6_5_1!=''">{{schedules5.guest_name_6_5_1}}</p>
          </td>
          <td>
            <button *ngIf="schedules5.date_6_5_1==''"
            mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
            <button *ngIf="schedules5.date_6_5_1!=''"
            mat-raised-button color="primary" class="mentorButEdit">Edit</button>

            <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

              <p *ngIf="schedules5?.reportF_6_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[5].mentee_userId)" color="primary">Upload Report</button></p>

               <p *ngIf="schedules5?.reportF_6_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[5].mentee_userId)" color="primary">Update Report</button></p>

               <p *ngIf="schedules5?.reportF_6_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_6_5_1}}">View File</a></p>

           </div>

          </td>
      </tr>

<tr  *ngIf="TMentee>=7">
          <td >7</td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
              <mat-label>Mentee Institute Name</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input matInput placeholder="Mentee Institute Name" formControlName="mentee_7_5_1" required value="{{Mentee[6].mentee_userId}}" readonly>
              <mat-error *ngIf="addActivityForm5.controls.mentee_7_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
            </mat-form-field>
            <p style="font-size: 14px;" class="menteenamedata">{{Mentee[6].instituteName}}</p>
            <!-- <p style="font-size: 14px;" class="menteeTypeData">
              Funded By: {{Mentee[6].mentee_type}}</p> -->
          </td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Tentative date</mat-label>
                <input matInput readonly [matDatepicker]="picker4171" formControlName="date_7_5_1" [min]="minDate" [max]="maxDate" >
                <mat-datepicker-toggle matSuffix [for]="picker4171"></mat-datepicker-toggle>
                <mat-datepicker #picker4171></mat-datepicker>
            </mat-form-field>
          </td>
          <td>

            <mat-label>Tentative time</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input readonly [ngxTimepicker]="picker4172" formControlName="time_7_5_1" >
            <ngx-material-timepicker #picker4172 placeholder="Session Time"  ></ngx-material-timepicker>

          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select</mat-label>
              <mat-select autocomplete="off" placeholder="Select" formControlName="guest_7_5_1"  >
                <mat-option>Select</mat-option>
                <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[6].menteeFaculty">{{guest.name}}</mat-option>
              </mat-select>
          </mat-form-field>
          <p *ngIf="schedules5.guest_name_7_5_1!=''">{{schedules5.guest_name_7_5_1}}</p>
          </td>
          <td>
            <button *ngIf="schedules5.date_7_5_1==''"
            mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
            <button *ngIf="schedules5.date_7_5_1!=''"
            mat-raised-button color="primary" class="mentorButEdit">Edit</button>

            <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

              <p *ngIf="schedules5?.reportF_7_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[6].mentee_userId)" color="primary">Upload Report</button></p>

               <p *ngIf="schedules5?.reportF_7_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[6].mentee_userId)" color="primary">Update Report</button></p>

               <p *ngIf="schedules5?.reportF_7_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_7_5_1}}">View File</a></p>

           </div>

          </td>
      </tr>

<tr  *ngIf="TMentee>=8">
          <td >8</td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
              <mat-label>Mentee Institute Name</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input matInput placeholder="Mentee Institute Name" formControlName="mentee_8_5_1" required value="{{Mentee[7].mentee_userId}}" readonly>
              <mat-error *ngIf="addActivityForm5.controls.mentee_8_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
            </mat-form-field>
            <p style="font-size: 14px;" class="menteenamedata">{{Mentee[7].instituteName}}</p>
            <!-- <p style="font-size: 14px;" class="menteeTypeData">
              Funded By: {{Mentee[7].mentee_type}}</p> -->
          </td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Tentative date</mat-label>
                <input matInput readonly [matDatepicker]="picker4181" formControlName="date_8_5_1" [min]="minDate" [max]="maxDate" >
                <mat-datepicker-toggle matSuffix [for]="picker4181"></mat-datepicker-toggle>
                <mat-datepicker #picker4181></mat-datepicker>
            </mat-form-field>
          </td>
          <td>

            <mat-label>Tentative time</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input readonly [ngxTimepicker]="picker4182" formControlName="time_8_5_1" >
            <ngx-material-timepicker #picker4182 placeholder="Session Time"  ></ngx-material-timepicker>

          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select</mat-label>
              <mat-select autocomplete="off" placeholder="Select" formControlName="guest_8_5_1"  >
                <mat-option>Select</mat-option>
                <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[7].menteeFaculty">{{guest.name}}</mat-option>
              </mat-select>
          </mat-form-field>
          <p *ngIf="schedules5.guest_name_8_5_1!=''">{{schedules5.guest_name_8_5_1}}</p>
          </td>
          <td>
            <button *ngIf="schedules5.date_8_5_1==''"
            mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
            <button *ngIf="schedules5.date_8_5_1!=''"
            mat-raised-button color="primary" class="mentorButEdit">Edit</button>

            <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

              <p *ngIf="schedules5?.reportF_8_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[7].mentee_userId)" color="primary">Upload Report</button></p>

               <p *ngIf="schedules5?.reportF_8_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[7].mentee_userId)" color="primary">Update Report</button></p>

               <p *ngIf="schedules5?.reportF_8_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_8_5_1}}">View File</a></p>

           </div>


          </td>
      </tr>

<tr  *ngIf="TMentee>=9">
          <td >9</td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
              <mat-label>Mentee Institute Name</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input matInput placeholder="Mentee Institute Name" formControlName="mentee_9_5_1" required value="{{Mentee[8].mentee_userId}}" readonly>
              <mat-error *ngIf="addActivityForm5.controls.mentee_9_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
            </mat-form-field>
            <p style="font-size: 14px;" class="menteenamedata">{{Mentee[8].instituteName}}</p>
            <!-- <p style="font-size: 14px;" class="menteeTypeData">
              Funded By: {{Mentee[8].mentee_type}}</p> -->
          </td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Tentative date</mat-label>
                <input matInput readonly [matDatepicker]="picker4191" formControlName="date_9_5_1" [min]="minDate" [max]="maxDate" >
                <mat-datepicker-toggle matSuffix [for]="picker4191"></mat-datepicker-toggle>
                <mat-datepicker #picker4191></mat-datepicker>
            </mat-form-field>
          </td>
          <td>

            <mat-label>Tentative time</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input readonly [ngxTimepicker]="picker4192" formControlName="time_9_5_1" >
            <ngx-material-timepicker #picker4192 placeholder="Session Time"  ></ngx-material-timepicker>

          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select</mat-label>
              <mat-select autocomplete="off" placeholder="Select" formControlName="guest_9_5_1"  >
                <mat-option>Select</mat-option>
                <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[8].menteeFaculty">{{guest.name}}</mat-option>
              </mat-select>
          </mat-form-field>
          <p *ngIf="schedules5.guest_name_9_5_1!=''">{{schedules5.guest_name_9_5_1}}</p>
          </td>
          <td>
            <button *ngIf="schedules5.date_9_5_1==''"
            mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
            <button *ngIf="schedules5.date_9_5_1!=''"
            mat-raised-button color="primary" class="mentorButEdit">Edit</button>



            <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

              <p *ngIf="schedules5?.reportF_9_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[8].mentee_userId)" color="primary">Upload Report</button></p>

               <p *ngIf="schedules5?.reportF_9_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[8].mentee_userId)" color="primary">Update Report</button></p>

               <p *ngIf="schedules5?.reportF_9_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_9_5_1}}">View File</a></p>

           </div>

          </td>
      </tr>

<tr  *ngIf="TMentee>=10">
          <td >10</td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
              <mat-label>Mentee Institute Name</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input matInput placeholder="Mentee Institute Name" formControlName="mentee_10_5_1" required value="{{Mentee[10].mentee_userId}}" readonly>
              <mat-error *ngIf="addActivityForm5.controls.mentee_10_5_1.errors?.required">Mentee Institute UserID is required</mat-error>
            </mat-form-field>
            <p style="font-size: 14px;" class="menteenamedata">{{Mentee[9].instituteName}}</p>
            <!-- <p style="font-size: 14px;" class="menteeTypeData">
              Funded By: {{Mentee[9].mentee_type}}</p> -->
          </td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Tentative date</mat-label>
                <input matInput readonly [matDatepicker]="picker41101" formControlName="date_10_5_1" [min]="minDate" [max]="maxDate" >
                <mat-datepicker-toggle matSuffix [for]="picker41101"></mat-datepicker-toggle>
                <mat-datepicker #picker41101></mat-datepicker>
            </mat-form-field>
          </td>
          <td>

            <mat-label>Tentative time</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input readonly [ngxTimepicker]="picker41102" formControlName="time_10_5_1" >
            <ngx-material-timepicker #picker41102 placeholder="Session Time"  ></ngx-material-timepicker>

          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select</mat-label>
              <mat-select autocomplete="off" placeholder="Select" formControlName="guest_10_5_1"  >
                <mat-option>Select</mat-option>
                <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[10].menteeFaculty">{{guest.name}}</mat-option>
              </mat-select>
          </mat-form-field>
          <p *ngIf="schedules5.guest_name_10_5_1!=''">{{schedules5.guest_name_10_5_1}}</p>
          </td>
          <td>
            <button *ngIf="schedules5.date_10_5_1==''"
            mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
            <button *ngIf="schedules5.date_10_5_1!=''"
            mat-raised-button color="primary" class="mentorButEdit">Edit</button>

            <div class="col-sm-12ss" style="clear:both; overflow: hidden; padding-top: 15px;">

              <p *ngIf="schedules5?.reportF_10_5_1==''"><button type="button" mat-raised-button (click)="onEditClick('5',Mentee[9].mentee_userId)" color="primary">Upload Report</button></p>

               <p *ngIf="schedules5?.reportF_10_5_1!=''"><button  type="button" mat-raised-button (click)="onEditClick('5',Mentee[9].mentee_userId)" color="primary">Update Report</button></p>

               <p *ngIf="schedules5?.reportF_10_5_1!=''"><a target="_blank" href="{{ baseUrl + schedules5?.reportF_10_5_1}}">View File</a></p>

           </div>

          </td>
      </tr>

        <!-- <tr>
          <td colspan="7" class="submitLast"><button mat-raised-button color='primary' class="btn btn-success form1But"
            [disabled]='addActivityForm5.invalid'>Submit</button></td>
        </tr> -->

      </tbody>
  </table>

</form>
