import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-iic4o-dialog',
  templateUrl: './iic4o-dialog.component.html',
  styleUrls: ['./iic4o-dialog.component.css']
})
export class Iic4ODialogComponent implements OnInit {

  signupForm: FormGroup;
  show1: boolean;
  show: boolean;
  data1: any;
  id: any;
  ActivityModel:any;
  minDate2: any;
  requField: boolean = false;
  requField2: any;
  edit: boolean;
  editData: any;
  document:any;
  ip_type:any;
  ip_status:any;
  ip_app_number:any;
  filed_ipr:any;
  ipr_document: FileList | null;
  institute_name:any;
  institute_address:any;
  institute_city:any;
  institute_state:any;
  aishe_code:any;
  iic_4ostatus:any;
  requireField:any;
  checkaishe:any;
  affiliation_status:any;
  show_affiliate:any;
  show_iic_4ostatus:any;


  constructor(  private formBuilder: FormBuilder,
    private toastr: ToastrService, private router: Router, private InstituteDashboardService: InstituteDashboardService,
    public dialogRef: MatDialogRef<Iic4ODialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
		dialogRef.disableClose = true;

    }

  ngOnInit(): void {
    this.requireField = 0;
    this.checkaishe = 0;

    this.InstituteDashboardService.getUserAisheUStatus().subscribe(
      data => this.handleResponseaishe(data)
    );

	  this.signupForm = this.formBuilder.group({
      'iic_4ostatus' : [],
      'affiliation_status' : []
    });

  }

  handleResponseaishe(data)
  {
    this.checkaishe = data['data']['checkaishe'];
    this.affiliation_status = data['data']['affiliation_status'];
    this.show_iic_4ostatus = data['data']['iic4o_status'];


    if(this.checkaishe==1 && this.affiliation_status==0)
    {
      this.show_affiliate=1;
    }
    else
    {
      this.show_affiliate=0;
    }

  }

    onSubmit(data){

      var formData = new FormData();

      formData.append('iic_4ostatus', this.signupForm.get('iic_4ostatus').value);
      formData.append('affiliation_status', this.signupForm.get('affiliation_status').value);
      formData.append('formType', '1');

      this.InstituteDashboardService.submitUserIic4oStatus(formData).subscribe(
        data => this.handleResponse(data));

    }

    handleResponse(data){
      if (data['status'] == 1) {
        alert("Thank you for your opinion. it is saved successfully.");
        this.router.navigateByUrl('/institute/dashboard2021-22');
        this.dialogRef.close();

      }
      else
      {
        this.toastr.error(data['msg']);
      }
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    onIPStatusChange(ob) {
      let iicStatus = ob.value;
      if(iicStatus == '2') {
        this.requireField = 0;
      } else {
        this.requireField = 1;
      }

    }

}
