<div class="container">

    <!-- *ngIf="!quarter_flg" -->
    <div class="row" *ngIf="!quarter_flg">
        <p style="
        margin-left: 20px;
    ">Note:</p>
        <p style="
    margin-left: 20px;
    color:red;
">"IIC Calendar and Self Driven Activity reports can be uploaded on IIC portal after upgradation of My Council
            of existing IICs only.
            "</p>
    </div>



    <div class="m-4 row">



        <!-- <div class="col-3" *ngIf="quarter_flg"> -->
        <div class="col-3">
            <!--<button mat-raised-button color='primary' class="mat-elevation-z6" (click)='openSelfDrivenForm()'>
                <mat-icon>add</mat-icon> Add Self Driven Activity
            </button> -->
            <a routerLink="/institute/self-driven-activity-add">
                <button mat-raised-button color='primary' class="mat-elevation-z6">
                    <mat-icon>add</mat-icon> Add Self Driven Activity
                </button>
            </a>
        </div>
        <div class="col-4">
            <mat-select placeholder="Filter with Quarter" [(ngModel)]="quarter_filter" (ngModelChange)="quarterFilter()"
                class="instiIICcalActi">
                <mat-option value="">All Quarter</mat-option>
                <mat-option value="Quarter I">Semester 1–Quarter I</mat-option>
                <mat-option value="Quarter II">Semester 1–Quarter II</mat-option>
                <mat-option value="Quarter III">Semester 2–Quarter III</mat-option>
                <mat-option value="Quarter IV">Semester 2–Quarter IV</mat-option>
            </mat-select>
        </div>

        <div class="col-5 example">
            <input class="searchExter" matInput placeholder="Search Title" (keyup)="filter($event)">
            <button type="button" class="searchButton" (click)="search()"><i class="fa fa-search"
                    style="font-size: 28px;"></i></button>


        </div>

    </div>
    <!-- <div class="row offset-md-8">
	<div class="col-7 selfDriNewBut1" *ngIf="!firstPage">
        <button type="button" (click)='previousPage()' class="btn btn-success ActivitySuccessBut">Previous</button>
    </div>
    <div class="col-5" *ngIf="!lastPage">
        <button type="button" (click)='nextPage()' class="btn btn-success ActivitySuccessBut">Next</button>
    </div>
</div> -->

    <div class="row m-4">
        <!-- <div class="col-xl-4 col-md-3 mb-4" *ngFor="let activity of selfActivity;let i = index">
        <div class="aheto-pricing aheto-pricing--default  mat-elevation-z8">
            <div class="aheto-pricing__content">
                <h4 class="aheto-pricing__title t-bold">Title</h4>
                <div class="aheto-pricing__description">
                    <p class="aheto-heading__desc2Home">{{activity?.title}}</p>
                </div>
                <div class="mt-3 aheto-pricing__footer">
                    <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn"
                        (click)='openDialog(activity)'>View Details</button>
                </div>
                <div class="mt-3 aheto-pricing__footer" *ngIf="activity?.response === 'Accepted' || activity?.response === 'Modified'">
                    <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn"
                        (click)='removeCalendar(activity?.eventId, activity?.status)'>Remove from My
                        Calendar</button>
                </div>
                <div class="mt-3 aheto-pricing__footer" *ngIf="activity?.response !== 'Accepted' && activity?.response !== 'Modified'">
                    <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn"
                        (click)='addCalendar(activity?.eventId)'>Add to My
                        Calendar</button>
                </div>
            </div>
        </div>
    </div> -->


        <div class="col-md-4" *ngFor="let activity of selfActivity;let i = index">
            <div class="CardBox">
                <img src="assets/customCard/card-gradient-img.png"
                    class="CardBoxContentTopImg CardBoxContentTopImgMyCouncil">
                <!-- <img src="assets/customCard/card-top-banner.png" class="CardBoxContentTopBanner"> -->
                <div class="CardBoxContent">
                    <h3>Title</h3>
                    <p id="announcementParaScroll">{{activity?.title}}</p>
                    <p>{{activity?.quarter}}</p>
                </div>
                <div class="CardBoxContent">
                    <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
                        (click)='openDialog(activity)'>View Details</button>
                </div>
                <!-- <div class="CardBoxContent"
                    *ngIf="activity?.response === 'Accepted' || activity?.response === 'Modified'">
                    <button type="button"
                        class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG removeRedActivityBut"
                        (click)='removeCalendar(activity?.eventId,activity?.status)' >Remove from My
                        Calendar</button>
                </div> -->
                <div class="CardBoxContent"
                    *ngIf="activity?.response !== 'Accepted' && activity?.response !== 'Modified'">
                    <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
                        (click)='addCalendar(activity?.eventId)' >Add to My
                        Calendar</button>
                </div>

                <!-- <div *ngIf="!(activity?.quarter!=='Quarter I' || activity?.quarter!=='Quarter II')">
                <div  class="CardBoxContent" *ngIf="activity?.status!='Submitted'">
                    <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
                    (click)='onEditClick(activity?.eventId)'> Update Self Driven Activity</button>
                </div>
              </div> 

              <div  class="CardBoxContent" *ngIf="activity?.status!='Submitted'">
                  <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
                  (click)='onEditClick(activity?.eventId)'> Update Self Driven Activity</button>
              </div> -->

                <div class="CardBoxContent" *ngIf="activity?.status!='Submitted'">
                    <button type="button"
                        class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG removeRedActivityBut"
                        (click)='deleteCalendar(activity)'>Delete</button>
                </div>


                <!-- <img src="assets/customCard/card-bottom-gradient.png" class="CardBoxContentbottomBanner"> -->
            </div>
        </div>


    </div>

    <!-- <div class="row" style="text-align: center">
    <div class="col-6" *ngIf="!firstPage">
        <button type="button" (click)='previousPage()' class="btn btn-success">Previous</button>
    </div>
    <div class="col-6" *ngIf="!lastPage">
        <button type="button" (click)='nextPage()' class="btn btn-success">Next</button>
    </div>
</div> -->

    <div class="row">
        <div class="col-md-12">
            <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
                [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
            </mat-paginator>

        </div>
    </div>
</div>
