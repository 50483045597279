<div class="container" style="margin-top: 30px;">	
    <div class="row" color="primary">
		<div class="col-md-12">
        	<h2 class="ss" style="text-align: center;background-color: #116b74;padding: 10px 0;color: #fff;border-radius: 4px; margin: 50px 0 20px 0;">
            Add Nominate ATL Coordinator</h2>
    	</div>
	</div>
	<mat-card class="mat-elevation-z6 proInstCardMat">
		<div class="row">
			<div class="col-md-12">
				<form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
       				<div class="tableBody">
			  			<div class="table-responsive">
							<table class="table">
                                <tr>
                                    <td>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Name</mat-label>
                                            <input matInput autocomplete="off" placeholder="Enter Name" formControlName="name" type="text" required>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Email</mat-label>
                                            <input matInput autocomplete="off" placeholder="Enter Email" formControlName="email" type="text" required>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Phone No</mat-label>
                                            <input matInput autocomplete="off" placeholder="Enter Phone No" formControlName="mobile" type="text" required>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Designation</mat-label>
                                            <input matInput autocomplete="off" placeholder="Enter Designation" formControlName="designation" type="text" required>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Gender</mat-label>
                                            <mat-select autocomplete="off" placeholder="Select Gender" formControlName="gender"  required>
                                                <mat-option value="Male">Male</mat-option>
                                                <mat-option value="Female">Female</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
								<tr>
									<td>
										<button mat-raised-button color='primary' class="btn btn-success float-right" [disabled]='iicImpactQuestions.invalid'>Submit</button>
									</td>
								</tr>


	   						</table>
	   					</div>
	   				</div>
				</form>
			</div>
		</div>
	</mat-card>
</div>
