<h1 mat-dialog-title class="text-center instStuDetailsTitle">{{this.pagetitle}}</h1>
<div mat-dialog-content>
  <div class="container">
    <form [formGroup]="addActivityForm1" (submit)="onSubmit(addActivityForm1.value)">
      <!-- <div class="row" *ngIf="aptype == 5">
        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
          <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label>Activity</mat-label>
            <mat-select placeholder="Select the activity" formControlName="activity" (selectionChange)="activityChange()">
              <mat-option class="drList" value="iic_calendar">IIC Calendar</mat-option>
              <mat-option class="drList" value="mic_driven">MIC Driven</mat-option>
              <mat-option class="drList" value="celebration">Celebration</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
          <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label>Quarter</mat-label>
            <mat-select placeholder="Quarter" formControlName="quarter" required   (selectionChange)="activityChange()">
              <mat-option value="Quarter 1">Quarter 1</mat-option>
              <mat-option value="Quarter 2">Quarter 2</mat-option>
              <mat-option value="Quarter 3">Quarter 3</mat-option>
              <mat-option value="Quarter 4">Quarter 4</mat-option>
            </mat-select>
            <mat-error *ngIf="addActivityForm1.controls.quarter.errors?.required">Quarter is required
            </mat-error>
          </mat-form-field>
        </div>


      </div> -->
      <!-- <div class="row" *ngIf="aptype == 5" class="col-md-12">
        <div fxFlex="50" fxFlex.lt-sm="100" >
          <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label>Event</mat-label>
            <mat-select placeholder="Select the activity" formControlName="title">
              <mat-option class="drList" value="{{value.title}}" *ngFor="let value of eventInstitute">{{value.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div> -->
      <div class="row" *ngIf="aptype==5">
        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Title</mat-label>
            <input matInput autocomplete="off" placeholder="Title" formControlName="title" type="text" required readonly>
            <mat-error *ngIf="addActivityForm1.controls.title.errors?.required">Title is required</mat-error>
          </mat-form-field>
        </div>

	  
        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
          <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label>Quarter</mat-label>
            <input matInput autocomplete="off" placeholder="Quarter" formControlName="quarter" type="text" required readonly>
            <mat-error *ngIf="addActivityForm1.controls.quarter.errors?.required">Quarter is required
            </mat-error>
          </mat-form-field>
        </div>
        

	    </div>
      <div class="row" *ngIf="aptype!=5">

        <!-- <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">


          <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label>Session</mat-label>
            <mat-select placeholder="Conducted for" formControlName="conducted_for" required (selectionChange)="onStatusChange3($event,'1')">
              <mat-option value="Individual">Individual</mat-option>
              <mat-option value="Group">Group</mat-option>
            </mat-select>
            <mat-error *ngIf="addActivityForm1.controls.conducted_for.errors?.required">Conducted for is required
            </mat-error>

          </mat-form-field>

        </div>

        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6" [ngClass]="show_field_1=='1' ? 'irshowtr' : 'irhidetr'">


          <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label>Select the school</mat-label>
            <mat-select placeholder="Select the school" formControlName="atl_userId" >
              <mat-option class="drList" value="{{sc.atl_id}}" *ngFor="let sc of schools">{{sc.school_name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="addActivityForm1.controls.atl_userId.errors?.required">School is required
            </mat-error>

          </mat-form-field>

        </div> -->

        <div class="clearfix" style="width:100%; clear:both; overflow:hidden;"></div>

        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Title</mat-label>
            <input matInput autocomplete="off" placeholder="Title" formControlName="title" type="text" required>
            <mat-error *ngIf="addActivityForm1.controls.title.errors?.required">Title is required</mat-error>
          </mat-form-field>
      </div>

	  
  <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
    <mat-form-field appearance="outline" class="form1Seassion">
      <mat-label>Quarter</mat-label>
      <mat-select placeholder="Quarter" formControlName="quarter" required   >
        <mat-option value="Quarter 1">Quarter 1</mat-option>
        <mat-option value="Quarter 2">Quarter 2</mat-option>
        <mat-option value="Quarter 3">Quarter 3</mat-option>
        <mat-option value="Quarter 4">Quarter 4</mat-option>
      </mat-select>
      <mat-error *ngIf="addActivityForm1.controls.quarter.errors?.required">Quarter is required
      </mat-error>
    </mat-form-field>
      </div>

	  </div>

<!-- Row Ends  -->

<!-- <div class="row">

  <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
    <mat-form-field appearance="outline" class="form1Seassion">
      <mat-label>Activity Type</mat-label>
      <mat-select placeholder="Activity Type" formControlName="activity_type" required   >
        <mat-option value="Orientation and Awareness Session on Innovation & Entrepreneurship">Orientation and Awareness Session on Innovation & Entrepreneurship</mat-option>
        <mat-option value="A workshop/session on Design Thinking">A workshop/session on Design Thinking</mat-option>
        <mat-option value="A workshop/session on Prototype Development">A workshop/session on Prototype Development</mat-option>
        <mat-option value="Guide the student teams with patents and taking their innovation to market.">Guide the student teams with patents and taking their innovation to market.</mat-option>
        <mat-option value="Advanced skill development">Advanced skill development</mat-option>
      </mat-select>
      <mat-error *ngIf="addActivityForm1.controls.activity_type.errors?.required">Activity Type is required
      </mat-error>
    </mat-form-field>
  </div>  

</div> -->

<!-- Row Ends  -->

	  <div class="row">

        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">

        <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label>Tentative date</mat-label>
            <input matInput readonly [matDatepicker]="picker1" formControlName="session_date" [min]="minDate" [max]="maxDate" >
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        </div>

		<div  fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Duration (In Hours or Days)</mat-label>
        <input matInput autocomplete="off" placeholder="Duration" formControlName="session_time2" type="text" required>
        <mat-error *ngIf="addActivityForm1.controls.session_time2.errors?.required">Duration is required</mat-error>
      </mat-form-field>

        <!-- <mat-label>Tentative time</mat-label>
        <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>

    <input readonly [ngxTimepicker]="picker" formControlName="session_time" >
<ngx-material-timepicker #picker placeholder="Session Time"  ></ngx-material-timepicker> -->

</div>


      </div>

      <div class="row">
      <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">

        <mat-form-field appearance="outline" class="form1Seassion">
          <mat-label>Mode of Conduct</mat-label>
          <mat-select placeholder="Session Mode" formControlName="session_mode" required (selectionChange)="onStatusChange3($event,'2')">
            <mat-option value="Offline">Offline</mat-option>
            <mat-option value="Online">Online</mat-option>
            <mat-option value="Hybrid">Hybrid</mat-option>
          </mat-select>
          <mat-error *ngIf="addActivityForm1.controls.session_mode.errors?.required">Session is required
          </mat-error>
    
        </mat-form-field>
    
      </div>
      <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">

        <mat-form-field appearance="outline" class="form1Seassion">
          <mat-label>Select the school</mat-label>
          <mat-select placeholder="Select the school" formControlName="atl_userId" multiple>
            <mat-option class="drList" value="{{sc.atl_id}}" *ngFor="let sc of schools">{{sc.school_name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="addActivityForm1.controls.atl_userId.errors?.required">School is required
          </mat-error>
    
        </mat-form-field>
    
      </div>
      </div>
    

<div class="row">

<div fxFlex="50" fxFlex.lt-sm="100" class="col-md-12"  [ngClass]="show_field_2=='1' ? 'irshowtr' : 'irhidetr'">
    <mat-form-field appearance="outline" class="form1Seassion">
      <!-- <mat-label>Meeting ID</mat-label> -->
      <mat-label>Online session link</mat-label>
      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <input matInput placeholder="Meeting URL" formControlName="meeting_url">
    </mat-form-field>
  </div>

  <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-12"  [ngClass]="show_field_3=='1' ? 'irshowtr' : 'irhidetr'">
    <mat-form-field appearance="outline" class="form1Seassion">
      <mat-label>Venue Details</mat-label>
      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <input matInput placeholder="Venue Details" formControlName="venue_details">
    </mat-form-field>
  </div>
</div>

  <div class="row" *ngIf="aptype==8">
    <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
      <mat-form-field appearance="outline" class="form1Seassion">
        <mat-label>Point of Contact Name</mat-label>
        <input matInput placeholder="Point of Contact Name" formControlName="point_of_contact_name">
        <mat-error *ngIf="addActivityForm1.controls.point_of_contact_name.errors?.required">Point of Contact Name is required
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
      <mat-form-field appearance="outline" class="form1Seassion">
        <mat-label>Point of Contact Email</mat-label>
        <input matInput placeholder="Point of Contact Email" formControlName="point_of_contact_email">
        <mat-error *ngIf="addActivityForm1.controls.point_of_contact_email.errors?.required">Point of Contact Email is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="aptype!=8">
    <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-12">
      <mat-form-field appearance="outline" class="form1Seassion">
        <mat-label>Point of Contact</mat-label>
        <mat-select placeholder="Point of Contact" formControlName="coordinator" required>
          <mat-option class="drList" [value]="mb.Id" *ngFor="let mb of members">{{mb.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="addActivityForm1.controls.coordinator.errors?.required">Coordinator is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-10">
        <label>Upload a Brochure (Optional):</label>
        <mat-error>PDF(max 2MB)</mat-error>
    </div>

    <div class="col-lg-8 col-md-2 float-right">
        <label class="custom-file-label float-right" for="customFile" *ngIf="!report">Choose
            file</label>
        <label class="custom-file-label float-right" for="customFile"
            *ngIf="report">{{report[0]['name']}}</label>
        <input #reportRef type="file" accept="application/pdf" name="report"
            (change)="( report = reportRef.files )" class="custom-file-input float-right" >
    </div>
    <div class="col-lg-8 col-md-2 float-right"  *ngIf="old_file_upload!=''">
      <a href="{{baseImageUrl + file_upload}}" target="_blank">
          <button type="button" class="btn btn-danger mt-2 mb-2">Download Report</button>

          <!-- <button type="button" mat-raised-button color="warn" class="ml-3"
              (click)="reportStatus = !reportStatus">Upload
              Report</button> -->
      </a>
  </div>
</div>


<div class="row">
		<div class="col-md-12">
      <button mat-raised-button color='primary' class="btn btn-success form1But"
        [disabled]='addActivityForm1.invalid'>Submit</button>
		</div>
      </div>
    </form>
  </div>

</div>
