import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-collaboration',
  templateUrl: './collaboration.component.html',
  styleUrls: ['./collaboration.component.css']
})
export class CollaborationComponent implements OnInit {
  page_data: any;
  id:any;
  fileName: File;
  form: FormGroup;
  public iicImpactQuestions: FormGroup;
  ipr_document: FileList | null;
  minDate = "2018-01-01";
  maxDate = "2025-03-31";

  baseUrl = myGlobals.baseUrl1;
  ForWhom : any;

  constructor(private jarwis: JarwisService, public fb: FormBuilder, private toastr: ToastrService) {
    
    
   }
      
ngOnInit(): void { 
  this.ForWhom=localStorage.getItem('ForWhom');
  this.jarwis.dataCollaborationdata('All').subscribe( data => this.setData(data) );
 }

  setData(data){

    this.page_data = [];

    for (let i = 0; i < data.length; i++) {
      this.page_data.push(data[i])
    }
  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  deleteitem(data){
    this.jarwis.datadelete(data).subscribe( data => this.handleResponseData(data));
  }


  handleResponseData(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg']);
      this.jarwis.dataCollaborationdata('All').subscribe( data => this.setData(data) );
      // window.location.href='/institute/collaboration';
    }
    else{
      this.toastr.error(data['msg']);
    }
  }


  

}