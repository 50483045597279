<div class="container">
    <div class="row" color="primary">
        <div class="col-md-12">
            <h2 class="ss"
                style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
                List of Co-ordinators
            </h2>
        </div>
    </div>
<!--
	<div class="card">
        <div class="card-title"><p>Details of e-Learning Sessions Organized.</p></div>
	</div> -->
 
    <div class="row">
        <div class="col-md-12">
            <div class="refrredBox">
            <div class="row rws-listpage">
                <div class="col-md-12">
                    <p><a class="btn btn-primary referralBut" routerLink='/institute/announcement/nomination-add/add'>Add New Co-ordinator</a></p>
                    <div class="tableBody">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead class="facultyTable">
                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Contact</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Nominated for</th>
                                        <th scope="col">City Selected</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let nomination of iicCoordinator; index as i" >
                                        <td>{{i+1}}</td>
                                        <td>{{nomination?.name}}</td>
                                        <td>{{nomination?.email}}</td>
                                        <td>{{nomination?.contact}}</td>
                                        <td>{{nomination?.gender}}</td>
                                        <td>{{nomination?.nomination_type}}</td>
                                        <td>{{nomination?.city}}</td>
                                        <td class="color: #004085; background-color: #cce5ff; border-color: #b8daff;">
                                        <p class="btn-inline"><button mat-raised-button (click)="onClickEvent(nomination,'Edit')" color="warn" class="btn-tbl-edit">
                                            <mat-icon>edit</mat-icon>
                                            </button></p>
                                        <p class="btn-inline"><button mat-raised-button (click)="onClickEvent(nomination?.id,'Delete')" color="warn" class="btn-tbl-delete">
                                                <mat-icon>delete</mat-icon>
                                            </button></p>
                                        </td>
                                    </tr>
                                    <tr *ngIf="iicCoordinator.length== 0" col="7">
                                        <h3 style="text-align: center">No Data Found</h3>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>



            </div>
            </div>
            </div>
        </div>
    </div>
</div>
