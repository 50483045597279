<style type="text/css">
    button.btn.btn-danger-video.video {
        margin: 0 auto;
        background: #2d7201 !important;
        padding: 6px 30px !important;
        border-radius: 50px;
        color: #fff;
        border: 2px solid #4cbc05 !important;
        font-size: 14px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        display: block !important;
    }
</style>


<div class="container">

    <div class="row" *ngIf="!this.input">
		<div class="col-md-12">

            <div>
            <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btns closeButModal"
            >
                            <mat-icon class="matIconCloseDialog">close</mat-icon>

        
        </button>
</div>
			<div class="aisheCodeBox">
				<h3>AISHE Code: <span>{{aishe_code}}</span></h3>
				<p>Is your Institute AISHE Code Correct?</p>
			</div>
		</div>
		<div class="col-md-6">
			<button mat-raised-button color="primary" (click)="correct()" class="yesAisheBut">Yes</button>
		</div>
		<div class="col-md-6">
			<button mat-raised-button color="primary" (click)="inputfield()" class="noAisheBut">No</button>
		</div>
    </div>

    <mat-dialog-content class="mat-typography correctAISHECode">

    <div class="row" *ngIf="this.input">
        <form [formGroup]="verifyForm" (ngSubmit)="onSubmit1(verifyForm.value)" class="hoiFormMain">

<div class="row">  
    <div>
        <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btns closeButModal1">
  <mat-icon class="matIconCloseDialog">close</mat-icon>   
    </button>
</div>
    <div fxFlex="50" class="col-md-12">
        <p class="correctAisheCode">Please enter your correct Institute AISHE Code:</p>
    </div>

    <div fxFlex="50" class="col-md-12">
        <mat-form-field class="example-full-width aisheCodeBoxInput" appearance="outline">
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <mat-label>AISHE Code</mat-label>
          <input matInput formControlName="aishe" placeholder="AISHE Code" required >
          <mat-error *ngIf="verifyForm.controls['aishe'].hasError('required')">AISHE Code is
            required</mat-error>
        </mat-form-field>
      </div>
		
	 <div class="col-md-12">    
		<button  mat-raised-button color='primary' class="btn btn-success aisheUpdatebut"
		[disabled]='verifyForm.invalid'>Verify</button>
	</div>
</div>
	
        </form>
    </div>

<div *ngIf="this.showVerifyBox">


<mat-card class="m-4 mat-elevation-z6 proInstCardMat">
    <div class="row m-4">
        
<h2>Institute Details:</h2>
        <div class="col-md-12">
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Institute Name</p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{instituteName}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">City</p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{this.city}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">State</p>
                </div>
                <div class="col-md-8">
                    <p  class="anaContent">{{this.state}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Type</p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{this.type}}</p>
                </div>
            </div>
            <div class="row btmLine">
                <div class="col-md-4">
                    <p class="anaTitle">Sub-Type</p>
                </div>
                <div class="col-md-8">
                    <p class="anaContent">{{this.sub_type}}</p>
                </div>
            </div>
       



            <div class="row btmLine">
                <div class="col-md-8">
                    <p class="anaTitle">
                        Is your Institute Information Correct?  </p>
                </div>
                <div class="col-md-4">
                    <!-- <p class="anaContent"> -->

                        <div class="col-md-2">
                            <button mat-raised-button color="primary" (click)="YesToastr()" class="yesAisheBut mt-instiBut">Yes</button>
                        </div>
                        <div class="col-md-2">
                            <button mat-raised-button color="primary" (click)="noToastr()" class="noAisheBut mt-instiBut">No</button>
                        </div>
                    <!-- </p> -->
                </div>          
            </div>






        </div>
    </div>
</mat-card>
</div>
</mat-dialog-content>



<!-- <div class="col-md-12">    
    <button  mat-raised-button color='primary' class="btn btn-success aisheUpdatebut"
    [disabled]='aisheForm.invalid'>Update</button>
</div> -->

    <!-- <div class="col-12">
        <h3>1. Registered IIC should upgrade their IIC "My Council" as per IIC 3.0. </h3>
		<h3>2. Update the President and Convenor whatsapp mobile numbers if you wish to become part of IIC Zonal Whatsapp Groups. </h3>
		<h3>3. IIC Calendar and Self Driven Activity reports can be uploaded on IIC portal after upgradation of My Council of existing IICs only.</h3>
		<h3>4. You can download IIC Calendar under Notification Tab. </h3>
		<h3>5. Keep Visiting Notification Tab for future updates.</h3>
  </div> -->

    <!-- <div class="col-12">
        <button type="button" class="btn btn-danger-video video" mat-dialog-close>Close</button>
    </div> -->
</div>