import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class JarwisService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  signUp(data) {
    return this.http.post(`${this.baseUrl}/register`, data);
  }

  AtlsignUp(data) {
    return this.http.post(`${this.baseUrl}/atl-register`, data);
  }

  getAisheDetails(data,type='') {
    const aicteCode = {
      aishe_code: data,
    };
    return this.http.get(`${this.baseUrl}/aishe-details/` + data +'?pid='+type);
  }

  login(data) {
    return this.http.post(`${this.baseUrl}/login`, data);
  }

  sendPasswordResetLink(data) {
    return this.http.post(`${this.baseUrl}/forgot-password-request`, data);
  }

  changePassword(data) {
    let headers = new HttpHeaders();
    const authToken = 'Bearer ' + data['resetToken'];

    headers.append('Authorization', authToken);
    return this.http.post(`${this.baseUrl}/forgot-password-response`, data, {
      headers: headers
    });
  }

  emailVerification(data) {
    return this.http.post(`${this.baseUrl}/verify-email`, data);
  }
  phoneVerification(data) {
    return this.http.post(`${this.baseUrl}/otpVerification`, data);
  }

  remove() {
    localStorage.removeItem('token');
  }

  atlVerificationSend(data) {
    return this.http.get(`${this.baseUrl}/atlVerification?vid=`+data);
  }

  stateList() {
    return this.http.get(`${this.baseUrl}/iicstateList`);
  }

  getInstitute(data) {
    return this.http.get(`${this.baseUrl}/iicstateAtlList?state_id=`+data);
  }

  getInstituteName(data) {
    return this.http.get(`${this.baseUrl}/iicgetInstituteName?atl_id=`+data);
  }

  getAffiliateAisheDetails(data, type) {
    return this.http.get(`${this.baseUrl}/aishe-affiliate-details?aishe_code=`+data+`&type=`+type);
  }

  teachingLearning(data){
    return this.http.post(`${this.baseUrl}/teaching-learning`, data);

  }
  datadelete(data){
   // return this.http.post(`${this.baseUrl}/deletedata`, data);
    return this.http.get(`${this.baseUrl}/deletedata?id=`+data);
  }
  collaborationdata1(data)
  {
   return this.http.post(`${this.baseUrl}/collaboration1`, data);
  }
  collaborationdata(d1,d2,d3,d4,d5,d6){
    // alert(d6.value);
    return this.http.get(`${this.baseUrl}/collaboration?d1=`+d1+`&d2=`+d2+`&d3=`+d3+`&d4=`+d4
    +`&d5=`+d5+`&d6=`+d6);

  }
  teachingLearningData(type,subtype){
    return this.http.get(`${this.baseUrl}/teaching-learning-data?rid=`+type+`&subtype=`+subtype);
  }

  dataCollaborationdata(rid){
    return this.http.get(`${this.baseUrl}/collaboration-data?rid=`+rid);
  }

  deleteAcademicDetails(rid,subtype){
    return this.http.get(`${this.baseUrl}/academic-data-delete?rid=`+rid+`&type=`+subtype);
  }
  
  getHandholdingRequestData(id,memberId){
    return this.http.get(`${this.baseUrl}/get-handholdingData?id=`+id+`&member_id=`+memberId);
  }
  
  acceptHandholdingRequestData(data){
    return this.http.post(`${this.baseUrl}/accept-handholdingData`,data);
  }

  getApprovalType(){
    return this.http.get(`${this.baseUrl}/approval-type`);
  }

  
 
}
