<div class="container">
  <mat-dialog-content class="mat-typography decInstSem">
    <div style="text-align: center">
      <h1>Semester 1</h1>
      <mat-error>First 6 month of academic calendar includes terms & trisemester.</mat-error>
    </div>
    <form [formGroup]="declareSemesterForm" (ngSubmit)="onSubmit(declareSemesterForm.value)">
      <div class="row" style="text-align: center">
        <h2>Duration of the Semester</h2>
      </div>
      <div class="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput readonly [matDatepicker]="picker1" placeholder="Start Date"
              formControlName="duration_semester_first_from">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['duration_semester_first_from'].hasError('required')">
              Start Date is
              required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="ml-2">
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput readonly [matDatepicker]="picker2" placeholder="End Date"
              formControlName="duration_semester_first_to" [min]="minDate1">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['duration_semester_first_to'].hasError('required')">
              End Date is
              required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="row" style="text-align: center">
        <h2>Study Holidays</h2>
      </div>
      <div class="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput readonly [matDatepicker]="picker3" placeholder="Start Date"
              formControlName="study_holidays_first_from">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['study_holidays_first_from'].hasError('required')">
              Start Date is
              required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="ml-2">
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput readonly [matDatepicker]="picker4" placeholder="End Date"
              formControlName="study_holidays_first_to" [min]="minDate2">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['study_holidays_first_to'].hasError('required')">
              End Date is
              required</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div class="row" style="text-align: center">
        <h2>Semester Vacation</h2>
      </div>
      <div class="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput readonly [matDatepicker]="picker5" placeholder="Start Date"
              formControlName="semester_vacation_first_from">
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['semester_vacation_first_from'].hasError('required')">
              Start Date is
              required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="ml-2">
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput readonly [matDatepicker]="picker6" placeholder="End Date"
              formControlName="semester_vacation_first_to" [min]="minDate3">
            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['semester_vacation_first_to'].hasError('required')">
              End Date is
              required</mat-error>
          </mat-form-field>
        </div>
      </div> -->

      <div>
        <h1 style="text-align: center">Semester 2</h1>
      </div>
      <div class="row">
        <h2>Duration of the Semester</h2>
      </div>
      <div class="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput readonly [matDatepicker]="picker7" placeholder="Start Date"
              formControlName="duration_semester_sec_from">
            <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
            <mat-datepicker #picker7></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['duration_semester_sec_from'].hasError('required')">
              Start Date is
              required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="ml-2">
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput readonly [matDatepicker]="picker8" placeholder="End Date"
              formControlName="duration_semester_sec_to" [min]="minDate4">
            <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
            <mat-datepicker #picker8></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['duration_semester_sec_to'].hasError('required')">
              End Date is
              required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="row" style="text-align: center">
        <h2>Study Holidays</h2>
      </div>
      <div class="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput readonly [matDatepicker]="picker9" placeholder="Start Date"
              formControlName="study_holidays_sec_from">
            <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
            <mat-datepicker #picker9></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['study_holidays_sec_from'].hasError('required')">
              Start Date is
              required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="ml-2">
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput readonly [matDatepicker]="picker10" placeholder="End Date"
              formControlName="study_holidays_sec_to" [min]="minDate5">
            <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
            <mat-datepicker #picker10></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['study_holidays_sec_to'].hasError('required')">
              End Date is
              required</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <!-- <div class="row" style="text-align: center">
        <h2>Semester Vacation</h2>
      </div>
      <div class="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput readonly [matDatepicker]="picker11" placeholder="Start Date"
              formControlName="semester_vacation_sec_from">
            <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
            <mat-datepicker #picker11></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['semester_vacation_sec_from'].hasError('required')">
              Start Date is
              required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="ml-2">
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput readonly [matDatepicker]="picker12" placeholder="End Date"
              formControlName="semester_vacation_sec_to" [min]="minDate6">
            <mat-datepicker-toggle matSuffix [for]="picker12"></mat-datepicker-toggle>
            <mat-datepicker #picker12></mat-datepicker>
            <mat-error *ngIf="declareSemesterForm.controls['semester_vacation_sec_to'].hasError('required')">
              End Date is
              required</mat-error>
          </mat-form-field>
        </div>
      </div> -->
      <mat-dialog-actions>
        <button type="button" mat-raised-button color='warn' (click)='onNoClick()' style="margin-right: 320px;
            " class="btn btn-danger instituteBtnClose">Close</button>
        <!-- <button  mat-raised-button color='primary' class="float-right"
                 [disabled]='declareSemesterForm.invalid'>Submit</button> -->

        <button *ngIf="show1" mat-raised-button color='primary' class="float-right"
          [disabled]='declareSemesterForm.invalid'>Submit</button>

        <button *ngIf="show" mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='declareSemesterForm.invalid'>Update</button>
      </mat-dialog-actions>

    </form>
  </mat-dialog-content>
</div>
