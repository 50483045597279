<mat-tab-group mat-align-tabs="center" *ngIf="admin; else elseBlock">
  <mat-tab label="Schedule Activity">
    <app-activity-scheduled></app-activity-scheduled>
  </mat-tab>
  <mat-tab label="Edit Activity">
    <app-edit-schedule-component></app-edit-schedule-component>
  </mat-tab>
</mat-tab-group>
<ng-template #elseBlock>
    <div>No Data Found</div>
</ng-template>
