import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { emailValidator } from 'src/app/app.component';

@Component({
  selector: 'app-transfer-existing-innovator-form',
  templateUrl: './transfer-existing-innovator-form.component.html',
  styleUrls: ['./transfer-existing-innovator-form.component.css']
})
export class TransferExistingInnovatorFormComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  protoTypeList:any;
  protoTypeList1:any;
  old_team_lead_data:any = [];
  new_team_lead_data:any = [];
  isRegister:any = 0;
  isNew:any = 0;

  constructor(private fb: FormBuilder, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {



  }

  ngOnInit(): void {
    this.iicImpactQuestions = this.fb.group({
      'type': [null, Validators.required],
        'new_team_lead_id': [],
        'prototype': [],    
        'name': [],
        'email': [],
        'mobile_number': [],
        'gender': [],
        'user_type': [],
      });
    

    
    this.getTeamLeadData();
    this.instituteActivityService.getProtoType().subscribe(
      (data) => this.handleResponseProtoType(data)
    ); 
  }

  handleResponseProtoType(data,id="") {
    if(id){
      this.protoTypeList =data;
    }else{
      this.protoTypeList1 = data;
    }
  } 

  getTeamLeadData(id = ""){
    if(id){
      this.instituteActivityService.getProtoType(id).subscribe(
        (data) => this.handleResponseProtoType(data,id)
      ); 
    }else{
      this.instituteActivityService.getTeamLeadData().subscribe(
        (data) => this.handleResponseTeamLeadData(data)
      );
    }
  }

  handleResponseTeamLeadData(data){
    this.new_team_lead_data = data;
  }

  onSelectBoxChange(value){
    this.getTeamLeadData(value);
  }
  
  

  onSubmit(data) {
    var formData = new FormData();

    formData.append('type', this.iicImpactQuestions.get('type').value);
    formData.append('prototype', this.iicImpactQuestions.get('prototype').value);

    if(this.iicImpactQuestions.get('type').value == 'transfer'){

      formData.append('name', this.iicImpactQuestions.get('name').value);
      formData.append('email', this.iicImpactQuestions.get('email').value);
      formData.append('mobile_number', this.iicImpactQuestions.get('mobile_number').value);
      formData.append('gender', this.iicImpactQuestions.get('gender').value);
      formData.append('user_type', this.iicImpactQuestions.get('user_type').value);
      

      this.instituteActivityService.submityuktiInnovatorRegistration(formData).subscribe(
        data => this.handleResponseData(data));
    }else{
      formData.append('new_team_lead_id', this.iicImpactQuestions.get('new_team_lead_id').value);
    
      this.instituteActivityService.submitYuktiInnovatorInterchange(formData).subscribe(
        data => this.handleResponseData(data));
    }
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/transfered-innovation-with-existing';
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  radioChange(value){
    if(value=="transfer"){
      this.isRegister = 0;
      this.isNew = 1;
    }else{
      this.isRegister = 1;
      this.isNew = 0;
    }
  }
  
  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
}
