<form [formGroup]="inchargeForm" (ngSubmit)="onSubmit(inchargeForm.value)">

  <div>
    <mat-form-field class="example-full-width col-12" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="incharge_name" placeholder="Incharge Name" required>
      <mat-error *ngIf="inchargeForm.controls.incharge_name.errors?.required">Name
        is required
      </mat-error>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="example-full-width col-12" appearance="outline">
      <mat-label>Email:</mat-label>
      <input matInput formControlName="incharge_email" placeholder="Incubation Incharge Email">
      <mat-error *ngIf="inchargeForm.controls.incharge_email.errors?.required">Email is
        required
      </mat-error>
      <mat-error *ngIf="inchargeForm.controls.incharge_email.errors?.pattern">Invalid Email
      </mat-error>
    </mat-form-field>
  </div>


  <div>
    <mat-form-field class="example-full-width col-12" appearance="outline">
      <mat-label>Incharge Designation</mat-label>
      <input matInput formControlName="incharge_designation" placeholder="Incharge Designation" required>
      <mat-error *ngIf="inchargeForm.controls.incharge_designation.errors?.required">Designation
        is required
      </mat-error>
    </mat-form-field>
  </div>


  <div>
    <mat-form-field class="example-full-width col-12" appearance="outline">
      <mat-label>Phone:</mat-label>
      <input matInput formControlName="incharge_phone" minlength="10" maxlength="10" (keypress)=isNumberKey($event)>
      <mat-error *ngIf="inchargeForm.controls.incharge_phone.errors?.required">Phone number
        is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="col-6" style="text-align: center !important">
      <button class="btn btn-success btnDownSuccess" type="submit" [disabled]='inchargeForm.invalid'>Submit</button>
    </div>
    <div class="col-6" style="text-align: center !important">
      <button type="button" class="btn btn-danger instituteBtnClose" (click)="closeDialog()">Close</button>
    </div>
  </div>



</form>