<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Internal Report">
      <app-ia-activity-report-internal></app-ia-activity-report-internal>
  </mat-tab>
  <mat-tab label="External Report">
      <app-ia-activity-report-external></app-ia-activity-report-external>
  </mat-tab>
  <mat-tab label="Submitted Upskilling Trainings">
      <app-ia-activity-report-training></app-ia-activity-report-training>
  </mat-tab>
</mat-tab-group>


