<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

<link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
<link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">

<style>
	.navbar-nav>li {
		float: none !important;
		text-align: center !important;
		line-height: 24px;
	}
</style>

<div class="limiter">
	<div class="container-login100">
		<div class="wrap-login100">
			<span class="login100-form-title p-b-20">
				<img src="assets/institute/images/logoiic.png" class="loginIICLogo signupIICLogo">
			</span>
			<!-- <span class="login100-form-title loginInstiTopContent"> -->
				<!-- <h2>Nomination For IA Online Training Session Is Closed Now</h2> -->
			<!-- </span> -->
			<form [formGroup]="registrationOnlineForm" (ngSubmit)="onSubmit(registrationOnlineForm.value)"
				class="form-horizontal" style="color: black !important;">
				<div class="form-group">
					<div class="col-md-12"><label>Select Institute Type</label></div>
					<div class="col-md-12">
						<select class="form-control" id="institute_type" formControlName="institute_type" [(ngModel)]="optionValue">
							<option value="1">Institutes registered in Institutions’ Innovation Council</option>
							<option value="2">Institutes not registered in Institutions’ Innovation Council</option>
						</select>
						 <mat-error *ngIf="registrationOnlineForm.controls.institute_type.errors?.required">Institue Type
							is required
						</mat-error>
					</div>
				</div>
				<div class="selection-of-iic">
					<div class="form-group">
						<div class="col-md-12"><label>State</label></div>
						<div class="col-md-12">
							<select class="form-control" formControlName="state">
								<option *ngFor="let state of stateArr" value="{{state.name}}">
									{{state.name}}</option>
							</select>
							<mat-error *ngIf="registrationOnlineForm.controls.state.errors?.required">Institute State is
								required
							</mat-error>
						</div>
					</div>
					<div class="form-group institute_dropdown" *ngIf="optionValue == 1">
						<div class="col-md-12"><label>Institute name</label></div>
						<div class="col-md-12">
							<select class="form-control" formControlName="institute_name">
							<option *ngFor="let institute of institutes" value="{{institute.instituteName}}">

									{{institute.instituteName}}</option>
							</select>
							<mat-error *ngIf="registrationOnlineForm.controls.institute_name.errors?.required">Institute
								Name is required
							</mat-error>
						</div>
					</div>
					<div class="form-group institute_input" *ngIf="optionValue == 2">
						<div class="col-md-12"><label>Institute name</label></div>
						<div class="col-md-12">
							<input class="form-control" id="institute_name" formControlName="institute_name">
							<mat-error *ngIf="registrationOnlineForm.controls.institute_name.errors?.required">Institute
								Name is required
							</mat-error>
						</div>
					</div>
				</div>


				<div class="nomination-all-details">
					<table class="table table-bordered" formArrayName="quantities">

      <tr *ngFor="let quantity of quantities().controls; let i=index" [formGroupName]="i">
        <td>
            Name<span style="color:red;">*</span> :
            <input type="text" id="name" formControlName="name" class="form-control">

        </td>
        <td>
            Email<span style="color:red;">*</span>:
            <input type="text" formControlName="email" class="form-control">

        </td>
		<td>
            Phone<span style="color:red;">*</span>:
            <input type="text" formControlName="phone" class="form-control">

        </td>
		<td>
            Designation<span style="color:red;">*</span>:
            <input type="text" formControlName="designation" class="form-control">

        </td>
		<td>
            Gender<span style="color:red;">*</span>:
            <select  formControlName="gender" class="form-control">
			<option value='Male'>Male</option>
			<option value="Female">Female</option>
			</select>

        </td>
        <td>
			<i class="fa fa-trash-o" aria-hidden="true" (click)="removeQuantity(i)" style="cursor: pointer !important;color: red !important;"></i>
        </td>
      </tr>
    </table>
   <button type="button" (click)="addQuantity()" class="btn btn-primary" style="float:right;padding: 5px 7px !important;">Add More</button>
				</div>


				<div class="col-md-12">
					<button class="btn btn-danger" type="submit">Submit</button>
				</div>

      </form>
		</div>
	</div>
</div>
