import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-president-details',
  templateUrl: './president-details.component.html',
  styleUrls: ['./president-details.component.css']
})

export class PresidentDetailsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'phone', 'CURRENT_INSTITUTE_ADDRESS',];
  dataSource: MatTableDataSource<any>;
  liveData: any;
  spoc_email: any;
  spoc_name: any;
  spoc_contact: any;
  currentPageNo: any;
  headElements = ['NAME', 'EMAIL', 'PHONE', 'EDIT'];
  public show: boolean = false;
  public buttonName: any = 'Show';
  PresidentForm: FormGroup;
  ForWhom : any;

  constructor(private institutemyCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {

    this.PresidentForm = this.formBuilder.group({
      spoc_name: [null, Validators.compose([Validators.required])],
      spoc_email: [
        null,
        Validators.compose([Validators.required, Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
        ),
      ],
      spoc_contact: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ],

    });
    this.getPresidentDetails();
    this.ForWhom=localStorage.getItem('ForWhom');
  }

  getPresidentDetails() {
    this.institutemyCouncilService.getPresident('').subscribe(
      data => this.setData(data)

    );
  }

  setData(data) {

    this.liveData = data;

    this.PresidentForm.get('spoc_name').setValue(data['hoiDetails']['spoc_name']);
    this.PresidentForm.get('spoc_email').setValue(data['hoiDetails']['spoc_email']);
    this.PresidentForm.get('spoc_contact').setValue(data['hoiDetails']['spoc_contact']);

    this.spoc_name = data['hoiDetails']['spoc_name'];
    this.spoc_email = data['hoiDetails']['spoc_email'];
    this.spoc_contact = data['hoiDetails']['spoc_contact'];
  }
  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.buttonName = "Hide";
    } else {
      this.buttonName = "Show";
    }
  }

  onSubmit(values) {
    this.institutemyCouncilService.updatePresident(values).subscribe(data => this.handleResponse(data));
  }

  handleResponse(data) {
    if (data[1] == "Success") {
      this.toastr.success("Updated Successfully !");
      this.show = false;
    } else {
      this.toastr.error(data[1]);
    }
    this.getPresidentDetails();
  }

  backPage() {
    this.show = false;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }
}

















