
<form style="margin-left:10%">
    <h3>Teaching and learning2 </h3> <br><br>
    <label >Title of the credit course</label><br>
    <input type="text" name="agency_name" id="agency_name"><br/>

    <label >Course code</label><br>
    <input type="text" name="agency_name" id="agency_name"><br/>

    <label >Academic Year</label><br>
    <select name="collaborated_agency_name" id="collaborated_agency_name">
      <option value="volvo">Year1</option>
      <option value="saab">Year2</option>
      <option value="mercedes">Year3</option> 
    </select><br>


    
    <label >Course Type</label><br>
    <select name="collaborated_agency_name" id="collaborated_agency_name">
      <option value="volvo">Type1</option>
      <option value="saab">Type2</option>
    
    </select><br>

    <label >Level of Course</label><br>
    <input type="text" name="agency_name" id="agency_name"><br/>

    <label >Course offering department/Centre/Unit/</label><br>
    <input type="text" name="agency_name" id="agency_name"><br/>

    <label >Total Credit Score</label><br>
    <select name="collaborated_agency_name" id="collaborated_agency_name">
      <option value="volvo">Score1</option>
      <option value="saab">Score2</option>
      
    </select><br>

    <label >Course Start Date</label><br>
    <input matInput [matDatepicker]="picker" name="matDatepicker" id="matDatepicker">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker><br/>

    <label >Course End Date</label><br>
    <input matInput [matDatepicker]="picker" name="matDatepicker" id="matDatepicker">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker><br/>
    
    <label >Total Duratin of Course(in days)</label><br>
    <input type="text" name="Perioud_Of_Collaboration" id="Perioud_Of_Collaboration"><br/>

    <label >Total Numbers of Contact Hours(Minimum 10 contact hours) of the course</label><br>
    <input type="text" name="Perioud_Of_Collaboration" id="Perioud_Of_Collaboration"><br/>

    
    <label >Mode of Delivery of course</label><br>
    <select name="collaboration_status" id="collaboration_status">
      <option value="statu1">Course1</option>
      <option value="status2">Course2</option>
      <option value="status3">Course3</option> 
    </select><br>
    
    <label >Total Numbers of Beneficiaries/Participants Undergone The Course </label><br>
    <input type="text" name="Perioud_Of_Collaboration" id="Perioud_Of_Collaboration"><br/>
    <br><br>
      <input type="submit" value="Save & Addmore" (click)="teaching_learning2()" >
      <input type="submit" value="Save & Continue">
      <input type="submit" value="Clear">
    
    
    </form>
    <br/><br/>
    <table style="margin-left:10%; border-style:solid;" > 
    <tr><th>S.No</th>
    <th>Title of course</th>
    <th>Course code</th>
    <th>Academic year</th>
    <th>Type of course</th>
    <th>Level of course</th>
     <th>Course offering Department/centre/unit name</th>
     <th>Total credit score</th>
     <th>Course start date</th>
     <th>Course end date</th>
     <th>Total duration of course</th>
     <th>Total number of contact hourse of course</th>
     <th>Mode of delivery</th>
     <th>Total No of Benificiries</th>
    </tr>
    
    
    <tr>
    <td>1</td>
    <td>data2</td>
    <td>data3</td>
    <td>data4</td>
    <td>data5</td>
    <td>data6</td>
    <td>data7</td>
    <td>data8</td>
    </tr>
    
    
    </table>

