<h1 mat-dialog-title class="text-center instStuDetailsTitle">Add UDISE Number</h1>
<div mat-dialog-content>
  <div class="container">
    <form [formGroup]="addActivityForm" (submit)="onSubmit(addActivityForm.value)">
      <div class="row">
        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>UDISE Number</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input matInput placeholder="UDISE Number" formControlName="udise_number" required >
            <mat-error *ngIf="addActivityForm.controls.udise_number.errors?.required">UDISE Number is required</mat-error>
          </mat-form-field>
        </div>      
      </div>
      <button mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='addActivityForm.invalid'>Submit</button>
    </form>
  </div>
</div>
