<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;">
            Merge an Existing IIC</h2>
    </div>

	<!-- <div class="card">
        <div class="card-title"><p>Details of Constitutient Institute</p></div>
	</div> -->
    
    <div style="width: 100%;text-align: right;margin-bottom:15px;"><a id="backButton" class="btn btn-primary referralBut" routerLink='/institute/subiic'>Go Back</a></div>
	<div class="card">
        <!-- <div class="card-title"><p>Fetch IIC Details</p></div> -->
        <div class="card-body">
            <form id="iicsubMerge" class="instiBankForm" [formGroup]="iicImpactQuestions1" (ngSubmit)="onSearch(iicImpactQuestions1.value)">
                <table class="table table-hover">
                    <tr>
                        <td width="25%">
                            IIC Id
                        </td>
                        <td>
                            <mat-form-field appearance="outline" class="w-100">
                            <mat-label>IIC Id</mat-label>
        
                            <input matInput autocomplete="off" placeholder="IIC Id(IC201810001) " formControlName="iic" type="text"   required>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            <button mat-raised-button color='primary' class="btn btn-success float-right"
                [disabled]='iicImpactQuestions1.invalid'>Fetch IIC Details</button>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
	</div>
    <div style="display: none;" id="content-display">
        <form id="iicsubMerge1" class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
            <table class="table table-hover">
                <tr>
                    <td width="25%">
                        Institute Name
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Institute Name</mat-label>

                        <input matInput autocomplete="off" placeholder="Institute Name" formControlName="institute_name" type="text"   required>
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        Address
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Address</mat-label>	
                            <input matInput autocomplete="off" placeholder="Address" formControlName="address" type="text"   required>
                            </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        State
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>State</mat-label>	
                            <input matInput autocomplete="off" placeholder="State" formControlName="state" type="text">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        City
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>City</mat-label>	
                            <input matInput autocomplete="off" placeholder="City" formControlName="city" type="text">
                        </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        Type of Courses Offered by the Constitutient Institute
                    </td>
                    <td>

                        <mat-form-field class="w-100 ml-3" appearance="outline">
                            <mat-label>Type of Courses Offered by the Constitutient Institute</mat-label>
                            <mat-select formControlName="ins_subtype" required>
                            <mat-option value="Technical & Non Technical Both">Technical & Non Technical</mat-option>
                            <mat-option value="Non Technical Only">Non Technical Only</mat-option>
                            </mat-select>
                        </mat-form-field>					
                    </td>
                </tr>

                <tr>
                    <td>
                        Constitutient Institute's AISHE Code
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>AISHE Code</mat-label>	
                            <input matInput autocomplete="off" placeholder="AISHE Code" formControlName="aishe_code" type="text" readonly="true">
                            </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        President Name
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>President Name</mat-label>	
                            <input matInput autocomplete="off" placeholder="President Name" formControlName="president_name" type="text" required>
                            </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        President Email
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>President Email</mat-label>	
                            <input matInput autocomplete="off" placeholder="President Email" formControlName="president_email" type="email" required>
                            </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        President Mobile Number
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>President Mobile Number</mat-label>	
                            <input matInput autocomplete="off" placeholder="President Mobile Number" formControlName="president_mobile" type="text">
                            </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        Convener Name
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Convener Name</mat-label>	
                            <input matInput autocomplete="off" placeholder="Convener Name" formControlName="convener_name" type="text" required>
                            </mat-form-field>
                    </td>
                </tr>

                <tr>
                    <td>
                        Convener Email
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Convener Email</mat-label>	
                            <input matInput autocomplete="off" placeholder="Convener Email" formControlName="convener_email" type="email" required>
                            </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        Convener Mobile Number
                    </td>
                    <td>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Convener Mobile Number</mat-label>	
                            <input matInput autocomplete="off" placeholder="Convener Mobile Number" formControlName="convener_mobile" type="text">
                            </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        &nbsp;
                    </td>
                    <td>
                        <button type="button" mat-raised-button color='primary' class="btn btn-success float-right" (click)="onClickInvite()">Invite</button>
                    </td>
                </tr>


        </table>
        </form>
    </div>
</div>