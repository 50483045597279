<div class="container" style="margin-top: 30px;">	
    <div class="row" color="primary">
		<div class="col-md-12">
        <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Profile</h2>
    </div>
	</div>
	<mat-card class="mat-elevation-z6 proInstCardMat">
	<div class="row">
	<div class="col-md-12">
	<form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
       <div class="tableBody">
			  <div class="table-responsive">
		<table class="table">
		
		<tr>
			<td>
				State
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-select autocomplete="off" placeholder="Please Select" formControlName="state"  required (selectionChange)="stateChange($event)">
                        <mat-option value="{{value.id}}" *ngFor="let value of state">{{value.name}}</mat-option>
                      </mat-select>
				</mat-form-field>
                
			</td>
		</tr>
		<tr>
			<td>
				ATL ID
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-select autocomplete="off" placeholder="Please Select" formControlName="aishe_code"  required [disabled]="true">
                        <mat-option *ngFor="let value of institute" value="{{value.atl_code}}">
                            ATL ID: {{value?.atl_code}}, {{value?.school_name}}, {{value?.district}}</mat-option>
                    </mat-select>
					<mat-error *ngIf="iicImpactQuestions.controls.aishe_code.errors?.required">Aishe Code is required
                    </mat-error>
				</mat-form-field>
			</td>
		</tr>
		<tr>
			<td>
				UDISE Code
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-label>UDISE Code</mat-label>
                    <input matInput placeholder="UDISE Code" formControlName="udise_number" disabled>
				</mat-form-field>
                
			</td>
		</tr>
		<tr>
			<td>
				Name of the School
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Name of the School</mat-label>
                    <input matInput placeholder="Name of the School" formControlName="institute_name" required>
                    <mat-error *ngIf="iicImpactQuestions.controls.institute_name.errors?.required">Name of the School is required
                    </mat-error>
				</mat-form-field>
			</td>
		</tr>
		<tr>
			<td>
				School Address
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-label>School Address</mat-label>
                    <input matInput placeholder="Enter Address" formControlName="institute_address" required>
                    <mat-error *ngIf="iicImpactQuestions.controls.institute_address.errors?.required">Enter Address is required
                    </mat-error>
				</mat-form-field>
			</td>
		</tr>
		<tr>
			<td>
				City Name
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-label>City Name</mat-label>
                    <input matInput placeholder="City Name" formControlName="institute_city" required (keypress)="isCharKey($event)">
                    <mat-error *ngIf="iicImpactQuestions.controls.institute_city.errors?.required">City Name is required
                    </mat-error>
				</mat-form-field>
			</td>
		</tr>
		<tr>
			<td>
				Head of the School Name
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Head of the School Name</mat-label>
                    <input matInput placeholder="Head of the School Name" formControlName="name" required>
                    <mat-error *ngIf="iicImpactQuestions.controls.name.errors?.required">Head of the School Name is required
                    </mat-error>
				</mat-form-field>
			</td>
		</tr>
		<tr>
			<td>
				Email ID
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Email ID</mat-label>
                    <input matInput placeholder="Email ID" formControlName="email" required
                    (keypress)="isSpace($event)">
                    <mat-error *ngIf="iicImpactQuestions.controls.email.errors?.required">Email ID is required
                    </mat-error>
                    <mat-error *ngIf="iicImpactQuestions.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
				</mat-form-field>
			</td>
		</tr>
		<tr>
			<td>
				Mobile Number
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput placeholder="Mobile Number" formControlName="mobile_number"
                    required minlength="10" maxlength="10" (keypress)="isNumKey($event)">
                    <mat-error *ngIf="iicImpactQuestions.controls.mobile_number.errors?.required">Mobile Number is required
                    </mat-error>
                    <mat-error *ngIf="iicImpactQuestions.controls.mobile_number.errors?.minlength">Mobile Number should be of 10 digits
                    </mat-error>
				</mat-form-field>
			</td>
		</tr> 
            <tr>
				<td>
					&nbsp;
				</td>
				<td>
					<button mat-raised-button color='primary' class="btn btn-success float-right" [disabled]='iicImpactQuestions.invalid'>Submit</button>
				</td>
			</tr>


	   </table>
	   </div>
	   </div>
	</form>
	</div>
	</div>
	</mat-card>
</div>