<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Add Innovation Submission</h2>
    </div>

    <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">

      <mat-card style="overflow: hidden;">
      <div class="rws-teamdetails">
          <h3>Team Lead Details</h3>
        <div class="row">
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Team Lead Name</mat-label>
              <input matInput autocomplete="off" placeholder="Team Lead Name" formControlName="team_lead_name" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Team Lead Email</mat-label>
              <input matInput autocomplete="off" placeholder="Team Lead Email" formControlName="team_lead_email" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Team Lead Mobile No.</mat-label>
              <input matInput autocomplete="off" placeholder="Team Lead Mobile No." formControlName="team_lead_mobile" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Gender</mat-label>
              <mat-select autocomplete="off" placeholder="Gender" formControlName="team_lead_gender"  required>
                <mat-option>Select</mat-option>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Category</mat-label>
              <mat-select autocomplete="off" placeholder="Category" formControlName="team_lead_category"  required>
                <mat-option>Select</mat-option>
                <mat-option value="General">General</mat-option>
                <mat-option value="OBC">OBC</mat-option>
                <mat-option value="ST">ST</mat-option>
                <mat-option value="SC">SC</mat-option>
                <mat-option value="EWS">EWS</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Occupation</mat-label>
              <mat-select autocomplete="off" placeholder="Occupation" formControlName="user_type"  required>
                <mat-option>Select</mat-option>
                <mat-option value="Student">Student</mat-option>
                <mat-option value="Faculty">Faculty</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

        </div>

      </div>
    </mat-card>

    <p>
      <mat-radio-group formControlName="submission_type" (change)="onStatusChange2($event)"  aria-label="Select an option" class="col-md-12" required>
        <mat-radio-button class="alumniRadioBut" value="1"><p class="radioButpara">Idea / POC Submission</p></mat-radio-button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-radio-button class="alumniRadioBut" value="2"><p class="radioButpara">Innovation / Prototype</p></mat-radio-button>
      </mat-radio-group>
    </p>

    <table class="table table-hover repositoryFormTable">
      <tr *ngIf="show_field_3==1">
        <td width="25%">
          Title
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
          <mat-label>Title / Name (10 Words Max)</mat-label>
          <input matInput autocomplete="off" placeholder="Title / Name" formControlName="title" type="text" (keyup)="countWords1($event)" [(ngModel)]="titleModel1" required>
          <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount1}} / 10</p>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Sector / Domain
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">

                  <mat-label> Theme</mat-label>

                  <mat-select autocomplete="off" placeholder="Theme" formControlName="theme"  required multiple>
                    <mat-option>Select</mat-option>
                    <mat-option value="{{theme.id}}" *ngFor="let theme of programTheme">{{theme.name}}</mat-option>
                  </mat-select>
                  <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
              </mat-form-field>

        </td>
      </tr>

      <tr *ngIf="show_field_3==1 && show_field_2==0">
        <td>
          TRL Level
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="outline" style="width: 424px;" >
            <mat-select formControlName="trl_level" placeholder="TRL Level" required>
                <mat-option value="0">TRL 0 : Idea. Unproven concept, no testing has been performed</mat-option>
                <mat-option value="1">TRL 1 : Basic research. Principles postulated observed but no experimental proof available</mat-option>
                <mat-option value="2">TRL 2 : Technology formulation. Concept and application have been formulated</mat-option>
                <mat-option value="3">TRL 3 : Applied research. First laboratory tests completed; proof of concept</mat-option>
        </mat-select>
        </mat-form-field>
        </td>
      </tr>

      <tr *ngIf="show_field_2==1">
        <td>
          TRL Level
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="outline" style="width: 424px;" >
            <mat-select formControlName="trl_level" placeholder="TRL Level" required>
                <mat-option value="0">TRL 0 : Idea. Unproven concept, no testing has been performed</mat-option>
                <mat-option value="1">TRL 1 : Basic research. Principles postulated observed but no experimental proof available</mat-option>
                <mat-option value="2">TRL 2 : Technology formulation. Concept and application have been formulated</mat-option>
                <mat-option value="3">TRL 3 : Applied research. First laboratory tests completed; proof of concept</mat-option>
                <mat-option value="4">TRL 4 : Small scale prototype built in a laboratory environment ("ugly" prototype)</mat-option>
                <mat-option value="5">TRL 5 : Large scale prototype tested in intended environment</mat-option>
                <mat-option value="6">TRL 6 : Prototype system tested in intended environment close to expected performance</mat-option>
                <mat-option value="7">TRL 7 : Demonstration system operating in operational environment at pre-commercial scale</mat-option>
                <mat-option value="8">TRL 8 : First of a kind commercial system. Manufacturing issues solved</mat-option>
                <mat-option value="9">TRL 9 : Full commercial application, technology available for consumers</mat-option>
        </mat-select>
        </mat-form-field>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Current Stage of Support
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="outline" style="width: 424px;" >
            <mat-select formControlName="category" placeholder="Category" required>
                <mat-option value="Receiving Pre-incubation Support">  Receiving Pre-incubation Support</mat-option>
                <mat-option value="Receiving Incubation Support">  Receiving Incubation Support</mat-option>
                <mat-option value="No Support Received">  No Support Received</mat-option>
        </mat-select>
        </mat-form-field>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Innovation Form
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="outline" style="width: 424px;" >
            <mat-select formControlName="type" placeholder="Innovation Form" required multiple>
                <mat-option value="Product">Product</mat-option>
                <mat-option value="Process">Process</mat-option>
                <mat-option value="Service">Service</mat-option>
                <mat-option value="Market Place">Market Place</mat-option>
                <mat-option value="Business/Management Innovation">Business/Management Innovation</mat-option>
        </mat-select>
        </mat-form-field>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Define the problem and its relevance to today's market / sociaty / industry need<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Define the problem and its relevance to today's market / sociaty / industry need</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Define the problem and its relevance to today's market / sociaty / industry need" formControlName="description" required
          (keyup)="countWords2($event)" [(ngModel)]="titleModel2"></textarea>
          <!-- <mat-error *ngIf="mandateForm.controls.inst_address.errors?.required">Address</mat-error> -->
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount2}} / 100</p>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Describe the Solution / Proposed / Developed<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Describe the Solution / Proposed / Developed</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Describe the Solution / Proposed / Developed" formControlName="description_solution"
          (keyup)="countWords3($event)" [(ngModel)]="titleModel3" required></textarea>
          <!-- <mat-error *ngIf="mandateForm.controls.inst_address.errors?.required">Address</mat-error> -->
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount3}} / 100</p>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Explain the uniqueness and distinctive features of the (product / process / service) solution<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Explain the uniqueness and distinctive features of the (product / process / service) solution</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Explain the uniqueness and distinctive features of the (product / process / service) solution" formControlName="description_feature"
          (keyup)="countWords4($event)" [(ngModel)]="titleModel4" required></textarea>
          <!-- <mat-error *ngIf="mandateForm.controls.inst_address.errors?.required">Address</mat-error> -->
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount4}} / 100</p>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          How your proposed / developed (product / process / service) solution is different from similiar kind of product by the competitors if any<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>How your proposed / developed (product / process / service) solution is different from similiar kind of product by the competitors</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="How your proposed / developed (product / process / service) solution is different from similiar kind of product by the competitors" formControlName="description_similar"
          (keyup)="countWords5($event)" [(ngModel)]="titleModel5" required></textarea>
          <!-- <mat-error *ngIf="mandateForm.controls.inst_address.errors?.required">Address</mat-error> -->
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount5}} / 100</p>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Innovation type:
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="outline" style="width: 424px;" >
            <mat-select formControlName="innovation_type" placeholder="Innovation type"  (selectionChange)="onStatusChange1($event)" required>
                <mat-option value="Patentable/IP Based">Patentable/IP Based</mat-option>
                <mat-option value="Non Patentable/Non-IP Based">Non Patentable/Non-IP Based</mat-option>
        </mat-select>
        </mat-form-field>
        </td>
      </tr>

      <tr *ngIf="show_field_1==1">
        <td>
          Specify the IP Components (Max 100 Words)<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Specify the IP Components</mat-label>
            <textarea rows="2" maxlength="500" matInput placeholder="Specify the IP Components" formControlName="ip_component" (keyup)="countWords6($event)" [(ngModel)]="titleModel6"></textarea>
            <!-- <mat-error *ngIf="mandateForm.controls.inst_address.errors?.required">Address</mat-error> -->
            </mat-form-field>
            <p class="totalNumber">Total Number of words: {{titleCount3}} / 100</p>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Video URL
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
          <mat-label>Video URL</mat-label>

          <input matInput autocomplete="off" placeholder="Video URL" formControlName="video_url" type="text" required>
          <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
          </mat-form-field>

          <span *ngIf="show_field_1==0"><input type="hidden" formControlName="ip_component" value=""/> </span>
        </td>
      </tr>

      <tr *ngIf="show_field_3==1">
        <td>
          Upload Photograph: (JPG max 2 MB)<br/>
          <a href="https://nic.mic.gov.in/assets/assets/images/innovation-dowloads/Idea-or-Proof-of-Concept-Submisson-Format.pdf" target="_blank">Refer the format</a>
        </td>
        <td>
          <div class="col-12">
            <label class="custom-file-label float-right" for="customFile"
                              *ngIf="!ipr_document">Choose file</label>

            <label class="custom-file-label float-right" for="customFile"
                *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>

            <input #ipr_documentRef type="file" accept="image/jpeg, image/jpg"  name="ipr_document"
                (change)="( ipr_document = ipr_documentRef.files )"
                class="custom-file-input float-right" required>
              </div>

        </td>
      </tr>

      <tr *ngIf="document">
        <th>&nbsp;</th>
        <td colspan="3">
            <a href="https://api.mic.gov.in{{document}}" target="_blank">View File</a>
        </td>
      </tr>


      <tr *ngIf="show_field_2==1">
        <td>
          If your Innovation is technology based, then specify the TRL Level (Technology Readiness Level) and features of Prototype.<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>If your Innovation is technology based, then specify the TRL Level (Technology Readiness Level) and features of Prototype.</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="If your Innovation is technology based, then specify the TRL Level (Technology Readiness Level) and features of Prototype." formControlName="innovation_technology"
          (keyup)="countWords16($event)" [(ngModel)]="titleModel16" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount16}} / 100</p>
        </td>
      </tr>

  <tr *ngIf="show_field_2==1">
        <td>
          Specific- Specify the features of Innovation/Prototype<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Specific- Specify the features of Innovation/Prototype</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Specific- Specify the features of Innovation/Prototype" formControlName="feasibility_specific"
          (keyup)="countWords7($event)" [(ngModel)]="titleModel7" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount7}} / 100</p>
        </td>
      </tr>


  <tr *ngIf="show_field_2==1">
        <td>
          Measurable- Mention the approach to convert prototype in to market ready product with milestones<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Measurable- Mention the approach to convert prototype in to market ready product with milestones</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Measurable- Mention the approach to convert prototype in to market ready product with milestones" formControlName="feasibility_measureable"
          (keyup)="countWords8($event)" [(ngModel)]="titleModel8" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount8}} / 100</p>
        </td>
      </tr>

  <tr *ngIf="show_field_2==1">
        <td>
          Attainable- Explain how you are going to achieve the commercialization objective with the available resources at your disposal.<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Attainable- Explain how you are going to achieve the commercialization objective with the available resources at your disposal.</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Attainable- Explain how you are going to achieve the commercialization objective with the available resources at your disposal." formControlName="feasibility_attainable"
          (keyup)="countWords9($event)" [(ngModel)]="titleModel9" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount9}} / 100</p>
        </td>
      </tr>


  <tr *ngIf="show_field_2==1">
        <td>
          Realistic- what kind of skillset of team and resources required to achieve the goal in specific time period?<br/>
          (Max: 100 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Realistic- what kind of skillset of team and resources required to achieve the goal in specific time period?</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Realistic- what kind of skillset of team and resources required to achieve the goal in specific time period?" formControlName="feasibility_realistic"
          (keyup)="countWords10($event)" [(ngModel)]="titleModel10" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount10}} / 100</p>
        </td>
      </tr>


  <tr *ngIf="show_field_2==1">
        <td>
          Timeline- Develop a timeline against the milestones for taking prototype to the commercial level/startup stage.<br/>
          (Max: 110 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Timeline- Develop a timeline against the milestones for taking prototype to the commercial level/startup stage.</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Timeline- Develop a timeline against the milestones for taking prototype to the commercial level/startup stage." formControlName="feasibility_timeline"
          (keyup)="countWords11($event)" [(ngModel)]="titleModel11" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount11}} / 110</p>
        </td>
      </tr>

  <tr *ngIf="show_field_2==1">
        <td>
          Usability: what is the usability of your innovation/ prototype.(Max 50 words)*<br/>
          (Max: 120 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Usability: what is the usability of your innovation/ prototype.(Max 50 words)*</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Usability: what is the usability of your innovation/ prototype.(Max 50 words)*" formControlName="applicable_usability"
          (keyup)="countWords12($event)" [(ngModel)]="titleModel12" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount12}} / 120</p>
        </td>
      </tr>

  <tr *ngIf="show_field_2==1">
        <td>
          Scalability: how your innovation/ prototype will be scalable at market level<br/>
          (Max: 130 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Scalability: how your innovation/ prototype will be scalable at market level</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Scalability: how your innovation/ prototype will be scalable at market level" formControlName="applicable_scalability"
          (keyup)="countWords13($event)" [(ngModel)]="titleModel13" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount13}} / 130</p>
        </td>
      </tr>

  <tr *ngIf="show_field_2==1">
        <td>
          Economic sustainability: Explain the potential of innovation/ prototype to become profitable or financially viable.<br/>
          (Max: 140 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Economic sustainability: Explain the potential of innovation/ prototype to become profitable or financially viable.</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Economic sustainability: Explain the potential of innovation/ prototype to become profitable or financially viable." formControlName="applicable_economic"
          (keyup)="countWords14($event)" [(ngModel)]="titleModel14" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount14}} / 140</p>
        </td>
      </tr>

  <tr *ngIf="show_field_2==1">
        <td>
          Environment Sustainability: How your innovation/ prototype is environment friendly or address environmental problems<br/>
          (Max: 150 Words)
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
          <mat-label>Environment Sustainability: How your innovation/ prototype is environment friendly or address environmental problems</mat-label>
          <textarea rows="2" maxlength="500" matInput placeholder="Environment Sustainability: How your innovation/ prototype is environment friendly or address environmental problems" formControlName="applicable_enviroment"
          (keyup)="countWords15($event)" [(ngModel)]="titleModel15" required></textarea>
          </mat-form-field>
          <p class="totalNumber">Total Number of words: {{titleCount15}} / 150</p>
        </td>
      </tr>

     </table>


     <p><button mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicImpactQuestions.invalid'>Submit</button></p>

	</form>
</div>
