import { AmbassadorDashboardService } from './../../../Services/ambassador/ambassador-dashboard.service';
import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../app/app.component';
@Component({
  selector: 'app-iic-innovation-ambassador-new',
  templateUrl: './iic-innovation-ambassador-new.component.html',
  styleUrls: ['./iic-innovation-ambassador-new.component.css']
})
export class IicInnovationAmbassadorNewComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  insCount2021:any;
  insBasic2021:any;
  insAdvance2021:any;
  insCount2022:any;
  insBasic2022:any;
  insAdvance2022:any;
  resultInst:any;
  
  constructor(private ambassadorDashboardService: AmbassadorDashboardService) { }

  ngOnInit(): void {
	  
		  /*------------------------------------------------------------------
   	 Smooth Scroll Landing
	 ------------------------------------------------------------------*/
// Select all »a« elements with a parent class »links« and add a function that is executed on click
$( '#navbarColor01 a' ).on( 'click', function(e){
	
  // Define variable of the clicked »a« element (»this«) and get its href value.
  var href = $(this).attr( 'href' );
  
  // Run a scroll animation to the position of the element which has the same id like the href value.
  $( 'html, body' ).animate({
		scrollTop: $( href ).offset().top
  }, '300' );
	
  // Prevent the browser from showing the attribute name of the clicked link in the address bar
  e.preventDefault();

});

$(document).ready(function(){ 
    $(window).scroll(function(){ 
        if ($(this).scrollTop() > 100) { 
            $('#scroll').fadeIn(); 
        } else { 
            $('#scroll').fadeOut(); 
        } 
    }); 
    $('#scroll').click(function(){ 
        $("html, body").animate({ scrollTop: 0 }, 600); 
        return false; 
    }); 
});  
this.getDashboardDetails();
}

getDashboardDetails() {
  this.ambassadorDashboardService.getDashboardDetails().subscribe(
    data => this.handleResponse(data)
  );
}

handleResponse(data) {

this.resultInst = data['resultInst'];
this.insCount2021 = data['instotal2021'];
this.insBasic2021 = data['insbasic2021'];
this.insAdvance2021 = data['insadvance2021'];
this.insCount2022 = data['instotal2022'];
this.insBasic2022 = data['insbasic2022'];
this.insAdvance2022 = data['insadvance2022'];

}

  
  

}
