<!-- <app-content-header
    [icon]="'pie_chart'"
    [title]="'Pie Charts'"
    [hideBreadcrumb]="false"
    [hasBgImage]="true"
    [class]="'pb-4'"></app-content-header> -->

    <div *ngIf="ForWhom=='Institute'" class="container">
      <div class="row">

          <div class="col-lg-6 col-xs-12">
              <mat-card>
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="activityDashboard">pie_chart</mat-icon>Activity By Theme
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>
                  <div class="w-100 h-300p">
                      <!-- <div fxLayout="row" fxLayoutAlign="space-around">
                          <mat-checkbox color="primary" class="example-margin" [checked]="explodeSlices"
                              (change)="explodeSlices = !explodeSlices">Explode Slices</mat-checkbox>
                          <mat-checkbox color="primary" class="example-margin" [checked]="showLabels"
                              (change)="showLabels = !showLabels">Show Labels</mat-checkbox>
                      </div> -->
                      <ngx-charts-pie-chart [scheme]="colorScheme" [results]="single" [legend]="showPieLegend" [legendPosition]="legendPosition"
                          [explodeSlices]="explodeSlices" [labels]="showLabels" [doughnut]="doughnut" [trimLabels]="trimLabels" (select)="onSelect($event)">
                      </ngx-charts-pie-chart>
                  </div>
              </mat-card>
          </div>

          <div class="col-xs-12 col-lg-6">
              <mat-card>
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="bookmarkDashboard">bookmark</mat-icon>Overall Impact
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>

                  <div class="w-100 h-300p">
                      <div class="row">
                          <div class="col-6">
                              <p>Total Student: <b>{{overallImpact?.studentParticipants}}</b></p>
                          </div>
                          <div class="col-6">
                              <p>Total Faculty: <b>{{overallImpact?.facultyParticipants}}</b></p>
                          </div>
                      </div>
                      <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="multi2"
                          [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                          [showYAxisLabel]="showYAxisLabel"
                          [yAxisLabel]="yAxisStackedLabel" (select)="onSelect($event)">
                      </ngx-charts-bar-vertical-stacked>
                  </div>
                  <!-- <a routerLink="" class="float-right mb-4">...more</a> -->
              </mat-card>
          </div>
      </div>


      <div class="row">
          <div class="col-lg-6 col-xs-12">
              <mat-card>
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="activityDashboard">bar_chart</mat-icon>My Activities
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>
                  <div class="w-100 h-300p">
                      <ngx-charts-bar-horizontal-2d [scheme]="barColorScheme" [results]="multi" [gradient]="barGradient"
                          [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showBarLegend" legendPosition="right"
                          [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                          [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
                      </ngx-charts-bar-horizontal-2d>
                  </div>
              </mat-card>
          </div>
          <div class="col-lg-6 col-xs-12">
              <mat-card>
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="myImpactDashboard">multiline_chart</mat-icon>My Impact
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>

                  <!-- <div class="w-100 h-300p"> -->
                  <mat-form-field class="ml-2">
                      <mat-label>Chart For</mat-label>
                      <mat-select [(ngModel)]="selectedType" (selectionChange)="setEventwiseBarData()">
                          <mat-option value="IIC Calendar Activity">IIC Calendar Activity</mat-option>
                          <mat-option value="MIC Driven Activity"> MIC Driven Activity</mat-option>
                          <mat-option value="Self Driven Activity"> Self Driven Activity</mat-option>
                      </mat-select>
                  </mat-form-field>
                  <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                      [colors]="barChartColors" [plugins]="barChartPlugins" [legend]="barChartLegend"
                      [chartType]="barChartType">
                  </canvas>
                  <!-- </div> -->
                  <!-- <a routerLink="" class="float-right mb-4">...more</a> -->
              </mat-card>
          </div>
      </div>

    <!-- <div class="row dashboardChartRowMargin"> -->
        <!-- <div class="col-md-12">
          <div class="activityDashboardTitle">
            <h2>My Activity Report</h2>
          </div>
        </div> -->
        <!-- <div class="col-lg-4 col-sm-6 col-xs-12">
          <mat-card>
            <mat-card-header fxLayoutAlign="center center">
              <mat-card-subtitle>
                <h2>
                  <mat-icon class="iicCalendarActivitDashboard">speed</mat-icon>IIC Calendar Activity
                </h2>
              </mat-card-subtitle>
            </mat-card-header>
            <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [options]="options1"
              [bottomLabel]="bottomLabel" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              <p class="chartParaBottom">Minimun Threshold Value (9)</p>
              <p class="chartParaBottom1">Total Number of IIC Calendar Activity (40)</p>
            </mat-card>
        </div> -->
         <!-- <div class="col-lg-4 col-sm-6 col-xs-12">
          <mat-card>
            <mat-card-subtitle>
              <h2>
                <mat-icon class="micDrivenDashboard">speed</mat-icon>MIC Driven Activity
              </h2>
            </mat-card-subtitle>
            <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue2" [options]="options2"
              [bottomLabel]="bottomLabel2" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart> -->
             <!-- <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue2" [options]="options2"
              [bottomLabel]="bottomLabel2" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              <p class="chartParaBottom">Minimun Threshold Value (8)</p>
              <p class="chartParaBottom1">Total Number of MIC Driven Activity (8)</p>
          </mat-card>
        </div> -->
         <!-- <div class="col-lg-4 col-sm-6 col-xs-12">
          <mat-card>
            <mat-card-subtitle>
              <h2>
                <mat-icon class="selfDrivenDashboard">speed</mat-icon>Self Driven Activity
              </h2>
            </mat-card-subtitle>
            <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue3" [options]="options3"
              [bottomLabel]="bottomLabel3" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              <p class="chartParaBottomSelf">Minimun Threshold Value (9)</p>
              <p class="chartParaBottomSelf1">Highest Number of Self Driven Activity (269) by</p>
              <p class="chartParaBottomSelf2">Sri Krishna Arts and Science College, Coimbatore</p>
          </mat-card>
        </div> -->
      </div>

   <!-- <div class="container mb-2">
      <div class="row">
          <div class="p-2 col-lg-4 col-sm-6 col-xs-12">
              <mat-card>
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="iicCalendarActivitDashboard">speed</mat-icon>IIC Calendar Activity
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>
                  <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [options]="options1"
                      [bottomLabel]="bottomLabel" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              </mat-card>
          </div>
          <div class="p-2 col-lg-4 col-sm-6 col-xs-12">
              <mat-card>
                  <mat-card-subtitle>
                      <h2>
                          <mat-icon class="micDrivenDashboard">speed</mat-icon>MIC Driven Activity
                      </h2>
                  </mat-card-subtitle>
                  <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue2" [options]="options2"
                      [bottomLabel]="bottomLabel2" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              </mat-card>
          </div>
          <div class="p-2 col-lg-4 col-sm-6 col-xs-12">
              <mat-card>
                  <mat-card-subtitle>
                      <h2>
                          <mat-icon class="selfDrivenDashboard">speed</mat-icon>Self Driven Activity
                      </h2>
                  </mat-card-subtitle>
                  <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue3" [options]="options3"
                      [bottomLabel]="bottomLabel3" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              </mat-card>
          </div>
      </div>
  </div> -->
<!-- </div> -->

<div *ngIf="ForWhom=='School'" class="container-fluid schoolChartFluid">
      <div class="row">

          <div class="col-lg-6 col-xs-12">
              <mat-card class="schoolChartCard">
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="activityDashboard">pie_chart</mat-icon>Activity By Theme
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>
                  <div class="w-100 h-300p">
                      <!-- <div fxLayout="row" fxLayoutAlign="space-around">
                          <mat-checkbox color="primary" class="example-margin" [checked]="explodeSlices"
                              (change)="explodeSlices = !explodeSlices">Explode Slices</mat-checkbox>
                          <mat-checkbox color="primary" class="example-margin" [checked]="showLabels"
                              (change)="showLabels = !showLabels">Show Labels</mat-checkbox>
                      </div> -->
                      <ngx-charts-pie-chart [scheme]="colorScheme" [results]="single" [legend]="showPieLegend" [legendPosition]="legendPosition"
                          [explodeSlices]="explodeSlices" [labels]="showLabels" [doughnut]="doughnut" [trimLabels]="trimLabels" (select)="onSelect($event)">
                      </ngx-charts-pie-chart>
                  </div>
              </mat-card>
          </div>

          <div class="col-xs-12 col-lg-6">
              <mat-card class="schoolChartCard">
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="bookmarkDashboard">bookmark</mat-icon>Overall Impact
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>

                  <div class="w-100 h-300p">
                      <div class="row">
                          <div class="col-6">
                              <p>Total Student: <b>{{overallImpact?.studentParticipants}}</b></p>
                          </div>
                          <div class="col-6">
                              <p>Total Faculty: <b>{{overallImpact?.facultyParticipants}}</b></p>
                          </div>
                      </div>
                      <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="multi2"
                          [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                          [showYAxisLabel]="showYAxisLabel"
                          [yAxisLabel]="yAxisStackedLabel" (select)="onSelect($event)">
                      </ngx-charts-bar-vertical-stacked>
                  </div>
                  <!-- <a routerLink="" class="float-right mb-4">...more</a> -->
              </mat-card>
          </div>
      </div>


      <div class="row">
          <div class="col-lg-6 col-xs-12">
              <mat-card class="schoolChartCard">
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="activityDashboard">bar_chart</mat-icon>My Activities
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>
                  <div class="w-100 h-300p">
                      <ngx-charts-bar-horizontal-2d [scheme]="barColorScheme" [results]="multi" [gradient]="barGradient"
                          [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showBarLegend" legendPosition="right"
                          [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                          [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
                      </ngx-charts-bar-horizontal-2d>
                  </div>
              </mat-card>
          </div>
          <div class="col-lg-6 col-xs-12">
              <mat-card class="schoolChartCard">
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="myImpactDashboard">multiline_chart</mat-icon>My Impact
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>

                  <!-- <div class="w-100 h-300p"> -->
                  <mat-form-field class="ml-2">
                      <mat-label>Chart For</mat-label>
                      <mat-select [(ngModel)]="selectedType" (selectionChange)="setEventwiseBarData()">
                          <mat-option value="IIC Calendar Activity">IIC Calendar Activity</mat-option>
                          <mat-option value="MIC Driven Activity"> MIC Driven Activity</mat-option>
                          <mat-option value="Self Driven Activity"> Self Driven Activity</mat-option>
                      </mat-select>
                  </mat-form-field>
                  <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                      [colors]="barChartColors" [plugins]="barChartPlugins" [legend]="barChartLegend"
                      [chartType]="barChartType">
                  </canvas>
                  <!-- </div> -->
                  <!-- <a routerLink="" class="float-right mb-4">...more</a> -->
              </mat-card>
          </div>
      </div>
      <!--
     <div class="row dashboardChartRowMargin">
        <div class="col-md-12">
          <div class="activityDashboardTitle">
            <h2>My Activity Report</h2>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-xs-12">
          <mat-card>
            <mat-card-header fxLayoutAlign="center center">
              <mat-card-subtitle>
                <h2>
                  <mat-icon class="iicCalendarActivitDashboard">speed</mat-icon>IIC Calendar Activity
                </h2>
              </mat-card-subtitle>
            </mat-card-header>
            <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [options]="options1"
              [bottomLabel]="bottomLabel" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              <p class="chartParaBottom">Minimun Threshold Value (3)</p>
              <p class="chartParaBottom1">Total Number of IIC Calendar Activity (21)</p>
            </mat-card>
        </div>
         <div class="col-lg-4 col-sm-6 col-xs-12">
          <mat-card>
            <mat-card-subtitle>
              <h2>
                <mat-icon class="micDrivenDashboard">speed</mat-icon>MIC Driven Activity
              </h2>
            </mat-card-subtitle>
            <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue2" [options]="options2"
              [bottomLabel]="bottomLabel2" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              <p class="chartParaBottom">Minimun Threshold Value (2)</p>
              <p class="chartParaBottom1">Total Number of MIC Driven Activity (2)</p>
          </mat-card>
        </div>
         <div class="col-lg-4 col-sm-6 col-xs-12">
          <mat-card>
            <mat-card-subtitle>
              <h2>
                <mat-icon class="selfDrivenDashboard">speed</mat-icon>Self Driven Activity
              </h2>
            </mat-card-subtitle>
            <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue3" [options]="options3"
              [bottomLabel]="bottomLabel3" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              <p class="chartParaBottomSelf">Minimun Threshold Value (3)</p>
              <p class="chartParaBottomSelf1">Highest Number of Self Driven Activity (59) by</p>
              <p class="chartParaBottomSelf2">PSG College of Arts and Science</p>
          </mat-card>
        </div>
      </div>
  </div>
-->
  <!-- <div class="container mb-2">
      <div class="row">
          <div class="p-2 col-lg-4 col-sm-6 col-xs-12">
              <mat-card>
                  <mat-card-header fxLayoutAlign="center center">
                      <mat-card-subtitle>
                          <h2>
                              <mat-icon class="iicCalendarActivitDashboard">speed</mat-icon>IIC Calendar Activity
                          </h2>
                      </mat-card-subtitle>
                  </mat-card-header>
                  <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue" [options]="options1"
                      [bottomLabel]="bottomLabel" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              </mat-card>
          </div>
          <div class="p-2 col-lg-4 col-sm-6 col-xs-12">
              <mat-card>
                  <mat-card-subtitle>
                      <h2>
                          <mat-icon class="micDrivenDashboard">speed</mat-icon>MIC Driven Activity
                      </h2>
                  </mat-card-subtitle>
                  <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue2" [options]="options2"
                      [bottomLabel]="bottomLabel2" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              </mat-card>
          </div>
          <div class="p-2 col-lg-4 col-sm-6 col-xs-12">
              <mat-card>
                  <mat-card-subtitle>
                      <h2>
                          <mat-icon class="selfDrivenDashboard">speed</mat-icon>Self Driven Activity
                      </h2>
                  </mat-card-subtitle>
                  <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue3" [options]="options3"
                      [bottomLabel]="bottomLabel3" [bottomLabelFont]="bottomLabelFont"></rg-gauge-chart>
              </mat-card>
          </div>
      </div>
  </div> -->
</div>
