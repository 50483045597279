import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-join-us-whatsapp',
  templateUrl: './join-us-whatsapp.component.html',
  styleUrls: ['./join-us-whatsapp.component.css']
})
export class JoinUsWhatsappComponent implements OnInit {
  zone:any;
  constructor() { }

  ngOnInit(): void {
    this.zone = localStorage.getItem("InsZone");

  }

}
