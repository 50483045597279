<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">National Technology Week</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item active" aria-current="page">National Technology Week 2023</li>
  </ul>
</nav>

<div class="limiter limiterAmbasador ntw-section">
    <div class="container">
        <div class="row">
            <div class="container-login100">
                <div class="tab-box" id="newContentIncubation">
                	<div class="headingAmbassador">
						<h2>National Technology Week 2023</h2>
					</div>
                
                </div>
            </div>
        </div>
        <div class="row">
        	<div class="col-md-12 text-center mt-4 mb-2">
        		<h3>
        			AICTE, Ministry of Education pavilion at<br>National Technology Week (11th–14th May) organized at Pragati Maidan, New Delhi
        		</h3>
        	</div>
        </div>	
        <div class="row mt-3 mb-4">
        	<div class="col-md-12">
        		<img class="img-fluid tech-img" src="{{imageBasePath}}assets/wp_images/ntw/all-in-one.JPG" alt="image">
        	</div>
        </div>
        <div class="row mb-3">
        	<div class="col-md-12">
        		<p>
        			National Technology Week was organised by 11 Government departments and Ministries having their own innovation pavilions where more than 500 technology start-ups and innovations were showcased. The theme of this National Technology Week was “SCHOOL TO STARTUP, IGNITING YOUNG MINDS TO INNOVATE” and was inaugurated by our Hon'ble PM Shri Narendra Modi on 11th May 2023 at Pragati Maidan, New Delhi.AICTE and Ministry of Education had its own pavilion where 8 tracks were organised for this 4 days’ event. These 8 tracks include:
        		</p>
        	</div>	
        </div>
       <!--  <div class="row mb-4 mt-4 mt-md-0 align-items-center bg-color">
            <div class="col-md-6">
                <div class="imageBox" style="background: url('assets/wp_images/ntw/exhibition-stalls.JPG');"> </div>
            </div>
            <div class="text-box">
                <dl>
                    <dt>1.&nbsp;Exhibition stalls by IIC institutions:</dt>
                    <dd class="mt-4">Nearby IIC institutions of NCR-region displayed their banners by show casing their achievements and progress.</dd>
                </dl>
            </div>
        </div> -->
        <div class="row mb-4 align-items-center bg-color">
            <div class="imageBox" style="background: url('assets/wp_images/ntw/exhibition-stall-institute.png');">
                
            </div>
            <div class="text-box">
                <dl>
                    <dt>1.&nbsp;Exhibition stalls by IIC institutions:</dt>
                    <dd class="mt-4">Nearby IIC institutions of NCR-region displayed their banners by show casing their achievements and progress.</dd>
                </dl>
            </div>
        </div>  
         <div class="row mb-4 align-items-center">
           <!--  <div class="imageBox" style="background: url('assets/wp_images/ntw/exhibition-stalls.JPG');"> </div>
            <div class="text-box">
               <dl>
                    <dt>3.&nbsp;Exhibition stalls by Innovators/startups: </dt>
                    <dd class="mt-4">50 Innovations and startups which were funded by MoE and AICTE were demonstrated and showcased.</dd>
                </dl>
            </div> -->

            
            <div class="col-md-6">
                <dl>
                    <dt>2.&nbsp;Exhibition stalls by Innovators/startups: </dt>
                    <dd class="mt-4">50 Innovations and startups which were funded by MoE and AICTE were demonstrated and showcased.</dd>
                </dl>
            </div>
            <div class="col-md-6">
                <img class="img-fluid tech-img" src="assets/wp_images/ntw/exhibition-stalls-1.JPG" alt="image">
            </div>
            


        </div>
        <div class="row mb-4 align-items-center bg-color">
            <div class="imageBox" style="background: url('assets/wp_images/ntw/idea-labs.JPG');">
                
            </div>
            <div class="text-box">
                <dl>
                    <dt>3.&nbsp;AICTE Idea Labs:</dt>
                    <dd class="mt-4">Learning experiential tracks of idea lab demo space.</dd>
                </dl>
            </div>
            <!-- <div class="col-md-6">

                <dl>
                    <dt>1.&nbsp;AICTE Idea Labs:</dt>
                    <dd class="mt-4">Learning experiential tracks of idea lab demo space.</dd>
                </dl>
            </div>
            <div class="col-md-6">
                <img class="img-fluid tech-img" src="assets/wp_images/ntw/idea-labs.JPG" alt="image">
            </div> -->
        </div>	
         <div class="row mb-4 align-items-center">
            <div class="col-md-6">
                <dl>
                    <dt>4.&nbsp;IKS, e-Kumbh & Anuvadini:  </dt>
                    <dd class="mt-4">Displayed the IKS & e-Kumb books and Anuvadini AI tools.</dd>
                </dl>
            </div>
         	<div class="col-md-6">
        		<img class="img-fluid tech-img" src="assets/wp_images/ntw/IKS.JPG" alt="image">
        	</div>
        	
        </div>

       
         <div class="row mb-4 align-items-center bg-color">
         	<div class="imageBox" style="background: url('assets/wp_images/ntw/center-stage.JPG');">
                
            </div>
            <div class="text-box">
               <dl>
                    <dt>5.&nbsp;Centre stage:</dt>
                    <dd class="mt-4">Featured some good innovations / startups storytelling sessions, TED talks, crossword puzzle and quiz round.</dd>
                </dl>
            </div>

        	<!-- <div class="col-md-6">
        		<dl>
        			<dt>4.&nbsp;Centre stage:</dt>
	        		<dd class="mt-4">Featured some good innovations / startups storytelling sessions, TED talks, crossword puzzle and quiz round.</dd>
        		</dl>
        	</div>
            <div class="col-md-6">
                <img class="img-fluid tech-img" src="assets/wp_images/ntw/center-stage.JPG" alt="image">
            </div> -->



        </div>
        
        <div class="row mb-4 align-items-center">

            <!-- <div class="imageBox" style="background: url('assets/wp_images/ntw/Investor-Pitch-2.png');">
                
            </div>
            <div class="text-box">
               <dl>
                    <dt>5.&nbsp;Investor’s pitch:</dt>
                    <dd class="mt-4">It was a close room presentation cum pitching session for innovators/startup founders with the VC & Angel Experts. Each innovators/startup was given 15-20 minutes represent their project.The list of experts with their expertise are as follows:
                        <ul>
                            <li>Mr. Anish Suresh Pai, Investment Analyst, 9Unicorns </li>
                            <li>Mr. Kumar Dheeraj, Investments team - Deal Sourcing </li>
                            <li>Ms. Reshma Jhaveri, Head - Deal Sourcing & Evaluation</li>
                        </ul>
                    </dd>
                </dl>
            </div> -->


            <div class="col-md-6">
                <dl>
                    <dt>6.&nbsp;Investor’s pitch:</dt>
                    <dd class="mt-4">It was a close room presentation cum pitching session for innovators/startup founders with the VC & Angel Experts. Each innovators/startup was given 15-20 minutes represent their project.The list of experts with their expertise are as follows:
                        <ul>
                            <li>Mr. Anish Suresh Pai, Investment Analyst, 9Unicorns </li>
                            <li>Mr. Kumar Dheeraj, Investments team - Deal Sourcing </li>
                            <li>Ms. Reshma Jhaveri, Head - Deal Sourcing & Evaluation</li>
                        </ul>
                    </dd>
                </dl>
            </div>
         	<div class="col-md-6">
        		<img class="img-fluid tech-img" src="assets/wp_images/ntw/Investor-Pitch-2.png" alt="image">
        	</div>
        	
        </div>
        <div class="row mb-4 align-items-center bg-color" style="padding: 30px 0px;">
             <div class="imageBox" style="background: url('assets/wp_images/ntw/IP-Clinic-1.png');">
                
            </div>
            <div class="text-box text-box-1">
               <dl>
                    <dt>7.&nbsp;IP Clinics and mentoring round :  </dt>
                    <dd class="mt-4">
                        IP clinics was the close room interaction with the 22 Startups/Innovators where experts gave the one-to-one consultation to the registered innovators.The list of IP clinic experts with their expertise are as follows:
                        <ul>
                            <li>Ms. Reema Sahni Mediratta, Sr. Pr. Manager, i-TTO, FITT, IIT Delhi</li>
                            <li>Ms. Pragati Aggarwal, Registered Indian Patent Agent, RTTP  </li>
                            <li>Ms. S. Kiran, Technology officer-IP, FITT, IIT Delhi </li>
                            <li>Ms. Ridhima Wali, Project Officer, IP& TT. FITT, IIT Delhi</li>
                        </ul>
                        Also there was the stall visit & on-spot mentoring to Startups to understand their business and technology development strategies.The mentors list of with their expertise are as follows:
                        <ul>
                            <li>Mr. Girish Shivani, Executive Director and Fund Manager </li>
                            <li>Mr. Naresh Priyadarshi, CEO, IIITD Innovation & Incubation Center </li>
                            <li>Ms. Smita Mishra, Founder & CEO, Fandoro Technologies </li>
                            <li>Mr. Amit Kumar Verma, TIE, New Delhi</li>
                        </ul>

                    </dd>
                </dl>
            </div>

            
            <!-- <div class="col-md-6">
                <img class="img-fluid tech-img" src="assets/wp_images/ntw/IP-Clinic-1.png" alt="image">
            </div>
            <div class="col-md-6">
                <dl>
                    <dt>7.&nbsp;IP Clinics and mentoring round :  </dt>
                    <dd class="mt-4">
                        IP clinics was the close room interaction with the 22 Startups/Innovators where experts gave the one-to-one consultation to the registered innovators.The list of IP clinic experts with their expertise are as follows:
                        <ul>
                            <li>Ms. Reema Sahni Mediratta, Sr. Pr. Manager, i-TTO, FITT, IIT Delhi</li>
                            <li>Ms. Pragati Aggarwal, Registered Indian Patent Agent, RTTP  </li>
                            <li>Ms. S. Kiran, Technology officer-IP, FITT, IIT Delhi </li>
                            <li>Ms. Ridhima Wali, Project Officer, IP& TT. FITT, IIT Delhi</li>
                        </ul>
                        Also there was the stall visit & on-spot mentoring to Startups to understand their business and technology development strategies.The mentors list of with their expertise are as follows:
                        <ul>
                            <li>Mr. Girish Shivani, Executive Director and Fund Manager </li>
                            <li>Mr. Naresh Priyadarshi, CEO, IIITD Innovation & Incubation Center </li>
                            <li>Ms. Smita Mishra, Founder & CEO, Fandoro Technologies </li>
                            <li>Mr. Amit Kumar Verma, TIE, New Delhi</li>
                        </ul>

                    </dd>
                </dl>
            </div> -->
        </div>
        <div class="row mb-4 align-items-center">
            <!--  <div class="text-box">
               <dl>
                    <dt>8.&nbsp;Digital Pavilion:  </dt>
                    <dd class="mt-4">
                        MoE and AICTE digital pavilion showcased Innovations & startup policy initiatives and achievements.
                    </dd>
                </dl>
            </div>
            <div class="imageBox" style="background: url('assets/wp_images/ntw/digital.JPG');"> </div> -->
           

        	<div class="col-md-6">
        		<dl>
        			<dt>8.&nbsp;Digital Pavilion:  </dt>
	        		<dd class="mt-4">
	        			MoE and AICTE digital pavilion showcased Innovations & startup policy initiatives and achievements.
	        		</dd>
        		</dl>
        	</div>
        	<div class="col-md-6">
        		<img class="img-fluid tech-img" src="assets/wp_images/ntw/digital.JPG" alt="image">
        	</div>
        </div>
    </div>
</div>
<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>