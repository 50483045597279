<div class="container-fluid">
  <div class="m-4 row">

    <div class="col-3">
      <mat-select placeholder="Select District" [(ngModel)]="activity_filter" (ngModelChange)="activityFilter()"
        class="instiIICcalActi" (selectionChange)="onDistrictChange($event)">
        <mat-option value="">Select District</mat-option>
        <mat-option value="{{district.district}}" *ngFor="let district of districtList">{{district.district}}</mat-option>
      </mat-select>
    </div>

    <div class="col-3">
      <mat-select placeholder="Select City" [(ngModel)]="quarter_filter" (ngModelChange)="quarterFilter()"
        class="instiIICcalActi">
        <mat-option value="">Select City</mat-option>
        <mat-option value="{{city.city}}" *ngFor="let city of cityList">{{city.city}}</mat-option>
      </mat-select>
    </div>

    <div class="col-6 example">
		  <input class="searchExter" matInput placeholder="Search Title" (keyup)="filter($event)">
		  <button type="button" class="searchButton" (click)="search()"><i class="fa fa-search" style="font-size: 28px;"></i></button>
    </div>
  </div>

  <div class="row m-4">
     <table class="table table-hover table-border mt-4 table-responsive">
      <thead class="facultyTable">
        <tr>
          <td colspan="7" allign="left" class="danger "
            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Choose ATL Schools</h1>
            <p style="text-align:center; padding-top: 5px; font-size: 18px; font-weight: bold;">Please shortlist 3 ATL Schools. Prescribed Activity will be enabled shortly.</p>
          </td>
        </tr>
        <tr>
          <th width="5%">S.No.</th>
          <th width="10%">School Code</th>
          <th width="20%">School Name</th>
          <th width="25%">Address</th>
          <th width="20%">Email</th>
          <th width="15%">Mobile</th>
          <th width="5%">Action</th>
        </tr>
      </thead>
      <tbody>
		<tr *ngFor="let activity of myActivity;let i = index">
    <td>{{i+1}}</td>
    <td>{{activity?.atl_code}}</td>
    <td>{{activity?.school_name}}</td>
    <td>{{activity?.address}}<br/>{{activity?.city}}<br/>{{activity?.district}}</td>
    <td>{{activity?.email}}</td>
    <td>{{activity?.contact_no}}</td>
    <td>
      <div *ngIf='activity?.join_status==0'>
        <p *ngIf='activity?.approval_status==0'>
          <button mat-button  (click)="onSubmitClick(activity, '0')" style="
                background-color: green; color: white;">
            Choose to Mentor
          </button>
        </p>

        <p *ngIf='activity?.approval_status>0' style="padding-top: 10px;">
          <button mat-button (click)="onSubmitClick(activity, '1')" style="
                background-color: red; color: white;">
            Remove 
          </button>
        </p>

        <p *ngIf='activity?.approval_status>0'>
          <button mat-button  (click)="onSubmitClick(activity, '2')" style="
                background-color: green; color: white;">
            Send Reminder Email
          </button>
        </p>
    </div>

    <div *ngIf='activity?.join_status==1' class="alert alert-success" style="text-align: center; padding: 5px 10px !important; margin-top: 10px; color: #155724;
    background-color: #d4edda; border-color: #c3e6cb;" role="alert">
      Joined
    </div>

    </td>
    </tr>

    <tr *ngIf="total_pages==0"><td colspan="7" style=" font-size:18px; text-align: center; color: #721c24;
      background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
      There is no school found.
    </td></tr>



      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
        [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
      </mat-paginator>
    </div>
  </div>
</div>





<!-- Table -->


<!-- <div class="form-group">

  <div class="container mt-3">


    <table class="table table-hover mt-4">
      <thead class="facultyTable">
        <tr>
          <td colspan="3" allign="left" class="danger "
            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">My Activity</h1>
          </td>
        </tr>


        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Title</th>
          <th scope="col">Quarter</th>
          <th scope="col">Activity Type</th>
          <th scope="col">View Details</th>
          <th scope="col">Report</th>
          <th scope="col">Status</th>
          <th scope="col">Comment</th>
          <th scope="col">Download Report</th>
        </tr>
      </thead>
      <tbody>
		<tr *ngFor="let activity of myActivity;let i = index">
<td>{{i+1}}</td>
<td>{{activity?.title}}</td>
<td>{{activity?.quarter}}</td>
<td>{{activity?.type}}</td>
<td >
  <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
  (click)='openDialog(activity)'>View Details</button>
</td>
<td *ngIf="activity.url != ''">
  <button *ngIf="activity.url == 'monthly_report'"
  class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"><a class="activity_upload_btn"
    target="_blank" style="color: white;"
    href="/institute/activity/monthly_report/{{activity.eventId}}?action={{activity.action}}&instid={{activity.institute_id}}">
    {{activity.label}}</a>
</button>

<button *ngIf="activity.url == 'monthly-report-of-council'"
  class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">
  <a target="_blank" class="activity_upload_btn"
    href="/institute/activity/monthly-report-of-council/{{activity.eventId}}?action={{activity.action}}&instid={{activity.institute_id}}"
    class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">{{activity.label}}</a>
</button></td>

<td *ngIf="activity.url == ''">
  <button class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">
    <a style="color: white;" href="javascript:void(0)"> {{activity.label}} </a>
  </button>
</td>
<td>{{activity?.status}}</td>
<td *ngIf="activity.comments != null">{{activity?.comments}}</td>
<td *ngIf="activity.comments == null">NA</td>

<td  *ngIf="activity.download_url != ''">
  <button type="button" (click)="downloadReport(activity.download_url,activity.eventId)"
  class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG buttonSmallFont">Download
  Report</button>
</td>
<td  *ngIf="activity.download_url == ''">
NA
</td>
	</tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-md-12">
        <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
          [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
        </mat-paginator>

      </div>
    </div>
</div>
</div> -->







