  <mat-dialog-content class="mat-typography">

    <div class="container">

        <div class="row perInformation2">
            <div class="col-md-12">
                <h3 class="pInfor" style="font-weight: bold;">I give my confirmation to participate in IIC 4.0</h3>
            </div>
        </div>

        <form [formGroup]="signupForm" (ngSubmit)="onSubmit(signupForm.value)">
          <div class="form-group">

            <div class="row">
              <div class="col-6">
                Participants
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="longs">
                  <mat-label>Participants</mat-label>
                  <input matInput formControlName="student" placeholder="Participants"  type="number" >
                  <mat-error *ngIf="signupForm.controls['student'].hasError('required')">Participants field is required</mat-error>
                </mat-form-field>
              </div>
            </div>


            <div>

              <div class="row">
                  <div class="col-6">
                      <label>Upload Photo</label>
                      <mat-error>JPG(max 2MB)</mat-error>
                  </div>

                  <div class="col-6">
                      <label class="custom-file-label float-right" for="customFile"
                      *ngIf="!ipr_document">Choose file</label>

                      <label class="custom-file-label float-right" for="customFile"
                          *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>

                      <input #ipr_documentRef type="file" accept="image/png, image/jpeg, image/jpg" name="ipr_document"
                          (change)="( ipr_document = ipr_documentRef.files )"
                          class="custom-file-input float-right" required>
                </div>

            </div>


            </div>

          </div>

          <mat-dialog-actions>
            <button mat-raised-button color='primary' class="float-right"
              [disabled]='signupForm.invalid'>Submit</button>
          </mat-dialog-actions>

        </form>
    </div>
    <!-- </mat-card> -->
</mat-dialog-content>
<!-- </section> -->
