import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  EmailValidator,
} from '@angular/forms';

@Component({
  selector: 'app-institute-activity-prototype',
  templateUrl: './institute-activity-prototype.component.html',
  styleUrls: ['./institute-activity-prototype.component.css']
})
export class InstituteActivityPrototypeComponent implements OnInit {
  editPossible:boolean=true
  public activityForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }
  editRow(){

  }
  onSubmit(val){

  }
  updatePOC(){
    
  }

}
