import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-atl-institute-list',
  templateUrl: './atl-institute-list.component.html',
  styleUrls: ['./atl-institute-list.component.css']
})
export class AtlInstituteListComponent implements OnInit {

  public iicImpactQuestions: FormGroup;
  coordinatorDetail:any;
  checkStatus:any;
  allCities:any;

  constructor(
    private activityService: InstituteActivityService,private toastr: ToastrService,private fb: FormBuilder

  ) { }

  ngOnInit(): void {

    this.activityService.atlInstituteDetails().subscribe(
      (data) => this.handleResponse(data)
    );
    this.activityService.getAtlCityDistrictList('all').subscribe(
      (data) => this.handleResponseDistrict(data)
    );

    this.coordinatorDetail = "";
    this.iicImpactQuestions = this.fb.group({
      'institute_id': [null],
      'institute_name': [null],
      'city': [null]
    });

  }

  handleResponse(data)
  {
    this.coordinatorDetail = data.altInstituteDetails;
    this.checkStatus = data.checkStatus;
  }
  handleResponseDistrict(data)
  {
    this.allCities = data.districts;
  }

  onSubmitClick(data, type){
    this.activityService.mentorInstituteAtlSelection(data.id, type).subscribe(
      data => this.handleResponse2(data)
    );
  }

  handleResponse2(data){
    if(data['status']=='1'){
      alert(data['message']);
      this.activityService.atlInstituteDetails().subscribe(
        (data) => this.handleResponse(data)
      );
      // window.location.href='/institute/atl-institute-list';
    }else{
      this.toastr.error(data['message'])

    }
  }

  onSubmit(data) {

    // var formData = new FormData();

    // formData.append('institute_id', this.iicImpactQuestions.get('institute_id').value);
    // formData.append('institute_name', this.iicImpactQuestions.get('institute_name').value);
    this.activityService.mentorInstituteAtlSelectionFilter(this.iicImpactQuestions.get('institute_id').value,this.iicImpactQuestions.get('institute_name').value,this.iicImpactQuestions.get('city').value).subscribe(
      data => this.handleResponseData(data));

  }
  handleResponseData(data){
    this.coordinatorDetail = data.altInstituteDetails;
  }
}
