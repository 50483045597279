import { Component, OnInit } from '@angular/core';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-online-session-list',
  templateUrl: './online-session-list.component.html',
  styleUrls: ['./online-session-list.component.css']
})
export class OnlineSessionListComponent implements OnInit {
  nominationArray:any;

  constructor(private innovationService: InstituteInnovationContestService) { }

  ngOnInit(): void {

    this.innovationService.iaSessionList().subscribe(
      data => this.handleResponse(data)
    );

  }

  handleResponse(data) {
    this.nominationArray = data['data'];
  }

}
