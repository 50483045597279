<h1 mat-dialog-title class="text-center instStuDetailsTitle">Orientation cum Mentoring Sessions</h1>
<div mat-dialog-content>
  <div class="container">
    <form [formGroup]="addActivityForm1" (submit)="onSubmit(addActivityForm1.value)">

    <p>{{editData?.instituteName}}</p>

    <div class="row">

	  <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">

    <mat-form-field appearance="outline" class="form1Seassion">
      <mat-label>Session Mode</mat-label>
      <mat-select placeholder="Session Mode" formControlName="session_mode" required (selectionChange)="onStatusChange3($event,'2')">
        <mat-option value="Offline">Offline</mat-option>
        <mat-option value="Online">Online</mat-option>
      </mat-select>
      <mat-error *ngIf="addActivityForm1.controls.session_mode.errors?.required">Session is required
      </mat-error>

    </mat-form-field>

  </div>

  <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Title</mat-label>
      <input matInput readonly autocomplete="off" readonly placeholder="Title" formControlName="title" type="text" required>
      <mat-error *ngIf="addActivityForm1.controls.title.errors?.required">Title is required</mat-error>
    </mat-form-field>
</div>


	  </div>
<!-- Row Ends  -->

	  <div class="row">

        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">

        <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label>Tentative date</mat-label>
            <input matInput readonly [matDatepicker]="picker1" formControlName="session_date" [min]="minDate" [max]="maxDate" >
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        </div>

		<div  fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
        <mat-label>Tentative time</mat-label>
        <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>

        <input readonly [ngxTimepicker]="picker" formControlName="session_time" >
        <ngx-material-timepicker #picker placeholder="Session Time"  ></ngx-material-timepicker>
</div>


      </div>

<div class="row">

<div fxFlex="50" fxFlex.lt-sm="100" class="col-md-12"  [ngClass]="show_field_2=='1' ? 'irshowtr' : 'irhidetr'">
    <mat-form-field appearance="outline" class="form1Seassion">
      <!-- <mat-label>Meeting ID</mat-label> -->
      <mat-label>Online session link</mat-label>
      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <input matInput placeholder="Meeting URL" formControlName="meeting_url">
    </mat-form-field>
  </div>

  <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-12"  [ngClass]="show_field_3=='1' ? 'irshowtr' : 'irhidetr'">
    <mat-form-field appearance="outline" class="form1Seassion">
      <mat-label>Venue Details</mat-label>
      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <input matInput placeholder="Venue Details" formControlName="venue_details">
    </mat-form-field>
  </div>
</div>

<div class="row">
  <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-12">
    <mat-form-field appearance="outline" class="form1Seassion">
      <mat-label>Coordinator</mat-label>
      <mat-select placeholder="Coordinator" formControlName="coordinator" required>
        <mat-option class="drList" value="{{mb.id}}" *ngFor="let mb of members">{{mb.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="addActivityForm1.controls.coordinator.errors?.required">Coordinator is required
      </mat-error>
    </mat-form-field>
    </div>
  </div>


<div class="row">
		<div class="col-md-12">
      <button mat-raised-button color='primary' class="btn btn-success form1But"
        [disabled]='addActivityForm1.invalid'>Submit</button>
		</div>
      </div>
    </form>
  </div>

</div>
