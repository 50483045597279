import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-atl-mentor-of-change',
  templateUrl: './atl-mentor-of-change.component.html',
  styleUrls: ['./atl-mentor-of-change.component.css']
})
export class AtlMentorOfChangeComponent implements OnInit {

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicrepository:any;

  constructor(private fb: FormBuilder, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.requireField=0;

    this.instituteActivityService.getMentorofChange().subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.iicrepository = data['data'];
  }

  onClickEvent(data,type){

    if(type=="Edit")
    {
      window.location.href="/institute/repository-add/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.deleteIicRepositoryDetails(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }

  }

  handleResponse1(data)
  {

  }


}
