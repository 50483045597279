 <!--title-->
   <title>

     Why HEIs should Join ICs Network? | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>


<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Innovation Council</a>
      </li>
      <li class="aht-breadcrumbs__item">
        Why He Is
      </li>
    </ul>
  </div>
	
	<section class="paddsection">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="titleheadertop">

                  <h1 class="inner-theme-heading">Why HEIs should Join ICs Network? </h1>

                </div>

            </div>

        </div>



          <div class="row marwgy">

            <div class="col-md-6">

                <div class="whyicc">

                <h3>Institutions’ innovation council</h3>

                <img src="{{imageBasePath}}assets/wp_images/map_heis.jpg">     

            </div>

            </div>



         <div class="col-md-6">

            <div class="listulwhy">

                 <ul>
<li>No major capital investment required and IC will make use of existing local ecosystem</li>
<li>Students/Faculty associated with ICs will have exclusive opportunity to participate in various Innovation related initiative and competitions organized by MHRD</li>
<li>Win exciting prizes/Certificates every month</li>
<li>Meet/Interact renowned Business Leaders and top-notch academicians</li>
<li>Opportunity to nurture and prototype new ideas</li>
<li>Mentoring by industry Professionals</li>
<li>Experiment with new technologies</li>
<li class="liborder">Visit new places and see new culture</li>
</ul>

            </div>

          </div>

      </div>

        </div>

    </section>


<!-- Footer -->
<app-inner-footer></app-inner-footer>