import { Component, OnInit, Inject, Optional } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  EmailValidator,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../../../../app.component';

@Component({
  selector: 'app-institute-external-dialog',
  templateUrl: './institute-external-dialog.component.html',
  styleUrls: ['./institute-external-dialog.component.css'],
})
export class InstituteExternalDialogComponent implements OnInit {

  baseImageUrl = myGlobals.baseImageUrl;
  public externalForm: FormGroup;

  externalCV: FileList | null;

  show1: boolean;
  show: boolean;
  externalName: any;
  externalQualification: any;
  fbl: any;
  id1: any;
  externalyears: any;
  member: any;
  studentsEmail: any;
  studentsContact: any;
  externalOrganisation: any;
  externalDesignation: any;
  externalRoles: any;
  nameExternalCV: any;
  qualificationModel: any;
  role: any;
  showCV: boolean;
  gender: any;
  menteeStatus:any;
  MentorList:any;
  MentorData:any;
  nameField:any;

  constructor(
    private fb: FormBuilder,
    private jarwis: JarwisService,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteExternalDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
      if (this.data['CV_upload'] != null) {
        this.showCV = true;
      } else {
        this.showCV = false;
      }
    }
  }

  ngOnInit(): void {

    this.menteeStatus = 0;
    this.nameField = 0;

    this.germenteeData();

    this.externalForm = this.fb.group({
      externalName: [null, Validators.compose([Validators.required])],
      studentsEmail: [
        null,
        Validators.compose([Validators.required, Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
        ),
      ],
      studentsContact: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
      externalDesignation: [null, Validators.compose([Validators.required])],
      externalOrganisation: [null, Validators.compose([Validators.required])],
      externalQualification: [null, Validators.compose([Validators.required])],
      member: [null, Validators.compose([Validators.required])],
      externalyears: [null, Validators.compose([Validators.required])],
      externalRoles: [null, Validators.compose([Validators.required])],
      externalCV: [null],
      id: [null],
      gender: [null, Validators.compose([Validators.required])],
    });


    this.fbl = this.data;
    this.externalName = this.data['name'];
    this.studentsEmail = this.data['email'];
    this.studentsContact = this.data['mobile'];
    this.externalDesignation = this.data['designation'];
    this.externalOrganisation = this.data['organisation'];
    this.externalQualification = this.data['qualification'];
    this.id1 = this.data['id'];
    this.externalyears = this.data['experience_in_years'];
    this.member = this.data['members'];
    this.externalRoles = this.data['externalRoles'];
    this.externalCV = this.data['externalCV'];
    this.role = this.data['role'];
    this.gender = this.data['gender'];
    this.qualificationModel = this.data['qualification'];

    if (this.data['qualification'] == "Post Graduate") {
      this.qualificationModel = "1";
    } else if (this.data['qualification'] == "Doctorate") {
      this.qualificationModel = "2";
    } else if (this.data['qualification'] == "Post Doctorate") {
      this.qualificationModel = "3";
    } else if (this.data['qualification'] == "Graduate") {
      this.qualificationModel = "4";
    }


    this.externalForm.controls['externalName'].setValue(this.externalName);
    this.externalForm.controls['studentsEmail'].setValue(this.studentsEmail);
    this.externalForm.controls['studentsContact'].setValue(this.studentsContact);
    this.externalForm.controls['externalDesignation'].setValue(this.externalDesignation);
    this.externalForm.controls['externalOrganisation'].setValue(this.externalOrganisation);
    // this.externalForm.controls['nameExternalCV'].setValue(this.nameExternalCV);
    this.externalForm.controls['externalQualification'].setValue(this.externalQualification);
    this.externalForm.controls['externalyears'].setValue(this.externalyears);
    this.externalForm.controls['member'].setValue(this.member);
    this.externalForm.controls['externalRoles'].setValue(this.externalRoles);
    this.externalForm.controls['id'].setValue(this.id1);
    this.externalForm.controls['externalCV'].setValue(this.externalCV);
    this.externalForm.controls['externalRoles'].setValue(this.role);
    this.externalForm.controls['gender'].setValue(this.gender);

    if(this.role=="From_Mentor_institute")
    {

    }

  }

  germenteeData(){
    this.myCouncilService.getInstituteMenteeStatus().subscribe(
      data => this.handleResponseMentee(data)
    );
  }

  handleResponseMentee(data)
  {
    this.menteeStatus = data['MenteeStatus'];
  }


  onSubmit(values) {

    var cvFile =
      this.externalCV && this.externalCV.length ? this.externalCV[0] : null;

    var formData = new FormData();

    formData.append('externalCV', cvFile);
    formData.append(
      'externalName',
      this.externalForm.get('externalName').value
    );
    formData.append(
      'studentsEmail',
      this.externalForm.get('studentsEmail').value
    );
    formData.append(
      'studentsContact',
      this.externalForm.get('studentsContact').value
    );
    formData.append(
      'externalDesignation',
      this.externalForm.get('externalDesignation').value
    );
    formData.append(
      'externalOrganisation',
      this.externalForm.get('externalOrganisation').value
    );
    formData.append(
      'externalQualification',
      this.externalForm.get('externalQualification').value
    );
    formData.append('member', this.externalForm.get('member').value);
    formData.append(
      'externalyears',
      this.externalForm.get('externalyears').value
    );
    formData.append(
      'externalRoles',
      this.externalForm.get('externalRoles').value
    );
    formData.append(
      'externalRoles',
      this.externalForm.get('externalRoles').value
    );
    formData.append('gender', this.externalForm.get('gender').value
    );
    if (this.show1 == true) {
      this.myCouncilService
        .submitExternal(formData)
        .subscribe((data) => this.handleResponse(data));
    }
    else {

      this.myCouncilService.updateExternal(formData, values['id']).subscribe(
        data => this.handleResponse1(data)
      );
    }

  }

  handleResponse1(data) {
    if (data['status'] == "1") {
      this.toastr.success(" Updated Successfully !");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
    this.myCouncilService.dataUpdated.next(true);
  }


  handleResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success('Member Added Successfully !');
      this.close();
    } else {
      this.toastr.error(data['msg']);
    }
    this.myCouncilService.dataUpdated.next(true);
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

  isDecimal(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 46) return true;

    return false;
  }

  onMemberRoleChange(ob) {
    // let mentorStatus = ob.value;
    // if(mentorStatus == 'From_Mentor_institute') {
    //   this.nameField = true;

    //   this.myCouncilService.getInstituteMentorMembers().subscribe(
    //     data => this.handleResponseMentor(data)
    //   );

    // } else {
    //   this.nameField = false;
    // }

  }

  handleResponseMentor(data)
  {
    this.MentorList = data['MentorList'];
  }

  onMemberdataChange(ob) {
    let mentorStatus = ob.value;
    this.myCouncilService.getInstituteMembersData(mentorStatus).subscribe(
      data => this.handleResponseSetMentor(data)
    );
  }

  handleResponseSetMentor(data)
  {

    this.externalForm.controls['studentsEmail'].setValue(data['data']['email']);
    this.externalForm.controls['studentsContact'].setValue(data['data']['mobile']);
    this.externalForm.controls['externalDesignation'].setValue(data['data']['designation']);
    this.externalForm.controls['externalOrganisation'].setValue(data['data']['organization']);
    this.externalForm.controls['externalQualification'].setValue(data['data']['qualification']);
    this.externalForm.controls['externalyears'].setValue(data['data']['experience_years']);
    this.externalForm.controls['gender'].setValue(data['data']['gender']);
    this.externalForm.controls['member'].setValue(data['data']['memberType']);
  }

  close() {
    this.dialogRef.close();
  }
}
