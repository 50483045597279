<link rel='stylesheet' id='skilled-style-css'  href='/assets/assets/css/responsive.css' type='text/css' media='all' />
<link rel='stylesheet' id='skilled-style-css'  href='/assets/assets/css/customAll.css' type='text/css' media='all' />
<link rel='stylesheet' id='skilled-style-css'  href='/assets/assets/css/style.css' type='text/css' media='all' />

<section class="onlineSessionNavBox">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-2 logoBg">
				<figure class="wpb_wrapper vc_figure">
					<a routerLink="/"><img class=" vc_single_image-img topLogo MobResOnlineSession" src="assets/assets/images/iiclogo.png" alt="Logo"/></a>
				</figure>
			</div>
			<div class="col-md-8">
				<div class="onlineHeadingContent">
					<h2>Institution’s Innovation Council</h2>
					<p>Online Training sessions</p>
				</div>
			</div>
			<div class="col-md-2">
				<div class="row">
					<div class="col-md-6">
						<div class="onlineLogoutButGreen">
							<a routerLink="/osia-request-reset" class="dropdown-item"  style="cursor: pointer">Reset Password</a>
						</div>
					</div>
					<div class="col-md-6">
						<div class="onlineLogoutBut resetButLogout">
							<a class="dropdown-item" (click)="logout()" style="cursor: pointer">Logout</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>



