
<div class="container">
  <div class="instMyCouncilModal">
      <div class="container">
        <div class="row">
          <div class="col-md-12"
            style="background-color: #fff;color: #000 !important;font-size: 27px;">
            <p class="hdr bifuracationPara">Performance Report for the Academic Year 2021-22</p>
            <div style="padding: 0 0 20px 0; margin: 0 0 20px 0;">
              <ul>
                <!-- <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Performance Report for the Academic Year 2021-22</b></li> -->
                <!-- <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">Bifurcation of Score and Reward Points</li> -->
                <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">
                  <div class="row">
                    <div class="col-md-12 NewScoreList">
                      <span class="mic_btn mic_totalmarks">Total Score: {{formData?.total_marks}}/100</span>
                      <span class="mic_btn mic_rewardpoints">Total Reward: {{formData?.totalRewards}}</span>
                      <!-- <span class="mic_btn mic_rating1">Rating: {{formData?.rating}}/4</span>
                      <span class="mic_btn mic_rating2">Fifth Star Rating: {{fstarScore?.star_rating}}/1</span> -->
                      <span class="mic_btn mic_rating">Final Star: {{formData?.final_star}}/5</span>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- <p style="line-height:1.1; text-align:center;">Scores are calculated based on the minimum prescribed activities in each “activity type”. IIC-Institute earn Reward Points for conducting more than prescribed minimum activities under each “activity type”, which is effective towards 5th star</p> -->

              <table id="scoreActivity" class="table table-hover" style="margin-bottom: 0px;border:1px solid #ffffff;">
                <tbody style="text-align: center;">
                <tr>
                  <td style="text-align:center; vertical-align:top;"><strong>Activity Category</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total No of Submited Activities&nbsp;</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total No of Approved Activities&nbsp;</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>*Disapproval %&nbsp; of Activities</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total Score Earned&nbsp; for Q1-Q4 Activities (I = A*80%)</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total Score Earned&nbsp; from Participation (II = B*20%)</strong></td>
                  <td style="text-align:center; vertical-align:top;"><strong>Total Score Earned&nbsp; (I + II = 100)</strong></td>
                </tr>
                <tr>
                  <td style="text-align:center; vertical-align:top;">IIC Calendar Activity</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.iicAll}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.iicAllApproved}}</td>
                  <td rowspan="5" style="text-align:center; vertical-align:top;">{{formData?.disapproved_percentage}}%</td>
                  <td rowspan="5" style="text-align:center; vertical-align:top;">{{formData?.a80}}</td>
                  <td rowspan="5" style="text-align:center; vertical-align:top;">{{formData?.b20}}</td>                  
                  <td rowspan="5" style="text-align:center; vertical-align:top;">{{formData?.awithb}}</td>
                </tr> 
                <tr>
                  <td style="text-align:center; vertical-align:top;">MIC driven Activity</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.micAll}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.micAllApproved}}</td>
                </tr>
                <tr>
                  <td style="text-align:center; vertical-align:top;">Self-driven Activity</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.selfAll}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.selfAllApproved}}</td>
                </tr>
                <tr>
                  <td style="text-align:center; vertical-align:top;">Celebration Activity</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.celAll}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.celAllApproved}}</td>
                </tr>
                <tr>
                  <td style="text-align:center; vertical-align:top;"><strong>Total</strong></td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.TotalActivitySubmitted}}</td>
                  <td style="text-align:center; vertical-align:top;">{{formData?.total_approved}}</td>                  
                </tr>
                <tr>
                  <td colspan="7" style="text-align:left; vertical-align:middle;">*Propotionate Reduction in the Reward Points Based on Disapproval % ** No Reward Points upon Crossing the total no of Disapproval Activities Percentage of 30% or More.						
                  </td>
                </tr>
              </tbody>
            </table>

            <table style="text-align: center;" class="table table-hover">
              <tbody>
                  <tr>
                      <td colspan="7"><strong>Table A: Unit Score/Activity (Q1-Q4)</strong></td>
                  </tr>
                  <tr>
                      <td rowspan="2"><strong>Activity Category</strong></td>
                      <td rowspan="2"><strong>Max Score/Category</strong></td>
                      <td rowspan="2"><strong>Threshold No of Activties/Category</strong></td>
                      <td rowspan="2"><strong>Unit Score/Activity&nbsp;</strong></td>
                      <td colspan="2"><strong>Unit score per Activity</strong></td>
                      <td rowspan="2"><strong>Score Earned (A)</strong></td>
                  </tr>
                  <tr>
                      <td><strong>Offline(100%)</strong></td>
                      <td><strong>Online (75%)</strong></td>
                  </tr>
                  <tr>
                      <td>IIC&nbsp; CalendarActivities</td>
                      <td>50</td>
                      <td>12</td>
                      <td>4.17</td>
                      <td>4.17</td>
                      <td>3.13</td>
                      <td>{{formData?.iic_section_score_final}}</td>
                  </tr>
                  <tr>
                      <td>Self-Driven Activities</td>
                      <td>30</td>
                      <td>12</td>
                      <td>2.50</td>
                      <td>2.50</td>
                      <td>1.88</td>
                      <td>{{formData?.self_section_score_final}}</td>
                  </tr>
                  <tr>
                      <td>MIC Driven Activities</td>
                      <td>10</td>
                      <td>9</td>
                      <td>1.11</td>
                      <td>1.11</td>
                      <td>0.83</td>
                      <td>{{formData?.mic_section_score_final}}</td>
                  </tr>
                  <tr>
                      <td>Celebration Activities</td>
                      <td>10</td>
                      <td>4</td>
                      <td>2.50</td>
                      <td>2.50</td>
                      <td>1.88</td>
                      <td>{{formData?.cele_section_score_final}}</td>
                  </tr>
                  <tr>
                      <td><strong>Total</strong></td>
                      <td>100</td>
                      <td><br></td>
                      <td><br></td>
                      <td><br></td>
                      <td><br></td>
                      <td>{{formData?.all_section_score_final}}</td>
                  </tr>
              </tbody>
          </table>
            
          <table class="table table-hover">
            <tbody>
                <tr>
                    <!-- <td><strong>B</strong></td> -->
                    <td colspan="6" style="text-align: center;" ><strong>Table B: Score for the IIC's Participation in Various Programs</strong></td>
                </tr>
                <tr>
                    <td colspan="4" ><strong>Incentive Parameters</strong></td>
                    <td ><strong>Weightage</strong></td>
                    <td ><strong>Score Obtained</strong></td>
                </tr>
                <tr>
                    <td colspan="4">1. Redeeming of Reward Points Earned by the IIC (Upto 500 points)</td>
                    <td >10%</td>
                    <td ><span *ngIf="fstarScore?.reward_score">{{fstarScore?.reward_score}}</span>
                      <span *ngIf="!fstarScore?.reward_score">0</span></td>
                </tr>
                <tr>
                    <td rowspan="4">2. IIC Created YUKTI - Inovation Repository at the Institute level.&nbsp;</td>
                    <td colspan="3">i.&nbsp; Scouted and Verified Minimum of 50&nbsp; Ideas/PoCs</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.idea_relative_score">{{fstarScore?.idea_relative_score}}</span>
                      <span *ngIf="!fstarScore?.idea_relative_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">ii. Scouted and Verified Minimum of 25 Innovations/Prototypes</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.innovation_relative_score">{{fstarScore?.innovation_relative_score}}</span>
                      <span *ngIf="!fstarScore?.innovation_relative_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">iii. Scouted and Verified Minimum of 15 Startups (Registered Startups but Not recognized by DPIIT)&nbsp;</td>
                    <td >10%</td>
                    <td ><span *ngIf="fstarScore?.non_dpit_score">{{fstarScore?.non_dpit_score}}</span>
                      <span *ngIf="!fstarScore?.non_dpit_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">iv. Scouted and Verfied Minimium of 5 Registered Startups Recognized by DPIIT (Submitted Document of Proof of Recognition)&nbsp;</td>
                    <td >10%</td>
                    <td ><span *ngIf="fstarScore?.dpit_startup_score">{{fstarScore?.dpit_startup_score}}</span>
                      <span *ngIf="!fstarScore?.dpit_startup_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">3.&nbsp; Innovations from IIC institutions Received Funding Support from MIC (Winners of SIH 2022 - 2%, YUKTI NIC 2020 Innovations - 8%)</td>
                    <td >10%</td>
                    <td ><span *ngIf="fstarScore?.nic_team_bplan_score">{{fstarScore?.nic_team_bplan_score}}</span>
                      <span *ngIf="!fstarScore?.nic_team_bplan_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">4. IIC Referred more than 5 HEIs to Join the IIC Network (Upto 20 referrals)</td>
                    <td >10%</td>
                    <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                      <span *ngIf="!fstarScore?.referral_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">5. IIC acted as Mentor Institute (Activities Reported through the IIC portal)&nbsp;</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.volunteer_mentor_score">{{fstarScore?.volunteer_mentor_score}}</span>
                      <span *ngIf="!fstarScore?.volunteer_mentor_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">6. Impact Lectures Organised (Sessions Reported through the IIC Portal)&nbsp;</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.impact_score">{{fstarScore?.impact_score}}</span>
                      <span *ngIf="!fstarScore?.impact_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">7. IIC has Registered and Adopted NISP (Status Updated through the NISP Portal)</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.nisp_score">{{fstarScore?.nisp_score}}</span>
                      <span *ngIf="!fstarScore?.nisp_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">8. IIC Hosted the SIH 2022 Grand Finale Event&nbsp;</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.sih_host_score">{{fstarScore?.sih_host_score}}</span>
                      <span *ngIf="!fstarScore?.sih_host_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">9. IIC Hosted the Regional Meet 2022 Event</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.rm_host_score">{{fstarScore?.rm_host_score}}</span>
                      <span *ngIf="!fstarScore?.rm_host_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">10. IIC with Active Faculty Innovation Ambassadors (Minimum 10&nbsp; IAs and Submitted Reports)</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.ia_training_score">{{fstarScore?.ia_training_score}}</span>
                      <span *ngIf="!fstarScore?.ia_training_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">11. IIC linked with upto 3 ATLs/SICs for Mentoring Support</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.atl_school_score">{{fstarScore?.atl_school_score}}</span>
                      <span *ngIf="!fstarScore?.atl_school_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4">12. IIC Successfully Submitted Application for the ARIIA 2022</td>
                    <td >5%</td>
                    <td ><span *ngIf="fstarScore?.ariia_score">{{fstarScore?.ariia_score}}</span>
                      <span *ngIf="!fstarScore?.ariia_score">0</span>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"><strong>Total</strong></td>
                    <td ><strong>100%</strong></td>
                    <td ><span *ngIf="fstarScore?.total_score">{{fstarScore?.total_score}}</span>
                      <span *ngIf="!fstarScore?.total_score">0</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="row">
          <div class="col-sm-8 offset-sm-2">

        <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 5px; margin-bottom: 20px;">Table C: Score Range and Star Allocation</span>

        <table style="text-align: center;" class="table table-hover">
          <tbody>
            <tr  style="background-color: #ececec !important;">
              <th class="hdr scoreTH" width="25%" style="text-align:center;background-color: #ececec !important;">Score Range</th>
              <th class="hdr scoreTH" width="25%" style="text-align:center;background-color: #ececec !important;">Star allocation</th>
            </tr>
            <tr>
              <td class="hdr scoStarConv" style="font-weight: bold;"> 0<Score<=25 </td>
              <td class="hdr scoStarConv" style="font-weight: bold;">One star</td>
            </tr>
            <tr>
              <td class="hdr scoStarConv" style="font-weight: bold;"> 25<Score<=50
              </td>
              <td class="hdr scoStarConv" style="font-weight: bold;">Two star</td>
            </tr>
            <tr>
              <td class="hdr scoStarConv" style="font-weight: bold;"> 50<Score<=65
              </td>
              <td class="hdr scoStarConv" style="font-weight: bold;">Three Star </td>
            </tr>
            <tr>
              <td class="hdr scoStarConv" style="font-weight: bold;"> 65<Score<=80
              </td>
              <td class="hdr scoStarConv" style="font-weight: bold;">Three and a Half Star  </td>
            </tr>
            <tr>
              <td class="hdr scoStarConv" style="font-weight: bold;"> 80<Score<=90
              </td>
              <td class="hdr scoStarConv" style="font-weight: bold;">Four Star</td>
            </tr>
            <tr>
              <td class="hdr scoStarConv" style="font-weight: bold;"> 90<Score<=95
              </td>
              <td class="hdr scoStarConv" style="font-weight: bold;">Four and a Half Star</td>
            </tr>
            <tr>
              <td class="hdr scoStarConv" style="font-weight: bold;"> 95<Score<=100
              </td>
              <td class="hdr scoStarConv" style="font-weight: bold;">Five Star</td>
            </tr>
          </tbody>
        </table>

        </div>
        </div>

<!--         
            <table class="table table-hover" style="margin-bottom: 0px;">
                <tbody>
                  <tr>
                    <td width="15%" rowspan="2" colspan="1" style="background-color:#ececec">Total disapproved activities</td>
                    <td width="10%" rowspan="2" colspan="1">{{formData?.total_disapproved}}</td>
                    <td width="15%" rowspan="2" colspan="1" style="background-color:#ececec">Total Activity Submitted</td>
                    <td width="10%" rowspan="2" colspan="1">{{formData?.TotalActivitySubmitted}}</td>
                    <td width="15%" rowspan="2" colspan="1" style="background-color:#ececec">Disapprove Percentage</td>
                    <td width="10%" rowspan="2" colspan="1">{{formData?.disapproved_percentage}}</td>
                    <td  width="5%" rowspan="7"><br></td>
                    <td width="20%" rowspan="1" colspan="2">Disapproved Percentage = (Number of Activities Disapproved/Total activities submitted)*100%	
                    </td>
                  </tr>
                  
                  <tr>
                    <td rowspan="1" colspan="2" style="background-color: #ececec" style="background-color:#ececec">Penalty Points Calculation	
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2" colspan="1" style="background-color:#ececec">"Final Score<br/>

                      Final Socre = Score - (Score * Penalty points)"
                      
                      
                      </td>
                    <td rowspan="2" colspan="4">{{formData?.total_score}}</td>
                    <td rowspan="2" colspan="1" style="background-color:#ececec">Penalty Points</td>

                    <td width="10%" style="background-color: #ececec">Range</td>
                    <td width="10%" style="background-color: #ececec">Penalty Points</td>
                  </tr>
                  <tr>

                    <td>Disapprove Percentage>=74.5
                    </td>
                    <td>0.5
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="3" colspan="1" style="background-color: #ececec">"Final Reward<br/>

                      Final Reward = Reward - (Reward * Penalty Points)"
                      
                      
                      </td>
                    <td rowspan="3" colspan="4">{{formData?.total_reward_score}}</td>
                    <td rowspan="3" colspan="1">{{formData?.penalty_point}}</td>
                    <td>49.5&lt;=Disapprove Percentage&lt;75</td>
                    <td>0.4</td>
                  </tr>
                  <tr>

                    <td>24.5 &lt; Disapprove Percentage &lt; 50</td>
                    <td>0.3</td>
                  </tr>
                  <tr>

                    <td>0 &lt; Disapprove Percentage &lt; 25
                    </td>
                    <td>0.2</td>
                  </tr>
                </tbody>
              </table>

              <ul>
                <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Cumulative fifth star report for the academic year 2021-22</b></li>
                <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">Fifth Score and Reward Points</li>
              </ul>

              <table class="table table-hover" style="margin-bottom: 0px;">
                <tbody>
                  <tr style="background-color: #ececec">
                    <td colspan="2" style="height:60px; text-align:center; vertical-align:bottom;  width:327px"><strong>Parameter</strong></td>
                    <td style="vertical-align:bottom;  width:162px"><strong>Maximum score</strong></td>
                    <td style="vertical-align:bottom;  width:152px"><strong>Score you received</strong></td>
                  </tr>
                  <tr>
                    <td colspan="2" style="height:20px; text-align:center; vertical-align:bottom;  width:327px"><strong>Reward Score</strong></td>
                    <td style="vertical-align:bottom;  width:162px">10</td>
                    <td style="vertical-align:bottom;  width:152px">{{fstarScore?.reward_score}}</td>
                  </tr>
                  <tr>
                    <td rowspan="4" style="height:100px; text-align:center; vertical-align:bottom;  width:155px"><strong>YUKTI</strong></td>
                    <td style="vertical-align:bottom;  width:172px"><strong>Idea-relative score</strong></td>
                    <td style="vertical-align:bottom;  width:162px">5</td>
                    <td style="vertical-align:bottom;  width:152px">{{fstarScore?.idea_relative_score}}</td>
                  </tr>
                  <tr>
                    <td style="height:20px; vertical-align:bottom;  width:172px"><strong>Innovation Relative Score</strong></td>
                    <td style="vertical-align:bottom;  width:162px">5</td>
                    <td style="vertical-align:bottom;  width:152px">{{fstarScore?.innovation_relative_score}}</td>
                    </tr>
                    <tr>
                      <td style="height:20px; vertical-align:bottom;  width:172px"><strong>Startup Score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">10</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.non_dpit_score}}</td>
                    </tr>
                    <tr>
                      <td style="height:40px; vertical-align:bottom;  width:172px"><strong>DPIT recoganized Startup score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">10</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.dpit_startup_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="    height:38px; text-align:center; vertical-align:bottom;  width:327px"><strong>Volunteer Mentor Score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">10</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.volunteer_mentor_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="    height:20px; text-align:center; vertical-align:bottom;  width:327px"><strong>NISP Score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">5</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.nisp_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="height:20px; text-align:center; vertical-align:bottom;  width:327px"><strong>SIH/RM HOST Score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">5</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.sih_rm_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="    height:38px; text-align:center; vertical-align:bottom;  width:327px"><strong>IA report submitted Score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">10</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.ia_training_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="    height:40px; text-align:center; vertical-align:bottom;  width:327px"><strong>ATL Schools Score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">5</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.atl_school_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="    height:57px; text-align:center; vertical-align:bottom;  width:327px"><strong>ARIIA Data Submission Status Score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">5</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.ariia_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="    height:40px; text-align:center; vertical-align:bottom;  width:327px"><strong>Referral Score</strong></td>
                      <td style="vertical-align:bottom;  width:162px">20</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.referral_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="    height:40px; text-align:center; vertical-align:bottom;  width:327px"><strong>Grand Total</strong></td>
                      <td style="vertical-align:bottom;  width:162px">100</td>
                      <td style="vertical-align:bottom;  width:152px">{{fstarScore?.total_score}}</td>
                    </tr>
                    <tr>
                      <td colspan="2" style="height:28px; text-align:center; vertical-align:bottom;  width:327px"><strong>5th star</strong></td>
                      <td style="vertical-align:bottom;  width:162px"><strong>1</strong></td>
                      <td style="vertical-align:bottom;  width:152px"><strong>{{fstarScore?.star_rating}}</strong></td>
                    </tr>
                  </tbody>
                </table>
                        

              <p class="hdr" style="font-size: 16px;margin-bottom: 2px;">Weightage of each activity type:</p>

              <p class="hdr" style="font-size: 18px;margin-bottom: 2px;"><b>Note:</b> IIC calendar year is divided in 4 quarters. IICs can earn maximum 25 marks in each quarter
              </p>
              <table class="table table-hover" style="margin-bottom: 0px;">
                <tbody>
                  <tr style="background-color: #ececec;">
                    <td style="   height:60px; vertical-align:bottom; white-space:normal; width:208px"><strong>Activity Type</strong></td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px"><strong>Weightage in %</strong></td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px"><strong>Total Score (Q1-Q4)</strong></td>
                    <td style="   vertical-align:bottom; white-space:normal; width:97px"><strong>Total Activity Announced/Threshold</strong></td>
                    <td colspan="2" style="  border-right:.7px solid black; border-top:1px solid black; text-align:center; vertical-align:bottom; white-space:normal; width:224px"><strong>Unit score per activity (Q1-Q4)</strong></td>
                  </tr>
                  <tr>
                    <td style="   height:20px; vertical-align:bottom; white-space:normal; width:208px"><strong>&nbsp;</strong></td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px"><strong>&nbsp;</strong></td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px"><strong>&nbsp;</strong></td>
                    <td style="   vertical-align:bottom; white-space:normal; width:97px"><strong>&nbsp;</strong></td>
                    <td style="   vertical-align:bottom; white-space:normal; width:100px"><strong>Offline</strong></td>
                    <td style="   vertical-align:bottom; white-space:normal; width:124px"><strong>Online</strong></td>
                  </tr>
                  <tr>
                    <td style="   height:20px; vertical-align:bottom; white-space:normal; width:208px">IIC Annual Calendar Plan</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">50%</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">50</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:97px">28</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:100px">1.79</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:124px">0.895</td>
                  </tr>
                  <tr>
                    <td style="   height:20px; vertical-align:middle; white-space:normal; width:208px">MIC Driven Activities</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">10%</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">10</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:97px">9</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:100px">1.43</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:124px">0.71</td>
                  </tr>
                  <tr>
                    <td style="   height:20px; vertical-align:bottom; white-space:normal; width:208px">Self-Driven Activities</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">30%</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">30</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:97px">12</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:100px">2.5</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:124px">1.25</td>
                  </tr>
                  <tr>
                    <td style="   height:40px; vertical-align:middle; white-space:normal; width:208px">Celebration Activities</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">10%</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">10</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:97px">12</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:100px">0.83</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:124px">0.415</td>
                  </tr>
                  <tr>
                    <td style="   height:38px; vertical-align:middle; white-space:normal; width:208px">Total Score</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">100%</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:80px">100</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:97px">49</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:100px">6.231</td>
                    <td style="   vertical-align:bottom; white-space:normal; width:124px">3.115</td>
                  </tr>
                </tbody>
              </table> -->
              <!--
              <p class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Following methodology has been used for calculation of quarterly score:</b></p>
              <p class="hdr" style="font-size: 17px;margin-bottom: 20px;">Scores are calculated based on the minimum prescribed activities in each “activity type”. IIC earn Reward Points for conducting more than prescribed minimum activities under each “activity type”, which is effective towards 5th star. </p><br/>
              <p class="hdr hdrScore scoreHdrNew" style="font-size: 17px;margin-bottom: 20px;">Score Matrix for IIC 2019-20 (All 4 quarters): </p>

              <div>
                <img src="assets/img/socre_formula.jpg" class="img-responsive matrixIIC" />
              </div>
              -->


              <!-- <div class="row">
                <div class="col-sm-8">

                  <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 5px; margin-bottom: 20px;">Effective score range for Star allocation from Quarter 1 to Quarter 4:</span>

              <table style="text-align: center;"
                class="table table-hover">
                <tbody>
                  <tr  style="background-color: #ececec !important;">
                    <th class="hdr scoreTH" width="25%" style="text-align:center;background-color: #ececec !important;">Score Range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;background-color: #ececec !important;">Star allocation</th>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;"> 0<Score<=24.5 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">One star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;"> 25<Score<=49.5
                    </td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Two star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;"> 50<Score<=64.5
                    </td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three Star </td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;"> 65<Score<=84.5
                    </td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three and a Half Star  </td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;"> 85<Score<=100
                    </td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Four Star</td>
                  </tr>
                </tbody>
              </table>


              </div>

                <div class="col-sm-4">

                  <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 5px; margin-bottom: 20px;">Effective score range for fifth star:</span>


                  <table style="text-align: center;" class="table table-hover">
                    <tbody>
                      <tr style="background-color: #ececec">
                        <td style="height:38px; vertical-align:bottom; white-space:normal; width:100px"><strong>Score Range</strong></td>
                        <td style=" vertical-align:bottom; white-space:normal; width:124px"><strong>Star allocation</strong></td>
                      </tr>
                      <tr>
                        <td style=" height:40px; vertical-align:bottom; white-space:normal; width:100px">0&lt;Score&lt;=33.33</td>
                        <td style="     vertical-align:bottom; white-space:normal; width:124px">Zero Star</td>
                      </tr>
                      <tr>
                        <td style="height:57px; vertical-align:bottom; white-space:normal; width:100px">33.33&lt;Score&lt;=66.66</td>
                        <td style="     vertical-align:bottom; white-space:normal; width:124px">Half Star</td>
                      </tr>
                      <tr>
                        <td style="height:40px; vertical-align:bottom; white-space:normal; width:100px">66.66&lt;Score&lt;=100</td>
                        <td style="vertical-align:bottom; white-space:normal; width:124px">One Star</td>
                      </tr>
                    </tbody>
                  </table>

                </div>

                <div class="col-sm-7" style="font-size: 12px; display: none;">

                  <h4 class="NewScoreList" style="font-size: 18px;font-weight: 900;" ><strong>Parameters for 5th Star:</strong></h4>

                  <table style="text-align: center;"
                class="table table-hover">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="25%" style="text-align:left; width: 10%;" >S. No.</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:left; width: 55%;">Parameter</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:left; width: 15%;">Weightage</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:left; width: 20%;">Score Earned</th>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 1 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Reward Points earned</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 30 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.reward_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 2 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Submission of Annual Report of IIC Institution 2020-21</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 15 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.annual_report_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 3 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Participation in Innovation Ambassador Training Program {{fStar?.ia_training_score}}</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.ia_training_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 4 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Shortlisted Student innovation teams from IIC institutions have reached the 3rd stage (Business Plan development) as part of the National Innovation Contest 2020.</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.nic_team_bplan_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 5 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Participation and Hosting of Toycathon 2021 program of MIC</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.toycathon_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 6 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Participation in NISP</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.nisp_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 7 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Participation in ARIIA</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important; ">{{fStar?.ariia_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; "> 8 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Innovation Ambassadors from the IIC institutions had engaged as Mentor to the student teams as part of the National Innovation Contest 2020.</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 10 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important;">{{fStar?.ia_nic_mentoship_score}}</td>
                  </tr>

                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">&nbsp; </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; ">Total</td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#068d6b !important;"> 100 </td>
                    <td class="hdr scoStarConv" style="font-weight: bold; text-align:left; color:#FF0000 !important;">{{fStar?.total_score}}</td>
                  </tr>

                </tbody>
              </table>

                </div>

              </div> -->


              <!-- <span class="hdr" style="text-align:center;font-size: 16px; line-height: 1.2; display: block;"><b>Note:</b> IIC
                calendar year is divided in 4 quarters. IICs can earn maximum 25 marks in each
                quarter.</span>
              <hr style="margin:0px !important;">

              <div class="alert alert-danger" style="margin-top: 20px; font-size:14px; line-height: 1.4; color: #FF0000;">*For the IIC calendar year 2020-21, considering the impact of national lockdown had adversely affected all HEIs in terms of deferring and squeezing academic and IIC calendar year, it further limited HEIs in terms nature and mode of activities conducted, and quality participations in comparison to physical mode of activities, further there is drastic reduction in on-campus engagements for innovation and startup generating activities during the period, a normalization approach was applied towards star allocation. Therefore, for the 2020-21, the maximum stars any institution can obtain is 3.5 out of 4 stars allocated . Similarly, for the 5th star, considering the criteria (mentioned in the performance card), the maximum star any institution can obtain is 0.5 star score out of 1upon fulfilling majority of these requirements. Institute can check the stars obtained in respective sections and total stars obtained at the top of the performance card. This applies to all IIC institutions irrespective of categories they belong to. </div> -->


              <!-- <table style="width:50% !important;margin: auto;margin-bottom: 20px;text-align: center;"
                class="table table-hover">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Starting Score range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Ending Score range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Star allocation</th>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">>0</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">24.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">One Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">24.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">34.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">One and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">34.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">49. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Two Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">49.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">64. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Two and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">64.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">74.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">74.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">84. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">84.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">100</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Four Star</td>
                  </tr>
                </tbody>
              </table> -->

              <!--
              <p class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Parameters for the calculation of 5th Star:</b></p>
              <table class="table table-hover" style="margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th class="hdr scoreTH">S.No</th>
                    <th class="hdr scoreTH">5th star Parameters</th>
                    <th class="hdr scoreTH">Weightage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Regional Mentoring Session hosting</td>
                    <td>5 marks</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Innovation Ambassador Training Hosting</td>
                    <td>30 marks</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Innovation Contest 2020 Participation</td>
                    <td>12.5</td>
                  </tr>
                  <tr>
                    <td>3.1 </td>
                    <td>50- ideas</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>3.2 </td>
                    <td>25 PoCs</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>3.3 </td>
                    <td>15 prototype</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Reward points</td>
                    <td>50 marks *</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Finalist of PoC contest</td>
                    <td>10</td>
                  </tr>

                </tbody>
              </table>

              <table class="table table-hover" style="margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th class="hdr scoreTH">Score Range</th>
                    <th class="hdr scoreTH"> Star</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Score greater than equal to 50 marks</td>
                    <td>Full star</td>
                  </tr>
                  <tr>
                    <td>10 greater than equal to score greater than 49.9 marks</td>
                    <td>0.5 star</td>
                  </tr>
                  <tr>
                    <td>Score greater than 9.9marks</td>
                    <td>0 star</td>
                  </tr>

                </tbody>
              </table>

              <div>
                <img src="assets/img/5star.jpg" class="img-responsive matrixIIC" />
              </div>

            </div>
          </div>
        </div>

      </div>
  </div>
</div>
-->
<!-- <div class="container">
<div class="instMyCouncilModal">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="background-color: #fff;color: #000 !important;font-size: 27px;">
          <p class="hdr bifuracationPara">Performance Report</p>
          <div style="padding: 0 0 20px 0; margin: 0 0 20px 0;">
            <ul>
              <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Quarter 1 & Quarter 2 Cumulative Score</b></li>
              <li class="hdr" style=";font-size: 17px;margin-bottom: 20px;">Bifurcation of Score and Reward Points</li>
              <li class="hdr" style=";font-size: 17px;margin-bottom: 20px;">
                <div class="row">
                  <div class="col-md-6 scoreBifuraction">
                    <p>Score:{{formData?.total_marks}}</p>
                  </div>
                  <div class="col-md-6 rewardBifuracation">
                    <p>Reward:{{formData?.totalRewardsInDecimal}}</p>
                  </div>
                </div>
              </li>
            </ul>
            <table class="table table-hover" style="margin-bottom: 0px;">
              <tbody>
                <tr>
                  <th class="hdr" width="20%">Activity Type</th>
                  <th class="hdr" width="20%">Total Number of Activities Approved</th>
                  <th class="hdr" width="20%">Total Threshold Number of Activities</th>
                  <th class="hdr" width="20%">Score (for minimum prescribed activities)</th>
                  <th class="hdr" width="25%">Adjusted Cumulative Reward Points For Additional Activities Beyond the Threshold Numbers for Each Category (Reflection in multiple of 100 with activity score)</th>
                </tr>
                <tr>
                  <td class="hdr" tyle="font-weight: bold;"> IIC Calendar Activity (Score for 1 activity=4.16) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.iicCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.iic_total_marks}}<br><span style="color:#068d6b !important;">(Max Score=24.96)</span></td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.ScoreIIC}}</td>
                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;">MIC driven Activity (Score for 1 activity=1.67) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.micCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.mic_total_marks}} <br> <span style="color:#068d6b !important;">(Max Score=10.02)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.scoreMIC}}
                  </td>
                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;">Self-driven Activity (Score for 1 activity=2.5) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.selfCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.self_total_marks}}
                    <br>
                    <span style="color:#068d6b !important;">(Max Score=15)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.ScoreSelf}}
                  </td>
                </tr>
              </tbody>
            </table>
            <span class="hdr" style="text-align:center;font-size: 12px;display: block;"><b>Note:</b> IIC calendar year is divided in 4 quarters. IICs can earn maximum 25 marks in each quarter.</span>
            <hr style="margin:0px !important;">
            <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 25px; margin-bottom: 20px;">Score to star Conversion:</span>
            <table style="width:30% !important;margin: auto;margin-bottom: 20px;" class="table table-hover">
              <tbody>
                <tr>
                  <th class="hdr" width="25%" style="text-align:center;">Score</th>
                  <th class="hdr" width="25%" style="text-align:center;">Star</th>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">0-6.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Zero Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">7 – 14.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Half Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">15-24.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">One Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">25-34.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">One and a Half Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">35-50</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Two Star</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
</div> -->
