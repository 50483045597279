import { Component, OnInit } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';

@Component({
  selector: 'app-atl-invite-iic',
  templateUrl: './atl-invite-iic.component.html',
  styleUrls: ['./atl-invite-iic.component.css']
})
export class AtlInviteIicComponent implements OnInit {

  atlInviteIicDetail:any;
  constructor(
    private activityService: InstituteActivityService
  ) { 
  }

  ngOnInit(): void {

    this.activityService.getAtlInviteIIC().subscribe(
      (data) => this.handleResponse(data)
    );

    this.atlInviteIicDetail = "";


  }

  handleResponse(data)
  {
    this.atlInviteIicDetail = data;
  }
}
