<div class="container">
	<h1 class="announcementHeadingMain">IIC Institution's Annual Report 2023-24</h1>
  <form [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
    <table class="table table-hover">
      <tr>
        <th><h3>1. Institute has established the Incubation/Preincubation facility after consitution of IIC? <span class="starMendet">*</span></h3></th>
        <td colspan="2">
          <mat-form-field appearance="outline" class="longs">
            <mat-label>Institute has established the Incubation/Preincubation facility after consitution of IIC?</mat-label>
            <mat-select matInput formControlName="incubation_status" placeholder="Institute has established the Incubation/Preincubation facility after consitution of IIC?" required>
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="iicImpactQuestions.controls.incubation_status.errors?.required">
              Institute has established the Incubation/Preincubation facility after consitution of IIC? is required.
            </mat-error>
          </mat-form-field>
        </td>
      </tr>

      <tr><td colspan="3"><h3>2.	Highlight Achievements (Narrative/Graphical/Tabular Representation)</h3></td></tr>
      <!-- colspan="7" -->
      <tr><td  class="thSub theSubTitle"><h3>a. Number of Ideas Generated <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td>
        <td>2022-23
          <input type="text" formControlName="student_idea_22_23" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2023-24
          <input type="text" formControlName="student_idea_23_24" (keypress)=isNumberKey($event) required class="formControl">
        </td>
      </tr>


      <!-- <tr>
        <th class="thSub">Student</th>
        <td>2018-19
          <input type="text" formControlName="student_idea_18_19" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2019-20
          <input type="text" formControlName="student_idea_19_20" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2020-21
          <input type="text" formControlName="student_idea_20_21" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2021-22
          <input type="text" formControlName="student_idea_21_22" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2022-23
          <input type="text" formControlName="student_idea_22_23" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2023-24
          <input type="text" formControlName="student_idea_23_24" (keypress)=isNumberKey($event) required class="formControl">
        </td>
    </tr> -->

    <!-- <tr>
      <th class="thSub">Faculty</th>
      <td>2018-19
        <input type="text" formControlName="faculty_idea_18_19" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2019-20
        <input type="text" formControlName="faculty_idea_19_20" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2020-21
        <input type="text" formControlName="faculty_idea_20_21" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2021-22
        <input type="text" formControlName="faculty_idea_21_22" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2022-23
        <input type="text" formControlName="faculty_idea_22_23" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2023-24
        <input type="text" formControlName="faculty_idea_23_24" (keypress)=isNumberKey($event) required class="formControl">
      </td>
  </tr> -->
  <!-- colspan="6" -->
  <tr>
    <td  class="thSub theSubTitle"><h3>b. No of Innovation/Prototype developed <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td>
    <td>2022-23
      <input type="text" formControlName="student_proto_22_23" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2023-24
      <input type="text" formControlName="student_proto_23_24" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <!-- <tr>
    <th class="thSub">Student</th>
    <td>2018-19
      <input type="text" formControlName="student_proto_18_19" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2019-20
      <input type="text" formControlName="student_proto_19_20" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2020-21
      <input type="text" formControlName="student_proto_20_21" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2021-22
      <input type="text" formControlName="student_proto_21_22" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2022-23
      <input type="text" formControlName="student_proto_22_23" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2023-24
      <input type="text" formControlName="student_proto_23_24" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr> -->

  <!-- <tr>
    <th class="thSub">Faculty</th>
    <td>2018-19
      <input type="text" formControlName="faculty_proto_18_19" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2019-20
      <input type="text" formControlName="faculty_proto_19_20" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2020-21
      <input type="text" formControlName="faculty_proto_20_21" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2021-22
      <input type="text" formControlName="faculty_proto_21_22" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2022-23
      <input type="text" formControlName="faculty_proto_22_23" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2023-24
      <input type="text" formControlName="faculty_proto_23_24" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr> -->
  <!-- colspan="6" -->
  <tr>
    <td class="thSub theSubTitle"><h3>c. No. of Start-ups/Ventures established/Supported <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td>
    <td>2022-23
      <input type="text" formControlName="student_startup_22_23" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2023-24
      <input type="text" formControlName="student_startup_23_24" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>
  <tr>
    <td class="thSub theSubTitle"><h3>d. No. of startup raised angel & VC investment <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td>
    <td>2022-23
      <input type="text" formControlName="VC_investment_22_23" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2023-24
      <input type="text" formControlName="VC_investment_23_24" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <!-- <tr>
    <th class="thSub">Student</th>
    <td>2018-19
      <input type="text" formControlName="student_startup_18_19" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2019-20
      <input type="text" formControlName="student_startup_19_20" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2020-21
      <input type="text" formControlName="student_startup_20_21" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2021-22
      <input type="text" formControlName="student_startup_21_22" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2022-23
      <input type="text" formControlName="student_startup_22_23" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2023-24
      <input type="text" formControlName="student_startup_23_24" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr> -->

  <!-- <tr>
    <th class="thSub">Faculty</th>
    <td>2018-19
      <input type="text" formControlName="faculty_startup_18_19" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2019-20
      <input type="text" formControlName="faculty_startup_19_20" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2020-21
      <input type="text" formControlName="faculty_startup_20_21" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2021-22
      <input type="text" formControlName="faculty_startup_21_22" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2022-23
      <input type="text" formControlName="faculty_startup_22_23" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2023-24
      <input type="text" formControlName="faculty_startup_23_24" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr> -->

  <tr><td colspan="3" class="thSub theSubTitle"><h3>e. No. of IPs generated, published and granted <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td></tr>

  <tr>
    <th class="thSub">I.	No. of Copyright Registered</th>
    <td colspan="2"><input type="text" formControlName="no_of_copyright" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th class="thSub">II.	No. of Industrial Designs obtained</th>
    <td colspan="2"><input type="text" formControlName="no_of_industrial" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th class="thSub">III.	Number of Patents Filed</th>
    <td colspan="2"><input type="text" formControlName="no_of_patents" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th class="thSub">IV.	Number of Patents published</th>
    <td colspan="2"><input type="text" formControlName="no_of_patents_published" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th class="thSub">V.	Number of Patents Granted</th>
    <td colspan="2"><input type="text" formControlName="no_of_patents_granted" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th class="thSub"><h3>f.	No. of Technology Transfer and Commercialisation happened</h3></th>
    <td colspan="2"><input type="text" formControlName="no_of_tech_transfer" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>


  <tr>
    <td><h3>3.	Funding Details <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td>
    <!-- <td>2022-23
      <input type="text" formControlName="amount_spent_22_23" (keypress)=isNumberKey($event) required class="formControl">
    </td>
    <td>2023-24
      <input type="text" formControlName="amount_spent_23_24" (keypress)=isNumberKey($event) required class="formControl">
    </td> -->
  </tr>
    <tr>
        <th class="thSub">Amount spent on promotion and awareness generation on Innovation Entrepreneurship in the campus </th>
        <!-- <td>2018-19
          <input type="text" formControlName="amount_spent_18_19" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2019-20
          <input type="text" formControlName="amount_spent_19_20" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2020-21
          <input type="text" formControlName="amount_spent_20_21" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2021-22
          <input type="text" formControlName="amount_spent_21_22" (keypress)=isNumberKey($event) required class="formControl">
        </td> -->
        <td>2022-23
          <input type="text" formControlName="amount_spent_22_23" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2023-24
          <input type="text" formControlName="amount_spent_23_24" (keypress)=isNumberKey($event) required class="formControl">
        </td>
    </tr>

    <tr>
      <th class="thSub">Amount grant or fund supported to student & Faculty lead Innovations, start-ups and IPR</th>
      <!-- <td>2018-19
        <input type="text" formControlName="amount_grant_18_19" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2019-20
        <input type="text" formControlName="amount_grant_19_20" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2020-21
        <input type="text" formControlName="amount_grant_20_21" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2021-22
        <input type="text" formControlName="amount_grant_21_22" (keypress)=isNumberKey($event) required class="formControl">
      </td> -->
      <td>2022-23
        <input type="text" formControlName="amount_grant_22_23" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2023-24
        <input type="text" formControlName="amount_grant_23_24" (keypress)=isNumberKey($event) required class="formControl">
      </td>
  </tr>

  <!-- <tr><td colspan="4"><h3>4.	Participated in National/International level hackathon/Any competition <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td></tr>

    <tr>
        <th class="thSub">Student Participated</th>
        <td colspan="3"><input type="text" formControlName="student_participated" (keypress)=isNumberKey($event) required class="formControl">
        </td>
    </tr>

    <tr>
      <th class="thSub">Student Winners</th>
      <td colspan="3"><input type="text" formControlName="student_winner" (keypress)=isNumberKey($event) required class="formControl">
      </td>
  </tr>

  <tr>
    <th class="thSub">Faculty Participated</th>
    <td colspan="3"><input type="text" formControlName="faculty_participated" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th class="thSub">Faculty Winners</th>
    <td colspan="3"><input type="text" formControlName="faculty_winner" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th><h3>5. IIC created I&E ecosystem in your campus since 2018 <span class="starMendet">*</span></h3></th>
    <td>Strongly Agree
      <input type="radio" formControlName="ecosystem" value="StronglyAgree" required>
    </td>
    <td>
      Agree
      <input type="radio" formControlName="ecosystem" value="Agree" required>
    </td>
    <td>Disagree
      <input type="radio" formControlName="ecosystem" value="Disagree" required>
    </td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>

  <tr>
    <th><h3>6. Started Offering Academic Credit Core/Elective Subject on Entrepreneurship/Innovation/IPR <span class="starMendet">*</span></h3></th>
    <td>Strongly Agree
      <input type="radio" formControlName="credit_score" value="StronglyAgree" required>
    </td>
    <td>
      Agree
      <input type="radio" formControlName="credit_score" value="Agree" required>
    </td>
    <td>Disagree
      <input type="radio" formControlName="credit_score" value="Disgree" required>
    </td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>

  <tr>
    <th><h3>7. IIC Network benefited us to collaborate with other Institute in terms of knowledge and infra <span class="starMendet">*</span></h3></th>
    <td>Strongly Agree
    <input type="radio" formControlName="network_benefit" value="StronglyAgree" required>
    </td>
    <td>
    Agree
    <input type="radio" formControlName="network_benefit" value="Agree" required>
    </td>
    <td>Disagree
    <input type="radio" formControlName="network_benefit" value="Disagree" required>
    </td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <th><h3>8. Institute have realized the importance the role of dedicated infrastructure & facilities <span class="starMendet">*</span><p class="thSub">(Pre-Incubation, Incubation etc in campus to Promote Innovation & Entrepreneurship and IPR through IIC activities)</p></h3></th>
    <td>Strongly Agree
    <input type="radio" formControlName="role_of_infra" value="StronglyAgree" required>
    </td>
    <td>
    Agree
    <input type="radio" formControlName="role_of_infra" value="Agree" required >
    </td>
    <td>Disagree
    <input type="radio" formControlName="role_of_infra" value="Disagree" required>
    </td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>

  <tr>
    <th><h3>9. Have you participated in ARIIA Ranking? <span class="starMendet">*</span></h3></th>
    <td colspan="3"><select name="" id="" formControlName="ariia_status" required class="formControl">
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select></td>
  </tr>
  <tr>
    <th><h3>10.	Have you participated in NISP? <span class="starMendet">*</span></h3></th>
    <td colspan="3"><select name="" id="" formControlName="nisp_status" required class="formControl">
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select></td>
  </tr> -->
  <tr><td colspan="3"><h3>4.	Total Cost/Expenses spend towards innovation & Startups (FY - 2023-24) <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td></tr>

  <tr>
      <th class="thSub">Total Program Implementation Cost</th>
      <td colspan="2"><input type="text" formControlName="implementation_cost" (keypress)=isNumberKey($event) required class="formControl">
      </td>
  </tr>

  <tr>
    <th class="thSub">Total Infrastructure Development Cost</th>
    <td colspan="2"><input type="text" formControlName="development_cost" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th class="thSub">Total grant supported to innovation & startups Cost</th>
    <td colspan="2"><input type="text" formControlName="startups_cost" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>
  
  <tr><td colspan="3"><h3>5. Sources of fund contribution to support innovation & startups  (FY - 2023-24) <span class="starMendet">*</span></h3><p class="smallFont">(Type 0 if not applicable)</p></td></tr>

  <tr>
      <th class="thSub">Total fund received from external agency & sources </th>
      <td colspan="2"><input type="text" formControlName="external_fund" (keypress)=isNumberKey($event) required class="formControl">
      </td>
  </tr>

  <tr>
    <th class="thSub">Total fund spent from own resources</th>
    <td colspan="2"><input type="text" formControlName="own_resources_fund" (keypress)=isNumberKey($event) required class="formControl">
    </td>
  </tr>

  <tr>
    <th><h3>6. Testimonial for the IIC program <span class="starMendet">*</span></h3></th>
    <td colspan="2"><textarea formControlName="testimonial" id="testimonial" cols="30" rows="10" required class="formControl"></textarea></td>
  </tr>
<tr>
  <th><h3>7. Suggestions, if any <span class="starMendet">*</span></h3></th>
  <td colspan="2"><textarea formControlName="suggestions" id="suggestions" cols="30" rows="10" required class="formControl"></textarea></td>
</tr>

<tr>
  <th><h3>8. Video URL, if any </h3></th>
  <td colspan="2"><textarea formControlName="video_url" id="video_url" cols="30" rows="10" class="formControl"></textarea></td>
</tr>

<tr>
  <th><h3>Annual Report (2023-24) <span class="starMendet">*</span><mat-error>PDF(max 10 MB)</mat-error> </h3>
  <p><a href="{{ imageBasePath }}assets/announcements/IIC Annual Report 2023-24 - Format.docx" target="_blank">Annual Report Template</a></p>

  </th>
  <td colspan="3">
    <div class="col-12">
    <label class="custom-file-label float-right" for="customFile"
                      *ngIf="!ipr_document">Choose file</label>

    <label class="custom-file-label float-right" for="customFile"
        *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>

    <input #ipr_documentRef type="file" accept="application/pdf" name="ipr_document"
        (change)="( ipr_document = ipr_documentRef.files )"
        class="custom-file-input float-right" required>
      </div>
    </td>
</tr>

<tr *ngIf="document!=''">
  <th>&nbsp;</th>
  <td colspan="5">
      <a href="{{ baseUrl + document}}" target="_blank">View Annual Report</a>
  </td>
</tr>

<tr>
  <td colspan="4">
  <div class="row">
    <div class="col-md-5"></div>
    <div class="col-md-2">
      <div fxFlex="50" class="m-2">   <button style="
          margin-left: 603px;
      " mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicImpactQuestions.invalid'>Submit</button></div>
    </div>
    <div class="col-md-5"></div>

      </div>
</td>
</tr>


    </table>
    </form>
</div>
<p>&nbsp;</p>
