 <!--title-->
   <title>

    Hackathon | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>

<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Hackathon</a>
      </li>
      <li class="aht-breadcrumbs__item">
        Smart India Hackathon
      </li>
    </ul>
  </div>
<section class="paddsection">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="titleheadertop">

                  <h1 class="inner-theme-heading">Smart India Hackathon</h1>

                </div>

            </div>

        </div>





        <div class="row rowmar">

            <div class="col-md-6">

                <div class="imagesbox">

                  <img src="{{imageBasePath}}assets/wp_images/images_modi.png">

                </div>

            </div>



            <div class="col-md-6">

                <div class="textboxsih">

                    <p><img src="{{imageBasePath}}assets/wp_images/quat1.png"></p>

                    <div class="grediyant">

                  <h4>I am happy to see young minds thinking about ways to take our nation forward. It is a delight to be among smart innovators of smart India. <span>
				  <img src="{{imageBasePath}}assets/wp_images/qaut2.png"></span></h4>

              </div>



                </div>

            </div>

        </div>

    </div>





     <div class="container">

        <div class="row">

            <div class="col-md-6">

                 <div class="imagestext">
                    <p>Smart India Hackathon 2020 is a nationwide initiative to provide students a platform to solve some of the pressing problems we face in our daily lives, and thus inculcate a culture of product innovation and a mindset of problem solving.The last edition of the hackathon saw over 5 million+ students from various colleges compete for the top prize at 65+ locations.In SIH 2020, the students would have the opportunity to work on challenges faced within various Ministries, Departments, Industries, PSUs and NGOs to create world class solutions for some of the top organizations including industries in the world, thus helping the Private sector hire the best minds from across the nation. It can help to:</p>
<ul class="listpack">
<li>   Harness creativity &amp; expertise of students</li>
<li>   Spark institute-level hackathons</li>
<li>   Build funnel for &#8216;Startup India&#8217; campaign</li>
<li>   Crowdsource solutions for improving governance and quality of life</li>
<li>   Provide opportunity to citizens to provide innovative solutions to India&#8217;s daunting problems</li>
</ul>
<p>The first three editions SIH2017, SIH2018 and SIH2019 proved to be extremely successful in promoting innovation, out-of-the-box thinking in young minds, especially engineering students from across India.</p>
                 




                   <div class="pracewiner">


                   </div>



                 <div class="pracepack">

                  <!--  <ul>

                        <li class="bor1">

                            <h5>Complex:</h5>

                          <h2><i class="fa fa-rupee">1,00,000</i></h2>  

                        </li>



                        <li class="bor1">

                             <h5>Simple:</h5>

                          <h2> <i class="fa fa-rupee">50,0000</i></h2>  

                        </li>





                        <li class="bore1">

                             <h5>Complicated:</h5>

                          <h2><i class="fa fa-rupee">75,000</i></h2>  

                        </li>

                    </ul> -->

                   </div>

                 </div>

            </div>



            <div class="col-md-6">

                <div class="video">

                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/aaycaWJdBTg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                </div>



                <div class="boxtext4">

                    <h2>ANNOUNCMENTS</h2>

                  <div class="boxp">

                    <p><a href="https://www.sih.gov.in/SoftwarefinalResult">Software finale results with nodal center</a></p>
<p>Software Hackathon is now rescheduled along with Hardware Hackathon in the month of June or July, 2020.</p>

                </div>

                </div>

            </div>

        </div>

     </div>

</section>



<section class="sectionbutton">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="textbotton">

                    <h4>For More Details <a href="http://www.sih.gov.in/">Vist the website</a></h4>

                </div>

            </div>

        </div>

    </div>

</section>


<!-- Footer -->
<app-inner-footer></app-inner-footer>