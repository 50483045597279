import { Component, OnInit } from '@angular/core';
import { InstituteAnnouncementService } from 'src/app/Services/institute-announcement.service';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-iic-impact-lecture-series2023',
  templateUrl: './iic-impact-lecture-series2023.component.html',
  styleUrls: ['./iic-impact-lecture-series2023.component.css']
})
export class IicImpactLectureSeries2023Component implements OnInit {
  IMPCount :any;
  Themes: any[] = [];

  imageBasePath= myGlobals.imageBasePath;

  constructor(private IAS: InstituteAnnouncementService,) { }

  ngOnInit(): void {
    this.IAS.getIicImpactLactureCount()
    .subscribe((data) => this.handleResponse(data));
    this.IAS.getIicImpactLactureThemeCount()
    .subscribe((data: any[]) => {
      this.Themes = data;
    });
		  /*------------------------------------------------------------------
   	 Smooth Scroll Landing
	 ------------------------------------------------------------------*/
// Select all »a« elements with a parent class »links« and add a function that is executed on click
$( '#navbarColor01 a' ).on( 'click', function(e){
	
  // Define variable of the clicked »a« element (»this«) and get its href value.
  var href = $(this).attr( 'href' );
  
  // Run a scroll animation to the position of the element which has the same id like the href value.
  $( 'html, body' ).animate({
		scrollTop: $( href ).offset().top
  }, '300' );
	
  // Prevent the browser from showing the attribute name of the clicked link in the address bar
  e.preventDefault();

});

$(document).ready(function(){ 
    $(window).scroll(function(){ 
        if ($(this).scrollTop() > 100) { 
            $('#scroll').fadeIn(); 
        } else { 
            $('#scroll').fadeOut(); 
        } 
    }); 
    $('#scroll').click(function(){ 
        $("html, body").animate({ scrollTop: 0 }, 600); 
        return false; 
    }); 
});  
	  
  }
  handleResponse(data)
  {
    this.IMPCount = data;
  }
}
