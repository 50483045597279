import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../../app/app.component';
@Component({
  selector: 'app-singapore-india-hackathon',
  templateUrl: './singapore-india-hackathon.component.html',
  styleUrls: ['./singapore-india-hackathon.component.css']
})
export class SingaporeIndiaHackathonComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
