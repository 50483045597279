<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">International Exposure</h1>	-->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">International Exposure</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>International Exposure</a></li>
    <li class="breadcrumb-item active" aria-current="page">India-South Korea</li>
  </ul>
</nav>

<section class="indiaSouthKoreaBox">
	<div class="container">
		<div class="vc_row">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">Indian Startups Exposure Visit to Korea</h2>
				</div>
			</div>
		</div>
	</div>
	
	<div class="container">
		<div class="vc_row">
			<div class="vc_col-md-12">
				<div class="headingAmbassadorContent">
					<h3>Details of the Program</h3>
					<p>MHRD’s Innovation Cell has selected students for 2 nd exposure visit to South Korea under collaborative effort between All India Council for Technical Education (AICTE) and Daegu Centre for Creative Economy Innovation (CCEI, Daegu).</p>
					<p>This program offers opportunities to interact with ecosystem enablers and start-ups from South Korea.Students were selected from IICs through POC Contest organized during month of March - August 2019 and Smart India Hackathon 2019.In 2018, 10 entrepreneurs visited South Korea as part of this program. This year 05 entrepreneurs are visiting for 05 days exposure visit where they will also participate and compete in the 5 th GIF (Global Innovator Festa), one of the biggest Start-up event in Korea.</p>
				</div>
			</div>
		</div>
	</div>
</section>


<section class="accleProgram">
	<div class="container">
		<div class="vc_row">
			<div class="vc_col-md-12 marginIndiaCanadaBottom">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">Glimpse of Korea Visit</h2>
				</div>
			</div>
		</div>
		<div class="vc_row">
			<div class="col-md-4">
				<div class="column">
					<img src="assets/assets/images/sk1.jpg" alt="Snow">
						<h6></h6>
				</div>
			</div>
			<div class="col-md-4">
				<div class="column">
					<img src="assets/assets/images/sk2.jpg" alt="Snow">
						<h6></h6>
				</div>
			</div>
			<div class="col-md-4">
				<div class="column">
					<img src="assets/assets/images/sk3.jpg" alt="Snow">
						<h6></h6>
				</div>
			</div>
			<div class="col-md-4">
				<div class="column">
					<img src="assets/assets/images/sk4.jpg" alt="Snow">
						<h6></h6>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>