import { Component, OnInit } from '@angular/core';
import { LandingService } from 'src/app/Services/landing.service';
import * as myGlobals from '../../../app.component';

@Component({
  selector: 'app-iic-download',
  templateUrl: './iic-download.component.html',
  styleUrls: ['./iic-download.component.css']
})
export class IicDownloadComponent implements OnInit {
	
	currentPageNo = 1;
	firstPage = true;
	lastPage = false;
	page: any;
	baseImageUrl = myGlobals.baseImageUrl;
	baseUrl = myGlobals.baseUrl1;
	images = [];

downloadsDetails : any;
  constructor(private landing:LandingService) { }

  ngOnInit(): void {
	   this.landing.getDownloadsData().subscribe((data)=>this.handleResponse(data));
  }
handleResponse(data){
	this.downloadsDetails=data;
	
	this.images = [];

    data['data'].forEach(element => {
      if (element.announcementType == 'Image') {
        this.images.push(element.announcement);
      }
    });

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
}
public onPageChanged(event) {
    this.page = event;
  }

}
