import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { emailValidator } from 'src/app/app.component';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import * as myGlobals from '../../../../../../app.component';
import { AtalActivityProposalDialogComponent } from '../atal-activity-proposal-dialog/atal-activity-proposal-dialog.component';

@Component({
  selector: 'app-atal-activity-form4',
  templateUrl: './atal-activity-form4.component.html',
  styleUrls: ['./atal-activity-form4.component.css']
})
export class AtalActivityForm4Component implements OnInit {

  edit: boolean;
  editData: any;
  id: any;
  report: FileList | null;
  public addActivityForm4: FormGroup;
  date1:any;
  file_upload: any;
  reportStatus: boolean;
  Mentee:any;
  minDate = "2024-12-20";
  maxDate = "2026-03-31";

  baseImageUrl = myGlobals.baseImageUrl2;
  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<AtalActivityProposalDialogComponent>,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public activity: InstituteActivityService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.edit = false;

      if (this.data['activity_id']!= undefined || this.data['activity_id']!= null) {
        this.edit = true;

        this.id = this.data['activity_id'];
      }
      else {
        this.edit = false;
      }
     }

  ngOnInit(): void {

    this.getScheduleTable();

     this.addActivityForm4 = this.fb.group({
      'session_date': [null,Validators.required],
      'session_time': [null,Validators.required],
      'speaker_name': [null,Validators.required],
      'quarter': [null,Validators.required],
      'designation':[null],
      'contactemail':[null],
      'contact':[null],
     });
     if(this.id!='undefined'){
      this.activity.getSchedule(this.id).subscribe(data=>this.setData(data));}
  }

  getScheduleTable(){
    this.activity.schedules().subscribe(
      data => this.setScheduleTable(data)
    );
  }

  setScheduleTable(data){
    this.Mentee = data["Mentee"];
  }


  setData(data){
    this.editData=data;
    this.addActivityForm4.controls['session_date'].setValue(new Date(data['Activity']['scheduled_date']));
    this.addActivityForm4.controls['session_time'].setValue(data['Activity']['scheduled_time']);
    this.addActivityForm4.controls['speaker_name'].setValue(data['Activity']['speaker_name']);
    this.addActivityForm4.controls['designation'].setValue(data['Activity']['speaker_designation']);
    this.addActivityForm4.controls['contactemail'].setValue(data['Activity']['contact_email']);
    this.addActivityForm4.controls['contact'].setValue(data['Activity']['contact']);
    this.addActivityForm4.controls['quarter'].setValue(data['Activity']['quarter']);
    this.file_upload=data['Activity']['file_upload'];
if(this.file_upload!=null){
  this.reportStatus=true;
}
 }



  onSubmit(data){


    var cvFile =
      this.report && this.report.length ? this.report[0] : null;

    var formData = new FormData();

    formData.append('file', cvFile);
    formData.append('activityid', this.id);
    formData.append('session_date',this.changeDate(this.addActivityForm4.get('session_date').value));
    formData.append('speaker_name',this.addActivityForm4.get('speaker_name').value);
    formData.append('session_time',this.addActivityForm4.get('session_time').value);
    formData.append('designation',this.addActivityForm4.get('designation').value);
    formData.append('contactemail',this.addActivityForm4.get('contactemail').value);
    formData.append('contact',this.addActivityForm4.get('contact').value);
    formData.append('quarter',this.addActivityForm4.get('quarter').value);

    formData.append('id','4');

     if (!this.edit) {
      this.activity
        .schedule(formData)
        .subscribe((data) => this.handleResponse(data));}

    else {

    formData.append('file_upload_hidden',this.editData['Activity']['file_upload']);
      this.activity
        .editSchedule(formData)
        .subscribe((data) => this.handleResponse(data));
    }

  }
  handleResponse(data){
    if(data['message']=="Success"){
      this.toastr.success("Successfully Submitted");
      this.dialogRef.close();
    }

  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

}
