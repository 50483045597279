<!-- <mat-dialog-content class="mat-typography"> -->
<div class="d-none d-sm-block">
    <div class="container">
        <h2 class="text-center instStuDetailsTitle">My Activity</h2>
        <div class="row">
            <div class="col-md-12">
                <div class="row btmLine">
                    <div class="col-md-2">
                        <p class="anaTitle">Title</p>
                    </div>
                    <div class="col-md-10">
                        <p class="anaContent">{{activity?.title}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-2">
                        <p class="anaTitle">Description</p>
                    </div>
                    <div class="col-md-10">
                        <p class="anaContent">{{activity?.description}}</p>
                    </div>
                </div>
                <div class="row btmLine">
                    <div class="col-md-2">
                        <p class="anaTitle">Quarter</p>
                    </div>
                    <div class="col-md-10">
                        <p class="anaContent">{{activity?.quarter}}</p>
                    </div>
                </div>
                <!-- <div class="row btmLine">
                    <div class="col-md-2">
                        <p class="anaTitle">Basic Type</p>
                    </div>
                    <div class="col-md-10">
                        <p class="anaContent">{{activity?.basicType}}</p>
                    </div>
                </div> -->
                <div class="row btmLine">
                    <div class="col-md-2">
                        <p class="anaTitle">Activity Type</p>
                    </div>
                    <div class="col-md-10">
                        <p class="anaContent">{{activity?.type}}</p>
                    </div>
                </div>    
                 <!-- <div class="row btmLine">
                    <div class="col-md-2">
                        <p class="anaTitle">Duration </p>
                    </div>
                    <div class="col-md-10">
                        <p class="anaContent">{{activity?.startDate}} to {{activity?.endDate}}</p>
                    </div>
                </div>    -->
                         
                <!-- <div class="row btmLine">
                    <div class="col-md-2">
                        <p class="anaTitle">Report</p>
                    </div>
                    <div class="col-md-5">
                        <a *ngIf="activity.url != ''" [routerLink]="[activity.url,activity.eventId]"
                            [queryParams]="{'action':activity.action,'instid':activity.institute_id}" target="_blank">
                            <button type="button" class="btn btn-primary mt-2 mb-2">{{activity?.label}}</button>
                        </a>
                        <span *ngIf="activity.url == ''">{{activity?.label}}</span>
                    </div>
                    <div class="col-md-5">
                        <a *ngIf="activity.download_url != '' || (activity.label == 'Report Verified' || activity.label == 'Report Verified')"  target="_blank">
                            <button type="button" (click)="downloadReport(activity.download_url,activity.eventId)" class="btn btn-primary mt-2 mb-2">Download Report</button>
                        </a> 
                    </div>
                </div> -->
             
                <div mat-dialog-actions align="end" class="mt-3">
                    <button type="button" class="btn btn-danger instituteBtnClose" (click)="onNoClick()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </mat-dialog-content> -->