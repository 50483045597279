import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../../app/app.component';
@Component({
  selector: 'app-why-heis',
  templateUrl: './why-heis.component.html',
  styleUrls: ['./why-heis.component.css']
})
export class WhyHeisComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
