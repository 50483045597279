import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { subscribeOn } from 'rxjs/operators';
import { InstituteSuggestionService } from 'src/app/Services/institute-suggestion.service';
import * as myGlobals from '../../../../app.component';
import { saveAs as importedSaveAs } from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-institute-bank-form',
  templateUrl: './institute-bank-form.component.html',
  styleUrls: ['./institute-bank-form.component.css']
})
export class InstituteBankFormComponent implements OnInit {
  baseImageUrl = myGlobals.baseImageUrl;

  milestonePDFForm: FormGroup;
mandateForm: FormGroup;
mandate:any=null;
externalCV: FileList | null;
externalCV1: FileList | null;
report:any
step1:boolean=false;
step2:boolean=false;
mandateId:any;
  pan_report: any;
  showCV: boolean;
  report1: any;
  signed_doc: any;
  showCV1: boolean;
  show: boolean;
  id1: any;
  type: string;
  constructor(private formBuilder:FormBuilder,
    private router:Router,private route: ActivatedRoute,
    private service:InstituteSuggestionService, private toastr:ToastrService) {
      this.route.params.subscribe((params) => {
        this.id1 = router.url;
      });
      if(this.id1=="/institute/mentor-mentee"){
        this.type="Menter-Mentee"
      }
      if(this.id1=="/institute/impactLectures"){
        this.type="Impact"
      }
     }

  ngOnInit(): void {
    this.mandateForm=this.formBuilder.group({
      'instname':['', Validators.required],
			'id_type':['', Validators.required],
			'perma_id':['', Validators.required],
			'inst_type':['', Validators.required],
			'inst_address':['', Validators.required],
			'inst_city':['', Validators.required],
			'inst_state':['', Validators.required],
			'inst_pin':['', Validators.required],
			'inst_pan':['', Validators.required],
			'head_type':['', Validators.required],
			'head_name':['', Validators.required],
			'head_contact':[null, Validators.compose([Validators.required])],
			'head_email':['', Validators.required],
			'bank_name':['', Validators.required],
			'bank_contact':[null, Validators.compose([Validators.required])],
			'branch_name':['', Validators.required],
			'bank_address':['', Validators.required],
			'bank_state':['', Validators.required],
			'bank_city':['', Validators.required],
			'bank_pin':['', Validators.required],
			'account_name':['', Validators.required],
			'account_type':['', Validators.required],
			'account_number':['', Validators.required],
			'bank_ifsc':['', Validators.required]

    });


    this.milestonePDFForm = this.formBuilder.group({

    });

this.getMandate();

  }

getMandate(){
  this.service.getMandate().subscribe((data) => this.setData(data))

}

  setData(data){
    if(data['mandate']['0']['status']==1){
      this.step1=true;
    }
    this.mandateForm.controls['instname'].setValue(data['mandate']['0']['institute_name']);
    this.mandateForm.controls['perma_id'].setValue(data['mandate']['0']['permanent_id']);
    this.mandateForm.controls['id_type'].setValue(data['mandate']['0']['permanent_id_type']);
    this.mandateForm.controls['inst_type'].setValue(data['mandate']['0']['institute_type']);
    this.mandateForm.controls['inst_address'].setValue(data['mandate']['0']['Address']);
    this.mandateForm.controls['inst_city'].setValue(data['mandate']['0']['institute_city']);
    this.mandateForm.controls['inst_state'].setValue(data['mandate']['0']['institute_state']);
    this.mandateForm.controls['inst_pin'].setValue(data['mandate']['0']['institute_pin']);
    this.mandateForm.controls['inst_pan'].setValue(data['mandate']['0']['institute_pan']);
    this.mandateForm.controls['head_type'].setValue(data['mandate']['0']['head_type']);
    this.mandateForm.controls['head_name'].setValue(data['mandate']['0']['head_name']);
    this.mandateForm.controls['head_type'].setValue(data['mandate']['0']['head_type']);
    this.mandateForm.controls['head_email'].setValue(data['mandate']['0']['head_email']);
    this.mandateForm.controls['head_contact'].setValue(data['mandate']['0']['head_contact']);
    this.mandateForm.controls['bank_name'].setValue(data['mandate']['0']['bank_name']);
    this.mandateForm.controls['bank_contact'].setValue(data['mandate']['0']['bank_contact']);
    this.mandateForm.controls['bank_address'].setValue(data['mandate']['0']['bank_address']);
    this.mandateForm.controls['bank_state'].setValue(data['mandate']['0']['bank_state']);
    this.mandateForm.controls['bank_city'].setValue(data['mandate']['0']['bank_city']);
    this.mandateForm.controls['bank_pin'].setValue(data['mandate']['0']['bank_pin']);
    this.mandateForm.controls['bank_ifsc'].setValue(data['mandate']['0']['bank_ifsc']);
    this.mandateForm.controls['branch_name'].setValue(data['mandate']['0']['branch_name']);
    this.mandateForm.controls['account_name'].setValue(data['mandate']['0']['account_name']);
    this.mandateForm.controls['account_type'].setValue(data['mandate']['0']['account_type']);
    this.mandateForm.controls['account_number'].setValue(data['mandate']['0']['account_number']);
    this.mandateId=data['mandate']['0']['id'];


    this.pan_report=data['mandate']['0']['pan_upload']
if(this.pan_report!=null){
  this.showCV=true;
}


this.signed_doc=data['mandate']['0']['signed_upload']
if(this.signed_doc!=null){
  this.showCV1=true;
  this.show=true;
}

  }
download(){

  this.service.download().subscribe(
    data => this.downloadFile(data, 'Mandate.pdf')
  )

};
// download1(){

//   this.service.downloadLetter(this.type).subscribe(
//     data => this.downloadFile(data, 'Sanction Letter.pdf')
//   )

// }

downloadFile(data, fileName) {
  let file = new Blob([data], { type: 'application/pdf' });
  importedSaveAs.saveAs(file, fileName);
}
onSubmit(data){
  var cvFile =
  this.externalCV && this.externalCV.length ? this.externalCV[0] : null;

  var formData = new FormData();

  formData.append('pan', cvFile);
  formData.append('pan_hidden', this.pan_report);
  formData.append('type',this.type);
  formData.append(
    'instname',
    this.mandateForm.get('instname').value
  );
  formData.append(
    'id_type',
    this.mandateForm.get('id_type').value
  );
  formData.append(
    'perma_id',
    this.mandateForm.get('perma_id').value
  );
  formData.append(
    'inst_type',
    this.mandateForm.get('inst_type').value
  );
  formData.append(
    'inst_address',
    this.mandateForm.get('inst_address').value
  );
  formData.append(
    'inst_city',
    this.mandateForm.get('inst_city').value
  );
  formData.append(
    'inst_state',
    this.mandateForm.get('inst_state').value
  );
  formData.append(
    'inst_pin',
    this.mandateForm.get('inst_pin').value
  );
  formData.append(
    'inst_pan',
    this.mandateForm.get('inst_pan').value
  );
  formData.append(
    'head_type',
    this.mandateForm.get('head_type').value
  );
  formData.append(
    'head_name',
    this.mandateForm.get('head_name').value
  );
  formData.append(
    'head_contact',
    this.mandateForm.get('head_contact').value
  );
  formData.append(
    'head_email',
    this.mandateForm.get('head_email').value
  );
  formData.append(
    'bank_name',
    this.mandateForm.get('bank_name').value
  );
  formData.append(
    'bank_contact',
    this.mandateForm.get('bank_contact').value
  );
  formData.append(
    'bank_address',
    this.mandateForm.get('bank_address').value
  );
  formData.append(
    'bank_city',
    this.mandateForm.get('bank_city').value
  );
  formData.append(
    'bank_state',
    this.mandateForm.get('bank_state').value
  );
  formData.append(
    'bank_pin',
    this.mandateForm.get('bank_pin').value
  );
  formData.append(
    'branch_name',
    this.mandateForm.get('branch_name').value
  );
  formData.append(
    'account_name',
    this.mandateForm.get('account_name').value
  );
  formData.append(
    'account_type',
    this.mandateForm.get('account_type').value
  );
  formData.append(
    'account_number',
    this.mandateForm.get('account_number').value
  );
  formData.append(
    'bank_ifsc',
    this.mandateForm.get('bank_ifsc').value
  );
  formData.append('id',this.mandateId);
  this.service.submitMandate(formData).
  subscribe(data=>this.handleResponse1(data));
}
handleResponse1(data1){
  this.getMandate();
this.toastr.success(data1['message'],'Proceed to step 2 and Download the mandate');

    // if(data1[]=="Data added successfully"){
    //   this.toastr.success("Data recorded successfully");
    // }
    // this.toastr.error("Check all the form values");
  }





  onSubmit2(data){
    var cvFile =
    this.externalCV1 && this.externalCV1.length ? this.externalCV1[0] : null;
    var formData = new FormData();
// if(this.report!=null && this.report!=undefined){

  if(this.externalCV1!=null && this.externalCV1!=undefined ){


  formData.append('signed', cvFile);
  formData.append('id',this.mandateId);

  this.service.signedMandate(formData).subscribe(
    data => this.reponseFile(data));
   }
    else{
      this.toastr.error("Kindly upload the report!")

    }
// }
// if(this.report1==null && this.report1==undefined)
//   {
//   if(this.externalCV1!=null && this.externalCV1!=undefined ){
//     formData.append('file', cvFile);

//     // this.teamLeadService.submitmiledoc(formData).subscribe(
//     //   data => this.handleResponseData(data));
//   }else{
//     this.toastr.error("Kindly upload the report!")
//   }


// }



    // if(this.externalCV!=null && this.externalCV!=undefined ){
    //   formData.append('file', cvFile);
    //   this.teamLeadService.submitmiledoc(formData).subscribe(
    //     data => this.handleResponseData(data));
    // }else{
    //   this.toastr.error("Kindly upload the report!")
    // }

  }
  reponseFile(data){
    this.toastr.success(data['message'])
    this.getMandate();

  }



}
