import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../../app/app.component';
@Component({
  selector: 'app-iflts-past',
  templateUrl: './iflts-past.component.html',
  styleUrls: ['./iflts-past.component.css']
})
export class IfltsPastComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
