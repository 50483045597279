import { Component, OnInit } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';

@Component({
  selector: 'app-atl-ia-mentor-detail',
  templateUrl: './atl-ia-mentor-detail.component.html',
  styleUrls: ['./atl-ia-mentor-detail.component.css']
})
export class AtlIaMentorDetailComponent implements OnInit {

  iaMentorCoordinator:any;
  per_page:any;
  current_pages:any;

  constructor(
    private activityService: InstituteActivityService,

  ) { }

  ngOnInit(): void {

    this.activityService.iaMentorCoordinator(this.per_page = 10, this.current_pages).subscribe(
      (data) => this.handleResponse(data)
    );

    this.iaMentorCoordinator = "";


  }

  handleResponse(data)
  {
    this.iaMentorCoordinator = data.result.data;
  }
}
