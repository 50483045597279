<mat-card>
    <div class="container">

    <div class="row m-4 row-cols-sm-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-5">
      <div class="col">
        <div class="instIdeaTotalCount">
            <p>Total Individuals Registered:</p>
            <span>{{counts?.totalRegistration}}</span>
        </div>
    </div>

      <!-- <div class="col">
        <div class="instIdeaTotalCount">
            <p>Total Ideas Submitted:</p>
            <span>{{counts?.pending_count}}</span>
        </div>
    </div>
        <div class="col">
            <div class="instIdeaTotalCount">
                <p>Total Ideas Assigned:</p>
                <span>{{counts?.assigned_count}}</span>
            </div>
        </div>
        <div class="col">
            <div class="instIdeaTotalCount">
                <p>Total Ideas Verified:</p>
                <span>{{counts?.verified_count}}</span>
            </div>
        </div>
        <div class="col">
            <div class="instIdeaTotalCount">
                <p>Total Ideas Recommended:</p>
                <span>{{counts?.approved_count}}</span>
            </div>
        </div> -->
        <div class="col"><a href="https://yukti.mic.gov.in/registration/new" target="_blank">
            <div class="instIdeaTotalCount">
                <p>Invite Innovator For Registration</p>
            </div></a>
        </div>
        <div class="col"><a [routerLink]="['/institute/transfered-innovation-with-existing']">
            <div class="instIdeaTotalCount">
                <p>Create Innovation Login</p>
            </div></a>
        </div>
    </div>
      <div class="row">
        <!-- <div class="col-5">
          <h5 class="ml-3">Total Approved Count: {{counts?.approved_count}}</h5>
          <h5 class="ml-3">Total Disapproved Count: {{counts?.disapproved_count}}</h5>
          <h5 class="ml-3">Total Pending Count: {{counts?.pending_count}}</h5>
        </div> -->
        <!-- <div class="col-2" style="text-align: end;">
          <mat-form-field class="instiIdeaSelectBox">
            <mat-label>Status Filter</mat-label>
            <mat-select (selectionChange)="changeFilter()" [(ngModel)]="filterModel">
              <mat-option value="All" default selected>All</mat-option>
              <mat-option value="Approved">Approved</mat-option>
              <mat-option value="Disapproved">Disapproved</mat-option>
              <mat-option value="pending">Pending</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="col-12">
          <p>Application Submission Link of YUKTI National Innovation Repository (NIR): <a href="https://yukti.mic.gov.in/login/{{counts?.iic_id}}" target="_blank">https://yukti.mic.gov.in/login/{{counts?.iic_id}}</a></p>
          <p>(Please share this link with your Students/Alumni/Recent Graduates/ Faculty/Staff /Entrepreneur/Startup Founders incubated at the Incubation Unit of the institute for registration and submission of their Ideas/Innovations/Startups developed/supported at your institute)</p>
        </div>
        <!-- <div class="col-3" style="text-align: right;"><a class="btn btn-primary" routerLink='/institute/innovation-repository-add/add'>Add Innovation</a></div> -->
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" style="box-shadow:0 0px 10px rgb(0 0 0 / 20%) !important">
            <div class="mt-4 table-responsive w-100">
              <table class="table table-hover ideaTableHover table-bordered">
                <thead class="facultyTable">
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Team Leader Details</th>
                    <th scope="col">Usertype</th>
                    <th scope="col">View & Verify ID</th>
                    <th scope="col">No. of Ideas</th>
                    <th scope="col">No. of Innovations</th>
                    <th scope="col">No. of Startups</th>
                    <th scope="col">Verification Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let idea of ideaSubmission; let i = index">
                    <td>{{ i+1 }}</td>
                    <td>{{ idea?.team_lead_name }}<br/>{{ idea?.team_lead_email }}<br/>{{ idea?.team_lead_phone }}</td>
                    <td>{{ idea?.user_type }}<br>
                      {{ idea?.enrollment_id }}<br></td>
                    <td>
                      
                      
                      <span *ngIf="idea?.verify_status=='Yes'" style="text-align: center;"><button type="button" (click)="openEvaluationDialogTeamData(idea)" class="btn btn-success viewBtn btngreen">
                        View Details
                      </button>  </span>
                      
                      <span *ngIf="idea?.verify_status==null"><button type="button" (click)="openEvaluationDialogTeamData(idea)" class="btn btn-success viewBtn">
                        View & Verify ID
                      </button>  </span>

                      <span *ngIf="idea?.verify_status==''"><button type="button" (click)="openEvaluationDialogTeamData(idea)" class="btn btn-success viewBtn">
                        View & Verify ID
                      </button>  </span>

                    </td>
                    <td>
                      <span *ngIf="idea?.total_idea">{{idea?.total_idea}}</span>
                      <span *ngIf="!idea?.total_idea">0</span>
                    </td>
                    <td>
                      <span *ngIf="idea?.total_innovation">{{idea?.total_innovation}}</span>
                      <span *ngIf="!idea?.total_innovation">0</span></td>
                    <td>
                      <span *ngIf="idea?.total_startup">{{idea?.total_startup}}</span>
                      <span *ngIf="!idea?.total_startup">0</span></td>
                    <td>
                      <span *ngIf="idea?.verify_status=='Yes'" style="text-align: center;">Verified</span>
                      <span *ngIf="idea?.verify_status==''">Verification Pending</span>
                      <span *ngIf="idea?.verify_status==null">Verification Pending</span>
                    </td>  
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="row">
                    <div class="col-md-12">
                        <mat-paginator #paginator
                                        showFirstLastButtons
                                        (page)="nextPage($event)"
                                        [length]="total_pages"
                                        [pageIndex]="frontCurrentPageNo"
                                        [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                                    </mat-paginator>

                    </div>
                </div> -->
          </div>
        </div>
      </div>
    </div>


    </mat-card>
