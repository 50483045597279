import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';
import { emailValidator } from 'src/app/app.component';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';
import { Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-institute-faculty-dialog',
  templateUrl: './institute-faculty-dialog.component.html',
  styleUrls: ['./institute-faculty-dialog.component.css']
})
export class InstituteFacultyDialogComponent implements OnInit {
  public facultyForm: FormGroup;
  show: boolean;
  show1: boolean;
  fbl: any;
  fbl1: any;
  id1: any;
  name1: any;
  email1: any;
  mobile1: any;
  designation1: any;
  deaprtment1: any;
  experience_in_years1: any;
  // roles1: any;
  qualification1: any;
  roleModel = [];
  qualificationModel: any;
  gender: any;
  mstatusform: any;

  // Role = ['Vice President', 'Convener', 'Social Media', 'Innovation Activity', 'Internship Activity Coordinator', 'IPR Activity Coordinator', 'Start up Activity Coordinator', 'ARIIA Coordinator', 'NIRF Coordinator', 'Member'];
  role1: any;
  Role: any;

  constructor(private fb: FormBuilder,
    private jarwis: JarwisService,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteFacultyDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
    }
  }




  ngOnInit(): void {
    this.mstatusform = localStorage.getItem('UserMenteeStatus');
    this.facultyForm = this.fb.group({
      'name': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, emailValidator,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
      ],
      'mobile': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      'designation': [null, Validators.required],
      'deaprtment': [null, Validators.required],
      'experience_years': [null, Validators.required],
      'roles': [null, Validators.required],
      'qualification': [null, Validators.required],
      'id': [null],
      'gender':[null, Validators.required]
    });

    this.getRole();

    if (this.data) {
      this.fbl = this.data;
      this.name1 = this.data['name'];
      this.email1 = this.data['email'];
      this.mobile1 = this.data['mobile'];
      this.designation1 = this.data['designation'];
      this.deaprtment1 = this.data['deaprtment'];
      this.experience_in_years1 = this.data['experience_in_years'];
      this.id1 = this.data['id'];
      this.qualification1 = this.data['qualification'];
      this.role1 = this.data['roles'];
      this.gender = this.data['gender'];

      this.facultyForm.controls['name'].setValue(this.name1);
      this.facultyForm.controls['email'].setValue(this.email1);
      this.facultyForm.controls['mobile'].setValue(this.mobile1);
      this.facultyForm.controls['designation'].setValue(this.designation1);
      this.facultyForm.controls['deaprtment'].setValue(this.deaprtment1);
      this.facultyForm.controls['experience_years'].setValue(this.experience_in_years1);
      this.facultyForm.controls['roles'].setValue(this.role1);
      // this.facultyForm.controls['qualification'].setValue(this.qualification1);
      this.facultyForm.controls['id'].setValue(this.id1);
      this.facultyForm.controls['gender'].setValue(this.gender);
      this.roleModel = this.role1.split(',');

      if (this.data['qualification'] == "Post Graduate") {
        this.qualificationModel = "1";
      } else if (this.data['qualification'] == "Doctorate") {
        this.qualificationModel = "2";
      } else if (this.data['qualification'] == "Post Doctorate") {
        this.qualificationModel = "3";
      } else if (this.data['qualification'] == "Graduate") {
        this.qualificationModel = "4";
      } else if (this.data['qualification'] == "Diploma") {
        this.qualificationModel = "5";
      }

    }
  }

  onSubmit(values) {

    if (values['roles'].length <= 2) {

      if (((values['roles']['0'] == "Vice President") && (values['roles']['1'] == "Convener")) || ((values['roles']['0'] == "Convener") && (values['roles']['1'] == "Vice President"))) {
        this.toastr.error("Vice President Role cannot be combined with any other roles !")
      } else {
        if (this.show1 == true) {

          this.myCouncilService.submitFaculties(values).subscribe(
            data => this.handleResponse(data)
          );
        }
        else {

          this.myCouncilService.updateFaculty(values).subscribe(
            data => this.handleResponse1(data)
          );
        }
      }

    } else {
      this.toastr.error('Maximum 2 roles can be selected !')
    }
  }


  getRole() {
    if (this.data) {
      this.myCouncilService.getRoleFaculty(this.data['id']).subscribe(
        data => this.handleResponse5(data)
      )
    } else {
      this.myCouncilService.getRoles().subscribe((data) => this.handleResponse5(data));
    }

  }

  handleResponse1(data) {
    if (data['status'] == "1") {
      this.toastr.success("Faculty Member Updated Successfully !");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
    this.myCouncilService.dataUpdated.next(true);
  }


  handleResponse(data) {
    if (data['status'] == "1") {
      this.toastr.success("Faculty Member Added Successfully !");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
    this.myCouncilService.dataUpdated.next(true);
  }

  handleResponse5(data) {
    this.Role = data['rolesFaculty'];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

}
