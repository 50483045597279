<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover" *ngIf="showCertificate==1">
                    <thead>
                        <tr>
                            <td colspan="3" allign="left" class="danger"
                                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Certificate</h1>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Title</th>
                            <th scope="col">Report</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="showEstablishment">

                        <tr *ngIf="showRating2023">
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Letter of Appreciation 2022-23</td>
                            <td class="downloadD">
                                <button type="button" class="btn btn-success btnDownSuccessCerti"
                                    (click)="getLetterofAppriciation('2022-23')"> Download
                                </button>
                            </td>
                        </tr>
                        
                        <tr *ngIf="showRating2022">
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Letter of Appreciation 2021-22</td>
                            <td class="downloadD">
                                <button type="button" class="btn btn-success btnDownSuccessCerti"
                                    (click)="getLetterofAppriciation('2021-22')"> Download
                                </button>
                            </td>
                        </tr>

                        <tr *ngIf="showRating2023">
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Ratings Certificate 2022-23</td>
                            <td class="downloadD">
                                <button type="button" class="btn btn-success btnDownSuccessCerti"
                                    (click)="getRatingCertificate2023('2022-23')"> Download
                                </button>
                            </td>
                        </tr>
                        
                        <tr *ngIf="showRating2022">
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Ratings Certificate 2021-22</td>
                            <td class="downloadD">
                                <button type="button" class="btn btn-success btnDownSuccessCerti"
                                    (click)="getRatingCertificate2022('2021-22')"> Download
                                </button>
                            </td>
                        </tr>

                        <tr *ngIf="showRating2021">
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Ratings Certificate 2020-21</td>
                            <td class="downloadD">
                                <button type="button" class="btn btn-success btnDownSuccessCerti"
                                    (click)="getRatingCertificate2021('2020-21')"> Download
                                </button>
                            </td>
                        </tr>

                        <tr *ngIf="showRating1920">
                          <td class="starD">
                              <mat-icon>stars</mat-icon>
                          </td>
                          <td>Ratings Certificate 2019-20</td>
                          <td class="downloadD">
                              <button type="button" class="btn btn-success btnDownSuccessCerti"
                                  (click)="getRatingCertificate1920()"> Download
                              </button>
                          </td>
                      </tr>
                        <tr *ngIf="showRating">
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Ratings Certificate 2018-19</td>
                            <td class="downloadD">
                                <button type="button" class="btn btn-success btnDownSuccessCerti"
                                    (click)="getRatingCertificate()"> Download
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Establishment Certificate</td>
                            <td class="downloadD">
                                <button type="button" class="btn btn-success btnDownSuccessCerti"
                                    (click)="getEstablishmentCertificate2()">
                                    Download
                                </button>
                            </td>
                        </tr>





                        <!-- <tr>
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Member Certificate 2018-19</td>
                            <td class="downloadD">
                                <button type="button" class="btn btn-success btnDownSuccessCerti"
                                    (click)="openDialog()">
                                    Download
                                </button>
                            </td>
                        </tr> -->


                    </tbody>
                    <tbody *ngIf="!showEstablishment">
                        <tr style="text-align: center;">
                            <td colspan="3">No Certificate to download</td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-hover" *ngIf="showCertificate==0">
                    <thead>
                        <tr>
                            <td colspan="3" allign="left" class="danger"
                                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Certificate</h1>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Title</th>
                            <th scope="col">Report</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr style="text-align: center;">
                            <td colspan="3">No Certificate to download</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>


<!-- <div class="certificateBoxProfile">
<div class="container">
<div class="row profileRowMargin">
    <div class="col-4">
	<div class="">
      <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG" (click)="getRatingCertificate()">
        <p class="profilePara">Ratings Certificate 2019-20 </p><span class="badge badge-light ml-2">
          <mat-icon> arrow_circle_down</mat-icon>
        </span>
      </button>
	 </div>
    </div>
    <div class="col-4">
	<div class="">
      <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG" (click)="getEstablishmentCertificate()">
        <p class="profilePara">Establishment Certificate </p><span class="badge badge-light ml-2">
          <mat-icon> arrow_circle_down</mat-icon>
        </span>
      </button>
	  </div>
    </div>
  </div>
  </div>
  </div> -->
