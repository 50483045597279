<!--title-->
   <title>

    About MHRD’S Innovation Cell | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>

<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.php">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">About Us</a>
      </li>
      <li class="aht-breadcrumbs__item">
        MHRD's Innovation Cell
      </li>
    </ul>
  </div>
<section class="paddsection">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="titleheadertop">

                  <h1 class="inner-theme-heading">MHRD's Innovation Cell</h1>

                </div>

            </div>

        </div>





        <div class="row rowmar">

            <div class="col-md-6">

                <div class="textboxsih rankingmar abouts1">

                    <div class="grediyant">

                  <h4>Ministry of Human Resource Development, Govt. of India has established an ‘Innovation cell’ with a purpose of systematically fostering the culture of Innovation in all Higher Education Institutions (HEIs) across the country.</h4>

              </div>

                </div>



                

            </div>



            <div class="col-md-6">

                <!-- <div class="imagesbox rankingimg abouts1img">

                  <div class="abtSlider abtMHRDSlider">
					  <div class="slide"><img src="{{imageBasePath}}assets/wp_images/about1.png"></div>
					  <div class="slide"><img src="{{imageBasePath}}assets/wp_images/about2.png"></div>
					  <div class="slide"><img src="{{imageBasePath}}assets/wp_images/yukti.png"></div>
					  <div class="slide"><img src="{{imageBasePath}}assets/wp_images/about3.png"></div>
					  <div class="slide"><img src="{{imageBasePath}}assets/wp_images/DDH.png"></div>
					</div>

                </div> -->
				<div class="imagesbox rankingimg abouts1img">
				<div id="carouselExampleControls1" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="{{imageBasePath}}assets/wp_images/DDH.png">
      </div>
      <div class="carousel-item">
        <img src="{{imageBasePath}}assets/wp_images/about2.png">
      </div>
      <div class="carousel-item">
        <img src="{{imageBasePath}}assets/wp_images/yukti.png">
      </div>
	  <div class="carousel-item">
        <img src="{{imageBasePath}}assets/wp_images/about3.png">
      </div>
	  <div class="carousel-item">
        <img src="{{imageBasePath}}assets/wp_images/DDH.png">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div> 
  </div>

            </div>

        </div>





                <div class="row">

            <div class="col-md-12">
			<div class="rankingtext">

                   <p>MIC will focus on creating complete ecosystem which will foster the culture of Innovation across all educational institutions from ideas generation to pre-incubation, incubation and graduating from the incubator as successful start-ups. MIC will also work on designing ranking system to identify institutions in the forefront of innovation.</p>

                </div>

                <div class="rankingtextwidt">

                <p>Ministry of HRD has established ‘MRHD’s Innovation Cell’ with the mandate to work closely with our Higher Education Institutions (HEIs) to encourage the creative energy of our student population to work on new ideas and innovation and promote them to create start-ups and entrepreneurial ventures.</p>

                

                </div>

            </div>

        </div>
    </div>

</section>

<section class="sectionbutton">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="textbotton">

                    <h4>For more detail about our initiatives <a href="assets/brochure/index.html" target="_blank">Go through our brohcure</a></h4>

                </div>

            </div>

        </div>

    </div>

</section>

<!-- Footer -->
<app-inner-footer></app-inner-footer>