import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';

@Component({
  selector: 'app-atl-login-dialog',
  templateUrl: './atl-login-dialog.component.html',
  styleUrls: ['./atl-login-dialog.component.css']
})
export class AtlLoginDialogComponent implements OnInit {

  edit: boolean;
  editData: any;
  id: any;
  report: FileList | null;
  public addActivityForm: FormGroup;
  date1:any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AtlLoginDialogComponent>,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public activity: InstituteActivityService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      
     }

  ngOnInit(): void {
   
     this.addActivityForm = this.fb.group({
      'udise_number': [null,Validators.required]
     });
  }
  onSubmit(data){

    var formData = new FormData();
    formData.append('udise_number',this.addActivityForm.get('udise_number').value);
      this.activity
        .addUDISENumber(formData)
        .subscribe((data) => this.handleResponse(data));
  }
  handleResponse(data){
    if(data['message']=="Success"){
      this.toastr.success("Submitted Successfully!");
      window.location.href='/institute/atlsic-dashboard';
    }
    else{
      this.toastr.error("Kindly check the values!")

    }
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

}
