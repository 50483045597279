<div class="container">
    <div class="row" color="primary">
		<div class="col-md-12">
			<h2 class="ss"
				style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
				Nominate Representatives for IIC Regional Meet
			</h2>
		</div>
    </div>


<div class="row">
	<div class="col-md-12">
		<form class="instiBankForm" [formGroup]="iicCoordinatorAddition" (ngSubmit)="onSubmit(iicCoordinatorAddition.value)">
			<div class="card" style="width:50%; margin:0 auto;">
				<mat-select placeholder="Select Nominated For" formControlName="coordinator_type" [(ngModel)]="coordinator_type" class="instiIICcalActi">
					<mat-option *ngFor="let role of Role" [value]="role">
						For {{role}}
					  </mat-option>
				</mat-select>
			</div>

	<mat-radio-group formControlName="submission_type" (change)="onStatusChange($event)"  aria-label="Select an option" class="col-md-12" >
		<mat-radio-button class="alumniRadioBut" value="IIC Members" style="margin-right: 30px;"><p class="radioButpara">IIC Member</p></mat-radio-button>
		&nbsp;&nbsp;&nbsp;&nbsp;
		<mat-radio-button class="alumniRadioBut" value="Others"><p class="radioButpara">Others</p></mat-radio-button>
	</mat-radio-group>

		<table class="table table-hover">
			<tr class="ifYes" *ngIf="requireField==1" >
				<td>
					Select as Co-ordinator
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label> Select Co-ordinator </mat-label>
						<mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name"  required (selectionChange)="onNbaStatusChange($event)">
							<mat-option>Select</mat-option>
							<mat-option value="{{activity.id}}" *ngFor="let activity of iicActivity">{{activity.name}}</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			</tr>
			<tr>
				<td>
					Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Name</mat-label>
						<input matInput autocomplete="off" placeholder="Name" formControlName="name" type="text" [value]="name"  required>
					</mat-form-field>
				</td>
			</tr>
      		<tr>
				<td>
					Email
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Email</mat-label>
						<input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email" [value]="email"  required>
					</mat-form-field>
				</td>
			</tr>
      		<tr>
				<td>
					Contact
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Contact</mat-label>
						<input matInput autocomplete="off" placeholder="Contact" formControlName="contact" type="text" [value]="contact"  required>
            		</mat-form-field>
				</td>
			</tr>
			<tr>
				<td>
					Gender
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label> Select Gender </mat-label>
						<mat-select autocomplete="off" placeholder="Select Gender" formControlName="gender"  required>
							<mat-option>Select</mat-option>
							<mat-option value="Male">Male</mat-option>
							<mat-option value="Female">Female</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			</tr>
			<tr>
				<td>
					City
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100" >
						<mat-label> Select City </mat-label>
						<mat-select autocomplete="off" placeholder="Select City" (selectionChange)="onCityChange($event)" formControlName="city"  required >
							<mat-option>Select</mat-option>
							<mat-option value="{{iicCity.host_city}}" *ngFor="let iicCity of iicCoordinatorCity">{{iicCity.host_city}}</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			</tr>
			<tr class="ifYes" *ngIf="requireField1==1" >
				<td>
					Institute Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Institute Name</mat-label>
						<input matInput autocomplete="off" placeholder="Institute Name" formControlName="ins_name" type="text">
            		</mat-form-field>
				</td>
			</tr>
			<tr class="ifYes" *ngIf="requireField1==1" >
				<td>
					Mentoring Session Date
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Mentoring Session Date</mat-label>
						<input matInput autocomplete="off" placeholder="Mentoring Session Date" formControlName="session_date" type="text">
            		</mat-form-field>
				</td>
			</tr>
			<tr *ngIf="poster_count==0">
				<td>
					Are you bringing your poster?
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100" >
						<mat-label> Are you bringing your poster? </mat-label>
						<mat-select autocomplete="off" placeholder="Are you bringing your poster" formControlName="poster" required >
							<mat-option value="Yes">Yes</mat-option>
							<mat-option value="No">No</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			</tr>
			<tr>
				<td>
					Will you showcase innovation
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100" >
						<mat-label> Will you showcase innovation </mat-label>
						<mat-select autocomplete="off" placeholder="Will you showcase innovation" (selectionChange)="onInnovationChange($event)" formControlName="showcase" required >
							<mat-option value="Yes">Yes</mat-option>
							<mat-option value="No">No</mat-option>
						</mat-select>
					</mat-form-field>

					<div *ngIf="poster_count==1">
						<input matInput type="hidden" formControlName="poster" value="" >
					</div>
				</td>
			</tr>

			<tr *ngIf="requireField2==1">
				<td>
					No. of Innovation(s)
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>No. of Innovation(s)</mat-label>
						<input matInput type="number" min="1" max="200" autocomplete="off" placeholder="No. of Innovation(s)" formControlName="innovation_count">
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					&nbsp;
				</td>
				<td>
					<button mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicCoordinatorAddition.invalid'>Submit</button>
				</td>
			</tr>
	   </table>
	</form>
</div>	
</div>
