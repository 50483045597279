import { Component, OnInit } from '@angular/core';
import baguetteBox from 'baguettebox.js';
import * as myGlobals from '../../../../../../app/app.component';
@Component({
  selector: 'app-ntw2023',
  templateUrl: './ntw2023.component.html',
  styleUrls: ['./ntw2023.component.css']
})
export class Ntw2023Component implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

   ngOnInit(): void {
    baguetteBox.run('.gallery', {
      captions: true, // display image captions.
      buttons: 'auto', // arrows navigation
      fullScreen: false,
      noScrollbars: false,
      bodyClass: 'baguetteBox-open',
      titleTag: false,
      async: false,
      preload: 2,
      animation: 'fadeIn', // fadeIn or slideIn
      verlayBackgroundColor: 'rgba(0,0,0,.8)'
    });
  }

}
