import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IicInnovationAmbassadorService } from 'src/app/Services/iic-innovation-ambassador.service';


@Component({
  selector: 'app-new-ia-request',
  templateUrl: './new-ia-request.component.html',
  styleUrls: ['./new-ia-request.component.css']
})
export class NewIaRequestComponent implements OnInit {

  list: any;
  page: any;
  page2: any;
  list2: any;

  constructor(private instituteAmbassadorService: IicInnovationAmbassadorService, private toastr:ToastrService) {
    this.list = [];
    this.list2 = [];
  }

  ngOnInit(): void {
    this.getRequests();
    this.getSubmitted();
  }
  getSubmitted(){
    this.instituteAmbassadorService.getSubmitted().subscribe(
      data =>
        this.setSubmitted(data));

  }
  setSubmitted(data) {
    this.list2=data;
    // for(let i=0;i<data.length;i++){
    //   this.list2.push(data[0][i]);
    // }
  }
  getRequests() {
    this.instituteAmbassadorService.getRequest().subscribe(
      data => {
        this.setRequests(data)
      }
    )

  }
  setRequests(data) {
    this.list=[];
    for(let i=0;i<data.length;i++){
      this.list.push(data[i]);
    }
  }

  public onPageChanged(event) {
    this.page = event;
  }

  public onPageChanged2(event) {
    this.page2 = event;
  }
  respondRequest(data,status){
    data['status']=status;
    this.instituteAmbassadorService.submitRequest(data).subscribe(data=>this.handleResponse(data));
  }
  handleResponse(data){
    if(data['status']==1){
      this.toastr.success("Ambassador accepted");
      document.location.href="/institute/request-ambassador";
    }
    else
      this.toastr.error("Ambasssador not rejected");

  }

}
