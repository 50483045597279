import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as myGlobals from 'src/app/app.component';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-activity-admin',
  templateUrl: './edit-activity-admin.component.html',
  styleUrls: ['./edit-activity-admin.component.css'],
})
export class EditActivityAdminComponent implements OnInit {
  event_id: string;
  public activitySubmit: FormGroup;
  admin: boolean = false;
  baseUrl = myGlobals.baseUrl1;
  imageBasePath = myGlobals.imageBasePath;
  selectedFile: File | null = null;
  name: any;
  file: any;

  constructor(
    private myCouncilService: InstituteMyCouncilService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private instituteActivityService: InstituteActivityService,
    private toastr: ToastrService,
  ) 
  {
      this.activitySubmit = this.fb.group({
      type: ['', Validators.required],
      quarter: ['', Validators.required],
      title: ['', Validators.required],
      theme: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
      description: ['', Validators.required],
      status :['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.myCouncilService.getUser().subscribe((data) => {
      this.admin = data['institute_id'] === 'IC201810001';
    });
    this.route.params.subscribe((params) => {
      this.event_id = params['event_id'];
    });

    if ( this.event_id ) {
      this.instituteActivityService
        .getReport(this.event_id)
        .subscribe(
          (data) => {
            this.handleResponse(data)
            console.log(data);
            this.name = data['title']
            this.file = data['attachmentPath']
          },
          (error) => {
            console.error('There was an error fetching the activity data:', error);
            this.toastr.error('There was an error fetching the activity data.');
          }
        );
    }

  }

  handleResponse(data: any): void {
    console.log(data); 
 
    // const startdate = data.startdate ? this.formatDate(data.startDate) : '';
    // const enddate = data.enddate ? this.formatDate(data.endDate) : '';

    this.activitySubmit.patchValue({
      type: data.basicType || '', 
      quarter: data.quarter || '',
      title: data.title || '',
      theme: data.theme || '',
      startdate: data.startDate,
      enddate: data.endDate, 
      description: data.description || '',
      status: data.show_status || 0,
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const label = document.querySelector(`label[for="fileUpload"]`);
  
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.selectedFile = file;
      label.textContent = file.name;
    } else {
      this.selectedFile = null;
      label.textContent = 'Choose file';
    }
  }

  onSubmit(): void {
    const value = this.activitySubmit.value;
    console.log("Form Value: ", value);
    console.log("Form Validity: ", this.activitySubmit.valid);
    console.log("Selected File: ", this.selectedFile);
    if (this.activitySubmit.invalid || !this.selectedFile) {
      this.toastr.error('Please fill in all required fields and upload a file.');
      return;
    }

    const formData = new FormData();
    Object.keys(this.activitySubmit.value).forEach(key => {
      formData.append(key, this.activitySubmit.value[key]);
    });
    formData.append('file', this.selectedFile);
  
    this.submitActivity(formData);
  }

  submitActivity(formData: FormData): void {
    this.instituteActivityService.updateReport(formData, this.event_id).subscribe(
      data => this.handleResponseData(data),
      error => {
        console.error('There was an error submitting the activity!', error);
        this.toastr.error('There was an error submitting the activity.');
      }
    );
  }

  handleResponseData(data: any): void {
    if (data['status'] === "1") {
      this.activitySubmit.reset();
      this.selectedFile = null;
      this.toastr.success("Activity updated Successfully");
      this.router.navigate(['/institute/activity-scheduled']); 
    } else {
      this.toastr.error(data['msg']);
    }
  }

  back(){
    window.history.go(-1);
  }


}
