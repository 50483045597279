<div class="container">
  <mat-dialog-content class="mat-typography instiFactDialoge">
    <form [formGroup]="facultyForm" (ngSubmit)="onSubmit(facultyForm.value)">
      <div class="row">

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" placeholder="Name" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="facultyForm.controls['name'].hasError('required')">Faculty Name is
              required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">
          <mat-form-field class="gender example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Gender</mat-label>
            <mat-select placeholder="Gender" formControlName="gender" required>
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error *ngIf="facultyForm.controls['gender'].hasError('required')">Faculty gender is
              required</mat-error>

          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">mail</mat-icon>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" placeholder="Email" required (keypress)=isSpace($event)>
            <mat-error *ngIf="facultyForm.controls['email'].hasError('required')">Faculty Email is
              required</mat-error>
            <mat-error *ngIf="facultyForm.controls['email'].hasError('invalidEmail')">Invlaid Email
              Address</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stay_current_portrait
            </mat-icon>
            <mat-label>Contact</mat-label>
            <input matInput formControlName="mobile" placeholder="Contact" minlength="10" maxlength="10" required
              (keypress)=isNumberKey($event)>
            <mat-error *ngIf="facultyForm.controls['mobile'].hasError('required')">Contact Number
              is required</mat-error>
            <mat-error *ngIf="facultyForm.controls['mobile'].hasError('minlength')">Contact Number
              should be of 10 numbers </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">assignment_turned_in
            </mat-icon>
            <mat-label>Designation</mat-label>
            <input matInput formControlName="designation" placeholder="Designation" required
              (keypress)=isCharKey($event)>
            <mat-error *ngIf="facultyForm.controls['designation'].hasError('required')">Faculty
              Designation is
              required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">account_circle</mat-icon>
            <mat-label>Department</mat-label>
            <input matInput formControlName="deaprtment" placeholder="Department" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="facultyForm.controls['deaprtment'].hasError('required')">Faculty
              Department is
              required</mat-error>
          </mat-form-field>
        </div>

      </div>

      <div class="row">

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon>
            <mat-label>Qualification</mat-label>
            <mat-select placeholder="Qualification" formControlName="qualification" required
              [(ngModel)]="qualificationModel">
              <mat-option value="1">Post Graduate</mat-option>
              <mat-option value="2">Doctorate</mat-option>
              <mat-option value="3">Post Doctorate</mat-option>
              <mat-option value="4">Graduate</mat-option>
              <mat-option value="5">Diploma</mat-option>
            </mat-select>
            <mat-error *ngIf="facultyForm.controls['qualification'].hasError('required')">Faculty
              Qualification is
              required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">article</mat-icon>
            <mat-label>Experience in Years</mat-label>
            <input matInput type="number" step="0.01" formControlName="experience_years" placeholder="Experience in Years" required
              maxlength="4">
            <mat-error *ngIf="facultyForm.controls['experience_years'].hasError('required')">Faculty
              Experience is
              required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">


          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
            <!-- <mat-label>Assign Roles</mat-label>
            <input matInput formControlName="roles" placeholder="Assign Roles" required>
            <mat-error *ngIf="facultyForm.controls['roles'].hasError('required')">Assign roles is
              required</mat-error> -->
            <mat-label>Assign Roles</mat-label>

            <mat-select formControlName="roles" multiple [(ngModel)]="roleModel">
              <app-select-check-all [model]="facultyForm.get('roles')" [values]="Role">
              </app-select-check-all>
              <mat-option *ngFor="let role of Role" [value]="role">
                {{role}}
              </mat-option>
            </mat-select>
          </mat-form-field>

      </div>
      </div>
      <div class="row">
      </div>
      <div mat-dialog-actions>
        <button type="button" mat-raised-button color='warn' (click)='onNoClick()' style="margin-left: 280px;
            " class="btn btn-danger instituteBtnClose">Close</button>
        <button *ngIf="show1" mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='facultyForm.invalid'>Submit</button>

        <button *ngIf="show" mat-raised-button color='primary' class="btn btn-success float-right"
          >Update</button>
      </div>
    </form>
  </mat-dialog-content>
</div>
