import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { emailValidator } from 'src/app/app.component';

@Component({
  selector: 'app-yukti-innovator-form',
  templateUrl: './yukti-innovator-form.component.html',
  styleUrls: ['./yukti-innovator-form.component.css']
})
export class YuktiInnovatorFormComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  protoTypeList:any;
  isTransfer:any = 0;
  isNew:any = 1;

  constructor(private fb: FormBuilder, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {



  }

  ngOnInit(): void {
    this.iicImpactQuestions = this.fb.group({
      'name': [null, Validators.required],
      'email': [null, Validators.compose([Validators.required, emailValidator])],
      'mobile_number': [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      'gender': [null, Validators.required],
      'user_type': [null, Validators.required],
      'proto_type': [null, Validators.required],
      'title': [null, Validators.required],
      'developed_as_part_of': [null, Validators.required],
      'academic_year': [null, Validators.required]
    });

    this.instituteActivityService.getProtoType().subscribe(
      (data) => this.handleResponseProtoType(data)
    );

  }

  handleResponseProtoType(data) {
    this.protoTypeList = data;
  } 
  radioChange(value){
    if(value == 'transfer'){
      this.isTransfer = 1;
      this.isNew = 0;
    }else if(value == 'new'){
      this.isTransfer = 0;
      this.isNew = 1;

    }

  }

  onSubmit(data) {
    var formData = new FormData();

    formData.append('name', this.iicImpactQuestions.get('name').value);
    formData.append('email', this.iicImpactQuestions.get('email').value);
    formData.append('mobile_number', this.iicImpactQuestions.get('mobile_number').value);
    formData.append('gender', this.iicImpactQuestions.get('gender').value);
    formData.append('user_type', this.iicImpactQuestions.get('user_type').value);
    formData.append('type', 'new');

    // if(this.isTransfer){
    //   if(this.iicImpactQuestions.get('prototype').value == "" || this.iicImpactQuestions.get('prototype').value == null){
    //     this.toastr.error("Prototype Field Required");
    //     return false;
    //   }
    //   formData.append('prototype', this.iicImpactQuestions.get('prototype').value);
    // }else{
      if(this.iicImpactQuestions.get('proto_type').value == "" || this.iicImpactQuestions.get('proto_type').value == null){
        this.toastr.error("Prototype Field Required");
        return false;
      }
      formData.append('proto_type', this.iicImpactQuestions.get('proto_type').value);
      if(this.iicImpactQuestions.get('title').value == "" || this.iicImpactQuestions.get('title').value == null){
        this.toastr.error("Title Field Required");
        return false;
      }
      formData.append('title', this.iicImpactQuestions.get('title').value);
      if(this.iicImpactQuestions.get('developed_as_part_of').value == "" || this.iicImpactQuestions.get('developed_as_part_of').value == null){
        this.toastr.error("Developed as part of Field Required");
        return false;
      }
      formData.append('developed_as_part_of', this.iicImpactQuestions.get('developed_as_part_of').value);
      if(this.iicImpactQuestions.get('academic_year').value == "" || this.iicImpactQuestions.get('academic_year').value == null){
        this.toastr.error("Academic year Field Required");
        return false;
      }
      formData.append('academic_year', this.iicImpactQuestions.get('academic_year').value);
    // }

    // var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    // if(this.totalcount>0)
    // {
    //   formData.append('document', cvFile);
    //   formData.append('document_old', this.document);
    // }
    // else
    // {
    //   if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
    //   formData.append('document', cvFile);
    // }

    this.instituteActivityService.submityuktiInnovatorRegistration(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/yukti-innovator-form';
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
}
