<div class="container">
    <mat-dialog-content class="mat-typography instiFactDialoge">
    <h3>Innovation Ambassador - Submitted Upskilling/Reskilling Trainings</h3>
    <table class="table">
      <tbody>
        <!-- <tr>
          <td colspan="4"><h4>Promotion in social media</h4></td>
        </tr> -->
        <tr>
          <td><strong>Training Title</strong><br/>
            {{showData?.training_title}}</td>
          <td><strong>Training Type</strong><br/>
            {{showData?.training_type}}</td>
          <td><strong>Agency Type</strong><br/>
            {{showData?.agency_type}}</td>
          <td><strong>Training Given by</strong><br/>
            {{showData?.training_given_by}}</td>
        </tr>
        <!-- <tr>
          <td colspan="4"><h4>Program Information</h4></td>
        </tr> -->
        <tr>
          <td><strong>Training Mode</strong><br/>
            {{showData?.training_mode}}</td>
          <td><strong>Sponsered</strong><br/>
            {{showData?.sponsered}}</td>
          <td><strong>Duration</strong><br/>
            {{showData?.duration1}}</td>
          <td><strong>Training Theme</strong><br/>
            {{showData?.training_theme}}</td>
        </tr>
        <tr>
          <td><strong>Summary of Training</strong><br/>
            {{showData?.summary_of_training}}</td>
          <td><strong>Academic Year</strong><br/>
            {{showData?.academic_year}}</td>
          <td><strong>Training Quarter</strong><br/>
            {{showData?.training_quarter}}</td>
          <td><strong>Skill</strong><br/>
                {{showData?.skill}}</td>
        </tr>
        <tr>
          <td><strong>Start Date</strong><br/>
            {{showData?.start_date}}</td>
          <td><strong>End Date</strong><br/>
            {{showData?.end_date}}</td>
          <td><strong>Status</strong><br/>
            {{showData?.status}}</td>
          
        </tr>
      </tbody>
    </table>
    
    <div class="iicformsection">
      <form [formGroup]="facultyForm" (ngSubmit)="onSubmit(facultyForm.value)">
    
        <div class="row">
    
          <div class="col-sm-6">
            <mat-form-field class="gender example-full-width" appearance="outline">
              <mat-label>Verification Status</mat-label>
              <mat-select placeholder="Verification Status" formControlName="status" required>
                <mat-option value="Verified">Approved</mat-option>
                <mat-option value="Unverified">Disapproved</mat-option>
              </mat-select>
              <mat-error *ngIf="facultyForm.controls['status'].hasError('required')">Verification Status is required</mat-error>
    
            </mat-form-field>
          </div>
    
          <div class="col-sm-3">
            <button mat-raised-button color='primary' class="btn btn-success float-right"
              [disabled]='facultyForm.invalid'>Submit</button>
          </div>
    
          <div class="col-sm-3">
            <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btn btn-danger instituteBtnClose">Close</button>
          </div>
    
          </div>
    
      </form>
    </div>
    
    </mat-dialog-content>
    </div>
    