import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { emailValidator } from 'src/app/app.component';

@Component({
  selector: 'app-institute-affiliate-program-edit',
  templateUrl: './institute-affiliate-program-edit.component.html',
  styleUrls: ['./institute-affiliate-program-edit.component.css']
})
export class InstituteAffiliateProgramEditComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  public signupForm: FormGroup;
  public error = [];

  instituteDetails: any;

  institute_name: any = '';
  institute_address: any = '';
  institute_city: any = '';
  institute_state: any = '';
  uploadDiv: boolean;


  constructor(private jarwis: JarwisService, private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });



  }

  ngOnInit(): void {

    this.signupForm = this.fb.group({
      name: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      mobile_number: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      // password: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/()\\[\\{}])(?=.*[A-Z])(?=.*[a-z]).*$')])],
      // confirm_password: [null, Validators.compose([Validators.required, Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/])(?=.*[A-Z])(?=.*[a-z]).*$')])],
    });

    this.instituteActivityService.getIicReferralDetails(this.rid).subscribe(
      (data) => this.handleResponseSetData(data)
    );

  }

  handleResponseSetData(data) {

    // this.signupForm.controls['name'].setValue(selected.id);
    this.signupForm.patchValue({
      name: data['name'],
      email: data['email'],
      mobile_number: data['phone']
    });
  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();
    values = value;
    return values;
  }

  handleData2(data) {
    if (data == "Yes") {
      this.uploadDiv = true;
      this.signupForm.get('pid').setValidators(Validators.required);
      this.signupForm.get('pid').updateValueAndValidity();
    } else if (data == "No") {
      this.signupForm.get('pid').clearValidators();
      this.signupForm.get('pid').updateValueAndValidity();
      this.uploadDiv = false;
    }
  }

  // for toastr
  showSuccess(message: string) {
    this.toastr.success(message, 'Success');
  }
  showError(message: string) {
    this.toastr.error(message, 'Error!');
  }
  showWarning(message: string) {
    this.toastr.warning(message, 'Warning');
  }
  showInfo(message: string) {
    this.toastr.info(message, 'Info');
  }

  onSubmit(values) {

    if (this.signupForm.valid) {

        values['role'] = 3;
        values['action'] = "edit";

        values['rid'] = this.rid;

        this.instituteActivityService.ReferralInsSubmit(values).subscribe(
          data => this.handleResponse(data)
        );

      // if (values.password == values.confirm_password) {
      //   values['role'] = 3;
      //   values['institute_name'] = this.institute_name;
      //   values['address'] = this.institute_address;
      //   values['city'] = this.institute_city;
      //   values['state'] = this.institute_state;

      //   this.instituteActivityService.ReferralInsSubmit(values).subscribe(
      //     data => this.handleResponse(data)
      //   );
      // } else {
      //   this.toastr.error('Passwords does not match');
      // }

    } else {
      this.toastr.error('Kindly fill all the details');
    }

  }

  getAisheDetails(data) {
    this.jarwis.getAisheDetails(data).subscribe(
      data => this.getInstituteDetails(data)
    );
  }

  getInstituteDetails(data) {
    if (data['message'] == "Error") {
      this.toastr.error(data['reason']);
    }
    else {
      this.institute_name = data['aisheDetails']['0']['name'];
      this.institute_address = (data['aisheDetails']['0']['address_line1'] + ' ' + data['aisheDetails']['0']['address_line2']);
      this.institute_city = data['aisheDetails']['0']['city'];
      this.institute_state = data['aisheDetails']['0']['state'];
    }
  }

  handleResponse(data) {
    if (data['message'] == 'Success') {
      alert('Institute invitation has been sent successfully.');
      window.location.href="/institute/referral-institutes";
    } else {
      this.toastr.error(data['reason']);
    }

  }

  handleError(error) {
    this.showError(error.error.errors);
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }


}
