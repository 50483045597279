import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InstituteIncubationService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  submitIncubationData(data) {
    return this.http.post(`${this.baseUrl}/submit-incubation-data`, data);
  }
  getIncubationCenterDetails() {
    return this.http.get(`${this.baseUrl}/get-incubation-data`);
  }
  submitPreIncubationData(data) {
    return this.http.post(`${this.baseUrl}/submit-pre-incubation-data`, data);
  }

  updatePreIncubationData(data, id) {
    return this.http.post(`${this.baseUrl}/update-pre-incubation-data/` + id, data);
  }

  submitIPRTTOData(data) {
    return this.http.post(`${this.baseUrl}/submit-ipr-tto-data`, data);
  }

  updateIPRTTOData(data, id) {
    return this.http.post(`${this.baseUrl}/update-ipr-tto-data/`+ id, data);
  }

  getPreIncubationCenterDetails(data) {
    return this.http.get(`${this.baseUrl}/get-pre-incubation-data/` + data, data);
  }

  getIPRTTOFacilitiesDetails(data) {
    return this.http.get(`${this.baseUrl}/get-ipr-tto-data/` + data, data);
  }

  getPreIncubationDetails() {
    return this.http.get(`${this.baseUrl}/get-pre-incubation`);
  }

  getIPRTTODetails() {
    return this.http.get(`${this.baseUrl}/get-ipr-tto`);
  }


  deletePreIncubationData(data) {
    return this.http.post(`${this.baseUrl}/delete-pre-incubation-data`, data);
  }

  deleteIncubationData(data) {
    return this.http.post(`${this.baseUrl}/delete-incubation-data`, data);
  }

  deleteIPRTTOData(data) {
    return this.http.post(`${this.baseUrl}/delete-ipr-tto-data`, data);
  }

  // editIncubationData(data){
  //   return this.http.post(`${this.baseUrl}/delete-pre-incubation-data`,data);
  // }

  getDropdownList() {
    return this.http.get(`${this.baseUrl}/facilitiesServicesBeneficiaries`);
  }

  getDropdownListPreIncubation() {
    return this.http.get(`${this.baseUrl}/preIncubationFacilities`);
  }

  getThrustAreasList(data) {
    return this.http.get(`${this.baseUrl}/getTrustArea?trust_area=` + data);
  }
}
