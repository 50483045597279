<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">IIC 2019-20</h1> -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC 2019-20</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>At a Glance</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC 2019-20</li>
  </ul>
</nav>
<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>Star Rating 2019-20</h2>
				</div>
			</div>
		</div>
	</div>
</div>


<div id="content" class="content container">
 
<table align="center"  style="margin-left:auto;margin-right:auto;" class="table-hover table-responsive">
	<tr>
		
		<td style="text-align:center;">
		<a href="{{ baseUrl }}/uploads/institutes/homePageNews/TOP_IICs 2020-21-5 from_each_zone.pdf"  target="_blank" class="downLinkDownload">Top 5 from Each Zone
            </a>
		
		</td>
		<td style="text-align:center;">
		<a href="{{ baseUrl }}/uploads/institutes/homePageNews/TOP_IICs 2020-21-Special Category.pdf"  target="_blank" class="downLinkDownload">Special Category
            </a>
		</td>
		<td style="text-align:center;">
		<a href="{{ baseUrl }}/uploads/institutes/homePageNews/TOP_IICs_2019-20_5Star.pdf"  target="_blank" class="downLinkDownload">Best Performers - 5 Star IICs
            </a>
		
		
		</td>
          <td style="text-align:center;">
		  <a href="/iic_rating_2019_20"  class="downLinkDownload">Star Rating 2019-20-complete list
            </a>
             
          </td>
	</tr>
	


</table>

  </div>
<section class="iicDash1920Box">
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<mat-card class="dash19-20Shadow">
					<mat-card-header fxLayoutAlign="center center">
						<mat-card-subtitle>
							<h2 class="dash18-19">
								<mat-icon class="activityDashboard">pie_chart</mat-icon>Activity By Theme
							</h2>
						</mat-card-subtitle>
					</mat-card-header>
					<div class="w-100 h-300p">
						
						<ngx-charts-pie-chart [scheme]="colorScheme" [results]="single" [legend]="showPieLegend" [legendPosition]="legendPosition" 
							[explodeSlices]="explodeSlices" [labels]="showLabels" [doughnut]="doughnut" [trimLabels]="trimLabels" (select)="onSelect($event)">
						</ngx-charts-pie-chart>
					</div>
				</mat-card>
			</div>

			<div class="col-md-6">
				<mat-card class="dash19-20Shadow">
					<mat-card-header fxLayoutAlign="center center">
						<mat-card-subtitle>
							<h2 class="dash18-19">
								<mat-icon class="bookmarkDashboard">bookmark</mat-icon>Overall Impact
							</h2>
						</mat-card-subtitle>
					</mat-card-header>
					
					<div class="w-100 h-300p">
						
						<ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="multi2"
							[xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
							[showYAxisLabel]="showYAxisLabel"
							[yAxisLabel]="yAxisStackedLabel" (select)="onSelect($event)">
						</ngx-charts-bar-vertical-stacked>
					</div>
					<!-- <a routerLink="" class="float-right mb-4">...more</a> -->
				</mat-card>
			</div>
		</div>

		<div class="row dash19-20-pad">
			<div class="col-md-12">
				<mat-card class="dash19-20Shadow">
					<mat-card-header fxLayoutAlign="center center">
						<mat-card-subtitle>
							<h2 class="dash18-19">
								<mat-icon class="activityDashboard">bar_chart</mat-icon>My Activities
							</h2>
						</mat-card-subtitle>
					</mat-card-header>
					<div class="w-100 h-300p">
						<ngx-charts-bar-horizontal-2d [scheme]="barColorScheme" [results]="multi" [gradient]="barGradient"
							[xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showBarLegend" legendPosition="right"
							[showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
							[yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
						</ngx-charts-bar-horizontal-2d>
					</div>
				</mat-card>
			</div>
			
		</div>
	</div>
</section>

<section class="iic2018-19Box">
	<div class="container">
		<div class="cbp-row wh-content">
			<div class="cbp-container wh-padding">
				<div class="vc_row">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>IIC2.0 (Academic Year 2019-20)</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="vc_col-md-12">
				<p class="iic18-19Para">Based on the learnings from IIC1.0 and through large number consultation with HEIs, an upgraded version of IIC (IIC2.0) was designed with better functionalities and flexibilities to address the challenges and constraints faced by HEIs in 1st edition of IIC. Furthermore, incentive and reward system also integrated to create a competitive spirit among HEIs to organise more and more I&E activities in campus. More than 700 new HEIs have joined the IIC network. </p>
			</div>
			
			<div class="vc_col-md-12">
				<div class="headingAmbassadorContent">
					<h3>Distinctive Features of IIC2.0 </h3>
					<ul>
						<li>Introduction of a flexible semester based IIC Activity calendar plan for HEIs to plan and organize the activities in their campuses on regular basis and overcoming the challenges faced by HEIs because of varied academic calendars’ do exist in different states and across India.</li>
						<li>In addition to IIC calendar activities, two more activity types; ‘Self-Driven’ and ‘MIC Driven’ were introduced for HEIs to accommodate and organise more such activities independently and on collaborative mode. </li>
						<li>A methodological approach towards IIC functioning by mandating IIC-Institutes to organise council meetings with well-balanced representatives of experts, faculties and students as IIC member to meet in every quarter to plan and strategize the action plan for next quarter/semester efficiently and effectively. </li>
						<li>Introduction of new matrix for score and star allocation of to HEIs based upon the activity reports submitted and publish of the result on quarterly, and annual basis helped IIC Institutes to track their progress vis-by-vis compering their past quarter performance and also with other HEIs at zonal and national level. </li>
						<li>New upgraded portal of IIC also integrated features of Information dissemination and query resolution.</li>
					</ul>
				</div>
			</div>
		</div>
		
		<div class="cbp-row wh-content">
			<div class="cbp-container wh-padding paddingTwoDayBoot">
				<div class="vc_row">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Two-Days National Level Boot Camp</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>Business Plan & Enterprise Development for students</h3>
						</div>
					</div>
					<div class="vc_col-md-8 bdrbottom">
						<div class="headingAmbassadorContent">
							<ul class="twoDayBootCampUL">
								<li>POC Teams selected from regional level pitching sessions, organised during regional mentoring sessions were further scrutinised by an expert committee at national level at AICTE HQ, New Delhi. Total 43 teams were selected and invited to attend the Two- Day boot camp on Business Plan & Enterprise Development for students. Another 25 teams from Smart India Hackathon and Chatraviswakarma Award (organized by AICTE) were invited to participate in boot camp program at AICTE HQ, New Delhi on 9th and 10th Sept. 2019. On 11th Sept. 2019 these teams demonstrated the prototype at MIC National exhibition at AICTE, New Delhi and 5 teams received MoE’s Innovation Award 2019 by hands of Hon’ble Education Minister. </li>
								<li>Total 73 teams comprise of 140 students selected from IIC PoC Contest, student winner teams from SIH 2019 and teams from Chattra Viswakarma Award 2019 have participated in this bootcamp and exhibition program. More than 60 mentors and experts were part of this 2-day boot camp</li>
							</ul>
						</div>
					</div>
					<div class="vc_col-md-4">
						<div class="headingAmbassadorContent">
							<img class="img-responsive" src="assets/assets/images/boot-camp-on-business-plan.jpg">
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cbp-row wh-content">
			<div class="cbp-container gapDashboard">
				<div class="row">
					<div class="vc_col-md-4">
						<div class="headingAmbassadorContent1">
							<img class="img-responsive" src="assets/assets/images/training-program-for-faculties.jpg">
						</div>
					</div>
					<div class="vc_col-md-8">
					<div class="headingAmbassadorContent">
							<h3>Training program for faculties on “Mentorship Skill Development on Innovation and Entrepreneurship”</h3>
						</div>
						<div class="headingAmbassadorContent">
							<p>Based on the performance of IICs in various IIC activities given in IIC activity calendar 2018-19, IIC representatives from best performing 50 Institutes/IICs were invited to attend two-day training session at All India Council for technical Education Head Quarter New Delhi on 9th and 10th September 2019.  Furthermore, top 25 IICs (Selected zone wise) were awarded by Former Education Minister Shri Ramesh Pokhriyal ‘Nishankh’ for their active participation in IIC program during November 2018 to June 2019.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cbp-row wh-content iicAmbassadorTrainingBox">
			<div class="cbp-container">
				<div class="row">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Innovation Ambassador Training Series</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>IIC Innovation Ambassador</h3>
						</div>
					</div>
					<div class="vc_col-md-8 bdrbottom">
						<div class="headingAmbassadorContent">
							<p>IICs at institutes are envisioned an instrumental role to drive innovation and startup ecosystem at campus while connecting with ecosystem enablers at regional and national level. For this, the role of faculty being a member of IIC is very important to play as a mentor or guide young minds in their pursuit of Innovation and entrepreneurship and equally crucial for mentors to have the adequate mentoring skills. Considering the fact that Indian HEIs are lacking in innovation and start-up mentor capacity to guide students on IPR, Innovation and Entrepreneurship, Institutions Innovation council of MoE’s Innovation Cell aims to build mentorship capacity of IIC members through a series of training programs and designate the trained IIC members as "IIC - Innovation Ambassador". IIC-MoE's Innovation Cell has taken a target to train more than 5000 faculties to develop mentoring skills on above four themes by end of 2019-2020 IIC calendar year (by June 2020).</p>
							<a routerLink="/iic-innovation-ambassador" class="dashReadMore">Read More</a>
						</div>
					</div>
					<div class="vc_col-md-4">
						<div class="headingAmbassadorContent">
							<img class="img-responsive" src="assets/assets/images/iic-ambassador-series.jpg">
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cbp-row wh-content iicAmbassadorTrainingBox">
			<div class="cbp-container">
				<div class="row">					
					<div class="vc_col-md-4">
						<div class="headingAmbassadorContent">
							<img class="img-responsive" src="assets/assets/images/impact.png">
						</div>
					</div>
					<div class="vc_col-md-8 bdrbottom">
						<div class="headingAmbassadorContentImpact">
							<h3>Impact Lecture Series</h3>
						</div>
						<div class="headingAmbassadorContent">
							<p>The objective of the scheme is to promote innovation, IPR and startup awareness among students and faculties in the IIC institute located in Rural/Himalayan/North Eastern region/Tier III Cities with less outreach to the innovation ecosystem enablers. This impact series will enable IICs to organise two lectures (on Different dates) in the field of innovation, IPR, and startup by inviting successful innovators, patent expert and entrepreneurs. MHRD’s Innovation will provide financial support to selected Institutions (IICs) to conduct these lectures. Institute will be asked submit a detailed proposal to MIC.</p>
							<a routerLink="/iic201920" class="dashReadMore">Read More</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cbp-row wh-content iicAmbassadorTrainingBox">
			<div class="cbp-container">
				<div class="row">
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>Online Session</h3>
						</div>
					</div>
					<div class="vc_col-md-8 bdrbottom">
						<div class="headingAmbassadorContent">
							<p>Due to the prevailing lockdown in the entire nation, MOE’s Innovation Cell has adapted the digital mode and processes to continue driving the innovation ecosystem in HEIs. In the quest to complete the IIC calendar activities for the IIC session 2019-2020, MIC has announced the 17 online sessions for faculties and students which were delivered by experts arranged by MOE’s Innovation Cell for the benefit of students and faculties. These seventeen e-sessions replaced the remaining physical activities of IIC calendar plan for the Quarter 3 & 4, which couldn’t be completed due to restricted movement in ongoing Covid- 19 pandemic.</p>
							<a routerLink="/iic-online-sessions" class="dashReadMore">Read More</a>
						</div>
					</div>
					<div class="vc_col-md-4">
						<div class="headingAmbassadorContent">
							<img class="img-responsive" src="assets/assets/images/online-seassion-Pagebg.jpg">
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cbp-row wh-content iicAmbassadorTrainingBox">
			<div class="cbp-container">
				<div class="row">					
					<div class="vc_col-md-4">
						<div class="headingAmbassadorContent">
							<img class="img-responsive" src="assets/assets/images/national-innovation-contest-dash-pic.jpg">
						</div>
					</div>
					<div class="vc_col-md-8 bdrbottom">
						<div class="headingAmbassadorContent">
							<h3>National Innovation Contest</h3>
						</div>
						<div class="headingAmbassadorContent">
							<p>The IIC National Innovation Contest 2020 was announced during the month of February 2020. The 2nd edition of IIC-National Innovation Contest (2020) is currently under progress for the students from IIC-Institutes to take part in this 360-degree innovative and experiential learning process.</p>
							<a routerLink="/innovation-conest2020" class="dashReadMore">Read More</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</section>