import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.css']
})
export class ResponseResetComponent implements OnInit {

  public error = [];

  public form = {
    email: null,
    password: null,
    confirm_password: null,
    token: null,
  };

  constructor(private route: ActivatedRoute,
    private jarwis: JarwisService,
    private router: Router,
    private toastr: ToastrService) {
    route.queryParams.subscribe(params => {
      this.form.token = params['token']
    });
  }

  onSubmit() {
    this.jarwis.changePassword(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    if (data['message'] == "Error") {
      this.toastr.error(data['reason']);
    } else {
      this.toastr.success("Password changed sucessfully !");
      this.router.navigateByUrl('/login');
    }

  }

  handleError(error) {
    if (error.error.errors.email != null)
      //this.showError(error.error.errors.email);

      if (error.error.errors.password != null) {
        //this.showError(error.error.errors.password);
      }
  }

  ngOnInit() {
  }

}
