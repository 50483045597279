<!-- IIC Website Header -->
<!-- <app-iic-web-main-navbar></app-iic-web-main-navbar> -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">Innovation Contest 2020</h1>
		</div>
	</div>
</div> -->

<!-- <nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>IIC Innovation Contest</a></li>
    <li class="breadcrumb-item active" aria-current="page">Innovation Contest 2020</li>
  </ul>
</nav> -->

<div id="carouselExampleControlsMobile" class="carousel slide bannerSlideInnovation" data-ride="carousel">
  <div class="carousel-inner">
	<div class="carousel-item active">
	  <img class="d-block w-100" src="assets/assets/images/mentor-mentee-program-banner.jpg" alt="First slide">
	  <div class="carousel-caption innovationContest2020Content">
		<h2>Mentor-Mentee <br>Programme <br>for IIC Institutions</h2>
	  </div>
	</div>
  </div>

  <!-- <a class="carousel-control-prev landingSliderBannerPrevMob" href="#carouselExampleControlsMobile" role="button" data-slide="prev">
	<i class="fa fa-arrow-left" aria-hidden="true"></i>
	<span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next landingSliderBannerNextMob" href="#carouselExampleControlsMobile" role="button" data-slide="next">
	<i class="fa fa-arrow-right" aria-hidden="true"></i>
	<span class="sr-only">Next</span>
  </a> -->
</div>



<div class="pageNavigationBar">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<button class="navbar-toggler mentorNavRes" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
					  <span class="navbar-toggler-icon"></span>
					</button>

					<div class="collapse navbar-collapse" id="navbarColor01">
					  <ul class="navbar-nav mr-auto mentorPageNav">
						<li class="nav-item active">
						  <a class="nav-link" href="#objectiveBox" data-scroll-nav="1"><mat-icon>check_circle</mat-icon> Objective</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#outcomesBox"><mat-icon>outbound</mat-icon> Expected Outcomes</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#mm21"><mat-icon>payments</mat-icon> M-M 2021-22</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#timeline"><mat-icon>front_hand</mat-icon>Time Line</a>
						</li>
						<!-- <li class="nav-item">
						  <a class="nav-link" href="#applyBox"><mat-icon>approval</mat-icon> How and When to Apply</a>
						</li> -->
						<!-- <li class="nav-item">
						  <a class="nav-link" href="#eligibilityBox"><mat-icon>admin_panel_settings</mat-icon> Eligibility</a>
						</li> -->
						<li class="nav-item">
						  <a class="nav-link" href="#activitiesBox"><mat-icon>local_activity</mat-icon> Mentoring Activities</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="{{ imageBasePath }}assets/announcements/Mentor-Mentee-2023-scheme-document.pdf" target="_blank"><mat-icon>batch_prediction</mat-icon> Scheme Document</a>
						</li>
						<li class="nav-item">
						  <!-- <img src="assets/assets/images/new-icon-gif-13.jpg" class="newGifIcon"> -->
						  <a class="nav-link" href="#resultDeclare"><mat-icon>content_paste_go</mat-icon> Downloads</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#termBox"><mat-icon>gavel</mat-icon> Terms and Conditions</a>
						</li>
						<!-- <li class="nav-item">
						  <a class="nav-link" href="#contactBox"><mat-icon>contact_phone</mat-icon> Contact Us</a>
						</li> -->
					  </ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</div>



<section class="mentorObjective" id="objectiveBox" data-scroll-index="1">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Objective</h2>
				</div>
			</div>
			<div class="col-md-12">
				<div class="objContentPara">
					<p>The objective of the Mentor-Mentee scheme for IIC institutions is to engage high-performing IIC institutions, especially those that have secured a star rating of 3.5 and above during the previous IIC calendar year, to act as mentors for IIC institutions in need of guidance and support. This twinning program aims to facilitate knowledge exchange and resource mobilization between institutions through inter-institutional collaborations. The scheme is designed to pair one mentor IIC institute with up to five mentee IIC institutes.</p>
					<p class="objContentParaColor">Under this scheme, approximately 100 high-performing IIC institutions will act as mentors to 500 IIC institutions, maintaining an average ratio of 1:5. Each eligible mentor institute will receive funding assistance of Rs. 2,25,000/- from MIC/AICTE to organize the prescribed activities for the mentee institutions.</p>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- <section class="totalNumberMentor">
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<div class="totalNumberBox">
					<div class="row">
						<div class="col-md-4">
							<div class="totalNumberLeftSide">
								<img src="assets/assets/images/iaTrainingScheme2021.png">
							</div>
						</div>
						<div class="col-md-8">
							<div class="totalNumberRightSide">
								<h3>Total Number of Mentor</h3>
								<h2>56</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="totalNumberBox">
					<div class="row">
						<div class="col-md-4">
							<div class="totalNumberLeftSide">
								<img src="assets/assets/images/iaTrainingScheme2021.png">
							</div>
						</div>
						<div class="col-md-8">
							<div class="totalNumberRightSide">
								<h3>Total Number of Mentee</h3>
								<h2>152</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->

<section class="dashboardBox" id="mm21">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMainDashboard">
					<h2 class="hdngBrkrDivDashboard">Mentor-Mentee Scheme 2023-24</h2>
				</div>
			</div>
		</div>

		<div class="row dashTop">
            <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount2223?.total_ins}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p class="instiApplied">Total no. of Mentor Institutions <br> <br> </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="nat-wid-icon">
                                <img src="assets/assets/images/icons/nodal-center-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount2223?.total_mic_funded}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentor Institutions(With Financial Assisatance from MIC/AICTE)</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="idea-icon1">
                                <img src="assets/assets/images/icons/ideas.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount2223?.Volunteer}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentor Institutions(Without Financial Assisatance from MIC/AICTE)</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="idea-icon">
                                <img src="assets/assets/images/icons/institutation-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<div class="row response-gap">
			<div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount2223?.total_volunteer}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentor Institutions given consent (Without Financial Assisatance from MIC/AICTE)</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="evelau">
                                <img src="assets/assets/images/icons/mentor-without-funding-support.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount2223?.Mentee}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>Total No. Mentee Institutions</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="evelau evelau_1">
                                <img src="assets/assets/images/icons/evelaturos.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row">
                        <div class="col-md-8 ext-pad2 ext-pad22017">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount2223?.Schedule}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentoring Activities Scheduled/Organised</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="stu-sih-ico">
                                <img src="assets/assets/images/icons/stu-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</section>

<section class="expectedOutcome" id="outcomesBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Expected Outcomes</h2>
				</div>
			</div>
			<div class="col-md-5">
				<div class="exOutImg">
					<img src="assets/assets/images/mentorMenteeExpectedOutcome.png">
				</div>
			</div>
			<div class="col-md-7">
				<div class="expectList">
					<ul>
						<li>Active participation and involvement of students, faculties and staff and most importantly members of the IIC institute in the program.</li>
						<li>Awareness generation among students, faculties and staff on recent developments and approaches in for innovation, entrepreneurship and intellectual property and their importance in wealth creation at individual level and national level.</li>
						<li>Nurturing leadership potential of student innovators, and entrepreneurs by providing them coordination role to achieve the program objective.</li>
						<li>Program will set foundation for institute’s participation in MoE’s Innovation and Entrepreneurship initiatives such as IIC, Hackathons, NISP, YUKTI Innovation Repository and YUKTI Innovation Challenge related programs at MIC and AICTE.</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- <section class="expectedOutcome1" id="applyBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">How and When to Apply</h2>
				</div>
			</div>
			<div class="col-md-7">
				<div class="expectList">
					<ul>
						<li>Applications are invited in the month of July 2023. </li>
            <li>Last date to apply 20 th July 2023.</li>
            <li>Institute must apply through its IIC institute login ID at <a href="https://iic.mic.gov.in/login" target="_blank">https://iic.mic.gov.in/login</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-5">
				<div class="exOutImg">
					<img src="assets/assets/images/howToApply.png">
				</div>
			</div>
		</div>
	</div>
</section> -->

<!-- NEW CARD FOR 2020-21 -->
<section class="dashboardBox" id="mm21">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMainDashboard">
					<h2 class="hdngBrkrDivDashboard">Mentor-Mentee Scheme 2021-22</h2>
				</div>
			</div>
		</div>

		<div class="row dashTop">
            <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount?.total_ins}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p class="instiApplied">Total no. of Mentor Institutions <br><br></p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="nat-wid-icon">
                                <img src="assets/assets/images/icons/nodal-center-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount?.total_mic_funded}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentor Institutions(With Financial Assisatance from MIC/AICTE)</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="idea-icon1">
                                <img src="assets/assets/images/icons/ideas.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount?.Volunteer}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentor Institutions(Without Financial Assisatance from MIC/AICTE)</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="idea-icon">
                                <img src="assets/assets/images/icons/institutation-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<div class="row response-gap">
			<div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount?.total_volunteer}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentor Institutions given consent (Without Financial Assisatance from MIC/AICTE)</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="evelau">
                                <img src="assets/assets/images/icons/mentor-without-funding-support.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount?.Mentee}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>Total No. Mentee Institutions</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="evelau evelau_1">
                                <img src="assets/assets/images/icons/evelaturos.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row">
                        <div class="col-md-8 ext-pad2 ext-pad22017">
                            <div class="nat-wid-nodel">
                                <h3>{{MMCount?.Schedule}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentoring Activities Scheduled/Organised</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="stu-sih-ico">
                                <img src="assets/assets/images/icons/stu-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</section>
<!-- NEW CARD FOR 2020-21 -->

<section class="expectedOutcome" id="eligibilityBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Eligibility</h2>
				</div>
			</div>
			<div class="col-md-5">
				<div class="exOutImg1">
					<img src="assets/assets/images/eligibility.png">
				</div>
			</div>
			<div class="col-md-7">
				<div class="outcomeHeadingPara">
					<p>Institute should have approved Institution’s Innovation Council as per the norms of Innovation Cell, Ministry of Education, Govt. of India. The following IIC Institutions are eligible to apply.</p>
				</div>
			</div>
			<div class="col-md-6">
				<div class="expectListEligibility">
					<h3>a. Selection of Mentor IIC Institution</h3>
					<ul>
						<li>The IIC Institution must have secured 3.5 stars and above during the previous IIC Calendar year 2021-22.</li>
						<li>The IIC Institution must have completed MY COUNIL for the current IIC Calendar year.</li>
						<li>The IIC Institution must have submitted minimum 6 activity reports for the current IIC Calendar year 2022-23.</li>
						<li>The IIC Institution must have pre incubation/ incubation facilities in their campus.</li>
						<li>Preference will be given to institutions have participated in Innovation Ambassador Training Program organized by MIC, and participation in MoE’s initiatives such as NIRF Ranking on Innovation Achievements, adoption of NISP, KAPILA etc.</li>
					</ul>
				</div>
			</div>
			<div class="col-md-6">
				<div class="expectListEligibility">
					<h3>b. Selection of Mentee IIC Institution</h3>
					<ul>
						<li>IIC institutes that have secured 3 stars or less during IIC Star Ratings during IIC Calendar Year 2021-22</li>
						<li>Newly joined IIC institutions during IIC calendar year 2022-23</li>
						<li class="eliBoldList">The institutions fulfilling the one of the above-mentioned conditions have to meet following mandatory requirements during current IIC Calendar year 2022-23: -</li>
					</ul>
					<p><i class="fa fa-caret-right" aria-hidden="true"></i> Updated My council</p>
					<p><i class="fa fa-caret-right" aria-hidden="true"></i> Upload of quarterly meeting report</p>
					<p><i class="fa fa-caret-right" aria-hidden="true"></i> Submitted at least 1 approved activity report</p>
				</div>
			</div>
			<div class="col-md-12">
				<div class="eliBottomPara">
					<p>Final Selection will be based on the recommendation by the Evaluation
						Committee and the availability of the fund as per approved budget.</p>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="expectedOutcome1" id="timeline">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Timeline of Implementation of the Mentor-Mentee Scheme</h2>
				</div>
			</div>
			<div class="col-md-12">
				<table>
					<thead>
						<tr>
							<th>S.No.</th>
							<th>Activity</th>
							<th>Timeline</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td>Consent &amp; Nomination of Coordinator</td>
							<td>7th - 20th July</td>
						</tr>
						<tr>
							<td>2</td>
							<td>Selection of Mentor Institute</td>
							<td>last week of August 2023</td>
						</tr>
						<tr>
							<td>3</td>
							<td>Mapping of Mentor-Mentee Institutions</td>
							<td>September 2023</td>
						</tr>
						<tr>
							<td>4</td>
							<td>Announcement of  Mentor-Mentee Institutions</td>
							<td>September 2023</td>
						</tr>
						<tr>
							<td>5</td>
							<td>Implementation of Mentor-Mentee Activities</td>
							<td>October 2023  - June 2024</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</section>
<section class="activitiesBoxMain" id="activitiesBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain1">
					<h2 class="hdngBrkrDiv1">Mentoring Activities </h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="activitiContentPara">
					<p>Under the Mentor-Mentee scheme, each mentor IIC institute shall perform following suggestive activities for their mentee institutions</p>
				</div>
			</div>
			<div class="col-md-6">
				<div class="manteeContentMain">
					<p><span>1.</span>	Mentor institute to conduct an orientation cum vision building/mentoring sessions for all the key functionaries of IIC members of mentee institutions: The key functionaries of IIC at the mentor institute will conduct orientation sessions for all the key functionaries of IIC members at mentee institutions. One orientation session should be organised at the beginning of the Semester/IIC Calendar year and one at the mid of IIC calendar year.</p>
				</div>
				<div class="manteeContentMain">
					<p><span>2.</span> Mentor institute to take part in the quarterly progress meeting of mentee IIC institutions and provide guidance on planning, action plan preparation and improvisation of I&E activities to be conducted in the mentee institutions: At least one key functionary of IIC from the mentor institute will join the IIC of mentee institute as external expert member. He/she will take part in the quarterly progress meetings of mentee institution and provide guidance and support in planning, action plan preparation, and improvisation of I&E activities to be conducted in the mentee institutions. One person from mentor institute can take above role in maximum two mentee institutions and take part in at least 3 quarterly meetings of a mentee institute.</p>
				</div>
			</div>
			<div class="col-md-6">
				<div class="manteeContentMain">
					<p><span>3.</span> The mentor institution will plan, identify external experts and deliver two IIC calendar activities planned by the mentee institute: Mentor institute to handhold mentee institute to organise at least two activities listed in the IIC calendar activity plan in each mentee IIC institute. Mentor institute will identify and arrange the resource persons/external experts to deliver the sessions.</p>
				</div>
				<div class="manteeContentMain">
					<p><span>4.</span> The mentor institute will organize a 2 days I&E exposure visit cum training program on I&E related theme such as pre-incubation and Incubation facility creation, IPR filing & management etc. for mentee institute representatives at the mentor institute’s campus for all Mentee Institute representatives.</p>
				</div>
				<div class="manteeContentMain">
					<p><span>5.</span> A key functionary of mentor IIC institute will conduct a visit to mentee institute for progress monitoring cum feedback session towards the end of the IIC calendar year.</p>
				</div>
			</div>
			<div class="col-md-12">
				<p class="manteeBottomTextItalic">*The activities 1, 2, 3, 5 may be organised either on online mode or physical mode as per the convenient to both mentor and mentee institutions.</p>
			</div>
		</div>
	</div>
</section>

<section class="mentorSchemeBox" id="mentorScheme">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Delivering activities of Mentor-Mentee Scheme</h2>
				</div>
			</div>
			<div class="col-md-5">
				<div class="exOutImg">
					<img src="assets/assets/images/mentorMenteeScheme.png">
				</div>
			</div>
			<div class="col-md-7">
				<div class="expectList">
					<ul>
						<li>The Mentor Institute Coordinator shall be primarily responsible for the implementation of the project.</li>
						<li>The Mentor Institute Coordinator shall be an IIC President or IIC convener of the Mentor institute.</li>
						<li>The Mentee institutions are also required to nominate coordinator for their institute from their Teaching/Non-Teaching faculty members (one from each mentee institutions) as the co-coordinators of the project</li>
						<li>Quarterly presentation on the outcome of the scheme should be submitted in IIC Portal.</li>
						<li>If it is found that the grant-in-aid released is not being utilized for the purposes for which it was intended for and the progress of the programme is not satisfactory, action may be taken to seeker fund of grant released.</li>
						<li>Further extension of financial support from AICTE, MIC shall be based on the progress of the scheme and effective utilization of the earlier grant-in-aid provided.</li>
						<li>If the Mentor Institute Coordinator leaves the institution, retires, or goes on long leave, the institute will appoint another Mentor Institute Coordinator to the programme, under intimation to the MIC immediately.</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="termsConditionBoxMain" id="termBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Terms and Conditions</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<h3 class="termsHeadingBottom">Utilization/Refund/Maintenance of Released Fund </h3>
			</div>
			<div class="col-md-12">
				<div class="expectListTerm">
					<ul>
						<li>The mentor IIC institute shall maintain proper accounts of the expenditure out of the grants, which shall be utilized only on the scheme.</li>
						<li>AICTE/MIC or its nominee shall have the right to check/verify the account to satisfy that the fund has been utilized for the purpose for it was sanctioned.</li>
						<li>The Principal / Director / Registrar shall intimate about the receipt of the grant to AICTE/MIC.</li>
						<li>The released/sanctioned fund for Mentor-Mentee Scheme cannot be utilized for any other programme/ sessions.</li>
					</ul>
					<p><i class="fa fa-caret-right" aria-hidden="true"></i> In case the Mentor-Mentee Scheme is cancelled, the funds must be returned back to AICTE/MIC immediately with interest accrued thereon.</p>
					<p><i class="fa fa-caret-right" aria-hidden="true"></i> In any case, if the institute is required to refund the grant or interest accrued thereon or balance amount, the amount will be refunded to AICTE/MIC. (by way of a demand draft in favor of INNOVATION CELL ACCOUNT payable at New Delhi or through NEFT/RTGS at INNOVATION CELL ACCOUNT, Account No:37903899633, IFSC: SBIN0050203, SBI, SHASTRI BHAWAN, RAJENDRA PRASAD ROAD, NEW DELHI 11000).</p>
					<p><i class="fa fa-caret-right" aria-hidden="true"></i> As MIC needs adequate time for depositing the Demand Draft in the bank, the same be immediately dispatched to avoid any lapse of the validity period.</p>
				</div>
				<div class="expectListTerm">
					<ul>
						<li>The Institute shall strictly follow the provisions laid down in the scheme document and sanction order No. ……………………. dated ………………. issued by this office. All correspondences related to the scheme must contain this number along with year of sanction of the scheme; failing which correspondence will not be entertained.</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="resultDeclare" id="resultDeclare">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Downloads</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="resultDeclareBox">	
				<p><a href="{{ imageBasePath }}assets/announcements/Mentor-Mentee-2023-scheme-document.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i> Scheme Document for Mentor-Mentee 2023</a></p>			
				<p><a href="{{ imageBasePath }}assets/announcements/Report-Format-Mentor-Mentee.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i> Report Format for the activities under Mentor-Mentee Scheme</a></p>
				<p><a href="{{ imageBasePath }}assets/announcements/soe-mentor-mentee.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i> Format of Statement of Expenditure for Mentor-Mentee Scheme</a></p>
				<p><a href="{{ imageBasePath }}assets/announcements/Utilization-Certificate-Mentor-Mentee.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i> Utilization Certificate for Mentor-Mentee Scheme</a></p>					
				<p><a href="{{ imageBasePath }}assets/announcements/Mentor-Mentee-Scheme.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i> Scheme Document for Mentor-Mentee 2021-22</a></p>					
				<p><a href="/mentor-mentee-2021" target="_blank"><i aria-hidden="true" class="fa fa-download"></i>  Mentor-Mentee Phase I</a></p>					
				<p><a href="/volunteer-mentor-mentee-2021" target="_blank"><i aria-hidden="true" class="fa fa-download"></i>  Mentor-Mentee Phase II</a></p>					

			</div>
		</div>
	</div>
</section>

<section class="contactBoxMain" id="contactBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">For more information</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="contactBoxContent">
					<p class="contactEmail"><a routerLink="/iic-implementation-team">Contact Us</a></p>
					<!-- <p class="contactPhone">Phone No: 011 2958 1223/1239/1240/1235/1513/1226</p> -->
					<p class="contactPhone">Phone No: 011 2958 1513/1235/1517/1240/1226</p>
					<p class="contactPhone">Email Address: mm.iic.mic@aicte-india.org, selva.rani@aicte-india.org</p>
				</div>
			</div>
		</div>
	</div>
</section>

<a href="#" id="scroll" style="display: none;"><span></span></a>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
