<app-iic-web-navbar></app-iic-web-navbar>
<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Institute Council List</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>


<nav aria-label="breadcrumb">
	<ul class="breadcrumb iicWebBreadcrumb">
		<li class="breadcrumb-item"><a routerLink="/">Home</a></li>
		<!-- <li class="breadcrumb-item"><a>Resources</a></li> -->
		<li class="breadcrumb-item active" aria-current="page">Institute Council Detail</li>
	</ul>
</nav>

<section class="iaAmbassadorProfileBox">
	<div class="container">
	<div class="row" color="primary">
                <h2 class="councilTitle" style="text-align: center"> Institute Detail </h2>
            </div>
        <mat-card class="mat-elevation-z6 hoiInstiDetials">
            
        <div>
                <div class="row m-4">
                    <div class="col-md-12">
                        <div class="row btmLine">
                            <div class="col-md-4">
                                <p class="anaTitle">IIC ID</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.iicId}}</p>
                            </div>
                        </div>
                        <div class="row btmLine">
                            <div class="col-md-4">
                                <p class="anaTitle">Institute Name</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.instituteName}}</p>
                            </div>
                        </div>
                        <div class="row btmLine">
							<div class="col-md-4" style="padding-left: 0;">
								<div class="col-md-4">
                                <p class="anaTitle">City</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.instituteCity}}</p>
                            </div>
							</div>
							<div class="col-md-4">
								<div class="col-md-4">
                                <p class="anaTitle">State</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.instituteState}}</p>
                            </div>
							</div>
							<div class="col-md-4">
								<div class="col-md-4">
									<p class="anaTitle">Zone</p>
								</div>
								<div class="col-md-8">
									<p class="anaContent">{{this.instituteZone}}</p>
								</div>
							</div>
                            
                        </div>
                        <!--<div class="row btmLine">
                            <div class="col-md-4">
                                <p class="anaTitle">State</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.instituteState}}</p>
                            </div>
                        </div> -->
                        <!-- <div class="row btmLine">
                            <div class="col-md-4">
                                <p class="anaTitle">Zone</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.instituteZone}}</p>
                            </div>
                        </div> -->
                    </div>
                </div>
        </div>
    </mat-card>
	
	<div class="row" color="primary">
            <h2 class="councilTitle" style="text-align: center"> IIC President </h2>
        </div>
    <mat-card class="mat-elevation-z6 hoiInstiDetials">
        
        <div>
                <div class="row m-4" >
                    <div class="col-md-12">
                        <div class="row btmLine">
                            <div class="col-md-4">
                                <p class="anaTitle">Name</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.name}}</p>
                            </div>
                        </div>
                        <div class="row btmLine">
                            <div class="col-md-4">
                                <p class="anaTitle">Designation</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.designation}}</p>
                            </div>
                        </div>
                        <!-- <div class="row btmLine">
                            <div class="col-md-4">
                                <p class="anaTitle">Department</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{nomination.department}}</p>
                            </div>
                        </div> -->
                        <div class="row btmLine">
                            <div class="col-md-4">
                                <p class="anaTitle">Email</p>
                            </div>
                            <div class="col-md-8">
                                <p class="anaContent">{{this.email}}</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
     </mat-card>

     <div class="row" color="primary">
        <h2 class="councilTitle" style="text-align: center"> IIC Convener </h2>
    </div>
<mat-card class="mat-elevation-z6 hoiInstiDetials">
    
    <div>
            <div class="row m-4" >
                <div class="col-md-12">
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Name</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.convener_name}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Designation</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.convener_designation}}</p>
                        </div>
                    </div>
                    <!-- <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Department</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{nomination.department}}</p>
                        </div>
                    </div> -->
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Email</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.convener_email}}</p>
                        </div>
                    </div>
                </div>
            </div>
    </div>
 </mat-card>
	 
	<div class="row" color="primary">
            <h2 class="councilTitle" style="text-align: center"> List of Council Members </h2>
        </div>
    <mat-card class="mat-elevation-z6 hoiInstiDetials">
        

        <div>
                <div class="">
                    <table class="table table-hover">
                        <thead class="facultyTable">
                            <tr>
                                <th scope="col"> S.No.</th>
                                <th scope="col"> Name</th>
                                <th scope="col"> Role</th>
                                <th scope="col"> Designation</th>
                                <th scope="col"> Department</th>
                                <!-- <th scope="col"> Email Id</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let details of theme; let i = index;">
                                <td>{{i+1}}</td>
                                <td>{{details?.name}}</td>
                                <td>{{details?.roles}}</td>
                                <td>{{details?.designation}}</td>
                                <td>{{details?.deaprtment}}</td>
                               <!-- <td>{{details?.email}}</td> -->
                            </tr>
                            <!-- <tr *ngIf="nomination2.length <= 0" col="4">
                                <h3 style="text-align: center">No Data Found</h3>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
        </div>
    </mat-card>
</div>
</section>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>