<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;">
            Details of e-Learning Sessions Organized</h2>
    </div>

	<div class="card">
        <!-- <div class="card-title"><p>Details of e-Learning Sessions Organized.</p></div> -->
	</div>
<div class="refrredBox">
 <div class="row rws-listpage">
	<div class="col-md-12">
  <p *ngIf="ForWhom=='Institute'"><a class="btn btn-primary referralBut" routerLink='/institute/repository-add/add'>Add New</a></p>
  <div class="tableBody">
				<div class="table-responsive">
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Title</th>
            <th scope="col">Theme</th>
            <th scope="col">Organised as</th>
            <th scope="col">Tags</th>
            <th scope="col">Video URL</th>
            <th scope="col">Expert Details</th>
            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let nomination of iicrepository; index as i" >
            <td>{{i+1}}</td>
            <td>{{nomination?.title}}</td>
            <td>{{nomination?.theme}}</td>
            <td>
              <span *ngIf="nomination?.iic_activity=='IIC'">{{nomination?.iic_activity}} Calendar Activity</span>
              <span *ngIf="nomination?.iic_activity!='IIC'">{{nomination?.iic_activity}} Driven Activity</span>
            </td>
            <td>{{nomination?.tags}}</td>
            <td>{{nomination?.video}}</td>
            <td>{{nomination?.expert_name}}
              <br/>{{nomination?.expert_email}}
              <br/>{{nomination?.expert_contact}}
              <br/>{{nomination?.expert_designation}}
              <br/>{{nomination?.expert_org}}
            </td>
            <td *ngIf="nomination?.verify_status!=1" class="color: #004085;
            background-color: #cce5ff; border-color: #b8daff;">

            <p *ngIf="nomination?.verify_status==0">Pending</p>
            <p *ngIf="nomination?.verify_status==3">Re-open</p>
            <p *ngIf="nomination?.remark!=''">Remarks: {{nomination?.remark}}</p>
            <p *ngIf="nomination?.verify_status==2" style="font-weight: bold; color:#FF0000;">Disapproved</p>

            <p *ngIf="nomination?.verify_status!=2 && ForWhom == 'Institute'"><button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn" class="btn-tbl-edit">
                <mat-icon>edit</mat-icon>
                </button></p>

            <p *ngIf="nomination?.verify_status!=2 && ForWhom == 'Institute'"><button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')"   color="warn" class="btn-tbl-delete">
                  <mat-icon>delete</mat-icon>
                  </button></p>



            </td>

            <td *ngIf="nomination?.verify_status==1" style="color: #155724;
            background-color: #d4edda; border-color: #c3e6cb;">

              Approved

            </td>
        </tr>
        <tr *ngIf="iicrepository.length== 0" col="7">
            <h3 style="text-align: center">No Data Found</h3>
        </tr>
    </tbody>
</table>
</div>
</div>



 </div>
</div>
</div>
</div>
