<mat-tab-group mat-align-tabs="center">
  <mat-tab label="IIC Annual Performance Report 2023-24">
    <app-academic-year-performance202324></app-academic-year-performance202324>
  </mat-tab>
  <mat-tab label="IIC Annual Performance Report 2022-23">
    <app-academic-year-performance202223></app-academic-year-performance202223>
  </mat-tab>
  <mat-tab label="IIC Annual Performance Report 2021-22">
    <app-academic-year-performance-2021-22></app-academic-year-performance-2021-22>
    <!-- <p>IIC Annual Performance Report 2021-22 will be available soon!</p> -->
  </mat-tab>
  <mat-tab label="IIC Annual Performance Report 2020-21">
      <app-current-year-performance></app-current-year-performance>
  </mat-tab>
  <mat-tab label="IIC Annual Performance Report 2019-20">
      <app-previous-year-performance></app-previous-year-performance>
  </mat-tab>
</mat-tab-group>

