import { Component, OnInit } from '@angular/core';
import { OnlinePagesInnerService } from 'src/app/Services/onlineSession/online-pages-inner.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-online-session-details',
  templateUrl: './online-session-details.component.html',
  styleUrls: ['./online-session-details.component.css']
})
export class OnlineSessionDetailsComponent implements OnInit {
  list: any;
  // page: any;
  showButton: number=0;

  constructor(private onlinePagesInnerService: OnlinePagesInnerService,private toastr: ToastrService) {
    this.list = [];
  }

  ngOnInit(): void {

    this.onlinePagesInnerService.getRemSessionList().subscribe(
      data => {
        this.list = data['data']
      }
    );
    this.sessionVideoCount();
  }

sessionVideoCount(){
  this.onlinePagesInnerService.videoCount().subscribe(
    data => {this.handleResponse(data)   }
  )
}
handleResponse(data){
	
  if(((data['completedAssessmentCount']['completedAssessmentCount'])>9)&&((data['completedSessionCount']['completedSessionCount'])>9)){
   this.showButton=1;
  }
}
  public assessmentFormUpdate(id,assessment_form_status){
	  this.onlinePagesInnerService.assessmentFormStatus(id,assessment_form_status).subscribe(
      data => {
        this.handle(data)
      })
  }
  handle(data){
	  if(data['status']==1){
  this.toastr.success(data['msg']);
  this.onlinePagesInnerService.getRemSessionList().subscribe(
      data => {
        this.list = data['data']
      }
    );
	  }else{
		  this.toastr.success(data['msg']);
	  }

}

}
