<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>


<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Advisory Committee</h1>	-->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Advisory Committee</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>About Us</a></li>
    <li class="breadcrumb-item active" aria-current="page">Advisory Committee</li>
  </ul>
</nav>

<!-- content start -->
<div class="cbp-row wh-content advisoryCommittee">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>IIC Advisory Committee</h2>
				</div>
			</div>
		</div>
	</div>
</div>

<section class="adCommiteeBox">
	<div class="container">
		<div class="row">
			<div class="col-md-4 offset-md-4">
				<div class="advisoryCommiteeChairmanCard">
					<!-- <h3>Prof. Anil D. Sahasrabudhe</h3> -->
					<!-- <h3>Prof. M. Jagadesh Kumar</h3> -->
					<h3>Prof. T. G. Sitharam</h3>
					<p>Chairman, AICTE</p>
				</div>
			</div>
		</div>
		<div class="row adRowMar">
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Dr. M.P Poonia</h3>
					<h4>Vice Chairman ,AICTE</h4>
					<p>Member</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Prof. Rajnish Jain</h3>
					<h4>Secretary, UGC</h4>
					<p>Member</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Mr. Anand ADC</h3>
					<h4>Ministry of MSME</h4>
					<p>Member</p>
				</div>
			</div>
		</div>
		
		<div class="row adRowMar">
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Shri. Deepanyar Mallick</h3>
					<h4>DDG, Ministry of MSDE</h4>
					<p>Member</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Shri. R Ramanan</h3>
					<h4>Mission Director, AIM, NITI Aayog</h4>
					<p>Member</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Dr. Anita Gupta</h3>
					<h4>Adviser & Associate Head, NSTEDB, DST, MoST</h4>
					<p>Member</p>
				</div>
			</div>
		</div>
		
		<div class="row adRowMar">
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Dr. Manish Diwan</h3>
					<h4>Head – Strategic Partnership & Entrepreneurship, BIRAC</h4>
					<p>Member</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Dr. H Purushotham</h3>
					<h4>Chairman cum Managing Director, NRDC</h4>
					<p>Member</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Shri Anil Agrawal</h3>
					<h4>Joint Secretary, Startup India, DPIIT, MoC, GoI</h4>
					<p>Member</p>
				</div>
			</div>
		</div>
		
		<div class="row adRowMar">
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Dr. Yashwant Dev Panwar</h3>
					<h4>TIFAC</h4>
					<p>Member</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Dr. Abhay Jere</h3>
					<h4>CIO, MHRD’s Innovation Cell</h4>
					<p>Member</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="advisoryCommiteeMemberCard">
					<h3>Mr. Dipan Sahu</h3>
					<h4>National Coordinator of IIC, MHRD’s Innovation Cell</h4>
					<p>Member</p>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- <div id="content" class="content container">
 
<table align="center"  style="margin-left:auto;margin-right:auto;" class="table-hover">
	<tr>
		<td>
			<b>Prof. Anil D. Sahasrabudhe</b>
			<br> AICTE
		</td>
		<td>Chairman</td>
	</tr>
	<tr>
		<td>
			<b>Dr. M.P Poonia</b>
			<br>Vice Chairman ,AICTE
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Prof, Rajnish Jain</b>
			<br>Secretary, UGC 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Mr Anand ADC </b>
			<br> Ministry of MSME 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Shri Deepanyar Mallick </b>
			<br> DDG, Ministry of MSDE 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Shri R Ramanan </b>
			<br> Mission Director, AIM, NITI Aayog 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Dr. Anita Gupta</b>
			<br>Adviser &amp; Associate Head, NSTEDB, DST, MoST 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Dr. Manish Diwan</b>
			<br>Head – Strategic Partnership &amp; Entrepreneurship, BIRAC 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Dr. H Purushotham</b>
			<br>Chairman cum Managing Director, NRDC 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Shri Anil Agrawal</b>
			<br>Joint Secretary, Startup India, DPIIT, MoC, GoI 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Dr. Yashwant Dev Panwar</b>
			<br>TIFAC 
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Dr. Abhay Jere</b>
			<br>CIO, MHRD’s Innovation Cell
		</td>
		<td>Member</td>
	</tr>
	<tr>
		<td>
			<b>Mr. Dipan Sahu</b>
			<br>National Coordinator of IIC, MHRD’s Innovation Cell
		</td>
		<td>Convener</td>
	</tr>
</table>

  </div> -->

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>