import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
@Component({
  selector: 'app-nominated-atl-coordinator',
  templateUrl: './nominated-atl-coordinator.component.html',
  styleUrls: ['./nominated-atl-coordinator.component.css']
})
export class NominatedAtlCoordinatorComponent implements OnInit {

  coordinatorDetail:any;

  constructor(
    private activityService: InstituteActivityService,

  ) { }

  ngOnInit(): void {

    this.activityService.atlNominatedCoordinateDetails().subscribe(
      (data) => this.handleResponse(data)
    );

    this.coordinatorDetail = "";


  }

  handleResponse(data)
  {
    this.coordinatorDetail = data;
  }



}
