import { Injectable } from '@angular/core';
import * as myGlobals from '../../app.component';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmbassadorDashboardService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  getDashboardDetails() {
    return this.http.get(`${this.baseUrl}/iicDashboardIA`);
  }
}
