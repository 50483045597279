export const environment = {
  production: false,
  baseUrl : 'https://qaiicapi.mic.gov.in/api',
// export const baseUrl1 = 'https://iicapi.mic.gov.in',
  baseUrl1 : 'https://miciicsta01.blob.core.windows.net/miciiccontainer1',

  baseUrlOSIA : 'https://qaiicapi.mic.gov.in/api/osia',
  baseImageUrl : 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads/institutes',
  baseImageUrl1 : 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads',
  baseImageUrl2 : 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/',
  baseImageUrlIA : 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads/ia',

  manthanUrl : 'https://manthan-uat-api.ideabridge.co',
  manthanHost : 'manthan-uat.ideabridge.co',
  manthanOrigin : 'https://manthan-uat.ideabridge.co',
  manthanSubdomain : 'manthan-uat.ideabridge.co',

   imageBasePath : 'https://iic.mic.gov.in/uploads/',
  // manthanUrl : 'https://kafka.manthan.gov.in',
  // manthanHost : 'kafka.manthan.gov.in',
  // manthanOrigin : 'https://manthan.gov.in',
  // manthanSubdomain : 'manthan.gov.in',
};
