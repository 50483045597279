import { Component, OnInit } from '@angular/core';
import { InstituteDashboard1819Service } from 'src/app/Services/institute-dashboard1819.service';
import * as myGlobals from '../../../../../app.component';
import { ChartType, ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-dashboard1819-charts',
  templateUrl: './dashboard1819-charts.component.html',
  styleUrls: ['./dashboard1819-charts.component.css']
})
export class Dashboard1819ChartsComponent implements OnInit {

  starPerformers: any;
  baseImageUrl = myGlobals.baseImageUrl;
  public lineChartOptions: ChartOptions = {
    responsive: true,
    // scales: {
    //   xAxes: [{
    //     scaleLabel:
    //     {
    //       display: true,
    //       labelString: 'Events',
    //     }
    //   }], yAxes: [{
    //     scaleLabel:
    //     {
    //       display: true,
    //       labelString: 'Counts',
    //     }

    //   }]
    // },
  };
  public lineChartLabels: Label[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;
  public lineChartPlugins = [];

  lineChartData: ChartDataSets[] = [
    { data: [], fill: false, label: 'Top Marks', borderColor: '#2F3E9E' },
    { data: [], fill: false, label: 'Institute Marks', borderColor: '#D22E2E' }
  ];

  //Stacked bar
  public single: any[];
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Events';
  public showYAxisLabel = true;
  public yAxisLabel = 'Participants';
  public colorScheme = {
    domain: ['#378D3B', '#0096A6']
  };
  lineChart: any;

  constructor(private dashboardService: InstituteDashboard1819Service) {
    // Object.assign(this, {multi}); 
  }

  ngOnInit(): void {
    this.dashboardService.getCardsData().subscribe(
      data => {
        this.starPerformers = data['data'].starPerformer
        this.multi = data['data'].myImpact
        this.setEventwiseBarData(data['data'].myPerformance)
      }
    )
  }

  public onSelect(event) {
    console.log(event);
  }

  setEventwiseBarData(data) {

    data.forEach(element => {
      element.topMarks.forEach(element => {
        this.lineChartData[0]['data'].push(element)
      });
      element.instituteMarks.forEach(element => {
        this.lineChartData[1]['data'].push(element)
      });
      element.event.forEach(element => {
        this.lineChartLabels.push(element)
      });
    });
  }

}
