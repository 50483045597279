import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-charts2022',
  templateUrl: './charts2022.component.html',
  styleUrls: ['./charts2022.component.css']
})

export class Charts2022Component implements OnInit {

  public single: any[];
  public showLegend = true;
  public gradient = true;
  public colorScheme = {
    domain: ['#586B7D', '#5F9EDA', '#9E9492', '#0096A6', '#F47B00', '#606060']
  };
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;
  public trimLabels = false;
  public showPieLegend = false;
  public legendPosition: string = 'below';

  //bar
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public barGradient = false;
  public showBarLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Quarterwise count of activities';
  public showYAxisLabel = false;
  public yAxisLabel = 'Activity';
  public barColorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#91d494', '#0096A6', '#F47B00', '#606060']
  };
  public autoScale = true;

  //Stacked bar
  public multi2: any[];
  public xAxisStackedLabel = 'Events';
  public showYAxisStackedLabel = true;
  public yAxisStackedLabel = 'Participants';
  public stackedColorScheme = {
    domain: ['#378D3B', '#0096A6']
  };
  overallImpact: any;
  ForWhom:any;

  ngOnInit() {

    this.ForWhom='Institute';

    //Pie data
    this.dashboardService.getPieData(localStorage.getItem('token')).subscribe(
      data => this.setPieData(data['data'])
    );

    //bar data
    this.dashboardService.getQuarterwiseBarData2022().subscribe(
      data => this.setBarData(data)
    )

    //Eventwise Bar
    this.setEventwiseBarData();

    //gauge chhart for IIC Calendar
    this.setGauge1Data();

    //gauge chhart for MIC Driven
    this.setGauge2Data();

    //gauge chhart for self-driven
    this.setGauge3Data();

    //stacked bar chart
    this.dashboardService.getOverAllImapact().subscribe(
      data => {
        this.multi2 = data['data'].myImpact
        this.overallImpact = data['data']
      }
    )
  }

  //bar and line chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel:
        {
          display: false,
          labelString: 'Events',
        }
      }], yAxes: [{
        scaleLabel:
        {
          display: true,
          labelString: 'Counts',
        }
      }]
    },
    // elements: {
    //   line: {
    //           fill: false
    //   }
    // }
  };


  public barChartLabels: Label[] = ['Event 1', 'Event 2', 'Event 3', 'Event 4', 'Event 5'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartColors: Color[] = [
    { backgroundColor: '#91d494' },
    { backgroundColor: '#0096A6' },
    { backgroundColor: '#2F3E9E' },
    { backgroundColor: '#D22E2E' }
  ]

  barChartData: ChartDataSets[] = [
    { data: [], label: 'Student' },
    { data: [], label: 'Faculty' },
    { data: [], "type": "line", fill: false, label: 'Highest Student', borderColor: '#2F3E9E' },
    { data: [], "type": "line", fill: false, label: 'Highest Faculty', borderColor: '#D22E2E' }
  ];

  barChartDataInitiate() {
    this.barChartData = [
      { data: [], label: 'Student' },
      { data: [], label: 'Faculty' },
      { data: [], "type": "line", fill: false, label: 'Highest Student', borderColor: '#2F3E9E' },
      { data: [], "type": "line", fill: false, label: 'Highest Faculty', borderColor: '#D22E2E' }
    ];
  }

  selectedType = 'IIC Calendar Activity';

  setEventwiseBarData() {
    if (this.selectedType == 'IIC Calendar Activity') {

      this.barChartDataInitiate();

      //this.barChartType = 'bar';
      this.barChartData.forEach(element => {
        element.data = []
      });
      let data = 1;
      this.dashboardService.getEventWiseBarData(data).subscribe(
        data => {
          data['IIC Calendar Activity']['Students'].forEach(element => {
            this.barChartData[0]['data'].push(element)
          });
          data['IIC Calendar Activity']['Faculty'].forEach(element => {
            this.barChartData[1]['data'].push(element)
          });
          data['IIC Calendar Activity']['Max_Students'].forEach(element => {
            this.barChartData[2]['data'].push(element)
          });
          data['IIC Calendar Activity']['Max_Faculty'].forEach(element => {
            this.barChartData[3]['data'].push(element)
          });
        }
      );
    }
    if (this.selectedType == 'MIC Driven Activity') {
      //this.barChartType = 'bar';

      this.barChartDataInitiate();

      this.barChartData.forEach(element => {
        element.data = []
      });

      let data = 2;
      this.dashboardService.getEventWiseBarData(data).subscribe(
        data => {
          data['MIC driven Activity']['Students'].forEach(element => {
            this.barChartData[0]['data'].push(element)
          });
          data['MIC driven Activity']['Faculty'].forEach(element => {
            this.barChartData[1]['data'].push(element)
          });
          data['MIC driven Activity']['Max_Students'].forEach(element => {
            this.barChartData[2]['data'].push(element)
          });
          data['MIC driven Activity']['Max_Faculty'].forEach(element => {
            this.barChartData[3]['data'].push(element)
          });
        }
      );
    }
    if (this.selectedType == 'Self Driven Activity') {

      this.barChartData.forEach(element => {
        element.data = []
      });
      let data = 3;

      this.barChartData = [
        { data: [], label: 'Student' },
        { data: [], label: 'Faculty' }
      ];

      //this.barChartType = 'line';
      this.dashboardService.getEventWiseBarData(data).subscribe(
        data => {
          data['Self-driven Activity']['Students'].forEach(element => {
            this.barChartData[0]['data'].push(element)
          });
          data['Self-driven Activity']['Faculty'].forEach(element => {
            this.barChartData[1]['data'].push(element)
          });
          // data['Self-driven Activity']['Max_Students'].forEach(element => {
          //   this.barChartData[2]['data'].push(element)
          // });
          // data['Self-driven Activity']['Max_Faculty'].forEach(element => {
          //   this.barChartData[3]['data'].push(element)
          // });
        }

      );
    }

  }

  //Gauge chart
  public canvasWidth = 300;
  public needleValue: number = 0;
  public needleValue2: number = 0;
  public needleValue3: number = 0;
  public centralLabel = '';
  public bottomLabel;
  public bottomLabelFont = 30;
  public bottomLabel2 = '';
  public bottomLabel3 = '';
  public options1 = {
    hasNeedle: true,
    needleColor: 'black',
    arcPaddingColor: 'white',
    arcColors: [],
    arcDelimiters: [],
    rangeLabel: [],
    arcLabels: [],
    arcPadding: [2],
    arcOverEffect: true,
  }

  public options2 = {
    hasNeedle: true,
    needleColor: 'black',
    arcPaddingColor: 'white',
    arcColors: [],
    arcDelimiters: [],
    rangeLabel: [],
    arcLabels: [],
    arcPadding: [2],
    arcOverEffect: true,
  }

  public options3 = {
    hasNeedle: true,
    needleColor: 'black',
    arcPaddingColor: 'white',
    arcColors: [],
    arcDelimiters: [],
    rangeLabel: [],
    arcLabels: [],
    arcPadding: [2],
    arcOverEffect: true,
  }

  public setGauge1Data() {

    let data = 1;
    this.dashboardService.getEventWiseGaugeData2022(data).subscribe(
      data => {
        let endValue = data['IIC Calendar Activity'][0]['gauge_endpoint'];
        this.needleValue = 100 / endValue * data['IIC Calendar Activity'][0]['gauge_value'];
        this.options1.arcColors = data['IIC Calendar Activity'][0]['gauge_value'] > 9 ? ['green', '#c2c2a3'] : ['red', '#c2c2a3'];
        this.bottomLabel = data['IIC Calendar Activity'][0]['gauge_value'];
        this.options1.arcDelimiters = [100 / endValue * 9];
        this.options1.arcLabels = [9];
        this.options1.rangeLabel = ['0', "" + endValue + ""];
      }
    )
  }

  public setGauge2Data() {

    let data = 2;
    this.dashboardService.getEventWiseGaugeData2022(data).subscribe(
      data => {
        let endValue1 = data['MIC driven Activity'][0]['gauge_endpoint'];
        // this.needleValue2 = data['MIC driven Activity'][0]['gauge_value'];
        // this.options2.arcColors = data['MIC driven Activity'][0]['gauge_value'] > 8 ? ['green', '#c2c2a3'] : ['red', '#c2c2a3'];
        // this.bottomLabel2 = data['MIC driven Activity'][0]['gauge_value'];
        // this.options2.arcDelimiters = [100 / endValue * 8];
        // this.options2.arcLabels = [8];
        // this.options2.rangeLabel = ['0', "" + endValue + ""];


        // alert(endValue1);


        //let endValue = Number(endValue1)+Number(2);
        let endValue = Number(endValue1)+Number(4);
        this.needleValue2 = data['MIC driven Activity'][0]['gauge_value'];
        //this.options3.arcColors = ['red', '#c2c2a3'];
        this.options2.arcColors = data['MIC driven Activity'][0]['gauge_value'] > 8 ? ['green', '#c2c2a3'] : ['red', '#c2c2a3'];
        this.bottomLabel2 = data['MIC driven Activity'][0]['gauge_value'];
        this.options2.arcDelimiters = [100 / endValue * 8];
        this.options2.arcLabels = [8];
        this.options2.rangeLabel = ['0', "" + endValue + ""];


        // alert(endValue);
        // alert(this.needleValue2);
        // alert(this.options2.arcColors);
        // alert(this.bottomLabel2);
        // alert(this.options2.arcDelimiters);
        // alert(this.options2.arcLabels);
        // alert(this.options2.rangeLabel);
      }
    )
  }

  public setGauge3Data() {

    let data = 3;

    this.dashboardService.getEventWiseGaugeData2022(data).subscribe(
      data => {
        let endValue = data['Self-driven Activity'][0]['gauge_endpoint'];
        this.needleValue3 = 100 / endValue * data['Self-driven Activity'][0]['gauge_value'];
        //this.options3.arcColors = ['red', '#c2c2a3'];
        this.options3.arcColors = data['Self-driven Activity'][0]['gauge_value'] > 9 ? ['green', '#c2c2a3'] : ['red', '#c2c2a3'];
        this.bottomLabel3 = data['Self-driven Activity'][0]['gauge_value'];
        this.options3.arcDelimiters = [100 / endValue * 9];
        this.options3.arcLabels = [9];
        this.options3.rangeLabel = ['0', "" + endValue + ""];
      }
    )
  }

  constructor(
    private dashboardService: InstituteDashboardService
  ) {
  }

  public onSelect(event) {
  }

  setPieData(data) {
    data.forEach(function (obj) {
      obj.name = obj.theme_name;
      delete obj.theme_name;
      obj.value = obj.theme_count;
      delete obj.theme_value;
    });
    this.single = data;
  }

  setBarData(data) {

    data.forEach(element => {

      element.series.forEach(function (obj) {
        obj.name = obj.quarter;
        delete obj.quarter;
        obj.value = obj.count;
        delete obj.count;
      });
    });
    this.multi = data;
  }
}
