import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { AtalActivityForm1Component } from './atal-activity-form1/atal-activity-form1.component';
import { AtalActivityForm2Component } from './atal-activity-form2/atal-activity-form2.component';
import { AtalActivityForm3Component } from './atal-activity-form3/atal-activity-form3.component';
import { AtalActivityForm4Component } from './atal-activity-form4/atal-activity-form4.component';
import { AtalActivityFormGlobalComponent } from './atal-activity-form-global/atal-activity-form-global.component';


@Component({
  selector: 'app-atal-activity-proposal',
  templateUrl: './atal-activity-proposal.component.html',
  styleUrls: ['./atal-activity-proposal.component.css']
})
export class AtalActivityProposalComponent implements OnInit {

  public addActivityForm1: FormGroup;

  Status:any;
  activity: any[];
  schedules:any[];
  schedules2:any;
  schedules3:any[];
  schedules4:any[];
  Schedules2_2:[];
  Schedules2_3:[];
  Schedules2_4:[];
  schedules5:any;
  activityid: any;
  activity1: boolean;
  activity2: boolean;
  activity3: boolean;
  activity5: boolean;
  activity4: boolean;
  guest_name_1_2_1:any;
  guest_name_2_2_1:any;
  guest_name_3_2_1:any;
  guest_name_4_2_1:any;
  guest_name_5_2_1:any;
  guest_name_1_3_1:any;
  guest_name_2_3_1:any;
  guest_name_3_3_1:any;
  guest_name_4_3_1:any;
  guest_name_5_3_1:any;
  guest_name_1_4_1:any;
  guest_name_2_4_1:any;
  guest_name_3_4_1:any;
  guest_name_4_4_1:any;
  guest_name_5_4_1:any;
  total_pages: any;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  showStatus1:any;
  showStatus2:any;
  MyActivityannualReport:any;
  myActivity:any;

  Mentee:any;

  minDate = "2021-12-01";
  maxDate = "2022-03-31";

  show : any;
  show1 : any;

  public addActivityForm2: FormGroup;
  public addActivityForm5: FormGroup;
  public addActivityForm2_2: FormGroup;
  public addActivityForm2_3: FormGroup;
  public addActivityForm2_4: FormGroup;

  iic_mem:any;
  ia_evnt:any;
  evnt_detail:any = [];
  quarterAtl:number = 0;
  titleAtl:number = 0;
  activityTitle:any;

  constructor(private activityService: InstituteActivityService, private router:Router,
    public dialog: MatDialog, private toastr: ToastrService,private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.showStatus1=0;
    this.showStatus2=0;
    this.show=0;
    this.show1=0;
    // evnt_detail

    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();
    // this.activityChange();

    this.Schedules2_2=[];
    this.Schedules2_3=[];
    this.Schedules2_4=[];

    this.addActivityForm1 = this.fb.group({
      'activity': [],
      'quarter': [],
      'title': [],
    });

  }
  activityChange(){
    this.activityService.getInstituteValue().subscribe(
      data=>this.setEventInstitute(data));
  }
  setEventInstitute(data){
    this.evnt_detail = data.response;
  }
  onSubmit(data){
    // var formData = new FormData();
    // formData.append('activity',data['activity']);
    // formData.append('quarter',data['quarter']);
    this.activityService.getInstituteValue(data['activity'],data['quarter'],'',data['title']).subscribe(
      data=>this.setEventInstitute(data));
  }

  getActivityStatus(){
    this.activityService.getAtalActivityStatus().subscribe(
      data => this.setStatus(data)
    );
  }
  ActivityStatus(){
    this.activityService.getAtalActivityStatus().subscribe(
      data => this.setactivityStatus(data)
    );
  }

  setactivityStatus(data){

if(data['1']==1){
  this.activity1=true
}else{
  this.activity1=false
}

if(data['2']==1){
  this.activity2=true
}else{
  this.activity2=false
}

if(data['3']==1){
  this.activity3=true
}else{
  this.activity3=false
}

if(data['4']==1){
  this.activity4=true
}else{
  this.activity4=false
}

if(data['5']==1){
  this.activity5=true
}else{
  this.activity5=false
}

  }

  getScheduleTable(){
    this.activityService.AtalSchedules().subscribe(
      data => this.setScheduleTable(data)
    );
  }
  getDataTable(){
    // this.activityService.atalActivities().subscribe(
    //   data => this.setDataTable(data)
    // );
  }
  setStatus(data){
    this.Status=data;
  }
  setScheduleTable(data){
    this.schedules=[];
    for(let i=0;i<data['Schedules'].length;i++){
      this.schedules.push(data['Schedules'][i])
    }

    // this.schedules2=data['AtlMentee'];
    this.schedules2=[];
    for(let i=0;i<data['Schedules2'].length;i++){
      this.schedules2.push(data['Schedules2'][i])
    }


    this.schedules3=[];
    for(let i=0;i<data['Schedules3'].length;i++){
      this.schedules3.push(data['Schedules3'][i])
    }

    this.schedules4=[];
    for(let i=0;i<data['Schedules4'].length;i++){
      this.schedules4.push(data['Schedules4'][i])
    }
    this.iic_mem = data['Schedules6']['member'];
    this.ia_evnt=data['Schedules6']['ia'];

    if(data['dataMCOunt']>0)
    {
      this.showStatus2 = 1;
    }

  }

  setDataTable(data){
    this.activity=[];
    for(let i=0;i<data['Activities'].length;i++){
      this.activity.push(data['Activities'][i])
    }
  }


  onEditClick(activity, type){

    const data1={
      data:activity,
      aptype:type
    }

    
  const dialogRef = this.dialog.open(AtalActivityFormGlobalComponent, {
    data: activity,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();
  });
}

OpenActivity2Form(activity){
  const dialogRef = this.dialog.open(AtalActivityForm2Component, {
    data: activity,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();
  });
}

onSubmitClick(id,activity){
  var data: any[]=[]
  data['id']=activity;
  data['activity_id']=id;
  this.openDialog1(data, '');
}


onDeleteClick(activityid){

    // data['activityid']=activityid;
  this.activityid=activityid
  const p={
    activityid:activityid
  }
    this.activityService.deleteAtalSchedule(p).subscribe(data=>this.handleresponse(data));
}
onDeleteScheduleClick(activityid){

    // data['activityid']=activityid;
  this.activityid=activityid
  const p={
    activityid:activityid
  }
    this.activityService.deleteAtlActivitySchedule(p).subscribe(data=>this.handleresponse(data));
}
handleresponse(data){
  if(data['message']=='Successfully Deleted')
    {this.toastr.success("Successfully Deleted");
    this.getScheduleTable();}
  else
    this.toastr.error("Not deleted");
}


openDialog1(data, type){
  const data1={
    data:data,
    aptype:type
  }

  if(data.id==1){

    const dialogRef = this.dialog.open(AtalActivityForm1Component, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();
    });
  }

  if(data.id=="global"){

    const dialogRef = this.dialog.open(AtalActivityFormGlobalComponent, {
      data: data1,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();
    });
  }

  if(data.id==3){

    const dialogRef = this.dialog.open(AtalActivityForm3Component, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();

    });
  }
  if(data.id==4){

    const dialogRef = this.dialog.open(AtalActivityForm4Component, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();

    });
  }

}

openDialog(data, type, evnt_id=""){

  const data1={
    data:data,
    aptype:type,
    evnt_id:evnt_id
  }


if(data==1){

  const dialogRef = this.dialog.open(AtalActivityForm1Component, {
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

  });
}

if(data=="global"){
 
  const dialogRef = this.dialog.open(AtalActivityFormGlobalComponent, {
    data: data1
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();
  });
}


if(data==3){

  const dialogRef = this.dialog.open(AtalActivityForm3Component, {
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

  });
}

if(data==4){

  const dialogRef = this.dialog.open(AtalActivityForm4Component, {
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

  });
}


  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  handleResponse(data){
    if(data['message']=="Success"){
      this.toastr.success("Successfully Submitted");
    }

  }

  nominateDrop(event){
    if(event == 'iic_member'){
      this.show = 1;
      this.show1 = 0;
    }else{
      this.show = 0;
      this.show1 = 1;
    }
  }

  onNominateClick(type,id){
    var data = {type:type,id:id};
    this.activityService.submitNominateMember(data).subscribe(
      data => this.changeNominate(data)
    );

  }
  changeNominate(data){
    if(data['status'] == "Success"){
      document.getElementById('nominateSuccess').style.display = 'none';
      document.getElementById('nominateRemove').style.display = 'block';
    }
  }
  changeNominate1(data){
    if(data['status'] == "Success"){
      document.getElementById('nominateSuccess').style.display = 'block';
      document.getElementById('nominateRemove').style.display = 'none';
    }
  }

  onNominateRemoveClick(type,id){
    var data = {type:type,id:id,is_remove:1};
    this.activityService.submitNominateMember(data).subscribe(
      data => this.changeNominate1(data)
    );
  }
  changeActivityAtl(data){
    console.log(data);
    if(data){
      this.quarterAtl = 1;
    }

  }
  changeQuarterAtl(data){
    console.log(data);
    if(data){
      var activity = this.addActivityForm1.get('activity').value;
      var quarter = this.addActivityForm1.get('quarter').value;
      this.activityService.getATLActivityTitle(activity,quarter).subscribe(
        data => this.handleActivityAtl(data)
      );
      
    }
  }

  handleActivityAtl(data){
    this.activityTitle = data;
    this.titleAtl = 1;
  }
}


