import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { saveAs as importedSaveAs } from 'file-saver';


@Component({
  selector: 'app-dashboard-activity-list',
  templateUrl: './dashboard-activity-list.component.html',
  styleUrls: ['./dashboard-activity-list.component.css']
})
export class DashboardActivityListComponent implements OnInit {
  list: any;
  page: any;

  constructor(private dashboardService: InstituteDashboardService) { 
    dashboardService.getdashboardDownloadList().subscribe(
      data => this.list = data['data']
    )
  }

  ngOnInit(): void {
  }


  public onPageChanged(event) {
    this.page = event;
  }

  getActivityReport(eventId) {
    if(eventId == 'EVE151' || eventId == 'EVE2890' || eventId == 'EVE10137'){
      this.dashboardService.getMonthlyReportCouncilPdf(eventId).subscribe(
        data => this.downloadFile(data, 'ActivityReport.pdf')
      )
    }
    else{
      this.dashboardService.getMonthlyReportPdf(eventId).subscribe(
        data => this.downloadFile(data, 'ActivityReport.pdf')
      )
    }
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }

}
