import { Component, OnInit } from '@angular/core';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-iic-impact-lecture-series-new',
  templateUrl: './iic-impact-lecture-series-new.component.html',
  styleUrls: ['./iic-impact-lecture-series-new.component.css']
})
export class IicImpactLectureSeriesNewComponent implements OnInit {

  imageBasePath= myGlobals.imageBasePath;
  
  constructor() { }

  ngOnInit(): void {
	  
		  /*------------------------------------------------------------------
   	 Smooth Scroll Landing
	 ------------------------------------------------------------------*/
// Select all »a« elements with a parent class »links« and add a function that is executed on click
$( '#navbarColor01 a' ).on( 'click', function(e){
	
  // Define variable of the clicked »a« element (»this«) and get its href value.
  var href = $(this).attr( 'href' );
  
  // Run a scroll animation to the position of the element which has the same id like the href value.
  $( 'html, body' ).animate({
		scrollTop: $( href ).offset().top
  }, '300' );
	
  // Prevent the browser from showing the attribute name of the clicked link in the address bar
  e.preventDefault();

});

$(document).ready(function(){ 
    $(window).scroll(function(){ 
        if ($(this).scrollTop() > 100) { 
            $('#scroll').fadeIn(); 
        } else { 
            $('#scroll').fadeOut(); 
        } 
    }); 
    $('#scroll').click(function(){ 
        $("html, body").animate({ scrollTop: 0 }, 600); 
        return false; 
    }); 
});  
	  
  }

}
