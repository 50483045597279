import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';

@Component({
  selector: 'app-select-menter-mentee-form',
  templateUrl: './select-menter-mentee-form.component.html',
  styleUrls: ['./select-menter-mentee-form.component.css']
})
export class SelectMenterMenteeFormComponent implements OnInit {
  public form2: FormGroup;
  preferenceModel:any;
  tableData: any[];
  status: number=0;
  preferencevalue: any;
  type: string;
  sss: string;
  ss1: string;
  showmenteeTable: number;
  showmentorTable: number;
  menteeDetails: any[];
  id: any;
  submittedData: any[];
  fundby:any;
  isDisplay:any = 0;
  // preference
  constructor(private activityService: InstituteActivityService,private router:Router,
    public dialog: MatDialog, private toastr: ToastrService,private fb: FormBuilder,) { }
  ngOnInit(): void {

    this.fundby=localStorage.getItem('FundedBy');
    
    this.getPreference();
    this.checkMemtorEligible();
    this.getMenteeTable();

    // if(this.showmentorTable){
      this.form2 = this.fb.group({
        'prefrence': [null, Validators.required],
        'funded_by': [null, Validators.required],
      });
    // }
    // if(this.showmenteeTable){
    //   this.form2 = this.fb.group({
    //     'funded_by': [null, Validators.required],
    //   });
    // }
  }

  SubmittedMentorDetails(){
    this.activityService.getSelectedMantors().subscribe(
      data => this.setSelectedMantors(data)
    );
  }
  setSelectedMantors(data){
    this.submittedData = [];

    data['data'].forEach((element) => {
      this.submittedData.push(element);
    });

  }
  checkMemtorEligible(){
    this.activityService.checkMemtorEligible().subscribe(
      data => this.setcheckMemtorEligible(data)
    );
  }

  setcheckMemtorEligible(data){
    if(data['mentor_eligible']==1){
      this.type="mentor"
      this.sss="Mentor"
      this.ss1="Mentor"
      // this.showmenteeTable=
      this.showmentorTable=1;

    }
    if(data['mentee_eligible']==1){
      this.type="mentee"
      this.sss="Mentee"
      this.ss1="Mentor"
      this.showmenteeTable=1;
      this.SubmittedMentorDetails();

    }
  }





  getPreference(){
    this.activityService.menteePrefrenceStatus().subscribe(
      data => this.handleResponse(data)
    );
  }
  // menteePrefrenceStatus
  onSubmit(data){
    this.preferencevalue=data;
    this.activityService.submitMenteePrefrence(data).subscribe(
      data => this.handleResponse(data)
    );

  }

  handleResponse(data){
    if(data['status']==0)
    {
      this.toastr.error(data['message'])
    }
    else
    {

    this.tableData=[];

    if(data['data'].length==0){
      this.status=0;
    }else{

      // this.tableData=[];
      // for(let i=0;i<data['data'].length;i++){
      //   this.tableData.push(data['data'][i])
      // }
      
      this.tableData= data['data'];
      if(this.tableData[0]["instituteName"]===undefined) { this.status=0; } else { this.status=1; }
    }
    }

if(this.sss=="Mentee"){
  this.SubmittedMentorDetails();

}
  }


  onSubmitClick(data){

    this.preferencevalue['id']=data.id

    this.activityService.mentorSelection(this.preferencevalue).subscribe(
      data => this.handleResponse2(data)
    );
  }

  handleResponse2(data){
    if(data['status']=='0')
    {
      this.toastr.error(data['message'])
    }
    else
    {
      if(data['status']=='1'){
        this.toastr.success(data['message'])

      }else{
        this.toastr.error(data['message'])

      }
      // this.SubmittedMentorDetails();
      if(this.sss=="Mentee"){
        this.SubmittedMentorDetails();
      }

    }

  }





















// Mentor tableData

getMenteeTable(){
  this.activityService.getMenteeTable().subscribe(
    data => this.setMentorTable(data)
  );
}

setMentorTable(data){
  this.menteeDetails=[];

  for(let i=0;i<data['data'].length;i++){
    this.menteeDetails.push(data['data'][i])
  }
}

onAcceptClick(data){


this.id=data.mentee_userId
    this.activityService.acceptMantee(this.id, 'Accept').subscribe(
      data => this.handleResponse4(data)
    );


}

onRemoveClick(data){
  this.id=data.mentee_userId
  this.activityService.acceptMantee(this.id, 'Remove').subscribe(
    data => this.handleResponse4(data)
  );
}

handleResponse4(data){


  if(data['status']=='1'){
    this.toastr.success(data['msg']);
    this.getMenteeTable();
  }
  else{
    this.toastr.error(data['msg'])
  }
  // if(this.sss=="Mentee"){
  //   this.SubmittedMentorDetails();

  // }
}
onDeleteClick(data){
  if (confirm("Are you sure you want to delete?")) {
    this.id=data;
    this.activityService.removeSelectedMentors(this.id).subscribe(
      data => this.handleResponse1(data)
    );
    }

}
handleResponse1(data){
  if(data['status']=='1'){
    this.toastr.success(data['msg']);
    this.SubmittedMentorDetails();
    // window.location.reload();
  }else{
    this.toastr.error(data['msg'])
  }
}

onDeleteClick1(data){
  if (confirm("Are you sure you want to remove?")) {
    this.id=data;
    this.activityService.removeSelectedMentee(this.id).subscribe(
      data => this.handleResponse12(data)
    );
  }
}

handleResponse12(data){
  if(data['status']=='1'){
    this.toastr.success(data['msg']);
    this.getMenteeTable();
    // window.location.reload();
  }else{
    this.toastr.error(data['msg'])
  }
}


}
