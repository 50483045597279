<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

<link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
<link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">

<style>
	.navbar-nav>li {
		float: none !important;
		text-align: center !important;
		line-height: 24px;
	}
</style>

<!-- <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container-fluid navigation_width">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="@"><img src="assets/img/logo.png" class="img-responsive"></a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav navbar-right" style="width:auto;float: left !important;right:40px; top: 0;">
          <li><span style="font-size: 27px;">MHRD’s Innovation Cell<br>Institution’s Innovation Council</span><br>Institute Portal</li>
        </ul>
      </div>
    </div>
  </nav> -->

<!-- <div class="limiter">
    <div class="container-login100">
		<div class="wrap-login100 p-b-50">
			<span class="login100-form-title p-b-20" style="margin-top:100px;">
				<img src="assets/institute/images/logoiic.png" class="loginIICLogo">
			</span>
			<span class="login100-form-title">
				<p><a href="assets/html/index.html" target="_blank" class="blinking" style="margin-bottom:2%; font-size:18px; font-weight:bold;color:#C72027; text-align:center;">HELP: ABOUT IIC AND PORTAL GUIDELINES</a></p>
			</span>
			
			<form class="login100-form validate-form flex-sb flex-w" #loginForm=ngForm (submit)="onSubmit()">
				<div class="form-group wrap-input100 validate-input m-b-16">
					<!-- <label class="control-label col-sm-2" for="email">Email:</label> -->
<!--<input type="email" class="input100" id="email" placeholder="Enter email" name="email" [(ngModel)]="form.email" required>
				</div>
				
				<div class="form-group wrap-input100 validate-input m-b-16">
					<!-- <label class="control-label col-sm-2" for="pwd">Password:</label> -->
<!--	<input type="password" class="input100" id="password" placeholder="Enter password" name="password" [(ngModel)]="form.password" required>
				</div>
				
				<div class="form-group pull-left" style="padding-top: 10px;">
					<label>
						<a routerLink="/request-password-reset" class=""><span class="loginLink">Reset Password</span></a>
					</label>
				</div>
				
				<div class="form-group pull-left" style="padding-top: 10px;">
					<label>
						<a [routerLink]="['/signup']" routerLinkActive="router-link-active" class=""><span class="loginLink">Signup</span></a>
					</label>
				</div>
				
				<div class="container-login100-form-btn m-t-17">
					<button type="submit" class="login100-form-btn" [disabled]="!loginForm.valid">Login</button>
				</div>
			</form>
		</div>
    </div>
</div> -->


<!-- New Login -->

<div class="limiter">
	<div class="container-login100">
		<div class="wrap-login100">
			<span class="login100-form-title loginInstiTopContent">
				<h2>MOE’s Innovation Cell</h2>
				<p>Institution’s Innovation Council</p>
				<span>Institute Portal</span>
			</span>
			<div class="login100-pic js-tilt" data-tilt>
				<img src="assets/institute/images/logoiic.png" alt="IIC Logo">
			</div>
			<form class="login100-form validate-form flex-sb flex-w" #loginForm=ngForm (submit)="onSubmit(captcha_response.value)">
				<span class="login100-form-title">
					Institute Login
				</span>
				<div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
					<input type="email" class="input100" id="email" placeholder="Enter email" name="email"
						[(ngModel)]="form.email" required>
					<span class="focus-input100"></span>
					<span class="symbol-input100">
						<i class="fa fa-envelope" aria-hidden="true"></i>
					</span>
				</div>
				<div class="wrap-input100 validate-input" data-validate="Password is required">
					<input type="password" class="input100" id="password" placeholder="Enter password" name="password"
						[(ngModel)]="form.password" required>
					<span class="focus-input100"></span>
					<span class="symbol-input100">
						<i class="fa fa-lock" aria-hidden="true"></i>
					</span>
				</div>
				<!-- <div class="wrap-input100 validate-input" data-validate="Login type is required" style="display: flex;padding: 15px 0px;">
					<input type="radio" name="type" style="height: 20px;width: 20px;" [(ngModel)]="form.type" required value="1">&nbsp;<span style="color:#000000;padding-top: 3px;font-weight: 500;">Institue/ATL Login</span>
					<input type="radio" name="type" style="margin-left: 15px;height: 20px;width: 20px;" [(ngModel)]="form.type" required value="2">&nbsp;<span style="color:#000000;padding-top: 3px;padding-top: 3px;font-weight: 500;">Student Login</span>
				</div> -->
				<div id="captcha-box">
					<!-- <re-captcha (resolved)="resolved($event)" siteKey="6Lc0dcoZAAAAAGVDDIpIcXdxnFmpl_8iCDhv56hT">
					</re-captcha> -->
					<div class="captchaContainer">
						<div class="captchaBlock">
						  <div class="captchaText">
							<canvas id="canvas2" #canvas2></canvas>
						  </div>
						  <input
							type="text"
							class="captchaInputText"
							[maxLength]="6"
							[minLength]="6"
							#captcha_response
							id="captcha_response"
							placeholder="Enter Text Here"
						  />
						</div>
						<a class="captchaRefreshBtn" (click)="createCaptcha()"
						  ><mat-icon>cached</mat-icon></a
						>
					</div>
					<!-- <div class="captchaErrorBox" *ngIf="captchaResponse != null">
						<div *ngIf="captcha_verified" style="color: green">
							Captcha Verified Successfully !
						</div>
						<div *ngIf="!captcha_verified" style="color: red">
							Please Enter Correct Captcha !
						</div>
					</div> -->
				</div>

				<div class="text-center p-t-12">
					<a routerLink="/request-password-reset" class="txt2 resetPassBorder">Forgot Password</a>
				</div>

				<div class="container-login100-form-btn">
					<button type="submit" class="login100-form-btn" [disabled]="!loginForm.valid">Login</button>
				</div>

				<div class="text-center p-t-12">
					<a [routerLink]="['/signup']" routerLinkActive="router-link-active" class="txt2 newRegisBorder newRegBtn">
						New Registration for Higher Education Institutions
						<!--<i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>--></a>
				</div>

				<div class="text-center p-t-12">
					<a [routerLink]="['/atl-signup']" routerLinkActive="router-link-active" class="txt2 newRegisBorder newRegBtn">
						New Registration for ATL
						<!--<i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>--></a>
				</div>
			</form>

			<span class="login100-form-title abtIICLandingLogin">
				<p><a href="assets/html/index.html" target="_blank" class="blinking">IIC Guide and Portal Manual</a>
				</p>
			</span>
		</div>
	</div>
</div>



<!-- Footer 
<app-institute-footer></app-institute-footer>-->