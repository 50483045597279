import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';

@Component({
  selector: 'app-subiic-existing',
  templateUrl: './subiic-existing.component.html',
  styleUrls: ['./subiic-existing.component.css']
})
export class SubiicExistingComponent implements OnInit {

  public iicImpactQuestions: FormGroup;
  public iicImpactQuestions1: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  cityname:any;
  states = [];
  institutes = [];
  arr:any;
  president_email:any;
  convener_email:any;
  iic_id:any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "existing";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });

    this.iicImpactQuestions = this.fb.group({
      institute_name: [''],
      address: [''],
      state: [''],
      city: [''],
      aishe_code: [''],
      ins_subtype: [''],
      president_name: [''],
      president_email: [''],
      president_mobile: [''],
      convener_name: [''],
      convener_email: [''],
      convener_mobile: [''],
    });
    this.iicImpactQuestions1 = this.fb.group({
      iic: ['']
    });
    // this.states = this.getStates();

  }
  
  ngOnInit(): void {
    // this.instituteActivityService.getStateDetails().subscribe(
    //   (data) => this.handleResponse1(data)
    // );
  }

  handleResponse1(data) {
    this.states = data
  }

  handleResponse2(data) {
    this.institutes = data
  }

  handleResponse3(data) {
    if(data['status'] == 'success'){
      document.getElementById('content-display').style.display = 'block';
      this.iicImpactQuestions.patchValue({
        institute_name : data['data']['institute_name'],
        address: data['data']['institute_address'],
        state: data['data']['state'],
        city: data['data']['city'],
        aishe_code: data['data']['aishe_code'],
        president_name: data['data']['president_name'],
        president_email: data['data']['president_email'],
        president_mobile: data['data']['president_contact'],
        convener_name: data['data']['convener_name'],
        convener_email: data['data']['convener_email'],
        convener_mobile: data['data']['convener_contact'],
      });
      this.president_email = data['data']['president_email'];
      this.convener_email = data['data']['convener_email'];
    }else{
      document.getElementById('content-display').style.display = 'none';
      this.toastr.error(data['data']);
    }
  }

  onSearch(data){
    this.iic_id = this.iicImpactQuestions1.get('iic').value;
    this.instituteActivityService.getDetailByIic(this.iicImpactQuestions1.get('iic').value).subscribe(
      (data) => this.handleResponse3(data)
    );
  }
  onSubmit(data){
    // this.instituteActivityService.getIICDetail(this.iicImpactQuestions.get('institute_name').value).subscribe(
    //   (data) => this.handleResponse2(data)
    // );
  }

  onChangeState(stateId: number) {
    if (stateId) {
      this.arr = {
        'stateId' : stateId,
        'type' : 'IIC',
      };
      this.instituteActivityService.getInstituteDetails(this.arr).subscribe(
        (data) => this.handleResponse2(data)
      );
    } else {
      this.institutes = null;
    }
  }

  onClickInvite(){
    this.arr = {
      'president_email' : this.president_email,
      'convener_email' : this.convener_email,
      'iic_id' : this.iic_id,
    };
    this.instituteActivityService.getInviteSubIic(this.arr).subscribe(
      (data) => this.handleResponse4(data)
    );
  }

  handleResponse4(data){
    if(data['status'] == 'success'){
      this.toastr.success(data['data']);
    }else{
      this.toastr.error(data['data']);
    }
  }

}
