import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-yukti-institute-nominate-challenges-new',
  templateUrl: './yukti-institute-nominate-challenges-new.component.html',
  styleUrls: ['./yukti-institute-nominate-challenges-new.component.css']
})
export class YuktiInstituteNominateChallengesNewComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  innovationData:any;
  nominatedInnovationData:any;
  yuktiChallenge:any;
  challenge_id:any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.challenge_id = params.challenge_id;
    });
    
    this.iicImpactQuestions = this.fb.group({
      'innovation_id': [null, Validators.required]
    });
    this.instituteActivityService.getInnovationData(this.challenge_id).subscribe(
      (data) => this.handleResponse(data)
    );
    this.instituteActivityService.getNominatedInnovationData(this.challenge_id).subscribe(
      (data) => this.handleResponseNominated(data)
    );

  }

  handleResponse(data) {
    this.innovationData = data.innovationData;
    this.yuktiChallenge = data.yuktiChallenge;
  }
  handleResponseNominated(data) {
    this.nominatedInnovationData = data;
  }

  onSubmit(data){
    var formData = new FormData();

    formData.append('challenge_id', this.challenge_id);
    formData.append('innovation_id', this.iicImpactQuestions.get('innovation_id').value);
    this.instituteActivityService.submitNominatedInnovationData(formData).subscribe(
      data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']){
      this.toastr.success('Nominated Successfully');
      window.location.reload();
    }
  }

  onClickEvent(data,type){
    if(type=="Edit")
    {
      window.location.href="/institute/yukti-challenges-add/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.deleteNominatedChallenges(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }
  }

  handleResponse1(data)
  {

  }

}
