<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Impact Lecture Series</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Impact Lecture Series</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>Impact Lecture Series</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC 2020-21</li>
  </ul>
</nav>


<div class="content-impactLecture" id="content">
	<section class="about_section layout_padding">
		<div class="container">
		  <div class="vc_row innovationAmbassadorBox ambPadding1">
				<div class="vc_col-md-12">
					<div class="headingAmbassador">
						<h2 class="incubTabHeading">Impact Lecture Series for aspirational IICs (2020-21)</h2>
					</div>
				</div>
			</div>
		  <div class="row">
			<div class="col-md-6">
			  <div class="detail-box">
				<p id="paraimpact20_21">
					The objective of the scheme is to promote innovation, IPR and 
					startup awareness among students and faculties in the IIC institute 
					located in Rural/Himalayan/North Eastern region/Tier III Cities with 
					less outreach to the innovation ecosystem enablers. This impact series
					will enable IICs to organise two lectures (on Different dates) in the
					field of innovation, IPR, and startup by inviting successful innovators,
					patent expert and entrepreneurs. MHRD’s Innovation will provide financial 
					support to selected Institutions (IICs) to conduct these 
					lectures. Institute will be asked submit a detailed proposal to MIC.
				</p>
				<a href="assets/images/Recommendation_of_Impact_Lecture_Series_3.pdf" target="_blank">
				  PDF of Selected Institutions
				</a>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="img-box">
				<img src="assets/assets/images/00b.jpg" class="img-fluid impLec20" alt="">
			  </div>
			</div>
		  </div>
		</div>
  </section>
</div>




<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>