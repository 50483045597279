<div class="container-fluid">
  <div class="row" color="primary">
    <h2 class="ss" style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;margin: 0 15px 20px 15px;width:100%;"> IPR Innovator's Login Creation / Transfer of Innovation from Old Innovator to new YUKTI User</h2>
  </div>
  
  <!-- <div class="card">
  <div class="card-title"><p>Details of yukti challenge to be organized</p></div>
  </div> -->
  
  <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
    <table class="table table-hover" style="box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);">
      <tr>
        <td>
          Principal Inventor Name
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Principal Inventor Name</mat-label>
            <input matInput autocomplete="off" placeholder="Principal Inventor Name" formControlName="principal_inventor_name" type="text" required>
            <mat-error *ngIf="iicImpactQuestions.controls.principal_inventor_name.errors?.required">Principal Inventor Name
              is required
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          Principal Inventor Type
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Principal Inventor Type</mat-label>
            <input matInput autocomplete="off" placeholder="Principal Inventor Type" formControlName="principal_inventor_type" type="text" required>
            <mat-error *ngIf="iicImpactQuestions.controls.principal_inventor_type.errors?.required">Principal Inventor Type
              is required
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          Principal Inventor Email
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Principal Inventor Email</mat-label>
            <input matInput autocomplete="off" placeholder="Principal Inventor Email" formControlName="principal_inventor_email" type="text" required>
            <mat-error *ngIf="iicImpactQuestions.controls.principal_inventor_email.errors?.required">Principal Inventor Email
              is required
            </mat-error>
            <mat-error *ngIf="iicImpactQuestions.controls.principal_inventor_email.hasError('invalidEmail')">Invalid Principal Inventor Email</mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          Principal Inventor Contact
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Principal Inventor Contact</mat-label>
            <input matInput autocomplete="off" placeholder="Principal Inventor Contact" formControlName="principal_inventor_contact" type="text" minlength="10" maxlength="10" required (keypress)="isNumKey($event)">
            <mat-error *ngIf="iicImpactQuestions.controls.principal_inventor_contact.errors?.required">Principal Inventor Contact is required </mat-error>
            <mat-error *ngIf="iicImpactQuestions.controls.principal_inventor_contact.errors?.minlength">Principal Inventor Contact should be of 10 digits </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          Invention Title
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Invention Title</mat-label>
            <input matInput autocomplete="off" placeholder="Invention Title" formControlName="invention_title" type="text" required>
            <mat-error *ngIf="iicImpactQuestions.controls.invention_title.errors?.required">Invention Title
              is required
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          Gender
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender" required>
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error *ngIf="iicImpactQuestions.controls.gender.errors?.required"> Gender is required </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          Current Engagement with the Institute
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label> Current Engagement with the Institute</mat-label>
            <mat-select autocomplete="off" placeholder="Current Engagement with the Institute" formControlName="user_type"  required>
            <mat-option value="Student">Student</mat-option>
            <mat-option value="Faculty">Faculty/Staff</mat-option>
            <mat-option value="Alumni">Graduated/Alumni Member</mat-option>
            <mat-option value="Entrepreneur">Entrepreneur at Incubation Unit/Institute</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          &nbsp;
        </td>
        <td>
          <button mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicImpactQuestions.invalid'>Submit</button>
        </td>
      </tr>
    </table>
  </form>
</div>