import { Component, OnInit } from '@angular/core';
import { OnlineJarwisService } from 'src/app/Services/onlineSession/online-jarwis.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OnlineTokenService } from 'src/app/Services/onlineSession/online-token.service';
import { Router } from '@angular/router';
import { OnlineAuthService } from 'src/app/Services/onlineSession/online-auth.service';
@Component({
  selector: 'app-online-session-login',
  templateUrl: './online-session-login.component.html',
  styleUrls: ['./online-session-login.component.css']
})
export class OnlineSessionLoginComponent implements OnInit {

  public loginForm: FormGroup;
  public error = null;

  captchaResponse;

  constructor(
    private jarwis: OnlineJarwisService,
    private token: OnlineTokenService,
    private router: Router,
    private auth: OnlineAuthService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  onSubmit(values) {
    if (this.captchaResponse != null) {
      this.jarwis.login(values).subscribe(
        (data) => this.handleResponse(data),
        (error) => this.handleError(error)
      );
    } else {
      this.toastr.error('Kindly verify Captcha first!');
    }
  }

  handleResponse(data) {
    if (data['message'] == 'Error') {
      this.toastr.error(data['reason']);
    } else {
      this.token.handle(data.token);
      let payload = this.token.payload(data.token);
      this.auth.changeStatus(true);
      this.router.navigateByUrl('details-page-IA');
    }
  }

  handleError(error) {
    this.error = error.error.error;
    this.toastr.error(error.error.error);
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }
}
