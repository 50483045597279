import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ActivityForm1Component } from './activity-form1/activity-form1.component';
import { ActivityForm2Component } from './activity-form2/activity-form2.component';
import { ActivityForm3Component } from './activity-form3/activity-form3.component';
import { ActivityForm4Component } from './activity-form4/activity-form4.component';
import { ActivityForm5Component } from './activity-form5/activity-form5.component';
import { ActivityReportForm1Component } from './activity-report-form1/activity-report-form1.component';
import { ActivityProposalDialogComponent } from './activity-proposal-dialog/activity-proposal-dialog.component';
import * as myGlobals from '../../../../../../../../app/app.component';

@Component({
  selector: 'app-activity-proposal',
  templateUrl: './activity-proposal.component.html',
  styleUrls: ['./activity-proposal.component.css']
})
export class ActivityProposalComponent implements OnInit {

  baseUrl = myGlobals.baseUrl1;
  imageBasePath = myGlobals.imageBasePath;
  Status:any;
  activity: any[];
  schedules:any[];
  schedules2:any;
  schedules3:any[];
  schedules4:any[];
  Schedules2_2:[];
  Schedules2_3:[];
  Schedules2_4:[];
  schedules5:any;
  activityid: any;
  activity1: boolean;
  activity2: boolean;
  activity3: boolean;
  activity5: boolean;
  activity4: boolean;
  guest_name_1_2_1:any;
  guest_name_2_2_1:any;
  guest_name_3_2_1:any;
  guest_name_4_2_1:any;
  guest_name_5_2_1:any;
  guest_name_1_3_1:any;
  guest_name_2_3_1:any;
  guest_name_3_3_1:any;
  guest_name_4_3_1:any;
  guest_name_5_3_1:any;
  guest_name_1_4_1:any;
  guest_name_2_4_1:any;
  guest_name_3_4_1:any;
  guest_name_4_4_1:any;
  guest_name_5_4_1:any;
  TMentee:any;
  total_glines:any;
  glines:any;
  MmStatus_total:any;
  MmStatus:any;
  OmStatus:any;
  MmStatus2_total:any;
  OmStatus_total:any;
  RQ_1_file:any;
  RQ_12_file:any;
  RQ_21_file:any;
  RQ_22_file:any;
  RQ_23_file:any;
  RQ_24_file:any;
  RQ_3_file:any;
  RQ_32_file:any;
  RQ_4_file:any;
  RQ_5_file:any;
  RQ_6_file:any;
  RQ_7_file:any;
  RQ_8_file:any;
  RQ_81_file:any;
  RQ_82_file:any;

  report: FileList | null;
  uc_report: FileList | null;

  ipr_document: FileList | null;
  ip_copy: FileList | null;
  all_report: FileList | null;

  Mentee:any;

  minDate = "2021-12-01";
  maxDate = "2022-08-31";

  isShow:number=0;

  public addActivityForm2: FormGroup;
  public addActivityForm5: FormGroup;
  public addActivityForm2_2: FormGroup;
  public addActivityForm2_3: FormGroup;
  public addActivityForm2_4: FormGroup;
  public addActivityForm6: FormGroup;
  public addActivityForm7: FormGroup;
  public addActivityForm8: FormGroup;

  fundedBy : any;

  constructor(private activityService: InstituteActivityService,private router:Router,
    public dialog: MatDialog, private toastr: ToastrService,private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

    this.MmStatus_total=0;
    this.MmStatus2_total=0;
    this.OmStatus_total=0;

    this.Schedules2_2=[];
    this.Schedules2_3=[];
    this.Schedules2_4=[];

    this.TMentee=0;

    this.addActivityForm6 = this.fb.group({
      nisp_status: false,
      iepolicy_status: false,
      ariia_status: false
    });

    this.addActivityForm7 = this.fb.group({
      ia_status: false,
      mentoring_support: false,
      incubation_status: false
    });

    this.addActivityForm8 = this.fb.group({
      video_url : [],
    });
    

  }
getActivityStatus(){
  this.activityService.getActivityStatus().subscribe(
    data => this.setStatus(data)
  );
}
  ActivityStatus(){
    this.activityService.activityStatus().subscribe(
      data => this.setactivityStatus(data)
    );
  }
  setactivityStatus(data){

if(data['1']==1){
  this.activity1=true
}else{
  this.activity1=false
}

if(data['2']==1){
  this.activity2=true
}else{
  this.activity2=false
}

if(data['3']==1){
  this.activity3=true
}else{
  this.activity3=false
}

if(data['4']==1){
  this.activity4=true
}else{
  this.activity4=false
}

if(data['5']==1){
  this.activity5=true
}else{
  this.activity5=false
}

  }

  getScheduleTable(){
    this.activityService.schedules().subscribe(
      data => this.setScheduleTable(data)
    );
  }
  getDataTable(){
    this.activityService.activities().subscribe(
      data => this.setDataTable(data)
    );
  }
  setStatus(data){
    this.Status=data;
  }
  setScheduleTable(data){
    this.schedules=[];
    for(let i=0;i<data['Schedules'].length;i++){
      this.schedules.push(data['Schedules'][i])
    }

    this.schedules3=[];
    for(let i=0;i<data['Schedules3'].length;i++){
      this.schedules3.push(data['Schedules3'][i])
    }

    this.schedules4=[];
    for(let i=0;i<data['Schedules4'].length;i++){
      this.schedules4.push(data['Schedules4'][i])
    }

    this.Mentee = data["Mentee"];

    this.TMentee=data["TMentee"];

    this.RQ_1_file=data["RQ_1_file"];
    this.RQ_12_file=data["RQ_12_file"];
    this.RQ_21_file=data["RQ_21_file"];
    this.RQ_22_file=data["RQ_22_file"];
    this.RQ_23_file=data["RQ_23_file"];
    this.RQ_24_file=data["RQ_24_file"];
    this.RQ_3_file=data["RQ_3_file"];
    this.RQ_32_file=data["RQ_32_file"];
    this.RQ_4_file=data["RQ_4_file"];
    this.RQ_6_file=data["RQ_6_file"];
    this.RQ_7_file=data["RQ_7_file"];
    this.RQ_5_file=data["RQ_5_file"];

    this.RQ_8_file=data["RQ_8_file"];
    this.RQ_81_file=data["RQ_81_file"];
    this.RQ_82_file=data["RQ_82_file"];

    this.MmStatus_total = data["MmStatus_total"];
    this.MmStatus = data["MmStatus"];
    this.MmStatus2_total = data["MmStatus2_total"];
    this.OmStatus_total = data["OmStatus_total"];
    this.OmStatus = data["OmStatus"];

    this.schedules2=data['Schedules2'];
    this.schedules5=data['Schedules5'];

    this.Schedules2_2=[];
    this.Schedules2_3=[];
    this.Schedules2_4=[];

    this.Schedules2_2=data['Schedules2_2'];
    this.Schedules2_3=data['Schedules2_3'];
    this.Schedules2_4=data['Schedules2_4'];

    this.guest_name_1_2_1 = data['Schedules2_2']['guest_name_1_2_1'];
    this.guest_name_2_2_1 = data['Schedules2_2']['guest_name_2_2_1'];
    this.guest_name_3_2_1 = data['Schedules2_2']['guest_name_3_2_1'];
    this.guest_name_4_2_1 = data['Schedules2_2']['guest_name_4_2_1'];
    this.guest_name_5_2_1 = data['Schedules2_2']['guest_name_5_2_1'];

    this.guest_name_1_3_1 = data['Schedules2_3']['guest_name_1_3_1'];
    this.guest_name_2_3_1 = data['Schedules2_3']['guest_name_2_3_1'];
    this.guest_name_3_3_1 = data['Schedules2_3']['guest_name_3_3_1'];
    this.guest_name_4_3_1 = data['Schedules2_3']['guest_name_4_3_1'];
    this.guest_name_5_3_1 = data['Schedules2_3']['guest_name_5_3_1'];

    this.guest_name_1_4_1 = data['Schedules2_4']['guest_name_1_4_1'];
    this.guest_name_2_4_1 = data['Schedules2_4']['guest_name_2_4_1'];
    this.guest_name_3_4_1 = data['Schedules2_4']['guest_name_3_4_1'];
    this.guest_name_4_4_1 = data['Schedules2_4']['guest_name_4_4_1'];
    this.guest_name_5_4_1 = data['Schedules2_4']['guest_name_5_4_1'];

    this.total_glines=data["total_glines"];
    this.glines=data["Glines"];
    this.fundedBy = data?.fundedBy;
    this.addActivityForm8.patchValue({
      'video_url'		: data['video_url']
    });
    if(this.total_glines>0)
    {
      this.addActivityForm6.patchValue({
        'nisp_status'		: data['Glines']['nisp_status'],
        'iepolicy_status'		: data['Glines']['iepolicy_status'],
        'ariia_status'		: data['Glines']['ariia_status']
      });

      this.addActivityForm7.patchValue({
        'ia_status'		: data['Glines']['ia_status'],
        'mentoring_support'		: data['Glines']['mentoring_support'],
        'incubation_status'		: data['Glines']['incubation_status']
      });

    }

  }

  setDataTable(data){
    this.activity=[];
    for(let i=0;i<data['Activities'].length;i++){
      this.activity.push(data['Activities'][i])
    }
  }


onEditClick(id,activity){
  var data: any[]=[]
  data['id']=activity;
  data['activity_id']=id;
  this.openDialog1(data);
}
onDeleteClick(activityid){

    // data['activityid']=activityid;
this.activityid=activityid
    const p={
      activityid:activityid
    }
    this.activityService.deleteSchedule(p).subscribe(data=>this.handleresponse(data));
}
handleresponse(data){
  if(data['message']=='Successfully Deleted')
    {this.toastr.success("Successfully Deleted");
    this.getScheduleTable();}
  else
    this.toastr.error("Not deleted");
}


openDialog1(data){

  if(data.id==1){

    const dialogRef = this.dialog.open(ActivityForm1Component, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();
    });
  }

  if(data.id==2){

    const dialogRef = this.dialog.open(ActivityForm2Component, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();

   });
  }
  if(data.id==3){

    const dialogRef = this.dialog.open(ActivityForm3Component, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();

    });
  }
  if(data.id==4){

    const dialogRef = this.dialog.open(ActivityForm4Component, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();

    });
  }
  if(data.id==5){
    const dialogRef = this.dialog.open(ActivityForm5Component, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDataTable();
      this.getScheduleTable();
      this.ActivityStatus();
    });
  }

}

openReportDialog(data){
      const dialogRef = this.dialog.open(ActivityReportForm1Component, {
        data: data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getScheduleTable();
      });
  }

  openDialog(data){

if(data==1){

  const dialogRef = this.dialog.open(ActivityForm1Component, {
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

  });
}

if(data==2){

  const dialogRef = this.dialog.open(ActivityForm2Component, {
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

 });
}
if(data==3){

  const dialogRef = this.dialog.open(ActivityForm3Component, {
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

  });
}
if(data==4){
  console.log('fdhfd-  '+data);
  const dialogRef = this.dialog.open(ActivityForm4Component, {
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

  });
}
if(data==5){

  const dialogRef = this.dialog.open(ActivityForm5Component, {
    data: data,
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

  });
}

    // const dialogRef = this.dialog.open(ActivityProposalDialogComponent, {
    //   data: data,
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   this.getDataTable();
    // });
  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }


onSubmit6(data){

  data['activity']="6";
  this.activityService.submitMenteeGuidance(data).subscribe(
    data => this.handleResponse(data)
  );
}

 onSubmit7(data){
    data['activity']="7";
    this.activityService.submitMenteeGuidance(data).subscribe(
      data => this.handleResponse(data)
    );
}

onSubmit8(data){

  var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;
  var cvFile1 = this.ip_copy && this.ip_copy.length ? this.ip_copy[0] : null;
  var cvFile2 = this.all_report && this.all_report.length ? this.all_report[0] : null;
    if (cvFile!= undefined || cvFile!= null || this.RQ_8_file || this.fundedBy == 'Volunteer')
    {

    }
    else
    {
      this.toastr.error("Please upload the Statement of Expenditure.");
      return false;
    }

    if (cvFile1!= undefined || cvFile1!= null || this.RQ_81_file || this.fundedBy == 'Volunteer')
    {

    }
    else
    {
      this.toastr.error("Please upload the Utilization Certificate.");
      return false;
    }
    
    if (cvFile2!= undefined || cvFile2!= null || this.RQ_82_file)
    {

    }
    else
    {
      this.toastr.error("Please upload the Overall Report.");
      return false;
    }

    var formData = new FormData();

    formData.append('file', cvFile);
    formData.append('file2', cvFile1);
    formData.append('file3', cvFile2);
    formData.append('video_url', data['video_url']);
    formData.append('schedule_id', '8');
    this.activityService.postScheduleReport(formData).subscribe((data) => this.handleResponse8(data));



}

handleResponse(data){
  if(data['status']=="1"){
    this.toastr.success(data['msg']);
  }
  else
  {
    this.toastr.error(data['msg']);
  }

}

handleResponse8(data){
  if(data['status']=="1"){
    this.toastr.success(data['message']);
    this.getScheduleTable();
  }
  else
  {
    this.toastr.error(data['message']);
  }

}

  
  onActivityStatus(data,activity_id,session){
    if(session == 1){
      session = 'Session 1';
    }else if(session == 2){
      session = 'Session 2';
    }else if(session == 3){
      session = 'Session 3';
    }else{
      session = 1;
    }
    this.activityService.getActionByMentee(data,activity_id,session).subscribe(
      data => this.handleResponseActionCheck(data)
    );
  }

  handleResponseActionCheck (data){
    this.OmStatus = data;
  }

}


