import { Component, OnInit, Inject, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { InstituteStudent } from './institute-student';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-institute-student',
  templateUrl: './institute-student.component.html',
  styleUrls: ['./institute-student.component.css'],
})
export class InstituteStudentComponent implements OnInit {
  // * list of students
  students: InstituteStudent[] = [];
  ForWhom:any;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  readMore: boolean = true;

  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;

  studentStatus:any;
  studentRoles:any;
  checkForConvenor:any;

  filterData;
  isDisplay:any = 1;

  constructor(
    public dialog: MatDialog,
    private instituteMyCouncilService: InstituteMyCouncilService,private toastr :ToastrService
  ) { }

  ngOnInit(): void {
    this.ForWhom=localStorage.getItem('ForWhom');
    this.getStudents(this.currentPageNo);

    this.studentStatus = localStorage.getItem('StudentRolesStatus');
    this.studentRoles = localStorage.getItem('StudentRolesIncomplete');
  }

  nextPage() {
    this.currentPageNo++;
    this.getStudents(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getStudents(this.currentPageNo);
  }

  search(event) {
    if (this.filterData) {
      this.instituteMyCouncilService.searchStudent(this.filterData).subscribe(
        data => this.handleResponse(data)
      );
    } else {
      this.getStudents(this.currentPageNo);
    }
  }

  getStudents(pageNo) {
    this.instituteMyCouncilService
      .getStudents(this.per_page,pageNo)
      .subscribe((data) => this.handleResponse(data));
  }
  getStudents1() {
    this.instituteMyCouncilService
      .getStudents(this.per_page,this.currentPageNo)
      .subscribe((data) => this.handleResponse(data));
  }

  nextPage1(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1);
	  } else {
		this.currentPageNo = (e.pageIndex+1);
	  }
	this.per_page = e.pageSize;
    this.getStudents1();
  }

  handleResponse(data) {
    this.students = [];
    this.checkForConvenor = data?.checkForConvenor;
    data['data'].forEach((element) => {
      this.students.push(element);
    });
    this.total_pages = data.meta.pagination.total;

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteStudentDialog, {});

    dialogRef.afterClosed().subscribe((result) => {
      this.getStudents(this.currentPageNo);
    });
  }

  openPresidentDialog() {
    const dialogRef = this.dialog.open(InstituteStudentPresidentDialog, {});

    dialogRef.afterClosed().subscribe((result) => {
      this.handleResponseStudentPresident();
    });
  }

  handleResponseStudentPresident(){

  }

  openEditDialog(data) {
    const dialogRef = this.dialog.open(InstituteStudentDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getStudents(this.currentPageNo);
    });
  }

  deleteDialog(data) {
    const dialogRef = this.dialog.open(InstituteDeleteDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getStudents(this.currentPageNo);
    });
  }


  deleteInstituteDetails() {
    alert('Are you sure you want to delete Member Details');
  }

  addPresident (val){
    this.instituteMyCouncilService.addPresidentStudent(val).subscribe(
      data => this.handleResponseAddPresident(data)
    );
  }

  handleResponseAddPresident(data){
    if(data['status']){
      this.getStudents(this.currentPageNo);
      this.toastr.success(data['msg']);
    }else{
      this.toastr.error(data['msg']);
    }
  } 
  
  removePresident (val){
    this.instituteMyCouncilService.removePresidentStudent(val).subscribe(
      data => this.handleResponseRemovePresident(data)
    );
  }

  handleResponseRemovePresident(data){
    if(data['status']){
      this.getStudents(this.currentPageNo);
      this.toastr.success(data['msg']);
    }else{
      this.toastr.error(data['msg']);
    }
  } 

}


// ! student dialog
@Component({
  selector: 'app-institute-student-dialog',
  templateUrl: 'institute-student-dialog.html',
})
export class InstituteStudentDialog implements OnInit {
  studentForm: FormGroup;

  // Role = ['Innovation Coordinator', 'Startup Coordinator', 'Internship Coordinator', 'Social Media Coordinator', 'IPR Coordinator', 'Members'];

  currentAssociationWith = [];
  show1: boolean;
  show: boolean;
  fbl: any;
  name1: any;
  email1: any;
  mobile1: any;
  stream1: any;
  semester1: any;
  id1: any;
  is_member1: any;
  year1: any;
  role1: any;
  dicipline: any;
  roleModel = [];
  Role: any;
  RoleStatus: any;
  showRole: boolean;
  showMember: boolean;
  gender: any;

  constructor(
    private formBuilder: FormBuilder,
    private instituteMyCouncilService: InstituteMyCouncilService,
    public dialogRef: MatDialogRef<InstituteStudentDialog>,
    private toastr: ToastrService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
    }
  }

  ngOnInit() {
    this.studentForm = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required])],
      email: [
        null,
        Validators.compose([Validators.required, Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      ],
      mobile: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
      stream: [null, Validators.compose([Validators.required])],
      year: [null, Validators.compose([Validators.required])],
      semester: [null, Validators.compose([Validators.required])],
      dicipline: [null, Validators.compose([Validators.required])],
      is_member: [],
      role: [null, Validators.compose([Validators.required])],
      id: [null],
      gender: [null, Validators.compose([Validators.required])]
    });
this.MakeVisibleDropdown();

    this.instituteMyCouncilService.getCurrentAssociation().subscribe(
      (data) => this.setAssociation(data)
    );

    if (this.data) {
      this.instituteMyCouncilService.getRoleStudent(this.data['id']).subscribe(
        (data) => this.handleResponse5(data)
      );
    } else {
      this.instituteMyCouncilService.getRoles().subscribe(
        (data) => this.handleResponse5(data)
      );
    }

    this.fbl = this.data;
    this.name1 = this.data['name'];
    this.email1 = this.data['email'];
    this.mobile1 = this.data['mobile'];
    this.stream1 = this.data['stream'];
    this.year1 = this.data['year'];
    this.semester1 = this.data['semester'];
    this.id1 = this.data['id'];
    this.is_member1 = this.data['is_member'];
    this.role1 = this.data['role'];
    this.dicipline = this.data['dicipline'];
    this.gender = this.data['gender'];

    this.studentForm.controls['name'].setValue(this.name1);
    this.studentForm.controls['email'].setValue(this.email1);
    this.studentForm.controls['mobile'].setValue(this.mobile1);
    this.studentForm.controls['stream'].setValue(this.stream1);
    this.studentForm.controls['year'].setValue(this.year1);
    this.studentForm.controls['semester'].setValue(this.semester1);
    this.studentForm.controls['is_member'].setValue(this.is_member1);
    this.studentForm.controls['id'].setValue(this.id1);
    this.studentForm.controls['role'].setValue(this.role1);
    this.studentForm.controls['dicipline'].setValue(this.dicipline);
    this.studentForm.controls['gender'].setValue(this.gender);
    this.roleModel = this.role1.split(',');


  }

  MakeVisibleDropdown(){
    this.instituteMyCouncilService.MakeVisibleDropdown().subscribe(
      data => this.setVisibledropdown(data)
    );
  }
  setVisibledropdown(data){
    this.RoleStatus=data.status;
    if(this.RoleStatus==1){
      this.showMember=true;
      // const is_member = this.studentForm.get('is_member');
      // is_member.setValidators([Validators.required]);
      // is_member.updateValueAndValidity();
    }
    // else{
      const is_member = this.studentForm.get('is_member');
      is_member.setValidators([null]);
      is_member.updateValueAndValidity();
    // }

  }
  handleResponse5(data) {
    this.Role = data['rolesStudent'];
  }


  setAssociation(data) {
    data['data'].forEach(element => {
      this.currentAssociationWith.push(element);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) {
    if (this.studentForm.valid) {
      let student = new InstituteStudent();

      if (values['role'].length <= 2) {
        if (this.show1 == true) {



          student.name = values['name'];
          student.email = values['email'];
          student.mobile = values['mobile'];
          student.stream = values['stream'];
          student.year = values['year'];
          student.semester = values['semester'];
          student.dicipline = values['dicipline'];
          student.role = values['role'];
          student.is_member = values['is_member'];
          student.gender = values['gender'];
          this.instituteMyCouncilService.addStudent(values).subscribe(
            data => this.handleResponse(data)
          );
        }
        else {


          student.name = values['name'];
          student.email = values['email'];
          student.mobile = values['mobile'];
          student.stream = values['stream'];
          student.year = values['year'];
          student.semester = values['semester'];
          student.dicipline = values['dicipline'];
          student.role = values['role'];
          student.is_member = values['is_member'];
          student.gender = values['gender'];
          this.instituteMyCouncilService.updateStudent(values).subscribe(
            data => this.handleResponse1(data)
          );
        }
      } else {
        this.toastr.error("Maximum 2 roles can be selected !")
      }

    }
  }

  handleResponse1(data) {
    if (data['status'] == "1") {
      this.toastr.success("Details Updated Successfully !");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
    this.instituteMyCouncilService.dataUpdated.next(true);
  }


  handleResponse(data) {
    if (data['status'] == "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
    this.instituteMyCouncilService.dataUpdated.next(true);
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
}
// ! student President dialog
@Component({
  selector: 'app-institute-student-president-dialog',
  templateUrl: 'institute-student-president-dialog.html',
})
export class InstituteStudentPresidentDialog implements OnInit {
  studentForm: FormGroup;

  // Role = ['Innovation Coordinator', 'Startup Coordinator', 'Internship Coordinator', 'Social Media Coordinator', 'IPR Coordinator', 'Members'];

  currentAssociationWith = [];
  show1: boolean;
  show: boolean;
  fbl: any;
  name1: any;
  email1: any;
  mobile1: any;
  stream1: any;
  semester1: any;
  id1: any;
  is_member1: any;
  year1: any;
  role1: any;
  dicipline: any;
  roleModel = [];
  Role: any;
  RoleStatus: any;
  showRole: boolean;
  showMember: boolean;
  gender: any;

  constructor(
    private formBuilder: FormBuilder,
    private instituteMyCouncilService: InstituteMyCouncilService,
    public dialogRef: MatDialogRef<InstituteStudentPresidentDialog>,
    private toastr: ToastrService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
    }
  }

  ngOnInit() {
    this.studentForm = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required])],
      email: [
        null,
        Validators.compose([Validators.required, Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      ],
      mobile: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
      stream: [null, Validators.compose([Validators.required])],
      year: [null, Validators.compose([Validators.required])],
      semester: [null, Validators.compose([Validators.required])],
      dicipline: [null, Validators.compose([Validators.required])],
      is_member: [],
      // role: [null, Validators.compose([Validators.required])],
      id: [null],
      gender: [null, Validators.compose([Validators.required])]
    });
    this.MakeVisibleDropdown();

    this.instituteMyCouncilService.getCurrentAssociation().subscribe(
      (data) => this.setAssociation(data)
    );

    if (this.data) {
      this.instituteMyCouncilService.getRoleStudent(this.data['id']).subscribe(
        (data) => this.handleResponse5(data)
      );
    } else {
      this.instituteMyCouncilService.getRoles().subscribe(
        (data) => this.handleResponse5(data)
      );
    }

    // this.fbl = this.data;
    // this.name1 = this.data['name'];
    // this.email1 = this.data['email'];
    // this.mobile1 = this.data['mobile'];
    // this.stream1 = this.data['stream'];
    // this.year1 = this.data['year'];
    // this.semester1 = this.data['semester'];
    // this.id1 = this.data['id'];
    // this.is_member1 = this.data['is_member'];
    // this.role1 = this.data['role'];
    // this.dicipline = this.data['dicipline'];
    // this.gender = this.data['gender'];

    // this.studentForm.controls['name'].setValue(this.name1);
    // this.studentForm.controls['email'].setValue(this.email1);
    // this.studentForm.controls['mobile'].setValue(this.mobile1);
    // this.studentForm.controls['stream'].setValue(this.stream1);
    // this.studentForm.controls['year'].setValue(this.year1);
    // this.studentForm.controls['semester'].setValue(this.semester1);
    // this.studentForm.controls['is_member'].setValue(this.is_member1);
    // this.studentForm.controls['id'].setValue(this.id1);
    // this.studentForm.controls['role'].setValue(this.role1);
    // this.studentForm.controls['dicipline'].setValue(this.dicipline);
    // this.studentForm.controls['gender'].setValue(this.gender);
    // this.roleModel = this.role1.split(',');


  }

  MakeVisibleDropdown(){
    this.instituteMyCouncilService.MakeVisibleDropdown().subscribe(
      data => this.setVisibledropdown(data)
    );
  }
  setVisibledropdown(data){
    this.RoleStatus=data.status;
    if(this.RoleStatus==1){
      this.showMember=true;
      // const is_member = this.studentForm.get('is_member');
      // is_member.setValidators([Validators.required]);
      // is_member.updateValueAndValidity();
    }
    // else{
      const is_member = this.studentForm.get('is_member');
      is_member.setValidators([null]);
      is_member.updateValueAndValidity();
    // }

  }
  handleResponse5(data) {
    this.Role = data['rolesStudent'];
  }


  setAssociation(data) {
    data['data'].forEach(element => {
      this.currentAssociationWith.push(element);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



  onSubmitPresident(values) {
    if (this.studentForm.valid) {
      let student = new InstituteStudent();
      student.name = values['name'];
      student.email = values['email'];
      student.mobile = values['mobile'];
      student.stream = values['stream'];
      student.year = values['year'];
      student.semester = values['semester'];
      student.dicipline = values['dicipline'];
      student.role = "President";
      student.is_member = values['is_member'];
      student.gender = values['gender'];
      this.instituteMyCouncilService.submitStudentPresident(student).subscribe(
        data => this.handleResponse(data)
      );
    }
  }

  handleResponse1(data) {
    if (data['status'] == "1") {
      this.toastr.success("Details Updated Successfully !");
      this.dialogRef.close();
      this.instituteMyCouncilService.dataUpdated.next(true);
    } else {
      this.toastr.error(data['msg']);
    }
    
  }


  handleResponse(data) {
    if (data['status'] == "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
      this.instituteMyCouncilService.dataUpdated.next(true);
    } else {
      this.toastr.error(data['msg']);
    }
    
  }
  handleResponsePresident(data) {
    if (data['status'] == "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
      this.instituteMyCouncilService.dataUpdated.next(true);
    } else {
      this.toastr.error(data['msg']);
    }
    
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
}


///delete dialog
@Component({
  selector: 'app-institute-delete-dialog',
  templateUrl: './institute-delete-dialog.html',
  // styleUrls: ['./institute-declare-semesters.component.css']
})

export class InstituteDeleteDialogComponent implements OnInit {
  id: any;

  constructor(public dialog: MatDialog,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteStudentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.id = this.data['id'];
    // this.getDeclareSemesters(this.currentPageNo);
  }

  deleteDialog() {
    this.myCouncilService.deleteStudents(this.id).subscribe(
      data => this.handleData(data)
    );
  }

  handleData(data) {
    if (data['status'] = "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Not able to delete !');
    }
    this.myCouncilService.dataUpdated.next(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
