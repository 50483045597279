<app-iic-web-navbar></app-iic-web-navbar>
<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Regional Meet Video Gallery 2023</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section> 

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<!-- <li class="breadcrumb-item"><a>Gallery</a></li> -->
	<!-- <li class="breadcrumb-item"><a href="gallery/video-gallery">Video Gallery 2023</a></li> -->
	<li class="breadcrumb-item"><a href="./gallery/photo-gallery">Gallery</a></li>
	<li class="breadcrumb-item"><a>Regional Meet Video Gallery 2023</a></li>
  </ul>
</nav>

<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Regional Meet Video Gallery 2023</h2>
				<div>
					<a class="backButton" href="/gallery">Back to Gallery</a>
				</div>
			</div>
		</div>
		</div>	  
	</div>	
 </div>

<div class="container">
		<div class="row">
			<div class="gallery col-md-12">
				<div class="row pt-4">
					<div class="col-md-4 mb-3">
						<!-- <div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/BNnkbRBSmPE" title="Institution’s Innovation Council Regional Meet, Chandigarh | 26 July, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2023 | <span>Chandigarh</span></h4>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div> 
<app-iic-web-footer></app-iic-web-footer>
