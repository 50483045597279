<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC Annual Performance 2020-21</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>At a Glance</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Annual Performance 2020-21</li>
  </ul>
</nav>

<div class="container">
  <div class="row">
    <div class="col-sm-4"><a href="assets/doc/IIC-Annual-Performance-2020-21- Zone-Category-Wise.pdf" class="annoulLinkURL" target="_blank">IIC Annual Performance Snapshot</a></div>
    <div class="col-sm-4"><a href="assets/doc/IIC Annual Performance 2020-21-University.pdf" class="annoulLinkURL" target="_blank">IIC Annual Performance - University</a></div>
    <div class="col-sm-4"><a href="assets/doc/IIC Annual Performance 2020-21-College-and-standalone.pdf" class="annoulLinkURL" target="_blank">IIC Annual Performance - College & Standalone</a></div>
  </div>
</div>
<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>Categories</h2>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <section class="rating2021Box">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div style="position: relative;">
					<div class="banner-img5">
						<a routerLink="/iic-ratings2021-details/overall" style="margin: 6px 0 0 85px"><img src="assets/assets/images/overallIcon.png" alt="Overall"></a>
						<a routerLink="/iic-ratings2021-details/university" style="margin: 7px 0 0 371px"><img src="assets/assets/images/universityRating.png" alt="University"></a>
						<a routerLink="/iic-ratings2021-details/college" style="margin: 7px 0 0 659px"><img src="assets/assets/images/collegeRatingIcon.png" alt="College"></a>
						<a routerLink="/iic-ratings2021-details/standalone" style="margin: 8px 0 0 962px"><img src="assets/assets/images/standAloneRatingIcon.png" alt="Standalone"></a>
					</div>
					<div class="img-text5" style="position: relative;">
						<span>Overall </span>
						<span>University </span>
						<span>College</span>
						<span>Standalone</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> -->



<section class="rating2021Box">
	<div class="container">
	<div class="banner-img5">
		<mat-tab-group mat-align-tabs="center" class="ratingsTabMain">
		  <mat-tab class="matTabOverall">
			<ng-template mat-tab-label class="bannerIMG">
				<img src="assets/assets/images/overallIcon.png" alt="Overall" style="margin: -17px 0 0 365px">
				<div class="img-text5" style="position: relative;"><span>Overall</span></div>
			</ng-template>
			<app-iic-ratings2021-details></app-iic-ratings2021-details>
		  </mat-tab>

		  <mat-tab>
			<ng-template mat-tab-label class="bannerIMG">
				<img src="assets/assets/images/universityRating.png" alt="University" style="margin: -17px 0 0 409px; width: 70%;">
				<div class="img-text52" style="position: relative;"><span>University</span></div>
			</ng-template>
			<app-iic-ratings2021-details1></app-iic-ratings2021-details1>
		  </mat-tab>

		  <mat-tab>
			<ng-template mat-tab-label class="bannerIMG">
				<img src="assets/assets/images/collegeRatingIcon.png" alt="College" style="margin: -17px 0 0 365px; width: 70%;">
				<div class="img-text53" style="position: relative;"><span>College</span></div>
			</ng-template>
			<app-iic-ratings2021-details2></app-iic-ratings2021-details2>
		  </mat-tab>

		  <mat-tab>
			<ng-template mat-tab-label class="bannerIMG">
				<img src="assets/assets/images/standAloneRatingIcon.png" alt="Standalone" style="margin: -17px 0 0 354px; width: 70%;">
				<div class="img-text54" style="position: relative;"><span>Standalone</span></div>
			</ng-template>
			<app-iic-ratings2021-details3></app-iic-ratings2021-details3>
		  </mat-tab>
		</mat-tab-group>
	</div>
  </div>
</section>





<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
