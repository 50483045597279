<div class="container">
	<h1 class="announcementHeadingMain">MIC Driven Activity</h1>

  <form [formGroup]="iicImpactQuestions" *ngIf="rid=='M21-221574'">
    <table class="table table-hover">
      <tr>
        <td colspan="4"><h3>Inaugural Session - 11 January 2022</h3></td>
      </tr>
      <tr>
        <th>Time</th>
        <th>Session</th>
        <th>Acceptance</th>
        <th>Report</th>
      </tr>
      <tr>
        <td>10:30 AM - 10:35 AM</td>
        <td>Welcome Address by Chairman, AICTE</td>
        <td rowspan="12" style="vertical-align: top;">
          <p>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Consent</mat-label>
              <mat-select autocomplete="off" placeholder="" formControlName="session_1" (selectionChange)="onAccept($event,'1')">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </p>
        </td>
        <td rowspan="12" style="vertical-align: top;">

          <p *ngIf="student_1!=0">
            Participants: {{student_1}}
          </p>

          <p *ngIf="collage_1!=''">
            <a href="https://api.mic.gov.in{{collage_1}}" target="_blank">View Photo</a>
          </p>

          <p *ngIf="concent_1==1">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('1')">
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
          <p *ngIf="concent_1==0">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
        </td>

      </tr>
      <tr>
        <td>10:35 AM - 10:40 AM</td>
        <td>AM	Address by Secretary (HE), MoE</td>
      </tr>
      <tr>
        <td>10:40 AM - 10:45 AM</td>
        <td>Address by Secretary DPIIT, Mo C& I</td>
      </tr>
      <tr>
        <td>10:45 AM - 10:50 AM</td>
        <td>Address by Secretary (School) MoE</td>
      </tr>
      <tr>
        <td>10.50 AM - 10.55 AM</td>
        <td>Launch of Movie on “Journey of Hackathon”</td>
      </tr>
      <tr>
        <td>10.55 AM - 10.58 AM</td>
        <td>Release of National Innovation and Startup Policy for Schools</td>
      </tr>
      <tr>
        <td>10.58 AM - 11.15 PM</td>
        <td>Felicitation of Winners of MANTHAN Hackathon</td>
      </tr>
      <tr>
        <td>11.15 PM - 11.40 PM</td>
        <td>Distribution of Funding/Mentoring Support Letters 75 Innovative Technologies with Start-up Potential</td>
      </tr>
      <tr>
        <td>11.40 PM - 11.50 PM</td>
        <td>Announcing partnerships between Toycathon Winners & Toy Industry</td>
      </tr>
      <tr>
        <td>11.50 PM - 12.05 PM</td>
        <td>Address by “Guest of Honor” Hon'ble Shiksha Mantri, MoE</td>
      </tr>
      <tr>
        <td>12.05 PM - 12.20 PM</td>
        <td>Address by “Chief Guest” Hon'ble Minister, Ministry of Commerce and Industries</td>
      </tr>
      <tr>
        <td>12.20 PM - 12.23 PM</td>
        <td>Vote of Thanks by CIO, MoE's Innovation Cell</td>
      </tr>

      <tr>
        <td colspan="4"><h3>Keynote Address I - 11 January 2022</h3></td>
      </tr>
      <tr>
        <td>01:30 PM - 02:00 PM<br/>
          (20 min talk + 10 min Q&A)</td>
        <td>(Title: TBC) Invited Key Note Address by Shri Sridhar Vembu, Founder ZOHO Corporation (Confirmed for VC)</td>
        <td style="vertical-align: top;">
          <p>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Consent</mat-label>
              <mat-select autocomplete="off" placeholder="" formControlName="session_2" (selectionChange)="onAccept($event,'2')">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </p>
        </td>
        <td style="vertical-align: top;">

          <p *ngIf="student_2!=0">
            Participants: {{student_2}}
          </p>

          <p *ngIf="collage_2!=''">
            <a href="https://api.mic.gov.in{{collage_2}}" target="_blank">View Photo</a>
          </p>


          <p *ngIf="concent_2==1">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('2')">
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
          <p *ngIf="concent_2==0">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
        </td>
      </tr>

      <tr>
        <td colspan="4"><h3>Panel Discussion I - 11 January 2022</h3></td>
      </tr>
      <tr>
        <td>02:00 PM - 03:00 PM<br/>
          (60 minutes)
          </td>
        <td>
          <p>Innovation, Human Values, & Sustainability</p>

          <ul>
            <li>•	Shri Sonam Wangchuk, Social Engineer & Innovator (TBC)</li>
            <li>•	Shri Vikash Mittersain, Founder & Managing Director, Nazara Technologies (Confirmed)</li>
            <li>•	Shri Shrikant Badve, Managing Director, Badve Industries (Confirmed)</li>
          </ul>

          <p><strong>Moderator:</strong> Prof. Anil D Sahasrabudhe, Chairman, AICTE (Confirmed)</p>
        </td>
        <td style="vertical-align: top;">
          <p>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Consent</mat-label>
              <mat-select autocomplete="off" placeholder="" formControlName="session_3" (selectionChange)="onAccept($event,'3')">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </p>
        </td>
        <td style="vertical-align: top;">

          <p *ngIf="student_3!=0">
            Participants: {{student_3}}
          </p>

          <p *ngIf="collage_3!=''">
            <a href="https://api.mic.gov.in{{collage_3}}" target="_blank">View Photo</a>
          </p>

          <p *ngIf="concent_3==1">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('3')">
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
          <p *ngIf="concent_3==0">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
        </td>
      </tr>


      <tr>
        <td colspan="4"><h3>Keynote Address II - 11 January 2022</h3></td>
      </tr>
      <tr>
        <td>03:30 PM - 04:00 PM<br/>
          (20 min talk + 10 min Q&A)</td>
        <td>
          (Title: TBC) Invited Key Note Address by Shri. Gautam, Founder, FarEye Pvt. Ltd (Confirmed)
        </td>
        <td style="vertical-align: top;">
          <p>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Consent</mat-label>
              <mat-select autocomplete="off" placeholder="" formControlName="session_4" (selectionChange)="onAccept($event,'4')">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </p>
        </td>
        <td style="vertical-align: top;">

          <p *ngIf="student_4!=0">
            Participants: {{student_4}}
          </p>

          <p *ngIf="collage_4!=''">
            <a href="https://api.mic.gov.in{{collage_4}}" target="_blank">View Photo</a>
          </p>


          <p *ngIf="concent_4==1">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('4')">
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
          <p *ngIf="concent_4==0">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
        </td>
      </tr>

      <tr>
        <td colspan="4"><h3>Panel Discussion II - 11 January 2022</h3></td>
      </tr>
      <tr>
        <td>04:00 PM - 05.00 PM<br/>
          (60 minutes)
          </td>
        <td>
          <p>Best Innovation and Start-up Practices in HEIs </p>

          <ul>
            <li>•	Prof. Abhay Karandikar, Director, IIT Kanpur (Confirmed)</li>
            <li>•	Prof. AB Pandit, Vice Chancellor, ICT Mumbai (Confirmed)</li>
            <li>•	Shri Sameer Garde, Chief Executive Officer, Wadhwani AI (Confirmed)</li>
            <li>•	Shri Rajendra Ratnoo, Joint Secretary, DPIIT (Confirmed)</li>
          </ul>

          <p>Moderator: Dr. K. Radhakrishnan, Ex- Chairman, ISRO (Confirmed)</p>

        </td>
        <td style="vertical-align: top;">
          <p>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Consent</mat-label>
              <mat-select autocomplete="off" placeholder="" formControlName="session_5" (selectionChange)="onAccept($event,'5')">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </p>
        </td>
        <td style="vertical-align: top;">

          <p *ngIf="student_5!=0">
            Participants: {{student_5}}
          </p>

          <p *ngIf="collage_5!=''">
            <a href="https://api.mic.gov.in{{collage_5}}" target="_blank">View Photo</a>
          </p>


          <p *ngIf="concent_5==1">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('5')">
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
          <p *ngIf="concent_5==0">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
            <mat-icon>add</mat-icon> Upload Report
          </button>
          </p>
        </td>
      </tr>

      <tr *ngIf="submission_status==0">
        <td colspan="4">
          <p *ngIf="concent_1==1 || concent_2==1 || concent_3==1 || concent_4==1 || concent_5==1">
            <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="submitReportFinal()">
            <mat-icon>add</mat-icon> Submit Report
          </button>
          </p>
        </td>
      </tr>

    </table>
    </form>


    <form [formGroup]="iicImpactQuestions" *ngIf="rid=='M21-221573'">
      <table class="table table-hover">
        <tr>
          <td colspan="4"><h3>Keynote Address-IV - 12 January 2022</h3></td>
        </tr>
        <tr>
          <th>Time</th>
          <th>Session</th>
          <th>Acceptance</th>
          <th>Report</th>
        </tr>
        <tr>
          <td>10:00 AM - 10:30 AM<br>(20 min talk + 10 min Q&A)</td>
          <td>(Title: TBC)  Address by Prof. K VijayRaghavan, PSA, Govt. of India (Confirmed for VC)</td>
          <td style="vertical-align: top;">
            <p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Consent</mat-label>
                <mat-select autocomplete="off" placeholder="" formControlName="session_1" (selectionChange)="onAccept($event,'1')">
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </td>
          <td style="vertical-align: top;">

            <p *ngIf="student_1!=0">
              Participants: {{student_1}}
            </p>

            <p *ngIf="collage_1!=''">
              <a href="https://api.mic.gov.in{{collage_1}}" target="_blank">View Photo</a>
            </p>

            <p *ngIf="concent_1==1">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('1')">
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
            <p *ngIf="concent_1==0">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
          </td>

        </tr>

      <tr>
          <td>10.30 AM - 11.30 AM<br>
  (60 minutes)
  </td>
          <td><p><strong>Panel Discussion III: Grey Hairs not mandatory to build great enterprises</strong><br>
            •	Shri Yashraj Bhardwaj,Young Innovator (Confirmed)<br>
            •	Early Stage Startup Founder 1 - from NIC Finalist (TBD)<br>
            •	Early Stage Startup Founder 1 - from NIC Finalist (TBD)

            <br>
            <strong>Moderator:</strong> Ms. Shradha Sharma, Founder, Your Story (TBC) </p></td>
          <td style="vertical-align: top;">
            <p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Consent</mat-label>
                <mat-select autocomplete="off" placeholder="" formControlName="session_2" (selectionChange)="onAccept($event,'2')">
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </td>
          <td style="vertical-align: top;">

            <p *ngIf="student_2!=0">
              Participants: {{student_2}}
            </p>

            <p *ngIf="collage_2!=''">
              <a href="https://api.mic.gov.in{{collage_2}}" target="_blank">View Photo</a>
            </p>

            <p *ngIf="concent_2==1">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('2')">
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
            <p *ngIf="concent_2==0">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
          </td>

        </tr>


        <tr>
          <td colspan="4"><h3><strong>Keynote  Address-V</strong> - 12 January 2022</h3></td>
        </tr>
        <tr>
          <td><p>11:45  AM - 12:15 PM<br>
          (20 min talk + 10 min Q&amp;A)</p></td>
          <td>(Title: TBC) Invited Key Note Address by Shri Harshil Mathur, Founder,  Razor Pay (TBC)</td>
          <td style="vertical-align: top;">
            <p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Consent</mat-label>
                <mat-select autocomplete="off" placeholder="" formControlName="session_3" (selectionChange)="onAccept($event,'3')">
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </td>
          <td style="vertical-align: top;">

            <p *ngIf="student_3!=0">
              Participants: {{student_3}}
            </p>

            <p *ngIf="collage_3!=''">
              <a href="https://api.mic.gov.in{{collage_3}}" target="_blank">View Photo</a>
            </p>


            <p *ngIf="concent_3==1">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('3')">
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
            <p *ngIf="concent_3==0">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
          </td>
        </tr>

      <tr>
          <td><p>02:00 PM - 03:00 PM<br/>
  (60 minutes) </p></td>
          <td><p><strong>Panel Discussion- IV</strong>: <strong>Attracting BigInvestment for Innovativeideas  from Educational Institution</strong></p>
            <ul>
              <li>Dr. Anand Deshpande,  Founder, Chairman and MD, Persistent Systems (TBC)</li>
              <li>Mrs. Sridevi  Pankajam, MD, Commonwealth Bank of Australia (Confirmed)</li>
              <li>Shri C V  Raman, Chief Technology Officer, Maruti Suzuki India Limited. (Confirmed)</li>
            </ul>
            <p><strong>Moderator: </strong>Shri B V R Mohan  Reddy, Executive Chairman, Cyient (Confirmed)</p></td>
          <td style="vertical-align: top;">
            <p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Consent</mat-label>
                <mat-select autocomplete="off" placeholder="" formControlName="session_4" (selectionChange)="onAccept($event,'4')">
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </td>
          <td style="vertical-align: top;">

            <p *ngIf="student_4!=0">
              Participants: {{student_4}}
            </p>

            <p *ngIf="collage_4!=''">
              <a href="https://api.mic.gov.in{{collage_4}}" target="_blank">View Photo</a>
            </p>


            <p *ngIf="concent_4==1">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('4')">
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
            <p *ngIf="concent_4==0">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
          </td>
        </tr>

        <!-- <tr>
          <td colspan="4"><h3><strong>Keynote  Address-VI - </strong> 12 January 2022</h3></td>
        </tr>
        <tr>
          <td><p>02:00  PM - 02:30 PM<strong><br>
          </strong>(20 min talk + 10 min Q&amp;A)</p></td>
          <td>
            <p>(Title: TBC) Invited Key Note Address by Smt. Arundhati Bhattacharya, Chairperson &amp; CEO,  Salesforce (Confirmed for VC)</p>
          </td>
          <td style="vertical-align: top;">
            <p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Consent</mat-label>
                <mat-select autocomplete="off" placeholder="" formControlName="session_5" (selectionChange)="onAccept($event,'5')">
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </td>
          <td style="vertical-align: top;">

            <p *ngIf="student_5!=0">
              Participants: {{student_5}}
            </p>

            <p *ngIf="collage_5!=''">
              <a href="https://api.mic.gov.in{{collage_5}}" target="_blank">View Photo</a>
            </p>


            <p *ngIf="concent_5==1">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('5')">
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
            <p *ngIf="concent_5==0">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
          </td>
        </tr> -->

      <tr>
          <td><p>02:30  PM - 03.30PM<br>
            (60 minutes)</p></td>
          <td>
            <p><strong>Panel Discussion-V</strong>: <strong>Schools, Innovation, Game and Curriculum</strong></p>
            <ul>
              <li>Toycathon  Winner 1 (TBD) </li>
    <li>Mr. Tanmay Kumar Sethi, Class 9, DPS,Rourkela, Odisha (TBC) </li>
    <li>Ms. SulochanaKakodiya,  Class 11, M.S. GIRLS BICHHUA, Bichhua, Madhya Pradesh&nbsp;(TBC) </li>
    <li>Ayushi Singh, Class 11, Conossa&nbsp;Convent  Girls Inter college, Faizabad, UP (Confirmed) </li>
  </ul>
    <strong> Moderator: </strong>Shri  Anil Pradhan, Founder, IPSFRI&nbsp;(Confirmed)
  </td>
          <td style="vertical-align: top;">
            <p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Consent</mat-label>
                <mat-select autocomplete="off" placeholder="" formControlName="session_6" (selectionChange)="onAccept($event,'6')">
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </td>
          <td style="vertical-align: top;">

            <p *ngIf="student_6!=0">
              Participants: {{student_6}}
            </p>

            <p *ngIf="collage_6!=''">
              <a href="https://api.mic.gov.in{{collage_6}}" target="_blank">View Photo</a>
            </p>


            <p *ngIf="concent_6==1">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('6')">
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>

            <p *ngIf="concent_6==0">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
          </td>
        </tr>


        <tr>
          <td colspan="4"><h3><strong>Concluding  Ceremony</strong></h3></td>
        </tr>
        <tr>
          <td>03:45  PM - 03: 50 PM</td>
          <td>Welcome  by Prof. M P Poonia, Vice Chairman, AICTE</td>
          <td style="vertical-align: top;">
            <p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Consent</mat-label>
                <mat-select autocomplete="off" placeholder="" formControlName="session_7" (selectionChange)="onAccept($event,'7')">
                  <mat-option value="Yes">Yes</mat-option>
                  <mat-option value="No">No</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </td>
          <td style="vertical-align: top;">

            <p *ngIf="student_7!=0">
              Participants: {{student_7}}
            </p>

            <p *ngIf="collage_7!=''">
              <a href="https://api.mic.gov.in{{collage_7}}" target="_blank">View Photo</a>
            </p>


            <p *ngIf="concent_7==1">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog('7')">
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
            <p *ngIf="concent_7==0">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" disabled>
              <mat-icon>add</mat-icon> Upload Report
            </button>
            </p>
          </td>
        </tr>

        <tr *ngIf="submission_status==0">
          <td colspan="4">
            <p *ngIf="concent_1==1 || concent_2==1 || concent_3==1 || concent_4==1 || concent_5==1 || concent_6==1 || concent_7==1">
              <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="submitReportFinal()">
              <mat-icon>add</mat-icon> Submit Report
            </button>
            </p>
          </td>
        </tr>

      </table>
      </form>



</div>
<p>&nbsp;</p>
