import { Component, OnInit } from '@angular/core';
import baguetteBox from 'baguettebox.js';

@Component({
  selector: 'app-news2023',
  templateUrl: './news2023.component.html',
  styleUrls: ['./news2023.component.css']
})
export class News2023Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
	baguetteBox.run('.gallery', {
	  captions: true, // display image captions.
	  buttons: 'auto', // arrows navigation
	  fullScreen: false,
	  noScrollbars: false,
	  bodyClass: 'baguetteBox-open',
	  titleTag: false,
	  async: false,
	  preload: 2,
	  animation: 'fadeIn', // fadeIn or slideIn
	  verlayBackgroundColor: 'rgba(0,0,0,.8)'
	});
  }

}
