<section class="activityReportFormBox">
    <div class="container">
        <form [formGroup]="activityForm" (ngSubmit)="onSubmit(activityForm.value)">

            <div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <div class="portlet-title">
                        <div class="caption">
                            <span style="line-height: 1.4;"> Impact Lecture Series Statement of Expenditure</span><br/>

                        </div>
                    </div>
                </div>
            </div>


			<div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="overview">
                            <h3>Speaker Details</h3>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Total Amount ((Lecture1+Lecture2) (Including Venue Related Expenditure))(In Digit): <span class="redMendate">*</span></mat-label>
                                    <input type="number" matInput formControlName="total_amount" placeholder="Amount in Digit">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Total Amount ((Lecture1+Lecture2) (Including Venue Related Expenditure))(In Words): <span class="redMendate">*</span></mat-label>
                                    <input matInput formControlName="amount_in_words" placeholder="Amount in Words">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>


						<div class="row">
							<div class="col-12">
                                <div class="col-lg-6 col-md-6 cvDiv">
                                    <label class="uploadCV">Upload Statement of Expenditure(SOE) <span class="redMendate">*</span></label>
                                    <mat-error>doc/pdf(max 2MB)</mat-error>
                                    <p><a href="/assets/announcements/SoE-Impact-Lecture-IIC-AY2024-25.pdf" target="_blank">Sample for Statement of Expenditure</a></p>

                                </div>

                                <div class="col-lg-6 col-md-6 float-right">

                                    <label class="custom-file-label float-right" for="customFile"
                                                      *ngIf="!speaker_cv">Choose file</label>

                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="speaker_cv">{{speaker_cv[0]['name']}}</label>

                                    <input #speaker_cvRef type="file" accept="application/pdf, application/msword" name="speaker_cv"
                                        (change)="( speaker_cv = speaker_cvRef.files )"
                                        class="custom-file-input float-right" required>

                                        <p *ngIf="speaker_cv_url!=''"><a href="{{ baseUrl + speaker_cv_url}}" target="_blank">View Document</a></p>
                                </div>
                            </div>
						</div>
						<div class="row">
							<div class="col-12">
                                <div class="col-lg-6 col-md-6 cvDiv">
                                    <label class="uploadCV">Utilization Certificate (UC) <span class="redMendate">*</span></label>
                                    <mat-error>PDF(max 2MB)</mat-error>
                                    <p><a href="/assets/doc/impact-utilization-certificate.pdf" target="_blank">Sample Format</a></p>

                                </div>

                                <div class="col-lg-6 col-md-6 float-right">
                                    <label class="custom-file-label float-right" for="customFile"
                                                      *ngIf="!uc_cv">Choose file</label>

                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="uc_cv">{{uc_cv[0]['name']}}</label>
                                    <input #uc_cvRef type="file" accept="application/pdf"  name="uc_cv"
                                        (change)="( uc_cv = uc_cvRef.files )"
                                        class="custom-file-input float-right" required>
                                    <p *ngIf="uc_url!=''" style="clear:both; overflow:hidden; width:100%; padding-top:10px;"><a target="_blank" href="{{ baseUrl + uc_url}}">View File</a></p>
                                </div>
                            </div>
                        </div>
                    </mat-card>

                    <mat-dialog-actions align="end" class="reportFormActivityButton">
                      <button mat-raised-button type="submit" class="btn btn-success">Submit</button>

                    </mat-dialog-actions>

                </div>
            </div>

        </form>

      <p style="color: crimson;">Note: To View/Preview the latest uploaded file, click the Submit button</p>

    </div>
</section>



