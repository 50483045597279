<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12" >
                <table class="table table-hover" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="6" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Registered Innovation Ambassador Details </h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col"> Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Contact</th>
                            <th scope="col">Expert Area</th>
                            <th scope="col">Download</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="list?.length">
                        <tr *ngFor="let item of list| paginate: {id: 'pagination1', itemsPerPage: 5, currentPage: page }; let i = index">
                            <td>{{(i + 1)+ (5 * (page-1))}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.contact}}</td>
                            <td>{{item.themeName}}</td>
                            <td class="downloadD" *ngIf="item?.status=='0'">
                                <a href="javascript:void(0)"
                                    (click)="downloadReport(item.name)"
                                    class="btn btn-success btnDownSuccess">
                                    Download
                                </a>
                            </td>


                            <td  *ngIf="item?.status=='1'">
                              Certificate Available Soon
                            </td>


                        </tr>
                    </tbody>
                    <tbody *ngIf="!list?.length">
                        <tr style="text-align: center;">
                            <td colspan="5">No Ambassador with active account</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls id="pagination1" class="annular-pagination float-right" maxSize="3"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
            </div>

            <div class="col-md-12" >
                <table class="table table-hover" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="5" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Not Registered Innovation Ambassador Details </h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col"> Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Expert Area</th>
                            <th scope="col">Download</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="list2?.length">
                        <tr *ngFor="let item of list2| paginate: {id: 'pagination2', itemsPerPage: 5, currentPage: page2 }; let i = index">
                            <td>{{(i + 1)+ (5 * (page2-1))}}</td>
                            <td>{{item.member_name}}</td>
                            <td>{{item.name}}</td>
                            <td class="downloadD">
                                <a href="javascript:void(0)"
                                    (click)="downloadReport(item.member_name)"
                                    class="btn btn-success btnDownSuccess">
                                    Download
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!list2?.length">
                        <tr style="text-align: center;">
                            <td colspan="5">No Record Found for Not Registered Innovation Ambassador</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls id="pagination2" class="annular-pagination float-right" maxSize="3"
                    (pageChange)="onPageChanged2($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
