import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-nominate-coordinator',
  templateUrl: './nominate-coordinator.component.html',
  styleUrls: ['./nominate-coordinator.component.css']
})

export class NominateCoordinatorComponent implements OnInit {

  imageBasePath= myGlobals.imageBasePath;
  public iicCoordinatorAddition: FormGroup;

  coordinator_type:any;
  submission_type:any;
  name: any = '';
  email: any = '';
  contact: any = '';
  gender: any = '';
  city: any = '';
  show_field_2:any;
  showtype:any;
  show_field_3:any;
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField_1:any;
  requireField_2:any;
  requireField_3:any;
  requireField_4:any;
  requireField_5:any;
  requireField_6:any;
  requireField_7:any;
  requireField_8:any;
  requireField_9:any;
  requireField_10:any;
  requireField_11:any;
  requireField_12:any;
  showDivField_1:any=0;
  showDivField_2:any=0;
  showDivField_3:any=0;
  showDivField_4:any=0;
  showDivField_5:any;
  showDivField_6:any;
  programTheme:any;
  iicActivity:any;
  studentDetail:any;
  iicCoordinatorCity:any;
  rid:any;
  type:any;
  activity_id:any;
  Role: any;
  standing: any;
  poster_count: any;

  total_count:any;
  nData:any;
  type_iic_checked:any;
  type_nisp_checked:any;
  type_ariia_checked:any;
  type_ip_checked:any;

  student_param:any='';
  is_faculty_member:number=0;


  minDate = "2018-01-01";
  maxDate = "2025-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute,     private myCouncilService: InstituteMyCouncilService, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
  }

  ngOnInit(): void {

    this.type_iic_checked=false;
    this.type_nisp_checked=false;
    this.type_ariia_checked=false;
    this.type_ip_checked=false;


    this.total_count=0;
    this.poster_count=0;
    this.requireField_1=0;
    this.requireField_2=0;
    this.requireField_3=0;
    this.requireField_4=0;
    this.requireField_5=1;
    this.requireField_6=1;
    this.requireField_7=1;
    this.requireField_8=1;
    this.requireField_9=0;
    this.requireField_10=0;

    this.iicCoordinatorAddition = this.fb.group({
      'type_iic': [],
      'name_1': [],
      'email_1': [],
      'contact_1': [],
      'gender_1': [],
      'city_1': [],
      'type_nisp': [],
      'name_2': [],
      'email_2': [],
      'contact_2': [],
      'gender_2': [],
      'city_2': [],
      'type_ariia': [],
      'name_3': [],
      'email_3': [],
      'contact_3': [],
      'gender_3': [],
      'city_3': [],
      'type_ip': [],
      'name_4': [],
      'email_4': [],
      'contact_4': [],
      'gender_4': [],
      'city_4': [],
      'submission_type_1': [],
      'user_name_1': [],
      'submission_type_2': [],
      'user_name_2': [],
      'submission_type_3': [],
      'user_name_3': [],
      'submission_type_4': [],
      'user_name_4': [],
      'poster': [null, Validators.required],
    });

    this.instituteActivityService.getNominatedCoordinator().subscribe(
      (data) => this.handleResponseNM(data)
    );

    this.instituteActivityService.getIicCoordinatorCityDetails().subscribe(
      (data) => this.handleResponse1(data)
    );

    this.instituteActivityService.getIicCoordinatorList("IIC Members",'NONE','NONE').subscribe(
      (data) => this.handleResponseList(data)
    );
    this.instituteActivityService.getIicCoordinatorList("Student",'NONE','NONE').subscribe(
      (data) => this.handleResponseStudentList(data)
    );

  }

  handleResponseNM(data)
  {
    this.total_count=data['total_count'];
    this.nData=data['data'];

    if(this.total_count>0)
    {
      var type1 = false;
      if(this.nData['type_iic']){
        type1 = true;
      }
      var type2 = false;
      if(this.nData['type_nisp']){
        type2 = true;
      }
      var type3 = false;
      if(this.nData['type_ariia']){
        type3 = true;
      }
      var type4 = false;
      if(this.nData['type_ip']){
        type4 = true;
      }
      this.iicCoordinatorAddition.patchValue({
        type_iic: type1,
        name_1: this.nData['name_1'],
        email_1: this.nData['email_1'],
        contact_1: this.nData['contact_1'],
        gender_1: this.nData['gender_1'],
        city_1: this.nData['city_1'],
        submission_type_1: this.nData['submission_type_1'],
        type_nisp: type2,
        name_2: this.nData['name_2'],
        email_2: this.nData['email_2'],
        contact_2: this.nData['contact_2'],
        gender_2: this.nData['gender_2'],
        city_2: this.nData['city_2'],
        submission_type_2: this.nData['submission_type_2'],
        type_ariia: type3,
        name_3: this.nData['name_3'],
        email_3: this.nData['email_3'],
        contact_3: this.nData['contact_3'],
        gender_3: this.nData['gender_3'],
        city_3: this.nData['city_3'],
        submission_type_3: this.nData['submission_type_3'],
        type_ip: type4,
        name_4: this.nData['name_4'],
        email_4: this.nData['email_4'],
        contact_4: this.nData['contact_4'],
        gender_4: this.nData['gender_4'],
        city_4: this.nData['city_4'],
        submission_type_4: this.nData['submission_type_4'],
        poster: this.nData['poster_status'],
      });
      if(this.nData['city_1']!="null" || this.nData['city_1']!="")
      {
        this.showDivField_1 = 1;
        this.type_iic_checked=true;
      }

      if(this.nData['city_2'])
      {
        this.showDivField_2 = 1;
        this.type_nisp_checked=true;
      }

      if(this.nData['city_3'])
      {
        this.showDivField_3 = 1;
        this.type_ariia_checked=true;
      }

      if(this.nData['city_4'])
      {
        this.showDivField_4 = 1;
        this.type_ip_checked=true;
      }


    }

  }


  getRole() {
    // if (this.data) {
    //   this.myCouncilService.getRoleCoordinator(this.data['id']).subscribe(
    //     data => this.handleResponse5(data)
    //   )
    // } else {
      this.myCouncilService.getCoordinatorRoles().subscribe((data) => this.handleResponse5(data));
    //}

  }
  handleResponseForAutoPopulate(data) {
    this.programTheme = "";
  }

  handleResponse1(data) {
    this.iicCoordinatorCity = data['data'];
    // this.iicCoordinatorCity = [];
    // for (let i = 0; i < data['data'].length; i++) {
    //   if(data['data'][i]['totalMemberCount']<1)
    //   {
    //     this.iicCoordinatorCity.push(data['data'][i]);
    //   }
    // }
  }

  handleResponse5(data) {
    this.Role = data['rolesCoordinator'];
  }

  handleResponse(data) {
    if(data['totalcount'] > 0){

    }
    this.totalcount = data['totalcount'];
  }

  handleResponseList(data) {
    this.activity_id = data['name'];
    this.iicActivity = data['IIC_Members'];
    this.programTheme = data['theme'];
  }
  handleResponseStudentList(data) {
    this.studentDetail = data;
  }

  onSubmit(data) {

    var formData = new FormData();
    

    if(this.iicCoordinatorAddition.get('type_iic').value==null && this.iicCoordinatorAddition.get('type_nisp').value==null && this.iicCoordinatorAddition.get('type_ariia').value==null && this.iicCoordinatorAddition.get('type_ip').value==null && this.type_iic_checked==false && this.type_nisp_checked==false && this.type_ariia_checked==false && this.type_ip_checked==false)
    {
      this.toastr.error("Please nominate at-least one Representative for IIC Regional Meet.");
      return false;
    }
    if(this.iicCoordinatorAddition.get('type_iic').value==false && this.iicCoordinatorAddition.get('type_nisp').value==false && this.iicCoordinatorAddition.get('type_ariia').value==false && this.iicCoordinatorAddition.get('type_ip').value==false)
    {
      this.toastr.error("Please nominate at-least one Representative for IIC Regional Meet.");
      return false;
    }
    if(this.iicCoordinatorAddition.get('submission_type_1').value=="Members")
    {
      this.is_faculty_member = 1;
    }
    if(this.iicCoordinatorAddition.get('submission_type_2').value=="Members")
    {
      this.is_faculty_member = 1;
    }
    if(this.iicCoordinatorAddition.get('submission_type_3').value=="Members" )
    {
      this.is_faculty_member = 1;
    }
    if(this.iicCoordinatorAddition.get('submission_type_4').value=="Members")
    {
      this.is_faculty_member = 1;
    }
    if(this.is_faculty_member == 0)
    {
      this.toastr.error("Please nominate at-least one Faculty Member Representative for IIC Regional Meet.");
      return false;
    }
    if(this.iicCoordinatorAddition.get('type_iic').value==true)
    {
      if(this.iicCoordinatorAddition.get('submission_type_1').value==undefined)
      {
        this.toastr.error("Please fill submission of representative for Participate 1.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('name_1').value==null)
      {
        this.toastr.error("Please fill name of representative for Participate 1.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('email_1').value==null)
      {
        this.toastr.error("Please fill email of representative for Participate 1.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('contact_1').value==null)
      {
        this.toastr.error("Please fill contact of representative for Participate 1.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('gender_1').value==null)
      {
        this.toastr.error("Please fill gender of representative for Participate 1.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('city_1').value==null)
      {
        this.toastr.error("Please fill Regional Meet to Attend of representative for Participate 1.");
        return false;
      }

      formData.append('type_iic', 'Participate 1');
      formData.append('submission_type_1', this.iicCoordinatorAddition.get('submission_type_1').value);
      formData.append('name_1', this.iicCoordinatorAddition.get('name_1').value);
      formData.append('email_1', this.iicCoordinatorAddition.get('email_1').value);
      formData.append('contact_1', this.iicCoordinatorAddition.get('contact_1').value);
      formData.append('city_1', this.iicCoordinatorAddition.get('city_1').value);
      formData.append('gender_1', this.iicCoordinatorAddition.get('gender_1').value);
    }else{
      formData.append('type_iic', '');
      formData.append('submission_type_1', '');
      formData.append('name_1', '');
      formData.append('email_1', '');
      formData.append('contact_1', '');
      formData.append('city_1', '');
      formData.append('gender_1', '');
    }

    if(this.iicCoordinatorAddition.get('type_nisp').value==true)
    {
      if(this.iicCoordinatorAddition.get('submission_type_2').value==null)
      {
        this.toastr.error("Please fill submission type of representative for Participate 2.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('name_2').value==null)
      {
        this.toastr.error("Please fill name of representative for Participate 2.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('email_2').value==null)
      {
        this.toastr.error("Please fill email of representative for Participate 2.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('contact_2').value==null)
      {
        this.toastr.error("Please fill contact of representative for Participate 2.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('gender_2').value==null)
      {
        this.toastr.error("Please fill gender of representative for Participate 2.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('city_2').value==null)
      {
        this.toastr.error("Please fill Regional Meet to Attend of representative for Participate 2.");
        return false;
      }

      formData.append('type_nisp', 'Participate 2');
      formData.append('submission_type_2', this.iicCoordinatorAddition.get('submission_type_2').value);
      formData.append('name_2', this.iicCoordinatorAddition.get('name_2').value);
      formData.append('email_2', this.iicCoordinatorAddition.get('email_2').value);
      formData.append('contact_2', this.iicCoordinatorAddition.get('contact_2').value);
      formData.append('city_2', this.iicCoordinatorAddition.get('city_2').value);
      formData.append('gender_2', this.iicCoordinatorAddition.get('gender_2').value);
    }else{
      formData.append('submission_type_2', '');
      formData.append('type_nisp', '');
      formData.append('name_2', '');
      formData.append('email_2', '');
      formData.append('contact_2', '');
      formData.append('city_2', '');
      formData.append('gender_2', '');
    }

    if(this.iicCoordinatorAddition.get('type_ariia').value==true)
    {
      if(this.iicCoordinatorAddition.get('submission_type_3').value==null)
      {
        this.toastr.error("Please fill submission type of representative for Participate 3.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('name_3').value==null)
      {
        this.toastr.error("Please fill name of representative for Participate 3.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('email_3').value==null)
      {
        this.toastr.error("Please fill email of representative for Participate 3.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('contact_3').value==null)
      {
        this.toastr.error("Please fill contact of representative for Participate 3.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('gender_3').value==null)
      {
        this.toastr.error("Please fill gender of representative for Participate 3.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('city_3').value==null)
      {
        this.toastr.error("Please fill Regional Meet to Attend of representative for Participate 3.");
        return false;
      }
      formData.append('type_ariia', 'Participate 3');
      formData.append('submission_type_3', this.iicCoordinatorAddition.get('submission_type_3').value);
      formData.append('name_3', this.iicCoordinatorAddition.get('name_3').value);
      formData.append('email_3', this.iicCoordinatorAddition.get('email_3').value);
      formData.append('contact_3', this.iicCoordinatorAddition.get('contact_3').value);
      formData.append('city_3', this.iicCoordinatorAddition.get('city_3').value);
      formData.append('gender_3', this.iicCoordinatorAddition.get('gender_3').value);
    }else {
      formData.append('type_ariia', '');
      formData.append('submission_type_3', '');
      formData.append('name_3', '');
      formData.append('email_3', '');
      formData.append('contact_3', '');
      formData.append('city_3', '');
      formData.append('gender_3', '');
    }

    if(this.iicCoordinatorAddition.get('type_ip').value==true)
    {
      if(this.iicCoordinatorAddition.get('submission_type_4').value==null)
      {
        this.toastr.error("Please fill submission type of representative for Participate 4.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('name_4').value==null)
      {
        this.toastr.error("Please fill name of representative for Participate 4.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('email_4').value==null)
      {
        this.toastr.error("Please fill email of representative for Participate 4.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('contact_4').value==null)
      {
        this.toastr.error("Please fill contact of representative for Participate 4.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('gender_4').value==null)
      {
        this.toastr.error("Please fill gender of representative for Participate 4.");
        return false;
      }
      if(this.iicCoordinatorAddition.get('city_4').value==null)
      {
        this.toastr.error("Please fill Regional Meet to Attend of representative for Participate 4.");
        return false;
      }
      formData.append('type_ip', 'Participate 4');
      formData.append('submission_type_4', this.iicCoordinatorAddition.get('submission_type_4').value);
      formData.append('name_4', this.iicCoordinatorAddition.get('name_4').value);
      formData.append('email_4', this.iicCoordinatorAddition.get('email_4').value);
      formData.append('contact_4', this.iicCoordinatorAddition.get('contact_4').value);
      formData.append('city_4', this.iicCoordinatorAddition.get('city_4').value);
      formData.append('gender_4', this.iicCoordinatorAddition.get('gender_4').value);
    }else{
      formData.append('type_ip', '');
      formData.append('submission_type_4', '');
      formData.append('name_4', '');
      formData.append('email_4', '');
      formData.append('contact_4', '');
      formData.append('city_4', '');
      formData.append('gender_4', '');
    }
    formData.append('poster', this.iicCoordinatorAddition.get('poster').value);

   

    this.instituteActivityService.submitNominatedCoordinator(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg']);
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

  onTypeChange(ob, type) {
       if(type=="IIC")
    {
      if(ob.checked==true) {
        this.showDivField_1 = 1;
      } else {
        this.showDivField_1 = 0;
      }
    }

    if(type=="NISP")
    {
      if(ob.checked==true) {
        this.showDivField_2 = 1;
      } else {
        this.showDivField_2 = 0;
      }
    }

    if(type=="ARIIA")
    {
      if(ob.checked==true) {
        this.showDivField_3 = 1;
      } else {
        this.showDivField_3 = 0;
      }
    }

    if(type=="IP")
    {
      if(ob.checked==true) {
        this.showDivField_4 = 1;
      } else {
        this.showDivField_4 = 0;
      }
    }

  }

  onStatusChange(ob, type) {
    let nbaStatus = ob.value;

    if(type=="IIC")
    {
      if(nbaStatus == 'Members') {
        this.requireField_1 = 1;
        this.requireField_5 = 0;
        this.requireField_9 = 0;
      }else if(nbaStatus == 'Student') {
        this.requireField_1 = 0;
        this.requireField_5 = 0;
        this.requireField_9 = 1;
      } else {
        this.requireField_1 = 0;
        this.requireField_5 = 1;
        this.requireField_9 = 0;
      }
    }

    if(type=="NISP")
    {
      if(nbaStatus == 'Members') {
        this.requireField_2 = 1;
        this.requireField_6 = 0;
        this.requireField_10 = 0;
      }else if(nbaStatus == 'Student') {
        this.requireField_2 = 0;
        this.requireField_6 = 0;
        this.requireField_10 = 1;
      } else {
        this.requireField_10 = 0;
        this.requireField_2 = 0;
        this.requireField_6 = 1;
      }
    }

    if(type=="ARIIA")
    {
      if(nbaStatus == 'Members') {
        this.requireField_3 = 1;
        this.requireField_7 = 0;
        this.requireField_11 = 0;
      }else if(nbaStatus == 'Student') {
        this.requireField_3 = 0;
        this.requireField_7 = 0;
        this.requireField_11 = 1;
      } else {
        this.requireField_3 = 0;
        this.requireField_7 = 1;
        this.requireField_11 = 0;
      }
    }

    if(type=="IP")
    {
      if(nbaStatus == 'Members') {
        this.requireField_4 = 1;
        this.requireField_8 = 0;
        this.requireField_12 = 0;
      }else if(nbaStatus == 'Student') {
        this.requireField_4 = 0;
        this.requireField_8 = 0;
        this.requireField_12 = 1;
      } else {
        this.requireField_4 = 0;
        this.requireField_8 = 1;
        this.requireField_12 = 0;
      }
    }

  }

  onNbaStatusChange(ob, type,t1="") {
    let nbaStatus = ob.value;
    
    if(type == 'Student'){
      this.showtype = type;
      this.student_param = t1;
      this.instituteActivityService.getIicEvaluatorList(ob.value,nbaStatus,'StudentMemberRecord').subscribe(
        (data) => this.handleResponsePatch(data)
      );
    }else{
      this.showtype = type;
      this.instituteActivityService.getIicEvaluatorList(ob.value,nbaStatus,"MemberRecord").subscribe(
        (data) => this.handleResponsePatch(data)
      );
    }

  }

  handleResponsePatch(data) {

    if(this.showtype=="IIC" || this.student_param=="Student_p1") {
      this.iicCoordinatorAddition.patchValue({
        name_1: data['data']['name'],
        email_1: data['data']['email'],
        contact_1: data['data']['mobile'],
        gender_1: data['data']['gender']
      });
    }

    if(this.showtype=="NISP" || this.student_param=="Student_p2") {
      this.iicCoordinatorAddition.patchValue({
        name_2: data['data']['name'],
        email_2: data['data']['email'],
        contact_2: data['data']['mobile'],
        gender_2: data['data']['gender']
      });
    }

    if(this.showtype=="ARIIA"  || this.student_param=="Student_p3") {
      this.iicCoordinatorAddition.patchValue({
        name_3: data['data']['name'],
        email_3: data['data']['email'],
        contact_3: data['data']['mobile'],
        gender_3: data['data']['gender']
      });
    }

    if(this.showtype=="IP"  || this.student_param=="Student_p4") {
      this.iicCoordinatorAddition.patchValue({
        name_4: data['data']['name'],
        email_4: data['data']['email'],
        contact_4: data['data']['mobile'],
        gender_4: data['data']['gender']
      });
    }

  }

  onInnovationChange(ob) {
    let iicCity = ob.value;
    if(iicCity == 'Yes') {

    }else {

    }
  }

  handleResponsePatch1(data) {
    this.iicCoordinatorAddition.patchValue({
      ins_name: data['data']['host_institute_name'],
      session_date: data['data']['mentoring_session_date']
    });
  }


}
