<!-- <p>student-training-status works!</p> -->
<div class="container">
    <div style="text-align: left;margin: 0;padding: 10px">  
        <p>Maximum of 20 members can be nominated for IA Basic Level Training.</p>
        <p>Nominated members can only be replaced (delete) if he/she has not started the training.</p>
        <p>While nominating, verify the name and email-id to avoid login and certificate issues.</p>
        <p>After nomination, auto generated email will be received by the nominated member with the login credentials.</p>
    </div>
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px; ">
            Nominate Innovation Ambassador for Training</h2>
    </div>
    <!-- <mat-card style="margin-bottom: 49px;">
    <a routerLink="/institute/institutes-list" class="registerInstiAnchor">List of Elligible Innovation Ambassadors</a>
    </mat-card> -->
    
    <mat-card style="margin-bottom: 49px; display: none;margin-top: 15px;"  *ngIf="this.showStatus=='1'">
        <p>
            Your institute is eligible to Nominate for Innovation Ambassador scheme.
            If your institute is willing to participate, please nominate a coordinator.
        </p>
        <br>
        <p>
            Nominate teaching/non-teaching IIC Member for the Innovation Ambassador Training.
            IIC Members who have already received Innovation Ambassador training and registered
            their public profile are eligible to participate in Advanced Level and
            other members can participate in Basic Level Training.
        </p>
        <br>
        <!-- <p>All registered Innovation Ambassadors can be nominated for Advanced Level Training.
        Maximum 10 members can be nominated for Basic Level Training</p> -->
        <p><strong>Advance Level:</strong></p>
    
        <p><a routerLink="/institute/institutes-list" class="registerInstiAnchor1">View the list of IIC Members participated in Innovation Ambassador Training Programs.</a> For advanced level training innovation ambassador should enroll in the IA portal prior to nomination</p>
        <br>
    
        <p><strong>Foundation Level:</strong></p>
        <p>Maximum 10 new IIC members (Teaching/Non-Teaching) can be nominated</p>
        <br>
        <p>Eligibility criteria to participate in Innovation Ambassador Training:</p>
        <p> 1. The IIC Institution must have completed MY COUNCIL for the current IIC Calendar year.
        <mat-icon style="color: green;"  *ngIf="this.showStatus=='1'">done</mat-icon>
        <mat-icon style="color: red;"  *ngIf="this.showStatus=='0'">clear</mat-icon></p>
        <p>For more details: <a href="https://iic.mic.gov.in/iic-innovation-ambassador2021" target="_blank"> https://iic.mic.gov.in/iic-innovation-ambassador2021</a> </p>
    </mat-card>
    
    <mat-card style="margin-bottom: 49px;"  *ngIf="this.showStatus=='0'">
        <p>
            Your institute is not eligible to Nominate for Innovation Ambassador scheme. </p>
            <br>
            <p>
            Nominate teaching/non-teaching IIC Member for the Innovation Ambassador Training.
            IIC Members who have already received Innovation Ambassador training and registered
            their public profile are eligible to participate in Advanced Level and
            other members can participate in Basic Level Training.
        </p>
        <br>
        <p>All registered Innovation Ambassadors can be nominated for Advanced Level Training.
            Maximum 10 members can be nominated for Basic Level Training</p> <br>
        <p>
    Eligibility criteria to participate in Innovation Ambassador Training:</p>
    <p> 1. The IIC Institution must have completed MY COUNCIL for the current IIC Calendar year.
            <mat-icon style="color: green;"  *ngIf="this.showStatus=='1'">done</mat-icon>
            <mat-icon style="color: red;"  *ngIf="this.showStatus=='0'">clear</mat-icon></p>
            <p>For more details: <a href="https://iic.mic.gov.in/iic-innovation-ambassador2021" target="_blank"> https://iic.mic.gov.in/iic-innovation-ambassador2021</a> </p>
    </mat-card>
    
    <div *ngIf="this.showStatus=='1'">
        <div class="row pt-4">
            <div class="col-md-12">
                <form [formGroup]="nominationForm" (ngSubmit)="onSubmit(nominationForm.value)">
                    <div class="col-md-4">
                        <mat-select placeholder="Select" formControlName="faculty_type"   [(ngModel)]="faculty_type" class="instiIICcalActi">
                            <!-- <mat-option>Foundation Level</mat-option> -->
                            <mat-option value="1">Foundation Level</mat-option>
                            <!-- <mat-option value="2">Advanced Level</mat-option> -->
                        </mat-select>
                    </div>
                    <div class="col-md-4">
                        <mat-select placeholder="Select IIC Member"
                         [(ngModel)]="teacher_type" formControlName="faculty_name" (selectionChange)="changed()" multiple
                            class="instiIICcalActi">
                            <mat-option *ngFor="let role of teacher" [value]="role?.display_name">
                                {{role?.name}}
                              </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="!showdiasablebuttton" mat-raised-button type="submit" [disabled]='nominationForm.invalid' class="btn btn-success float-right" color="primary">Nominate</button>
                        <button *ngIf="showdiasablebuttton" mat-raised-button type="button" disabled class="btn btn-success float-right" color="primary">Nominate</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
