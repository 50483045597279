import { Component, Inject, OnInit } from '@angular/core';
import { LandingService } from 'src/app/Services/landing.service';
import * as myGlobals from '../../../app.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { single2 } from './data';
import { single3 } from './data';
import { single4 } from './data';
import * as $ from 'jquery';

@Component({
  selector: 'app-iic-impact-dashboard',
  templateUrl: './iic-impact-dashboard.component.html',
  styleUrls: ['./iic-impact-dashboard.component.css'],
})
export class IicImpactDashboardComponent implements OnInit {
  // Chart Start 2019-20
  imageBasePath = myGlobals.imageBasePath;
  single2: any[];
  view2: any[] = [400, 400];
  legend: boolean = true;
  legendPosition: string = 'below';

  // Chart End 2019-20

  // Chart Start 2020-21

  single3: any[];
  single4: any[];
  view3: any[] = [400, 400];
  legend3: boolean = true;
  LegendTitle = 'I&E Activity Theme';
  LegendTitle2 = 'Discipline / Stream';
  legendPosition3: string = 'below';

  // Chart End 2020-21

  downloadsDetails: any;
  totalIICNo: any;
  totalUnionTerritoriesState: any;
  totalRegisteredInstituteState: any;
  publicNewsDetails: any;
  newsDetails: any;
  feedbackDetails: any;
  public announcementsDetails: any;
  baseImageUrl = myGlobals.baseImageUrl;
  baseImageUrl1 = myGlobals.baseImageUrl1;
  idAttr: any = 'test';
  actClass: any = 'actClass';

  // Map Variables

  liveMap: any;
  approvedInstitutes: any;
  approvedInstitutesType: any;
  approvedInstitutesCourses: any;

  JammuAndKashmir: any;
  AndamanAndNicobarIslands: any;
  Telangana: any;
  AndhraPradesh: any;
  ArunachalPradesh: any;
  Assam: any;
  Bihar: any;
  Chandigarh: any;
  Chhattisgarh: any;
  DadraandNagarHaveli: any;
  DamanandDiu: any;
  Delhi: any;
  Goa: any;
  Gujarat: any;
  Haryana: any;
  HimachalPradesh: any;
  Jharkhand: any;
  Karnataka: any;
  Kerala: any;
  Lakshadweep: any;
  MadhyaPradesh: any;
  Maharashtra: any;
  Manipur: any;
  Meghalaya: any;
  Mizoram: any;
  Nagaland: any;
  Orissa: any;
  Puducherry: any;
  Punjab: any;
  Rajasthan: any;
  Sikkim: any;
  Ladakh: any;
  TamilNadu: any;
  Tripura: any;
  UttarPradesh: any;
  Uttarakhand: any;
  WestBengal: any;
  firstRange: any = '#a6d0f8';
  secondRange: any = '#5ba7f2';
  thirdRange: any = '#127eeb';
  fourthRange: any = '#0b559e';
  fifthRange: any = '';
  colorRange: any = '';
  hoverState = 'Delhi';
  courses: any[];
  instituteCount: any[];

  //Stacked bar
  single: any[];
  multi: any[];

  view: any[] = [1000, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Courses Offered';
  showYAxisLabel = true;
  yAxisLabel = 'Number of IIC Institutes';

  colorScheme = {
    domain: [
      '#5AA454',
      '#f15c80',
      '#C7B42C',
      '#7cb5ec',
      '#44a9a8',
      '#f7a35c',
      '#7184ba',
      '#91e8e1',
    ],
  };
  colorScheme2 = {
    domain: [
      '#5AA454',
      '#E44D25',
      '#CFC0BB',
      '#7aa3e5',
      '#a8385d',
      '#aae3f5',
      '#586B7D',
    ],
  };
  colorScheme3 = {
    domain: [
      '#5AA454',
      '#E44D25',
      '#CFC0BB',
      '#7aa3e5',
      '#a8385d',
      '#aae3f5',
      '#586B7D',
      '#0096A6',
    ],
  };
  test: any;
  courseSubCount: any[];
  CentralOpenUniversity: any;
  CentralUniversity: any;
  DeemedUniversityGovernment: any;
  DeemedUniversityPrivate: any;
  InstituteofNationalImportance: any;
  InstituteunderStateLegislature: any;
  StateOpenUniversity: any;
  StatePrivateUniversity: any;
  StatePublicUniversity: any;
  AffiliatedCollege: any;
  ConstituentUniversity: any;
  PGCenterOffCampus: any;
  RecognizedCenter: any;
  InstitutesunderMinistries: any;
  Nursing: any;
  Paramedical: any;
  PGDMInstitutes: any;
  TeacherTraining: any;
  TechnicalPolytechnic: any;
  bannerData: any;
  faculty_data: number = 0;
  faculty_datasp: number = 0;
  student_data: any;
  external_data: any;
  iaevent_data: any;

  icbdata: any;
  icedc: any;
  iciedc: any;
  icnew_iedc: any;
  icinnovation_cell: any;
  start_up_cell: any;
  icnidhi_center: any;
  ictinkering_lab: any;

  icpadata2020: any;
  icpadata2019: any;
  icpadata2018: any;
  icpagata2020: any;
  icpagata2019: any;
  icpagata2018: any;

  ay18_submitted: any;
  ay18_approved: any;
  ay18_iic: any;
  ay18_mic: any;
  ay18_self: any;
  ay19_submitted: any;
  ay19_approved: any;
  ay19_iic: any;
  ay19_mic: any;
  ay19_self: any;
  ay20_submitted: any;
  ay20_approved: any;
  ay20_iic: any;
  ay20_mic: any;
  ay20_self: any;
  nispcount: any;

  total_tech: any;
  total_ip: any;
  total_innov: any;
  total_startup: any;

  icidea_gene2018: any;
  icidea_gene2019: any;
  icidea_gene2020: any;

  icinnov_gene2018: any;
  icinnov_gene2019: any;
  icinnov_gene2020: any;

  icstartup_data2018: any;
  icstartup_data2019: any;
  icstartup_data2020: any;

  ay18_participants: any;
  ay19_participants: any;
  ay20_participants: any;

  ay21_submit: any;
  ay21_submitted: any;
  ay22_submitted: any;
  ay22_submit: any;
  ay23_submitted: any;
  ay24_submitted: any;
  ay21_participants: any;
  ay22_participants: any;

  constructor(private landing: LandingService, public dialog: MatDialog) {
    this.getYearWiseMap();
    Object.assign(this, { single2 }); // For Pie Chart
    Object.assign(this, { single3 }); // For Pie Chart
    Object.assign(this, { single4 }); // For Pie Chart
  }
  onSelect(event) {
    console.log(event);
  }
  onSelect2(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data))); // For Pie Chart
  }
  onSelect3(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data))); // For Pie Chart
  }
  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data))); // For Pie Chart
  }
  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data))); // For Pie Chart
  }

  ngOnInit(): void {
    this.landing
      .getApprovedIICData()
      .subscribe((data) => this.handleResponse(data));
    this.landing
      .getPublicNews()
      .subscribe((data) => this.handleResponse1(data));
    this.landing.getFeedback().subscribe((data) => this.handleResponse2(data));
    this.landing
      .getAnnouncementsData()
      .subscribe((data) => this.handleResponse3(data));
    this.landing
      .getDownloadsData()
      .subscribe((data) => this.handleResponse4(data));
    this.landing
      .getPublicNewsChunk()
      .subscribe((data) => this.handleResponse5(data));

    this.landing
      .getCourseCount()
      .subscribe((data) => this.setCourseCount(data));
    this.landing
      .getInstituteCount()
      .subscribe((data) => this.setInstituteCount(data));

    //    this.announcementsDetails = {};
    // this.handle();

    // Map Function Calling
    this.getYearWiseMap();

    this.landing
      .getBannerDetails('iic')
      .subscribe((data) => this.handleResponse6(data));

    this.getSetStatitics();

    this.coursesSubCount();

    // testimonial

    // testimonial end
  }

  openPage(id) {
    document.location.href = '/iic-impact-statewise-details/' + id;
  }

  coursesSubCount() {
    this.landing
      .instituteSubTypeCount()
      .subscribe((data) => this.setcoursesSubCount(data));
  }
  setcoursesSubCount(data) {
    this.CentralOpenUniversity = data['0']['sub_type_count'];
    this.CentralUniversity = data['2']['sub_type_count'];
    this.DeemedUniversityGovernment = data['4']['sub_type_count'];
    this.DeemedUniversityPrivate = data['5']['sub_type_count'];
    this.InstituteofNationalImportance = data['6']['sub_type_count'];
    this.InstituteunderStateLegislature = data['7']['sub_type_count'];
    this.StateOpenUniversity = data['14']['sub_type_count'];
    this.StatePrivateUniversity = data['15']['sub_type_count'];
    this.StatePublicUniversity = data['16']['sub_type_count'];

    // COllege
    this.AffiliatedCollege = data['1']['sub_type_count'];
    this.ConstituentUniversity = data['3']['sub_type_count'];
    this.PGCenterOffCampus = data['11']['sub_type_count'];
    this.RecognizedCenter = data['13']['sub_type_count'];

    // Standalone
    this.InstitutesunderMinistries = data['9']['sub_type_count'];

    this.Nursing = data['8']['sub_type_count'];
    this.Paramedical = data['10']['sub_type_count'];
    this.PGDMInstitutes = data['12']['sub_type_count'];
    this.TeacherTraining = data['17']['sub_type_count'];
    this.TechnicalPolytechnic = data['18']['sub_type_count'];
  }

  getSetStatitics() {
    this.landing
      .getIicImpactDashboard('iic')
      .subscribe((data) => this.handleResponse7(data));
  }

  handleResponse7(data) {
    this.faculty_data = data['data']['faculty'];
    this.student_data = data['data']['students'];
    this.external_data = data['data']['external'];
    this.iaevent_data = data['data']['iaevent'];

    this.icbdata = data['data']['icbdata'];
    this.icedc = data['data']['icedc'];
    this.iciedc = data['data']['iciedc'];
    this.icnew_iedc = data['data']['icnew_iedc'];

    this.icinnovation_cell = data['data']['icinnovation_cell'];
    this.start_up_cell = data['data']['start_up_cell'];
    this.icnidhi_center = data['data']['icnidhi_center'];
    this.ictinkering_lab = data['data']['ictinkering_lab'];

    this.icpadata2020 = data['data']['icpadata2020'];
    this.icpadata2019 = data['data']['icpadata2019'];
    this.icpadata2018 = data['data']['icpadata2018'];
    this.icpagata2020 = data['data']['icpagata2020'];
    this.icpagata2019 = data['data']['icpagata2019'];
    this.icpagata2018 = data['data']['icpagata2018'];
    this.nispcount = data['data']['nispcount'];

    this.total_tech = data['data']['total_tech'];
    this.total_ip = data['data']['total_ip'];
    this.total_innov = data['data']['total_innov'];
    this.total_startup = data['data']['total_startup'];

    this.ay18_submitted = data['data']['ay18_submitted'];
    this.ay18_approved = data['data']['ay18_approved'];
    this.ay18_iic = data['data']['ay18_iic'];
    this.ay18_mic = data['data']['ay18_mic'];
    this.ay18_self = data['data']['ay18_self'];

    this.ay19_submitted = data['data']['ay19_submitted'];
    this.ay19_approved = data['data']['ay19_approved'];
    this.ay19_iic = data['data']['ay19_iic'];
    this.ay19_mic = data['data']['ay19_mic'];
    this.ay19_self = data['data']['ay19_self'];

    this.ay20_submitted = data['data']['ay20_submitted'];
    this.ay20_approved = data['data']['ay20_approved'];
    this.ay20_iic = data['data']['ay20_iic'];
    this.ay20_mic = data['data']['ay20_mic'];
    this.ay20_self = data['data']['ay20_self'];

    this.ay21_submitted = data['data']['ay21_submitted'];
    this.ay22_submitted = data['data']['ay22_submitted'];
    this.ay21_submit = data['data']['ay21_submittend'];
    this.ay22_submit = data['data']['ay22_submittend'];
    this.ay23_submitted = data['data']['ay23_submitted'];
    this.ay24_submitted = data['data']['ay24_submitted'];
    this.ay21_participants = data['data']['ay21_participants'];
    this.ay22_participants = data['data']['ay22_participants'];

    this.icidea_gene2018 = data['data']['icidea_gene2018'];
    this.icidea_gene2019 = data['data']['icidea_gene2019'];
    this.icidea_gene2020 = data['data']['icidea_gene2020'];

    this.icinnov_gene2018 = data['data']['icinnov_gene2018'];
    this.icinnov_gene2019 = data['data']['icinnov_gene2019'];
    this.icinnov_gene2020 = data['data']['icinnov_gene2020'];

    this.icstartup_data2018 = data['data']['icstartup_data2018'];
    this.icstartup_data2019 = data['data']['icstartup_data2019'];
    this.icstartup_data2020 = data['data']['icstartup_data2020'];

    this.ay18_participants = data['data']['ay18_participants'];
    this.ay19_participants = data['data']['ay19_participants'];
    this.ay20_participants = data['data']['ay20_participants'];

    // this.data1 = data['data']['chart201920'];
    // this.data2 = data['data']['chart202021'];

    // Object.assign(this.data1); // For Pie Chart
    // Object.assign(this.data2); // For Pie Chart
  }

  setInstituteCount(data) {
    this.instituteCount = [];
    for (let i = 0; i < data.length; i++) {
      this.instituteCount.push(data[i]);
    }
  }

  setCourseCount(data) {
    this.single = [];
    for (let i = 0; i < data.length; i++) {
      this.single.push(data[i]);
    }
    this.multi = this.single;
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  handleResponse(data) {
    this.totalIICNo = data['total_iic_no'];
    this.totalUnionTerritoriesState = data['total_union_territories_state'];
    this.totalRegisteredInstituteState =
      data['total_registered_institute_state'];
  }
  handleResponse1(data) {
    this.publicNewsDetails = data;
  }
  handleResponse2(data) {
    this.feedbackDetails = data;
  }
  handleResponse3(data) {
    this.announcementsDetails = data['data'];
  }
  handleResponse4(data) {
    this.downloadsDetails = data;
  }
  handleResponse5(data) {
    this.newsDetails = data;
  }

  handleResponse6(data) {
    this.bannerData = data['data'];
  }

  // Map Ts functions

  // Map Api
  getYearWiseMap() {
    this.landing
      .getMapDetails()
      .subscribe((data) => this.setStateWiseCounts(data['data']));
    // this.setStateWiseCounts(data['DATA'])
  }

  setStateWiseCounts(data) {
    this.test = data['8']['state'];
    this.approvedInstitutes = data['8']['instCount'];
    this.approvedInstitutesType = data['8']['totalInstituteType'];

    this.approvedInstitutesCourses = data['8']['totalInstituteCourses'];

    // if (data['7']['state'] ) {
    // 	console.log("Hiiii")
    // }else{
    // 	console.log("helloo")
    // }
    this.liveMap = data;
    let stateCounts = data;
    // stateCounts.forEach(((data) => this.setStatesCount(data)
    // )
    stateCounts.forEach((data) => {
      this.setStatesCount(data);
      this.setColorRange(data);
    });
  }

  setColorRange(data) {
    let colorrange = '';
    var id = data.state.split(' ').join('');
    var approvedCount = parseInt(data.instCount);
    const firstRange: any = '#d9e7f5',
      secondRange: any = '#a1cae3',
      thirdRange: any = '#3e86bf',
      fourthRange: any = '#2b6eae',
      fifthRange: any = '#265496';
    if (document.getElementById(id)) {
      if (approvedCount >= 100 && approvedCount <= 250) {
        document.getElementById(id).style.fill = firstRange;
      }
      if (approvedCount >= 251 && approvedCount <= 500) {
        document.getElementById(id).style.fill = secondRange;
      }
      if (approvedCount >= 501 && approvedCount <= 1000) {
        document.getElementById(id).style.fill = thirdRange;
      }
      if (approvedCount >= 1001 && approvedCount <= 1500) {
        document.getElementById(id).style.fill = fourthRange;
      } else {
        if (approvedCount > 1500) {
          document.getElementById(id).style.fill = fifthRange;
        }
      }
    }

    return colorrange;
  }

  setStatesCount(data) {
    const firstRange: any = '#a6d0f8',
      secondRange: any = '#5ba7f2',
      thirdRange: any = '#127eeb',
      fourthRange: any = '#0b559e';
    if (data.instCount >= 100 && data.instCount <= 200) {
      this.colorRange = firstRange;
    }
    if (data.instCount > 200 && data.instCount <= 300) {
      this.colorRange = secondRange;
    }
    if (data.instCount > 300 && data.instCount <= 400) {
      this.colorRange = thirdRange;
    } else {
      this.colorRange = fourthRange;
    }

    switch (data.state) {
      case 'Andaman and Nicobar Islands': {
        this.AndamanAndNicobarIslands = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Jammu and Kashmir': {
        this.JammuAndKashmir = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Telangana': {
        this.Telangana = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Andhra Pradesh': {
        this.AndhraPradesh = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Arunachal Pradesh': {
        this.ArunachalPradesh = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Assam': {
        this.Assam = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Bihar': {
        this.Bihar = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Chandigarh': {
        this.Chandigarh = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Chhattisgarh': {
        this.Chhattisgarh = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Dadra and NagarHaveli': {
        this.DadraandNagarHaveli = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Daman and Diu': {
        this.DamanandDiu = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Delhi': {
        this.Delhi = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Haryana': {
        this.Haryana = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Himachal Pradesh': {
        this.HimachalPradesh = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Jharkhand': {
        this.Jharkhand = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Karnataka': {
        this.Karnataka = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Kerala': {
        this.Kerala = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Lakshadweep': {
        this.Lakshadweep = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Madhya Pradesh': {
        this.MadhyaPradesh = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Maharashtra': {
        this.Maharashtra = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Manipur': {
        this.Manipur = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Meghalaya': {
        this.Meghalaya = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Mizoram': {
        this.Mizoram = data.state + '  : ' + data.instCount;

        break;
      }
      case 'Nagaland': {
        this.Nagaland = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Odisha': {
        this.Orissa = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Punjab': {
        this.Punjab = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Puducherry': {
        this.Puducherry = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Pondicherry': {
        this.Puducherry = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Rajasthan': {
        this.Rajasthan = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Tamil Nadu': {
        this.TamilNadu = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Tripura': {
        this.Tripura = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Telangana': {
        this.Telangana = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Uttar Pradesh': {
        this.UttarPradesh = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Uttarakhand': {
        this.Uttarakhand = data.state + '  : ' + data.instCount;
        break;
      }
      case 'West Bengal': {
        this.WestBengal = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Goa': {
        this.Goa = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Gujarat': {
        this.Gujarat = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Sikkim': {
        this.Sikkim = data.state + '  : ' + data.instCount;
        break;
      }
      case 'Ladakh': {
        this.Ladakh = data.state + '  : ' + data.instCount;
        break;
      }
    }
  }

  mouseOver(state) {
    let approvedinsti = '0';
    let totalInstituteTypei = '0';
    let totalInstituteCoursesi = '0';

    this.liveMap.forEach(function (key, index) {
      if (state === key.state) {
        approvedinsti = key.instCount;
        totalInstituteTypei = key.totalInstituteType;
        totalInstituteCoursesi = key.totalInstituteCourses;
        if (key.state == 'Andaman and Nicobar Islands') {
          document.getElementById('Andaman and Nicobar Islands').style.stroke =
            '#b8b8b8';
          document.getElementById('Andaman and Nicobar Islands').style[
            'stroke-width'
          ] = '1';
          document.getElementById('Andaman and Nicobar Islands').style[
            'stroke-opacity'
          ] = '2';
        } else if (key.state == 'Lakshadweep') {
          document.getElementById('Lakshadweep').style.stroke = '#b8b8b8';
          document.getElementById('Lakshadweep').style['stroke-width'] = '1';
          document.getElementById('Lakshadweep').style['stroke-opacity'] = '2';
        } else if (key.state == 'Chandigarh') {
          document.getElementById('Chandigarh').style.stroke = '#b8b8b8';
          document.getElementById('Chandigarh').style['stroke-width'] = '1';
          document.getElementById('Chandigarh').style['stroke-opacity'] = '2';
        } else if (key.state == 'Puducherry') {
          document.getElementById('Puducherry').style.stroke = '#b8b8b8';
          document.getElementById('Puducherry').style['stroke-width'] = '1';
          document.getElementById('Puducherry').style['stroke-opacity'] = '2';
        } else if (key.state == 'Daman and Diu') {
          document.getElementById('DamanandDiu').style.stroke = '#b8b8b8';
          document.getElementById('DamanandDiu').style['stroke-width'] = '1';
          document.getElementById('DamanandDiu').style['stroke-opacity'] = '2';
        } else {
          // document.getElementById(state.split(" ").join("")).style.stroke= (document.getElementById(state.split(" ").join("")).style.fill);
          document.getElementById(state.split(' ').join('')).style.stroke =
            '#0c0c0c';
          document.getElementById(state.split(' ').join('')).style[
            'stroke-width'
          ] = '1';
          document.getElementById(state.split(' ').join('')).style[
            'stroke-opacity'
          ] = '2';
        }
      }
    });

    this.approvedInstitutes = approvedinsti;
    this.approvedInstitutesType = totalInstituteTypei;
    this.approvedInstitutesCourses = totalInstituteCoursesi;
    this.hoverState = state;
  }

  setStroke(state) {
    if (
      state === 'Lakshadweep' ||
      state === 'Chandigarh' ||
      state === 'Puducherry' ||
      state === 'Daman and Diu'
    ) {
      document.getElementById(state.split(' ').join('')).style.stroke =
        '#a3a3a3';
      // document.getElementById(state).style['stroke-width']= "2";
      document.getElementById(state.split(' ').join('')).style[
        'stroke-opacity'
      ] = '1';
    } else if (state === 'Andaman and Nicobar Islands') {
      document.getElementById('Andaman and Nicobar Islands').style.stroke =
        '#a3a3a3';
      document.getElementById('Andaman and Nicobar Islands').style[
        'stroke-opacity'
      ] = '0';

      // document.getElementById(state.split(" ").join("")).style.stroke = "#a3a3a3";
      // document.getElementById(state.split(" ").join("")).style['stroke-opacity'] = "0";
    } else {
      document.getElementById(state.split(' ').join('')).style.stroke =
        '#a3a3a3';
      // document.getElementById(state).style['stroke-width']= "2";
      document.getElementById(state.split(' ').join('')).style[
        'stroke-opacity'
      ] = '0';
    }
  }

  //=============Click Box Fundtion
  showBox(testId) {
    if (testId == 0) {
      $('#actClass0').addClass('active');
      $('#actClass1').removeClass('active');
      $('#actClass2').removeClass('active');
    } else if (testId == 1) {
      $('#actClass0').removeClass('active');
      $('#actClass1').addClass('active');
      $('#actClass2').removeClass('active');
    } else if (testId == 2) {
      $('#actClass0').removeClass('active');
      $('#actClass1').removeClass('active');
      $('#actClass2').addClass('active');
    }

    if ($('#test' + testId).is(':visible')) {
      $('#test' + testId).toggle(500);
      return;
    }

    var visibleBoxes = $('.accordionBox:visible');
    if (visibleBoxes.length > 0) {
      $('.accordionBox:visible').toggle(500);
    }

    $('#test' + testId).toggle(500);
  }

  // Counter Veriable
  // Faculty Member
  // faculty_data:number = 0;

  faculty_dataStop: any = setInterval(() => {
    this.faculty_datasp++;

    if (this.faculty_datasp == this.faculty_data) {
      clearInterval(this.faculty_dataStop);
    }
  }, 1000);
}
