<mat-dialog-content class="mat-typography">
        <div   role="dialog">
						<div class="modal-dialog">
							<div class="modal-content">
								<div class="modal-header" style="background-color:#02b3cc;">
									<h4 class="modal-title" style="text-align:center;color:#ffffff;font-size:18px;font-weight:bold;">Description</h4>
								</div>
								<div class="modal-body" style="padding:5%; background-color:#d6f3f7;">
									<p style="font-size:14px;">{{this.description}}</p>
								</div>
								<div class="modal-footer" style="background-color:#02b3cc;">
									<button (click)= onNoClick() type="button" class="btn btn-default" data-dismiss="modal">Close</button>
								</div>
							</div>
						</div>
                    </div>
                    </mat-dialog-content>