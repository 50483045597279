import { Component, OnInit, Inject, Type } from '@angular/core';
import { AuthService } from 'src/app/Services/auth.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mic-outcome',
  templateUrl: './mic-outcome.component.html',
  styleUrls: ['./mic-outcome.component.css']
})


export class MicOutcomeComponent implements OnInit {
  public outcomeForm: FormGroup;
 
  hardwareCount:any;
  industryCount:any;
  ministryCount:any;
  softwareCount:any;
  themeCount:any;
  totalIdeas:any;
    theme:any;
    searchValue:string;
    changeData:any;
    Tabledata:any=[];
    yearModel=["All"];
    yearModel1:any;
    setType:any;
    data1:['type','year'];
    currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;

  constructor(private auth:AuthService,  private fb: FormBuilder,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
   
this.outcomeForm = this.fb.group({
  themes: [null],
  type: [null],
  year: [null],
 
});
this.auth.getOutcomeData()
.subscribe((data) => this.handleResponse(data));

this.outcomeForm.controls['type'].setValue('');
this.outcomeForm.controls['year'].setValue("");
this.getdata1(this.data1);
 }

 getdata1(data){
   this.changeData=data;
  this.auth.UpdateTableData(this.changeData,this.currentPageNo)
  .subscribe((data) => this.handleResponse1(data));
 }

  handleResponse(data){
    this.themeCount=data['themeCount'];
    this.hardwareCount=data['hardwareCount'];
    this.industryCount=data['industryCount'];
    this.ministryCount=data['ministryCount'];
    this.softwareCount=data['softwareCount'];
    this.totalIdeas=data['totalIdea'];
    this.theme = [];
          for (let i = 0; i < data['theme'].length; i++) {
            this.theme.push(data['theme'][i]['name']);
          }   
  }
  openDialog(data) {
    const dialogRef = this.dialog.open(MicOutcomeDialogComponent, {
      data: data,
    });
  }

  onSubmit(data){
    this.data1=data;
    this.getdata1(this.data1);
  }

  // applyFilter(filterValue: string) {
  //   this.Tabledata.filter = filterValue.trim().toLowerCase();

  //   if (this.Tabledata.paginator) {
  //     this.Tabledata.paginator.firstPage();
  //   }
  // }

  handleResponse1(data){
    this.Tabledata = [];
    data['data'].forEach((element) => {
      this.Tabledata.push(element);
  });
  if (
    data['meta']['pagination']['current_page'] ==
    data['meta']['pagination']['total_pages']
  ) {
    this.firstPage = false;
    this.lastPage = true;
  } else {
    this.lastPage = false;
  }

  if (data['meta']['pagination']['current_page'] == 1) {
    this.firstPage = true;
    this.lastPage = false;
  } else {
    this.firstPage = false;
  }
}

nextPage() {
  this.currentPageNo++;
  this.getdata1(this.data1);
}

previousPage() {
  this.currentPageNo--;
  this.getdata1(this.data1);
}
}



///Outcome read more Dialog

@Component({
  selector: 'app-mic-outcome-dialog',
  templateUrl: './mic-outcome-dialog.html',
  // styleUrls: ['./institute-declare-semesters.component.css']
})
export class MicOutcomeDialogComponent implements OnInit {
  description: any;

  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<MicOutcomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
       this.description=this.data['description'];
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}







