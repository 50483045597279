<section class="activityReportFormBox">
    <div class="container">
        <form [formGroup]="activityForm" (ngSubmit)="onSubmit(activityForm)">

            <div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <div class="portlet-title">
                        <div class="caption">
                            <span style="line-height: 1.4;"> Impact Lecture Series Report</span><br/>
                            <span>{{session_name}} - Lecture {{type}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <a id="backLecture" class="backButton" href="/institute/impactLectures">Back</a>
            </div>
			<div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="overview">
                            <h3>Date & Duration</h3>
                        </div>
                        <div class="row">
                            <div class="col-6">

                              <mat-form-field class="example-full-width" style="width: 100%;">
                                  <mat-label>Lecture Date <span class="redMendate">*</span></mat-label>
                                  <input matInput [matDatepicker]="picker2" readonly formControlName="lecture_date"  [min]="minDate" [max]="maxDate" >
                                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                                </mat-form-field>

                            </div>
                            <div class="col-6">
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Duration (In Hours) <span class="redMendate">*</span></mat-label>
                                    <input type="number" matInput formControlName="lecture_duration"
                                        placeholder="Duration (In Hours)">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
<!-- 20-6 -->
    <div class="row dashboardChartRowMargin">
        <div class="col-md-12">
            <mat-card>
                <div class="overview">
                    <h3>Lecture Details</h3>
                </div>
                <div class="table-responsive">
                    <table class="table table-borderless" id="sessionReport" border="0">
                    <tbody *ngFor="let nomination of sessionList; index as i">
                        <tr *ngIf="type==1">
                            <td>Title of lecture 1: {{nomination?.title}}</td>
                            <td>Theme of lecture 1: {{nomination?.theme}}</td>
                        </tr>
                        <tr *ngIf="type==2">
                            <td>Title of lecture 2:{{nomination?.title2}}</td>
                            <td>Theme of lecture 2:{{nomination?.theme2}}</td>
                        </tr>
                    </tbody>
                </table>
                </div>


               <!--  <div class="row">
                    <div class="col-6">

                      <mat-form-field class="example-full-width" style="width: 100%;">
                          <mat-label>Title of lecture 1:</mat-label>
                          <input type="text" matInput readonly value="{{nomination?.title}}">
                        </mat-form-field>

                    </div>
                            
                </div> -->
                

            </mat-card>
        </div>
    </div>
<!-- end 20-6 -->
			<div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="overview">
                            <h3>Speaker Details</h3>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Speaker Name <span class="redMendate">*</span></mat-label>
                                    <input matInput formControlName="speaker_name" placeholder="Speaker Name">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Email <span class="redMendate">*</span></mat-label>
                                    <input matInput formControlName="speaker_email" placeholder="Email">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>

						<div class="row">
                            <div class="col-6">
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Designation <span class="redMendate">*</span></mat-label>
                                    <input matInput formControlName="speaker_designation" placeholder="Designation">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Organization <span class="redMendate">*</span></mat-label>
                                    <input matInput formControlName="speaker_organization" placeholder="Organization">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>

						<div class="row">
                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Phone Number <span class="redMendate">*</span></mat-label>
                                    <input matInput formControlName="speaker_phone" placeholder="phone" minlength="10" maxlength="10" (keypress)="isNumKey($event)">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Experience in Year <span class="redMendate">*</span></mat-label>
                                    <input type="number" matInput formControlName="speaker_experience" placeholder="Experience in Year" min="1" max="20">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>

						<div class="row">
							<div class="col-6">
                                <div class="col-lg-6 col-md-6 cvDiv">
                                    <label class="uploadCV">Upload CV <span class="redMendate">*</span></label>
                                    <mat-error>doc/pdf(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-6 col-md-6 float-right">

                                  <div class="col-12">
                                    <label class="custom-file-label float-right" for="customFile"
                                                      *ngIf="!speaker_cv">Choose file</label>

                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="speaker_cv">{{speaker_cv[0]['name']}}</label>

                                    <input #speaker_cvRef type="file" accept="application/pdf, application/msword" name="speaker_cv"
                                        (change)="( speaker_cv = speaker_cvRef.files )"
                                        class="custom-file-input float-right" required>
                                      </div>

                                      <p *ngIf="speaker_cv_url!=''"><a href="{{baseurl +speaker_cv_url}}" style="min-width: 160px; display: block; clear: both;">View File</a></p>

                                </div>
                            </div>
						</div>
                    </mat-card>
                </div>
            </div>

			<div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="overview">
                            <h3>Student/Faculty Participants</h3>
                        </div>
                        <div class="row">
							<div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>No. of Student participants <span class="redMendate">*</span></mat-label>
                                    <input type="number" matInput formControlName="no_of_student"
                                        placeholder="No. of Student participant" min="1">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
							<div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>No. of Faculty participants <span class="redMendate">*</span></mat-label>
                                    <input type="number" matInput formControlName="no_of_faculty"
                                        placeholder="No. of Faculty participant" min="1">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
							<div class="col-12">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Outcome <span class="redMendate">*</span></mat-label>
                                    <textarea matInput formControlName="outcome" placeholder="Outcome"></textarea>
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>

			<div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                      <div class="overview">
                        <h3>Honorarium Details</h3>
                    </div>
                        <div class="row">
							<div class="col-12">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 100%;">
                                    <mat-label>Honorarium Paid <span class="redMendate">*</span></mat-label>
                                    <!-- <input type="number" matInput formControlName="honorarium_paid" placeholder="Honorarium Paid" min="1" max="3000"> -->
                                    <input type="number" matInput formControlName="honorarium_paid" placeholder="Honorarium Paid">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
								<!-- <mat-error class="mat-error-paid">Max Rs. 3000</mat-error> -->
                            </div>
							<div class="col-6">
                                <div class="col-lg-6 col-md-6 cvDiv">
                                    <label class="uploadCV">Honorarium Receipt <span class="redMendate">*</span></label>
                                    <mat-error>pdf(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-6 col-md-6 float-right">
                                  <div class="col-12">
                                    <label class="custom-file-label float-right" for="customFile"
                                                      *ngIf="!receipt_of_transaction">Choose file</label>

                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="receipt_of_transaction">{{receipt_of_transaction[0]['name']}}</label>

                                    <input #receipt_of_transactionRef type="file" accept="application/pdf" name="receipt_of_transaction"
                                        (change)="( receipt_of_transaction = receipt_of_transactionRef.files )"
                                        class="custom-file-input float-right" required>
                                      </div>

                                      <p *ngIf="receipt_of_transaction_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="{{baseurl +receipt_of_transaction_url}}">View File</a></p>
                                      <!-- <p *ngIf="receipt_of_transaction_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="https://api.mic.gov.in{{receipt_of_transaction_url}}">View File</a></p> -->

                                </div>
                            </div>
							<div class="col-6">
                                <div class="col-lg-6 col-md-6 cvDiv">
                                    <label class="uploadCV">Prof of Transaction <span class="redMendate">*</span></label>
                                    <mat-error>image/doc/pdf(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-6 col-md-6 float-right">
                                  <div class="col-12">
                                    <label class="custom-file-label float-right" for="customFile"
                                                      *ngIf="!proof_of_transaction">Choose file</label>

                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="proof_of_transaction">{{proof_of_transaction[0]['name']}}</label>

                                    <input #proof_of_transactionRef type="file" accept="application/pdf" name="proof_of_transaction"
                                        (change)="( proof_of_transaction = proof_of_transactionRef.files )"
                                        class="custom-file-input float-right" required>
                                      </div>
                                      <p *ngIf="proof_of_transaction_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="{{baseurl+proof_of_transaction_url}}">View File</a></p>
                                      <!-- <p *ngIf="proof_of_transaction_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="https://api.mic.gov.in{{proof_of_transaction_url}}">View File</a></p> -->

                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>

			<div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="overview">
                            <h3>Attachments</h3>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Video Url <span class="redMendate">*</span></mat-label>
                                    <input matInput formControlName="video_url" placeholder="Video Url">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>


                            <div class="col-6">
                                <div class="col-lg-6 col-md-6">
                                    <label>Photograph1 <span *ngIf="action != 'edit'" style="color:red">*</span></label>
                                    <mat-error>image(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-6 col-md-6 float-right">
                                  <div class="col-12">
                                    <label class="custom-file-label float-right" for="customFile"
                                                      *ngIf="!photo_1">Choose file</label>

                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="photo_1">{{photo_1[0]['name']}}</label>

                                    <input #photo_1Ref type="file" accept="images/*" name="photo_1"
                                        (change)="( photo_1 = photo_1Ref.files )"
                                        class="custom-file-input float-right" required>
                                      </div>

                                      <p *ngIf="photo_2_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="{{baseurl +photo_2_url}}">View File</a></p>

                                      <!-- <p *ngIf="photo_2_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="https://api.mic.gov.in{{photo_2_url}}">View File</a></p> -->

                                </div>
                            </div>


                        </div>
                        <div class="row">

                            <div class="col-6">
                                <div class="col-lg-6 col-md-6">
                                    <label>Photograph2 </label>
                                    <mat-error>image(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-6 col-md-6 float-right">
                                  <div class="col-12">
                                    <label class="custom-file-label float-right" for="customFile"
                                                      *ngIf="!photo_2">Choose file</label>

                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="photo_2">{{photo_2[0]['name']}}</label>

                                    <input #photo_2Ref type="file" accept="images/*" name="photo_2"
                                        (change)="( photo_2 = photo_2Ref.files )"
                                        class="custom-file-input float-right" required>
                                      </div>
                                      <p *ngIf="photo_2_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="{{baseurl +photo_2_url}}">View File</a></p>
                                      <!-- <p *ngIf="photo_2_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="https://api.mic.gov.in{{photo_2_url}}">View File</a></p> -->

                                </div>
                            </div>


                            <div class="col-6">
                                <div class="col-lg-6 col-md-6">
                                    <label> Session plan / Brochure / Document / overall report of the activity <span class="redMendate">*</span>
                                    </label>
                                    <mat-error>pdf(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-6 col-md-6 float-right">
                                  <div class="col-12">
                                    <label class="custom-file-label float-right" for="customFile"
                                                      *ngIf="!session_pdf">Choose file</label>

                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="session_pdf">{{session_pdf[0]['name']}}</label>

                                    <input #session_pdfRef type="file" accept="application/pdf" name="session_pdf"
                                        (change)="( session_pdf = session_pdfRef.files )"
                                        class="custom-file-input float-right" required>
                                      </div>

                                      <p *ngIf="session_pdf_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="{{baseurl + session_pdf_url}}">View File</a></p>
                                      <!-- <p *ngIf="session_pdf_url!=''"><a  style="min-width: 160px; display: block; clear: both;" href="https://api.mic.gov.in{{session_pdf_url}}">View File</a></p> -->

                                </div>
                            </div>

                        </div>

                    </mat-card>
                </div>
            </div>

			<div class="social">
                <mat-card>
                    <div class="overview">
                        <h3 style="color:#E26A6A;">Promotion in Social Media</h3>
                    </div>
                    <!-- <table>
                        <tbody>
                            <tr>
                                <th scope="col">Social Media (Facebook or Twitter or Instagram)</th>
                                <td scope="col"><input matInput type="text" formControlName="social_media"
                                  placeholder="Social Media URL" style="width: 399px;"></td>
                            </tr>
                        </tbody>
                    </table> -->
					<div class="row">
						<div class="col-6">
							<p class="socialMediaPara">Social Media (Facebook or Twitter or Instagram) <span class="redMendate">*</span></p>
						</div>
						<div class="col-6">
							<mat-form-field class="example-full-width" style="width: 100%;">
								<input matInput type="text" formControlName="social_media"
                                  placeholder="Social Media URL" style="width: 399px;">
							</mat-form-field>
						</div>
					</div>
                </mat-card>
				<mat-dialog-actions align="end" class="reportFormActivityButton">
					<button mat-raised-button type="submit" class="btn btn-success">Submit</button>

				</mat-dialog-actions>
            </div>



        </form>

<p style="color: crimson;">Note: To View/Preview the latest uploaded file, click the Submit button</p>

    </div>
</section>



