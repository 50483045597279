import { Component, OnInit } from '@angular/core';
import { IicIncubatorstabService } from 'src/app/Services/iic-incubatorstab.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-incubators',
  templateUrl: './incubators.component.html',
  styleUrls: ['./incubators.component.css']
})


export class IncubatorsComponent implements OnInit {

  public incubators: FormGroup;
  currentPageNo: number = 1;
  firstPage = true;
  total_pages : number;
  lastPage: boolean = false;
  incubatorDetails = [];
  changeData: any;
  data1: any;
  allvalues:any;
  state: any;
  StateModel: any;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  frontCurrentPageNo: number = 0;
  

  constructor(private micService: IicIncubatorstabService,private fb: FormBuilder,
    public dialog: MatDialog,) {}

  ngOnInit(): void {

    this.incubators = this.fb.group({
      state: [null],
      type_of_registration: [null],
      funding_scheme_support_received:[null],
    });
    this.getIds();
    this.getState();
    this.getIncubators(this.data1);
    this.incubators.controls['type_of_registration'].setValue('');
    this.incubators.controls['funding_scheme_support_received'].setValue('');
    this.incubators.controls['state'].setValue(this.allvalues);


  }

  getState(){
    this.micService
    .getState()
    .subscribe((data) => this.setState(data));
  }
  setState(data){
    this.state = [];
    for (let i = 0; i < data.length; i++) {
      this.state.push(data[i]);
    }
  }

  getIds(){
    this.micService
    .getState()
    .subscribe((data) => this.setId(data));

  }

  setId(data){
    this.allvalues = [];
    for (let i = 0; i < data.length; i++) {
      this.allvalues.push(data[i]['id']);
    }
this.StateModel=this.allvalues;
  }



  getIncubators(data) {
    this.changeData=data;
	
	
    this.micService
      .getIicIncubationDetails(this.changeData,this.currentPageNo,this.per_page)
      .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data) {
	  
	this.total_pages = data.incubatorDetails.meta.pagination.total;  
    this.incubatorDetails = data['incubatorDetails']['data'];

    if (
      data['incubatorDetails']['meta']['pagination']['current_page'] ==
      data['incubatorDetails']['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['incubatorDetails']['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {
	if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	this.per_page = e.pageSize;
    this.getIncubators(this.data1);
  }
  previousPage() {
    this.currentPageNo--;
    this.getIncubators(this.data1);
  }

  onSubmit(data){
    this.data1=data;
    this.currentPageNo=1;
    this.getIncubators(this.data1);
  }
}