import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-aph-verification-dialog',
  templateUrl: './aph-verification-dialog.component.html',
  styleUrls: ['./aph-verification-dialog.component.css']
})
export class AphVerificationDialogComponent implements OnInit {

  approvalCode:any;
  approvalStatus:any;
  approvalData:any;

  constructor(public dialogRef: MatDialogRef<AphVerificationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.approvalData = data;
    }

  ngOnInit(): void {
    this.approvalCode = this.approvalData['data']['approvalCode'];
    this.approvalStatus = this.approvalData['data']['approval_status'];
  }

}
