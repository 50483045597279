<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">IIC Online Session</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC Online Session</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Webinar</li>
  </ul>
</nav>

<section class="onlineSeassionContent">
	<div class="cbp-container container wh-padding">
		<div class="vc_row innovationAmbassadorBox">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2>IIC Online sessions</h2>
				</div>
			</div>
		</div>
		<div class="vc_row">
			<div class="col-md-12">
				<p class="onlineContentSeassion">Due to the prevailing lockdown in the entire nation, MOE’s Innovation Cell has adapted the digital mode and processes to continue driving the innovation ecosystem in HEIs. In the quest to complete the IIC calendar activities for the IIC session 2019-2020, MIC has announced the 17 online sessions for faculties and students which were delivered by experts arranged by MOE’s Innovation Cell for the benefit of students and faculties. These seventeen e-sessions replaced the remaining physical activities of IIC calendar plan for the Quarter 3 & 4, which couldn’t be completed due to restricted movement in ongoing Covid- 19 pandemic. </p>
				
				<p class="onlineContentSeassion">These sessions were broadcasted through social media platforms and were joined by all the institute having established IIC or aspiring to establish IIC in their campus. The faculties and students were given post session assignment, based on which the e-certificate were provided to the participants from MOE’s Innovation Cell.</p>
			</div>
		</div>
	</div>
</section>

<div class="cbp-row wh-content">
			<div class="cbp-container container wh-padding">
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>MoE's Innovation Cell - Online Session</h2>
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox webniar">	
					<div class="col-md-12">
									<div class="vc_col-md-4" *ngFor="let session of iicOnlineSessions">
						<a routerLink="/iic-online-sessions-details/ + {{session?.id}}">
							<div class="thumbnail_section">
								<div class="thumb_img">
									<img src="{{baseImageUrl + '/' + session?.thumbnail_image}}" class="img-thumbnail">
								</div>
								<div class="thumb_details">
									<div class="top-info">
									<span class="status">{{session?.status}}</span>										
										<div class="ribbon-horizontal"><span class="ses_theme_text">{{session?.video_theme}}</span></div>
									<div class="clearfix"></div>	
									</div>
									<div class="clearfix"></div>
									<div><p class="thumb_title">{{session?.video_title}}</p></div>
									<div class="clearfix"></div>
									<div><p class="thumb_extra">
										<!--<span class="thumb_auth"><span class="datime">Date:</span> 2020-05-08</span> -->
										<span class="thumb_auth"><span class="datime">Date:</span> {{session?.new_date}}</span>
										<!-- <span class="thumb_dur"><span class="datime">Time:</span> 03:00 - 04:00 pm</span> -->
										<span class="thumb_dur"><span class="datime">Time:</span>{{session?.session_time}}</span>
									</p></div>
									<div class="clearfix"></div>
								</div>
							</div>
						</a>
					</div>
							</div>
					
				</div>
				
				<div class="vc_row-full-width vc_clearfix"></div>
				


			</div>
		</div>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>