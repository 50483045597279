<div class="container">
  <div class="instMyCouncilModal">
    <div class="container">
      <div class="row">
        <div class="col-md-12"
          style="background-color: #fff;color: #000 !important;font-size: 27px;">
          <p class="hdr bifuracationPara">Performance Report for the Academic Year 2023-24</p>
          <div style="padding: 0 0 20px 0; margin: 0 0 20px 0;">
            <ul>
              <!-- <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Performance Report for the Academic Year 2021-22</b></li> -->
              <!-- <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">Bifurcation of Score and Reward Points</li> -->
              <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">
                <div class="row">
                  <div class="col-md-12 NewScoreList">
                    <span class="mic_btn mic_totalmarks">Total Score: {{formData?.total_marks}}/100</span>
                    <span class="mic_btn mic_rewardpoints">Total Reward: {{formData?.totalRewards}}</span>
                    <!-- <span class="mic_btn mic_rating1">Rating: {{formData?.rating}}/4</span>
                    <span class="mic_btn mic_rating2">Fifth Star Rating: {{fstarScore?.star_rating}}/1</span> -->
                    <span class="mic_btn mic_rating">Final Star: {{formData?.final_star}}/5</span>
                  </div>
                </div>
              </li>
            </ul>

            <!-- <p style="line-height:1.1; text-align:center;">Scores are calculated based on the minimum prescribed activities in each “activity type”. IIC-Institute earn Reward Points for conducting more than prescribed minimum activities under each “activity type”, which is effective towards 5th star</p> -->

            <table id="scoreActivity" class="table table-hover" style="margin-bottom: 0px;border:1px solid #ffffff;">
              <tbody style="text-align: center;">
              <tr>
                <td style="text-align:center; vertical-align:top;"><strong>Activity Summary</strong></td>
                <td style="text-align:center; vertical-align:top;"><strong>Score Earned&nbsp; for Q1-Q4 Activities (I = A*80%)</strong></td>
                <td style="text-align:center; vertical-align:top;"><strong>Score Earned&nbsp; from Participation (II = B*20%)</strong></td>
                <td style="text-align:center; vertical-align:top;"><strong>Total Score Earned&nbsp; (I + II = 100)</strong></td>
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;">Total no of Submitted Activities : {{ (formData) ? formData?.TotalActivitySubmitted_new : 0 }}</td>
                <td style="text-align:center; vertical-align:top;" rowspan="3"><strong>Total Score Earned  for Q1-Q4 Activities :</strong> {{ (formData) ? formData?.totalCountForTableA : 0 }}</td>
                <td style="text-align:center; vertical-align:top;" rowspan="3"><strong>Total Score Earned  from Participation :</strong> {{fstarScore?.total_score}}</td>                  
                <td style="text-align:center; vertical-align:top;" rowspan="6">{{formData?.total_marks}}</td>
              </tr> 
              <tr>
                <td style="text-align:center; vertical-align:top;" >Total IA Verified Report : {{ (fstarScore) ? (fstarScore?.ia_online_selfdriven + fstarScore?.ia_offline_selfdriven) : 0 }}</td>
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;" >No of Approved Activities : {{ (fourthStarScore) ? fourthStarScore?.total_approved : 0 }}</td>
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;">No of Disapproved Activities : {{ (fourthStarScore) ? ((formData?.TotalActivitySubmitted_new + (fstarScore?.ia_online_selfdriven + fstarScore?.ia_offline_selfdriven)) - fourthStarScore?.total_approved) : 0 }}</td>
                <td style="text-align:center; vertical-align:top;" rowspan="3"><b>I.  </b><strong>Total Score Earned  for Q1-Q4 Activities (I = A*80%) :</strong> {{ (formData) ? formData?.totalScoreEarned : 0 }}</td>
                <td style="text-align:center; vertical-align:top;" rowspan="3"><b>II.  </b><strong>Total Score Earned  from Participation (II = B*20%) :</strong> {{ (formData) ? formData?.b20 : 0 }}</td>
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;">Disapproval % of Activities : {{ (fourthStarScore) ? fourthStarScore?.disapproved_percentage : 0 }}</td>
                
              </tr>
              <tr>
                <td style="text-align:center; vertical-align:top;">Reward Point Earned : {{ (fourthStarScore) ? fourthStarScore?.total_reward_score : 0 }}</td>
              </tr>
            </tbody>
          </table>

          <table style="text-align: center;" class="table table-hover">
            <tbody>
                <tr>
                    <td colspan="12"><strong>Table A: Unit Score/Activity (Q1-Q4)</strong></td>
                </tr>
                <tr>
                    <td><strong>S. No.</strong></td>
                    <td><strong>Activity Category</strong></td>
                    <td><strong>Activity Level</strong></td>
                    <td><strong>No. of Prescribed Activities</strong></td>
                    <td><strong>Max Allocation Score</strong></td>
                    <td><strong>No of Threshold Activties</strong></td>
                    <td><strong>Unit Score/Activity&nbsp;</strong></td>
                    <td><strong>Unit Score/Activity (Offline)&nbsp;</strong></td>
                    <td><strong>Unit Score/Activity (Online)&nbsp;</strong></td>
                    <td><strong>Total No of Submitted Activities</strong></td>
                    <td><strong>No of Approved Activities</strong></td>
                    <td><strong>Total Score Earned for Q1-Q4 Activities</strong></td>
                </tr>
                <tr>
                    <td rowspan="4">1</td>
                    <td rowspan="4">IIC&nbsp; Calendar Activities</td>
                    <td>Level 1</td>
                    <td>6</td>
                    <td rowspan="2">15.00</td>
                    <td>..</td>
                    <td rowspan="2">1.00</td>
                    <td>1.00</td>
                    <td>0.75</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (formData) ? formData?.iicOnlineLevel1or2online : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.iicOnlineLevel1or2offline : 0 }}</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.iic_online_count : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.iic_count : 0 }}</td>
                    <td rowspan="4">{{ (fourthStarScore) ? fourthStarScore?.iic_score : 0 }}</td>
                </tr>
                <tr>
                    <td>Level 2</td>
                    <td>9</td>
                    <td>..</td>
                    <td>1.00</td>
                    <td>0.75</td>
                </tr>
                <tr>
                    <td>Level 3</td>
                    <td>7</td>
                    <td rowspan="2">25.00</td>
                    <td>..</td>
                    <td rowspan="2">1.67</td>
                    <td>1.33</td>
                    <td>1.00</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (formData) ? formData?.iicOnlineLevel3or4online : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.iicOnlineLevel3or4offline : 0 }}</td>
                    <td rowspan="2"><strong>Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.iic_online_level_3_count : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.iic_offline_level_3_count : 0 }}</td>
                </tr>
                <tr>
                    <td>Level 4</td>
                    <td>8</td>
                    <td>..</td>
                    <td>1.96</td>
                    <td>1.47</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>MIC Driven Activities</td>
                    <td></td>
                    <td>9</td>
                    <td>20.00</td>
                    <td>..</td>
                    <td>2.22</td>
                    <td>2.86</td>
                    <td>2.15</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.micAllOnline : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.micAllOffline : 0 }}</td>
                    <td><strong>Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.mic_online_count : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.mic_count : 0 }}</td>
                    <td>{{ (fourthStarScore) ? fourthStarScore?.mic_score : 0 }}</td>
                </tr>
                <tr>
                    <td rowspan="2">3</td>
                    <td rowspan="2">Celebration Activities</td>
                    <td>Level 1 & 2</td>
                    <td rowspan="2">14</td>
                    <td rowspan="2">10.00</td>
                    <td rowspan="2">8</td>
                    <td>0.94</td>
                    <td>0.94</td>
                    <td>0.71</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.rowCelCountAllOnlineLevel1or2 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.rowCelCountAllOfflineLevel1or2 : 0 }}</td>
                    <td><strong>Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.cele_online_count : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.cel_count : 0 }}</td>
                    <td rowspan="2"><strong>Score : </strong> {{ (formData) ? formData?.newCelebrationScore : 0 }}  
                      <br/><br /><strong>Reward : </strong> {{ (formData) ? formData?.newCelebrationReward : 0 }} </td>
                </tr>
                <tr>
                    <td>Level 3 & 4</td>
                    <td>1.25</td>
                    <td>1.25</td>
                    <td>0.94</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.rowCelCountAllOnlineLevel3or4 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.rowCelCountAllOfflineLevel3or4 : 0 }}</td>
                    <td><strong>Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.cele_online_level_3_count : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.cele_offline_level_3_count : 0 }}</td>
                </tr>
                <tr>
                    <td rowspan="4">4</td>
                    <td rowspan="4">Self-Driven Activities</td>
                    <td>Level 1</td>
                    <td rowspan="2">..</td>
                    <td rowspan="2">30.00</td>
                    <td rowspan="2">16</td>
                    <td>1.41</td>
                    <td>0.94</td>
                    <td>0.70</td>
                    <td rowspan="2"><strong> Self Driven Online : </strong> {{ (formData) ? formData?.rowSelfCountAllOnlineLevel1or2 : 0 }}  
                      <br/><br/> <strong> IA Verified Online : </strong> {{ (fstarScore) ? (fstarScore?.ia_online_selfdriven) : 0 }}
                      <br/><br /><strong>Self Driven Offline : </strong> {{ (formData) ? formData?.rowSelfCountAllOfflineLevel1or2 : 0 }}
                      <br/><br/><strong>IA Verified Offline : </strong> {{ (fstarScore) ? (fstarScore?.ia_offline_selfdriven) : 0 }}
                    </td>
                    <td rowspan="2"><strong>Self Driven Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_online_count - fstarScore?.ia_online_selfdriven : 0 }}  
                      <br/><br/> <strong> IA Approved Online : </strong> {{ (fstarScore) ? (fstarScore?.ia_online_selfdriven) : 0 }}
                      <br/><br /><strong>Self Driven Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_count - fstarScore?.ia_offline_selfdriven : 0 }}
                      <br/><br/><strong>IA Approved Offline : </strong> {{ (fstarScore) ? (fstarScore?.ia_offline_selfdriven) : 0 }}
                    </td>
                    <td rowspan="2"><strong>Score : </strong> {{ (formData) ? formData?.newSelfDrivenScore : 0 }}  
                      <br/><br /><strong>Reward : </strong> {{ (formData) ? formData?.newSelfDrivenReward : 0 }} </td>
                </tr>
                <tr>
                    <td>Level 2</td>
                    <!-- <td rowspan="2">..</td>
                    <td rowspan="2">30.00</td>
                    <td rowspan="2">16</td> -->
                    <td>1.41</td>
                    <td>0.94</td>
                    <td>0.70</td>
                </tr>
                <tr>
                    <td>Level 3</td>
                    <td rowspan="2">..</td>
                    <td rowspan="2">30.00</td>
                    <td rowspan="2">16</td>
                    <td>1.88</td>
                    <td>1.25</td>
                    <td>0.94</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.rowSelfCountAllOnlineLevel3or4 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.rowSelfCountAllOfflineLevel3or4 : 0 }}</td>
                    <td><strong>Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_online_level_3_count : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_offline_level_3_count : 0 }}</td>
                    <td rowspan="2"><strong>Score : </strong> {{ (formData) ? formData?.newSelfDrivenScore : 0 }}  
                        <br/><br /><strong>Reward : </strong> {{ (formData) ? formData?.newSelfDrivenReward : 0 }} </td>
                </tr>
                <tr>
                    <td>Level 4</td>
                    <td>1.88</td>
                    <td>2.19</td>
                    <td>1.64</td>
                    <td><strong>Online : </strong> {{ (formData) ? formData?.rowSelfCountAllOnlineLevel3or4 : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (formData) ? formData?.rowSelfCountAllOfflineLevel3or4 : 0 }}</td>
                    <td><strong>Online : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_online_level_3_count : 0 }}  
                      <br/><br /><strong>Offline : </strong> {{ (fourthStarScore) ? fourthStarScore?.self_offline_level_3_count : 0 }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Grand Total</td>
                  <td></td>
                  <td></td>
                  <td>100.00</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ (formData) ? formData?.totalCountForTableA : 0 }}</td>
              </tr>
            </tbody>
        </table>
          
        <table class="table table-hover">
          <tbody>
            <tr>
                <!-- <td><strong>B</strong></td> -->
                <td colspan="5" style="text-align: center;" ><strong>Table B: Score for the IIC's Participation in Various Programs</strong></td>
            </tr>
            <tr>
                <td ><strong>S. No.</strong></td>
                <td colspan="2" style="text-align: center;"><strong>Incentive Parameters</strong></td>
                <td ><strong>Weightage</strong></td>
                <td ><strong>Score Obtained</strong></td>
            </tr>
            <tr>
                <td>1.</td>
                <td>Redemption of Reward Points Earned by the IIC</td>
                <td >Maximum 100 Reward Points can be redeemed</td>
                <td >8%</td>
                <td ><span *ngIf="fstarScore?.reward_score">{{fstarScore?.reward_score}}</span>
                  <span *ngIf="!fstarScore?.reward_score">0</span></td>
            </tr>
            <tr>
                <td rowspan="4">2.</td>
                <td rowspan="4">YUKTI - Inovation Repository at the Institute level (From 1st October 2022 To 31st August 2023).&nbsp;</td>
                <td>I.&nbsp; Minimium of 50 Verfied Ideas/PoCs in the Yukti platform</td>
                <td >5%</td>
                <td ><span *ngIf="fstarScore?.idea_relative_score">{{fstarScore?.idea_relative_score}}</span>
                  <span *ngIf="!fstarScore?.idea_relative_score">0</span>
                </td>
            </tr>
            <tr>
              <td>II.&nbsp; Minimium of 25 Verfied Innovation/Prototype in the Yukti platform</td>
                <td >5%</td>
                <td ><span *ngIf="fstarScore?.innovation_relative_score">{{fstarScore?.innovation_relative_score}}</span>
                  <span *ngIf="!fstarScore?.innovation_relative_score">0</span>
                </td>
            </tr>
            <tr>
              <td>III.&nbsp; Minimium of 10 Verfied Startups in the Yukti platform.(Registered Startups)</td>
                <td >5%</td>
                <td ><span *ngIf="fstarScore?.non_dpit_score">{{fstarScore?.non_dpit_score}}</span>
                  <span *ngIf="!fstarScore?.non_dpit_score">0</span>
                </td>
            </tr>
            <tr>
              <td>IV.&nbsp; Minimium of 5 Verfied Startups in the Yukti platform.(DPIIT Recognized)</td>
                <td >5%</td>
                <td ><span *ngIf="fstarScore?.dpit_startup_score">{{fstarScore?.dpit_startup_score}}</span>
                  <span *ngIf="!fstarScore?.dpit_startup_score">0</span>
                </td>
            </tr>
            <tr>
                <td rowspan="3">3.</td>
                <td rowspan="3">Institutions Participation YUKTI Innovation Challenge 2023</td>
                <td>I. Nomination upto 10 Innovations.</td>
                <td>3%</td>
                <td ><span *ngIf="fstarScore?.ia_nic_mentoship_score">{{fstarScore?.ia_nic_mentoship_score}}</span>
                  <span *ngIf="!fstarScore?.ia_nic_mentoship_score">0</span>
                </td>
            </tr>
            <tr>
              <td>II. Nos of Innovations Completed First Round of YUKTI Innovation Challenge</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.nic_team_bplan_score">{{fstarScore?.nic_team_bplan_score}}</span>
                <span *ngIf="!fstarScore?.nic_team_bplan_score">0</span>
              </td>
            </tr>
            <tr>
              <td>III. Nos of Innovations Completed First Round of YUKTI Innovation Challenge</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.nic_team_bplan_score">{{fstarScore?.nic_team_bplan_score}}</span>
                <span *ngIf="!fstarScore?.nic_team_bplan_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="3">4.</td>
              <td rowspan="3">Institute has referred HEIs to join the IIC Network&nbsp;</td>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>5.</td>
              <td>Finalist/Benificiary of impact lecture and Mentor-Mentee Scheme&nbsp;</td>
              <td>Institute Selected as a Mentor (or) Benificiary of Impact Lecture Scheme/Mentee Scheme&nbsp;</td>
              <td >5%</td>
              <td ><span *ngIf="fstarScore?.impact_score">{{fstarScore?.impact_score}}</span>
                <span *ngIf="!fstarScore?.impact_score">0</span>
              </td>
            </tr>
            <tr>
              <td>6.</td>
              <td>Finalist/Benificiary of impact lecture and Mentor-Mentee Scheme&nbsp;</td>
              <td>Institute Selected as a Mentor (or) Benificiary of Impact Lecture Scheme/Mentee Scheme&nbsp;</td>
              <td >5%</td>
              <td ><span *ngIf="fstarScore?.impact_score">{{fstarScore?.impact_score}}</span>
                <span *ngIf="!fstarScore?.impact_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2">7.</td>
              <td rowspan="2">Institute Position in NIRF-Innovation Ranking 2023&nbsp;</td>
              <td>I. Institute having Participation Only (2%)&nbsp;</td>
              <td rowspan="2">5%</td>
              <td rowspan="2"><span *ngIf="fstarScore?.ariia_score">{{fstarScore?.ariia_score}}</span>
                <span *ngIf="!fstarScore?.ariia_score">0</span>
              </td>
            </tr>
            <tr>
              <td>II. Institute having Band/Rank (5%)&nbsp;</td>
            </tr>
            <tr>
              <td>8.</td>
              <td>Finalist/Benificiary of impact lecture and Mentor-Mentee Scheme&nbsp;</td>
              <td>Institute Selected as a Mentor (or) Benificiary of Impact Lecture Scheme/Mentee Scheme&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.impact_score">{{fstarScore?.impact_score}}</span>
                <span *ngIf="!fstarScore?.impact_score">0</span>
              </td>
            </tr>
            <tr>
              <td>9.</td>
              <td>Finalist/Benificiary of impact lecture and Mentor-Mentee Scheme&nbsp;</td>
              <td>Institute Selected as a Mentor (or) Benificiary of Impact Lecture Scheme/Mentee Scheme&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.impact_score">{{fstarScore?.impact_score}}</span>
                <span *ngIf="!fstarScore?.impact_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2">10.</td>
              <td rowspan="2">Institute has referred HEIs to join the IIC Network&nbsp;</td>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="4">11.</td>
              <td rowspan="4">Institute has referred HEIs to join the IIC Network&nbsp;</td>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >5%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2">12.</td>
              <td rowspan="2">Institute has referred HEIs to join the IIC Network&nbsp;</td>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2">13.</td>
              <td rowspan="2">Institute has referred HEIs to join the IIC Network&nbsp;</td>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >2%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td>Minimum 5 HEIs are referred to join the IIC Network&nbsp;</td>
              <td >3%</td>
              <td ><span *ngIf="fstarScore?.referral_score">{{fstarScore?.referral_score}}</span>
                <span *ngIf="!fstarScore?.referral_score">0</span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Total &nbsp;</td>
              <td>&nbsp;</td>
              <td>100%</td>
              <td>{{fstarScore?.total_score}}</td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-sm-8 offset-sm-2">
            <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 5px; margin-bottom: 20px;">Table C: Score Range and Star Allocation</span>

          <table style="text-align: center;" class="table table-hover">
            <tbody>
              <tr  style="background-color: #ececec !important;">
                <th class="hdr scoreTH" width="25%" style="text-align:center;background-color: #ececec !important;">Score Range</th>
                <th class="hdr scoreTH" width="25%" style="text-align:center;background-color: #ececec !important;">Star allocation</th>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 0<Score<=25 </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">One star</td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 25<Score<=45
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Two star</td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 45<Score<=60
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Three Star </td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 60<Score<=75
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Three and a Half Star  </td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 75<Score<=90
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Four Star</td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 90<Score<=95
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Four and a Half Star</td>
              </tr>
              <tr>
                <td class="hdr scoStarConv" style="font-weight: bold;"> 95<Score<=100
                </td>
                <td class="hdr scoStarConv" style="font-weight: bold;">Five Star</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
    </div>
  </div>
</div>