import { Component, OnInit } from '@angular/core';
import { IicInnovationAmbassadorService } from 'src/app/Services/iic-innovation-ambassador.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as myGlobals from '../../../app.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IicContactUsComponent } from '../iic-contact-us/iic-contact-us.component';

@Component({
  selector: 'app-iic-ambassador-profile',
  templateUrl: './iic-ambassador-profile.component.html',
  styleUrls: ['./iic-ambassador-profile.component.css']
})
export class IicAmbassadorProfileComponent implements OnInit {

public innovation: FormGroup;
public studentForm: FormGroup;
  allvalues: any[];
  passId:any;
  text:'hello';
  allvaluesStudents: any[];
  StateModel: any[];
  StateModelStudents: any[];
  state: any;
  stateStudents: any;
  changeData: any;
  changeDataStudents: any;
  currentPageNo: number = 1;
  currentPageNoStudents: number = 1;
  total_pages : number;
  total_pages_students : number;
  firstPage = true;
  firstPageStudents = true;
  lastPage: boolean = false;
  lastPageStudents: boolean = false;
  theme: any[];
  themeStudents: any[];
  dataStudents: any;
  pageSizeOptions: number[] = [10, 25,50, 100];
  pageSizeOptionsStudents: number[] = [10, 25,50, 100];
  per_page : number = 10;
  per_page_students : number = 10;
  frontCurrentPageNo: number = 0;
  frontCurrentPageNoStudents: number = 0;
  baseImageUrl = myGlobals.baseImageUrlIA;

  constructor(public dialog: MatDialog, private iicInnovationAmbassadorService: IicInnovationAmbassadorService,private fb: FormBuilder,private router:Router,) { }

  ngOnInit(): void {
    this.innovation = this.fb.group({
      state: [null],
      area: [null],
	  searchExter: [null],

    });
	this.studentForm = this.fb.group({
	  stateStudents: [null],
      areaStudents: [null],
	  searchExterStudents: [null],

    });
this.getIds();
    this.getState();
    this.innovation.controls['area'].setValue('');
this.innovation.controls['state'].setValue(this.allvalues);
this.getIdsStudents();
    this.getStateStudents();
    this.studentForm.controls['areaStudents'].setValue('');
this.studentForm.controls['stateStudents'].setValue(this.allvaluesStudents);

const data ={
  type:'Faculty'
};
this.iicInnovationAmbassadorService.getFacultyData(data,this.currentPageNo,this.per_page)
.subscribe((data) => this.handleResponse(data));

const dataStudents ={
  type:'Students'
};
this.iicInnovationAmbassadorService.getStudentData(dataStudents,this.currentPageNoStudents,this.per_page_students)
.subscribe((dataStudents) => this.handleResponseStudent(dataStudents));

  }

  gotoContact(event){
    this.router.navigate(['/iic-contact-us'],{state: {data:event}});
  }

  getState(){
    this.iicInnovationAmbassadorService
    .getState()
    .subscribe((data) => this.setState(data));
  }
  getStateStudents(){
    this.iicInnovationAmbassadorService
    .getStateStudents()
    .subscribe((dataStudents) => this.setStateStudents(dataStudents));
  }
  setState(data){
    this.state = [];
    for (let i = 0; i < data.length; i++) {
      this.state.push(data[i]);
    }
  }
  setStateStudents(dataStudents){
    this.stateStudents = [];
    for (let i = 0; i < dataStudents.length; i++) {
      this.stateStudents.push(dataStudents[i]);
    }
  }

  getIds(){
    this.iicInnovationAmbassadorService
    .getState()
    .subscribe((data) => this.setId(data));

  }
  getIdsStudents(){
    this.iicInnovationAmbassadorService
    .getStateStudents()
    .subscribe((dataStudents) => this.setIdStudents(dataStudents));

  }

  setId(data){
    this.allvalues = [];
    for (let i = 0; i < data.length; i++) {
      this.allvalues.push(data[i]['id']);
    }
      this.StateModel=this.allvalues;
  }
  setIdStudents(dataStudents){
    this.allvaluesStudents = [];
    for (let i = 0; i < dataStudents.length; i++) {
      this.allvaluesStudents.push(dataStudents[i]['id']);
    }
      this.StateModelStudents=this.allvaluesStudents;
  }

  getFaculty(data) {
    this.changeData=data;
    this.iicInnovationAmbassadorService.getFacultyData(this.changeData,this.currentPageNo,this.per_page)
    .subscribe((data) => this.handleResponse(data));
}
getStudents(dataStudents) {
    this.changeDataStudents=dataStudents;
    this.iicInnovationAmbassadorService.getStudentData(this.changeDataStudents,this.currentPageNoStudents,this.per_page_students)
    .subscribe((dataStudents) => this.handleResponseStudent(dataStudents));
}

handleResponse(data) {
    this.theme = [];
  for (let i = 0; i < data['innovationAmbassdorDetails']['data'].length; i++) {
    this.theme.push(data['innovationAmbassdorDetails']['data'][i]);
  }
this.total_pages = data.innovationAmbassdorDetails.meta.pagination.total;
if (
    data['innovationAmbassdorDetails']['meta']['pagination']['current_page'] ==
    data['innovationAmbassdorDetails']['meta']['pagination']['total_pages']
  ) {
    this.firstPage = false;
    this.lastPage = true;
  } else {
    this.lastPage = false;
  }

  if (data['innovationAmbassdorDetails']['meta']['pagination']['current_page'] == 1) {
    this.firstPage = true;
    this.lastPage = false;
  } else {
    this.firstPage = false;
  }
}
handleResponseStudent(dataStudents) {
    this.themeStudents = [];
  for (let i = 0; i < dataStudents['innovationAmbassdorDetails']['data'].length; i++) {
    this.themeStudents.push(dataStudents['innovationAmbassdorDetails']['data'][i]);
  }
this.total_pages_students = dataStudents.innovationAmbassdorDetails.meta.pagination.total;
if (
    dataStudents['innovationAmbassdorDetails']['meta']['pagination']['current_page'] ==
    dataStudents['innovationAmbassdorDetails']['meta']['pagination']['total_pages']
  ) {
    this.firstPageStudents = false;
    this.lastPageStudents = true;
  } else {
    this.lastPageStudents = false;
  }

  if (dataStudents['innovationAmbassdorDetails']['meta']['pagination']['current_page'] == 1) {
    this.firstPageStudents = true;
    this.lastPageStudents = false;
  } else {
    this.firstPageStudents = false;
  }
}

nextPage(e: any) {
	if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1);
	  } else {
		this.currentPageNo = (e.pageIndex+1);
	  }
	this.per_page = e.pageSize;
  const data ={
    type:'Faculty'
  };
  this.getFaculty(data);
 }
 nextPageStudents(e: any) {
	if(this.per_page_students != e.pageSize) {

      this.currentPageNoStudents = (e.pageIndex+1);
	  } else {
		this.currentPageNoStudents = (e.pageIndex+1);
	  }
	this.per_page_students = e.pageSize;
  const dataStudents ={
    type:'Students'
  };
  this.getStudents(dataStudents);
 }
 onSubmit(data){
  data['type']='Faculty';
  this.currentPageNo=1;
  this.getFaculty(data);
}
onSubmitStudent(dataStudents){

  dataStudents['type']='Students';
  this.currentPageNoStudents=1;
  this.getStudents(dataStudents);
}

openDialog(data) {
  const dialogRef = this.dialog.open(IicContactUsComponent, {
    data: data
  });
  dialogRef.afterClosed().subscribe((result) => {

  });
}

}
