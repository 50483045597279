import { Component, OnInit } from '@angular/core';
import { InstituteAmbassadorService } from 'src/app/Services/institute-ambassador.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-institute-ambassador',
  templateUrl: './institute-ambassador.component.html',
  styleUrls: ['./institute-ambassador.component.css']
})
export class InstituteAmbassadorComponent implements OnInit {
  list: any[];
  page: any = 1;
  page2: any = 1;
  list2: any[];


  constructor(private instituteAmbassadorService: InstituteAmbassadorService) {
    this.list = [];
   this.list2 = [];
  }

  ngOnInit(): void {

    this.instituteAmbassadorService.getInstituteAmbassadorList().subscribe(
      data => {
        this.list = data['data']
      }
    )

    this.instituteAmbassadorService.getNonRegInstituteAmbassadorService().subscribe(
      data => {
        this.list2 = data['data']
      }
    )
  }

  public onPageChanged(event) {
    this.page = event;
  }

  public onPageChanged2(event) {
    this.page2 = event;
  }

  public downloadReport(name) {
    var endPoint = "certificate-ia/details?name="+name;
    this.instituteAmbassadorService
      .getReportForDownload(endPoint)
      .subscribe((data) => this.downloadFile(data, endPoint + '.pdf'));
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }
}
