<mat-tab-group mat-align-tabs="center">
  <mat-tab label="YUKTI Innovator's Transfer Form">
<div class="container-fluid">
  <div class="row" color="primary">
    <h2 class="ss" style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;margin: 0 15px 20px 15px;width:100%;"> YUKTI Innovator's Login Creation / Transfer of Innovation from Old Innovator to new YUKTI User</h2>
  </div>

	<!-- <div class="card">
    <div class="card-title"><p>Details of yukti challenge to be organized</p></div>
	</div> -->

  <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
    <table class="table table-hover" style="box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);">
      <tr>
        <td>
          Innovator Status
        </td>
        <td>
          <mat-radio-group id="typeOuter" formControlName="type" required aria-label="Type of Courses Offered by the Institute/University:" (change)="radioChange($event.value)">
            <mat-radio-button value="transfer" class="radioButSignUp" >Innovator Not Registered in YUKTI</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="registered" class="radioButSignUp">Innovator Registered in YUKTI</mat-radio-button>
            <mat-error *ngIf="iicImpactQuestions.controls.type.errors?.required">
              Type is required
            </mat-error>
          </mat-radio-group>
        </td>
      </tr>
      <tr *ngIf="isRegister">
        <td>
          New Team Lead
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>New Team Lead</mat-label>
            <mat-select autocomplete="off" formControlName="new_team_lead_id" (selectionChange)="onSelectBoxChange($event.value)">
              <!-- <mat-option>Select</mat-option> -->
              <mat-option value="{{value.id}}" *ngFor="let value of new_team_lead_data">{{value.team_lead_name}}, {{value.team_lead_email}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      
      <tr *ngIf="isNew">
        <td>
          Name
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input matInput autocomplete="off" placeholder="Name" formControlName="name" type="text">
            <mat-error *ngIf="iicImpactQuestions.controls.name.errors?.required">Name
              is required
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isNew">
        <td>
          Email Id
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email Id</mat-label>
            <input matInput autocomplete="off" placeholder="Email Id" formControlName="email" type="text" >
            <mat-error *ngIf="iicImpactQuestions.controls.email.errors?.required">Email Id
              is required
            </mat-error>
            <mat-error *ngIf="iicImpactQuestions.controls.email.hasError('invalidEmail')">Invalid
              email address</mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isNew">
        <td>
          Mobile Number
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Mobile Number</mat-label>
            <input matInput autocomplete="off" placeholder="Mobile Number" formControlName="mobile_number" type="text" minlength="10" maxlength="10" (keypress)="isNumKey($event)">
            <mat-error *ngIf="iicImpactQuestions.controls.mobile_number.errors?.required">Mobile Number is required </mat-error>
            <mat-error *ngIf="iicImpactQuestions.controls.mobile_number.errors?.minlength">Mobile Number should be of 10 digits </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isNew">
        <td>
          Gender
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error *ngIf="iicImpactQuestions.controls.gender.errors?.required"> Gender is required </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isNew">
        <td>
          Current Engagement with the Institute
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label> Current Engagement with the Institute</mat-label>
            <mat-select autocomplete="off" placeholder="Current Engagement with the Institute" formControlName="user_type">
            <mat-option value="Student">Student</mat-option>
            <mat-option value="Faculty">Faculty/Staff</mat-option>
            <mat-option value="Alumni">Graduated/Alumni Member</mat-option>
            <mat-option value="Entrepreneur">Entrepreneur at Incubation Unit/Institute</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isRegister">
        <td>
          Choose the Innovations that are all needed to be transferred to the above mentioned Innovator
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Choose the Innovations for transfer</mat-label>
            <mat-select autocomplete="off" formControlName="prototype" multiple>
              <!-- <mat-option>Select</mat-option> -->
              <mat-option value="{{value.id}}" *ngFor="let value of protoTypeList">{{value.team_id}} - {{value.idea_title}}, {{value.submission_type}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isNew">
        <td>
          Choose the Innovations that are all needed to be transferred to the above mentioned Innovator
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Choose the Innovations for transfer</mat-label>
            <mat-select autocomplete="off" formControlName="prototype" multiple>
              <!-- <mat-option>Select</mat-option> -->
              <mat-option value="{{value.id}}" *ngFor="let value of protoTypeList1">{{value.team_id}} - {{value.idea_title}}, {{value.submission_type}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          &nbsp;
        </td>
        <td>
          <button mat-raised-button color='primary' class="btn btn-success float-right" [disabled]='iicImpactQuestions.invalid'>Submit</button>
        </td>
      </tr>
    </table>
	</form>
</div>
</mat-tab>
<mat-tab label="YUKTI Innovator's Transfer List">
  <app-transfer-existing-innovator-list></app-transfer-existing-innovator-list>
</mat-tab>
</mat-tab-group>
