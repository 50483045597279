<mat-tab-group mat-align-tabs="center" >
<mat-tab label="Refer Institute for IIC Registration" >
<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Refer Institute for IIC Registration</h2>
    </div>

	<div class="card">
        <div class="card-title"><p>List of Referred Institute by you.</p></div>
	</div>
<div class="refrredBox">
 <div class="rws-listpage">
  <p><a class="btn btn-primary referralBut" routerLink='/institute/add-referral-institute/add'>Add New</a></p>
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Institute Name</th>
            <th scope="col">Referred by</th>
            <th scope="col">Aishe Code</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Status of Acceptance</th>
            <th scope="col">IIC Registration Status</th>
            <th scope="col">Action</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let nomination of iicrepository; index as i" >
            <td>{{i+1}}</td>
            <td *ngIf="nomination?.email_verification_token=='Verified'" style="color: #155724;
            background-color: #d4edda; border-color: #c3e6cb;">
            <a href ="/institute/institute-mm-dashboard/{{nomination?.userId}}" target="_blank">{{nomination?.instituteName}}</a>
            </td>
            <td *ngIf="nomination?.email_verification_token!='Verified'">
              {{nomination?.instituteName}}
            </td>
            <td>
              {{nomination?.userId}}
            </td>
            <td>{{nomination?.aishe_code}}</td>
            <td>{{nomination?.instituteCity}}</td>
            <td>{{nomination?.stateName}}</td>
            <td *ngIf="nomination?.email_verification_token!='Verified'" class="color: #004085;
            background-color: #cce5ff; border-color: #b8daff;">
              Pending
            </td>
            <td *ngIf="nomination?.email_verification_token=='Verified'" style="color: #155724;
            background-color: #d4edda; border-color: #c3e6cb;">
              Accepted
            </td>
            <td>
              <span *ngIf="nomination?.applicationStatus=='New'">Pending</span>
              <span *ngIf="nomination?.applicationStatus=='Reopened'">In Process</span>
              <span *ngIf="nomination?.applicationStatus=='Disapproved'">Disapproved</span>
              <span *ngIf="nomination?.applicationStatus=='Approved'">Approved</span>
              <span *ngIf="nomination?.userId===null">NA</span>
            </td>

            <td style="display:inline;">
              <p *ngIf="nomination?.applicationStatus=='New' && nomination?.email_verification_token!='Verified'">
                <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn" class="btn-tbl-edit">
                  <mat-icon>edit</mat-icon> 
                  <!-- Edit -->
                  </button>
              </p>

              <p *ngIf="nomination?.applicationStatus=='New' && nomination?.email_verification_token!='Verified'">
                <button (click)="onClickEvent(nomination?.id, 'Email')"   color="warn" class="btn-tbl-mail">
                  <mat-icon>email</mat-icon> 
                  <!-- Send Email -->
                  </button>
              </p>

              <p *ngIf="nomination?.applicationStatus=='New' && nomination?.email_verification_token!='Verified'">
                <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')"   color="warn" class="btn-tbl-delete">
                  <mat-icon>delete</mat-icon>
                  <!-- Remove -->
                  </button>
              </p>
            </td>
        </tr>
        <tr *ngIf="iicrepository?.length== 0" col="7">
            <h3 style="text-align: center">No Data Found</h3>
        </tr>
    </tbody>
</table>

 </div>

 </div>

</div>
</mat-tab>
<mat-tab label="Non-IIC Institute">
  <app-institute-assigner-detail></app-institute-assigner-detail>
</mat-tab>
<mat-tab label="Inactive IIC">
  <app-institute-inactive-iic-detail></app-institute-inactive-iic-detail>
</mat-tab>
</mat-tab-group>