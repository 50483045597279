
<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>
<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Photo Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Gallery</a></li>
	<li class="breadcrumb-item"><a href="gallery/photo-gallery">Photo Gallery</a></li>
    <li class="breadcrumb-item active" aria-current="page">Regional Meet Nagpur 2022</li>
  </ul>
</nav>


<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Regional Meet held on 29<sup>th</sup> Jul, 2022 at Nagpur</h2>
				<div>
					<a class="backButton" href="gallery/photo-gallery">Back to Gallery</a>
				</div>
			</div>
		</div>
		</div>	  
		  
	</div>	
 </div>

<div class="container">
		<div class="row">
			<div class="gallery col-md-12">
				<div class="row">
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-1.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-1.jpg" alt="image">
						</a>
					</div>
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-2.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-2.jpg" alt="image">
						</a>
					</div> -->
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-13.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-13.jpg" alt="image">
						</a>
					</div> -->
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-3.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-3.jpg" alt="image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-4.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-4.jpg" alt="image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-5.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-5.jpg" alt="image">
						</a>
					</div>
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-6.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-6.jpg" alt="image">
						</a>
					</div> -->
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-7.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-7.jpg" alt="image">
						</a>
					</div> -->
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-8.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-8.jpg" alt="image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-9.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-9.jpg" alt="image">
						</a>
					</div>
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-10.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-10.jpg" alt="image">
						</a>
					</div> -->																					
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-11.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-11.jpg" alt="image">
						</a>
					</div> -->
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-12.jpg" data-caption="">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/nagpur/ng-12.jpg" alt="image">
						</a>
					</div> -->
					
				</div>
			</div>
			
		</div>
	</div> 

<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
