import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-bifurcation-score',
  templateUrl: './bifurcation-score.component.html',
  styleUrls: ['./bifurcation-score.component.css']
})
export class BifurcationScoreComponent implements OnInit {
  formData: any;
  currentData: any;
  constructor(private instituteMyCouncilService: InstituteMyCouncilService) {

  }

  // constructor(private instituteMyCouncilService: InstituteMyCouncilService) {
  //   this.instituteMyCouncilService
  //     .getRewardPoints()
  //     .subscribe((data) => this.handleResponse(data));
  // }


  ngOnInit(): void {



  }



}
