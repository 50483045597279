import { Component, OnInit } from '@angular/core';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-institute-stage-iii-evaluation',
  templateUrl: './institute-stage-iii-evaluation.component.html',
  styleUrls: ['./institute-stage-iii-evaluation.component.css']
})
export class InstituteStageIIIEvaluationComponent implements OnInit {

  evaluation: any[];

  constructor(private innovationService: InstituteInnovationContestService) { }

  ngOnInit(): void {
    this.innovationService.getStage3Evaluation().subscribe(
      data => this.handleData(data)
    )
  }
  handleData(data) {
    this.evaluation = [];
    for (let i = 0; i < data['data'].length; i++) {
      this.evaluation.push(data['data'][i]);
    }
  }

}
