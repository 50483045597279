
<div class="container">
  <div class="instMyCouncilModal">
      <div class="container">
        <div class="row">
          <div class="col-md-12"
            style="background-color: #fff;color: #000 !important;font-size: 27px;">
            <p class="hdr bifuracationPara">Performance Report</p>
            <div style="padding: 0 0 20px 0; margin: 0 0 20px 0;">
              <ul>
                <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Cumulative Performance Report for the Quarter 1, Quarter 2, Quarter 3 & Quarter 4 for the academic year 2019-20</b></li>
                <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">Bifurcation of Score and Reward Points</li>
                <li class="hdr" style="font-size: 17px;margin-bottom: 20px;">
                  <div class="row">
                    <div class="col-md-12 NewScoreList">
                      <span class="mic_btn mic_totalmarks">Score:{{formData?.total_marks}}</span>
                      <span class="mic_btn mic_rewardpoints">Reward:{{formData?.totalRewards}}</span>
                      <span class="mic_btn mic_rating">Rating:{{formData?.rating}}</span>
                    </div>
                  </div>

                </li>
              </ul>

              <table class="table table-hover" style="margin-bottom: 0px;">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="20%">Activity Type
                    </th>
                    <th class="hdr scoreTH" width="20%">Total Number
                      of
                      Activities Approved</th>
                    <th class="hdr scoreTH" width="20%">Total
                      Threshold
                      Number of Activities</th>
                    <th class="hdr scoreTH" width="20%">Score (for
                      minimum
                      prescribed activities)</th>
                    <th class="hdr scoreTH" width="25%">Adjusted
                      Cumulative
                      Reward Points For Additional Activities Beyond the Threshold Numbers for Each
                      Category (Reflection in multiple of 100 with activity score)</th>
                  </tr>
                  <tr>
                    <td class="hdr"
                      style="font-weight: bold;">
                      IIC Calendar Activity (Score for 1 activity=4.16) Minimum 12 activities</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.iicCount}}</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      12</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.iic_total_marks}}<br>
                      <span style="color:#1de9b6 !important;">(Max Score=50)</span>
                    </td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.totalRewardsIIC}}
                    </td>

                  </tr>
                  <tr>
                    <td class="hdr"
                      style="font-weight: bold;">
                      MIC driven Activity (Score for 1 activity=0.833) Minimum 24 activities</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.micMarksCount}}</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      24</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.mic_total_marks}} <br>
                      <span style="color:#1de9b6 !important;">(Max Score=20)</span>
                    </td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.totalRewardsMIC}}

                    </td>

                  </tr>
                  <tr>
                    <td class="hdr"
                      style="font-weight: bold;">
                      Self-driven Activity (Score for 1 activity=2.5) Minimum 12 activities</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.selfCount}}</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      12</td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.self_total_marks}}
                      <br>
                      <span style="color:#1de9b6 !important;">(Max Score=30)</span>
                    </td>
                    <td class="hdr" style="color:red !important;font-weight: 900;">
                      {{formData?.totalRewardsSelf}}
                    </td>

                  </tr>
                </tbody>
              </table>
              <p class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Weightage of each activity type:</b></p>
              <table class="table table-hover" style="margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th class="hdr scoreTH">Activity Type</th>
                    <th class="hdr scoreTH">Weightage in %</th>
                    <th class="hdr scoreTH">Q1</th>
                    <th class="hdr scoreTH">Q2</th>
                    <th class="hdr scoreTH">Q3</th>
                    <th class="hdr scoreTH">Q4</th>
                    <th class="hdr scoreTH">Total Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>IIC Annual Calendar Plan</td>
                    <td>50%</td>
                    <td>12.5</td>
                    <td>12.5</td>
                    <td>12.5</td>
                    <td>12.5</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Self-Driven Activities</td>
                    <td>30%</td>
                    <td>7.5</td>
                    <td>7.5</td>
                    <td>7.5</td>
                    <td>7.5</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>MIC Driven Activities</td>
                    <td>20%</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td>Total Score</td>
                    <td>100%</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>100</td>
                  </tr>
                </tbody>
              </table>
              <p class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Following methodology has been used for calculation of quarterly score:</b></p>
              <p class="hdr" style="font-size: 17px;margin-bottom: 20px;">Scores are calculated based on the minimum prescribed activities in each “activity type”. IIC earn Reward Points for conducting more than prescribed minimum activities under each “activity type”, which is effective towards 5th star. </p><br/>
              <p class="hdr hdrScore scoreHdrNew" style="font-size: 17px;margin-bottom: 20px;">Score Matrix for IIC 2019-20 (All 4 quarters): </p>

              <div>
                <img src="assets/img/socre_formula.jpg" class="img-responsive matrixIIC" />
              </div>

              <span class="hdr" style="text-align:center;font-size: 12px;display: block;"><b>Note:</b> IIC
                calendar year is divided in 4 quarters. IICs can earn maximum 25 marks in each
                quarter.</span>
              <hr style="margin:0px !important;">
              <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 25px; margin-bottom: 20px;">Effective score range for Star allocation from Quarter 1 to Quarter 4:</span>
              <table style="width:50% !important;margin: auto;margin-bottom: 20px;text-align: center;"
                class="table table-hover">
                <tbody>
                  <tr>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Starting Score range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Ending Score range</th>
                    <th class="hdr scoreTH" width="25%" style="text-align:center;">Star allocation</th>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">>0</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">24.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">One Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">24.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">34.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">One and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">34.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">49. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Two Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">49.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">64. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Two and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">64.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">74.166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">74.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">84. 166</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Three and a Half Star</td>
                  </tr>
                  <tr>
                    <td class="hdr scoStarConv" style="font-weight: bold;">84.99</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">100</td>
                    <td class="hdr scoStarConv" style="font-weight: bold;">Four Star</td>
                  </tr>
                </tbody>
              </table>
              <p class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Parameters for the calculation of 5th Star:</b></p>
              <table class="table table-hover" style="margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th class="hdr scoreTH">S.No</th>
                    <th class="hdr scoreTH">5th star Parameters</th>
                    <th class="hdr scoreTH">Weightage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Regional Mentoring Session hosting</td>
                    <td>5 marks</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Innovation Ambassador Training Hosting</td>
                    <td>30 marks</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Innovation Contest 2020 Participation</td>
                    <td>12.5</td>
                  </tr>
                  <tr>
                    <td>3.1 </td>
                    <td>50- ideas</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>3.2 </td>
                    <td>25 PoCs</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>3.3 </td>
                    <td>15 prototype</td>
                    <td>10 marks</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Reward points</td>
                    <td>50 marks *</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Finalist of PoC contest</td>
                    <td>10</td>
                  </tr>

                </tbody>
              </table>

              <table class="table table-hover" style="margin-bottom: 0px;">
                <thead>
                  <tr>
                    <th class="hdr scoreTH">Score Range</th>
                    <th class="hdr scoreTH"> Star</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Score greater than equal to 50 marks</td>
                    <td>Full star</td>
                  </tr>
                  <tr>
                    <td>10 greater than equal to score greater than 49.9 marks</td>
                    <td>0.5 star</td>
                  </tr>
                  <tr>
                    <td>Score greater than 9.9marks</td>
                    <td>0 star</td>
                  </tr>

                </tbody>
              </table>

              <div>
                <img src="assets/img/5star.jpg" class="img-responsive matrixIIC" />
              </div>

            </div>
          </div>
        </div>

      </div>
  </div>
</div>

<!-- <div class="container">
<div class="instMyCouncilModal">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="background-color: #fff;color: #000 !important;font-size: 27px;">
          <p class="hdr bifuracationPara">Performance Report</p>
          <div style="padding: 0 0 20px 0; margin: 0 0 20px 0;">
            <ul>
              <li class="hdr" style="font-size: 17px;margin-bottom: 2px;"><b>Quarter 1 & Quarter 2 Cumulative Score</b></li>
              <li class="hdr" style=";font-size: 17px;margin-bottom: 20px;">Bifurcation of Score and Reward Points</li>
              <li class="hdr" style=";font-size: 17px;margin-bottom: 20px;">
                <div class="row">
                  <div class="col-md-6 scoreBifuraction">
                    <p>Score:{{formData?.total_marks}}</p>
                  </div>
                  <div class="col-md-6 rewardBifuracation">
                    <p>Reward:{{formData?.totalRewardsInDecimal}}</p>
                  </div>
                </div>
              </li>
            </ul>
            <table class="table table-hover" style="margin-bottom: 0px;">
              <tbody>
                <tr>
                  <th class="hdr" width="20%">Activity Type</th>
                  <th class="hdr" width="20%">Total Number of Activities Approved</th>
                  <th class="hdr" width="20%">Total Threshold Number of Activities</th>
                  <th class="hdr" width="20%">Score (for minimum prescribed activities)</th>
                  <th class="hdr" width="25%">Adjusted Cumulative Reward Points For Additional Activities Beyond the Threshold Numbers for Each Category (Reflection in multiple of 100 with activity score)</th>
                </tr>
                <tr>
                  <td class="hdr" tyle="font-weight: bold;"> IIC Calendar Activity (Score for 1 activity=4.16) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.iicCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.iic_total_marks}}<br><span style="color:#1de9b6 !important;">(Max Score=24.96)</span></td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.ScoreIIC}}</td>
                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;">MIC driven Activity (Score for 1 activity=1.67) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">{{formData?.micCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.mic_total_marks}} <br> <span style="color:#1de9b6 !important;">(Max Score=10.02)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.scoreMIC}}
                  </td>
                </tr>
                <tr>
                  <td class="hdr" style="font-weight: bold;">Self-driven Activity (Score for 1 activity=2.5) Minimum 6 activities</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.selfCount}}</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">6</td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.self_total_marks}}
                    <br>
                    <span style="color:#1de9b6 !important;">(Max Score=15)</span>
                  </td>
                  <td class="hdr" style="color:red !important;font-weight: 900;">
                    {{formData?.ScoreSelf}}
                  </td>
                </tr>
              </tbody>
            </table>
            <span class="hdr" style="text-align:center;font-size: 12px;display: block;"><b>Note:</b> IIC calendar year is divided in 4 quarters. IICs can earn maximum 25 marks in each quarter.</span>
            <hr style="margin:0px !important;">
            <span class="hdr" style="text-align:center;font-size: 18px;font-weight: 900; display: block;margin-top: 25px; margin-bottom: 20px;">Score to star Conversion:</span>
            <table style="width:30% !important;margin: auto;margin-bottom: 20px;" class="table table-hover">
              <tbody>
                <tr>
                  <th class="hdr" width="25%" style="text-align:center;">Score</th>
                  <th class="hdr" width="25%" style="text-align:center;">Star</th>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">0-6.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Zero Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">7 – 14.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Half Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">15-24.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">One Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">25-34.99</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">One and a Half Star</td>
                </tr>
                <tr>
                  <td class="hdr scoStarConv" style="font-weight: bold;">35-50</td>
                  <td class="hdr scoStarConv" style="font-weight: bold;">Two Star</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
</div> -->

