<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">IIC Downloads</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC Downloads</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Resources</a></li>
    <li class="breadcrumb-item active" aria-current="page">Downloads</li>
  </ul>
</nav>

<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>Institution's Innovation Council Download</h2>
				</div>
			</div>
		</div>
	</div>
</div>


	
<div id="content" class="content container">

 <table align="center"  style="margin-left:auto;margin-right:auto;" class="table-hover table-responsive tableMobRes">
 <tbody>
 <tr *ngFor="let item of downloadsDetails| paginate: { itemsPerPage: 5, currentPage: page }; let index = index;">
	<!-- <tr *ngFor="let item of downloadsDetails let index = index"> -->
		<td style="width: 100%;">
			<b>{{item?.title}}</b>
		</td>
		<td  *ngIf="item?.report_upload">
		<a href="{{ baseUrl}}/{{item?.report_upload}}" target="_blank" class="downLinkDownload downLinkDownloadMobRes">Download</a>
		</td>
		<td  *ngIf="!item?.report_upload">
		N/A
		</td>
	</tr>
</tbody>
<div class="notificationPagination"><pagination-controls class="annular-pagination float-right" maxSize="3"
    (pageChange)="onPageChanged($event)"></pagination-controls> </div>

</table>

<!--<table align="center"  style="margin-left:auto;margin-right:auto;" class="table-hover">
	<tr>
		<td>
			<b>Recommended List of IIC-Institutes for the funding support to organise two Impact	Lecture Sessions 2019-20</b>
		</td>
		<td><a href="assets/doc/Recommendation-of-Impact-Lecture-Series.pdf" target="_blank" class="downLinkDownload">Download</a></td>
	</tr>
	<tr>
		<td>
			<b>View and download 2018-19 Reports</b>
		</td>
		<td><a routerLink="/institute/dashboard/18-19" class="downLinkDownload">Download</a></td>
	</tr>
	<tr>
		<td>
			<b>Guidelines to form IIC Council</b>
		</td>
		<td><a href="assets/html/index.html" target="_blank" class="downLinkDownload">Download</a></td>
	</tr>
	<tr>
		<td>
			<b>IIC 2.0 Calendar Plan</b>
		</td>
		<td><a href="assets/doc/Prescribed Annual Calendar Activities for IICs - 2019-20 (1).pdf" target="_blank" class="downLinkDownload">Download</a></td>
	</tr>
	<tr>
		<td>
			<b>IIC Logo</b>
		</td>
		<td><a href="assets/doc/iiclogo.png" target="_blank" class="downLinkDownload">Download</a></td>
	</tr>
	<tr>
		<td>
			<b>National Innovation and Startup policy 2019 for students and faculties</b>
		</td>
		<td><a href="assets/doc/startup_policy_2019.pdf" target="_blank" class="downLinkDownload">Download</a></td>
	</tr>


</table>-->

  </div>



<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
