<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Faculty Status" *ngIf="ForWhom == 'Institute'">
    <ng-template mat-tab-label> Faculty Training Status </ng-template>
    <div class="container">
      <!-- <mat-tab-group> -->
        <!-- mat-align-tabs="center" class="menteeLabelBut" -->
        <!-- <mat-tab> -->
          <!-- style="padding-bottom: 15px" -->
          <!-- <ng-template mat-tab-label> Faculty IA Nomination </ng-template> -->
           <mat-card
            style="margin-bottom: 49px; display: none; margin-top: 15px"
            *ngIf="this.showStatus == '1'"
          >
            <p>
              Your institute is eligible to Nominate for Innovation Ambassador
              scheme. If your institute is willing to participate, please
              nominate a coordinator.
            </p>
            <br />
            <p>
              Nominate teaching/non-teaching IIC Member for the Innovation
              Ambassador Training. IIC Members who have already received
              Innovation Ambassador training and registered their public profile
              are eligible to participate in Advanced Level and other members
              can participate in Basic Level Training.
            </p>
            <br />
            <p><strong>Advance Level:</strong></p>

            <p>
              <a
                routerLink="/institute/institutes-list"
                class="registerInstiAnchor1"
                >View the list of IIC Members participated in Innovation
                Ambassador Training Programs.</a
              >
              For advanced level training innovation ambassador should enroll in
              the IA portal prior to nomination
            </p>
            <br />

            <p><strong>Foundation Level:</strong></p>
            <p>
              Maximum 20 new IIC members (Teaching/Non-Teaching) and 10 students
              can be nominated
            </p>
            <br />
            <p>
              Eligibility criteria to participate in Innovation Ambassador
              Training:
            </p>
            <p>
              1. The IIC Institution must have completed MY COUNCIL for the
              current IIC Calendar year.
              <mat-icon style="color: green" *ngIf="this.showStatus == '1'"
                >done</mat-icon
              >
              <mat-icon style="color: red" *ngIf="this.showStatus == '0'"
                >clear</mat-icon
              >
            </p>
            <p>
              For more details:
              <a
                href="https://iic.mic.gov.in/iic-innovation-ambassador2021"
                target="_blank"
              >
                https://iic.mic.gov.in/iic-innovation-ambassador2021</a
              >
            </p>
          </mat-card> 

          <mat-card style="margin-bottom: 49px" *ngIf="this.showStatus == '0'">
            <p>
              Your institute is not eligible to Nominate for Innovation
              Ambassador scheme.
            </p>
            <br />
            <p>
              Nominate teaching/non-teaching IIC Member for the Innovation
              Ambassador Training. IIC Members who have already received
              Innovation Ambassador training and registered their public profile
              are eligible to participate in Advanced Level and other members
              can participate in Basic Level Training.
            </p>
            <br />
            <p>
              All registered Innovation Ambassadors can be nominated for
              Advanced Level Training. Maximum 10 members can be nominated for
              Basic Level Training
            </p>
            <br />
            <p>
              Eligibility criteria to participate in Innovation Ambassador
              Training:
            </p>
            <p>
              1. The IIC Institution must have completed MY COUNCIL for the
              current IIC Calendar year.
              <mat-icon style="color: green" *ngIf="this.showStatus == '1'"
                >done</mat-icon
              >
              <mat-icon style="color: red" *ngIf="this.showStatus == '0'"
                >clear</mat-icon
              >
            </p>
            <p>
              For more details:
              <a
                href="https://iic.mic.gov.in/iic-innovation-ambassador2021"
                target="_blank"
              >
                https://iic.mic.gov.in/iic-innovation-ambassador2021</a
              >
            </p>
          </mat-card>

          <div *ngIf="this.showStatus == '1'">
            <!-- List starts -->
            <div class="row" color="primary">
              <h2 class="ss">
                Training Status of Nominated Faculty Innovation Ambassador
              </h2>
            </div>
            <div class="">
              <div class="">
                <table class="table table-hover">
                  <thead class="facultyTable">
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact</th>
                      <!-- <th scope="col">Session Status</th> -->
                      <!-- <th scope="col">Type</th> -->
                      <th scope="col">Status of Basic Training</th>
                      <th scope="col">Status of Advance Training</th>
                      <th scope="col">Status of Reskilling Training</th>
                      <th scope="col">Status of Upskilling Training</th>
                      <!-- <th scope="col">No. of Sessions Watched</th> -->
                      <th scope="col">Last login</th>
                      <!-- <th scope="col">Phase</th> -->
                      <th scope="col" style="width: 10%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let nomination of nominationArray; index as i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ nomination?.name }}</td>
                      <td>{{ nomination?.email }}</td>
                      <td style="white-space: nowrap">
                        {{ nomination?.contact }}
                      </td>
                      <td>
                        <p *ngIf="nomination?.is_complete_basic == 0">
                          <i
                            class="fa fa-times-circle icon-style"
                            aria-hidden="true"
                            title="Not Started"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_basic == 1">
                          <i
                            class="fa fa-check-circle-o icon-style"
                            aria-hidden="true"
                            title="Completed"
                          ></i>
                        </p>
                        <p
                          *ngIf="nomination?.is_complete_basic == 2"
                          (click)="
                            openDialogfacultyCount(
                              nomination?.is_complete_basic == 2
                            )
                          "
                        >
                          <i
                            class="fa fa-spinner icon-style"
                            aria-hidden="true"
                            title="Ongoing"
                          ></i>
                        </p>
                      </td>

                      <!-- <td> 
                                                <p *ngIf="nomination?.type==1">Faculty</p>
                                                <p *ngIf="nomination?.type==2">Student</p></td> -->
                      <!-- <td> -->
                      <!-- <p *ngIf="nomination?.Participate > 0">{{nomination?.total_assessment_submission}}</p> -->
                      <!-- <p *ngIf="nomination?.Participate<13">Offline Assessments</p> -->

                      <!-- </td> -->
                      <td>
                        <p *ngIf="nomination?.is_complete_advance == 0">
                          <i
                            class="fa fa-times-circle icon-style"
                            aria-hidden="true"
                            title="Not Started"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_advance == 1">
                          <i
                            class="fa fa-check-circle-o icon-style"
                            aria-hidden="true"
                            title="Completed"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_advance == 2">
                          <i
                            class="fa fa-spinner icon-style"
                            aria-hidden="true"
                            title="Ongoing"
                          ></i>
                        </p>
                      </td>

                      <td>
                        <p *ngIf="nomination?.is_complete_YIC == 0">
                          <i
                            class="fa fa-times-circle icon-style"
                            aria-hidden="true"
                            title="Not Started"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_YIC == 1">
                          <i
                            class="fa fa-check-circle-o icon-style"
                            aria-hidden="true"
                            title="Completed"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_YIC == 2">
                          <i
                            class="fa fa-spinner icon-style"
                            aria-hidden="true"
                            title="Ongoing"
                          ></i>
                        </p>
                      </td>
                    <!-- Upskill 15-10-24 -->
                    <td>
                      <!-- testing -->
                      <p *ngIf="nomination?.iaTrainings == 0">
                        <i
                          class="fa fa-times-circle icon-style"
                          aria-hidden="true"
                          title="Not Started"
                        ></i>
                      </p>
                      <p *ngIf="nomination?.iaTrainings > 29">
                        <i
                          class="fa fa-check-circle-o icon-style"
                          aria-hidden="true"
                          title="Completed"
                        ></i>
                      </p>
                      <p *ngIf="(nomination?.iaTrainings > 0) && (nomination?.iaTrainings < 29)">
                        <i
                          class="fa fa-spinner icon-style"
                          aria-hidden="true"
                          title="Ongoing"
                        ></i>
                      </p>
                    </td>
                     <!-- end upskill -->
                      <td style="white-space: nowrap">
                        <p *ngIf="nomination?.total_login_count > 0">
                          {{ nomination?.created_on | date : "dd-MM-yyyy" }}
                        </p>
                      </td>

                      <td>
                        <!-- <button *ngIf="nomination?.total_assessment_submission==0" mat-fab color="primary" (click)="onDeleteClick(nomination?.email,2)" style="background-color: #de2727;width: 100%;margin-bottom: 10px;border-radius: 50px;">Remove Nomination
                                                </button> -->
                        <button
                          *ngIf="nomination?.total_assessment_submission == 0"
                          (click)="onEditClick(nomination)"
                          class="mentorButEdit mat-focus-indicator mat-raised-button mat-button-base mat-primary"
                          color="primary"
                          mat-raised-button=""
                          ng-reflect-color="primary"
                          style="margin: 0; padding: 0 1.1em"
                        >
                          <span class="mat-button-wrapper">
                            <mat-icon
                              class="mat-icon notranslate material-icons mat-icon-no-color"
                              role="img"
                              aria-hidden="true"
                              data-mat-icon-type="font"
                              >create</mat-icon
                            >
                            Edit
                          </span>
                        </button>

                        <button
                          *ngIf="nomination?.total_assessment_submission >= 0"
                          (click)="onDeleteClick(nomination?.email, 2)"
                          class="mat-focus-indicator mat-raised-button mat-button-base mat-warn"
                          color="warn"
                          mat-raised-button=""
                          ng-reflect-color="warn"
                          style="margin-bottom: 10px"
                        >
                          <span class="mat-button-wrapper">
                            <mat-icon
                              class="mat-icon notranslate material-icons mat-icon-no-color"
                              role="img"
                              aria-hidden="true"
                              data-mat-icon-type="font"
                              >delete</mat-icon
                            >
                            Delete
                          </span>
                        </button>

                        <!--  <button *ngIf="nomination?.total_assessment_submission>=0" mat-fab color="primary" (click)="onEditClick(nomination?.email,2)" style="background-color:green;border-radius:50px;width: 100%;">Edit
                                                </button> -->
                      </td>
                    </tr>
                    <tr *ngIf="nominationArray?.length <= 0" col="4">
                      <h3 style="text-align: center">No Data Found</h3>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="row">
                                <div class="col-md-12">
                                    <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)" [length]="total_pages"
                                        [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                                    </mat-paginator>
                                </div>
                            </div> -->
            </div>
          </div>
        <!-- </mat-tab> -->
        <!-- List ends -->
      <!-- </mat-tab-group> -->
    </div>
  </mat-tab>

  <mat-tab label="Student Status">
    <ng-template mat-tab-label> Student Training Status </ng-template>
    <div class="container">
      <!-- <mat-tab-group mat-align-tabs="center" class="menteeLabelBut"> -->
        <!-- <mat-tab style="padding-bottom: 15px"> -->
          <!-- <ng-template mat-tab-label> Student IA Nomination </ng-template> -->
          <mat-card
            style="margin-bottom: 49px; display: none; margin-top: 15px"
            *ngIf="this.showStatus == '1'"
          >
            <p>
              Your institute is eligible to Nominate for Innovation Ambassador
              scheme. If your institute is willing to participate, please
              nominate a coordinator.
            </p>
            <br />
            <p>
              Nominate teaching/non-teaching IIC Member for the Innovation
              Ambassador Training. IIC Members who have already received
              Innovation Ambassador training and registered their public profile
              are eligible to participate in Advanced Level and other members
              can participate in Basic Level Training.
            </p>
            <br />
            <p><strong>Advance Level:</strong></p>

            <p>
              <a
                routerLink="/institute/institutes-list"
                class="registerInstiAnchor1"
                >View the list of IIC Members participated in Innovation
                Ambassador Training Programs.</a
              >
              For advanced level training innovation ambassador should enroll in
              the IA portal prior to nomination
            </p>
            <br />

            <p><strong>Foundation Level:</strong></p>
            <p>
              Maximum 20 new IIC members (Teaching/Non-Teaching) and 10 students
              can be nominated
            </p>
            <br />
            <p>
              Eligibility criteria to participate in Innovation Ambassador
              Training:
            </p>
            <p>
              1. The IIC Institution must have completed MY COUNCIL for the
              current IIC Calendar year.
              <mat-icon style="color: green" *ngIf="this.showStatus == '1'"
                >done</mat-icon
              >
              <mat-icon style="color: red" *ngIf="this.showStatus == '0'"
                >clear</mat-icon
              >
            </p>
            <p>
              For more details:
              <a
                href="https://iic.mic.gov.in/iic-innovation-ambassador2021"
                target="_blank"
              >
                https://iic.mic.gov.in/iic-innovation-ambassador2021</a
              >
            </p>
          </mat-card>

          <mat-card style="margin-bottom: 49px" *ngIf="this.showStatus == '0'">
            <p>
              Your institute is not eligible to Nominate for Innovation
              Ambassador scheme.
            </p>
            <br />
            <p>
              Nominate teaching/non-teaching IIC Member for the Innovation
              Ambassador Training. IIC Members who have already received
              Innovation Ambassador training and registered their public profile
              are eligible to participate in Advanced Level and other members
              can participate in Basic Level Training.
            </p>
            <br />
            <p>
              All registered Innovation Ambassadors can be nominated for
              Advanced Level Training. Maximum 10 members can be nominated for
              Basic Level Training
            </p>
            <br />
            <p>
              Eligibility criteria to participate in Innovation Ambassador
              Training:
            </p>
            <p>
              1. The IIC Institution must have completed MY COUNCIL for the
              current IIC Calendar year.
              <mat-icon style="color: green" *ngIf="this.showStatus == '1'"
                >done</mat-icon
              >
              <mat-icon style="color: red" *ngIf="this.showStatus == '0'"
                >clear</mat-icon
              >
            </p>
            <p>
              For more details:
              <a
                href="https://iic.mic.gov.in/iic-innovation-ambassador2021"
                target="_blank"
              >
                https://iic.mic.gov.in/iic-innovation-ambassador2021</a
              >
            </p>
          </mat-card>

          <div *ngIf="this.showStatus == '1'">
            <!-- List starts -->
            <div class="row" color="primary">
              <h2 class="ss">
                Training Status of Nominated Student Innovation Ambassador 
              </h2>
            </div>
            <div class="">
              <div class="">
                <table class="table table-hover">
                  <thead class="facultyTable">
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Basic Training Status</th>
                      <th scope="col">Advance Training Status</th>
                      <th scope="col">Re-Skilling Training Status</th>
                      <th scope="col">UpSkilling Training Status</th>
                      <!-- <th scope="col">Type</th> -->
                      <!-- <th scope="col">No. of Assessments Submitted</th> -->
                      <!-- <th scope="col">No. of Sessions Watched</th> -->
                      <th scope="col">Last login</th>
                      <!-- <th scope="col">Phase</th> -->
                      <th scope="col" style="width: 10%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let nomination of nominationStudentArray;
                        index as i
                      "
                    >
                      <td>{{ i + 1 }}</td>
                      <td>{{ nomination?.name }}</td>
                      <td>{{ nomination?.email }}</td>
                      <td style="white-space: nowrap">
                        {{ nomination?.contact }}
                      </td>
                      <td>
                        <p *ngIf="nomination?.is_complete_basic == 0">
                          <i
                            class="fa fa-times-circle icon-style"
                            aria-hidden="true"
                            title="Not Started"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_basic == 1">
                          <i
                            class="fa fa-check-circle-o icon-style"
                            aria-hidden="true"
                            title="Completed"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_basic == 2">
                          <i
                            class="fa fa-spinner icon-style"
                            aria-hidden="true"
                            title="Ongoing"
                          ></i>
                        </p>
                      </td>
                      <td>
                        <p *ngIf="nomination?.is_complete_advance == 0">
                          <i
                            class="fa fa-times-circle icon-style"
                            aria-hidden="true"
                            title="Not Started"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_advance == 1">
                          <i
                            class="fa fa-check-circle-o icon-style"
                            aria-hidden="true"
                            title="Completed"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_advance == 2">
                          <i
                            class="fa fa-spinner icon-style"
                            aria-hidden="true"
                            title="Ongoing"
                          ></i>
                        </p>
                      </td>
                      <td>
                        <p *ngIf="nomination?.is_complete_YIC == 0">
                          <i
                            class="fa fa-times-circle icon-style"
                            aria-hidden="true"
                            title="Not Started"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_YIC == 1">
                          <i
                            class="fa fa-check-circle-o icon-style"
                            aria-hidden="true"
                            title="Completed"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.is_complete_YIC == 2">
                          <i
                            class="fa fa-spinner icon-style"
                            aria-hidden="true"
                            title="Ongoing"
                          ></i>
                        </p>
                      </td>
                      <!-- <td> 
                                            <p *ngIf="nomination?.type==1">Faculty</p>
                                            <p *ngIf="nomination?.type==2">Student</p></td> -->
                      <!-- <td>
                                        <p *ngIf="nomination?.Participate > 13">{{nomination?.total_assessment_submission}}</p> -->
                      <!-- <p *ngIf="nomination?.Participate < = 13">Offline Mode</p> -->

                      <!-- </td> -->
<!-- student upskill -->
                      <td>
                        <p *ngIf="nomination?.iaTrainings == 0">
                          <i
                            class="fa fa-times-circle icon-style"
                            aria-hidden="true"
                            title="Not Started"
                          ></i>
                        </p>
                        <p *ngIf="nomination?.iaTrainings > 29">
                          <i
                            class="fa fa-check-circle-o icon-style"
                            aria-hidden="true"
                            title="Completed"
                          ></i>
                        </p>
                        <p *ngIf="(nomination?.iaTrainings > 0) && (nomination?.iaTrainings < 29)">
                          <i
                            class="fa fa-spinner icon-style"
                            aria-hidden="true"
                            title="Ongoing"
                          ></i>
                        </p>
                      </td>
 <!-- student upskill end -->
                      <td style="white-space: nowrap">
                        <p *ngIf="nomination?.total_login_count > 0">
                          {{ nomination?.created_on | date : "dd-MM-yyyy" }}
                        </p>
                      </td>

                      <td>
                        <!-- <button *ngIf="nomination?.total_assessment_submission==0" mat-fab color="primary" (click)="onDeleteClick(nomination?.email,2)" style="background-color: #de2727;width: 100%;margin-bottom: 10px;border-radius: 50px;">Remove Nomination
                                            </button> -->
                        <button
                          *ngIf="nomination?.total_assessment_submission == 0"
                          (click)="onEditClick(nomination)"
                          class="mentorButEdit mat-focus-indicator mat-raised-button mat-button-base mat-primary"
                          color="primary"
                          mat-raised-button=""
                          ng-reflect-color="primary"
                          style="margin: 0; padding: 0 1.1em"
                        >
                          <span class="mat-button-wrapper">
                            <mat-icon
                              class="mat-icon notranslate material-icons mat-icon-no-color"
                              role="img"
                              aria-hidden="true"
                              data-mat-icon-type="font"
                              >create</mat-icon
                            >
                            Edit
                          </span>
                        </button>

                        <button
                          *ngIf="nomination?.total_assessment_submission >= 0"
                          (click)="onDeleteClick(nomination?.email, 2)"
                          class="mat-focus-indicator mat-raised-button mat-button-base mat-warn"
                          color="warn"
                          mat-raised-button=""
                          ng-reflect-color="warn"
                          style="margin-bottom: 10px"
                        >
                          <span class="mat-button-wrapper">
                            <mat-icon
                              class="mat-icon notranslate material-icons mat-icon-no-color"
                              role="img"
                              aria-hidden="true"
                              data-mat-icon-type="font"
                              >delete</mat-icon
                            >
                            Delete
                          </span>
                        </button>

                        <!--  <button *ngIf="nomination?.total_assessment_submission>=0" mat-fab color="primary" (click)="onEditClick(nomination?.email,2)" style="background-color:green;border-radius:50px;width: 100%;">Edit
                                            </button> -->
                      </td>
                    </tr>
                    <tr *ngIf="nominationStudentArray?.length <= 0" col="4">
                      <h3 style="text-align: center">No Data Found</h3>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="row">
                            <div class="col-md-12">
                                <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)" [length]="total_pages"
                                    [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                                </mat-paginator>
                            </div>
                        </div> -->
            </div>
          </div>
        <!-- </mat-tab> -->
        <!-- List ends -->
      <!-- </mat-tab-group> -->
    </div>
  </mat-tab>
</mat-tab-group>
