<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Add New Evaluator</h2>
    </div>

<!--
	<div class="row">
		<div class="wrapBorder col-md-6">
			<input formControlName="userType" type="radio" id="userType" value="1"
				style="margin: 100px;">IA
			<input formControlName="userType" type="radio" id="userType" value="0"
				style="margin: 100px;">External
			<span class="focus-input100"></span>
		</div>
	</div> -->

	<!-- <div [formGroup]="myGroup">
		<mat-radio-group formControlName="submission_type" (change)="onStatusChange($event)"  aria-label="Select an option" class="col-md-12" required>
		  <mat-radio-button class="alumniRadioBut" value="1"><p class="radioButpara">IA</p></mat-radio-button>
		  &nbsp;&nbsp;&nbsp;&nbsp;
		  <mat-radio-button class="alumniRadioBut" value="2"><p class="radioButpara">External</p></mat-radio-button>
		</mat-radio-group>
	</div> -->
<!--
	<tr class="ifYes" *ngIf="requireField==1">
		<td>
			Select as Evaluator
		</td>
		<td>
			{{activity_id}}
			<mat-form-field appearance="outline" class="w-100">

			<mat-label> Select Evaluator </mat-label>

			<mat-select autocomplete="off" placeholder="Select Evaluator" formControlName="user_name"  required>
			<mat-option>Select</mat-option>
			<mat-option value="{{activity.id}}" *ngFor="let activity of iicActivity">{{activity.first_name}}</mat-option>
			</mat-select>
			<mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error>
		</mat-form-field>
		</td>
	</tr> -->
    <form class="instiBankForm" [formGroup]="iicEvaluatorAddition" (ngSubmit)="onSubmit(iicEvaluatorAddition.value)">

		<mat-radio-group formControlName="submission_type" (change)="onStatusChange($event)"  aria-label="Select an option" class="col-md-12" >
			<mat-radio-button class="alumniRadioBut" value="1" style="margin-right: 30px;"><p class="radioButpara">Internal IA</p></mat-radio-button>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<mat-radio-button class="alumniRadioBut" value="3" style="margin-right: 30px;"><p class="radioButpara">External IA</p></mat-radio-button>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<mat-radio-button class="alumniRadioBut" value="2"><p class="radioButpara">External Verifer</p></mat-radio-button>
		  </mat-radio-group>

		<table class="table table-hover">
			<tr class="ifYes" *ngIf="requireField==1" >
				<td>
					Select as Evaluator
				</td>
				<td>
					<!-- {{activity_id}} -->
					<mat-form-field appearance="outline" class="w-100">
						<mat-label> Select Evaluator </mat-label>
						<mat-select autocomplete="off" placeholder="Select Evaluator" formControlName="user_name"  required (selectionChange)="onNbaStatusChange($event)">
					<mat-option>Select</mat-option>
					<mat-option value="{{activity.id}}" *ngFor="let activity of iicActivity">{{activity.name}}</mat-option>
					</mat-select>
					<!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
				</mat-form-field>
				</td>
			</tr>
			<tr class="ifYes" *ngIf="requireField==3" >
				<td>
					Enter IIC ID
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Enter IIC ID</mat-label>

					<input matInput autocomplete="off" placeholder="Enter IIC ID" formControlName="iicid" type="text" [value]="iicid" #iic required>
					<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
					</mat-form-field>
					<!-- <div *ngIf="Details==1">
						<p style="background-color: black;text-align: center;font-weight: bolder; color: #fff;">{{iicName?.instituteName}},{{iicName.name}}</p>
					</div>
					<div *ngIf="Details==0">
						<p style="background-color: black;text-align: center;font-weight: bolder; color: #fff;">{{iicName?.instituteName}},{{iicName.name}}</p>
					</div> -->
					<button mat-raised-button color='primary' class="btn btn-success sub-btn" (click)="getInstitute(iic.value)" type="button">Search</button>
				</td>
			</tr>
			<tr class="ifYes" *ngIf="requireField==3 && Show==true" >
				<td>
					Select Evaluator
				</td>
				<td>
					<!-- {{activity_id}} -->
					<mat-form-field appearance="outline" class="w-100">
						<mat-label> Select Evaluator </mat-label>
						<mat-select autocomplete="off" placeholder="Select Evaluator" formControlName="user_name"  required (selectionChange)="onNbaStatusChange2($event)">
					<mat-option>Select</mat-option>
					<mat-option value="{{members.id}}" *ngFor="let members of iicDetails">{{members?.name}}</mat-option>
					</mat-select>
					<!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
				</mat-form-field>
				</td>
			</tr>
			<tr *ngIf="requireField==1">
				<td>
					Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Name</mat-label>

					<input matInput autocomplete="off" placeholder="Name" formControlName="name" type="text" [value]="name" readonly="true" required>
					<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
					</mat-form-field>
				</td>
			</tr>
			<tr *ngIf="requireField==3">
				<td>
					Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Name</mat-label>

					<input matInput autocomplete="off" placeholder="Name" formControlName="name" type="text" [value]="name" readonly="true" required>
					<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
					</mat-form-field>
				</td>
			</tr>
			<tr *ngIf="requireField==0">
				<td>
					Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Name</mat-label>

					<input matInput autocomplete="off" placeholder="Name" formControlName="name" type="text" [value]="name"  required>
					<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
					</mat-form-field>
				</td>
			</tr>
      		<tr *ngIf="requireField==1">
				<td>
					Email
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Email</mat-label>

						<input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email" type="text" [value]="email" readonly="true"  required>
						<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            		</mat-form-field>
				</td>
			</tr>
      		<tr *ngIf="requireField==3">
				<td>
					Email
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Email</mat-label>

						<input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email" type="text" [value]="email" readonly="true" required>
						<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            		</mat-form-field>
				</td>
			</tr>
      		<tr *ngIf="requireField==0">
				<td>
					Email
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Email</mat-label>

						<input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email" type="text" [value]="email" required>
						<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            		</mat-form-field>
				</td>
			</tr>
      		<tr *ngIf="requireField==1">
				<td>
					Contact
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Contact</mat-label>

						<input matInput autocomplete="off" placeholder="Contact" formControlName="contact" type="text" [value]="contact" readonly="true" required>
						<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            		</mat-form-field>
				</td>
			</tr>
      		<tr *ngIf="requireField==3">
				<td>
					Contact
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Contact</mat-label>

						<input matInput autocomplete="off" placeholder="Contact" formControlName="contact" type="text" [value]="contact" readonly="true" required>
						<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            		</mat-form-field>
				</td>
			</tr>
      		<tr *ngIf="requireField==0">
				<td>
					Contact
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Contact</mat-label>

						<input matInput autocomplete="off" placeholder="Contact" formControlName="contact" type="text" [value]="contact"  required>
						<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            		</mat-form-field>
				</td>
			</tr>
			<tr>
				<td>
					&nbsp;
				</td>
				<td style="text-align:left">
					<button mat-raised-button color='primary' class="btn btn-success sub-btn" type="submit"
          [disabled]='iicEvaluatorAddition.invalid'>Submit</button>
				</td>
			</tr>
	   </table>
	</form>
</div>
