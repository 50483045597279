import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { HttpClient,HttpHeaders,HttpBackend   } from '@angular/common/http';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-institute-sic-choose',
  templateUrl: './institute-sic-choose.component.html',
  styleUrls: ['./institute-sic-choose.component.css']
})
export class InstituteSicChooseComponent implements OnInit {

  private sicUrl = myGlobals.sicApiPath;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  myActivity: InstitueMyActivity[] = [];
  reportsClosed: boolean = true;
  quarter_filter: string = '';
  activity_filter:string='';
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  title1 = "";
  searched: string;
  search_keyword: string;
  eventId: any;
  cityList: any;
  districtList: any;
  MyActivityannualReport: number = 0;
  actiontype: any;
  hostPath: any;
  stateName: any;
  stateId: any;
  allStates: any;
  getStateId: any;

  constructor(
    private activityService: InstituteActivityService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private http: HttpClient,handler: HttpBackend
  ) { 
    this.http = new HttpClient(handler);
    this.stateName=localStorage.getItem('institutesStateName');
    this.stateId=localStorage.getItem('institutesState');
  }

  ngOnInit(): void {
  
    this.getAllState();
    this.getMyActivityDetails();
    // this.getMyActivityDetails(this.currentPageNo);

    // this.activityService.getAtlCityDistrictList('all').subscribe(
    //   (data) => this.handleResponseList(data, 'all')
    // );
	

  }

  getAllState(){
    var dataResponse = this.stateUrl().subscribe(
       data => this.handleResponseState(data));
  }

  handleResponseState(data){
    this.allStates = data;
    for(const item of this.allStates){
      if(item?.label == this.stateName){
        this.getStateId = item?.id;
      }
    }

    if(this.getStateId){
      this.getAllDistrict(this.getStateId);
    }
  }
  
  stateUrl(){
    return this.http.get(`${this.sicUrl}/register/getStates?country=101`);
  }
  
  getAllDistrict(stateId){
    var dataResponse = this.districtUrl(stateId).subscribe(
       data => this.handleResponseDistrict(data));
  }
  
  districtUrl(stateId){
    return this.http.get(`${this.sicUrl}/register/getDistricts?state=`+stateId);
  }

  handleResponseDistrict(data){
    this.districtList = data;
  }

  handleResponseList(data) {
    
  //   if(type=="city")
  //   {
  //     this.cityList       = data['cities'];
  //   }
  //   else
  //   {
  //     this.cityList       = data['cities'];
  //     this.districtList   = data['districts'];
  //   }
  }

  onSubmitClick(data, type){
    this.activityService.mentorAtalSelection(data.id, type).subscribe(
      data => this.handleResponse2(data)
    );
  }

  handleResponse2(data){
    if(data['status']=='1'){
      alert(data['message']);
      window.location.href='/institute/atl-school-list';
    }else{
      this.toastr.error(data['message'])

    }
  }

  // nextPage() {
  //   this.currentPageNo++;
  //   this.getMyActivityDetails(this.currentPageNo);
  // }

  // previousPage() {
  //   this.currentPageNo--;
  //   this.getMyActivityDetails(this.currentPageNo);
  // }

  getMyActivityDetails() {
    const formData = {
      'email' : 'iic.mhrd@aicte-india.org',
      'password' : 'cCaS32RvQgVLwYrXzjLrpIFuMQdNFi',
      // 'subdomain' : this.manthanSubdomain,
    };
    let headers = new HttpHeaders({
      'Host': this.sicUrl,
      'Origin': 'https://iic.mic.gov.in',
    });
    let options = { headers: headers };
    var dataResponse = this.loginUrl(formData,options).subscribe(
       data => this.handleResponseData(data));

    // this.activityService
    //   .getIicAtalSchoolList(this.per_page, pageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22')
    //   .subscribe((data) => this.handleResponse(data));
  }

  loginUrl (data,options){
    return this.http.post(`${this.sicUrl}/auth/login`,data,options);
  }
  
  handleResponseData(data){
    this.getAllSicMember(data)
  }
  
  getAllSicMember(token){
    if(token){
      const formData = {
        'username' : 'sd.mic@aicte-india.org',
        'password' : 'SKdhw#24DUx$4ecH',
        // 'subdomain' : this.manthanSubdomain,
      };
      
      let headers = new HttpHeaders({
        'x-api-key': 'LgOiuSu64U',
        'Host': this.hostPath,
        'Origin': 'https://iic.mic.gov.in',
        // 'Origin': this.manthanOrigin,
        // 'X-Frame-Options' : 'ALLOW-FROM',
        // 'Content-Type' : 'application/json',
        // 'Access-Control-Allow-Origin' : this.manthanOrigin
        // "Access-Control-Allow-Origin" : "*",
        // "Access-Control-Allow-Headers" : "Origin, X-Requested-With, Content-Type, Accept"
      });
      let options = { headers: headers };
      return this.http.post(`${this.sicUrl}/Auth/Login`,formData,options);
    }
  }

  getMyActivityDetails1() {
    this.activityService
      .getIicAtalSchoolList(this.per_page, this.currentPageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22')
      .subscribe((data) => this.handleResponse(data));
  }
  // nextPage1(e: any) {
  //   if (this.per_page != e.pageSize) {

  //     this.currentPageNo = (e.pageIndex + 1);
  //   } else {
  //     this.currentPageNo = (e.pageIndex + 1);
  //   }
  //   this.per_page = e.pageSize;
  //   this.getMyActivityDetails1();
  // }

  // reviewRequest(data){
  //   this.eventId=data;

  //   this.activityService
  //   .submitReviewRequest(this.eventId)
  //   .subscribe((data) => this.reviewResponse(data));
  // }

  // reviewResponse(data) {
  //   if(data['status']=='1'){
  //     this.toastr.success(data['msg']);
  //     this.getMyActivityDetails(this.currentPageNo);
  //   }else{
  //     this.toastr.error(data['msg']);
  //   }
  // }

  // onDeleteClick(data){
  //   this.eventId=data['eventId'];

  //   if (confirm('Are you sure you want to delete?')) {
  //     // Save it!
  //   this.activityService
  //   .deleteMyActivity(this.eventId)
  //   .subscribe((data) => this.deleteResponse(data));
  //   } else {
  //     // Do nothing!
  //     console.log('not confirm');
  //   }
  // }

//   deleteResponse(data){
// if(data['status']=='1'){
//   this.toastr.success(data['msg']);
//   this.getMyActivityDetails(this.currentPageNo);
// }else{
//   this.toastr.error(data['msg']);
// }
//   }

  // quarterFilter() {
  //   this.currentPageNo = 1;
  //   this.getMyActivityDetails(this.currentPageNo);
  // }

  // activityFilter(){
  //   this.currentPageNo = 1;
  //   this.getMyActivityDetails(this.currentPageNo);
  // }

  onDistrictChange(ob){

    let district = ob.value;

    if(district!="")
    {
      this.getMyActivityDetails();
      // this.activityService.getAtlCityDistrictList(district).subscribe(
      //   (data) => this.handleResponseList(data,'city')
      // );
    }
    // else
    // {
    //   this.activityService.getAtlCityDistrictList("all").subscribe(
    //     (data) => this.handleResponseList(data,'all'));
    // }

  }

  downloadReport(endPoint, event_id) {
    this.activityService
      .getReportForDownload(endPoint, event_id)
      .subscribe((data) => this.downloadFile(data, endPoint + '.pdf'));
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }


  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searched = filterValue;
  }

  search() {
    this.search_keyword = this.searched;
    this.activityService.getIicAtalSchoolList(this.per_page, this.currentPageNo, this.quarter_filter, this.search_keyword,this.activity_filter,'2021-22').subscribe(
      data => this.handleResponse(data)
    )
  }

  handleResponse(data) {

    this.myActivity = data.data;
    this.total_pages = data.meta.pagination.total;
    this.MyActivityannualReport= data.meta.annualReport;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }


  }

  openDialog(row) {
    row.action = '';
    row.url = '';
    row.label = 'NA';
    // row.type = '';
    row.download_url = '';


    row.url = "monthly_report";
    row.download_url = "monthlyReportPdf";
    if (row.status == 'Submitted') {
      row.action = "edit";
      row.label = "Uploaded Successfully";
    }
    else if (row.status == 'Not Submitted') {
      row.action = "add";
      row.label = "Upload Report";
      row.download_url = "";
    } else if (row.status == 'Verified') {
      row.url = "";
      row.label = 'Report Verified';
    } else if (row.status == 'Disapproved') {
      row.url = "";
      row.label = 'Report Disapproved.';
    }

    const dialogRef = this.dialog.open(InstituteSicDialog, {
      data: { activity: row },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

}

export class InstitueMyActivity {
  id: number;
  title: string;
  description: string;
  userId: string;
  quarter: string;
  endDate: string;
  startDate: string;
  comments: string;
}

@Component({
  selector: 'app-institute-sic-dialog',
  templateUrl: 'institute-sic-dialog.html',
})
export class InstituteSicDialog implements OnInit {
  id: any;
  activity: any;
  cityList:any;
  districtList:any;
  baseUrl = 'https://mic.gov.in/images/events/';

  constructor(
    private formBuilder: FormBuilder,
    private activityService: InstituteActivityService,
    public dialogRef: MatDialogRef<InstituteSicDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.activity = this.data['activity'];


  }


  downloadReport(endPoint, event_id) {
    this.activityService
      .getReportForDownload(endPoint, event_id)
      .subscribe((data) => this.downloadFile(data, endPoint + '.pdf'));
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) { }




}