import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
//import { emailValidator } from 'src/app/app.component';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import * as myGlobals from 'src/app/app.component';


@Component({
  selector: 'app-impact-soe-form',
  templateUrl: './impact-soe-form.component.html',
  styleUrls: ['./impact-soe-form.component.css']
})

export class ImpactSoeFormComponent implements OnInit {

  public activityForm: FormGroup;
  photograph1: any;
  photograph2: any;
  programData: any;
  facultyList: string[] = [];
  studentList: string[] = [];
  programType: string[] = [];
  programTheme: string[] = [];
  event_id: string;
  action: string;
  institute_id: string;
  programName: any;
  myDate = (new Date());latest_date: any;
  minDate = "2021-09-08";
  maxDate = "2021-10-15";

  program_type_other_flg: boolean;
  program_theme_other_flg: boolean;

  public start_date: any;
  public date: any;

  public baseImageUrl = myGlobals.baseImageUrl;
  public baseUrl = myGlobals.baseUrl1;

  social: any[];
  social1: any[];
  social2: any[];
  urlReport: any;
  session_id:any;
  type:any;
  speaker_cv: FileList | null;
  uc_cv: FileList | null;
  proof_of_transaction: FileList | null;
  receipt_of_transaction: FileList | null;
  photo_1: FileList | null;
  photo_2: FileList | null;
  session_pdf: FileList | null;

  speaker_cv_url: string;
  uc_url: string;

  photo_1_url: string;
  photo_2_url: string;
  receipt_of_transaction_url: string;
  proof_of_transaction_url: string;
  totalcount:any;
  session_pdf_url:any;
  session_name:any;

  constructor(public fb: FormBuilder,
    private jarwis: JarwisService,
    private instituteMaster: InstituteInnovationContestService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.program_theme_other_flg = true;
    this.program_type_other_flg = true;
    this.start_date = "";
    this.date = "";


  }

  ngOnInit(): void {

    this.totalcount = 0;
    this.speaker_cv_url = "";
    this.uc_url = "";
    this.photo_1_url = "";
    this.photo_2_url = "";
    this.receipt_of_transaction_url = "";
    this.proof_of_transaction_url = "";
    this.session_pdf_url = "";

    this.activityForm = this.fb.group({
      total_amount: [null, Validators.required],
      amount_in_words: [null, Validators.required],
    });

    this.instituteMaster.getSoeData().subscribe(
      (data) => this.handleResponse(data)
    );

  }

  handleResponse(data) {

    if(data['totalcount'] > 0){

    this.activityForm.patchValue({
      total_amount: data['total_amount'],
      amount_in_words: data['amount_in_words']
    });

    this.speaker_cv_url               = data['soe_document'];
    this.uc_url               = data['uc_document'];

  }
    this.totalcount = data['totalcount'];

  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  onSubmit(values) {

    var formData = new FormData();

    var speaker_cv = this.speaker_cv && this.speaker_cv.length ? this.speaker_cv[0] : null;

    if(this.totalcount > 0){
      formData.append('soe_document', speaker_cv);
      formData.append('old_soe_document', this.speaker_cv_url);
    }
    else
    {
      if(this.speaker_cv==null && this.speaker_cv==undefined) { alert("Please upload the proof of transaction document."); return false; }
      formData.append('soe_document', speaker_cv);
      formData.append('old_soe_document', "");
    }
    
    var uc_cv = this.uc_cv && this.uc_cv.length ? this.uc_cv[0] : null;

    if(this.totalcount > 0){
      formData.append('uc_document', uc_cv);
      formData.append('old_uc_document', this.uc_url);
    }
    else
    {
      if(this.uc_cv==null && this.uc_cv==undefined) { alert("Please upload the proof of transaction document."); return false; }
      formData.append('uc_document', uc_cv);
      formData.append('old_uc_document', "");
    }

    formData.append('total_amount', this.activityForm.get('total_amount').value);
    formData.append('amount_in_words', this.activityForm.get('amount_in_words').value);

    this.instituteMaster.submitSoeData(formData).subscribe(
      data => this.handleResponseData(data));

  }

  handleResponseData(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg']);
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

}
