import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { ActivityMenteeDialogComponent } from 'src/app/components/users/institute/atl-user-dashboard/atl-mentee-activity/activity-mentee-dialog/activity-mentee-dialog.component';
import { AtalActivityFormGlobalComponent } from 'src/app/components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atal-activity-form-global/atal-activity-form-global.component';

@Component({
  selector: 'app-atl-mentee-activity',
  templateUrl: './atl-mentee-activity.component.html',
  styleUrls: ['./atl-mentee-activity.component.css']
})
export class AtlMenteeActivityComponent implements OnInit {

  schedules:any;
  schedules2:any;
  schedules3:any;
  schedules4:any;
  schedules5:any;
  total_pages:any;

  constructor(
    private activityService: InstituteActivityService,
    public dialog: MatDialog,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {

    this.activityService.atlSchedules().subscribe(
      (data) => this.handleResponse(data)
    );

    this.schedules      = "";
    this.schedules2     = "";
    this.schedules3     = "";
    this.schedules4     = "";
    this.total_pages    = 0;


  }

  handleResponse(data)
  {
    this.schedules      = data['Schedules'];
    this.schedules2     = data['Schedules2'];
    this.schedules3     = data['Schedules3'];
    this.schedules4     = data['Schedules4'];
    this.schedules5     = data['Schedules5'];
    this.total_pages    = 0;
  }

  onEditClick(data){
    const dialogRef = this.dialog.open(ActivityMenteeDialogComponent, {
      data:data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getFaculties();

    });
  }

  getFaculties(){
    
  }

  openDialog(data, type){
    
    const data1={
      data:data,
      aptype:type,
      newForm:1,
    }
     
    const dialogRef = this.dialog.open(AtalActivityFormGlobalComponent, {
      data: data1
    });
  
    dialogRef.afterClosed().subscribe((result) => {
    });    
  }

}
