<div class="container-fluid">
    <!-- List starts -->
 
    <div class="row" color="primary">
        <div class="col-md-12">
            <h2 class="ss" style="text-align: center;background-color: #116b74;padding: 10px 0;color: #fff;border-radius: 4px; margin: 50px 0 20px 0;">
            Institute Detail</h2>
        </div>
    </div>
    <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
    <div class="row" color="primary">
        <div class="col-md-12">
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Institute Id</mat-label>
                    <input matInput autocomplete="off" placeholder="Institute Id" formControlName="institute_id" type="text" >
                </mat-form-field>                
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Institute Name</mat-label>
                    <input matInput autocomplete="off" placeholder="Institute Name" formControlName="institute_name" type="text" >
                </mat-form-field>                
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select Institute City</mat-label>
                    <mat-select formControlName="city">
                        <mat-option *ngFor="let val of allCities" value="{{val.district}}">
                        {{val?.district}}</mat-option>
                    </mat-select>
                </mat-form-field>                
            </div>
            <div class="col-md-3">
                <button mat-raised-button color='primary' class="btn btn-success float-right" [disabled]='iicImpactQuestions.invalid'>Submit</button>               
            </div>
        </div>
    </div>
    </form>
    <div class="row">
        <div class="col-md-12">
			<div class="nominatedAddButton">
				<!-- <a routerLink="/institute/atl-add-nominate-coordinator"><button class="btn btn-success">Nominate ATL Coordinator</button></a> -->
			</div>
            <div class="tableBody">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="facultyTable">
                            <tr>
                                <th width="5%">S.No.</th>
                                <th width="10%">UserId</th> 
                                <th width="10%">Name</th> 
                                <th width="10%">Email</th>
                                <th width="20%">Phone</th>
                                <th width="20%">Institute Name</th>
                                <th width="20%">State</th>
                                <th width="5%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let activity of coordinatorDetail;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{activity?.userId}}</td>
                                <td>{{activity?.name}}</td>
                                <td>{{activity?.email}}</td>
                                <td>{{activity?.phone}}</td>
                                <td>{{activity?.instituteName}}</td>
                                <td>{{activity?.state_name}}</td>
                                <td>
                                    <!-- <button mat-button routerLink="/institute/atl-add-nominate-coordinator" [queryParams]="{id: activity?.id}" style="background-color: green; color: white;">
                                        Edit
                                    </button>                             -->
                                    <div *ngIf='activity?.join_status==0'>
                                        <p *ngIf='activity?.approval_status==0 && checkStatus==0'>
                                          <button mat-button  (click)="onSubmitClick(activity, '0')" style="
                                                background-color: green; color: white;">
                                            Choose to Mentor
                                          </button>
                                        </p>
                                
                                        <p *ngIf='activity?.approval_status>0' style="padding-top: 10px;">
                                          <button mat-button (click)="onSubmitClick(activity, '1')" style="
                                                background-color: red; color: white;">
                                            Remove 
                                          </button>
                                        </p>
                                
                                        <p *ngIf='activity?.approval_status>0'>
                                          <button mat-button  (click)="onSubmitClick(activity, '2')" style="
                                                background-color: green; color: white;">
                                            Send Reminder Email
                                          </button>
                                        </p>
                                    </div>
                                
                                    <div *ngIf='activity?.join_status==1' class="alert alert-success" style="text-align: center; padding: 5px 10px !important; margin-top: 10px; color: #155724;
                                    background-color: #d4edda; border-color: #c3e6cb;" role="alert">
                                      Joined
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="coordinatorDetail?.length==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
                            background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                            There is no institute listed yet.
                            </td></tr>
 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> 
 </div>