<div class="container-fluid">
    <!-- List starts -->
 
    <div class="row" color="primary">
        <div class="col-md-12">
            <h2 class="ss" style="text-align: center;background-color: #116b74;padding: 10px 0;color: #fff;border-radius: 4px; margin: 50px 0 20px 0;">
            ATL Coordinator</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
			<div class="nominatedAddButton">
				<a routerLink="/institute/atl-add-nominate-coordinator"><button class="btn btn-success">Nominate ATL Coordinator</button></a>
			</div>
            <div class="tableBody">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="facultyTable">
                            <tr>
                                <th width="5%">S.No.</th>
                                <th width="10%">Name</th> 
                                <th width="10%">Email</th>
                                <th width="20%">Phone</th>
                                <th width="5%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let activity of coordinatorDetail;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{activity?.name}}</td>
                                <td>{{activity?.email}}</td>
                                <td>{{activity?.mobile}}</td>
                                <td>
                                    <button mat-button routerLink="/institute/atl-add-nominate-coordinator" [queryParams]="{id: activity?.id}" style="background-color: green; color: white;">
                                        Edit
                                    </button>                            
                                </td>
                            </tr>
                            <tr *ngIf="coordinatorDetail?.length==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
                            background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                            There is no activity added yet.
                            </td></tr>
 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div> 
 </div>