<div class="container-fluid">

<div class="form-group">
  <div class="mt-3">
    <table class="table table-hover">
      <thead class="facultyTable">
        <tr>
          <td colspan="10" allign="left" class="danger " style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Incubation Units</h1>
          </td>
        </tr>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Incubation Unit Name</th>
          <th scope="col">Incubation Incharge Name</th>
          <th scope="col">Incubation Incharge Email</th>
          <th scope="col">Incubation Incharge Phone</th>
          <th scope="col">Funding Agency/Schemes Available</th>
          <th scope="col" *ngIf="ForWhom=='Institute'">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let incubationDetail of incubationDetails; let i = index;">
          <td scope="row">{{i +1}}</td>
          <td>{{incubationDetail?.name}}</td>
          <td>{{incubationDetail?.incubation_incharge_name}}</td>
          <td>{{incubationDetail?.incubation_incharge_phone}}</td>
          <td>{{incubationDetail?.incubation_incharge_email}}</td>
          <td>{{incubationDetail?.funding_scheme_support_received}}</td>
          <td *ngIf="ForWhom=='Institute'">
            <mat-icon (click)="editIncubationRow(incubationDetail)" class="m-2" style="cursor: pointer;">edit</mat-icon>
            <mat-icon (click)="deleteIncubationRow(incubationDetail.id)" class="m-2" style="cursor: pointer;" class="matDelIco">
              delete_forever</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mt-3">
    <table class="table table-hover">
      <thead class="facultyTable">
        <tr>
          <td colspan="10" allign="left" class="danger " style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Pre incubation Details</h1>
          </td>
        </tr>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Type</th>
          <th scope="col">Pre-Incubation Unit Name</th>
          <th scope="col">Pre-Incubation Incharge Name</th>
          <th scope="col">Pre-Incubation Incharge Email</th>
          <th scope="col">Pre-Incubation Incharge Phone</th>
          <th scope="col">Website Url</th>
          <th scope="col" *ngIf="ForWhom=='Institute'">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let preIncubationDetail of preIncubationDetails; let j = index;">
          <td scope="row">{{j +1}}</td>
          <td>{{preIncubationDetail?.sub_type}}</td>
          <td>{{preIncubationDetail?.name}}</td>
          <td>{{preIncubationDetail?.incubation_incharge_name}}</td>
          <td>{{preIncubationDetail?.incubation_incharge_email}}</td>
          <td>{{preIncubationDetail?.incubation_incharge_phone}}</td>
          <td>{{preIncubationDetail?.incharge_url}}</td>
          <td *ngIf="ForWhom=='Institute'">
            <mat-icon (click)="openEditDialog(preIncubationDetail)" class="m-2" style="cursor: pointer;">edit</mat-icon>
            <mat-icon (click)='deletePreIncubationRow(preIncubationDetail.id)' style="cursor: pointer;" class="matDelIco">delete_forever</mat-icon>
          </td>
        </tr>       
      </tbody>
    </table>
  </div>

  <div class="mt-3">
    <table class="table table-hover">
      <thead class="facultyTable">
        <tr>
          <td colspan="10" allign="left" class="danger " style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">IPR & TTO centers</h1>
          </td>
        </tr>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Type</th>
          <th scope="col">Facility Name</th>
          <th scope="col">Facility Incharge Name</th>
          <th scope="col">Facility Incharge Email</th>
          <th scope="col">Facility Incharge Phone</th>
          <th scope="col">Website Url</th>
          <th scope="col" *ngIf="ForWhom=='Institute'">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr  *ngFor="let ipr_tto_facility of ipr_tto_facilities; let k = index;">
          <td scope="row">{{k +1}}</td>
          <td>{{ipr_tto_facility?.sub_type}}</td>
          <td>{{ipr_tto_facility?.name}}</td>
          <td>{{ipr_tto_facility?.incubation_incharge_name}}</td>
          <td>{{ipr_tto_facility?.incubation_incharge_email}}</td>
          <td>{{ipr_tto_facility?.incubation_incharge_phone}}</td>
          <td>{{ipr_tto_facility?.incharge_url}}</td>
          <td *ngIf="ForWhom=='Institute'">
            <mat-icon (click)="openEditDialog1(ipr_tto_facility)" class="m-2" style="cursor: pointer;">edit</mat-icon>
            <mat-icon (click)='deleteRow(ipr_tto_facility.id)' style="cursor: pointer;" class="matDelIco">delete_forever</mat-icon>
            <!-- <button type="button" mat-raised-button color='warn' (click)='deleteRow("ipr")'>Delete</button> -->
          </td>        
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
