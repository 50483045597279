import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../app.component';
import baguetteBox from 'baguettebox.js';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
	


  constructor() { }

  ngOnInit(): void {
	  
	  
	  // Image Gallery Slider
		
		baguetteBox.run('.gallery', {
  captions: true, // display image captions.
  buttons: 'auto', // arrows navigation
  fullScreen: false,
  noScrollbars: false,
  bodyClass: 'baguetteBox-open',
  titleTag: false,
  async: false,
  preload: 2,
  animation: 'fadeIn', // fadeIn or slideIn
  verlayBackgroundColor: 'rgba(0,0,0,.8)'
});

	  
  }


}
