import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { News } from './news';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InstituteNewsCornerService } from 'src/app/Services/institute-news-corner.service';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../../app.component';

@Component({
  selector: 'app-institute-news-corner',
  templateUrl: './institute-news-corner.component.html',
  styleUrls: ['./institute-news-corner.component.css'],
})
export class InstituteNewsCornerComponent implements OnInit {
  readMore = true;
  readMore1 = true;

  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;


  total_pages1: any;
  frontCurrentPageNo1: number = 0;
  pageSizeOptions1: number[] = [10, 25,50, 100];
  per_page1 : number = 10;


  newsList: News[] = [];
  selfNewsList: News[] = [];
  baseUrl = myGlobals.baseImageUrl;
  currentPage = 1;
  selfNewsCurrentPage = 1;

  firstPage = true;
  lastPage: boolean = false;

  firstSelfPage = true;
  lastSelfPage: boolean = false;
  filterModel = "All";
  filterModelAll = "All";
  filterModelSelf = "All";

  statusList = ['Approved', 'Dis'];

  filterThemeAll = [];
  filterThemeSelf = [];

  // private baseImageUrl = myGlobals.baseImageUrl;

  constructor(
    public dialog: MatDialog,
    private instituteNewsCornerService: InstituteNewsCornerService
  ) {
    this.getNews();
    this.getSelfNews();
  }

  ngOnInit() {

    this.instituteNewsCornerService.getThemesDropdown().subscribe(
      data => {
        this.filterThemeAll = data['Theme'];
        this.filterThemeSelf = data['Theme'];
      }
    )
  }

  getNews() {
    this.instituteNewsCornerService
      .getAllNewsCornerDetails(this.per_page,this.currentPage)
      .subscribe((data) => this.handleResponse(data));
  }

  getSelfNews() {
    this.instituteNewsCornerService
      .getSelfNewsCornerDetails(this.per_page,this.currentPage)
      .subscribe((data) => this.handleSelfNewsResponse(data));
  }

  nextPage11(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPage = (e.pageIndex+1);
	  } else {
		this.currentPage = (e.pageIndex+1);
	  }
	this.per_page = e.pageSize;
    this.getSelfNews();
  }

  nextPage1(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPage = (e.pageIndex+1);
	  } else {
		this.currentPage = (e.pageIndex+1);
	  }
	this.per_page = e.pageSize;
    this.getNews();
  }
  handleResponse(data) {
    this.newsList = [];

    data['data'].forEach((element) => {
      element.forEach((news) => {
        this.newsList.push(news);
      });
    });
    this.total_pages = data.meta.pagination.total;


    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  changeThemeAll() {

    if (this.filterModelAll == "All") {
      this.currentPage = 1;
      this.getNews();
    } else {
      this.instituteNewsCornerService.getFilterThemeAll(this.currentPage, this.filterModelAll).subscribe(
        data => this.handleResponse(data)
      )
    }
  }

  changeThemeSelf() {

    if (this.filterModelSelf == "All") {
      this.selfNewsCurrentPage = 1;
      this.getSelfNews();
    } else {
      this.instituteNewsCornerService.getFilterThemeSelf(this.selfNewsCurrentPage, this.filterModelSelf).subscribe(
        data => this.handleSelfNewsResponse(data)
      )
    }
  }

  handleSelfNewsResponse(data) {
    this.selfNewsList = [];

    data['data'].forEach((element) => {
      element.forEach((news) => {
        this.selfNewsList.push(news);
      });
    });
    this.total_pages1 = data.meta.pagination.total;


    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstSelfPage = false;
      this.lastSelfPage = true;
    } else {
      this.lastSelfPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstSelfPage = true;
      this.lastSelfPage = false;
    } else {
      this.firstSelfPage = false;
    }
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(InstituteNewsCornerComponentDialog, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getNews();
      this.getSelfNews();
    });
  }

  deleteDialog(data) {
    const dialogRef = this.dialog.open(
      InstituteNewsCornerDeleteDialogComponent,
      {
        data: data,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      this.getNews();
      this.getSelfNews();
    });
  }

  nextPage() {
    this.currentPage++;
    if (this.filterModelAll == "All") {
      this.getNews();
    } else {
      this.changeThemeAll();
    }
  }

  previousPage() {
    this.currentPage--;
    if (this.filterModelAll == "All") {
      this.getNews();
    } else {
      this.changeThemeAll();
    }
  }

  nextPageSelfNews() {

    this.selfNewsCurrentPage++;
    if (this.filterModel == "All" && this.filterModelSelf == "All") {
      this.getSelfNews();
    } else if (this.filterModel != "All") {
      this.changeFilter();
    } else {
      this.changeThemeSelf();
    }

  }

  previousPageSelfNews() {

    this.selfNewsCurrentPage--;

    if (this.filterModel == "All" && this.filterModelSelf == "All") {
      this.getSelfNews();
    } else if (this.filterModel != "All") {
      this.changeFilter();
    } else {
      this.changeThemeSelf();
    }

  }

  changeFilter() {
    if (this.filterModel == "All") {
      this.getSelfNews();
    } else {
      this.instituteNewsCornerService.getNewsCornerStatus(this.selfNewsCurrentPage, this.filterModel).subscribe(
        data => this.handleSelfNewsResponse(data)
      );
    }
  }

}

@Component({
  selector: 'app-institute-news-corner-dialog',
  templateUrl: 'institute-news-corner-dialog.html',
})
export class InstituteNewsCornerComponentDialog implements OnInit {
  newsForm: FormGroup;
  baseUrl = myGlobals.baseImageUrl;

  activityData: any = [];
  showDiv: boolean = false;
  showDiv1: boolean = false;
  minDate;
  editFlag: boolean = false;
  activityModel: any;
  activityType: string[] = [];
  addedCalenderList = [];
  bannerUpload_Logo: FileList | null;
  brochure: any;
  s: boolean = false;

  constructor(
    private toastr: ToastrService,
    private instituteNewsCornerService: InstituteNewsCornerService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InstituteNewsCornerComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.instituteNewsCornerService.getActivityType().subscribe((data) => {
      this.activityData = data['data']['activity_type'];

      if (this.activityData.length > 0) {
        this.activityData.forEach((element) => {
          this.activityType.push(element.parent_name);
        });

      }
    });
  }

  handleResponseActivityType(data) {
    this.activityData = data['data']['activity_type'];

    if (this.activityData.length > 0) {
      this.activityData.forEach((element) => {
        this.activityType.push(element.parent_name);
      });

    }
  }

  ngOnInit() {
    this.newsForm = this.formBuilder.group({
      activity_type: [null, Validators.required],
      event_title: [null, Validators.required],
      event_theme: [null],
      Target_Aud: [null, Validators.required],
      participants: [null],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      event_url: [null],
      no_of_participant: [null],
      description: [null],
      added_calendar: [null, Validators.required],
      brochure: [null],
    });

    this.newsForm.get('activity_type').valueChanges.subscribe((data) => {
      if (data) {
        this.setAddedCalendarList(data);
      }
    });

    this.newsForm.get('fromDate').valueChanges.subscribe((data) => {
      this.minDate = data;
    });

    this.newsForm.get('activity_type').valueChanges.subscribe((data) => {
      this.activitySelection(data);
    });

    if (this.data) {
      this.newsForm.get('event_theme').setValue(this.data['event_theme']);
      this.newsForm.get('event_title').setValue(this.data['evant_title']);
      this.newsForm.get('Target_Aud').setValue(this.data['target_audience']);
      this.newsForm.get('participants').setValue(this.data['participants']);
      this.newsForm.get('fromDate').setValue(new Date(this.data['from_dt']));
      this.newsForm.get('toDate').setValue(new Date(this.data['to_dt']));
      this.newsForm.get('event_url').setValue(this.data['event_url']);
      this.newsForm
        .get('no_of_participant')
        .setValue(this.data['no_of_participant']);
      this.newsForm.get('description').setValue(this.data['Comment']);
      this.newsForm.get('activity_type').setValue(this.data['type']);
      this.newsForm.get('brochure').setValue(this.data['brochure']);

      this.brochure = this.data['brochure'];
      if (this.brochure == '' || this.brochure == undefined) {
        this.s = false;
      } else {
        this.s = true;
      }
      this.activityModel = this.data['type'];

      setTimeout(() => {
        this.setAddedCalendarList(this.data['type']);

        this.addedCalenderList.forEach((element) => {
          if (element.value == this.data['eventId']) {
            this.newsForm.get('added_calendar').setValue(this.data['eventId']);
          }
        });
      }, 5000);
    }
  }

  activitySelection(value) {

    if (value == 'MIC driven Activity' || value == 'IIC Calendar Activity') {
      this.showDiv = true;
      this.showDiv1 = false
    } else if (value == 'Self-driven Activity') {
      this.showDiv = false;
      this.showDiv1 = false;
    }

    if (value == 'Other') {
      this.showDiv = true;
      this.showDiv1 = true;
      this.newsForm.get('added_calendar').clearValidators();
      this.newsForm.get('added_calendar').updateValueAndValidity();
    }

    if (this.showDiv) {
      this.newsForm.get('no_of_participant').setValidators(Validators.required);
      this.newsForm.get('event_url').setValidators(Validators.required);
      this.newsForm.get('participants').setValidators(Validators.required);
      this.newsForm.get('Target_Aud').setValidators(Validators.required);
      this.newsForm.get('description').setValidators(Validators.required);
      this.newsForm.get('event_theme').setValidators(Validators.required);
      this.newsForm.get('fromDate').setValidators(Validators.required);
      this.newsForm.get('toDate').setValidators(Validators.required);
      this.newsForm.get('event_title').setValidators(Validators.required);
    } else {
      this.newsForm.get('no_of_participant').clearValidators();
      this.newsForm.get('event_url').clearValidators();
      this.newsForm.get('participants').clearValidators();
      this.newsForm.get('Target_Aud').clearValidators();
      this.newsForm.get('description').clearValidators();
      this.newsForm.get('event_theme').clearValidators();
      this.newsForm.get('fromDate').clearValidators();
      this.newsForm.get('toDate').clearValidators();
      this.newsForm.get('event_title').clearValidators();
    }

    this.newsForm.get('no_of_participant').updateValueAndValidity();
    this.newsForm.get('event_url').updateValueAndValidity();
    this.newsForm.get('participants').updateValueAndValidity();
    this.newsForm.get('Target_Aud').updateValueAndValidity();
    this.newsForm.get('description').updateValueAndValidity();
    this.newsForm.get('event_theme').updateValueAndValidity();
    this.newsForm.get('fromDate').updateValueAndValidity();
    this.newsForm.get('toDate').updateValueAndValidity();
    this.newsForm.get('event_title').updateValueAndValidity();
  }

  setAddedCalendarList(activityType) {

    this.addedCalenderList = [];

    this.activityData.forEach((element) => {
      if (element.parent_name == activityType) {

        element['data'].forEach((addedCalender) => {

          this.addedCalenderList.push(addedCalender);
        });
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) {

    values['fromDate'] = this.changeDate(values['fromDate']);
    values['toDate'] = this.changeDate(values['toDate']);

    var formData = new FormData();

    if (values['activity_type'] == 'Self-driven Activity') {

      this.addedCalenderList.forEach(element => {

        if (element.value == values['added_calendar']) {
          values['fromDate'] = element.start_date;
          values['toDate'] = element.end_date;
          values['event_title'] = element.name;
        }
      });


      formData.append(
        'activity_type',
        this.newsForm.get('activity_type').value
      );
      formData.append(
        'added_calendar',
        this.newsForm.get('added_calendar').value +
        '/' +
        values['fromDate'] +
        '/' +
        values['toDate'] +
        '/' +
        values['event_title']
      );
    } else {
      var bannerUpload_Logo =
        this.bannerUpload_Logo && this.bannerUpload_Logo.length
          ? this.bannerUpload_Logo[0]
          : null;

      formData.append('bannerUpload_Logo', bannerUpload_Logo);
      formData.append(
        'activity_type',
        this.newsForm.get('activity_type').value
      );
      formData.append('event_title', this.newsForm.get('event_title').value);
      formData.append('event_theme', this.newsForm.get('event_theme').value);
      formData.append('Target_Aud', this.newsForm.get('Target_Aud').value);
      formData.append('participants', this.newsForm.get('participants').value);
      formData.append('fromDate', values['fromDate']);
      formData.append('toDate', values['toDate']);
      formData.append('event_url', this.newsForm.get('event_url').value);
      formData.append(
        'no_of_participant',
        this.newsForm.get('no_of_participant').value
      );
      formData.append('description', this.newsForm.get('description').value);
      formData.append(
        'added_calendar',
        this.newsForm.get('added_calendar').value
      );
    }

    if (this.data) {
      formData.append('id', this.data['iea_id']);
      this.instituteNewsCornerService
        .updateNews(formData)
        .subscribe((data) => this.handleResponse(data));
    } else {
      this.instituteNewsCornerService
        .submitNews(formData)
        .subscribe((data) => this.handleResponse(data));
    }
  }

  handleResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }
}

///Delete Dialog

@Component({
  selector: 'app-institute-news-corner-delete-dialog',
  templateUrl: './institute-news-corner-delete-dialog.html',
  // styleUrls: ['./institute-declare-semesters.component.css']
})
export class InstituteNewsCornerDeleteDialogComponent implements OnInit {
  id: any;

  constructor(
    public dialog: MatDialog,
    private instituteNewsCornerService: InstituteNewsCornerService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteNewsCornerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.id = this.data['iea_id'];
  }

  deleteDialog() {
    this.instituteNewsCornerService
      .deleteNews(this.id)
      .subscribe((data) => this.handleData(data));
  }

  handleData(data) {
    if ((data['status'] = '1')) {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Not able to delete !');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
