import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ToastrService } from 'ngx-toastr';
import { InstitutePocSubmissionComponent } from '../institute-poc-submission.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-institute-poc-submission-dialog',
  templateUrl: './institute-poc-submission-dialog.component.html',
  styleUrls: ['./institute-poc-submission-dialog.component.css']
})
export class InstitutePocSubmissionDialogComponent implements OnInit {
  public pocForm: FormGroup;
  pocScore: any;
  commentFlag: boolean = false;
  constructor(
    private fb: FormBuilder,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstitutePocSubmissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.pocForm = this.fb.group({
      status: [null],
      score: [null, Validators.compose([Validators.max(50), Validators.min(2)])],
      comment: [null],
    });

    if (this.data['comment'] != '0' && this.data['comment'] != '') {
      this.commentFlag = true;
    }
  }

  onSubmit(values) {

    values['id'] = this.data['id'];
    values['type'] = 'POC';
    this.innovationService.submitPocSubmission(values).subscribe(
      data => this.handleResponse(data)
    );

  }

  handleResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 46) return true;

    return false;
  }

}
