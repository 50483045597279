<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">International Exposure</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">International Exposure</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>International Exposure</a></li>
    <li class="breadcrumb-item active" aria-current="page">India-Canada</li>
  </ul>
</nav>

<section class="indiaCanadaBox">
	<div class="container">
		<div class="vc_row">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">Canada-India Acceleration Program</h2>
				</div>
			</div>
		</div>
	</div>
	
	<div class="container">
	<div class="row">
		<div class="wpb_wrapper">
			<div class="wpb_column vc_column_container vc_col-sm-12">
				<div class="vc_column-inner">
					<div class="wpb_wrapper">
						<div id="ultimate-heading-49105f6608820746a" class="uvc-heading ult-adjust-bottom-margin ultimate-heading-49105f6608820746a uvc-5447 ">
							<div class="uvc-heading-spacer no_spacer" style="top"></div>
							<div class="headingAmbassadorContent">
								<h3>What is CIAP?</h3>
							</div>
							<div class="headingAmbassadorContent"><p>Canada-India Acceleration Program (CIAP) is a joint program managed jointly by All India Council for
								Technical Education (AICTE) and the Canada-India Centre for Excellence (CICE) at Carleton
								University.MoU was signed in this regard and 1st cohort was started in the Year 2018. In the 1st cohort,
								total 10 Indian female entrepreneurs had successfully completed the acceleration Program. CIAP is a
								collaborative program aims at providing international acceleration opportunity to 100 female early stage
								entrepreneurs (50 from each country) over a period of five years. This year 6 women entrepreneurs
								were identified through various initiatives of AICTE and MHRD’s Innovation Cell for 10 days
								comprehensive bootcamp and exposure visit to Canada.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="wpb_column vc_column_container vc_col-sm-12">
				
					<div class="wpb_wrapper">
						<div class="wpb_column vc_column_container vc_col-sm-12">
							<div class="vc_column-inner">
								<div class="wpb_wrapper">
									<div id="ultimate-heading-49105f6608820746a" class="uvc-heading ult-adjust-bottom-margin ultimate-heading-49105f6608820746a uvc-5447">
										<div class="uvc-heading-spacer no_spacer" style="top"></div>
										<div class="headingAmbassadorContent">
											<h3>2nd Cohort CIAP 2019-20</h3>
										</div>
										<div class="headingAmbassadorContent"><p>Under this collaboration entrepreneur will undergo Global Business Acceleration Program from 19th - 31st Jan 2020 facilitated in partnership with Canada India Centre for Excellence (CICE) at Sprott School of
							Management of Carleton University, Ottawa, Canada. CIAP is aimed at enabling women entrepreneurs
							from Canada and India to access international markets with a focus on the Canadian and Indian markets.
							This year 6 women entrepreneurs were identified through various initiatives of AICTE and MHRD’s
							Innovation Cell for 10 days comprehensive bootcamp and exposure visit to Canada.</p>
									</div>
									</div>
								</div>
							</div>
						</div>
							
						
					</div>
			</div>
		</div>
	</div>
	</div>
</section>

<section class="accleProgram">
	<div class="container">
		<div class="vc_row">
			<div class="vc_col-md-12 marginIndiaCanadaBottom">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">Glimpse of CIAP</h2>
				</div>
			</div>
		</div>
		<div class="vc_row">
			<div class="col-md-4">
				<div class="column">
					<img src="assets/assets/images/ciap.jpg" alt="Snow">
						<h6>A CIAP review meeting chaired by Hon’ble AICTE
						Chairman in esteemed presence of Deputy High
						Commissioner, Canada and Senior Trade
						Commissioner, Canada</h6>
				</div>
			</div>
			<div class="col-md-4">
				<div class="column">
					<img src="assets/assets/images/ciap2.jpg" alt="Forest">
					<h6>Five women entrepreneurs visited Canada as part of CIAP</h6>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>