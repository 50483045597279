import { Component, OnInit } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';

@Component({
  selector: 'app-iic-report-download',
  templateUrl: './iic-report-download.component.html',
  styleUrls: ['./iic-report-download.component.css']
})
export class IicReportDownloadComponent implements OnInit {

  coordinatorDetail:any;

  constructor(
    private activityService: InstituteActivityService,

  ) { }

  ngOnInit(): void {

    this.activityService.iicReportDetails().subscribe(
      (data) => this.handleResponse(data)
    );

    this.coordinatorDetail = "";


  }

  handleResponse(data)
  {
    this.coordinatorDetail = data;
  }

}
