import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TokenService } from './token.service';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../app.component';

@Injectable({
  providedIn: 'root',
})
export class AfterLoginService implements CanActivate {
  constructor(private token: TokenService) {}

  canActivate(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ):
    | boolean
    | import('@angular/router').UrlTree
    | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    let payload = this.token.payload(localStorage.getItem('token'));

    // alert(payload.role_id);

    if (payload.role_id == '3' || payload.role_id == '5' || payload.role_id == '10') {
      return true;
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AfterLoginAmbassadorService implements CanActivate {
  private baseUrl = myGlobals.baseUrl;

  constructor(private token: TokenService, private http: HttpClient) {}

  canActivate(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ):
    | boolean
    | import('@angular/router').UrlTree
    | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    let payload = this.token.payload(localStorage.getItem('token'));

    if (payload.role_id == '4') {
      return true;
    } else {
      return false;
    }
  }
}
