import { Component, OnInit } from '@angular/core';
import { InstituteDashboard1819Service } from 'src/app/Services/institute-dashboard1819.service';
import * as myGlobals from '../../../../../app.component';

@Component({
  selector: 'app-dashboard1819-announcements',
  templateUrl: './dashboard1819-announcements.component.html',
  styleUrls: ['./dashboard1819-announcements.component.css']
})
export class Dashboard1819AnnouncementsComponent implements OnInit {

  announcements: any;
  page: any;
  baseImageUrl = myGlobals.baseImageUrl;

  constructor(private dashboardService: InstituteDashboard1819Service) {
    dashboardService.getAnnouncements().subscribe(
      data => {
        this.announcements = data['data']
      }
    )
  }

  public onPageChanged(event) {
    this.page = event;
    this.announcements;
  }

  ngOnInit(): void {
  }

}
