import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstitutePrototypeSubmissionDialogComponent } from './institute-prototype-submission-dialog/institute-prototype-submission-dialog.component';
import * as myGlobals from '../../../../../app.component';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-institute-prototype-submission',
  templateUrl: './institute-prototype-submission.component.html',
  styleUrls: ['./institute-prototype-submission.component.css']
})
export class InstitutePrototypeSubmissionComponent implements OnInit {

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  prototypeSubmission = [];
  filterModel = "All";
  counts: any;
  frontCurrentPageNo: number = 0;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;

  constructor(private fb: FormBuilder,
    public dialog: MatDialog,
    private innovationService: InstituteInnovationContestService) { }

  ngOnInit(): void {
    this.getPrototypeSubmission();
    this.innovationService.getStatusCounts('prototype').subscribe(
      data => this.counts = data['prototype']['0']
    )
  }

  getPrototypeSubmission() {
    this.innovationService.getPrototypeSubmission(this.currentPageNo,this.per_page).subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.prototypeSubmission = [];

    data['data'].forEach((element) => {
      this.prototypeSubmission.push(element);
    });

this.total_pages = data.meta.pagination.total;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	this.per_page = e.pageSize;
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.changeFilter();
    }
  }

  previousPage() {
    this.currentPageNo--;
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.changeFilter();
    }
  }


  openDialog(data) {
    const dialogRef = this.dialog.open(InstitutePrototypeSubmissionDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {

      this.getPrototypeSubmission();
    });
  }

  changeFilter() {
    if (this.filterModel == "All") {
      this.getPrototypeSubmission();
    } else {
      this.innovationService.getPrototypeSubmissionFilter(this.currentPageNo, this.filterModel).subscribe(
        data => this.handleResponse(data)
      );
    }
  }

}
