<section class="contactUsBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<p class="hdr bifuracationPara">Implementation team of Institutions Innvovation Council</p>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6 offset-md-3">
				<div class="card card-profile">
					<div class="card-header instiCardHeaderContact" style="background: #2d3091;">
						<div class="profile-picture">
							<div class="avatar avatar-xl">
								<img src="../assets/assets/images/imp-team/dipan-sahu.jpg" alt="..." class="avatar-img rounded-circle">
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="user-profile text-center">
							<div class="name">Mr. Dipan Sahu</div>
							<div class="job">Assistant Innovation Director</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Email</h3>
									<p>dipan.sahu@gov.in </p>
									<p>dipan.sahu@aicte-india.org</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Phone</h3>
									<p>011 2958 1226</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="card card-profile">
					<div class="card-header instiCardHeaderContact" style="background: #2d3091;">
						<div class="profile-picture">
							<div class="avatar avatar-xl">
								<img src="../assets/assets/images/imp-team/selva-rani.jpg" alt="..." class="avatar-img rounded-circle">
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="user-profile text-center">
							<div class="name">Ms. Selvarani</div>
							<div class="job">Zone Incharge</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Email</h3>
									<p>sro.iic.mic@aicte-india.org</p>
									<p>scro.iic.mic@aicte-india.org</p>
									<!-- <p>nwro.iic.mic@aicte-india.org</p> -->
									<!-- <p>selva.rani@aicte-india.org</p> -->
								</div>
							</div>
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Phone</h3>
									<p>011 2958 1513</p>
								</div>
							</div>
						</div>

						<!-- <div id="InstcontactUsCompoID" class="collapse"> -->

							<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Southern/SRO </p>
									<p>Southern-Central/SCRO </p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Puducherry</li>
										<li>Tamil Nadu</li>
										<li>Telangana</li>
										<li>Andhra Pradesh</li>

									</ul>
								</div>
							</div>


							<!-- <div class="CardBoxContent">
							<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>North-West/NWRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Chandigarh</li>
										<li>Delhi</li>
										<li>Haryana</li>
										<li>Himachal Pradesh</li>
										<li>Jammu and Kashmir</li>
										<li>Punjab</li>
										<li>Rajasthan</li>
									</ul>
								</div>
							</div> -->

							<!-- <div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Eastern/ERO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Andaman and Nicobar Islands </li>
										<li>Arunachal Pradesh</li>
										<li>Assam</li>
										<li>Jharkhand</li>
										<li>Manipur</li>
										<li>Meghalaya</li>
										<li>Mizoram</li>
										<li>Nagaland</li>
										<li>Odisha/Orissa</li>
										<li>Sikkim</li>
										<li>Tripura</li>
										<li>West Bengal</li>
									</ul>
								</div>
							</div> -->

							<!-- <div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Northern/NRO </p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Bihar</li>
										<li>Uttar Pradesh</li>
										<li>Uttarakhand</li>
									</ul>
								</div>
								<div class="mainZoneBox">
									<h3>Region</h3>
									<p>Northern/NRO</p>
								</div>
							</div> -->

							<!-- <div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>North-West/NWRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Chandigarh</li>
										<li>Delhi</li>
										<li>Haryana</li>
										<li>Himachal Pradesh</li>
										<li>Jammu and Kashmir</li>
										<li>Punjab</li>
										<li>Rajasthan</li>
									</ul>
								</div>
								<div class="mainZoneBox">
									<h3>Region</h3>
									<p>North-West/NWRO</p>
								</div>
							</div> -->

						<!-- </div> -->

						<!-- <div class="cardReadMore">
							<button data-toggle="collapse" data-target="#InstcontactUsCompoID" *ngIf="readMore"
								(click)="readMore = !readMore">Read
								More</button>
							<button data-toggle="collapse" data-target="#InstcontactUsCompoID" *ngIf="!readMore"
								(click)="readMore = !readMore">Read
								Less</button>
						</div> -->
					</div>

				</div>
			</div>

			<!-- <div class="col-md-6">
				<div class="card card-profile">
					<div class="card-header instiCardHeaderContact" style="background: #2d3091;">
						<div class="profile-picture">
							<div class="avatar avatar-xl">
								<img src="../assets/assets/images/imp-team/ankush-sharma.jpg" alt="..." class="avatar-img rounded-circle">
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="user-profile text-center">
							<div class="name">Mr. Ankush Sharma</div>
							<div class="job">Zone Incharge</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Email</h3>
									<p>nro.iic.mic@aicte-india.org</p>
									<p>nwro.iic.mic@aicte-india.org</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Phone</h3>
									<p>011 2958 1223</p>
								</div>
							</div>
						</div>

						

							<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Northern/NRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Bihar</li>
										<li>Uttar Pradesh</li>
										<li>Uttarakhand</li>
									</ul>
								</div>
							</div>

							<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>North-West/NWRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Chandigarh</li>
										<li>Delhi</li>
										<li>Haryana</li>
										<li>Himachal Pradesh</li>
										<li>Jammu and Kashmir</li>
										<li>Punjab</li>
										<li>Rajasthan</li>
									</ul>
								</div>
							</div>
					</div>

				</div>
			</div> -->

			<div class="col-md-6">
				<div class="card card-profile">
					<div class="card-header instiCardHeaderContact" style="background: #2d3091;">
						<div class="profile-picture">
							<div class="avatar avatar-xl">
								<img src="../assets/assets/images/imp-team/abhishek-ranjan-kumar.jpg" alt="..." class="avatar-img rounded-circle">
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="user-profile text-center">
							<div class="name">Mr. Abhishek Ranjan Kumar</div>
							<div class="job">Zone Incharge</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Email</h3>
									<p>swro.iic.mic@aicte-india.org</p>
									<p>cro.iic.mic@aicte-india.org</p>
									<!-- <p>innovationofficer4@aicte-india.org</p> -->
								</div>
							</div>
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Phone</h3>
									<p>011 2958 1517</p>
								</div>
							</div>
						</div>

						<!-- <div id="InstcontactUsCompoID" class="collapse"> -->

							<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>South-West/SWRO</p>
									<p>Central/CRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Karnataka</li>
										<li>Kerala</li>
										<li>Chhattisgarh</li>
										<li>Gujarat</li>
										<li>Madhya Pradesh</li>
									</ul>
								</div>
							</div>
							
							<!-- <div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Central/CRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Chhattisgarh</li>
										<li>Gujarat</li>
										<li>Madhya Pradesh</li>
									</ul>
								</div>
							</div> -->



						<!-- </div> -->

						<!-- <div class="cardReadMore">
							<button data-toggle="collapse" data-target="#InstcontactUsCompoID" *ngIf="readMore"
								(click)="readMore = !readMore">Read
								More</button>
							<button data-toggle="collapse" data-target="#InstcontactUsCompoID" *ngIf="!readMore"
								(click)="readMore = !readMore">Read
								Less</button>
						</div> -->
					</div>

				</div>
			</div>

		</div>

		<div class="row">		

			<div class="col-md-6">
				<div class="card card-profile">
					<div class="card-header instiCardHeaderContact" style="background: #2d3091;">
						<div class="profile-picture">
							<div class="avatar avatar-xl">
								<img src="../assets/assets/images/imp-team/Monika.png" alt="..." class="avatar-img rounded-circle">
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="user-profile text-center">
							<div class="name">Ms. Monika Choudhary</div>
							<div class="job">Zone Incharge</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Email</h3>
									<p>wro.iic.mic@aicte-india.org</p>
									
									<!-- <p>startupfellow4@aicte-india.org</p> -->
								</div>
							</div>
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Phone</h3>
									<p>011 2958 1337</p>
								</div>
							</div>
						</div>
							<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Western/WRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Dadra and Nagar Haveli</li>
										<li>Daman and Diu</li>
										<li>Goa</li>
										<li>Maharashtra</li>
									</ul>
								</div>
							</div>
							<!-- <div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>South-Central/SCRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Andhra Pradesh</li>
										<li>Telangana</li>
									</ul>
								</div>
							</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-6">
				<div class="card card-profile">
					<div class="card-header instiCardHeaderContact" style="background: #2d3091;">
						<div class="profile-picture">
							<div class="avatar avatar-xl">
								<img src="../assets/assets/images/imp-team/Jerry.png" alt="image" class="avatar-img rounded-circle">
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="user-profile text-center">
							<div class="name">Mr. Jerry Joshy</div>
							<div class="job">Zone Incharge</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Email</h3>
									<p>nwro.iic.mic@aicte-india.org</p>
									<p>nro.iic.mic@aicte-india.org</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Phone</h3>
									<p>011 2958 1225</p>
								</div>
							</div>
						</div>
							<!-- <div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Western/WRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Dadra and Nagar Haveli</li>
										<li>Daman and Diu</li>
										<li>Goa</li>
										<li>Maharashtra</li>
									</ul>
								</div>
							</div>
							<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>South-Central/SCRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Andhra Pradesh</li>
										<li>Telangana</li>
									</ul>
								</div>
							</div> -->
							<div class="CardBoxContent">
							<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>North-West/NWRO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Chandigarh</li>
										<li>Delhi</li>
										<li>Haryana</li>
										<li>Himachal Pradesh</li>
										<li>Jammu and Kashmir</li>
										<li>Punjab</li>
										<li>Rajasthan</li>
									</ul>
								</div>
							</div>
					</div>
				</div>
			</div>
			<!-- <div class="col-md-6">
				<div class="card card-profile">
					<div class="card-header instiCardHeaderContact" style="background: #2d3091;">
						<div class="profile-picture">
							<div class="avatar avatar-xl">
								<img src="../assets/assets/images/imp-team/udyan.jpg" alt="..." class="avatar-img rounded-circle">
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="user-profile text-center">
							<div class="name">Mr. Udyan Maurya</div>
							<div class="job">Zone Incharge</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Email</h3>
									<p>ero.iic.mic@aicte-india.org</p>
									<p>nro.iic.mic@aicte-india.org</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Phone</h3>
									<p>011 2958 1240</p>
								</div>
							</div>
						</div>

							<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Eastern/ERO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Andaman and Nicobar Islands </li>
										<li>Arunachal Pradesh</li>
										<li>Assam</li>
										<li>Jharkhand</li>
										<li>Manipur</li>
										<li>Meghalaya</li>
										<li>Mizoram</li>
										<li>Nagaland</li>
										<li>Odisha/Orissa</li>
										<li>Sikkim</li>
										<li>Tripura</li>
										<li>West Bengal</li>
									</ul>
								</div>
							</div>
							<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Northern/NRO </p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Bihar</li>
										<li>Uttar Pradesh</li>
										<li>Uttarakhand</li>
									</ul>
								</div>
							</div>
					</div>

				</div>
			</div> -->
			<div class="col-md-6">
				<div class="card card-profile">
					<div class="card-header instiCardHeaderContact" style="background: #2d3091;">
						<div class="profile-picture">
							<div class="avatar avatar-xl">
								<img src="assets/assets/images/imp-team/subrat.jpg" alt="Monika" class="avatar-img rounded-circle">
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="user-profile text-center">
							<div class="name">Mr. Subrat Kr. Sahu</div>
							<div class="job">Zone Incharge</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Email</h3>
									<p>ero.iic.mic@aicte-india.org</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="CardBoxContent">
									<h3>Phone</h3>
									<p>011 2958 1227</p>
								</div>
							</div>
						</div>
						<div class="CardBoxContent">
								<div class="mainZoneBox">
									<h3>Zone</h3>
									<p>Eastern/ERO</p>
								</div>
								<div class="mainZoneBox zoneBoxWithLine">
									<h3>State</h3>
									<ul>
										<li>Andaman and Nicobar Islands </li>
										<li>Arunachal Pradesh</li>
										<li>Assam</li>
										<li>Jharkhand</li>
										<li>Manipur</li>
										<li>Meghalaya</li>
										<li>Mizoram</li>
										<li>Nagaland</li>
										<li>Odisha/Orissa</li>
										<li>Sikkim</li>
										<li>Tripura</li>
										<li>West Bengal</li>
									</ul>
								</div>
						</div>
						</div>

				</div>
			</div>
			
			


		</div>
		
	</div>
</section>



<!-- <div class="panel panel-default">
	<div class="panel-body table-responsive">
		<table cellspacing="0" border="0" width="95%" style="margin:auto;">
	<colgroup width="184"></colgroup>
	<colgroup width="186"></colgroup>
	<colgroup width="249"></colgroup>
	<colgroup width="255"></colgroup>
	<tbody><tr class="btn-primary-table table_design">
		<td colspan="4" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000;border-right: 1px solid #000000;" height="55" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Implelmentation team of Institutions Innvovation Council</font></b></td>

	</tr>
	<tr class="btn-primary-table table_design">
		<td colspan="4" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;" height="48" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Mr. Dipan Sahu <br>National Coordinator</font></b></td>

	</tr>
	<tr class="btn-primary-table table_design">
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="42" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Zone</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">State</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Region</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Zone Incharge</font></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" height="40" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Southern/SRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Puducherry</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Southern/SRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="14" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Ms. Selvarani<br>selva.rani@aicte-india.org<br>011 2958 1513</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Tamil Nadu</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Southern/SRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="12" height="258" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Eastern/ERO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Andaman and Nicobar Islands</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Arunachal Pradesh</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Assam</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Jharkhand</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Manipur</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Meghalaya</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Mizoram</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Nagaland</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Odisha/Orissa</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Sikkim</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Tripura</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">West Bengal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#F2F2F2"><font color="#000000">Eastern/ERO</font></td>
		</tr>
		<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" height="60" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Northern/NRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Bihar</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">Northern/NRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="10" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Mr. Ankush Sharma<br>ankush.sharma@aicte-india.org<br>011 2958 1223<br></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Uttar Pradesh</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">Northern/NRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Uttarakhand</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">Northern/NRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="7" height="140" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#222222">North-West/NWRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Chandigarh</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">North-West/NWRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Delhi</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">North-West/NWRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Haryana</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">North-West/NWRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Himachal Pradesh</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">North-West/NWRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Jammu and Kashmir</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">North-West/NWRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Punjab</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">North-West/NWRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Rajasthan</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#FFFFFF"><font color="#000000">North-West/NWRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" height="60" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Central/CRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Chhattisgarh</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#E7E6E6"><font color="#000000">Central/CRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="5" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Mr. Sarim Moin<br>innovationofficer2@aicte-india.org<br>011 2958 1241</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Gujarat</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#E7E6E6"><font color="#000000">Central/CRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Madhya Pradesh</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#E7E6E6"><font color="#000000">Central/CRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" height="40" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">South-Central/SCRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Andhra Pradesh</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#E7E6E6"><font color="#000000">South-Central/SCRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Telangana</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom" bgcolor="#E7E6E6"><font color="#000000">South-Central/SCRO</font></td>
		</tr>


	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" height="40" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">South-West/SWRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Karnataka</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom"><font color="#000000">South-West/SWRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="6" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Mr. Pankaj Pandey<br>pankajpandey@aicte-india.org<br>011 2958 1239<br></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Kerala</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom"><font color="#000000">South-West/SWRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="4" height="80" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Western/WRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Dadra and Nagar Haveli</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom"><font color="#000000">Western/WRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Daman and Diu</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom"><font color="#000000">Western/WRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Goa</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom"><font color="#000000">Western/WRO</font></td>
		</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Maharashtra</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="bottom"><font color="#000000">Western/WRO</font></td>
		</tr>
</tbody></table><br><br>
								</div>
							</div>

							-->
