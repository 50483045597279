<mat-tab-group mat-align-tabs="center">
<mat-tab label="Nominate Representatives for IIC Regional Meet">
<div class="container">
    <div class="row" color="primary">
		<div class="col-md-12">
			<h2 class="ss"
				style="text-align: center;background-color: #545460;padding: 10px 0;color: #fff;border-radius: 4px; margin: 20px 0 20px 0;">
				Nominate Representatives for IIC Regional Meet
			</h2>
		</div>
    </div>

  <div class="nomiButton"> <!-- Button trigger modal -->
    <button type="button" class="downloadButtonNomni" data-toggle="modal" data-target="#exampleModaliic">
      Poster Guidelines
    </button>

    <a href="{{ imageBasePath }}assets/announcements/Sample Copy - Poster Design.pdf" target="_blank" class="downloadButtonNomni"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>Sample Poster</a>
	<a href="{{ imageBasePath }}assets/announcements/Tentative-Schedule-and-Agenda-Plan-IIC-Regional-Meet.pdf" target="_blank" class="downloadButtonNomni"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>Schedule of IIC Regional Meet 2025 - Download</a>
	</div>

	<!-- Modal -->
	<div class="modal fade" id="exampleModaliic" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="top:15%;">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<p>IIC institutions are also invited to take part in the IIC Poster Gallery round to be held during the regional meet. This will be a demonstration platform for participating IIC institutions to showcase institutions' innovation and entrepreneurship ecosystem preparedness, models/best practices, contributions, recent achievements and impacts made in the last 2 academic years 2022-23, 2023-24. For this, IIC institute is expected to prepare a poster in the form of Roll up Standee of size 6 by 3 feet size for display during the regional meet. Best posters from IIC institutions will be felicitated during the regional meet.</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>


  <p style="color: #FF0000; font-size:20px; font-weight: 600;">*Note - Minimum one faculty member is required for this regional meet nomination.</p>

	<form class="instiBankForm" [formGroup]="iicCoordinatorAddition" (ngSubmit)="onSubmit(iicCoordinatorAddition.value)">

  <!-- Nominate For IIC Track -->

		<div class="iic-boxshow">
			<section class="example-section">
				<mat-checkbox class="example-margin" formControlName="type_iic" [checked]='type_iic_checked' value="Yes" (change)="onTypeChange($event,'IIC')" style="font-size: 18px;font-weight: 600;color: #000;">For Participants 1</mat-checkbox>
			</section>

			<div class="iic-subfields" [ngClass]="showDivField_1=='1' ? 'irshowtr' : 'irhidetr'" >
				<div class="row">
					<div class="col-sm-6 text-left">
						<p>
							<mat-radio-group formControlName="submission_type_1" (change)="onStatusChange($event,'IIC')"  aria-label="Select an option" class="col-md-12 text-left" >
								<mat-radio-button class="alumniRadioBut" value="Members"><p class="radioButpara">Faculty Member</p></mat-radio-button>
								&nbsp;&nbsp;
								<mat-radio-button class="alumniRadioBut" value="Student"><p class="radioButpara">Student Member</p></mat-radio-button>
								&nbsp;&nbsp;
								<!-- <mat-radio-button class="alumniRadioBut" value="IA"><p class="radioButpara">IA Member</p></mat-radio-button>
								&nbsp;&nbsp; -->
								<mat-radio-button class="alumniRadioBut" value="Others"><p class="radioButpara">Others</p></mat-radio-button>
							</mat-radio-group>
						</p>
					</div>
					<div class="col-sm-6">
						<p [ngClass]="requireField_1=='1' ? 'irshowtr' : 'irhidetr'">
							<mat-form-field appearance="outline" class="w-100">
								<mat-label> Select Participants </mat-label>
								<mat-select autocomplete="off" placeholder="Select Participants" formControlName="user_name_1" (selectionChange)="onNbaStatusChange($event,'IIC')">
									<mat-option>Select</mat-option>
									<mat-option value="{{activity.id}}" *ngFor="let activity of iicActivity">{{activity.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</p>
						<p [ngClass]="requireField_9=='1' ? 'irshowtr' : 'irhidetr'">
							<mat-form-field appearance="outline" class="w-100">
								<mat-label> Select Student </mat-label>
								<mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name_1" (selectionChange)="onNbaStatusChange($event,'Student','Student_p1')">
									<mat-option>Select</mat-option>
									<mat-option value="{{activity.Id}}" *ngFor="let activity of studentDetail">{{activity.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</p>
						<!-- <p [ngClass]="requireField_21=='1' ? 'irshowtr' : 'irhidetr'">
							<mat-form-field appearance="outline" class="w-100">
								<mat-label> Select IA </mat-label>
								<mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name_1" (selectionChange)="onNbaStatusChange($event,'IA','IA_p1')">
									<mat-option>Select</mat-option>
									<mat-option value="{{activity.id}}" *ngFor="let activity of iaDetail">{{activity.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</p> -->
					</div>
				</div>

				<table class="table table-hover">
					<tr>
						<td colspan="2">
							<div class="row">

								<div class="col-sm-3" [ngClass]="requireField_5=='1' ? 'irshowtr' : 'irhidetr'">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Name</mat-label>
										<input matInput autocomplete="off" placeholder="Name" formControlName="name_1" type="text">
									</mat-form-field>
								</div>

								<div class="col-sm-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Email</mat-label>
										<input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email_1">
									</mat-form-field>
								</div>

								<div class="col-sm-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Contact</mat-label>
										<input matInput autocomplete="off" placeholder="Contact" formControlName="contact_1" type="text">
									</mat-form-field>
								</div>

								<div class="col-sm-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label> Select Gender </mat-label>
										<mat-select autocomplete="off" placeholder="Select Gender" formControlName="gender_1">
											<mat-option>Select</mat-option>
											<mat-option value="Male">Male</mat-option>
											<mat-option value="Female">Female</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

							</div>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<mat-form-field appearance="outline" class="w-100" >
								<mat-label> Select a Regional Meet to Attend </mat-label>
								<mat-select autocomplete="off" placeholder="Select a Regional Meet to Attend" formControlName="city_1"  >
									<mat-option value="{{iicCity.host_city}}" *ngFor="let iicCity of iicCoordinatorCity">
										Seats Available {{iicCity.totalSeatAvailable}} >>
										{{iicCity.mentoring_session_date}} >>
										{{iicCity.host_city}} >>
										{{iicCity.host_institute_name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<!-- Nominate For NISP Track -->

		<div class="iic-boxshow">
			<section class="example-section">
				<mat-checkbox class="example-margin" formControlName="type_nisp" value="Yes" [checked]='type_nisp_checked' (change)="onTypeChange($event,'NISP')">For Participants 2</mat-checkbox>
			</section>

			<div class="iic-subfields" [ngClass]="showDivField_2=='1' ? 'irshowtr' : 'irhidetr'" >
				<div class="row">
					<div class="col-sm-6 text-left">
						<p>
							<mat-radio-group formControlName="submission_type_2" (change)="onStatusChange($event,'NISP')"  aria-label="Select an option" class="col-md-12 text-left" >
								<mat-radio-button class="alumniRadioBut" value="Members"><p class="radioButpara">Faculty Member</p></mat-radio-button>
								&nbsp;&nbsp;
								<mat-radio-button class="alumniRadioBut" value="Student"><p class="radioButpara">Student Member</p></mat-radio-button>
								&nbsp;&nbsp;
								<!-- <mat-radio-button class="alumniRadioBut" value="IA"><p class="radioButpara">IA Member</p></mat-radio-button>
								&nbsp;&nbsp; -->
								<mat-radio-button class="alumniRadioBut" value="Others"><p class="radioButpara">Others</p></mat-radio-button>
							</mat-radio-group>
						</p>
					</div>
					<div class="col-sm-6">
						<p [ngClass]="requireField_2=='1' ? 'irshowtr' : 'irhidetr'">
							<mat-form-field appearance="outline" class="w-100">
								<mat-label> Select Co-ordinator </mat-label>
								<mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name_2" (selectionChange)="onNbaStatusChange($event,'NISP')">
									<mat-option>Select</mat-option>
									<mat-option value="{{activity.id}}" *ngFor="let activity of iicActivity">{{activity.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</p>
						<p [ngClass]="requireField_10=='1' ? 'irshowtr' : 'irhidetr'">
							<mat-form-field appearance="outline" class="w-100">
								<mat-label> Select Student </mat-label>
								<mat-select autocomplete="off" formControlName="user_name_2" (selectionChange)="onNbaStatusChange($event,'Student','Student_p2')">
									<mat-option>Select</mat-option>
									<mat-option value="{{activity.Id}}" *ngFor="let activity of studentDetail">{{activity.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</p>
						<!-- <p [ngClass]="requireField_22=='1' ? 'irshowtr' : 'irhidetr'">
							<mat-form-field appearance="outline" class="w-100">
								<mat-label> Select IA </mat-label>
								<mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name_2" (selectionChange)="onNbaStatusChange($event,'IA','IA_p2')">
									<mat-option>Select</mat-option>
									<mat-option value="{{activity.id}}" *ngFor="let activity of iaDetail">{{activity.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</p> -->
					</div>
				</div>

				<table class="table table-hover">
					<tr>
						<td colspan="2">
							<div class="row">

								<div class="col-sm-3" [ngClass]="requireField_6=='1' ? 'irshowtr' : 'irhidetr'">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Name</mat-label>
										<input matInput autocomplete="off" placeholder="Name" formControlName="name_2" type="text">
									</mat-form-field>
								</div>

								<div class="col-sm-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Email</mat-label>
										<input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email_2">
									</mat-form-field>
								</div>

								<div class="col-sm-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Contact</mat-label>
										<input matInput autocomplete="off" placeholder="Contact" formControlName="contact_2" type="text">
									</mat-form-field>
								</div>

								<div class="col-sm-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label> Select Gender </mat-label>
										<mat-select autocomplete="off" placeholder="Select Gender" formControlName="gender_2" >
											<mat-option>Select</mat-option>
											<mat-option value="Male">Male</mat-option>
											<mat-option value="Female">Female</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

							</div>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<mat-form-field appearance="outline" class="w-100" >
								<mat-label> Select a Regional Meet to Attend </mat-label>
								<mat-select autocomplete="off" placeholder="Select a Regional Meet to Attend" formControlName="city_2" >
									<mat-option value="{{iicCity.host_city}}" *ngFor="let iicCity of iicCoordinatorCity">
										Seats Available {{iicCity.totalSeatAvailable}} >>
										{{iicCity.mentoring_session_date}} >>
										{{iicCity.host_city}} >>
										{{iicCity.host_institute_name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</td>
					</tr>
				</table>
			</div>
		</div>



<!-- Nominate For ARIIA Track -->

	<!-- <div class="iic-boxshow">
		<section class="example-section">
			<mat-checkbox class="example-margin" formControlName="type_ariia" value="Yes" [checked]='type_ariia_checked' (change)="onTypeChange($event,'ARIIA')">For Participants 3</mat-checkbox>
		</section>

		<div class="iic-subfields" [ngClass]="showDivField_3=='1' ? 'irshowtr' : 'irhidetr'" >
			<div class="row">
				<div class="col-sm-6 text-left">
					<p><mat-radio-group formControlName="submission_type_3" (change)="onStatusChange($event,'ARIIA')"  aria-label="Select an option" class="col-md-12 text-left" >
						<mat-radio-button class="alumniRadioBut" value="Members"><p class="radioButpara">Faculty Member</p></mat-radio-button>
						&nbsp;&nbsp;
						<mat-radio-button class="alumniRadioBut" value="Student"><p class="radioButpara">Student Member</p></mat-radio-button>
						&nbsp;&nbsp;
						<mat-radio-button class="alumniRadioBut" value="IA"><p class="radioButpara">IA Member</p></mat-radio-button>
						&nbsp;&nbsp;
						<mat-radio-button class="alumniRadioBut" value="Others"><p class="radioButpara">Others</p></mat-radio-button>
					</mat-radio-group>
					</p>
				</div>
				<div class="col-sm-6">
					<p [ngClass]="requireField_3=='1' ? 'irshowtr' : 'irhidetr'">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label> Select Co-ordinator </mat-label>
							<mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name_3" (selectionChange)="onNbaStatusChange($event,'ARIIA')">
								<mat-option>Select</mat-option>
								<mat-option value="{{activity.id}}" *ngFor="let activity of iicActivity">{{activity.name}}</mat-option>
							</mat-select>
						</mat-form-field>
					</p>
					<p [ngClass]="requireField_11=='1' ? 'irshowtr' : 'irhidetr'">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label> Select Student </mat-label>
							<mat-select autocomplete="off" formControlName="user_name_3" (selectionChange)="onNbaStatusChange($event,'Student','Student_p3')">
								<mat-option>Select</mat-option>
								<mat-option value="{{activity.Id}}" *ngFor="let activity of studentDetail">{{activity.name}}</mat-option>
							</mat-select>
						</mat-form-field>
					</p>
					<p [ngClass]="requireField_23=='1' ? 'irshowtr' : 'irhidetr'">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label> Select IA </mat-label>
							<mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name_3" (selectionChange)="onNbaStatusChange($event,'IA','IA_p3')">
								<mat-option>Select</mat-option>
								<mat-option value="{{activity.id}}" *ngFor="let activity of iaDetail">{{activity.name}}</mat-option>
							</mat-select>
						</mat-form-field>
					</p>
				</div>
			</div>

			<table class="table table-hover">
				<tr>
					<td colspan="2">
						<div class="row">

							<div class="col-sm-3" [ngClass]="requireField_7=='1' ? 'irshowtr' : 'irhidetr'">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Name</mat-label>
									<input matInput autocomplete="off" placeholder="Name" formControlName="name_3" type="text">
								</mat-form-field>
							</div>

							<div class="col-sm-3">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Email</mat-label>
									<input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email_3">
								</mat-form-field>
							</div>

							<div class="col-sm-3">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Contact</mat-label>
									<input matInput autocomplete="off" placeholder="Contact" formControlName="contact_3" type="text">
								</mat-form-field>
							</div>

							<div class="col-sm-3">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label> Select Gender </mat-label>
									<mat-select autocomplete="off" placeholder="Select Gender" formControlName="gender_3" >
										<mat-option>Select</mat-option>
										<mat-option value="Male">Male</mat-option>
										<mat-option value="Female">Female</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

						</div>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<mat-form-field appearance="outline" class="w-100" >
							<mat-label> Select a Regional Meet to Attend </mat-label>
							<mat-select autocomplete="off" placeholder="Select a Regional Meet to Attend" formControlName="city_3" >
								<mat-option value="{{iicCity.host_city}}" *ngFor="let iicCity of iicCoordinatorCity">
									Seats Available {{iicCity.totalSeatAvailable}} >>
                  {{iicCity.mentoring_session_date}} >>
									{{iicCity.host_city}} >>
									{{iicCity.host_institute_name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</td>
				</tr>
		   </table>

		</div>

	</div> -->



<!-- Nominate For IP Track -->

<!-- <div class="iic-boxshow">
  <section class="example-section">
    <mat-checkbox class="example-margin" formControlName="type_ip" value="Yes" [checked]='type_ip_checked' (change)="onTypeChange($event,'IP')">For Participants 4</mat-checkbox>
  </section>

  <div class="iic-subfields" [ngClass]="showDivField_4=='1' ? 'irshowtr' : 'irhidetr'" >
    <div class="row">
      <div class="col-sm-6 text-left">
        <p><mat-radio-group formControlName="submission_type_4" (change)="onStatusChange($event,'IP')"  aria-label="Select an option" class="col-md-12 text-left" >
          <mat-radio-button class="alumniRadioBut" value="Members"><p class="radioButpara">Faculty Member</p></mat-radio-button>
          &nbsp;&nbsp;
          <mat-radio-button class="alumniRadioBut" value="Student"><p class="radioButpara">Student Member</p></mat-radio-button>
          &nbsp;&nbsp;
          <mat-radio-button class="alumniRadioBut" value="IA"><p class="radioButpara">IA Member</p></mat-radio-button>
          &nbsp;&nbsp;
          <mat-radio-button class="alumniRadioBut" value="Others"><p class="radioButpara">Others</p></mat-radio-button>
        </mat-radio-group>
        </p>
      </div>
      <div class="col-sm-6">
        <p [ngClass]="requireField_4=='1' ? 'irshowtr' : 'irhidetr'">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label> Select Co-ordinator </mat-label>
            <mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name_4" (selectionChange)="onNbaStatusChange($event,'IP')">
              <mat-option>Select</mat-option>
              <mat-option value="{{activity.id}}" *ngFor="let activity of iicActivity">{{activity.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </p>
				<p [ngClass]="requireField_12=='1' ? 'irshowtr' : 'irhidetr'">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label> Select Student </mat-label>
						<mat-select autocomplete="off" formControlName="user_name_4" (selectionChange)="onNbaStatusChange($event,'Student','Student_p4')">
							<mat-option>Select</mat-option>
							<mat-option value="{{activity.Id}}" *ngFor="let activity of studentDetail">{{activity.name}}</mat-option>
						</mat-select>
					</mat-form-field>
				</p>
				<p [ngClass]="requireField_24=='1' ? 'irshowtr' : 'irhidetr'">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label> Select IA </mat-label>
						<mat-select autocomplete="off" placeholder="Select Co-ordinator" formControlName="user_name_4" (selectionChange)="onNbaStatusChange($event,'IA','IA_p4')">
							<mat-option>Select</mat-option>
							<mat-option value="{{activity.id}}" *ngFor="let activity of iaDetail">{{activity.name}}</mat-option>
						</mat-select>
					</mat-form-field>
				</p>
      </div>
    </div>

    <table class="table table-hover">
      <tr>
        <td colspan="2">
          <div class="row">

            <div class="col-sm-3" [ngClass]="requireField_8=='1' ? 'irshowtr' : 'irhidetr'">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Name</mat-label>
                <input matInput autocomplete="off" placeholder="Name" formControlName="name_4" type="text">
              </mat-form-field>
            </div>

            <div class="col-sm-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Email</mat-label>
                <input matInput type="email" autocomplete="off" placeholder="Email" formControlName="email_4">
              </mat-form-field>
            </div>

            <div class="col-sm-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Contact</mat-label>
                <input matInput autocomplete="off" placeholder="Contact" formControlName="contact_4" type="text">
              </mat-form-field>
            </div>

            <div class="col-sm-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label> Select Gender </mat-label>
                <mat-select autocomplete="off" placeholder="Select Gender" formControlName="gender_4">
                  <mat-option>Select</mat-option>
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <mat-form-field appearance="outline" class="w-100" >
            <mat-label> Select a Regional Meet to Attend </mat-label>
            <mat-select autocomplete="off" placeholder="Select a Regional Meet to Attend" formControlName="city_4" >
              <mat-option value="{{iicCity.host_city}}" *ngFor="let iicCity of iicCoordinatorCity">
                Seats Available {{iicCity.totalSeatAvailable}} >>
                {{iicCity.mentoring_session_date}} >>
                {{iicCity.host_city}} >>
                {{iicCity.host_institute_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
     </table>

  </div>

</div> -->

	<!-- Footer Form Data  -->
	<p><mat-form-field appearance="outline" class="w-100" >
		<mat-label> Confirm institute's participation in IIC Poster display during the regional meet </mat-label>
		<mat-select autocomplete="off" placeholder="Confirm institute's participation in IIC Poster display during the regional meet" formControlName="poster" required >
			<mat-option value="Yes">Yes</mat-option>
			<mat-option value="No">No</mat-option>
		</mat-select>
	</mat-form-field></p>

  <p><button mat-raised-button color='primary' class="btn btn-success float-right" [disabled]='iicCoordinatorAddition.invalid'>Submit</button></p>

	</form>

</div>
</mat-tab>
<mat-tab label="Yukti Nominate for Regional Meet">
	<app-yukti-rm-nomination></app-yukti-rm-nomination>
</mat-tab>
</mat-tab-group>