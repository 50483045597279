<div class="container" *ngIf="this.showStatus==='1' || this.showStatus==='0'">
<!-- <mat-card>
    <p>Your institute is eligible to join in Mentor-Mentee scheme as {{this.type}}.  If your institute is willing to participate, please nominate a coordinator.</p>
</mat-card> -->
<!-- *ngIf="this.type=='mentee'" -->
<mat-card style="margin-bottom: 36px;" >
    <form [formGroup]="impactAnalysis" (ngSubmit)="onSubmit(impactAnalysis.value)">
        <div class="row">
		<div class="col-md-2"></div>
		<div class="col-md-8">
			<div fxFlex="50" class="m-2">
            <mat-form-field class="example-full-width nominateMentorBox" appearance="outline" style="width: 493px;" >
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Nominate coordinator from your institute*</mat-label>
              <mat-select formControlName="mentor" [(ngModel)]="mentorModel">
                <mat-option *ngFor="let role of mentor" [value]="role?.memberId">
                  {{role?.facultyName}}
                </mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="impactAnalysis.controls['faculty'].hasError('required')">Coordinator from My Council Faculty members is
                required</mat-error> -->
            </mat-form-field>
          </div>
		</div>
		<div class="col-md-2"></div>


        </div>
  <!-- <div class="row" style="
  margin-left: 168px;
">
    <div class="col-lg-3 col-md-10">
      <label>Upload Mandate Form:</label>
      <mat-error>PDF(max 2MB)</mat-error>
    </div>

    <div class="col-lg-4 col-md-2 float-right">
      <label class="custom-file-label float-right" for="customFile" *ngIf="!externalCV">Choose file</label>
      <label class="custom-file-label float-right" for="customFile"
        *ngIf="externalCV">{{externalCV[0]['name']}}</label>
      <input #externalCVRef type="file" accept="application/pdf" name="externalCV"
        (change)="( externalCV = externalCVRef.files )" class="custom-file-input float-right" required>
    </div>

  </div>

  <div class="row">
    <a href="../../../../../../assets/doc/Mandate Form for Institute.doc" style="vertical-align: -webkit-baseline-middle;
    float: left;font-size:14px;"
    >
      <font color="#b13c52"><b><u style="margin-left: 193px;text-decoration: underline !important;">Format of mandate form</u></b></font>
    </a>
  </div> -->





<!-- </div> -->

<!-- <div *ngIf="showCV">
  <div class="row">
    <a href="{{baseImageUrl + data.CV_upload}}" target="_blank">
      <button type="button" class="btn btn-danger mt-2 mb-2">Download CV</button>
      <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showCV = !showCV">Upload
        CV</button>
    </a>
  </div>
</div> -->
<div class="row">
		<div class="col-md-5"></div>
		<div class="col-md-2">
			<div fxFlex="50" class="m-2">   <button style="
            margin-left: 603px;
        " mat-raised-button color='primary' class="btn btn-success float-right"
            [disabled]='impactAnalysis.invalid'>Submit</button></div>
		</div>
		<div class="col-md-5"></div>
        </div>
        <!-- </div> -->
</form>

<!-- <div class="row" *ngIf="this.mandate==1">
  <p>Fill Signed Mandate Form</p>
  <button mat-raised-button color='primary' class="btn btn-success float-right" (click)="signedForm()"
>Mandate Form</button>
</div> -->

</mat-card>


    <!-- List starts -->

    <div class="row" color="primary">
      <h2 class="ss"
          style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
        Nominated IIC Member Details </h2>
  </div>


  <div class="">
      <div class="">
          <table class="table table-hover">
              <thead class="facultyTable">
                  <tr>
                      <!-- <th scope="col">S.No.</th> -->
                      <th scope="col"> Name</th>
                      <th scope="col"> Email</th>
                      <th scope="col"> Contact</th>
                      <th scope="col">Designation</th>
                      <!-- <th scope="col">Mandate Form</th> -->
                      <th scope="col">Action</th>

                  </tr>
              </thead>
              <tbody>
                  <tr *ngIf="this.status!= '0'">
                      <!-- <td *ngIf="nomination?.training_type==1">{{i+1}}</td> -->
                      <td >{{mentorName}}</td>
                      <td >{{mentor_email}}</td>
                      <td >{{mentor_mobile}}</td>
                      <td>{{mentor_designation}}</td>

                      <!-- <td>
                        <a href="{{baseImageUrl + file_upload}}" target="_blank">
                        <button type="button" class="btn btn-danger mt-2 mb-2">View Mandate Form</button>
                      </a></td> -->

                      <td>
                        <!-- *ngIf="this.type=='mentee'"
                        *ngIf="this.type=='mentee'" -->
                        <button mat-raised-button (click)="onEditClick()" color="primary" class="mentorButEdit" *ngIf="isVisible">
                            <mat-icon>create</mat-icon>
                            Edit</button>
                              <button mat-raised-button (click)="onDeleteClick()" color="warn" *ngIf="isVisible">
                            <mat-icon>delete</mat-icon>
                            Delete</button>
                      </td>
                      <!-- <td >

            <button mat-fab color="primary" (click)="onDeleteClick(nomination)" style="
            background-color: #de2727;
          ">
              <mat-icon>delete</mat-icon>
            </button>                        </td> -->
                  </tr>
                  <tr *ngIf="this.status== '0'" col="5">
                      <h3 style="text-align: center">No Data Found</h3>
                  </tr>
              </tbody>
          </table>

      </div>
      <!-- <div class="row">
          <div class="col-md-12">
              <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)" [length]="total_pages"
                  [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
              </mat-paginator>

          </div>
      </div> -->
  </div>



  <!-- List ends -->


</div>

<!-- <div class="container" *ngIf="this.showStatus=='0'">
    <p>
        Please Complete My Council Tab.
    </p>
    </div> -->
