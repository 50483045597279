import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileService } from 'src/app/Services/file.service';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as myGlobals from '../../../../../../app.component';


@Component({
  selector: 'app-institute-second-activity-form',
  templateUrl: './institute-activity-second-form.component.html',
  styleUrls: ['./institute-activity-second-form.component.css']
})
export class InstituteActivitySecondFormComponent implements OnInit {

  public mycouncil: FormGroup;

  public urls: {
    resolution: any;
    quarterly_plan: any;
    mom: any;
    coucil_photo: any;
  };

  public event_id: string;
  public action: string;
  public institute_id: string;
  public program_driven_by = [];
  //public baseUrl = "https://mic.gov.in";
  public baseImageUrl = myGlobals.baseImageUrl;


  constructor(
    private fb: FormBuilder,
    private instituteActivityService: InstituteActivityService,
    private fileService: FileService,
    private toastr: ToastrService,
    //public dialogRef: MatDialogRef<InstituteCouncilMeetingDialogComponent>,
    //@Inject(MAT_DIALOG_DATA) public data: any
    private route: ActivatedRoute,
    private router: Router
    //private instituteAnnouncementService: InstituteAnnouncementService
  ) {

    this.event_id = '';
    this.action = '';
    this.institute_id = '';

    this.urls = {
      resolution: '',
      quarterly_plan: '',
      mom: '',
      coucil_photo: ''
    };
  }

  ngOnInit(): void {

    // this.event_id = this.route.snapshot.paramMap.get('event_id');
    // this.action = this.route.snapshot.queryParamMap.get('action');
    // this.institute_id = this.route.snapshot.queryParamMap.get('instid');

    this.route.params.subscribe(params => {
      //this.id = +params['id']; // (+) converts string 'id' to a number
      this.event_id = params['event_id'];



      // In a real app: dispatch action to load the details here.
    });

    this.route.queryParamMap.subscribe((params) => this.setQueryParams(params));


    // this.route.paramMap.subscribe(params => {
    //   this.product_id = params.get('id');
    // });
    this.mycouncil = this.fb.group({

      event_driven: [null, Validators.required],
      activity_name: [null, Validators.required],
      date_conducted_meeting: [null, Validators.required],
      quarter: [null, Validators.required],

      resolution: [null, Validators.required],
      resolution_file: [null, Validators.required],

      quarterly_plan: [null, Validators.required],
      quarterly_plan_file: [null, Validators.required],

      minutes_of_meeting: [null, Validators.required],
      minutes_of_meeting_file: [null, Validators.required],

      photo_upload: [null, Validators.required],
      photo_upload_file: [null, Validators.required]
    });

    this.instituteActivityService
      .getMonthlyReportOfCouncil(this.event_id)
      .subscribe(
        (data) => this.handleResponse(data)
      );
  }

  setQueryParams(data) {
    //this.orderObj = { ...params.keys, ...params };
    this.action = data.get('action');
    this.institute_id = data.get('instid') ? data.get('instid') : '';
  }


  handleResponse(data) {

    this.mycouncil.patchValue({
      activity_name: data.title,
      event_driven: data.type
    });

    if (this.action == 'edit') {


      this.urls.resolution = data.resolution_report && data.resolution_report != '0' ? this.baseImageUrl + data.resolution_report : '';
      this.urls.quarterly_plan = data.quarterly_plan && data.quarterly_plan != '0' ? this.baseImageUrl + data.quarterly_plan : '';
      this.urls.mom = data.report && data.report != '0' ? this.baseImageUrl + data.report : '';
      this.urls.coucil_photo = data.photo1 && data.photo1 != '0' ? this.baseImageUrl + data.photo1 : '';

      this.mycouncil.patchValue({
        quarter: data.quarter,
        date_conducted_meeting: new Date(data.date)
      });

    }
  }

  onFileChange(event, type) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (type == 'resolution') {
        this.mycouncil.patchValue({
          resolution_file: file
        });
      } else if (type == 'quarterly_plan') {
        this.mycouncil.patchValue({
          quarterly_plan_file: file
        });
      } else if (type == 'minutes_of_meeting') {
        this.mycouncil.patchValue({
          minutes_of_meeting_file: file
        });
      } else if (type == 'photo_upload') {
        this.mycouncil.patchValue({
          photo_upload_file: file
        });
      }
    }
  }


  public submitApplication(): void {

    //var form =  this.mycouncil.value;
    var date_conducted_meeting = this.mycouncil.get('date_conducted_meeting').value; //this.form.date_conducted_meeting;
    var quarter = this.mycouncil.get('quarter').value;//this.form.quarter;
    var activity_name = this.mycouncil.get('activity_name').value;//this.form.activity_name;

    var resolution = this.mycouncil.get('resolution_file').value;
    var quarterly_plan = this.mycouncil.get('quarterly_plan_file').value;
    var minutes_of_meeting = this.mycouncil.get('minutes_of_meeting_file').value;
    var photo_upload = this.mycouncil.get('photo_upload_file').value;

    //formData.append('file', this.myForm.get('resolution').value);
    //var resolution =
    //   this.form.resolution && this.form.resolution.length
    //     ? this.form.resolution[0]
    //     : null;
    // var quarterly_plan =
    //   this.form.quarterly_plan && this.form.quarterly_plan.length
    //     ? this.form.quarterly_plan[0]
    //     : null;
    // var minutes_of_meeting =
    //   this.form.minutes_of_meeting && this.form.minutes_of_meeting.length
    //     ? this.form.minutes_of_meeting[0]
    //     : null;
    // var photo_upload =
    //   this.form.photo_upload && this.form.photo_upload.length
    //     ? this.form.photo_upload[0]
    //     : null;

    this.submitData({
      event_id: this.event_id,
      event_driven: this.mycouncil.get('event_driven').value,
      activity_name: activity_name,
      date_conducted_meeting: date_conducted_meeting,
      quarter: quarter,
      resolution: resolution,
      quarterly_plan: quarterly_plan,
      minutes_of_meeting: minutes_of_meeting,
      photo_upload: photo_upload,
    });

  }

  submitData(data) {

    //this.router.navigate(['/institute/monthly-report-of-council/'+this.event_id], { queryParams: { action: 'edit' }});

    if (this.mycouncil.valid) {
      var formData = new FormData();
      formData.append('eventId', data.event_id);
      formData.append('eventDriven', data.event_driven);
      formData.append('activityName', data.activity_name);
      formData.append('date_conducted_meeting', this.formatDate(data.date_conducted_meeting));
      formData.append('quarter', this.formatDate(data.quarter));

      data.resolution && formData.append('resolution', data.resolution);
      data.quarterly_plan &&
        formData.append('quarterly_plan', data.quarterly_plan);
      data.minutes_of_meeting &&
        formData.append('minutes_of_meeting', data.minutes_of_meeting);
      data.photo_upload && formData.append('photo_upload', data.photo_upload);


      this.instituteActivityService
        .submitMyMonthlyReportCouncil(formData)
        .subscribe((data) => this.handleSubmitResponse(data));
    }
  }

  handleSubmitResponse(data) {
    if (data['status'] == '1') {

      this.toastr.success(data['msg']);

      setTimeout(() => {
        this.router.navigate(['/institute/monthly-report-of-council/' + this.event_id], { queryParams: { action: 'edit' } });
        this.instituteActivityService
          .getMonthlyReportOfCouncil(this.event_id)
          .subscribe(
            (data) => this.handleResponse(data)
          );
      }, 2000);

      // this.instituteMaster
      // .getMonthlyReportData(this.event_id)
      // .subscribe((data) => this.handleResponseFacultyData(data));

    } else {
      this.toastr.error('Kindly check the details!');
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

}
