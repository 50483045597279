<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Manage Challenges</h2>
    </div>

	<div class="card">
        <div class="card-title" style="text-align: center;"><p>Create and Manage Idea/ Innovation/ Startup Challenges at Institute level.</p></div>
	</div>
<div class="refrredBox">
 <div class="row rws-listpage">
	<div class="col-md-12">
  
  <div class="tableBody">
				<div class="table-responsive">
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th>Sr. No.</th>
          <th>Innovation Title</th>
          <th>Description</th>
          <th>Institute Name</th>
          <th>City</th>
          <th>State</th>
          <th>Theme</th>
          <th>Verification Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let nomination of iicrepository; index as i" >
            <td>{{i+1}}</td>
          <td>{{nomination?.idea_title}}</td>
          <td>{{nomination?.description}}</td>
          <td>{{nomination?.instituteName}}</td>
          <td>{{nomination?.instituteCity}}</td>
          <td>{{nomination?.stateName}}</td>
          <td>{{nomination?.theme}}</td>
          <td>{{nomination?.verifier_status}}</td>
        </tr>
        <tr *ngIf="iicrepository.length== 0" col="7">
            <h3 style="text-align: center">No Data Found</h3>
        </tr>
    </tbody>
</table>
</div>
</div>



 </div>
</div>
</div>
</div>
