import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-institute-activity',
  templateUrl: './institute-iic-impact.component.html',
  styleUrls: ['./institute-iic-impact.component.css']
})
export class InstituteIicImpactComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  constructor(private fb: FormBuilder, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {


    this.instituteActivityService.getIicImpactDetails().subscribe(
      (data) => this.handleResponse(data)
    );
    this.iicImpactQuestions = this.fb.group({
      'que1': [null, Validators.required],
      'funding_allot_from_inst_bud_fy_18_19':[null, Validators.required,],
      'funding_allot_from_inst_bud_fy_19_20': [null, Validators.required],
      'funding_allot_from_inst_bud_fy_20_21': [null, Validators.required],
      'funding_allot_from_ext_source_fy_18_19': [null, Validators.required],
      'funding_allot_from_ext_source_fy_19_20': [null, Validators.required],
      'funding_allot_from_ext_source_fy_20_21': [null, Validators.required],
      'no_of_startup_register' : [null, Validators.required],
      'no_of_startup_yet_to_be_register' : [null, Validators.required],
      'no_of_startup_revenue_generated_fy_18_19' : [null, Validators.required],
      'no_of_startup_revenue_generated_fy_19_20' : [null, Validators.required],
      'no_of_startup_revenue_generated_fy_20_21' : [null, Validators.required],
      'no_of_startup_fund_receive_fy_18_19' : [null, Validators.required],
      'no_of_startup_fund_receive_fy_19_20' : [null, Validators.required],
      'no_of_startup_fund_receive_fy_20_21' : [null, Validators.required],
      'no_of_patent_failed' : [null, Validators.required],
      'no_of_patent_granted' : [null, Validators.required],
      'no_of_copyright_filed' : [null, Validators.required],
      'no_of_copyright_granted' : [null, Validators.required],
      'iic_createdi_e_campus_2018' : [null, Validators.required],
      'que7' : [null, Validators.required],
      'que8' : [null, Validators.required],
      'que9' : [null, Validators.required],
      'participeted_no_idea_submit' : [null, Validators.required],
      'participeted_no_of_student' : [null, Validators.required],
      'participeted_no_of_winer' : [null, Validators.required],
      'que11' : [null, Validators.required],
      'que12' : [null, Validators.required],
      'testimonials' : [null, Validators.required],
      'suggestions' : [null, Validators.required]
    });
  }

  handleResponse(data) {
    if(data['totalcount'] > 0){
    this.iicImpactQuestions.patchValue({
      que1: data['que1'],
      funding_allot_from_inst_bud_fy_19_20: data['funding_allot_from_inst_bud_fy_19_20'],
funding_allot_from_inst_bud_fy_20_21: data['funding_allot_from_inst_bud_fy_20_21'],
funding_allot_from_ext_source_fy_18_19: data['funding_allot_from_ext_source_fy_18_19'],
funding_allot_from_ext_source_fy_19_20: data['funding_allot_from_ext_source_fy_19_20'],
funding_allot_from_ext_source_fy_20_21: data['funding_allot_from_ext_source_fy_20_21'],
no_of_startup_register: data['no_of_startup_register'],
no_of_startup_yet_to_be_register: data['no_of_startup_yet_to_be_register'],
no_of_startup_revenue_generated_fy_18_19: data['no_of_startup_revenue_generated_fy_18_19'],
no_of_startup_revenue_generated_fy_19_20: data['no_of_startup_revenue_generated_fy_19_20'],
no_of_startup_revenue_generated_fy_20_21: data['no_of_startup_revenue_generated_fy_20_21'],
no_of_startup_fund_receive_fy_18_19: data['no_of_startup_fund_receive_fy_18_19'],
no_of_startup_fund_receive_fy_19_20: data['no_of_startup_fund_receive_fy_19_20'],
no_of_startup_fund_receive_fy_20_21: data['no_of_startup_fund_receive_fy_20_21'],
no_of_patent_failed: data['no_of_patent_failed'],
no_of_patent_granted: data['no_of_patent_granted'],
no_of_copyright_filed: data['no_of_copyright_filed'],
no_of_copyright_granted: data['no_of_copyright_granted'],
iic_createdi_e_campus_2018: data['iic_createdi_e_campus_2018'],
que7: data['que7'],
que8: data['que8'],
que9: data['que9'],
participeted_no_idea_submit: data['participeted_no_idea_submit'],
participeted_no_of_student: data['participeted_no_of_student'],
participeted_no_of_winer: data['participeted_no_of_winer'],
que11: data['que11'],
que12: data['que12'],
testimonials: data['testimonials'],
suggestions: data['suggestions'],
      funding_allot_from_inst_bud_fy_18_19: data['funding_allot_from_inst_bud_fy_18_19']

    });
  }
    this.totalcount = data['totalcount'];
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  onSubmit(data) {

    this.instituteActivityService.submitIicImpact(data,localStorage.getItem('token')).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg'])

    }
    else{
      this.toastr.error(data['msg']);

    }
  }



}
