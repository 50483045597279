<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12" >
                <table class="table table-hover" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="6" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">IIC Members participated in Innovation Ambassador Training Programs </h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col"> Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Program Participated</th>
                            <th scope="col">Status of Public Profile Registration in IA Portal</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of instituteList;index as i">
                            <td>{{i + 1}}</td>
                            <td>{{item.member_name}}</td>
                            <td *ngIf="item.themeName==null">N/A</td>
                            <td *ngIf="item.themeName!=null">{{item.themeName}}</td>

                            <td>{{item.source}}</td>



                        </tr>
                    </tbody>
                    <tbody *ngIf="instituteList.length<1">
                        <tr style="text-align: center;">
                            <td colspan="5">No Data forund</td>
                        </tr>
                    </tbody>
                </table>
               
            </div>

       
        </div>
    </div>
</div> 
