
<div class="rws-chartdata" style="clear: both; width: 100%;">

  <div class="row">
    <div class="col-md-3">

      <h3>Star Rating</h3>
<ngx-charts-bar-vertical
  [view]="view"
  [scheme]="barColorScheme"
  [results]="ratingChart1"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel">
  </ngx-charts-bar-vertical>

    </div>
    <div class="col-md-9">

      <h3>Graph with theme</h3>
  <!-- <ngx-charts-bar-vertical
  [view]="view2"
  [scheme]="barColorScheme"
  [results]="evmixdata"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel1"
  [yAxisLabel]="yAxisLabel1">
  </ngx-charts-bar-vertical> -->

  <ngx-charts-bar-vertical-2d
    [view]="view2"
    [scheme]="colorScheme3"
    [results]="evmixdata"
    [gradient]="gradient3"
    [xAxis]="showXAxis3"
    [yAxis]="showYAxis3"
    [legend]="showLegend3"
    [groupPadding]="5"
    [showXAxisLabel]="showXAxisLabel3"
    [showYAxisLabel]="showYAxisLabel3"
    [xAxisLabel]="xAxisLabel3"
    [yAxisLabel]="yAxisLabel3"
    [legendTitle]="legendTitle3">
  </ngx-charts-bar-vertical-2d>

    </div>

  </div>

</div>

<div class="rws-chartdata" style="clear: both; width: 100%; padding:20px 0 0 0;">
<table class="table table-bordered dataTable no-footer newTablePagiIncub table-responsive" style="color: #000;" role="grid" aria-describedby="myTable_ia_info">
<thead>
<tr role="row">
  <th class="sorting_asc" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-sort="ascending" width="100">Theme</th>
  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">Design Thinking & Critical Thinking</th>
  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">Entrepreneurship</th>
  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">Incubation & Pre Incubation</th>
  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">Innovation</th>
  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">IPR</th>
  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">Other</th>
  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">R&D and Innovation</th>
  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">Startup</th>
</tr>
</thead>
<tbody>
<tr role="row" class="odd">
  <td class="sorting_1">No. of Activities</td>
  <td *ngFor="let details of evThemeChart1; let i = index;">{{details?.value}}</td>
</tr>
<!-- <tr role="row" class="odd">
  <td class="sorting_1">Topper Institute Activities </td>
  <td *ngFor="let details2 of evThemeChart2; let i = index;">{{details2?.value}}</td>
</tr> -->
</tbody>
</table>
</div>

