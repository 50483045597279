import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-yukti-nominated-challenges',
  templateUrl: './yukti-nominated-challenges.component.html',
  styleUrls: ['./yukti-nominated-challenges.component.css']
})
export class YuktiNominatedChallengesComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  page: any;
  totalcount = 0;

  yukti_challenges:any;

  constructor(private fb: FormBuilder, private instituteMaster: InstituteInnovationContestService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.yuktiChallenge();
  }
  yuktiChallenge() {
    this.instituteMaster.getYuktiChallengeDetail('all').subscribe(
      data => this.handleChallengeResponse(data)
    );
  
  }
  handleChallengeResponse(data){
    this.yukti_challenges = data;
  }
  onClickEvent(id){
    if(confirm('Are you sure went to remove nomination.') === true){
      this.instituteMaster.yuktiChallengeNominateDelete(id).subscribe(
        data => this.handleChallengeRemoveResponse(data)
      );
    }else{

    }
  }
  handleChallengeRemoveResponse(data){
    if(data['status'] == 1){
      this.yuktiChallenge();
      this.toastr.success('Remove Successfully');
    }else{
      this.toastr.error('Something went Wrong.');
    }
  }
  public onPageChanged(event) {
    this.page = event;
  }
}
