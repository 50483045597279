import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';

@Component({
  selector: 'app-activity-proposal-dialog',
  templateUrl: './activity-proposal-dialog.component.html',
  styleUrls: ['./activity-proposal-dialog.component.css']
})
export class ActivityProposalDialogComponent implements OnInit {
  edit: boolean;
  editData: any;
  id: any;
  report: FileList | null;
  public addActivityForm: FormGroup;
  date1:any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ActivityProposalDialogComponent>,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public activity: InstituteActivityService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.edit = false;

      if (this.data != undefined || this.data != null) {
        this.edit = true;
    
        this.id = this.data['activity_id'];
      }
      else {
        this.edit = false;
      }
     }

  ngOnInit(): void {
   
     this.addActivityForm = this.fb.group({
      'session_date': [null,Validators.required],
      'session_time': [null,Validators.required],
      'speaker_name': [null],
      'designation':[null],
      'title':[null],
      'meeting_id':[null],
      'session':[null],
     });
 
this.setValidity(this.id);
this.activity.getSchedule(this.id).subscribe(data=>this.setData(data));
  }
  setValidity(id){
    // switch (id) {
      // case 1:  {
      //   const status = this.addActivityForm.get('meeting_id');
        // const status = this.addActivityForm.get('title');
        // const status = this.addActivityForm.get('meeting_id');

      //   status.setValidators([Validators.required]);
      //   status.updateValueAndValidity();
      //   break;
      // }
      // case 2:
      // case 3:
      // case 9:
      // case 10:
      // case 12:
      // case 13:
      // case 14:
      //   {
      //     const status = this.actionItemsForm.get('status');
      //     status.setValidators([Validators.required]);
      //     status.updateValueAndValidity();
      //     break;
      //   }
      // case 4:
      // case 5:
      // case 6:
      // case 7:
      // case 8:
      // case 11: {
      //   const status = this.actionItemsForm.get('status');
      //   const date_of_publish = this.actionItemsForm.get('date_of_publish');

      //   status.setValidators([Validators.required]);
      //   date_of_publish.setValidators([Validators.required]);
      //   status.updateValueAndValidity();
      //   date_of_publish.updateValueAndValidity();
      //   break;
      // }
  }
setData(data){
  console.log(data)
}
  onSubmit(data){
   

    var cvFile =
      this.report && this.report.length ? this.report[0] : null;

    var formData = new FormData();

    formData.append('file', cvFile);
    formData.append('id', this.id);
    formData.append('session_date',this.addActivityForm.get('session_date').value);
    formData.append('speaker_name',this.addActivityForm.get('speaker_name').value);
    formData.append('session_time',this.addActivityForm.get('session_time').value);
    formData.append('designation',this.addActivityForm.get('designation').value);
    formData.append('title',this.addActivityForm.get('title').value);
    formData.append('meeting_id',this.addActivityForm.get('meeting_id').value);
    formData.append('session',this.addActivityForm.get('session').value);


    
    // if (this.show1 == true) {
      this.activity
        .schedule(formData)
        .subscribe((data) => this.handleResponse(data));
    // }
    // else {

    //   this.myCouncilService.updateExternal(formData, values['id']).subscribe(
    //     data => this.handleResponse1(data)
    //   );
    // }

  }
  handleResponse(data){
    if(data['message']=="Success"){
      this.toastr.success("Submitted Successfully!")
    }
    else{
      this.toastr.error("Kindly check the values!")

    }
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

}
