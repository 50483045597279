import { Component, OnInit } from '@angular/core';
import { IicIncubatorstabService } from 'src/app/Services/iic-incubatorstab.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-preincubators',
  templateUrl: './preincubators.component.html',
  styleUrls: ['./preincubators.component.css']
})

export class PreincubatorsComponent implements OnInit {
  public preincubators: FormGroup;
  currentPageNo: number = 1;
  total_pages : number;
  firstPage = true;
  lastPage: boolean = false;
  state:any;
  theme: any[];
  data1: any;
  StateModel:any;
  allvalues:any;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  frontCurrentPageNo: number = 0;

  changeData: any;
  constructor(private micService: IicIncubatorstabService,
     private fb: FormBuilder,
    public dialog: MatDialog,) { }

  ngOnInit(): void {

    this.preincubators = this.fb.group({
      state: [null],
      type_of: [null],

    });

this.getIds();
    this.getState();
    this.preincubators.controls['type_of'].setValue('');
this.preincubators.controls['state'].setValue(this.allvalues);
this.getIncubators(this.data1);
  }
  getState(){
    this.micService
    .getState()
    .subscribe((data) => this.setState(data));
  }
  setState(data){
    this.state = [];
    for (let i = 0; i < data.length; i++) {
      this.state.push(data[i]);
    }
  }

  getIds(){
    this.micService
    .getState()
    .subscribe((data) => this.setId(data));

  }

  setId(data){
    this.allvalues = [];
    for (let i = 0; i < data.length; i++) {
      this.allvalues.push(data[i]['id']);
    }
this.StateModel=this.allvalues;
  }

  getIncubators(data) {

      this.changeData=data;
      this.micService.getIicPreIncubationDetails(this.changeData,this.currentPageNo,this.per_page)
      .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data) {
      this.theme = [];
    for (let i = 0; i < data['incubatorDetails']['data'].length; i++) {
      this.theme.push(data['incubatorDetails']['data'][i]);
    }
this.total_pages = data.incubatorDetails.meta.pagination.total;  
    if (
      data['incubatorDetails']['meta']['pagination']['current_page'] ==
      data['incubatorDetails']['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['incubatorDetails']['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {
    if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	this.per_page = e.pageSize;
    this.getIncubators(this.data1);
  }

  previousPage() {
    this.currentPageNo--;
    this.getIncubators(this.data1);
  }



  onSubmit(data){
    this.data1=data;
    this.currentPageNo=1;
    this.getIncubators(this.data1);
  }
}