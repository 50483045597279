import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../app/app.component';
import { JarwisService } from 'src/app/Services/jarwis.service';
@Component({
  selector: 'app-inner-navbar',
  templateUrl: './inner-navbar.component.html',
  styleUrls: ['./inner-navbar.component.css'],
})
export class InnerNavbarComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor(private jarwis: JarwisService) {}

  ngOnInit(): void {}
}
