import { Injectable, Type } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IicOnlineSessionsService {
private baseUrl = myGlobals.baseUrl;
  constructor(private http: HttpClient) { }
  
  getIICOnlineSessions() {
    return this.http.get(`${this.baseUrl}/iicOnlineSession`);
  }
  
  getIICOnlineSessionData(id) {
    return this.http.get(`${this.baseUrl}/iicOnlineSessionData?id=` + id);
  }
  get201920Rating(zone,data, page,per_page=10) {
    return this.http.get(`${this.baseUrl}/get201920Rating?regional_zone=` + zone + `&per_page=`+per_page+`&page=` + page,data);
  }
}
