<div class="container">
    <form  [formGroup]="mycouncil" (submit)="submitApplication()">
        <mat-card>
            <div>
                <div class="portlet-title">
                    <div class="caption">
                        <span style="color:blue;"> <h2>Monthly Report Of Council </h2></span>
                    </div>
                </div>
            </div>
        </mat-card>
        <div>
            <mat-card>
                <div class="row">
                    <mat-form-field class="col-md-6 col-sm-12">
                        <mat-label>Program driven by</mat-label>
                        <mat-select formControlName="event_driven">
                            <!-- <mat-option selected="selected" [value]="mycouncil.controls.event_driven.value">{{mycouncil.controls.event_driven.value}}</mat-option> -->
                            <mat-option>Select</mat-option>    
                            <mat-option value="IIC Calendar Activity">IIC Calendar Activity</mat-option>
                            <mat-option value="MIC driven Activity">MIC driven Activity</mat-option>
                            <mat-option value="Self-driven Activity">Self-driven Activity</mat-option>            
                        </mat-select>           
                    </mat-form-field>

                    <!-- <div class="col-md-6 col-sm-12">
                        <label>Program driven by</label>
                        <select [(ngModel)]="form.event_driven">
                            <option></option>
                        </select>
                    </div> -->

                    <mat-form-field  class="col-md-6 col-sm-12">
                        <mat-label>Program/Activity Name</mat-label>
                        <input matInput formControlName="activity_name" name="eventDriven" id="eventDriven" placeholder="Enter program driven">
                    </mat-form-field>

                    <!-- <mat-form-field class="col-md-6 col-sm-12">
                        <mat-label>Quaterly Plan</mat-label>
                        <input matInput [matDatepicker]="picker2" name="quarter" [(ngModel)]="form.quarter" class="float-right">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field> -->
                </div>
                <div>
                    <div class="row">
                        <mat-form-field class="col-md-6 col-sm-12">
                            <mat-label>Date Meeting Conducted</mat-label>
                            <input matInput [matDatepicker]="picker1" name="date_conducted_meeting" formControlName="date_conducted_meeting">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        
                        <mat-form-field class="col-md-6 col-sm-12">
                            <mat-label>Quaterly Plan</mat-label>
                            <mat-select formControlName="quarter">
                                <mat-option>Select</mat-option> 
                                <mat-option value="Quater I">Quarter I</mat-option>
                                <mat-option value="Quater II">Quarter II</mat-option>
                                <mat-option value="Quater III">Quarter III</mat-option>
                                <mat-option value="Quater IV">Quarter IV</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <div>
                    <div class="row">
                        <div class="col-lg-8 col-md-10">
                            <label>Resolution:<span style="color:red">*</span></label>
                            <mat-error>In the form of pdf of max. size 2MB</mat-error>
                        </div>

                        <div class="col-lg-4 col-md-2 float-right">        
                            <label class="custom-file-label float-right" for="customFile" *ngIf="!mycouncil.get('resolution').value">Upload Resolution File</label>
                            <!-- <label class="custom-file-label float-right" for="customFile" >Choose file</label> -->
                            <label class="custom-file-label float-right" for="customFile" *ngIf="mycouncil.get('resolution').value">{{mycouncil.get('resolution').value}}</label>
                            <input #resolutionRef required type="file" accept="application/pdf" formControlName="resolution" class="custom-file-input float-right" id="resolution" name="resolution" multiple (change)="onFileChange($event,'resolution')" required>
                            <a *ngIf="action == 'edit' && urls.resolution != '' && urls.resolution != 'NA'" href="{{urls.resolution}}">Preview</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <a href="../../../../../../../assets/doc/Resolution_Format.pdf" style="vertical-align: -webkit-baseline-middle;
                            float: right;font-size:14px;" target="_blank">
                            <font color="#b13c52"><b><u style="margin-left: 650px;">Download the template</u></b></font>
                            </a>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="row">
                        <div class="col-lg-8 col-md-10">
                            <label>Quarterly Plan:<span style="color:red">*</span></label>
                            <mat-error>PDF(max 2MB)</mat-error>
                        </div>

                        <div class="col-lg-4 col-md-2 float-right">
                            <label class="custom-file-label float-right" *ngIf="!mycouncil.get('quarterly_plan').value" for="customFile">Upload Quarterly Plan</label>
                            <label class="custom-file-label float-right" *ngIf="mycouncil.get('quarterly_plan').value" for="customFile">{{mycouncil.get('quarterly_plan').value}}</label>
                            <input #quarterly_planRef required type="file" formControlName="quarterly_plan" accept="application/pdf" name="quarterly_plan"  (change)="onFileChange($event,'quarterly_plan')" class="custom-file-input float-right" required>
                            <a *ngIf="action == 'edit' && urls.quarterly_plan != '' && urls.quarterly_plan != 'NA'" href="{{urls.quarterly_plan}}">Preview</a>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="row">
                        <div class="col-lg-8 col-md-10">
                            <label>Minutes of Meeting:<span style="color:red">*</span></label>
                            <mat-error>PDF(max 2MB)</mat-error>
                        </div>

                        <div class="col-lg-4 col-md-2 float-right">
                            <label class="custom-file-label float-right" *ngIf="!mycouncil.get('minutes_of_meeting').value" for="customFile">Upload Minutes of Meeting</label>
                            <label class="custom-file-label float-right" *ngIf="mycouncil.get('minutes_of_meeting').value" for="customFile">{{mycouncil.get('minutes_of_meeting').value}}</label>
                            <input #minutes_of_meetingRef required  type="file" accept="application/pdf" formControlName="minutes_of_meeting" name="minutes_of_meeting" (change)="onFileChange($event,'minutes_of_meeting')" class="custom-file-input float-right" required>
                            <a *ngIf="action == 'edit' && urls.mom != '' && urls.mom != 'NA'" href="{{urls.mom}}">Preview</a>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="row">
                        <div class="col-lg-8 col-md-10">
                            <label>Photo of council meeting:<span style="color:red">*</span></label>
                            <mat-error>Image(max 2MB)</mat-error>
                        </div>

                        <div class="col-lg-4 col-md-2 float-right">
                            <label class="custom-file-label float-right" *ngIf="!mycouncil.get('photo_upload').value" for="customFile">Upload Photo of council meeting</label>
                            <label class="custom-file-label float-right" *ngIf="mycouncil.get('photo_upload').value" for="customFile">{{mycouncil.get('photo_upload').value}}</label>
                            <input #photo_uploadRef type="file" required accept="image/*" formControlName="photo_upload" name="photo_upload" (change)="onFileChange($event,'photo_upload')" class="custom-file-input float-right" required>
                            <a *ngIf="action == 'edit' &&  urls.coucil_photo != '' && urls.coucil_photo != 'NA'" href="{{urls.coucil_photo}}">Preview</a>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div>
            <!-- <div class="row"> -->
            <button type="submit" *ngIf="action == 'add'" class="float-right" mat-raised-button color='primary'>Submit Form</button>
            <button type="submit" *ngIf="action == 'edit'" class="float-right" mat-raised-button color='primary'>Update Form</button>
            <!-- <button type="button" mat-raised-button color='warn' (click)="closeDialog()">Close</button> -->
        <!-- </div> -->
        </div>
    </form>
</div>