import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { LastLoginDialogComponent } from './last-login-dialog/last-login-dialog.component';


@Component({
  selector: 'app-institute-profile',
  templateUrl: './institute-profile.component.html',
  styleUrls: ['./institute-profile.component.css']
})
export class InstituteProfileComponent implements OnInit {

  data: any;
  data1: Object;
  application_status: any;
  instituteDisaproved: any;
  qrCode: any[];
  ratingValue: any;
  buttonShow: boolean;
  ForWhom: any;
  convinerData: any[];

  constructor(private mycouncilService: InstituteMyCouncilService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.mycouncilService.getInfoDetails().subscribe(
      data => this.handleResponse(data)
    );
    this.checkMyCouncil();
    this.getQRCode();
    this.getRatingStatus();
    this.ForWhom="";
    this.ForWhom=localStorage.getItem('ForWhom');

  
  }

  getRatingStatus(){
    this.mycouncilService.getRating().subscribe(
      data => this.setRatingStatus(data));
  }

  setRatingStatus(data){
    this.ratingValue=data['rating']['aishe_popup_status'];
    if(this.ratingValue==2){
      this.buttonShow=true;
    }
  }
  

  getQRCode(){
    this.mycouncilService.getQRCode().subscribe(
      data => this.setQRCode(data));
  }
  setQRCode(data){
  this.qrCode=[];
    for(let i =0;i<data['response'].length;i++){
      this.qrCode.push(data['response'][i])
    }
  }
  checkMyCouncil() {
    this.mycouncilService.checkMyCouncil().subscribe(
      data => this.handle(data)
    );
  }
  handle(data) {
    this.application_status = data['applicationStatus']
    this.instituteDisaproved=data['applicationStatusDisapproved']
  }
  handleResponse(data) {
    // this.data = data['mainData']['data']['0'];
    this.data = data['data']['0'];
  }

  getEstablishmentCertificate() {
    this.mycouncilService.getEstablismentCertificate().subscribe(
      data => this.downloadFile(data, 'EstablismentCertificate.pdf')
    );
  }


  getRatingCertificate() {
    this.mycouncilService.getRatingCertificate().subscribe(
      data => this.downloadFile(data, 'RatingCertificate.pdf')
    );
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }

  openDialog() {
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialogLastLogin() {
    const dialogRef1 = this.dialog.open(LastLoginDialogComponent, {
    });

    dialogRef1.afterClosed().subscribe(result => {
    });
  }

}
