import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-institute-dashboard1819',
  templateUrl: './institute-dashboard1819.component.html',
  styleUrls: ['./institute-dashboard1819.component.css']
})
export class InstituteDashboard1819Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
