import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.css']
})
export class ResetPasswordDialogComponent implements OnInit {

  public resetForm: FormGroup
  email: any;

  constructor(private myCouncilService: InstituteMyCouncilService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>) { }

  ngOnInit(): void {

    this.myCouncilService.getHOI('').subscribe(
      data => this.handleResponse(data)
    );

    this.resetForm = this.fb.group({
      'email': [null],
      'old_password': [null, Validators.required],
      'new_password': [null, Validators.compose([Validators.required, Validators.minLength(8),
      Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/()\\[\\{}])(?=.*[A-Z])(?=.*[a-z]).*$')])],
      'confirm_password': [null, Validators.compose([Validators.required, Validators.minLength(8),
      Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/()\\[\\{}])(?=.*[A-Z])(?=.*[a-z]).*$')]
      )],
    }
      , {
        validator: this.ConfirmedValidator('new_password', 'confirm_password')
      });

  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  handleResponse(data) {
    this.email = data['hoiDetails']['email'];
    this.resetForm.get('email').setValue(this.email);
  }

  onSubmit(value) {


    if (this.resetForm.valid) {

      if (value.new_password == value.confirm_password) {

        var formData = new FormData();
        formData.append('email', this.resetForm.get('email').value);
        formData.append('old_password', this.resetForm.get('old_password').value);
        formData.append('new_password', this.resetForm.get('new_password').value);

        this.myCouncilService.resetPassword(formData).subscribe(
          data => this.handleData(data)
        );
      } else {
        this.toastr.error('Passwords does not match');
      }
    } else {
      this.toastr.error('Kindly fill all the details');
    }
  }



  handleData(data) {
    if (data['1'] == "Success") {
      this.toastr.success("Password Reset Successfull !");
      this.dialogRef.close();
    } else if (data['message'] == "error") {
      this.toastr.error(data['reason'])
    }
  }

}
