import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InstitutesListDialogComponent } from './institutes-list-dialog/institutes-list-dialog.component';
import * as myGlobals from '../../../../../app/app.component';

@Component({
  selector: 'app-innovation-conest2020',
  templateUrl: './innovation-conest2020.component.html',
  styleUrls: ['./innovation-conest2020.component.css']
})
export class InnovationConest2020Component implements OnInit {

  baseUrl = myGlobals.baseUrl1;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
openDialog() {
    const dialogRef = this.dialog.open(InstitutesListDialogComponent, {
      width: '750px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog is closed!');
    });
  }
}
