import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../app/app.component';
@Component({
  selector: 'app-innovation-ranking',
  templateUrl: './innovation-ranking.component.html',
  styleUrls: ['./innovation-ranking.component.css']
})
export class InnovationRankingComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
