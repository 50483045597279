import { Injectable,Type } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IicInnovationAmbassadorService {

  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

    getFacultyData(data, page,per_page=10) {
    return this.http.post(`${this.baseUrl}/getMicInnovationAmbassadorDetails?per_page=`+per_page+`&page=` + page, data);
  }

  getStudentData(data, page,per_page=10) {
    return this.http.post(`${this.baseUrl}/getMicInnovationAmbassadorDetails?per_page=`+per_page+`&page=` + page, data);
  }
  getState() {
    return this.http.get(`${this.baseUrl}/getState`);
  }
  getStateStudents() {
    return this.http.get(`${this.baseUrl}/getState`);
  }

  getRequest(){
    return this.http.get(`${this.baseUrl}/getInstRequest`);
  }

  submitRequest(data){
    return this.http.post(`${this.baseUrl}/respondRequest`, data);
  }

  sendEmailReminder(data){
    return this.http.post(`${this.baseUrl}/sendEmailReminder`, data);
  }

  getSubmitted(){
    return this.http.get(`${this.baseUrl}/getsubmitted`);

  }
}
