import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JarwisService } from 'src/app/Services/jarwis.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {
 public formValues = {
    mobile_number: null,
	sms_otp: null,
    token: null
  };
  constructor(private route: ActivatedRoute,
    private toastr: ToastrService,
    private jarwis: JarwisService,
    private router: Router) {
    route.queryParams.subscribe(params => {
      this.formValues.token = params['token']
    });
    route.queryParams.subscribe(params => {
      this.formValues.mobile_number = params['phone']
    });
	route.queryParams.subscribe(params => {
      this.formValues.sms_otp = params['otp']
    });
  }

  ngOnInit() {
    this.jarwis.phoneVerification(this.formValues).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );

  }

  handleResponse(data) {
    this.toastr.success('Your IIC account is verified successfully.  Login and complete the My Council details.');
    this.router.navigateByUrl('/login');
  }

  handleError(error) {
    this.toastr.error('Phone Already Verified or Incorrect token');
  }

}
