import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';

@Component({
  selector: 'app-institute-iic-akam-dialog',
  templateUrl: './institute-iic-akam-dialog.component.html',
  styleUrls: ['./institute-iic-akam-dialog.component.css']
})
export class InstituteIicAkamDialogComponent implements OnInit {

  signupForm: FormGroup;
  show1: boolean;
  show: boolean;
  data1: any;
  id: any;
  ActivityModel:any;
  minDate2: any;
  requField: boolean = false;
  requField2: any;
  edit: boolean;
  editData: any;
  document:any;
  ip_type:any;
  ip_status:any;
  ip_app_number:any;
  filed_ipr:any;
  ipr_document: FileList | null;
  institute_name:any;
  institute_address:any;
  institute_city:any;
  institute_state:any;
  aishe_code:any;
  iic_4ostatus:any;
  requireField:any;
  checkaishe:any;
  affiliation_status:any;
  show_affiliate:any;

  rid:any;
  session:any;
  concent:any;
  student:any;
  faculty:any;
  collage:any;
  benefits:any;

  constructor(  private formBuilder: FormBuilder,
    private toastr: ToastrService, private InstituteDashboardService: InstituteDashboardService,
    private instituteActivityService :InstituteActivityService,
    public dialogRef: MatDialogRef<InstituteIicAkamDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
		// dialogRef.disableClose = true;

    this.rid            = data['rid'];
    this.session        = data['session'];
    this.concent        = data['concent'];
    this.student        = data['student'];

    this.faculty        = data['faculty'];
    this.document       = data['collage'];

    }

  ngOnInit(): void {
    this.requireField = 0;
    this.checkaishe = 0;

    this.signupForm = this.formBuilder.group({
      'student'   : [null, Validators.required]
    });

    this.signupForm.patchValue({
      student: this.student
    });

  }

    onSubmit(data){

      var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

      if(this.document=="")
      {
        if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
      }

      var formData = new FormData();

      formData.append('student', this.signupForm.get('student').value);
      formData.append('document', cvFile);
      formData.append('document_old', this.document);
      formData.append('session', this.session);
      formData.append('rid', this.rid);

      this.instituteActivityService.postIicAkamReportDetails(formData).subscribe(
        (data) => this.handleResponse(data)
      );

    }

    handleResponse(data){
      if (data['status'] == 1) {

          alert(data['msg']);
          this.dialogRef.close();

      }
      else
      {
        this.toastr.error(data['msg']);
      }
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

}
