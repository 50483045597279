import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../../app/app.component';
@Component({
  selector: 'app-leadership-team',
  templateUrl: './leadership-team.component.html',
  styleUrls: ['./leadership-team.component.css']
})
export class LeadershipTeamComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
