import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InstituteSocialMediaDialogComponent } from './institute-social-media-dialog/institute-social-media-dialog.component';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { InstituteSocial } from './institute-social';
import * as myGlobals from '../../../../../app.component';

@Component({
  selector: 'app-institute-social-media',
  templateUrl: './institute-social-media.component.html',
  styleUrls: ['./institute-social-media.component.css']
})
export class InstituteSocialMediaComponent implements OnInit {

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  Social: InstituteSocial[] = [];
  imageUrl = myGlobals.baseImageUrl;
  readMore: boolean = true;
  constructor(
    public dialog: MatDialog,
    private instituteMyCouncilService: InstituteMyCouncilService
  ) { }


  ngOnInit(): void {
    this.getSocial();
  }

  nextPage() {
    this.currentPageNo++;
    this.getSocial();
  }

  previousPage() {
    this.currentPageNo--;
    this.getSocial();
  }

  getSocial() {
    this.instituteMyCouncilService
      .getSocial(this.currentPageNo)
      .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data) {
    this.Social = [];

    data['data'].forEach((element) => {
      this.Social.push(element);
    });

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteSocialMediaDialogComponent, {
      data: { pageValue: this.Social }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getSocial();
    });
  }

  getInstituteDetails() {
    this.openDialog();
  }

  deleteInstituteDetails() {
    alert('Are you sure you want to delete this faculty ?');
  }
}

