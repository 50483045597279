import { Component, OnInit } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-atl-activity-view-submission',
  templateUrl: './atl-activity-view-submission.component.html',
  styleUrls: ['./atl-activity-view-submission.component.css']
})
export class AtlActivityViewSubmissionComponent implements OnInit {

  coordinatorDetail:any;
  rid : any;
  constructor(
    private activityService: InstituteActivityService, private route: ActivatedRoute
  ) { 
    this.route.params.subscribe((params) => {
      this.rid = params.activity_id;
    });
  }

  ngOnInit(): void {

    this.activityService.getAtlSubmittedReport(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

    this.coordinatorDetail = "";


  }

  handleResponse(data)
  {
    this.coordinatorDetail = data;
  }

}
