<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="3" allign="left" class="danger"
                                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">2018-19 Announcements</h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col">Announcement</th>
                            <th scope="col">More Details</th>
                            <th scope="col"> Date Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let announcement of announcements | paginate: { itemsPerPage: 5, currentPage: page }; let i = index">
                            <td *ngIf="announcement?.image_title">{{announcement.image_title}}</td>
                            <td *ngIf="!announcement?.image_title">{{announcement.announcement}}</td>
                            <td *ngIf="announcement.attachment" class="downloadD">
                                <a href="{{baseImageUrl + announcement.attachment}}" target="_blank"
                                    class="btn btn-success btnDownSuccess">
                                    <!-- <mat-icon>cloud_download</mat-icon> --> Download
                                </a></td>
                            <td *ngIf="!announcement.attachment">N/A</td>
                            <td>{{announcement.dateCreated}}</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls class="annular-pagination float-right" maxSize="3"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
