<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;">
            Create IICs in Constitutient Institutes</h2>
    </div>
    <div style="float:right;"><a id="backButton" class="btn btn-primary referralBut" routerLink='/institute/subiic'>Go Back</a></div>
	<div class="card" id="ttlText">
        <div class="card-title"><p>Details of Constitutient Institute</p></div>
	</div>
    <form id="iicsubAdd" class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
       <table class="table table-hover">
			<tr>
				<td width="25%">
					Constitutient Institute Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Constitutient Institute Name</mat-label>

					<input matInput autocomplete="off" placeholder="Constitutient Institute Name" formControlName="institute_name" type="text"   required>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Address
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Address</mat-label>	
						<input matInput autocomplete="off" placeholder="Address" formControlName="address" type="text"   required>
						</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					State
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>State</mat-label>	
						<input matInput autocomplete="off" placeholder="State" formControlName="state" type="text" readonly="true">
					</mat-form-field>
				</td>
			</tr>
			
			<tr>
				<td>
					City
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>City</mat-label>	
						<input matInput autocomplete="off" placeholder="City" formControlName="city" type="text" readonly="true">
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Type of Courses Offered by the Constitutient Institute
				</td>
				<td>

					<mat-form-field class="w-100 ml-3" appearance="outline">
						<mat-label>Type of Courses Offered by the Constitutient Institute</mat-label>
						<mat-select formControlName="ins_subtype" required>
						  <mat-option value="Technical & Non Technical Both">Technical & Non Technical</mat-option>
						  <mat-option value="Non Technical Only">Non Technical Only</mat-option>
						</mat-select>
					  </mat-form-field>					
				</td>
			</tr>

			<tr>
				<td>
					Institute Category
				</td>
				<td>

					<mat-form-field class="w-100 ml-3" appearance="outline">
						<mat-label>Institute Category</mat-label>
						<mat-select formControlName="institute_type" required>
						  <mat-option value="Institute of National Importance & Central Universities / CFTIs">Institute of National Importance & Central Universities / CFTIs</mat-option>
						  <mat-option value="University & Deemed to be University (Govt. & Govt. Aided)">University & Deemed to be University (Govt. & Govt. Aided)</mat-option>
						  <mat-option value="University & Deemed to be University (Private / Self Financed)">University & Deemed to be University (Private / Self Financed)</mat-option>
						  <mat-option value="Colleges / Institutes (Govt. & Govt. Aided)">Colleges / Institutes (Govt. & Govt. Aided)</mat-option>
						  <mat-option value="Colleges / Institutes (Private / Self Financed)">Colleges / Institutes (Private / Self Financed)</mat-option>
						  <mat-option value="An Affiliating University">An Affiliating University</mat-option>
						  <mat-option value="Open University">Open University</mat-option>
						</mat-select>
						<mat-error *ngIf="iicImpactQuestions.controls.institute_type.errors?.required">
						  Institute Category is required
						</mat-error>
					</mat-form-field>				
				</td>
			</tr>

			<tr>
				<td>
					Constitutient Institute's AICTE Permanent ID (Optional)
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>AICTE Permanent ID</mat-label>	
						<input matInput autocomplete="off" placeholder="AICTE Permanent ID" formControlName="pid" type="text">
						</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Constitutient Institute's AISHE Code (Optional)
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>AISHE Code</mat-label>	
						<input matInput autocomplete="off" placeholder="AISHE Code" formControlName="aishe_code" type="text" readonly="true">
						</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Sub IIC Incharge Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Sub IIC Incharge Name</mat-label>	
						<input matInput autocomplete="off" placeholder="Sub IIC Incharge Name" formControlName="name" type="text" required>
						</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Sub IIC Incharge Designation
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Sub IIC Incharge Designation</mat-label>	
						<input matInput autocomplete="off" placeholder="Sub IIC Incharge Designation" formControlName="designation" type="text" required>
						</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Sub IIC Incharge Email
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Sub IIC Incharge Email</mat-label>	
						<input matInput autocomplete="off" placeholder="Sub IIC Incharge Email" formControlName="email" type="email" required>
						</mat-form-field>
				</td>
			</tr>
			<tr>
				<td>
					Sub IIC Incharge Mobile Number
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Sub IIC Incharge Mobile Number</mat-label>	
						<input matInput autocomplete="off" placeholder="Sub IIC Incharge Mobile Number" formControlName="mobile" type="text">
						</mat-form-field>
				</td>
			</tr>
			<tr>
				<td>
					&nbsp;
				</td>
				<td>
					<button style="margin-bottom:15px;" mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicImpactQuestions.invalid'>Submit</button>
				</td>
			</tr>


	   </table>
	</form>
</div>
