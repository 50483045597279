import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InstituteAnnouncementService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  getInstituteAnnouncements(pageNo) {
    return this.http.get(
      `${this.baseUrl}/announcement/details?per_page=10&page=` + pageNo
    );
  }
  getNotification(pageNo) {
    return this.http.get(
      `${this.baseUrl}/getAnnouncements?per_page=10&page=` + pageNo
    );
  }

  getInstitutePerDetail(iicId){
    return this.http.get(`${this.baseUrl}/getInstitutePerDetail?iicId=`+iicId);
  }

  getPresidentDetails(iicId){
    return this.http.get(`${this.baseUrl}/getPresidentDetails?iicId=`+iicId);
  }
  
  getConvenerDetails(iicId){
    return this.http.get(`${this.baseUrl}/getConvenerDetails?iicId=`+iicId);
  }

  getIicCouncilDetails(iicId){
    return this.http.get(`${this.baseUrl}/getIicCouncilDetails?iicId=`+iicId);
  }
  getApprovedInstituteDetails(data, page, per_page = 10) {
    return this.http.post(`${this.baseUrl}/getApprovedInstitute?per_page=` + per_page + `&page=` + page, data);
  }
  getApprovedInstituteRankingDetails(data, page, per_page = 10) {
    return this.http.post(`${this.baseUrl}/getApprovedInstituteRanking?per_page=` + per_page + `&page=` + page, data);
  }

  getApprovedInstituterepositoryDetails(data, page, per_page = 10) {
    return this.http.post(`${this.baseUrl}/getApprovedInstituterepository?per_page=` + per_page + `&page=` + page, data);
  }

  getIicMentorMenteeList(data, page, per_page = 10) {
    return this.http.post(`${this.baseUrl}/getIicMentorMenteeList?per_page=` + per_page + `&page=` + page, data);
  }

  getInstituteTypeDetails() {
    return this.http.get(`${this.baseUrl}/instituteTypeDetails`);
  }
  getInstituteCoursesDetails() {
    return this.http.get(`${this.baseUrl}/getInstituteCoursesDetails`);
  }
  getZone() {
    return this.http.get(`${this.baseUrl}/getZone`);
  }

  getStateDetails(instituteAllType) {
    return this.http.get(`${this.baseUrl}/getInstituteState/` + instituteAllType);
  }

  getRegionDetails(instituteAllType) {
    return this.http.get(`${this.baseUrl}/getInstituteState/` + instituteAllType);
  }

  getStateZoneDetails(instituteAllType) {
    return this.http.get(`${this.baseUrl}/getInstituteZoneState/` + instituteAllType);
  }

  getinstituteRating(userId) {
    return this.http.get(`${this.baseUrl}/institute-rating-data?userId=` + userId);
  }

  getIicMentorMenteeCount() {
    return this.http.get(`${this.baseUrl}/institute-mm-count-data`);
  }

  getIicMentorMenteeCount2223() {
    return this.http.get(`${this.baseUrl}/institute-mm-count-data2223`);
  }

  getIicImpactLactureCount() {
    return this.http.get(`${this.baseUrl}/institute-im-count-data`);
  }

  getIicImpactLactureThemeCount() {
    return this.http.get(`${this.baseUrl}/institute-imT-count-data`);
  }

  getApprovedInstituteRankingDetails2022(data, page, per_page = 10) {
    return this.http.post(`${this.baseUrl}/getApprovedInstituteRanking2022?per_page=` + per_page + `&page=` + page, data);
  }
  getApprovedInstituteRankingDetails2023(data, page, per_page = 10) {
    return this.http.post(`${this.baseUrl}/getApprovedInstituteRanking2023?per_page=` + per_page + `&page=` + page, data);
  }

  getinstituteRating2022(userId) {
    return this.http.get(`${this.baseUrl}/institute-rating-data-2022?userId=` + userId);
  }
  
  getinstituteRating2023(userId) {
    return this.http.get(`${this.baseUrl}/institute-rating-data-2023?userId=` + userId);
  }

}
