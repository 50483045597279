<!-- Preview Form Starts Here -->
<div class="container">
  <div fxFlex="100" class="m-4">
    <div class="row marginRow" *ngIf="this.ForWhom != 'institute_student'">
      <div class="form-group">
        <div>
          <button mat-raised-button color='primary' (click)='openDialog()'>
            <mat-icon>home</mat-icon> | Update Institute/President Details
          </button>
        </div>
      </div>
    </div>    

    <div class="row">
      <div class="col-md-12">
        <div class="CardBox">
          <img src="assets/customCard/card-gradient-img.png" class="CardBoxContentTopImg CardBoxContentTopImgMyCouncil">

          <div class="CardBoxContent">
            <h3>More information about Institute</h3>
          </div>
          <div class="CardBoxContent">
            <h3>Institute Name</h3>
            <p>{{instituteData?.instituteName}}</p>
          </div>

          <div class="CardBoxContent">
            <h3>Institute State</h3>
            <p>{{instituteData?.statename}}</p>
          </div>

          <div class="CardBoxContent">
            <h3>Institute Type</h3>
            <p>{{instituteType}}</p>
          </div>

          <div id="InstDetails" class="collapse">

            <div class="CardBoxContent">
              <h3>Courses Offered</h3>
              <p>{{instituteData?.nbaAccreditatedCourses}}</p>
            </div>

            <div class="CardBoxContent">
              <h3>Approvals</h3>
              <p>{{instituteData?.aicteApproved}}</p>
            </div>

            <div class="CardBoxContent">
              <h3>Institute City</h3>
              <p> {{instituteData?.instituteCity}}</p>
            </div>



            <div class="CardBoxContent">
              <h3>Institute Address</h3>
              <p>{{instituteData?.instituteAddress}}</p>
            </div>

            <div class="CardBoxContent">
              <h3>Sub Type</h3>
              <p>{{instituteSubType}}</p>
            </div>

            <div class="CardBoxContent">
              <h3>Facilities</h3>
              <p>{{instituteData?.Facilities}}</p>
            </div>

            <div class="CardBoxContent">
              <h3>Website</h3>
              <p>{{instituteData?.website}}</p>
            </div>
            <div class="CardBoxContent">

              <div *ngIf="instituteData?.nba">
                <a href="{{baseUrl + instituteData?.nba}}" target="_blank">
                  <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG instiDetailsDownBut">Download
                    NBA</button>
                </a>
              </div>

              <div *ngIf="instituteData?.naac">
                <a href="{{baseUrl + instituteData?.naac}}" target="_blank">
                  <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG instiDetailsDownBut">Download
                    NAAC</button>
                </a>
              </div>

              <div *ngIf="instituteData?.institute_letter_head">
                <a href="{{baseUrl + instituteData?.institute_letter_head}}" target="_blank">
                  <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG instiDetailsDownBut">Download
                    Startup /IPR Training</button>
                </a>
              </div>

              <div *ngIf="instituteData?.offering_academic_credit_core">
                <a href="{{baseUrl + instituteData?.offering_academic_credit_core}}" target="_blank">
                  <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG instiDetailsDownBut">Download
                    Academic Credit Score</button>
                </a>
              </div>

            </div>
          </div>

          <div class="cardReadMore">

            <button data-toggle="collapse" data-target="#InstDetails" *ngIf="readMore"
              (click)="readMore=!readMore">Read More</button>
            <button data-toggle="collapse" data-target="#InstDetails" *ngIf="!readMore"
              (click)="readMore=!readMore">Read Less</button>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>
<!-- Preview Form Ends Here -->
