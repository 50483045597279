import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteAffiliateProgramAddDialog } from '../institute-affiliate-program/institute-affiliate-program-add-dialog/institute-affiliate-program-add-dialog.component';

@Component({
  selector: 'app-institute-affiliate-program-pending',
  templateUrl: './institute-affiliate-program-pending.component.html',
  styleUrls: ['./institute-affiliate-program-pending.component.css']
})
export class InstituteAffiliateProgramPendingComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicrepository:any;

  constructor(private fb: FormBuilder, public dialog: MatDialog, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.requireField=0;

    this.instituteActivityService.getAffiliateInsNewList('affiliatePending').subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    // this.iicrepository = data['data'];

    this.iicrepository = [];

    for (let i = 0; i < data['data'].length; i++)
    {
      if(data['data'][i]['reg_applicationStatus']!="Approved")
      {
        this.iicrepository.push(data['data'][i]);
      }
    }

  }

  onClickEvent(data,type){

    if(type=="Edit")
    {
      window.location.href="/institute/edit-affiliate-institute/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.deleteIicReferralDetails(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }

  }

  onClickAddEvent(data)
  {
    const dialogRef = this.dialog.open(InstituteAffiliateProgramAddDialog, {data});
    dialogRef.afterClosed().subscribe((result) => {
      this.instituteActivityService.getAffiliateInsNewList('affiliate').subscribe(
        (data) => this.handleResponse(data)
      );
    });
  }

  handleResponse1(data)
  {

  }


}
