<div class="container">
    <form [formGroup]="announcementDrivenForm" (ngSubmit)="submitForm()">
        <!-- <mat-card> -->
        <div>
            <div class="portlet-title">
                <div class="caption">
                    <h1 class="text-center instStuDetailsTitle mat-dialog-title"> SELF-DRIVEN ACTIVITY </h1>
                </div>
            </div>
        </div>
        <!-- </mat-card> -->
        <div>
            <!-- <mat-card> -->
            <div class="row">
                <div class="col-6">
                    <mat-form-field style="width: 400px;">
                        <mat-label>Event Title</mat-label>
                        <input type="type" matInput formControlName="event_title" placeholder="Event Title" required>
                        <mat-error *ngIf="announcementDrivenForm.controls['event_title'].hasError('required')">Event
                            title is
                            required</mat-error>
                    </mat-form-field>
                </div>



                <div class="col-6">

                    <mat-form-field style="width: 200px;">
                        <mat-label>Start Date</mat-label>
                        <input matInput readonly [matDatepicker]="picker1" placeholder="Start Date"
                            formControlName="start_date" required>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error *ngIf="announcementDrivenForm.controls['start_date'].hasError('required')">Start date
                            is
                            required</mat-error>
                    </mat-form-field>

                    <mat-form-field style="width: 200px;">
                        <mat-label>End Date</mat-label>
                        <input matInput readonly [matDatepicker]="picker2" placeholder="End Date"
                            formControlName="end_date" [min]="minDate" required>
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error *ngIf="announcementDrivenForm.controls['end_date'].hasError('required')">End date is
                            required</mat-error>
                    </mat-form-field>

                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-form-field style="width: 400px;">
                        <mat-select formControlName="event_theme" placeholder="Select Event Theme" required>
                            <mat-option>Select</mat-option>
                            <mat-option value="{{theme}}" *ngFor="let theme of programTheme">{{theme}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="announcementDrivenForm.controls['event_theme'].hasError('required')">Event
                            theme is
                            required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field style="width: 400px;">
                        <mat-label>URL for the Event</mat-label>
                        <input type="url" title='Inculde https:' matInput formControlName="event_url"
                            placeholder="Event URL">
                        <mat-error *ngIf="announcementDrivenForm.controls['event_url'].hasError('pattern')">Kindly enter
                            url in correct format!</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" *ngIf="show1">
                <div class="col-6">
                    <mat-form-field style="width: 400px;">
                        <mat-label>Enter Your Theme</mat-label>
                        <input matInput placeholder="Theme Others" formControlName="event_theme_others" value="">
                        <mat-error *ngIf="announcementDrivenForm.controls.event_theme_others.errors?.required">Other
                            theme is required!</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field style="width: 400px;">
                        <mat-select formControlName="quarter" placeholder="Select Quarter" required>
                            <mat-option>Select Quarter</mat-option>
                            <mat-option value="Quarter I" *ngIf="quarter1==1">Quarter I</mat-option>
                            <mat-option value="Quarter II" *ngIf="quarter2==1">Quarter II</mat-option>
                            <mat-option value="Quarter III" *ngIf="quarter3==1">Quarter III</mat-option>
                            <mat-option value="Quarter IV" *ngIf="quarter4==1">Quarter IV</mat-option>
                        </mat-select>
                        <mat-error *ngIf="announcementDrivenForm.controls['quarter'].hasError('required')">Select
                            Quarter</mat-error>
                    </mat-form-field>
                </div>


                <!-- <div class="col-6">
                  <div class="col-lg-8 col-md-10">
                      <label>Upload Brochure*</label>
                      <mat-error>PDF(max 2MB)</mat-error>
                  </div>

                  <div class="col-lg-4 col-md-2 float-right">
                      <label class="custom-file-label float-right" for="customFile"
                          *ngIf="!brochure">Choose file</label>
                      <label class="custom-file-label float-right" for="customFile"
                          *ngIf="brochure">
                          File Selected</label>
                          <input #brochureRef type="file" accept="application/pdf" name="brochure"
                          formControlName="brochure" (change)="onFileChange($event)"
                          class="custom-file-input float-right" required>

                  </div>
              </div> -->





              <div class="col-6">
                <div class="col-lg-8 col-md-10">
                    <label> Upload Brochure
                    </label>
                    <mat-error>PDF(max 2MB)</mat-error>
                </div>

                <div class="col-lg-4 col-md-2 float-right">
                    <label class="custom-file-label float-right"
                        *ngIf="!announcementDrivenForm.get('brochure').value" for="customFile">Choose file</label>
                    <label class="custom-file-label float-right"
                        *ngIf="announcementDrivenForm.get('brochure').value"
                        for="customFile">File Selected</label>
                    <input #brochureRef type="file" accept="application/pdf" name="brochure"
                        formControlName="brochure" (change)="onFileChange($event)"
                        class="custom-file-input float-right">

                </div>
            </div>




              <!-- <div class="row">
                <div class="col-lg-8 col-md-10">
                  <label>Upload Brochure:</label>
                  <mat-error>PDF(max 2MB)</mat-error>
                </div>

                <div class="col-lg-4 col-md-2 float-right">
                  <label class="custom-file-label float-right" for="customFile" *ngIf="!externalCV">Choose file</label>
                  <label class="custom-file-label float-right" for="customFile"
                    *ngIf="externalCV">{{externalCV[0]['name']}}</label>
                  <input #externalCVRef type="file" accept="application/pdf" name="externalCV"
                    (change)="( externalCV = externalCVRef.files )" class="custom-file-input float-right" required>
                </div>

              </div> -->




                <!-- <div class="col-6">
                    <div class="col-6" style="width: 400px;">
                        <label class="custom-file-label float-right" for="brochure">Upload Brochure</label>
                        <input #brochureRef type="file" accept="application/pdf" name="brochure"
                            formControlName="brochure" (change)="onFileChange($event)"
                            class="custom-file-input float-right" required>
                            <mat-error>PDF(max 2MB)</mat-error>

                    </div>
                </div> -->

            </div>

            <div class="row">

                <div class="col-6">
                    <mat-form-field style="width: 400px;">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description" placeholder="Enter Description"
                            (keyup)="countWords($event)" [(ngModel)]="descriptionModel" required></textarea>
                        <!-- <p class="mat-error">Total Number of words: {{wordCount}} / 50</p> -->
                        <mat-error></mat-error>
                        <mat-error *ngIf="announcementDrivenForm.controls['description'].hasError('required')">
                            Description is
                            required</mat-error>
                    </mat-form-field>
                </div>
				<div class="col-6">
					<mat-dialog-actions align="end" class="selfDrivenNew">
						<button mat-raised-button type="submit" class="btn btn-success selfDrivFormBut">Submit</button>
						<button mat-button mat-dialog-close class="btn btn-danger instituteBtnClose">Close</button>

					</mat-dialog-actions>
				</div>
            </div>


            <!-- </mat-card> -->
        </div>



    </form>
</div>
