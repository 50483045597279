<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>
<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">

<!-- <div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">About MIC</h1>							
		</div>
	</div>
</div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">News & Events</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<!-- <li class="breadcrumb-item"><a>Gallery</a></li> -->
	<li class="breadcrumb-item"><a href="./gallery/photo-gallery">Gallery</a></li>
    <li class="breadcrumb-item active" aria-current="page">News & Media of Regional Meet 2023</li>
  </ul>
</nav>

<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss">
	   <div class="vc_col-md-12">
			<div class="headingAmbassador head-gh-outer">
				<h2>News & Media of Regional Meet 2023</h2>
				<div>
					<a class="backButton" href="/gallery">Back to Gallery</a>
				</div>
			</div>
		</div>
		</div>
		
		  
		  
	</div>	
 </div>


<div id="" class="about">
	<div class="container">
		<div class="row margin-lg-30t">
			<div class="gallery col-md-12">
				<div class="row">
					<!-- <div class="col-md-4">
						<a class="lightbox" href="assets/img/htcampus.png" data-caption="Education new generation of innovators">
							<img src="assets/img/htcampus-thumb.png" alt="First image">
						</a>
						<p class="newsParaBottom">Education new generation of innovators</p>
					</div> -->
					
					
				</div>
			</div>
			
		</div>
	</div>	
 </div>

<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>
<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>