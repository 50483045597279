import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteDashboard2025Component } from 'src/app/components/users/institute/institute-dashboard2025/institute-dashboard2025.component';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';

@Component({
  selector: 'app-technology-transfer-data',
  templateUrl: './technology-transfer-data.component.html',
  styleUrls: ['./technology-transfer-data.component.css']
})
export class TechnologyTransferDataComponent implements OnInit {

  public inchargeForm: FormGroup;

  constructor(private fb: FormBuilder,private dashboardService: InstituteDashboardService,public dialogRef: MatDialogRef<InstituteDashboard2025Component>) { }

  ngOnInit(): void {

    this.dashboardService.getDashboardTT().subscribe(
      data => this.handleResponse(data)
    );

    this.inchargeForm = this.fb.group({
      number_of_innovation_ready: [null, Validators.required],
      is_transferred: [null, Validators.required],
      number_of_innovation_undergone: [null, Validators.required],
    });
    
  }
  handleResponse(data){
    if(data['status'] == true){
      this.inchargeForm.patchValue({
        number_of_innovation_ready: data['data']['number_of_innovation_ready'],
        is_transferred: data['data']['is_transferred'],
        number_of_innovation_undergone: data['data']['number_of_innovation_undergone']
      });
    }
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  onSubmit(values) {
    // if (this.inchargeForm.valid) {
    //   this.dialogRef.close(values);
    // }

    this.dashboardService.submitDashboardTT(values).subscribe(
      data => this.handleResponseData(data)
    );
  }

  handleResponseData(data){
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
