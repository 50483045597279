import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import * as myGlobals from 'src/app/app.component';
import { HttpClient,HttpHeaders,HttpBackend   } from '@angular/common/http';

@Component({
  selector: 'app-manthan-user',
  templateUrl: './manthan-user.component.html',
  styleUrls: ['./manthan-user.component.css']
})
export class ManthanUserComponent implements OnInit {

  public iicImpactQuestions: FormGroup;
  private manthanUrl = myGlobals.manthanUrl;
  private manthanHost = myGlobals.manthanHost;
  private manthanOrigin = myGlobals.manthanOrigin;
  private manthanSubdomain = myGlobals.manthanSubdomain;
  firstName : any;
  lastName : any;
  email : any;
  isRegister : any;
  tokenMathan :any;
  instituteName :any;
  instituteType :any;
  manthan_institute_id :any;

  constructor(private instituteActivityService :InstituteActivityService,private toastr: ToastrService,private http: HttpClient,handler: HttpBackend)
  {
    // this.route.params.subscribe((params) => {
    //   this.rid = params.rid;
    // });


    this.http = new HttpClient(handler);
  }

  ngOnInit(): void {   
    this.getUserData();
    this.isRegister = 0;

  }
 
  getUserData(){
    this.instituteActivityService.getUserData().subscribe(
      (data) => this.handleResponseUserData(data)
    );
  }

  handleResponseUserData(data){
    this.firstName = data?.firstName;
    this.lastName = data?.lastName;
    this.email = data?.email;
    this.isRegister = data?.is_manthan_register;
    this.instituteName = data?.instituteName;
    this.instituteType = data?.instituteType;
    this.manthan_institute_id = data?.manthan_institute_id;
  }
  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  onSubmit() {

    const formData = {
      'username' : 'sd.mic@aicte-india.org',
      'password' : 'SKdhw#24DUx$4ecH',
      'subdomain' : this.manthanSubdomain,
    };
    
    let headers = new HttpHeaders({
      'x-api-key': 'LgOiuSu64U',
      'Host': this.manthanHost,
      'Origin': 'https://iic.mic.gov.in',
      // 'Origin': this.manthanOrigin,
      // 'X-Frame-Options' : 'ALLOW-FROM',
      // 'Content-Type' : 'application/json',
      // 'Access-Control-Allow-Origin' : this.manthanOrigin
      // "Access-Control-Allow-Origin" : "*",
      // "Access-Control-Allow-Headers" : "Origin, X-Requested-With, Content-Type, Accept"
    });
    let options = { headers: headers };
    var dataResponse = this.newUrl(formData,options).subscribe(
       data => this.handleResponseData(data));
  }
  newUrl (data,options){
    return this.http.post(`${this.manthanUrl}/Auth/Login`,data,options);
  }

  handleResponseData(data){
    if(data['Token']){
      this.tokenMathan = data['Token'];
      this.addUserInstituteManthan(data['Token']);
      // this.addUserManthan(data['Token']);
      // window.location.href='/institute/repository';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

  

  addUserInstituteManthan(token){
    if(this.instituteName){
      if(this.instituteType == 1 || this.instituteType == 4){
        var formData =  {"Data":{"Address":{},"InternationalAddress":{"Country":"","Location":""},"Domain":"","Url":"","AssociatedCategory":"","DeansAssigned":[],"Icon":null,"Description":"","Owners":[],"Type":"Private","Type2":null,"Sector":"","Premier":false,"International":false,"SubType":"","State":"","LineMinistry":""},"IsVerified":true,"Name":this.instituteName,"TypeId":1};
      }else{
        var formData =  {"Data":{"Address":{},"InternationalAddress":{"Country":"","Location":""},"Domain":"","Url":"","AssociatedCategory":"","DeansAssigned":[],"Icon":null,"Description":"","Owners":[],"Type":"Government","Type2":null,"Sector":"","Premier":false,"International":false,"SubType":"","State":"","LineMinistry":""},"IsVerified":true,"Name":this.instituteName,"TypeId":1};
      }
      
      let headers = new HttpHeaders({
        'x-api-key': 'LgOiuSu64U',
        'Host': this.manthanHost,
        // 'origin': this.manthanOrigin,
        'Origin': 'https://iic.mic.gov.in',
        'AuthenticationType': 'token',
        // 'X-Frame-Options' : 'ALLOW-FROM',
        // 'Content-Type' : 'application/json',
        // 'Access-Control-Allow-Origin' : this.manthanOrigin,
        'Authorization': 'Bearer '+token
      });
      let options = { headers: headers };
      var dataResponse = this.addUserInstituteUrl(formData,options).subscribe(
        data => this.handleResponseUserAddInstituteData(data));
    }else{
      this.addUserManthan(this.tokenMathan,"0f5c1a64-8792-4e39-bdcc-d1f03bde18ca");
    }
  }

  addUserInstituteUrl (data,options){
    return this.http.post(`${this.manthanUrl}/Institution/Create`,data,options);
  }

  handleResponseUserAddInstituteData(data){
    if(data != 'Institution with same name already exist'){
      if(data){
        this.manthan_institute_id = data;
        this.instituteActivityService.updateManthanStatus(data).subscribe(
          (data) => this.handleResponseList1(data)
        );
      }else{
        this.instituteActivityService.updateManthanStatus("0f5c1a64-8792-4e39-bdcc-d1f03bde18ca").subscribe(
          (data) => this.handleResponseList1(data)
        );
      }
      
      
    }else{
      this.getUserData();
      if(this.manthan_institute_id){
        this.addUserManthan(this.tokenMathan,this.manthan_institute_id);
      }
      this.toastr.error(data);
    }
  }

  handleResponseList1(data){
    this.addUserManthan(this.tokenMathan,this.manthan_institute_id);
  }

  addUserManthan(token,instituteId){
    const formData = {
      'FirstName' : this.firstName,
      'LastName' : this.lastName,
      'EmailAddress' : this.email,
      'Prefix' : '',
      'AdditionalProperties' : [{

        "Id": "9c58a916-d497-4156-a361-a8dfbb36245c",

        "PropertyValue": {

            "Data": '{"Affiliations":[{ "TypeId":13,"InstitutionId": "'+instituteId+'" ,"RoleType":"","StudentType":""}]}'

        }

    }, {

        "Id": "e94028e4-47a9-4283-8119-a6e73dced949",

        "PropertyValue": {

            "Data": "{\"RoleNames\":[{\"Name\":\"Admin\",\"Value\":false},{\"Name\":\"Moderator\",\"Value\":false},{\"Name\":\"Market Researcher\",\"Value\":false}]}"

        }

    }, {

        "Id": "ec8c45ce-04c9-409c-a132-b759dbfbf103",

        "PropertyValue": {

            "Data": "{\"Categories\":[]}"

        }

    }, {

        "Id": "a7812636-8408-4822-b918-aa0b4ef526c3",

        "PropertyValue": {

            "Data": "{\"SupplyDemand\":[{\"Name\":\"Supply Side\",\"Value\":false},{\"Name\":\"Demand Side\",\"Value\":false}]}"

        }

    },{"Id":"9ac0516a-432c-41c1-8d96-ff00ab42ad8b","PropertyValue":{"Data":"IIC"}}],
      'Education' : '',
      'Organisation' : '',
    };
    
    let headers = new HttpHeaders({
      'x-api-key': 'LgOiuSu64U',
      'Host': this.manthanHost,
      // 'origin': this.manthanOrigin,
      'Origin': 'https://iic.mic.gov.in',
      'AuthenticationType': 'token',
      // 'X-Frame-Options' : 'ALLOW-FROM',
      // 'Content-Type' : 'application/json',
      // 'Access-Control-Allow-Origin' : this.manthanOrigin,
      'Authorization': 'Bearer '+token
    });
    let options = { headers: headers };
    var dataResponse = this.addUserUrl(formData,options).subscribe(
       data => this.handleResponseUserAddData(data));
  }

  addUserUrl (data,options){
    return this.http.post(`${this.manthanUrl}/User/Add`,data,options);
  }

  handleResponseUserAddData(data){
    this.getUserData();
    if(data == null){
      if(this.instituteName){
        this.instituteActivityService.updateManthanStatus().subscribe(
          (data) => this.handleResponseList(data)
        );
      }else{
        this.instituteActivityService.updateManthanStatus("0f5c1a64-8792-4e39-bdcc-d1f03bde18ca").subscribe(
          (data) => this.handleResponseList(data)
        );
      }
    }else{
      this.toastr.error('Email Already Exist');
    }
  }

  handleResponseList(data){
    this.getUserData();
  }
}
