<mat-tab-group mat-align-tabs="center" class="menteeLabelBut">
  <mat-tab *ngIf="checkUserAccepted">
    <ng-template mat-tab-label>
      Invitation of ATL Schools
    </ng-template>
    <div style="margin-top: 30px;" >
      <div class="container-fluid">
        <div class="row m-4">
          <div class="alert alert-danger" style="margin-top:10px; width:100%;">
            Please shortlist 3 ATL Schools and once they will accept the invitation, Prescribed Activity section will be enabled.
          </div>
           <table class="table table-hover table-border mt-2 table-responsive">
            <thead class="facultyTable">
              <tr>
                <td colspan="7" allign="left" class="danger "
                  style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                  <h1 class="announcementHeadingMain">List of ATL Schools - Accept Invitation</h1>
                </td>
              </tr>
              <tr>
                <th width="5%">S.No.</th>
                <th width="10%">School Code</th>
                <th width="20%">School Name</th>
                <th width="25%">Address</th>
                <th width="20%">Email</th>
                <th width="15%">Mobile</th>
                <th width="5%">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let activity of atlSchoolRequest;let i = index">
                <td>{{i+1}}</td>
                <td>{{activity?.atl_code}}</td>
                <td>{{activity?.school_name}}</td>
                <td>{{activity?.address}}<br/>{{activity?.city}}<br/>{{activity?.district}}</td>
                <td>{{activity?.email}}</td>
                <td>{{activity?.contact_no}}</td>
                <td>
                  <p style="padding-top: 10px;">
                    <button mat-button (click)="onSubmitAtlRequest(activity?.id, '1')" style=" background-color: blue; color: white;">
                      Accept
                    </button>
                  </p>
                  <p style="padding-top: 10px;">
                    <button mat-button (click)="onSubmitAtlRequest(activity?.id, '2')" style=" background-color: red; color: white;">
                      Remove
                    </button>
                  </p>
                </td>
              </tr>
              <tr *ngIf="atlSchoolRequest?.length==0">
                <td colspan="7" style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                There is no school invitation found.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
        Selected ATL Schools
    </ng-template>

    <div style="margin-top: 30px;" >
      <div class="container-fluid">
        <!-- <div class="m-4 row">

          <div class="col-3">
            <mat-select placeholder="All Activity Type" [(ngModel)]="activity_filter" (ngModelChange)="activityFilter()"
              class="instiIICcalActi" (selectionChange)="onDistrictChange($event)">
              <mat-option value="">All District</mat-option>
              <mat-option value="{{district.district}}" *ngFor="let district of districtList">{{district.district}}</mat-option>
            </mat-select>
          </div>

          <div class="col-3">
            <mat-select placeholder="All Quarter" [(ngModel)]="quarter_filter" (ngModelChange)="quarterFilter()"
              class="instiIICcalActi">
              <mat-option value="">All City</mat-option>
              <mat-option value="{{city.city}}" *ngFor="let city of cityList">{{city.city}}</mat-option>
            </mat-select>
          </div>

          <div class="col-6 example">
            <input class="searchExter" matInput placeholder="Search Title" (keyup)="filter($event)">
            <button type="button" class="searchButton" (click)="search()"><i class="fa fa-search" style="font-size: 28px;"></i></button>


              </div>
        </div> -->

        <div class="row m-4">

          <div class="alert alert-danger" style="margin-top:10px; width:100%;" *ngIf="showStatus1==1">
            Please shortlist 3 ATL Schools and once they will accept the invitation, Prescribed Activity section will be enabled.
          </div>

           <table class="table table-hover table-border mt-2 table-responsive">
            <thead class="facultyTable">
              <tr>
                <td colspan="7" allign="left" class="danger "
                  style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                  <h1 class="announcementHeadingMain">List of ATL Schools - Accepted Invitation</h1>
                </td>
              </tr>
              <tr>
                <th width="5%">S.No.</th>
                <th width="10%">School Code</th>
                <th width="20%">School Name</th>
                <th width="25%">Address</th>
                <th width="20%">Email</th>
                <th width="15%">Mobile</th>
                <th width="15%">Nominate IIC Coordinator</th>
                <th width="5%">Action</th>
              </tr>
            </thead>
            <tbody>
          <tr *ngFor="let activity of myActivity;let i = index">
          <td>{{i+1}}</td>
          <td>{{activity?.atl_code}}</td>
          <td>{{activity?.school_name}}</td>
          <td>{{activity?.address}}<br/>{{activity?.city}}<br/>{{activity?.district}}</td>
          <td>{{activity?.school_email}}</td>
          <td>{{activity?.contact_no}}</td>
          <td>
            <form [formGroup]="selectionForm">
            <mat-form-field>
              <mat-select formControlName="member" placeholder="Select Member" (selectionChange)="nominateIICCoordinator($event.value,activity?.atl_id)">
                <mat-option *ngFor="let option of iic_mem" [value]="option.Id">{{ option.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            </form>
            <p>{{activity?.m_name}}</p>
          </td>
          <td>

          <div *ngIf='activity?.join_status==1' class="alert alert-success" style="text-align: center; padding: 5px 10px !important; margin-top: 10px; color: #155724;
          background-color: #d4edda; border-color: #c3e6cb;" role="alert">
            Accepted
          </div>

          <div *ngIf='activity?.join_status==0 && activity?.mentee_accepted==0' class="alert alert-success" style="text-align: center; padding: 5px 10px !important; margin-top: 10px; color: #856404;
          background-color: #fff3cd; border-color: #ffeeba;" role="alert">
            Awating for Confirmation
          </div>

          <div *ngIf='activity?.join_status==0 && activity?.mentee_accepted==1' class="alert alert-success" style="text-align: center; padding: 5px 10px !important; margin-top: 10px; color: #856404;
          background-color: #fff3cd; border-color: #ffeeba;" role="alert">
            ATL has already selected an IIC
          </div>

          <p *ngIf='activity?.join_status==0' style="padding-top: 10px;">
            <button mat-button (click)="onSubmitClick(activity, '1')" style="
                  background-color: red; color: white;">
              Remove
            </button>
          </p>

          <!-- <p *ngIf='total_pages>3' style="padding-top: 10px;">
            <button mat-button (click)="onSubmitClick(activity, '1')" style="
                  background-color: red; color: white;">
              Remove
            </button>
          </p> -->
          </td>
          </tr>
          <tr *ngIf="total_pages==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
            background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
            There is no school found.
          </td></tr>

            </tbody>
          </table>
        </div>
        <!-- <div class="row">
          <div class="col-md-12">
            <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
              [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
            </mat-paginator>
          </div>
        </div> -->
      </div>

    </div>
</mat-tab>

  <mat-tab >
    <ng-template mat-tab-label>
      Prescribed Activities
    </ng-template>
    <div style="margin-top: 30px;" >
      <div class="container-fluid">
        <div class="row m-4">
          <div class="alert alert-danger" style="margin-top:10px; width:100%;"  *ngIf="showStatus1==1">
            Please shortlist ATL Schools from 'Choose ATL Schools'. Once they will accept the invitation then Prescribed Activity will be enabled.
          </div>
          <div style="margin-top:20px;" *ngIf="showStatus2==1">
            <app-atal-activity-proposal></app-atal-activity-proposal>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>


  <mat-tab >
    <ng-template mat-tab-label>
      Nominate Mentor
    </ng-template>
    <div style="margin-top: 30px;" >
      <div class="container-fluid">
        <div class="row m-4">
          <div class="col-md-12">
            <div class="alert alert-danger" style="margin-top:10px; width:100%;"  *ngIf="showStatus1==1">
            Please shortlist 3 ATL Schools and once they will accept the invitation then Prescribed Activity will be enabled.
          </div>
          <div style="margin-top:20px;" *ngIf="showStatus2==1">
            <div  *ngIf="ia_evnt?.length==0">
              <p style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;padding: 10px 15px;">
                  No Innovation Ambassadors in your institute. <br>Please nominate teaching.non-teaching faculty members for the IC training and under Handholding and Capacity Development menu.
              </p>

            </div>

            <table class="table table-hover table-border mt-2 table-responsive" *ngIf="ia_evnt?.length >=1">
              <thead class="facultyTable">
                <tr>
                  <td colspan="8" allign="left" class="danger "
                    style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                    <h1 class="announcementHeadingMain">Innovation Ambassadors</h1>
                  </td>
                </tr>
                <tr>
                  <th width="5%" >S.No.</th>
                  <th width="10%" >IAs Name</th>
                  <th width="10%" >Email ID</th>
                  <th width="10%" >Contact Number</th>
                  <th width="20%" >Institute Name</th>
                  <th width="5%" >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let activity of ia_evnt;let i = index">
                  <td>{{i+1}}</td>
                  <td>{{activity?.name}}</td>
                  <td>{{activity?.email}}</td>
                  <td>{{activity?.contact}}</td>
                  <td>{{activity?.institute_name}}</td>
                  <td>
                    <button mat-button (click)="onNominateClick(2,activity?.id)" style="
                    background-color: green; color: white;" [ngStyle]="{'display': (activity?.is_nominate==0) ? 'block' : 'none'}" id="nominateSuccess">
                      Nominate
                    </button>
                    <button mat-button (click)="onNominateRemoveClick(2,activity?.id)" style="
                    background-color: green; color: white;" [ngStyle]="{'display': (activity?.is_nominate==1) ? 'block' : 'none'}" id="nominateRemove">
                      Remove Nominate
                    </button>
        
                  </td>
                </tr>
                <!-- <tr *ngIf="ia_evnt?.length==0">
                  <td colspan="8" style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                    There is no innovation ambassador added yet.
                  </td>
                </tr>  -->   
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)" [length]="total_pages1" [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
              </mat-paginator>
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      Coordinators Detail
    </ng-template>
    <div style="margin-top: 30px;" >
      <div class="container-fluid">
        <div class="row m-4">
			<div class="col-md-12">
          <div style="margin-top:20px;" *ngIf="showStatus2==1">
            <mat-tab-group class="matGroupActionPlan">
              <mat-tab>
                <ng-template mat-tab-label>
                  ATL Coordinator
                </ng-template>
                <app-nominated-iic-atl-coordinator-list></app-nominated-iic-atl-coordinator-list>
              </mat-tab>
              <mat-tab >
                <ng-template mat-tab-label>
                  IIC Coordinator
                </ng-template>
                <app-nominated-iic-coordinator-list></app-nominated-iic-coordinator-list>
              </mat-tab>
              <mat-tab >
                <ng-template mat-tab-label>
                  IA as Mentors
                </ng-template>
                <app-atl-ia-mentor-detail></app-atl-ia-mentor-detail>
              </mat-tab>
            </mat-tab-group>
          </div>
		  </div>
        </div>
      </div>
    </div>
  </mat-tab>

</mat-tab-group>







