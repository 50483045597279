import { Component, OnInit, Inject } from '@angular/core';
import { InstituteDashboard1819Service } from 'src/app/Services/institute-dashboard1819.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard1819-notifications',
  templateUrl: './dashboard1819-notifications.component.html',
  styleUrls: ['./dashboard1819-notifications.component.css']
})
export class Dashboard1819NotificationsComponent implements OnInit {

  notifications: any;
  page: any;
  email: any;
  // baseUrl = myGlobals.baseUrl;

  constructor(private dashboardService: InstituteDashboard1819Service,
    private instituteCouncilService: InstituteMyCouncilService,
    public dialog: MatDialog) {
    dashboardService.getNotifications().subscribe(
      data => {
        this.notifications = data
      }
    )


    instituteCouncilService.getHOI('').subscribe(
      data => this.email = data['hoiDetails']['email']
    )
  }

  public onPageChanged(event) {
    this.page = event;
    this.notifications;
  }

  openDialog(notification){
    const dialogRef = this.dialog.open(NotificationDialog, {
      width: '500px',
      data: { notification: notification },
    });
  }

  ngOnInit(): void {
  }

}

@Component({
  selector: 'app-notification-dialog',
  templateUrl: 'notification-dialog.html',
})
export class NotificationDialog {
  notification: any;

  constructor(    public dialogRef: MatDialogRef<NotificationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.notification = data.notification;
  }

}