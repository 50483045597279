import { Injectable, Type } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  private baseUrl = myGlobals.baseUrl;
  constructor(private http: HttpClient) { }

  getApprovedIICData() {
    return this.http.get(`${this.baseUrl}/getApprovedIICNo`);
  }
  getPublicNews() {
    return this.http.get(`${this.baseUrl}/getPublicNews`)
  }
  getPublicNewsChunk() {
    return this.http.get(`${this.baseUrl}/getPublicNewsChunk`)
  }
  getFeedback() {
    return this.http.get(`${this.baseUrl}/getPublicFeedback`)
  }
  getAnnouncementsData() {
    return this.http.get(`${this.baseUrl}/getAnnouncements`)
  }
  getDownloadsData() {
    return this.http.get(`${this.baseUrl}/getDownloads`)
  }
  getToken(token) {
    return this.http.get(
      `${this.baseUrl}/checkValidToken/` + token);
  }
  getInstitutesList(data, page, per_page = 10) {
    return this.http.get(`${this.baseUrl}/getInnovationcontestDetails?per_page=` + per_page + `&page=` + page, data);
  }

  // Map Api Data
  getMapDetails() {
    return this.http.get(`${this.baseUrl}/stateInstituteCount`)
  }

  submitInternCRequest1data(){
    // alert('test function here');

    return this.http.get(`http://127.0.0.1:8000/api/submitInternCRequest1`);
    }


  getMapMultipleDetails(data) {
    return this.http.get(`${this.baseUrl}/stateDataCount?type=`+data)
  }


  // Course Count

  getCourseCount() {
    return this.http.get(`${this.baseUrl}/instituteCoursesCount`)
  }

  getInstituteCount() {
    return this.http.get(`${this.baseUrl}/instituteTypeCount`)
  }



  instituteSubTypeCount(){
    return this.http.get(`${this.baseUrl}/instituteSubTypeCount`)

  }

  getBannerDetails(site) {
    return this.http.get(`${this.baseUrl}/banner-details?site=`+site)
  }

  getActivityLists(academic_year) {
    return this.http.get(`${this.baseUrl}/activity-lists?academic_year=`+academic_year)
  }

  getIicImpactDashboard(site) {
    return this.http.get(`${this.baseUrl}/iic-impact-dashboard-data?site=`+site)
  }

}
