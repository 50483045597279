import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
//import { emailValidator } from 'src/app/app.component';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import * as myGlobals from 'src/app/app.component';


@Component({
  selector: 'app-impact-lectures-report-form',
  templateUrl: './impact-lectures-report-form.component.html',
  styleUrls: ['./impact-lectures-report-form.component.css']
})

export class ImpactLecturesReportFormComponent implements OnInit {

  public activityForm: FormGroup;
  photograph1: any;
  photograph2: any;
  programData: any;
  facultyList: string[] = [];
  studentList: string[] = [];
  programType: string[] = [];
  programTheme: string[] = [];
  event_id: string;
  action: string;
  institute_id: string;
  programName: any;
  myDate = (new Date());latest_date: any;
  minDate = "2022-05-10";
  maxDate = "2025-08-31";

  program_type_other_flg: boolean;
  program_theme_other_flg: boolean;

  public start_date: any;
  public date: any;

  public baseImageUrl = myGlobals.baseImageUrl;
  public baseurl = myGlobals.baseUrl1;

  social: any[];
  social1: any[];
  social2: any[];
  urlReport: any;
  session_id:any;
  type:any;
  speaker_cv: FileList | null;
  proof_of_transaction: FileList | null;
  receipt_of_transaction: FileList | null;
  photo_1: FileList | null;
  photo_2: FileList | null;
  session_pdf: FileList | null;

  speaker_cv_url: string;
  photo_1_url: string;
  photo_2_url: string;
  receipt_of_transaction_url: string;
  proof_of_transaction_url: string;
  totalcount:any;
  session_pdf_url:any;
  session_name:any;
  sessionList:any;

  constructor(public fb: FormBuilder,
    private jarwis: JarwisService,
    private instituteMaster: InstituteInnovationContestService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.program_theme_other_flg = true;
    this.program_type_other_flg = true;
    this.start_date = "";
    this.date = "";


  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.session_id = params['session_id'];
      this.type = params['type'];
      this.session_name = 'Session ' + params['session'];
    });

    this.totalcount = 0;
    this.speaker_cv_url = "";
    this.photo_1_url = "";
    this.photo_2_url = "";
    this.receipt_of_transaction_url = "";
    this.proof_of_transaction_url = "";
    this.session_pdf_url = "";

    this.activityForm = this.fb.group({
      lecture_date: [null, Validators.required],
      lecture_duration: [null, Validators.required],
      speaker_name: [null, Validators.required],
      speaker_email: [null, Validators.required],
      speaker_designation: [null, Validators.required],
      speaker_organization: [null],
      speaker_phone: [null, Validators.required],
      speaker_experience: [null, Validators.required],
      no_of_student: [null, Validators.required],
      no_of_faculty: [null, Validators.required],
      outcome: [null, Validators.required],
      video_url: [null, Validators.required],
      social_media: [null, Validators.required],
      honorarium_paid: [null, Validators.required],
    });

    this.instituteMaster.getSessionReportIL(this.session_id, this.type).subscribe(
      (data) => this.handleResponse(data)
    );
    this.getTable();
  }

  handleResponse(data) {

    if(data['totalcount'] > 0){

    this.activityForm.patchValue({
      lecture_date: data['lecture_date'],
      lecture_duration: data['lecture_duration'],
      speaker_name: data['speaker_name'],
      speaker_email: data['speaker_email'],
      speaker_designation: data['speaker_designation'],
      speaker_organization: data['speaker_organization'],
      speaker_phone: data['speaker_phone'],
      speaker_experience: data['speaker_experience'],
      no_of_student: data['no_of_student'],
      no_of_faculty: data['no_of_faculty'],
      outcome: data['outcome'],
      video_url: data['video_url'],
      social_media: data['social_media'],
      honorarium_paid: data['honorarium_paid']
    });

    this.speaker_cv_url               = data['speaker_cv'];
    this.photo_1_url                  = data['photo_1'];
    this.photo_2_url                  = data['photo_2'];
    this.receipt_of_transaction_url   = data['receipt_of_transaction'];
    this.proof_of_transaction_url     = data['proof_of_transaction'];
    this.session_pdf_url              = data['session_pdf'];

  }
    this.totalcount = data['totalcount'];

  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  onSubmit(values) {

    var formData = new FormData();

    var speaker_cv = this.speaker_cv && this.speaker_cv.length ? this.speaker_cv[0] : null;

    if(this.totalcount > 0){
      formData.append('speaker_cv', speaker_cv);
      formData.append('old_speaker_cv', this.speaker_cv_url);
    }
    else
    {
      formData.append('speaker_cv', speaker_cv);
      formData.append('old_speaker_cv', "");
    }

    var receipt_of_transaction = this.receipt_of_transaction && this.receipt_of_transaction.length ? this.receipt_of_transaction[0] : null;

    if(this.totalcount > 0){
      formData.append('receipt_of_transaction', receipt_of_transaction);
      formData.append('old_receipt_of_transaction', this.receipt_of_transaction_url);
    }
    else
    {
      if(this.receipt_of_transaction==null && this.receipt_of_transaction==undefined) { alert("Please upload the proof of transaction document."); return false; }
      formData.append('receipt_of_transaction', receipt_of_transaction);
      formData.append('old_receipt_of_transaction', "");
    }

    var proof_of_transaction = this.proof_of_transaction && this.proof_of_transaction.length ? this.proof_of_transaction[0] : null;

    if(this.totalcount > 0){
      formData.append('proof_of_transaction', proof_of_transaction);
      formData.append('old_proof_of_transaction', this.proof_of_transaction_url);
    }
    else
    {
      if(this.proof_of_transaction==null && this.proof_of_transaction==undefined) { alert("Please upload the proof of transaction document."); return false; }

      formData.append('proof_of_transaction', proof_of_transaction);
      formData.append('old_proof_of_transaction', "");
    }

    var photo_1 = this.photo_1 && this.photo_1.length ? this.photo_1[0] : null;

    if(this.totalcount > 0){
      formData.append('photo_1', photo_1);
      formData.append('old_photo_1', this.photo_1_url);
    }
    else
    {
      if(this.photo_1==null && this.photo_1==undefined) { alert("Please upload the photograph 1."); return false; }
      formData.append('photo_1', photo_1);
      formData.append('old_photo_1', "");
    }

    var photo_2 = this.photo_2 && this.photo_2.length ? this.photo_2[0] : null;

    if(this.totalcount > 0){
      formData.append('photo_2', photo_2);
      formData.append('old_photo_2', this.photo_2_url);
    }
    else
    {
      formData.append('photo_2', photo_2);
      formData.append('old_photo_2', "");
    }

    var session_pdf = this.session_pdf && this.session_pdf.length ? this.session_pdf[0] : null;

    if(this.totalcount > 0){
      formData.append('session_pdf', session_pdf);
      formData.append('old_session_pdf', this.session_pdf_url);
    }
    else
    {
      if(this.session_pdf==null && this.session_pdf==undefined) { alert("Please upload the Session plan / Brochure / Document."); return false; }

      formData.append('session_pdf', session_pdf);
      formData.append('old_session_pdf', "");
    }
    formData.append('session_id', this.session_id);
    formData.append('lecture_id', this.type);
    formData.append('session_name', this.session_name);
    formData.append('lecture_date', this.changeDate(this.activityForm.get('lecture_date').value));
    formData.append('lecture_duration', this.activityForm.get('lecture_duration').value);
    formData.append('speaker_name', this.activityForm.get('speaker_name').value);
    formData.append('speaker_email', this.activityForm.get('speaker_email').value);
    formData.append('speaker_designation', this.activityForm.get('speaker_designation').value);
    formData.append('speaker_organization', this.activityForm.get('speaker_organization').value);
    formData.append('speaker_phone', this.activityForm.get('speaker_phone').value);
    formData.append('speaker_experience', this.activityForm.get('speaker_experience').value);
    formData.append('no_of_student', this.activityForm.get('no_of_student').value);
    formData.append('no_of_faculty', this.activityForm.get('no_of_faculty').value);
    formData.append('outcome', this.activityForm.get('outcome').value);

    formData.append('video_url', this.activityForm.get('video_url').value);
    formData.append('social_media', this.activityForm.get('social_media').value);
    formData.append('honorarium_paid', this.activityForm.get('honorarium_paid').value);

    this.instituteMaster.submitSessionReportIL(formData).subscribe(
      data => this.handleResponseData(data));

  }

//20-6-24
getTable(){
  this.instituteMaster.getsessions().subscribe(
    data => this.setsessions(data)
  );  

}

setsessions(data){
  this.sessionList=[];
for(let i=0;i<data['sessions'].length;i++){
  this.sessionList.push(data['sessions'][i])
}
}
//20-6-24
  handleResponseData(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg']);
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
}
