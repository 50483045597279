<div class="container-fluid">
<mat-tab-group mat-align-tabs="center">
<mat-tab label="Manage Challenge">

<div class="container-fluid">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Manage Challenges</h2>
    </div>

	<div class="card">
        <div class="card-title" style="text-align: center;"><p>Create and Manage Idea/ Innovation/ Startup Challenges at Institute level.</p></div>
	</div>
<div class="refrredBox">
 <div class="row rws-listpage">
	<div class="col-md-12">
  <p><a class="btn btn-primary referralBut" routerLink='/institute/yukti-challenges-add/add'>Add New</a></p>
  <div class="tableBody">
				<div class="table-responsive">
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Title</th>
            <th scope="col">Theme</th>
            <th scope="col">Application Start & End Date</th>
            <th scope="col">Challenge Start & End Date</th>
            <th scope="col">Brochure</th>
            <th scope="col">Info</th>
            <th scope="col">Co-ordinator Details</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let nomination of iicrepository; index as i" >
            <td>{{i+1}}</td>
            <td>{{nomination?.title}}</td>
            <td>{{nomination?.theme}}</td>
            <td>
                {{nomination?.submission_start_date}}
                <br/>
                {{nomination?.submission_end_date}}
            </td>
            <td>{{nomination?.event_start_date}}<br/>{{nomination?.event_end_date}}</td>
            <td><a href="{{ baseUrl + nomination?.brochure}}" target="_blank">View File</a></td>
            <td>{{nomination?.level}}
                <br/>{{nomination?.user_type}}
                <br/>{{nomination?.region}}
              </td>
            <td>{{nomination?.coordinator_name}}
              <br/>{{nomination?.coordinator_email}}
              <br/>{{nomination?.coordinator_phone}}
            </td>
            <td *ngIf="nomination?.status!=1" style="width: 8%;">
				<p class="yuktiPending">Pending</p>
            </td>
            <td *ngIf="nomination?.status==1" style="width: 8%;">
              <p class="yuktiLive">Live</p>
            </td>
            <td>
                <p *ngIf="nomination?.status!=1"><a routerLink="/institute/view-submission/{{ nomination?.id }}">
					<button mat-raised-button matTooltip="View Submission" matTooltipPosition="after" aria-label="Button that displays a tooltip when focused or hovered over" color="warn" class="btn-tbl-edit">
						<mat-icon>list</mat-icon>
                    </button></a></p>    
                <p *ngIf="nomination?.status!=1 && nomination?.isAlreadyNominated!=1"><button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn" class="btn-tbl-edit">
                    <mat-icon>edit</mat-icon>
                    </button></p>    
                <p *ngIf="nomination?.status!=1 && nomination?.isAlreadyNominated!=1"><button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')"   color="warn" class="btn-tbl-delete">
                      <mat-icon>delete</mat-icon>
                      </button></p>
            </td>
        </tr>
        <tr *ngIf="iicrepository?.length== 0" >
            <td colspan="10"><h3 style="text-align: center">No Data Found</h3></td>
        </tr>
    </tbody>
</table>
</div>
</div>



 </div>
</div>
</div>
</div>
</mat-tab>
<mat-tab label="Nominate Challenge">
    <app-yukti-institute-challenges></app-yukti-institute-challenges>
</mat-tab>
</mat-tab-group>
</div>