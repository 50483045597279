<div class="container">

  <div class="row">
    <div class="col-8">
      <button mat-raised-button color='primary' class="suggestionCustomButton" (click)='openDialog()'>
        <mat-icon>add</mat-icon>Submit Your Suggestion
      </button>
    </div>
    <!--<div class="col-2">
      <div *ngIf="!firstPage">
        <button type="button" class="mr-4" (click)='previousPage()' class="btn btn-success">Previous</button>
      </div>
    </div>
	<div class="col-2">
      <div *ngIf="!lastPage">
        <button type="button" class="ml-4" (click)='nextPage()' class="btn btn-success">Next</button>
      </div>
    </div>-->
  </div>

  <div class="mt-3">
    <table class="table table-hover">
      <thead class="facultyTable">
        <tr>
          <td colspan="7" allign="left" class="danger " style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Suggestion/Query List</h1>
          </td>
        </tr>
        <tr>
          <th scope="col" style="text-align: center">Subject</th>
          <th scope="col" style="text-align: center">Message</th>
          <th scope="col" style="text-align: center">Remark</th>
          <th scope="col" style="text-align: center">Status</th>
          <th scope="col" style="text-align: center">Remark Date</th>
          <th scope="col" style="text-align: center">View Replies</th>
          <th scope="col" style="text-align: center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let message of submittedMessage; let i = index;">
          <td style="text-align: center">{{message?.subject}}</td>
          <td style="text-align: center">{{message?.messege}}</td>
          <td style="text-align: center">{{message?.remark}}</td>
          <td style="text-align: center">{{message?.status}}</td>
          <td style="text-align: center">{{message?.remarkdate}}</td>
          <td style="text-align: center">
            <mat-icon (click)="openDialogReplyList(message)" class="instiSuggestinView">remove_red_eye</mat-icon>
          </td>
          <td style="text-align: center">
            <button type="button" mat-raised-button color='primary' (click)="replyMessage(message)" class="btn btn-success btnDownSuccess">Reply</button>
          </td>
        </tr>

        <tr *ngIf="submittedMessage.length<1">
          <p><i>No records added yet!</i></p>
        </tr>
      </tbody>
    </table>
  </div>

  <!--<div class="row announcementMargin">
		<div class="col-md-4" *ngFor="let message of submittedMessage; let i = index;">
      <div class="CardBox">
        <img src="assets/customCard/card-gradient-img.png" class="CardBoxContentTopImg">
        <img src="assets/customCard/card-top-banner.png" class="CardBoxContentTopBanner">
        <div class="CardBoxContentTopName">
          <!-- <h3>Name</h3>
          <h3>Suggestion/Query List</h3>
        </div>
        <div class="CardBoxContent">
          <h3>Subject</h3>
          <p>{{message?.subject}}</p>
        </div>

		<div class="CardBoxContent">
		  <h3>Message</h3>
		  <p >{{message?.messege}}</p>
		</div>
		<div id="InstSuggestionCustomID" class="collapse">

			<div class="CardBoxContent">
			  <h3>Remark</h3>
			  <p>{{message?.remark}}</p>
			</div>

			<div class="CardBoxContent">
			  <h3>Status</h3>
			  <p>{{message?.status}}</p>
			</div>

			<div class="CardBoxContent">
			  <h3>Remark Date</h3>
			  <p>{{message?.remarkdate}}</p>
			</div>
		</div>

		<div class="CardBoxContent">
          <div class="row">
            <div class="col-md-6 suggestionViewReply">
              <div class="cardEditIcon">
                <h3>View Replies</h3>
				<p><mat-icon (click)="openDialogReplyList(message)" class="suggestionRedEye">remove_red_eye</mat-icon></p>
              </div>
            </div>
            <div class="col-md-6 suggestionAction">
              <div class="cardEditIcon">
                <h3>Action</h3>
				<p><button type="button" mat-raised-button color='primary' (click)="replyMessage(message)" class="instIdeaSubViewDetails">Reply</button></p>
              </div>
            </div>
          </div>
        </div>


        <div class="cardReadMore">

          <button data-toggle="collapse" data-target="#InstSuggestionCustomID">Read More</button>

        </div>

      </div>
    </div>
	<div class="col-md-12" *ngIf="submittedMessage.length<1">
		<p><i>No records added yet!</i></p>
	</div>
	</div> -->

   <!-- <div mat-dialog-actions align="center" class="mt-3">
        <div *ngIf="!firstPage">
            <button type="button" class="mr-4" (click)='previousPage()' class="btn btn-success">Previous</button>
        </div>
        <div *ngIf="!lastPage">
            <button type="button" class="ml-4" (click)='nextPage()' class="btn btn-success">Next</button>
        </div>
    </div> -->
	<div class="row">
			<div class="col-md-12">
				<mat-paginator #paginator
								showFirstLastButtons
								(page)="nextPage($event)"
								[length]="total_pages"
								[pageIndex]="frontCurrentPageNo"
								[pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
							</mat-paginator>

			</div>
		</div>
</div>
