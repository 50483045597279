import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { id } from '@swimlane/ngx-charts';
import { data } from 'jquery';

@Component({
  selector: 'app-evaluator-add',
  templateUrl: './evaluator-add.component.html',
  styleUrls: ['./evaluator-add.component.css']
})
export class InstituteEvaluatorAddComponent implements OnInit {

  public iicEvaluatorAddition: FormGroup;

  name: any = '';
  email: any = '';
  contact: any = '';
  iicid:any='';
  show_field_2:any;
  show_field_3:any;
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicDetails: any[]=[];
  iicName: any;
  rid:any;
  Details:any;
  Show:any=false;
  type:any;
  activity_id:any;

  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
      // this.type = params.type;
    });

  }

  ngOnInit(): void {

    this.iicEvaluatorAddition = this.fb.group({
      'name': [null, Validators.required],
      'email': [null, Validators.required],
      'submission_type': [null, Validators.required],
      'user_name': [],
      'iicid': [null],
      'contact': [null, Validators.required],
    });

    this.requireField=0;
    this.activity_id=0;

    // this.instituteActivityService.getIicEvaluatorList(this.type).subscribe(
    //   (data) => this.handleResponseList(data)
    // );

    this.instituteActivityService.getIicEvaluatorDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

  }

  handleResponseForAutoPopulate(data) {

    // this.programTheme = data['data'];
    // if (data['data'][0]) {
    //   data['data'][0].forEach((element) => {
    //     this.programTheme.push(element.data.data.name);
    //   });
    // }
    this.programTheme = "";

  }

  handleResponse(data) {

    // if(data['submission_type']=="SELF")
    // {
    //   this.requireField=0;
    // }
    // else
    // {
      if(data['submission_type']=="1" || data['submission_type']=="2" || data['submission_type']=="3")
      {
        this.requireField=1;
        // this.instituteActivityService.getIicEvaluatorList(data['submission_type']).subscribe(
        //   (data) => this.handleResponseList(data)
        // );
      }
    //}

    //this.activity_id = data['activity_id'];

    if(data['totalcount'] > 0){
      //  alert(data['activity_id']);
    this.iicEvaluatorAddition.patchValue({
      name: data['name'],
      email: data['email'],
      contact: data['contact'],

    });
  }
    this.totalcount = data['totalcount'];

  }

  handleResponseList(data) {
    this.activity_id = data['name'];
    this.iicActivity = data['data'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('name', this.iicEvaluatorAddition.get('name').value);
    formData.append('email', this.iicEvaluatorAddition.get('email').value);
    formData.append('contact', this.iicEvaluatorAddition.get('contact').value);
    formData.append('submission_type', this.iicEvaluatorAddition.get('submission_type').value);
    formData.append('user_name', this.iicEvaluatorAddition.get('user_name').value);
    formData.append('iicid', this.iicEvaluatorAddition.get('iicid').value);
    formData.append('rid', "New");

    this.instituteMaster.submitIicEvaluator(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/innovation-repository';
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  onStatusChange(ob) {
    let nbaStatus = ob.value;
    if(nbaStatus == '1') {
      this.show_field_2 = 1;
      this.requireField = 1;
      this.instituteActivityService.getIicEvaluatorList(ob.value,'NONE','NONE').subscribe(
        (data) => this.handleResponseList(data)
      );
    }
    else if(nbaStatus == '3'){
      this.show_field_2 = 3;
      this.requireField = 3;
    } else {
      this.requireField = 0;
      // this.show_field_2 = 2;
      // this.requireField = 1;
      // this.instituteActivityService.getIicEvaluatorList(ob.value,'NONE','NONE').subscribe(
      //   (data) => this.handleResponseList(data)
      // );
    }

  }

  getInstitute(data) {
      this.instituteActivityService.getInstitute(data).subscribe((data) => this.handleResponse1(data));
      this.instituteActivityService.getInstituteName(data).subscribe((data) => this.handleResponse2(data));
      // this.instituteActivityService.getInstituteData(data).subscribe((data) => this.onNbaStatusChange2(data));
    }
    handleResponse1(data){
      this.iicDetails = data;
  }
  handleResponse2(data){
    this.iicName = data;
    if (data['message'] == "Error") {
      this.Show = false;
      this.toastr.error(data['reason']);
    }
    else{
      this.Show = true;
      this.toastr.success(data['instituteName']+','+data['name']);
    }
}

  onNbaStatusChange(ob) {
    let nbaStatus = ob.value;
    this.instituteActivityService.getIicEvaluatorList(ob.value,nbaStatus,this.show_field_2).subscribe(
      (data) => this.handleResponsePatch(data)
    );

  }
  onNbaStatusChange2(ob) {

    let nbaStatus = ob.value;
    this.instituteActivityService.getInstituteData(ob.value,nbaStatus,this.show_field_2).subscribe(
      (data) => this.handleResponsePatch1(data)
    );
  }
  

  handleResponsePatch(data) {
    this.iicEvaluatorAddition.patchValue({
      name: data['data']['name'],
      email: data['data']['email'],
      contact: data['data']['mobile']
    });
  }
  handleResponsePatch1(data) {
    this.iicEvaluatorAddition.patchValue({
      name: data['data']['name'],
      email: data['data']['email'],
      contact: data['data']['mobile']
    });
  }




}
