<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12" >
                <table class="table table-hover" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="7" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Innovation Ambassador Requests</h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col"> Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Contact</th>
                            <th scope="col">Old Institute Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="list?.length > 0">
                        <tr *ngFor="let item of list| paginate: { itemsPerPage: 5, currentPage: page }; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.contact}}</td>
                            <td>{{item.instituteName}}</td>
                            <td>{{item.status}}</td>
                            
                           
                            <td >
                               <button type="button" class="btn btn-success" [disabled]="item.status!='Pending'" (click)="respondRequest(item,'Approved')">Approve</button>
                               <button type="button" class="btn btn-danger" [disabled]="item.status!='Pending'" (click)="respondRequest(item,'Disapproved')">Disapprove</button>

                            </td>
                       
                        </tr>
                    </tbody>
                    <tbody *ngIf="list?.length == 0">
                        <tr style="text-align: center;">
                            <td colspan="5">No Ambassador with transfer request</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls class="annular-pagination float-right" maxSize="3"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
            </div>

            <div class="col-md-12" >
                <table class="table table-hover" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="7" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Accepted Innovation Ambassador Requests </h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col"> Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Contact</th>
                            <th scope="col">Old Institute Name</th>
                           
                        </tr>
                    </thead>
                    <tbody *ngIf="list2[0]?.length">
                        <tr *ngFor="let item of list2[0]| paginate: { itemsPerPage: 5, currentPage: page }; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.contact}}</td>
                          
                            <td>{{item.instituteName}}</td>
                           
                        </tr>
                    </tbody>
                    <tbody *ngIf="!list2[0]?.length">
                        <tr style="text-align: center;">
                            <td colspan="5">No Record Found for Accepted Innovation Ambassador</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls class="annular-pagination float-right" maxSize="3"
                    (pageChange)="onPageChanged2($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
