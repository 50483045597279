import { Component, OnInit } from '@angular/core';
import { OnlinePagesInnerService } from 'src/app/Services/onlineSession/online-pages-inner.service';

@Component({
  selector: 'app-sesssion-list',
  templateUrl: './sesssion-list.component.html',
  styleUrls: ['./sesssion-list.component.css']
})
export class SesssionListComponent implements OnInit {
  sessionList: any[];

  constructor(private onlinePagesInnerService: OnlinePagesInnerService) { }

  ngOnInit(): void {
    // this.onlinePagesInnerService.getSessionList().subscribe(
    //   data => 
    //     this.setData(data)   )
  }
  setData(data){
//     this.sessionList=[];
// for(let i=0;i<data['data'].length;i++){
//   this.sessionList.push(data['data'][i])
// }

  }
  // getSessionList() {
  //   return this.http.get(`${this.baseUrl}/getNICTraining`);
  // }
}
