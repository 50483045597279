import { Component, OnInit } from '@angular/core';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-edit-schedule-component',
  templateUrl: './edit-schedule-component.component.html',
  styleUrls: ['./edit-schedule-component.component.css']
})
export class EditScheduleComponentComponent implements OnInit {
  
  events: any[] = [];
  baseUrl = myGlobals.baseUrl1;

  constructor(private instituteActivityService: InstituteActivityService) { }

  ngOnInit(): void {
    this.instituteActivityService.getActivities().subscribe(
      (data:any[]) => {
        this.events = data;
      },
      (error) => {
        console.error('Error fetching events', error);
      }
    );
  }
}
