<div class="container-fluid">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            ATL Coordinator
        </h2>
    </div>

    <div class="refrredBox">
        <div class="row rws-listpage">
	        <div class="col-md-12">
                <!-- <p><a class="btn btn-primary referralBut" routerLink='/institute/atl-add-nominate-coordinator'>Add New</a></p> -->
                <div class="tableBody">
				    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead class="facultyTable">
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">School Name</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <!-- <th scope="col">Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let activity of coordinatorDetail;let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{activity?.school_name}}</td>
                                    <td>{{activity?.name}}</td>
                                    <td>{{activity?.email}}</td>
                                    <td>{{activity?.mobile}}</td>
                                    <!-- <td>
                                        <button mat-button routerLink="/institute/atl-add-nominate-coordinator" [queryParams]="{id: activity?.id}" style="background-color: green; color: white;">
                                            Edit
                                        </button>                            
                                    </td> -->
                                </tr>
                                <tr *ngIf="coordinatorDetail?.length==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
                                background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                                Innovation Ambassadors are not nominated.
                                </td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
