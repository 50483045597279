 <!--title-->
   <title>

     star Rating 2018-19 | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>

<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Innovation Council</a>
      </li>
      <li class="aht-breadcrumbs__item">
        IIC Rating 2018-19
      </li>
    </ul>
  </div>
	
<div class="row ratingMarginBox">
<div class="col-sm-12">
				
<h1 style="font-size: 40px;text-align:center; color:#ff5402; margin-bottom: 20px; margin-top:20px; "> Star Ratings of Institution's Innovation Councils for the IIC Calendar Year&nbsp; 2018-19</h1>
<h3 style="font-size:21px;text-align:center; margin-bottom: 20px; margin-top:20px; ">Congratulations!!!.  MHRD's Innovation Cell has announced Zonewise Star rating for the IICs based on their performance of the IIC Calendar Year 2018-19.  </h3>
<h3 style="font-size:21px;text-align:center; margin-bottom: 20px; margin-top:20px; ">The assigned Star Rating is not a national ranking of HEIs and it just reflects the performance of IICs based on our assessment. </h3>


<table cellspacing="0" border="0" width="75%" style="margin: auto;">
	<colgroup width="42"></colgroup>
	<colgroup width="184"></colgroup>
	<colgroup width="194"></colgroup>
	<colgroup width="186"></colgroup>
	<tr>
		<td style="background-color:#0d133d;border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="45" align="center" valign=middle bgcolor="#FFFFFF"><b><font face="Arial" color="#fff">S.No</font></b></td>
		<td style="background-color:#0d133d;border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><b><font face="Arial" color="#fff">Zone</font></b></td>
		<td style="background-color:#0d133d;border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><b><font face="Arial" color="#fff">Download PDF</font></b></td>
		<td style="background-color:#0d133d;border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><b><font face="Arial" color="#fff">State</font></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="60" align="center" valign="middle" bgcolor="#FFFFFF" sdval="1" sdnum="1033;"><font face="Arial" color="#000000">1</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Central/CRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000"><a href="assets/zone/CRO.pdf" target="_blank"><img src="assets/zone/download_icon.svg" style="width:8%"/></a></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Chhattisgarh</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Gujarat</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Madhya Pradesh</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#E7E6E6" sdval="2" sdnum="1033;"><font face="Arial" color="#000000">2</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">South-Central/SCRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000"><a href="assets/zone/SCRO.pdf" target="_blank"><img src="assets/zone/download_icon.svg" style="width:8%"/></a></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Andhra Pradesh</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Telangana</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="60" align="center" valign="middle" bgcolor="#FFFFFF" sdval="3" sdnum="1033;"><font face="Arial" color="#000000">3</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Northern/NRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000"><a href="assets/zone/NRO.pdf" target="_blank"><img src="assets/zone/download_icon.svg" style="width:8%"/></a></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Bihar</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Uttar Pradesh</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Uttarakhand</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=7 height="140" align="center" valign="middle" bgcolor="#E7E6E6" sdval="4" sdnum="1033;"><font face="Arial" color="#222222">4</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=7 align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#222222">North-West/NWRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=7 align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#222222"><a href="assets/zone/NWRO.pdf" target="_blank"><img src="assets/zone/download_icon.svg" style="width:8%"/></a></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Chandigarh</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Delhi</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Haryana</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Himachal Pradesh</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Jammu and Kashmir</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Punjab</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Rajasthan</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#FFFFFF" sdval="5" sdnum="1033;"><font face="Arial" color="#000000">5</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Southern/SRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000"><a href="assets/zone/SRO.pdf" target="_blank"><img src="assets/zone/download_icon.svg" style="width:8%"/></a></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Puducherry</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Tamil Nadu</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=12 height="258" align="center" valign="middle" bgcolor="#E7E6E6" sdval="6" sdnum="1033;"><font face="Arial" color="#000000">6</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=12 align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Eastern/ERO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=12 align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000"><a href="assets/zone/ERO.pdf" target="_blank"><img src="assets/zone/download_icon.svg" style="width:8%"/></a></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Andaman and Nicobar Islands</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Arunachal Pradesh</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Assam</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Jharkhand</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Manipur</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Meghalaya</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Mizoram</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Nagaland</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Odisha/Orissa</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Sikkim</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Tripura</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">West Bengal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#FFFFFF" sdval="7" sdnum="1033;"><font face="Arial" color="#000000">7</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">South-West/SWRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000"><a href="assets/zone/SWRO.pdf" target="_blank"><img src="assets/zone/download_icon.svg" style="width:8%"/></a></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Karnataka</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Kerala</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=4 height="80" align="center" valign="middle" bgcolor="#E7E6E6" sdval="8" sdnum="1033;"><font face="Arial" color="#000000">8</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=4 align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Western/WRO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;" rowspan=4 align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000"><a href="assets/zone/WRO.pdf" target="_blank"><img src="assets/zone/download_icon.svg" style="width:8%"/></a></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Dadra and Nagar Haveli</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Daman and Diu</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Goa</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Maharashtra</font></td>
	</tr>
	</table>
	</div>
		
</div>

<!-- Footer -->
<app-inner-footer></app-inner-footer>