<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <td colspan="3" allign="left" class="danger"
                                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Certificates</h1>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone </th>
                            <th scope="col">Certificate</th>

                        </tr>
                    </thead>
                    <tbody>


                        <tr *ngFor="let team of certificate;index as i">
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>{{team?.name}}</td>
                            <td>{{team?.email}}</td>
                            <td>{{team?.mobile}}</td>
                            <!-- {{this.baseurl +team?.CV_upload}} -->
                            <!-- <td class="downloadD">
                              <a target="_blank" href="{{team?.certificate_url + team?.encrypted}}">
                                <button type="button" class="btn btn-success btnDownSuccessCerti">
                                 Download
                                </button>
                              </a>
                            </td> -->
                            <td class="downloadD">
                              <button type="button" class="btn btn-success btnDownSuccessCerti"
                                  (click)="getMembertCertificate(team)">
                                  Download
                              </button>
                          </td>
                        </tr>

                        <!-- (click)="getEstablishmentCertificate()" -->



                    </tbody>

                </table>

            </div>
        </div>
    </div>
</div>
