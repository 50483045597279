<div class="container-fluid">
<!-- <mat-tab-group mat-align-tabs="center" class="menteeLabelBut" style="margin: 50px 0 0 0;">
    <mat-tab>
      <ng-template mat-tab-label>
          Select IIC Institutes
      </ng-template> -->

      <div class="container-fluid" *ngIf="isDisplay==1">
        <mat-card class="mat-elevation-z6 proInstCardMat">   
                  <div class="row">
                
                    <div class="col-6">
                      <mat-select placeholder="All Activity Type" [(ngModel)]="activity_filter" (ngModelChange)="activityFilter()"
                        class="instiIICcalActi" (selectionChange)="onDistrictChange($event)">
                        <mat-option value="">All District</mat-option>
                        <mat-option value="{{district.district}}" *ngFor="let district of districtList">{{district.district}}</mat-option>
                      </mat-select>
                    </div>
                
                       <div class="col-6 example">
                          <input class="searchExter" matInput placeholder="Search Title" (keyup)="filter($event)">
                          <button type="button" class="searchButton" (click)="search()"><i class="fa fa-search" style="font-size: 28px;"></i></button>
                    </div>
                  </div>
                
                  <div class="row">
            <div class="col-md-12">
                     <div class="tableBody">
              <div class="table-responsive">
                <table class="table">
               
               
                      <thead class="facultyTable">
                        <tr>
                          <td colspan="7" allign="left" class="danger "
                            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                            <h1 class="announcementHeadingMain">Choose IIC Institutes</h1>
                            <p style="text-align:center; padding-top: 5px; font-size: 18px; font-weight: bold;">Please shortlist 2 IIC. Prescribed Activity will be enabled shortly.</p>
                          </td>
                        </tr>
                        <tr>
                          <th width="5%">S.No.</th>
                          <th width="10%">IIC ID</th>
                          <th width="20%">Institute Name</th>
                          <th width="25%">Address</th>
                          <th width="20%">District</th>
                          <th width="5%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let activity of myActivity;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{activity?.userId}}</td>
                    <td>{{activity?.instituteName}}</td>
                    <td>{{activity?.instituteAddress}}
                      <!-- <br/>{{activity?.district}}<br/>{{activity?.pincode}} -->
                    </td>
                    <td>{{activity?.district}}</td>
                    <td>
                      <div *ngIf='activity?.join_status==0'>
                        <p *ngIf='activity?.approval_status==0'>
                          <button mat-button  (click)="onSubmitClick(activity, '0')" style="
                                background-color: green; color: white;">
                            Choose as a Mentor
                          </button>
                        </p>
                
                        <p *ngIf='activity?.approval_status>0' style="padding-top: 10px;">
                          <button mat-button (click)="onSubmitClick(activity, '1')" style="
                                background-color: red; color: white;">
                            Remove 
                          </button>
                        </p>
                
                        <!-- <p *ngIf='activity?.approval_status>0'>
                          <button mat-button  (click)="onSubmitClick(activity, '2')" style="
                                background-color: green; color: white;">
                            Send Reminder Email
                          </button>
                        </p> -->
      
                    </div>
                
                    <div *ngIf='activity?.join_status==1' class="alert alert-success" style="text-align: center; padding: 5px 10px !important; margin-top: 10px; color: #155724;
                    background-color: #d4edda; border-color: #c3e6cb;" role="alert">
                      Joined
                    </div>
                
                    </td>
                    </tr>
                
                    <tr *ngIf="total_pages==0"><td colspan="7" style=" font-size:18px; text-align: center; color: #721c24;
                      background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                      There is no Institutes found.
                    </td></tr>
                
                
                
                      </tbody>
              
                    </table>
              </div>
              </div>
              </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
                        [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                      </mat-paginator>
                    </div>
                  </div>
               
                
            
      </mat-card>
      </div>


  <!-- </mat-tab>
  
 <mat-tab >
      <ng-template mat-tab-label>
        Manage Activities
      </ng-template> -->
      <div style="margin-top: 30px;" >
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="alert alert-danger" style="margin-top:10px; width:100%;"  *ngIf="showStatus1==1">
              Please shortlist 3 ATL Schools and once they will accept the invitation then Prescribed Activity will be enabled.
            </div>
            <div style="margin-top:20px;" *ngIf="showStatus2==1">
              <app-atal-activity-proposal></app-atal-activity-proposal>
            </div> -->
			<div class="col-md-12">
            <div style="margin-top:20px;">
                <app-atl-mentee-activity></app-atl-mentee-activity>            
            </div>
			</div>
          </div>
        </div>
      </div>
  <!-- </mat-tab>
  

  
  </mat-tab-group> -->
  
</div>  
  
  
  
  
  
  
  
