import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteSuggestionService } from 'src/app/Services/institute-suggestion.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reply-dialog',
  templateUrl: './reply-dialog.component.html',
  styleUrls: ['./reply-dialog.component.css'],
})
export class ReplyDialogComponent implements OnInit {
  replyForm: FormGroup;

  suggestion;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private instituteSuggestionService: InstituteSuggestionService,
    public dialogRef: MatDialogRef<ReplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.suggestion = data['Id'];
  }

  ngOnInit(): void {
    this.replyForm = this.formBuilder.group({
      subject: [null, Validators.required],
      message: [null, Validators.required],
    });

    this.replyForm.controls['subject'].setValue(this.suggestion['subject']);
  }

  onSubmit(values) {
    values['Id'] = this.suggestion.id;
    this.instituteSuggestionService
      .submitReply(values)
      .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      if (data['response_code'] == '0') {
        this.toastr.error(data['response_message']);
      } else {
        this.toastr.error('Some error occured.');
      }
    }
  }

  close() {
    this.dialogRef.close();
  }
}
