import { Component, OnInit, Inject } from '@angular/core';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-institute-prototype-submission-dialog',
  templateUrl: './institute-prototype-submission-dialog.component.html',
  styleUrls: ['./institute-prototype-submission-dialog.component.css']
})
export class InstitutePrototypeSubmissionDialogComponent implements OnInit {

  id: string;
  formData: any;

  public prototypeForm: FormGroup;
  gender: string;
  poc_prototype: string;
  showPrototypeScore: boolean = false;
  constructor(private innovationService: InstituteInnovationContestService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstitutePrototypeSubmissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // this.id = "45860";
    this.id = this.data['id'];
    this.innovationService.getPrototypeDetails(this.id).subscribe(
      data => this.handleResponse(data)
    );

    this.prototypeForm = this.fb.group({
      'score': [null, Validators.compose([Validators.required, Validators.max(50), Validators.min(2)])],
      'comment': [null, Validators.required],
      'status': [null, Validators.required],
    })
  }

  handleResponse(data) {
    this.formData = data['data']['0']
    if (this.formData['mentor_gender'] == 1) {
      this.gender = "Male";
    } else {
      this.gender = "Female";
    }

    if (this.formData['poc'] == '' && this.formData['prototype'] == '') {
      this.poc_prototype = "Idea";
    } else if (this.formData['poc'] == null && this.formData['prototype'] == null) {
      this.poc_prototype = "Idea";
    } else if (this.formData['poc'] == "idea") {
      this.poc_prototype = "Idea to Advanced";
    } else if (this.formData['prototype'] == "idea") {
      this.poc_prototype = "Prototype to Advanced";
    } else if ((this.formData['poc'] != '' || this.formData['poc'] != null) && this.formData['prototype'] == null) {
      this.poc_prototype = "POC";
    } else if ((this.formData['prototype'] != '' || this.formData['prototype'] != null) && this.formData['poc'] == null) {
      this.poc_prototype = "Prototype";
    } else if (this.formData['poc'] == "New") {
      this.poc_prototype = "POC";
    } else if (this.formData['prototype'] == "New") {
      this.poc_prototype = "Prototype";
    }

    if (this.formData['proto_status'] == "Disapproved" || this.formData['proto_status'] == "Approved") {
      this.showPrototypeScore = true;
    } else {
      this.showPrototypeScore = false;
    }
  }

  onSubmit(values) {

    values['id'] = this.formData['id'];
    values['type'] = "Prototype";

    this.innovationService.submitPrototypeSubmission(values).subscribe(
      data => this.handleData(data)
    );
  }

  handleData(data) {
    if (data['status'] == "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 46) return true;

    return false;
  }

}
