 <!--title-->
   <title>

    India First Leadership Talk Series | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>


<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Leadership Talk</a>
      </li>
      <li class="aht-breadcrumbs__item">
       Upcoming Episode
      </li>
    </ul>
  </div>
<section class="bggrey2">
        <div class="container">
          <div class="row">

    <div class="col-md-12">

            <div class="titleheadertop">

              <h1 class="inner-theme-heading">India First Leadership Talk Series</h1>

            </div>

        </div>

    </div>

        <div class="row" style="margin-top: 30px;">
          <div class="col-md-12">
              <div>
                <div class='tabs maintabing1'>
                  <div class='tab-buttons'>
                    <!--<span class='content1'>Upcoming Episode</span>-->
                    <span class='content2 active'>Upcoming Episodes</span>
                  </div>
                </div>
            </div>
        </div>
    </div>


  <div class='tab-content'>

   <div class='content2'>
     <div class="row">
	 
        <div class="col-md-8 col-md-offset-2">
            <div class="upcaming">
                    <img src="{{imageBasePath}}assets/wp_images/leadership-talk-09-6-20.jpg">

                       <div class="upcamingbox">
                          <!--<h3>How to deal with failure </h3>-->
                          <p>Shri R Subrahmanyam,IAS and Secretary, Department of Social Justice & Empowerment</p>
						  <p>Date: 13th June 2020</p>
						  <p>Time: 1.00 PM</p>
						  <p>TWITTER LIVE AT: @abhayjere</p>

                            <div class="upcamingboxvideo">
                        <ul>
                            <!--<li><a href="https://www.youtube.com/watch?v=D9PIA53C-7I"><i class="fa fa-youtube-play"></i> Watch Videos</a></li>
                            <li><a href="#"><i class="fa fa-photo"></i> Photos</a></li>
                            <li><a href="#"><i class='fas fa-award'></i> Winners</a></li>-->
                        </ul>
                    </div>

                       </div>


                </div>
             </div>
			 

			 

         

             


          </div>

				
         
                    </div>
                </div>


                </div>
         
</section>


<!-- Footer -->
<app-inner-footer></app-inner-footer>