import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JarwisService } from 'src/app/Services/jarwis.service';

@Component({
  selector: 'app-online-session-navbar',
  templateUrl: './online-session-navbar.component.html',
  styleUrls: ['./online-session-navbar.component.css']
})
export class OnlineSessionNavbarComponent implements OnInit {

  constructor(   private jarwisService: JarwisService,
    private router: Router,) { }

  ngOnInit(): void {
  }
  logout() {
    this.jarwisService.remove();
    this.router.navigateByUrl('/onlinetraining-for-IA');
  }
}
