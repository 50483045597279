<div class="container-fluid">
  <!-- List starts -->
  <div class="row" color="primary">
    <div class="col-md-12">
      <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">Action Plan & Scheduled Activities</h2>
    </div>
  </div>

  <mat-tab-group class="matGroupMenteeActivity">
    <mat-tab label="Orientation Sessions">
	    <div class="row">
        <div class="col-md-12">
          <div class="tableBody">
            <div class="table-responsive">
			        <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <td colspan="8" allign="left" class="danger "
                      style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                      <h1 class="announcementHeadingMain">Orientation Sessions</h1>
                    </td>
                  </tr>
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">Publish Date</th>
                    <th width="10%">Mode of Session</th>
                    <th width="20%">Quarter</th>
                    <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
                    <th width="20%">Session Details</th>
                    <th width="15%">Co-Ordinator</th>
                    <th width="5%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let activity of schedules;let i = index">
                    <td>{{i+1}}</td>
                    <td>      
                      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
                      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
                      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
                    </td>
                    <td>{{activity?.session_mode}}</td>
                    <td>{{activity?.quarter}}</td>
                    <!-- <td>{{activity?.activity_type}}</td> -->
                    <td>{{activity?.title}}<br/>
                      {{activity?.date}} @ {{activity?.time}}
                      <br/>
                      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
                      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
                    </td>
                    <td>{{activity?.spoc_name}}</td>
                    <td>
                      <button mat-button routerLink="/institute/atl-activity-report/1/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 0">
                        Submit Report
                      </button>
                      <button mat-button routerLink="/institute/atl-activity-report/1/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 1">
                        Submitted
                      </button>

                      <!-- <button mat-button (click)="onSubmitClick(activity, '1')" style="
                                background-color: red; color: white;">
                            Remove
                          </button> -->
                    </td>
                  </tr>
                  <tr *ngIf="schedules.length==0">
                    <td colspan="8" style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;"> There is no activity added yet. </td>
                  </tr>
                </tbody>
              </table>
	          </div>
	        </div>
	      </div>
	    </div>
    </mat-tab>
    <!-- Tab Ends  -->
    <mat-tab label="Trainings & Bootcamps">
	    <div class="row" >
		    <div class="col-md-12">
		      <div class="tableBody">
		        <div class="table-responsive">
			        <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <td colspan="8" allign="left" class="danger "
                      style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                      <h1 class="announcementHeadingMain">Trainings & Bootcamps</h1>
                    </td>
                  </tr>
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">Publish Date</th>
                    <th width="10%">Mode of Session</th>
                    <th width="20%">Quarter</th>
                    <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
                    <th width="20%">Session Details</th>
                    <th width="15%">Co-Ordinator</th>
                    <th width="5%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let activity of schedules2;let i = index">
                    <td>{{i+1}}</td>
                    <td>      
                      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
                      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
                      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
                    </td>
                    <td>{{activity?.session_mode}}</td>
                    <td>{{activity?.quarter}}</td>
                    <!-- <td>{{activity?.activity_type}}</td> -->
                    <td>{{activity?.title}}<br/>
                      {{activity?.date}} @ {{activity?.time}}
                      <br/>
                      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
                      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
                    </td>
                    <td>{{activity?.spoc_name}}</td>
                    <td>
                      <button mat-button routerLink="/institute/atl-activity-report/2/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 0">
                        Submit Report
                      </button>
                      <button mat-button routerLink="/institute/atl-activity-report/2/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 1">
                        Submitted
                      </button>

                      <!-- <button mat-button (click)="onSubmitClick(activity, '1')" style="
                                background-color: red; color: white;">
                            Remove
                          </button> -->

                    </td>
                  </tr>
                  <tr *ngIf="schedules2.length==0">
                    <td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
                    background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                    There is no activity added yet.
                    </td>
                  </tr>
                </tbody>
              </table>
	          </div>
	        </div>
	      </div>
	    </div>
    </mat-tab>
    <!-- Tab Ends  -->
    <mat-tab label="Mentor-Mentee Meets">
      <div class="row">
		    <div class="col-md-12">
			    <div class="tableBody">
					  <div class="table-responsive">
						  <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <td colspan="8" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                      <h1 class="announcementHeadingMain">Mentor-Mentee Meets</h1>
                    </td>
                  </tr>
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">Publish Date</th>
                    <th width="10%">Mode of Session</th>
                    <th width="20%">Quarter</th>
                    <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
                    <th width="20%">Session Details</th>
                    <th width="15%">Co-Ordinator</th>
                    <th width="5%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let activity of schedules3;let i = index">
                    <td>{{i+1}}</td>
                    <td>      
                      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
                      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
                      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
                    </td>
                    <td>{{activity?.session_mode}}</td>
                    <td>{{activity?.quarter}}</td>
                    <!-- <td>{{activity?.activity_type}}</td> -->
                    <td>{{activity?.title}}<br/>
                      {{activity?.date}} @ {{activity?.time}}
                      <br/>
                      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
                      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
                    </td>
                    <td>{{activity?.spoc_name}}</td>
                    <td>
                      <button mat-button routerLink="/institute/atl-activity-report/3/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 0">
                        Submit Report
                      </button>
                      <button mat-button routerLink="/institute/atl-activity-report/3/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 1">
                        Submitted
                      </button>

                      <!-- <button mat-button (click)="onSubmitClick(activity, '1')" style=" background-color: red; color: white;">
                        Remove
                      </button> -->
                    </td>
                  </tr>
                  <tr *ngIf="schedules3.length==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
                    background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                    There is no activity added yet.
                    </td>
                  </tr>
                </tbody>
              </table>
		        </div>
		      </div>
		    </div>
		  </div>
    </mat-tab>
    <!-- Tab Ends  -->
    <mat-tab label="Exposure Visits">
	    <div class="row">
		    <div class="col-md-12">
          <div class="tableBody">
					  <div class="table-responsive">
						  <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <td colspan="8" allign="left" class="danger "
                      style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                      <h1 class="announcementHeadingMain">Exposure Visits</h1>
                    </td>
                  </tr>
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">Publish Date</th>
                    <th width="10%">Mode of Session</th>
                    <th width="20%">Quarter</th>
                    <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
                    <th width="20%">Session Details</th>
                    <th width="15%">Co-Ordinator</th>
                    <th width="5%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let activity of schedules4;let i = index">
                    <td>{{i+1}}</td>
                    <td>      
                      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
                      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
                      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
                    </td>
                    <td>{{activity?.session_mode}}</td>
                    <td>{{activity?.quarter}}</td>
                    <!-- <td>{{activity?.activity_type}}</td> -->
                    <td>{{activity?.title}}<br/>
                      {{activity?.date}} @ {{activity?.time}}
                      <br/>
                      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
                      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
                    </td>
                    <td>{{activity?.spoc_name}}</td>
                    <td>
                      <button mat-button routerLink="/institute/atl-activity-report/4/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 0">
                        Submit Report
                      </button>
                      <button mat-button routerLink="/institute/atl-activity-report/4/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 1">
                        Submitted
                      </button>

                      <!-- <button mat-button (click)="onSubmitClick(activity, '1')" style="
                                background-color: red; color: white;">
                            Remove
                          </button> -->

                    </td>
                  </tr>
                  <tr *ngIf="schedules4.length==0">
                    <td colspan="8" style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                    There is no activity added yet.
                    </td>
                  </tr>
                </tbody>
              </table>
	          </div>
	        </div>
	      </div>
	    </div>
    </mat-tab>

    <!-- Tab Ends  -->

    <mat-tab label="Join IIC Activities">
      <div class="row">
        <div class="col-md-12">
          <div class="tableBody">
            <!-- <button mat-raised-button (click)="openDialog('global', 5)" color="primary">Schedule Invite</button> -->
            <div class="table-responsive">
              <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <td colspan="8" allign="left" class="danger "
                      style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                      <h1 class="announcementHeadingMain">Join IIC Activities</h1>
                    </td>
                  </tr>
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">Publish Date</th>
                    <th width="10%">Mode of Session</th>
                    <th width="20%">Quarter</th>
                    <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
                    <th width="20%">Session Details</th>
                    <th width="15%">Co-Ordinator</th>
                    <th width="5%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let activity of schedules5;let i = index">
                    <td>{{i+1}}</td>
                    <td>      
                      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
                      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
                      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
                    </td>
                    <td>{{activity?.session_mode}}</td>
                    <td>{{activity?.quarter}}</td>
                    <td>{{activity?.title}}<br/>
                      {{activity?.date}} @ {{activity?.time}}
                      <br/>
                      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
                      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
                    </td>
                    <td>{{activity?.spoc_name}}</td>
                    <td>
                      <button mat-button routerLink="/institute/atl-activity-report/5/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 0">
                        Submit Report
                      </button>
                      <button mat-button routerLink="/institute/atl-activity-report/5/{{ activity?.id }}" style="background-color: green; color: white;" *ngIf="activity?.isReportSubmitted == 1">
                        Submitted
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="schedules4.length==0">
                    <td colspan="8" style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                      There is no activity added yet.
                    </td>
                  </tr>  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Manage ATL Activities" style="overflow: hidden;">
      <div class="row">
        <div class="col-md-12">
          <button mat-raised-button (click)="openDialog('global', 8)" color="primary">Add ATL Activity</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>