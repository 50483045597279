<div class="container" *ngIf="this.showmenteeTable==1">
    <form [formGroup]="form2" (ngSubmit)="onSubmit(form2.value)" >
    <div class="row" style=" margin-bottom: 20px; ">
      <div fxFlex="40" class="m-2">
          <mat-label style="margin-left: 28px; margin-right: 102px;">Preference Dropdown from your institute: </mat-label>
        <mat-form-field class="example-full-width nominateMentorBox" appearance="outline">
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <mat-label>Select Preference</mat-label>

              <mat-select formControlName="prefrence" [(ngModel)]="preferenceModel" placeholder="Select Preference" required>
                <mat-option value="1">Mentors within state</mat-option>
                <mat-option value="2">Mentors within zone</mat-option>
                <mat-option value="3">Mentors in other zone</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="40" class="m-2">
          <mat-label style="margin-left: 28px; margin-right: 102px;">Funded By: </mat-label>
        <mat-form-field class="example-full-width nominateMentorBox" appearance="outline">
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <mat-label>Select Funded By</mat-label>

              <mat-select formControlName="funded_by" placeholder="Select Funded By" required>
                <mat-option value="all">All</mat-option>
                <mat-option value="MIC">MIC</mat-option>
                <mat-option value="Volunteer">Volunteer</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="20" class="m-2">   <button style="
        margin-left: 193px;
    " mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='form2.invalid'>Submit</button></div>
    </div>
</form>

    <!-- List starts -->

     <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
           Mentor Details</h2>
    </div>

    <div class="row" *ngIf="status==0" color="primary">
      No Data Found.
    </div>


    <div *ngIf="status==1" class="">
        <div class="">
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <th scope="col" style="width: 5%;">S.No.</th>
                        <th scope="col"> Institute Name</th>
                        <th scope="col" style="width: 16%;"> Name</th>
                        <th scope="col" style="width: 10%;"> Email</th>
                        <th scope="col" style="width: 10%;"> Contact</th>
                        <th scope="col" style="width: 10%;"> State</th>
                        <th scope="col" style="width: 11%;"> Zone</th>
                        <th scope="col" style="width: 11%;"> Mentor Type</th>
                        <th scope="col"> Action</th>

                        <!-- <th scope="col">Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nomination of tableData; index as i" >
                        <td>{{i+1}}</td>
                        <td >{{nomination?.instituteName}}</td>
                        <td >{{nomination?.name}}</td>
                        <td >{{nomination?.email}}</td>
                        <td >{{nomination?.mobile}}</td>
                        <td >{{nomination?.stateName}}</td>

                        <td >{{nomination?.zone}}</td>
                        <td >{{nomination?.funded_by}}</td>
                        <td>	
                            <button *ngIf="nomination?.totalcount==0 && isDisplay" mat-button  (click)="onSubmitClick(nomination)" style="background-color: green; color: white;">Select as Mentor</button>
                            <button mat-button disabled *ngIf="nomination?.totalcount==1" style="background-color: gray; color: white;">Select as Mentor</button>
                        </td>
                    </tr>
                    <tr *ngIf="tableData?.length==0" col-span="5">
                        <h3 style="text-align: center">No Data Found</h3>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- List ends -->






    <!-- List  22222222 starts -->

    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
           Selected Mentor Details</h2>
    </div>


    <div class="">
        <div class="">
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">Institute Name</th>

                        <!-- <th scope="col"> Name</th>
                        <th scope="col"> Email</th>
                        <th scope="col"> Contact</th> -->
                        <th scope="col"> State</th>

                        <th scope="col"> Zone</th>
                        <th scope="col"> Preference</th>
                        <th scope="col"> Acceptance</th>
                         <th scope="col"> Action</th>
                        <!--<th scope="col"> Action</th> -->

                        <!-- <th scope="col">Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nomination of submittedData; index as i" >
                        <td>{{i+1}}</td>
                        <td >{{nomination?.instituteName}}</td>
                        <td >{{nomination?.stateName}}</td>

                        <!-- <td >{{nomination?.name}}</td>
                        <td >{{nomination?.email}}</td>
                        <td >{{nomination?.mobile}}</td> -->
                        <td >{{nomination?.zone}}</td>
                        <td >{{nomination?.prefrence}}</td>

                        <td *ngIf="nomination?.accept_status==1">Accepted by Mentor</td>
                        <td *ngIf="nomination?.accept_status==0">No Action Taken</td>

                        <td *ngIf="nomination?.accept_status==1">
                          <p style="margin-bottom: 5px;">{{nomination?.name}}</p>
                          <p style="margin-bottom: 5px;">{{nomination?.email}}</p>
                          <p style="margin-bottom: 5px;">{{nomination?.mobile}}</p>
                          <p style="margin-bottom: 5px;">{{nomination?.designation}}</p>

                        </td>
                        <td *ngIf="nomination?.accept_status==0">

                          <button mat-raised-button (click)="onDeleteClick(nomination?.main_id)"   color="warn">
                          <mat-icon>delete</mat-icon>
                          Delete</button>

                        </td>
                        <!-- <td>	
                            <button mat-button  (click)="onSubmitClick(nomination)" style="
							background-color: rgb(188, 214, 188); color: white;">Submit</button>
                        </td> -->
                    </tr>
                    <tr *ngIf="submittedData?.length== 0" col="5">
                        <h3 style="text-align: center">No Data Found</h3>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- List ends -->






</div>




<!-- Mentor Table

</Table> -->




<div class="container" *ngIf="this.showmentorTable==1">
    <!-- <form [formGroup]="form2" (ngSubmit)="onSubmit(form2.value)">
        <div class="row" style="margin-bottom: 20px;">
          <div fxFlex="50" class="m-2">
              <mat-label style="margin-left: 28px;margin-right: 102px;">Preference Dropdown from your institute: </mat-label>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Select Preference</mat-label>
                  <mat-select formControlName="prefrence" [(ngModel)]="preferenceModel" placeholder="Select Preference" required>
                    <mat-option value="1">Preference 1</mat-option>
                    <mat-option value="2">Preference 2</mat-option>
                    <mat-option value="3">Preference 3</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="50" class="m-2">   <button style="
            margin-left: 193px;
        " mat-raised-button color='primary' class="btn btn-success float-right"
            [disabled]='form2.invalid'>Submit</button></div>
        </div>
    </form> -->

        <!-- List starts -->

        <div class="row" color="primary">
            <h2 class="ss"
                style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
               Mentee Details</h2>
        </div>

        <div class="">
            <div class="">
                <table class="table table-hover">
                    <thead class="facultyTable">
                        <tr>
                            <th scope="col" width="10%">S.No.</th>
                            <th scope="col" width="30%"> Mentee Institute</th>
                            <th scope="col" width="15%"> State</th>
                            <!-- <th scope="col"> Contact</th> -->
                            <th scope="col" width="15%"> Zone</th>
                            <th scope="col"> Action</th>

                            <!-- <th scope="col">Action</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let nomination of menteeDetails; index as i" >
                            <td>{{i+1}}</td>
                            <td *ngIf="nomination?.accept_status==1"><a href="institute/institute-mm-dashboard/{{nomination?.mentee_userId}}">{{nomination?.instituteName}}</a></td>
                            <td *ngIf="nomination?.accept_status==0">{{nomination?.instituteName}}</td>
                            <td >{{nomination?.state}}</td>
                            <!-- <td >{{nomination?.mobile}}</td> -->
                            <td >{{nomination?.zone}}</td>


                            <td *ngIf="nomination?.accept_status==1">

                              <!-- <button mat-raised-button (click)="onDeleteClick1(nomination?.main_id)"   color="warn">
                              <mat-icon>delete</mat-icon>
                              Accepted by Me/Remove to choose other</button> -->

                              <div class="rws-show" *ngIf="nomination?.accept_status==1">
                                <p style="margin-bottom: 5px;">{{nomination?.mentee_name}}</p>
                                <p style="margin-bottom: 5px;">{{nomination?.mentee_email}}</p>
                                <p style="margin-bottom: 5px;">{{nomination?.mentee_phone}}</p>
                                <p style="margin-bottom: 5px;">{{nomination?.mentee_designation}}</p>
                              </div>

                            </td>
                            <td *ngIf="nomination?.accept_status==0">

                              <button *ngIf="nomination?.mentee_accepted==1" disabled  mat-button  style="
                                background-color: gray; color: white;
                            ">
    Accepted by other Mentor							</button>


    <button *ngIf="nomination?.mentee_accepted==0"  mat-button  (click)="onAcceptClick(nomination)" style="
    background-color: green; color: white;
">
Choose as Mentee							</button>

</td>
                        <!-- <td *ngIf="fundby=='MIC'">
                          <div class="rws-show" *ngIf="nomination?.accept_status==1">
                            <p style="margin-bottom: 5px;">{{nomination?.mentee_name}}</p>
                            <p style="margin-bottom: 5px;">{{nomination?.mentee_email}}</p>
                            <p style="margin-bottom: 5px;">{{nomination?.mentee_phone}}</p>
                            <p style="margin-bottom: 5px;">{{nomination?.mentee_designation}}</p>
                          </div>
                        </td> -->
                        </tr>
                        <tr *ngIf="menteeDetails?.length== 0" col="5">
                            <h3 style="text-align: center">No Data Found</h3>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- List ends -->
    </div>


