import { Component, OnInit } from '@angular/core';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ToastrService } from 'ngx-toastr';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-institute-nomination',
  templateUrl: './institute-nomination.component.html',
  styleUrls: ['./institute-nomination.component.css']
})
export class InstituteNominationComponent implements OnInit {

  checkFlag: boolean;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  nomination = [];
  nominationArray = [];
  acceptedNomination = [];
  count: any;
  frontCurrentPageNo: number = 0;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;

  constructor(private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getNomination();

    this.innovationService.getNominationCount().subscribe(
      (data) => this.handleCount(data)
    );

    this.innovationService.getNominatedPrototype().subscribe(
      data => this.handleData(data)
    )

  }

  handleData(data) {

    this.acceptedNomination = [];

    data['data'].forEach((element) => {
      this.acceptedNomination.push(element);
    });
  }

  handleCount(data) {
    this.count = data['data']['0']['remaining_nominated_count'];

  }

  onClick(data, event) {


    if (event['checked'] == true) {
      this.nomination.push(data);
    } else if (event['checked'] == false) {
      this.nomination.forEach((element, index) => {
        if (element == data) {
          this.nomination.splice(index, 1)
        }
      });
    }


  }

  addPrototype() {

    if (this.nomination.length <= this.count) {
      this.innovationService.submitPrototype(this.nomination).subscribe(
        data => {
          if (data['status'] == '1') {
            this.toastr.success(data['message']);
            this.ngOnInit();
          } else {
            this.toastr.error(data['msg']);
          }
        }
      )
    } else {
      this.toastr.error("Maximum " + this.count + " Prototypes can be selected !");
    }
  }

  getNomination() {
    this.innovationService.getNomination(this.currentPageNo,this.per_page).subscribe(
      data => this.handleResponse(data)
    );
  }

  nextPage(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	this.per_page = e.pageSize;
    this.getNomination();
  }

  previousPage() {
    this.currentPageNo--;
    this.getNomination();
  }


  handleResponse(data) {

    this.nominationArray = [];

    data['data'].forEach((element) => {
      this.nominationArray.push(element);
    });

this.total_pages = data.meta.pagination.total;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  withdraw(id) {

    this.innovationService.withdrawPrototype(id).subscribe(
      data => {
        if (data['status'] == "1") {
          this.toastr.success(data['msg']);
          this.ngOnInit();
        } else {
          this.toastr.error(data['msg'])
        }
      }
    )
  }
}
