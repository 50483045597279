import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-institute-atal-school',
  templateUrl: './institute-atal-school.component.html',
  styleUrls: ['./institute-atal-school.component.css'],
})
export class InstituteAtalSchoolComponent implements OnInit {
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  myActivity: InstitueMyActivity[] = [];
  reportsClosed: boolean = true;
  quarter_filter: string = '';
  activity_filter:string='';
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  title1 = "";
  searched: string;
  search_keyword: string;
  eventId: any;
  cityList: any;
  districtList: any;
  MyActivityannualReport: number = 0;
  actiontype: any;
  constructor(
    private activityService: InstituteActivityService,
    public dialog: MatDialog,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
  
    this.getMyActivityDetails(this.currentPageNo);

    this.activityService.getAtlCityDistrictList('all').subscribe(
      (data) => this.handleResponseList(data, 'all')
    );
	

  }

  handleResponseList(data,type) {
    if(type=="city")
    {
      this.cityList       = data['cities'];
    }
    else
    {
      this.cityList       = data['cities'];
      this.districtList   = data['districts'];
    }
  }

  onSubmitClick(data, type){
    this.activityService.mentorAtalSelection(data.id, type).subscribe(
      data => this.handleResponse2(data)
    );
  }

  handleResponse2(data){
    if(data['status']=='1'){
      alert(data['message']);
      window.location.href='/institute/atl-school-list';
    }else{
      this.toastr.error(data['message'])

    }
  }

  nextPage() {
    this.currentPageNo++;
    this.getMyActivityDetails(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getMyActivityDetails(this.currentPageNo);
  }

  getMyActivityDetails(pageNo) {
    this.activityService
      .getIicAtalSchoolList(this.per_page, pageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22')
      .subscribe((data) => this.handleResponse(data));
  }
  getMyActivityDetails1() {
    this.activityService
      .getIicAtalSchoolList(this.per_page, this.currentPageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22')
      .subscribe((data) => this.handleResponse(data));
  }
  nextPage1(e: any) {
    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    this.getMyActivityDetails1();
  }

  reviewRequest(data){
    this.eventId=data;

    this.activityService
    .submitReviewRequest(this.eventId)
    .subscribe((data) => this.reviewResponse(data));
  }

  reviewResponse(data) {
    if(data['status']=='1'){
      this.toastr.success(data['msg']);
      this.getMyActivityDetails(this.currentPageNo);
    }else{
      this.toastr.error(data['msg']);
    }
  }

  onDeleteClick(data){
    this.eventId=data['eventId'];

    if (confirm('Are you sure you want to delete?')) {
      // Save it!
    this.activityService
    .deleteMyActivity(this.eventId)
    .subscribe((data) => this.deleteResponse(data));
    } else {
      // Do nothing!
      console.log('not confirm');
    }
  }

  deleteResponse(data){
if(data['status']=='1'){
  this.toastr.success(data['msg']);
  this.getMyActivityDetails(this.currentPageNo);
}else{
  this.toastr.error(data['msg']);
}
  }

  quarterFilter() {
    this.currentPageNo = 1;
    this.getMyActivityDetails(this.currentPageNo);
  }

  activityFilter(){
    this.currentPageNo = 1;
    this.getMyActivityDetails(this.currentPageNo);
  }

  onDistrictChange(ob){

    let district = ob.value;

    if(district!="")
    {
      this.activityService.getAtlCityDistrictList(district).subscribe(
        (data) => this.handleResponseList(data,'city')
      );
    }
    else
    {
      this.activityService.getAtlCityDistrictList("all").subscribe(
        (data) => this.handleResponseList(data,'all'));
    }

  }

  downloadReport(endPoint, event_id) {
    this.activityService
      .getReportForDownload(endPoint, event_id)
      .subscribe((data) => this.downloadFile(data, endPoint + '.pdf'));
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }


  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searched = filterValue;
  }

  search() {
    this.search_keyword = this.searched;
    this.activityService.getIicAtalSchoolList(this.per_page, this.currentPageNo, this.quarter_filter, this.search_keyword,this.activity_filter,'2021-22').subscribe(
      data => this.handleResponse(data)
    )
  }

  handleResponse(data) {

    this.myActivity = data.data;
    this.total_pages = data.meta.pagination.total;
    this.MyActivityannualReport= data.meta.annualReport;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }


  }

  openDialog(row) {
    row.action = '';
    row.url = '';
    row.label = 'NA';
    // row.type = '';
    row.download_url = '';


    row.url = "monthly_report";
    row.download_url = "monthlyReportPdf";
    if (row.status == 'Submitted') {
      row.action = "edit";
      row.label = "Uploaded Successfully";
    }
    else if (row.status == 'Not Submitted') {
      row.action = "add";
      row.label = "Upload Report";
      row.download_url = "";
    } else if (row.status == 'Verified') {
      row.url = "";
      row.label = 'Report Verified';
    } else if (row.status == 'Disapproved') {
      row.url = "";
      row.label = 'Report Disapproved.';
    }

    const dialogRef = this.dialog.open(InstituteAtalSchoolDialog, {
      data: { activity: row },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }


}

export class InstitueMyActivity {
  id: number;
  title: string;
  description: string;
  userId: string;
  quarter: string;
  endDate: string;
  startDate: string;
  comments: string;
}

@Component({
  selector: 'app-institute-atal-school-dialog',
  templateUrl: 'institute-atal-school-dialog.html',
})
export class InstituteAtalSchoolDialog implements OnInit {
  id: any;
  activity: any;
  cityList:any;
  districtList:any;
  baseUrl = 'https://mic.gov.in/images/events/';

  constructor(
    private formBuilder: FormBuilder,
    private activityService: InstituteActivityService,
    public dialogRef: MatDialogRef<InstituteAtalSchoolDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.activity = this.data['activity'];


  }


  downloadReport(endPoint, event_id) {
    this.activityService
      .getReportForDownload(endPoint, event_id)
      .subscribe((data) => this.downloadFile(data, endPoint + '.pdf'));
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) { }




}
