<!--title-->
   <title>

   MHRD | Innovation Cell - Home
    </title>
	<link rel="stylesheet" href="/assets/css/bootstrap.min.css">
	<!-- <link rel="stylesheet" href="/assets/css/style.css" type="text/css"> -->
	<link rel="stylesheet" type="text/css" href="/assets/vendors/select2/select2.min.css">
<!--===============================================================================================-->
	<link rel="stylesheet" type="text/css" href="/assets/vendors/select2/main.css">
<!--===============================================================================================-->
<style>
.help-block{
	color:red;
}
.container-contact100 {
   
    background: linear-gradient(45deg, #c49f47, #abe7ed) !important;
}
.wrap-contact100 {
padding: 18px 10px 45px 25px !important;
}
.wrap-contact100 {
    width: 100% !important;
}
.wrapBorder{
	border-bottom: 2px solid #d9d9d9;
    padding-bottom: 13px;
    margin-bottom: 27px;
}
.label-input100 {
    font-size: 20px !important;
}
.input100 {
font-size: 14px !important;
}
.blinking{
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>


<div class="container-contact100">
		<div class="wrap-contact100">
		<div class="col-md-12">
		<span class="col-md-3"><img src="assets/images/ashok_icon.jpg" style="width:36%;float: left;"></span>
		<span class="col-md-3" style="text-align:center;"><img src="assets/images/MIC_LOGO.png" style="width:60%;"></span>
		<span class="col-md-3" style="text-align:right;"><img src="assets/images/ugc_logo.jpg" style="width:34%;"></span>
		<span class="col-md-3"><img src="assets/images/aicte_logo.png" style="width:24%;float: right;"></span>
		</div>
		<div class="col-md-12" style="text-align:center;margin-bottom:21px;margin-top:21px;">
		<span style="font-size:20px;color:rgb(191,49,26);font-weight:900;">Nominate a Sr. Faculty for NISP Implementation at Institute Level</span>
		<p style="color:red;">Closing date for current phase of nominations is 30 June 2020.</p>
		<p class="blinking"><span style="color:red;">Haven’t joined MHRD’s NISP campaign yet? </span> <span ><a style="color:blue;" href="/start-up-policy/startup_application">click here to join</a></span></p>
		</div>
			<form class="contact100-form validate-form" id="nominateFacultyForm" role="form" method="post">
				<div class="row">
                <div class="wrapBorder col-md-12 validate-input" data-validate = "Institute name is required">
<span class="label-input100">Institute, if Participated in NISP Campaign </span>
<select class="select2 select2-container select2-container--default" name="startup_aplication_ins" id="startup_aplication_ins" onchange="getAisheCode(this.value)" required>
<option value="">Select the Institute from List</option>
<option value="Other">Other</option>
<option value="C-35998">&#34;Bhabha Engineering Research Institute, Hoshangabad Road, Near Misrod, Bhopal-462026&#34;</option>
<option value="C-36016">&#34;Malwa Institute of Technology, Village Nipani, Bypass Road, Opp, D.P.S., Indore   &#34;</option>
<option value="C-8977">100007-SSBT&#39;S COLLEGE OF ENGINEERING & TECHNOLOGY, BAMBHORI.</option>
<option value="C-9027">100010-KCES&#39;S INSTITUTE OF MANAGEMENT & RESEARCH, JALGAON.</option>
<option value="C-8912">230032-PSGVPM&#39;S D.N.PATEL COLLEGE OF ENGINEERING, SHAHADA, DIST-NANDURBAR.</option>
<option value="C-15759">A G PATIL INSTITUTE OF TECHNOLOGY  18 2A2  PRATANAGER SOREGON ROAD</option>
<option value="S-488">A.P.GOVERNMENT INSTITUTE OF LEATHER TECHNOLOGY</option>
<option value="C-24992">A.V.C. College of Engineering</option>
<option value="C-10224">Aarupadai Veedu Institute of Technology</option>
<option value="C-10217">Aarupadai Veedu Medical College and Hospital</option>
<option value="C-46112">ABES ENGINEERING COLLEGE, GHAZIABAD</option>
<option value="C-46112">ABES ENGINEERING COLLEGE, GHAZIABAD</option>
<option value="C-1244">Academy for Technical and Management Excellence, MYSORE</option>
<option value="C-30036">Academy of Management and Information Technology, (AMIT), Khurda</option>
<option value="c-6516">Achariya College of Engineering Technology</option>
<option value="c-6516">Achariya College of Engineering Technology</option>
<option value="C-1319">Acharya Institute of Technology, BANGALORE</option>
<option value="C-1319">Acharya Institute of Technology, BANGALORE</option>
<option value="C-36156">Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Near manglya Square, Indore</option>
<option value="C-36237">Acropolis Institute of Technology & Research, Village Raukhedi, Khasra No. 144/3, Indore Bypass Road, Indore-453771</option>
<option value="C-36237">Acropolis Institute of Technology & Research, Village Raukhedi, Khasra No. 144/3, Indore Bypass Road, Indore-453771</option>
<option value="C-1440">ACS College of Engineering, BANGALORE</option>
<option value="55770">Adani institute of infrastructure engineering</option>
<option value="C-11745">Adi Shankara Institute of Engineering and Technology, Sankar Nagar, Mattoor,  Kalady- 683 547</option>
<option value="C-18060">ADITYA COLLEGE OF ENGINEERING & TECHNOLOGY</option>
<option value="C-17952">Aditya Engineering College, Aditya Nagar, ADB Raod, Surampalem, PIN-533437(CC-A9)</option>
<option value="C-48472">AHALIA SCHOOL OF MANAGEMENT, KOZHIPPARA</option>
<option value="C-46109">AJAY KUMAR GARG ENGINEERING COLLEGE, GHAZIABAD</option>
<option value="U-0885">Ajeenkya D Y Patil University</option>
<option value="S-14880">AKOLE TALUKA EDUCATION SOCIETYS FACULTY OF POLYTECHNIC</option>
<option value="C-41227">All India Shivaji Memorial Societys College of Engineering, Pune</option>
<option value="C-41410">All India Shri Shivaji Memorial Society&#39;s Institute of Information Technology, Pune 411001.</option>
<option value="U-0757">AMITY UNIVERSITY, RAIPUR</option>
<option value="U-0497">Amity University, Uttar Pradesh</option>
<option value="C-26080">Amjad Ali Khan College of Business Administration</option>
<option value="C-27065">Amrita College of Engineering and Technology</option>
<option value="C-46022">ANAND COLLEGE OF PHARMACY, AGRA</option>
<option value="C-16618">Anand Institute of Higher Technology</option>
<option value="C-24171">Anil Neerukonda Institute Of Technology & Sciences</option>
<option value="c-25004">Anjalai Ammal Mahalingam Engineering College</option>
<option value="C-45182">ANJUMAN-I-ISLAMS SCHOOL OF ENGINEERING AND TECHNOLOGY ATANJUMAN-I-ISLAM KALSEKAR TECHNICAL, MUMBAI</option>
<option value="U-0439">Anna University, Chennai</option>
<option value="C-26843">Annamacharya College of Pharmacy, Rajampet</option>
<option value="C-26843">Annamacharya College of Pharmacy, Rajampet</option>
<option value="C-11099">Annasaheb Dange College of Engineering and Technology,  ASHTA</option>
<option value="C-6541">Arignar anna government arts and sciences College Anna Govt. Arts College</option>
<option value="C-6541">Arignar Anna Govt. Arts College</option>
<option value="C-49129">ARJUN COLLEGE OF TECHNOLOGY</option>
<option value="S-1187">ARYA BHARATHI POLYTECHNIC TUMKUR</option>
<option value="C-30107">Aryan Institute of Engineering and Technology, (AIET), Barakuda, Khurda, Bhubaneswar</option>
<option value="S-14545">ASIAN BUSINESS SCHOOL</option>
<option value="S-14545">ASIAN BUSINESS SCHOOL</option>
<option value="C-17993">Avanthi Institute of Engineering Technology, Chirukupally(V), Bhogapuram(M), Near Tagarapuvalasa Bridge, PIN- 531 162 (CC-Q7)</option>
<option value="U-0444">Avinashilingam Institute for Home Science & Higher Education for Women, Coimbatore</option>
<option value="C-41062">Ayyan Thiruvalluvar College of Arts and Science</option>
<option value="C-48594">B I T SINDRI</option>
<option value="C-25175">B K Birla Institute of Engineering & Technology, Pilani</option>
<option value="C-1336">B M S Institute of technology, BANGALORE</option>
<option value="S-1195">B V V S POLYTECHNIC BAGALKOT</option>
<option value="C-6226">B.P. Poddar Institute of Management and Technology 115</option>
<option value="U-0445">B.S. Abdur Rahman Institute of Science & Technology</option>
<option value="U-0177">Baddi University of Emerging Sciences and Technology, Baddi (Makhnumajra), Solan</option>
<option value="C-25894">Badruka College PG Centre</option>
<option value="C-26897">Balaji  College of Pharmacy, Anantapur</option>
<option value="C-19874">Balaji Institute of Technology & Science</option>
<option value="C-1290">Ballari Institute of Technology & Management, BELLARY</option>
<option value="U-0500">Banaras Hindu University, Banaras</option>
<option value="U-0500">Banaras Hindu University, Banaras</option>
<option value="U-0500">Banaras Hindu University, Banaras</option>
<option value="C-1340">Bangalore Institute of Technology, BANGALORE-560 004</option>
<option value="C-18167">BAPATLA COLLEGE OF PHARMACY,  BAPATLA-522101(CC-10)</option>
<option value="C-18935">Bapurao Deshmukh Engineering College, Sewagram</option>
<option value="C-6251">BCDA College of Pharmacy and Technology 201</option>
<option value="c-6203">Bengal College of Engineering and Technology for Women 260</option>
<option value="C-6318">Bengal Institute of Technology and Management 163</option>
<option value="C-312">BHAGVAN MAHAVIR PHARMACY COLLEGE, SURAT 215 </option>
<option value="C-312">BHAGVAN MAHAVIR PHARMACY COLLEGE, SURAT 215 </option>
<option value="C-6142">Bharat Technology 208</option>
<option value="U-0446">Bharath Institute of Higher Education & Research</option>
<option value="C-6497">Bharathiyar College of Engineering and Technology</option>
<option value="U-0292">Bharati Vidyapeeth, Pune</option>
<option value="S-1708">BHIVRABAI SAWANT POLYTECHNIC WAGHOLI PUNE</option>
<option value="C-30155">Bhubaneswar College of Engineering, (BCE), Khordha</option>
<option value="U-0202">Birla Institute of Technology, Ranchi</option>
<option value="C-8344">BIT Extension Center Deoghar</option>
<option value="C-1262">BMS College of Engineering, BANGALORE</option>
<option value="C-33873">Bombay College of Pharmacy Kalina Santacruz (E) Mumbai - 400 098</option>
<option value="U-0846">BRAINWARE UNIVERSITY</option>
<option value="1-3516008541">BSA COLLEGE OF ENGINEERING & TECHNOLOGY</option>
<option value="C-46805">BUNDELKHAND INSTITUTE OF ENGINEERING AND TECHNOLOGY, JHANSI</option>
<option value="S-14546">BUNTS SANGHA&#39;S UMA KRISHNA SHETTY INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH</option>
<option value="c-48145">BVRIT Hyderabad</option>
<option value="C-25014">C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)</option>
<option value="C-1406">C M R Institute of Technology, BANGALORE</option>
<option value="S-1203">C P C (GOVT.) POLYTECHNIC MYSORE</option>
<option value="C-410">C.K.Shah Vijapurwala Institute of Management, Vadodra (SFI) 705</option>
<option value="U-0570">Calcutta University, Kolkata</option>
<option value="C-6197">Camellia School of Engineering  and Technology 238</option>
<option value="C-6197">Camellia School of Engineering  and Technology 238</option>
<option value="U-0158">Central University of Haryana, Mahendergarh</option>
<option value="U-0178">Central University of Himachal Pradesh, Dharmshala, Kangra</option>
<option value="U-0631">CENTRAL UNIVERSITY OF JAMMU</option>
<option value="U-0631">CENTRAL UNIVERSITY OF JAMMU</option>
<option value="C-29461">CGC College of Engineering, Landran</option>
<option value="C-49526">CGC TECHNICAL CAMPUS, FACULTY OF MANAGEMENT</option>
<option value="C-49526">CGC TECHNICAL CAMPUS, FACULTY OF MANAGEMENT</option>
<option value="C-23939">Ch.S.D. St. THERESAS (A) COLLEGE FOR WOMEN</option>
<option value="C-25622">Chaitanya Bharathi Institute of Technology</option>
<option value="C-39486">Chalapathi Institute of Pharmaceutical Sciences, Lam</option>
<option value="C-29359">Chandigarh College of  Engineering & Technology (Degree Wing), Sector-26</option>
<option value="c-10342">Chandigarh Engg College, Landran</option>
<option value="U-0747">CHANDIGARH UNIVERSITY</option>
<option value="U-0505">Chatrapati Sahuji Maharaj Kanpur University, Kanpur</option>
<option value="U-0505">Chatrapati Sahuji Maharaj Kanpur University, Kanpur</option>
<option value="C-41519">Chaudhary Atarsingh Yadav Memorial Education Trusts, Siddhant College of Management Studies,Pune</option>
<option value="U-0788">CHAUDHARY BANSI LAL UNIVERSITY</option>
<option value="U-0159">Chaudhary Charan Singh Haryana Agricultural University, Hisar</option>
<option value="C-39452">Chebrolu Hanumaiah College of Pharmaceutical Sciences</option>
<option value="c-16576">Chennai Institute of Technology</option>
<option value="C-16576">Chennai Institute of Technology</option>
<option value="U-0180">Chitkara University, Kalu Jhanda (Barotiwala), Solan</option>
<option value="U-0373">Chitkara University, Patiala</option>
<option value="U-0217">Christ University, Bangalore</option>
<option value="U-0909">Cluster University Srinagar</option>
<option value="C-19752">CM Engineering College</option>
<option value="C-19735">CMR College of Engineering & Technology</option>
<option value="C-19760">CMR Engineering College</option>
<option value="C-19837">CMR Institute of Technology</option>
<option value="C-19837">CMR Institute of Technology</option>
<option value="C-36942">Coimbatore Institute of Engineering and Technology</option>
<option value="C-36942">Coimbatore Institute of Engineering and Technology</option>
<option value="C-36969">Coimbatore Institute of Technology</option>
<option value="C-6270">College of Engineering and Management, Kolaghat 107</option>
<option value="C-41593">College of Engineering Pune, Wellesely Road, Shivajinagar, Pune - 411 005. </option>
<option value="C-24004">College of Engineering(A)</option>
<option value="C-11009">College of Engineering, KARAD</option>
<option value="C-43708">College of Engineering, Thiruvananthapuram</option>
<option value="C-35437">College of Nursing, Sangli</option>
<option value="C-37016">CSI College of Engineering</option>
<option value="C12200">csjm university</option>
<option value="C-51494">CSMSS Chh. Shahu College of Engineering</option>
<option value="U-0892">CT University, Ludhiana</option>
<option value="C-18376">Cummins College of Engineering for Women, Mauja</option>
<option value="C-18546">Dadasaheb Balpande College of Pharmacy, Besa road</option>
<option value="C-8753">Darbhanga College of Engineering</option>
<option value="U-0295">Datta Meghe Institute of Medical Sciences, Nagpur</option>
<option value="U-0295">Datta Meghe Institute of Medical Sciences, Nagpur</option>
<option value="C-10384">DAV Institute of Engineering and Technology, Jalandhar</option>
<option value="U-0507 ">Dayalbagh Educational Institute, Agra</option>
<option value="U-0507">Dayalbagh Educational Institute, Agra</option>
<option value="U-0507">Dayalbagh Educational Institute, Agra</option>
<option value="C-45307">DAYANANDA SAGAR ACADEMY OF TECHNOLOGY AND MANAGEMENT, BANGALORE</option>
<option value="U-0296">Deccan College Postgraduate & Research Institute, Pune</option>
<option value="C-6361">Deen Dayal Upadhyaya College</option>
<option value="C-12830">DEI TECHNICAL COLLEGE</option>
<option value="C-18086">Devineni Venkata Ramana & Dr. Hima Sekhar MIC College of Technology, Kanchikacherla, PIN-521180(CC-H7)</option>
<option value="C-21268">Devsthali Vidhyapeeth College of Pharmacy</option>
<option value="C-46966">DEWAN V S INSTITUTE OF ENGINEERING AND TECHNOLOGY, MEERUT</option>
<option value="C-16615">Dhaanish Ahmed College of Engineering</option>
<option value="C-41485">Dhole Patil Education Societys Dhole Patil College of Engineering,Pune</option>
<option value="U-0774">DIT UNIVERSITY</option>
<option value="C-46324">DON BOSCO COLLEGE OF ENGINEERING, FATORDA, MARGAO</option>
<option value="C-28327">DPG Institute of Technology and Management</option>
<option value="C-48357">Dr D Y PATIL EDUCATIONAL ENTERPRISES CHARITABEL TRUST Dr D Y PATIL GROUP OF INSTITUTIONS, Dr D Y PATIL SCHOOL OF MANAGEMENT, Dr D Y PATIL KNOWLEDGE CITY, CHARHOLI</option>
<option value="C-46648">DR D Y PATIL EDUCATIONAL ENTERPRISES CHARITABLE TRUSTS DR D Y PATIL GROUP OF INSTITUTIONS DR D Y PATIL SCHOOL OF ENGINEERING, PUNE</option>
<option value="U-0393">Dr K N Modi University, Tonk</option>
<option value="C-36929">Dr N.G.P. Institute of Technology</option>
<option value="C-1291">Dr. Ambedkar Institute of Technology, BANGALORE</option>
<option value="C-30164">Dr. Ambedkar Memorial Institute of Information Technology and Management Science, Rourkela</option>
<option value="C-30164">Dr. Ambedkar Memorial Institute of Information Technology and Management Science, Rourkela</option>
<option value="U-0509">Dr. B. R. Ambedkar University, Agra</option>
<option value="U-0298">Dr. Babasaheb Ambedkar Marathwada University, Aurangabad</option>
<option value="U-0299">Dr. Babasaheb Ambedkar Technological University, Lonere</option>
<option value="U-0299">Dr. Babasaheb Ambedkar Technological University, Lonere</option>
<option value="C-49267">DR. BHIMRAO AMBEDKAR ENGINEERING COLLEGE OF INFORMATION TECHNOLOGY,BANDA</option>
<option value="U-0301">Dr. D. Y. Patil Biotechnology and Bioinformatics Institute, Dr. D.Y.Patil Vidyapeeth, Pune</option>
<option value="C-43239">Dr. D. Y. Patil College of Physiotherapy, Pune</option>
<option value="C-43243">Dr. D. Y. Patil Dental College & Hospital, Pune.</option>
<option value="C-42019">Dr. D. Y. Patil Institute of Management and Research, Pimpri, pune</option>
<option value="C-13799">Dr. D.Y. Patil Homoeopathy College, Pimpri, Pune</option>
<option value="C-41223">Dr. D.Y. Patil Unitech Society&#39;s Dr. D. Y. Patil Institute of Pharmaceutical Science and Research, Pimpri, Pune 411018</option>
<option value="C-42227">Dr. D.Y. Patil Vidya Pratishthan Society&#39;s Dr. D. Y. Patil Institute of Technology, Pimpri, Pune 411018</option>
<option value="C-42227">Dr. D.Y. Patil Vidya Pratishthan Society&#39;s Dr. D. Y. Patil Institute of Technology, Pimpri, Pune 411018</option>
<option value="C-41755">Dr. D.Y.Patil Institute of Engineering, Management and Research, Sector no 29, PCNTDA (Ravet), Akurdi, Pune 411044</option>
<option value="U-0301">Dr. D.Y.Patil Vidyapeeth, Pune</option>
<option value="U-0301">Dr. D.Y.Patil Vidyapeeth, Pune</option>
<option value="C-1379">Dr. Timmaiah  Institute of Technology,  K G F</option>
<option value="U-0938">Dr. Vishwanath Karad MIT World Peace University</option>
<option value="U-0181">Dr. Y.S. Parmar University of Horticulture and Forestry, Solan</option>
<option value="C-46774">Dr.A.P.J. ABDUL KALAM TECHNICAL UNIVERSITY ,LUCKNOW</option>
<option value="C-37050">Dr.Mahalingam College of Engineering and Technology</option>
<option value="C-51374">DUMKA ENGINEERING COLLEGE (ESTD. BY GOVT. OF JHARKHAND & RUN BY TECHNO INDIA UNDER PPP)</option>
<option value="S-13792">DURGAPUR POLYTECHNIC COLLEGE</option>
<option value="24758">DUVVURU RAMANAMMA WOMENS COLLEGE(AUTONOMOUS)</option>
<option value="C-24989">Edayathangudy G.S.Pillay Engineering College</option>
<option value="C-52868">ELITTE COLLEGE OF ENGINEERING 346</option>
<option value="C-47685">ENGINEERING COLLEGE, BANSWARA</option>
<option value="C-47685">ENGINEERING COLLEGE, BANSWARA</option>
<option value="C-25122">Engineering College,Ajmer</option>
<option value="S-88">ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA</option>
<option value="C-7987">ERANAD KNOWLEDGE CITY TECHNICAL CAMPUS, CHERUKULAM, MANJERI</option>
<option value="C-37073">Excel College of Engineering & Technology</option>
<option value="C-36930">Excel Engineering College</option>
<option value="C-42729">Faculty of Engineering, Teerthanker Mahaveer University </option>
<option value="C-46998">FEROZE GANDHI INSTITUTE OF ENGINEERING AND TECHNOLOGY</option>
<option value="C-27091">Francis Xavier Engineering College</option>
<option value="C-46239">G L Bajaj Institute of Technology and Management, Greater Noida</option>
<option value="C-41863">G. H. Raisoni Educational & Medical foundation Arts, Science and Commerce College, Pune</option>
<option value="U-0554">G.B. Pant Universtiy of Agriculture and Technology, Pantnagar</option>
<option value="C-18817">G.H. Raisoni College of Engineering ,Nagpur</option>
<option value="C-41879">G.H. Raisoni Education & Medical Foundations G.H. Raisoni Institute of Engineering & Technology,Pune</option>
<option value="C-16521">G.K.M. College of Engineering and Technology</option>
<option value="U-0513">G.L.A University, Mathura</option>
<option value="U-0513">G.L.A University, Mathura</option>
<option value="C-27009">G.Pullaiah College of Engineering & Technology, Kurnool</option>
<option value="S-16955">GAIGHATA GOVERNMENT POLYTECHNIC GOVERNMENT POLYTECHNIC</option>
<option value="C-46229">GALGOTIA S COLLEGE OF ENGINEERING AND TECHNOLOGY, GREATER NOIDA</option>
<option value="C-46229">GALGOTIA S COLLEGE OF ENGINEERING AND TECHNOLOGY, GREATER NOIDA</option>
<option value="U-0643">GALGOTIAS UNIVERSITY</option>
<option value="U-0643">GALGOTIAS UNIVERSITY</option>
<option value="C-30033">Gandhi Institute For Technology (GIFT), Khurda, Bhubaneswar</option>
<option value="U-0011">Gandhi Institute of Technology & Management (GITAM) University,</option>
<option value="U-0011">Gandhi Institute of Technology & Management (GITAM) University,</option>
<option value="C-49558">Ganga Global Institute of Teacher Education</option>
<option value="C-28433">Ganga Institute of Technology & Management</option>
<option value="C-58235">Ganpat University Institute of Computer Technology</option>
<option value="U-0918">Garden City University</option>
<option value="C-27016">GATES Institute of Technology,  Gooty</option>
<option value="C-43567">Gaya College of Engineering</option>
<option value="C-24008">Gayatri Vidya Parishad College for Degree & PG courses (A)</option>
<option value="C-17913">Gayatri Vidya Parishad College of Engineering (Autonomous), 530048(CC-13)</option>
<option value="C-10842">Geeta Engg. College</option>
<option value="C-27588">Geethanjali College of Pharmacy</option>
<option value="C-1419">Ghousia College of Engineering, RAMANAGARAM</option>
<option value="C-113">GIDC Rajju Shroff Rofel Institute of Management Studies-Vapi (SFI) 716 </option>
<option value="U-1071">GIET University</option>
<option value="C-17958">GMR Institute of Technology (GMRIT), GMR Nagar, Rajam, Srikakulam Dt.,-532127 (CC-34))</option>
<option value="C-27988">GND University College, Near Govt. College of Edu., Ladowali Rd.</option>
<option value="C-27988">GND University College, Near Govt. College of Edu., Ladowali Rd.</option>
<option value="S-376">GNIT COLLEGE OF MANAGEMENT</option>
<option value="C-30154">Gopal Krishna College of Engineering and Technology (GKCET), Jeypore</option>
<option value="C-56586">GOVERNMENT COLLEGE OF ENGINEERING</option>
<option value="C-34341">Government College of Engineering, Railway Station Road.</option>
<option value="C-423">GOVERNMENT ENGINEERING COLLEGE, PATAN 022</option>
<option value="C-135">GOVERNMENT ENGINEERING COLLEGE, VALSAD 019</option>
<option value="S-12172">GOVERNMENT GIRLS POLYTECHNIC, DEHRADUN</option>
<option value="S-15208">GOVERNMENT POLYTECHNIC BARGARH(CHITRAKOOT)</option>
<option value="S-12765">GOVERNMENT POLYTECHNIC COLLEGE KOTTUR, THENI</option>
<option value="S-14918">GOVERNMENT POLYTECHNIC COLLEGE, BAGIDORA</option>
<option value="S-16502">Government Polytechnic Dirang</option>
<option value="S-1270">GOVERNMENT POLYTECHNIC LINGASUGURU RAICHUR DISTRICT</option>
<option value="S-12197">GOVERNMENT POLYTECHNIC, VEEROKHAL</option>
<option value="C-217">Government Polytechnic,Dahod 630 </option>
<option value="S 968">Govt Polytechnic Meham</option>
<option value="S-1165">GOVT WOMEN POLYTECHNIC BOKARO</option>
<option value="S-1165">GOVT WOMEN POLYTECHNIC BOKARO</option>
<option value="C-6134">Govt. College of Engineering and Ceramic Technology 113</option>
<option value="C-22934">GOvt. Degree College, Reasi</option>
<option value="C-1314">Govt. Engineering College, KARAWAR</option>
<option value="C-13380">Govt. Mahavidyalaya, Bilaspur</option>
<option value="S-1508">GOVT. POLYTECHNIC COLLEGE PERUMBAVOOR</option>
<option value="C-13329">Govt. Raza (PG) College</option>
<option value="1341-2017">GSSS Institute of Engineering & Technology for Women</option>
<option value="C-6138">Gupta College of Technological Sciences 124</option>
<option value="U-0162">Guru  Jambheshwar University of Science and Technology, Hissar</option>
<option value="U-0162">Guru  Jambheshwar University of Science and Technology, Hissar</option>
<option value="C-48617">GURU GOBIND SINGH FOUNDATIONS GURU GOBIND SINGH COLLEGE OF ENGINEERING, & RESEARCH CENTRE, KHALSA EDUCATIONAL COMPLEX, GURU GOBIND SINGH MARG</option>
<option value="U-0099">Guru Gobind Singh Indraprastha University</option>
<option value="C-10381">Guru Nanak Dev Engineering College, Ludhiana</option>
<option value="C-19531">Guru Nanak Institute of Technology</option>
<option value="C-19515">Guru Nanak Institutions Technical Campus</option>
<option value="U-0556">Gurukul Kangri Vishwavidyalaya, Haridwar</option>
<option value="C-18146">GVVR INSTITUTE OF TECHNOLOGY, Vempa Road, Tundurru, Bhimavaram,  PIN- 534207.(CC-JH</option>
<option value="C-18146">GVVR INSTITUTE OF TECHNOLOGY, Vempa Road, Tundurru, Bhimavaram,  PIN- 534207.(CC-JH</option>
<option value="C-18146">GVVR INSTITUTE OF TECHNOLOGY, Vempa Road, Tundurru, Bhimavaram,  PIN- 534207.(CC-JH</option>
<option value="C-21302">Gyani Indra Singh Institute of Professional Studies</option>
<option value="C-51523">Gyanmanjari Institute of Technology</option>
<option value="U-0864">Harcourt Butler Technical University</option>
<option value="U-0178	">HIMACHAL PRADESH CENTRAL, UNIVERSITY</option>
<option value="C-6594">Himalayan Pharmacy Institute, Majhitar</option>
<option value="C-28494">Hindu Instt. of Management & Technology</option>
<option value="U-0454">Hindustan Institute of Technology and Science, Kancheepuram</option>
<option value="C-37046">Hindusthan Institute of Technology</option>
<option value="C-32846">HMR Institute of Technology & Management</option>
<option value="C-35787">Holy Cross College, Tiruchirappalli - 620 002.</option>
<option value="C-19850">Hyderabad Institute of Technology & Management</option>
<option value="C-34345">I.I.R.W&#39;s, International Centre of Excellence in Engineering and Management, Pune-Aurangabad Highway, Pandharpur.</option>
<option value="C-46247">I.T.S ENGINEERING COLLEGE, GREATER NOIDA</option>
<option value="S-389">IILM GRADUATE SCHOOL OF MANAGEMENT</option>
<option value="U-0889">IIMT University</option>
<option value="U-0889">IIMT University</option>
<option value="C-46125">IMS ENGINEERING COLLEGE, GHAZIABAD</option>
<option value="U-0516">Indian Institute of Information Technology, Allahabad</option>
<option value="U-0455">Indian Institute of Information Technology, Design & Manufacturing, Kancheepuram</option>
<option value="S-15966">Indian Institute of Information Technology, Design and Manufacturing</option>
<option value="U-0795">INDIAN INSTITUTE OF INFORMATION TECHNOLOGY, GUWAHATI</option>
<option value="U-0796">INDIAN INSTITUTE OF INFORMATION TECHNOLOGY, KALYANI</option>
<option value="S-14881">Indian Institute of Plantation Management (IIPM), Bangalore</option>
<option value="U-0305">Indian Institute of Science Education & Research (IISER), Pune,</option>
<option value="U-0205">Indian Institute of Technology (Indian School of Mines), Dhanbad</option>
<option value="U-0088">Indira Kala Sangit Vishwavidyalaya, Khairagarh</option>
<option value="S-848">INDO GERMAN TOOL ROOM AHMEDABAD</option>
<option value="S-848">INDO GERMAN TOOL ROOM AHMEDABAD</option>
<option value="C-29741">Indore Institute of Science & Technology, Pithampur Road, Rau, Indore</option>
<option value="S-142">INDUS BUSINESS ACADEMY</option>
<option value="U-0663">INDUS UNIVERSITY</option>
<option value="C-34036">Innovative Engineers and Teachers Education Society Leelavati Awhad Institute of Technology Management Studies & Research opp. Gajanan Maharaj Temple Kanhor Karav Road Badlapur (W) dist. Thane- 421 503</option>
<option value="C-19706">Institute of Aeronautical Engineering</option>
<option value="U-0308">Institute of Chemical Technology, Mumbai</option>
<option value="U-0308">Institute of Chemical Technology, Mumbai</option>
<option value="S-2328">INSTITUTE OF ENGINEERING & MANAGEMENT JEYPORE</option>
<option value="C-25265">Institute of Management Studies, BJS Rampuria Jain College</option>
<option value="C-46749">INSTITUTE OF MANAGEMENT STUDIES, GHAZIABAD</option>
<option value="C-46914">INTERNATIONAL INSTITUTE FOR SPECIAL EDUCATION, LUCKNOW</option>
<option value="U-0221">International Institute of Information Technology, Bangalore</option>
<option value="U-0221">International Institute of Information Technology, Bangalore</option>
<option value="U-0014">International Institute of Information Technology, Hyderabad</option>
<option value="S-16690">International Management Institute</option>
<option value="C-18133">International School of Technology & Science (Women), NH-5, East gonagudem,  Rajanagram (md),   Rajahmundry,  PIN -533294.(CC-6W)</option>
<option value="C-29726">IPS Academy, Rajendra Nagar,  Indore</option>
<option value="C-36080 ">IPS College of Technology & Management, Shivpuri Link Road, Near Bela Ki Bawadi Tiraha, Gwalior</option>
<option value="C-36080 ">IPS College of Technology & Management, Shivpuri Link Road, Near Bela Ki Bawadi Tiraha, Gwalior</option>
<option value="C-60673">Is hear Deshmukh Institute of Pharmacy</option>
<option value="C-10398">ISF college of Pharmacy, Moga</option>
<option value="U-0194">Islamic University of Science & Technology, Pulwama</option>
<option value="C-17902">J N T U K University College of Engineering, Vizianagaram</option>
<option value="C-49058">J.K.K.Nattraja College of Pharmacy</option>
<option value="C-25176">Jagan Nath Gupta Institute of Engineering & Technology,Jaipur</option>
<option value="S-74">JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL</option>
<option value="C-46830">JAGRAN INSTITUTE OF MANAGEMENT, KANPUR</option>
<option value="C-46830">JAGRAN INSTITUTE OF MANAGEMENT, KANPUR</option>
<option value="S-309">JAIPURIA INSTITUTE OF MANAGEMENT</option>
<option value="C-47550">JAMIA MOHAMMADIA EDUCATION SOCIETYs MUMBAI MAULANA MUKHTAR AHMAD NADVI TECHNICAL CAMPUS, MALEGAON</option>
<option value="C-37021">Jansons Institute of Technology</option>
<option value="C-43332">JAWAHARLAL COLLEGE OF ENGINEERING & TECHNOLOGY, MANGALAM</option>
<option value="C-19450">Jawaharlal Nehru Technological University, Hyderabad/Joginpally B.R Engineering College		</option>
<option value="C-19643">Jaya Prakash Narayan College of Engineering</option>
<option value="C-19596">Jayamukhi Institute of Technological Sciences</option>
<option value="S-1867">JAYAWANT SHIKSHAN PRASARAK MANDAL&#39;S JAYAWANT INSTITUTE OF PHARMACY PUNE</option>
<option value="C-45222">JAYAWANTI BABU FOUNDATIONÃ¢Â€Â™S METROPOLITAN INSTITUTE OF TECHNOLOGY AND MANAGEMENT, SINDHUDURG</option>
<option value="U-0275">Jaypee University of Engeenering & Technology, Guna</option>
<option value="U-0275">Jaypee University of Engineering & Technology, Guna</option>
<option value="C-41938">Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass Highway, Tathwade,Pune  411 033</option>
<option value="c-41938">Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass Highway, Tathwade,Pune  411 033</option>
<option value="C-19615">JB Institute of Engineering & Technology</option>
<option value="S-13780">JEEVAN POLYTECHNIC COLLEGE, MANAPPARAI</option>
<option value="C-16525">Jerusalem College of Engineering</option>
<option value="C-6260">JIS College of Engineering 123</option>
<option value="C-42216">JSPMs Jayawantrao Sawant College of Engineering, Handewadi Road, Hadapsar, Pune 28.</option>
<option value="C-47874">JSS ACADEMY OF TECHNICAL EDUCATION, NOIDA</option>
<option value="C-19868">Jyothisamithi Institute of Technological Technology & Science</option>
<option value="S-14444">JYOTHISHMATHI INSTITUTE OF TECHNOLOGICAL SCIENCES, KARIMNAGAR</option>
<option value="U-0404">Jyoti Vidyapeeth Women&#39;s University, Jaipur</option>
<option value="C-45295">K S R INSTITUTE FOR ENGINEERING AND TECHNOLOGY, NAMAKKAL</option>
<option value="C-13536">K. N. Govt. P.G. College, Gyanpur, Sant Ravidas Nagar, Bhadohi</option>
<option value="C-9531">K.S.R. College of Arts & Science for Women</option>
<option value="C-39247">K.S.Rangasamy College of Technology</option>
<option value="C-41603">K.V.N. NAIK S. P. SANSTHAS LOKNETE GOPINATHJI MUNDE INSTITUTE OF ENGINEERING EDUCATION AND RESEARCH</option>
<option value="C-41603">K.V.N. NAIK S. P. SANSTHAS LOKNETE GOPINATHJI MUNDE INSTITUTE OF ENGINEERING EDUCATION AND RESEARCH</option>
<option value="C-37070">Kalaignar Karunanidhi Institute of Technology</option>
<option value="U-0458">Kalasalingam Academy of Research and Education, Srivilliputhrur</option>
<option value="U-0356">Kalinga Institute of Industrial Technology, Bhubaneswar</option>
<option value="C-42062">Kalyani Charitable Trusts K.R.Sapkal College of Management Studies, Kalyani Hills, Anjaneri,Tryambakeshwar Road, Nashik</option>
<option value="C-42062">Kalyani Charitable Trusts K.R.Sapkal College of Management Studies, Kalyani Hills, Anjaneri,Tryambakeshwar Road, Nashik</option>
<option value="C-19928">Kamala Institute of Technology & Science</option>
<option value="C-46831">KANPUR INSTITUTE OF TECHNOLOGY , KANPUR</option>
<option value="C- 27898">Kanya Maha Vidyalaya </option>
<option value="C-11094">Karmaveer Bahurao Patil Institute of Management  Studies & Research,</option>
<option value="C-11094">Karmaveer Bahurao Patil Institute of Management  Studies & Research,</option>
<option value="C-1282">Karnataka Law Society, HALIYAL  Dt: N.K.</option>
<option value="U-0229">Karnataka State Akkamahadevi Women&#39;s University, Vijayapura </option>
<option value="C-16599">Karpaga Vinayaga College of Engineering and Technology</option>
<option value="C-37044">Karpagam College of Engineering</option>
<option value="C-37044">Karpagam College of Engineering</option>
<option value="C-37035">Karpagam Institute of Technology</option>
<option value="U-0460">Karunya Institute of Technology and Sciences, Coimbatore</option>
<option value="C-41964">Kasturi Education Sanstha&#39;s College of Pharmacy, Shikrapur, Tal.Shirur Dist Pune</option>
<option value="C-41875">Khatun Minority Womens Social Welfare & Educational Societys Royal College of Pharmaceutical,Educational & Research, Sachane Khurd, Malegaon, Dist. Nashik 423203</option>
<option value="C-46111">KIET Group of Institutions (Krishna Institute of Engineering and Technology), Ghaziabad </option>
<option value="C-46111">KIET Group of Institutions (Krishna Institute of Engineering and Technology), Ghaziabad </option>
<option value="C-25038">Kings College of Engineering</option>
<option value="C-25038">Kings College of Engineering</option>
<option value="C-16466">Kings Engineering College</option>
<option value="C-46774">KIPM COLLEGE OF ENGINEERING AND TECHNOLOGY, GORAKHPUR</option>
<option value="S-10759">KISAN VIDYA PRASARAK SANSTHA`S MAHARANI AHILYABAI HOLKAR COLLEGE OF PHARMACY BORADI</option>
<option value="C-37070">KIT- Kalaignar Karunanidhi Institute of Technology</option>
<option value="C-18118">KKR&KSR Institute of Technology & Sciences, VINJANAMPADU Village (CC-JR)</option>
<option value="U-0854">KLE TECHNOLOGICAL UNIVERSITY</option>
<option value="C-35582">KLESs J.G.Commerce College Hubli</option>
<option value="U-0020 ">Koneru Lakshmaiah Education Foundation,Guntur</option>
<option value="U-0020 ">Koneru Lakshmaiah Education Foundation,Guntur</option>
<option value="C-37065">Kongu Engineering College</option>
<option value="S-2845">KONGUNADU POLYTECHNIC COLLEGE THOLURPATTI</option>
<option value="C-33916">Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Karjat Dist  Raigad  410 201</option>
<option value="C-33916">Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Karjat Dist  Raigad  410 201</option>
<option value="C-36999">KPR INSTITUTE OF ENGINEERING AND TECHNOLOGY</option>
<option value="C-46126">KRISHNA ENGINERING COLLEGE, GHAZIABAD</option>
<option value="C-15627">Kruti Institute of Technology and Engineering Knowledge Village,</option>
<option value="C-45295">KSR INSTITUTE FOR ENGINEERING AND TECHNOLOGY, NAMAKKAL</option>
<option value="C-26974">Kuppam Engineering College. Chittoor</option>
<option value="U-0164">Kurukshetra University, Kurukshetra</option>
<option value="C-409">L.M.COLLEGE OF PHARMACY, AHMEDABAD 228</option>
<option value="C-17889">Lakireddy Bali Reddy College of Engineering, L.B. Reddy Nagar, Mylavaram, PIN-521230(CC-76)</option>
<option value="C-17889">Lakireddy Bali Reddy College of Engineering, L.B. Reddy Nagar, Mylavaram, PIN-521230(CC-76)</option>
<option value="C-29649">Lakshmi Narayan College of Technology,  Sanwar Road, Indore</option>
<option value="U-0277">Lakshmibai National Institute of Physical Education, Gwalior</option>
<option value="C-33661">Lala Lajpatrai College of Management Studies Lala Lajpatrai Marg Haji Ali Mumbai 400 034</option>
<option value="C-358">LAXMINARAYAN DEV COLLEGE OF PHARMACY, BHOLAV, BHARUCH 274</option>
<option value="S-331">LOYOLA INSTITUTE OF BUSINESS ADMINISTRATION</option>
<option value="C-55475">LYDIA COLLEGE OF PHARMACY</option>
<option value="C-1343">M S Engineering College, BANGALORE</option>
<option value="C-39470">M.A.M College of Pharmacy, Narsaraopet</option>
<option value="C-29377">M.C.M.D.A.V.College For Women, Sector 36 A</option>
<option value="C-27046">M.E.T. Engineering College</option>
<option value="C-27046">M.E.T. Engineering College</option>
<option value="U-0461">M.G.R. Educational and Research Institute, Chennai</option>
<option value="C-45291">M.KUMARASAMY COLLEGE OF ENGINEERING, KARUR</option>
<option value="C-226">M.L.Institute of Diploma Studies, Bhandu 635 </option>
<option value="C-29614">M.L.T. College, Saharsa</option>
<option value="C-25070">Madras Institute of Technology Campus</option>
<option value="C-41725">MAEER&#39;s MIT Academy of Engineering, Alandi (Devachi), Dehu Phata, Taluka - Khed, District - Pune 412105</option>
<option value="C-32870">Maharaja Agrasen Institute of Technology</option>
<option value="U-0168">Maharishi Markandeshwar University, Mullana-Ambala</option>
<option value="S-1927">MAHATMA EDUCATION SOCIETY&#39;S PILLAIS HOC POLYTECHNIC KHALAPUR</option>
<option value="C-33505">Mahatma Education Societys Pillai college of Engineering, New Panvel</option>
<option value="C-46279">MAHATMA GANDHI MISSION COLLEGE OF ENGINEERING AND TECHNOLOGY, NOIDA</option>
<option value="C-52051">Mahaveer Institute of Technology and Science, Jadan</option>
<option value="C-9562">Mahendra Arts & Science college</option>
<option value="C-36952">Mahendra Institute of Engineering and  Technology</option>
<option value="C-37004">Mahendra Institute of Technology</option>
<option value="S-1934">MAHILA VIKAS SANSTHA&#39;S DR. R.G. BHOYAR INSTITUTE OF PHARMACY WARDHA</option>
<option value="C-16468">Mailam Engineering College</option>
<option value="U-0283">Makhanlal Chaturvedi National University of Journalism and Communication, Bhopal</option>
<option value="c-1299">Malik Sandal Inst. of Arts & Architecture, BIJAPUR</option>
<option value="C-19764">Malla Reddy College of Engineering for women</option>
<option value="C-19562">Malla Reddy Institute of Engineering and Technology</option>
<option value="C-6265">Mallabhum Institute of Technology 158</option>
<option value="S-1356">MALLIK SANDAL POLYTECHNIC BIJAPUR</option>
<option value="C-1300">Malnad College of Engineering, HASSAN</option>
<option value="U-0410">Malviya National Institute of Technology, Jaipur</option>
<option value="U-0169">Manav Rachna International University, Faridabad</option>
<option value="C-11729">Mangalam College of Engineering, Mangalam Hills, Ettumanoor P.O, Kottayam-686 631</option>
<option value="C-1402">Mangalore Institute of Engg. & Technology,MANGALORE</option>
<option value="C-33792">Manjara Charitable Trusts Rajiv Gandhi Institute of Technology Serve No.161 CTS No 1976 1 48 Juhu Versova Link Road Behind HDFC Bank Versova Andheri (West) Mumbai 400061</option>
<option value="U-0464">Manonmaniam Sundaranar University, Tirunelveli</option>
<option value="C-34190">Maratha Mandirs Babasaheb Gawde Institute of Management Studies Maratha Mandir Marg Mumbai 400 008</option>
<option value="C-48179">MARIAN INTERNATIONAL INSTITUTE OF MANAGEMENT, KUTTIKKANAM</option>
<option value="U-0825">Marwadi University</option>
<option value="C-25498">Matrusri Enginering College</option>
<option value="U-0091">MATS SCHOOL OF INFORMATION TECHNOLOGY</option>
<option value="U-0091">MATS University, Raipur</option>
<option value="U-0284">Maulana Azad National Institute of Technology, Bhopal</option>
<option value="C-43183">Mauli Group of Institution&#39;s College of Engineering &  Technology, Shegaon,</option>
<option value="C-17233">Mayang Anchalik College</option>
<option value="C-16551">Meenakshi College of Engineering</option>
<option value="C-46414">MEERUT INSTITUTE OF ENGINEERING AND TECHNOLOGY, MEERUT</option>
<option value="C-46194">MEERUT INSTITUTE OF TECHNOLOGY, BARAL, PARTAPUR, MEERUT</option>
<option value="C-46194">MEERUT INSTITUTE OF TECHNOLOGY, BARAL, PARTAPUR, MEERUT</option>
<option value="C-27058">Mepco Schlenk Engineering College</option>
<option value="C-8037">MES KEVEEYAM COLLEGE, VALANCHERI</option>
<option value="C-60113">Military College of Electronics and Mechanical Engineering</option>
<option value="U-0891">MIT Art, Design and Technology University, Pune</option>
<option value="U-0345">Mizoram University, Aizwal</option>
<option value="C-19753 ">MLR Institute of Technology</option>
<option value="C-19624">MNR College of Engineering & Technology</option>
<option value="C-23000">Model Institute of Engg. & Technology</option>
<option value="C-41463">Modern Education Societys Ness Wadia College of Commerce, P.V.K.Joag Path, Pune 411004</option>
<option value="C-50944">Modern Group of Colleges, Mukerian</option>
<option value="C-50944">Modern Group of Colleges, Mukerian</option>
<option value="C-35982">Modern Institute of Pharmaceutical Sciences, Gram Alwasa, Sanwer Road, Indore</option>
<option value="C-29889">Modern Institute of Prof. Studies, Gram Alwasa, Sanwer Road, Indore</option>
<option value="C-29860 ">Modern Institute. of Prof. Science, Gram Alwasa, Sanwar Road, Indore</option>
<option value="C-43650">Mohandas College of Engineering &Technology, Nedumangad, Thiruvananthapuram.</option>
<option value="C-20774">Mount Carmel College,58, Palace Rd, Bangalore-52 (Autonomous)</option>
<option value="C-20774">Mount Carmel College,58, Palace Rd, Bangalore-52 (Autonomous)</option>
<option value="C-25024">Mount Zion College of Engineering and Technology</option>
<option value="C-25464">Muffakham Jah College of Engineering & Technology</option>
<option value="C-19112">Mugberia Gangadhar Mahavidyalaya</option>
<option value="C-42111">Mumbai Education Trusts College of Pharmacy, Bhujbal Knowledge City, Adgaon , Nashik 422003</option>
<option value="U-0318">Mumbai University MumbaiAlkesh Dinesh Mody Institute for Financial and Management Studies, University of Mumbai</option>
<option value="C-9490">Muthayammal College of Arts & Science</option>
<option value="C-1400">N M A M Institute of Technology, NITTE</option>
<option value="C-36558">N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN</option>
<option value="C- 41120">NAADHA ARTS AND SCIENCE COLLEGE</option>
<option value="C-56319">NADAR SARASWATHI COLLEGE OF ENGINEERING AND TECHNOLOGY</option>
<option value="C-1407">Nagarjuna College of Engg. & Technology, BANGALORE</option>
<option value="C-12858">Nalanda College, Biharshariff</option>
<option value="C-30034">Nalanda Institute of Technology (NIT), Bhubaneswar</option>
<option value="C-19609">Nalla Malla Reddy Engineering College</option>
<option value="C-11074">Nanasaheb Mahadik College of Engineering, Pune - Banglore High way (NH4) Tal. Walwa</option>
<option value="S-1975">NANDED PHARMACY COLLEGE (POLY)</option>
<option value="C-37038">Nandha College of Technology</option>
<option value="C-37038">Nandha College of Technology</option>
<option value="C-17953">Narasaraopeta Engineering College, Kotappa Konda Road, Yellamanda Post, Narasaraopet,    PIN-522601(CC-47)</option>
<option value="C-17912">Narasaraopeta Institute of Pharmaceutical Sciences, Kotappakonda Road, Yellamanda (P.O), Narasaraopet (Md)  PIN- 522 601,(CC-CD)</option>
<option value="C-26887">Narayana Engineering College</option>
<option value="C-40302">Nargund College of Pharmacy</option>
<option value="U-0319">Narsee Monjee Institute of Management Studies, Mumbai</option>
<option value="C-6257">Narula Institute of Technology 127</option>
<option value="C-27089">National Engineering College</option>
<option value="S-8931">NATIONAL INSTITUTE OF INDUSTRIAL ENGINEERING, MUMBAI</option>
<option value="C-29316">National Institute of Technical Teacher Training & Research  Sector-26</option>
<option value="U-0615">National Institute of Technology Arunachal Pradesh</option>
<option value="U-0263">National Institute of Technology Calicut</option>
<option value="U-0621">National Institute of Technology Puducherry</option>
<option value="U-0207">National Institute of Technology, Jamshedpur</option>
<option value="U-0072">National Institute of Technology, Patna</option>
<option value="U-0092">National Institute of Technology, Raipur</option>
<option value="U-0197">National Institute of Technology, Srinagar</option>
<option value="U-0467">National Institute of Technology, Tiruchirapalli</option>
<option value="U-0025">National Institute of Technology, Warangal</option>
<option value="C-41799">Navjeevan Education Societys Institute of Management, Shivajinagar, CIDCO, 4th Scheme, Nashik 422009</option>
<option value="C-41799">Navjeevan Education Societys Institute of Management, Shivajinagar, CIDCO, 4th Scheme, Nashik 422009</option>
<option value="C-8241">NEHRU COLLEGE OF ENGINEERING AND RESEARCH CENTRE, PAMBADY</option>
<option value="C-6139">Netaji Subhash Engineering College 109</option>
<option value="C-18348">New Arts Commerce and Science College, Bachelor Road</option>
<option value="S-715">NEW GOVERNMENT POLYTECHNIC PATNA-13</option>
<option value="C-49217">NEW HORIZON INSTITUTE OF TECHNOLOGY AND MANAGEMENT</option>
<option value="C-16470">New Prince Shri Bhavani College of Engineering and Technology</option>
<option value="C-17905">Newton&#39;s Institute of Engineering, Aluguraja Pally(V), Koppunoor Post, Macherla(M),  PIN-522426  (CC-A1)</option>
<option value="C-30096">NIIS Institute of Business Administration (IBA), Bhubaneswar</option>
<option value="C-55710">NIRMALA COLLEGE OF HEALTH SCIENCE</option>
<option value="C-39481">Nirmala College of Pharmacy</option>
<option value="S-1993">NIT POLYTECHNIC</option>
<option value="C-58004">Nitte School of Architecture </option>
<option value="C-24337">Noble Institute of Science & Tech</option>
<option value="C-17306">Nowgong College</option>
<option value="C-36117">Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-462021</option>
<option value="U-0360">Orissa University of Agriculture &  Technology, Bhubaneswar</option>
<option value="C-1377">Oxford College of Engineering, BANGALORE</option>
<option value="C-1345">P E S College of Engineering, MANDYA</option>
<option value="C-41039">P K R Arts College for Women</option>
<option value="U-0873">P.P. Savani University</option>
<option value="C-26783">P.S.N.A. College of Engineering and Technology, Dindigul</option>
<option value="C-42143">P.V.G.s College of Engineering and Technology, Pune 9.</option>
<option value="C-37000">Paavai Engineering College</option>
<option value="C-17916">PACE Institution of Technology & Sciences, NH-5, Near Valluramma Temple, Vallur, Ongole,-523272(CC-KQ)</option>
<option value="C-11196">Padmabhooshan Vasantraodada Patil Institute  of   Technology, BUDHAGAON</option>
<option value="C-18005">Paladugu Parvathi Devi College of Engineering and Technology, Surampalli(v), Gannavaram(M), PIN-520001(CC-KR).</option>
<option value="C-26775">Pandiyan Saraswathi Yadav Engineering College, Arasanoor, Sivagangai</option>
<option value="C-16620">Panimalar Engineering College</option>
<option value="C-58672">PARUL INSTITUTE OF AYURVED AND RESEARCH</option>
<option value="C-157">Parul Institute of Diploma Studies,Limda 639 </option>
<option value="C-131">PARUL INSTITUTE OF ENGG. AND TECH., LIMDA, VAGHODIA 037</option>
<option value="C-117">PARUL INSTITUTE OF PHARMACY, LIMDA 233</option>
<option value="U-0763">PARUL UNIVERSITY</option>
<option value="C-27340">Pathfinder Institute of Pharmacy Education & Research, Near Warangal Airport, Warangal</option>
<option value="c-16482">PERI Institute of Technology</option>
<option value="U-0469">Periyar Maniammal Institute of Science & Technoloyg, Thanjavur</option>
<option value="U-0469">Periyar Maniammal Institute of Science & Technoloyg, Thanjavur</option>
<option value="C-27037">PET Engineering College</option>
<option value="C-33961">Pillai College of Arts, Commerce & Science (Autonomous)</option>
<option value="C-246">PIONEER PHARMACY COLLEGE, SAYAJIPURA 235</option>
<option value="C-6581">Pondicherry Engineering College</option>
<option value="C-6581">Pondicherry Engineering College</option>
<option value="U-0471">Ponnaiyah Ramajayam Institute of Science & Technology, Thanjavur</option>
<option value="C-25173">Poornima College of Engineering,Jaipur</option>
<option value="C-25158">Poornima Institute of Engineering & Technology,Jaipur</option>
<option value="U-0322">Pravara Institute of Medical Sciences, Ahmednagar</option>
<option value="C-44115">Pravara Rural Education Society&#39;s College of Pharmacy</option>
<option value="C-36003">Prestige Institute of Engineering Management & Research, Scheme No 74-C. Sector D, Indore - 452010</option>
<option value="C-36003">Prestige Institute of Engineering Management & Research, Scheme No 74-C. Sector D, Indore - 452010</option>
<option value="C-36003">Prestige Institute of Engineering Management & Research, Scheme No 74-C. Sector D, Indore - 452010</option>
<option value="C-36003">Prestige Institute of Engineering Management & Research, Scheme No 74-C. Sector D, Indore - 452010</option>
<option value="C-31687">Prestige Institute of Management, Dewas</option>
<option value="S-153">PRIN L N WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT AND RESEARCH</option>
<option value="C‐34129">PRIN L N WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT AND RESEARCH</option>
<option value="C-16609">Prince Shri Venkateshwara Padmavathy Engineering College</option>
<option value="C-18266">PRIYADARSHINI J. L. COLLEGE OF PHARMACY, Hingna Road</option>
<option value="C-18266">PRIYADARSHINI J. L. COLLEGE OF PHARMACY, Hingna Road</option>
<option value="C-18266">PRIYADARSHINI J. L. COLLEGE OF PHARMACY, Hingna Road</option>
<option value="C-43184">Prof Ram Meghe College of Engineering & Management, Badnera Rly</option>
<option value="C-43184">Prof Ram Meghe College of Engineering & Management, Badnera Rly</option>
<option value="C-43072">Prof. Ram Meghe Institute of Technology & Research Badnera Rly.</option>
<option value="C-41767">Progressive Educational Societys Modern College of Engineering,Shivajinagar, Pune 5</option>
<option value="C-41087">Providence College for Women</option>
<option value="C-41124">PSG College of Arts and Science</option>
<option value="C-41124">PSG College of Arts and Science</option>
<option value="C-41124">PSG College of Arts and Science</option>
<option value="C-37013">PSG College of Technology</option>
<option value="C-41540">Pune District Education Associations Seth Govind Raghunath Sable College of Pharmacy, Saswad</option>
<option value="C-41910">Pune Vidyarthi Griha&#39;s College of Engineering and Technology, Pune</option>
<option value="C-10418">Punjab College of Technical Education, Baddowal</option>
<option value="U-0383">Punjabi University, Patiala</option>
<option value="C-26948">PVKK Institute of Technology, Anantapur</option>
<option value="C-17997">QIS College of Engineering & Technology, Vengamukkapalem (Village), Pondur Road, Ongle(M),PIN-523 272.(CC-49)</option>
<option value="U-0647">R K University, Rajkot</option>
<option value="C-16497">R M K College of Engineering and Technology</option>
<option value="C-1269">R V College of Engineering, BANGALORE</option>
<option value="S-16672">R. C. Patel Polytechnic, Shirpur</option>
<option value="C-16614">R.M.K Engineering College</option>
<option value="C-20753">R.S. College of Management And Science, 327/66, Gangenahalli, Bellary Road, Bangalore-32.</option>
<option value="C-20753">R.S. College of Management And Science, 327/66, Gangenahalli, Bellary Road, Bangalore-32.</option>
<option value="C-30039">Raajdhani Engineering College (REC), Bhubaneswar</option>
<option value="U-0656">RABINDRANATH TAGORE UNIVERSITY</option>
<option value="C-26907">Raghavendra Institute of Pharmaceutical Education & Research, Anantapur</option>
<option value="C-41611">Raisoni College of Engineering & Management, Chas, Ahmednagar 414008</option>
<option value="C-26814">Raja College of Engineering, Madurai</option>
<option value="S-164">RAJAGIRI BUSINESS SCHOOL</option>
<option value="C-16626">Rajalakshmi Engineering College</option>
<option value="C-11208">Rajarambapu Institute of Technology, Rajaramnagar, SAKHARALE</option>
<option value="C-11208">Rajarambapu Institute of Technology, Rajaramnagar, SAKHARALE</option>
<option value="U-0419">Rajasthan Technical University, Kota</option>
<option value="C-6506">Rajiv Gandhi Arts & Science College</option>
<option value="C-16540">Rajiv Gandhi College of Engineering</option>
<option value="c-16540">Rajiv Gandhi College of Engineering</option>
<option value="C-18560">Rajiv Gandhi College of Engineering and Research, Wanadongri</option>
<option value="S-675">RAJIV GANDHI GOVT. POLYTECHNIC</option>
<option value="C-46725">RAJSHREE INSTITUTE OF MANAGEMENT AND TECHNOLOGY, BAREILLY</option>
<option value="C-48666">RAM-EESH INSTITUTE OF VOCATIONAL & TECHNICAL EDUCATION</option>
<option value="U-0808">RAMA UNIVERSITY UTTAR PRADESH</option>
<option value="U-0808">RAMA UNIVERSITY UTTAR PRADESH</option>
<option value="C-49144">RAMCO INSTITUTE OF TECHNOLOGY</option>
<option value="C-26852">Rami Reddy Subbarami Reddy Engineering College, Kavali</option>
<option value="U-0602">Rani Channamma University, Belagavi</option>
<option value="U-0332">Rashtrasant Tukadoji Maharaj Nagpur University, Nagpur</option>
<option value="C-41134">Rathinam College of Arts and Science</option>
<option value="C-41134">Rathinam College of Arts and Science</option>
<option value="C-45287">RATHINAM TECHNICAL CAMPUS, COIMBATORE</option>
<option value="C-10296">Rayat Group of Institutions</option>
<option value="C-10296">Rayat Group of Institutions</option>
<option value="C-25722">RBVRR College of Pharmacy</option>
<option value="S-12785">RECT POLYTECHNIC COLLEGE SOUTH VIJAYANARAYANAM, TIRUNELVELI</option>
<option value="U-0725">REVA UNIVERSITY, BANGALORE</option>
<option value="C-33953">Rishi Dayaram  and Seth Hassaram National College  and Seth Wassiamul Assomull Science College ,Lnking Road Bandra West Mumbai  400 05</option>
<option value="C-34027">Rizvi Education Societys College of Engineering Bandra (West) Mumbai-400 051</option>
<option value="C-21298">Roorkee Institute of Technlogy</option>
<option value="C-43267">RTC Institute of Technology</option>
<option value="sasciencecolle5">RTM, Nagpur University, Nagpur/Shankarlal Agrawal Science college Salekasa District Gondia </option>
<option value="C-16639">Rungta College of Engineering & Technology Kohka-Kurud Bhilai.</option>
<option value="C-1261">Rural Engineering College, BHALKI</option>
<option value="C-20757">RV Institute of Management, No.17, 26th Main, 36th Cross, Jayanagar, 4th T Block, Bangalore-41.</option>
<option value="C-20757">RV Institute of Management, No.17, 26th Main, 36th Cross, Jayanagar, 4th T Block, Bangalore-41.</option>
<option value="c-37001">RVS Educational Trust&#39;s Group of Institutions</option>
<option value="C-1321">S G Balekundri Institute of Technology,  BELGAUM</option>
<option value="C-1370">S L N College of Engineering, RAICHUR</option>
<option value="C 48">S N Patel Institute of Technology & Research Centre</option>
<option value="C-41100">S N R Sons College</option>
<option value="C-7592">S.G.G.S. Inst. of Engineering & Technology, Vishnupuri, Nanded</option>
<option value="U-0473">S.R.M. Institute of Science & Technology, Chennai</option>
<option value="U-0473">S.R.M. Institute of Science & Technology, Chennai</option>
<option value="U-0473">S.R.M. Institute of Science & Technology, Chennai</option>
<option value="C-11773">S.S.V. College, Valayanchirangara P.O., Perumbavoor - 683 556</option>
<option value="C-36296">Sagar Institute of Science  & Technology, Gandhi Nagar, Pipalner Near Airport, Jaipur Road, NH 12, Bhopal - 462036</option>
<option value="U-0916">SAGE UNIVERSITY</option>
<option value="C-24163">Sagi Rama Krishnam Raju Engineering College</option>
<option value="C-1265">Sahyadri Institute of Technology & Management, MANGALORE</option>
<option value="C-1235">Sai Vidya Institute of Technology, BANGALORE</option>
<option value="C-37023">Salem College of Engineering and Technology</option>
<option value="S-2058">SAMARTH RURAL EDUCATION INSTITUTE SAMARTH POLYTECHNIC BANGARWADI BELHE</option>
<option value="C-41331">Sanjeevani Rural Education Societys Sanjeevani College of Pharmaceutical & Research, At. Sahajanandnagar, Post. Shinganapur, Tal. Kopargaon ,Dist.Ahmednagar</option>
<option value="S-2070">SANJIVANI RURAL EDUCATION SOCIETY&#39;S K.B.P. POLYTECHNIC KOPARGAON DIST AHMEDNAGAR</option>
<option value="C-48662">SANT GAJANAN MAHARAJ COLLEGE OF ENGINEERING, KOLHAPUR</option>
<option value="C-48662">SANT GAJANAN MAHARAJ COLLEGE OF ENGINEERING, KOLHAPUR</option>
<option value="C-11194">Sant Gajanan Maharaj College of Pharmacy, Chinchewadi, Mahagaon,</option>
<option value="C-41180">Sarah Tucker College, Perumalpuram, Palayamkottai - 627 011</option>
<option value="C-16538">Saraswathy College of Engineering and Technology</option>
<option value="U-0149">Sardar Vallabhbhai National Institute of Technology, Surat</option>
<option value="C-16985">Sarosh Institute of Hotel Administration, Nitte Campus, N.H.48, Kodakal, Kannur Post, Mangalore-575007</option>
<option value="C-57">SARVAJANIK COLLEGE OF ENGG. & TECH. , SURAT 042 </option>
<option value="C-17862">Sarvajanik College of Engineering & Technology, Faculty of Architecture</option>
<option value="C-17862">Sarvajanik College of Engineering & Technology, Faculty of Architecture</option>
<option value="C-47703">SASMIRAS INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH</option>
<option value="C-60">SAT KAIVAL COLLEGE OF PHARMACY, SAARSA 246</option>
<option value="U-0474">Sathyabama Institute of Science & Technology, Chennai</option>
<option value="0474">Sathyabama Institute of Science and Technology</option>
<option value="U-0151">Saurashtra University, Rajkot</option>
<option value="U-0475">Saveetha Institute of Medical And Technical Sciences</option>
<option value="U-0323">Savitribai Phule Pune University</option>
<option value="U-0323">Savitribai Phule Pune University</option>
<option value="S-166">SCHOOL OF COMMUNICATION AND MANAGEMENT STUDIES (SCMS)</option>
<option value="C-9462">School of Engineering</option>
<option value="C-13645">School of Management Sciences, Khushipur, Bachhawan, Varanasii</option>
<option value="U-0626">School of Planning & Architecture, Bhopal</option>
<option value="c-11633">SCMS School of Technology & Management, Muttom, Aluva</option>
<option value="C- 41189">Scott Christian College (Autonomous)</option>
<option value="C-1397">SECAB Institute of Engg. & Technology,  BIJAPUR</option>
<option value="C-21319">SEEMANT INSTITUTE OF TECHNOLOGY (Constituent College of Uttarakhand technical University)</option>
<option value="C-37068">Selvam College of Technology</option>
<option value="C-36945">Sengunthar College of Engineering</option>
<option value="C-36966">Sengunthar Engineering College</option>
<option value="C-36966">Sengunthar Engineering College</option>
<option value="C-36548">SFR COLLEGE FOR WOMEN</option>
<option value="C-25923">Shadan Institute of Computer Studies (Girls)</option>
<option value="C-25923">Shadan Institute of Computer Studies (Girls)</option>
<option value="C-41982">Shahajirao Patil Vikas Pratishthans S.B.Patil College of Engineering, Vangali, Tal. Indapur, Dist.Pune 413106</option>
<option value="C-18158">Shankar Reddy Institute of Pharmaceutical Sciences, Bestavaripeta (M),  PIN-523346(CC-9K)</option>
<option value="U-0702">SHREE GURU GOBIND SINGH TRICENTENARY UNIVERSITY, GURGAON</option>
<option value="C-58643">Shree Santkrupa Institute of Engineering And Technology, (B. Tech.) </option>
<option value="C-61653">SHREE SIDDHESHWAR WOME&#39;S COLLEGE OF ENGINEEING, SOLAPUR, MAHARASHTRA, INDIA</option>
<option value="C-34296">Shreeyash Pratishthan&#39;s Shreeyash College of Engineering and Technology , Satara Tanda.</option>
<option value="C-34296">Shreeyash Pratishthan&#39;s Shreeyash College of Engineering and Technology , Satara Tanda.</option>
<option value="C-49105">Shri Ambabai Talim Sanstha, Sanjay Bhokare Group of Institutes, Faculty of Engineering,  Tilak Nagar, Miraj-Sangli Road, Miraj Â– 416 414, Dist-Sangli.</option>
<option value="C-36246">Shri Balaji Institute of Technology & Management, Khasra No. 238, Village Gohchi, Tah. & Dist. Betul - 460005</option>
<option value="S-2143">SHRI BHAGUBHAI MAFATLAL POLYTECHNIC MUMBAI</option>
<option value="S-2143">SHRI BHAGUBHAI MAFATLAL POLYTECHNIC MUMBAI</option>
<option value="C-211">SHRI DHANVANTARY PHARMACY COLLEGE, KIM 254 </option>
<option value="C-42949">Shri I Ianuman Vyam Prasarak Mandal&#39;s College of Engineering & Technology, Amravati.</option>
<option value="C-1433">Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL</option>
<option value="C-41699">Shri Neminath Jain Brahmacharyashramas College of Engineering, Neminagar, Chandwad, Dist.Nashik 423101</option>
<option value="C-18886">Shri Ramdeobaba College of Engineering and Management, Nagpur</option>
<option value="U-0753">SHRI RAMSWAROOP MEMORIAL UNIVERSITY</option>
<option value="C-33804">Shri Rishikesh Shikshan Prasarak Mandals D D Visapute College of Pharmacy and Research Centre at & post Devad Near Panvel Dist Raigad-410 206</option>
<option value="S-2171">SHRI SADASHIVRAO PATIL SHIKSHAN SANSTHA&#39;S KAMPTEE POLYTECHNIC KAMPTEE.</option>
<option value="C16655">Shri Shankaracharaya Group of Institutions</option>
<option value="C-97">Shri SWAMI ATMANAND SARASWATI INSTITUTE OF TECH., SURAT  076</option>
<option value="C-82">SHRI SWAMINARAYAN SANSKAR PHARMACY COLLEGE, ZUNDAL 260 </option>
<option value="U-0905">Shri Vaishnav Vidyapeeth Vishwavidyalaya</option>
<option value="C-58821">Shri Vile Parle Kelvani Mandals Institute of Technology, Dhule</option>
<option value="C-58821">Shri Vile Parle Kelvani Mandals Institute of Technology, Dhule</option>
<option value="C-15695">SHRI VITTHAL EDUCATION AND RECARCH INSTIIUTES COLLEGE OF PHARMACY GOPALPUR PB.NO 54 TAL.PANDHARPUR DIST-SOLAPUR</option>
<option value="S-1439">SIDDAGANGA POLYTECHNIC TUMKUR</option>
<option value="C-41220">Siddhant Institute of Business Management, At Post. Sudumbare, Tal. Maval, Dist.Pune 412109</option>
<option value="C-54960">SIDDI DEGREE COLLEGE</option>
<option value="C-33861">SIES Graduate School of Technology  Plot No. 1C/D/E Shri Chandrasekarendra Saraswathy, Sector - 5, Navi Mumbai 40706  </option>
<option value="C-183">SIGMA INSTITUTE OF ENGG., BAKROL 050</option>
<option value="C-8627">Sikkim Manipal Institute of Technology (SMIT)</option>
<option value="C-41394">Sinhgad Institute of Pharmacy,Narhe,Pune-41</option>
<option value="C-41900">Sinhgad Technical Education Society&#39;s Sinhgad College of Pharmacy, Vadgaon (Bk.), Sinhgad Road, Pune 411041</option>
<option value="C-41391">Sinhgad Technical Education Societys Sinhgad College of Engineering, Wadgaon Bk., Pune</option>
<option value="C-42028">Sinhgad Technical Education Societys Sinhgad Institute of Management and Computer Application, Narhe  Ambegaon, Dist. Pune  411041</option>
<option value="C-41424">Sinhgad Technical Education Societys Sinhgad Institute of Management, Vadgaon Bk., Off. Sinhgad Road, Pune 411041</option>
<option value="C-41214">Sinhgad Technical Education Societys Sinhgad Institute of Technology & Science , Narhe Ambegaon,Pune  41</option>
<option value="C-1327">Sir M Visvesvaraya Institute of Technology, BANGALORE</option>
<option value="C-15745">SKN SINHAGAD COLLEG OF   ENGINEERING KORTI  TAL PANDHARPUR  DIST-SOLAPUR</option>
<option value="C-46700">SMT FULEHRA SMARAK COLLEGE OF PHARMACY , BALLIA</option>
<option value="C-1324">Smt. Kamala & Vekappa  M Agadi College of Engg. & Technology, Lakshmeshwara</option>
<option value="c-37036">SNS College of Engineering</option>
<option value="c-37036">SNS College of Engineering</option>
<option value="c-36947">SNS College of Technology</option>
<option value="C-37028">Sona College of Technology</option>
<option value="C-46807">SR GROUP OF INSTITUTIONS COLLEGE OF ENGINEERING MANAGEMENT AND TECHNOLOGY, JHANSI</option>
<option value="C-11604">Sree Buddha College of Engineering for Women, Ayathil, Elavumthitta P. 0., Pathanamthitta, 689 625</option>
<option value="C-16491">Sree Sastha Institute of Engineering and Technology</option>
<option value="C-19951">Sreenidi Institute of Science &Technology</option>
<option value="C-43321">SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR</option>
<option value="C-26939">Sri  Venkateswar  College of Engineering &Technology, RVS Murukambattu, Chittoor</option>
<option value="C-26990">Sri  Venkateswara  College of Engineering, Karambadi road, Tirupati</option>
<option value="C-52089">SRI AUROBINDO INSTITUTE OF PHARMACY</option>
<option value="U-0477">Sri Chandrasekharendra Saraswathi Vishwa Mahavidyalaya, Kancheepuram</option>
<option value="C-36266">Sri Dadaji Institute of Technology & Science, Rehmapur, Khandwa Dist. East Nimar-450001</option>
<option value="U-0241">Sri Devraj Urs Acaedmy of Highehr Education and Research, Kolar</option>
<option value="C-37057">Sri Eshwar College of Engineering</option>
<option value="C-19647">Sri indu College of Engineering & Technology</option>
<option value="C-36995">Sri Krishna College of Engineering and Technology</option>
<option value="C-36995">Sri Krishna College of Engineering and Technology</option>
<option value="C-37064">Sri Krishna College of Technology</option>
<option value="C-6553">Sri Manakula Vinayagar Engineering College</option>
<option value="C-41040">Sri Nehru Maha Vidyalaya College of Arts and Science</option>
<option value="U-0034">Sri Padmavathi Mahila Visva Vidyalayam, Tirupathi</option>
<option value="C-26872">Sri Rama College of Engineering College, Tirupathi</option>
<option value="C-41059">Sri Ramakrishna College of Arts and Science for Women</option>
<option value="C-41059">Sri Ramakrishna College of Arts and Science for Women</option>
<option value="C-37089">Sri Ramakrishna Engineering College</option>
<option value="C-37032">Sri Ramakrishna Institute of Technology</option>
<option value="S-3025">SRI RAMAKRISHNA POLYTECHNIC COLLEGE -IDIGARAI PANCHAYAT</option>
<option value="C-">Sri Ranganathar Institute of Engineering and Technology</option>
<option value="C-16476">Sri Sai Ram Institute of Technology</option>
<option value="C-16547">Sri Sairam Engineering College</option>
<option value="C-16547">Sri Sairam Engineering College</option>
<option value="C-43987">Sri Sankara Arts & Science College</option>
<option value="C-41149">Sri Sarada College for Women, V.M. Chatram, Tirunelveli - 627 011</option>
<option value="C-41149">Sri Sarada College for Women, V.M. Chatram, Tirunelveli - 627 011</option>
<option value="C-41149">Sri Sarada College for Women, V.M. Chatram, Tirunelveli - 627 011</option>
<option value="C-45303">SRI SHANMUGHA COLLEGE OF ENGINEERING AND TECHNOLOGY, SALEM</option>
<option value="U-0242">Sri Siddharatha Acedemy of Higher Education</option>
<option value="c-1381">Sri Venkateshwara College of Engineering, BANGALORE</option>
<option value="C-16572">Sri Venkateswara College of Engineering</option>
<option value="C-26904">Sri Venkateswara College of Pharmacy, Chittoor</option>
<option value="C-45320">SSM INSTITUTE OF ENGINEERING AND TECHNOLOGY, DINDIGUL</option>
<option value="C-1277">ST JOSEPH ENGINEERING COLLEGE </option>
<option value="C-1277">ST JOSEPH ENGINEERING COLLEGE Vamanjoor, Mangaluru</option>
<option value="C-11789">St. Joseph&#39;s College of Engg. and Technology,Choondacherry P.O., PIassanal (Via), Palai, Kottayam-686 579</option>
<option value="c-50442">ST. JOSEPH&#39;S INSTITUTE OF TECHNOLOGY, OMR</option>
<option value="c-50442">ST. JOSEPH&#39;S INSTITUTE OF TECHNOLOGY, OMR</option>
<option value="C-16537">St. Josephs College of Engineering</option>
<option value="C-19826">St. Peter&#39;s Engineering College</option>
<option value="C-11756">St. Xavier&#39;s College for Women, Aluva - 683 101</option>
<option value="C-25820">STANLEY  COLLEGE OF ENGINEERING AND TECHNOLOGY FOR WOMEN</option>
<option value="C-19591">Sultan Ul Uloom College of  Pharmacy</option>
<option value="C-19591">Sultan Ul Uloom College of  Pharmacy</option>
<option value="U-0893">Swarnim Startup & Innovation University</option>
<option value="C-30060">Synergy Institute of Engineering and Technology (SIET), Dhenkanal</option>
<option value="C-9463">T.K.M.Institute of Technology</option>
<option value="C-37029">Tagore Institute of Engineering and Technology</option>
<option value="C-37029">Tagore Institute of Engineering and Technology</option>
<option value="C-37010">Tamilnadu College of Engineering</option>
<option value="U-0487">Tamilnadu Veterinary & Animal Sciences University, Chennai</option>
<option value="C-6133">Techno India 130</option>
<option value="C-6200">Techno India Banipur 244</option>
<option value="U-0865">TECHNO INDIA UNIVERSITY, WEST BENGAL</option>
<option value="C-46454">Techno International Batanagar </option>
<option value="C-36262">Thakral College of Technology,  Thakral Nagar, Raisen Road, Bhopal 462021</option>
<option value="C-33525">Thakur College of Engineering & Technology Shaymnarayan Thakur Marg Thakur Villaige Samata Nagar Kandivli (E) Mumbai 400 101</option>
<option value="C-33525">Thakur College of Engineering & Technology Shaymnarayan Thakur Marg Thakur Villaige Samata Nagar Kandivli (E) Mumbai 400 101</option>
<option value="C-33525">Thakur College of Engineering & Technology Shaymnarayan Thakur Marg Thakur Villaige Samata Nagar Kandivli (E) Mumbai 400 101</option>
<option value="U-0385">Thapar Institute of Engineering and Technology, Patiala</option>
<option value="C-20797">The East Point College of  Higher Education, No.147, Bidarahalli, Virgonagar Post, Bangalore -49</option>
<option value="U-0494">The Institute of Chartered Financial Analysts of India University, Agartala</option>
<option value="U-0211">The Institute of Chartered Financial Ayalysts of India University, Ranchi</option>
<option value="U-0211">The Institute of Chartered Financial Ayalysts of India University, Ranchi</option>
<option value="C-26802">Theni Kamavar Sangam College of Technology, Theni</option>
<option value="C-25590">TMSS College of Management</option>
<option value="C-55677">TOLANI MOTWANE INSTITUTE OF MANAGEMENT STUDIES ADIPUR</option>
<option value="C-30185">Trident Academy of Technology (TAT), Bhubaneswar</option>
<option value="C-51485">Trinity Academy of Engineering</option>
<option value="C-19698">Trinity College of Engineering & Technology</option>
<option value="C-36185">Trinity Institute of Technology & Research, Hathai Kheda Kokta, Raisen Road, Bhopal</option>
<option value="U-0495">Tripura University, Agartala</option>
<option value="U-0495">Tripura University, Agartala</option>
<option value="C-1366">UBDT College of Engineering, Davanagere</option>
<option value="C-10494">Universal Institute of Engg. & Technology , Vill Ballopur, Lalru</option>
<option value="C-44450">UNIVERSITY COLLEGE OF ENGINEERING & TECHNOLOGY</option>
<option value="c-16587">University College of Engineering Villupuram</option>
<option value="U-0823">UNIVERSITY OF ENGINEERING AND MANAGEMENT, KOLKATA</option>
<option value="U-0195">University of Jammu, Jammu Tawi</option>
<option value="C-33629">University of Mumbai </option>
<option value="S -1174">University Polytechnic B IT  Mesra,Ranchi</option>
<option value="S-1174">UNIVERSITY POLYTECHNIC BIT MESRA</option>
<option value="C-24342">V.S.M. College of Engineering</option>
<option value="C-292">V.V.P. ENGINEERING COLLEGE, RAJKOT 047</option>
<option value="C-27419">Vaagdevi College of Engineering</option>
<option value="C-11302">Vaishno College of Education Vill. Thapkour, PO Bhadroya, Teh. Nurpur, Distt kangra</option>
<option value="C-11353">Vaishno College Of Engg. Thapkour, Nurpur, Kangra</option>
<option value="C11353">Vaishno College of Engineering</option>
<option value="C-58523">Vasantidevi Patil Institute of Pharmacy (B.Pharm)</option>
<option value="S-670">VASAVI POLYTECHNIC BANAGANANPALLI</option>
<option value="C-18003">Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)</option>
<option value="C-18010">Velagapudi RamlaKrishna Siddhartha Engineering College, Vasantha Nagar, Kanuru, Vijayawada-520007(CC-8W)</option>
<option value="U-0490">Vellore Institute of Technology, Vellore</option>
<option value="U-0490">Vellore Institute of Technology, Vellore</option>
<option value="U-0491">Vels Institute of Science, Technology and Advanced Studies, Chennai</option>
<option value="C-41689">Vidya Pratishthan&#39;s Kamalnayan Bajaj Institute of Engineering & Technology, Baramati, M.I.D.C.road, District - Pune</option>
<option value="C-45250">VIDYA VIKAS EDUCATION TRUST UNIVERSAL COLLEGE OF ENGINEERING, THANE</option>
<option value="C-18044">Vignan&#39;s Institute of Engineering for Women, Kapujaggarupeta, Vadlapudi Post, Gajuwaka, PIN-530049(CC-NM)</option>
<option value="C-54054">VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES</option>
<option value="C-54054">VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES</option>
<option value="C-43798">Vimal Jyothi Engineering College</option>
<option value="C-10218">Vinayaka Mission&#39;s Annapoorana College of Nursing</option>
<option value="C-10222">Vinayaka Mission&#39;s Kirupananda Variyar Arts and Science College</option>
<option value="C-10214">Vinayaka Mission&#39;s Kirupananda Variyar Engineering College</option>
<option value="C-10214">Vinayaka Mission&#39;s Kirupananda Variyar Engineering College</option>
<option value="C-10219">Vinayaka Mission&#39;s Kirupananda Variyar Medical College and Hospital</option>
<option value="C-10219">Vinayaka Mission&#39;s Kirupananda Variyar Medical College and Hospital</option>
<option value="U-0492">VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM</option>
<option value="U-0492">VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM</option>
<option value="C-27036">Vins Christian Women College of Engineering</option>
<option value="C-19613">Vishnu Institute of Pharmaceutical Education and Research</option>
<option value="U-0888">VISHWAKARMA UNIVERSITY</option>
<option value="C-19859">Visvesvaraya College of Engineering Technology</option>
<option value="C-11623">Viswajyothi College of Engineering and Technology, Vazhakulam P.O., Muvattupuzha , Ernakulam- 686 670</option>
<option value="C17903">Viswanadha Institute of Technolgy and Management ( IC201912153)</option>
<option value="1-3508808387">VIVEKANAND EDUCATION SOCIETY&#39;S INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH</option>
<option value="C-33639">Vivekanand Education Society&#39;s Institute of Technology, Collector colony, Chembur Mumbai  400 074</option>
<option value="C-33553">Vivekanand Education Societys College of Pharmacy Sindhi Society Chembur Mumbai 400 071</option>
<option value="C-33895">Vivekanand Education Societys Institute of Technology Sindhi Society Chembur Mumbai  400 071</option>
<option value="C-25237">Vivekanand Institute of Technology,Jaipur</option>
<option value="C-36506">VIVEKANANDA COLLEGE</option>
<option value="C-1329">Vivekananda College of Engg. & Tech., PUTTUR</option>
<option value="U-0748">VIVEKANANDA GLOBAL UNIVERSITY</option>
<option value="C-37022">Vivekanandha College of Engineering for Women</option>
<option value="C-24174">VVR Kanaka Mahalakshmi Degree College</option>
<option value="C-43946">WOMENS CHRISTIAN COLLEGE</option>
<option value="S-157">XAVIER INSTITUTE OF MANAGEMENT AND ENTREPRENEURSHIP</option>
<option value="S-14893">XAVIER INSTITUTE OF MANAGEMENT AND ENTREPRENEURSHIP</option>
<option value="S-1176">XAVIER INSTITUTE OF POLYTECHNIC AND TECHNOLOGY</option>
<option value="S-1176">XAVIER INSTITUTE OF POLYTECHNIC AND TECHNOLOGY</option>
<option value="C-41595">Yashaswi Education Society&#39;s International Institute of Management Science, Elpro International, Chinchwad, Pune 411033</option>
<option value="C-18254">Yashwantrao Chavan Engineering College, Wanadongri, Hingna road</option>
<option value="U-0250">Yenepoya University, Mangalore</option>
<option value="C-48769">YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU</option>
<option value="C-48769">YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU</option>
</select>
<span class="focus-input100"></span>

</div>
</div>
<div class="row">
               
<div class="wrapBorder col-md-12 validate-input" data-validate = "Institute name is required">
<span class="label-input100">AISHE Code <a href="http://aishe.gov.in/aishe/aisheCode" style="color:blue;float:right;" target="_blank"><u>Know your AISHE code</u></a></span>
<input class="input100" name="insAisheCode"
id="insAisheCode" value=""  placeholder="Enter AISHE code" required>
<span class="focus-input100"></span>

</div>
</div>

				<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Name is required">
					<span class="label-input100">University/Institute Name</span>
					<input class="input100" name="insName" id="insName" value="" placeholder="Name of institute" required>
					<span class="focus-input100"></span>
				</div>
				<div class="wrapBorder col-md-6 validate-input">
					<span class="label-input100">City</span>
					<input class="input100" name="insCity" id="insCity" value="" placeholder="City of institute">
					<span class="focus-input100"></span>
				</div>
				
				</div>
				<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "State is required">
					<span class="label-input100">State</span>
					<input class="input100" name="insState" id="insState" value="" placeholder="State of institute">
					<span class="focus-input100"></span>
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "State is required">
					<span class="label-input100">Correspondence Address</span>
					<input class="input100" name="insAddress" id="insAddress" value="" placeholder="Enter address" required="">
					<span class="focus-input100"></span>
				</div>
				<!--<div class="wrapBorder col-md-6 input100-select">
					<span class="label-input100">Type of instiute</span>
					<input class="input100" name="instituteType" id="instituteType" value="" placeholder="Type of instiute">
					<span class="focus-input100"></span>
				</div>-->
				
				</div>
				<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Type of instiute">
					<span class="label-input100">Type of instiute</span>
					<div>
						<select class="select2 select2-container select2-container--default" name="instituteType" id="instituteTypes" required>
							<option value="">Select</option>
							<option value="Institute of National Importance & Central Universities/CFTIs">Institute of National Importance & Central Universities/CFTIs</option>
							<option value="State University & Deemed Universities (Govt. & Govt. Aided)">State University & Deemed Universities (Govt. & Govt. Aided)</option>
							<option value="Govt. Colleges/Institutes (Affiliated or Standalone)">Govt. Colleges/Institutes (Affiliated or Standalone)</option>
							<option value="University & Deemed University (Private)">University & Deemed University (Private)</option>
							<option value="Private Colleges/Institutes (Affiliated or Standalone)">Private Colleges/Institutes (Affiliated or Standalone)</option>
						</select>
					</div>
					
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Name is required">
					<span class="label-input100">Name of the Head of institute</span>
					<input class="input100" name="inputName" id="inputName" value="" placeholder="Name of the Head of institute" required>
					<span class="focus-input100"></span>
				</div>
				
				</div>
				<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Email is required">
					<span class="label-input100">Email id of the Head of institute</span>
					<input class="input100" name="inputEmail" id="inputEmail" value="" placeholder="Email id of the Head of institute" required>
					<span class="focus-input100"></span>
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Phone No. is required">
					<span class="label-input100">Contact Number of the Head of institute</span>
					<input class="input100" name="inputMobile" id="inputMobile" value="" placeholder="Contact Number of the Head of institute" min="0" minlength="10" maxlength="10" required>
					<span class="focus-input100"></span>
				</div>
				
				</div>
				
				<div class="row">
				<div class="wrapBorder col-md-12 validate-input" data-validate = "Phone No. is required">
					<span class="label-input100">Designation of the Head of institute</span>
					<div>
						<select class="select2 select2-container select2-container--default" name="inputDesignation" id="inputDesignation" required>
							<option value="">Select</option>
							<option value="Vice Chancellor">Vice Chancellor</option>
							<option value="Director">Director</option>
							<option value="Principal">Principal</option>
						</select>
					</div>
					
				</div>
              
				</div>
				<div class="row" style="text-align: center;margin-bottom: 10px;">
				<span style="font-size:15px;color:rgb(191,49,26);font-weight:900;">Nominate Senior Faculty</span>
				<p style="color:red;">Faculty must have experience in Innovation/ Intellectual property Rights and/ or Entrepreneurship/ Startups.</p>
				</div>
		<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Senior Faculty Name">
					<span class="label-input100">Senior Faculty Name</span>
					<input class="input100" name="facultyName" id="facultyName" value="" placeholder="Enter Senior Faculty Name" required>
					<span class="focus-input100"></span>
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Designation is required">
					<span class="label-input100">Designation</span>
					<input class="input100" name="facultyDesignation" id="facultyDesignation" value="" placeholder="Enter Designation" required>
					<span class="focus-input100"></span>
				</div>
				
				</div>
				<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Contact No. is required">
					<span class="label-input100">Contact No.</span>
					<input class="input100" name="facultyContact" id="facultyContact" value="" placeholder="Enter Contact No."  min="0" minlength="10" maxlength="10" required>
					<span class="focus-input100"></span>
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Email is required">
					<span class="label-input100">Email</span>
					<input class="input100" name="facultyEmail" id="facultyEmail" value="" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$" placeholder="Enter Email">
					<span class="focus-input100"></span>
				</div>
				
				</div>
				<div class="row">
				<div class="wrapBorder col-md-12 validate-input" data-validate = "Phone No. is required">
					<span class="label-input100">Upload approval letter from Head of institute</span>
					<div>
						<input type="file" class="input100" name="approval_letter" id="approval_letter" value="" required>
					<span class="focus-input100"></span>
					</div>
					
				</div>
              
				</div>

				<input type="hidden" id="token" name="token" value="1591952544" />
				<div class="container-contact100-form-btn">
					<div class="wrap-contact100-form-btn">
						<div class="contact100-form-bgbtn" style="background: linear-gradient(45deg, #c49f47, #abe7ed) !important;"></div>
						<button class="contact100-form-btn">
							<span>
								Submit
							</span>
						</button>
					</div>
				</div>
				<input type="hidden" name="insStatus" id="insStatus" value="">
			</form>
		</div>
	</div>

	<div id="dropDownSelect1"></div>
<!-- The Modal -->
  <div class="modal fade" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
      
        
        <!-- Modal body -->
        <div class="modal-body">
        <p  style="font-size:15px;color:rgb(191,49,26);font-weight:900;text-align: center;">Thank you for joining NISP Campaign.  Clik here to <br><u><a href="nominate_faculty_for_nisp.html"  style="font-size:13px;color:blue;">Nominate a Sr. Faculty for NISP Implementation at Institute Level</a></u></p>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer" style="padding: 5px;text-align: center;border-top: 1px solid #ffff;">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
<!--===============================================================================================-->
	<script src="/assets/vendor/jquery/jquery-3.2.1.min.js"></script>
<!--===============================================================================================-->
	<script src="/assets/vendor/select2/select2.min.js"></script>
	<script type="text/javascript" src="/assets/js/jquery.validate.min.js"></script>
	<script src="../maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
	<script>
	
	function getAisheCode(aisheCode){
	if(aisheCode=='Other'){
		$('#insAisheCode').val('');
		$("#insName").val('');	
		$("#insCity").val('');	
		$("#insState").val('');	
		$("#insAddress").val('');
		$("#instituteType").val();
		$("#insStatus").val(0);
	}
	else{
		$('#insAisheCode').val(aisheCode);
		$.ajax({
		type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
		url         : 'institute/session/verifyAisheCode.php', // the url where we want to POST
		data        : {Code:aisheCode}, // our data object
		dataType    : 'text', // what type of data do we expect back from the server
		encode      : true, 
		
		success:function(data) {
			var reply = JSON.parse(data);
			if(reply.sts!=0){
			$("#insName").val(reply.name);	
			$("#insCity").val(reply.city);	
			$("#insState").val(reply.state);	
			$("#insAddress").val(reply.address_line1);
			$("#instituteType").val(reply.type);
			$("#insStatus").val(1);
			$('#appErr').text("");
			}else{
				$('#appErr').text("Please enter valid AISHE code.");
				$("#insName").val('');	
				$("#insCity").val('');	
				$("#insState").val('');	
				$("#insAddress").val('');
				$("#instituteType").val();
				$("#insStatus").val(0);
				
			}			
		}
		
  });
	}
}
$(document).on('keyup change blur',"#insAisheCode", function() {
var words = $(this).val();      
if(words!='')
{
$.ajax({
		type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
		url         : 'institute/session/verifyAisheCode.php', // the url where we want to POST
		data        : {Code:words}, // our data object
		dataType    : 'text', // what type of data do we expect back from the server
		encode      : true, 
		
		success:function(data) {
			var reply = JSON.parse(data);
			if(reply.sts!=0){
			$("#insName").val(reply.name);	
			$("#insCity").val(reply.city);	
			$("#insState").val(reply.state);	
			$("#insAddress").val(reply.address_line1);
			$("#instituteType").val(reply.type);
			$("#insStatus").val(1);
			$('#appErr').text("");
			}else{
				$('#appErr').text("Please enter valid AISHE code.");
				$("#insName").val('');	
				$("#insCity").val('');	
				$("#insState").val('');	
				$("#insAddress").val('');
				$("#instituteType").val();
				$("#insStatus").val(0);
				
			}			
		}
		
  });
}
});
	function showOther(id){
		if(id==3){
			$("#other_lebel").css('display','block');
			$("#other").prop('disabled', false);

		}else{
			$("#other_lebel").css('display','none');
			$("#other").prop('disabled', true);
		}
	}
		$(".selection-2").select2({
			minimumResultsForSearch: 20,
			dropdownParent: $('#dropDownSelect1')
		});
//Add Institute Validations
$.validator.addMethod("customemail", function(value, element) {
            return /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
        }, "Invalid Email address");

$("#nominateFacultyForm").validate({
rules: {			
	startup_aplication_ins:
	{
		required: true,
	},
			insAisheCode:
			{
				required: true,
			},
			insName:
			{
				required: true,
			},
			insCity:
			{
				required: true,
			},
			insState:
			{
				required: true,
			},insAddress:
			{
				required: true,
			},instituteType:
			{
				required: true,
			},inputName:
			{
				required: true
			},
			inputEmail: 
			{
				required: true,
                //customemail: true				
			},
			
			inputMobile:
			{
				required: true,
				minlength:10,
				maxlength:10,
			},
			inputDesignation:
			{
				required: true,
			},facultyName:
			{
				required: true,
			},facultyEmail:
			{
				required: true,
				//customemail: true
			},
},
messages: {
	

	
},

highlight: function(element) {
	$(element).closest('.form-group').addClass('has-error');
},
unhighlight: function(element) {
	$(element).closest('.form-group').removeClass('has-error');
},
errorElement: 'span',
errorClass: 'help-block',
errorPlacement: function(error, element) 
{
	if(element.parent('.input-group').length) 
		{
		error.insertAfter(element.parent());
		} 
	else 
		{
		error.insertAfter(element);
		}
}
});
	
//Add Report
$("#nominateFacultyForm").on('submit',(function(e) {
e.preventDefault();
var insStatus=$("#insStatus").val();
	//if(insStatus==1){
if($("#nominateFacultyForm").valid())
{		
	$.ajax({
	url: "add_startup_application_for_nominate_faculty.php",
	type: "POST",
	data:  new FormData(this),
	contentType: false,
	cache: false,
	processData:false,
	
	success:function(data) {
		var reply = data.replace(/\s+/, "");
		if(reply==1){
			$('#myModal').modal('show');
		location.reload();
		}else if(reply=='Error'){
			alert('Something went wrong.');
		}else if(reply==2){
			alert('This Email id of the Head of institute already exist.');
		}else if(reply==3){
			alert('This Contact Number of the Head of institute already exist.');
		}
						
	}
});			
}
//}else{
		//alert('Please enter correct AISHE code');
	//}

}));
	</script>


<!-- Footer -->
<app-inner-footer></app-inner-footer>