<div class="container" style="margin-top: 30px;">	
  <div class="row" color="primary">
    <div class="col-md-12">
      <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
          Contact Details
      </h2>
    </div>
  </div>
  <mat-card class="mat-elevation-z6 proInstCardMat">
    <div class="row">
      <div class="col-md-12">
        <p>For any support/queries related to ATL - IIC linkage program,
          please contact the IIC Implementation team at Ministry of Education’s Innovation Cell at <a href="https://iic.mic.gov.in/about-us/contact_us" target="_blank">https://iic.mic.gov.in/about-us/contact_us</a></p>
      </div>
    </div>
  </mat-card>
</div>  