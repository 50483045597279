<div class="container" style="margin-top: 30px;">	
    <div class="row" color="primary">
		<div class="col-md-12">
        	<h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Activity Report Submit</h2>
    	</div>
	</div>
	<mat-card class="mat-elevation-z6 proInstCardMat">
		<div class="row">
			<div class="col-md-12">
				<form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
       				<div class="tableBody">
			  			<div class="table-responsive">
							<table class="table">
								<tr>
									<td>Participations status</td>
									<td>
										<mat-form-field class="w-100" appearance="outline">
											<mat-select formControlName="participation_status" placeholder="Please Select" required>
											<!-- <mat-option value="Faculty">Faculty</mat-option> -->
												<mat-option value="participated">Participated</mat-option>							
												<mat-option value="not-participated">Not Participated </mat-option>							
											</mat-select>
										</mat-form-field>
									</td>
								</tr>

								<tr>
									<td>
										Overview of the event
									</td>
									<td>
										<mat-form-field class="w-100" appearance="outline">
										<mat-label>Overview of the event</mat-label>

										<textarea rows="2" maxlength="1000" matInput placeholder="Overview of the event" formControlName="remark" required></textarea>					
										</mat-form-field>
									</td>
								</tr>    
								<tr>
									<td>
										Learning
									</td>
									<td>
										<mat-form-field class="w-100" appearance="outline">
										<mat-label>Learning</mat-label>

										<textarea rows="2" maxlength="1000" matInput placeholder="Learning" formControlName="learning" required></textarea>					
										</mat-form-field>
									</td>
								</tr>    
								<tr>
									<td>
										Number of Teachers Participated
									</td>
									<td>
										<mat-form-field class="w-100" appearance="outline">
										<mat-label>Number of Teachers Participated</mat-label>
										<input matInput autocomplete="off" placeholder="Number of Teachers Participated" formControlName="number_of_teachers_participated" type="text"   required>					
										</mat-form-field>
									</td>
								</tr>    
								<tr>
									<td>
										Number of students Participated
									</td>
									<td>
										<mat-form-field class="w-100" appearance="outline">
										<mat-label>Number of students Participated</mat-label>
										<input matInput autocomplete="off" placeholder="Number of students Participated" formControlName="number_of_students_participated" type="text"   required>					
										</mat-form-field>
									</td>
								</tr>    
								<tr>
									<td>
										Number of External Members Participated
									</td>
									<td>
										<mat-form-field class="w-100" appearance="outline">
										<mat-label>Number of External Members Participated</mat-label>
										<input matInput autocomplete="off" placeholder="Number of External Members Participated" formControlName="number_of_external_members_participated" type="text"   required>					
										</mat-form-field>
									</td>
								</tr>    
								<tr>
									<td>
										&nbsp;
									</td>
									<td>
										<button mat-raised-button color='primary' class="btn btn-success float-right" [disabled]='iicImpactQuestions.invalid'>Submit</button>
									</td>
								</tr>


	   						</table>
	   					</div>
	   				</div>
				</form>
			</div>
		</div>
	</mat-card>
</div>
