<section class="instIncubCenter">
  <div class="container">
    <form [formGroup]="incubationCentersForm" (ngSubmit)="onSubmit2(incubationCentersForm.value)">
      <mat-card class="mat-elevation-z6 mt-4 mb-2">
        <marquee style="color: red;">Press Ctrl+Shift+R after every successful submission notification of Incubation Center form </marquee>
        <p style="color: red;">All Fields are Mandatory. </p>

  <div class="row">
    <div class="col-5">
      <mat-form-field class="example-full-width col-12" appearance="outline">
        <mat-label>Incubation Center/Unit Name</mat-label>
        <input matInput formControlName="center_name" placeholder="Incubation Center/Unit Name"
          (keypress)=isCharKey($event)>
        <mat-error *ngIf="incubationCentersForm.controls.center_name.errors?.required">Incubation Center/Innovation Center Name
          is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-7">
      <mat-form-field class="col-12 example-full-width" appearance="outline" style="
      width: 524px;">
        <mat-label> Registration Status:</mat-label>
        <mat-select formControlName="registration_status_centre" >
          <mat-option *ngFor="let role of registration_status_centre" [value]="role">
            {{role}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="incubationCentersForm.controls.registration_status_centre.errors?.required">
          Status of Centre is required </mat-error>
      </mat-form-field>
    </div> 

     <div *ngIf="show1" class="col-5">
      <mat-form-field class="col-12 example-full-width" appearance="outline">
        <mat-label>Registered as:</mat-label>
        <mat-select formControlName="registered_as">
          <mat-option *ngFor="let role of registered_as" [value]="role">
            {{role}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="incubationCentersForm.controls.registered_as.errors?.required">
          Registered as is required </mat-error>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field class="col-12 example-full-width" appearance="outline">
        <mat-label>Funding Agency/Schemes Available:</mat-label>

        <mat-select formControlName="funding_schemes_support" (selectionChange)="getSelectedApprovalType()"
          multiple [(ngModel)]="fundingModel">
          <app-select-check-all [model]="incubationCentersForm.get('funding_schemes_support')"
            [values]="fundingSchemes">
          </app-select-check-all>
          <mat-option *ngFor="let role of fundingSchemes" [value]="role">
            {{role}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="incubationCentersForm.controls.funding_schemes_support.errors?.required">
          Funding Schemes/Schemes Available is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12 ml-4" *ngIf="showCIN1">
      <h1 class="incubIncHeading">CIN Number (21-Digits Alpha-Numeric):
      <a href="../../../../../../assets//img/CIN.png" target="_blank"
          style="color: green;text-decoration: underline !important;">Sample Format</a>
      </h1>
    </div>

    <div class="col-2" *ngIf="showCIN1">
      <mat-form-field class="example-full-width col-12 upperCase" appearance="outline">
        <input matInput placeholder="Listing Status" formControlName="pin_no_1" type="text" class="upperCase"
          minlength="1" maxlength="1" (keypress)="isCharKey($event)" style="
          font-size: 15px;">
      </mat-form-field>
    </div>

      <div class="col-2" *ngIf="showCIN1">
        <mat-form-field class="example-full-width col-12 upperCase" appearance="outline" style="width: 157px;">
          <input matInput placeholder="Industry Code" formControlName="pin_no_2"
            minlength="5" class="upperCase"
            maxlength="5" (keypress)=isNumberKey($event) style="
            font-size: 15px;">
        </mat-form-field>
      </div>
      <div class="col-2" *ngIf="showCIN1">
        <mat-form-field class="example-full-width col-12 upperCase" appearance="outline" style="
        width: 173px;">
          <input matInput placeholder="State Code" formControlName="pin_no_3" minlength="2" class="upperCase"
            maxlength="2" (keypress)=isCharKey($event) style="
            font-size: 15px;">
        </mat-form-field>
      </div>
      <div class="col-2" *ngIf="showCIN1">
        <mat-form-field class="example-full-width col-12 upperCase" appearance="outline" style="
        width: 161px;">
          <input matInput placeholder="Inc. Year" formControlName="pin_no_4" minlength="4"
            class="upperCase" maxlength="4" (keypress)=isNumberKey($event) style="
            font-size: 15px;">
        </mat-form-field>
      </div>
      <div class="col-2" *ngIf="showCIN1">
        <mat-form-field class="example-full-width col-12 upperCase" appearance="outline" style="
        width: 161px;">
          <input matInput placeholder="Ownership" formControlName="pin_no_5" class="upperCase"
            minlength="3" maxlength="3" (keypress)=isCharKey($event) style="
            font-size: 15px;">
        </mat-form-field>
      </div>
      <div class="col-2" *ngIf="showCIN1">
        <mat-form-field class="example-full-width col-12 upperCase" appearance="outline" style="
        width: 161px;">
          <input matInput placeholder="Company No." formControlName="pin_no_6"
            class="upperCase" minlength="6" maxlength="6" (keypress)=isNumberKey($event) style="
            font-size: 15px;">
        </mat-form-field>
      </div>

      <div class="col-4" *ngIf="show1" >
      <mat-label>Registration Proof (PDF Max. 2MB)</mat-label>
      <div>Registration No./CIN No. must be visible in the uploaded document</div>
      </div>

      <div *ngIf="show1">
      <div class="col-8 example-full-width" *ngIf="!cinFileFlag">
        <div class=" example-full-width col-12">
          <div *ngIf=!cin_file_flag class="col-12 incubCentInstFile">
            <div class="row">
              <div>
                <label class="custom-file-label float-right" for="customFile" *ngIf="!cin_file">Choose file</label>
                <label class="custom-file-label float-right" for="customFile"
                  *ngIf="cin_file">{{cin_file[0]['name']}}</label>
                <input type="file" #cinFileRef accept="application/pdf,application/PDF" class="custom-file-input float-right"
                  id="cin_file" name="cin_file" (change)="( cin_file = cinFileRef.files )">
              </div>
            </div>
          </div>
        </div>

        <!-- show succesfull file -->
        <div *ngIf=cin_file_flag class="m-4">
          <div class="row">
            <div class="col-lg-10 col-md-10">


            </div>
            <div class="col-lg-2 col-md-2 float-right">
              <button type="button" mat-raised-button color="warn" class="float-right"
                (click)="deleteFile('cin_file')">
                Re-Upload Attachment
              </button>
            </div>
          </div>
        </div>
        <!-- document upload end -->
      </div>
    </div>


    <div *ngIf="cinFileFlag">
      <a href="{{baseImageUrl + cinFilePath}}" target="_blank"><button type="button" mat-raised-button
          color="primary">
          Download CIN File
        </button></a>
    </div>

    <div *ngIf="cinFileFlag">
      <button type="button" mat-raised-button color="warn" class="ml-3" (click)="cinFileFlag = !cinFileFlag">
        Re-Upload CIN File
      </button>
    </div>

    <!-- !  -->
    <div class="col-12">
      <h1 class="incubIncHeading incubheadFirst">In-Charge Details</h1>
      <!-- <div class="inChargeIncubBut">
        <button type="button" (click)="openDialog()" mat-fab color="warn" [disabled]="inchareDetails.length==4"
          aria-label="Example icon button with a home icon">
          <mat-icon>add</mat-icon>
        </button>
      </div> -->

      <div>
        <mat-form-field class="col-3" appearance="outline">
          <mat-label>Name:</mat-label>
          <input matInput formControlName="incharge_name" placeholder="Enter Incubation Incharge Name">
          <mat-error *ngIf="incubationCentersForm.controls.incharge_name.errors?.required">Name is
            required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="outline">
          <mat-label>Email:</mat-label>
          <input matInput formControlName="incharge_email" placeholder="Incubation Incharge Email">
          <mat-error *ngIf="incubationCentersForm.controls.incharge_email.errors?.required">Email is
            required
          </mat-error>
          <mat-error *ngIf="incubationCentersForm.controls.incharge_email.errors?.pattern">Invalid Email
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="outline">
          <mat-label>Designation:</mat-label>
          <input matInput formControlName="incubation_incharge_designation"
            placeholder="Enter Incubation Incharge designation">
          <mat-error *ngIf="incubationCentersForm.controls.incubation_incharge_designation.errors?.required">
            Designation is
            required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-3" appearance="outline">
          <mat-label>Phone:</mat-label>
          <input matInput formControlName="incharge_phone" minlength="10" maxlength="10"
            (keypress)=isNumberKey($event)>
          <mat-error *ngIf="incubationCentersForm.controls.incharge_phone.errors?.required">Phone number
            is required
          </mat-error>
        </mat-form-field>
      </div>

            <!-- <table class="table table-striped table-bordered" style="margin-top:40px !important;">
              <thead>
                <tr>
                  <th scope="col">Sr. No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let incharge of inchareDetails; let i = index">
                  <th scope="row">{{i+2}}</th>
                  <td>{{incharge.incharge_name}}</td>
                  <td>{{incharge.incharge_email}}</td>
                  <td>{{incharge.incharge_designation}}</td>
                  <td>{{incharge.incharge_phone}}</td>
                  <td>
                    <mat-icon (click)="deleteInchargeDetails(i)">delete_forever</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table> -->
          </div>
          <!-- !  -->

          <div class="col-12">
            <mat-form-field class="col-12 example-full-width" appearance="outline" style="width: 1032px;">
              <mat-label>Type of Supports Available</mat-label>
              <mat-select formControlName="type_services_offered" multiple [(ngModel)]="serviceModel">
                <app-select-check-all [model]="incubationCentersForm.get('type_services_offered')"
                  [values]="type_services_offered">
                </app-select-check-all>
                <mat-option *ngFor="let role of type_services_offered" [value]="role">
                  {{role}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="incubationCentersForm.controls.type_services_offered.errors?.required">
                Type of Supports Available is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="col-12 example-full-width" appearance="outline" style="width: 1032px;">
              <mat-label>Range of Services Offered</mat-label>
              <mat-select formControlName="facilities_services" multiple [(ngModel)]="facilitiesModel">
                <app-select-check-all [model]="incubationCentersForm.get('facilities_services')"
                  [values]="facilities_services_multi">
                </app-select-check-all>
                <mat-option *ngFor="let role of facilities_services" [value]="role?.id">
                  {{role?.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="incubationCentersForm.controls.facilities_services.errors?.required">
                Range of Services Offered is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>URL (Optional):</mat-label>
              <input matInput formControlName="incubation_url" placeholder="Enter Webiste URL">
            </mat-form-field>
          </div>



          <div class="col-12">
            <!-- <p>Press enter or comma after each entry.</p> -->
            <mat-form-field class="example-chip-list example-full-width col-12" appearance="outline">
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let thrust of thrustAreas" [selectable]="true" [removable]="true"
                  (removed)="remove(thrust)">
                  {{thrust}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input #thrustAreaInput formControlName="thrust_areas" placeholder="Thrust Areas (Optional) (Press enter a comma after each entry)"
                  [matAutocomplete]="auto" [matChipInputFor]="chipList" required
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
                  [(ngModel)]="filterThrustAreaData" (keyup)="getThrustAreasList()">
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let filteredAreas of filteredThrustAreas | async" [value]="filteredAreas">
                  {{filteredAreas}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-12" style="text-align: center !important">
            <button type="button" class="btn btn-primary incubSubmit" type="submit">Save & Submit</button>
              <!-- [disabled]='incubationCentersForm.invalid'>Save & Submit</button> -->
          </div>
        </div>
      </mat-card>
    </form>

  </div>
</section>
