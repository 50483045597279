import { Component, OnInit } from '@angular/core';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-institute-mentoring-session',
  templateUrl: './institute-mentoring-session.component.html',
  styleUrls: ['./institute-mentoring-session.component.css']
})
export class InstituteMentoringSessionComponent implements OnInit {
  session: any[];

  constructor(private innovationService: InstituteInnovationContestService) { }

  ngOnInit(): void {
    this.innovationService.getMentoringSession().subscribe(
      data => this.handleData(data)
    )
  }
  handleData(data) {
    this.session = [];
    for (let i = 0; i < data.length; i++) {
      this.session.push(data[i]);
    }
  }

}
