import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-india-canada',
  templateUrl: './india-canada.component.html',
  styleUrls: ['./india-canada.component.css']
})
export class IndiaCanadaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
