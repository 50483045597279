<div class="ss">
    <mat-dialog-content class="mat-typography">
  
    <h2 class="cc">Enter Suggestion/Queries</h2>
  
    <form [formGroup]="suggestionForm" (ngSubmit)="onSubmit(suggestionForm.value)">
      <label for="name">Type</label>
  
        <mat-form-field class="col-12 example-full-width" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let role of types" [value]="role">
              {{role}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="suggestionForm.controls.type.errors?.required">
            Registration is required </mat-error>
        </mat-form-field>
      <label for="name">Subject</label>
      <input type="text" id="subject" name="subject" formControlName="subject" class="full-width form-control" /><br />
      <label for="message">Message</label>
      <textarea name="message" id="message" formControlName="message" cols="30" rows="10"
        class="full-width form-control"></textarea><br /><br>
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <label>Upload related attachment:</label>
            <mat-error>Upload any file or photo </mat-error>
          </div>
          <div class="col-lg-5 col-md-2 float-right">
            <label class="custom-file-label float-right" for="customFile" *ngIf="!attach">Choose file</label>
            <label class="custom-file-label float-right" for="customFile"
              *ngIf="attach">{{attach[0]['name']}}</label>
            <input #attachRef type="file" accept="application/*" name="attach"
              (change)="( attach = attachRef.files )" class="custom-file-input float-right" required>
          </div>
  
        </div>
      <div style="text-align: center;">
        <button class="btn btn-success mat-elevation-z6 mt-3" color="primary" mat-raised-button [disabled]="suggestionForm.invalid">
          Submit
        </button>
        <button type="button" class="btn btn-danger instituteBtnClose ml-4" mat-raised-button color="warn" (click)="onNoClick()">
          Close
        </button>
      </div>
    </form>
    </mat-dialog-content>
  </div>
  