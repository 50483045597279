import { Component, OnInit } from '@angular/core';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-atl-announcement-detail',
  templateUrl: './atl-announcement-detail.component.html',
  styleUrls: ['./atl-announcement-detail.component.css']
})
export class AtlAnnouncementDetailComponent implements OnInit {

  announcements:any;

  constructor(private route: ActivatedRoute, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.instituteActivityService.getAnnouncementDetail().subscribe(
      (data) => this.handleResponseList(data)
    );
  }

  handleResponseList(data) {
    this.announcements = data;
  }

}
