
<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>
<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Photo Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Gallery</a></li>
	<li class="breadcrumb-item"><a href="gallery/photo-gallery">Photo Gallery</a></li>
    <li class="breadcrumb-item active" aria-current="page">Regional Meet Bangalore 2023</li>
  </ul>
</nav>


<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Regional Meet at Bangalore</h2>
				<div>
					<a class="backButton" href="/gallery-photo-2023">Back to Gallery</a>
				</div>
			</div>
		</div>
		</div>	  
		  
	</div>	
 </div>

<div class="container">
		<div class="row">
			<div class="gallery col-md-12">
				<div class="row">
					<div class="col-md-4">
						<a class="lightbox" href="uploads/Regional-Meet-Photo-2023/bangalore/1.JPG" data-caption="">
							<img src="uploads/Regional-Meet-Photo-2023/bangalore/1.JPG" alt="First image">
						</a>
					</div>

					<div class="col-md-4">
						<a class="lightbox" href="uploads/Regional-Meet-Photo-2023/bangalore/2.JPG" data-caption="">
							<img src="uploads/Regional-Meet-Photo-2023/bangalore/2.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="uploads/Regional-Meet-Photo-2023/bangalore/3.JPG" data-caption="">
							<img src="uploads/Regional-Meet-Photo-2023/bangalore/3.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="uploads/Regional-Meet-Photo-2023/bangalore/4.JPG" data-caption="">
							<img src="uploads/Regional-Meet-Photo-2023/bangalore/4.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="uploads/Regional-Meet-Photo-2023/bangalore/5.JPG" data-caption="">
							<img src="uploads/Regional-Meet-Photo-2023/bangalore/5.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="uploads/Regional-Meet-Photo-2023/bangalore/6.JPG" data-caption="">
							<img src="uploads/Regional-Meet-Photo-2023/bangalore/6.JPG" alt="First image">
						</a>
					</div>
					
				</div>
			</div>
			
		</div>
	</div> 

<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
