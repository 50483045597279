<div class="container-fluid">

  <div class="row addTeachingTopRow">
    <div class="col-4">
     <!-- <button mat-raised-button color='primary' (click)='openDialog()'><mat-icon>add</mat-icon> Add Student Members
      </button> -->
    </div>
    <div class="col-4">
      <!-- <div class="row">
        <div class="col-2" *ngIf="!firstPage">
          <button type="button" (click)='previousPage()' class="btn btn-success">Previous</button>
        </div>
        <div class="col-2" *ngIf="!lastPage" style="margin-left: 150px;">
          <button type="button" (click)='nextPage()' class="btn btn-success">Next</button>
        </div>
      </div> -->
    </div>


    <div class="col-4">
      <!--<mat-form-field class="example-full-width" appearance="outline" style="color: black; width: 330px;">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search..." [(ngModel)]="filterData" (keyup)=search($event)>
      </mat-form-field> -->
    </div>
  </div>

  <div class="row">
    <!--<div class="col-md-4" *ngFor="let student of students;let i = index">
       <div class="CardBox">
        <img src="assets/customCard/card-gradient-img.png" class="CardBoxContentTopImg">
        <img src="assets/customCard/card-top-banner.png" class="CardBoxContentTopBanner">
        <div class="CardBoxContentTopName">
          <h3>{{student?.name}}</h3>
        </div>
        <div class="CardBoxContent">
          <h3>Email</h3>
          <p>{{student?.email}}</p>
        </div>

        <div class="CardBoxContent">
          <h3>Dicipline</h3>
          <p>{{student?.dicipline}}</p>
        </div>

        <div class="CardBoxContent">
            <h3>Role: </h3>
            <p>{{student?.role}}</p>
          </div> -->

        <!-- <div id="InstStudeID" class="collapse">

          <div class="CardBoxContent">
            <h3>Semester</h3>
            <p>{{student?.semester}}</p>
          </div>

          <div class="CardBoxContent">
            <h3>Stream</h3>
            <p>{{student?.stream}}</p>
          </div>

          <div class="CardBoxContent">
            <h3>Year: </h3>
            <p>{{student?.year}}</p>
          </div>


          <div class="CardBoxContent">
          <h3>Association With: </h3>
          <p>{{student?.is_member}}</p>
        </div>
        </div> -->

        <!-- <div class="CardBoxContent">
          <div class="row">
            <div class="col-md-6">
              <div class="form-button-action">
                <a (click)="openEditDialog(student)" data-toggle="tooltip" title=""
                  class="btn btn-link btn-primary btn-lg edit1" data-original-title="Edit Task">
                  <i class="fa fa-edit"> Edit</i>
                </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-button-action">
                <a (click)="deleteDialog(student)" data-toggle="tooltip" title=""
                  class="btn btn-link btn-danger deleteInst1" data-original-title="Remove">
                  <i class="fa fa-times"> Delete</i>
                </a>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="cardReadMore">

          <button data-toggle="collapse" data-target="#InstStudeID" *ngIf="readMore" (click)="readMore=!readMore">Read
            More</button>
          <button data-toggle="collapse" data-target="#InstStudeID" *ngIf="!readMore" (click)="readMore=!readMore">Read
            Less</button>

        </div> -->


      <!--</div>
    </div> -->
  </div>

  <mat-card class="mat-elevation-z6 proInstCardMat">
	<div class="row">
		<div class="col-md-4" *ngIf="this.ForWhom != 'institute_student'">
			<!-- <button mat-raised-button color='primary' (click)='openPresidentDialog()' class="schDetBut"><mat-icon>add</mat-icon> Add Student Presidents</button> -->
			<button mat-raised-button color='primary' (click)='openDialog()' class="schDetBut"><mat-icon>add</mat-icon> Add Student Members</button>
		</div>
		<div class="col-md-4">

		</div>
		<div class="col-md-4">
			<mat-form-field class="example-full-width instiFacSearch" appearance="outline" style="color: black; width: 330px;">
				<mat-label>Search</mat-label>
				<input matInput placeholder="Search..." [(ngModel)]="filterData" (keyup)=search($event)>
			</mat-form-field>
		</div>
	</div>

  <div class="rws-infotouser" *ngIf="studentStatus==0" style="padding-top: 20px;">
    <div class="alert alert-danger" role="alert">
      Following Student roles need to be assign/add.<br/>{{studentRoles}}
    </div>
  </div>


	<div class="row">
		<div class="col-md-12">
			<div class="tableBody">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
                <th><p class="anaTitle">Role</p></th>
								<th><p class="anaTitle">Name</p></th>
								<th><p class="anaTitle">Email</p></th>
								<th><p class="anaTitle">Discipline</p></th>								
								<th><p class="anaTitle">Semester</p></th>
								<th><p class="anaTitle">Stream</p></th>
								<th><p class="anaTitle">Year</p></th>
								<th><p class="anaTitle">Association With</p></th>
								<th><p class="anaTitle">Action</p></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let student of students;let i = index">
                <td><p class="anaContent">{{student?.role}}</p></td>
								<td><p class="anaContent">{{student?.name}}</p></td>
								<td><p class="anaContent">{{student?.email}}</p></td>
								<td><p class="anaContent">{{student?.dicipline}}</p></td>								
								<td><p class="anaContent">{{student?.semester}}</p></td>
								<td><p class="anaContent">{{student?.stream}}</p></td>
								<td><p class="anaContent">{{student?.year}}</p></td>
								<td><p class="anaContent">{{student?.is_member}}</p></td>
								<td>
									<!-- <div class="form-button-action">
										<a (click)="openEditDialog(faculty)" data-toggle="tooltip" title=""
										  class="btn btn-link btn-primary btn-lg edit1" data-original-title="Edit Task">
										  <i class="fa fa-edit"> Edit</i>
										</a>
									</div> -->

                  <button mat-icon-button="" class="btn-tbl-edit" (click)="addPresident(student)" title="Add President" *ngIf="checkForConvenor==1 && student?.isConvenorRole == 1 && isDisplay == 1 && student?.convenor_status == 0">
                    <mat-icon>add</mat-icon>
                  </button>
                  <button mat-icon-button="" class="btn-tbl-edit" (click)="addPresident(student)" title="Remove President" *ngIf="checkForConvenor==1 && student?.isConvenorRole == 1  && isDisplay == 1 && student?.convenor_status == 1">
                    <mat-icon>remove</mat-icon>
                  </button>

									<button mat-icon-button="" class="btn-tbl-edit" (click)="openEditDialog(student)"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span><span class="mat-button-focus-overlay"></span></button>

									<!-- <div class="form-button-action">
										<a (click)="deleteDialog(faculty)" data-toggle="tooltip" title=""
										  class="btn btn-link btn-danger deleteInst1" data-original-title="Remove">
										  <i class="fa fa-times"> Delete</i>
										</a>
									</div> -->

									<button mat-icon-button="" class="btn-tbl-delete" aria-label="delete" (click)="deleteDialog(student)">
									  <mat-icon>delete</mat-icon>
									</button>

								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
		    <mat-paginator #paginator
				  showFirstLastButtons
				  (page)="nextPage1($event)"
				  [length]="total_pages"
				  [pageIndex]="frontCurrentPageNo"
				  [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
			</mat-paginator>

		</div>
	</div>
</mat-card>

  <!-- <div *ngIf="students.length<1">
    <div class="card text-center text-white bg-info mb-4 card-shadow">
      <div class="card-body">
        <p class="card-text text-white"><i>No students added yet!</i></p>
      </div>
    </div>
  </div> -->
  <!-- </div> -->



</div>
