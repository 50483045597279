import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InstituteDashboard1819Service {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  
// 1-) GET:-https://qamicapi.aicte-india.org/api/2018-19Dasboard
// For more
// 2-) GET:-https://qamicapi.aicte-india.org/api/getActivityReport
// 3-) GET:-https://qamicapi.aicte-india.org/api/getAnnouncementReport
// 4-) GET:-https://qamicapi.aicte-india.org/api/getRankingReport
// 5-) GET:-https://qamicapi.aicte-india.org/api/getNotificationReport
// 6-) GET:-https://qamicapi.aicte-india.org/api/getStarPerformerReport

getCardsData() {
  return this.http.get(
    `${this.baseUrl}/2018-19Dasboard`
  );
}

getActivityList(){
  return this.http.get(
    `${this.baseUrl}/getActivityReport`
  );
}

getRankings(){
  return this.http.get(
    `${this.baseUrl}/getRankingReport`
  );
}

getNotifications(){
  return this.http.get(
    `${this.baseUrl}/getNotificationReport`
  );
}

getStarPerformers(){
  return this.http.get(
    `${this.baseUrl}/getStarPerformerReport`
  );
}

getActivityReport(data){
  return this.http.get(
    `${this.baseUrl}/activity/monthlyReportPdf18/`+data, { responseType: 'arraybuffer' }
  );
}

getAnnouncements(){
  return this.http.get(
    `${this.baseUrl}/getAnnouncementReport`
  );
}

}
