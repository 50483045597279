import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { InstituteRequestLogoService } from '../../../../Services/institute-request-logo.service';
import { FileService } from 'src/app/Services/file.service';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../../app.component';

@Component({
  selector: 'app-institute-request-logo',
  templateUrl: './institute-request-logo.component.html',
  styleUrls: ['./institute-request-logo.component.css'],
})
export class InstituteRequestLogoComponent implements OnInit {
  requestLogoDetails: string[] = [];

  baseImageUrl = myGlobals.baseImageUrl;

  per_page = 10;
  page = 1;
  filterModel = "All";

  constructor(
    public dialog: MatDialog,
    public requestLogoService: InstituteRequestLogoService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.requestLogoService
      .getRequestLogoDetails(this.page)
      .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data) {
    this.requestLogoDetails = [];
    data['data'].forEach((element) => {
      this.requestLogoDetails.push(element);
    });
  }

  nextPage() {
    this.page++;
    if (this.filterModel == "All") {
      this.getData();
    } else {
      this.changeFilter();
    }
  }

  previousPage() {
    this.page--;
    if (this.filterModel == "All") {
      this.getData();
    } else {
      this.changeFilter();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteRequestLogoDialog, {});

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  deleteLogo(id) {
    alert('Are you sure you want to delete ?');
  }

  changeFilter() {
    if (this.filterModel == "All") {
      this.getData();
    } else {
      this.requestLogoService.getLogoStatus(this.page, this.filterModel).subscribe(
        data => this.handleResponse(data)
      );
    }
  }

}

@Component({
  selector: 'app-institute-request-logo-dialog',
  templateUrl: 'institute-request-logo-dialog.html',
})
export class InstituteRequestLogoDialog implements OnInit {
  requestLogoForm: FormGroup;
  minDate: any;
  logoFile: FileList | null;

  constructor(
    private fileService: FileService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InstituteRequestLogoComponent>,
    public requestLogoService: InstituteRequestLogoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.requestLogoForm = this.formBuilder.group({
      event_title: [null, Validators.required],
      event_theme: [null, Validators.required],
      Target_Aud: [null, Validators.required],
      participants: [null, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      event_url: [null, Validators.required],
      no_of_participant: [null, Validators.required],
      namePhotoUpload: [null],
    });

    this.requestLogoForm.get('fromDate').valueChanges.subscribe(
      data => {
        this.minDate = data
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) {

    var logoFile =
      this.logoFile && this.logoFile.length ? this.logoFile[0] : null;

    var formData = new FormData();

    formData.append('bannerUpload_Logo', logoFile);
    formData.append(
      'event_title',
      this.requestLogoForm.get('event_title').value
    );
    formData.append(
      'event_theme',
      this.requestLogoForm.get('event_theme').value
    );
    formData.append('Target_Aud', this.requestLogoForm.get('Target_Aud').value);
    formData.append(
      'participants',
      this.requestLogoForm.get('participants').value
    );
    formData.append(
      'fromDate',
      this.changeDate(this.requestLogoForm.get('fromDate').value)
    );
    formData.append(
      'toDate',
      this.changeDate(this.requestLogoForm.get('toDate').value)
    );
    formData.append('event_url', this.requestLogoForm.get('event_url').value);
    formData.append(
      'no_of_participant',
      this.requestLogoForm.get('no_of_participant').value
    );

    if (this.requestLogoForm.valid) {
      this.requestLogoService
        .submitRequestLogoData(formData)
        .subscribe((data) => this.handleResponse(data));
    } else {
      this.toastr.error("Kindly fill all the details!");
    }
  }

  handleResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Kindly check the details!');
    }
  }

  changeDate(values) {

    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

}
