<div class="container-fluid">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;width: 100%;">
            IIC Previous Academic Year Report
        </h2>
    </div>

    <div class="refrredBox">
        <div class="row rws-listpage">
	        <div class="col-md-12">
                <!-- <p><a class="btn btn-primary referralBut" routerLink='/institute/atl-add-nominate-coordinator'>Add New</a></p> -->
                <div class="tableBody">
				    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead class="facultyTable">
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">Academic Year</th>
                                    <th scope="col">Activity Summary</th>
                                    <th scope="col">List of approved Activities</th>
                                    <!-- <th scope="col">Seprate Report</th> -->
                                    <!-- <th scope="col">Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let activity of coordinatorDetail;let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{activity?.acadmic_year}}</td>
                                    <td><a href="{{activity?.overall_report}}">Download</a></td>
                                    <td><a href="{{activity?.download_report}}">Download</a></td>
                                    <!-- <td><a href="{{activity?.other_url}}" *ngIf="activity?.other_url !=''">Download Report</a></td> -->
                                    <!-- <td>
                                        <button mat-button routerLink="/institute/atl-add-nominate-coordinator" [queryParams]="{id: activity?.id}" style="background-color: green; color: white;">
                                            Edit
                                        </button>                            
                                    </td> -->
                                </tr>
                                <tr *ngIf="coordinatorDetail?.length==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
                                background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                                There is no activity added yet.
                                </td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
