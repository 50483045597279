<div class="container">
    <mat-dialog-content class="mat-typography instiFactDialoge">
      <form [formGroup]="impactAnalysis" (ngSubmit)="onSubmit(impactAnalysis.value)">
      
      
        <div class="row">
          <div fxFlex="50" class="m-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Coordinator from My Council Faculty members*</mat-label>
              <mat-select formControlName="faculty" [(ngModel)]="facultyModel">           
                <mat-option *ngFor="let role of head" [value]="role?.facultyName">
                  {{role?.facultyName}}
                </mat-option>
              </mat-select>              
              <mat-error *ngIf="impactAnalysis.controls['faculty'].hasError('required')">Coordinator from My Council Faculty members is
                required</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" class="m-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon>
              <mat-label>Session</mat-label>
              <mat-select placeholder="Session" 
                [(ngModel)]="sessionModel" formControlName="session"  [disabled]="sessionModel" >
                <mat-option value="Session 1">Session 1</mat-option>
                <mat-option value="Session 2">Session 2</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>




        <div class="row">
          <div fxFlex="50" class="m-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon>
              <mat-label>Mode of the session</mat-label>
              <mat-select placeholder="Mode of the session" formControlName="mode" required
                [(ngModel)]="sessionModeModel">
                <mat-option value="Online">Online</mat-option>
                <mat-option value="Face to Face">Face to Face</mat-option>
              </mat-select>
              <mat-error *ngIf="impactAnalysis.controls['mode'].hasError('required')">
                Mode of the Session is
                required</mat-error>
            </mat-form-field>
          </div>
          
          <div fxFlex="50" class="m-2">
         
          <mat-form-field color="accent" appearance="outline">
            <mat-label>Tentative date</mat-label>
            <input matInput [matDatepicker]="picker2" readonly formControlName="date"  [min]="minDate" >
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
          </mat-form-field> </div>
        </div>

<div class="row">
    <div fxFlex="50" class="m-2">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <mat-label>Theme*</mat-label>
          <mat-select formControlName="theme" [(ngModel)]="themeModel">           
            <mat-option *ngFor="let role of theme" [value]="role?.id">
              {{role?.name}}
            </mat-option>
          </mat-select>              
          <mat-error *ngIf="impactAnalysis.controls['theme'].hasError('required')">Theme is
            required</mat-error>
        </mat-form-field>
      </div>



      <div fxFlex="50" class="m-2">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">assignment_turned_in
          </mat-icon>
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" placeholder="Title" required>
          <mat-error *ngIf="impactAnalysis.controls['title'].hasError('required')">Title is
            required</mat-error>
        </mat-form-field>
      </div>
    

</div>


<div *ngIf="!showCV">
  <div class="row">
    <div class="col-lg-8 col-md-10">
      <label>File Upload:</label>
      <mat-error>PDF(max 2MB)</mat-error>
    </div>

    <div class="col-lg-4 col-md-2 float-right">
      <label class="custom-file-label float-right" for="customFile" *ngIf="!externalCV">Choose file</label>
      <label class="custom-file-label float-right" for="customFile"
        *ngIf="externalCV">{{externalCV[0]['name']}}</label>
      <input #externalCVRef type="file" accept="application/pdf" name="externalCV"
        (change)="( externalCV = externalCVRef.files )" class="custom-file-input float-right" required>
    </div>

  </div>
</div>

<!-- <div *ngIf="showCV">
  <div class="row">
    <a href="{{baseImageUrl + report}}" target="_blank">
      <button type="button" class="btn btn-danger mt-2 mb-2">Download Report</button>
      <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showCV = !showCV">Upload
        Report</button>
    </a>
  </div>
</div> -->



        <div mat-dialog-actions>
  
          <button type="button" mat-raised-button color='warn' (click)='onNoClick()' style="margin-right: 320px;
              " class="btn btn-danger instituteBtnClose">Close</button>
          <button *ngIf="show1" mat-raised-button color='primary' class="btn btn-success float-right"
            [disabled]='impactAnalysis.invalid'>Submit</button>
  
          <button *ngIf="show" mat-raised-button color='primary' class="btn btn-success float-right"
            [disabled]='impactAnalysis.invalid'>Update</button>
  
  
        </div>
      </form>
    </mat-dialog-content>
  </div>






