<div *ngIf="ForWhom=='Institute' || ForWhom=='institute_student'" class="container">
    <!-- <ng-container *ngIf="!show">
        <div>
            <mat-card class="ww">
                <table mdbTable>
                    <thead class="black white-text">
                        <tr>
                            <th style="background-color: #720dd0; color: aliceblue; height: 35px; width: 985px;"
                                *ngFor="let head of headElements" scope="col">{{head}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr mdbTableCol>
                            <td>{{names}}</td>
                            <td>{{this.email}}</td>
                            <td>{{this.mobile_number}}</td>
                            <td>
                                <button mat-fab color="primary">
                                    <mat-icon (click)="toggle()">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card>
        </div>
    </ng-container> -->

    <div *ngIf="!show">
        <mat-card class="mat-elevation-z6 proInstCardMat">
            <div class="row m-4">
                <div class="col-md-12">
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Name</p>
                        </div>
                        <div class="col-md-4">
                            <p class="anaContent">{{names}}</p>
                        </div>
                        
                        <div class="col-md-4" *ngIf="this.ratingValue=='2'">
                            <button mat-raised-button type="button"  (click)="openDialog()" color="primary" class="editAisheCodeBut">Edit AISHE Code</button>
                                          </div>
                    </div>





                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Email</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.email}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Alternate Email</p>
                        </div>
                        <div class="col-md-8">
                            <p *ngIf="alternate_email == null" class="anaContent">NA</p>
                            <p *ngIf="alternate_email != null" class="anaContent">{{alternate_email}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Contact</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.mobile_number}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Alternate Contact</p>
                        </div>
                        <div class="col-md-8">
                            <p *ngIf="alternate_phone == null" class="anaContent">NA</p>
                            <p *ngIf="alternate_phone != null" class="anaContent">{{alternate_phone}}</p>
                        </div>
                    </div>
                    <div class="row btmLine" *ngIf="ForWhom=='Institute'">
                        <div class="col-md-4">
                            <p class="anaTitle"> </p>
                        </div>
                        <div class="col-md-8" >
                            <button type="button" class="btn btn-primary incubSubmit" (click)="toggle()">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <ng-container *ngIf="show">
        <mat-card class="m-4 mat-elevation-z6 hoiInstiDetials">
            <div class="row" color="primary">
                <h2 class="ss" style="text-align: center"> Update Head of Institute</h2>
            </div>
            <form [formGroup]="hoiForm" (ngSubmit)="onSubmit(hoiForm.value)">
                <div *ngIf="commentFlag1">
                    <div class="row m-4">
                        <div class="col-md-12">
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">AISHE Code:</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="anaContent">{{this.aishe_code}}</p>
                                </div>
                                    <!-- <div class="col-md-4" *ngIf="this.ratingValue=='2'">
                      <button mat-raised-button type="button"  (click)="openDialog()" color="primary" class="editAisheCodeBut">Edit AISHE Code</button>
                                    </div> -->
                               

        
                            </div>




                         




                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute Name:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.institute_name}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute Address: </p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{institute_address}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute City: </p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.institute_city}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute State:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent"> {{this.institute_state}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="commentFlag">
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="example-full-width" style="width: 290px; margin-left: 7% !important"
                                appearance="outline">
                                <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
                                <mat-label>Enter AISHE Code</mat-label>
                                <input matInput placeholder="Enter AISHE Code" formControlName="aishe_code" #aishe
                                    required>
                                <mat-error *ngIf="hoiForm.controls.aishe_code.errors?.required">AISHE Code is
                                    required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <button mat-raised-button (click)="getAisheDetails(aishe.value)" type="button"
                                class="getInstiDetails mt-4">Get Institute Details</button>
                        </div>
                        <div class="col-2 mt-4">
                            <a href="http://aishe.gov.in/aishe/aisheCode" target="_blank" style="font-weight: bold">Know
                                Your AISHE Code</a>
                        </div>
                    </div>

                    <div class="row m-4">
                        <div class="col-md-12">
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">AISHE Code:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.aishe_code}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute Name:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.institute_name}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute Address: </p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{institute_address}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute City: </p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.institute_city}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute State:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent"> {{this.institute_state}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-6" style="text-align: end">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Name of the Head of Institute</mat-label>
                            <input matInput placeholder="Name" formControlName="name" required
                                (keypress)=isCharKey($event)>
                            <mat-error *ngIf="hoiForm.controls.name.errors?.required">Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Contact</mat-label>
                            <input matInput formControlName="mobile_number" placeholder="Contact" minlength="10"
                                maxlength="10" required (keypress)=isNumberKey($event)>
                            <mat-error *ngIf="hoiForm.controls.mobile_number.errors?.required">Contact is
                                required</mat-error>
                            <mat-error *ngIf="hoiForm.controls.mobile_number.errors?.minlength">10 digits mobile
                                number only</mat-error>
                            <mat-error *ngIf="hoiForm.controls.mobile_number.errors?.maxlength">10 digits mobile
                                number only</mat-error>
                            <mat-error *ngIf="hoiForm.controls.mobile_number.errors?.pattern">Enter number only
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6" style="text-align: end">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Alternate Email</mat-label>
                            <input matInput placeholder="Name" formControlName="alternate_email" required
                                (keypress)=isSpace($event)>
                            <mat-error *ngIf="hoiForm.controls.alternate_email.errors?.required">Alternate Email is
                                required
                            </mat-error>
                            <mat-error *ngIf="hoiForm.controls.alternate_email.hasError('invalidEmail')">Invalid email
                                address</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Alternate Contact</mat-label>
                            <input matInput formControlName="alternate_phone" placeholder="Contact" minlength="10"
                                maxlength="10" required (keypress)=isNumberKey($event)>
                            <mat-error *ngIf="hoiForm.controls.alternate_phone.errors?.required">Contact is
                                required</mat-error>
                            <mat-error *ngIf="hoiForm.controls.alternate_phone.errors?.minlength">10 digits mobile
                                number only</mat-error>
                            <mat-error *ngIf="hoiForm.controls.alternate_phone.errors?.maxlength">10 digits mobile
                                number only</mat-error>
                            <mat-error *ngIf="hoiForm.controls.alternate_phone.errors?.pattern">Enter number only
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Institute Name</mat-label>
                            <input matInput formControlName="institute_name" placeholder="Institute Name" required>
                            <mat-error *ngIf="hoiForm.controls.institute_name.errors?.required">Institute Name is
                                required</mat-error>
                        </mat-form-field>
                    </div>
                </div>


                <div style="text-align: center">

                    <button type="button" color="warn" class="btn btn-danger instituteBtnClose" mat-raised-button
                        (click)="backPage()" style="margin-right:5px;">Back</button>

                    <button class="btn btn-success btnDownSuccess hoiUpdateBut" color="primary" mat-raised-button
                        [disabled]='hoiForm.invalid'>Update</button>
                </div>
            </form>
        </mat-card>
    </ng-container>
</div>




<div *ngIf="ForWhom=='School'" class="container">
    <!-- <ng-container *ngIf="!show">
        <div>
            <mat-card class="ww">
                <table mdbTable>
                    <thead class="black white-text">
                        <tr>
                            <th style="background-color: #720dd0; color: aliceblue; height: 35px; width: 985px;"
                                *ngFor="let head of headElements" scope="col">{{head}} </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr mdbTableCol>
                            <td>{{names}}</td>
                            <td>{{this.email}}</td>
                            <td>{{this.mobile_number}}</td>
                            <td>
                                <button mat-fab color="primary">
                                    <mat-icon (click)="toggle()">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card>
        </div>
    </ng-container> -->

    <div *ngIf="!show">
        <mat-card class="m-4 mat-elevation-z6 proInstCardMat">
            <div class="row">
                <!--<div class="col-md-12">
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Name</p>
                        </div>
                        <div class="col-md-4">
                            <p class="anaContent">{{names}}</p>
                        </div>
                        
                        <div class="col-md-4" *ngIf="this.ratingValue=='2'">
                            <button mat-raised-button type="button"  (click)="openDialog()" color="primary" class="editAisheCodeBut">Edit AISHE Code</button>
                                          </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Email</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.email}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Alternate Email</p>
                        </div>
                        <div class="col-md-8">
                            <p *ngIf="alternate_email == null" class="anaContent">NA</p>
                            <p *ngIf="alternate_email != null" class="anaContent">{{alternate_email}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Contact</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.mobile_number}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Alternate Contact</p>
                        </div>
                        <div class="col-md-8">
                            <p *ngIf="alternate_phone == null" class="anaContent">NA</p>
                            <p *ngIf="alternate_phone != null" class="anaContent">{{alternate_phone}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle"> </p>
                        </div>
                        <div class="col-md-8">
                            <button type="button" class="btn btn-primary incubSubmit" (click)="toggle()">Edit</button>
                        </div>
                    </div>
                </div> -->
				
				<div class="col-md-12">
				<div class="tableBody">
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th><p class="anaTitle">Name</p></th>
									<th><p class="anaTitle">Email</p></th>
									<th><p class="anaTitle">Alternate Email</p></th>
									<th><p class="anaTitle">Contact</p></th>
									<th><p class="anaTitle">Alternate Contact</p></th>
									<th><p class="anaTitle">Action</p></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td><p class="anaContent">{{names}}</p></td>
									<td><p class="anaContent">{{this.email}}</p></td>
									<td><p *ngIf="alternate_email == null" class="anaContent">NA</p>
										<p *ngIf="alternate_email != null" class="anaContent">{{alternate_email}}</p>
									</td>
									<td><p class="anaContent">{{this.mobile_number}}</p></td>
									<td><p *ngIf="alternate_phone == null" class="anaContent">NA</p>
										<p *ngIf="alternate_phone != null" class="anaContent">{{alternate_phone}}</p>
									</td>
									<td>
										<button mat-icon-button="" class="btn-tbl-edit" (click)="toggle()"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span><span class="mat-button-focus-overlay"></span></button>
									</td>
								</tr>				
							</tbody>
						</table>
					</div>
				</div>
				</div>
				
				
            </div>
        </mat-card>
    </div>

    <ng-container *ngIf="show">
        <mat-card class="m-4 mat-elevation-z6 hoiInstiDetials">
            <div class="row" color="primary">
                <h2 class="ss" style="text-align: center"> Update Head of Institute</h2>
            </div>
            <form [formGroup]="hoiForm" (ngSubmit)="onSubmit(hoiForm.value)">
                <div *ngIf="commentFlag1">
                    <div class="row m-4">
                        <div class="col-md-12">
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">AISHE Code:</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="anaContent">{{this.aishe_code}}</p>
                                </div>
                                    <!-- <div class="col-md-4" *ngIf="this.ratingValue=='2'">
                      <button mat-raised-button type="button"  (click)="openDialog()" color="primary" class="editAisheCodeBut">Edit AISHE Code</button>
                                    </div> -->
                               

        
                            </div>




                         




                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute Name:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.institute_name}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute Address: </p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{institute_address}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute City: </p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.institute_city}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute State:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent"> {{this.institute_state}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="commentFlag">
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="example-full-width" style="width: 290px; margin-left: 7% !important"
                                appearance="outline">
                                <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
                                <mat-label>Enter AISHE Code</mat-label>
                                <input matInput placeholder="Enter AISHE Code" formControlName="aishe_code" #aishe
                                    required>
                                <mat-error *ngIf="hoiForm.controls.aishe_code.errors?.required">AISHE Code is
                                    required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <button mat-raised-button (click)="getAisheDetails(aishe.value)" type="button"
                                class="getInstiDetails mt-4">Get Institute Details</button>
                        </div>
                        <div class="col-2 mt-4">
                            <a href="http://aishe.gov.in/aishe/aisheCode" target="_blank" style="font-weight: bold">Know
                                Your AISHE Code</a>
                        </div>
                    </div>

                    <div class="row m-4">
                        <div class="col-md-12">
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">AISHE Code:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.aishe_code}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute Name:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.institute_name}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute Address: </p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{institute_address}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute City: </p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent">{{this.institute_city}}</p>
                                </div>
                            </div>
                            <div class="row btmLine">
                                <div class="col-md-4">
                                    <p class="anaTitle">Institute State:</p>
                                </div>
                                <div class="col-md-8">
                                    <p class="anaContent"> {{this.institute_state}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-6" style="text-align: end">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Name of the Head of Institute</mat-label>
                            <input matInput placeholder="Name" formControlName="name" required
                                (keypress)=isCharKey($event)>
                            <mat-error *ngIf="hoiForm.controls.name.errors?.required">Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Contact</mat-label>
                            <input matInput formControlName="mobile_number" placeholder="Contact" minlength="10"
                                maxlength="10" required (keypress)=isNumberKey($event)>
                            <mat-error *ngIf="hoiForm.controls.mobile_number.errors?.required">Contact is
                                required</mat-error>
                            <mat-error *ngIf="hoiForm.controls.mobile_number.errors?.minlength">10 digits mobile
                                number only</mat-error>
                            <mat-error *ngIf="hoiForm.controls.mobile_number.errors?.maxlength">10 digits mobile
                                number only</mat-error>
                            <mat-error *ngIf="hoiForm.controls.mobile_number.errors?.pattern">Enter number only
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6" style="text-align: end">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Alternate Email</mat-label>
                            <input matInput placeholder="Name" formControlName="alternate_email" required
                                (keypress)=isSpace($event)>
                            <mat-error *ngIf="hoiForm.controls.alternate_email.errors?.required">Alternate Email is
                                required
                            </mat-error>
                            <mat-error *ngIf="hoiForm.controls.alternate_email.hasError('invalidEmail')">Invalid email
                                address</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width" style="width: 250px;" appearance="outline">
                            <mat-label>Alternate Contact</mat-label>
                            <input matInput formControlName="alternate_phone" placeholder="Contact" minlength="10"
                                maxlength="10" required (keypress)=isNumberKey($event)>
                            <mat-error *ngIf="hoiForm.controls.alternate_phone.errors?.required">Contact is
                                required</mat-error>
                            <mat-error *ngIf="hoiForm.controls.alternate_phone.errors?.minlength">10 digits mobile
                                number only</mat-error>
                            <mat-error *ngIf="hoiForm.controls.alternate_phone.errors?.maxlength">10 digits mobile
                                number only</mat-error>
                            <mat-error *ngIf="hoiForm.controls.alternate_phone.errors?.pattern">Enter number only
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>


                <div style="text-align: center">

                    <button type="button" color="warn" class="btn btn-danger instituteBtnClose" mat-raised-button
                        (click)="backPage()" style="margin-right:5px;">Back</button>

                    <button class="btn btn-success btnDownSuccess hoiUpdateBut" color="primary" mat-raised-button
                        [disabled]='hoiForm.invalid'>Update</button>
                </div>
            </form>
        </mat-card>
    </ng-container>
</div>