<div class="mt-2 container" *ngIf="admin; else elseBlock">
  
  <div class="container">
    <h1 class="announcementHeadingMain">Activity Scheduled</h1>
    <form [formGroup]="activitySubmit" (ngSubmit)="onSubmit()">
      <table class="table table-hover">
        <tr>
          <th><h3>1. Activity Type<span class="text-danger">*</span></h3></th>
          <td>
            <div class="form-group">
              <label for="activityType"></label>
              <select class="form-control" placeholder="Select Type" formControlName="type" required>
                <option value="" disabled selected>Select Type</option>
                <option value="MIC driven Activity">MIC driven Activity</option>
                <!-- <option value="Self-driven Activity">Self-driven Activity</option> -->
                <option value="IIC Calendar Activity">IIC Calendar Activity</option>
                <option value="Celebrations">Celebrations</option>
              </select>
            </div>
          </td>
        </tr>
    
        <tr>
          <th><h3>2. Activity Quarter<span class="text-danger">*</span></h3></th>
          <td>
            <div class="form-group">
              <label for="activityQuarter"></label>
              <select class="form-control" placeholder="Select Quarter" formControlName="quarter" required>
                <option value="" disabled selected>Select Quarter</option>
                <option value="Quarter I">Quarter I</option>
                <option value="Quarter II">Quarter II</option>
                <option value="Quarter III">Quarter III</option>
                <option value="Quarter IV">Quarter IV</option>
              </select>
            </div>
          </td>
        </tr>
    
        <tr>
          <th><h3>3. Activity Title<span class="text-danger">*</span></h3></th>
          <td>
            <input type="text" placeholder="Activity Title" formControlName="title" required class="form-control">
          </td>
        </tr>
    
        <tr>
          <th><h3>4. Activity Theme<span class="text-danger">*</span></h3></th>
          <td>
            <div class="form-group">
              <label for="activityTheme"></label>
              <select class="form-control" placeholder="Select Theme" formControlName="theme" required>
                <option value="" disabled selected>Select Type</option>
                <option value="Innovation">Innovation</option>
                <option value="IPR">IPR</option>
                <option value="Startup">Startup</option>
                <option value="R&D and Innovation">R&D and Innovation</option>
                <option value="Design Thinking & Critical Thinking">Design Thinking & Critical Thinking</option>
                <option value="Incubation and Pre Incubation">Incubation and Pre Incubation</option>
                <option value="IPR & Technology Transfer">IPR & Technology Transfer</option>
                <option value="Entrepreneurship & Startup">Entrepreneurship & Startup</option>
                <option value="Innovation & Design Thinking">Innovation & Design Thinking</option>
                <option value="Pre-Incubation & Incubation Management">Pre-Incubation & Incubation Management</option>
              </select>
            </div>
          </td>
        </tr>
    
        <tr>
          <th><h3>5. Start Date<span class="text-danger">*</span></h3></th>
          <td>
            <div class="form-group">
              <label for="startDate"></label>
              <input type="date" formControlName="startdate" required class="form-control">
            </div>
          </td>
        </tr>
    
        <tr>
          <th><h3>6. End Date<span class="text-danger">*</span></h3></th>
          <td>
            <div class="form-group">
              <label for="endDate"></label>
              <input type="date" formControlName="enddate" required class="form-control">
            </div>
          </td>
        </tr>

        <tr>
          <th><h3>7. File Upload<span class="text-danger">*</span></h3></th>
          <td>
            <div class="custom-file">
              <input 
                type="file" 
                accept="application/pdf" 
                (change)="onFileChange($event)" 
                id="fileUpload" 
                class="custom-file-input" 
                required
              />
              <label class="custom-file-label" for="fileUpload">Choose file</label>
            </div>
          </td>
        </tr>

        <tr>
          <th><h3>8. Description<span class="text-danger">*</span></h3></th>
          <td>
            <textarea formControlName="description" placeholder="Description" id="description" cols="30" rows="10" required class="form-control"></textarea>
          </td>
        </tr>
    
        <tr>
          <td colspan="2">
            <div class="text-center">
              <button type="submit" mat-raised-button color="primary" class="btn btn-success">Submit</button>
            </div>
          </td>
        </tr>
      </table>
    </form>    
  </div>
  
  </div>
  <p>&nbsp;</p>  
  <ng-template #elseBlock>
    <div>No Data Found</div>
  </ng-template>