<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">About MIC</h1>							
		</div>
	</div>
</div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">About MIC</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>About Us</a></li>
    <li class="breadcrumb-item active" aria-current="page">About MIC</li>
  </ul>
</nav>


<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss">
	   <div class="vc_col-md-12">
			<div class="headingAmbassador">
				<h2>Ministry of Education’s Innovation Cell (MIC)</h2>
			</div>
		</div>
		</div>
		<div class="vc_row innovationAmbassadorBox">
			<div class="vc_col-md-12">
				<div class="headingAmbassadorContent">
					<h3>Fostering a culture of Innovation & Progress</h3>												
				</div>
			</div>
			  <div class="vc_col-md-6">
				 <div class="about-box">				
					<p> Ministry of Education, Govt. of India has established its ‘Innovation Cell’ at All India Council for Technical Education with a purpose of systematically fostering the culture of Innovation in all Higher Education Institutions (HEIs) across the country.</p>
						
					<p>MoE’s Innovation Cell (MIC) is focusing to create and strengthen the culture of Innovation and entrepreneurship ecosystem across all HEIs to enable them in promoting and supporting student innovations and sustainable start-ups from academic institutions. </p>	

					<p>MoE’s Innovation Cell has launched and implementing various policy programs such as Smart India Hackathon, International Hackathons, Atal Ranking of Institutions on Innovation Achievements (ARIIA), establishment of Institution’s Innovation Councils (IICs) and National Innovation and Start-up Policy (NISP) for Higher Education Institutions (HEIs) to engage, encourage and channelize the creative energy and entrepreneurial potential of Indian youths to build innovations and enterprises. </p>				
				 </div>
			  </div>
			  <div class="vc_col-md-6">
				  <div class="about-img">
					<figure><img src="assets/assets/images/aboutImg.jpg" alt="img" class="img-fluid"></figure>				
				 </div>			
			  </div>
		  </div>
		  
		  <div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="iniMobResHeading">Initiatives of MoE’s Innovation Cell</h2>
				</div>
			</div>
			<div class="vc_col-md-6">
				<div class="headingAmbassadorContentAbt">
					<ul>
						<li>Institution’s Innovation Council</li>
						<li>Smart India Hackathon </li>
						<li>International Hackathons</li>								
					</ul>
				</div>
			</div>
			<div class="vc_col-md-6">
				<div class="headingAmbassadorContentAbt headingAmbassadorContentAbtMob">
					<ul>
						<li>National Innovation Start-up Policy </li>
						<li>YUKTI2.0</li>
						<li>Drug Discovery Hackathon</li>							
					</ul>
				</div>
			</div>
		</div>
	</div>	
 </div>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>