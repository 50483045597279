import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InstituteSuggestionService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) {}

  readPosts(sid){
    var data={
      id:sid
    }
    return this.http.post(`${this.baseUrl}/readPost`, data);
  }

  getSuggestionStats(){
    return this.http.get(
      `${this.baseUrl}/getsuggestionstats`);
  }

  submitSuggestion(data) {
    return this.http.post(`${this.baseUrl}/create-suggestion/submit`, data);
  }

  getSuggestion(pageNo,per_page=10) {
    return this.http.get(
      `${this.baseUrl}/list-suggestion/details?per_page=`+per_page+`&page=` + pageNo
    );
  }

  submitReply(data) {
    return this.http.post(
      `${this.baseUrl}/create-suggestion-reply/submit`,
      data
    );
  }

  getReplyList(suggestionId) {
    return this.http.get(
      `${this.baseUrl}/list-suggestion-reply/details?suggestion_id=` +suggestionId );
  }
  suggestionReason() {
    return this.http.get(
      `${this.baseUrl}/suggestionReason`);
  }
  getMandate(){
    return this.http.get(`${this.baseUrl}/getmandate`);
  }
  submitMandate(data){
    return this.http.post(`${this.baseUrl}/submitmandate`,data);
  }

  signedMandate(data){
    return this.http.post(`${this.baseUrl}/signedmandate`,data);

  }
  download(){
    return this.http.get(`${this.baseUrl}/downloadmandate`, { responseType: 'arraybuffer' });

  }
  downloadLetter(type){
    return this.http.get(`${this.baseUrl}/downloadmandateLetter?type=`+type, { responseType: 'arraybuffer' });

  }
}
