import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { AphVerificationDialogComponent } from 'src/app/components/users/institute/institute-dashboard2024/aph-verification-dialog/aph-verification-dialog.component';
import { TechnologyTransferDataComponent } from 'src/app/components/users/institute/institute-dashboard2025/technology-transfer-data/technology-transfer-data.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-institute-dashboard2025',
  templateUrl: './institute-dashboard2025.component.html',
  styleUrls: ['./institute-dashboard2025.component.css']
})
export class InstituteDashboard2025Component implements OnInit {

  IicStatus4o:any;
  IicInsType:any;
  approvalCode:any;
  approvalStatus:any;
  constructor(private dashboardService: InstituteDashboardService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getUserIic4oStatus();

    const dialogRef = this.dialog.open(TechnologyTransferDataComponent, {
      
    });
    dialogRef.afterClosed().subscribe(data => this.handleResponseTechnologyTransferDialog(data));
  }

  handleResponseTechnologyTransferDialog(data){
    
  }

  getUserIic4oStatus() {
    this.dashboardService.getUserIic4oStatus().subscribe(
      data => this.handleResponse(data)
    );
  }


  handleResponse(data) {
    this.IicStatus4o = data['data']['IicStatus4o'];
    this.IicInsType = data['data']['IicInsType'];
    this.approvalCode = data['data']['approvalCode'];
    this.approvalStatus = data['data']['approval_status'];
    if(this.approvalStatus == 'Pending'){
    // if(this.approvalStatus){
        const dialogRef = this.dialog.open(AphVerificationDialogComponent, {
          data : data
        });
        dialogRef.afterClosed().subscribe(data => this.handleResponseDialog(data));
    }
  }

  handleResponseDialog(data){

  }

}
