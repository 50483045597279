import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../app/app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-iic-web-navbar',
  templateUrl: './iic-web-navbar.component.html',
  styleUrls: ['./iic-web-navbar.component.css']
})
export class IicWebNavbarComponent implements OnInit {

  baseUrl = myGlobals.baseUrl1;
  imageBasePath= myGlobals.imageBasePath;

  constructor(private router: Router) { }

  hasRoute(route: string) {
    return this.router.url == route;
  }

  ngOnInit(): void {
  }

}
