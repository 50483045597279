<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

<link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
<link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">

<style>
  .navbar-nav>li {
    float: none !important;
    text-align: center !important;
    line-height: 24px;
  }
</style>

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <span class="login100-form-title p-b-20">
        <img src="assets/institute/images/logoiic.png" class="loginIICLogo signupIICLogo">
      </span>
      <span class="login100-form-title loginInstiTopContent">
        <h2>ATL Sign Up</h2>
      </span>
      <form id="signup-form" class="form-horizontal" [formGroup]="signupForm" (submit)="onSubmit(signupForm.value)"
        style="color: black !important;">
        <div [hidden]="!error">
          {{error}}
        </div>

        <div class="form-group">
          <mat-form-field class="w-100" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Select State</mat-label>
            <mat-select formControlName="institute_state" placeholder="State" required>
              <mat-option *ngFor="let st of state" value="{{st.id}}">
                {{st?.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="signupForm.controls.institute_state.errors?.required">School of Institute is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="w-100" appearance="outline">
          <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <mat-label>Select School</mat-label>
          <mat-select formControlName="aishe_code" placeholder="Select School" required>
            <mat-option *ngFor="let st of institute" value="{{st.atl_code}}">
              ATL ID: {{st?.atl_code}}, {{st?.school_name}}, {{st?.district}}</mat-option>
          </mat-select>
          <mat-error *ngIf="signupForm.controls.aishe_code.errors?.required">School Name
            is required
          </mat-error>
        </mat-form-field>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">account_balance</mat-icon>
            <mat-label>Name of School</mat-label>
            <input matInput placeholder="Name of School" formControlName="institute_name" required>
            <mat-error *ngIf="signupForm.controls.institute_name.errors?.required">Name of School is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">article</mat-icon>
            <mat-label>Address of School</mat-label>
            <input matInput placeholder="Enter Address" formControlName="institute_address" required>
            <mat-error *ngIf="signupForm.controls.institute_address.errors?.required">Enter Address is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">confirmation_number</mat-icon>
            <mat-label>City of School</mat-label>
            <input matInput placeholder="City of School" formControlName="institute_city" required (keypress)="isCharKey($event)">
            <mat-error *ngIf="signupForm.controls.institute_city.errors?.required">City of School is required
            </mat-error>
          </mat-form-field>
        </div>         

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Name of the Head of School</mat-label>
            <input matInput placeholder="Name of the Head of School" formControlName="name" required>
            <mat-error *ngIf="signupForm.controls.name.errors?.required">Name of the Head of School is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">mail</mat-icon>
            <mat-label>Email Id of the Head of School</mat-label>
            <input matInput placeholder="Email Id of the Head of School" formControlName="email" required
              (keypress)="isSpace($event)">
            <mat-error *ngIf="signupForm.controls.email.errors?.required">Email Id of the Head of School is required
            </mat-error>
            <mat-error *ngIf="signupForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stay_current_portrait</mat-icon>
            <mat-label>Mobile Number of the Head of School</mat-label>
            <input matInput placeholder="Mobile Number of the Head of School" formControlName="mobile_number"
              required minlength="10" maxlength="10" (keypress)="isNumKey($event)">
            <mat-error *ngIf="signupForm.controls.mobile_number.errors?.required">Mobile Number of the Head of School
              is
              required
            </mat-error>
            <mat-error *ngIf="signupForm.controls.mobile_number.errors?.minlength">Mobile Number should be of 10 digits
            </mat-error>
          </mat-form-field>
        </div>

              

                

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">lock</mat-icon>
            <mat-label>Enter Password</mat-label>
            <input matInput placeholder="Enter Password" formControlName="password" type="password" required>
            <!-- <mat-error>Password must contain one uppercase, one lowercase and minimum length should be 8 characters</mat-error> -->
            <mat-error *ngIf="signupForm.controls.password.errors?.required">Enter Password is required
            </mat-error>
          </mat-form-field>
          <mat-error style="margin-left: 15px;
      ">Password must contain one uppercase, one lowercase,one special characters(@!$#%*%&) and minimum length should be 8 characters</mat-error>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">vpn_key</mat-icon>
            <mat-label>Enter Confirm Password</mat-label>
            <input matInput placeholder="Enter Confirm Password" formControlName="confirm_password" type="password"
              required>
            <mat-error *ngIf="signupForm.controls.confirm_password.errors?.required">Confirm Password is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mb-4" id="captcha-box">
          <re-captcha (resolved)="resolved($event)" siteKey="6Lc0dcoZAAAAAGVDDIpIcXdxnFmpl_8iCDhv56hT">
          </re-captcha>
        </div>

        <div class="col-md-6">
          <button class="login100-form-btnSignUp" mat-raised-button type="submit"
            [disabled]="signupForm.invalid">Submit</button>
        </div>
        <div class="col-md-6" style="margin-top: 2%;">
          <a [routerLink]="['/login']" routerLinkActive="router-link-active" style="float:right;color:#1a73e8;">Sign in instead</a>
        </div>

      </form>
    </div>
  </div>
</div>
