import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnlinePagesInnerService } from 'src/app/Services/onlineSession/online-pages-inner.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/app.component';

@Component({
  selector: 'app-osia-request-reset',
  templateUrl: './osia-request-reset.component.html',
  styleUrls: ['./osia-request-reset.component.css']
})
export class OsiaRequestResetComponent implements OnInit {

  public error = [];
  public ResetPasswordForm: FormGroup;
  public form = {
    email: null,
    password: null,
    confirm_password: null,
	new_password: null,
    token: null,
  };

  constructor(private route: ActivatedRoute,
    private onlinepagesinnerService: OnlinePagesInnerService,
	private router: Router,
    private toastr: ToastrService,
	private fb: FormBuilder) {
    
  }

  onSubmit() {
    this.onlinepagesinnerService.changePassword(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    )
  }

  handleResponse(data) {
    if (data['status'] == 0) {
      this.toastr.error(data['msg']);
    } else {
      this.toastr.success("Password changed sucessfully !");
      this.router.navigateByUrl('/onlinetraining-for-IA');
    }

  }

  handleError(error) {
    if (error.error.errors.email != null)
      //this.showError(error.error.errors.email);

      if (error.error.errors.password != null) {
        //this.showError(error.error.errors.password);
      }
  }

  ngOnInit() {
	  this.ResetPasswordForm = this.fb.group({
      
      email: [null, Validators.compose([Validators.required, emailValidator])],
	  password: [null, Validators.required],
      new_password: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/()\\[\\{}])(?=.*[A-Z])(?=.*[a-z]).*$')])],
      confirm_password: [null, Validators.compose([Validators.required, Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/])(?=.*[A-Z])(?=.*[a-z]).*$')])],
    });
  }


}
