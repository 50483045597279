import { Component, Inject, OnInit } from '@angular/core';
import { OnlinePagesInnerService } from 'src/app/Services/onlineSession/online-pages-inner.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-online-session-video',
  templateUrl: './online-session-video.component.html',
  styleUrls: ['./online-session-video.component.css']
})
export class OnlineSessionVideoComponent implements OnInit {
  ses_id :any;
  list: any;
  player: YT.Player;

  public video: any;

  startTime: any;
  currentVideoTime: any;

  playerVars: {
    autoplay: 0,
    modestbranding: 0,
    controls: 0,
    disablekb: 0,
    rel: 0,
    showinfo: 0,
    fs: 0,
    playsinline: 0,
  }
  watchStatus: number=0;
   show: boolean=false;
  assessment: any;
  constructor(private route: ActivatedRoute, private toastr: ToastrService,
     public dialog: MatDialog,
    private onlinePagesInnerService: OnlinePagesInnerService) {
    this.route.params.subscribe((params) => {
      this.ses_id = params['ses_id'];
    });
   }

  ngOnInit(): void {

    this.getData();

  }



  savePlayer(event) {

console.log("player state",event.target.getPlayerState());

  }

  onPlayerStateChange(event){
    var player;
    if(event.target.getPlayerState()=="0"){
      console.log("video Ended");
    }

  }

  onStateChange(event){
    this.currentVideoTime = event.target.getCurrentTime();
    if(this.currentVideoTime>0){
    this.onlinePagesInnerService.resumeVideo(this.ses_id,this.currentVideoTime).subscribe(
      data => {
        this.handleResponse(data)
      })}

if(event.target.getDuration()>"0"){
    if( this.currentVideoTime==event.target.getDuration()){
this.watchStatus=1;
    this.onlinePagesInnerService.watchStatus(this.ses_id,this.watchStatus).subscribe(
      data => {
        this.handle(data)
      })
    }}
  }

  openDialog() {
    const dialogRef = this.dialog.open(OnlineSessionVideoDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      // this.getExternal();

    });
  }

handle(data){
  this.openDialog();
  this.toastr.success("You have watched this Video");
  this.show=true;

}
  handleResponse(data){
if(data['status']=="0"){
  this.startTime=this.currentVideoTime
} }

  getData() {
    this.onlinePagesInnerService.getVideoSessionDetails(this.ses_id).subscribe(
      data => {
        this.list = data['data'];
        this.video =data['data']['0']['video_id'];
        this.assessment=data['data']['0']['assisment'];
        if(((data['data']['0']['pause_time'])==null)||((data['data']['0']['pause_time'])==undefined)){
        this.startTime=data['data']['0']['start_time']
      }else{
        this.startTime=data['data']['0']['pause_time']
      }
      if((data['data']['0']['watch_status'])=='1'){
        this.show=true;
      }
    }
  );
  }
}


///Dialog Component



@Component({
  selector: 'app-online-session-video-dialog',
  templateUrl: './online-session-video-dialog.html',
  // styleUrls: ['./institute-declare-semesters.component.css']
})

export class OnlineSessionVideoDialogComponent implements OnInit {
  // id: any;

  constructor(public dialog: MatDialog,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<OnlineSessionVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // this.id = this.data['id'];
    // this.getDeclareSemesters(this.currentPageNo);
  }



  handleData(data) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}









