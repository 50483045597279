import { Component, OnInit } from '@angular/core';
import baguetteBox from 'baguettebox.js';
import * as myGlobals from '../../../../../../app/app.component';
@Component({
  selector: 'app-photo-gallery-bangalore',
  templateUrl: './photo-gallery-bangalore.component.html',
  styleUrls: ['./photo-gallery-bangalore.component.css']
})
export class PhotoGalleryBangaloreComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
     baguetteBox.run('.gallery', {
    captions: true, // display image captions.
    buttons: 'auto', // arrows navigation
    fullScreen: false,
    noScrollbars: false,
    bodyClass: 'baguetteBox-open',
    titleTag: false,
    async: false,
    preload: 2,
    animation: 'fadeIn', // fadeIn or slideIn
    verlayBackgroundColor: 'rgba(0,0,0,.8)'
    });
  }

}
