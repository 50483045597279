import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import * as myGlobals from '../../../../../../app.component';
import { AtalActivityProposalDialogComponent } from '../atal-activity-proposal-dialog/atal-activity-proposal-dialog.component';

@Component({
  selector: 'app-atal-activity-form3',
  templateUrl: './atal-activity-form3.component.html',
  styleUrls: ['./atal-activity-form3.component.css']
})
export class AtalActivityForm3Component implements OnInit {
  activites:any[];
  edit: boolean;
  editData: any;
  id: any;
  report: FileList | null;
  public addActivityForm3: FormGroup;
  date1:any;
  file_upload: any;
  speaker_profile2:any;
  reportStatus: boolean;
  minDate = "2024-09-01";
  maxDate = "2026-03-31";

  speaker_profile: FileList | null;
  speaker_profileStatus: boolean;

  old_data:any;
  old_data_count:any;

  // baseImageUrl = myGlobals.baseImageUrl2;
  baseImageUrl = myGlobals.baseUrl1;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AtalActivityProposalDialogComponent>,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public activity: InstituteActivityService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.edit = false;

      if (this.data['activity_id']!= undefined || this.data['activity_id']!= null) {
        this.edit = true;

        this.id = this.data['activity_id'];
      }
      else {
        this.edit = false;
        this.id = 'Add';
      }
     }

  ngOnInit(): void {

    this.file_upload = "";
    this.speaker_profile2 = "";

     this.addActivityForm3 = this.fb.group({
      'session_date': [null,Validators.required],
      'session_time': [null,Validators.required],
      'quarter': [null,Validators.required],
      'speaker_name': [null],
      'designation':[null],
      'meeting_id':[null],
      'iic_activity':[null],
      'session':[null],
     });

     this.old_data="0";
     this.old_data_count="0";

     this.activity.getSchedule("All").subscribe(data=>this.setData2(data));

     if(this.id!='undefined'){
      this.activity.getSchedule(this.id).subscribe(data=>this.setData(data));
    }
     this.activity.getActivity().subscribe(data=>this.setActivity(data));


  }
  setData(data){
    this.editData=data;
    this.addActivityForm3.controls['session_date'].setValue(new Date(data['Activity']['scheduled_date']));
    this.addActivityForm3.controls['session_time'].setValue(data['Activity']['scheduled_time']);
    this.addActivityForm3.controls['speaker_name'].setValue(data['Activity']['speaker_name']);
    this.addActivityForm3.controls['designation'].setValue(data['Activity']['speaker_designation']);
    this.addActivityForm3.controls['meeting_id'].setValue(data['Activity']['meeting_id']);
    this.addActivityForm3.controls['iic_activity'].setValue(data['Activity']['iic_activity']);
    this.addActivityForm3.controls['session'].setValue(data['Activity']['activity']);
    this.addActivityForm3.controls['quarter'].setValue(data['Activity']['quarter']);
    this.file_upload=data['Activity']['file_upload'];
    this.speaker_profile2=data['Activity']['speaker_profile'];

if(this.file_upload!=null){
  this.reportStatus=true;
}

if(this.speaker_profile2!=null){
  this.speaker_profileStatus=true;
}



 }

setData2(data){

     this.old_data="0";
     this.old_data_count=data["Activity_Count"];

     if(this.old_data_count==1)
     {
      this.old_data = data["tActivity"][0];
     }
     else
     {

     }

}



  onSubmit(data){


    var cvFile =
      this.report && this.report.length ? this.report[0] : null;

    var cvFile2 =
      this.speaker_profile && this.speaker_profile.length ? this.speaker_profile[0] : null;

    if(cvFile==null && cvFile==undefined && this.file_upload=="") { alert("Please upload the Activity Schedule."); return false; }

    var formData = new FormData();

    alert(this.id);

    formData.append('file', cvFile);
    formData.append('file2', cvFile2);
    formData.append('as_id', this.id);
    formData.append('activity', '3');
    formData.append('session_date',this.changeDate(this.addActivityForm3.get('session_date').value));
    formData.append('speaker_name',this.addActivityForm3.get('speaker_name').value);
    formData.append('session_time',this.addActivityForm3.get('session_time').value);
    formData.append('designation',this.addActivityForm3.get('designation').value);
    formData.append('meeting_id',this.addActivityForm3.get('meeting_id').value);
    formData.append('iic_activity',this.addActivityForm3.get('iic_activity').value);
    formData.append('session',this.addActivityForm3.get('session').value);
    formData.append('quarter',this.addActivityForm3.get('quarter').value);
    formData.append('old_speaker_profile',this.speaker_profile2);
    formData.append('atl_userId',"");
    formData.append('id','3');

     if (!this.edit) {
      this.activity
    .AtlSchedule(formData)
    .subscribe((data) => this.handleResponse(data));
      }

    else {

    formData.append('file_upload_hidden',this.editData['Activity']['file_upload']);
    this.activity
    .AtlSchedule(formData)
    .subscribe((data) => this.handleResponse(data));
    }



  }
  handleResponse(data){
    if(data['status']=="1")
    {
      this.toastr.success("Successfully Submitted");
      this.dialogRef.close();
    }

  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  setActivity(data){

    this.activites=[];

    if(this.old_data_count==1)
     {
      for(let i=0;i<data['Activities'].length;i++){
        if(this.old_data!=data['Activities'][i]["id"])
        {
          this.activites.push(data['Activities'][i]);
        }
      }
     }
     else
     {
      for(let i=0;i<data['Activities'].length;i++){
        this.activites.push(data['Activities'][i])
      }
     }




  }
}
