<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Calender 2020-21</h1> -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Calender 2020-21</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Calender 2020-21</li>
  </ul>
</nav>

<section class="iicCalenderBoxSec">
	<div class="container">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">IIC Activities Calendar for Academic Year 2020-21</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="vc_col-md-12 iicActiTop">
				<p class="iicCalFirstText">IIC calendar plan with various new features will be introduced from September 2020. To make comprehensive impact in field of innovation, IPRs and Entrepreneurship following measures are introduced in IIC 2020-21 edition. </p>
				<p class="iicCalFirstText1"><strong>Considering the current COVID-19 situation</strong></p>
				<ul>
					<li>quarter 1 and quarter2 will be merged and institutes can conduct the activities accordingly.</li>
					<li>Mandatory activities are introduced to support and make awareness of IIC National Innovation Contest. </li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-md-3">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
				  <li><h3 class="tabHeadingCal">Semester 1</h3></li>
				  <li class="nav-item">
					<a class="nav-link active" data-toggle="tab" href="#quarter1" role="tab" aria-controls="home">Quarter 1</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter2" role="tab" aria-controls="profile">Quarter 2</a>
				  </li>
				  <li><h3 class="tabHeadingCal">Semester 2</h3></li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter3" role="tab" aria-controls="messages">Quarter 3</a>
				  </li>
				  <li class="nav-item">
					<a class="nav-link" data-toggle="tab" href="#quarter4" role="tab" aria-controls="settings">Quarter 4</a>
				  </li>
				</ul>
			</div>
			<div class="col-md-9">
				<div class="tab-content">
				  <div class="tab-pane active" id="quarter1" role="tabpanel">
						<div id="quarter1" class="tabcontent">
						<div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 1 </h3>
						  </div>
						  <div class="col-md-8" style="text-align:center;"><span  style="font-size: 15px;color:#252b50;font-weight: 900;">Thrust Area :</span><span style="font-size: 16px;color:#c71e23;font-weight: 900;">Inspiration, Motivation and Ideation</span></div>
						 </div>
						  <!-- <p>London is the capital city of England.</p> -->
						  <table class="table table-bordered dataTable no-footer table-responsive">
                <thead>
                  <tr>
                  <th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
                  <th scope="col" class="sorting">Activity</th>
                  <th scope="col" class="sorting">Mandatory/Elective</th>
                  <th scope="col" class="sorting">Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let quarter of quarter_1;let i = index">
                  <th scope="row">{{i+1}}</th>
                  <td><strong>{{quarter?.title}}</strong></td>
                  <td><strong>{{quarter?.mandatory_elective}}</strong></td>
                  <td>
                  <a _ngcontent-uby-c4="" href="{{ baseUrl }}/uploads/institutes/IICActivitiesCalendar/{{quarter?.attachmentPath}}" target="_blank">Activity Guideline</a>
                  </td>
                  </tr>
                </tbody>
                </table>
						</div>

				  </div>
				  <div class="tab-pane" id="quarter2" role="tabpanel">
					<div id="quarter2" class="tabcontent">
					  <div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 2 </h3>
						  </div>
						  <div class="col-md-8" style="text-align:center;"><span  style="font-size: 15px;color:#252b50;font-weight: 900;">Thrust Area :</span><span style="font-size: 16px;color:#c71e23;font-weight: 900;">Validation and Concept Development</span></div>
						 </div>
             <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Activity</th>
								<th scope="col" class="sorting">Mandatory/Elective</th>
								<th scope="col" class="sorting">Download</th>
							  </tr>
							</thead>
							<tbody>
							  <tr  *ngFor="let quarter of quarter_2;let i = index">
								<th scope="row">{{i+1}}</th>
								<td><strong>{{quarter?.title}}</strong></td>
								<td><strong>{{quarter?.mandatory_elective}}</strong></td>
								<td>
								<a _ngcontent-uby-c4="" href="{{ baseUrl }}/uploads/institutes/IICActivitiesCalendar/{{quarter?.attachmentPath}}" target="_blank">Activity Guideline</a>
								</td>
							  </tr>
							</tbody>
						  </table>
					 </div>

				  </div>
				  <div class="tab-pane" id="quarter3" role="tabpanel">
					<div id="quarter3" class="tabcontent">
					  <div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 3 </h3>
						  </div>
						  <div class="col-md-8" style="text-align:center;"><span  style="font-size: 15px;color:#252b50;font-weight: 900;">Thrust Area :</span><span style="font-size: 16px;color:#c71e23;font-weight: 900;">Prototype, Design, Process Development for  Business Model/ Process/ Services</span></div>
						 </div>
             <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Activity</th>
								<th scope="col" class="sorting">Mandatory/Elective</th>
								<th scope="col" class="sorting">Download</th>
							  </tr>
							</thead>
							<tbody>
							  <tr  *ngFor="let quarter of quarter_3;let i = index">
								<th scope="row">{{i+1}}</th>
								<td><strong>{{quarter?.title}}</strong></td>
								<td><strong>{{quarter?.mandatory_elective}}</strong></td>
								<td>
								<a _ngcontent-uby-c4="" href="{{ baseUrl }}/uploads/institutes/IICActivitiesCalendar/{{quarter?.attachmentPath}}" target="_blank">Activity Guideline</a>
								</td>
							  </tr>
							</tbody>
						  </table>
					 </div>

				  </div>
				  <div class="tab-pane" id="quarter4" role="tabpanel">
					<div id="quarter4" class="tabcontent">
						<div class="col-md-12">
						<div class="col-md-4">
						  <h3 class="calenderHeadingQuarter">Quarter 4 </h3>
						  </div>
						  <div class="col-md-8" style="text-align:center;"><span  style="font-size: 15px;color:#252b50;font-weight: 900;">Thrust Area :</span><span style="font-size: 16px;color:#c71e23;font-weight: 900;">Awareness about Startup and related Ecosystem</span></div>
						 </div>
             <table class="table table-bordered dataTable no-footer table-responsive">
							<thead>
							  <tr>
								<th scope="col" class="sorting" style="width: 4% !important;">Sr. No.</th>
								<th scope="col" class="sorting">Activity</th>
								<th scope="col" class="sorting">Mandatory/Elective</th>
								<th scope="col" class="sorting">Download</th>
							  </tr>
							</thead>
							<tbody>
							  <tr  *ngFor="let quarter of quarter_4;let i = index">
								<th scope="row">{{i+1}}</th>
								<td><strong>{{quarter?.title}}</strong></td>
								<td><strong>{{quarter?.mandatory_elective}}</strong></td>
								<td>
								<a _ngcontent-uby-c4="" href="{{ baseUrl }}/uploads/institutes/IICActivitiesCalendar/{{quarter?.attachmentPath}}" target="_blank">Activity Guideline</a>
								</td>
							  </tr>
							</tbody>
						  </table>
					  </div>

				  </div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="vc_row-full-width vc_clearfix"></div>

<section class="ratingScoringBox">
	<div class="container">
		<div class="vc_row wpb_row vc_row-fluid vc_custom_1497359159460">
					<div class="wpb_column vc_column_container vc_col-sm-12">
					<div class="vc_row innovationAmbassadorBox ambPadding1">
						<div class="vc_col-md-12">
							<div class="headingAmbassador">
								<h2 class="incubTabHeading">Rating and Scoring System</h2>
							</div>
						</div>
					</div>
						<div class="vc_column-inner vc_custom_1499083301093">
							<div class="wpb_wrapper">
								<div class="linp-post-list  linp_post_list-75236859249a281716f5a04bfd4b0c84 horizontal">
									<div class="vc_row">
										<div class="vc_col-md-7">
											<div class="ratingContent">
												<h3>All Activities to be done by IICs are categorized in three types-</h3>
												<ul>
													<li>IIC Calendar Activity</li>
													<li>MIC Driven Activity</li>
													<li>Self- Driven Activity</li>
												</ul>
												<p><strong>IIC Calendar Activity – </strong> MoE's Innovation Cell (MIC) prescribes an annual activity calendar for IICs. In each quarter Minimum 3 activities out of scheduled activities are to be completed by an IIC to receive full score. Institutes are encouraged to do a greater number of activities from calendar beyond the prescribed minimum. It is expected that institutes which are in the emerging phase of the innovation ecosystem- will follow the calendar plan vividly.</p>
												<p><strong>MIC Driven Activity - </strong> MIC schedules various activities from time to time on its own or  in collaboration with partner organization and government departments.All MIC Driven Activities are mandatory.
                                                These activities are scheduled on IIC portal under MIC Driven Activity tab.</p>
												<p><strong>Self- Driven Activity - </strong> IICs are encouraged to plan their own activities apart from IIC calendar for promoting entrepreneurship and innovation in their campuses. They may have collaborations with different agencies, departments, and Industries for organizing seminars, competitions, workshops, training sessions relevant to entrepreneurship and innovation.To score maximum marks/star in a particular quarter, it is necessary to complete atleast 3 activities under Self Driven Category.</p>
											</div>
										</div>
										<div class="vc_col-md-5">
											<div class="ratingContent1">
												<img src="assets/assets/images/graph-image.png" class="img-responsive">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	</div>
</section>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
