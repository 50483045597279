import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../../app/app.component';
@Component({
  selector: 'app-iicwebinar',
  templateUrl: './iicwebinar.component.html',
  styleUrls: ['./iicwebinar.component.css']
})
export class IicwebinarComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
  }

}
