import { Injectable } from '@angular/core';
import * as myGlobals from '../../app.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class OnlinePagesInnerService {
  private baseUrl = myGlobals.baseUrl;
  private baseUrlOSIA = myGlobals.baseUrlOSIA;

  constructor(private http: HttpClient) { }

  getRemSessionList(){
    return this.http.get(
      `${this.baseUrl}/osia/rem-session-list`);
  }

  getVideoSessionDetails(sesId){
    return this.http.get(`${this.baseUrl}/osia/session-detail-page/` + sesId
    );
  }
  resumeVideo(id,pause){
    const data1 ={'ses_id':id, 'paused':pause}
    return this.http.post(`${this.baseUrl}/osia/resumeVideo`,data1);
  }

  watchStatus(id,status){
    const data1 ={'ses_id':id, 'watch_status':status }
    return this.http.post(`${this.baseUrl}/osia/watchStatus`,data1);
  }
  assessmentFormStatus(id,assessment_form_status){
    const data1 ={'online_session_1_id':id, 'assessment_form_status':assessment_form_status }
    return this.http.post(`${this.baseUrl}/osia/assessmentFormStatusUpdate`,data1);
  }


  videoCount(){
    return this.http.get(`${this.baseUrl}/osia/session-video-count`);
  }
  changePassword(data) {
    let headers = new HttpHeaders();
    const authToken = 'Bearer ' + data['resetToken'];

    headers.append('Authorization', authToken);
    return this.http.post(`${this.baseUrlOSIA}/osiaResetPassword`, data, {
      headers: headers
    });
  }


  // Session-list


  getSessionList() {
    return this.http.get(`${this.baseUrl}/getNICTraining`);
  }


}
