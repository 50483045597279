import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-transfer-existing-innovator-list',
  templateUrl: './transfer-existing-innovator-list.component.html',
  styleUrls: ['./transfer-existing-innovator-list.component.css']
})
export class TransferExistingInnovatorListComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  page: any;
  totalcount = 0;

  transferInnovation:any;

  constructor(private fb: FormBuilder, private instituteMaster: InstituteInnovationContestService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.yuktiChallenge();
  }
  yuktiChallenge() {
    this.instituteMaster.getTransferInnovation().subscribe(
      data => this.handleResponse(data)
    );
  
  }
  handleResponse(data){
    this.transferInnovation = data;
  }
  onClickEvent(id){
    if(confirm('Are you sure went to remove nomination.') === true){
      this.instituteMaster.removeTransferInnovation(id).subscribe(
        data => this.handleChallengeRemoveResponse(data)
      );
    }else{

    }
  }
  handleChallengeRemoveResponse(data){
    if(data['status'] == 1){
      this.yuktiChallenge();
      this.toastr.success('Remove Successfully');
    }else{
      this.toastr.error('Something went Wrong.');
    }
  }
  public onPageChanged(event) {
    this.page = event;
  }

  sendReminder(id){
    
    this.instituteMaster.transferInnovationReminder(id).subscribe(
      data => this.handleResponseReminder(data));
  }

  handleResponseReminder(data){
    if(data['status'] == 1){
      this.toastr.success(data['msg']);
    }else{
      this.toastr.error(data['msg']);
    }
  }
}
