<!-- <section class="sugListNew">
         <div class="container">
             <div class="row">
                 <div class="col-md-12">
                     <h1 class="article-title">Suggestion List</h1>
                 </div>
             </div>
         </div>

    </section> -->

    <section class="suggestionListMain">
         <div class="container">
             <div class="row">
                 <div class="col-md-12">
                    <div class="row">
                        <div class="col-8">
                          <button mat-raised-button color='primary' class="suggestionCustomButton" (click)='openDialog()'>
                            <mat-icon>add</mat-icon>Submit Your Suggestion
                          </button>
                        </div>
						<div class="col-md-12 mt-3">
							 <h1 class="article-title">Suggestion List</h1>
						 </div>
					</div>

                  <div class="mt-3">
					<!--<div class="row">
						<div class="col-md-6">
							<h1>No. of posts: {{this.stats.posts}}</h1>
						</div>
						<div class="col-md-6">
							<h1>No.of replies: {{this.stats.replies}}</h1>
						</div>
						<div class="col-md-6">
							<h1>Last Posted: {{this.stats.posted['0']['submitted']}}</h1>
						</div>
						<div class="col-md-6">
							<h1>Last Replied: {{this.stats.replied['0']['submitted']}}</h1>
						</div>
					</div> -->
					<div class="row">
						<div class="col-md-3">
							<div class="instIdeaTotalCount">
								<p>No.of posts:</p>
								<span>{{this.stats.posts}}</span>
							</div>
						</div>
						<div class="col-md-3">
							<div class="instIdeaTotalCount">
								<p>No.of replies:</p>
								<span>{{this.stats.replies}}</span>
							</div>
						</div>
						<div class="col-md-3">
							<div class="instIdeaTotalCount">
								<p>Last Posted:</p>
								<span>{{this.stats.posted['0']['submitted']}}</span>
							</div>
						</div>
						<div class="col-md-3">
							<div class="instIdeaTotalCount">
								<p>Last Replied:</p>
								<span>{{this.stats.replied['0']['submitted']}}</span>
							</div>
						</div>
					</div>
                      <!-- <h1>No. of posts= {{this.stats.posts}}<br>
                      No.of replies={{this.stats.replies}}<br>
                      Last Posted={{this.stats.posted['0']['submitted']}}<br>
                      Last Replied={{this.stats.replied['0']['submitted']}}<br></h1> -->
                  </div>
                 <div class="sugCardBox mt-3">


                         <div *ngFor="let message of submittedMessage; let i = index;">
                         <div class="sugAllData" >



                             <div class="sugCardBox card">
                             <a *ngIf="((!message?.read_status) || this.unread)" type="button" class="btn btn-danger" (click)="getReplies(message?.id)" data-toggle="collapse" href="#content{{message?.id}}" aria-expanded="false" aria-controls="collapseExample">
                                 <p class="topTotal">{{message?.subject}} : {{message?.replies}} Replies {{message?.read_status}} {{unread}}</p>
                                 <p class="datePosted">Date Posted: {{message?.submission_date}}</p>
                                <p *ngIf="message.remarkdate" class="repliedAdmin"> Replied by admin:{{message.remarkdate}}</p>
                                <p *ngIf="message.lastreply" class="lastReplied">Last Replied:{{message?.lastreply}}</p>

                             </a>
                             <a *ngIf="message?.read_status && (!this.unread)" type="button" class="btn btn-info" (click)="getReplies(message?.id)" data-toggle="collapse" href="#content{{message?.id}}" aria-expanded="false" aria-controls="collapseExample">
                                <p class="topTotal">{{message?.subject}} : {{message?.replies}} Replies  {{message?.read_status}} {{unread}}</p>
                                <p class="datePosted">Date Posted: {{message?.submission_date}}</p>
                               <p *ngIf="message.remarkdate" class="repliedAdmin"> Replied by admin:{{message.remarkdate}}</p>
                               <p *ngIf="message.lastreply" class="lastReplied">Last Replied:{{message?.lastreply}}</p>

                            </a>

                                 <div class="collapse collapseFirst" id="content{{message?.id}}">
                             <div class="row">
                                 <div class="col-md-2">
                                     <h3 class="titleHeading">Message</h3>
                                 </div>
                                 <div class="col-md-10">
                                     <p class="titleContent">{{message?.messege}}</p>
                                 </div>
                             </div>

                             <div class="row"  *ngIf="message?.suggestion_file">
                                 <div class="col-md-2">
                                     <h3 class="titleHeading">Uploaded File</h3>
                                 </div>
                                 <div class="col-md-10">
                                     <p class="titleContent"><a href="{{message?.suggestion_file}}">Preview</a>
                                 </div>
                             </div>
                             <div *ngIf="message?.status=='1'" >
                             <div class="row" >

                                 <div class="col-md-2">
                                     <h3 class="titleHeading">Remark by MIC</h3>
                                 </div>
                                 <div class="col-md-10">
                                     <p class="titleContent">{{message?.remark}}</p>
                                 </div>
                                </div>
                                 <div class="row" *ngIf="message.remark_file">
                                 <div class="col-md-2">
                                     <h3 class="titleHeading">Attached File</h3>
                                 </div>
                                 <div class="col-md-10" >
                                     <p class="titleContent"><a target="_blank" href="{{message?.remark_file}}" >Preview</a>
                                 </div>
                             </div>
                                 </div>
                             <div class="row">
                                 <div class="col-md-2">
                                     <h3 class="titleHeading">Action</h3>
                                 </div>
                                 <div class="col-md-1">
                                     <p class="titleContent"><a data-toggle="collapse" href="#replyComment{{message?.id}}"   aria-expanded="false" aria-controls="collapseExample">
                                        <button *ngIf="!this.unread" class="btn btn-info seeRemarks" [disabled]="!message?.replies">See Replies</button>
                                        <button *ngIf="this.unread" class="btn btn-danger seeRemarks" [disabled]="!message?.replies">See Replies</button></a></p>
                                 </div>&nbsp;&nbsp;&nbsp;
                                 <div class="col-md-8">
                                    <p class="titleContent"><a class="btn btn-info suggestiondetailsReplyRemark" role="button" data-toggle="collapse" href="#reply{{message?.id}}" aria-expanded="false" aria-controls="collapseExample">Reply</a></p>
                                </div>
                             </div>
                             <div class="row">
                                <div class="collapse collapseFirstReply" id="reply{{message?.id}}">
                                    <form (submit)="onSubmit(message)" [formGroup]="replyForm">
                                       <mat-form-field appearance="outline" class="w-100">
                                           <mat-label>Reply</mat-label>
                                           <input matInput autocomplete="off" placeholder="message" formControlName="message" type="text" required>
                                           <mat-error *ngIf="replyForm.controls.message.errors?.required">Message is required</mat-error>
                                       </mat-form-field>

                                      <input style="font-weight:900;" matinput type="file" id="file" (change)="handleFileInput($event.target.files)" >
                                      <button type="submit" class="btn btn-success collapseFirstReplyBut">Send</button>
                                   </form>
                                  </div>
                            </div>

                             <div class="row" >
                                 <div class="collapse collapseSecond" style="
                                 width: 94%; align-content: center;
                             " id="replyComment{{message?.id}}">
                                       <div       *ngFor="let reply of replies">
                                     <div class="row" >
                                         <div class="col-md-12">
                                             <h3><p class="titleContent" >{{reply?.messege}}</p></h3>
                                         </div>
                                     </div>
                                     <div class="row" *ngIf="reply?.suggestion_reply_file">
                                 <div class="col-md-2">
                                     <h3 class="titleHeading">Attached File</h3>
                                 </div>
                                 <div class="col-md-10" >
                                     <p class="titleContent"><a target="_blank" href="{{reply?.suggestion_reply_file}}" >preview</a>
                                 </div>
                             </div>

                             <div *ngIf="reply?.status=='1'">
                                     <div class="row">

                                 <div class="col-md-2">
                                     <h3 class="titleHeading">Remark by MIC</h3>
                                 </div>
                                 <div class="col-md-10">
                                     <p class="titleContent">{{reply?.remark}}</p>
                                 </div>
                             </div>
                             <div class="row" *ngIf="reply?.remarkfile">
                                 <div class="col-md-2">
                                     <h3 class="titleHeading">Attached File</h3>
                                 </div>
                                 <div class="col-md-10">
                                     <p class="titleContent"><a target="_blank" href="{{reply?.remarkfile}}">Preview</a>
                                 </div>
                             </div></div>
                                     <div class="row">

                                         <div class="col-md-2">
                                             <p class="titleContent"><a class="btn btn-info suggestiondetailsReplyRemark" role="button" data-toggle="collapse" href="#reply{{message?.id}}" aria-expanded="false" aria-controls="collapseExample">Reply</a></p>
                                         </div>


                                         <div class="col-md-1">
                                             <p class="titleContent"><button class="btn voteThumbsUp" id="green"><i class="fa fa-thumbs-up fa-lg" aria-hidden="true"></i></button></p>
                                         </div>
                                         <div class="col-md-1">
                                             <p class="titleContent"><button class="btn voteThumbsDown" id="red"><i class="fa fa-thumbs-down fa-lg" aria-hidden="true"></i></button></p>
                                         </div>
                                     </div></div>



                             </div>
                         </div>
                         </div>

                     </div>
                     </div>
                     </div>


                 </div>
             </div>
            </div>
             </div>

    </section>
