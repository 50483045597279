import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-institute-iic-submission',
  templateUrl: './institute-iic-submission.component.html',
  styleUrls: ['./institute-iic-submission.component.css']
})
export class InstituteIicSubmissionComponent implements OnInit {

  public iicImpactQuestions: FormGroup;
  baseUrl = myGlobals.baseUrl1;
  imageBasePath= myGlobals.imageBasePath;

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  constructor(private fb: FormBuilder, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }


  ngOnInit(): void {


    this.instituteActivityService.getIicImpactSubDetails().subscribe(
      (data) => this.handleResponse(data)
    );
    this.iicImpactQuestions = this.fb.group({
      'incubation_status': [null, Validators.required],
      'no_of_copyright':[null, Validators.required,],
      'no_of_industrial': [null, Validators.required],
      'no_of_patents': [null, Validators.required],
      'no_of_patents_published': [null, Validators.required],
      'no_of_patents_granted': [null, Validators.required],
      'no_of_tech_transfer': [null, Validators.required],
      'implementation_cost': [null, Validators.required],
      'development_cost': [null, Validators.required],
      'startups_cost': [null, Validators.required],
      'external_fund': [null, Validators.required],
      'own_resources_fund': [null, Validators.required],
      'video_url': [],
      // 'amount_spent_18_19': [null, Validators.required],
      // 'amount_spent_19_20': [null, Validators.required],
      // 'amount_spent_20_21' : [null, Validators.required],
      // 'amount_spent_21_22' : [null, Validators.required],
      'amount_spent_22_23' : [null, Validators.required],
      'amount_spent_23_24' : [null, Validators.required],
      // 'amount_grant_18_19' : [null, Validators.required],
      // 'amount_grant_19_20' : [null, Validators.required],
      // 'amount_grant_20_21' : [null, Validators.required],
      // 'amount_grant_21_22' : [null, Validators.required],
      'amount_grant_22_23' : [null, Validators.required],
      'amount_grant_23_24' : [null, Validators.required],
      // 'student_participated' : [null, Validators.required],
      // 'student_winner' : [null, Validators.required],
      // 'faculty_participated' : [null, Validators.required],
      // 'faculty_winner' : [null, Validators.required],
      // 'ecosystem' : [null, Validators.required],
      // 'credit_score' : [null, Validators.required],
      // 'network_benefit' : [null, Validators.required],
      // 'role_of_infra' : [null, Validators.required],
      // 'ariia_status' : [null, Validators.required],
      // 'nisp_status' : [null, Validators.required],
      'testimonial' : [null, Validators.required],
      'suggestions' : [null, Validators.required],
      // 'student_idea_18_19' : [null, Validators.required],
      // 'student_idea_19_20' : [null, Validators.required],
      // 'student_idea_20_21' : [null, Validators.required],
      // 'student_idea_21_22' : [null, Validators.required],
      'student_idea_22_23' : [null, Validators.required],
      'student_idea_23_24' : [null, Validators.required],
      // 'faculty_idea_18_19' : [null, Validators.required],
      // 'faculty_idea_19_20' : [null, Validators.required],
      // 'faculty_idea_20_21' : [null, Validators.required],
      // 'faculty_idea_21_22' : [null, Validators.required],
      // 'faculty_idea_22_23' : [null, Validators.required],
      // 'faculty_idea_23_24' : [null, Validators.required],
      // 'student_proto_18_19' : [null, Validators.required],
      // 'student_proto_19_20' : [null, Validators.required],
      // 'student_proto_20_21' : [null, Validators.required],
      // 'student_proto_21_22' : [null, Validators.required],
      'student_proto_22_23' : [null, Validators.required],
      'student_proto_23_24' : [null, Validators.required],
      // 'faculty_proto_18_19' : [null, Validators.required],
      // 'faculty_proto_19_20' : [null, Validators.required],
      // 'faculty_proto_20_21' : [null, Validators.required],
      // 'faculty_proto_21_22' : [null, Validators.required],
      // 'faculty_proto_22_23' : [null, Validators.required],
      // 'faculty_proto_23_24' : [null, Validators.required],
      // 'student_startup_18_19' : [null, Validators.required],
      // 'student_startup_19_20' : [null, Validators.required],
      // 'student_startup_20_21' : [null, Validators.required],
      // 'student_startup_21_22' : [null, Validators.required],
      'student_startup_22_23' : [null, Validators.required],
      'student_startup_23_24' : [null, Validators.required],
      // 'faculty_startup_18_19' : [null, Validators.required],
      // 'faculty_startup_19_20' : [null, Validators.required],
      // 'faculty_startup_20_21' : [null, Validators.required],
      // 'faculty_startup_21_22' : [null, Validators.required],
      // 'faculty_startup_22_23' : [null, Validators.required],
      // 'faculty_startup_23_24' : [null, Validators.required],
      'VC_investment_22_23' : [null, Validators.required],
      'VC_investment_23_24' : [null, Validators.required]
    });
  }

  handleResponse(data) {
    if(data['totalcount'] > 0){
    this.iicImpactQuestions.patchValue({
      incubation_status: data['incubation_status'],
      no_of_copyright: data['no_of_copyright'],
      no_of_industrial: data['no_of_industrial'],
      no_of_patents: data['no_of_patents'],
      no_of_patents_published: data['no_of_patents_published'],
      no_of_patents_granted: data['no_of_patents_granted'],
      video_url: data['video_url'],
      no_of_tech_transfer: data['no_of_tech_transfer'],
      implementation_cost: data['implementation_cost'],
      development_cost: data['development_cost'],
      startups_cost: data['startups_cost'],
      external_fund: data['external_fund'],
      own_resources_fund: data['own_resources_fund'],

      // amount_spent_18_19: data['amount_spent_18_19'],
      // amount_spent_19_20: data['amount_spent_19_20'],
      // amount_spent_20_21: data['amount_spent_20_21'],
      // amount_spent_21_22: data['amount_spent_21_22'],
      amount_spent_22_23: data['amount_spent_22_23'],
      amount_spent_23_24: data['amount_spent_23_24'],
      // amount_grant_18_19: data['amount_grant_18_19'],
      // amount_grant_19_20: data['amount_grant_19_20'],
      // amount_grant_20_21: data['amount_grant_20_21'],
      // amount_grant_21_22: data['amount_grant_21_22'],
      amount_grant_22_23: data['amount_grant_22_23'],
      amount_grant_23_24: data['amount_grant_23_24'],
      // student_participated: data['student_participated'],
      // student_winner: data['student_winner'],
      // faculty_participated: data['faculty_participated'],
      // faculty_winner: data['faculty_winner'],
      // ecosystem: data['ecosystem'],
      // credit_score: data['credit_score'],
      // network_benefit: data['network_benefit'],
      // role_of_infra: data['role_of_infra'],
      // ariia_status: data['ariia_status'],
      // nisp_status: data['nisp_status'],
      testimonial: data['testimonial'],
      suggestions: data['suggestions'],
      // student_idea_18_19: data['student_idea_18_19'],
      // student_idea_19_20: data['student_idea_19_20'],
      // student_idea_20_21: data['student_idea_20_21'],
      student_idea_22_23: data['student_idea_22_23'],
      student_idea_23_24: data['student_idea_23_24'],
      // faculty_idea_18_19: data['faculty_idea_18_19'],
      // faculty_idea_19_20: data['faculty_idea_19_20'],
      // faculty_idea_20_21: data['faculty_idea_20_21'],
      // faculty_idea_21_22: data['faculty_idea_21_22'],
      // faculty_idea_22_23: data['faculty_idea_22_23'],
      // faculty_idea_23_24: data['faculty_idea_23_24'],
      // student_proto_18_19: data['student_proto_18_19'],
      // student_proto_19_20: data['student_proto_19_20'],
      // student_proto_20_21: data['student_proto_20_21'],
      // student_proto_21_22: data['student_proto_21_22'],
      student_proto_22_23: data['student_proto_22_23'],
      student_proto_23_24: data['student_proto_23_24'],
      // faculty_proto_18_19: data['faculty_proto_18_19'],
      // faculty_proto_19_20: data['faculty_proto_19_20'],
      // faculty_proto_20_21: data['faculty_proto_20_21'],
      // faculty_proto_21_22: data['faculty_proto_21_22'],
      // faculty_proto_22_23: data['faculty_proto_22_23'],
      // faculty_proto_23_24: data['faculty_proto_23_24'],
      // student_startup_18_19: data['student_startup_18_19'],
      // student_startup_19_20: data['student_startup_19_20'],
      // student_startup_20_21: data['student_startup_20_21'],
      // student_startup_21_22: data['student_startup_21_22'],
      student_startup_22_23: data['student_startup_22_23'],
      student_startup_23_24: data['student_startup_23_24'],
      // faculty_startup_18_19: data['faculty_startup_18_19'],
      // faculty_startup_19_20: data['faculty_startup_19_20'],
      // faculty_startup_20_21: data['faculty_startup_20_21'],
      // faculty_startup_21_22: data['faculty_startup_21_22'],
      // faculty_startup_22_23: data['faculty_startup_22_23'],
      // faculty_startup_23_24: data['faculty_startup_23_24'],
      VC_investment_22_23 : data['VC_investment_22_23'],
      VC_investment_23_24 : data['VC_investment_23_24']
    });

    this.document = data['annual_report'];
  }
    this.totalcount = data['totalcount'];

  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;
    return false;
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('incubation_status', this.iicImpactQuestions.get('incubation_status').value);
    formData.append('no_of_copyright', this.iicImpactQuestions.get('no_of_copyright').value);
    formData.append('no_of_industrial', this.iicImpactQuestions.get('no_of_industrial').value);
    formData.append('no_of_patents', this.iicImpactQuestions.get('no_of_patents').value);
    formData.append('no_of_patents_published', this.iicImpactQuestions.get('no_of_patents_published').value);
    formData.append('no_of_patents_granted', this.iicImpactQuestions.get('no_of_patents_granted').value);
    formData.append('video_url', this.iicImpactQuestions.get('video_url').value);
    formData.append('no_of_tech_transfer', this.iicImpactQuestions.get('no_of_tech_transfer').value);
    formData.append('implementation_cost', this.iicImpactQuestions.get('implementation_cost').value);
    formData.append('development_cost', this.iicImpactQuestions.get('development_cost').value);
    formData.append('startups_cost', this.iicImpactQuestions.get('startups_cost').value);
    formData.append('external_fund', this.iicImpactQuestions.get('external_fund').value);
    formData.append('own_resources_fund', this.iicImpactQuestions.get('own_resources_fund').value);
    // formData.append('amount_spent_18_19', this.iicImpactQuestions.get('amount_spent_18_19').value);
    // formData.append('amount_spent_19_20', this.iicImpactQuestions.get('amount_spent_19_20').value);
    // formData.append('amount_spent_20_21', this.iicImpactQuestions.get('amount_spent_20_21').value);
    // formData.append('amount_spent_21_22', this.iicImpactQuestions.get('amount_spent_21_22').value);
    formData.append('amount_spent_22_23', this.iicImpactQuestions.get('amount_spent_22_23').value);
    formData.append('amount_spent_23_24', this.iicImpactQuestions.get('amount_spent_23_24').value);
    // formData.append('amount_grant_18_19', this.iicImpactQuestions.get('amount_grant_18_19').value);
    // formData.append('amount_grant_19_20', this.iicImpactQuestions.get('amount_grant_19_20').value);
    // formData.append('amount_grant_20_21', this.iicImpactQuestions.get('amount_grant_20_21').value);
    // formData.append('amount_grant_21_22', this.iicImpactQuestions.get('amount_grant_21_22').value);
    formData.append('amount_grant_22_23', this.iicImpactQuestions.get('amount_grant_22_23').value);
    formData.append('amount_grant_23_24', this.iicImpactQuestions.get('amount_grant_23_24').value);
    // formData.append('student_participated', this.iicImpactQuestions.get('student_participated').value);

    // formData.append('student_winner', this.iicImpactQuestions.get('student_winner').value);
    // formData.append('faculty_participated', this.iicImpactQuestions.get('faculty_participated').value);
    // formData.append('faculty_winner', this.iicImpactQuestions.get('faculty_winner').value);
    // formData.append('ecosystem', this.iicImpactQuestions.get('ecosystem').value);
    // formData.append('credit_score', this.iicImpactQuestions.get('credit_score').value);
    // formData.append('network_benefit', this.iicImpactQuestions.get('network_benefit').value);
    // formData.append('role_of_infra', this.iicImpactQuestions.get('role_of_infra').value);
    // formData.append('ariia_status', this.iicImpactQuestions.get('ariia_status').value);
    // formData.append('nisp_status', this.iicImpactQuestions.get('nisp_status').value);
    formData.append('testimonial', this.iicImpactQuestions.get('testimonial').value);
    formData.append('suggestions', this.iicImpactQuestions.get('suggestions').value);

    // formData.append('student_idea_18_19', this.iicImpactQuestions.get('student_idea_18_19').value);
    // formData.append('student_idea_19_20', this.iicImpactQuestions.get('student_idea_19_20').value);
    // formData.append('student_idea_20_21', this.iicImpactQuestions.get('student_idea_20_21').value);
    // formData.append('student_idea_21_22', this.iicImpactQuestions.get('student_idea_21_22').value);
    formData.append('student_idea_22_23', this.iicImpactQuestions.get('student_idea_22_23').value);
    formData.append('student_idea_23_24', this.iicImpactQuestions.get('student_idea_23_24').value);
    // formData.append('faculty_idea_18_19', this.iicImpactQuestions.get('faculty_idea_18_19').value);
    // formData.append('faculty_idea_19_20', this.iicImpactQuestions.get('faculty_idea_19_20').value);
    // formData.append('faculty_idea_20_21', this.iicImpactQuestions.get('faculty_idea_20_21').value);
    // formData.append('faculty_idea_21_22', this.iicImpactQuestions.get('faculty_idea_21_22').value);
    // formData.append('faculty_idea_22_23', this.iicImpactQuestions.get('faculty_idea_22_23').value);
    // formData.append('faculty_idea_23_24', this.iicImpactQuestions.get('faculty_idea_23_24').value);

    // formData.append('student_proto_18_19', this.iicImpactQuestions.get('student_proto_18_19').value);
    // formData.append('student_proto_19_20', this.iicImpactQuestions.get('student_proto_19_20').value);
    // formData.append('student_proto_20_21', this.iicImpactQuestions.get('student_proto_20_21').value);
    // formData.append('student_proto_21_22', this.iicImpactQuestions.get('student_proto_21_22').value);
    formData.append('student_proto_22_23', this.iicImpactQuestions.get('student_proto_22_23').value);
    formData.append('student_proto_23_24', this.iicImpactQuestions.get('student_proto_23_24').value);
    // formData.append('faculty_proto_18_19', this.iicImpactQuestions.get('faculty_proto_18_19').value);
    // formData.append('faculty_proto_19_20', this.iicImpactQuestions.get('faculty_proto_19_20').value);
    // formData.append('faculty_proto_20_21', this.iicImpactQuestions.get('faculty_proto_20_21').value);
    // formData.append('faculty_proto_21_22', this.iicImpactQuestions.get('faculty_proto_21_22').value);
    // formData.append('faculty_proto_22_23', this.iicImpactQuestions.get('faculty_proto_22_23').value);
    // formData.append('faculty_proto_23_24', this.iicImpactQuestions.get('faculty_proto_23_24').value);

    // formData.append('student_startup_18_19', this.iicImpactQuestions.get('student_startup_18_19').value);
    // formData.append('student_startup_19_20', this.iicImpactQuestions.get('student_startup_19_20').value);
    // formData.append('student_startup_20_21', this.iicImpactQuestions.get('student_startup_20_21').value);
    // formData.append('student_startup_21_22', this.iicImpactQuestions.get('student_startup_21_22').value);
    formData.append('student_startup_22_23', this.iicImpactQuestions.get('student_startup_22_23').value);
    formData.append('student_startup_23_24', this.iicImpactQuestions.get('student_startup_23_24').value);
    // formData.append('faculty_startup_18_19', this.iicImpactQuestions.get('faculty_startup_18_19').value);
    // formData.append('faculty_startup_19_20', this.iicImpactQuestions.get('faculty_startup_19_20').value);
    // formData.append('faculty_startup_20_21', this.iicImpactQuestions.get('faculty_startup_20_21').value);
    // formData.append('faculty_startup_21_22', this.iicImpactQuestions.get('faculty_startup_21_22').value);
    // formData.append('faculty_startup_22_23', this.iicImpactQuestions.get('faculty_startup_22_23').value);
    // formData.append('faculty_startup_23_24', this.iicImpactQuestions.get('faculty_startup_23_24').value);
    formData.append('VC_investment_22_23', this.iicImpactQuestions.get('VC_investment_22_23').value);
    formData.append('VC_investment_23_24', this.iicImpactQuestions.get('VC_investment_23_24').value);

    var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    if(data['totalcount'] > 0){

      formData.append('document', cvFile);
      formData.append('document_old', this.document);

    }
    else
    {
      if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
      formData.append('document', cvFile);
    }

    this.instituteActivityService.submitIicSubImpact(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg']);
      window.location.href='/institute/activity';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }


}
