
<app-iic-web-navbar></app-iic-web-navbar>
<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Video Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<!-- <li class="breadcrumb-item"><a>Gallery</a></li> -->
	<li class="breadcrumb-item"><a href="./gallery/photo-gallery">Gallery</a></li>
	<!-- <li class="breadcrumb-item"><a href="gallery/video-gallery">Video Gallery</a></li> -->
	<li class="breadcrumb-item"><a>Video Gallery</a></li>
  </ul>
</nav>

<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Video Gallery</h2>
				<div>
					<a class="backButton" href="/gallery">Back to Gallery</a>
				</div>
			</div>
		</div>
		</div>	  
	</div>	
 </div>

<div class="container">
		<div class="row">
			<div class="gallery col-md-12">
				<div class="row pt-4">
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/BNnkbRBSmPE" title="Institution’s Innovation Council Regional Meet, Chandigarh | 26 July, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Chandigarh</span></h4>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/SgN1dAwOtEQ" title="Institution’s Innovation Council Regional Meet, Nagpur | 29 July, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Nagpur</span></h4>
						</div>
					</div>
					
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/p-zYVvWxJ8g" title="Institution’s Innovation Council Regional Meet, Kochi| 6 August, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Kochi</span></h4>
						</div>
					</div>

					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/mEt09lHX9ZU" title="Institution’s Innovation Council Regional Meet, Hyderabad | 12th August, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Hyderabad</span></h4>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/KIrHLHaDEVk" title="Institution’s Innovation Council Regional Meet, Bangalore | 16 August, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Bengaluru</span></h4>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/Uie-jGdTA1Y" title="Institution’s Innovation Council Regional Meet, Chennai | 21 July, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Chennai</span></h4>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/Y3dFH-aIHuQ" title="Institution’s Innovation Council Regional Meet, Pune | 1st August, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Pune</span></h4>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/6IBjllUpLTs" title="Institution’s Innovation Council Regional Meet, Guwahati | 4 August, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Guwahati</span></h4>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/mSmB7JAHJns" title="Institution’s Innovation Council Regional Meet, Kolkata | 2 August, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Kolkata</span></h4>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="vGallery">
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/MMCrGyubY7s" title="Institution’s Innovation Council Regional Meet, Lucknow | 29 August, 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Lucknow</span></h4>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="vGallery">				
							<!-- <iframe width="100%" height="200px" src="https://www.youtube.com/embed/MMCrGyubY7s" title="IIC Regional Meet held at Ahmedabad" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
							<iframe width="100%" height="200px" src="https://www.youtube.com/embed/ckYDS-eF6wo?list=PLmP9QrmTNPqDgrimlQug20NaRwl73qugn" title="IIC Regional Meet held at Ahmedabad" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							<h4>Regional Meet 2022 | <span>Ahmedabad</span></h4>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div> 
<app-iic-web-footer></app-iic-web-footer>
