
   <!-- List starts -->

   <div class="row" color="primary">
    <h2 class="ss"
        style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
     Action Plan & Scheduled Activities</h2>
</div>


<mat-tab-group class="matGroupActionPlan">
  <mat-tab label="Orientation Sessions">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Orientation Sessions</h2>
		</div>
      <div class="col-sm-9">
        <p>Conduct an orientation-cum-mentoring session for ATL Schools.</p>
          <!-- <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p> -->
          <div class="collapse multi-collapse" id="rws-guideline1">
            <div class="card card-body">
              <ul>
                <li>At least two orientations cum mentoring sessions for all mentee institutions or separate sessions for each mentee institution may be planned and conducted.</li>
                <li>Orientation session shall be conducted through online mode using video conferencing platforms.</li>
                <li>Key functionaries of IIC at the mentor IIC institute shall join as mentor experts and will take the session and explain the best practices, cases of their institute on how they are driving the I&E ecosystem highlighting resource mobilization strategies.</li>
                <li>Mentor IIC institute may spend expenses towards honorarium to the experts. The honorarium of Rs. 3000/- per expert per session and two such orientation cum mentoring sessions to be conducted by the mentor institute during the IIC calendar year.</li>
              </ul>
            </div>
          </div>
      </div>
      <div class="col-sm-3">
        <p><button mat-raised-button (click)="openDialog('global', 1)" color="primary">Schedule Sessions</button></p>
      </div>
    </div>


    <table class="table table-hover table-border mt-2 table-responsive">
      <thead class="facultyTable">
        <tr>
          <td colspan="8" allign="left" class="danger "
            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Orientation Sessions</h1>
          </td>
        </tr>
        <tr>
          <th width="5%" style="background-color:#333;">S.No.</th>
          <th width="10%" style="background-color:#333;">Publish Date</th>
          <th width="10%" style="background-color:#333;">Mode of Session</th>
          <th width="20%" style="background-color:#333;">Quarter</th>
          <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
          <th width="20%" style="background-color:#333;">Session Details</th>
          <th width="15%" style="background-color:#333;">Co-Ordinator</th>
          <th width="5%" style="background-color:#333;">Action</th>
        </tr>
      </thead>
      <tbody>
    <tr *ngFor="let activity of schedules;let i = index">
    <td>{{i+1}}</td>
    <td>      
      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
    </td>
    <td>{{activity?.session_mode}}</td>
    <td>{{activity?.quarter}}</td>
    <!-- <td>{{activity?.activity_type}}</td> -->
    <td>{{activity?.title}}<br/>
      {{activity?.date}} @ {{activity?.time}}
      <br/>
      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
    </td>
    <td>{{activity?.spoc_name}}</td>
    <td>
      <button mat-button (click)="onEditClick(activity,1)" style="background-color: green; color: white;" *ngIf="activity?.isMentorParticipated == 0">
        Edit
      </button>
      <button mat-button (click)="onDeleteScheduleClick(activity?.id)" style="background-color: red; color: white;" *ngIf="activity?.isMentorParticipated == 0">
        Delete
      </button>
      <a routerLink="/institute/atl-activity-view-submission/{{activity?.id}}"  *ngIf="activity?.isMentorParticipated == 1"><button mat-button style="
      background-color: blue; color: white;">
        Participation Report by ATL School
      </button></a>

<!-- <button mat-button (click)="onSubmitClick(activity, '1')" style="
                background-color: red; color: white;">
            Remove
          </button> -->

    </td>
    </tr>
    <tr *ngIf="total_pages==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
      background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
      There is no activity added yet.
    </td></tr>

      </tbody>
    </table>


  </mat-tab>

  <!-- Tab Ends  -->

  <mat-tab label="Trainings & Bootcamps">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Trainings & Bootcamps</h2>
		</div>
      <div class="col-sm-9">
        <p>Innovation Ambassadors from IIC Institutions and MoCs from ATLs will provide Mentoring guidance to School Students on their innovations and projects. </p>
          <!-- <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p> -->
          <div class="collapse multi-collapse" id="rws-guideline1">
            <div class="card card-body">
              <ul>
                <li>At least two orientations cum mentoring sessions for all mentee institutions or separate sessions for each mentee institution may be planned and conducted.</li>
                <li>Orientation session shall be conducted through online mode using video conferencing platforms.</li>
                <li>Key functionaries of IIC at the mentor IIC institute shall join as mentor experts and will take the session and explain the best practices, cases of their institute on how they are driving the I&E ecosystem highlighting resource mobilization strategies.</li>
                <li>Mentor IIC institute may spend expenses towards honorarium to the experts. The honorarium of Rs. 3000/- per expert per session and two such orientation cum mentoring sessions to be conducted by the mentor institute during the IIC calendar year.</li>
              </ul>
            </div>
          </div>
      </div>
      <div class="col-sm-3">
        <p><button mat-raised-button (click)="openDialog('global', 2)" color="primary">Schedule Training</button></p>
      </div>
    </div>


    <table class="table table-hover table-border mt-2 table-responsive">
      <thead class="facultyTable">
        <tr>
          <td colspan="8" allign="left" class="danger "
            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Trainings & Bootcamps</h1>
          </td>
        </tr>
        <tr>
          <th width="5%" style="background-color:#333;">S.No.</th>
          <th width="10%" style="background-color:#333;">Publish Date</th>
          <th width="10%" style="background-color:#333;">Mode of Session</th>
          <th width="20%" style="background-color:#333;">Quarter</th>
          <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
          <th width="20%" style="background-color:#333;">Session Details</th>
          <th width="15%" style="background-color:#333;">Co-Ordinator</th>
          <th width="5%" style="background-color:#333;">Action</th>
        </tr>
      </thead>
      <tbody>
    <tr *ngFor="let activity of schedules2;let i = index">
    <td>{{i+1}}</td>
    <td>      
      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
    </td>
    <td>{{activity?.session_mode}}</td>
    <td>{{activity?.quarter}}</td>
    <!-- <td>{{activity?.activity_type}}</td> -->
    <td>{{activity?.title}}<br/>
      {{activity?.date}} @ {{activity?.time}}
      <br/>
      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
    </td>
    <td>{{activity?.spoc_name}}</td>
    <td>
      <button mat-button (click)="onEditClick(activity,2)" style="background-color: green; color: white;" *ngIf="activity?.isMentorParticipated == 0">
        Edit
      </button>
      <button mat-button (click)="onDeleteScheduleClick(activity?.id)" style="background-color: red; color: white;" *ngIf="activity?.isMentorParticipated == 0">
        Delete
      </button>
      <a routerLink="/institute/atl-activity-view-submission/{{activity?.id}}"><button mat-button style="background-color: blue; color: white;" *ngIf="activity?.isMentorParticipated == 1">
          Participation Report by ATL School
        </button></a>

<!-- <button mat-button (click)="onSubmitClick(activity, '1')" style="
                background-color: red; color: white;">
            Remove
          </button> -->

    </td>
    </tr>
    <tr *ngIf="total_pages==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
      background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
      There is no activity added yet.
    </td></tr>

      </tbody>
    </table>


  </mat-tab>

  <!-- Tab Ends  -->


  <mat-tab label="Mentor-Mentee Meets">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Mentor-Mentee Meets</h2>
		</div>
      <div class="col-sm-9">
        <p>Both IIC institute and ATL Schools are required to organize Mentor-Mentee meets by involving innovation Ambassadors, MoCs and Experts to guide student’s innovators in the ATLs.At least 2 meets can be planned and organized during the period.</p>
          <!-- <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p> -->
          <div class="collapse multi-collapse" id="rws-guideline1">
            <div class="card card-body">
              <ul>
                <li>At least two orientations cum mentoring sessions for all mentee institutions or separate sessions for each mentee institution may be planned and conducted.</li>
                <li>Orientation session shall be conducted through online mode using video conferencing platforms.</li>
                <li>Key functionaries of IIC at the mentor IIC institute shall join as mentor experts and will take the session and explain the best practices, cases of their institute on how they are driving the I&E ecosystem highlighting resource mobilization strategies.</li>
                <li>Mentor IIC institute may spend expenses towards honorarium to the experts. The honorarium of Rs. 3000/- per expert per session and two such orientation cum mentoring sessions to be conducted by the mentor institute during the IIC calendar year.</li>
              </ul>
            </div>
          </div>
      </div>
      <div class="col-sm-3">
        <p><button mat-raised-button (click)="openDialog('global', 3)" color="primary">Schedule Meet</button></p>
      </div>
    </div>


    <table class="table table-hover table-border mt-2 table-responsive">
      <thead class="facultyTable">
        <tr>
          <td colspan="8" allign="left" class="danger "
            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Mentor-Mentee Meets</h1>
          </td>
        </tr>
        <tr>
          <th width="5%" style="background-color:#333;">S.No.</th>
          <th width="10%" style="background-color:#333;">Publish Date</th>
          <th width="10%" style="background-color:#333;">Mode of Session</th>
          <th width="20%" style="background-color:#333;">Quarter</th>
          <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
          <th width="20%" style="background-color:#333;">Session Details</th>
          <th width="15%" style="background-color:#333;">Co-Ordinator</th>
          <th width="5%" style="background-color:#333;">Action</th>
        </tr>
      </thead>
      <tbody>
    <tr *ngFor="let activity of schedules3;let i = index">
    <td>{{i+1}}</td>
    <td>      
      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
    </td>
    <td>{{activity?.session_mode}}</td>
    <td>{{activity?.quarter}}</td>
    <!-- <td>{{activity?.activity_type}}</td> -->
    <td>{{activity?.title}}<br/>
      {{activity?.date}} @ {{activity?.time}}
      <br/>
      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
    </td>
    <td>{{activity?.spoc_name}}</td>
    <td>
      <button mat-button (click)="onEditClick(activity,3)" style="background-color: green; color: white;" *ngIf="activity?.isMentorParticipated == 0">
        Edit
      </button>
      <button mat-button (click)="onDeleteScheduleClick(activity?.id)" style="background-color: red; color: white;" *ngIf="activity?.isMentorParticipated == 0">
        Delete
      </button>
      <a routerLink="/institute/atl-activity-view-submission/{{activity?.id}}"><button mat-button style="background-color: blue; color: white;" *ngIf="activity?.isMentorParticipated == 1">
          Participation Report by ATL School
        </button></a>

<!-- <button mat-button (click)="onSubmitClick(activity, '1')" style="
                background-color: red; color: white;">
            Remove
          </button> -->

    </td>
    </tr>
    <tr *ngIf="total_pages==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
      background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
      There is no activity added yet.
    </td></tr>

      </tbody>
    </table>


  </mat-tab>

  <!-- Tab Ends  -->


  <mat-tab label="Exposure Visits">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Exposure Visits</h2>
		</div>
      <div class="col-sm-9">
        <p>One Day Exposure visit by School students to IIC Institute and Interaction with Institute Student Innovators</p>
          <!-- <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p> -->
          <div class="collapse multi-collapse" id="rws-guideline1">
            <div class="card card-body">
              <ul>
                <li>At least two orientations cum mentoring sessions for all mentee institutions or separate sessions for each mentee institution may be planned and conducted.</li>
                <li>Orientation session shall be conducted through online mode using video conferencing platforms.</li>
                <li>Key functionaries of IIC at the mentor IIC institute shall join as mentor experts and will take the session and explain the best practices, cases of their institute on how they are driving the I&E ecosystem highlighting resource mobilization strategies.</li>
                <li>Mentor IIC institute may spend expenses towards honorarium to the experts. The honorarium of Rs. 3000/- per expert per session and two such orientation cum mentoring sessions to be conducted by the mentor institute during the IIC calendar year.</li>
              </ul>
            </div>
          </div>
      </div>
      <div class="col-sm-3">
        <p><button mat-raised-button (click)="openDialog('global', 4)" color="primary">Schedule Exposure Visit</button></p>
      </div>
    </div>


    <table class="table table-hover table-border mt-2 table-responsive">
      <thead class="facultyTable">
        <tr>
          <td colspan="8" allign="left" class="danger "
            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">Exposure Visits</h1>
          </td>
        </tr>
        <tr>
          <th width="5%" style="background-color:#333;">S.No.</th>
          <th width="10%" style="background-color:#333;">Publish Date</th>
          <th width="10%" style="background-color:#333;">Mode of Session</th>
          <th width="20%" style="background-color:#333;">Quarter</th>
          <!-- <th width="25%" style="background-color:#333;">Activity Type</th> -->
          <th width="20%" style="background-color:#333;">Session Details</th>
          <th width="15%" style="background-color:#333;">Co-Ordinator</th>
          <th width="5%" style="background-color:#333;">Action</th>
        </tr>
      </thead>
      <tbody>
    <tr *ngFor="let activity of schedules4;let i = index">
    <td>{{i+1}}</td>
    <td>      
      <span *ngIf="activity?.updated_at">{{activity?.updated_at}}</span>
      <span *ngIf="!activity?.updated_at">{{activity?.created_at}}</span>      
      <!-- <span *ngIf="activity?.conducted_for=='Individual'"><br/>{{activity?.instituteName}}</span> -->
    </td>
    <td>{{activity?.session_mode}}</td>
    <td>{{activity?.quarter}}</td>
    <!-- <td>{{activity?.activity_type}}</td> -->
    <td>{{activity?.title}}<br/>
      {{activity?.date}} @ {{activity?.time}}
      <br/>
      <span *ngIf="activity?.session_mode=='Offline'">{{activity?.venue_details}}</span>
      <span *ngIf="activity?.session_mode=='Online'">{{activity?.meeting_url}}</span>
    </td>
    <td>{{activity?.spoc_name}}</td>
    <td>
      <button mat-button (click)="onEditClick(activity,4)" style="background-color: green; color: white;" *ngIf="activity?.isMentorParticipated == 0">
        Edit
      </button>
      <button mat-button (click)="onDeleteScheduleClick(activity?.id)" style="background-color: red; color: white;" *ngIf="activity?.isMentorParticipated == 0">
        Delete
      </button>
      <a routerLink="/institute/atl-activity-view-submission/{{activity?.id}}"><button mat-button style="background-color: blue; color: white;" *ngIf="activity?.isMentorParticipated == 1" >
          Participation Report by ATL School
        </button></a>

<!-- <button mat-button (click)="onSubmitClick(activity, '1')" style="
                background-color: red; color: white;">
            Remove
          </button> -->

    </td>
    </tr>
    <tr *ngIf="total_pages==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
      background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
      There is no activity added yet.
    </td></tr>

      </tbody>
    </table>


  </mat-tab>

  <!-- Tab Ends  -->


  <!-- <mat-tab label="Nominated ATL Co-Ordinator">
    <div class="row" style="padding-top: 10px;">
      <div class="col-md-12">
        <h2 class="actionPlanHeading">Nominated ATL Co-Ordinator</h2>
      </div>
      <div class="col-md-12">
        <div class="col-6">
          <mat-form-field>
            <mat-select placeholder="Select Type" (selectionChange)="nominateDrop($event.value)">
                <mat-option value="iic_member">
                  IIC Member
                </mat-option>
                <mat-option value="innovation_amb">
                  Innovation Ambassadors
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
      </div>
      
      <div class="col-sm-12">
        <table class="table table-hover table-border mt-2 table-responsive" *ngIf="show">
          <thead class="facultyTable">
            <tr>
              <td colspan="8" allign="left" class="danger "
                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                <h1 class="announcementHeadingMain">IIC Member</h1>
              </td>
            </tr>
            <tr>
              <th width="5%" style="background-color:#333;">S.No.</th>
              <th width="10%" style="background-color:#333;">Name</th>
              <th width="10%" style="background-color:#333;">Email</th>
              <th width="10%" style="background-color:#333;">Mobile</th>
              <th width="20%" style="background-color:#333;">Designation</th>
              <th width="20%" style="background-color:#333;">Roles</th>
              <th width="5%" style="background-color:#333;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let activity of iic_mem;let i = index">
              <td>{{i+1}}</td>
              <td>{{activity?.name}}</td>
              <td>{{activity?.email}}</td>
              <td>{{activity?.mobile}}</td>
              <td>{{activity?.designation}}</td>
              <td>{{activity?.roles}}</td>
              <td>
                <button mat-button (click)="onNominateClick(1,activity?.Id)" style="
                background-color: green; color: white;" [ngStyle]="{'display': (activity?.is_nominate==0) ? 'block' : 'none'}" id="nominateSuccess">
                  Nominate
                </button>
                <button mat-button (click)="onNominateRemoveClick(1,activity?.Id)" style="
                background-color: green; color: white;" [ngStyle]="{'display': (activity?.is_nominate==1) ? 'block' : 'none'}" id="nominateRemove">
                  Remove Nominate
                </button>
    
              </td>
            </tr>
            <tr *ngIf="iic_mem?.length==0">
              <td colspan="8" style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                There is no iic member added yet.
              </td>
            </tr>    
          </tbody>
        </table>
        <table class="table table-hover table-border mt-2 table-responsive" *ngIf="show1==1">
          <thead class="facultyTable">
            <tr>
              <td colspan="8" allign="left" class="danger "
                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                <h1 class="announcementHeadingMain">Innovation Ambassadors</h1>
              </td>
            </tr>
            <tr>
              <th width="5%" style="background-color:#333;">S.No.</th>
              <th width="10%" style="background-color:#333;">Name</th>
              <th width="10%" style="background-color:#333;">Email</th>
              <th width="10%" style="background-color:#333;">Mobile</th>
              <th width="20%" style="background-color:#333;">Institute Name</th>
              <th width="5%" style="background-color:#333;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let activity of ia_evnt;let i = index">
              <td>{{i+1}}</td>
              <td>{{activity?.name}}</td>
              <td>{{activity?.email}}</td>
              <td>{{activity?.contact}}</td>
              <td>{{activity?.institute_name}}</td>
              <td>
                <button mat-button (click)="onNominateClick(2,activity?.id)" style="
                background-color: green; color: white;" [ngStyle]="{'display': (activity?.is_nominate==0) ? 'block' : 'none'}" id="nominateSuccess">
                  Nominate
                </button>
                <button mat-button (click)="onNominateRemoveClick(2,activity?.id)" style="
                background-color: green; color: white;" [ngStyle]="{'display': (activity?.is_nominate==1) ? 'block' : 'none'}" id="nominateRemove">
                  Remove Nominate
                </button>
    
              </td>
            </tr>
            <tr *ngIf="ia_evnt?.length==0">
              <td colspan="8" style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                There is no innovation ambassador added yet.
              </td>
            </tr>    
          </tbody>
        </table>
      </div>
    </div>
  </mat-tab> -->

  <!-- Tab Ends  -->

  <mat-tab label="Invite ATL for Participation in IIC Activities">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Invite ATL for Participation in IIC Activities</h2>
		</div>
    <div class="col-sm-9">
      <p>IIC Institute can invite ATLs to participate in various Innovation and Entrepreneurship promotion activities organised as per the IIC plan.</p>
        <!-- <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p> -->
        <div class="collapse multi-collapse" id="rws-guideline1">
          <div class="card card-body">
            <ul>
              <li>At least two orientations cum mentoring sessions for all mentee institutions or separate sessions for each mentee institution may be planned and conducted.</li>
              <li>Orientation session shall be conducted through online mode using video conferencing platforms.</li>
              <li>Key functionaries of IIC at the mentor IIC institute shall join as mentor experts and will take the session and explain the best practices, cases of their institute on how they are driving the I&E ecosystem highlighting resource mobilization strategies.</li>
              <li>Mentor IIC institute may spend expenses towards honorarium to the experts. The honorarium of Rs. 3000/- per expert per session and two such orientation cum mentoring sessions to be conducted by the mentor institute during the IIC calendar year.</li>
            </ul>
          </div>
        </div>
    </div>
    <div class="col-sm-3">
      <!-- <p><button mat-raised-button (click)="openDialog('global', 5)" color="primary">Schedule Invite</button></p> -->
    </div>
      <div class="col-sm-12">
        <form [formGroup]="addActivityForm1" (submit)="onSubmit(addActivityForm1.value)">
          <div class="row">
            
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="form1Seassion">
                <mat-label>Activity</mat-label>
                <mat-select placeholder="Select the activity" formControlName="activity" (selectionChange)="changeActivityAtl($event.value)">
                  <!-- <mat-option class="drList" value="">Select Activity</mat-option> -->
                  <mat-option class="drList" value="iic_calendar">IIC Calendar</mat-option>
                  <mat-option class="drList" value="mic_driven">MIC Driven</mat-option>
                  <mat-option class="drList" value="celebration">Celebration</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="quarterAtl == 1">
              <mat-form-field appearance="outline" class="form1Seassion">
                <mat-label>Quarter</mat-label>
                <mat-select placeholder="Quarter" formControlName="quarter" (selectionChange)="changeQuarterAtl($event.value)">
                  <!-- <mat-option value="">Select Quarter </mat-option> -->
                  <mat-option value="Quarter 1">Quarter 1</mat-option>
                  <mat-option value="Quarter 2">Quarter 2</mat-option>
                  <mat-option value="Quarter 3">Quarter 3</mat-option>
                  <mat-option value="Quarter 4">Quarter 4</mat-option>
                </mat-select>
                <mat-error *ngIf="addActivityForm1.controls.quarter.errors?.required">Quarter is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="titleAtl == 1">
              <mat-form-field appearance="outline" class="form1Seassion">
                <mat-label>Title</mat-label>
                <mat-select placeholder="Title" formControlName="title">
                  <mat-option *ngFor="let val of activityTitle" value="{{val?.title}}">{{ val?.title }}</mat-option>
                </mat-select>
                <mat-error *ngIf="addActivityForm1.controls.quarter.errors?.required">Quarter is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <button type="submit" class="btn btn-success">Submit</button>
            </div>
          
          </div>
        </form>
        <!-- <p>Updated Soon!</p> -->
          <!-- <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p> -->
        <table class="table table-hover table-border mt-2 table-responsive">
          <thead class="facultyTable">
            <tr>
              <td colspan="8" allign="left" class="danger "
                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                <h1 class="announcementHeadingMain">Institute Events</h1>
              </td>
            </tr>
            <tr>
              <th width="5%" style="background-color:#333;">S.No.</th>
              <th width="10%" style="background-color:#333;">Title</th>
              <th width="10%" style="background-color:#333;">Quarter</th>
              <th width="10%" style="background-color:#333;">Is Schedule</th>
              <!-- <th width="10%" style="background-color:#333;">Start Date</th>
              <th width="20%" style="background-color:#333;">End Date</th> -->
              <th width="5%" style="background-color:#333;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let activity of evnt_detail;let i = index">
              <td>{{i+1}}</td>
              <td>{{activity?.title}}</td>
              <td>{{activity?.quarter}}</td>
              <td>{{(activity?.is_schedule) ? 'Yes' : 'No'}}</td>
              <!-- <td>{{activity?.startDate}}</td>
              <td>{{activity?.endDate}}</td> -->
              <td>
                <!-- <button mat-button (click)="onNominateClick(2,activity?.id)" style="
                background-color: green; color: white;">
                  Nominate
                </button> -->
                <p *ngIf="activity?.is_schedule == 0"><button mat-raised-button (click)="openDialog('global', 5,activity?.id)" color="primary">Schedule Invite</button></p>
                <p><button mat-button (click)="onDeleteScheduleClick(activity?.a_id)" style="background-color: red; color: white;" *ngIf="activity?.isMentorParticipated == 0 && activity?.is_schedule == 1">
                  Delete
                </button></p>
              </td>
            </tr>
            <tr *ngIf="evnt_detail?.length==0">
              <td colspan="5" style=" font-size:18px; text-align: center; color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                There is no Institute Events. Please Use Filter to get record.
              </td>
            </tr>    
          </tbody>
        </table>
      </div>
    </div>
    
  </mat-tab>

  <!-- Tab Ends  -->


  <mat-tab label="Participate in ATL Activities">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Participate in ATL Activities</h2>
		</div>
      <div class="col-sm-12">
        <!-- <p>Activities are yet to add by Schools!</p> -->
        <!-- <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p> -->
          <app-atl-invite-iic></app-atl-invite-iic>
      </div>
    </div>

  </mat-tab>

  <!-- Tab Ends  -->


</mat-tab-group>
