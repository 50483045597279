import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iic-innovation-ambassador',
  templateUrl: './iic-innovation-ambassador.component.html',
  styleUrls: ['./iic-innovation-ambassador.component.css']
})
export class IicInnovationAmbassadorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
