import { Component, Inject, OnInit } from '@angular/core';
import { LandingService } from 'src/app/Services/landing.service';
import * as myGlobals from '../../../app.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';

//  Chart Start 2019-20

export var single2 = [
  {
    "name": " Startup",
    "value": 3367
  },
  {
    "name": "Design Thinking & Critical Thinking",
    "value": 986
  },
  // {
  //   "name": "R&D and Innovation",
  //   "value": 7922
  // },
  {
    "name": "Entrepreneurship",
    "value": 6758
  },
  {
    "name": "IPR",
    "value": 1226
  },
  {
    "name": "Innovation",
    "value": 10412
  },
  {
    "name": "Other",
    "value": 3744
  }
];


// Chart Start 2020-21

export var single3 = [
  {
    "name": " Startup",
    "value": 5161
  },
  {
    "name": " Incubation and Pre Incubation",
    "value": 643
  },
  {
    "name": "Design Thinking & Critical Thinking",
    "value": 2147
  },
  {
    "name": "R&D and Innovation",
    "value": 5717
  },
  {
    "name": "Entrepreneurship",
    "value": 7533
  },
  {
    "name": "IPR",
    "value": 2885
  },
  {
    "name": "Innovation",
    "value": 3043
  },
  {
    "name": "Other",
    "value": 1747
  }
];


// Chart Start 2021-22

export var single4 = [
  {
    "name": " Startup",
    "value": 7239
  },
  {
    "name": " Incubation and Pre Incubation",
    "value": 1805
  },
  {
    "name": "Design Thinking & Critical Thinking",
    "value": 7085
  },
  {
    "name": "R&D and Innovation",
    "value": 11773
  },
  {
    "name": "Entrepreneurship",
    "value": 12267
  },
  {
    "name": "IPR",
    "value": 3529
  },
  {
    "name": "Innovation",
    "value": 2623
  },
  {
    "name": "Other",
    "value": 0
  }
];

