 <!--title-->
   <title>

     IIC Advisory Committee | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>

<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Innovation Council</a>
      </li>
      <li class="aht-breadcrumbs__item">
        IIC Advisory Committee
      </li>
    </ul>
  </div>
<h2 style="text-align:center; color:#ff5402; margin-bottom: 20px; margin-top:20px; ">National Advisory Committee for Institution's Innovation Council</h2>


<table align="center" style="margin-bottom: 20px; margin-top:20px; width:800px">
 
  <tr>
    <td><b>Prof. Anil D. Sahasrabudhe</b> <br>
 AICTE</td>
    <td>Chairman</td>
    
  </tr>
  <tr>
    <td><b>Dr. M.P Poonia</b><br>
Vice Chairman ,AICTE
</td>
    
    <td>Member</td>
  </tr>
 <tr>
    <td><b>Prof, Rajnish Jain</b><br>Secretary, UGC

</td>
    
    <td>Member</td>
  </tr>
  
  
  
   <tr>
    <td><b>Mr Anand ADC </b><br> Ministry of MSME

</td>
    
    <td>Member</td>
  </tr>
  
  
   <tr>
    <td><b>Shri Deepanyar Mallick </b><br> DDG, Ministry of MSDE

</td>
    
    <td>Member</td>
  </tr>
  
  
  
   <tr>
    <td><b>Shri R Ramanan </b><br> Mission Director, AIM, NITI Aayog

</td>
    
    <td>Member</td>
  </tr>
  
  
  <tr>
    <td><b>Dr. Anita Gupta</b><br>Adviser & Associate Head, NSTEDB, DST, MoST

</td>
    
    <td>Member</td>
  </tr>
  
  
   <tr>
    <td><b>Dr. Manish Diwan</b><br>Head – Strategic Partnership & Entrepreneurship, BIRAC

</td>
    
    <td>Member</td>
  </tr>
  
  
  <tr>
    <td><b>Dr. H Purushotham</b><br>Chairman cum Managing Director, NRDC

</td>
    
    <td>Member</td>
  </tr>
  
  
    <tr>
    <td><b>Shri Anil Agrawal</b><br>Joint Secretary, Startup India, DPIIT, MoC, GoI

</td>
    
    <td>Member</td>
  </tr>
  
   
    <tr>
    <td><b>Dr. Yashwant Dev Panwar</b><br>TIFAC

</td>
    
    <td>Member</td>
  </tr>
  
   <tr>
    <td><b>Dr. Abhay Jere</b><br>CIO, MHRD’s Innovation Cell
</td>
    
    <td>Member</td>
  </tr>
  
  
  <tr>
    <td><b>Mr. Dipan Sahu</b><br>National Coordinator of IIC, MHRD’s Innovation Cell
</td>
    
    <td>Convener</td>
  </tr>
  
 
  

  
</table>

<!-- Footer -->
<app-inner-footer></app-inner-footer>