import { Injectable,Type } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IicIncubatorstabService {

  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  getIicIncubationDetails(data, page,per_page=10) {
    return this.http.post(
      `${this.baseUrl}/getMicIncubationDetails?per_page=`+per_page+`&page=` + page, data);
  }

  getState() {
    return this.http.get(`${this.baseUrl}/getState`);
  }

  getIicPreIncubationDetails(data, page,per_page=10) {
    return this.http.post(`${this.baseUrl}/getMicPreIncubationDetails?per_page=`+per_page+`&page=` + page, data);
  }
}
