<h3 class="modal-titleDialoge">Institute Details</h3>
<mat-dialog-content class="mat-typography">
   
    <table class="table">
        <thead class="thead-dark">
            <tr>
                <th scope="col">Institute ID</th>
				<th scope="col">Institute's Name</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let institute of institutesList">
                <td>{{institute?.userId}}</td>
				<td>{{institute?.instituteName}}</td>
                <td>{{institute?.City}}</td>
                <td>{{institute?.State}}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="institutesList.length<0">
        <p>No Institutes Found!</p>
    </div>
	<div class="row">
		<div class="col-md-12">
			<mat-paginator #paginator
				showFirstLastButtons
				(page)="nextPage($event)"
				[length]="total_pages"
				[pageIndex]="frontCurrentPageNo"
				[pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
			</mat-paginator>
		</div>
	</div>
    <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()" color="warn" class="btn btn-danger iicInnovModalBut">Close</button>
    </div>
</mat-dialog-content>