import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ThemeService } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';

@Component({
  selector: 'app-mentor-navbar',
  templateUrl: './mentor-navbar.component.html',
  styleUrls: ['./mentor-navbar.component.css']
})
export class MentorNavbarComponent implements OnInit {
  type: string;
  showStatus: any;
  public conscentForm: FormGroup;
  conscentStatus: any;
  conscent: boolean=false;
  conscentModel:any;
  type1: string;
  reports: any;
  ratingstatus: boolean;
  ratingMenteestatus: boolean=false;
  ratingMentorstatus: boolean;
  notEligible: boolean;
  checkmentor: number;
  checkmentee: number;
  noteligible: number;
  finalCriteria: boolean;
  finalCriteria1: number;
  finalCriteria3: number;
  finalCriteria2: number;
  conscentvalue: number;
  showNavbar: number;
  disableStatus: any;
  finalValue: number;
  conscentbyUser:any;
  schedule: any;
  funded_by: any;
  usertype: any;
  MentorMandateStatus:any;
  showUpdated: number;
  isVisible: number =1;
  isEnabled: number =0;

  constructor(private activityService: InstituteActivityService,private router:Router,
     private toastr: ToastrService,private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.showUpdated =0;
    this.conscentForm = this.fb.group({
      'conscent': [null, Validators.required],
    });
    this.getmentorMenteeConsentStatus();
    this.getMenteeStatus();
    this.eligibility();
    this.getMentorStatus();
    this.getMentorMandateStatus();
    // this.navbarEligibility();
  }
  getMenteeStatus(){
    this.activityService.getCheckMentee().subscribe(
      data => this.setMenteeStatus(data)
    );
  }

  getMentorMandateStatus(){
    this.activityService.getMentorMandateStatus().subscribe(
      data => this.setMentorMandateStatus(data)
    );
  }

  setMentorMandateStatus(data){
    this.MentorMandateStatus=data['data']['MentorMandateStatus'];
  }

  setMenteeStatus(data){
    this.schedule=data['status'];
  }
  getMentorStatus(){
    this.activityService.getMentorStatus().subscribe(
      data => this.setMentorStatus(data)
    );
  }
  setMentorStatus(data){
    this.disableStatus=data['data']['0']['nomination_status'];
    // this.getMentorDetails();
    // this.getCordinatorDropdown();
  }
//  checkmandate(){
//     this.activityService.getcheckmandate().subscribe(
//       data => this.setcheckMandate(data)
//     );
//   }

  // setcheckMandate(data){
  //   if(data['status']==1){
  //     this.mandate=1
  //   }else{
  //     this.mandate=0
  //   }
  // }




  getmentorMenteeConsentStatus(){
    this.activityService.getmentorMenteeConsentStatus().subscribe(
      data => this.setmentorMenteeConsentStatus(data)
    );
  }

  setmentorMenteeConsentStatus(data){
  this.conscentStatus   = data['data']['0']['consent_status'];
  this.conscentbyUser   = data['data']['0']['consent'];
  this.funded_by        = data['data']['0']['funded_by'];
  this.usertype         = data['data']['0']['type'];

  localStorage.setItem('FundedBy', this.funded_by);

  this.funded_by = data['data']['0']['funded_by'];



  if(this.conscentStatus=='0' && this.conscentbyUser=="No"){
  this.conscent=true;
  this.conscentModel=this.conscentStatus;
  this.conscentvalue=0
  }

  if(this.conscentStatus=='1' && this.conscentbyUser=="No"){
    this.conscent=true;
    this.conscentModel=this.conscentStatus;
    this.conscentvalue=0;
    this.conscentStatus==0;
    }

  if(this.conscentbyUser==""){
    this.conscent=false;
    this.conscentModel=this.conscentStatus;
    this.conscentvalue=0
    }



  if(this.conscentStatus==2){
  this.conscent=false;
  this.conscentvalue=0;
}

if(this.conscentStatus=='1' && this.conscentbyUser=="Yes"){
  this.conscent=true;
  var x=this.conscentStatus.toString();
  this.conscentModel=x;
  this.conscentvalue=1
  }

  // alert(this.conscentvalue);

  }

  onSubmit(data){
    data['usertype']="Mentee";
      this.activityService.submitMentorMenteeConsent(data).subscribe(
        data => this.handleResponse1(data)
      );
  }

  onSubmit2(data){
    data['usertype']="Mentor";
    this.activityService.submitMentorMenteeConsent(data).subscribe(
      data => this.handleResponse1(data)
    );
}

  handleResponse1(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg'])
      this.getmentorMenteeConsentStatus();
      this.checkMemtorEligible();
      this.getMentorStatus();

    }else{
      this.toastr.success(data['msg'])
      this.getmentorMenteeConsentStatus();
      this.checkMemtorEligible();
      this.getMentorStatus();


    }
  }
  eligibility() {
    this.activityService.getHOI().subscribe(
      data => this.instituteData(data)
    );
  }

  instituteData(data) {
    if (data) {
      var institute_id = data['hoiDetails']['institute_id'];
      // if(institute_id == 'IC201810001'){
      //   this.showUpdated = 1;
      // }
      this.activityService.checkActivityButton(institute_id).subscribe(
        data => this.showActivityButton(data)
      );
    }
  }

  showActivityButton(data){
    this.showStatus=data['status'];
    this.checkMemtorEligible();

    // this.showStatus=0;
  }

  checkMemtorEligible(){
    this.activityService.checkMemtorEligible().subscribe(
      data => this.setcheckMemtorEligible(data)
    );
  }

  setcheckMemtorEligible(data){
    if(data['mentor_eligible']==1){
      this.type="mentor"
      this.type1="Mentee"
      this.checkmentor=1;
      this.checkmentee=0;

      this.ratingMentorstatus=true;
      this.isVisible=0;
    }else{
      this.type="mentee"
      this.type1="Mentor"
      this.ratingMenteestatus=true;
      this.checkmentee=1;
      this.checkmentor=0;

    }

    this.reports=data['reports'];
    if(this.checkmentor==1  && this.reports==1 && this.showStatus==1){
      this.finalCriteria1=1;
      this.finalCriteria3=1;

    }else if(this.checkmentee==1 && this.reports==1 && this.showStatus==1){
      this.finalCriteria1=1;
      this.finalCriteria2=1;

    }
     else{
      this.finalCriteria1=0;
    }

if(data['mentor_eligible']==1){
  this.finalValue=1;
}else if(data['mentee_eligible']==1){
  this.finalValue=1;
}else{
  this.finalValue=0;
  this.finalCriteria1=0;
}

// alert(this.conscentvalue);

    // if((data['mentor_eligible']==0) && (data['mentee_eligible']==0) ){
    //   this.notEligible=true;
    //   this.noteligible=1
    // }else{
    //   this.notEligible=false;
    // }

  }


  // navbarEligibility(){
  //   this.checkMemtorEligible();
  //   this.getmentorMenteeConsentStatus();

  //   if(this.finalCriteria1==1 && this.conscentvalue==1){
  //     this.showNavbar=1
  //   }
  // }
}
