import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { emailValidator } from 'src/app/app.component';

@Component({
  selector: 'app-institute-affiliate-program-add-dialog',
  templateUrl: './institute-affiliate-program-add-dialog.component.html',
  styleUrls: ['./institute-affiliate-program-add-dialog.component.css']
})
export class InstituteAffiliateProgramAddDialog implements OnInit {

  signupForm: FormGroup;

  show1: boolean;
  show: boolean;
  data1: any;
  id: any;
  ActivityModel:any;
  minDate2: any;
  requField: boolean = false;
  requField2: any;
  edit: boolean;
  editData: any;
  document:any;
  ip_type:any;
  ip_status:any;
  ip_app_number:any;
  filed_ipr:any;
  ipr_document: FileList | null;
  datashow:any;

  totalcount = 0;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  public error = [];

  instituteDetails: any;

  institute_name: any = '';
  institute_address: any = '';
  institute_city: any = '';
  institute_state: any = '';
  uploadDiv: boolean;

  constructor(  private jarwis: JarwisService, private fb: FormBuilder, private route: ActivatedRoute,
  private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,
   private toastr: ToastrService, public dialogRef: MatDialogRef<InstituteAffiliateProgramAddDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    if ((this.data == undefined) || (this.data == null)) {
      this.show1 = true;
    }
    else {
      this.show = true;
      this.data1=data
    }
    }

  ngOnInit(): void {

    this.signupForm = this.fb.group({
      name: [null, Validators.required],
      aicte_approved_program: [null, Validators.required],
      pid: [null],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      mobile_number: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      // aishe_code: [{ value: null, disabled: true }, Validators.required],
      // institute_name: [{ value: null, disabled: true }, Validators.required],
      // institute_city: [{ value: null, disabled: true }, Validators.required],
      // institute_state: [{ value: null, disabled: true }, Validators.required],
      // institute_address: [{ value: null, disabled: true }, Validators.required],
    });

    if(this.show==true){
      this.setData(this.data1);
    }

  }

  setData(data){

    this.datashow = data;

    // this.signupForm.controls['institute_name'].setValue(this.datashow['name']);
    // this.signupForm.controls['institute_city'].setValue(this.datashow['city']);
    // this.signupForm.controls['institute_state'].setValue(this.datashow['state']);
    // this.signupForm.controls['institute_address'].setValue(this.datashow['address_line1'] +' '+ data['address_line2']);
    // this.signupForm.controls['aishe_code'].setValue(this.datashow['aicte_code']);

  }

    onSubmit(values){
      if (this.signupForm.valid) {

        values['role']  = 3;
        values['institute_name'] = this.datashow['name'];
        values['address'] = this.datashow['address_line1']+' '+this.datashow['address_line2'];
        values['city'] = this.datashow['city'];
        values['state'] = this.datashow['state'];

        values['action'] = "add";
        values['rtype'] = "affiliate";

        this.instituteActivityService.AffiliateInsSubmit(values).subscribe(
          data => this.handleResponse(data)
        );


    } else {
      this.toastr.error('Kindly fill all the details');
    }

    }

    handleResponse(data){
      this.toastr.success(data['message']);
      this.dialogRef.close();
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    onNbaStatusChange(ob) {
      let nbaStatus = ob.value;
      if(nbaStatus == 'No') {
        this.requField = false;
      } else {
        this.requField = true;
      }

    }

    onIPStatusChange(ob) {
      let nbaStatus = ob.value;
      if(nbaStatus == 'Filed') {
        this.requField2 = 4;
      } else {
        this.requField2 = 5;
      }

    }

    changeDate(values) {
      var value = '';
      value = value + new Date(values).getFullYear().toString();
      value = value + '-' + (new Date(values).getMonth() + 1);
      value = value + '-' + new Date(values).getDate().toString();
      values = value;
      return values;
    }

    // for toastr
  showSuccess(message: string) {
    this.toastr.success(message, 'Success');
  }
  showError(message: string) {
    this.toastr.error(message, 'Error!');
  }
  showWarning(message: string) {
    this.toastr.warning(message, 'Warning');
  }
  showInfo(message: string) {
    this.toastr.info(message, 'Info');
  }


  handleError(error) {
    this.showError(error.error.errors);
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

}
