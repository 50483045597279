<div class="container row">


    <!-- <div class="col-12" style="text-align: center" *ngIf="data=='MIC'">
        <h3>You are eligible to apply as <strong>Mentor</strong> in Mentor-Mentee Scheme of IIC.
          <br/>For more details, please click on Mentor-Mentee under Scheme Menu</h3>
    </div> -->
    <div class="col-12" style="text-align: center" *ngIf="data?.fundedBy=='MIC'">
        <h3>Your institute is selected for the <strong>Mentor role with funding support</strong> from MoE's Innovation Cell for the academic year 2023-24.  Please submit your consent to take the application forward by 27, October 2023.</h3>
      <!-- <h3>Your institute is selected for <strong>Mentor Mentee Scheme</strong> with funding support from MOE's Innovation Cell.</h3> -->
    </div>
    <div class="col-12" style="text-align: center"  *ngIf="data?.fundedBy=='Volunteer'">
      <h3>Your institute is selected for the <strong> Mentor role without funding support (volunteer)</strong> from MoE's Innovation Cell for the academic year 2023-24. Please submit your consent to take the application forward by October 27, 2023.</h3>
      <!-- <h3>Your institute is selected for <strong>Mentor Mentee Scheme</strong> without funding support from MOE's Innovation Cell.</h3> -->
    </div>

    <div *ngIf="data?.fundedBy">
      <form [formGroup]="conscentForm" (ngSubmit)="onSubmit(conscentForm.value)">
        <div class="row">
          <div fxFlex="50" class="m-2">
        <mat-label style="margin-left: 47px; ">Are your institute willing? </mat-label>
          </div>
        <div fxFlex="50" class="m-8" style="margin-left: 83px; margin-right: 113px; ">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon>
            <mat-select placeholder="Are your institute willing?" formControlName="conscent" required  >
              <mat-option value="1">Yes</mat-option>
              <mat-option value="0">No</mat-option>
            </mat-select>
            <mat-error *ngIf="conscentForm.controls['conscent'].hasError('required')">
              Institute willingness  is
              required</mat-error>
          </mat-form-field>
        </div>

        <div  fxFlex="50" class="m-8">
            <button  mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='conscentForm.invalid'>Submit</button>
          </div>
        </div>
      </form>

    </div>

    <div class="col-12" style="text-align: center;">
      <button type="button" class="btn btn-danger" mat-dialog-close>Close</button>
    </div>

    <!-- <div class="col-6" style="text-align: end">
        <button type="button" class="btn btn-danger" (click)="openPage()">Yes</button>
    </div>
    <div class="col-6">
        <button type="button" class="btn btn-success" mat-dialog-close>No</button>
    </div>

    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close>No</button>
        <button mat-button (click)="deleteDialog()">Yes</button>
    </mat-dialog-actions> -->


</div>
