<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Incubation</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Incubation</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Resources</a></li>
    <li class="breadcrumb-item active" aria-current="page">Incubation</li>
  </ul>
</nav>

<div class="cbp-row wh-content incubatorBox">
			<div class="cbp-container wh-padding">
				
				<div class="vc_row innovationAmbassadorBox ambPadding1">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Incubation Center</h2>
						</div>
					</div>
					
					<div class="limiter" >

	<div class="row drop-sub-box">
		<form [formGroup]="incubators" (ngSubmit)="onSubmit(incubators.value)" class="formIICInnoFaculty">
		<div class="col-md-3">
			<div class="form-group">
				<label>Type of Registration</label>
				<select class="form-control" id="type_of_registration" formControlName="type_of_registration">
					<option value="">All</option>
					<option value="SPV:Section 8 Company/Society Act">SPV:Section 8 Company/Society Act</option>
					<option value="Associate with Academic Department">Associate with Academic Department</option>
					<option value="Established by Private entity &amp; housed at institute">Established by Private entity &amp; housed at institute</option>
				</select>
			</div>
		</div>
		
		<div class="col-md-3">
			<div class="form-group">
				<label for="funding_scheme_support_received">Funding Schemes/Support Received:</label>
				  <select class="form-control" id="funding_scheme_support_received" formControlName="funding_scheme_support_received">
					<option value="">All</option>
					<option value="DBT/BIRAC">DBT/BIRAC</option>
					<option value="DST/NSTEDB/NIDHI">DST/NSTEDB/NIDHI</option>
					<option value="AIM/NITI Ayog">AIM/NITI Ayog</option>
					<option value="MEITY">MEITY</option>
					<option value="MSME">MSME</option>
					<option value="CSR/Private">CSR/Private</option>
					<option value="Own Resource">Own Resource</option>
					<option value="Other Central/State Government">Other Central/State Government</option>
				  </select>
			</div>
		</div>
		
		<div class="col-md-4">
			<div class="form-group">
				<label class="incubState">State</label>
				<mat-form-field class="incubTabDropdown">
					<mat-select formControlName="state" multiple [(ngModel)]="StateModel">
						<app-select-check-all [model]="incubators.get('state')" [values]="allvalues">
						</app-select-check-all>
						<mat-option *ngFor="let state of state" [value]="state.id">
						  {{state.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		
		<div class="col-md-2">
			<div class="form-group">
				<button type="submit" name="Submit" id="submit" class="sub-but">Submit</button>
			</div>
		</div>
	</form>
	</div>
	
	<div class="row">
	<div class="container-login100">
		<div class="tab-box" id="newContentIncubation">
			<!-- Incubation Data -->
			<div id="incubators" class="tab-pane active">
				<div id="newContentIncubations">
					<p style="color:#ff5402;font-size:24px;font-weight:700;"></p>
					<div id="myTable_wrapper" class="dataTables_wrapper no-footer">
						<div class="table-responsive">
							<table class="table table-bordered dataTable no-footer incubTableMobRes" style="color: #000;" id="myTable" role="grid" aria-describedby="myTable_info">
								<thead>
									<tr role="row">
										<th class="sorting_asc" tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort="ascending" aria-label="S.No.: activate to sort column descending" style="width: 2%;">S.No.</th>
										<th class="sorting" tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Institute Name &amp;amp; Address: activate to sort column ascending" style="width: 213.2px;">Institute Name &amp; Address</th>
										<th class="sorting" tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Unit/Center Details: activate to sort column ascending" style="width: 193.2px;">Unit/Center Details</th>
										<th class="sorting" tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Incharge Details: activate to sort column ascending" style="width: 258.8px;">Incharge Details</th>
										<th class="sorting" tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Thrust Area: activate to sort column ascending" style="width: 220.4px;">Thrust Area</th>
									</tr>
								</thead>
								<tbody>
									<tr role="row" class="odd" *ngFor="let item of incubatorDetails; let i = index">
										<!-- <td class="sorting_1">{{item?.serial_number}}</td> -->
										<td>{{i+1}}</td>
										<td>
											<p>{{item?.institutesDetails['0']['instituteName']}}</p>
                                            <p><b>City: </b>{{item?.institutesDetails['0']['instituteCity']}}</p>
                                            <p>
                                                <b>State: </b>{{item?.institutesDetails['0']['instituteState']}}
                                            </p>
                                            <p *ngIf="item?.institutesDetails['0']['institutePincode']" ><b>Pin Code: </b>{{item?.institutesDetails['0']['institutePincode']}}</p>
                                            <p *ngIf="!item?.institutesDetails['0']['institutePincode']" ><b>Pin Code: </b>N/A</p>
										</td>
										<td>
                                            <p><b>Name: </b>{{item?.name}}</p>
                                            <p><b>Registration Type: </b>{{item?.type_of_registration}}</p>
                                            <p><b>Funding Schemes/Support Received: </b>{{item?.funding_scheme_support_received}}</p>
                                            <p class="para-break"><b>Url: </b>
                                                <a target="_BLANK" href="{{item?.website_url}}" *ngIf="item?.website_url!='null'">{{item?.website_url}}</a>
                                                <b style="font-weight: normal;" *ngIf="item?.website_url=='null'">N/A</b>
                                            </p>
                                        </td>
										<td>
											<p><b>Name: </b>{{item?.incubation_incharge_name}}</p>
                                            <p><b>Email: </b>{{item?.incubation_incharge_email}}</p>
                                            <!-- <p><b>Phone: </b>{{item?.incubation_incharge_phone}}</p> -->
										</td>
										<td>{{item?.thrust_area}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<mat-paginator #paginator
				showFirstLastButtons
				(page)="nextPage($event)"
				[length]="total_pages"
				[pageIndex]="frontCurrentPageNo"
				[pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
			</mat-paginator>
		</div>
	</div>	
	</div>
					
				</div>
				
				<div class="vc_row-full-width vc_clearfix"></div>
				
			</div>
		</div>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>