<div class="container">
  <div class="row" color="primary">
    <h2 class="ss w-100" style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px; ">
            Innovation Transfer Request</h2>
  </div>
	<!-- <div class="card">
    <div class="card-title" style="text-align: center;"><p>Create and Manage Idea/ Innovation/ Startup Challenges at Institute level.</p></div>
	</div> -->
  <div class="refrredBox">
    <div class="row rws-listpage">
	    <div class="col-md-12">
        <!-- <p><a class="btn btn-primary referralBut" routerLink='/institute/yukti-challenges-add/add'>Add New</a></p> -->
        <div class="tableBody">
				  <div class="table-responsive">
            <table class="table table-hover">
              <thead class="facultyTable">
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Innovation Title</th>
                  <th scope="col">Old Team Lead Email</th>
                  <th scope="col">New Team Lead Email</th>
                  <th scope="col">Aprroval Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let nomination of transferInnovation| paginate: { itemsPerPage: 10, currentPage: page }; let i = index;" >
                  <td>{{i+1}}</td>
                  <td>{{nomination?.idea_title}}</td>
                  <td>
                      {{nomination?.old_team_lead_email}}
                  </td>
                  <td>{{nomination?.new_team_lead_email}}</td>
                  
                  <td> {{nomination?.approval_status}}  </td>
                  <td>   
                    <p *ngIf="nomination?.approval_status=='Pending'">
                      <button mat-raised-button (click)="onClickEvent(nomination?.id)" color="warn" class="btn-tbl-delete"> <mat-icon>delete</mat-icon></button>
                    </p>
                    <p *ngIf="nomination?.approval_status=='Pending'">
                      <button mat-raised-button (click)="sendReminder(nomination?.id)" color="warn" class="btn-tbl-delete"> <mat-icon>notification_important</mat-icon></button>
                    </p>
                  </td>
                </tr>
                <tr *ngIf="transferInnovation?.length== 0" >
                  <td colspan="5"> <h3 style="text-align: center">No Data Found</h3></td>
                </tr>
              </tbody>
            </table>
            <pagination-controls class="annular-pagination float-right" maxSize="3" (pageChange)="onPageChanged($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>