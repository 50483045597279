
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group" style="box-shadow:0 0px 10px rgb(0 0 0 / 20%) !important; margin: 20px 0 0 0;">
        <div class="mt-4 table-responsive" style="margin-top:0 !important;">
          <table class="table table-hover ideaTableHover table-bordered" style="margin-top: 0px !important;">
            <thead class="facultyTable">
              <tr>
                <th colspan="10" style="text-align:center;background-color: #0d133d;padding-top:5px !important;">
                  <h4 class="innovTitle">List of recommended Innovations/Prototypes For Regional Meet Nomination</h4>
                </th>
              </tr>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Innovation ID</th>
                <th scope="col">Innovation Title</th>
                <th scope="col">Developed as part of</th>
                <th scope="col">Team Lead Details</th>
                <th scope="col">Submitted Status</th>
                <th scope="col">Willing to Exhibit</th>
                <th scope="col">Nomination</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let value of prototypeSubmission; let i = index">
                <td>{{ i+1 }}</td>
                <td>{{ value?.team_id }}</td>
                <td>{{ value?.idea_title }}<br><b>TRL Title - {{ value?.trl_level }}</b></td>
                <td>{{ value?.developed_as_part_of }}</td>
                <td>{{ value?.team_lead_name }}<br/>{{ value?.team_lead_email }}<br/>{{ value?.team_lead_phone }}<br/>{{ value?.user_type }}<br>{{ value?.enrollment_id }}</td>
                <td><span [ngStyle]="{'display': (value?.isSubmittedDocumnet==0) ? 'block' : 'none', 'color' : 'blue'}">Not Submitted</span>
                  <span [ngStyle]="{'display': (value?.isSubmittedDocumnet==1) ? 'block' : 'none', 'color' : 'blue'}">Submitted</span></td>
                  <td>{{ value?.is_given_demo }}</td>
                  <td>
                    <!-- (ngSubmit)="onSubmit(innovationNomantion.value)" -->
                  <div id="nominateSuccessForm-{{ value?.idea_id }}" [ngStyle]="{'display': (value?.is_rm_nomination==0) ? 'block' : 'none'}">
                    <form [formGroup]="innovationNomantion"   >
                      <input [hidden]="true" [ngModel]="value?.idea_id" formControlName="innovation_id">
                      <input [hidden]="true" [ngModel]="rid" formControlName="challenge_id">
                      <mat-form-field class="instiIdeaSelectBox">
                        <mat-label>Regional Meet City</mat-label>
                        <mat-select formControlName="city">
                          <mat-option *ngFor="let val of cities" [value]="val?.host_city">{{ val?.host_city }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="instiIdeaSelectBox">
                        <mat-label>Willing to Exhibit</mat-label>
                        <mat-select formControlName="is_demo">
                          <mat-option value="1">Yes</mat-option>
                          <mat-option value="0">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="instiIdeaSelectBox">
                        <mat-label>Number of Participant</mat-label>
                        <mat-select formControlName="no_of_participant">
                          <mat-option value="1">1</mat-option>
                          <mat-option value="2">2</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- <button type="submit" class="btn btn-primary">Nominate</button> -->
                        <button type="button" (click)="nominateChallenge(value?.idea_id,value?.challenge_id)" class="btn btn-success verify-btn" id="nominateSuccess-{{ value?.idea_id }}"  [ngStyle]="{'display': (value?.is_rm_nomination==0) ? 'block' : 'none'}">
                          Nominate
                        </button>
                    </form>
                  </div>
                  <button type="button" (click)="RemoveChallenge(value?.idea_id)" class="btn btn-danger verify-btn" id="nominateRemove-{{ value?.idea_id }}" [ngStyle]="{'display': (value?.is_rm_nomination==1) ? 'block' : 'none'}">
                      Remove Nominated
                    </button>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)" [length]="total_pages" [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                          </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>