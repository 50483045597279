import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as myGlobals from '../../../../../../../app/app.component';

@Component({
  selector: 'app-repository-dialog',
  templateUrl: './repository-dialog.component.html',
  styleUrls: ['./repository-dialog.component.css']
})
export class RepositoryDialogComponent implements OnInit {
  data: any;
  activity: any;

  baseUrl = myGlobals.baseImageUrl;

  constructor(private activityService: InstituteActivityService,
    private dialogRef1: MatDialogRef<RepositoryDialogComponent>) {

     }

    ngOnInit(): void {
      this.activity = this.data['activity'];
    }

    onNoClick(): void {
      this.dialogRef1.close();
    }
}
