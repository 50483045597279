import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { JarwisService } from 'src/app/Services/jarwis.service';

@Component({
  selector: 'app-teaching-learning',
  templateUrl: './teaching-learning.component.html',
  styleUrls: ['./teaching-learning.component.css']
})
export class TeachingLearningComponent implements OnInit {

  data:any;
  page_data: any;
  //myArray:[];
  selectdiv:boolean;
  academic: any;
  nonacademic: any;
  ForWhom: any;

  constructor(private jarwis: JarwisService, public fb: FormBuilder) {

     }
 

  ngOnInit(): void {
    this.jarwis.teachingLearningData('All','NONE').subscribe(data => this.setData(data));

    this.ForWhom=localStorage.getItem('ForWhom');
  }

  setData(data){
    this.academic     = data['AcademicList'];
    this.nonacademic  = data['NonAcademicList'];
  }
  
  onClickEvent(data,type,subtype){

    if(subtype=='Academic')
    {
      if(type=="Edit")
      {
        window.location.href="/institute/teaching-learning-add/"+data;
      }
      else
      {
        if (confirm("Are you sure you want to delete?")) {
          this.jarwis.deleteAcademicDetails(data, subtype).subscribe(
            data => this.handleResponse1(data)
          );
        }
      }
    }
    else
    {
      if(type=="Edit")
      {
        window.location.href="/institute/teaching-nonacademic-add/"+data;
      }
      else
      {
        if (confirm("Are you sure you want to delete?")) {
          this.jarwis.deleteAcademicDetails(data, subtype).subscribe(
            data => this.handleResponse1(data)
          );
        }
      }
    }
  }

  handleResponse1(data)
  {

  }

}







