import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-members-certificates1819',
  templateUrl: './members-certificates1819.component.html',
  styleUrls: ['./members-certificates1819.component.css']
})
export class MembersCertificates1819Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
