import { Component, OnInit } from '@angular/core';
import { AbstractControl,FormArray,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { emailValidator } from 'src/app/app.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-transfer-innovation-with-registration',
  templateUrl: './transfer-innovation-with-registration.component.html',
  styleUrls: ['./transfer-innovation-with-registration.component.css']
})
export class TransferInnovationWithRegistrationComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  protoTypeList:any;
  protoTypeList1:any;
  old_team_lead_data:any = [];
  new_team_lead_data:any = [];
  isRegister:any = 0;
  isNew:any = 0;
  isNewRegister:any = 0;
  isExistingUser:any = 0;
  isEnable:any = 0;
  copyrightcoinv: FormArray = this.fb.array([]);
  dataSource2 = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns2 = [
    'institute_type',
    'prototype',
    'type',
    'title',
    'year_of_development',
    'action',
  ];
  existingInnovation:any=0;
  innovationUserData:any=[];
  innovationData:any=[];
  isAll:any = 0;
  isDisabled:any = false;

  isReadOnly:boolean=false;

  constructor(private fb: FormBuilder, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {



  }

  ngOnInit(): void {
    this.iicImpactQuestions = this.fb.group({
        'prefer_type': [null],
        'innovator': [null],
        'name': [null],
        'email': [null],
        'mobile_number': [null],
        'gender': [null],
        'user_type': [null],
        'copyright_coinventors': this.copyrightcoinv,
      });
    

    
    this.getTeamLeadData();
    this.instituteActivityService.getProtoType().subscribe(
      (data) => this.handleResponseProtoType(data)
    ); 
    this.instituteActivityService.getInnovationUserData().subscribe(
      (data) => this.handleResponseInnovationUserData(data)
    ); 
  }

  handleResponseProtoType(data,id="") {
    if(id){
      this.protoTypeList =data;
    }else{
      this.protoTypeList1 = data;
    }
  } 
  handleResponseInnovationUserData(data,val=""){
    if(val){
      this.innovationData = data;
      this.isReadOnly = true;
      this.iicImpactQuestions.patchValue({
        'name': data['team_lead_name'],
        'email': data['team_lead_email'],
        'mobile_number': data['team_lead_phone'],
        'gender': data['team_lead_gender'],
        'user_type': data['user_type']
      });
    }else{
      this.innovationUserData = data;
    }
  }
  changeForPreferType (value){
    if(value=="old"){
      this.existingInnovation = 1;
    }else{
      this.existingInnovation = 0;
    }
    this.isAll = 1;
    this.isReadOnly = false;
  }
  changeForInnovationUser(value){
    if(value){
      this.instituteActivityService.getInnovationUserData(value).subscribe(
        (data) => this.handleResponseInnovationUserData(data,value)
      );
    }
  }
  getTeamLeadData(id = ""){
    if(id){
      this.instituteActivityService.getProtoType(id).subscribe(
        (data) => this.handleResponseProtoType(data,id)
      ); 
    }else{
      this.instituteActivityService.getTeamLeadData().subscribe(
        (data) => this.handleResponseTeamLeadData(data)
      );
    }
  }

  handleResponseTeamLeadData(data){
    this.new_team_lead_data = data;
  }

  onSelectBoxChange(value){
    this.getTeamLeadData(value);
  }
  
  

  onSubmit(data) {
    var formData = new FormData();

    // formData.append('type', this.iicImpactQuestions.get('type').value);
    // formData.append('prototype', this.iicImpactQuestions.get('prototype').value);

    // if(this.iicImpactQuestions.get('type').value == 'transfer'){

      formData.append('prefer_type', this.iicImpactQuestions.get('prefer_type').value);
      formData.append('innovator', this.iicImpactQuestions.get('innovator').value);
      formData.append('name', this.iicImpactQuestions.get('name').value);
      formData.append('email', this.iicImpactQuestions.get('email').value);
      formData.append('mobile_number', this.iicImpactQuestions.get('mobile_number').value);
      formData.append('gender', this.iicImpactQuestions.get('gender').value);
      formData.append('user_type', this.iicImpactQuestions.get('user_type').value);
      formData.append('coinventors', JSON.stringify(data.copyright_coinventors));
      

      this.instituteActivityService.submitTransferInnovatorForm(formData).subscribe(
        data => this.handleResponseData(data));
    // }else{
    //   formData.append('new_team_lead_id', this.iicImpactQuestions.get('new_team_lead_id').value);
    
    //   this.instituteActivityService.submitYuktiInnovatorInterchange(formData).subscribe(
    //     data => this.handleResponseData(data));
    // }
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/transfer-existing-innovator';
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  radioChange(value){
    if(value=="new_user"){
      this.isRegister = 0;
      this.isNew = 1;
    }else{
      this.isRegister = 1;
      this.isNew = 0;
    }
    this.isEnable=1;
  }
  
  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

  addRowforcopyRightCoinventors() {

    //Added Validation : Add row only works when the all the previous fields are valid. //By Ninad LTI
    if (this.copyrightcoinv.valid) {
      const row = this.fb.group({
        institute_type: [null, Validators.required],
        prototype: [null],
        type: [null, Validators.required],
        title: [null, Validators.required],
        year_of_development: [null, Validators.required],
        // action: [null, Validators.required],
      });

      this.copyrightcoinv.push(row);

      this.updateViewforCopyrightCoinvestors();
    } else {
      this.toastr.error('Please fill the previous row first');
    }
  }

  updateViewforCopyrightCoinvestors() {
    this.dataSource2.next(this.copyrightcoinv.controls);
  }

  onDeleteRowforCopyrightCoinventors(rowIndex: any) {
    let inchargeList = this.iicImpactQuestions.get('copyright_coinventors') as FormArray;

    inchargeList.removeAt(rowIndex);

    this.updateViewforCopyrightCoinvestors();
  }
  get formFields(): FormArray {
    return this.iicImpactQuestions.get('copyright_coinventors') as FormArray;
  }

  changeForType(index,value){
    console.log(index);
    console.log(value);
    document.getElementById("title-"+index).style.display = "";
    document.getElementById("type-"+index).style.display = "";
    document.getElementById("year-"+index).style.display = "";
    if(value == 'old'){
      document.getElementById("prototype-"+index).style.display = "";
    }else if(value == 'new'){
      document.getElementById("prototype-"+index).style.display = "none";

    }
  }

  changeForProtoType(index,value){
    this.instituteActivityService.getProtoTypeTransfer(value).subscribe(
      (data) => this.handleResponseProtoTypeCheck(data,index)
    );
  }

  handleResponseProtoTypeCheck(data,index){
    this.formFields.at(index).patchValue({
      title: data['title'],
      year_of_development: data['year'],
      type: data['type'],
    });
    // this.iicImpactQuestions['copyright_coinventors']["'"+index+"'"]['title'].setValue('copyright_coinventors');
    // this.iicImpactQuestions.patchValue({
    //   'institute_type': data['school_name'],
    //   prototype: data['district'],
    //   email: data['email'],
    //   mobile_number: data['contact_no'],
    //   institute_address: data['address']
    // });
    // this.iicImpactQuestions.FormGroup.controls.name.setValue(response.name);
    // document.getElementById("title-"+index).value = "gfg";
    // document.getElementById("type-"+index).value;
    // document.getElementById("year-"+index).value = "";
  }

}
