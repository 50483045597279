<!-- List starts -->

   <div class="row" color="primary">
    <h2 class="ss"
        style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
     Action Plan & Scheduled Activities</h2>
</div>

<div class="alert alert-info" role="alert" *ngIf="TMentee==0">
  Awating response from mentee institutes. At-least one mentee institute need to accept the  invitation then Activity Schedule will be enabled.
</div>

<mat-tab-group class="matGroupActionPlan"  *ngIf="TMentee>0">
  <mat-tab label="Orientation & Mentoring sessions">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Orientation & Mentoring sessions</h2>
		</div>
      <div class="col-sm-10">
        <p>Conduct an orientation cum mentoring session for all the key functionaries of IIC members at mentee institutions.</p>
          <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p>
          <div class="collapse multi-collapse" id="rws-guideline1">
            <div class="card card-body">
              <ul>
                <li>At least two orientations cum mentoring sessions for all mentee institutions or separate sessions for each mentee institution may be planned and conducted.</li>
                <li>Orientation session shall be conducted through online mode using video conferencing platforms.</li>
                <li>Key functionaries of IIC at the mentor IIC institute shall join as mentor experts and will take the session and explain the best practices, cases of their institute on how they are driving the I&E ecosystem highlighting resource mobilization strategies.</li>
                <li>Mentor IIC institute may spend expenses towards honorarium to the experts. The honorarium of Rs. 3000/- per expert per session and two such orientation cum mentoring sessions to be conducted by the mentor institute during the IIC calendar year.</li>
              </ul>
            </div>
          </div>
      </div>
      <div class="col-sm-2">
        <p><button mat-raised-button (click)="openDialog(1)" [disabled]='!this.activity1' color="primary">Schedule</button></p>
        </div>
    </div>
    <div style="overflow-x: auto;">
    <table class="table table-hover">
      <thead class="facultyTable">
          <tr>
              <th scope="col" style="width: 2%;"> Sr.No.</th>
              <th scope="col">Mentee Institution Details</th>
              <th scope="col">Session No.</th>
              <th scope="col" style="width: 8%;">Schedule & Details</th>
              <th scope="col" style="width: 10%;">Speaker Details</th>
              <th scope="col">Activity Brochure/Schedule</th>
              <!-- <th scope="col">Report</th>
              <th scope="col">Action</th> -->
              <th scope="col" style="width: 20%;">Update Schedule</th>
              <th scope="col" style="width: 10%;">Action by Mentee</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let schedule of schedules; index as i"  >
              <td >{{i+1}}</td>
              <td *ngIf="schedule?.mentee_id!=null">{{schedule?.mentee_id}}</td>
              <td *ngIf="schedule?.mentee_id==null">For all Mentee Institutions</td>
              <td *ngIf="schedule?.activity!=null">{{schedule?.activity}}</td>
              <td *ngIf="schedule?.activity==null">N/A</td>
              <td >
                <strong>Date:</strong> {{schedule?.scheduled_date}}<br>
                <strong>Time:</strong> {{schedule?.scheduled_time}}<br>
                <strong>Link:</strong> {{schedule?.meeting_id}}
              </td>
              <td *ngIf="schedule?.speaker_name!=null">
                <strong>Name:</strong> {{schedule?.speaker_name}}<br>
                <strong>Designation:</strong> {{schedule?.speaker_designation}}<br>
                <strong>Contact:</strong> {{schedule?.contact}}
              </td>
             <td *ngIf="schedule?.file_upload!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.file_upload}}" target="_blank">Preview</a></td>
             <td *ngIf="schedule?.file_upload==null">N/A</td>
			 <!-- <td>
                <button mat-raised-button color="primary" class="mentorButEdit">Upload Report</button>

              </td>
			  <td>
                <button mat-raised-button color="primary" class="mentorButEdit">Scheduled</button>

              </td> -->
              <td>

                <p><button mat-raised-button (click)="onEditClick(schedule?.id,schedule?.activity_id)" color="primary" class="mentorButEdit">
                    <mat-icon>create</mat-icon>
                    Edit</button></p>
                  <p><button mat-raised-button (click)="onDeleteClick(schedule?.id)" color="warn">
                    <mat-icon>delete</mat-icon>
                    Delete</button></p>

                    <div *ngIf="i==0">
                      <p *ngIf="RQ_1_file==''"><button mat-raised-button (click)="openReportDialog(1)" color="primary">Upload Report</button></p>
                      <p *ngIf="RQ_1_file!=''"><button mat-raised-button (click)="openReportDialog(1)" color="primary">Update Report</button></p>
                      <p *ngIf="RQ_1_file!=''"><a target="_blank" href="{{ baseUrl + RQ_1_file}}">View File</a></p>
                    </div>

                    <div *ngIf="i==1">
                      <p *ngIf="RQ_12_file==''"><button mat-raised-button (click)="openReportDialog(12)" color="primary">Upload Report</button></p>
                      <p *ngIf="RQ_12_file!=''"><button mat-raised-button (click)="openReportDialog(12)" color="primary">Update Report</button></p>
                      <p *ngIf="RQ_12_file!=''"><a target="_blank" href="{{ baseUrl + RQ_12_file}}">View File</a></p>
                    </div>

              </td>
              <td>
                <p *ngIf="OmStatus_total==0">-</p>
                <p *ngIf="OmStatus_total>0"><button type="button" class="btn btn-success"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new" (click)="onActivityStatus(1,schedule?.activity_id,schedule?.activity)"> View ({{OmStatus_total}})</a></button></p>
              </td>
          </tr>
          <tr *ngIf="schedules.length<1">
              <td colspan="9"><h3 style="text-align: center">No Data Found</h3></td>
          </tr>
      </tbody>
  </table>

  </div>
  <div class="modal fade bs-example-modal-new activityModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="body-message">
            <table class="table table-hover table-response tableGuidence">
              <thead class="facultyTable">
                <tr>
                  <th scope="col" style="width: 25%;">Mentee Institute</th>
                  <th scope="col" style="width: 25%;">Participation Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let mmData of OmStatus; index as i"  >
                <td>{{mmData?.mentee_userId}}</td>
                <td><p>{{mmData?.status}}</p></td>
              </tr>
              </tbody>
              </table>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        </div>

      </div>
      <!-- Modal Content: ends -->

    </div>

  </div>

</mat-tab>
  <!-- Tab Ends  -->

  <mat-tab label="Quarterly Progress Meetings">

    <app-activity-report-form-qpm></app-activity-report-form-qpm>

  </mat-tab>

<!-- Tab Ends -->

  <mat-tab label="Organise IIC calendar activity">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Organise IIC calendar activity</h2>
		</div>
      <div class="col-sm-10">
        <p>Plan and deliver two IIC calendar activity for each mentee IIC through an external experts identified by the mentor institute</p>
          <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline3" role="button" aria-expanded="false" aria-controls="rws-guideline3">View Guidlines</a></p>
          <div class="collapse multi-collapse" id="rws-guideline3">
            <div class="card card-body">
              <ul>
                <li>Mentor institute in coordination with the mentee institute will identify two IIC calendar activities to be conducted in the mentee IIC institute.</li>

                <li>Mentor institute will prepare the session plan and identify the external resource persons to deliver the session.</li>

                <li>Activities shall be conducted in online mode using video conferencing platforms.</li>

                <li>Each session/activity should accommodate at least two external experts to deliver the session for the participants of the mentee institute.</li>

                <li>Mentee institute will ensure a minimum participation of 200 nos including IIC members, students and faculties and staff.</li>

                <li>Mentor IIC institute may spend expenses towards honorarium to the external experts. The honorarium of Rs. 3000/- per expert, two experts per session and two sessions per IIC calendar year to be conducted in the mentee institute by the mentor institute.</li>
              </ul>
            </div>
          </div>
      </div>
      <div class="col-sm-2">
        <p><button mat-raised-button (click)="openDialog(3)" [disabled]='!this.activity3' color="primary">Schedule</button></p>
      </div>
    </div>

      <div class="table-responsive">
    <table class="table table-hover">
      <thead class="facultyTable">
          <tr>
              <th scope="col" style="width: 2%;"> Sr.No.</th>
              <th scope="col">Mentee Institution Details</th>
              <th scope="col">Session No.</th>
              <th scope="col" style="width: 10%;">Schedule & Details</th>
              <th scope="col" style="width: 5%;">Speaker Details</th>
              <th scope="col" style="width: 5%;">IIC Activity Name</th>
              <th scope="col">Activity Brochure/Schedule</th>
              <th scope="col">Report</th>
              <th scope="col">Action</th>
              <th scope="col" style="width: 20%;">Update Schedule</th>
              <th scope="col" style="width: 20%;">Action by Mentee</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let schedule of schedules3; index as i"  >
              <td >{{i+1}}</td>
              <td *ngIf="schedule?.mentee_id!=null">{{schedule?.mentee_id}}</td>
              <td *ngIf="schedule?.mentee_id==null">For all Mentee</td>
              <td *ngIf="schedule?.activity!=null">{{schedule?.activity}}</td>
              <td *ngIf="schedule?.activity==null">N/A</td>
              <td >
                <strong>Date:</strong> {{schedule?.scheduled_date}}<br>
                <strong>Time:</strong> {{schedule?.scheduled_time}}<br>
                <strong>Link:</strong> {{schedule?.meeting_id}}
              </td>
              <td *ngIf="schedule?.speaker_name!=null">
                <strong>Name:</strong> {{schedule?.speaker_name}}<br>
                <strong>Designation:</strong> {{schedule?.speaker_designation}}<br>
                <strong>Contact:</strong> {{schedule?.contact}}
              </td>

              <td *ngIf="schedule?.iic_activity_name!=null">{{schedule?.iic_activity_name}}</td>
             <td *ngIf="schedule?.iic_activity_name==null">N/A</td>

             <td *ngIf="schedule?.file_upload!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.file_upload}}" target="_blank">Preview</a></td>
             <td *ngIf="schedule?.file_upload==null">N/A</td>
			 <td>
                <button mat-raised-button color="primary" class="mentorButEdit">Upload Report</button>

              </td>
			  <td>
                <button mat-raised-button color="primary" class="mentorButEdit">Scheduled</button>

              </td>
              <td>
                <p><button mat-raised-button (click)="onEditClick(schedule?.id,schedule?.activity_id)" color="primary" class="mentorButEdit">
                    <mat-icon>create</mat-icon>
                    Edit</button></p>
                <p><button mat-raised-button (click)="onDeleteClick(schedule?.id)" color="warn">
                    <mat-icon>delete</mat-icon>
                    Delete</button></p>

                    <div *ngIf="i==0">
                      <p *ngIf="RQ_3_file==''"><button mat-raised-button (click)="openReportDialog(3)" color="primary">Upload Report</button></p>
                      <p *ngIf="RQ_3_file!=''"><button mat-raised-button (click)="openReportDialog(3)" color="primary">Update Report</button></p>
                      <p *ngIf="RQ_3_file!=''"><a target="_blank" href="{{ baseUrl + RQ_3_file}}">View File</a></p>
                    </div>

                    <div *ngIf="i==1">
                      <p *ngIf="RQ_32_file==''"><button mat-raised-button (click)="openReportDialog(32)" color="primary">Upload Report</button></p>
                      <p *ngIf="RQ_32_file!=''"><button mat-raised-button (click)="openReportDialog(32)" color="primary">Update Report</button></p>
                      <p *ngIf="RQ_32_file!=''"><a target="_blank" href="{{ baseUrl + RQ_32_file}}">View File</a></p>
                    </div>
              </td>
              <td>
                <p *ngIf="OmStatus_total==0">-</p>
                <p *ngIf="OmStatus_total>0"><button type="button" class="btn btn-success"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new" (click)="onActivityStatus(3,schedule?.activity_id,schedule?.activity)"> View ({{OmStatus_total}}) </a></button></p>
              </td>
          </tr>
          <tr *ngIf="schedules3.length<1">
              <td colspan="9"><h3 style="text-align: center">No Data Found</h3></td>
          </tr>
      </tbody>
  </table>
</div>
  <div class="modal2 fade bs-example-modal-new" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

    <div class="modal-dialog">

      <!-- Modal Content: begins -->
      <div class="modal-content">
        <div class="modal-body">
          <div class="body-message">
            <table class="table table-hover table-response tableGuidence">
              <thead class="facultyTable">
                <tr>
                  <th scope="col" style="width: 25%;">Mentee Institute</th>
                  <th scope="col" style="width: 25%;">Participation Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let mmData of OmStatus; index as i"  >
                <td>{{mmData?.mentee_userId}}</td>
                <td><p>{{mmData?.status}}</p></td>
              </tr>
              </tbody>
              </table>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        </div>

      </div>
      <!-- Modal Content: ends -->

    </div>

  </div>

  </mat-tab>

  <!-- Tab Ends -->

  <mat-tab label="I&E Training & Exposure Visit">

    <div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">I&E Training & Exposure Visit</h2>
		</div>
      <div class="col-sm-10">
        <p>Organize a 2 days long I&E exposure visit cum training program on I&E related theme such as pre-incubation and Incubation facility creation, IPR filing & management etc. for mentee institute representatives.</p>
          <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline3" role="button" aria-expanded="false" aria-controls="rws-guideline3">View Guidlines</a></p>
          <div class="collapse multi-collapse" id="rws-guideline3">
            <div class="card card-body">
              <ul>

                <li>Mentor institute will plan and organize a 2-day long exposure cum training program for representatives from mentee institutions to be delivered at the mentor institute.</li>

                <li>Mentee IIC institution can nominate up to two key functionaries of IIC to take part in the program.</li>

                <li>Maximum 10 participants from the 5 mentee institutions shall comprise the one cohort of training.</li>

                <li>The travel cost of the nominee/representative from the mentee institutions to be borne by the respective mentee IIC institutions.</li>

                <li>Mentor institute will take care of the venue related expenses to deliver the training program and expenses may include expert’s fee/honorarium, food, site visits, accommodation and training and communication material cost for the participants.</li>

                <li>The total funding support from MIC/AICTE for organising such a program is Rs. 1,25,000/-. Mentor institutes may bear the additional cost if incurred.</li>

              </ul>
            </div>
          </div>
      </div>
      <div class="col-sm-2">
        <p><button mat-raised-button (click)="openDialog(4)" [disabled]='!this.activity4' color="primary">Schedule</button></p>
        <p *ngIf="RQ_4_file==''"><button mat-raised-button (click)="openReportDialog(4)" color="primary">Upload Report</button></p>
        <p *ngIf="RQ_4_file!=''"><button mat-raised-button (click)="openReportDialog(4)" color="primary">Update Report</button></p>
        <p *ngIf="RQ_4_file!=''"><a target="_blank" href="{{ baseUrl + RQ_4_file}}">View File</a></p>
      </div>
    </div>

    <table class="table table-hover">
      <thead class="facultyTable">
          <tr>
              <th scope="col" style="width: 2%;"> Sr.No.</th>
              <th scope="col"> Mentee Institution Details</th>
              <!-- <th scope="col">Session</th> -->
              <th scope="col" style="width: 13%;">Exposure Visit Schedule</th>
              <th scope="col" style="width: 15%;">Mentor Institute Coordinator</th>
              <th scope="col">Activity Brochure/Schedule</th>
			        <th scope="col">Report</th>
			        <th scope="col">Action</th>
              <th scope="col" style="width: 20%;">Update Schedule</th>
              <th scope="col" style="width: 20%;">Action by Mentee</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let schedule of schedules4; index as i"  >
              <td >{{i+1}}</td>
              <td *ngIf="schedule?.mentee_id!=null">{{schedule?.mentee_id}}</td>
              <td *ngIf="schedule?.mentee_id==null">For all Mentee</td>
              <td *ngIf="schedule?.activity!=null">{{schedule?.activity}}</td>
              <!-- <td *ngIf="schedule?.activity_id!=null">{{schedule?.activity_id}}</td> -->
              <td >
                <strong>Date:</strong> {{schedule?.scheduled_date}}<br>
                <strong>Time:</strong> {{schedule?.scheduled_time}}<br>
                <strong>Link:</strong> {{schedule?.meeting_id}}
              </td>
              <td *ngIf="schedule?.speaker_name!=null">
                <strong>Name:</strong> {{schedule?.speaker_name}}<br>
                <strong>Designation:</strong> {{schedule?.speaker_designation}}<br>
                <strong>Contact:</strong> {{schedule?.contact}}
              </td>
             <td *ngIf="schedule?.file_upload!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.file_upload}}" target="_blank">Preview</a></td>
             <td *ngIf="schedule?.file_upload==null">N/A</td>
			 <td>
                <button mat-raised-button color="primary" class="mentorButEdit">Upload Report</button>

              </td>
			  <td>
                <button mat-raised-button color="primary" class="mentorButEdit">Scheduled</button>

              </td>
              <td>
                <button mat-raised-button (click)="onEditClick(schedule?.id,schedule?.activity_id)" color="primary" class="mentorButEdit">
                    <mat-icon>create</mat-icon>
                    Edit</button>
                      <button mat-raised-button (click)="onDeleteClick(schedule?.id)" color="warn">
                    <mat-icon>delete</mat-icon>
                    Delete</button>
              </td>
              <td>
                <p *ngIf="OmStatus_total==0">-</p>
                <p *ngIf="OmStatus_total>0"><button type="button" class="btn btn-success"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new" (click)="onActivityStatus(4,schedule?.activity_id,schedule?.activity)">View ({{OmStatus_total}})</a></button></p>
              </td>
          </tr>
          <tr *ngIf="schedules4.length<1">
              <td colspan="9"><h3 style="text-align: center">No Data Found</h3></td>
          </tr>
      </tbody>
  </table>

  <div class="modal2 fade bs-example-modal-new" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

    <div class="modal-dialog">

      <!-- Modal Content: begins -->
      <div class="modal-content">
        <div class="modal-body">
          <div class="body-message">
            <table class="table table-hover table-response tableGuidence">
              <thead class="facultyTable">
                <tr>
                  <th scope="col" style="width: 25%;">Mentee Institute</th>
                  <th scope="col" style="width: 25%;">Participation Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let mmData of OmStatus; index as i"  >
                <td>{{mmData?.mentee_userId}}</td>
                <td><p>{{mmData?.status}}</p></td>
              </tr>
              </tbody>
              </table>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        </div>

      </div>
      <!-- Modal Content: ends -->

    </div>

  </div>


  </mat-tab>

  <!-- Tab Ends  -->


  <mat-tab label="Progress Monitoring & Impact Measurement">

    <app-activity-report-form-pmim></app-activity-report-form-pmim>

  </mat-tab>

  <mat-tab label="Guidance">
	<div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Guidance : NISP, ARIIA, HACKATHON</h2>
		</div>
		<div class="col-md-12"><p>The mentor institution is expected to encourage, guide and handhold mentee institutions in participating various innovation and entrepreneurship initiatives of MoE such as adoption of NISP, formulation of I&E policy at the institute level, Participation in ARIIA,</p></div>
      <!-- <div class="col-sm-12">
        Conduct progress monitoring cum feedback & mentoring session with each mentee institute.
      </div>
      <div class="col-sm-2">
        <p><button mat-raised-button (click)="openDialog(5)" [disabled]='!this.activity5' color="primary">Schedule</button></p>
      </div> -->
    </div>

    <p *ngIf="RQ_6_file==''"><button mat-raised-button (click)="openReportDialog(6)" color="primary">Upload Report</button></p>
    <p *ngIf="RQ_6_file!=''"><button mat-raised-button (click)="openReportDialog(6)" color="primary">Update Report</button></p>
    <p *ngIf="RQ_6_file!=''"><a target="_blank" href="{{ baseUrl + RQ_6_file}}">View File</a></p>


    <form [formGroup]="addActivityForm6" (submit)="onSubmit6(addActivityForm6.value)">
      <div class="row">

        <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-12">
		<section class="form-check">


		<table class="table table-hover table-response tableGuidence">
		  <thead class="facultyTable">
			  <tr>
				  <th scope="col" style="width: 2%;"> </th>
				  <th scope="col" style="width: 10%;">Guidance</th>
				  <th scope="col" style="width: 13%;">Action by Mentee</th>
			  </tr>
		  </thead>
		  <tbody>
			<tr>
				<td><p><mat-checkbox formControlName="nisp_status"></mat-checkbox></p></td>
				<td><p>NISP</p></td>
				<td>
          <p *ngIf="MmStatus_total==0">-</p>
          <p *ngIf="MmStatus_total>0"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new">{{MmStatus_total}}</a></p>
        </td>
			</tr>
			<tr>
				<td><p><mat-checkbox formControlName="iepolicy_status"></mat-checkbox></p></td>
				<td><p>Formulation of I&E policy at the institute level</p></td>
				<td><p *ngIf="MmStatus_total==0">-</p>
          <p *ngIf="MmStatus_total>0"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new">{{MmStatus_total}}</a></p></td>
			</tr>
			<!-- <tr>
				<td><p><mat-checkbox formControlName="ariia_status"></mat-checkbox></p></td>
				<td><p>Participation in ARIIA</p></td>
				<td><p *ngIf="MmStatus_total==0">-</p>
          <p *ngIf="MmStatus_total>0"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new">{{MmStatus_total}}</a></p></td>
			</tr> -->
		  </tbody>
	  </table>

    <div class="modal fade bs-example-modal-new activityModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

      <div class="modal-dialog">

        <!-- Modal Content: begins -->
        <div class="modal-content">

          <!-- Modal Header
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title" id="gridSystemModalLabel">Your Headings</h4>
          </div>
        -->

          <div class="modal-body">
            <div class="body-message">
              <table class="table table-hover table-response tableGuidence">
                <thead class="facultyTable">
                  <tr>
                    <th scope="col" style="width: 25%;">Mentee Institute</th>
                    <th scope="col" style="width: 25%;">NISP Status</th>
                    <th scope="col" style="width: 25%;">Formulation of I&E policy at the institute level</th>
                    <th scope="col" style="width: 25%;">Participation in ARIIA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let mmData of MmStatus; index as i"  >
                  <td>{{mmData?.mentee_userId}}</td>
                  <td><p>{{mmData?.nisp_status}}</p></td>
                  <td><p>{{mmData?.iepolicy_status}}</p></td>
                  <td><p>{{mmData?.ariia_status}}</p></td>
                </tr>
                </tbody>
                </table>
            </div>
          </div>

          <!-- Modal Footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>

        </div>
        <!-- Modal Content: ends -->

      </div>

    </div>


        <!-- <h4>Select your Guidance:</h4>
        <p><mat-checkbox formControlName="nisp_status">NISP</mat-checkbox></p>
        <p><mat-checkbox formControlName="iepolicy_status">Formulation of I&E policy at the institute level</mat-checkbox></p>
        <p><mat-checkbox formControlName="ariia_status">Participation in ARIIA</mat-checkbox></p> -->
      </section>

      <div fxFlex="50" class="m-2">   <button style="
        margin-left: 193px;
      " mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='addActivityForm6.invalid'>Submit</button>
      </div>
    </div>
    </div>
    </form>

  </mat-tab>

  <mat-tab label="Handholding Support">
		<div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Handholding Support: Pre-Incubation & Incubation Facility Management</h2>
		</div>
		<div class="col-md-12"><p>Training of Innovation Ambassadors, Mentoring support to students and establishment of pre-incubation and incubation facilities etc.</p></div>

    </div>
    
    <p *ngIf="RQ_7_file==''"><button mat-raised-button (click)="openReportDialog(7)" color="primary">Upload Report</button></p>
    <p *ngIf="RQ_7_file!=''"><button mat-raised-button (click)="openReportDialog(7)" color="primary">Update Report</button></p>
    <p *ngIf="RQ_7_file!=''"><a target="_blank" href="{{ baseUrl + RQ_7_file}}">View File</a></p>


      <section class="form-check">
        <h4>Select your Handholding :</h4>
        <table class="table table-hover table-response tableGuidence">
          <thead class="facultyTable">
            <tr>
              <th scope="col" style="width: 2%;">S.No.</th>
              <th scope="col" style="width: 10%;">IIC ID</th>
              <th scope="col" style="width: 13%;">Institute Name</th>
              <th scope="col" style="width: 13%;">Address</th>
              <th scope="col" style="width: 13%;">City</th>
              <th scope="col" style="width: 13%;">State</th>
              <th scope="col" style="width: 13%;">Status of Acceptance by Referred Institute</th>
              <th scope="col" style="width: 13%;">Action</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td><p><mat-checkbox formControlName="ia_status"></mat-checkbox></p></td>
            <td><p>Training of Innovation Ambassadors</p></td>
            <td>
              <p *ngIf="MmStatus2_total==0">-</p>
              <p *ngIf="MmStatus2_total>0"><a href="javascript:void(0);" data-toggle="modal2" data-target=".bs-example-modal-new">{{MmStatus2_total}}</a></p>
            </td>
          </tr>
          <tr>
            <td><p><mat-checkbox formControlName="mentoring_support"></mat-checkbox></p></td>
            <td><p>Mentoring support to students</p></td>
            <td><p *ngIf="MmStatus2_total==0">-</p>
              <p *ngIf="MmStatus2_total>0"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new">{{MmStatus2_total}}</a></p></td>
          </tr>
          <tr>
            <td><p><mat-checkbox formControlName="incubation_status"></mat-checkbox></p></td>
            <td><p>Establishment of pre-incubation and incubation facilities etc.</p></td>
            <td><p *ngIf="MmStatus2_total==0">-</p>
              <p *ngIf="MmStatus2_total>0"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new">{{MmStatus2_total}}</a></p></td>
          </tr>

          </tbody>
        </table>
      </section>
      <section class="form-check">
        <h4>Select your Handholding Support:</h4>

        <form [formGroup]="addActivityForm7" (submit)="onSubmit7(addActivityForm7.value)">
      <table class="table table-hover table-response tableGuidence">
          <thead class="facultyTable">
            <tr>
              <th scope="col" style="width: 2%;"> </th>
              <th scope="col" style="width: 10%;">Guidance</th>
              <th scope="col" style="width: 13%;">Action by Mentee</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td><p><mat-checkbox formControlName="ia_status"></mat-checkbox></p></td>
            <td><p>Training of Innovation Ambassadors</p></td>
            <td>
              <p *ngIf="MmStatus2_total==0">-</p>
              <p *ngIf="MmStatus2_total>0"><a href="javascript:void(0);" data-toggle="modal2" data-target=".bs-example-modal-new">{{MmStatus2_total}}</a></p>
            </td>
          </tr>
          <tr>
            <td><p><mat-checkbox formControlName="mentoring_support"></mat-checkbox></p></td>
            <td><p>Mentoring support to students</p></td>
            <td><p *ngIf="MmStatus2_total==0">-</p>
              <p *ngIf="MmStatus2_total>0"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new">{{MmStatus2_total}}</a></p></td>
          </tr>
          <tr>
            <td><p><mat-checkbox formControlName="incubation_status"></mat-checkbox></p></td>
            <td><p>Establishment of pre-incubation and incubation facilities etc.</p></td>
            <td><p *ngIf="MmStatus2_total==0">-</p>
              <p *ngIf="MmStatus2_total>0"><a href="javascript:void(0);" data-toggle="modal" data-target=".bs-example-modal-new">{{MmStatus2_total}}</a></p></td>
          </tr>

          </tbody>
        </table>

        <div fxFlex="50" class="m-2"><button style="margin-left: 193px;
        " mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='addActivityForm7.invalid'>Submit</button>
        </div>

      </form>


        <div style="clear:both;" class="modal2 fade bs-example-modal-new2" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

          <div class="modal-dialog">

            <!-- Modal Content: begins -->
            <div class="modal-content">
              <div class="modal-body">
                <div class="body-message">
                  <table class="table table-hover table-response tableGuidence">
                    <thead class="facultyTable">
                      <tr>
                        <th scope="col" style="width: 25%;">Mentee Institute</th>
                        <th scope="col" style="width: 25%;">Training of Innovation Ambassadors</th>
                        <th scope="col" style="width: 25%;">Mentoring support to students</th>
                        <th scope="col" style="width: 25%;">Establishment of pre-incubation and incubation facilities etc.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let mmData of MmStatus; index as i"  >
                      <td>{{mmData?.mentee_userId}}</td>
                      <td><p>{{mmData?.ia_status}}</p></td>
                      <td><p>{{mmData?.mentoring_support}}</p></td>
                      <td><p>{{mmData?.incubation_status}}</p></td>
                    </tr>
                    </tbody>
                    </table>
                </div>
              </div>

              <!-- Modal Footer -->
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal2">Close</button>
              </div>

            </div>
            <!-- Modal Content: ends -->

          </div>

        </div>

      </section>
  </mat-tab>
  <!-- *ngIf="isShow" -->
  <!-- *ngIf="fundedBy != 'Volunteer'" -->
  <mat-tab label="Overall Report" >
		<div class="row" style="padding-top: 10px;">
		<div class="col-md-12">
			<h2 class="actionPlanHeading">Overall Report</h2>
		</div>

    </div>

    <form [formGroup]="addActivityForm8" (submit)="onSubmit8(addActivityForm8.value)">
      <section class="form-check">
        <h4>Upload overall report in pdf format</h4>


        <table class="table table-hover table-response tableGuidence">
          <tbody>
          <tr>
            <td>
                Statement of Expenditure<br/>
                <mat-error>PDF(max 2MB)</mat-error>
                <p><a href="{{ imageBasePath }}assets/doc/soe_mentor_mentee_23_24.pdf" target="_blank">Sample Format</a></p>
              </td>
            <td>
              <div class="col-12">
                <label class="custom-file-label float-right" for="customFile"
                                  *ngIf="!ipr_document">Choose file</label>

                <label class="custom-file-label float-right" for="customFile"
                    *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>

                <input #ipr_documentRef type="file" accept="application/pdf"  name="ipr_document"
                    (change)="( ipr_document = ipr_documentRef.files )"
                    class="custom-file-input float-right" required>
                  </div>

                  <p *ngIf="RQ_8_file!=''" style="clear:both; overflow:hidden; width:100%; padding-top:10px;"><a target="_blank" href="{{ baseUrl + RQ_8_file}}">View File</a></p>

            </td>
          </tr>
          <tr>
            <td>Utilization Certificate (UC)<br/>
              <mat-error>PDF(max 2MB)</mat-error>
              <p><a href="{{ imageBasePath }}assets/doc/Utilization_Certificate_mentor_mentee_23_24.pdf" target="_blank">Sample Format</a></p></td>
            <td>
              <div class="col-12">
                <label class="custom-file-label float-right" for="customFile"
                                  *ngIf="!ip_copy">Choose file</label>

                <label class="custom-file-label float-right" for="customFile"
                    *ngIf="ip_copy">{{ip_copy[0]['name']}}</label>

                <input #ip_copyRef type="file" accept="application/pdf"  name="ip_copy"
                    (change)="( ip_copy = ip_copyRef.files )"
                    class="custom-file-input float-right" required>
                  </div>
                  <p *ngIf="RQ_81_file!=''" style="clear:both; overflow:hidden; width:100%; padding-top:10px;"><a target="_blank" href="{{ baseUrl + RQ_81_file}}">View File</a></p>
            </td>
          </tr>
          <tr>
            <td>Overall Report<br/>
              <mat-error>PDF(max 2MB)</mat-error>
              <p><a href="{{ imageBasePath }}assets/doc/MM_Report_OverAll_23_24.pdf" target="_blank">Sample Format</a></p></td>
            <td>
              <div class="col-12">
                <label class="custom-file-label float-right" for="customFile"
                                  *ngIf="!all_report">Choose file</label>

                <label class="custom-file-label float-right" for="customFile"
                    *ngIf="all_report">{{all_report[0]['name']}}</label>

                <input #all_reportRef type="file" accept="application/pdf"  name="all_report"
                    (change)="( all_report = all_reportRef.files )"
                    class="custom-file-input float-right" required>
              </div>
              <p *ngIf="RQ_81_file!=''" style="clear:both; overflow:hidden; width:100%; padding-top:10px;"><a target="_blank" href="{{ baseUrl + RQ_82_file}}">View File</a></p>
            </td>
          </tr>
          <tr>
            <td>Video Url of Over All Report
              <!-- <br/><mat-error>PDF(max 2MB)</mat-error> -->
            </td>
            <td>
              <mat-form-field class="w-100 ml-3" appearance="outline">
                <input matInput placeholder="Video Url of Over All Report" formControlName="video_url" required>
              </mat-form-field>
            </td>
          </tr>
          </tbody>
        </table>

        <div fxFlex="50" class="m-2">
          <button style="margin-left: 193px;" mat-raised-button color='primary' class="btn btn-success float-right">Submit</button>
        </div>

      </section>

    </form>

  </mat-tab>
  <!-- <mat-tab label="Referal and Linkage">
    <app-mentor-referal-form></app-mentor-referal-form>
  </mat-tab>
  <mat-tab label="Handholding Inactive IIC">
    <app-handholding-inactive-iic></app-handholding-inactive-iic>
  </mat-tab> -->

</mat-tab-group>
