<style type="text/css">
	button.btn.btn-danger-video.video {
    margin: 0 auto;
    background: #2d7201 !important;
    padding: 6px 30px !important;
    border-radius: 50px;
    color: #fff;
    border: 2px solid #4cbc05 !important;
    font-size: 14px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    display: block !important;
}
</style>

<div class="container row">
  <div class="col-12" style="text-align: center">
      <h3>You have watched this Video Successfully!</h3>
  </div>

  <div class="col-12">
      <button type="button" class="btn btn-danger-video video" mat-dialog-close >Close</button>
  </div>
  <!-- <div class="col-6">
      <button type="button" class="btn btn-success" >No</button>
  </div> -->
<!--
  <mat-dialog-actions align="center">
      <button mat-button mat-dialog-close>No</button>
      <button mat-button (click)="deleteDialog()">Yes</button>
  </mat-dialog-actions> -->


</div>
