<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

  <link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
  <link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
  <link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
  <link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">
  
  <style>
  .navbar-nav>li {
      float: none !important;
      text-align: center !important;
	  line-height: 24px;
    }


</style>

  <!-- <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container-fluid navigation_width">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="@"><img src="assets/institute/images/logo.png" class="img-responsive"></a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav navbar-right" style="width:auto;float: left !important;right:40px; top: 0;">
          <li><span style="font-size: 27px;">MHRD’s Innovation Cell<br>Institution’s Innovation Council</span><br>Institute Portal</li>
        </ul>
      </div>
    </div>
  </nav> -->
<div class="limiter">
    <div class="container-login100">
		<div class="wrap-login100">
		<span class="login100-form-title p-b-20">
				<img src="assets/institute/images/logoiic.png" class="loginIICLogo signupIICLogo">
			</span>
			<span class="login100-form-title loginInstiTopContent">
					<h2>Reset Password</h2>
			    </span>
<form class="login100-form login100-formwithForgetPassword validate-form flex-sb flex-w" #ResetPasswordForm=ngForm (ngSubmit)="onSubmit()">

  <div class="login__body">
    <div class="form-group form-group--float form-group--centered">
      <input type="email" style="color:black" name="email" placeholder="Enter Email Id" class="input100"
        [(ngModel)]="form.email" id="inputEmail3" required>
      <label>Email Address</label>
    </div>

    <div class="form-group form-group--float form-group--centered">
      <input type="password" style="color:black" name="password" placeholder="Enter Password" class="input100"
        [(ngModel)]="form.password" id="inputPassword3" required>
      <label>Password</label>
    </div>

    <div class="form-group form-group--float form-group--centered">
      <input type="password" style="color:black" name="confirm_password" placeholder="Enter Confirm Password"
        class="input100" [(ngModel)]="form.confirm_password" id="inputPassword4" required>
      <label>Confirm Password</label>
    </div>
	<div class="container-login100-form-btn m-t-17">
    <button type="submit" class="login100-form-btnSignUp" [disabled]="!ResetPasswordForm.valid">
      <!-- <mat-icon class="mt-2"> arrow_forward_ios</mat-icon> --> submit
    </button>
	</div>
  </div>

</form>

		</div>
	</div>
</div>


<!-- Footer 
<app-institute-footer></app-institute-footer> -->