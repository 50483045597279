export class InstituteStudent {
  id: number;
  name: string;
  email: string;
  institute: string;
  stream: string;
  mobile: string;
  year: string;
  semester: string;
  dicipline: string;
  role: string;
  is_member: string;
  gender: string;

  // constructor(
  //   name,
  //   email,
  //   contact,
  //   department,
  //   academic_year,
  //   semester,
  //   association,
  //   role
  // ) {
  //   name = this.name;
  //   email = this.email;
  //   contact = this.contact;
  //   department = this.department;
  //   academic_year = this.academic_year;
  //   semester = this.semester;
  //   association = this.association;
  //   role = this.role;
  // }
}
