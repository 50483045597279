<div class="ss">
    <h2 class="cc">Enter Reply</h2>
    <form [formGroup]="replyForm" (ngSubmit)="onSubmit(replyForm.value)">
        <label for="name">Subject</label>
        <input type="text" id="subject" name="subject" formControlName="subject" class="full-width" readonly /><br />
        <label for="message">Message</label>
        <textarea name="message" id="message" formControlName="message" cols="30" rows="10" class="full-width"></textarea
    ><br />
    <div style="text-align: center;">
      <button
        class="btn btn-success mat-elevation-z6 mt-3"
        color="primary"
        mat-raised-button
        [disabled]="replyForm.invalid"
      >
        Submit
      </button>
      <button
        class="btn btn-danger instituteBtnClose ml-4"
        type="button"
        color="warn"
        mat-raised-button
        (click)="close()"
      >
        Close
      </button>
    </div>
  </form>
</div>