<div class="container">
    <div *ngIf="!show">
        <mat-card class="m-4 mat-elevation-z6 proInstCardMat">
            <div class="row m-4">
                <div class="col-md-12">
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Name</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.spoc_name}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Email</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.spoc_email}}</p>
                        </div>
                    </div>
                    <div class="row btmLine">
                        <div class="col-md-4">
                            <p class="anaTitle">Contact</p>
                        </div>
                        <div class="col-md-8">
                            <p class="anaContent">{{this.spoc_contact}}</p>
                        </div>
                    </div>
                    <div class="row btmLine" *ngIf="ForWhom == 'Institute'">
                        <div class="col-md-4">
                            <p class="anaTitle"> </p>
                        </div>
                        <div class="col-md-8">
                            <button type="button" class="btn btn-primary incubSubmit" (click)="toggle()">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <ng-container *ngIf="show">
        <mat-card class="m-4 mat-elevation-z6 hoiInstiDetials">
            <div class="row" color="primary">
                <h2 class="ss" style="text-align: center"> Update President Details</h2>
            </div>
            <form [formGroup]="PresidentForm" (ngSubmit)="onSubmit(PresidentForm.value)">
                <div class="row">
                    <!-- <div class="col-2"></div> -->
                    <div class="col-4">
                        <mat-form-field class="example-full-width" style="width: 290px;" appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="Name" formControlName="spoc_name" required>
                            <mat-error *ngIf="PresidentForm.controls.spoc_name.errors?.required">Name is
                                required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="example-full-width" style="width: 290px;" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="spoc_email" placeholder="Email" required>
                            <mat-error *ngIf="PresidentForm.controls.spoc_email.errors?.required">Email is
                                required</mat-error>
                            <mat-error *ngIf="PresidentForm.controls.spoc_email.errors?.email">Invalid email
                                address</mat-error>
                        </mat-form-field>
                    </div>
                <!-- </div> -->
                <!-- <div class="row"> -->
                    <!-- <div class="col-2"></div> -->
                    <div class="col-4">
                        <mat-form-field class="example-full-width" style="width: 290px;" appearance="outline">
                            <mat-label>Contact</mat-label>
                            <input matInput formControlName="spoc_contact" placeholder="Contact" required minlength="10"
                                maxlength="10" (keypress)=isNumberKey($event)>
                            <mat-error *ngIf="PresidentForm.controls.spoc_contact.errors?.required">Contact
                                is required</mat-error>
                            <mat-error *ngIf="PresidentForm.controls.spoc_contact.errors?.minlength">10
                                digits mobile number only</mat-error>
                            <mat-error *ngIf="PresidentForm.controls.spoc_contact.errors?.maxlength">10
                                digits mobile number only</mat-error>
                            <mat-error *ngIf="PresidentForm.controls.spoc_contact.errors?.pattern">Enter
                                number only</mat-error>
                        </mat-form-field>
                    </div>
                </div>              
                <div  style="text-align: center">             
                    <button type="button" color="warn" class="btn btn-danger instituteBtnClose" mat-raised-button (click)="backPage()" style="margin-right:5px;">Back</button>  
                    <button class="btn btn-success btnDownSuccess hoiUpdateBut" color="primary" mat-raised-button 
                    [disabled]='PresidentForm.invalid'>Update</button>
                    </div>                  
            </form>
        </mat-card>

        <!-- <div>
            <mat-card class="ww">
                <div class="row" color="primary">
                    <h2 class="ss"> Update President Details</h2>
                </div>
                <div class="container">
                    <mat-dialog-content class="mat-typography">
                        <form [formGroup]="PresidentForm" (ngSubmit)="onSubmit(PresidentForm.value)">
                            <div class="row">
                                <div fxFlex="50" class="m-2">
                                    <mat-form-field class="example-full-width" style="width: 290px;"
                                        appearance="outline">
                                        <mat-label>Name</mat-label>
                                        <input matInput placeholder="Name" formControlName="spoc_name" required>
                                        <mat-error *ngIf="PresidentForm.controls.spoc_name.errors?.required">Name is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div fxFlex="50" class="m-2">
                                    <mat-form-field class="example-full-width" style="width: 290px;"
                                        appearance="outline">
                                        <mat-label>Email</mat-label>
                                        <input matInput formControlName="spoc_email" placeholder="Email" required>
                                        <mat-error *ngIf="PresidentForm.controls.spoc_email.errors?.required">Email is
                                            required</mat-error>
                                        <mat-error *ngIf="PresidentForm.controls.spoc_email.errors?.email">Invalid email
                                            address</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div fxFlex="50" class="m-2">
                                    <mat-form-field class="example-full-width" style="width: 290px;"
                                        appearance="outline">
                                        <mat-label>Contact</mat-label>
                                        <input matInput formControlName="spoc_contact" placeholder="Contact" required
                                            minlength="10" maxlength="10" (keypress)=isNumberKey($event)>
                                        <mat-error *ngIf="PresidentForm.controls.spoc_contact.errors?.required">Contact
                                            is required</mat-error>
                                        <mat-error *ngIf="PresidentForm.controls.spoc_contact.errors?.minlength">10
                                            digits mobile number only</mat-error>
                                        <mat-error *ngIf="PresidentForm.controls.spoc_contact.errors?.maxlength">10
                                            digits mobile number only</mat-error>
                                        <mat-error *ngIf="PresidentForm.controls.spoc_contact.errors?.pattern">Enter
                                            number only</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div style="text-align: center">
                                <button class="mat-elevation-z6 mt-3" color="primary" mat-raised-button
                                    [disabled]='PresidentForm.invalid'>Update</button>
                            </div>
                        </form>
                    </mat-dialog-content>
                </div>
            </mat-card>
        </div> -->
    </ng-container>
</div>