<mat-card>
<div class="container">
<div class="row m-4">
	<div class="col-md-4">
		<div class="instIdeaTotalCount">
			<p>Total Approved Count:</p>
			<span>{{counts?.approved_count}}</span>
		</div>
	</div>
	<div class="col-md-4">
		<div class="instIdeaTotalCount">
			<p>Total Disapproved Count:</p>
			<span>{{counts?.disapproved_count}}</span>
		</div>
	</div>
	<div class="col-md-4">
		<div class="instIdeaTotalCount">
			<p>Total Pending Count:</p>
			<span>{{counts?.pending_count}}</span>
		</div>
	</div>
</div>
  <div class="row">
    <!-- <div class="col-5">
      <h5 class="ml-3">Total Approved Count: {{counts?.approved_count}}</h5>
      <h5 class="ml-3">Total Disapproved Count: {{counts?.disapproved_count}}</h5>
      <h5 class="ml-3">Total Pending Count: {{counts?.pending_count}}</h5>
    </div> -->
    <div class="col-8" style="text-align: end;">
      <mat-form-field class="instiIdeaSelectBox">
        <mat-label>Status Filter</mat-label>
        <mat-select (selectionChange)="changeFilter()" [(ngModel)]="filterModel">
          <mat-option value="All" default selected>All</mat-option>
          <mat-option value="Approved">Approved</mat-option>
          <mat-option value="Disapproved">Disapproved</mat-option>
          <mat-option value="pending">Pending</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--<div class="col-2" style="text-align: end;">
      <div *ngIf="!firstPage">
        <button type="button" class="mr-4" (click)="previousPage()" class="btn btn-success">
          Previous
        </button>
      </div>
    </div>
    <div class="col-2">
      <div *ngIf="!lastPage">
        <button type="button" class="ml-4" (click)="nextPage()" class="btn btn-success">
          Next
        </button>
      </div>
    </div>-->
  </div>
</div>
  <div class="form-group">
    <div class="m-4">
      <table class="table table-hover ideaTableHover">
        <thead class="facultyTable">
          <tr>
            <th scope="col">Sr. No.</th>
            <th scope="col">Title</th>
            <th scope="col">Theme</th>
            <th scope="col">Idea File</th>
            <th scope="col">Lead Name</th>
            <th scope="col">Lead Contact</th>
            <th scope="col">Status</th>
            <th scope="col">Date & Time</th>
            <th scope="col">View Details</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let idea of ideaSubmission; let i = index">
            <td>{{ idea?.serial_number }}</td>
            <td>{{ idea?.idea_title }}</td>
            <td>{{ idea?.theamName }}</td>
            <td>
              <mat-icon *ngIf="idea?.pdf_upload_idea == null"> close </mat-icon>
              <a *ngIf="idea?.pdf_upload_idea != null" href="{{baseUrlIdea + idea?.pdf_upload_idea}}" target="_blank">
                <mat-icon style="cursor: pointer">arrow_circle_down
                </mat-icon>
              </a>
            </td>
            <td>{{ idea?.team_lead_name }}</td>
            <td>{{ idea?.team_lead_phone }}</td>
            <td>{{ idea?.status }}</td>
            <td>{{ idea?.created_date }}</td>
            <td>
              <button type="button" mat-raised-button color="primary" (click)="openDialog(idea)" class="btn btn-success btnDownSuccess">
                View Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
	<div class="row">
			<div class="col-md-12">
				<mat-paginator #paginator
								showFirstLastButtons
								(page)="nextPage($event)"
								[length]="total_pages"
								[pageIndex]="frontCurrentPageNo"
								[pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
							</mat-paginator>
							
			</div>
		</div>
  </div>
</mat-card>
