import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  EmailValidator,
} from '@angular/forms';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteIdeaSubmissionDialogComponent } from './institute-idea-submission-dialog/institute-idea-submission-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as myGlobals from '../../../../../app.component';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-institute-idea-submission',
  templateUrl: './institute-idea-submission.component.html',
  styleUrls: ['./institute-idea-submission.component.css']
})
export class InstituteIdeaSubmissionComponent implements OnInit {

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  // baseUrlIdea = "https://mic.gov.in/innovationcontest/";
  baseUrlIdea = myGlobals.baseImageUrl;
  ideaSubmission = [];
  filterModel = "All";
  counts: any;
  frontCurrentPageNo: number = 0;
  total_pages : number;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;
  constructor(private fb: FormBuilder,
    private innovationService: InstituteInnovationContestService,
    private dialog: MatDialog) { }

  ngOnInit(): void {

    this.getIdeaSubmission();

    this.innovationService.getStatusCounts('idea').subscribe(
      data => this.counts = data['idea']['0']
    );
  }


  getIdeaSubmission() {
    this.innovationService.getIdeaSubmission(this.currentPageNo,this.per_page).subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.ideaSubmission = [];

    data['data'].forEach((element) => {
      this.ideaSubmission.push(element);
    });

this.total_pages = data.meta.pagination.total;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }


  openDialog(data) {
    const dialogRef = this.dialog.open(InstituteIdeaSubmissionDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getIdeaSubmission();
    });
  }

  nextPage(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	this.per_page = e.pageSize;
    if (this.filterModel == "All") {
      this.getIdeaSubmission();
    } else {
      this.changeFilter();
    }
  }

  previousPage() {
    this.currentPageNo--;
    if (this.filterModel == "All") {
      this.getIdeaSubmission();
    } else {
      this.changeFilter();
    }
  }


  onSubmit(value) {
  }

  changeFilter() {
    if (this.filterModel == "All") {
      this.getIdeaSubmission();
    } else {
      this.innovationService.getIdeaSubmissionFilter(this.currentPageNo, this.filterModel).subscribe(
        data => this.handleResponse(data)
      );
    }
  }
}
