import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-last-login-dialog',
  templateUrl: './last-login-dialog.component.html',
  styleUrls: ['./last-login-dialog.component.css']
})
export class LastLoginDialogComponent implements OnInit {
  data: any; 
  list: any;
  constructor(private mycouncilService: InstituteMyCouncilService,
    private dialogRef1: MatDialogRef<LastLoginDialogComponent>) {
      this.list = [];
     }

    ngOnInit(): void {
      this.mycouncilService.getInfoDetails().subscribe(
        data => {
          this.list = data['data']
        }
      );
    }
}
