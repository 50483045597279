import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-nominate-form3',
  templateUrl: './nominate-form3.component.html',
  styleUrls: ['./nominate-form3.component.css']
})
export class NominateForm3Component implements OnInit {
  sessionList: any[];
  delete_id: any;

  constructor(private fb: FormBuilder,
     private activityService: InstituteActivityService,
      public dialog: MatDialog,
      private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.getTable();


  }

  onClickOpenForm(id,type,session){
    // alert(id);
    // alert(type);
    window.location.href='institute/impactLecture/report/'+id+'/'+type+'/'+session+'';
  }

  getTable(){
    this.innovationService.getsessions().subscribe(
      data => this.setsessions(data)
    );

  }

  setsessions(data){
    this.sessionList=[];
for(let i=0;i<data['sessions'].length;i++){
  this.sessionList.push(data['sessions'][i])
}
  }


  handleResponse1(data){
if(data['status']=='1'){
  this.toastr.success(data['msg']);
  this.getTable();
  // this.getcheckSessionDetails();

}

else{
  this.toastr.error(data['msg'])
}
     }

}
