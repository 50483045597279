import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-iic4o-dialog2',
  templateUrl: './iic4o-dialog2.component.html',
  styleUrls: ['./iic4o-dialog2.component.css']
})
export class Iic4ODialogComponent2 implements OnInit {

  signupForm: FormGroup;
  show1: boolean;
  show: boolean;
  data1: any;
  id: any;
  ActivityModel:any;
  minDate2: any;
  requField: boolean = false;
  requField2: any;
  edit: boolean;
  editData: any;
  document:any;
  ip_type:any;
  ip_status:any;
  ip_app_number:any;
  filed_ipr:any;
  ipr_document: FileList | null;
  institute_name:any;
  institute_address:any;
  institute_city:any;
  institute_state:any;
  aishe_code:any;
  iic_4ostatus:any;
  requireField:any;
  checkaishe:any;
  affiliation_status:any;
  show_affiliate:any;
  show_iic_4ostatus:any;


  constructor(  private formBuilder: FormBuilder,
    private toastr: ToastrService, private router: Router, private InstituteDashboardService: InstituteDashboardService,
    public dialogRef: MatDialogRef<Iic4ODialogComponent2>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
		dialogRef.disableClose = true;

    }

  ngOnInit(): void {
    this.requireField = 0;
    this.checkaishe = 0;

    this.InstituteDashboardService.getUserAisheUStatus().subscribe(
      data => this.handleResponseaishe(data)
    );

	  this.signupForm = this.formBuilder.group({
      'institute_type' : [null,Validators.required],
      'institute_subtype' : [null,Validators.required]
    });

  }

  handleResponseaishe(data)
  {

  }

    onSubmit(data){

      var formData = new FormData();

      formData.append('institute_type', this.signupForm.get('institute_type').value);
      formData.append('institute_subtype', this.signupForm.get('institute_subtype').value);
      formData.append('formType', '2');

      this.InstituteDashboardService.submitUserIic4oStatus(formData).subscribe(
        data => this.handleResponse(data));

    }

    handleResponse(data){
      if (data['status'] == 1) {
        alert("Thank you for updating the data. it is saved successfully.");
        this.router.navigateByUrl('/institute/dashboard2021-22');
        this.dialogRef.close();

      }
      else
      {
        this.toastr.error(data['msg']);
      }
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    onIPStatusChange(ob) {
      let iicStatus = ob.value;
      if(iicStatus == '2') {
        this.requireField = 0;
      } else {
        this.requireField = 1;
      }

    }

}
