
<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>


<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Video Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
		<li class="breadcrumb-item">Student Innovation Video</li>
  </ul>
</nav>

<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Student Innovation Video</h2>
			</div>
		</div>
		</div>	  
	</div>	
 </div>

<div class="container">
		<div class="row">
			<div class="gallery col-md-12">
				<div class="row pt-4">
						<!-- <div class="col-md-4 mb-3">
							<a href="https://www.youtube.com/embed/GaXPZwiGmhk" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/GaXPZwiGmhk" title="SIH 2020: IIC Innovation Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Automatic Sugarcane Cutter With Bud Detection</h4>
								</div>
							</a>
						</div>
						<div class="col-md-4 mb-3">
							<a href="https://www.youtube.com/embed/q2-C5WAR6ms" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/q2-C5WAR6ms" title="SIH 2020: IIC Innovation Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Exnos</h4>
								</div>
							</a>
						</div>
						<div class="col-md-4 mb-3">
							<a href="https://www.youtube.com/embed/hF6TDEOLocM" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/hF6TDEOLocM" title="SIH 2020: IIC Innovation Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Early Pregnancy Detection From Cattle Urine</h4>
								</div>
							</a>
						</div> -->
						<div class="col-md-4 mb-3">
							<a href="https://www.youtube.com/watch?v=GaXPZwiGmhk&t=4s&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-1.png');">
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Automatic Sugarcane Cutter With Bud Detection</h4>
									</div>					
							</div>
							</a>
					</div>
						<div class="col-md-4 mb-3">
							<a href="https://www.youtube.com/watch?v=q2-C5WAR6ms&feature=emb_title&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-2.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Exnos</h4>
									</div>					
							</div>
							</a>
						</div>
						<div class="col-md-4 mb-3">
							<a href="https://www.youtube.com/watch?v=hF6TDEOLocM&feature=emb_title&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-3.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Early Pregnancy Detection From Cattle Urine</h4>
									</div>					
							</div>
							</a>
						</div>


						<div class="col-md-4 mb-3">
							<!-- <a href="https://www.youtube.com/embed/0BmwvvrZ9RQ" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/0BmwvvrZ9RQ" title="SIH 2020: IIC Innovation Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Body Temperature Tracer</h4>
								</div>
							</a> -->
							<a href="https://www.youtube.com/watch?v=0BmwvvrZ9RQ&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-1.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Body Temperature Tracer</h4>
									</div>					
							</div>
							</a>

						</div>
						<div class="col-md-4 mb-3">
							<!-- <a href="https://www.youtube.com/embed/NlYlXHc3uig" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/NlYlXHc3uig" title="SIH 2020: Bioform Kreator3d Zeeshan Mallick By Chethan Gowda" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Bioform Kreator3d Zeeshan Mallickd</h4>
								</div>
							</a> -->
							<a href="https://www.youtube.com/watch?v=NlYlXHc3uig&feature=emb_title&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-2.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Bioform Kreator3d Zeeshan Mallickd</h4>
									</div>					
							</div>
							</a>
						</div>
						<div class="col-md-4 mb-3">
							<!-- <a href="https://www.youtube.com/embed/WhipH1jSMPQ" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/WhipH1jSMPQ" title="SIH 2020: Agriculture and Rural Development" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Fruit Handling and Management Equipment</h4>
								</div>
							</a> -->


							<a href="https://www.youtube.com/watch?v=WhipH1jSMPQ&feature=emb_title&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-3.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Fruit Handling and Management Equipment</h4>
									</div>					
							</div>
							</a>
						</div>
						<div class="col-md-4 mb-3">
							<!-- <a href="https://www.youtube.com/embed/mqg_AepdV_Q" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/mqg_AepdV_Q" title="SIH 2020: Water Flow Meter By Ankit Jha" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Water Flow Meter</h4>
								</div>
							</a> -->

							<a href="https://www.youtube.com/watch?v=mqg_AepdV_Q&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-1.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Water Flow Meter</h4>
									</div>					
							</div>
							</a>
						</div>
						<div class="col-md-4 mb-3">
							<!-- <a href="https://www.youtube.com/embed/DRImBkNrtVc" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/DRImBkNrtVc" title="SIH 2020: Water Cleanser By Ganesh Annadurai" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Water Cleanser</h4>
								</div>
							</a> -->
							<a href="https://www.youtube.com/watch?v=DRImBkNrtVc&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-2.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Water Cleanser</h4>
									</div>					
							</div>
							</a>
						</div>
						<div class="col-md-4 mb-3">
							<!-- <a href="https://www.youtube.com/embed/4OQPRohEBOA" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/4OQPRohEBOA" title="SIH 2020: Increasing Productivity Using Multi Agro Machine" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Increasing Productivity Using Agro Machine</h4>
								</div>
							</a> -->
							<a href="https://www.youtube.com/watch?v=4OQPRohEBOA&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-3.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Increasing Productivity Using Agro Machine</h4>
									</div>					
							</div>
							</a>
						</div>
						<div class="col-md-4 mb-3">
							<!-- <a href="https://www.youtube.com/embed/eilBlFmllPE" target="_blank">
								<div class="innVideoOuter">
									<iframe width="100%" height="200px" src="https://www.youtube.com/embed/eilBlFmllPE" title="SIH 2020: Development of Herbal Formulation to Treat Early Stage Breast Cancer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									<h4>Development of Herbal Formulation to Treat Early Stage Breast Cancer</h4>
								</div>
							</a> -->

							<a href="https://www.youtube.com/watch?v=eilBlFmllPE&feature=emb_title&ab_channel=MoEInnovationCell" target="_blank">
							<div class="innVideoOuter" style="background-image: url('assets/images/thumbnail-img-1.png');">				
									<div class="thumb-img">
										<i class="fa fa-youtube-play" aria-hidden="true"></i>
										<h4>Development of Herbal Formulation to Treat Early Stage Breast Cancer</h4>
									</div>					
							</div>
							</a>

						</div>
					</div>
			</div>
		</div>
	</div> 
<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
