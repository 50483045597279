<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">IIC Notification</h1> -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC Notification</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Resources</a></li>
    <li class="breadcrumb-item active" aria-current="page">Notification</li>
  </ul>
</nav>

<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>Institution's Innovation Council Notification</h2>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container">
	<div class="row">
		<div class="col-md-12">
			<div id="content" class="content table-responsive">
				<table align="center"  style="width:100%" class="table-hover">
				<tbody>
					<tr *ngFor="let item of announcementsDetails| paginate: { itemsPerPage: 10, currentPage: page }; let i = index;">
						
						<td *ngIf="item?.announcementType == 'Text' && !item?.text_url">
						{{item?.announcement}}
						</td>
						<td *ngIf="item?.announcementType == 'Text' && item?.text_url">
						<a href="{{item?.text_url}}">
						{{item?.announcement}}
						</a>
						</td>
						<td *ngIf="item?.announcementType == 'Image'">
						{{item?.image_title}}
						</td>
						<td *ngIf="item?.attachment" style="text-align: center;width:20%">
				            <a href="{{baseImageUrl + item?.attachment}}"  target="_blank" class="downLinkDownload downLinkDownloadMobRes">Download
				            </a>
				          </td>
				          <td *ngIf="!item?.attachment" style="text-align: center;">
				            N/A
				          </td>
					</tr>
					<tr>
						<td colspan="2" style="width:100%;text-align:right;">
							 <div class="notificationPagination"><pagination-controls class="annular-pagination float-right" maxSize="3" (pageChange)="onPageChanged($event)"></pagination-controls>
				  			</div>
						</td>
					</tr>
					</tbody>
					 

				</table>
				 </div>
		</div>
	</div>
</div>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>