import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-evaluator-edit',
  templateUrl: './evaluator-edit.component.html',
  styleUrls: ['./evaluator-edit.component.css']
})
export class EvaluatorEditComponent implements OnInit {

  public iicEvaluatorAddition: FormGroup;

  name: any = '';
  email: any = '';
  contact: any = '';
  show_field_2:any;
  show_field_3:any;
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  type:any;
  activity_id:any;

  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });

  }

  ngOnInit(): void {

    // alert(this.rid);

    this.iicEvaluatorAddition = this.fb.group({
      'name': [null, Validators.required],
      'email': [null, Validators.required],
      'contact': [null, Validators.required],
    });

    this.requireField=0;
    this.activity_id=0;

    // this.instituteActivityService.getIicEvaluatorList(this.type).subscribe(
    //   (data) => this.handleResponseList(data)
    // );

    this.instituteActivityService.getIicEvaluatorDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

  }

  handleResponse(data) {

    if(data['totalcount'] > 0){
    this.iicEvaluatorAddition.patchValue({
      name: data['data']['first_name'],
      email: data['data']['email'],
      contact: data['data']['contact'],

    });
  }
    this.totalcount = data['totalcount'];

  }

  handleResponseList(data) {
    this.activity_id = data['name'];
    this.iicActivity = data['data'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('name', this.iicEvaluatorAddition.get('name').value);
    formData.append('email', this.iicEvaluatorAddition.get('email').value);
    formData.append('contact', this.iicEvaluatorAddition.get('contact').value);
    formData.append('rid', this.rid);

    this.instituteMaster.submitIicEvaluator(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/innovation-repository';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

  onStatusChange(ob) {
    let nbaStatus = ob.value;
    if(nbaStatus == '1') {
      this.show_field_2 = 1;
      this.requireField = 1;
      this.instituteActivityService.getIicEvaluatorList(ob.value,'NONE','NONE').subscribe(
        (data) => this.handleResponseList(data)
      );
    } else {
      this.requireField = 0;
    }

  }

  onNbaStatusChange(ob) {
    let nbaStatus = ob.value;
    this.instituteActivityService.getIicEvaluatorList(ob.value,nbaStatus,this.show_field_2).subscribe(
      (data) => this.handleResponsePatch(data)
    );

  }

  handleResponsePatch(data) {
    this.iicEvaluatorAddition.patchValue({
      name: data['data']['name'],
      email: data['data']['email'],
      contact: data['data']['mobile']
    });
  }

}
