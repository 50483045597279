import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class InstituteTestimonialComponent implements OnInit {

  public iicTestimonialQuestions: FormGroup;

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iictestimonial:any;
  totalyearcount:any;

  constructor(private fb: FormBuilder, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.requireField=0;

    this.instituteActivityService.getIicTestimonialDetails('all').subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.iictestimonial = data['data'];

  }

  onClickEvent(data,type){

    if(type=="Edit")
    {
      window.location.href="/institute/activity/testimonial-add/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.deleteIicTestimonialDetails(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }

  }

  handleResponse1(data)
  {

  }


}
