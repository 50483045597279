import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-alt-invite-verfication-institute',
  templateUrl: './alt-invite-verfication-institute.component.html',
  styleUrls: ['./alt-invite-verfication-institute.component.css']
})
export class AltInviteVerficationInstituteComponent implements OnInit {

  vid:any;
  message:any;
  status:any;
  sData:any;

  constructor(
    private jarwis: JarwisService,
    private route: ActivatedRoute
  ) {



  }

  ngOnInit()
  {
    this.vid = "";

    this.route.queryParams
      .subscribe(params => {
        this.vid = params.vid;
      }
    );

      this.jarwis.atlVerificationSend(this.vid).subscribe(
        (data) => this.handleResponse(data)
      )

  }

  handleResponse(data) {
    this.message = data['message'];
    this.status = data['status'];
    this.sData = data['InstituteData'];
  }

}
