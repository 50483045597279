
<div class="container">

<div class="row addTeachingTopRow addTeachingTopRowExterMember" >
    <div class="col-4" *ngIf="sessionList.length<1">
      <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3"  (click)="openDialog()">
        <mat-icon>add</mat-icon> Add Impact Lectures Details
      </button>
    </div>
  </div>

</div>



<!-- [disabled]="sessionList.length>=2" -->






<!-- Convener Details -->


<div class="row" color="primary" >
    <h2 class="ss"
        style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
        Impact Lecture Session Details</h2>
</div>



    <div class="">
        <div class="">
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <th scope="col">S.No.</th>
                        <th>Session</th>
                        <th>Lecture 1</th>
                        <th>Lecture 2</th>
                        <th>Mode</th>
                        <!-- <th scope="col">Session</th>
                        <th scope="col">Title of lecture1</th>
                        <th scope="col">Theme of lecture 1</th> -->
                        <!--<th scope="col">Title of lecture 2</th>
                        <th scope="col">Theme of lecture 2</th>
                         <th scope="col">Tentative date</th> -->
                        <!-- <th scope="col">Mode of the session</th> -->
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nomination of sessionList; index as i">
                        <td>{{i+1}}</td>
                        <td>{{nomination?.session}}</td>
                        <td>Title of lecture 1: {{nomination?.title}}
                            <br>Theme of lecture 1: {{nomination?.theme}}
                        </td>
                        <td>Title of lecture 2: {{nomination?.title2}}
                            <br>Theme of lecture 2: {{nomination?.theme2}}
                        </td>
                        <td>{{nomination?.mode}}</td>
                        <!-- <td><br>Title: {{nomination?.title}}</td>
                        <td><br>Theme: {{nomination?.theme}}</td> -->
                         <!--<td>{{nomination?.title2}}</td>
                        <td>{{nomination?.theme2}}</td>
                        <td>{{nomination?.date}}</td> -->
                        <!-- <td><br>Mode: {{nomination?.mode}}</td> -->
<!-- <td>
  <a href="{{baseImageUrl + nomination?.file_upload}}" target="_blank">
  <button type="button" class="btn btn-danger mt-2 mb-2">Download Report</button>
</a></td> -->

                        <td>
                        	<button mat-raised-button (click)="onEditClick(nomination)" color="primary" class="mentorButEdit">
                                <mat-icon>create</mat-icon>
                                Edit</button>
                                   <!-- <button mat-raised-button (click)="onDeleteClick(nomination)" color="warn">
                                <mat-icon>delete</mat-icon>
                                Delete</button> -->
                        </td>
                    </tr>
                    <tr *ngIf="sessionList?.length < 0" col="6">
                                No Data Found
                    </tr>

                    <!-- <tr *ngIf="convenor_name=='0'" col="6">
                        No Data Found -->
            <!-- </tr> -->


                </tbody>
            </table>
        </div>
    </div>
