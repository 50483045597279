import { Component, OnInit, Inject } from '@angular/core';
import { InstituteSuggestionService } from 'src/app/Services/institute-suggestion.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reply-list',
  templateUrl: './reply-list.component.html',
  styleUrls: ['./reply-list.component.css'],
})
export class ReplyListComponent implements OnInit {
  suggestionId;

  replyList = [];

  constructor(
    private instituteSuggestionService: InstituteSuggestionService,
    public dialogRef: MatDialogRef<ReplyListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.suggestionId = data.suggestion.id;

    this.instituteSuggestionService
      .getReplyList(this.suggestionId)
      .subscribe((data) => this.handleResponse(data));
  }

  ngOnInit(): void {}

  handleResponse(data) {
    data['data'].forEach((element) => {
      this.replyList.push(element);
    });
  }
}
