import { Component, OnInit } from '@angular/core';
import { LandingService } from 'src/app/Services/landing.service';
import * as myGlobals from '../../../../app/app.component';

@Component({
  selector: 'app-iic202021-calender',
  templateUrl: './iic202021-calender.component.html',
  styleUrls: ['./iic202021-calender.component.css']
})
export class Iic202021CalenderComponent implements OnInit {

  baseUrl = myGlobals.baseUrl1;

  quarter_1:any;
  quarter_2:any;
  quarter_3:any;
  quarter_4:any;
  count_1:any;
  count_2:any;
  count_3:any;
  count_4:any;

  constructor(private landing: LandingService) { }

  ngOnInit(): void {

    this.landing.getActivityLists('2020-21').subscribe(
      data => this.handleResponse(data)
    );

  }

  handleResponse(data) {
		this.quarter_1  = data['data']['quarter_1'];
    this.count_1    = data['data']['count_1'];

    this.quarter_2  = data['data']['quarter_2'];
    this.count_2    = data['data']['count_2'];

    this.quarter_3  = data['data']['quarter_3'];
    this.count_3    = data['data']['count_3'];

    this.quarter_4  = data['data']['quarter_4'];
    this.count_4    = data['data']['count_4'];
	}

}
