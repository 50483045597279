<div class="instDownloadTable">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-striped" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="7" allign="left" class="danger" style="text-align:center;color:blue;">
                                <h1
                                    style="text-align: center;background-color: #0d133d;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px;">
                                    Star Performer List</h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col"> Sr. No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Email Id</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Star Performer Image Url</th>
                            <th scope="col">Event Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let starPerformer of starPerformers | paginate: { itemsPerPage: 10, currentPage: page }; let i = index">
                            <td>{{i + 1}}</td>
                            <td *ngIf="starPerformer.starName">{{starPerformer.starName}}</td>
                            <td *ngIf="!starPerformer.starName">N/A</td>
                            <td *ngIf="starPerformer.starDesignation">{{starPerformer.starDesignation}}</td>
                            <td *ngIf="!starPerformer.starDesignation">N/A</td>
                            <td *ngIf="starPerformer.starEmail">{{starPerformer.starEmail}}</td>
                            <td *ngIf="!starPerformer.starEmail">N/A</td>
                            <td *ngIf="starPerformer.starMobile">{{starPerformer.starMobile}}</td>
                            <td *ngIf="!starPerformer.starMobile">N/A</td>
                            <td *ngIf="starPerformer.starImage"><a href="{{baseImageUrl}}/institute/images/events/institutes/starImage/{{starPerformer.starImage}}" target="_blank">Image Url</a></td>
                            <td *ngIf="!starPerformer.starImage">N/A</td>
                            <td *ngIf="starPerformer.title">{{starPerformer.title}}</td>
                            <td *ngIf="!starPerformer.title">N/A</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls class="annular-pagination float-right" maxSize="3"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>