import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';

@Component({
  selector: 'app-dashboard-cards',
  templateUrl: './dashboard-cards.component.html',
  styleUrls: ['./dashboard-cards.component.css']
})
export class DashboardCardsComponent implements OnInit {
  activity: any;
  announcements: any;
  newsCorners: any;
  quarter2: any;
  quarter1: any;
  cumulative: any;
  cumulative_score: any;
  rewards_cumlative: any;
  ForWhom: any;

  constructor(private dashboardService: InstituteDashboardService) { }

  ngOnInit() {
    this.getReport();
    this.getAnnouncements();
    this.getNewsCorner();
    this.getRatings();
  }

  getReport() {
    this.dashboardService.getActivityData(localStorage.getItem('token')).subscribe(
      (data) => {
        this.activity = data['data'][0];
        ;
      }
    );
  }

  getAnnouncements() {
    this.dashboardService.getAnnouncements(localStorage.getItem('token')).subscribe(
      (data) => {
        this.setAnnouncements(data['data']);
      }
    );
  }

  setAnnouncements(data) {
    this.announcements = [];
    data.forEach(element => {
      this.announcements.push(element.announcement);
    });
  }

  getNewsCorner() {
    this.dashboardService.getAllNewsCornerDetails(localStorage.getItem('token')).subscribe(
      (data) => {
        this.setNewscorners(data['data']);
      }
    );
  }

  setNewscorners(data) {
    this.newsCorners = [];
    data.forEach(element => {
      element.forEach(news =>{
      this.newsCorners.push(news.evant_title);
      });
    });
  }

  getRatings(){
    this.dashboardService.getInstituteDashboardRating().subscribe(
      data => {
        this.quarter1 = data['data'][0];
        this.quarter2 = data['data'][1];
        this.cumulative = data['data'][2];
		 this.cumulative_score = data['meta']['pagination']['score_cumlative'];
		 this.rewards_cumlative = data['meta']['pagination']['rewards_cumlative'];
      }     
    )
  }
}
