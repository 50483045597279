import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-subiic-add',
  templateUrl: './subiic-add.component.html',
  styleUrls: ['./subiic-add.component.css']
})
export class SubiicAddComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  cityname:any;

  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });



  }

  ngOnInit(): void {

    this.cityname ="";

    this.iicImpactQuestions = this.fb.group({
      'institute_name': [null, Validators.required],
      'address':[null, Validators.required,],
      'city': [],
      'state': [],
      'ins_subtype': [null, Validators.required],
      'pid': [],
      'aishe_code': [],
      'doc_proof': [],
      'name': [null, Validators.required],
      'email': [null, Validators.required],
      'mobile': [null, Validators.required],
      'designation': [null, Validators.required],
      'institute_type': [null, Validators.required]
    });

    this.requireField=0;
    this.activity_id=0;

    this.instituteActivityService.getSubIicRepositoryDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

    this.instituteActivityService.getRatingDetails().subscribe(
      (data) => this.handleResponse1(data)
    );

    // var parm = JSON.stringify(["program_theme"]);
    // this.instituteMaster
    //   .getMasterDetails(parm)
    //   .subscribe((data) => this.handleResponseForAutoPopulate(data));



  }

  handleResponseForAutoPopulate(data) {

    // this.programTheme = data['data'];
    // if (data['data'][0]) {
    //   data['data'][0].forEach((element) => {
    //     this.programTheme.push(element.data.data.name);
    //   });
    // }

    this.programTheme = "";

  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }


  handleResponse(data) {

  if(data['totalcount'] > 0){
      
    this.iicImpactQuestions.patchValue({
      institute_name: data['institute_name'],
      address: data['address'],
      city: data['city'],
      // ins_type: data['ins_type'],      
      ins_subtype: data['ins_subtype'],
      aishe_code: data['aishe_code'],
      pid: data['pid'],
      name: data['name'],
      email: data['email'],
      mobile: data['mobile'],
      designation: data['designation'],
      institute_type: data['institute_type']
    });

  }

    this.cityname = data['cityname'];
    this.totalcount = data['totalcount'];

  }


  handleResponse1(data) {
    this.iicImpactQuestions.patchValue({
      aishe_code: data['rating']['aishe'],
      state: data['rating']['state'],
      city: data['rating']['city'],
    });

  }

  handleResponseList(data) {
    this.iicActivity = data['data'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('rid', this.rid);
    formData.append('role', '3');
    formData.append('institute_name', this.iicImpactQuestions.get('institute_name').value);
    formData.append('address', this.iicImpactQuestions.get('address').value);
    formData.append('city', this.iicImpactQuestions.get('city').value);
    formData.append('ins_type', "NONE");
    formData.append('aishe_code', this.iicImpactQuestions.get('aishe_code').value);
    formData.append('ins_subtype', this.iicImpactQuestions.get('ins_subtype').value);
    formData.append('aicte_approved_program', this.iicImpactQuestions.get('pid').value);
    formData.append('name', this.iicImpactQuestions.get('name').value);
    formData.append('email', this.iicImpactQuestions.get('email').value);
    formData.append('mobile_number', this.iicImpactQuestions.get('mobile').value);
    formData.append('designation', this.iicImpactQuestions.get('designation').value);
    formData.append('institute_type', this.iicImpactQuestions.get('institute_type').value);
    this.instituteActivityService.submitSubIicRepository(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if (data['message'] == 'Success') {
      alert('Institute invitation has been sent successfully.');
      window.location.href="/institute/subiic";
    } else {
      this.toastr.error(data['reason']);
    }
  }


}
