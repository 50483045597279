import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-academic-year-performance202223',
  templateUrl: './academic-year-performance202223.component.html',
  styleUrls: ['./academic-year-performance202223.component.css']
})
export class AcademicYearPerformance202223Component implements OnInit {

  formData: any;
  fStar:any;
  fstarScore:any;
  fourthStarScore:any;
  constructor(private instituteMyCouncilService: InstituteMyCouncilService) { }

  ngOnInit(): void {

    this.instituteMyCouncilService
      .getRewardPointsCurrentAyear('2022-23')
      .subscribe((data) => this.handleResponse1(data));
  }

  handleResponse1(data) {
    this.formData = data['data'];
    this.fStar = data['data']['FStar'];
    this.fstarScore = data['data']['FStarScore'];
    this.fourthStarScore = data['data']['fouthStar'];
  }

}
