import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ActivityProposalDialogComponent } from '../activity-proposal-dialog/activity-proposal-dialog.component';
import * as myGlobals from '../../../../../../../../app.component';

@Component({
  selector: 'app-activity-report-form2',
  templateUrl: './activity-report-form2.component.html',
  styleUrls: ['./activity-report-form2.component.css']
})
export class ActivityReportForm2Component implements OnInit {

  edit: boolean;
  editData: any;
  id: any;
  report: FileList | null;
  public addActivityForm1: FormGroup;
  public addActivityForm2: FormGroup;
  public addActivityForm5: FormGroup;
  date1:any;
  file_upload: any;
  reportStatus: boolean;
  minDate = "2021-12-01";
  maxDate = "2022-03-31";
  schedule_id:any;
  mentee_id:any;

  baseImageUrl = myGlobals.baseImageUrl2;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ActivityProposalDialogComponent>,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public activity: InstituteActivityService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.edit = false;

      this.schedule_id=data["activityId"];
      this.mentee_id=data["menteeId"];
     }

  ngOnInit(): void {

     this.addActivityForm1 = this.fb.group({
     });

     this.activity.getScheduledReport(this.mentee_id).subscribe(data=>this.setData(data));

  }

  setData(data){
    this.file_upload=data['MMDetails']['report'];

    if(this.file_upload!=null){
      this.reportStatus=true;
    }
  }



  onSubmit(data){

    var cvFile = this.report && this.report.length ? this.report[0] : null;

    if (cvFile!= undefined || cvFile!= null)
    {
        var formData = new FormData();

        formData.append('file', cvFile);
        formData.append('schedule_id', this.schedule_id);
        formData.append('mentee_id', this.mentee_id);
        this.activity.postScheduledReport(formData).subscribe((data) => this.handleResponse(data));

    }
    else
    {
      this.toastr.error("Please upload the Activity Report.");
    }
  }


  handleResponse(data){
    if(data['status']==1)
    {
      this.toastr.success(data['message']);
      this.dialogRef.close();
    }
    else
    {
      this.toastr.error(data['message']);
    }

  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }
}
