<div class="row" style="padding-top: 10px">
  <div class="col-md-12">
    <h2 class="actionPlanHeading">Quarterly Progress Meetings</h2>
  </div>
  <div class="col-sm-12">
    <p>
      Take part in the quarterly progress meeting of mentee IIC institutions and
      provide guidance on planning, progress assessment, action plan preparation
      and improvisation of I&E activities to be conducted in the mentee
      institutions.
    </p>
    <p>
      <a
        href="javascript:void(0);"
        data-toggle="collapse"
        href="#rws-guideline2"
        role="button"
        aria-expanded="false"
        aria-controls="rws-guideline2"
        >View Guidlines</a
      >
    </p>
    <div class="collapse multi-collapse" id="rws-guideline2">
      <div class="card card-body">
        <ul>
          <li>
            A key functionary from the IIC of the mentor institute will join as
            an expert member of IIC at the mentee institute.
          </li>

          <li>
            President of mentor IIC institute will nominate the member and
            he/she will take part in quarterly IIC meetings of IIC mentee
            institute.
          </li>

          <li>
            Once the member is nominated for the mentee institute, the same
            member is required to continue till the end of the IIC calendar
            year.
          </li>

          <li>
            Each nominated member needs to take part in 3 such
            quarterly/semester meetings during the IIC calendar year.
          </li>

          <li>
            Quarterly meetings shall be conducted on online mode using video
            conferencing platforms.
          </li>

          <li>
            Role of a member in the mentee IIC institute is to actively
            participate in quarterly progress meetings of mentee IIC
            institutions and provide guidance on planning, action plan
            preparation, progress assessment and improvisation of I&E activities
            to be conducted in the mentee institutions.
          </li>

          <li>
            Mentor IIC institute may spend expenses towards honorarium to the
            nominated members for completing the above tasks to support the
            mentee institution. Provision of honorarium amount of Rs. 1000/- per
            member per quarterly meeting action plan finalization for mentee
            institute.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <mat-tab-group>
      <mat-tab label="Q1/Semester 1">
        <p *ngIf="RQ_21_file == ''">
          <button
            mat-raised-button
            (click)="openReportDialog(21)"
            color="primary"
          >
            Upload Report
          </button>
        </p>
        <p *ngIf="RQ_21_file != ''">
          <button
            mat-raised-button
            (click)="openReportDialog(21)"
            color="primary"
          >
            Update Report
          </button>
        </p>
        <p *ngIf="RQ_21_file != ''">
          <a target="_blank" href="{{ baseUrl + RQ_21_file }}">View File</a>
        </p>

        <form
          [formGroup]="addActivityForm2"
          (submit)="onSubmit(addActivityForm2.value, 'Quarter 1')"
        >
          <table class="table table-hover table-responsive">
            <thead class="facultyTable">
              <tr>
                <th scope="col" style="width: 2%">Sr.No.</th>
                <th scope="col">Mentee Institute Name</th>
                <th scope="col">Meeting Link</th>
                <th scope="col" style="width: 10%">Date</th>
                <th scope="col" style="width: 10%">Time</th>
                <th scope="col" style="width: 5%">
                  Nominee from Mentor Institute
                </th>
                <!-- 5-7-24 -->
                <th scope="col" style="width: 2%">Participants Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let number of numbers">
                <tr *ngIf="TMentee >= number">
                  <td>{{ number }}</td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Mentee Institute Name</mat-label>
                      <mat-icon
                        matSuffix
                        class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                        >person</mat-icon
                      >
                      <input
                        matInput
                        placeholder="Mentee Institute Name"
                        formControlName="mentee_{{ number }}_1"
                        required
                        readonly
                      />
                      <mat-error
                        *ngIf="
                          addActivityForm2.controls.mentee_1_1.errors?.required
                        "
                        >Mentee Institute UserID is required</mat-error
                      >
                    </mat-form-field>
                    <p style="font-size: 14px" class="menteenamedata">
                      {{ Mentee[number - 1].instituteName }}
                    </p>
                    <!-- <p style="font-size: 14px" class="menteeTypeData">
                      Funded By: {{ Mentee[number - 1].mentee_type }}
                    </p> -->
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Online session link</mat-label>
                      <mat-icon
                        matSuffix
                        class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                        >person</mat-icon
                      >
                      <input
                        matInput
                        placeholder="Online session link"
                        formControlName="session_{{ number }}_1"
                        required
                      />
                      <mat-error
                        *ngIf="
                          addActivityForm2.controls.session_1_1.errors?.required
                        "
                        >Online session link is required</mat-error
                      >
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Tentative date</mat-label>
                      <input
                        matInput
                        readonly
                        [matDatepicker]="picker1"
                        formControlName="date_{{ number }}_1"
                        [min]="minDate"
                        [max]="maxDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-label>Tentative time</mat-label>
                    <mat-icon
                      matSuffix
                      class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                      >person</mat-icon
                    >
                    <input
                      readonly
                      [ngxTimepicker]="picker"
                      formControlName="time_{{ number }}_1"
                    />
                    <ngx-material-timepicker
                      #picker
                      placeholder="Session Time"
                    ></ngx-material-timepicker>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Select</mat-label>
                      <mat-select
                        autocomplete="off"
                        placeholder="Select"
                        formControlName="guest_{{ number }}_1"
                        required
                      >
                        <mat-option>Select</mat-option>
                        <mat-option
                          value="{{ guest?.id }}"
                          *ngFor="
                            let guest of Mentee[number - 1]?.menteeFaculty
                          "
                          >{{ guest?.name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <p *ngIf="guestNameCondition1(number)">
                      {{ guestNameValue1(number) }}
                    </p>
                  </td>
                  <td>{{ quaterparticipationstatus(number) }}</td>
                </tr>
              </ng-container>
              <!-- <tr *ngIf="TMentee>=1">
                  <td >1</td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Mentee Institute Name</mat-label>
                      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                      <input matInput placeholder="Mentee Institute Name" formControlName="mentee_1_1" required readonly>
                      <mat-error *ngIf="addActivityForm2.controls.mentee_1_1.errors?.required">Mentee Institute UserID is required</mat-error>
                    </mat-form-field>
                    <p style="font-size: 14px;" class="menteenamedata">{{Mentee[0].instituteName}}</p>
                    <p style="font-size: 14px;" class="menteeTypeData">
                      Funded By: {{Mentee[0].mentee_type}}</p>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Online session link</mat-label>
                      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                      <input matInput placeholder="Online session link" formControlName="session_1_1" required>
                      <mat-error *ngIf="addActivityForm2.controls.session_1_1.errors?.required">Online session link is required</mat-error>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                        <mat-label>Tentative date</mat-label>
                        <input matInput readonly [matDatepicker]="picker1" formControlName="date_1_1" [min]="minDate" [max]="maxDate" >
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>

                    <mat-label>Tentative time</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input readonly [ngxTimepicker]="picker" formControlName="time_1_1" >
                    <ngx-material-timepicker #picker placeholder="Session Time"  ></ngx-material-timepicker>

                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Select</mat-label>
                      <mat-select autocomplete="off" placeholder="Select" formControlName="guest_1_1"  required>
                        <mat-option>Select</mat-option>
                        <mat-option value="{{guest?.id}}" *ngFor="let guest of Mentee[0]?.menteeFaculty">{{guest?.name}}</mat-option>
                      </mat-select>
                  </mat-form-field>

                  <p *ngIf="schedules2?.guest_name_1_1!=''">{{schedules2?.guest_name_1_1}}</p>

                  </td>

                <td>
                  <button *ngIf="schedules2?.date_1_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2?.date_1_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>

              </tr>

              <tr *ngIf="TMentee>=2">
                <td >2</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_2_1" required readonly>
                    <mat-error *ngIf="addActivityForm2.controls.mentee_2_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[1].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[1].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_2_1">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker21" formControlName="date_2_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker21"></mat-datepicker-toggle>
                      <mat-datepicker #picker21></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker22" formControlName="time_2_1" >
                  <ngx-material-timepicker #picker22 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_2_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest?.id}}" *ngFor="let guest of Mentee[1]?.menteeFaculty">{{guest?.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p *ngIf="schedules2?.guest_name_2_1!=''">{{schedules2?.guest_name_2_1}}</p>
                </td>

                <td>
                  <button *ngIf="schedules2?.date_2_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2?.date_2_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>


      <tr *ngIf="TMentee>=3">
                <td>3</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_3_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2.controls.mentee_3_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[2].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[2].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_3_1">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker31" formControlName="date_3_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker31"></mat-datepicker-toggle>
                      <mat-datepicker #picker31></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker32" formControlName="time_3_1" >
                  <ngx-material-timepicker #picker32 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_3_1" >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest?.id}}" *ngFor="let guest of Mentee[2]?.menteeFaculty">{{guest?.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p *ngIf="schedules2?.guest_name_3_1!=''">{{schedules2?.guest_name_3_1}}</p>
                </td>

                <td>
                  <button *ngIf="schedules2?.date_3_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2?.date_3_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>
                <tr  *ngIf="TMentee>=4">
                <td >4</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_4_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2.controls.mentee_4_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[3].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[3].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_4_1">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker41" formControlName="date_4_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker41"></mat-datepicker-toggle>
                      <mat-datepicker #picker41></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker42" formControlName="time_4_1" >
                  <ngx-material-timepicker #picker42 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_4_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest?.id}}" *ngFor="let guest of Mentee[3]?.menteeFaculty">{{guest?.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p *ngIf="schedules2?.guest_name_4_1!=''">{{schedules2?.guest_name_4_1}}</p>
                </td>

                <td>
                  <button *ngIf="schedules2?.date_4_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2?.date_4_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>

      <tr  *ngIf="TMentee>=5">
                <td >5</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_5_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2.controls.mentee_5_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[4].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[4].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_5_1">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker51" formControlName="date_5_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker51"></mat-datepicker-toggle>
                      <mat-datepicker #picker51></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker52" formControlName="time_5_1" >
                  <ngx-material-timepicker #picker52 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_5_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[4].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p *ngIf="schedules2.guest_name_5_1!=''">{{schedules2.guest_name_5_1}}</p>
                </td>

                <td>
                  <button *ngIf="schedules2.date_5_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_5_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>

            <tr  *ngIf="TMentee>=6">
              <td >6</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_6_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2.controls.mentee_6_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[5].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[5].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_6_1">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker61" formControlName="date_6_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker61"></mat-datepicker-toggle>
                    <mat-datepicker #picker61></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker62" formControlName="time_6_1" >
                <ngx-material-timepicker #picker62 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_6_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[5].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p *ngIf="schedules2.guest_name_6_1!=''">{{schedules2.guest_name_6_1}}</p>
              </td>

              <td>
                <button *ngIf="schedules2.date_6_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_6_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

          <tr  *ngIf="TMentee>=7">
            <td >7</td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Mentee Institute Name</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input matInput placeholder="Mentee Institute Name" formControlName="mentee_7_1" required  readonly>
                <mat-error *ngIf="addActivityForm2.controls.mentee_7_1.errors?.required">Online session link is required</mat-error>
              </mat-form-field>
              <p style="font-size: 14px;" class="menteenamedata">{{Mentee[6].instituteName}}</p>
              <p style="font-size: 14px;" class="menteeTypeData">
                Funded By: {{Mentee[6].mentee_type}}</p>
            </td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Online session link</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input matInput placeholder="Online session link" formControlName="session_7_1">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Tentative date</mat-label>
                  <input matInput readonly [matDatepicker]="picker71" formControlName="date_7_1" [min]="minDate" [max]="maxDate" >
                  <mat-datepicker-toggle matSuffix [for]="picker71"></mat-datepicker-toggle>
                  <mat-datepicker #picker71></mat-datepicker>
              </mat-form-field>
            </td>
            <td>

              <mat-label>Tentative time</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input readonly [ngxTimepicker]="picker72" formControlName="time_7_1" >
              <ngx-material-timepicker #picker72 placeholder="Session Time"  ></ngx-material-timepicker>

            </td>
            <td>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select</mat-label>
                <mat-select autocomplete="off" placeholder="Select" formControlName="guest_7_1"  >
                  <mat-option>Select</mat-option>
                  <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[6].menteeFaculty">{{guest.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <p *ngIf="schedules2.guest_name_7_1!=''">{{schedules2.guest_name_7_1}}</p>
            </td>

            <td>
              <button *ngIf="schedules2.date_7_1==''" 
              mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
              <button *ngIf="schedules2.date_7_1!=''" 
              mat-raised-button color="primary" class="mentorButEdit">Edit</button>
            </td>
        </tr>

        <tr  *ngIf="TMentee>=8">
          <td >8</td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
              <mat-label>Mentee Institute Name</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input matInput placeholder="Mentee Institute Name" formControlName="mentee_8_1" required  readonly>
              <mat-error *ngIf="addActivityForm2.controls.mentee_8_1.errors?.required">Online session link is required</mat-error>
            </mat-form-field>
            <p style="font-size: 14px;" class="menteenamedata">{{Mentee[7].instituteName}}</p>
            <p style="font-size: 14px;" class="menteeTypeData">
              Funded By: {{Mentee[7].mentee_type}}</p>
          </td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
              <mat-label>Online session link</mat-label>
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <input matInput placeholder="Online session link" formControlName="session_8_1">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                <mat-label>Tentative date</mat-label>
                <input matInput readonly [matDatepicker]="picker81" formControlName="date_8_1" [min]="minDate" [max]="maxDate" >
                <mat-datepicker-toggle matSuffix [for]="picker81"></mat-datepicker-toggle>
                <mat-datepicker #picker81></mat-datepicker>
            </mat-form-field>
          </td>
          <td>

            <mat-label>Tentative time</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input readonly [ngxTimepicker]="picker82" formControlName="time_8_1" >
            <ngx-material-timepicker #picker82 placeholder="Session Time"  ></ngx-material-timepicker>

          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select</mat-label>
              <mat-select autocomplete="off" placeholder="Select" formControlName="guest_8_1"  >
                <mat-option>Select</mat-option>
                <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[7].menteeFaculty">{{guest.name}}</mat-option>
              </mat-select>
          </mat-form-field>
          <p *ngIf="schedules2.guest_name_8_1!=''">{{schedules2.guest_name_8_1}}</p>
          </td>

          <td>
            <button *ngIf="schedules2.date_8_1==''" 
            mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
            <button *ngIf="schedules2.date_8_1!=''" 
            mat-raised-button color="primary" class="mentorButEdit">Edit</button>
          </td>
      </tr>

      <tr  *ngIf="TMentee>=9">
        <td >9</td>
        <td>
          <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
            <mat-label>Mentee Institute Name</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input matInput placeholder="Mentee Institute Name" formControlName="mentee_9_1" required  readonly>
            <mat-error *ngIf="addActivityForm2.controls.mentee_9_1.errors?.required">Online session link is required</mat-error>
          </mat-form-field>
          <p style="font-size: 14px;" class="menteenamedata">{{Mentee[8].instituteName}}</p>
          <p style="font-size: 14px;" class="menteeTypeData">
            Funded By: {{Mentee[8].mentee_type}}</p>
        </td>
        <td>
          <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
            <mat-label>Online session link</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input matInput placeholder="Online session link" formControlName="session_9_1">
          </mat-form-field>
        </td>
        <td>
          <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
              <mat-label>Tentative date</mat-label>
              <input matInput readonly [matDatepicker]="picker91" formControlName="date_9_1" [min]="minDate" [max]="maxDate" >
              <mat-datepicker-toggle matSuffix [for]="picker91"></mat-datepicker-toggle>
              <mat-datepicker #picker91></mat-datepicker>
          </mat-form-field>
        </td>
        <td>

          <mat-label>Tentative time</mat-label>
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <input readonly [ngxTimepicker]="picker92" formControlName="time_9_1" >
          <ngx-material-timepicker #picker92 placeholder="Session Time"  ></ngx-material-timepicker>

        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Select</mat-label>
            <mat-select autocomplete="off" placeholder="Select" formControlName="guest_9_1"  >
              <mat-option>Select</mat-option>
              <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[8].menteeFaculty">{{guest.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <p *ngIf="schedules2.guest_name_9_1!=''">{{schedules2.guest_name_9_1}}</p>
        </td>

        <td>
          <button *ngIf="schedules2.date_9_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_9_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
    </tr>

    <tr  *ngIf="TMentee>=10">
      <td >10</td>
      <td>
        <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
          <mat-label>Mentee Institute Name</mat-label>
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <input matInput placeholder="Mentee Institute Name" formControlName="mentee_10_1" required  readonly>
          <mat-error *ngIf="addActivityForm2.controls.mentee_10_1.errors?.required">Online session link is required</mat-error>
        </mat-form-field>
        <p style="font-size: 14px;" class="menteenamedata">{{Mentee[9].instituteName}}</p>
        <p style="font-size: 14px;" class="menteeTypeData">
          Funded By: {{Mentee[9].mentee_type}}</p>
      </td>
      <td>
        <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
          <mat-label>Online session link</mat-label>
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <input matInput placeholder="Online session link" formControlName="session_10_1">
        </mat-form-field>
      </td>
      <td>
        <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
            <mat-label>Tentative date</mat-label>
            <input matInput readonly [matDatepicker]="picker101" formControlName="date_10_1" [min]="minDate" [max]="maxDate" >
            <mat-datepicker-toggle matSuffix [for]="picker101"></mat-datepicker-toggle>
            <mat-datepicker #picker101></mat-datepicker>
        </mat-form-field>
      </td>
      <td>

        <mat-label>Tentative time</mat-label>
        <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
        <input readonly [ngxTimepicker]="picker102" formControlName="time_10_1" >
        <ngx-material-timepicker #picker102 placeholder="Session Time"  ></ngx-material-timepicker>

      </td>
      <td>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Select</mat-label>
          <mat-select autocomplete="off" placeholder="Select" formControlName="guest_10_1"  >
            <mat-option>Select</mat-option>
            <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[9].menteeFaculty">{{guest.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      <p *ngIf="schedules2.guest_name_10_1!=''">{{schedules2.guest_name_10_1}}</p>
      </td>

      <td>
        <button *ngIf="schedules2.date_10_1==''" 
        mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
        <button *ngIf="schedules2.date_10_1!=''" 
        mat-raised-button color="primary" class="mentorButEdit">Edit</button>
      </td>
  </tr> -->

              <!-- <tr>
              <td colspan="7" class="submitLast"><button mat-raised-button color='primary' class="btn btn-success form1But"
                [disabled]='addActivityForm2.invalid'>Submit</button></td>
            </tr> -->
            </tbody>
          </table>
        </form>
      </mat-tab>

      <mat-tab label="Q2/Semester 1">
        <p *ngIf="RQ_22_file == ''">
          <button
            mat-raised-button
            (click)="openReportDialog(22)"
            color="primary"
          >
            Upload Report
          </button>
        </p>
        <p *ngIf="RQ_22_file != ''">
          <button
            mat-raised-button
            (click)="openReportDialog(22)"
            color="primary"
          >
            Update Report
          </button>
        </p>
        <p *ngIf="RQ_22_file != ''">
          <a target="_blank" href="{{ baseUrl + RQ_22_file }}">View File</a>
        </p>

        <form
          [formGroup]="addActivityForm2_2"
          (submit)="onSubmit_2_2(addActivityForm2_2.value, 'Quarter 2')"
        >
          <table class="table table-hover table-responsive">
            <thead class="facultyTable">
              <tr>
                <th scope="col" style="width: 2%">Sr.No.</th>
                <th scope="col">Mentee Institute Name</th>
                <th scope="col">Meeting Link</th>
                <th scope="col" style="width: 13%">Date</th>
                <th scope="col" style="width: 12%">Time</th>
                <th scope="col" style="width: 15%">
                  Nominee from Mentor Institute
                </th>
                <!-- 5-7-24 -->
                <th scope="col" style="width: 5%">Participants Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let number of numbers">
                <tr *ngIf="TMentee >= number">
                  <td>{{ number }}</td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Mentee Institute Name</mat-label>
                      <mat-icon
                        matSuffix
                        class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                        >person</mat-icon
                      >
                      <input
                        matInput
                        placeholder="Mentee Institute Name"
                        formControlName="mentee_{{ number }}_2_1"
                        required
                        readonly
                      />
                      <mat-error
                        *ngIf="
                          addActivityForm2_2.controls.mentee_1_2_1.errors
                            ?.required
                        "
                        >Online session link is required</mat-error
                      >
                    </mat-form-field>
                    <p style="font-size: 14px" class="menteenamedata">
                      {{ Mentee[number - 1].instituteName }}
                    </p>
                    <!-- <p style="font-size: 14px" class="menteeTypeData">
                      Funded By: {{ Mentee[number - 1].mentee_type }}
                    </p> -->
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Online session link</mat-label>
                      <mat-icon
                        matSuffix
                        class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                        >person</mat-icon
                      >
                      <input
                        matInput
                        placeholder="Online session link"
                        formControlName="session_{{ number }}_2_1"
                        required
                      />
                      <mat-error
                        *ngIf="
                          addActivityForm2_2.controls.session_1_2_1.errors
                            ?.required
                        "
                        >Online session link is required</mat-error
                      >
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Tentative date</mat-label>
                      <input
                        matInput
                        readonly
                        [matDatepicker]="picker2101"
                        formControlName="date_{{ number }}_2_1"
                        [min]="minDate"
                        [max]="maxDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2101"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2101></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-label>Tentative time</mat-label>
                    <mat-icon
                      matSuffix
                      class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                      >person</mat-icon
                    >
                    <input
                      readonly
                      [ngxTimepicker]="picker2102"
                      formControlName="time_{{ number }}_2_1"
                    />
                    <ngx-material-timepicker
                      #picker2102
                      placeholder="Session Time"
                    ></ngx-material-timepicker>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Select</mat-label>
                      <mat-select
                        autocomplete="off"
                        placeholder="Select"
                        formControlName="guest_{{ number }}_2_1"
                        required
                      >
                        <mat-option>Select</mat-option>
                        <mat-option
                          value="{{ guest.id }}"
                          *ngFor="let guest of Mentee[number - 1].menteeFaculty"
                          >{{ guest.name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>

                    <p>{{ guestNameValue2(number) }}</p>
                  </td>
                  <td>{{ quater2participationstatus(number) }}</td>
                  <td>
                    <button
                      *ngIf="dateButtonCondition1(2, 1, number)"
                      mat-raised-button
                      color="primary"
                      class="mentorButEdit"
                    >
                      Schedule
                    </button>
                    <button
                      *ngIf="dateButtonCondition1(2, 2, number)"
                      mat-raised-button
                      color="primary"
                      class="mentorButEdit"
                    >
                      Edit
                    </button>
                  </td>
                  <!-- 7-5-24 -->
                </tr>
              </ng-container>
              <!-- <tr *ngIf="TMentee>=1">
                  <td >1</td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Mentee Institute Name</mat-label>
                      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                      <input matInput placeholder="Mentee Institute Name" formControlName="mentee_1_2_1" required  readonly>
                      <mat-error *ngIf="addActivityForm2_2.controls.mentee_1_2_1.errors?.required">Online session link is required</mat-error>
                    </mat-form-field>
                    <p style="font-size: 14px;" class="menteenamedata">{{Mentee[0].instituteName}}</p>
                    <p style="font-size: 14px;" class="menteeTypeData">
                      Funded By: {{Mentee[0].mentee_type}}</p>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Online session link</mat-label>
                      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                      <input matInput placeholder="Online session link" formControlName="session_1_2_1" required>
                      <mat-error *ngIf="addActivityForm2_2.controls.session_1_2_1.errors?.required">Online session link is required</mat-error>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                        <mat-label>Tentative date</mat-label>
                        <input matInput readonly [matDatepicker]="picker2101" formControlName="date_1_2_1" [min]="minDate" [max]="maxDate" >
                        <mat-datepicker-toggle matSuffix [for]="picker2101"></mat-datepicker-toggle>
                        <mat-datepicker #picker2101></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>

                    <mat-label>Tentative time</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input readonly [ngxTimepicker]="picker2102" formControlName="time_1_2_1" >
                    <ngx-material-timepicker #picker2102 placeholder="Session Time"  ></ngx-material-timepicker>

                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Select</mat-label>
                      <mat-select autocomplete="off" placeholder="Select" formControlName="guest_1_2_1"  required>
                        <mat-option>Select</mat-option>
                        <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[0].menteeFaculty">{{guest.name}}</mat-option>
                      </mat-select>
                  </mat-form-field>

                  <p>{{guest_name_1_2_1}}</p>

                  </td>
                  <td>
                    <button *ngIf="schedules2.date_1_2_1==''" 
                    mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                    <button *ngIf="schedules2.date_1_2_1!=''" 
                    mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                  </td>
              </tr>

              <tr *ngIf="TMentee>=2">
                <td >2</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_2_2_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_2.controls.mentee_2_2_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[1].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[1].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_2_2_1">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker2121" formControlName="date_2_2_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker2121"></mat-datepicker-toggle>
                      <mat-datepicker #picker2121></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker2122" formControlName="time_2_2_1" >
                  <ngx-material-timepicker #picker2122 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_2_2_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[1].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_2_2_1}}</p>
                </td>
                <td>
                  <button *ngIf="schedules2.date_2_2_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_2_2_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>


      <tr *ngIf="TMentee>=3">
                <td >3</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_3_2_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_2.controls.mentee_3_2_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[2].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[2].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_3_2_1">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker2131" formControlName="date_3_2_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker2131"></mat-datepicker-toggle>
                      <mat-datepicker #picker2131></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker2132" formControlName="time_3_2_1" >
                  <ngx-material-timepicker #picker2132 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_3_2_1" >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[2].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_3_2_1}}</p>
                </td>
                <td>
                  <button *ngIf="schedules2.date_3_2_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_3_2_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>
                <tr *ngIf="TMentee>=4">
                <td >4</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_4_2_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_2.controls.mentee_4_2_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[3].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[3].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_4_2_1">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker2141" formControlName="date_4_2_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker2141"></mat-datepicker-toggle>
                      <mat-datepicker #picker2141></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker2142" formControlName="time_4_2_1" >
                  <ngx-material-timepicker #picker2142 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_4_2_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[3].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_4_2_1}}</p>
                </td>
                <td>
                  <button *ngIf="schedules2.date_4_2_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_4_2_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>

      <tr  *ngIf="TMentee>=5">
                <td >5</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_5_2_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_2.controls.mentee_5_2_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[4].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[4].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_5_2_1">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker2151" formControlName="date_5_2_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker2151"></mat-datepicker-toggle>
                      <mat-datepicker #picker2151></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker2152" formControlName="time_5_2_1" >
                  <ngx-material-timepicker #picker2152 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_5_2_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[4].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_5_2_1}}</p>
                </td>
                <td>
                  <button *ngIf="schedules2.date_5_2_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_5_2_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>

            <tr  *ngIf="TMentee>=6">
              <td >6</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_6_2_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_2.controls.mentee_6_2_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[5].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[5].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_6_2_1">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker2161" formControlName="date_6_2_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker2161"></mat-datepicker-toggle>
                    <mat-datepicker #picker2161></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker2162" formControlName="time_6_2_1" >
                <ngx-material-timepicker #picker2162 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_6_2_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[5].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_6_2_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_6_2_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_6_2_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

    <tr  *ngIf="TMentee>=7">
              <td >7</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_7_2_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_2.controls.mentee_7_2_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[6].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[6].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_7_2_1">
                  
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker2171" formControlName="date_7_2_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker2171"></mat-datepicker-toggle>
                    <mat-datepicker #picker2171></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker2172" formControlName="time_7_2_1" >
                <ngx-material-timepicker #picker2172 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_7_2_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[6].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_7_2_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_7_2_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_7_2_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

    <tr  *ngIf="TMentee>=8">
              <td >8</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_8_2_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_2.controls.mentee_8_2_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[7].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[7].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_8_2_1">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker2181" formControlName="date_8_2_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker2181"></mat-datepicker-toggle>
                    <mat-datepicker #picker2181></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker2182" formControlName="time_8_2_1" >
                <ngx-material-timepicker #picker2182 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_8_2_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[7].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_8_2_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_8_2_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_8_2_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

    <tr  *ngIf="TMentee>=9">
              <td >9</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_9_2_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_2.controls.mentee_9_2_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[8].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[8].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_9_2_1">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker2191" formControlName="date_9_2_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker2191"></mat-datepicker-toggle>
                    <mat-datepicker #picker2191></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker2192" formControlName="time_9_2_1" >
                <ngx-material-timepicker #picker2192 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_9_2_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[8].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_9_2_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_9_2_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_9_2_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

    <tr  *ngIf="TMentee>=10">
              <td >10</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_10_2_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_2.controls.mentee_10_2_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[9].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[9].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_10_2_1">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker21101" formControlName="date_10_2_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker21101"></mat-datepicker-toggle>
                    <mat-datepicker #picker21101></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker21102" formControlName="time_10_2_1" >
                <ngx-material-timepicker #picker21102 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_10_2_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[10].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_10_2_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_10_2_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_10_2_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr> -->

              <!-- <tr>
              <td colspan="7" class="submitLast"><button mat-raised-button color='primary' class="btn btn-success form1But"
                [disabled]='addActivityForm2_2.invalid'>Submit</button></td>
            </tr> -->
            </tbody>
          </table>
        </form>
      </mat-tab>

      <mat-tab label="Q3/Semester 2">
        <p *ngIf="RQ_23_file == ''">
          <button
            mat-raised-button
            (click)="openReportDialog(23)"
            color="primary"
          >
            Upload Report
          </button>
        </p>
        <p *ngIf="RQ_23_file != ''">
          <button
            mat-raised-button
            (click)="openReportDialog(23)"
            color="primary"
          >
            Update Report
          </button>
        </p>
        <p *ngIf="RQ_23_file != ''">
          <a target="_blank" href="{{ baseUrl + RQ_23_file }}">View File</a>
        </p>
        <form
          [formGroup]="addActivityForm2_3"
          (submit)="onSubmit_2_3(addActivityForm2_3.value, 'Quarter 3')"
        >
          <table class="table table-hover table-responsive">
            <thead class="facultyTable">
              <tr>
                <th scope="col" style="width: 2%">Sr.No.</th>
                <th scope="col">Mentee Institute Name</th>
                <th scope="col">Meeting Link</th>
                <th scope="col" style="width: 13%">Date</th>
                <th scope="col" style="width: 12%">Time</th>
                <th scope="col" style="width: 15%">
                  Nominee from Mentor Institute
                </th>
                <!-- 7-5-24 -->
                <th scope="col" style="width: 5%">Participants Status</th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let number of numbers">
                <tr *ngIf="TMentee >= number">
                  <td>{{ number }}</td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Mentee Institute Name</mat-label>
                      <mat-icon
                        matSuffix
                        class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                        >person</mat-icon
                      >
                      <input
                        matInput
                        placeholder="Mentee Institute Name"
                        formControlName="mentee_{{ number }}_3_1"
                        required
                        readonly
                      />
                      <mat-error
                        *ngIf="
                          addActivityForm2_3.controls.mentee_1_3_1.errors
                            ?.required
                        "
                        >Online session link is required</mat-error
                      >
                    </mat-form-field>
                    <p style="font-size: 14px" class="menteenamedata">
                      {{ Mentee[number - 1].instituteName }}
                    </p>
                    <!-- <p style="font-size: 14px" class="menteeTypeData">
                      Funded By: {{ Mentee[number - 1].mentee_type }}
                    </p> -->
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Online session link</mat-label>
                      <mat-icon
                        matSuffix
                        class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                        >person</mat-icon
                      >
                      <input
                        matInput
                        placeholder="Online session link"
                        formControlName="session_{{ number }}_3_1"
                        required
                      />
                      <mat-error
                        *ngIf="
                          addActivityForm2_3.controls.session_1_3_1.errors
                            ?.required
                        "
                        >Online session link is required</mat-error
                      >
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Tentative date</mat-label>
                      <input
                        matInput
                        readonly
                        [matDatepicker]="picker3101"
                        formControlName="date_{{ number }}_3_1"
                        [min]="minDate"
                        [max]="maxDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker3101"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker3101></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-label>Tentative time</mat-label>
                    <mat-icon
                      matSuffix
                      class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                      >person</mat-icon
                    >
                    <input
                      readonly
                      [ngxTimepicker]="picker3102"
                      formControlName="time_{{ number }}_3_1"
                    />
                    <ngx-material-timepicker
                      #picker3102
                      placeholder="Session Time"
                    ></ngx-material-timepicker>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Select</mat-label>
                      <mat-select
                        autocomplete="off"
                        placeholder="Select"
                        formControlName="guest_{{ number }}_3_1"
                        required
                      >
                        <mat-option>Select</mat-option>
                        <mat-option
                          value="{{ guest.id }}"
                          *ngFor="let guest of Mentee[number - 1].menteeFaculty"
                          >{{ guest.name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>

                    <p>{{ guestNameValue3(number) }}</p>
                  </td>
                  <td>{{ quater3participationstatus(number) }}</td>
                  <td>
                    <button
                      *ngIf="dateButtonCondition1(3, 1, number)"
                      mat-raised-button
                      color="primary"
                      class="mentorButEdit"
                    >
                      Schedule
                    </button>
                    <button
                      *ngIf="dateButtonCondition1(3, 2, number)"
                      mat-raised-button
                      color="primary"
                      class="mentorButEdit"
                    >
                      Edit
                    </button>
                  </td>
                  <!-- 7-5-24 -->
                </tr>
              </ng-container>
              <!-- <tr *ngIf="TMentee>=1">
                  <td >1</td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Mentee Institute Name</mat-label>
                      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                      <input matInput placeholder="Mentee Institute Name" formControlName="mentee_1_3_1" required  readonly>
                      <mat-error *ngIf="addActivityForm2_3.controls.mentee_1_3_1.errors?.required">Online session link is required</mat-error>
                    </mat-form-field>
                    <p style="font-size: 14px;" class="menteenamedata">{{Mentee[0].instituteName}}</p>
                    <p style="font-size: 14px;" class="menteeTypeData">
                      Funded By: {{Mentee[0].mentee_type}}</p>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Online session link</mat-label>
                      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                      <input matInput placeholder="Online session link" formControlName="session_1_3_1" required>
                      <mat-error *ngIf="addActivityForm2_3.controls.session_1_3_1.errors?.required">Online session link is required</mat-error>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                        <mat-label>Tentative date</mat-label>
                        <input matInput readonly [matDatepicker]="picker3101" formControlName="date_1_3_1" [min]="minDate" [max]="maxDate" >
                        <mat-datepicker-toggle matSuffix [for]="picker3101"></mat-datepicker-toggle>
                        <mat-datepicker #picker3101></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>

                    <mat-label>Tentative time</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input readonly [ngxTimepicker]="picker3102" formControlName="time_1_3_1" >
                    <ngx-material-timepicker #picker3102 placeholder="Session Time"  ></ngx-material-timepicker>

                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Select</mat-label>
                      <mat-select autocomplete="off" placeholder="Select" formControlName="guest_1_3_1"  required>
                        <mat-option>Select</mat-option>
                        <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[0].menteeFaculty">{{guest.name}}</mat-option>
                      </mat-select>
                  </mat-form-field>

                  <p>{{guest_name_1_3_1}}</p>

                  </td>

                  <td>
                    <button *ngIf="schedules2.date_1_3_1==''" 
                    mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                    <button *ngIf="schedules2.date_1_3_1!=''" 
                    mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                  </td>
              </tr>

              <tr *ngIf="TMentee>=2">
                <td >2</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_2_3_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_3.controls.mentee_2_3_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[1].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[1].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_2_3_1">
                    <!- <mat-error *ngIf="addActivityForm2_3.controls.session_3_1.errors?.required">Online session link is required</mat-error> ->
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker3121" formControlName="date_2_3_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker3121"></mat-datepicker-toggle>
                      <mat-datepicker #picker3121></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker3122" formControlName="time_2_3_1" >
                  <ngx-material-timepicker #picker3122 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_2_3_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[1].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_3_3_1}}</p>
                </td>
                <td>
                  <button *ngIf="schedules2.date_2_3_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_2_3_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>


      <tr *ngIf="TMentee>=3">
                <td >3</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_3_3_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_3.controls.mentee_3_3_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[2].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[2].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_3_3_1">
                    <!- <mat-error *ngIf="addActivityForm2_3.controls.session_3_1.errors?.required">Online session link is required</mat-error> ->
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker3131" formControlName="date_3_3_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker3131"></mat-datepicker-toggle>
                      <mat-datepicker #picker3131></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker3132" formControlName="time_3_3_1" >
                  <ngx-material-timepicker #picker3132 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_3_3_1" >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[2].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_3_3_1}}</p>
                </td>
                <td>
                  <button *ngIf="schedules2.date_3_3_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_3_3_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>
                <tr *ngIf="TMentee>=4">
                <td >4</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_4_3_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_3.controls.mentee_4_3_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[3].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[3].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_4_3_1">
                    <!- <mat-error *ngIf="addActivityForm2_3.controls.session_4_1.errors?.required">Online session link is required</mat-error> ->
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker3141" formControlName="date_4_3_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker3141"></mat-datepicker-toggle>
                      <mat-datepicker #picker3141></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker3142" formControlName="time_4_3_1" >
                  <ngx-material-timepicker #picker3142 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_4_3_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[3].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_4_3_1}}</p>
                </td>
                <td>
                  <button *ngIf="schedules2.date_4_3_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_4_3_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>

      <tr  *ngIf="TMentee>=5">
                <td >5</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_5_3_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_3.controls.mentee_5_3_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[4].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[4].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_5_3_1">
                    <!- <mat-error *ngIf="addActivityForm2_3.controls.session_5_1.errors?.required">Online session link is required</mat-error> ->
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker3151" formControlName="date_5_3_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker3151"></mat-datepicker-toggle>
                      <mat-datepicker #picker3151></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker3152" formControlName="time_5_3_1" >
                  <ngx-material-timepicker #picker3152 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_5_3_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[4].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_5_3_1}}</p>
                </td>
                <td>
                  <button *ngIf="schedules2.date_5_3_1==''" 
                  mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                  <button *ngIf="schedules2.date_5_3_1!=''" 
                  mat-raised-button color="primary" class="mentorButEdit">Edit</button>
                </td>
            </tr>

            <tr  *ngIf="TMentee>=6">
              <td >6</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_6_3_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_3.controls.mentee_6_3_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[5].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[5].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_6_3_1">
                  <!- <mat-error *ngIf="addActivityForm2_3.controls.session_6_1.errors?.required">Online session link is required</mat-error> ->
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker3161" formControlName="date_6_3_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker3161"></mat-datepicker-toggle>
                    <mat-datepicker #picker3161></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker3162" formControlName="time_6_3_1" >
                <ngx-material-timepicker #picker3162 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_6_3_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[5].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_6_3_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_6_3_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_6_3_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

    <tr  *ngIf="TMentee>=7">
              <td >7</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_7_3_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_3.controls.mentee_7_3_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[6].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[6].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_7_3_1">
                  <!- <mat-error *ngIf="addActivityForm2_3.controls.session_7_1.errors?.required">Online session link is required</mat-error> ->
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker3171" formControlName="date_7_3_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker3171"></mat-datepicker-toggle>
                    <mat-datepicker #picker3171></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker3172" formControlName="time_7_3_1" >
                <ngx-material-timepicker #picker3172 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_7_3_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[6].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_7_3_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_7_3_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_7_3_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

    <tr  *ngIf="TMentee>=8">
              <td >8</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_8_3_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_3.controls.mentee_8_3_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[7].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[7].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_8_3_1">
                  <!- <mat-error *ngIf="addActivityForm2_3.controls.session_8_1.errors?.required">Online session link is required</mat-error> ->
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker3181" formControlName="date_8_3_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker3181"></mat-datepicker-toggle>
                    <mat-datepicker #picker3181></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker3182" formControlName="time_8_3_1" >
                <ngx-material-timepicker #picker3182 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_8_3_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[7].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_8_3_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_8_3_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_8_3_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

    <tr  *ngIf="TMentee>=9">
              <td >9</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_9_3_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_3.controls.mentee_9_3_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[8].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[8].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_9_3_1">
                  <!- <mat-error *ngIf="addActivityForm2_3.controls.session_8_1.errors?.required">Online session link is required</mat-error> ->
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker3191" formControlName="date_9_3_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker3191"></mat-datepicker-toggle>
                    <mat-datepicker #picker3191></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker3192" formControlName="time_9_3_1" >
                <ngx-material-timepicker #picker3192 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_9_3_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[8].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_9_3_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_9_3_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_9_3_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr>

    <tr  *ngIf="TMentee>=10">
              <td >10</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_10_3_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_3.controls.mentee_10_3_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[9].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[9].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_10_3_1">
                  <!- <mat-error *ngIf="addActivityForm2_3.controls.session_8_1.errors?.required">Online session link is required</mat-error> ->
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker31101" formControlName="date_10_3_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker31101"></mat-datepicker-toggle>
                    <mat-datepicker #picker31101></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker31102" formControlName="time_10_3_1" >
                <ngx-material-timepicker #picker31102 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_10_3_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[10].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_10_3_1}}</p>
              </td>
              <td>
                <button *ngIf="schedules2.date_10_3_1==''" 
                mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
                <button *ngIf="schedules2.date_10_3_1!=''" 
                mat-raised-button color="primary" class="mentorButEdit">Edit</button>
              </td>
          </tr> -->

              <!-- <tr>
              <td colspan="7" class="submitLast"><button mat-raised-button color='primary' class="btn btn-success form1But"
                [disabled]='addActivityForm2_3.invalid'>Submit</button></td>
            </tr> -->
            </tbody>
          </table>
        </form>
      </mat-tab>

      <mat-tab label="Q4/Semester 2">
        <p *ngIf="RQ_24_file == ''">
          <button
            mat-raised-button
            (click)="openReportDialog(24)"
            color="primary"
          >
            Upload Report
          </button>
        </p>
        <p *ngIf="RQ_24_file != ''">
          <button
            mat-raised-button
            (click)="openReportDialog(24)"
            color="primary"
          >
            Update Report
          </button>
        </p>
        <p *ngIf="RQ_24_file != ''">
          <a target="_blank" href="{{ baseUrl + RQ_24_file }}">View File</a>
        </p>
        <form
          [formGroup]="addActivityForm2_4"
          (submit)="onSubmit_2_4(addActivityForm2_4.value, 'Quarter 4')"
        >
          <table class="table table-hover table-responsive">
            <thead class="facultyTable">
              <tr>
                <th scope="col" style="width: 2%">Sr.No.</th>
                <th scope="col">Mentee Institute Name</th>
                <th scope="col">Meeting Link</th>
                <th scope="col" style="width: 13%">Date</th>
                <th scope="col" style="width: 12%">Time</th>
                <th scope="col" style="width: 15%">
                  Nominee from Mentor Institute
                </th>
                <th scope="col">Report</th>
                <!-- 7-5-24 -->
                <th scope="col" style="width: 5%">Participants Status</th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr *ngFor="let number of numbers; let index = index+1;">
                <td>{{ number }}</td>
              </tr> -->
              <ng-container *ngFor="let number of numbers">
                <tr *ngIf="TMentee >= number">
                  <td>{{ number }}</td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Mentee Institute Name</mat-label>
                      <mat-icon
                        matSuffix
                        class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                        >person</mat-icon
                      >
                      <input
                        matInput
                        placeholder="Mentee Institute Name"
                        formControlName="mentee_{{ number }}_4_1"
                        required
                        readonly
                      />
                      <mat-error *ngIf="giveConnect()"
                        >Online session link is required</mat-error
                      >
                    </mat-form-field>
                    <p style="font-size: 14px" class="menteenamedata">
                      {{ Mentee[number - 1].instituteName }}
                    </p>
                    <!-- <p style="font-size: 14px" class="menteeTypeData">
                      Funded By: {{ Mentee[number - 1].mentee_type }}
                    </p> -->
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Online session link</mat-label>
                      <mat-icon
                        matSuffix
                        class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                        >person</mat-icon
                      >
                      <input
                        matInput
                        placeholder="Online session link"
                        formControlName="session_{{ number }}_4_1"
                        required
                      />
                      <mat-error
                        *ngIf="
                          addActivityForm2_4.controls.session_1_4_1.errors
                            ?.required
                        "
                        >Online session link is required</mat-error
                      >
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field
                      appearance="outline"
                      class="form1Seassion rwsform1Seassion"
                    >
                      <mat-label>Tentative date</mat-label>
                      <input
                        matInput
                        readonly
                        [matDatepicker]="picker4101"
                        formControlName="date_{{ number }}_4_1"
                        [min]="minDate"
                        [max]="maxDate"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker4101"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker4101></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-label>Tentative time</mat-label>
                    <mat-icon
                      matSuffix
                      class="mat-icon-sm mb-2 mr-2 secondary-text-color"
                      >person</mat-icon
                    >
                    <input
                      readonly
                      [ngxTimepicker]="picker4102"
                      formControlName="time_{{ number }}_4_1"
                    />
                    <ngx-material-timepicker
                      #picker4102
                      placeholder="Session Time"
                    ></ngx-material-timepicker>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form23Seassion">
                      <mat-label>Select</mat-label>
                      <mat-select
                        autocomplete="off"
                        placeholder="Select"
                        formControlName="guest_{{ number }}_4_1"
                        required
                      >
                        <mat-option>Select</mat-option>
                        <mat-option
                          value="{{ guest.id }}"
                          *ngFor="let guest of Mentee[number - 1].menteeFaculty"
                          >{{ guest.name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>

                    <p>{{ guestNameValue(number) }}</p>
                  </td>
                  <td>&nbsp;</td>
                  <td>{{ quater4participationstatus(number) }}</td>
                  <td>
                    <button
                      *ngIf="dateButtonCondition1(4, 1, number)"
                      mat-raised-button
                      color="primary"
                      class="mentorButEdit"
                    >
                      Schedule
                    </button>
                    <button
                      *ngIf="dateButtonCondition1(4, 2, number)"
                      mat-raised-button
                      color="primary"
                      class="mentorButEdit"
                    >
                      Edit
                    </button>
                  </td>
                  <!-- 7-5-24 -->
                </tr>
              </ng-container>
              <!--<tr *ngIf="TMentee>=1" >
                  <td >1</td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Mentee Institute Name</mat-label>
                      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                      <input matInput placeholder="Mentee Institute Name" formControlName="mentee_1_4_1" required  readonly>
                      <mat-error *ngIf="addActivityForm2_4.controls.mentee_1_4_1.errors?.required">Online session link is required</mat-error>
                    </mat-form-field>
                    <p style="font-size: 14px;" class="menteenamedata">{{Mentee[0].instituteName}}</p>
                    <p style="font-size: 14px;" class="menteeTypeData">
                      Funded By: {{Mentee[0].mentee_type}}</p>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Online session link</mat-label>
                      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                      <input matInput placeholder="Online session link" formControlName="session_1_4_1" required>
                      <mat-error *ngIf="addActivityForm2_4.controls.session_1_4_1.errors?.required">Online session link is required</mat-error>
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                        <mat-label>Tentative date</mat-label>
                        <input matInput readonly [matDatepicker]="picker4101" formControlName="date_1_4_1" [min]="minDate" [max]="maxDate" >
                        <mat-datepicker-toggle matSuffix [for]="picker4101"></mat-datepicker-toggle>
                        <mat-datepicker #picker4101></mat-datepicker>
                    </mat-form-field>
                  </td>
                  <td>

                    <mat-label>Tentative time</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input readonly [ngxTimepicker]="picker4102" formControlName="time_1_4_1" >
                    <ngx-material-timepicker #picker4102 placeholder="Session Time"  ></ngx-material-timepicker>

                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="form23Seassion">
                      <mat-label>Select</mat-label>
                      <mat-select autocomplete="off" placeholder="Select" formControlName="guest_1_4_1"  required>
                        <mat-option>Select</mat-option>
                        <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[0].menteeFaculty">{{guest.name}}</mat-option>
                      </mat-select>
                  </mat-form-field>

                  <p>{{guest_name_1_4_1}}</p>

                  </td>
				  <td>&nbsp;</td>
          <td>
            <button *ngIf="schedules2.date_1_4_1==''" 
            mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
            <button *ngIf="schedules2.date_1_4_1!=''" 
            mat-raised-button color="primary" class="mentorButEdit">Edit</button>
          </td>
              </tr>
              
              <tr *ngIf="TMentee>=2">
                <td >2</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_2_4_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_4.controls.mentee_2_4_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[1].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[1].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_2_4_1">
                     <mat-error *ngIf="addActivityForm2_4.controls.session_4_1.errors?.required">Online session link is required</mat-error> 
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker4121" formControlName="date_2_4_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker4121"></mat-datepicker-toggle>
                      <mat-datepicker #picker4121></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker4122" formControlName="time_2_4_1" >
                  <ngx-material-timepicker #picker4122 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="form23Seassion">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_2_4_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[1].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_2_4_1}}</p>
                </td>
				<td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_2_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_2_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
            </tr>


      <tr *ngIf="TMentee>=3">
                <td >3</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_3_4_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_4.controls.mentee_3_4_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[2].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[2].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_3_4_1">
                     <mat-error *ngIf="addActivityForm2_4.controls.session_4_1.errors?.required">Online session link is required</mat-error> 
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker4131" formControlName="date_3_4_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker4131"></mat-datepicker-toggle>
                      <mat-datepicker #picker4131></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker4132" formControlName="time_3_4_1" >
                  <ngx-material-timepicker #picker4132 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="form23Seassion">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_3_4_1" >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[2].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_3_4_1}}</p>
                </td>
				<td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_3_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_3_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
            </tr>
                <tr *ngIf="TMentee>=4">
                <td >4</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_4_4_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_4.controls.mentee_4_4_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[3].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[3].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_4_4_1">
                    <mat-error *ngIf="addActivityForm2_4.controls.session_4_1.errors?.required">Online session link is required</mat-error> 
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker4141" formControlName="date_4_4_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker4141"></mat-datepicker-toggle>
                      <mat-datepicker #picker4141></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker4142" formControlName="time_4_4_1" >
                  <ngx-material-timepicker #picker4142 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="form23Seassion">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_4_4_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[3].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_4_4_1}}</p>
                </td>
				<td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_4_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_4_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
            </tr>

      <tr  *ngIf="TMentee>=5">
                <td >5</td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Mentee Institute Name</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Mentee Institute Name" formControlName="mentee_5_4_1" required  readonly>
                    <mat-error *ngIf="addActivityForm2_4.controls.mentee_5_4_1.errors?.required">Online session link is required</mat-error>
                  </mat-form-field>
                  <p style="font-size: 14px;" class="menteenamedata">{{Mentee[4].instituteName}}</p>
                  <p style="font-size: 14px;" class="menteeTypeData">
                    Funded By: {{Mentee[4].mentee_type}}</p>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Online session link</mat-label>
                    <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                    <input matInput placeholder="Online session link" formControlName="session_5_4_1">
                    <mat-error *ngIf="addActivityForm2_4.controls.session_5_1.errors?.required">Online session link is required</mat-error> 
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                      <mat-label>Tentative date</mat-label>
                      <input matInput readonly [matDatepicker]="picker4151" formControlName="date_5_4_1" [min]="minDate" [max]="maxDate" >
                      <mat-datepicker-toggle matSuffix [for]="picker4151"></mat-datepicker-toggle>
                      <mat-datepicker #picker4151></mat-datepicker>
                  </mat-form-field>
                </td>
                <td>

                  <mat-label>Tentative time</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input readonly [ngxTimepicker]="picker4152" formControlName="time_5_4_1" >
                  <ngx-material-timepicker #picker4152 placeholder="Session Time"  ></ngx-material-timepicker>

                </td>
                <td>
                  <mat-form-field appearance="outline" class="form23Seassion">
                    <mat-label>Select</mat-label>
                    <mat-select autocomplete="off" placeholder="Select" formControlName="guest_5_4_1"  >
                      <mat-option>Select</mat-option>
                      <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[4].menteeFaculty">{{guest.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <p>{{guest_name_5_4_1}}</p>
                </td>
				<td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_5_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_5_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
            </tr>


            <tr  *ngIf="TMentee>=6">
              <td >6</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_6_4_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_4.controls.mentee_6_4_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[5].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[5].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_6_4_1">
                  <mat-error *ngIf="addActivityForm2_4.controls.session_6_1.errors?.required">Online session link is required</mat-error> 
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker4161" formControlName="date_6_4_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker4161"></mat-datepicker-toggle>
                    <mat-datepicker #picker4161></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker4162" formControlName="time_6_4_1" >
                <ngx-material-timepicker #picker4162 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="form23Seassion">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_6_4_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[5].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_6_4_1}}</p>
              </td>
			  <td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_6_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_6_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
          </tr>

    <tr  *ngIf="TMentee>=7">
              <td >7</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_7_4_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_4.controls.mentee_7_4_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[6].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[6].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_7_4_1">
                  <mat-error *ngIf="addActivityForm2_4.controls.session_7_1.errors?.required">Online session link is required</mat-error> 
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker4171" formControlName="date_7_4_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker4171"></mat-datepicker-toggle>
                    <mat-datepicker #picker4171></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker4172" formControlName="time_7_4_1" >
                <ngx-material-timepicker #picker4172 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="form23Seassion">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_7_4_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[6].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_7_4_1}}</p>
              </td>
			  <td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_7_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_7_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
          </tr>

    <tr  *ngIf="TMentee>=8">
              <td >8</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_8_4_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_4.controls.mentee_8_4_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[7].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[7].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_8_4_1">
             <mat-error *ngIf="addActivityForm2_4.controls.session_8_1.errors?.required">Online session link is required</mat-error> 
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker4181" formControlName="date_8_4_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker4181"></mat-datepicker-toggle>
                    <mat-datepicker #picker4181></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker4182" formControlName="time_8_4_1" >
                <ngx-material-timepicker #picker4182 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="form23Seassion">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_8_4_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[7].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_8_4_1}}</p>
              </td>
			  <td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_8_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_8_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
          </tr>

    <tr  *ngIf="TMentee>=9">
              <td >9</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_9_4_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_4.controls.mentee_9_4_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[8].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[8].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_9_4_1">
                   <mat-error *ngIf="addActivityForm2_4.controls.session_8_1.errors?.required">Online session link is required</mat-error> 
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker4191" formControlName="date_9_4_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker4191"></mat-datepicker-toggle>
                    <mat-datepicker #picker4191></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker4192" formControlName="time_9_4_1" >
                <ngx-material-timepicker #picker4192 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="form23Seassion">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_9_4_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[8].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_9_4_1}}</p>
              </td>
			  <td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_9_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_9_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
          </tr>

    <tr  *ngIf="TMentee>=10">
              <td >10</td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Mentee Institute Name</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Mentee Institute Name" formControlName="mentee_10_4_1" required  readonly>
                  <mat-error *ngIf="addActivityForm2_4.controls.mentee_10_4_1.errors?.required">Online session link is required</mat-error>
                </mat-form-field>
                <p style="font-size: 14px;" class="menteenamedata">{{Mentee[9].instituteName}}</p>
                <p style="font-size: 14px;" class="menteeTypeData">
                  Funded By: {{Mentee[9].mentee_type}}</p>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                  <mat-label>Online session link</mat-label>
                  <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                  <input matInput placeholder="Online session link" formControlName="session_10_4_1">
                 <mat-error *ngIf="addActivityForm2_4.controls.session_8_1.errors?.required">Online session link is required</mat-error> 
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="form1Seassion rwsform1Seassion">
                    <mat-label>Tentative date</mat-label>
                    <input matInput readonly [matDatepicker]="picker41101" formControlName="date_10_4_1" [min]="minDate" [max]="maxDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker41101"></mat-datepicker-toggle>
                    <mat-datepicker #picker41101></mat-datepicker>
                </mat-form-field>
              </td>
              <td>

                <mat-label>Tentative time</mat-label>
                <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
                <input readonly [ngxTimepicker]="picker41102" formControlName="time_10_4_1" >
                <ngx-material-timepicker #picker41102 placeholder="Session Time"  ></ngx-material-timepicker>

              </td>
              <td>
                <mat-form-field appearance="outline" class="form23Seassion">
                  <mat-label>Select</mat-label>
                  <mat-select autocomplete="off" placeholder="Select" formControlName="guest_10_4_1"  >
                    <mat-option>Select</mat-option>
                    <mat-option value="{{guest.id}}" *ngFor="let guest of Mentee[10].menteeFaculty">{{guest.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <p>{{guest_name_10_4_1}}</p>
              </td>
			  <td>&nbsp;</td>
        <td>
          <button *ngIf="schedules2.date_10_4_1==''" 
          mat-raised-button color="primary" class="mentorButEdit">Schedule</button>
          <button *ngIf="schedules2.date_10_4_1!=''" 
          mat-raised-button color="primary" class="mentorButEdit">Edit</button>
        </td>
          </tr> */


             <tr>
              <td colspan="7" class="submitLast"><button mat-raised-button color='primary' class="btn btn-success form1But"
                [disabled]='addActivityForm2_4.invalid'>Submit</button></td>
            </tr> -->
            </tbody>
          </table>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
