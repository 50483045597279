<h1 mat-dialog-title class="text-center instStuDetailsTitle">Add Meeting Details</h1>
<div mat-dialog-content>
  <div class="container">
    <form [formGroup]="addActivityForm" (submit)="onSubmit(addActivityForm.value)">
      <div class="row">
        <div fxFlex="50" fxFlex.lt-sm="100">

        <mat-form-field appearance="outline" class="col-12">
            <mat-label>Session Date</mat-label>
            <input matInput readonly [matDatepicker]="picker1" formControlName="session_date" >
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>



        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Speaker Name</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input matInput placeholder="Speaker Name" formControlName="speaker_name" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="addActivityForm.controls.speaker_name.errors?.required">Speaker Name is required</mat-error>
          </mat-form-field>
        </div>

      
      </div>





<div class="row">

    <div fxFlex="50" fxFlex.lt-sm="100">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Designation</mat-label>
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <input matInput placeholder="Designation" formControlName="designation" required (keypress)=isCharKey($event)>
          <mat-error *ngIf="addActivityForm.controls.designation.errors?.required">Designation is required</mat-error>
        </mat-form-field>
      </div>
      
      <div fxFlex="50" fxFlex.lt-sm="100">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Title</mat-label>
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <input matInput placeholder="Title" formControlName="title" required (keypress)=isCharKey($event)>
          <mat-error *ngIf="addActivityForm.controls.title.errors?.required">Title is required</mat-error>
        </mat-form-field>
      </div>


</div>


<div class="row">
    
<div fxFlex="50" fxFlex.lt-sm="100">
    <mat-form-field appearance="outline" class="col-12">
      <mat-label>Meeting ID</mat-label>
      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <input matInput placeholder="Meeting ID" formControlName="meeting_id" required>
      <mat-error *ngIf="addActivityForm.controls.meeting_id.errors?.required">Meeting ID is required</mat-error>
    </mat-form-field>
  </div>


  <div fxFlex="50" fxFlex.lt-sm="100">


    <mat-form-field appearance="outline" class="col-12">
      <mat-label>Session</mat-label>
      <mat-select placeholder="Session" formControlName="session" required   >
        <mat-option value="1">Session 1</mat-option>
        <mat-option value="2">Session 2</mat-option>
      </mat-select>
      <mat-error *ngIf="addActivityForm.controls.session.errors?.required">Session is required
      </mat-error>

    </mat-form-field>

  </div>


</div>
<div class="row">
    <div  fxFlex="50" fxFlex.lt-sm="100">
        <mat-label>Session Time</mat-label>
        <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>

    <input readonly [ngxTimepicker]="picker" formControlName="session_time" >
<ngx-material-timepicker #picker placeholder="Session Time"  ></ngx-material-timepicker>
</div>
</div>


<!-- <div *ngIf="!reportStatus"> -->
    <div class="row">
        <div class="col-lg-8 col-md-10">
            <label>Upload Copy:</label>
            <mat-error>PDF(max 2MB)</mat-error>
        </div>

        <div class="col-lg-4 col-md-2 float-right">
            <label class="custom-file-label float-right" for="customFile" *ngIf="!report">Choose
                file</label>
            <label class="custom-file-label float-right" for="customFile"
                *ngIf="report">{{report[0]['name']}}</label>
            <input #reportRef type="file" accept="application/pdf" name="report"
                (change)="( report = reportRef.files )" class="custom-file-input float-right" required>
        </div>

    </div>
<!-- </div> -->

<!-- <div *ngIf="reportStatus">
    <div class="row">
        <a href="{{baseImageUrl + report}}" target="_blank">
            <button type="button" class="btn btn-danger mt-2 mb-2">Download Report</button>
            <button type="button" mat-raised-button color="warn" class="ml-3"
                (click)="reportStatus = !reportStatus">Upload
                Report</button>
        </a>
    </div>
</div> -->


      <!-- <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btn btn-danger instituteBtnClose">Close</button> -->
      <!-- <button *ngIf="!edit" mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='addActivityForm.invalid'>Submit</button> -->
      <button *ngIf="edit" mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='addActivityForm.invalid'>Submit</button>

    </form>
  </div>

</div>
