import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../../app.component';
import { InstituteAnnouncementService } from 'src/app/Services/institute-announcement.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'council-detail',
  templateUrl: './council-detail.component.html',
  styleUrls: ['./council-detail.component.css']
})
export class CouncilDetailComponent implements OnInit {
  public innovation: FormGroup;
  public innovationCourse: FormGroup;
  iicId:any;
  name:any;
  convener_name: any;
  designation: any;
  convener_designation: any;
  email: any;
  convener_email: any;
  theme: any;
  instituteName: any;
  instituteCity: any;
  instituteState: any;
  instituteZone: any;
  nominationArray: any;
  nomination2: any;
  baseImageUrl = myGlobals.baseImageUrlIA;
  searched: string;
  search_keyword: string;
  constructor(private instituteannouncementservice: InstituteAnnouncementService, private route: ActivatedRoute, private fb: FormBuilder,) {
    this.route.params.subscribe((params) => {
      this.iicId = params.iicId;
    });
    this.nominationArray = [];
    this.nomination2 = [];
  }

  ngOnInit(): void {
    this.iicId;
    this.getPresidentDetails();
    this.getConvenerDetails();
    this.getInstitutePerDetail();
    this.getIicCouncilDetails();

  }
  getInstitutePerDetail(){
    this.instituteannouncementservice.getInstitutePerDetail(this.iicId).subscribe(
      (data) => this.instituteData(data)
    );
  }

  getPresidentDetails(){
    this.instituteannouncementservice.getPresidentDetails(this.iicId).subscribe(
      (data) => this.handleResponse(data)
    );
  }

  getConvenerDetails(){
    this.instituteannouncementservice.getConvenerDetails(this.iicId).subscribe(
      (data) => this.convenerData(data)
    );
  }

  getIicCouncilDetails(){
    this.instituteannouncementservice.getIicCouncilDetails(this.iicId).subscribe(
      (data) => this.handleResponse1(data)
    );

  }

  handleResponse(data) {
    this.name=data['name'];
    this.designation = data['designation'];
    this.email = data['email'];
  }

  handleResponse1(data) {
    this.theme = data['data']['dataImpact'];
    // for (let i = 0; i < data['data']['dataImpact'].length; i++) {
    //   this.theme.push(data['dataImpact']['data'][i]);
    // }
  }

  convenerData(data) {
    this.convener_name=data['name'];
    this.convener_designation = data['designation'];
    this.convener_email = data['email'];
  }

  instituteData(data) {
    this.iicId=data['iicId'];
    this.instituteName = data['instituteName'];
    this.instituteCity = data['instituteCity'];
    this.instituteState = data['instituteState'];
    this.instituteZone = data['regional_zone'];

  }

}
