<div class="text-center">
  <mat-error>Innovation Contest activities are added under submitted It will be added in Annual Score</mat-error>
</div>

<div class="text-center">
  <mat-error>In case of any query, please contact zonal coordinator</mat-error>
</div>

<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Activity Type</th>
      <th scope="col">Submitted</th>
      <th scope="col">Approved</th>
      <th scope="col">Disapproved</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of activityCount">
      <th scope="row">{{item?.type}}</th>
      <td>{{item?.Submitted}}</td>
      <td>{{item?.Approved}}</td>
      <td>{{item?.Disapproved}}</td>
    </tr>
  </tbody>
</table>


<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Idea Count</th>
      <th scope="col">PoC Count</th>
      <th scope="col">Prototype Count</th>
      <th scope="col">Nominated Prototype</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">{{idea}}</th>
      <td>{{poc}}</td>
      <td>{{prototype}}</td>
      <td>{{nominated}}</td>
    </tr>
  </tbody>
</table>

<div>
  <button mat-raised-button color="warn" class="btn btn-danger instituteBtnClose float-right" (click)="onNoClick()">Close</button>
</div>
