import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ActivityProposalDialogComponent } from '../activity-proposal-dialog/activity-proposal-dialog.component';
import * as myGlobals from '../../../../../../../../app.component';

@Component({
  selector: 'app-activity-form1',
  templateUrl: './activity-form1.component.html',
  styleUrls: ['./activity-form1.component.css']
})
export class ActivityForm1Component implements OnInit {

  edit: boolean;
  editData: any;
  id: any;
  report: FileList | null;
  public addActivityForm1: FormGroup;
  public addActivityForm2: FormGroup;
  public addActivityForm5: FormGroup;
  date1:any;
  file_upload: any;
  reportStatus: boolean;
  minDate = "2023-09-01";
  maxDate = "2024-12-31";

  baseImageUrl = myGlobals.baseImageUrl2;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ActivityProposalDialogComponent>,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public activity: InstituteActivityService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.edit = false;
      if (this.data['activity_id']!= undefined || this.data['activity_id']!= null) {
        this.edit = true;

        this.id = this.data['activity_id'];
      }
      else {
        this.edit = false;
      }
     }

  ngOnInit(): void {
     this.addActivityForm1 = this.fb.group({
      'session_date': [null,Validators.required],
      'session_time': [null,Validators.required],
      'quarter': [null,Validators.required],
      'speaker_name': [null,Validators.required],
      'designation':[null,Validators.required],
      'meeting_id':[null,Validators.required],
      'session':[null,Validators.required]
     });

     if(this.id!='undefined'){
     this.activity.getSchedule(this.id).subscribe(data=>this.setData(data));}

  }
  setData(data){
      this.editData=data;
      this.addActivityForm1.controls['session_date'].setValue(new Date(data['Activity']['scheduled_date']));
      this.addActivityForm1.controls['session_time'].setValue(data['Activity']['scheduled_time']);
      this.addActivityForm1.controls['speaker_name'].setValue(data['Activity']['speaker_name']);
      this.addActivityForm1.controls['designation'].setValue(data['Activity']['speaker_designation']);
      this.addActivityForm1.controls['meeting_id'].setValue(data['Activity']['meeting_id']);
      this.addActivityForm1.controls['session'].setValue(data['Activity']['activity']);
      this.addActivityForm1.controls['quarter'].setValue(data['Activity']['quarter']);
      this.file_upload=data['Activity']['file_upload'];
    if(this.file_upload!=null){
      this.reportStatus=true;
    }
 }

  onSubmit(data){

    var cvFile =
      this.report && this.report.length ? this.report[0] : null;

    if (cvFile!= undefined || cvFile!= null) {

    var formData = new FormData();

    formData.append('file', cvFile);
    formData.append('activityid', this.id);
    // if()
    formData.append('session_date',this.changeDate(this.addActivityForm1.get('session_date').value));
    formData.append('speaker_name',this.addActivityForm1.get('speaker_name').value);
    formData.append('session_time',this.addActivityForm1.get('session_time').value);
    formData.append('designation',this.addActivityForm1.get('designation').value);
    formData.append('meeting_id',this.addActivityForm1.get('meeting_id').value);
    formData.append('session',this.addActivityForm1.get('session').value);
    formData.append('quarter',this.addActivityForm1.get('quarter').value);
    formData.append('id','1');

     if (!this.edit) {
      this.activity
        .schedule(formData)
        .subscribe((data) => this.handleResponse(data));}

    else {

    formData.append('file_upload_hidden',this.editData['Activity']['file_upload']);
      this.activity
        .editSchedule(formData)
        .subscribe((data) => this.handleResponse(data));
    }
  }
  else
  {
    this.toastr.error("Please upload the Activity Schedule/Plan.");
  }


  }


  handleResponse(data){
    if(data['message']=="Success"){
      this.toastr.success("Successfully Submitted");
      this.dialogRef.close();
    }
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }
}
