<div class="container">
    <mat-dialog-content class="mat-typography instiFactDialoge">
      
        <div class="row">
  
          <div fxFlex="50" class="m-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Total Assements Done</mat-label>
              <label class="custom-file-label float-right" for="customFile"
              *ngIf="data">{{data}}</label>
              <label class="custom-file-label float-right" for="customFile"
              *ngIf="!data">No Data Found</label>
            </mat-form-field>
          </div>
  
         
          
        </div>
  
        <div mat-dialog-actions>
  
          <button type="button" mat-raised-button color='warn' (click)='onNoClick()' style="margin-left: 280px;
              " class="btn btn-danger instituteBtnClose">Close</button>
  
          
  
  
        </div>
      
    </mat-dialog-content>
  </div>