<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">IIC Rating 2019-20</h1>
		</div>
	</div>
</div>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">At a Glance</li>
  </ol>
</nav>

<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>List of Institutions Innovation Council(IIC) with STAR Ratings for the IIC Calendar Year 2019-20</h2>
				</div>
			</div>
		</div>
	</div>
</div>


<div id="content" class="content container">

<table  style="margin-left:auto;margin-right:auto;" class="table-hover">
	<tr>
		<th colspan='2' style="text-align:center;border-right: 1px solid #ffffff;text-align:center;">
          <img src="assets/img/MoE-Logo.png" style="width:190px;">
          </th>
		  <th colspan='3' style="text-align:center;border-left: 1px solid #ffffff;border-right: 1px solid #ffffff;text-align:center;">
            <img src="assets/assets/images/iiclogo.png" style="width:170px;">
          </th>
		  <th colspan='3' style="text-align:center; border-left: 1px solid #ffffff;text-align:center;">
          <img src="assets/img/aicte.png"  style="width:80px;margin-left: 30px;">
          </th>
	</tr>
	<tr>
		<th colspan='7' style="text-align:center;">
            List of Institutions Innovation Council (IIC) with STAR Ratings for the IIC Calendar Year 2019-20
          </th>
	</tr>
	<tr>
		<th style="text-align:center;">
            S.No.
          </th>
		  <th style="text-align:center;">
            IIC ID
          </th>
		  <th style="text-align:center;" width="40%">
            Institute Name
          </th>

		  <th style="text-align:center;" width="10%">
            City
          </th>
		  <th style="text-align:center;">
            State
          </th>
		  <th style="text-align:center;">
            Zone
          </th>
		  <th style="text-align:center;">
            Star Rating
          </th>
	</tr>
	<tr *ngFor="let items of zonalDetails let index=index">
		<td>
            {{index+1}}
          </td>
		  <td>
            {{items?.userId}}
          </td>
		  <td>
            {{items?.instituteName}}
          </td>

		  <td>
            {{items?.instituteCity}}
          </td>
		  <td>
            {{items?.stateName}}
          </td>
		  <td>
            {{items?.regional_zone}}
          </td>
		  <td>
		  <ul *ngIf="items?.final_star == 5" class="list-inline rating-list"  style="display: inline-block">
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
            </ul>
            <ul *ngIf="items?.final_star < 5 && items?.final_star > 4" class="list-inline rating-list"  style="display: inline-block">

              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/half-star.png" >
            </ul>
            <ul *ngIf="items?.final_star == 4" class="list-inline rating-list"  style="display: inline-block">

              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="items?.final_star < 4 && items?.final_star > 3" class="list-inline rating-list"  style="display: inline-block">


              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/half-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="items?.final_star == 3" class="list-inline rating-list"  style="display: inline-block">

              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="items?.final_star < 3 && items?.final_star > 2" class="list-inline rating-list"  style="display: inline-block">


              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/half-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="items?.final_star == 2" class="list-inline rating-list"  style="display: inline-block">

              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="items?.final_star < 2 && items?.final_star > 1" class="list-inline rating-list"  style="display: inline-block">


              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/half-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="items?.final_star == 1" class="list-inline rating-list"  style="display: inline-block">

              <img src="https://iic.mic.gov.in/uploads/institutes/stars/full-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="items?.final_star < 1 && items?.final_star > 0" class="list-inline rating-list"  style="display: inline-block">

              <img src="https://iic.mic.gov.in/uploads/institutes/stars/half-star.png" >
			  <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="items?.final_star == 0" class="list-inline rating-list"  style="display: inline-block">
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
              <img src="https://iic.mic.gov.in/uploads/institutes/stars/empty-star.png" >
            </ul>
          </td>
	</tr>



</table>
<div class="row">
		<div class="col-md-12">
			<mat-paginator #paginator
				showFirstLastButtons
				(page)="nextPage($event)"
				[length]="total_pages"
				[pageIndex]="frontCurrentPageNo"
				[pageSizeOptions]="pageSizeOptions" class="NewMatPaginationPreIncub">
			</mat-paginator>
		</div>
	</div>
  </div>



<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
