import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteSuggestionService } from 'src/app/Services/institute-suggestion.service';
import { InstituteSuggestionDialogComponent } from '../../institute-suggestion/institute-suggestion-dialog/institute-suggestion-dialog.component';

@Component({
  selector: 'app-suggest-dialog',
  templateUrl: './suggest-dialog.component.html',
  styleUrls: ['./suggest-dialog.component.css']
})
export class SuggestDialogComponent implements OnInit {

  subjec: any;
  message: any;
  attach: FileList | null;

  public suggestionForm: FormGroup;
  types: any[];
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<InstituteSuggestionDialogComponent>,
    private toastr: ToastrService,
    private suggestionService: InstituteSuggestionService,
    @Inject(MAT_DIALOG_DATA) data) {
    // this.external_email=data.external_email;
    this.getsuggestionReason();

  }

  ngOnInit(): void {
    this.suggestionForm = this.fb.group({
      subject: [null, Validators.compose([Validators.required])],
      message: [null, Validators.compose([Validators.required])],
      type: [null, Validators.compose([Validators.required])],
    });

  }

  getsuggestionReason(){

    this.suggestionService.suggestionReason().subscribe(
      data => this.setData(data)
    );
  }
  setData(data){
    this.types=[];
    for(let i=0;i<data.length; i++){
      this.types.push(data[i]['name'])
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) {

    var suggestionFile =
      this.attach && this.attach.length ? this.attach[0] : null;

    var formData = new FormData();

    formData.append('suggestionFile', suggestionFile);
    formData.append('subject',this.suggestionForm.get('subject').value);
    formData.append('message',this.suggestionForm.get('message').value);
    formData.append('type', this.suggestionForm.get('type').value);

       this.suggestionService.submitSuggestion(formData).subscribe(
      data => this.handleResponse(data)
    );

  }

  handleResponse(data) {
    if (data['status'] == "1") {
      this.dialogRef.close();
      this.toastr.success(data['msg']);
    } else {
      if (data['response_code'] == '0') {
        this.toastr.error(data['response_message']);
      } else {
        this.toastr.error('Some error occured.');
      }
    }
  }
}
