<div class="container">
  <mat-dialog-content class="mat-typography instiFactDialoge">
  <h3>IA External Activity Details</h3>
  <table class="table">
    <tbody>
      <tr>
        <td colspan="2"><h4>Program Information</h4></td>
      </tr>
      <tr>
        <td  colspan="2"><strong>Title</strong><br/>
          {{showData?.title}}</td>
      </tr>
      <tr>
        <td><strong>Academic Year</strong><br/>
          {{showData?.academic_year}}</td>
        <td><strong>Theme</strong><br/>
          {{showData?.theme}}</td>
      </tr>
      <tr>
        <td  colspan="2"><strong>Venue</strong><br/>
          {{showData?.venue_name}}</td>
      </tr>
      <tr>
        <td><strong>Venue City</strong><br/>
          {{showData?.venue_city}}</td>
        <td><strong>Venue State</strong><br/>
          {{showData?.venue_state}}</td>
      </tr>
      <tr>
        <td><strong>Date</strong><br/>
          {{showData?.workshop_date}}</td>
        <td><strong>Duration</strong><br/>
          {{showData?.duration}}</td>
      </tr>
      <tr>
        <td><strong>Student Participated</strong><br/>
          {{showData?.student_participation}}</td>
        <td><strong>Faculty Participated</strong><br/>
          {{showData?.faculty_participation}}</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Remark</strong><br/>
          {{showData?.remark}}</td>
      </tr>
      <tr>
        <td colspan="2"><h4>Attachments</h4></td>
      </tr>
      <tr>
        <td colspan="2"><strong>Video URL</strong><br/>
          {{showData?.video_url}}</td>
      </tr>
      <tr>
        <td><a target="_blank" href="https://api.mic.gov.in/uploads/ia/{{showData?.photo1}}"><button class="btn btn-primary mb-2" type="button">Photo 1</button></a></td>
        <td><a target="_blank" href="https://api.mic.gov.in/uploads/ia/{{showData?.photo2}}"><button class="btn btn-primary mb-2" type="button">Photo 2</button></a></td>
      </tr>
      <tr>
        <td><a target="_blank" href="https://api.mic.gov.in/uploads/ia/{{showData?.report}}"><button class="btn btn-primary mb-2" type="button">Report</button></a></td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>

  <div class="iicformsection">
    <form [formGroup]="facultyForm" (ngSubmit)="onSubmit(facultyForm.value)">

      <div class="row">

        <div class="col-sm-6">
          <mat-form-field class="gender example-full-width" appearance="outline">
            <mat-label>Verification Status</mat-label>
            <mat-select placeholder="Verification Status" formControlName="verify_status" required>
              <mat-option value="Approved">Approved</mat-option>
              <mat-option value="Disapproved">Disapproved</mat-option>
            </mat-select>
            <mat-error *ngIf="facultyForm.controls['verify_status'].hasError('required')">Verification Status is required</mat-error>

          </mat-form-field>
        </div>

        <div class="col-sm-3">
          <button mat-raised-button color='primary' class="btn btn-success float-right"
            [disabled]='facultyForm.invalid'>Submit</button>
        </div>

        <div class="col-sm-3">
          <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btn btn-danger instituteBtnClose">Close</button>
        </div>

        </div>

    </form>
  </div>

  </mat-dialog-content>
  </div>
