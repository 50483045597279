import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-repository',
  templateUrl: './repository.component.html',
  styleUrls: ['./repository.component.css']
})
export class RepositoryComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicrepository:any;
  ForWhom :any;

  constructor(private fb: FormBuilder, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.requireField=0;

    this.instituteActivityService.getIicRepositoryDetails('all').subscribe(
      (data) => this.handleResponse(data)
    );

    this.ForWhom=localStorage.getItem('ForWhom');
  }

  handleResponse(data) {

    this.iicrepository = data['data'];

  }

  onClickEvent(data,type){

    if(type=="Edit")
    {
      window.location.href="/institute/repository-add/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.deleteIicRepositoryDetails(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }

  }

  handleResponse1(data)
  {

  }


}
