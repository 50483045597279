import { Component, OnInit } from '@angular/core';
import baguetteBox from 'baguettebox.js';
import * as myGlobals from 'src/app/app.component';
@Component({
  selector: 'app-ahmedabad-photo',
  templateUrl: './ahmedabad-photo.component.html',
  styleUrls: ['./ahmedabad-photo.component.css']
})
export class AhmedabadPhotoComponent implements OnInit {
  public baseurl = myGlobals.baseImageUrl1;
  constructor() { }

  ngOnInit(): void {
    baguetteBox.run('.gallery', {
      captions: true, // display image captions.
      buttons: 'auto', // arrows navigation
      fullScreen: false,
      noScrollbars: false,
      bodyClass: 'baguetteBox-open',
      titleTag: false,
      async: false,
      preload: 2,
      animation: 'fadeIn', // fadeIn or slideIn
      verlayBackgroundColor: 'rgba(0,0,0,.8)'
    });
  }

}
