<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">PoC Contest 2019</h1>  -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">PoC Contest 2019</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>IIC Innovation Contest</a></li>
    <li class="breadcrumb-item active" aria-current="page">PoC Contest 2019</li>
  </ul>
</nav>


<div class="cbp-row wh-content PoCcontest2019">
			<div class="cbp-container container wh-padding">
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>About</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3 class="pocResMob">About National Innovation Contest:</h3>
							<p>Institution’s Innovation Council (IIC) of MHRD’s Innovation Cell and AICTE has designed an annual innovation contest to identify the creative minds from IIC-Institutes and engage them in building innovations and nurturing into start-ups through series of mentoring, boot camps and international acceleration programs. Further, ensuring survival by providing funding support and linkage with technology business incubation units and access to investment opportunity.</p>
							
							<h3 class="pocResMob">Role of IIC-Institutes in the National Innovation Contest: </h3>
							<p>IIC-Institutes are expecting to take lead in identifying and scouting innovative Ideas, Proof of Concepts (PoC) and Prototypes/Innovations minds from students and ensure these students will go through process of learning and validation of ideas and prototypes and finally IIC-Institutes need to nominate the best innovations to IIC-MIC through IIC Portal by 30th June 2020.</p>
							
							<p>IIC-Institutes need to disseminate and encourage more and more student innovators/entrepreneurs to submit their Ideas, Proof of Concepts (PoCs), Prototypes/Innovations to IIC-Institute to take part in this contest. Further IIC-institutes are requested to evaluate as per the evaluation parameter and score guideline provided to IIC-Institutes.</p>
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>POC Contest 2019</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3 class="pocResMob">BRIEF ABOUT IIC NATIONAL INNOVATION CONTEST 2019 (POC CONTEST 2019)</h3>
							<p>Last year (during IIC1.0) we have received more than 1650 numbers of PoC nominations from 700 IICs and more than 2500 enthusiastic student innovators/entrepreneurs took part in regional mentoring sessions were organised in 14 different locations across India during the period from July-Aug 2019.</p>
							
							<p>120 teams were shortlisted and gone through two-day National level Bootcamp at AICTE HQ in new Delhi and participated in National Exhibition, which was graced by the Hon’ble HRD Minister and 24 teams declared as finalist. Out of which, 17 teams were cleared the funding round and eligible for receiving incubation support. MIC-AICTE has provided seed grant of Rs. 4 to 5 lakhs per team to pursue innovation and incubation support for the FY 2021-22. From this pool of finalist, 5 student innovators got opportunity to receive training at CCEI, Daegu, South Korea and 5 female innovators took part in Canada India Acceleration Program at Carleton University, Ottawa, Canada.</p>													
						</div>
					</div>
				</div>
				
				
				<!-- <div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Success Stories of POC Contest</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3 class="pocResMob">SELECTED TEAM OF POC CONTEST 2019</h3>
							<h4><a href="assets/zone/selected_poc_list_2018-19.pdf" target="_blank">LIST OF TOP INNOVATIVE POCS FROM NATIONAL BOOTCAMP AND EXHIBITION 2019</a></h4>
							
							<table class="table table-bordered cd-table-striped table-responsive">
					  <thead class="thead-light">
						<tr>
						  <th scope="col" class="col1">S No.</th>
						  <th scope="col" class="col2">Team Name</th>
						  <th scope="col" class="col3">Project/PoC Title</th>
						  <th scope="col" class="col4">Institute Name</th>
						  <th scope="col" class="col5">State</th>
						  <th scope="col" class="col6">Amount Sanctioned(In Indian Rupees)</th>
						</tr>
					  </thead>
					  <tbody>
						<tr>
						  <th scope="row">1</th>
						  <td>SUVANA</td>
						  <td>Low cost residential flow meter</td>
						  <td>Muzaffarpur Institute of Technology</td>
						  <td>Bihar</td>
						  <td>4,50,000</td>
						</tr>
						<tr>
						  <th scope="row">2</th>
						  <td>Motion Sensing Glove</td>
						  <td>Motion Sensing Glove</td>
						  <td>UIET, Panjab University</td>
						  <td>Chandigarh </td>
						  <td>4,00,000</td>
						</tr>
						<tr>
						  <th scope="row">3</th>
						  <td>Cytokine</td>
						  <td>Bhangarya (Eclipta alba)-A new traditional plant to manage bleeding: From land to FIRST</td>
						  <td>L. M. College of Pharmacy</td>
						  <td>Gujarat</td>
						  <td>4,50,000</td>
						</tr>
						<tr>
						  <th scope="row">4</th>
						  <td>Mobile STP</td>
						  <td>Near Zero cost polluted water</td>
						  <td>CMR Institute of Technology </td>
						  <td>Karnataka</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">5</th>
						  <td>SAM Solutions</td>
						  <td>Automatic Puttu Making Machine</td>
						  <td>Vimal Jyothi Engineering College</td>
						  <td>Kerala</td>
						  <td>4,00,000</td>
						</tr>
						<tr>
						  <th scope="row">6</th>
						  <td>JAL</td>
						  <td>Desalination system</td>
						  <td>NIT Calicut</td>
						  <td>Kerala</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">7</th>
						  <td>Aztecs</td>
						  <td>Treatment of Seafood processing effluent using Stringed Bed suspended Bioreactor</td>
						  <td>KMEA ENGINEERING COLLEGE EDATHALA</td>
						  <td>Kerala</td>
						  <td>2,50,000</td>
						</tr>
						<tr>
						  <th scope="row">8</th>
						  <td>Novorbis</td>
						  <td>First Filter-less Air purification Unit</td>
						  <td>Acropolis Institute of Technologyand Research</td>
						  <td>Madhya Padesh </td>
						  <td>2,89,000</td>
						</tr>
						<tr>
						  <th scope="row">9</th>
						  <td>PREG-Ѷ-DET</td>
						  <td>A Kit for Visual Early Pregnancy Detection in Cattle from Urine</td>
						  <td>Institute of Chemical Technology</td>
						  <td>Maharashtra</td>
						  <td>5,00,000</td>
						</tr>
						<tr>
						  <th scope="row">10</th>
						  <td>RakDaa</td>
						  <td>Nano_Gr_Det Point of care blood group detection kit</td>
						  <td>Institute of Chemical Technology,Mumbai</td>
						  <td>Maharashtra</td>
						  <td>3,42,000</td>
						</tr>
						<tr>
						  <th scope="row">11</th>
						  <td>We-Wake IndiGreen</td>
						  <td>Bio-based Biodegradable Plastic Process and Product Development</td>
						  <td>Savitribai Phule Pune University</td>
						  <td>Maharashtra</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">12</th>
						  <td>JAPNAS</td>
						  <td>Mood analysis using facial expressions </td>
						  <td>Maharashtra Institute of Technology, Pune</td>
						  <td>Maharashtra</td>
						  <td>2,53,000</td>
						</tr>
						<tr>
						  <th scope="row">13</th>
						  <td>Fenice Tech</td>
						  <td>CRAB - clog removing autonomous bot </td>
						  <td>Dr. M.G.R. Innovation Institute Council</td>
						  <td>Tamil Nadu</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">14</th>
						  <td>Team Tested OK</td>
						  <td>Machine Vision system for Quality Control in Automobile Component Manufacturing</td>
						  <td>Kumaraguru College of Technology</td>
						  <td>Tamil Nadu</td>
						  <td>2,59,000</td>
						</tr>
						<tr>
						  <th scope="row">15</th>
						  <td>SmartMed</td>
						  <td>Optimus - Smart Pill Companion - vending machine with finger print</td>
						  <td>Sreenidhi Institute of Science and Technology</td>
						  <td>Telangana</td>
						  <td>3,00,000</td>
						</tr>
						<tr>
						  <th scope="row">16</th>
						  <td>Cipher generator</td>
						  <td>Cipher generator</td>
						  <td>I.T.S Engineering college</td>
						  <td>Uttar Pradesh</td>
						  <td>4,00,000</td>
						</tr>
						<tr>
						  <th scope="row">17</th>
						  <td>IntBotix</td>
						  <td>Recruitment Bot</td>
						  <td>IIIT-Allahabad </td>
						  <td>Uttar Pradesh</td>
						  <td>2,90,000</td>
						</tr>
					  </tbody>
					</table>
						</div>
					</div>
				</div> -->
				
				
				
				<div class="vc_row-full-width vc_clearfix"></div>
				


			</div>
		</div>
		
<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>