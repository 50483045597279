  <mat-dialog-content class="mat-typography">

    <div class="container">

        <div class="row perInformation2">
            <div class="col-md-12">
                <h2>Send Invitation to Institute</h2>
            </div>
        </div>

        <form class="form-horizontal" [formGroup]="signupForm" (submit)="onSubmit(signupForm.value)"
  style="color: black !important;">
  <div [hidden]="!error">
    {{error}}
  </div>

  <div class="form-group">
    <h4>Institute Details</h4>
    <p>{{this.datashow?.name}}</p>
    <p>{{this.datashow?.aicte_code}}</p>
    <p>{{this.datashow?.address_line1}} {{this.datashow?.address_line2}}</p>
    <p>{{this.datashow?.city}}</p>
    <p>{{this.datashow?.state}}</p>
  </div>


  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <mat-label>Name of the Head of Institute</mat-label>
      <input matInput placeholder="Name of the Head of Institute" formControlName="name" required>
      <mat-error *ngIf="signupForm.controls.name.errors?.required">Name of the Head of Institute is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">mail</mat-icon>
      <mat-label>Email Id of the Head of Institute</mat-label>
      <input matInput placeholder="Email Id of the Head of Institute" formControlName="email" required
        (keypress)="isSpace($event)">
      <mat-error *ngIf="signupForm.controls.email.errors?.required">Email Id of the Head of Institute is required
      </mat-error>
      <mat-error *ngIf="signupForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stay_current_portrait</mat-icon>
      <mat-label>Mobile Number of the Head of Institute</mat-label>
      <input matInput placeholder="Mobile Number of the Head of Institute" formControlName="mobile_number"
        required minlength="10" maxlength="10" (keypress)="isNumKey($event)">
      <mat-error *ngIf="signupForm.controls.mobile_number.errors?.required">Mobile Number of the Head of Institute
        is
        required
      </mat-error>
      <mat-error *ngIf="signupForm.controls.mobile_number.errors?.minlength">Mobile Number should be of 10 digits
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-label>Does institute run any AICTE Approved Course?</mat-label>
      <mat-select formControlName="aicte_approved_program" required>
        <mat-option value="Yes">Yes</mat-option>
        <mat-option value="No">No</mat-option>
      </mat-select>
      <mat-error *ngIf="signupForm.controls.aicte_approved_program.errors?.required">AICTE
        Approved Programme is
        required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group" *ngIf="uploadDiv">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <mat-label>Institute Permanent ID</mat-label>
      <input matInput placeholder="Institute Permanent ID" formControlName="pid">
      <mat-error *ngIf="signupForm.controls.pid.errors?.required">Institute Permanent ID is required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- <div class="form-group">
    <div>
      <mat-form-field class="w-100 ml-3" appearance="outline">
        <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
        <mat-label>AISHE Code of Affiliated Institute</mat-label>
        <input matInput placeholder="AISHE Code of Affiliated Institute" formControlName="aishe_code" #aishe required>
        <mat-error *ngIf="signupForm.controls.aishe_code.errors?.required">AISHE Code of Affiliated Institute is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">account_balance</mat-icon>
      <mat-label>Name of Institute</mat-label>
      <input matInput placeholder="Name of Institute" formControlName="institute_name" [value]="institute_name"
        required>
      <mat-error *ngIf="signupForm.controls.institute_name.errors?.required">Name of Institute is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">confirmation_number</mat-icon>
      <mat-label>City of Institute</mat-label>
      <input matInput placeholder="City of Institute" formControlName="institute_city" [value]="institute_city"
        required (keypress)="isCharKey($event)">
      <mat-error *ngIf="signupForm.controls.institute_city.errors?.required">City of Institute is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">location_on</mat-icon>
      <mat-label>State of Institute</mat-label>
      <input matInput placeholder="State of Institute" formControlName="institute_state" [value]="institute_state"
        required (keypress)="isCharKey($event)">
      <mat-error *ngIf="signupForm.controls.institute_state.errors?.required">State of Institute is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="w-100 ml-3" appearance="outline">
      <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">article</mat-icon>
      <mat-label>Enter Address</mat-label>
      <input matInput placeholder="Enter Address" formControlName="institute_address" [value]="institute_address"
        required>
      <mat-error *ngIf="signupForm.controls.institute_address.errors?.required">Enter Address is required
      </mat-error>
    </mat-form-field>
  </div> -->

  <div class="col-md-6">
    <button class="login100-form-btnSignUp" mat-raised-button type="submit"
      [disabled]="signupForm.invalid">Submit</button>
  </div>

</form>

    </div>
    <!-- </mat-card> -->
</mat-dialog-content>
<!-- </section> -->
