import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-institute-innovation-add',
  templateUrl: './institute-innovation-add.component.html',
  styleUrls: ['./institute-innovation-add.component.css']
})
export class InstituteInnovationAddComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  show_field_1:any;
  show_field_2:any;
  show_field_3:any;
  show_field_4:any;
  show_field_5:any;

  titleCount1: number = 0;
  titleModel1: any;
  titleCount2: number = 0;
  titleModel2: any;
  titleCount3: number = 0;
  titleModel3: any;
  titleCount4: number = 0;
  titleModel4: any;
  titleCount5: number = 0;
  titleModel5: any;
  titleCount6: number = 0;
  titleModel6: any;
  titleCount7: number = 0;
  titleModel7: any;
  titleCount8: number = 0;
  titleModel8: any;
  titleCount9: number = 0;
  titleModel9: any;
  titleCount10: number = 0;
  titleModel10: any;
  titleCount11: number = 0;
  titleModel11: any;
  titleCount12: number = 0;
  titleModel12: any;
  titleCount13: number = 0;
  titleModel13: any;
  titleCount14: number = 0;
  titleModel14: any;
  titleCount15: number = 0;
  titleModel15: any;
  titleCount16: number = 0;
  titleModel16: any;



  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute,
    private instituteMaster: InstituteInnovationContestService,
    private instituteActivityService :InstituteActivityService,
    private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });



  }

  ngOnInit(): void {

    this.show_field_1=0;
    this.show_field_2=0;
    this.show_field_3=0;
    this.show_field_4=0;
    this.show_field_5=0;

    this.iicImpactQuestions = this.fb.group({
      'team_lead_name': [null, Validators.required],
      'team_lead_email': [null, Validators.required],
      'team_lead_mobile': [null, Validators.required],
      'team_lead_gender': [null, Validators.required],
      'team_lead_category': [null, Validators.required],
      'user_type': [null, Validators.required],
      'title': [null, Validators.required],
      'theme': [null, Validators.required],
      'trl_level': [null, Validators.required],
      'innovation_type': [null, Validators.required],
      'description':[null, Validators.required,],
      'description_solution':[null, Validators.required,],
      'description_feature':[null, Validators.required,],
      'description_similar':[],
      'category': [null, Validators.required],
      'type': [null, Validators.required],
      'ip_component': [],
      'innovation_technology': [],
      'feasibility_specific': [],
      'feasibility_measureable': [],
      'feasibility_attainable': [],
      'feasibility_realistic': [],
      'feasibility_timeline': [],
      'applicable_usability': [],
      'applicable_scalability': [],
      'applicable_economic': [],
      'applicable_enviroment': [],
      'submission_type': [],
      'video_url':[]
    });

    this.iicImpactQuestions.patchValue({
      innovation_type: 1
    });

    this.requireField=0;
    this.activity_id=0;

    this.instituteActivityService.getIicActivityList("IIC").subscribe(
      (data) => this.handleResponseList(data)
    );

    this.instituteActivityService.getIicInnovationRepositoryDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

    // var parm = JSON.stringify(["program_theme"]);
    // this.instituteMaster
    //   .getMasterDetails(parm)
    //   .subscribe((data) => this.handleResponseForAutoPopulate(data));



  }

  onStatusChange1(ob) {
    let StatusVal = ob.value;
    if(StatusVal == "Patentable/IP Based") {
      this.show_field_1=1;
    }
    else
    {
      this.show_field_1=0;
    }

  }

  onStatusChange2(ob) {
      let nbaStatus = ob.value;
      if(nbaStatus == '1') {
        this.show_field_2 = 0;
        this.show_field_3 = 1;
      } else {
        this.show_field_2 = 1;
        this.show_field_3 = 1;
      }

    }

  handleResponseForAutoPopulate(data) {

    // this.programTheme = data['data'];
    // if (data['data'][0]) {
    //   data['data'][0].forEach((element) => {
    //     this.programTheme.push(element.data.data.name);
    //   });
    // }

    this.programTheme = "";

  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }


  handleResponse(data) {

    if(data['iic_activity']=="SELF")
    {
      this.requireField=0;
    }
    else
    {
      if(data['iic_activity']=="IIC" || data['iic_activity']=="MIC" || data['iic_activity']=="Celebration")
      {
        this.requireField=1;
        this.instituteActivityService.getIicActivityList(data['iic_activity']).subscribe(
          (data) => this.handleResponseList(data)
        );
      }
    }

    this.activity_id = data['activity_id'];

    if(data['totalcount'] > 0){

    this.iicImpactQuestions.patchValue({
      team_lead_name: data['team_lead_name'],
      team_lead_email: data['team_lead_email'],
      team_lead_mobile: data['team_lead_mobile'],
      team_lead_gender: data['team_lead_gender'],
      team_lead_category: data['team_lead_category'],
      title: data['title'],
      user_type: data['ICF']['user_type'],
      theme: data['ICF']['theme'],
      trl_level: data['ICF']['trl_level'],
      innovation_type: data['ICF']['innovation_type'],
      submission_type: data['ICF']['submission_type'],
      description: data['ICF']['description'],
      description_solution: data['ICF']['description_solution'],
      description_feature: data['ICF']['description_feature'],
      description_similar: data['ICF']['description_similar'],
      ip_component: data['ICF']['ip_component'],
      type: data['ICF']['type'],
      video_url: data['ICF']['video_url'],
      innovation_technology: data['ICF']['innovation_technology'],
      feasibility_specific: data['ICF']['feasibility_specific'],
      feasibility_measureable: data['ICF']['feasibility_measureable'],
      feasibility_attainable: data['ICF']['feasibility_attainable'],
      feasibility_realistic: data['ICF']['feasibility_realistic'],
      feasibility_timeline: data['ICF']['feasibility_timeline'],
      applicable_usability: data['ICF']['applicable_usability'],
      applicable_scalability: data['ICF']['applicable_scalability'],
      applicable_economic: data['ICF']['applicable_economic'],
      applicable_enviroment: data['ICF']['applicable_enviroment'],
      category: data['ICF']['category']
    });

    //alert(this.iicImpactQuestions.get('activity_id').value);

    this.document = data['pdf_upload_idea'];

  }
    this.totalcount = data['totalcount'];

  }

  handleResponseList(data) {
    // this.iicActivity = data['data'];
    this.programTheme = data['theme_NIC'];
  }

  onSubmit(data) {

    var formData = new FormData();

      formData.append('rid', this.rid);

      if (this.titleCount1 > 10)
      {
        this.toastr.error("Title/Name field should contain max 10 words.");
        return false;
      }

      if (this.titleCount2 > 100)
      {
        this.toastr.error("Define the problem and its relevance to today's market / sociaty / industry need field should contain max 100 words.");
        return false;
      }

      if (this.titleCount3 > 100)
      {
        this.toastr.error("Describe the Solution / Proposed / Developed field should contain max 100 words.");
        return false;
      }

      if (this.titleCount4 > 100)
      {
        this.toastr.error("Explain the uniqueness and distinctive features of the (product / process / service) solution field should contain max 100 words.");
        return false;
      }

      if(this.titleCount5 > 100)
      {
        this.toastr.error("How your proposed / developed (product / process / service) solution is different from similiar kind of product by the competitors if any field should max 100 words.");
        return false;
      }

      if(this.show_field_1==1)
      {
        if (this.titleCount6 > 100)
        {
          this.toastr.error("Specify the IP Components field should max 100 words.");
          return false;
        }
      }

      if(this.show_field_2 == 1)
      {
        if(this.titleCount16 > 100)
        {
          this.toastr.error("If your Innovation is technology based, then specify the TRL Level (Technology Readiness Level) and features of Prototype field should max 100 words.");
          return false;
        }
        if(this.titleCount7 > 100)
        {
          this.toastr.error("Specific- Specify the features of Innovation/Prototype field should max 100 words.");
          return false;
        }
        if(this.titleCount8 > 100)
        {
          this.toastr.error("Measurable- Mention the approach to convert prototype in to market ready product with milestones field should max 100 words.");
          return false;
        }
        if(this.titleCount9 > 100)
        {
          this.toastr.error("Attainable- Explain how you are going to achieve the commercialization objective with the available resources at your disposal field should max 100 words.");
          return false;
        }
        if(this.titleCount10 > 100)
        {
          this.toastr.error("Realistic- what kind of skillset of team and resources required to achieve the goal in specific time period field should max 100 words.");
          return false;
        }
        if(this.titleCount11 > 100)
        {
          this.toastr.error("Timeline- Develop a timeline against the milestones for taking prototype to the commercial level/startup stage field should max 100 words.");
          return false;
        }
        if(this.titleCount12 > 100)
        {
          this.toastr.error("Usability: what is the usability of your innovation/ prototype.(Max 50 words) field should max 100 words.");
          return false;
        }
        if(this.titleCount13 > 100)
        {
          this.toastr.error("Scalability: how your innovation/ prototype will be scalable at market level field should max 100 words.");
          return false;
        }
        if(this.titleModel14 > 100)
        {
          this.toastr.error("Economic sustainability: Explain the potential of innovation/ prototype to become profitable or financially viable field should max 100 words.");
          return false;
        }
        if(this.titleCount15 > 100)
        {
          this.toastr.error("Environment Sustainability: How your innovation/ prototype is environment friendly or address environmental problems field should max 100 words.");
          return false;
        }

        formData.append('innovation_technology', this.iicImpactQuestions.get('innovation_technology').value);
        formData.append('feasibility_specific', this.iicImpactQuestions.get('feasibility_specific').value);
        formData.append('feasibility_measureable', this.iicImpactQuestions.get('feasibility_measureable').value);
        formData.append('feasibility_attainable', this.iicImpactQuestions.get('feasibility_attainable').value);
        formData.append('feasibility_realistic', this.iicImpactQuestions.get('feasibility_realistic').value);
        formData.append('feasibility_timeline', this.iicImpactQuestions.get('feasibility_timeline').value);
        formData.append('applicable_usability', this.iicImpactQuestions.get('applicable_usability').value);
        formData.append('applicable_scalability', this.iicImpactQuestions.get('applicable_scalability').value);
        formData.append('applicable_economic', this.iicImpactQuestions.get('applicable_economic').value);
        formData.append('applicable_enviroment', this.iicImpactQuestions.get('applicable_enviroment').value);
      }
      else
      {
        formData.append('innovation_technology', this.iicImpactQuestions.get('innovation_technology').value);
        formData.append('feasibility_specific', this.iicImpactQuestions.get('feasibility_specific').value);
        formData.append('feasibility_measureable', this.iicImpactQuestions.get('feasibility_measureable').value);
        formData.append('feasibility_attainable', this.iicImpactQuestions.get('feasibility_attainable').value);
        formData.append('feasibility_realistic', this.iicImpactQuestions.get('feasibility_realistic').value);
        formData.append('feasibility_timeline', this.iicImpactQuestions.get('feasibility_timeline').value);
        formData.append('applicable_usability', this.iicImpactQuestions.get('applicable_usability').value);
        formData.append('applicable_scalability', this.iicImpactQuestions.get('applicable_scalability').value);
        formData.append('applicable_economic', this.iicImpactQuestions.get('applicable_economic').value);
        formData.append('applicable_enviroment', this.iicImpactQuestions.get('applicable_enviroment').value);
      }

      if(this.show_field_1==1)
      {
        if(this.iicImpactQuestions.get('ip_component').value=="")
        {
          this.toastr.error("Please fill Specify the IP Components and max 100 words.");
          return false;
        }
      }

      if(this.show_field_2==1)
      {
        if(this.iicImpactQuestions.get('video_url').value=="")
        {
          this.toastr.error("Please fill Video URL field.");
          return false;
        }
      }

      formData.append('title', this.iicImpactQuestions.get('title').value);
      formData.append('theme', this.iicImpactQuestions.get('theme').value);
      formData.append('trl_level', this.iicImpactQuestions.get('trl_level').value);
      formData.append('category', this.iicImpactQuestions.get('category').value);
      formData.append('type', this.iicImpactQuestions.get('type').value);

      formData.append('description', this.iicImpactQuestions.get('description').value);
      formData.append('description_solution', this.iicImpactQuestions.get('description_solution').value);
      formData.append('description_feature', this.iicImpactQuestions.get('description_feature').value);
      formData.append('description_similar', this.iicImpactQuestions.get('description_similar').value);
      formData.append('innovation_type', this.iicImpactQuestions.get('innovation_type').value);

      formData.append('ip_component', this.iicImpactQuestions.get('ip_component').value);
      formData.append('video_url', this.iicImpactQuestions.get('video_url').value);
      formData.append('submission_type', this.iicImpactQuestions.get('submission_type').value);

      var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

      if(this.totalcount>0){
        formData.append('document', cvFile);
        formData.append('document_old', this.document);
      }
      else
      {
        if(this.show_field_2==1)
        {
          if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
          formData.append('document', cvFile);
        }

      }

      formData.append('rid', this.rid);

    formData.append('team_lead_name', this.iicImpactQuestions.get('team_lead_name').value);
    formData.append('team_lead_email', this.iicImpactQuestions.get('team_lead_email').value);
    formData.append('team_lead_mobile', this.iicImpactQuestions.get('team_lead_mobile').value);
    formData.append('team_lead_gender', this.iicImpactQuestions.get('team_lead_gender').value);
    formData.append('team_lead_category', this.iicImpactQuestions.get('team_lead_category').value);


    this.instituteActivityService.submitIicNicRepository(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/innovation-repository';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

  countWords1(event) {
    var regex = /\s+/gi;
    this.titleCount1 = this.titleModel1.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount1 >= 10) {
      return false;
    } else {
      return true;
    }
  }

  countWords2(event) {
    var regex = /\s+/gi;
    this.titleCount2 = this.titleModel2.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount2 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords3(event) {
    var regex = /\s+/gi;
    this.titleCount3 = this.titleModel3.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount3 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords4(event) {
    var regex = /\s+/gi;
    this.titleCount4 = this.titleModel4.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount4 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords5(event) {
    var regex = /\s+/gi;
    this.titleCount5 = this.titleModel5.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount5 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords6(event) {
    var regex = /\s+/gi;
    this.titleCount6 = this.titleModel6.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount6 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords7(event) {
    var regex = /\s+/gi;
    this.titleCount7 = this.titleModel7.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount7 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords8(event) {
    var regex = /\s+/gi;
    this.titleCount8 = this.titleModel8.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount8 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords9(event) {
    var regex = /\s+/gi;
    this.titleCount9 = this.titleModel9.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount9 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords10(event) {
    var regex = /\s+/gi;
    this.titleCount10 = this.titleModel10.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount10 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords11(event) {
    var regex = /\s+/gi;
    this.titleCount11 = this.titleModel11.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount11 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords12(event) {
    var regex = /\s+/gi;
    this.titleCount12 = this.titleModel12.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount12 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords13(event) {
    var regex = /\s+/gi;
    this.titleCount13 = this.titleModel13.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount13 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords14(event) {
    var regex = /\s+/gi;
    this.titleCount14 = this.titleModel14.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount14 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords15(event) {
    var regex = /\s+/gi;
    this.titleCount15 = this.titleModel15.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount15 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  countWords16(event) {
    var regex = /\s+/gi;
    this.titleCount16 = this.titleModel16.trim().replace(regex, ' ').split(' ').length;
    if (this.titleCount16 >= 100) {
      return false;
    } else {
      return true;
    }
  }

  onIICStatusChange(ob) {
    let iicStatus = ob.value;
    if(iicStatus == 'SELF') {
      this.requireField = 0;
    } else {
      this.requireField = 1;
      this.instituteActivityService.getIicActivityList(ob.value).subscribe(
        (data) => this.handleResponseList(data)
      );

    }

  }

}
