<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Announcement from IIC Institutes">
    <div class="container">
      <div class="row mt-3">
        <div class="col-8">
          <mat-form-field appearance="outline">
            <mat-label>Theme Filter</mat-label>
            <mat-select (selectionChange)="changeThemeAll()" [(ngModel)]="filterModelAll">
              <mat-option value="All">All</mat-option>
              <mat-option *ngFor="let theme of filterThemeAll" value="{{theme}}">{{theme}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2" *ngIf="!firstPage">
          <!-- <button type="button" class="btn btn-success" (click)="previousPage()">
            Previous
          </button> -->
        </div>
        <div class="col-2" *ngIf="!lastPage">
          <!-- <button type="button" class="btn btn-success" (click)="nextPage()">
            Next
          </button> -->
        </div>
      </div>
      <div class="">
        <div class="row">

          <div class="col-md-4" *ngFor="let news of newsList; let i = index">
            <div class="CardBox">
              <img src="assets/customCard/card-gradient-img.png"
                class="CardBoxContentTopImg CardBoxContentTopImgMyCouncil">
              <div class="CardBoxContent">
                <h3>Institute</h3>
                <p>{{ news?.instituteName }}</p>
              </div>
              <div class="CardBoxContent">
                <h3>Activity</h3>
                <p>{{ news?.evant_title }}</p>
              </div>

              <div class="CardBoxContent">
                <h3>Theme <span> {{ news?.event_theme }} </span></h3>
              </div>

              <div class="CardBoxContent" *ngIf="news.brochure">
                <a href="{{news?.brochure}}" target="_blank">
                  <button type="button"
                    class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">Download Poster</button>
                </a>
              </div>

              <div id="InstNewsCornerID" class="collapse">

                <div class="row">
                  <div class="col-md-6">
                    <div class="CardBoxContent">
                      <h3>From</h3>
                      <p>{{ news?.from_dt }}</p>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="CardBoxContent">
                      <h3>To</h3>
                      <p>{{ news?.to_dt }}</p>
                    </div>
                  </div>

                </div>

                <div class="CardBoxContent" *ngIf="news?.description">
                  <h3>Description</h3>
                  <p>{{ news?.description }}</p>
                </div>
              </div>
              <!-- <div class="cardReadMore">

                <button data-toggle="collapse" data-target="#InstNewsCornerID">Read More</button>

              </div> -->

              <div class="cardReadMore">

                <button data-toggle="collapse" data-target="#InstNewsCornerID" *ngIf="readMore1"
                  (click)="readMore1=!readMore1">Read More</button>
                <button data-toggle="collapse" data-target="#InstNewsCornerID" *ngIf="!readMore1"
                  (click)="readMore1=!readMore1">Read Less</button>

              </div>

            </div>
          </div>
        </div>

        <!-- <div *ngIf="newsList.length < 1">
          <div class="card text-center text-white bg-info mb-4">
            <div class="card-body">
              <p class="card-text text-white"><i>No news added yet!</i></p>
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="col-md-12">
            <mat-paginator #paginator
                    showFirstLastButtons
                    (page)="nextPage1($event)"
                    [length]="total_pages"
                    [pageIndex]="frontCurrentPageNo"
                    [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                  </mat-paginator>
          </div>
        </div>


      </div>
    </div>
  </mat-tab>






  <mat-tab label="Request Announcement">
    <div class="container">
      <div class="row mt-3" style="text-align: center !important">
        <div class="col-2">
          <mat-form-field appearance="outline">
            <mat-label>Theme Filter</mat-label>
            <mat-select (selectionChange)="changeThemeSelf()" [(ngModel)]="filterModelSelf">
              <mat-option value="All">All</mat-option>
              <mat-option *ngFor="let theme of filterThemeSelf" value="{{theme}}">{{theme}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 ml-3">
          <mat-form-field appearance="outline">
            <mat-label>Status Filter</mat-label>
            <mat-select (selectionChange)="changeFilter()" [(ngModel)]="filterModel">
              <mat-option value="All" default selected>All</mat-option>
              <mat-option value="Approved">Approved</mat-option>
              <mat-option value="Disapproved">Disapproved</mat-option>
              <mat-option value="Pending">Pending</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3">
          <button mat-raised-button color="primary" (click)="openDialog('')">
            <mat-icon>add</mat-icon>Create Newscorner Request
          </button>
        </div>
        <div class="col-2" *ngIf="!firstSelfPage">
          <!-- <button type="button" class="btn btn-success" (click)="previousPageSelfNews()">
            Previous
          </button> -->
        </div>

        <div class="col-2" *ngIf="!lastSelfPage">
          <!-- <button type="button" class="btn btn-success" (click)="nextPageSelfNews()">
            Next
          </button> -->
        </div>

        <!-- <mat-form-field appearance="fill">
          <mat-label>Filter</mat-label>
          <mat-select>
            <mat-option *ngFor="let status of statusList" [value]="status">{{status}}</mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>


      <div class="">
        <div class="row">
          <div class="col-md-4" *ngFor="let selfNews of selfNewsList; let i = index">
            <div class="CardBox">
              <img src="assets/customCard/card-gradient-img.png"
                class="CardBoxContentTopImg CardBoxContentTopImgMyCouncil">

              <div class="CardBoxContent">
                <h3>Institute</h3>
                <p>{{ selfNews?.instituteName }}</p>
              </div>
              <div class="CardBoxContent">
                <h3>Activity</h3>
                <p>{{ selfNews?.evant_title }}</p>
              </div>

              <div class="CardBoxContent">
                <h3>Theme</h3>
                <p>{{ selfNews?.event_theme }}</p>
              </div>

              <div id="InstDeclearSelfID" class="collapse">

                <div class="CardBoxContent" *ngIf="selfNews?.verification_status == '0'">
                  <h3>Status</h3>
                  <p>Pending</p>
                </div>

                <div class="CardBoxContent" *ngIf="selfNews?.verification_status == '1'">
                  <h3>Status</h3>
                  <p>Approved</p>
                </div>

                <div class="CardBoxContent" *ngIf="selfNews?.verification_status == '3'">
                  <h3>Status</h3>
                  <p>Disapproved</p>
                </div>

                <div class="CardBoxContent">
                  <h3>From</h3>
                  <p>{{ selfNews?.from_dt }}</p>
                </div>

                <div class="CardBoxContent">
                  <h3>To</h3>
                  <p>{{ selfNews?.to_dt }}</p>
                </div>

                <div class="CardBoxContent" *ngIf="selfNews.description">
                  <h3>Description</h3>
                  <p>{{ selfNews?.description }}</p>
                </div>

                <div class="CardBoxContent" *ngIf="selfNews.Comment">
                  <h3>Comment by MIC</h3>
                  <p>{{ selfNews?.Comment }}</p>
                </div>

                <div class="CardBoxContent" *ngIf="selfNews.brochure">
                  <a href="{{selfNews?.brochure}}" target="_blank">
                    <button type="button"
                      class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">Download Poster</button>
                  </a>
                </div>

              </div>

              <div class="CardBoxContent" *ngIf="selfNews?.verification_status=='0'" >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-button-action">
                      <a (click)="openDialog(selfNews)" data-toggle="tooltip" title=""
                        class="btn btn-link btn-primary btn-lg edit" data-original-title="Edit Task">
                        <i class="fa fa-edit"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-button-action">
                      <a (click)="deleteDialog(selfNews)" data-toggle="tooltip" title=""
                        class="btn btn-link btn-danger deleteInst" data-original-title="Remove">
                        <i class="fa fa-times"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cardReadMore">

                <button data-toggle="collapse" data-target="#InstDeclearSelfID" *ngIf="readMore"
                  (click)="readMore=!readMore">Read More</button>
                <button data-toggle="collapse" data-target="#InstDeclearSelfID" *ngIf="!readMore"
                  (click)="readMore=!readMore">Read Less</button>

              </div>
            </div>
          </div>

        </div>

        <!-- <div *ngIf="selfNewsList.length < 1">
          <div class="card text-center text-white bg-info mb-4">
            <div class="card-body">
              <p class="card-text text-white"><i>No news added yet!</i></p>
            </div>
          </div>
        </div> -->
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-paginator #paginator
                  showFirstLastButtons
                  (page)="nextPage11($event)"
                  [length]="total_pages1"
                  [pageIndex]="frontCurrentPageNo1"
                  [pageSizeOptions]="pageSizeOptions1" class="NewMatPagination">
                </mat-paginator>
        </div>
      </div>

    </div>
  </mat-tab>
</mat-tab-group>
