import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-current-year-performance',
  templateUrl: './current-year-performance.component.html',
  styleUrls: ['./current-year-performance.component.css']
})
export class CurrentYearPerformanceComponent implements OnInit {
  formData: any;
  fStar:any;
  constructor(private instituteMyCouncilService: InstituteMyCouncilService) { }

  ngOnInit(): void {

    this.instituteMyCouncilService
      .getRewardPointsCurrent()
      .subscribe((data) => this.handleResponse1(data));
  }

  handleResponse1(data) {
    this.formData = data['data'];
    this.fStar = data['data']['FStar'];
  }

}
