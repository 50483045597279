import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-atl-yukti-submission',
  templateUrl: './atl-yukti-submission.component.html',
  styleUrls: ['./atl-yukti-submission.component.css']
})
export class AtlYuktiSubmissionComponent implements OnInit {

  currentPageNo: number = 1;
  myActivity: any[];
  firstPage = true;
  lastPage: boolean = false;
  reportsClosed: boolean = true;
  quarter_filter: string = '';
  activity_filter:string='';
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  title1 = "";
  searched: string;
  search_keyword: string;
  eventId: any;
  cityList: any;
  districtList: any;
  MyActivityannualReport: number = 0;
  actiontype: any;
  constructor(
    private activityService: InstituteActivityService,
    public dialog: MatDialog,
    private toastr: ToastrService,

  ) { }


  ngOnInit(): void {

    this.getMyActivityDetails(this.currentPageNo);

  }

  nextPage() {
    this.currentPageNo++;
    this.getMyActivityDetails(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getMyActivityDetails(this.currentPageNo);
  }

  getMyActivityDetails(pageNo) {
    this.activityService
      .getATLYuktiList(this.per_page, pageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22')
      .subscribe((data) => this.handleResponse(data));
  }
  getMyActivityDetails1() {
    this.activityService
      .getATLYuktiList(this.per_page, this.currentPageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22')
      .subscribe((data) => this.handleResponse(data));
  }
  nextPage1(e: any) {
    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    this.getMyActivityDetails1();
  }

  onClickEvent(data,type){

    if(type=="Edit")
    {
      window.location.href="/institute/atlsic-yukti-submission-add/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.activityService.deleteAtlYuktiRepositoryDetails(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }

  }

  handleResponse1(data)
  {
    this.toastr.success(data['message']);
    this.getMyActivityDetails(this.currentPageNo);
  }

  reviewResponse(data) {
    if(data['status']=='1'){
      this.toastr.success(data['msg']);
      this.getMyActivityDetails(this.currentPageNo);
    }else{
      this.toastr.error(data['msg']);
    }
  }

  onDeleteClick(data){
    this.eventId=data['eventId'];

    if (confirm('Are you sure you want to delete?')) {
      // Save it!
    this.activityService
    .deleteMyActivity(this.eventId)
    .subscribe((data) => this.deleteResponse(data));
    } else {
      // Do nothing!
      console.log('not confirm');
    }
  }

  deleteResponse(data){
if(data['status']=='1'){
  this.toastr.success(data['msg']);
  this.getMyActivityDetails(this.currentPageNo);
}else{
  this.toastr.error(data['msg']);
}
  }

  handleResponse(data) {
    this.myActivity = data.data;
    this.total_pages = data.meta.pagination.total;
    this.MyActivityannualReport= data.meta.annualReport;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }


  }
  

}