import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
// import { InstituteAffiliateProgramAddDialog } from './institute-affiliate-program-add-dialog/institute-affiliate-program-add-dialog.component';

@Component({
  selector: 'app-institute-assigner-detail',
  templateUrl: './institute-assigner-detail.component.html',
  styleUrls: ['./institute-assigner-detail.component.css']
})
export class InstituteAssignerDetailComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicrepository:any;

  constructor(private fb: FormBuilder, public dialog: MatDialog, private instituteActivityService :InstituteActivityService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.requireField=0;

    this.instituteActivityService.getIicInstitutesReferal('non-iic').subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.iicrepository = data['data'];
  }

 
}
