<!-- IIC Website Header -->
<!-- <app-iic-web-main-navbar></app-iic-web-main-navbar> -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">Innovation Contest 2020</h1>
		</div>
	</div>
</div> -->

<!-- <nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item"><a>IIC Innovation Contest</a></li>
    <li class="breadcrumb-item active" aria-current="page">Innovation Contest 2020</li>
  </ul>
</nav> -->

<div id="carouselExampleControlsMobile" class="carousel slide bannerSlideInnovation" data-ride="carousel">
  <div class="carousel-inner">
	<div class="carousel-item active">
	  <img class="d-block w-100" src="assets/assets/images/impect-lecture-series-program-banner.jpg" alt="First slide">
	  <div class="carousel-caption innovationContest2020Content">
		<h2>Impact Lecture <br>Series for<br> IIC Institutions</h2>
	  </div>
	</div>
  </div>

  <!-- <a class="carousel-control-prev landingSliderBannerPrevMob" href="#carouselExampleControlsMobile" role="button" data-slide="prev">
	<i class="fa fa-arrow-left" aria-hidden="true"></i>
	<span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next landingSliderBannerNextMob" href="#carouselExampleControlsMobile" role="button" data-slide="next">
	<i class="fa fa-arrow-right" aria-hidden="true"></i>
	<span class="sr-only">Next</span>
  </a> -->
</div>



<div class="pageNavigationBar">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<button class="navbar-toggler mentorNavRes" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
					  <span class="navbar-toggler-icon"></span>
					</button>

					<div class="collapse navbar-collapse" id="navbarColor01">
					  <ul class="navbar-nav mr-auto mentorPageNav">
						<li class="nav-item active">
						  <a class="nav-link" href="#objectiveBox" data-scroll-nav="1"><mat-icon>check_circle</mat-icon> Objective</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#applyBox"><mat-icon>approval</mat-icon> How and When to Apply</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#eligibilityBox"><mat-icon>admin_panel_settings</mat-icon> Eligibility</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#outcomesBox"><mat-icon>outbound</mat-icon> Expected Outcomes</a>
						</li>
						<!-- <li class="nav-item">
						  <a class="nav-link" href="#guideLine"><mat-icon>local_activity</mat-icon> Guidelines</a>
						</li> -->
						<!-- <li class="nav-item">
						  <a class="nav-link" href="assets/announcements/Impact-Lecture-Scheme.pdf" target="_blank"><mat-icon>batch_prediction</mat-icon> Scheme Document</a>
						</li> -->
						<li class="nav-item">
						  <!-- <a class="nav-link" href="assets/announcements/Impact-scheme-document-22-23.pdf" target="_blank"><mat-icon>batch_prediction</mat-icon> Scheme Document</a> -->
						  <a class="nav-link" href="{{ imageBasePath }}assets/announcements/Impact Lecture Scheme 2023-24.pdf" target="_blank"><mat-icon>batch_prediction</mat-icon> Scheme Document (2023-24)</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#termBox"><mat-icon>gavel</mat-icon> Terms and Conditions</a>
						</li>
						<li class="nav-item">
						  <!-- <img src="assets/assets/images/new-icon-gif-13.jpg" class="newGifIcon"> -->
						  <a class="nav-link" href="#resultDeclare"><mat-icon>content_paste_go</mat-icon> Downloads</a>
						</li>
						<!-- <li class="nav-item">
						  <img src="assets/assets/images/new-icon-gif-13.jpg" class="newGifIcon">
						  <a class="nav-link" href="#resultDeclare"><mat-icon>content_paste_go</mat-icon> Result Declared Phase 2</a>
						</li> -->
						<li class="nav-item">
						  <a class="nav-link" href="#contactBox"><mat-icon>contact_phone</mat-icon> Contact Us</a>
						</li>
					  </ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</div>


<section class="mentorObjective" id="objectiveBox" data-scroll-index="1">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Objective</h2>
				</div>
			</div>
			<div class="col-md-12">
				<div class="objContentPara">
					<p>Institution's Innovation Council established at HEI helps in planning, channelizing and organizing round the year activities in the areas of innovation, entrepreneurship and IPR to sensitise and motivate students and faculties to pursue innovation and start-up during their academic. </p>
					<p>Conduct of impact lecture sessions in HEIs are one of the approaches under the IIC program to support IIC institutions to generate awareness on innovation, IPR and start up in such institutions with funding assistance from MIC & AICTE. The impact lecture sessions in selected IIC institutions will not only be a streamlined campus innovation and start-up ecosystem but also help in establishing strong connections with ecosystem enablers at regional and national level, and aware students and faculty members about the innovation ecosystem in their surrounding and at national level.</p>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- new card -->
<section class="dashboardBox" id="mm21">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMainDashboard">
					<h2 class="hdngBrkrDivDashboard">Impact Lecture 2023-2024</h2>
				</div>
			</div>
		</div>

		<div class="row dashTop">
            <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{IMPCount?.Beneficiary}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p class="instiApplied">Total no. Beneficiary</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="nat-wid-icon">
                                <img src="assets/assets/images/icons/nodal-center-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{IMPCount?.Impact_Conducted}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Impact Conducted</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="idea-icon1">
                                <img src="assets/assets/images/icons/stu-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4 brd-right-bottom">
                <div class="nodal-center">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>000</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentor Institutions(Without Financial Assisatance from MIC/AICTE)</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="idea-icon">
                                <img src="assets/assets/images/icons/institutation-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
		<div class="flex">
		<div class="row response-gap" *ngFor="let theme of Themes">
			<div class="vc_row innovationAmbassadorBox ambPadding1">
				<div class="box col-md-18">
					<div class="ambBoxesBottom">
						<p class="themeCount">{{theme.theme_count}}</p>
						<div class="text-content">
							<p>{{theme.name}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
			<!-- <div class="flex">
			<div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row" *ngFor="let theme of Themes">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>{{theme.theme_count}}</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>{{theme.name}}</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="evelau">
                                <img src="assets/assets/images/icons/mentor-without-funding-support.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div></div> -->
            <!-- <div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="nat-wid-nodel">
                                <h3>000</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>Total No. Mentee Institutions</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="evelau evelau_1">
                                <img src="assets/assets/images/icons/evelaturos.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 brd-right-bottom-sec">
                <div class="nodal-center2">
                    <div class="row">
                        <div class="col-md-8 ext-pad2 ext-pad22017">
                            <div class="nat-wid-nodel">
                                <h3>000</h3>
                            </div>
                            <div class="line-bottom"><span>&nbsp;</span></div>
                            <div class="text-content">
                                <p>No. of Mentoring Activities Scheduled/Organised</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="stu-sih-ico">
                                <img src="assets/assets/images/icons/stu-icon.png" class="img-responsive" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
	</div>
</section>
<!-- new card -->
<section class="expectedOutcome1" id="applyBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">How and When to Apply</h2>
				</div>
			</div>
			<div class="col-md-7">
				<div class="expectList">
					<ul>
						<li>Applications are invited in the month of August 2023. Last date to apply 15th September 2023.</li>
                        <li>Institute must apply through its IIC institute login ID at <a href= 'https://iic.mic.gov.in/login'>https://iic.mic.gov.in/login</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-5">
				<div class="exOutImg">
					<img src="assets/assets/images/howToApply.png" />
				</div>
			</div>
		</div>
	</div>
</section>

<section class="expectedOutcomeEligibility" id="eligibilityBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Eligibility</h2>
				</div>
			</div>
			<div class="col-md-5">
				<div class="exOutImg1">
					<img src="assets/assets/images/impectEligibility.png">
				</div>
			</div>
			<div class="col-md-7">
				<div class="outcomeHeadingPara">
					<p>The Institute should have approved the Institution's Innovation Council as per the norms of Innovation Cell, Ministry of Education, Govt. of India. The following IIC Institutions are eligible to apply. </p>
				</div>
			</div>
			<div class="col-md-6">
				<div class="expectListEligibility">
					<h3>Existing IIC Institutes should have registered in IIC5.0 and those have secured 3 star and below in IIC5.0 annual performance (2022-23)</h3>
					<h3><strong>Or</strong></h3>
					<h3>Newly joined IIC institutions in IIC6.0 calendar year (2023-24) are also eligible to apply.IIC institutions should have updated details for the ‘My council’ section as part of the IIC 6.0</h3>
				</div>
			</div>
			<div class="col-md-6">
				<div class="expectListEligibility">
					<!-- <h3>IIC institutions should have updated details for the ‘My council’ section as part of the IIC5.0 and submitted at least 1 activity report during the Q1-Q4 of IIC5.0 calendar year (2022-23).
					</h3> -->
					<p><strong>Important Dates::<br>&nbsp;</strong>1. Interest Submission Deadline : May 12th, 2024.<br>
						2. Announcement of shortlisted beneficiary IIC institutions: May 22nd, 2024.<br>
						3. Release of Grant Assistance of Rs 20,000/Institute for organising IL Lecture (Physical Mode): June & July 2024.</p>
				</div>
			</div>
			<div class="col-md-12">
				<div class="eliBottomPara">
					<!-- <p><strong>Note:&nbsp;</strong>Eligibility conditions subject to change on the discretion of MIC with the approval of competent authority from time to time.</p> -->
					<p><strong>Note:&nbsp;</strong>Eligibility conditions subject to change on the discretion of MIC with the approval of competent authority from time to time.</p>
				</div>
			</div>
		</div>
	</div>
</section>  

<section class="expectedOutcome" id="outcomesBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Expected Outcomes</h2>
				</div>
			</div>
			<div class="col-md-5">
				<div class="exOutImg">
					<img src="assets/assets/images/impactLectureExpectedOutcome.png">
				</div>
			</div>
			<div class="col-md-7">
				<div class="expectList">
					<ul>
						<li>Active participation and involvement of students, faculties and staff and most importantly members of the IIC institute in the program. </li>
						<li>Awareness generation among students, faculties and staff on recent developments and new approaches for innovation, entrepreneurship and intellectual property and their importance in wealth creation at individual level and at national level.</li>
						<li>Nurturing leadership potential of student innovators, and entrepreneurs by providing them coordination role to achieve the program objective.</li>
						<li>Program will set foundation for institute’s participation in MoE’s Innovation and Entrepreneurship initiatives such as IIC, ARIIA, Hackathons, NISP, National Innovation Contest, and YUKTI 2.0 and related programs at MIC and AICTE.</li>
						<li>Strengthening the connection of IIC institutions located in <a href="{{ imageBasePath }}assets/announcements/Himalayan-State.pdf" target="_blank">Himalayan</a>/North Eastern region/Aspirational Districts with national innovation and entrepreneurial ecosystem enablers. </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>


<section class="activitiesBoxMain" id="guideLine">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain1">
					<h2 class="hdngBrkrDiv1">Guidelines for Selection of Institutions </h2>
				</div>
			</div>
		</div>
		<div class="row guideLineRow">
			<div class="col-md-6">
				<div class="manteeContentMain">
					<p><span>a.</span>	Submitted applications will be screened based on the provided eligibility criteria and any other additional criteria set by the evaluation committee.</p>
				</div>
			</div>
			<div class="col-md-6">
				<div class="manteeContentMain">
					<p><span>b.</span> Preference will be given to the IIC institutions belongs to Aspirational Districts <a href="{{ imageBasePath }}assets/announcements/Annex-I.pdf" target="_blank">(Annex-I).</a></p>
				</div>
			</div>
			<div class="col-md-12">
				<div class="manteeContentMain">
					<p><span>c.</span> Preference will be given to the IIC institutions from States & UTs of Himalayan Region including J&K, Ladakh and North Eastern region. <a href="{{ imageBasePath }}assets/announcements/Himalayan-State.pdf" target="_blank">(Annex-II).</a></p>
				</div>
			</div>
			
		</div>
	</div>
</section>
<!-- 27-09-23 -->
<!-- commented -->
<!-- <section class="mentorSchemeBox" id="guideLineForSession">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Guideline for conducting of impact lecture sessions and general conditions  </h2>
				</div>
			</div>
			<div class="col-md-5">
				<div class="exOutImg">
					<img src="assets/assets/images/impectGuideLine.png">
				</div>
			</div>
			<div class="col-md-7">
				<div class="expectList">
					<ul>
						<li>The impact session themes and lecture topics should be on innovation, entrepreneurship and IPR. And impact lectures should be delivered by external experts by inviting successful innovators, patent expert and entrepreneurs and resource persons from innovation and start-up ecosystem at the state/region/national level. </li>
						<li>Each Impact session should comprise two impact lectures to be delivered by two different experts on two different topics of minimum 1.5 hours’ duration each and should be conducted on the same day of the session date. </li>
						<li>If IIC institution is organise two impact sessions, then there should be a minimum time gap of 10 days between two impact sessions.  </li>
						<li>Impact sessions should be conducted in online mode. </li>
						<li>Impact sessions should be conducted after the announcement of results (or) notification issued in this regard to selected IIC institutions. i.e between 23rd May 2022 to 15th July 2022 and submit the reports by 30th July 2022 in the IIC portal as well in hard copy along with supporting documents i.e mandate form, statement of expenditure (in prescribed format) and honorarium receipts and proof of transaction of honorarium to the experts etc. </li>
					</ul>
				</div>
			</div>
			<div class="col-md-12">
				<p class="sessionPara">Note: For more information like signatories and postal address please refer the scheme document</p>
			</div>
			<div class="col-md-12">
				<div class="expectList listOnce">
					<ul>
						<li>IIC institute should get maximum participation by ensuring in-house participation of both students and faculty members and also inviting outside participants from other IIC institutions, Atal Tinkering Labs, Schools etc. </li>
						<li>IIC-Institutes are advised to carryout e-assessment and e-feedback (either by using google form or any other suitable medium) followed by the session to assess the understanding level of the participants. </li>
						<li>IIC Institute may issue e-certificates to the participants attending the session and have submitted e-assessment successfully. For this purpose, IIC-institute may use the MIC, AICTE and IIC logo in the certificate with IIC president as one of the signatory. </li>
						<li>Expenditure for the Impact session should be in accordance with the budget breakup given in below. </li>
						
					</ul>
				</div>
			</div>
		</div>
	</div>
</section> -->

<section class="mentorSchemeBox bugetTbl" id="">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Funding Assistance from MIC/AICTE </h2>
				</div>
			</div>
			<div class="col-md-12 text-center pt-4 mt-4">
				<div class="expectList pt-4">
					<div class="table-responsive">
						<table class="table budgetTbl">
							<tr>
								<th>Sr. No.</th>
								<th>Budget Breakup</th>
								<th>Amount (Online Mode)</th>
							</tr>
							<tr>
								<td>1</td>
								<td>Honorarium to the Expert</td>
								<td>Rs. 6000/ Session<br>
									(Maximum of Rs3000/Expert)</td>
							</tr>
							<tr>
								<td>2</td>
								<td>TA/DA</td>
								<td>Rs. 6,000/-</td>
							</tr>
							<tr>
								<td>3</td>
								<td>Venue related expenditure including food and snacks etc.</td>
								<td>Rs 8,000/-</td>
							</tr>
							<!-- <tr>
								<td colspan="2">Expense Amount per session ( two lectures)</td>
								<td>Rs.6,000/ Impact Session</td>
							</tr>	 -->
							<tr>
								<td colspan="2">Total Amount</td>
								<td>Rs.20,000/-(For 2 Impact Sessions)</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<p class="sessionPara"><strong>Note:&nbsp;</strong> TDS (10 %) should be deducted (as per income tax act) by the institute before releasing the Honorarium amount to the experts.</p>
			</div>
		</div>
	</div>
</section>
<section class="mentorSchemeBox" id="">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">EXPECTED OUTCOMES </h2>
				</div>
			</div>
			
			<div class="col-md-12 pt-4 mt-4">
				<div class="pt-4">
					<p class="sessionPara">Active participation and involvement of students, faculties and staff and most importantly members of the IIC institute in the program.</p>
					<p class="sessionPara">Awareness generation among students, faculties and staff on recent developments and new approaches for innovation, entrepreneurship and intellectual property and their importance in wealth creation at individual level and at national level.</p>
					<p class="sessionPara">Nurturing leadership potential of student innovators, and entrepreneurs by providing them coordination role to achieve the program objective.</p>
					<p class="sessionPara">Program will set foundation for institute’s participation in MoE’s Innovation and Entrepreneurship initiatives such as IIC, ARIIA, Hackathons, NISP, National Innovation Contest, and YUKTI and related programs at MIC and AICTE.</p>
					<p class="sessionPara">Strengthening the connection of IIC institutions located in Himalayan/North Eastern region/Aspirational Districts with national innovation and entrepreneurial ecosystem enablers.</p>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="termsConditionBoxMain" id="termBox">
	<div class="container">
		<div class="row">
			<div class="col-md-18">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Terms and Conditions</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<h3 class="termsHeadingBottom">a. Conducting the lecture Session  </h3>
				<div class="expectListTerm">
					<ul>
						<li>Impact lecture sessions should be conducted in offline mode.</li>
						<li>Competent and experienced experts with credibility deriving from state/national level I&E ecosystem should be invited as speaker to deliver the session. President of the IIC will take the call on finalizing the experts.</li>
						<li>HEI’s need to ensure in-house participation of minimum of 50 students and 10 teaching and non-teaching staff</li>
						<li>Duration of each impact session should be of minimum 1.5Hours.</li>
						<li>IIC-Institute is advised to carryout assessment and feedback (either by using google form or any other suitable medium) followed by the session to assess the subject level understanding of the participants.</li>
						<li>IIC Institute may issue certificates to the participants attending the session and have submitted assessment successfully. For this purpose, IIC-institute may use the MIC logo and IIC logo in the certificate.</li>
						<li>IIC-Institute may invite more than two experts to deliver the session.
							Reimbursement to the experts can be made as per the budget breakupmentioned in the point no. VII.</li>
						<li>IIC-institute is free to invite more than 2 experts if they want to do so and the additional cost required to complete the program must be borne by the institute	from their own or other sources. But the quality of the activities should not be compromised.</li>
					</ul>
				</div>
			</div>
			<div class="col-md-6">
				<h3 class="termsHeadingBottom">b. Utilization/Refund/Maintenance of Released Fund   </h3>
				<div class="expectListTerm">
					<ul>
						<li>The College/Institute shall maintain proper accounts of the expenditure out of the grants, which shall be utilized only on the scheme.</li>
						<li>AICTE/MIC or its nominee shall have the right to check/verify the account to satisfy that the fund has been utilized for the purpose for it was sanctioned.</li>
						<li>The Principal / Director / Registrar shall intimate about the receipt of the grant to AICTE/MIC.</li>
						<li>The released/sanctioned fund for organizing the impact lecture sessions	cannot be utilized for any other programme/ sessions.</li>
						<li>In case the impact lecture session is cancelled, the funds must be returned	back to AICTE/MIC immediately with interest accrued thereon.</li>
						<li>In any case, if the institute is required to refund the grant or interest accrued thereon or balance amount, the amount will be refunded to AICTE/MIC (by way of a demand draft in favour of INNOVATION CELL ACCOUNT payable at New Delhi or through NEFT/RTGS at INNOVATION CELL ACCOUNT, Account No:37903899633, IFSC: SBIN0050203, SBI, SHASTRI BHAWAN, RAJENDRA PRASAD ROAD, NEW DELHI 11000</li>
						<li>As MIC needs adequate time for depositing the Demand Draft in the bank, the	same be immediately dispatched to avoid any lapse of the validity period.</li>
						<li>The Institute shall strictly follow the provisions laid down in the scheme document and sanction order No. ……………………. dated ………………. issued by this office. All correspondences related to the scheme must contain this number along with year of sanction of the scheme; failing which	correspondence will not be entertained.</li>
					</ul>
				</div>
			</div>
			<!-- <div class="col-md-6">
				<h3 class="termsHeadingBottom">c. Use of Logo</h3>
				<div class="expectListTerm">
					<ul>
						<li>The MIC and IIC logo must be prominently displayed for promotion and certificate.</li>
						<li>The session venue in terms of stage backdrop/ podium must prominently display the MIC and IIC logo.</li>
						<li>All publicity materials/ advertisements/ brochures/ invitation cards/ any other collaterals/ event backdrop etc. related to Impact lecture session must carry the MIC and IIC logo.</li>
					</ul>
				</div>
			</div> -->
		</div>
		
	</div>
</section>

<section class="resultDeclare" id="resultDeclare">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">Downloads</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="resultDeclareBox">	
				<!-- <p><a href="assets/announcements/Impact Lecture Scheme 2023.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i>Scheme Document (2023-24)</a></p>			 -->
				<p><a href="{{ imageBasePath }}assets/announcements/Impact Lecture Scheme 2023-24.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i>Scheme Document (2023-24)</a></p>
				<p><a href="{{ imageBasePath }}assets/announcements/impact-result-publication-list-2.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i> List of HEIs Selected for Organising Impact Lectures in Online Mode Phase 1 (Other than N-E States, J&K, Ladakh, other Himalayan States & Aspirational Districts of Country)(2021-22)</a></p>
					<p><a href="{{ imageBasePath }}assets/announcements/impact-result-publication-list-1.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i> List of HEIs Selected for Organising Impact Lectures in online mode Phase 1 (N-E States, J&K, Ladakh, other Himalayan States & Aspirational Districts of Country)(2021-22)</a></p>
					<p><a href="{{ imageBasePath }}assets/announcements/impact-scheme-phase-ii-selected-iic-insitutions.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i> List of IIC Institutions Selected for Organising Impact Lectures in Online Mode-Phase 2 (2021-22)</a></p>
					<p><a href="{{ imageBasePath }}assets/announcements/soe-impact-FY-22-23.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i>Statement of Expenditure (2021-22)</a></p>
					<p><a href="{{ imageBasePath }}assets/assets/images/Recommendation_of_Impact_Lecture_Series_3.pdf" target="_blank"><i aria-hidden="true" class="fa fa-download"></i>List of HEIs selected for Organising Impact Lectures (2019-20)</a></p>	

			</div>
		</div>
	</div>
</section>

<section class="contactBoxMain" id="contactBox">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="mentorHeadingMain">
					<h2 class="hdngBrkrDiv">For more information</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="contactBoxContent">
					<p class="contactEmail"><a routerLink="/iic-implementation-team">Contact Us</a></p>
					<p class="contactPhone">Phone No: 011-2958 1235/1513/1517/1227/1316</p>
                    <p class="contactPhone">Mail Id: il.iic.mic@aicte-india.org</p>
				</div>
			</div>
		</div>
	</div>
</section>

<a href="#" id="scroll" style="display: none;"><span></span></a>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
