<div class="instDownloadTable">
    <div class="container">



        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <td colspan="3" allign="left" class="danger"
                                style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Downloads List</h1>
                            </td>
                        </tr>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Title</th>
                            <th scope="col">Report</th>
                        </tr>
                    </thead>
                    <tbody>
                      <!-- <tr *ngIf="ImpactGranted=='1'">
                        <td class="starD">
                            <mat-icon>stars</mat-icon>
                        </td>
                        <td>Sanction Letter - Impact Lecture</td>
                        <td class="downloadD">
                            <a href="https://api.mic.gov.in/uploads/institutes/impact_sanction_letter/{{userIdFile}}.pdf" target="_blank" class="btn btn-success btnDownSuccess">
                                Download
                            </a>
                        </td>
                      </tr>

                      <tr *ngIf="conscentStatus=='1' && usertype=='Mentor' && funded_by=='MIC'">
                        <td class="starD">
                            <mat-icon>stars</mat-icon>
                        </td>
                        <td>Sanction Letter - Mentor Mentee Scheme</td>
                        <td class="downloadD">
                            <a href="https://api.mic.gov.in/uploads/institutes/mm_sanction_letter/{{userIdFile}}.pdf" target="_blank" class="btn btn-success btnDownSuccess">
                                Download
                            </a>
                        </td>
                      </tr> -->
                      <!-- && isEnable==1 -->
                      <tr *ngIf="il_mandate==1 ">
                        <td class="starD">
                            <mat-icon>stars</mat-icon>
                        </td>
                        <td>Sanction Letter - Impact Lecture</td>
                        <td class="downloadD">
                            <button type="button" class="btn btn-success btnDownSuccess" (click)="download('Impact')">
                                Download
                            </button>
                        </td>
                      </tr>
                      <tr *ngIf="mm_mandate==1">
                        <td class="starD">
                            <mat-icon>stars</mat-icon>
                        </td>
                        <td>Sanction Letter - Mentor Mentee Scheme</td>
                        <td class="downloadD">
                            <button type="button" class="btn btn-success btnDownSuccess" (click)="download('Mentor-Mentee')">
                                Download
                            </button>
                        </td>
                      </tr>

                      <tr *ngFor="let down of downloads;let i = index" >
                          <td class="starD">
                              <mat-icon>stars</mat-icon>
                          </td>
                          <td>{{down?.title}}</td>
                          <td class="downloadD" *ngIf="down?.id!='2'">
                              <a href="{{down?.report_upload}}" target="_blank"
                                  class="btn btn-success btnDownSuccess">
                                  Download
                              </a>
                          </td>

                          <td class="downloadD" *ngIf="down?.id=='2'">
                              <a routerLink="/institute/dashboard/18-19"
                                  class="btn btn-success btnDownSuccess">
                                  Download
                              </a>
                          </td>

                      </tr>
                        <!-- <tr>
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>View and download 2018-19 Reports</td>
                            <td class="downloadD">
                                <a routerLink="/institute/dashboard/18-19" class="btn btn-success btnDownSuccess">
                                    Download
                                </a>
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>Guidelines to form IIC Council</td>
                            <td class="downloadD">
                                <a href="assets/html/index.html" class="btn btn-success btnDownSuccess" target="_blank">
                                  Download
                                </a>
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td> IIC Activity Calendar 2020-21</td>
                            <td class="downloadD">
                                <a href="https://api.mic.gov.in/uploads/admin/images/download/27430_download.pdf" class="btn btn-success btnDownSuccess" target="_blank">
                                Download
                                </a>
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>IIC Logo</td>
                            <td class="downloadD">
                                <a href="https://api.mic.gov.in/uploads/admin/images/download/53214_download.png" class="btn btn-success btnDownSuccess" target="_blank">
                                 Download
                                </a>
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td class="starD">
                                <mat-icon>stars</mat-icon>
                            </td>
                            <td>National Innovation and Startup policy 2019 for students and faculties</td>
                            <td class="downloadD">
                                <a href="assets/doc/startup_policy_2019.pdf" target="_balnk"
                                    class="btn btn-success btnDownSuccess">
                                Download
                                </a>
                            </td>
                        </tr> -->

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
