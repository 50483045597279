<!--title-->
   <title>

    MHRD | Innovation Cell - Home
    </title>
    <!--end of title--->
	<link rel="stylesheet" href="/assets/css/bootstrap.min.css">
	<!-- <link rel="stylesheet" href="/assets/css/style.css" type="text/css"> -->
	<link rel="stylesheet" type="text/css" href="/assets/vendors/select2/select2.min.css">
<!--===============================================================================================-->
	<link rel="stylesheet" type="text/css" href="/assets/vendors/select2/main.css">
<!--===============================================================================================-->
<style>
.help-block{
	color:red;
}
.container-contact100 {
   
    background: linear-gradient(45deg, #c49f47, #abe7ed) !important;
}
.wrap-contact100 {
padding: 18px 10px 45px 25px !important;
}
.wrap-contact100 {
    width: 100% !important;
}
.wrapBorder{
	border-bottom: 2px solid #d9d9d9;
    padding-bottom: 13px;
    margin-bottom: 27px;
}
.label-input100 {
    font-size: 20px !important;
}
.input100 {
font-size: 14px !important;
}
</style>

<!-- Header -->
<app-inner-navbar></app-inner-navbar>



<div class="container-contact100">
		<div class="wrap-contact100">
		<div class="col-md-12">
		<span class="col-md-3"><img src="assets/images/ashok_icon.jpg" style="width:36%;float: left;"></span>
		<span class="col-md-3" style="text-align:center;"><img src="assets/images/MIC_LOGO.png" style="width:60%;"></span>
		<span class="col-md-3" style="text-align:right;"><img src="assets/images/ugc_logo.jpg" style="width:34%;"></span>
		<span class="col-md-3"><img src="assets/images/aicte_logo.png" style="width:24%;float: right;"></span>
		</div>
		<div class="col-md-12" style="text-align:center;margin-bottom:21px;margin-top:21px;">
		<span style="font-size:20px;color:rgb(191,49,26);font-weight:900;">Sign up for Joining the Campaign on Implementing National Innovation and Startup Policy in Higher Educational Institutions across the Nation</span>
		</div>
			<form class="contact100-form validate-form" id="registrationForm" role="form" method="post">
				<div class="row">
                <div class="wrapBorder col-md-6 validate-input" data-validate = "Institute name is required">
					<span class="label-input100">AISHE Code <a href="http://aishe.gov.in/aishe/aisheCode" style="color:blue;float:right;" target="_blank"><u>Know your AISHE code</u></a></span>
					<input class="input100" name="insAisheCode"
					id="insAisheCode" value=""  placeholder="Enter AISHE code" required>
					<span class="focus-input100"></span>
					
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Name is required">
					<span class="label-input100">University/Institute Name</span>
					<input class="input100" name="insName" id="insName" value="" placeholder="Name of institute" required>
					<span class="focus-input100"></span>
				</div>
				</div>
				<div class="row">
				<div class="wrapBorder col-md-6 validate-input">
					<span class="label-input100">City</span>
					<input class="input100" name="insCity" id="insCity" value="" placeholder="City of institute">
					<span class="focus-input100"></span>
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "State is required">
					<span class="label-input100">State</span>
					<input class="input100" name="insState" id="insState" value="" placeholder="State of institute">
					<span class="focus-input100"></span>
				</div>
				</div>
				<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "State is required">
					<span class="label-input100">Correspondence Address</span>
					<input class="input100" name="insAddress" id="insAddress" value="" placeholder="Enter address" required="">
					<span class="focus-input100"></span>
				</div>
				<!--<div class="wrapBorder col-md-6 input100-select">
					<span class="label-input100">Type of instiute</span>
					<input class="input100" name="instituteType" id="instituteType" value="" placeholder="Type of instiute">
					<span class="focus-input100"></span>
				</div>-->
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Type of instiute">
					<span class="label-input100">Type of instiute</span>
					<div>
						<select class="selection-2" name="instituteType" id="instituteTypes" required>
							<option value="">Select</option>
							<option value="Institute of National Importance & Central Universities/CFTIs">Institute of National Importance & Central Universities/CFTIs</option>
							<option value="State University & Deemed Universities (Govt. & Govt. Aided)">State University & Deemed Universities (Govt. & Govt. Aided)</option>
							<option value="Govt. Colleges/Institutes (Affiliated or Standalone)">Govt. Colleges/Institutes (Affiliated or Standalone)</option>
							<option value="University & Deemed University (Private)">University & Deemed University (Private)</option>
							<option value="Private Colleges/Institutes (Affiliated or Standalone)">Private Colleges/Institutes (Affiliated or Standalone)</option>
						</select>
					</div>
					
				</div>
				</div>
				<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Name is required">
					<span class="label-input100">Name of the Head of institute</span>
					<input class="input100" name="inputName" id="inputName" value="" placeholder="Name of the Head of institute" required>
					<span class="focus-input100"></span>
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Email is required">
					<span class="label-input100">Email id of the Head of institute</span>
					<input class="input100" name="inputEmail" id="inputEmail" value="" placeholder="Email id of the Head of institute" required>
					<span class="focus-input100"></span>
				</div>
				</div>
				<div class="row">
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Phone No. is required">
					<span class="label-input100">Contact Number of the Head of institute</span>
					<input class="input100" name="inputMobile" id="inputMobile" value="" placeholder="Contact Number of the Head of institute" min="0" minlength="10" maxlength="10" required>
					<span class="focus-input100"></span>
				</div>
				<div class="wrapBorder col-md-6 validate-input" data-validate = "Phone No. is required">
					<span class="label-input100">Designation of the Head of institute</span>
					<div>
						<select class="selection-2" name="inputDesignation" id="inputDesignation" required>
							<option value="">Select</option>
							<option value="Vice Chancellor">Vice Chancellor</option>
							<option value="Director">Director</option>
							<option value="Principal">Principal</option>
						</select>
					</div>
					
				</div>
				</div>
				
				<div class="row">
				<div class="wrapBorder col-md-6 input100-select" style="padding-bottom: 23px;">
					<span class="label-input100" style="padding: 0px;">Do you think this policy will help in promotion of Innovation and Startup ecosystem in Higher Education Institutions?</span><br><br>
					<input type="radio" name="educationInstitutions" id="educationInstitutions1" value="1" style="margin: 20px;">Yes
					<input type="radio" name="educationInstitutions" id="educationInstitutions0" value="0" style="margin: 20px;">No
					<span class="focus-input100"></span>
				</div>
              
			  <div class="wrapBorder col-md-6 input100-select">
					<span class="label-input100" style="padding: 0px;">Do you have laid down any such policy at your institute or in practice to promote and support innovation and startup among students and/or faculty?</span>
					<br>
					<input type="radio" name="facultyStudents" id="facultyStudents1" value="1" style="margin: 20px;">Yes
					<input type="radio" name="facultyStudents" id="facultyStudents0" value="0" style="margin: 20px;">No
					<span class="focus-input100"></span>
				</div>
				</div>
				
				<div class="wrap-input100 input100-select">
					<span class="label-input100" style="padding: 0px;">Are you willing to participate in this policy initiative of promoting innovation and startup among students and faculties and staff by implementing it at your institution? </span><br>
					<input type="radio" name="initiativeInstitutions" id="initiativeInstitutions1" value="1" style="margin: 20px;">Yes
					<input type="radio" name="initiativeInstitutions" id="initiativeInstitutions0" value="0" style="margin: 20px;">No
					<span class="focus-input100"></span>
				</div>
				<div class="wrap-input100 input100-select">
					<span class="label-input100" style="padding: 0px;">Does your institute participate in following MHRD Initiatives towards promoting Innovation, IPR and Entrepreneurship in HEIs? (Please tick appropriate options, multi selection is allowed) </span><br>
					<input type="checkbox" name="HEIs[]" id="initiativeInstitutions1" value="Participation in Atal Ranking of Institutions on Innovation Achievement (ARIIA) for HEIs" style="margin: 20px;">Participation in Atal Ranking of Institutions on Innovation Achievement (ARIIA) for HEIs<br>
					<input type="checkbox" name="HEIs[]" id="initiativeInstitutions0" value="Established Institution's Innovation Council (IIC) and Actively Organizing Activities on Campus" style="margin: 20px;">Established Institution's Innovation Council (IIC) and Actively Organizing Activities on Campus<br>
					<input type="checkbox" name="HEIs[]" id="initiativeInstitutions1" value="Conducting Internal Hackathons and Participation of Students in Smart India Hackathon (SIH)" style="margin: 20px;">Conducting Internal Hackathons and Participation of Students in Smart India Hackathon (SIH)<br>
					<input type="checkbox" name="HEIs[]" id="initiativeInstitutions0" value="E-Learning on Entrepreneurship, Innovation and IPR by using the SWAYAM Platform Adoption of Internship Policy of AICTE and Allowing Students to join Startup and Innovation Projects for Internship" style="margin: 20px;">E-Learning on Entrepreneurship, Innovation and IPR by using the SWAYAM Platform Adoption of Internship Policy of AICTE and Allowing Students to join Startup and Innovation Projects for Internship<br>
					<input type="checkbox" name="HEIs[]" id="initiativeInstitutions0" value="Adoption of Induction Policy of AICTE and Orientation on Innovation & Startup as Alternative Career Option" style="margin: 20px;">Adoption of Induction Policy of AICTE and Orientation on Innovation & Startup as Alternative Career Option<br>
					<input type="checkbox" name="HEIs[]" id="initiativeInstitutions0" value="None of the above" style="margin: 20px;">None of the above
					<span class="focus-input100"></span>
				</div>
				
				<input type="hidden" id="token" name="token" value="1591952543" />
				<div class="container-contact100-form-btn">
					<div class="wrap-contact100-form-btn">
						<div class="contact100-form-bgbtn" style="background: linear-gradient(45deg, #c49f47, #abe7ed) !important;"></div>
						<button class="contact100-form-btn">
							<span>
								Submit
							</span>
						</button>
					</div>
				</div>
				<input type="hidden" name="insStatus" id="insStatus" value="">
			</form>
		</div>
	</div>

	<div id="dropDownSelect1"></div>

<!--===============================================================================================-->
	<script src="/assets/vendor/jquery/jquery-3.2.1.min.js"></script>
<!--===============================================================================================-->
	<script src="/assets/vendor/select2/select2.min.js"></script>
	<script type="text/javascript" src="/assets/js/jquery.validate.min.js"></script>
	<script>
	function showOther(id){
		if(id==3){
			$("#other_lebel").css('display','block');
			$("#other").prop('disabled', false);

		}else{
			$("#other_lebel").css('display','none');
			$("#other").prop('disabled', true);
		}
	}
		$(".selection-2").select2({
			minimumResultsForSearch: 20,
			dropdownParent: $('#dropDownSelect1')
		});
		//Registration Form Validation

$("#registrationForm").validate({
	rules: {
						
			inputName:
			{
				required: true
			},
			inputEmail: 
			{
				required: true,				
			},
			
			inputMobile:
			{
				required: true,
				minlength:10,
				maxlength:10,
			},
			inputDesignation:
			{
				required: true,
			},
			insAisheCode:
			{
				required: true,
			},
			insName:
			{
				required: true,
			},
			insCity:
			{
				required: true,
			},
			insState:
			{
				required: true,
			},insAddress:
			{
				required: true,
			},instituteType:
			{
				required: true,
			},educationInstitutions:
			{
				required: true,
			},facultyStudents:
			{
				required: true,
			},initiativeInstitutions:
			{
				required: true,
			},
	},
		messages: {
			
			inputName: {required:'Please enter Name of the Head of institute.'},
			inputEmail: {required: 'Please select Email id of the Head of institute.'},
			inputMobile: {required: 'Please enter Contact Number of the Head of institute.'},
			inputDesignation: {required: 'Please enter Designation of the Head of institute.'},
			insAisheCode: {required: 'Please enter Enter AISHE code'},
	},
		
		highlight: function(element) {
			$(element).closest('.form-group').addClass('has-error');
		},
		unhighlight: function(element) {
			$(element).closest('.form-group').removeClass('has-error');
		},
		errorElement: 'span',
		errorClass: 'help-block',
		errorPlacement: function(error, element) 
		{
			if(element.parent('.input-group').length) 
				{
				error.insertAfter(element.parent());
				} 
			else 
				{
				error.insertAfter(element);
				}
		}
});
		//Proceed Button
$('#registrationForm').submit(function(event) {
	event.preventDefault();
	 var insStatus=$("#insStatus").val();
	//if(insStatus==1){
		if($("#registrationForm").valid())
{	
			$.ajax({
			type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
			url         : 'add_startup_application.php', // the url where we want to POST
			data        : $('#registrationForm').serialize()+'&do=proceed', // our data object
			dataType    : 'text', // what type of data do we expect back from the server
			encode      : true, 
			success:function(data) {
				var reply = data.replace(/\s+/, "");
				if(reply==1){
					alert('Created Successfully.');
                    location.reload();					
				}else if(reply==2){
					alert('This Email already exist.');
				}else if(reply==3){
					alert('This mobile number already exist.');
				}else if(reply==4){
					alert('Expire.');
					location.reload(); 
				}
				else{
				alert(reply);
				}
				
			}
		});		
		event.preventDefault();
	// }else{
		// alert('Please enter correct AISHE code');
	// }
}
	});
	
	$(document).on('keyup change blur',"#insAisheCode", function() {
var words = $(this).val();      
if(words!='')
{
$.ajax({
		type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
		url         : 'institute/session/verifyAisheCode.php', // the url where we want to POST
		data        : {Code:words}, // our data object
		dataType    : 'text', // what type of data do we expect back from the server
		encode      : true, 
		
		success:function(data) {
			var reply = JSON.parse(data);
			if(reply.sts!=0){
			$("#insName").val(reply.name);	
			$("#insCity").val(reply.city);	
			$("#insState").val(reply.state);	
			$("#insAddress").val(reply.address_line1);
			$("#instituteType").val(reply.type);
			$("#insStatus").val(1);
			$('#appErr').text("");
			}else{
				$('#appErr').text("Please enter valid AISHE code.");
				$("#insName").val('');	
				$("#insCity").val('');	
				$("#insState").val('');	
				$("#insAddress").val('');
				$("#instituteType").val();
				$("#insStatus").val(0);
				
			}			
		}
		
  });
}
});
	</script>


<!-- Footer -->
<app-inner-footer></app-inner-footer>