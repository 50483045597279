import { Component, OnInit } from '@angular/core';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { Iic4ODialogComponent } from '../institute-dashboard2021/iic4o-dialog/iic4o-dialog.component';
import { Iic4ODialogComponent2 } from '../institute-dashboard2021/iic4o-dialog2/iic4o-dialog2.component';
import { AphVerificationDialogComponent } from './aph-verification-dialog/aph-verification-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-institute-dashboard2024',
  templateUrl: './institute-dashboard2024.component.html',
  styleUrls: ['./institute-dashboard2024.component.css']
})
export class InstituteDashboard2024Component implements OnInit {

  IicStatus4o:any;
  IicInsType:any;
  approvalCode:any;
  approvalStatus:any;
  constructor(private dashboardService: InstituteDashboardService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getUserIic4oStatus();
  }

  getUserIic4oStatus() {
    this.dashboardService.getUserIic4oStatus().subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.IicStatus4o = data['data']['IicStatus4o'];
    this.IicInsType = data['data']['IicInsType'];
    this.approvalCode = data['data']['approvalCode'];
    this.approvalStatus = data['data']['approval_status'];
    if(this.approvalStatus == 'Pending'){
    // if(this.approvalStatus){
        const dialogRef = this.dialog.open(AphVerificationDialogComponent, {
          data : data
        });
        dialogRef.afterClosed().subscribe(data => this.handleResponseDialog(data));
    }
    // alert(this.approvalCode);
    // if(this.IicStatus4o==0)
    // {
    //   const dialogRef = this.dialog.open(Iic4ODialogComponent, {});
    //   dialogRef.afterClosed().subscribe(data => this.handleResponseopen(data));
    // }
    // else
    // {
    //   if(this.IicInsType==0)
    //   {
    //     const dialogRef = this.dialog.open(Iic4ODialogComponent2, {});
    //     dialogRef.afterClosed().subscribe((result) => {
    //     });
    //   }

    // }

  }

  handleResponseDialog(data){

  }
  handleResponseopen(data)
  {
    if(this.IicInsType==0)
      {
        const dialogRef = this.dialog.open(Iic4ODialogComponent2, {});
        dialogRef.afterClosed().subscribe((result) => {
        });
      }
  }

}
