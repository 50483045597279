<div class="instDownloadTable">
    <div class="container">
<div class="row">

    <div class="col-md-8">
</div>

<div class="col-md-4">
    <app-export-file></app-export-file>
</div>
 

</div>

        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover" style="margin-bottom: 20px !important;">

                    <thead>
                        <tr>
                            <td colspan="3" allign="left" class="danger" style="text-align:center;color:blue;padding: 0 !important; height: auto;">
                                <h1 class="announcementHeadingMain">Activity Report Download</h1>
                            </td>
                        </tr>

                        <tr>
                            <th scope="col"> Sr. No.</th>
                            <th scope="col">Title</th>
                            <th scope="col">Report</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of list| paginate: { itemsPerPage: 5, currentPage: page }; let i = index">
                            <td>{{item?.serial_number}}</td>
                            <td>{{item.title}}</td>
                            <td class="downloadD"><a (click)="getActivityReport(item.eventId)" class="btn btn-success btnDownSuccess">
                                    <!-- <mat-icon>cloud_download</mat-icon> --> Download
                                </a></td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls class="annular-pagination float-right" maxSize="3"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
