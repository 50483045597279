import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';


@Component({
  selector: 'app-institute-evaluation-details',
  templateUrl: './institute-evaluation-details.component.html',
  styleUrls: ['./institute-evaluation-details.component.css']
})
export class InstituteEvaluationDetailsComponent implements OnInit {
  dataShow:any;
  evalData:any;
  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private innovationService: InstituteInnovationContestService,
    public dialogRef: MatDialogRef<InstituteEvaluationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { this.dataShow=data; }

  ngOnInit(): void
  {
    this.innovationService.getVerificationDetails(this.dataShow['idea_id_enc']).subscribe(
      data => this.handleResponseShow(data)
    );

  }

  handleResponseShow(data)
  {
    this.evalData = data['Eval'];
  }

  removeEvaluator(id, title, eval_id)
  {
    alert(id);
    alert(eval_id);

    if (confirm("Are you sure want to remove verifier "+ title) == true) {
      this.innovationService.DeleteProtoFromVerification(id, eval_id).subscribe(
        data => this.handleResponseDelete(data)
      );
    }
  }

  handleResponseDelete(data)
  {
    if(data["status"]==1)
    {
      alert("Evaluator removed successfully!");
      this.dialogRef.close();
    }
    else
    {
      alert("Evaluator unable to de-allocate!");
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
