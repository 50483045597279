import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteIdeaSubmission21Component } from '../institute-idea-submission.component';


@Component({
  selector: 'app-institute-idea-submission-dialog',
  templateUrl: './institute-idea-submission-dialog.component.html',
  styleUrls: ['./institute-idea-submission-dialog.component.css']
})
export class InstituteIdeaSubmissionDialog21Component implements OnInit {

  public ideaForm: FormGroup;
  pocScore: any;
  commentFlag: boolean = false;
  ideaScoreFlag: boolean = false;
  YIC_idea:any;

  constructor(private fb: FormBuilder,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteIdeaSubmission21Component>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.ideaForm = this.fb.group({
      status: [null],
      score: [null, Validators.compose([Validators.max(50), Validators.min(2)])],
      comment: [null],
    });

    if (this.data['team_lead_score'] != '0' && this.data['team_lead_score'] != '') {
      this.ideaScoreFlag = true;
    }

    this.innovationService.submitYuktiSubmissionData21(this.data?.id).subscribe(
      data => this.handleResponse(data)
    );

  }

  onSubmit(values) {
    values['id'] = this.data['idea_id'];
    values['type'] = 'Idea';
    this.innovationService.submitIdeaSubmission21(values).subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.YIC_idea = data['ICF'];

    // if (data['status'] == '1') {
    //   this.toastr.success(data['msg']);
    //   this.dialogRef.close();
    // } else {
    //   this.toastr.error(data['msg']);
    // }



  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 46) return true;

    return false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
