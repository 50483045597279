import { Component, OnInit } from '@angular/core';
import { IicOnlineSessionsService } from 'src/app/Services/iic-online-sessions.service';
import * as myGlobals from '../../../app.component';

@Component({
  selector: 'app-iic-online-sessions',
  templateUrl: './iic-online-sessions.component.html',
  styleUrls: ['./iic-online-sessions.component.css']
})
export class IicOnlineSessionsComponent implements OnInit {
 baseImageUrl = myGlobals.baseImageUrl;

  iicOnlineSessions = [];
  constructor(private iicOnlineSessionsService: IicOnlineSessionsService) { }

  ngOnInit(): void {
    this.iicOnlineSessionsService
      .getIICOnlineSessions()
      .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data) {
    this.iicOnlineSessions = data;

  }

}
