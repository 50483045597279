<div class="cbp-row wh-footer">
			<div class="cbp-container container wh-padding footerMainBottomBox">
				<section class="vc_section">
					<div id="footer-top" class="vc_row wpb_row vc_row-fluid vc_custom_1498816027003">
						<div class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-12 vc_col-lg-12">
							<div class="vc_column-inner vc_custom_1498815965908">
								<div class="wpb_wrapper">
									<div class="vc_row wpb_row vc_inner vc_row-fluid">
										<div class="wpb_column vc_column_container vc_col-xs-12 vc_col-sm-6 vc_col-md-6 qlinkMob vc_col-lg-3">
											<div class="vc_column-inner">
												<div class="wpb_wrapper wpb_wrapper_footer">
													<h5 style="font-size: 18px;color: #ffffff;text-align: left" class="vc_custom_heading vc_custom_1496749370573 footerLink">Quick Links</h5>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456319476"><a routerlink="/home" href="/home"><i class="fa fa-caret-right" aria-hidden="true"></i> Home</a></div>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456323955"><a href="#"><i class="fa fa-caret-right" aria-hidden="true"></i> Innovation Council</a></div>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1555409007585"><a href="https://www.sih.gov.in/" target="_blank"><i class="fa fa-caret-right" aria-hidden="true"></i> Smart India Hackathon</a></div>
													<!-- <div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1555409007585"><a href="https://ariia.gov.in/" target="_blank"> <i class="fa fa-caret-right" aria-hidden="true"></i> ARIIA</a></div> -->
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456331131"><a href="https://yukti.mic.gov.in/" target="_blank"><i class="fa fa-caret-right" aria-hidden="true"></i> Yukti 2.0</a></div>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456331131"><a href="https://innovateindia.mygov.in/ddh2020/" target="_blank"><i class="fa fa-caret-right" aria-hidden="true"></i> DDH</a></div>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456331131"><a href="https://nisp.mic.gov.in" target="_blank"><i class="fa fa-caret-right" aria-hidden="true"></i> NISP</a></div>
												</div>
											</div>
										</div>
										<div class="wpb_column vc_column_container vc_col-xs-12 vc_col-sm-6 vc_col-md-6 vc_col-lg-3">
											<div class="vc_column-inner vc_custom_1490007556855">
												<div class="wpb_wrapper wpb_wrapper_footer">
													<h5 style="font-size: 18px;color: #ffffff;text-align: left" class="vc_custom_heading vc_custom_1496749480644 footerLink">Related Links</h5>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456323955"><a href="https://www.aicte-india.org/" target="_blank"><i class="fa fa-caret-right" aria-hidden="true"></i> All India Council for Technical Education</a></div>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456323955"><a href="https://mhrd.gov.in/" target="_blank"><i class="fa fa-caret-right" aria-hidden="true"></i> Ministry of Education</a></div>													
												</div>
											</div>
										</div>
										<div class="wpb_column vc_column_container vc_col-xs-12 vc_col-sm-6 vc_col-md-6 vc_col-lg-3">
											<div class="vc_column-inner vc_custom_1490007547463">
												<div class="wpb_wrapper wpb_wrapper_footer">
													<h5 style="font-size: 18px;color: #ffffff;text-align: left" class="vc_custom_heading vc_custom_1496749498711 footerLink">Social Media Links</h5>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456367890"><a href="https://twitter.com/mhrd_innovation" target="_blank"><i class="fa fa-twitter twi-Footer" aria-hidden="true"></i> Twitter</a></div>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456359690"><a href="https://www.facebook.com/mhrdInnovation/" target="_blank"><i class="fa fa-facebook fab-footer" aria-hidden="true"></i> Facebook</a></div>
													<!-- <div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456355098"><a href="https://www.youtube.com/mhrdinnovationcell" target="_blank"><i class="fa fa-youtube-play ytube-footer" aria-hidden="true"></i> Youtube</a></div> -->

													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456355098"><a href="https://www.youtube.com/@MoEInnovationCell" target="_blank"><i class="fa fa-youtube-play ytube-footer" aria-hidden="true"></i> Youtube</a></div>
													<div style="font-size: 15px;text-align: left" class="vc_custom_heading vc_custom_1495456349979"><a href="https://www.instagram.com/mhrd.innovationcell/" target="_blank"><i class="fa fa-instagram inst-footer" aria-hidden="true"></i> Instagram</a></div>													
												</div>
											</div>
										</div>
										<div class="get-in-touch-block wpb_column vc_column_container vc_col-xs-12 vc_col-sm-6 vc_col-md-6 vc_col-lg-3">
										<div class="vc_column-inner vc_custom_1498816102915">
											<div class="wpb_wrapper wpb-wrapper-footer-last wpb_wrapper_footer">
												<h5 style="font-size: 18px;color: #ffffff;text-align: left" class="vc_custom_heading vc_custom_1496749513529 footerLink">Contact</h5>
												<p style="font-size: 14px;color: #ffffff;line-height: 5px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
													<i class="fa fa-list-alt"></i> <a href="#"> MoE's Innovation Cell</a>
												</p>
												<p style="font-size: 14px;color: #ffffff;line-height: 20px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
													<i class="fa fa-location-arrow"></i> <a href="https://www.aicte-india.org/" target="_blank"> All India Council for Technical Education (AICTE), Nelson Mandela Marg, VasantKunj, New Delhi-110070.</a>
												</p>
												<!-- <p style="font-size: 14px;color: #ffffff;line-height: 5px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
													<i class="fa fa-phone"></i> <a href="tel:011 29581316"> 011-29581316</a>
												</p> -->
												
												<p style="font-size: 14px;color: #ffffff;line-height: 5px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
													<i class="fa fa-phone"></i> <a href="tel:011 2958 1227"> 011 2958 1513</a>
												</p>
												<p style="font-size: 14px;color: #ffffff;line-height: 5px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
													<i class="fa fa-envelope-o"></i> <a href="mailto:iic.mhrd@aicte-india.org"> iic.mhrd@aicte-india.org</a>
												</p>
											</div>
										</div>
									</div>
									</div>
								</div>
							</div>
						</div>

						
					</div>
					<!-- Row Backgrounds -->
					
					
					<!-- Row Backgrounds -->
					
					<div class="vc_row wpb_row vc_row-fluid vc_custom_1496911172780 bottomFooter">
						<div class="wpb_column vc_column_container vc_col-sm-6 vc_col-xs-6">
							<div class="vc_column-inner vc_custom_1491466992847 bottomTermsBox">
								<div class="wpb_wrapper">
									<p style="text-align: left" class="vc_custom_heading" >Copyright IIC © 2020 </p>
								</div>
							</div>
						</div>
						<div class="wpb_column vc_column_container vc_col-sm-6 vc_col-xs-6">
							<div class="vc_column-inner vc_custom_1491466989293 allRightReserveBox">
								<div class="wpb_wrapper">
									<p style="text-align: right" class="vc_custom_heading">All Rights Reserved</p>
								</div>
							</div>
						</div>
					</div>
					<!-- Row Backgrounds -->
					<div class="upb_color" data-bg-override="0" data-bg-color="#030a1c" data-fadeout="" data-fadeout-percentage="30" data-parallax-content="" data-parallax-content-sense="30" data-row-effect-mobile-disable="true" data-img-parallax-mobile-disable="true" data-rtl="false"  data-custom-vc-row=""  data-vc="6.2.0"  data-is_old_vc=""  data-theme-support=""   data-overlay="false" data-overlay-color="" data-overlay-pattern="" data-overlay-pattern-opacity="" data-overlay-pattern-size=""></div>
				</section>
			</div>
		</div>
		
<div id="mySidenav" class="sidenav">
    <a routerLink="/institute-list" id="register">
		<img src="assets/assets/images/iic-sticky.png" class="img-responsive">
	</a>
	<!-- <a routerLink="/institute-list" id="register2">
		<img src="assets/assets/images/iic-sticky2.png" class="img-responsive">
	</a> -->
</div>

		