import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { AddDialogImpactComponent } from '../../add-dialog-impact/add-dialog-impact.component';
import * as myGlobals from '../../../../../../../app.component';

@Component({
  selector: 'app-nominate-form2-dialog',
  templateUrl: './nominate-form2-dialog.component.html',
  styleUrls: ['./nominate-form2-dialog.component.css']
})
export class NominateForm2DialogComponent implements OnInit {
  show1: boolean;
  show: boolean;
  public impactAnalysis: FormGroup;
  baseImageUrl = myGlobals.baseImageUrl2;

  fbl: any;
  fbl1: any;
  id1: any;
  name1: any;
  email1: any;
  mobile1: any;
  designation1: any;
  deaprtment1: any;
  experience_in_years1: any;
  // roles1: any;
  qualification1: any;
  facultyModel = [];
  sessionModel: any;
  sessionModeModel: any;
  themeModel:any;
  theme2Model:any;
  // Role = ['Vice President', 'Convener', 'Social Media', 'Innovation Activity', 'Internship Activity Coordinator', 'IPR Activity Coordinator', 'Start up Activity Coordinator', 'ARIIA Coordinator', 'NIRF Coordinator', 'Member'];
  role1: any;
  Role: any;
  theme: any[];
  head: any[];
  date: any;
  id: any;
  convenor_name: any;
  convenor_email: any;
  convenor_contact: any;
  convenor_designation: any;
  convenorSession: any;
  myDate = (new Date());latest_date: any;
  minDate = "2021-09-08";
  maxDate = "2021-11-11";
  update: number;
  updateSession: any;
  name: any;
  externalCV: FileList | null;
  actualDate: any;
  report: any;
  showCV: boolean;
  session: any;
  one: number;
  two: number;
  disable_fields:any;
  constructor(private fb: FormBuilder,
    private jarwis: JarwisService,
    private myCouncilService: InstituteMyCouncilService,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<AddDialogImpactComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {


    if ((this.data == null) || (this.data == undefined)) {
      this.show1 = true;
      this.getcheckSessionDetails()

    }
    else {
      this.show = true;
    }
  }

  ngOnInit(): void {

    this.disable_fields = 0;

    this.impactAnalysis = this.fb.group({
      'session': [null],
      'title': [null, Validators.required],
      // 'date': [null, Validators.required],
      'theme': [null, Validators.required],
      'theme2': [null, Validators.required],
      'title2': [null, Validators.required],
      'mode': [null, Validators.required]
    });
  this.innovationService.getThemes().subscribe(
    data => this.setThemeDropdown(data)
  );


  if ((this.data != null ) || (this.data != undefined )) {
  this.setUpdateData(this.data);
  }
  this.latest_date=this.changeDate(this.myDate)

}




getcheckSessionDetails(){
  this.innovationService.getcheckSessionDetails().subscribe(
    data => this.setcheckSessionDetails(data)
  );
}

setcheckSessionDetails(data){
  this.convenorSession=data['data']['0']['session_no']
  if(this.convenorSession=='Session 1'){
    this.one=1;
    this.two=0
  }
  if(this.convenorSession=='Session 2'){
    this.two=1;
    this.one=0;
  }
  // this.convenor_email=data['data']['0']['faculty_email']
  // this.convenor_contact=data['data']['0']['faculty_contact']
  // this.convenor_designation=data['data']['0']['faculty_designation']
}




setThemeDropdown(data){
  this.theme=[];
      for(let i=0;i<data['themes'].length;i++){
        this.theme.push(data['themes'][i])
      }
    }


    setUpdateData(data){
      // this.impactAnalysis.controls['faculty'].setValue(data['faculty_name']);
      // this.facultyModel=data['faculty_name']
      this.impactAnalysis.controls['mode'].setValue(data['mode']);
      this.impactAnalysis.controls['title'].setValue(data['title']);
      this.impactAnalysis.controls['title2'].setValue(data['title2']);

      // this.impactAnalysis.controls['date'].setValue(data['date']);
      // var x=data['session'].toString();
      // this.updateSession=x;
      // this.impactAnalysis.controls['session'].setValue(x);
      // this.sessionModel=x;
      this.impactAnalysis.controls['theme'].setValue(data['theme_id']);
      this.themeModel=data['theme_id'];
      this.impactAnalysis.controls['theme2'].setValue(data['theme_id2']);

      this.theme2Model=data['theme_id2'];

      this.sessionModeModel=data['mode']
      this.id=data['id']
     this.update=1;

     this.disable_fields = data['id']



     if(data['session']=='Session 1'){
      this.one=1;
      this.two=0;
      

    }
    if(data['session']=='Session 2'){
      this.two=1;
      this.one=0;
      this.impactAnalysis.controls['session'].setValue(data['session']);
    }

    this.impactAnalysis.controls['session'].setValue(data['session']);
     if(this.update!=1)
     {
       this.sessionModel=this.convenorSession;
     }else{
   this.sessionModel=this.updateSession;
     }

    //  this.name=data['faculty_name']


    //  this.report=data['file_upload']
    //  if(this.report!=null){
    //    this.showCV=true
    //  }
    }
    onNoClick(): void {
      this.dialogRef.close();
    }
    changeDate(values) {
      var value = '';
      value = value + new Date(values).getFullYear().toString();
      value = value + '-' + (new Date(values).getMonth() + 1);
      value = value + '-' + new Date(values).getDate().toString();

      values = value;

      return values;
    }

    onSubmit(data){

console.log(data);
      if(this.show1==true){
        // data['date']=this.changeDate(this.impactAnalysis.get('date').value);
        data['date']='0000-00-00';
        // if(this.update==1){
        //   data['session']=this.updateSession;
        // }else{
        //   data['session']=this.convenorSession;

        // }

        this.innovationService.submitSessionDetails(data).subscribe(
          data => this.handleResponseData(data));
      }

      if(this.show==true){
        // if(this.update==1){
        //   data['session']=this.updateSession;
        // }else{
        //   data['session']=this.convenorSession;

        // }
        // data['date']=this.changeDate(this.impactAnalysis.get('date').value);
        data['date']="0000-00-00";
        data['id']=this.id;
        this.innovationService.updateImapactSession(data).subscribe(
          data => this.handleResponseData(data));
      }

    }
    handleResponseData(data){
      if(data['status']=="1"){
        this.toastr.success(data['msg'])
        this.dialogRef.close();
      }
      else{
        this.toastr.error(data['msg']);
      }
    }

}
