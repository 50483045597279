<div class="mat-dialog-content">
<section class="contactHeading" style="padding-top: 0;">
	<div class="container">
		<div class="vc_row">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">For General Query</h2>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="contactUsBox contacts">
	<div class="container rounded contactMain">
		<div class="row">
			<div class="col-sm-6">
				<div class="get-in-touch-block wpb_column vc_column_container vc_col-sm-12">
					<div class="vc_column-inner vc_custom_1498816102915">
						<div class="wpb_wrapper wpb-wrapper-footer-last">
							<h5 style="font-size: 18px;color: #ffffff;text-align: left" class="vc_custom_heading vc_custom_1496749513529 contact">Contact</h5>
							<p style="font-size: 14px;color: #030a1c;line-height: 5px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
								<i class="fa fa-list-alt"></i> <a href="#"> MoE's Innovation Cell</a>
							</p>
							<p style="font-size: 14px;color: #030a1c;line-height: 20px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
								<i class="fa fa-location-arrow"></i> <a href="https://www.aicte-india.org/" target="_blank"> All India Council for Technical Education (AICTE), <br/>Nelson Mandela Marg, <br />VasantKunj, New Delhi-110070.</a>
							</p>
							<p style="font-size: 14px;color: #030a1c;line-height: 5px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
								<i class="fa fa-phone"></i> 
								<!-- <a href="tel:011 29581316"> 011 29581513/1223/1240/1519/1235</a> -->
								<a href="tel:011 29581316"> 011 29581513/1226/1240/1337/1513/1517</a>
							</p>
							<p style="font-size: 14px;color: #030a1c;line-height: 5px;text-align: left" class="vc_custom_heading vc_custom_1495456492688">
								<i class="fa fa-envelope-o"></i> <a href="mailto:iic.mhrd@aicte-india.org"> iic.mhrd@aicte-india.org</a>
							</p>
						</div>
					</div>
				</div>

				<div class="pt-lg-4 d-flex flex-row justify-content-start vc_col-sm-12">
					<div class="pad-icon"> <a class="fa fa-facebook text-white faContact" href="#"></a> </div>
					<div class="pad-icon"> <a class="fa fa-twitter text-white twContact" href="#"></a> </div>
					<div class="pad-icon"> <a class="fa fa-instagram text-white instaContact" href="#"></a> </div>
				</div>
			</div>
			<div class="col-sm-6 pad">
				<form [formGroup]="mailForm" (ngSubmit)="onSubmit(mailForm.value)" class="rounded msg-form form-horizontal">

				<div class="form-group">
            <mat-form-field class="w-100 example-full-width" appearance="outline">
              <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Subject</mat-label>
              <input class="form-control"  matInput placeholder="Subject" formControlName="category" required>
              </mat-form-field>
				</div>


					<mat-form-field class="w-100 example-full-width" appearance="outline">
					<mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
					<mat-label>Your Name</mat-label>
					<input class="form-control"  matInput placeholder="Your Name" formControlName="name" required>
				  </mat-form-field>

					<mat-form-field class="w-100 example-full-width" appearance="outline">
					<mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">mail</mat-icon>
					<mat-label>Email</mat-label>
					<input class="form-control"  matInput placeholder="Enter Your Email" formControlName="email" required>
				  </mat-form-field>


					<mat-form-field class="w-100 example-full-width" appearance="outline">
					  <mat-label>Message</mat-label>
					  <textarea class="form-control" matInput formControlName="message"></textarea>
					</mat-form-field>

				<div class="form-group d-flex justify-content-end"> <input type="submit" class="btn btn-primary text-white contactBut" value="Send Message"> </div>


				</form>
			</div>
		</div>
	</div>
</section>
</div>
