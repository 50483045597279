import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IicDashboard1920Service {

private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }


  getPieData() {
    return this.http.get(
      `${this.baseUrl}/iic-dashboard-pie/details`
    );
  }
getQuarterwiseBarData() {
    return this.http.get(
      `${this.baseUrl}/iic-dashboard-bar-qtr/details`
    );
  }



  getOverAllImapact(){
    return this.http.get(`${this.baseUrl}/getOverAllImapact`);

  }
}
