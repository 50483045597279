<div class="container-fluid" style="margin-top: 30px;">
<div class="row" color="primary">
	<div class="col-md-12">
      <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
          YUKTI-Idea Submissions</h2>
	</div>	  
  </div>
	<mat-card class="mat-elevation-z6 proInstCardMat">  
      <div class="row">
		<div class="col-md-12">
			<p><a class="btn btn-primary referralBut" routerLink='/institute/atlsic-yukti-submission-add/add'>Add New</a></p>
		</div>
		<div class="col-md-12">
        <div class="tableBody">
			  <div class="table-responsive">
		<table class="table">
		
          <thead class="facultyTable">
            <tr>
              <th width="5%">S.No.</th>
              <th width="10%">Team Details</th>
              <th width="20%">Idea Title</th>
              <th width="25%">Theme</th>
              <th width="25%">Academic Year</th>
              <th width="35%">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let activity of myActivity; let i = index">
        <td>{{i+1}}</td>
        <td>{{activity?.team_leader_name}}
            <br/>{{activity?.team_leader_email}}
            <br/>{{activity?.team_leader_phone}}
            <br/>{{activity?.team_leader_gender}}
            <br/>Standard: {{activity?.standard}}


        </td>
        <td>{{activity?.idea_title}}</td>
        <td>{{activity?.theme}}
            <br/>TRL: {{activity?.trl_level}}
        </td>
        <td>{{activity?.academic_year}}</td>
        <td>
          <p style="float:left;"><button mat-raised-button (click)="onClickEvent(activity?.id, 'Edit')"   color="warn" class="btn-tbl-edit">
            <mat-icon>edit</mat-icon>
            </button></p>

          <p><button mat-raised-button (click)="onClickEvent(activity?.id, 'Delete')"   color="warn" class="btn-tbl-delete">
              <mat-icon>delete</mat-icon>
              </button></p>
        </td>
        </tr>
    
        <tr *ngIf="total_pages==0"><td colspan="7" style=" font-size:18px; text-align: center; color: #721c24;
          background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
          There is no submission found.
        </td></tr>
    
          </tbody>
        </table>
		
		</div>
		</div>
		</div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
            [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
          </mat-paginator>
        </div>
      </div>
	  
	 </mat-card> 
	  
    </div>
