import { Component, OnInit } from '@angular/core';
import * as myGlobals from '../../../../app/app.component';
@Component({
  selector: 'app-iic-web-main-navbar',
  templateUrl: './iic-web-main-navbar.component.html',
  styleUrls: ['./iic-web-main-navbar.component.css']
})
export class IicWebMainNavbarComponent implements OnInit {

  baseUrl = myGlobals.baseUrl1;
  imageBasePath= myGlobals.imageBasePath;

  constructor() { }

  ngOnInit(): void {
  }

}
