import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { IncubationInchargeDialogComponent } from './incubation-incharge-dialog/incubation-incharge-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { InstituteSubscriptionComponent } from '../institute-subscription/institute-subscription.component'
import { Subscription } from 'rxjs';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { InstituteIncubationService } from 'src/app/Services/institute-incubation.service';
import { FileService } from 'src/app/Services/file.service';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as myGlobals from '../../../../../app.component';


@Component({
  selector: 'app-institute-incubation-centers',
  templateUrl: './institute-incubation-centers.component.html',
  styleUrls: ['./institute-incubation-centers.component.css'],
})
export class InstituteIncubationCentersComponent implements OnInit {

  baseImageUrl = myGlobals.baseImageUrl;

  [x: string]: any;
  public incubationCentersForm: FormGroup;
  cin_file_flag: boolean;
  cinFilePath: any;
  messages: any[] = [];
  subscription: Subscription;
  pin_no_6: any
  pin_no_5: any
  pin_no_4: any
  pin_no_3: any
  pin_no_2: any
  pin_no_1: any
  cin_number: any
  cin_file: FileList | null;
  fundingSchemes = [
    'DBT/BIRAC',
    'DST/NSTEDB/NIDHI',
    'AIM/NITI Ayog',
    'MeitY',
    'MSME',
    'AICTE-IDEALab',
    'CSR/Private',
    'Own Resource',
    'Other Central/State Government',
    'None'
  ];
  service_type: any[];
  facilities_services: any[];
  facilities_services_multi: any[];
  fundingModel: [];
  serviceModel: [];
  facilitiesModel: [];
  show1: boolean = false;
  type_services_offered: any[];
  registration_status_centre: any[];
  registered_as: any[];
  funding_schemes_support: any;
  facility: any[];
  cinFileFlag: boolean;

  inchareDetails = [];

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredThrustAreas: Observable<string[]>;
  thrustAreas: string[] = [];
  thrustAreasList: string[] = [];


// Change
  showCIN1:boolean;
  showregistered:boolean =true;
  registeredValue:any;
  @ViewChild('thrustAreaInput') thrustAreaInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  filterThrustAreaData;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private instituteIncubationService: InstituteIncubationService,
    private messageService: InstituteSubscriptionComponent,
    private fileService: FileService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.incubationCentersForm = this.fb.group({
      center_name: [null, Validators.required],
      funding_schemes_support: [null, Validators.required],
      // registration_type: [null, Validators.required],
      incubation_url: [null, null],
      incharge_name: [null, Validators.required],
      incharge_email: [
        null,
        Validators.compose([Validators.required, Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
        ),

      ],
      incharge_phone: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ],

      incubation_incharge_designation: [null, Validators.required],
      thrust_areas: [null],
      pin_no_1: [null],
      pin_no_2: [null],
      pin_no_3: [null],
      pin_no_4: [null],
      pin_no_5: [null],
      pin_no_6: [null],
      type_services_offered: [null, Validators.required],
      facilities_services: [null, Validators.required],
      registration_status_centre: [null, Validators.required],
      registered_as: [null],

      registration_number:[null]
    });

    this.filteredThrustAreas = this.incubationCentersForm
      .get('thrust_areas').valueChanges.pipe(
        startWith(null),
        map((fruit: string | null) => fruit ? this._filter(fruit) : this.thrustAreasList.slice()));
// Change

this.incubationCentersForm
.get('registered_as')
.valueChanges.subscribe((data) => this.changeValidationOfRegisteredAs(data));

//change
    this.incubationCentersForm
      .get('registration_status_centre')
      .valueChanges.subscribe((data) => this.changeValidation(data));



    this.subscription = this.messageService.onMessage().subscribe(message => {
      // this.incubationCentersForm.reset();

      if (message) {
        // window.location.reload();

        if (message.text.cin_file) {
          this.cinFileFlag = true;
          this.cinFilePath = message.text.cin_file;
        }

        this.messages.push(message);
        if (message.text.id) {

          // let inchargeName;
          // let inchargeNameArray = [];
          // let inchargeEmail;
          // let inchargeEmailArray = [];
          // let inchargePhone;
          // let inchargePhoneArray = [];
          // let inchargeDesignation;
          // let inchargeDesignationArray = [];

          // message.text.incubation_incharge_name.forEach((element, index) => {
          //   if (index == 0) {
          //     inchargeName = element
          //   } else {
          //     inchargeNameArray.push(element)
          //   }
          // });

          // message.text.incubation_incharge_email.forEach((element, index) => {
          //   if (index == 0) {
          //     inchargeEmail = element
          //   } else {
          //     inchargeEmailArray.push(element);
          //   }
          // });

          // message.text.incubation_incharge_phone.forEach((element, index) => {
          //   if (index == 0) {
          //     inchargePhone = element
          //   } else {
          //     inchargePhoneArray.push(element);
          //   }
          // });

          // message.text.incubation_incharge_designation.forEach((element, index) => {
          //   if (index == 0) {
          //     inchargeDesignation = element
          //   } else {
          //     inchargeDesignationArray.push(element);
          //   }
          // });

          // for (let i = 0; i < inchargeDesignationArray.length; i++) {
          //   let incharge = [];
          //   incharge['incharge_name'] = inchargeNameArray[i];
          //   incharge['incharge_phone'] = inchargePhoneArray[i];
          //   incharge['incharge_designation'] = inchargeDesignationArray[i];
          //   incharge['incharge_email'] = inchargeEmailArray[i];
          //   this.inchareDetails.push(incharge)
          // }
          this.facility = [];

          if (message.text['facilities_services'] != null) {
            for (let i = 0; i < message.text['facilities_services'].length; i++) {
              this.facility.push(message.text['facilities_services'][i]['id']);
            }
          }
          this.thrustAreas = message.text.thrust_area.split(",");
          this.incubationCentersForm.reset();
          this.incubationCentersForm.get('center_name').setValue(message.text.name);
          this.incubationCentersForm.get('funding_schemes_support').setValue(message.text.funding_scheme_support_received);
  
          if(message.text.registration_number!=null && message.text.registration_number!=undefined ){
          this.incubationCentersForm.get('registration_number').setValue(message.text.registration_number);
          }

          this.incubationCentersForm.get('incubation_url').setValue(message.text.website_url);
          this.incubationCentersForm.get('incubation_incharge_designation').setValue(message.text.incubation_incharge_designation);
          this.incubationCentersForm.get('incharge_name').setValue(message.text.incharge_name);
          this.incubationCentersForm.get('incharge_email').setValue(message.text.incharge_email);
          this.incubationCentersForm.get('incharge_phone').setValue(message.text.incharge_phone);
          this.incubationCentersForm.get('thrust_areas').setValue(this.thrustAreas);
          this.incubationCentersForm.get('type_services_offered').setValue(message.text.type_services_offered);
          this.incubationCentersForm.get('facilities_services').setValue(this.facility);
          this.incubationCentersForm.get('registration_status_centre').setValue(message.text.registration_status_centre);
          this.incubationCentersForm.get('registered_as').setValue(message.text.registered_as);

          if(message.text.cin_number!=null && message.text.cin_number!=undefined ){
            this.incubationCentersForm.get('pin_no_1').setValue(message.text.cin_number.substring(0, 1));
            this.incubationCentersForm.get('pin_no_2').setValue(message.text.cin_number.substring(1, 6));
            this.incubationCentersForm.get('pin_no_3').setValue(message.text.cin_number.substring(6, 8));
            this.incubationCentersForm.get('pin_no_4').setValue(message.text.cin_number.substring(8, 12));
            this.incubationCentersForm.get('pin_no_5').setValue(message.text.cin_number.substring(12, 15));
            this.incubationCentersForm.get('pin_no_6').setValue(message.text.cin_number.substring(15, 21));

          }

        }
      }
    });

    // get drop down values
    this.instituteIncubationService.getDropdownList().subscribe(
      data => this.setDropdown(data)
    );
  }

  uploadFile(event) {
    this.cin_file = event.files[0];
  }

  setFileFlags() {
  }

  deleteInchargeDetails(index) {

    // const index = this.inchareDetails.indexOf(data);
    if (index >= 0) {
      this.inchareDetails.splice(index, 1);
      this.toastr.success("Deleted Successfully!")
    }
  }

  deleteFile(fileName) {
    const data = {
      fileName: fileName,
    }

    this.fileService.deleteFile(data).subscribe((data => console.log(data)))
    if (fileName == 'cin_file') {
      this.cin_file_flag = false;
    }

  }

  setDropdown(data) {
    this.type_services_offered = [];
    for (let i = 0; i < data['typeServicesOffered'].length; i++) {
      this.type_services_offered.push(data['typeServicesOffered'][i]['name']);
    }
    this.facilities_services = [];
    for (let i = 0; i < data['facilitiesServices'].length; i++) {
      this.facilities_services.push(data['facilitiesServices'][i]);
    }
    this.registration_status_centre = [];
    for (let i = 0; i < data['registrationStatusCentre'].length; i++) {
      this.registration_status_centre.push(data['registrationStatusCentre'][i]['name']);
    }
    this.registered_as = [];
    for (let i = 0; i < data['registeredAs'].length; i++) {
      this.registered_as.push(data['registeredAs'][i]['name']);
    }
    this.facilities_services_multi = [];
    for (let i = 0; i < data['facilitiesServices'].length; i++) {
      this.facilities_services_multi.push(data['facilitiesServices'][i]['id']);
    }
  }

  changeValidation(checked) {
    const Registered_as = this.incubationCentersForm.get('registered_as');
    if (checked == "Legal Entity") {
      this.show1 = true;
      this.showregistered=true;
      Registered_as.setValidators([Validators.required]);
    } else {
      this.showregistered=true;
      Registered_as.clearValidators();
      Registered_as.reset();
      this.show1 = false;
      // this.showregistered=false;
      this.showCIN1=false;
    }
    Registered_as.updateValueAndValidity();
  }


  // ??change
  changeValidationOfRegisteredAs(data){
    const Registeration_Number = this.incubationCentersForm.get('registration_number');
    // const Registeration_Number = this.incubationCentersForm.get('registration_number');


  switch(data){

    case "Section 8 Company(Non-Profit Organization)":
      this.showCIN1=true;
      this.showregistered=true;
      Registeration_Number.clearValidators();

      Registeration_Number.updateValueAndValidity();
    break;
    case "As Society(Registration ACT 1860)":
      this.showCIN1=false;
      this.showregistered=false;
      Registeration_Number.setValidators([Validators.required]);
      Registeration_Number.updateValueAndValidity();
    break;    
    case "As Trust":
      this.showCIN1=false;
      this.showregistered=false;
      Registeration_Number.setValidators([Validators.required]);
      Registeration_Number.updateValueAndValidity();
    break;
    case "Pvt Ltd Company(For-Profit Organization)":
      this.showCIN1=true;
      this.showregistered=true;
      Registeration_Number.clearValidators();

      Registeration_Number.updateValueAndValidity();
    break;
    // case "As Firm/Startup as per DPIIT Norm (Including Section 25)":
    //   this.showCIN1=false;
    //   this.showregistered=false;
    //   Registeration_Number.setValidators([Validators.required]);
    //   Registeration_Number.updateValueAndValidity();
    // break;

    default:{
      this.showCIN1=false;
      this.showregistered=true;
      Registeration_Number.updateValueAndValidity();
      break;
    }
  }

}

  // ??change
  onSubmit2(values) {
    values['thrust_areas'] = this.thrustAreas;
    //alert("Test 3")
    this.registeredValue=this.incubationCentersForm.get('registration_status_centre').value;
    if(this.registeredValue=="Legal Entity"){
      if (!this.cinFileFlag) {
          var cin_file =
            this.cin_file && this.cin_file.length
              ? this.cin_file[0]
              : null;

          if (this.cin_file == null) {
            this.toastr.error('Kindly upload the CIN file.');
            return false;
          } else {
            if (!this.fileService.checkIfPdf(cin_file)) {
              this.toastr.error('File Must Be Type Of PDF');
              return false;
            }
          }
      }
    } 

    this.cin_number = values.pin_no_1 + values.pin_no_2 + values.pin_no_3 + values.pin_no_4 + values.pin_no_5 + values.pin_no_6
    values.cin_number = this.cin_number;
    values.cin_file = this.cin_file;

    //alert("Test 1")
    
    var formData = new FormData();
      formData.append('cin_file', cin_file);
      formData.append('center_name', values['center_name']);
      formData.append('funding_schemes_support', values['funding_schemes_support']);
      formData.append('registration_number', values['registration_number']);
      formData.append('incubation_url', values['incubation_url']);
      formData.append('thrust_areas', values['thrust_areas']);
      formData.append('type_services_offered', values['type_services_offered']);
      formData.append('facilities_services', values['facilities_services']);
      formData.append('registration_status_centre', values['registration_status_centre']);
      formData.append('registered_as', values['registered_as']);
      formData.append('cin_number', values['cin_number']);
      formData.append('incharge_name', values['incharge_name']);
      formData.append('incharge_email', values['incharge_email']);
      formData.append('incharge_phone', values['incharge_phone']);
      formData.append('incubation_incharge_designation', values['incubation_incharge_designation']);
      //alert("Test 2")
      this.instituteIncubationService
        .submitIncubationData(formData)
        .subscribe((data) => this.handleResponse(data));
      this.messageService.sendMessage('Update');
  }


  onSubmit(values) {
    values['thrust_areas'] = this.thrustAreas;

    this.registeredValue=this.incubationCentersForm.get('registration_status_centre').value;
    if(this.registeredValue=="Legal Entity"){
      if (!this.cinFileFlag) {
          var cin_file =
            this.cin_file && this.cin_file.length
              ? this.cin_file[0]
              : null;

          if (this.cin_file == null) {
            this.toastr.error('Kindly upload the CIN file.');
            return false;
          } else {
            if (!this.fileService.checkIfPdf(cin_file)) {
              this.toastr.error('File Must Be Type Of PDF');
              return false;
            }
          }

      }
    }
    var formData = new FormData();

    this.cin_number = values.pin_no_1 + values.pin_no_2 + values.pin_no_3 + values.pin_no_4 + values.pin_no_5 + values.pin_no_6
    values.cin_number = this.cin_number;
    values.cin_file = this.cin_file;
    delete values[`pin_no_1`];
    delete values[`pin_no_2`];
    delete values[`pin_no_3`];
    delete values[`pin_no_4`];
    delete values[`pin_no_5`];
    delete values[`pin_no_6`];

    formData.append('cin_file', cin_file);
    formData.append('center_name', values['center_name']);
    formData.append('funding_schemes_support', values['funding_schemes_support']);
    formData.append('registration_number', values['registration_number']);
    formData.append('incubation_url', values['incubation_url']);
    formData.append('thrust_areas', values['thrust_areas']);
    formData.append('type_services_offered', values['type_services_offered']);
    formData.append('facilities_services', values['facilities_services']);
    formData.append('registration_status_centre', values['registration_status_centre']);
    formData.append('registered_as', values['registered_as']);
    formData.append('cin_number', values['cin_number']);


    if (this.messages.length > 0) {
      formData.append('id', this.messages['0'].text.id);
    }

    let inchargeNamesArray = [];
    let inchargeEmailsArray = [];
    let inchargeDesignationsArray = [];
    let inchargeContactsArray = [];

    inchargeNamesArray.push(this.incubationCentersForm
      .get('incharge_name').value);
    inchargeEmailsArray.push(this.incubationCentersForm
      .get('incharge_email').value);
    inchargeDesignationsArray.push(this.incubationCentersForm
      .get('incubation_incharge_designation').value);
    inchargeContactsArray.push(this.incubationCentersForm
      .get('incharge_phone').value);

    this.inchareDetails.forEach(element => {
      inchargeNamesArray.push(element.incharge_name);
      inchargeEmailsArray.push(element.incharge_email);
      inchargeDesignationsArray.push(element.incharge_designation);
      inchargeContactsArray.push(element.incharge_phone);
    });

    values['incharge_name'] = inchargeNamesArray;
    values['incharge_email'] = inchargeEmailsArray;
    values['incubation_incharge_designation'] = inchargeDesignationsArray;
    values['incharge_phone'] = inchargeContactsArray;

    formData.append('incharge_name', values['incharge_name']);
    formData.append('incharge_email', values['incharge_email']);
    formData.append('incharge_phone', values['incharge_phone']);
    formData.append('incubation_incharge_designation', values['incubation_incharge_designation']);

    if (this.inchareDetails.length <= 4) {
      if (this.incubationCentersForm.valid) {

        this.instituteIncubationService
          .submitIncubationData(formData)
          .subscribe((data) => this.handleResponse(data));
        this.messageService.sendMessage('Update');
      }
    } else {
      this.toastr.error('Maximum 5 in-charge is allowed!');
    }
  }

  clearMessages(): void {
    // clear messages
    this.messageService.clearMessages();
  }

  handleResponse(data) {
    if (data['message'] == 'Success') {
      this.toastr.success('Data Submitted Successfully!');
              // window.location.reload();

    } else {
      this.toastr.error(data['reason'])
    }
  }

  getSelectedApprovalType() {
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  openDialog() {
    const dialogRef = this.dialog.open(IncubationInchargeDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.inchareDetails.push(result);
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.thrustAreas.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.incubationCentersForm
      .get('thrust_areas').setValue(this.thrustAreas);
    
  }

  remove(fruit: string): void {
    const index = this.thrustAreas.indexOf(fruit);

    if (index >= 0) {
      this.thrustAreas.splice(index, 1);
    }

    this.incubationCentersForm
      .get('thrust_areas').setValue(this.thrustAreas);

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.thrustAreas.push(event.option.viewValue);
    this.thrustAreaInput.nativeElement.value = '';
    this.incubationCentersForm
      .get('thrust_areas').setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.thrustAreasList.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

  getThrustAreasList() {
    this.thrustAreasList = [];
    this.instituteIncubationService.getThrustAreasList(this.filterThrustAreaData).subscribe(
      (data: any[]) => {
        data.forEach(element => {
          this.thrustAreasList.push(element.name);
        });
      }
    );
  }
}
