import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iic-ratings2021',
  templateUrl: './iic-ratings2021.component.html',
  styleUrls: ['./iic-ratings2021.component.css']
})
export class IicRatings2021Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
