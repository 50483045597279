<div class="container-fluid">
  <div class="m-4 row">
    <div class="col-3">
      <mat-select placeholder="All Quarter" [(ngModel)]="quarter_filter" (ngModelChange)="quarterFilter()"
        class="instiIICcalActi">
        <mat-option value="">All Quarter</mat-option>
        <mat-option value="Quarter I">Semester 1–Quarter I</mat-option>
        <mat-option value="Quarter II">Semester 1–Quarter II</mat-option>
        <mat-option value="Quarter III">Semester 2–Quarter III</mat-option>
        <mat-option value="Quarter IV">Semester 2–Quarter IV</mat-option>
      </mat-select>
    </div>

    <div class="col-3">
      <mat-select placeholder="All Activity Type" [(ngModel)]="activity_filter" (ngModelChange)="activityFilter()"
        class="instiIICcalActi">
        <mat-option value="">All Activity</mat-option>
        <mat-option value="1">IIC Calendar Activity</mat-option>
        <mat-option value="2">MIC Driven Activity</mat-option>
        <mat-option value="3">Self-Driven Activity</mat-option>
        <mat-option value="4">Celebration</mat-option>
      </mat-select>
    </div>


    <div class="col-6 example">
		  <input class="searchExter" matInput placeholder="Search Title" (keyup)="filter($event)">
		  <button type="button" class="searchButton" (click)="search()"><i class="fa fa-search" style="font-size: 28px;"></i></button>
    </div>
  </div>

  <div class="row m-4">
    <!-- <div class="col-md-4" *ngFor="let activity of myActivity;let i = index">
      <div class="CardBox">
        <img src="assets/customCard/card-gradient-img.png" class="CardBoxContentTopImg CardBoxContentTopImgMyCouncil">
        <div class="CardBoxContent">
          <h3>Title</h3>
          <p id="announcementParaScroll">{{activity?.title}}</p>
        </div>
        <div class="CardBoxContent" *ngIf="activity?.comments">
          <h3>Comment by MIC</h3>
          <p id="announcementParaScroll">{{activity?.comments}}</p>
        </div>
        <div class="CardBoxContent" *ngIf="activity.url != ''">

          <button *ngIf="activity.url == 'monthly_report'"
            class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"><a class="activity_upload_btn"
              target="_blank" style="color: white;"
              href="/institute/activity/monthly_report/{{activity.eventId}}?action={{activity.action}}&instid={{activity.institute_id}}">
              {{activity.label}}</a>
          </button>

          <button *ngIf="activity.url == 'monthly-report-of-council'"
            class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">
            <a target="_blank" class="activity_upload_btn"
              href="/institute/activity/monthly-report-of-council/{{activity.eventId}}?action={{activity.action}}&instid={{activity.institute_id}}"
              class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">{{activity.label}}</a>
          </button>
        </div>

        <div class="CardBoxContent" *ngIf="activity.url == ''">
          <button class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">
            <a style="color: white;" href="javascript:void(0)"> {{activity.label}} </a>
          </button>
        </div>

        <div class="CardBoxContent row" *ngIf="activity.download_url != ''">
          <div class="col-6 pl-0 pr-0">
            <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
              (click)='openDialog(activity)'>View Details</button>
          </div>
          <div class="col-6">
           <button type="button" (click)="downloadReport(activity.download_url,activity.eventId)"
              class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG buttonSmallFont">Download
              Report</button>
          </div>
        </div>

        <div class="CardBoxContent row" *ngIf="activity.download_url == ''">
          <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
            (click)='openDialog(activity)'>View Details</button>
        </div>
      </div>
    </div>
     -->


     <table class="table table-hover table-border mt-4 table-responsive">
      <thead class="facultyTable">
        <tr>
          <td colspan="10" allign="left" class="danger "
            style="text-align:center;color:blue;padding: 0 !important; height: auto;">
            <h1 class="announcementHeadingMain">My Activity</h1>
          </td>
        </tr>


        <tr>
          <th width="2%">S.No.</th>
          <th width="18%">Acivity</th>
          <th width="5%">View Details</th>
          <th width="5%">Report</th>
          <th width="10%" style="width: 25%;">Status</th>
          <th width="20%">Comment</th>
          <th width="20%">Review Request</th>
          <th width="10%">Download Report</th>
          <th width="5%">Action</th>
        </tr>
      </thead>
      <tbody>
		<tr *ngFor="let activity of myActivity;let i = index">
<td>{{i+1}}</td>
<td>
  <b>Name:- </b>{{activity?.title}}<br/><br/>
  <b>Quarter:-</b> {{activity?.quarter}}<br/><br/>
  <b>Type:- </b>{{activity?.type}}<br/><br/>
</td>
<!-- <td>{{activity?.quarter}}</td>
<td>{{activity?.type}}</td> -->
<td>
  <!-- <button type="button" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
  (click)='openDialog(activity)'>View Details</button> -->
  <mat-icon (click)="openDialog(activity)" class="m-2" style="cursor: pointer;" class="actView">
    visibility</mat-icon>
</td>

<td *ngIf="activity.url != ''">
  <span>
  <button *ngIf="activity.url == 'monthly_report' && activity?.status !='Disapproved' && activity?.status !='Verified'"
  class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">

  <a target="_blank" *ngIf="activity.eventId!='M23-24264980'" style="color: white;"
    href="/institute/activity/monthly_report/{{activity.eventId}}?action={{activity.action}}&instid={{activity.institute_id}}&curr_year=2024-25">
    {{activity.label}}</a>

   <!-- <a  *ngIf="activity.eventId=='M22-247171'" -->
   <a  *ngIf="activity.eventId=='M23-24264980'"
    target="_blank" style="color: white;"
    href="/institute/iic-impact-submission">
      <span *ngIf="MyActivityannualReport==1">{{activity.label}}</span>
      <span *ngIf="MyActivityannualReport==0">{{activity.label}}</span>
  </a>


</button>
</span>
<span>
  <button *ngIf="activity?.status =='Disapproved' || activity?.status =='Verified'"
  class="btn btn-info aheto-btn aheto-pricing__btn instiDownloadButBG" style="background-color: #53537b !important;">
  <a style="color: white;"
   href="#" onclick="return false;">
      <span *ngIf="MyActivityannualReport==1">{{activity.label}}</span>
      <span *ngIf="MyActivityannualReport==0">{{activity.label}}</span>
  </a>
  </button>
</span>

<button *ngIf="activity.url == 'monthly-report-of-council' && activity?.status !='Disapproved' && activity?.status !='Verified'"
  class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">
  <a target="_blank" class="activity_upload_btn"
    href="/institute/activity/monthly-report-of-council/{{activity.eventId}}?action={{activity.action}}&instid={{activity.institute_id}}"
    class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG">{{activity.label}} asdf</a>

</button>

</td>




<td *ngIf="activity?.status=='Resubmitted' && activity.eventId!='M23-24264980'" style="color: yellowgreen;"><p class="actResubmitted">{{activity?.status}}</p></td>
<td *ngIf="activity?.status=='Reopen' && activity.eventId!='M23-24264980'" style="color: palevioletred;"><p class="actReopen">{{activity?.status}}</p></td>
<td *ngIf="activity?.status=='Not Submitted' && activity.eventId!='M23-24264980'" style="color: red;"><p class="actNotSubmitted">{{activity?.status}}</p></td>
<td *ngIf="activity?.status=='Verified' && activity.eventId!='M23-24264980'" style="color: green;"><p class="actApproved">Approved</p></td>
<td *ngIf="activity?.status=='Disapproved' && activity.eventId!='M23-24264980'" style="color: brown;"><p class="actDisApproved">{{activity?.status}}</p></td>
<td *ngIf="activity?.status=='Submitted' && activity.eventId!='M23-24264980'" style="color: blue;"><p class="actSubmitted">{{activity?.status}}</p></td>
<td *ngIf="activity.eventId=='M23-24264980'">
  <span *ngIf="MyActivityannualReport==1" style="color: blue;"><p class="actSubmitted">{{activity?.status}}</p></span>
  <span *ngIf="MyActivityannualReport==0" style="color: red;"><p class="actDisApproved">Not Submitted</p></span>
</td>
<td *ngIf="activity?.status=='Not Submitted' && activity.eventId!='M23-24264980'">
  <button *ngIf="activity.eventId=='M21-224246'"
  class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG" style="margin-bottom: 15px;"
        (click)='openDialogRepository()'>View Status</button>


<button *ngIf="activity.eventId=='M21-224240'"
class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG" style="margin-bottom: 15px;"
  (click)='openDialogReferral()'>View Status</button>

</td>

<td *ngIf="activity.comments != null">{{activity?.comments}}</td>
<td *ngIf="activity.comments == null">NA</td>

<!-- <td *ngIf="activity?.status=='Disapproved' && activity?.review_request ==0"><button type="button" (click)="reviewRequest(activity?.eventId)" class="btn btn-primary aheto-btn aheto-pricing__btn instiDownloadButBG"
  >Request for Review</button></td> -->
  <td *ngIf="activity?.status=='Disapproved' && activity?.review_request ==0"><img src="assets/images/BUTTON.png" (click)="reviewRequest(activity?.eventId)" style="cursor: pointer;"></td>
    <td *ngIf="activity?.status=='Disapproved' && activity?.review_request !=0">Review Request Sent</td>
  <td *ngIf="activity?.status!='Disapproved'">NA</td>

<td  *ngIf="activity.download_url != '' && activity?.status=='Submitted' && activity.eventId!='M21-233063' && activity.eventId!='M21-221573' && activity.eventId!='M21-221574' && activity.eventId!='M23-24264980'">
  <mat-icon (click)="downloadReport(activity.download_url,activity.eventId)" class="m-2 actDownload matDelIco" style="cursor: pointer;">
              download_for_offline</mat-icon>
</td>
<td  *ngIf="activity.eventId=='M21-221573' && activity?.status=='Submitted'">
  <mat-icon (click)="downloadReportAKAM(activity.eventId)" class="m-2 actDownload matDelIco" style="cursor: pointer;">
              download_for_offline</mat-icon>
</td>
<td  *ngIf="activity.eventId=='M23-24264980' && activity?.status=='Submitted'">
  <a href="{{baseUrl + activity.annual_report}}" target="_blank"><mat-icon class="m-2 actDownload matDelIco" style="cursor: pointer;">
              download_for_offline</mat-icon></a>
</td>
<!-- <td  *ngIf="activity?.status!='Submitted'">
  <mat-icon (click)="downloadReport(activity.download_url,activity.eventId)" class="m-2 actDownload matDelIco" style="cursor: pointer;">
    download_for_offline</mat-icon>
</td> -->

<td *ngIf="activity?.status=='Submitted'">
  <!-- <button mat-raised-button (click)="onDeleteClick(activity)" color="warn"> Delete</button> -->

  <mat-icon (click)="onDeleteClick(activity)" class="m-2" style="cursor: pointer;" class="matDelIco">
              delete_forever</mat-icon>

</td>
<td *ngIf="activity?.status!='Submitted'">
  N/A
</td>
	</tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
        [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
      </mat-paginator>
    </div>
  </div>
</div>