import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OnlineTokenService } from './online-token.service';
import * as myGlobals from '../../app.component';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class OnlineAuthService {
  private baseUrl = myGlobals.baseUrl;

  private loggedIn = new BehaviorSubject<boolean>(this.token.loggedIn());

  authStatus = this.loggedIn.asObservable();

  changeStatus(value: boolean){
    this.loggedIn.next(value);
  }

  constructor(private token: OnlineTokenService, 
    private http: HttpClient) {}
 
}
