<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Pre Incubators</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Pre Incubators</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Resources</a></li>
    <li class="breadcrumb-item active" aria-current="page">Pre Incubators</li>
  </ul>
</nav>

<div class="cbp-row wh-content preIncubCenter">
			<div class="cbp-container wh-padding">
				
				<div class="vc_row">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>Pre Incubations Center</h2>
						</div>
					</div>
					
					<div class="limiter" >

	<div class="row drop-sub-box">
		<form [formGroup]="preincubators" (ngSubmit)="onSubmit(preincubators.value)"  class="formIICInnoFaculty">
		<div class="col-md-5">
			<div class="form-group">
				<label>Pre Incubators</label>
				<select class="form-control" id="type_of" formControlName="type_of">
					<option value="">All</option>
					<option value="EDC">EDC</option>
					<option value="IEDC">IEDC</option>
					<option value="New Gen IEDC">New Gen IEDC</option>
					<option value="Innovation Cell">Innovation Cell</option>
					<option value="Start-Up Cell">Start-up Cell</option>
					<option value="NIDHI Center">NIDHI Centre</option>
					<option value="Tinkering Lab">Tinkering Lab</option>
					<option value="AICTE-IDEALab">AICTE-IDEALab</option>
				</select>
			</div>
		</div>
		
		
		<div class="col-md-5">
			<div class="form-group">
				<label class="incubState">State</label>
				<mat-form-field class="preIncubatorAllTab">
					<mat-select formControlName="state" multiple [(ngModel)]="StateModel">
						<app-select-check-all [model]="preincubators.get('state')" [values]="allvalues">
						</app-select-check-all>
						<mat-option *ngFor="let state of state" [value]="state.id">
						  {{state.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		
		<div class="col-md-2">
			<div class="form-group">
				<button type="submit" name="Submit" id="submit" class="sub-but">Submit</button>
			</div>
		</div>
	</form>
	</div>
	
	<div class="row">
	<div class="container-login100">
		<div class="tab-box" id="newContentPreIncubation">
			<p style="color:#ff5402;font-size:24px;font-weight:700;"></p>
			<div id="myTable_wrapper" class="dataTables_wrapper no-footer">
				<div class="table-responsive">
					<table class="table table-bordered dataTable no-footer table-responsive incubTableMobRes" style="color: #000;" id="myTable" role="grid" aria-describedby="myTable_info">
						<thead>
							<tr role="row">
								<th class="sorting_asc" tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort="ascending" aria-label="S.No.: activate to sort column descending" style="width: 1%;">S.No.</th>
								<th class="sorting" tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Institute Details: activate to sort column ascending" style="width: 246.8px;">Institute Details</th>
								<th class="sorting" tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Pre Incubations: activate to sort column ascending" style="width: 531.6px;">Pre Incubations</th>
							</tr>
						</thead>
						<tbody>

							<tr  *ngFor="let details of theme; let i = index;">
								<td>{{i+1}}</td>
								<td>
									<p *ngIf="details?.institutesDetails.instituteName">{{details?.institutesDetails.instituteName}}</p>
                  <p *ngIf="!details?.institutesDetails.instituteName">N/A</p>

                  <p *ngIf="details?.institutesDetails.instituteCity"><b>City: </b>{{details?.institutesDetails.instituteCity}}</p>
                  <p *ngIf="!details?.institutesDetails.instituteCity"><b>City: </b>N/A</p>

                  <p *ngIf="details?.institutesDetails.instituteState">
											<b >State: </b>{{details?.institutesDetails.instituteState}}
                    </p>
                    <p *ngIf="!details?.institutesDetails.instituteState">
											<b >State: </b>N/A
										</p>
										<p *ngIf="details?.institutesDetails.institutePincode"><b>Pin Code:
                       </b>{{details?.institutesDetails.institutePincode}}</p>
                       <p *ngIf="!details?.institutesDetails.institutePincode"><b>Pin Code:
                      </b>N/A</p>
								</td>

								<td>
								<div class="row">
								<div *ngIf="details?.name" class="col-md-3">
									<p style="color:#5e187b;font-size:20px;font-weight:bold;">{{details?.sub_type}}</p>
                  <p *ngIf="details?.name"><b>Name:</b> {{details?.name}}</p>
                  <p *ngIf="!details?.name"><b>Name:</b> N/A</p>
                  <p class="para-break" *ngIf="details?.incubation_incharge_email"><b>Email:</b> {{details?.incubation_incharge_email}}</p>
                  <p class="para-break" *ngIf="!details?.incubation_incharge_email"><b>Email:</b>N/A</p>
                  <!-- <p *ngIf="details?.incubation_incharge_phone"><b>Phone:</b>{{details?.incubation_incharge_phone}}</p>
                  <p *ngIf="!details?.incubation_incharge_phone"><b>Phone:</b>N/A</p> -->
                  <p class="para-break"><b>Url: </b>
                    <a target="_BLANK" href="{{details?.website_url}}" *ngIf="details?.website_url!='#'">{{details?.website_url}}</a>
                    <b style="font-weight: normal;" *ngIf="details?.website_url=='#'">N/A</b>
                </p>
                
									<!-- <p class="para-break"><b>Url: </b><a target="_BLANK" href="#">{{details?.website_url_edc}}</a></p> -->
								</div>
								<div *ngIf="details?.name_iedc" class="col-md-3">
									<p style="color:#5e187b;font-size:20px;font-weight:bold;">IEDC</p>
                  <p *ngIf="details?.name_iedc"><b>Name:</b> {{details?.name_iedc}}</p>
                  <p *ngIf="!details?.name_iedc"><b>Name:</b>N/A</p>
									<p class="para-break" *ngIf="details?.email_iedc"><b>Email:</b>{{details?.email_iedc}}</p>
                  <p class="para-break" *ngIf="!details?.email_iedc"><b>Email:</b>N/A</p>
                  <p *ngIf="details?.phone_iedc"><b>Phone:</b>{{details?.phone_iedc}}</p>
                  <p *ngIf="!details?.phone_iedc"><b>Phone:</b>N/A</p>
                  <!-- <p class="para-break"><b>Url: </b>
                    <a target="_BLANK" href="{{details?.web_url_iedc}}" *ngIf="details?.web_url_iedc">{{details?.web_url_iedc}}</a>
                    <a target="_BLANK" href="#" *ngIf="!details?.web_url_iedc">N/A</a>
                </p> -->

                <p class="para-break"><b>Url: </b>
                  <a target="_BLANK" href="{{details?.web_url_iedc}}" *ngIf="details?.web_url_iedc!='#'">{{details?.web_url_iedc}}</a>
                  <b style="font-weight: normal;" *ngIf="details?.web_url_iedc=='#'">N/A</b>
              </p>
									<!-- <p class="para-break"><b>Url: </b><a target="_BLANK" href="#">{{details?.web_url_iedc}}</a></p> -->
								</div>
								<div *ngIf="details?.new_iedc" class="col-md-3">
									<p style="color:#5e187b;font-size:20px;font-weight:bold;">New Gen IEDC</p>
                  <p *ngIf="details?.new_iedc"><b>Name:</b> {{details?.new_iedc}}</p>
                  <p *ngIf="!details?.new_iedc"><b>Name:</b> N/A</p>
									<p class="para-break" *ngIf="details?.email_new_iedc"><b>Email:</b> {{details?.email_new_iedc}}</p>
                  <p class="para-break" *ngIf="!details?.email_new_iedc"><b>Email:</b>N/A</p>
                  <p  *ngIf="details?.phone_new_iedc"><b>Phone:</b>{{details?.phone_new_iedc}}</p>
                  <p  *ngIf="!details?.phone_new_iedc"><b>Phone:</b>N/A</p>
                  <!-- <p class="para-break"><b>Url: </b>
                    <a target="_BLANK" href="{{details?.web_url_new_iedc}}" *ngIf="details?.web_url_new_iedc">{{details?.web_url_new_iedc}}</a>
                    <a target="_BLANK" href="#" *ngIf="!details?.web_url_new_iedc">N/A</a>
                </p> -->
                <p class="para-break"><b>Url: </b>
                  <a target="_BLANK" href="{{details?.web_url_new_iedc}}" *ngIf="details?.web_url_new_iedc!='#'">{{details?.web_url_new_iedc}}</a>
                  <b style="font-weight: normal;" *ngIf="details?.web_url_new_iedc=='#'">N/A</b>
              </p>


									<!-- <p class="para-break"><b>Url: </b><a target="_BLANK" href="#">{{details?.web_url_new_iedc}}</a></p> -->
								</div>
								<div *ngIf="details?.name_innovation_cell" class="col-md-3">
									<p style="color:#5e187b;font-size:20px;font-weight:bold;">Innovation Cell</p>
                <p *ngIf="details?.name_innovation_cell"><b>Name:</b>{{details?.name_innovation_cell}} </p>
                <p *ngIf="!details?.name_innovation_cell"><b>Name:</b>N/A</p>
                <p class="para-break" *ngIf="details?.name_innovation_cell"><b>Email:</b> {{details?.email_innovation_cell}}</p>
                <p class="para-break" *ngIf="!details?.name_innovation_cell"><b>Email:</b> N/A</p>
                <p *ngIf="details?.name_innovation_cell"><b>Phone:</b>{{details?.phone_innovation_cell}}</p>
                <p *ngIf="!details?.name_innovation_cell"><b>Phone:</b>N/A</p>
                <!-- <p class="para-break"><b>Url: </b>
                  <a target="_BLANK" href="{{details?.web_url_innovation_cell}}" *ngIf="details?.web_url_innovation_cell">{{details?.web_url_innovation_cell}}</a>
                  <a target="_BLANK" href="#" *ngIf="!details?.web_url_innovation_cell">N/A</a>
              </p> -->

              <p class="para-break"><b>Url: </b>
                <a target="_BLANK" href="{{details?.web_url_innovation_cell}}" *ngIf="details?.web_url_innovation_cell!='#'">{{details?.web_url_innovation_cell}}</a>
                <b style="font-weight: normal;" *ngIf="details?.web_url_innovation_cell=='#'">N/A</b>
            </p>


                <!-- <p class="para-break"><b>Url: </b><a target="_BLANK" href="#">{{details?.web_url_innovation_cell}}</a></p> -->
								</div>
								<div *ngIf="details?.name_start_up_cell" class="col-md-3">
									<p style="color:#5e187b;font-size:20px;font-weight:bold;">Start-up Cell</p>
                  <p *ngIf="details?.name_start_up_cell"><b>Name:</b> {{details?.name_start_up_cell}}</p>
                  <p *ngIf="!details?.name_start_up_cell"><b>Name:</b> N/A</p>

									<p class="para-break" *ngIf="details?.name_start_up_cell"><b>Email:</b> {{details?.email_start_up_cell}}</p>
                  <p class="para-break" *ngIf="!details?.name_start_up_cell"><b>Email:</b> N/A</p>

                  <p *ngIf="details?.name_start_up_cell"><b>Phone:</b>{{details?.phone_start_up_cell}}</p>
                  <p *ngIf="!details?.name_start_up_cell"><b>Phone:</b>N/A</p>

                  <!-- <p class="para-break"><b>Url: </b>
                    <a target="_BLANK" href="{{details?.web_url_start_up_cell}}" *ngIf="details?.web_url_start_up_cell">{{details?.web_url_start_up_cell}}</a>
                    <a target="_BLANK" href="#" *ngIf="!details?.web_url_start_up_cell">N/A</a>
                </p> -->


                <p class="para-break"><b>Url: </b>
                  <a target="_BLANK" href="{{details?.web_url_start_up_cell}}" *ngIf="details?.web_url_start_up_cell!='#'">{{details?.web_url_start_up_cell}}</a>
                  <b style="font-weight: normal;" *ngIf="details?.web_url_start_up_cell=='#'">N/A</b>
              </p>

									<!-- <p class="para-break"><b>Url: </b><a target="_BLANK" href="#">{{details?.web_url_start_up_cell}}</a></p> -->
								</div>
								<div *ngIf="details?.name_nidhi_center" class="col-md-3">
									<p style="color:#5e187b;font-size:20px;font-weight:bold;">NIDHI Center</p>
                  <p *ngIf="details?.name_nidhi_center"><b>Name:</b> {{details?.name_nidhi_center}}</p>
                  <p *ngIf="!details?.name_nidhi_center"><b>Name:</b> N/A</p>
									<p class="para-break" *ngIf="details?.email_nidhi_center"><b>Email:</b> {{details?.email_nidhi_center}}</p>
                  <p class="para-break" *ngIf="!details?.email_nidhi_center"><b>Email:</b> N/A</p>
                  <p *ngIf="details?.phone_nidhi_center"><b>Phone:</b>{{details?.phone_nidhi_center}}</p>
                  <p *ngIf="!details?.phone_nidhi_center"><b>Phone:</b>N/A</p>
                  <!-- <p class="para-break"><b>Url: </b>
                    <a target="_BLANK" href="{{details?.web_url_nidhi_center}}" *ngIf="details?.web_url_nidhi_center">{{details?.web_url_nidhi_center}}</a>
                    <a target="_BLANK" href="#" *ngIf="!details?.web_url_nidhi_center">N/A</a>
                </p> -->

                <p class="para-break"><b>Url: </b>
                  <a target="_BLANK" href="{{details?.web_url_nidhi_center}}" *ngIf="details?.web_url_nidhi_center!='#'">{{details?.web_url_nidhi_center}}</a>
                  <b style="font-weight: normal;" *ngIf="details?.web_url_nidhi_center=='#'">N/A</b>
              </p>


									<!-- <p class="para-break"><b>Url: </b><a target="_BLANK" href="#">{{details?.web_url_nidhi_center}}</a></p> -->
								</div>
								<div *ngIf="details?.email_tinkering_lab" class="col-md-3">
									<p style="color:#5e187b;font-size:20px;font-weight:bold;">Tinkering Lab</p>
                  <p *ngIf="details?.email_tinkering_lab"><b>Name:</b> {{details?.email_tinkering_lab}}</p>
                  <p *ngIf="!details?.email_tinkering_lab"><b>Name:</b>N/A</p>

									<p class="para-break" *ngIf="details?.email_tinkering_lab"><b>Email:</b> {{details?.email_tinkering_lab}}</p>
                  <p class="para-break" *ngIf="!details?.email_tinkering_lab"><b>Email:</b> N/A</p>

                  <p *ngIf="details?.phone_tinkering_lab"><b>Phone:</b>{{details?.phone_tinkering_lab}}</p>
                  <p *ngIf="!details?.phone_tinkering_lab"><b>Phone:</b>N/A</p>

                  <!-- <p class="para-break"><b>Url: </b>
                    <a target="_BLANK" href="{{details?.web_url_tinkering_lab}}" *ngIf="details?.web_url_tinkering_lab">{{details?.web_url_tinkering_lab}}</a>
                    <a target="_BLANK" href="#" *ngIf="!details?.web_url_tinkering_lab">N/A</a>
                </p> -->

                <p class="para-break"><b>Url: </b>
                  <a target="_BLANK" href="{{details?.web_url_tinkering_lab}}" *ngIf="details?.web_url_tinkering_lab!='#'">{{details?.web_url_tinkering_lab}}</a>
                  <b style="font-weight: normal;" *ngIf="details?.web_url_tinkering_lab=='#'">N/A</b>
              </p>

									<!-- <p class="para-break"><b>Url: </b><a target="_BLANK" href="#">{{details?.web_url_tinkering_lab}}</a></p> -->
								</div>
								</div>
								</td>

							</tr>

						</tbody>
					</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<mat-paginator #paginator
				showFirstLastButtons
				(page)="nextPage($event)"
				[length]="total_pages"
				[pageIndex]="frontCurrentPageNo"
				[pageSizeOptions]="pageSizeOptions" class="NewMatPaginationPreIncub">
			</mat-paginator>
		</div>
	</div>


		</div>
					
				</div>
				
				<div class="vc_row-full-width vc_clearfix"></div>
				
			</div>
		</div>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>