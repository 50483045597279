import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InstituteRequestLogoService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  submitRequestLogoData(data) {
    return this.http.post(`${this.baseUrl}/create-logo-request/submit`, data);
  }
  getRequestLogoDetails(pageNo) {
    return this.http.get(
      `${this.baseUrl}/logo-request/details?per_page=10&page=` + pageNo
    );
  }

  getLogoStatus(pageNo, status) {
    return this.http.get(
      `${this.baseUrl}/logo-request/details?per_page=10&page=` + pageNo + `&filter=` + status
    );
  }
}
