<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

<link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
<link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">

<style>
  .navbar-nav>li {
    float: none !important;
    text-align: center !important;
    line-height: 24px;
  }
  label  {
    color:black;
  }
  strong  {
    color:black;
  }
</style>

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <span class="login100-form-title p-b-20">
        <img src="assets/institute/images/logoiic.png" class="loginIICLogo signupIICLogo">
      </span>
      <span class="login100-form-title loginInstiTopContent">
        <h2>Handholding Institute Request</h2>
      </span>
      <div class="col-md-8">
        <label>Institute Name : </label> <strong>   {{ memberDetail?.instituteName }}</strong>
      </div>
      <div class="col-md-4">
        <label>Institute Zone : </label> <strong>  {{ memberDetail?.member_zone }}</strong>
      </div>
      <form id="signup-form" class="form-horizontal" [formGroup]="handholdingRequest" (submit)="onSubmit(handholdingRequest.value)"
        style="color: black !important;">
        <div [hidden]="!error">
          {{error}}
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-label>Accept the Request</mat-label>
            <mat-select formControlName="accept_status" required>
              <mat-option value="1">Yes</mat-option>
              <mat-option value="2">No</mat-option>
            </mat-select>
            <mat-error *ngIf="handholdingRequest.controls.accept_status.errors?.required">
              Acceptance of request is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <button class="login100-form-btnSignUp" mat-raised-button type="submit"
            [disabled]="handholdingRequest.invalid">Submit</button>
        </div>
        <div class="col-md-6" style="margin-top: 2%;">
          <a [routerLink]="['/login']" routerLinkActive="router-link-active" style="float:right;color:#1a73e8;">Sign in instead</a>
        </div>

      </form>
    </div>
  </div>
</div>
