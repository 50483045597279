import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { emailValidator } from 'src/app/app.component';

@Component({
  selector: 'app-ipr-innovator-form',
  templateUrl: './ipr-innovator-form.component.html',
  styleUrls: ['./ipr-innovator-form.component.css']
})
export class IprInnovatorFormComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  constructor(private fb: FormBuilder, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
  }

  ngOnInit(): void {
      this.iicImpactQuestions = this.fb.group({
        'principal_inventor_name': [null, Validators.required],
        'principal_inventor_type': [null, Validators.required],
        'principal_inventor_email': [null, Validators.compose([Validators.required, emailValidator])],
        'principal_inventor_contact': [null, Validators.compose([Validators.required, Validators.minLength(8)])],
        'invention_title': [null, Validators.required],
        'gender': [null, Validators.required],
        'user_type': [null, Validators.required],
      });

  }


  onSubmit(data) {
    var formData = new FormData();

    formData.append('principal_inventor_name', this.iicImpactQuestions.get('principal_inventor_name').value);
    formData.append('principal_inventor_type', this.iicImpactQuestions.get('principal_inventor_type').value);
    formData.append('principal_inventor_email', this.iicImpactQuestions.get('principal_inventor_email').value);
    formData.append('principal_inventor_contact', this.iicImpactQuestions.get('principal_inventor_contact').value);
    formData.append('invention_title', this.iicImpactQuestions.get('invention_title').value);
    formData.append('gender', this.iicImpactQuestions.get('gender').value);
    formData.append('user_type', this.iicImpactQuestions.get('user_type').value);

    this.instituteActivityService.submityuktiIprRegistration(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/ipr-innovator-form';
    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
}
