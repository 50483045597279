import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { NominateForm2DialogComponent } from './nominate-form2-dialog/nominate-form2-dialog.component';

@Component({
  selector: 'app-nominate-form2',
  templateUrl: './nominate-form2.component.html',
  styleUrls: ['./nominate-form2.component.css']
})
export class NominateForm2Component implements OnInit {
  sessionList: any[];
  delete_id: any;

  constructor(private fb: FormBuilder, 
     private activityService: InstituteActivityService,
      public dialog: MatDialog,
      private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.getTable();


  }

  getTable(){
    this.innovationService.getsessions().subscribe(
      data => this.setsessions(data)
    );  

  }

  setsessions(data){
    this.sessionList=[];
	for(let i=0;i<data['sessions'].length;i++){
	  this.sessionList.push(data['sessions'][i])
	}
  }

  openDialog() {
    const dialogRef = this.dialog.open(NominateForm2DialogComponent, {
      data:null
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getTable();
    });
  }


  onEditClick(data){
    const dialogRef = this.dialog.open(NominateForm2DialogComponent, {
      data:data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getTable();
      // this.getcheckSessionDetails();
    });
  }
  onDeleteClick(data){
    this.delete_id = data['id'];

    if (confirm("Are you sure you want to delete?")) {
      this.innovationService.DeleteSession(this.delete_id).subscribe(
        data => this.handleResponse1(data)
      );  
      }
  }

  handleResponse1(data){
if(data['status']=='1'){
  this.toastr.success(data['msg']);
  this.getTable();
  // this.getcheckSessionDetails();

}
else{
  this.toastr.error(data['msg'])
}
     }

}
