import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-institute-innovation-evaluation',
  templateUrl: './institute-innovation-evaluation.component.html',
  styleUrls: ['./institute-innovation-evaluation.component.css']
})
export class InstituteInnovationEvaluationComponent implements OnInit {

  public mentorshipForm: FormGroup;
  innovative: any;
  patentability: any;
  strength: any;
  sum: any;
  nicid1: any;
  nic_id: any;
  market: any;
  team_members: any;
  risk_factor: any;
  robust: any;
  understand: any;
  filled: any;
  usefull: any;
  title: any;
  title1: string;
  userid1: string;
  teamId: string;
  protoId: string;
  userId: string;
  proto_id2: string;
  team_id: string;

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;
  minDate = "2018-01-01";
  maxDate = "2022-03-31";
  score_6: any;
  score_7: any;
  score_8: any;
  score_9: any;
  score_10: any;
  buttontype: any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });

    this.updateGetData();

  }

  updateGetData() {
    // this.instituteMaster
    //   .getSubmitEvaluationScoreIII(this.proto_id2, this.team_id, this.userId)
    //   .subscribe((data) => this.setupdategetdata(data));
  }

  setupdategetdata(data) {
    this.sum = 0;
    this.mentorshipForm.controls['innovation_relevance'].setValue(data['innovation_relevance'])
    this.mentorshipForm.controls['robustness'].setValue(data['robustness']);
    this.mentorshipForm.controls['uniqueness'].setValue(data['uniqueness']);
    this.mentorshipForm.controls['technological_scalability'].setValue(data['technological_scalability']);
    this.mentorshipForm.controls['business_model'].setValue(data['business_model']);

    this.mentorshipForm.controls['business_tarcktion'].setValue(data['business_tarcktion']);
    this.mentorshipForm.controls['competitive_advantage'].setValue(data['competitive_advantage']);
    this.mentorshipForm.controls['competancy_team'].setValue(data['competancy_team']);
    this.mentorshipForm.controls['reasonable_presentation'].setValue(data['reasonable_presentation']);
    this.mentorshipForm.controls['funding_investment'].setValue(data['funding_investment']);

    this.mentorshipForm.controls['recommend'].setValue(data['recommend']);

    this.mentorshipForm.controls['remark_1'].setValue(data['remark_1']);
    this.mentorshipForm.controls['remark_2'].setValue(data['remark_2']);
    this.mentorshipForm.controls['remark_3'].setValue(data['remark_3']);
    this.mentorshipForm.controls['remark_4'].setValue(data['remark_4']);

    // this.mentorshipForm.controls['strength_scroe'].setValue(data['strength_scroe']);
    // this.mentorshipForm.controls['team_members_scroe'].setValue(data['team_members_scroe']);
    // this.mentorshipForm.controls['understand_the_market_score'].setValue(data['understand_the_market_score']);
    // this.mentorshipForm.controls['useful_is_the_innovation_score'].setValue(data['useful_is_the_innovation_score']);

    this.sum = Number(data['total_score']);
    this.mentorshipForm.controls['remark'].setValue(data['remark']);
    if(data['recommend']!="")
    {
      this.buttontype=2;
    }
  }

  ngOnInit(): void {

    this.mentorshipForm = this.fb.group({
      'innovation_relevance': [null, Validators.required],
      'robustness': [null, Validators.required],
      'uniqueness': [null, Validators.required],
      'technological_scalability': [null, Validators.required],
      'business_model': [null, Validators.required],
      'business_tarcktion': [null, Validators.required],
      'competitive_advantage': [null, Validators.required],
      'competancy_team': [null, Validators.required],
      'reasonable_presentation': [null, Validators.required],
      'funding_investment': [null, Validators.required],
      'remark': [null, Validators.required],
      'remark_1': [null, Validators.required],
      'remark_2': [null, Validators.required],
      'remark_3': [null, Validators.required],
      'remark_4': [null, Validators.required],
      'recommend': [null, Validators.required],
    });
    this.mentorshipForm.get("innovation_relevance").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("robustness").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("uniqueness").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("technological_scalability").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("business_model").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("business_tarcktion").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("competitive_advantage").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("competancy_team").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("reasonable_presentation").valueChanges.subscribe(data => {
      this.changeValidators();
    })
    this.mentorshipForm.get("funding_investment").valueChanges.subscribe(data => {
      this.changeValidators();
    })

    // this.title = this.ambassador.proto_title;
    this.sum1();


    // var parm = JSON.stringify(["program_theme"]);
    // this.instituteMaster
    //   .getMasterDetails(parm)
    //   .subscribe((data) => this.handleResponseForAutoPopulate(data));



  }

  changeValidators() {
    this.sum = 0;

    this.innovative = Number(this.mentorshipForm.get('innovation_relevance').value);
    this.patentability = Number(this.mentorshipForm.get('robustness').value);
    this.strength = Number(this.mentorshipForm.get('uniqueness').value);
    this.market = Number(this.mentorshipForm.get('technological_scalability').value);
    this.team_members = Number(this.mentorshipForm.get('business_model').value);

    this.score_6 = Number(this.mentorshipForm.get('business_tarcktion').value);
    this.score_7 = Number(this.mentorshipForm.get('competitive_advantage').value);
    this.score_8 = Number(this.mentorshipForm.get('competancy_team').value);
    this.score_9 = Number(this.mentorshipForm.get('reasonable_presentation').value);
    this.score_10 = Number(this.mentorshipForm.get('funding_investment').value);

    if(this.innovative=="" || this.innovative == undefined)
    {
      this.innovative = 0;
    }
    if(this.patentability=="" || this.patentability == undefined)
    {
      this.patentability = 0;
    }
    if(this.strength=="" || this.strength == undefined)
    {
      this.strength = 0;
    }
    if(this.market=="" || this.market == undefined)
    {
      this.market = 0;
    }
    if(this.team_members=="" || this.team_members == undefined)
    {
      this.team_members = 0;
    }

    if(this.score_6=="" || this.score_6 == undefined)
    {
      this.score_6 = 0;
    }
    if(this.score_7=="" || this.score_7 == undefined)
    {
      this.score_7 = 0;
    }
    if(this.score_8=="" || this.score_8 == undefined)
    {
      this.score_8 = 0;
    }
    if(this.score_9=="" || this.score_9 == undefined)
    {
      this.score_9 = 0;
    }
    if(this.score_10=="" || this.score_10 == undefined)
    {
      this.score_10 = 0;
    }

    if(this.sum<0)
    {
      this.sum = 0;
      alert("test");
    }
    else
    {
      this.sum1();
    }
  }
  sum1() {
    this.sum = 0;
    this.sum = Number(this.innovative) + Number(this.patentability) + Number(this.strength) + Number(this.market) + Number(this.team_members) + Number(this.score_6) + Number(this.score_7) + Number(this.score_8) + Number(this.score_9) + Number(this.score_10);
  }



  onSubmit(data) {

    this.innovative = Number(this.mentorshipForm.get('innovation_relevance').value);
    this.patentability = Number(this.mentorshipForm.get('robustness').value);
    this.strength = Number(this.mentorshipForm.get('uniqueness').value);
    this.market = Number(this.mentorshipForm.get('technological_scalability').value);
    this.team_members = Number(this.mentorshipForm.get('business_model').value);

    this.score_6 = Number(this.mentorshipForm.get('business_tarcktion').value);
    this.score_7 = Number(this.mentorshipForm.get('competitive_advantage').value);
    this.score_8 = Number(this.mentorshipForm.get('competancy_team').value);
    this.score_9 = Number(this.mentorshipForm.get('reasonable_presentation').value);
    this.score_10 = Number(this.mentorshipForm.get('funding_investment').value);

    if(this.innovative<0){ alert('Problem-Solution Fit: Relevance of Innovation for the Industry/Society/Market Requirement value should vary between 0 to 10.'); return false; }

    if(this.patentability<0){ alert('Product-Market Fit: TRL & Readiness of Innovation or Solution for Market Entry/Roll out value should vary between 0 to 10.'); return false; }

    if(this.strength<0){ alert('Value Proposition Fit: Appropariate Quantification of Features of Product and Benefits to Customer value should vary between 0 to 10.'); return false; }

    if(this.market<0){ alert('Potential Market Size with Target Group Identification and Addressing Right Business Opportunity value should vary between 0 to 10.'); return false; }

    if(this.team_members<0){ alert('Business Fit: Readiness of Business Model for Implementation & Startup Formation value should vary between 0 to 10.'); return false; }

    if(this.score_6<0){ alert('Business Tarcktion: Efforts at Team or Startup to Commercialise the Innovation and Tracktions value should vary between 0 to 10.'); return false; }

    if(this.score_7<0){ alert('Competitive Advantage Level ( IP and USP) Associate with Innovaiton/Product/Business Model value should vary between 0 to 10.'); return false; }

    if(this.score_8<0){ alert('Competancy of Team to take the Innovation to Market value should vary between 0 to 10.'); return false; }

    if(this.score_9<0){ alert('Reasonable Presentation of Budget and Funding Requirement for Innovation & Startup Development value should vary between 0 to 10.'); return false; }

    if(this.score_10<0){ alert('Funding/Investment Readiness Level of Innovation Startup/innovation value should vary between 0 to 10.'); return false; }

    if (this.mentorshipForm.valid) {
      data['team_id'] = this.team_id;
      data['proto_id'] = this.proto_id2;
      data['userId'] = this.userId;
      data['idea_title'] = this.title;
      data['total_score']= this.sum;

      // this.instituteMaster
      //   .submitEvaluationIIIScore(data)
      //   .subscribe((data) => this.handleResponse(data));

    } else {
      this.toastr.error("Kindly fill all questions and remark! ")
    }

  }
  handleResponse(data) {
    if (data['msg'] == "Successfully created.") {
      this.buttontype=2;
      this.toastr.success("Successfully Submitted")
    }
    else {
      this.toastr.error(data['msg']);
    }
  }

  setData(data) {

    this.mentorshipForm.controls['innovative_score'].setValue(data['0']['innovative_score']);
    this.mentorshipForm.controls['filled_any_patent_score'].setValue(data['0']['filled_any_patent_score']);
    this.mentorshipForm.controls['market_scroe'].setValue(data['0']['market_scroe']);
    this.mentorshipForm.controls['patentability_scroe'].setValue(data['0']['patentability_scroe']);
    this.mentorshipForm.controls['risk_factor_score'].setValue(data['0']['risk_factor_score']);
    this.mentorshipForm.controls['robust_score'].setValue(data['0']['robust_score']);
    this.mentorshipForm.controls['strength_scroe'].setValue(data['0']['strength_scroe']);
    this.mentorshipForm.controls['team_members_scroe'].setValue(data['0']['team_members_scroe']);
    this.mentorshipForm.controls['understand_the_market_score'].setValue(data['0']['understand_the_market_score']);
    this.mentorshipForm.controls['useful_is_the_innovation_score'].setValue(data['0']['useful_is_the_innovation_score']);
    this.sum = data['0']['total_score']
    this.mentorshipForm.controls['remark'].setValue(data['0']['remark']);


  }


  formatLabel(value: number) {
    // if (value >= 1000) {
    //   return Math.round(value / 1000) + 'k';
    // }
    return value;
  }

}
