import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-institute-challenge-nomination',
  templateUrl: './institute-challenge-nomination.component.html',
  styleUrls: ['./institute-challenge-nomination.component.css']
})
export class InstituteChallengeNominationComponent implements OnInit {

  public instituteNomantion: FormGroup;

  allInstitutes:any;
  allChallenges:any;

  page: any;

  constructor(private fb: FormBuilder, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.instituteMaster.getYuktiChallengeDetail().subscribe(
      (data) => this.handleResponseChallenge(data)
    );
    this.instituteMaster.getAllInstitute().subscribe(
      (data) => this.handleResponse(data)
    );

    this.instituteNomantion = this.fb.group({
      challenge_id: [null],
      innovation_id: [null],
    });
  }

  handleResponseChallenge(data) {
    this.allChallenges = data;
  }

  handleResponse(data) {
    this.allInstitutes = data;
  }

  onSubmit(value) {
    var formData = new FormData();

    formData.append('challenge_id', this.instituteNomantion.get('challenge_id').value);
    formData.append('innovation_id', this.instituteNomantion.get('innovation_id').value);
    formData.append('type', '2');
    this.instituteMaster.yuktiChallengeNominateDetail(formData).subscribe(
      data => this.handleChallengeSubmitResponse(data)
    );
  }

  handleChallengeSubmitResponse(data){
    this.toastr.success('Nominate Sucessfully');
    // this.yukti_challenges = data;
  }

  onPageChanged(event){
    
  }
}
