import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JarwisService } from 'src/app/Services/jarwis.service';

@Component({
  selector: 'app-teaching-nonacademic-add',
  templateUrl: './teaching-nonacademic-add.component.html',
  styleUrls: ['./teaching-nonacademic-add.component.css']
})
export class TeachingNonacademicAddComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;
  month:any;
  days:any;

  minDate = "2023-01-01";
  maxDate = "2024-12-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService,private jarwis: JarwisService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });
  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'title': [null, Validators.required],
      'certificate_program':[null, Validators.required,],
      'academic_year': [null, Validators.required],
      'supported_by': [null, Validators.required],
      'offering_department': [null, Validators.required],
      'start_date': [null, Validators.required],
      'end_date': [null, Validators.required],
      'duration': [null, Validators.required],
      'course_hour': [null, Validators.required],
      'delivery_mode': [null, Validators.required],
      'no_of_participants': [null, Validators.required]
    });

    this.jarwis.teachingLearningData(this.rid,'nonAcademic').subscribe(
      (data) => this.handleResponse(data)
    );
    this.iicImpactQuestions.patchValue({ 
      academic_year: '2024-25',
    });
    
  }

  changeDate(values) {
    var newMonth = new Date(values).getMonth() + 1;
    if(newMonth == 10 || newMonth == 11 || newMonth == 12){
      this.month = newMonth;
    }else{
      this.month = '0'+newMonth;
    }
    var newDay = new Date(values).getDate().toString();
    if(newDay.length>1){
      this.days = newDay;
    }else{
      this.days = '0'+newDay;
    }
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + this.month;
    // value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + this.days;
    // value = value + '-' + new Date(values).getDate().toString();
    values = value;
    return values;
  }


  handleResponse(data) {   

    if(data['totalcount'] > 0){
      this.iicImpactQuestions.patchValue({
        title: data['title'],
        certificate_program: data['certificate_program'],
        academic_year: data['academic_year'],
        supported_by: data['supported_by'],
        offering_department: data['offering_department'],
        start_date: data['start_date'],
        end_date: data['end_date'],
        duration: data['duration'],
        course_hour: data['course_hour'],
        delivery_mode: data['delivery_mode'],
        no_of_participants: data['no_of_participants']
      });        

      this.document = data['document_file'];
    }else{
      this.document = '';
    }
    this.totalcount = data['totalcount'];

  }

  handleResponseList(data) {
    this.iicActivity = data['data'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {
    console.log(this.changeDate(this.iicImpactQuestions.get('start_date').value));
    console.log(this.changeDate(this.iicImpactQuestions.get('end_date').value));
    if(this.changeDate(this.iicImpactQuestions.get('start_date').value) > this.changeDate(this.iicImpactQuestions.get('end_date').value)){
      this.toastr.error('End date not greater than start date.');
      return false;
    }
    var formData = new FormData();

    formData.append('rid', this.rid);

    formData.append('title', this.iicImpactQuestions.get('title').value);
    formData.append('certificate_program', this.iicImpactQuestions.get('certificate_program').value);
    formData.append('academic_year', this.iicImpactQuestions.get('academic_year').value);
    formData.append('supported_by', this.iicImpactQuestions.get('supported_by').value);
    formData.append('offering_department', this.iicImpactQuestions.get('offering_department').value);
    formData.append('start_date', this.changeDate(this.iicImpactQuestions.get('start_date').value));
    formData.append('end_date', this.changeDate(this.iicImpactQuestions.get('end_date').value));
    formData.append('duration', this.iicImpactQuestions.get('duration').value);
    formData.append('course_hour', this.iicImpactQuestions.get('course_hour').value);
    formData.append('delivery_mode', this.iicImpactQuestions.get('delivery_mode').value);
    formData.append('no_of_participants', this.iicImpactQuestions.get('no_of_participants').value);

    var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    if(this.totalcount>0){

      formData.append('document', cvFile);
      formData.append('document_old', this.document);

    }
    else
    {
      if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
      formData.append('document', cvFile);
    }
    
    this.instituteActivityService.submitnonacademicaddRepository(formData).subscribe(
     data => this.handleResponseData(data));    
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/teaching-learning';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

}
