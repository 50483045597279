import { Component, OnInit } from '@angular/core';
import baguetteBox from 'baguettebox.js';
import * as myGlobals from '../../../../../app/app.component';
@Component({
  selector: 'app-poster-bengaluru',
  templateUrl: './poster-bengaluru.component.html',
  styleUrls: ['./poster-bengaluru.component.css']
})
export class PosterBengaluruComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  constructor() { }

  ngOnInit(): void {
    baguetteBox.run('.gallery', {
      captions: true, // display image captions.
      buttons: 'auto', // arrows navigation
      fullScreen: false,
      noScrollbars: false,
      bodyClass: 'baguetteBox-open',
      titleTag: false,
      async: false,
      preload: 2,
      animation: 'fadeIn', // fadeIn or slideIn
      verlayBackgroundColor: 'rgba(0,0,0,.8)'
    });
  }

}
