<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">IA Ambassador Profile</h1>	-->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IA Ambassador Profile</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Resources</a></li>
    <li class="breadcrumb-item active" aria-current="page">IA Ambassador Profile</li>
  </ul>
</nav>

<section class="iaAmbassadorProfileBox">
	<div class="container">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">IIC Innovation Ambassador</h2>
				</div>
			</div>
		</div>
	  <div class="row">
	  <div class="container-login100">
		<div class="col-xs-12 ">
		  <nav>
			<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
			  <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-faculty" role="tab" aria-controls="nav-home" aria-selected="true">
					<div class="incub-round2">
						<img src="assets/assets/images/faculty.png" />
						<p>Faculty</p>
				   </div>
			  </a>
			  <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-student" role="tab" aria-controls="nav-profile" aria-selected="false">
				  <div class="incub-round2">
						<img src="assets/assets/images/student.png" />
						<p>Student</p>
				   </div>
			  </a>
			  <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-expert" role="tab" aria-controls="nav-contact" aria-selected="false">
				  <div class="incub-round2">
						<img src="assets/assets/images/expert.png" />
						<p>Expert</p>
				   </div>
			  </a>
			</div>
		  </nav>
		  <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
			<div class="tab-pane fade show active" id="nav-faculty" role="tabpanel" aria-labelledby="nav-home-tab">

			  <div class="container">
			  <div class="row drop-sub-box">
			  <div class="col-md-12"><h3 class="tableHeadingIIC">Faculty</h3></div>
				<form [formGroup]="innovation" (ngSubmit)="onSubmit(innovation.value)" class="formIICInnoFaculty ng-pristine ng-valid ng-touched" novalidate="">
					<div class="col-md-3">
						<div class="form-group">
							<label>Expert Area</label>
							<select class="form-control" id="expert_area" formControlName="area">
								<option value="">All</option>
								<option value="1">Design thinking &amp; Innovations</option>
								<option value="2">IPR &amp; Technology Transfer</option>
								<option value="3">Entrepreneurship Development Program</option>
								<option value="4">Pre- Incubations &amp; Incubation Management</option>
							</select>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
						<label>State</label>
					<select class="form-control" id="state" formControlName="state" style="height: unset !important;">
						<option value="">State</option>
						<option *ngFor="let state of state" value="{{state.id}}">{{state.name}}</option>
					</select>
							<!-- <mat-form-field class="incubTabDropdown preIncubatorAllTab"> -->
								  <!-- <mat-select formControlName="state" multiple [(ngModel)]="StateModel"> -->
									  <!-- <app-select-check-all [model]="innovation.get('state')" [values]="allvalues"> -->
									  <!-- </app-select-check-all> -->
									  <!-- <mat-option *ngFor="let state of state" [value]="state.id"> -->
										<!-- {{state.name}} -->
									  <!-- </mat-option> -->
								  <!-- </mat-select> -->
							  <!-- </mat-form-field> -->
						</div>
					</div>
					<div class="col-md-3">
				  <div class="form-group">
				  <label>Keywords (Like Name etc.)</label>
					  <input class="form-control" id="searchExter" formControlName="searchExter" placeholder="Keywords (Like Name etc.)" >
				   </div>
				  </div>
					<div class="col-md-2">
						<div class="form-group">
							<button _ngcontent-pfa-c3="" class="sub-but" id="submit" name="Submit" type="submit">Submit</button>
						</div>
					</div>
				</form>
			</div>
		</div>

			 <div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="tab-box" id="newContentIaEventReport">
							<div class="dataTables_wrapper no-footer" id="myTable_ia_wrapper">
								<table class="table table-bordered dataTable no-footer newTablePagiIncub table-responsive" style="color: #000;" id="myTable_ia" role="grid" aria-describedby="myTable_ia_info">
					<thead>
					  <tr role="row">
						  <th class="sorting_asc" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-sort="ascending" aria-label="S.No.: activate to sort column descending" style="width: 74px;">S.No.</th>
						  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Expert Area: activate to sort column ascending" style="width: 133.2px;">Expert Area</th>
						  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Name of Innovation Ambassador: activate to sort column ascending" style="width: 187.6px;">Name of Innovation Ambassador</th>
						  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Institute Name: activate to sort column ascending" style="width: 183.6px;">Institute Name</th>
						 <!-- <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Email: activate to sort column ascending" style="width: 326px;">Email</th>
						  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Contact No.: activate to sort column ascending" style="width: 89.2px;">Contact No.</th> -->
						  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Clinic Timing:: activate to sort column ascending" style="width: 55.6px;">State</th>
						  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Contact No.: activate to sort column ascending" style="width: 89.2px;">Contact</th>
						  <!-- <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Clinic Timing:: activate to sort column ascending" style="width: 55.6px;">Clinic Timing:</th> -->
						  <!-- <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-label="Image: activate to sort column ascending" style="width: 134px;">Image</th> -->
					  </tr>
					</thead>
					<tbody>

					<tr role="row" class="odd" *ngFor="let details of theme; let i = index;">
							<td class="sorting_1">{{i+1}}</td>
							<td>
                {{details?.expert_area}}
							</td>
							<td>
                {{details?.name}}
							</td>
							<td>
                {{details?.institute_name}}
							</td>
							<!-- <td>
                {{details?.email}}
							</td>
							<td>
                {{details?.contact}}
							</td> -->
							
							<td>
								{{details?.stateName}}
							</td>
							<td style="vertical-align: middle;">
							<!-- <a routerLink="/iic-contact-us" class="sub-but" target="_blank">Contact</a> -->
							<button (click)="openDialog(details?.id)" class="sub-but subButAmbdProfile">Contact</button>
							</td>
							<!-- <td> -->
                <!-- {{details?.clinicTiming}} -->
							<!-- </td> -->
							<!-- <td>
							   <img src="{{baseImageUrl + details?.passport_size_photo}}" width="150">
							</td> -->
						</tr>
						</tbody>
				</table>
							</div>
						</div>
					</div>
					<div class="row">
			<div class="col-md-12">
				<mat-paginator #paginator
								showFirstLastButtons
								(page)="nextPage($event)"
								[length]="total_pages"
								[pageIndex]="frontCurrentPageNo"
								[pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
							</mat-paginator>
			</div>
		</div>
				</div>
			</div>


			</div>
			<div class="tab-pane fade" id="nav-student" role="tabpanel" aria-labelledby="nav-profile-tab">

			  <div class="container">
			  <div class="row drop-sub-box">
			  <div class="col-md-12"><h3 class="tableHeadingIIC">Student</h3></div>
				<form [formGroup]="studentForm" (ngSubmit)="onSubmitStudent(studentForm.value)" class="formIICInnoFaculty">
		<div class="col-md-3">
			<div class="form-group">
				<label>Expert Area</label>
				<select class="form-control" id="expert_area_students" formControlName="areaStudents">
					<option value="">All</option>
					<option value="1">Design thinking &amp; Innovations</option>
					<option value="2">IPR &amp; Technology Transfer</option>
					<option value="3">Entrepreneurship Development Program</option>
					<option value="4">Pre- Incubations &amp; Incubation Management</option>
				</select>
			</div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
	  <label>State</label>
		<select class="form-control" id="stateStudents" formControlName="stateStudents" style="height: unset !important;">
			<option value="">State</option>
			<option *ngFor="let stateStudents of stateStudents" value="{{stateStudents.id}}">{{stateStudents.name}}</option>
		</select>
      <!-- <mat-form-field class="incubTabDropdown preIncubatorAllTab"> -->
          <!-- <mat-select formControlName="stateStudents" multiple [(ngModel)]="StateModelStudents"> -->
              <!-- <app-select-check-all [model]="studentForm.get('stateStudents')" [values]="allvaluesStudents"> -->
              <!-- </app-select-check-all> -->
              <!-- <mat-option *ngFor="let stateStudents of stateStudents" [value]="stateStudents.id"> -->
                <!-- {{stateStudents.name}} -->
              <!-- </mat-option> -->
          <!-- </mat-select> -->
      <!-- </mat-form-field> -->
      </div>
  </div>
  <div class="col-md-3">
  <div class="form-group">
  <label>Keywords (Like Name etc.)</label>
      <input class="form-control" id="searchExterStudents" formControlName="searchExterStudents" placeholder="Keywords (Like Name etc.)" >
   </div>
  </div>

		<div class="col-md-2">
			<div class="form-group">
				<button type="submit" name="submitStudent" id="submitStudent" class="sub-but">Submit</button>
			</div>
		</div>
		</form>
			</div>
		</div>

		 <div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<div class="tab-box" id="newContentIaEventReportStudents">
							<div class="dataTables_wrapper no-footer" id="myTable_ia_wrapper_students">
								<table class="table table-bordered dataTable no-footer table-responsive" style="color: #000;" id="myTable_ia_students" role="grid" aria-describedby="myTable_ia__students_info">
					<thead>
					  <tr>
						<th>S.No.</th>
						<th>Expert Area</th>
						<th>Name of Innovation Ambassador</th>
						<th>Institute Name</th>
						<!-- <th>Email</th> -->
						<th>State</th>
						<!-- <th>Contact No.</th> -->
						<!-- <th>Clinic Timing:</th> -->
						<!-- <th>Image</th> -->
					  </tr>
					</thead>
					<tbody>

					<tr role="row" class="odd" *ngFor="let studentDetails of themeStudents; let i = index;">
							<td class="sorting_1">{{i+1}}</td>
							<td>
                {{studentDetails?.expert_area}}
							</td>
							<td>
                {{studentDetails?.name}}
							</td>
							<td>
				{{studentDetails?.institute_name}}
							</td>
							<!-- <td>
                {{studentDetails?.email}}
							</td> -->
							<td>
                {{studentDetails?.stateName}}
							</td>
							<!-- <td>
                {{studentDetails?.contact}}
							</td> -->
							<!-- <td> -->
                <!-- {{studentDetails?.clinicTiming}} -->
							<!-- </td> -->
							<!-- <td>
                <img src="{{baseImageUrl + details?.passport_size_photo}}" width="150">
							</td> -->
						</tr>
						</tbody>
				</table>
							</div>
						</div>
					</div>
					<div class="row">
			<div class="col-md-12">
				<mat-paginator #paginator
								showFirstLastButtons
								(page)="nextPageStudents($event)"
								[length]="total_pages_students"
								[pageIndex]="frontCurrentPageNoStudents"
								[pageSizeOptions]="pageSizeOptionsStudents" class="NewMatPagination">
							</mat-paginator>
			</div>
		</div>
				</div>
			</div>

			</div>
			<div class="tab-pane fade" id="nav-expert" role="tabpanel" aria-labelledby="nav-contact-tab">
			  <h2 class="noData"> No Data </h2>
			</div>
		  </div>

		</div>
		</div>
	  </div>
    </div>
</section>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
