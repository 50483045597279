<div class="container" style="margin-top: 30px;">	
    <div class="row" color="primary">
		<div class="col-md-12">
        <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Submit an Idea</h2>
    </div>
	</div>
	<mat-card class="mat-elevation-z6 proInstCardMat">
	<div class="row">
	<div class="col-md-12">
	<form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
       <div class="tableBody">
			  <div class="table-responsive">
		<table class="table">
		<tr>
			<td>
				Idea Developed By
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline">
					<mat-select formControlName="team_leader_type" placeholder="Please Select" required>
					<!-- <mat-option value="Faculty">Faculty</mat-option> -->
					<mat-option value="Student">Student</mat-option>							
				</mat-select>
				</mat-form-field>
			</td>
		</tr>


		<tr>
			<td>
				Standard/Class
			</td>
			<td>
				<mat-form-field class="w-100" appearance="outline" >
					<mat-select formControlName="standard" placeholder="Standard/Class" required>
						<mat-option value="I">I</mat-option>
						<mat-option value="II">II</mat-option>
						<mat-option value="III">III</mat-option>
						<mat-option value="IV">IV</mat-option>
						<mat-option value="V">V</mat-option>
						<mat-option value="VI">VI</mat-option>
						<mat-option value="VII">VII</mat-option>
						<mat-option value="VIII">VIII</mat-option>
						<mat-option value="IX">IX</mat-option>
						<mat-option value="X">X</mat-option>
						<mat-option value="XI">XI</mat-option>
						<mat-option value="XII">XII</mat-option>
				</mat-select>
				</mat-form-field>
			</td>
		</tr>


			<tr>
				<td>
					Team Leader Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Team Leader Name</mat-label>
					<input matInput autocomplete="off" placeholder="Team Leader Name" formControlName="team_leader_name" type="text"   required>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Team Leader Email
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Team Leader Email</mat-label>
					<input matInput autocomplete="off" placeholder="Team Leader Email" formControlName="team_leader_email" type="email"   required>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Team Leader Phone
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Team Leader Phone</mat-label>
					<input matInput autocomplete="off" placeholder="Team Leader Phone" formControlName="team_leader_phone" type="text"   required>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Team Leader Gender
				</td>
				<td>
					<mat-form-field class="w-100" appearance="outline">
						<mat-select formControlName="team_leader_gender" placeholder="Please Select" required>
							<mat-option value="Female">Female</mat-option>
							<mat-option value="Male">Male</mat-option>							
					</mat-select>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Idea Title
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Idea Title</mat-label>
					<input matInput autocomplete="off" placeholder="Idea Title" formControlName="idea_title" type="text" required>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Academic Year
				</td>
				<td>
					<mat-form-field class="w-100" appearance="outline" >
						<mat-select formControlName="academic_year" placeholder="Academic Year" required>
							<mat-option value="2019-20">2019-20</mat-option>
							<mat-option value="2020-21">2020-21</mat-option>
							<mat-option value="2021-22">2021-22</mat-option>
							<mat-option value="2022-23">2022-23</mat-option>
							<mat-option value="2023-24">2023-24</mat-option>
							<mat-option value="2024-25">2024-25</mat-option>
					</mat-select>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Theme
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">

						<mat-label> Theme</mat-label>
	  
						<mat-select autocomplete="off" placeholder="Theme" formControlName="theme" required multiple>
						  <mat-option>Select</mat-option>
						  <mat-option value="{{theme.name}}" *ngFor="let theme of programTheme">{{theme.name}}</mat-option>
						</mat-select>
						<!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Development Stage / TRL
				</td>
				<td>
					<mat-form-field class="w-100" appearance="outline">
						<mat-select formControlName="trl_level" placeholder="TRL Level" required>
							<mat-option value="1" class="drList">TRL 1 : Basic research. Principles postulated observed but no experimental proof available</mat-option>
							<mat-option value="2" class="drList">TRL 2 : Technology formulation. Concept and application have been formulated</mat-option>
							<mat-option value="3" class="drList">TRL 3 : Applied research. First laboratory tests completed; proof of concept</mat-option>
							<mat-option value="4">TRL 4 : Small scale prototype built in a laboratory environment ("ugly" prototype)</mat-option>		
							<mat-option value="5">TRL 5 : Large scale prototype tested in intended environment</mat-option>					
					</mat-select>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Idea/Project Description
				</td>
				<td>
					<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Idea/Project Description</mat-label>

					<textarea rows="2" maxlength="1000" matInput placeholder="Idea/Project Description" formControlName="idea_description" required></textarea>					
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Innovation/Business Potential
				</td>
				<td>
					<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Innovation Component</mat-label>

					<textarea rows="2" maxlength="1000" matInput placeholder="Innovation Component" formControlName="innovation_component" required></textarea>					
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Is the idea Patentable?
				</td>
				<td>
					<div class="radio-box">
						<mat-form-field class="w-100" appearance="outline">
						  <mat-select formControlName="ip_component" placeholder="Please Select" required>
							  <mat-option value="Yes">Yes</mat-option>
							  <mat-option value="No">No</mat-option>
					  </mat-select>
					  </mat-form-field>
					  </div>
				</td>
			</tr>

      <tr>
				<td>
				 Idea Diagram / Sketch (Optional)
				</td>
				<td>
					<div class="col-12">
            <label class="custom-file-label float-right" for="customFile"
                              *ngIf="!ipr_document">Choose file</label>

            <label class="custom-file-label float-right" for="customFile"
                *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>

            <input #ipr_documentRef type="file" accept="image/jpeg"  name="ipr_document"
                (change)="( ipr_document = ipr_documentRef.files )"
                class="custom-file-input float-right">
              </div>

				</td>
			</tr>

      <tr *ngIf="document">
        <th>&nbsp;</th>
        <td colspan="3">
            <a href="{{ baseUrl + document}}" target="_blank">View File</a>
        </td>
      </tr>

		<tr>
			<td>
				Idea Explanation Video (Optional)
			</td>
			<td>
				<mat-form-field appearance="outline" class="w-100">
				<mat-label>Video Url</mat-label>
				<input matInput autocomplete="off" placeholder="video" formControlName="video" type="text">
				</mat-form-field>
			</td>
		</tr>

      <tr>
				<td>
					&nbsp;
				</td>
				<td>
					<button mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicImpactQuestions.invalid'>Submit</button>
				</td>
			</tr>


	   </table>
	   </div>
	   </div>
	</form>
	</div>
	</div>
	</mat-card>
</div>
