<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Volunteer Mentor Mentee 2021</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<div id="carouselExampleControlsMobile" class="carousel slide bannerSlideInnovation" data-ride="carousel">
  <div class="carousel-inner">
	<div class="carousel-item active">
	  <img class="d-block w-100" src="assets/assets/images/mentor-mentee-program-banner.jpg" alt="First slide">
	  <div class="carousel-caption innovationContest2020Content">
		<h2>Mentor-Mentee <br>Programme <br>for IIC Institutions</h2>
	  </div>
	</div>
  </div>

  <!-- <a class="carousel-control-prev landingSliderBannerPrevMob" href="#carouselExampleControlsMobile" role="button" data-slide="prev">
	<i class="fa fa-arrow-left" aria-hidden="true"></i>
	<span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next landingSliderBannerNextMob" href="#carouselExampleControlsMobile" role="button" data-slide="next">
	<i class="fa fa-arrow-right" aria-hidden="true"></i>
	<span class="sr-only">Next</span>
  </a> -->
</div>

<div class="pageNavigationBar">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<button class="navbar-toggler mentorNavRes" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
					  <span class="navbar-toggler-icon"></span>
					</button>

					<div class="collapse navbar-collapse" id="navbarColor01">
					  <ul class="navbar-nav mr-auto mentorPageNav">
						<li class="nav-item active">
						  <a class="nav-link" href="#objectiveBox" data-scroll-nav="1"><mat-icon>check_circle</mat-icon> Objective</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#outcomesBox"><mat-icon>outbound</mat-icon> Expected Outcomes</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="/mentor-mentee-2021"><mat-icon>payments</mat-icon> Phase I</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="/volunteer-mentor-mentee-2021"><mat-icon>front_hand</mat-icon> Phase II</a>
						</li>
						<!-- <li class="nav-item">
						  <a class="nav-link" href="#applyBox"><mat-icon>approval</mat-icon> How and When to Apply</a>
						</li> -->
						<!-- <li class="nav-item">
						  <a class="nav-link" href="#eligibilityBox"><mat-icon>admin_panel_settings</mat-icon> Eligibility</a>
						</li> -->
						<li class="nav-item">
						  <a class="nav-link" href="#activitiesBox"><mat-icon>local_activity</mat-icon> Mentoring Activities</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="{{ imageBasePath }}assets/announcements/Mentor-Mentee-Scheme.pdf" target="_blank"><mat-icon>batch_prediction</mat-icon> Scheme Document</a>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#termBox"><mat-icon>gavel</mat-icon> Terms and Conditions</a>
						</li>
						<!-- <li class="nav-item">
						  <a class="nav-link" href="#contactBox"><mat-icon>contact_phone</mat-icon> Contact Us</a>
						</li> -->
					  </ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</div>


<div class="container" style="padding-top: 50px;">
  <div class="row">
    <form [formGroup]="innovationCourse"
      (ngSubmit)="onSubmitCourse(innovationCourse.value)"
      class="formIICInnoFaculty ng-pristine ng-valid ng-touched" novalidate="">
      <div class="col-md-3">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-label>Mentor Institute Name</mat-label>
          <input matInput formControlName="instituteName" placeholder="Mentor Institute Name">
          <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
      </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="ratingDetails1 example-full-width" appearance="outline"
          style="margin: auto;">
          <!-- <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon> -->
          <mat-label>Institute Course</mat-label>
          <mat-select placeholder="Institute Course"
            formControlName="instituteCourses" (selectionChange)="getCourseState()">
            <mat-option value="">All</mat-option>

            <mat-option *ngFor="let instituteCoursesItems of instituteCourses"
              [value]="instituteCoursesItems.courses">
              {{instituteCoursesItems.courses}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="ratingDetails1 example-full-width" appearance="outline"
          style="margin: auto;">
          <mat-label>Select State</mat-label>
          <mat-select formControlName="courseState" [(ngModel)]="StateModel2">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let stateItems of state"
              [value]="stateItems.id">
              {{stateItems.stateName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <button _ngcontent-pfa-c3="" class="sub-but" id="submitCourse"
            name="SubmitCourse" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>

<section class="ratingsTable">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<p class="mentorTableTop">List of IIC Institutions Selected as Mentor Institutions under the Mentor-Mentee Scheme 2021-22</p>
				<p class="mentorTableBottom">(Without Funding Assistance from MIC/AICTE)</p>
			</div>
			<div class="col-md-12" style="padding-right: 0;">
				<div class="tab-box" id="newContentIaEventReport">
					<div class="dataTables_wrapper no-footer" id="myTable_ia_wrapper">
						<table class="table table-bordered dataTable no-footer newTablePagiIncub table-responsive" style="color: #000;" role="grid" aria-describedby="myTable_ia_info">
			<thead>
			  <tr role="row">

				  <th class="sorting_asc" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-sort="ascending">S.No.</th>
				  <th class="sorting_asc" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">Mentor Institute Details</th>
				  <th class="sorting_asc" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1">Mentee Name</th>
				  <th class="sorting_asc" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 12.2%;">Mentee Institute State</th>
				  <th class="sorting_asc" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 12%;">Mentee Institute Zone</th>
			  </tr>
			</thead>
			<tbody>

        <tr role="row" class="odd" *ngFor="let details of theme; let i = index;">
					<td class="sorting_1">{{(i + 1)+ (10 * (currentPageNo-1))}}</td>
					<td>
              <p>{{details?.instituteName}}, {{details?.city}}, {{details?.stateName}}, {{details?.instituteZone}}</p>
              <p>{{details?.userId}}</p>
          </td>
          <td colspan="3" style="padding:0; border:none;">
            <table class="" style="color: #000; width: 100%; margin: 0;" role="grid" aria-describedby="myTable_ia_info">
              <tbody>
                <tr *ngFor="let mentees of details?.menteeInstitutes; let j = index;">
                  <td width="60%">{{mentees?.instituteName}}</td>
                  <td width="20%">{{mentees?.stateName}}</td>
                  <td width="20%">{{mentees?.regional_zone}}</td>
                </tr>
              </tbody>
            </table>
          </td>
				</tr>
				</tbody>
		</table>
					</div>
				</div>
			</div>

		</div>
		<div class="row">
        <div class="col-md-12">
          <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)"
            [length]="total_pages" [pageIndex]="frontCurrentPageNo"
            [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
          </mat-paginator>
        </div>
      </div>
	</div>
</section>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>

