<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

  <link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
  <link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
  <link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
  <link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">
  
  <style>
  .navbar-nav>li {
      float: none !important;
      text-align: center !important;
	  line-height: 24px;
    }


</style>

  <!-- <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container-fluid navigation_width">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="@"><img src="assets/institute/images/logo.png" class="img-responsive"></a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav navbar-right" style="width:auto;float: left !important;right:40px; top: 0;">
          <li><span style="font-size: 27px;">MHRD’s Innovation Cell<br>Institution’s Innovation Council</span><br>Institute Portal</li>
        </ul>
      </div>
    </div>
  </nav> -->

<div class="limiter">
    <div class="container-login100">
		<div class="wrap-login100">
			<span class="login100-form-title p-b-20">
				<img src="assets/institute/images/logoiic.png" class="loginIICLogo signupIICLogo">
			</span>
			<span class="login100-form-title loginInstiTopContent">
					<h2>Forgot Password</h2>
			    </span>
			
			<form class="login100-form login100-formwithForgetPassword validate-form flex-sb flex-w" #RequestResetForm=ngForm (ngSubmit)="onSubmit()">
				<div class="form-group wrap-input100 validate-input m-b-16">
					<!-- <label class="control-label col-sm-2" for="email">Email:</label> -->
						<input type="email" name="email" placeholder="Enter Email Id" class="input100" [(ngModel)]="form.email" id="inputEmail3" required
              autocomplete="off">
				</div>
				

				
				<!-- <div class="form-group pull-left" style="padding-top: 10px;">
					<label>
						<a routerLink="/login" class="float-right login100-form-btnSignUp">Sign In</a>
					</label>
				</div>
				
				<div class="form-group pull-left" style="padding-top: 10px;">
					<label>
						<a routerLink="/signup" class="float-left login100-form-btnSignUp btnNewUser">New User?</a>
					</label>
				</div> -->
				
				<div class="container-login100-form-btn m-t-17">
					<button type="submit" class="login100-form-btnSignUp" [disabled]="!RequestResetForm.valid">
            <!-- <mat-icon class="mt-2"> arrow_forward_ios</mat-icon> -->Submit
          </button>
          <!-- <label class="forgetSignIn">
						<a routerLink="/login" class="float-right login100-form-btnSignUp">Sign In</a>
					</label> -->
				</div>
			</form>
		</div>
    </div>
</div>
  
<!-- Footer 
<app-institute-footer></app-institute-footer> -->
  