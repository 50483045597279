import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-statuscountdialog',
  templateUrl: './statuscountdialog.component.html',
  styleUrls: ['./statuscountdialog.component.css']
})
export class StatuscountdialogComponent implements OnInit {
  
  nominationArray = [];

  constructor(
    public dialogRef: MatDialogRef<StatuscountdialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    
  }

  ngOnInit(): void {

    
    

   
  }

  

  onNoClick(): void {
    this.dialogRef.close();
  }

  

}
