<!-- <router-outlet></router-outlet> -->
<!-- <router-outlet></router-outlet> -->
<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>


<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Gallery</a></li>
  </ul>
</nav>

<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Gallery</h2>
			</div>
		</div>
		</div>	  
	</div>	
 </div>

<section id="galleryTabs">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<nav>
					<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
						
							<a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Photo</a>
						
						
							<a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Video</a>
					
						
							<a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">News & Media</a>
						
					</div>
				</nav>
				<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
					<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
						<div class="row">
							<div class="col-md-4 mb-3">
								<div class="gallery-img-outer">
									<a class="gallery-text" href="/gallery-photo-2023">
										<img class="sih-gallery-img img-fluid" src="{{imageBasePath}}assets/wp_images/regional-meet/chennai/thumbanils/IIC-thumbnail-footer-2023.png" alt="image">
										<p class="newsParaBottom">Regional Meet Photo 2023</p>
									</a>
								</div>
							</div>
							<div class="col-md-4 mb-3">
								<div class="gallery-img-outer">
									<a class="gallery-text" href="/photo-gallery">
										<img class="sih-gallery-img img-fluid" src="{{imageBasePath}}assets/wp_images/regional-meet/chennai/thumbanils/IIC-thumbnail-footer-2022.png" alt="image">
										<p class="newsParaBottom">Regional Meet Photo 2022</p>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
						<div class="row">
							 <div class="col-md-4 mb-3">
								<div class="gallery-img-outer">
									<a class="gallery-text" routerLink="/gallery-video-2023">
										<img class="sih-gallery-img img-fluid" src="{{imageBasePath}}assets/wp_images/regional-meet/chennai/thumbanils/video-gallery-thumb-2023.png" alt="image">
										<p class="newsParaBottom">Regional Meet Video 2023</p>
									</a>
								</div>
							</div>
							<div class="col-md-4 mb-3">
								<div class="gallery-img-outer">
									<a class="gallery-text" routerLink="/video-gallery">
										<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/regional-meet/chennai/thumbanils/video-gallery-thumb-2022.png" alt="image">
										<p class="newsParaBottom">Regional Meet Video 2022</p>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
						<div class="row">
							<div class="col-md-4 mb-3">
								<div class="gallery-img-outer">
									<a class="gallery-text" routerLink="/news-2023">
										<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/regional-meet/chennai/thumbanils/news-thumb-2023.png" alt="image">
										<p class="newsParaBottom">Regional Meet News 2023</p>
									</a>
								</div>
							</div>
							 <div class="col-md-4 mb-3">
								<div class="gallery-img-outer">
									<a class="gallery-text" routerLink="/media">
										<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/regional-meet/chennai/thumbanils/news-thumb-2022.png" alt="image">
										<p class="newsParaBottom">Regional Meet News 2022</p>
									</a>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			
			</div>
		</div>
	</div>
</section>

<app-iic-web-footer></app-iic-web-footer>
