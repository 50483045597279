<mat-card class="mat-elevation-z6 m-4 instiNominiCard">
      <h2 style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">Scan the QR code to join the whatsApp Group</h2>
	  
	<div class="row">
		<div class="col-md-12">
			<h2 class="title2">Do not share the QR code with other IIC Council Members</h2>
			<h2 class="title3">WhatsApp group is strictly for IIC President and IIC Covener only</h2>
		</div>
	</div>
  <div class="rwsdatalist row">
    <div class="col-md-8 offset-2" *ngIf="zone=='Central/CRO'">
        <div class="rwslistinner">
            <h3>Central/CRO</h3>
            <div class="rwslicontent">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Abhishek/new/1.jpg" class="img-fluid" alt="QR Code"/>
                  </div>
                  
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Abhishek/new/3.jpg" class="img-fluid" alt="QR Code"/>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8 offset-2" *ngIf="zone=='Eastern/ERO'">
      <div class="rwslistinner">
          <h3>Eastern/ERO</h3>
          <div class="rwslicontent">
              <div class="row">
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Udyan/1.png" class="img-fluid" alt="QR Code"/>
                  </div>
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Udyan/2.png" class="img-fluid" alt="QR Code"/>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
  <div class="rwsdatalist row">
    <div class="col-md-8 offset-2" *ngIf="zone=='Northern/NRO'">
        <div class="rwslistinner">
            <h3>Northern/NRO</h3>
            <div class="rwslicontent">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Udyan/3.png" class="img-fluid" alt="QR Code"/>
                  </div>
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Udyan/4.png" class="img-fluid" alt="QR Code"/>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8 offset-2" *ngIf="zone=='North-West/NWRO'">
      <div class="rwslistinner">
          <h3>North-West/NWRO</h3>
          <div class="rwslicontent">
              <div class="row">
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Shubham/3.png" class="img-fluid" alt="QR Code"/>
                  </div>
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Shubham/4.png" class="img-fluid" alt="QR Code"/>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
  <div class="rwsdatalist row">
    <div class="col-md-8 offset-2" *ngIf="zone=='South-Central/SCRO'">
        <div class="rwslistinner">
            <h3>South-Central/SCRO</h3>
            <div class="rwslicontent">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Surendar/new/1.jpeg" class="img-fluid" alt="QR Code"/>
                  </div>
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Surendar/new/2.jpeg" class="img-fluid" alt="QR Code"/>
                  </div>
                
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8 offset-2" *ngIf="zone=='Southern/SRO'">
      <div class="rwslistinner">
          <h3>Southern/SRO</h3>
          <div class="rwslicontent">
              <div class="row">
             
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Selva/1.png" class="img-fluid" alt="QR Code"/>
                  </div>
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Selva/2.png" class="img-fluid" alt="QR Code"/>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
  <div class="rwsdatalist row">
    <div class="col-md-8 offset-2" *ngIf="zone=='South-West/SWRO'">
        <div class="rwslistinner">
            <h3>South-West/SWRO</h3>
            <div class="rwslicontent">
                <div class="row">
                    <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Abhishek/new/2.jpg" class="img-fluid" alt="QR Code"/>
                  </div>
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Abhishek/new/4.jpg" class="img-fluid" alt="QR Code"/>
                  </div>
                </div>
            </div>
        </div> 
    </div>
    <div class="col-md-8 offset-2" *ngIf="zone=='Western/WRO'">
      <div class="rwslistinner">
          <h3>Western/WRO</h3>
          <div class="rwslicontent">
              <div class="row">
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Surendar/new/3.jpeg" class="img-fluid" alt="QR Code"/>
                  </div>
                  <div class="col-md-6 mb-3">
                    <img src="assets/images/qrcode/Surendar/new/4.jpeg" class="img-fluid" alt="QR Code"/>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </div>
</mat-card>
