<style type="text/css">
  .alert-red{color: #721c24; background-color: #f8d7da;  border-color: #f5c6cb;}
  .alert-green{color: #155724; background-color: #d4edda; border-color: #c3e6cb;}
</style>
<div class="container-fluid">


  <mat-card class="mat-elevation-z6 proInstCardMat">
	<div class="row" *ngIf="this.ForWhom != 'institute_student'">
		<div class="col-md-4">
			<button mat-raised-button color='primary' (click)='openDialog()' class="schDetBut">
				<mat-icon>add</mat-icon> Add Teaching/Non teaching Members
			</button>
		</div>
		<div class="col-md-4">

		</div>
		<!-- <div class="col-md-4">
			<mat-form-field class="example-full-width instiFacSearch" appearance="outline" style="color: black; width: 330px;">
				<mat-label>Search</mat-label>
				<input matInput placeholder="Search..." [(ngModel)]="filterData" (keyup)=search($event)>
			</mat-form-field>
		</div> -->
	</div>

  <!-- <div class="row">
		<div class="col-md-12">
			<div class="tableBody">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th><p class="anaTitle">Role</p></th>
								<th><p class="anaTitle">Members</p></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let faculty of facultylistroles;let i = index">
								<td *ngIf="faculty?.UserCount>0" class="alert-green"><p class="anaContent">{{faculty?.Role}}</p></td>
								<td *ngIf="faculty?.UserCount>0" class="alert-green">
                  <table class="table">
                    <tbody>
                      <tr *ngFor="let facultysubdata of faculty?.User;let i = index">
                        <td width="30%"><p class="anaContent">{{facultysubdata?.name}}</p></td>
                        <td width="30%"><p class="anaContent">{{facultysubdata?.email}}</p></td>
                        <td width="30%"><p class="anaContent">{{facultysubdata?.mobile}}</p></td>
                        <td width="20%">
                          <button mat-icon-button="" class="btn-tbl-edit" (click)="openEditDialog(facultysubdata)">
                            <span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span><span class="mat-button-focus-overlay"></span>
                          </button>
                          <button mat-icon-button="" class="btn-tbl-delete" aria-label="delete" (click)="deleteDialog(facultysubdata)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>

                <td *ngIf="faculty?.UserCount==0" class="alert-red"><p class="anaContent">{{faculty?.Role}}</p></td>
								<td *ngIf="faculty?.UserCount==0" class="alert-red">
                  <table class="table">
                    <tbody>
                      <tr *ngFor="let facultysubdata of faculty?.User;let i = index">
                        <td width="30%"><p class="anaContent">{{facultysubdata?.name}}</p></td>
                        <td width="30%"><p class="anaContent">{{facultysubdata?.email}}</p></td>
                        <td width="30%"><p class="anaContent">{{facultysubdata?.mobile}}</p></td>
                        <td width="20%">
                          <button mat-icon-button="" class="btn-tbl-edit" (click)="openEditDialog(facultysubdata)">
                            <span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span><span class="mat-button-focus-overlay"></span>
                          </button>
                          <button mat-icon-button="" class="btn-tbl-delete" aria-label="delete" (click)="deleteDialog(facultysubdata)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>


							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div> -->
  <div class="rws-infotouser" *ngIf="facultyStatus==0" style="padding-top: 20px;">
    <div class="alert alert-danger" role="alert">
      <strong>Faculty roles need to be assign/add.</strong><br/>{{facultyRoles}}
    </div>
  </div>

	<div class="row">
		<div class="col-md-12">
			<div class="tableBody">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th><p class="anaTitle">Roles</p></th>
								<th><p class="anaTitle">Name & Details</p></th>
								<th><p class="anaTitle">Department</p></th>
								<th><p class="anaTitle">Designation</p></th>
								<th><p class="anaTitle">Qualification</p></th>
								<!-- <th><p class="anaTitle">Position</p></th> -->
								<th><p class="anaTitle">Experience in Years</p></th>								
								<th style="width: 10%;"><p class="anaTitle">Action</p></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><p class="anaContent">President</p></td>
								<td><p class="anaContent" style="font-weight: bold; font-size: 16px;">{{president_detail?.spoc_name}}</p>
								<p class="anaContent">{{president_detail?.spoc_email}}</p>
								<p class="anaContent">{{president_detail?.spoc_contact}}</p>
								</td>
								<td></td>
								<td><p class="anaContent">{{president_detail?.spoc_designation}}</p></td>
								<td></td>
								<!-- <td></td> -->
								<td></td>
								<td><p>
									<a class="btn-tbl-edit" routerLink="/institute/profile"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span><span class="mat-button-focus-overlay"></span></a></p>
								</td>
							</tr>
							<tr *ngFor="let faculty of faculties;let i = index">
								<td><p class="anaContent">{{faculty?.roles}}</p></td>
								<td><p class="anaContent" style="font-weight: bold; font-size: 16px;">{{faculty?.name}}</p>
								<p class="anaContent">{{faculty?.email}}</p>
								<p class="anaContent">{{faculty?.mobile}}</p>
								</td>
								<td><p class="anaContent">{{faculty?.deaprtment}}</p></td>
								<td><p class="anaContent">{{faculty?.designation}}</p></td>
								<td><p class="anaContent">{{faculty?.qualification}}</p></td>
								<!-- <td><p class="anaContent">{{faculty?.position}}</p></td> -->
								<td><p class="anaContent">{{faculty?.experience_in_years}}</p></td>
								
								<td>
									<p *ngIf="faculty?.roles!='President'">
									<button mat-icon-button="" class="btn-tbl-edit" (click)="openEditDialog(faculty)"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span><span class="mat-button-focus-overlay"></span></button>
									<button mat-icon-button="" class="btn-tbl-delete" aria-label="delete" (click)="deleteDialog(faculty)">
									  <mat-icon>delete</mat-icon>
									</button>
								</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
    <div class="col-md-12">
      <mat-paginator #paginator showFirstLastButtons (page)="nextPage1($event)" [length]="total_pages"
        [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
      </mat-paginator>

    </div>
  </div>
</mat-card>

  <!-- <div *ngIf="faculties.length<1">
    <div class="card text-center text-white bg-info mb-4 card-shadow">
      <div class="card-body">
        <p class="card-text text-white"><i>No faculties added yet!</i></p>
      </div>
    </div>
  </div> -->

	<!-- Card Pagination -->






</div>
