<div class="container-fluid">
    <div class="row" color="primary">
      <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">Nomination Challenges</h2>
    </div>
  
    <div class="refrredBox">
  
      <div class="row rws-listpage">
        <div class="col-md-12 text-center mt-4">
          <h3><strong style="font-weight: 700;">Challenge Detail</strong></h3>
        </div>
        <div class="col-md-12 text-right mt-4">
          <a href="/institute/yukti-challenges" class="btn" style="background-color: #f08b04;color: #fff;">Back</a>
        </div>
        <div class="col-md-12 mt-2">
          <div class="col-md-6">
            <p><strong>Challenge Title -</strong>
            <span>{{ yuktiChallenge?.title }}</span></p>
            <p><strong>Coordinator Name -</strong>
            <span>{{ yuktiChallenge?.coordinator_name }}</span></p>
            <p><strong>Coordinator Email -</strong>
            <span>{{ yuktiChallenge?.coordinator_email }}</span></p>
            <p><strong>Coordinator Phone -</strong>
            <span>{{ yuktiChallenge?.coordinator_phone }}</span></p>
          </div>
          <div class="col-md-6">
            <p><strong>Submission Start Date -</strong>
            <span>{{ yuktiChallenge?.submission_start_date }}</span></p>
            <p><strong>Submission End Date -</strong>
            <span>{{ yuktiChallenge?.submission_end_date }}</span></p>
            <p><strong>Event Start Date -</strong>
            <span>{{ yuktiChallenge?.event_start_date }}</span></p>
            <p><strong>Event End Date -</strong>
            <span>{{ yuktiChallenge?.event_end_date }}</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="refrredBox">
      <div class="row rws-listpage">
        <div class="col-md-12">
          <!-- <p><a class="btn btn-primary referralBut" routerLink='/institute/yukti-challenges-add/add'>Add New</a></p> -->
          <div class="tableBody">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead class="facultyTable">
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Challenge Title</th>
                    <th scope="col">Theme</th>
                    <th scope="col">Innovation Title</th>
                    <th scope="col">Team Lead Name</th>
                    <th scope="col">Team Lead Email</th>
                    <th scope="col">Team Lead Phone</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let nomination of nominatedInnovationData; index as i" >
                    <td>{{i+1}}</td>
                    <td>{{nomination?.ctitle}}</td>
                    <td>{{nomination?.theme}}</td>
                    <td>
                      {{nomination?.idea_title}}
                    </td>
                    <td>{{nomination?.team_lead_name}}</td>
                    <td>{{nomination?.team_lead_email}}</td>
                    <td>{{nomination?.team_lead_phone}}</td>
                    <td>
                      <!-- <p *ngIf="nomination?.status!=1"><a routerLink="/institute/view-submission/{{ nomination?.id }}">
                      <button mat-raised-button matTooltip="View Submission" matTooltipPosition="after" aria-label="Button that displays a tooltip when focused or hovered over" color="warn" class="btn-tbl-edit">
                        <mat-icon>list</mat-icon>
                      </button></a></p>     -->
                      <!-- <p *ngIf="nomination?.status!=1">
                        <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn" class="btn-tbl-edit">
                      <mat-icon>edit</mat-icon>
                      </button></p>   -->  
                      <p *ngIf="nomination?.tstatus==1"><button mat-raised-button (click)="onClickEvent(nomination?.cnid, 'Delete')"   color="warn" class="btn-tbl-delete">
                      <mat-icon>delete</mat-icon>
                      </button></p>
                    </td>
                  </tr>
                  <tr *ngIf="nominatedInnovationData?.length== 0" >
                    <td colspan="8"><h3 style="text-align: center">No Data Found</h3></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>