import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-up-policy',
  templateUrl: './start-up-policy.component.html',
  styleUrls: ['./start-up-policy.component.css']
})
export class StartUpPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
