import { Injectable } from '@angular/core';
import * as myGlobals from '../../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OnlineRegistrationService {

  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  getState() { // get instituite name
    return this.http.get(
      `${this.baseUrl}/osia/getStateOSIA` 
    );
  }

  getOSInstitutes(state) { // get instituite name
    return this.http.get(
      `${this.baseUrl}/osia/getIICInstitutes/` + state
    );
  }


  getInstitutesParticipantNameOSIA(institute_name) { // get instituite name
    return this.http.get(
      `${this.baseUrl}/osia/getInstitutesParticipantNameOSIA/` + institute_name
    );
  }

  getInstitutesParticipantDetailsOSIA(participant_name) { // get instituite name
    return this.http.get(
      `${this.baseUrl}/osia/getInstitutesParticipantDetailsOSIA/` + participant_name
    );
  }

  submitOnlineRegistrationForm(data) {
    return this.http.post(
      `${this.baseUrl}/osia/registerOSIA `,
      data
    );
  }
  submitRegistrationOnlineForm(data) {
    return this.http.post(
      `${this.baseUrl}/osia/registerOnlineSessionIA `,
      data
    );
  }

  getInstitutes(state) { // get register approved instituite name
    return this.http.get(
      `${this.baseUrl}/osia/getInstitutes/` + state
    );
  }


}