import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { InstituteIicAkamDialogComponent } from './institute-iic-akam-dialog/institute-iic-akam-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-institute-iic-akam',
  templateUrl: './institute-iic-akam.component.html',
  styleUrls: ['./institute-iic-akam.component.css']
})
export class InstituteIicAkamComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  rid:any;
  concent_1:any;
  concent_2:any;
  concent_3:any;
  concent_4:any;
  concent_5:any;
  concent_6:any;
  concent_7:any;

  student_1:any;
  student_2:any;
  student_3:any;
  student_4:any;
  student_5:any;
  student_6:any;
  student_7:any;

  collage_1:any;
  collage_2:any;
  collage_3:any;
  collage_4:any;
  collage_5:any;
  collage_6:any;
  collage_7:any;
  submission_status:any;

  allData:any;

  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });
  }

  ngOnInit(): void {

    this.concent_1=0;
    this.concent_2=0;
    this.concent_3=0;
    this.concent_4=0;
    this.concent_5=0;
    this.concent_6=0;
    this.concent_7=0;

    this.student_1 = 0;
    this.student_2 = 0;
    this.student_3 = 0;
    this.student_4 = 0;
    this.student_5 = 0;
    this.student_6 = 0;
    this.student_7 = 0;

    this.collage_1 = "";
    this.collage_2 = "";
    this.collage_3 = "";
    this.collage_4 = "";
    this.collage_5 = "";
    this.collage_6 = "";
    this.collage_7 = "";

    this.submission_status = 0;

    this.instituteActivityService.getIicAkamSubDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

    this.iicImpactQuestions = this.fb.group({
      'session_1': [null],
      'session_2': [null],
      'session_3': [null],
      'session_4': [null],
      'session_5': [null],
      'session_6': [null],
      'session_7': [null]
    });


  }

  handleResponse(data) {

    if(data['totalcount'] > 0){

    this.iicImpactQuestions.patchValue({
      session_1: data['concent_1'],
      session_2: data['concent_2'],
      session_3: data['concent_3'],
      session_4: data['concent_4'],
      session_5: data['concent_5'],
      session_6: data['concent_6'],
      session_7: data['concent_7']
    });

    if(data['concent_1']=="Yes") { this.concent_1=1; } else { this.concent_1=0; }
    if(data['concent_2']=="Yes") { this.concent_2=1; } else { this.concent_2=0; }
    if(data['concent_3']=="Yes") { this.concent_3=1; } else { this.concent_3=0; }
    if(data['concent_4']=="Yes") { this.concent_4=1; } else { this.concent_4=0; }
    if(data['concent_5']=="Yes") { this.concent_5=1; } else { this.concent_5=0; }
    if(data['concent_6']=="Yes") { this.concent_6=1; } else { this.concent_6=0; }
    if(data['concent_7']=="Yes") { this.concent_7=1; } else { this.concent_7=0; }

  this.student_1 = data['student_1'];
  this.student_2 = data['student_2'];
  this.student_3 = data['student_3'];
  this.student_4 = data['student_4'];
  this.student_5 = data['student_5'];
  this.student_6 = data['student_6'];
  this.student_7 = data['student_7'];

  this.collage_1 = data['collage_1'];
  this.collage_2 = data['collage_2'];
  this.collage_3 = data['collage_3'];
  this.collage_4 = data['collage_4'];
  this.collage_5 = data['collage_5'];
  this.collage_6 = data['collage_6'];
  this.collage_7 = data['collage_7'];

  this.submission_status = data['submission_status'];

  }
    this.totalcount = data['totalcount'];

    this.allData = data;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;
    return false;
  }

  submitReportFinal()
  {
    var formData = new FormData();
    formData.append('event_id', this.rid);
    formData.append('submit_type', "finalSubmission");

    if(confirm("Are you sure want to submit the report? Once you will submit you were unable make any changes."))
    {
    this.instituteActivityService.postIicAkamSubDetails(formData).subscribe(
      data => this.handleResponseData2(data, "10", "10"));
    }

  }

  onAccept(ob, session) {

    var formData = new FormData();

    formData.append('concent', ob.value);
    formData.append('session', session);
    formData.append('event_id', this.rid);

    this.instituteActivityService.postIicAkamSubDetails(formData).subscribe(
      data => this.handleResponseData(data, ob.value, session));

  }

  handleResponseData(data, concentvalue, session){
    if(data['status']=="1"){

      this.toastr.success(data['msg']);

      if(session==1) { if(concentvalue=="Yes") { this.concent_1=1; } }
      if(session==2) { if(concentvalue=="Yes") { this.concent_2=1; } }
      if(session==3) { if(concentvalue=="Yes") { this.concent_3=1; } }
      if(session==4) { if(concentvalue=="Yes") { this.concent_4=1; } }
      if(session==5) { if(concentvalue=="Yes") { this.concent_5=1; } }
      if(session==6) { if(concentvalue=="Yes") { this.concent_6=1; } }
      if(session==7) { if(concentvalue=="Yes") { this.concent_7=1; } }

      this.instituteActivityService.getIicAkamSubDetails(this.rid).subscribe(
        (data) => this.handleResponse(data)
      );



    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  handleResponseData2(data, concentvalue, session){
    if(data['status']=="1"){

      this.toastr.success(data['msg']);

      if(session==1) { if(concentvalue=="Yes") { this.concent_1=1; } }
      if(session==2) { if(concentvalue=="Yes") { this.concent_2=1; } }
      if(session==3) { if(concentvalue=="Yes") { this.concent_3=1; } }
      if(session==4) { if(concentvalue=="Yes") { this.concent_4=1; } }
      if(session==5) { if(concentvalue=="Yes") { this.concent_5=1; } }
      if(session==6) { if(concentvalue=="Yes") { this.concent_6=1; } }
      if(session==7) { if(concentvalue=="Yes") { this.concent_7=1; } }

      this.instituteActivityService.getIicAkamSubDetails(this.rid).subscribe(
        (data) => this.handleResponse(data)
      );

    }
    else{
      this.toastr.error(data['msg']);
    }
  }

  openDialog(session) {

    let data=[];

    data['session']=session;
    data['rid']=this.rid;

    if(session==1)
    {
      data['concent']     = this.allData['concent_1'];
      data['student']     = this.allData['student_1'];
      data['faculty']     = this.allData['faculty_1'];
      data['collage']     = this.allData['collage_1'];
      data['benefits']     = this.allData['benefits_1'];
    }

    if(session==2)
    {
      data['concent']     = this.allData['concent_2'];
      data['student']     = this.allData['student_2'];
      data['faculty']     = this.allData['faculty_2'];
      data['collage']     = this.allData['collage_2'];
      data['benefits']     = this.allData['benefits_2'];
    }

    if(session==3)
    {
      data['concent']     = this.allData['concent_3'];
      data['student']     = this.allData['student_3'];
      data['faculty']     = this.allData['faculty_3'];
      data['collage']     = this.allData['collage_3'];
      data['benefits']    = this.allData['benefits_3'];
    }

    if(session==4)
    {
      data['concent']     = this.allData['concent_4'];
      data['student']     = this.allData['student_4'];
      data['faculty']     = this.allData['faculty_4'];
      data['collage']     = this.allData['collage_4'];
      data['benefits']     = this.allData['benefits_4'];
    }

    if(session==5)
    {
      data['concent']     = this.allData['concent_5'];
      data['student']     = this.allData['student_5'];
      data['faculty']     = this.allData['faculty_5'];
      data['collage']     = this.allData['collage_5'];
      data['benefits']     = this.allData['benefits_5'];
    }

    if(session==6)
    {
      data['concent']     = this.allData['concent_6'];
      data['student']     = this.allData['student_6'];
      data['faculty']     = this.allData['faculty_6'];
      data['collage']     = this.allData['collage_6'];
      data['benefits']     = this.allData['benefits_6'];
    }

    if(session==7)
    {
      data['concent']     = this.allData['concent_7'];
      data['student']     = this.allData['student_7'];
      data['faculty']     = this.allData['faculty_7'];
      data['collage']     = this.allData['collage_7'];
      data['benefits']     = this.allData['benefits_7'];
    }

    const dialogRef = this.dialog.open(InstituteIicAkamDialogComponent, {data: data});
    dialogRef.afterClosed().subscribe((result) => {


    });
  }


}
