import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteActivityCountDialogComponent } from './institute-activity-count-dialog/institute-activity-count-dialog.component';


@Component({
  selector: 'app-institute-activity',
  templateUrl: './institute-activity.component.html',
  styleUrls: ['./institute-activity.component.css']
})
export class InstituteActivityComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    //this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InstituteActivityCountDialogComponent);

    dialogRef.afterClosed().subscribe(result => { console.log(result); });
  }

}
