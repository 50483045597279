import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InstituteIdeaSubmissionComponent } from '../institute-idea-submission.component';

@Component({
  selector: 'app-institute-idea-submission-dialog',
  templateUrl: './institute-idea-submission-dialog.component.html',
  styleUrls: ['./institute-idea-submission-dialog.component.css']
})
export class InstituteIdeaSubmissionDialogComponent implements OnInit {
  public ideaForm: FormGroup;
  pocScore: any;
  commentFlag: boolean = false;
  ideaScoreFlag: boolean = false;

  constructor(private fb: FormBuilder,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteIdeaSubmissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.ideaForm = this.fb.group({
      status: [null],
      score: [null, Validators.compose([Validators.max(50), Validators.min(2)])],
      comment: [null],
    });

    if (this.data['team_lead_score'] != '0' && this.data['team_lead_score'] != '') {
      this.ideaScoreFlag = true;
    }


  }

  onSubmit(values) {
    values['id'] = this.data['id'];
    values['type'] = 'Idea'
    this.innovationService.submitIdeaSubmission(values).subscribe(
      data => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 46) return true;

    return false;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
