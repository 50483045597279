<!--title-->
<title> Institution&#8217;s Innovation Council | MHRD </title>
<!--end of title--->
<!-- Header -->
<app-inner-navbar></app-inner-navbar>
<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
  <ul class="aht-breadcrumbs__list">
    <li class="aht-breadcrumbs__item"> <a class="aht-breadcrumbs__link" href="index.html">Home</a> </li>
    <li class="aht-breadcrumbs__item"> <a class="aht-breadcrumbs__link" href="#">Innovation Council</a> </li>
    <li class="aht-breadcrumbs__item"> Institution’s Innovation Council </li>
  </ul>
</div>
<section class="paddsection">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="titleheadertop">
          <h1 class="inner-theme-heading">Institution’s Innovation Council </h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <h2>Introduction</h2>
        <div class="imagestext">
          <p> Ministry of Human Resource Development (MHRD), Govt. of India has established ‘MHRD’s Innovation Cell
            (MIC)’ to systematically foster the culture of Innovation amongst all Higher Education Institutions (HEIs).
            The primary mandate of MIC is to encourage, inspire and nurture young students by supporting them to work
            with new ideas and transform them into prototypes while they are informative years.</p>
          <p> MIC has envisioned encouraging creation of ‘Institution’s Innovation Council (IICs)’ across selected HEIs.
            A network of these IICs will be established to promote innovation in the Institution through multitudinous
            modes leading to an innovation promotion eco-system in the campuses.</p>
          <br>
          <h2>Major focus of IIC</h2>
          <br>
          <p> To create a vibrant local innovation ecosystem. Start-up supporting Mechanism in HEIs. Prepare institute
            for Atal Ranking of Institutions on Innovation Achievements Framework. Establish Function Ecosystem for
            Scouting Ideas and Pre-incubation of Ideas. Develop better Cognitive Ability for Technology Students.</p>
          <br>
          <h2>Functions of IICs</h2>
          <br>
          <ul>
            <li>To conduct various innovation and entrepreneurship-related activities prescribed by Central MIC in time
              bound fashion.</li>
            <li>Identify and reward innovations and share success stories.</li>
            <li>Organize periodic workshops/ seminars/ interactions with entrepreneurs, investors, professionals and
              create a mentor pool for student innovators.</li>
            <li>Network with peers and national entrepreneurship development organizations.</li>
            <li>Create an Institution’s Innovation portal to highlight innovative projects carried out by institution’s
              faculty and students.</li>
            <li>Organize Hackathons, idea competition, mini-challenges etc. with the involvement of industries.</li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-12" style="padding:0px;">
            <!-- code for graph -->
            <div class="border border-1 marbox">
              <h3 style="color:#ff5502;">IIC IMPACT</h3>
              <div class="col-md-12" style="padding:0px;">
                <div class="col-md-6"
                  style="color:#fff !important;float:left;background-color: #1CC09F !important;text-align: center;border-radius: 10px;padding:10px;">
                  <span><i class="fa fa-graduation-cap"
                      aria-hidden="true"></i>&nbsp;&nbsp;STUDENTS:<i>5,461,938</i></span> </div>
                <div class="col-md-5"
                  style="color:#fff !important;float:right;background-color: #FF5733 !important;text-align: center;border-radius: 10px;padding:10px;">
                  <span><i class="fa fa-university" aria-hidden="true"></i>&nbsp;&nbsp;FACULTY:<i>798,912</i></span>
                </div>
              </div>
              <div class="modal fade" id="suggestionModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                data-backdrop="static" aria-hidden="true">
                <div class="modal-dialog acceptmod" role="document">
                  <div class="modal-content">
                    <div class="modal-header" id="header">Events Details</div>
                    <div class="modal-body">
                      <div class="row dash-drop">
                        <div class="col-md-12" style="margin-bottom: 20px;margin-top: 20px;">
                          <table border=1>
                            <tr>
                              <th rowspan="1" width="20%" colspan="1">Event Id</th>
                              <th rowspan="1" width="80%" colspan="1">Event Title</th>
                            </tr>
                            <tr>
                              <td>EVE6</td>
                              <td> Episode 03 of India First Leadership Talk with Dr. Ajit Doval, NSA, Govt. of india
                              </td>
                            </tr>
                            <tr>
                              <td>EVE9</td>
                              <td> Episode 04 of India First Leadership Talk with with Prof. Anil D. Sahasrabudhe,
                                Chairman, AICTE</td>
                            </tr>
                            <tr>
                              <td>EVE10</td>
                              <td>Workshop on Cognitive Skills, Design Thinking and Critical Thinking using local
                                resource persons</td>
                            </tr>
                            <tr>
                              <td>EVE5</td>
                              <td>Episode 02 of India First Leadership Talk with Dr. Anand Deshpande, CMD, Persistent
                                Systems Ltd.</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bgcolorgrdiyant" style="padding:0px;">
                <ul class="chart">
                  <div id="chart_div1" class="graph" style="height: 400px;"></div>
                </ul>
              </div>
            </div>
            <!-- code for graph upto here -->
          </div>
          <div class="col-sm-12 mt-2 purple overview1 imagestext ">
            <h3>ANNOUNCEMENTS</h3>
            <ul class="list-seven list-unstyled ullisstmar mahi " style="height: 194px;overflow-y: auto; ">
              <li>

                <p title="Image Announcement Two "><i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; "
                    href="images/announcements/4d6dadb256c60a484ca11dbcfdda9c75.jpg " target="_blank ">
                    Image Announcement Two </a>
                </p>

              </li>
              <li>

                <p title="Image Announcement One "><i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; "
                    href="images/announcements/328d88b62abff818f8455879191bcebd.jpg " target="_blank ">
                    Image Announcement One </a>
                </p>

              </li>
              <li>

                <p
                  title="List of Student Innovations/Startup Proposals Selected for Receiving Funding Support and Incubation Linkage for the FY 2020-21 at IIC-MIC & AICTE ">
                  <i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; " href="0.html " target="_blank ">
                    List of Student Innovations/Startup.. </a>
                </p>

              </li>
              <li>

                <p
                  title="List of Student Innovations/Startup Proposals Selected for Receiving Funding Support and Incubation Linkage for the FY 2020-21 at IIC-MIC & AICTE ">
                  <i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; " href="0.html " target="_blank ">
                    List of Student Innovations/Startup.. </a>
                </p>

              </li>
              <li>

                <p title="Participate in IIC National Innovation Contest 2020 "><i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; "
                    href="images/announcements/6cf7fad115da285df72ab9a301feafd0.jpg " target="_blank ">
                    Participate in IIC National Innovat.. </a>
                </p>

              </li>
              <li>

                <p
                  title="For the impact lecture series, IIC-Institutes may organize these sessions till 30th April 2020 and can upload report, either in Q3 or Q4 on the IIC portal for calculation of score and star. ">
                  <i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; " href="0.html " target="_blank ">
                    For the impact lecture series, IIC-.. </a>
                </p>

              </li>
              <li>

                <p
                  title="Last date to conduct Q3 activities is extended till 15th April 2020 and the last date for submission of report for Q3 is 30th April 2020.">
                  <i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff;" href="0.html" target="_blank ">
                    Last date to conduct Q3 activities .. </a>
                </p>

              </li>
              <li>

                <p
                  title="Recommended List of IIC-Institutes for the funding support to organise two Impact Lecture Sessions 2019-20 ">
                  <i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; "
                    href="mic/wp-content/uploads/2020/02/Recommendation-of-Impact-Lecture-Series.html "
                    target="_blank ">
                    Recommended List of IIC-Institutes .. </a>
                </p>

              </li>
              <li>

                <p title="Provisional result for International startup exposure visits to South Korea and Canada ">
                  <i class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; " href="# " target="_blank ">
                    Provisional result for Internationa.. </a>
                </p>

              </li>
              <li>

                <p title="List of top Innovative PoCs from National Bootcamp and Exhibition 2019 "><i
                    class="fas fa-calendar-alt "></i>
                  <a style="text-decoration: underline;color:#fff; " href="assets/zone/selected_poc_list_2018-19.pdf "
                    target="_blank ">
                    List of top Innovative PoCs from Na.. </a>
                </p>

              </li>
              <li>

                <p title="Star Ratings of Institution 's Innovation Councils for the IIC Calendar Year 2018-19"><i
                    class="fas fa-calendar-alt"></i>
                  <a style="text-decoration: underline;color:#fff;" href="mic/star-rating-2018-19.html" target="_blank">
                    Star Ratings of Institution's Innov.. </a>
                </p>
              </li>
              <li>
                <p title="List of top three IICs in each zone"><i class="fas fa-calendar-alt"></i> <a
                    style="text-decoration: underline;color:#fff;" href="assets/doc/topIICSineachzone.pdf"
                    target="_blank">
                    List of top three IICs in each zone </a> </p>
              </li>
              <li>
                <p title="
List of shortlisted PoCs for National Exhibition in AICTE Headquarter, New Delhi on September 11, 2019."><i
                    class="fas fa-calendar-alt"></i> <a style="text-decoration: underline;color:#fff;"
                    href="assets/doc/List%20of%20Shortlisted%20PoCs%20for%20National%20Exhibition.pdf" target="_blank">

                    List of shortlisted PoCs for Nati.. </a> </p>
              </li>
              <li>
                <p title="Voting Appeal Result is declared.  Congratulations!"><i class="fas fa-calendar-alt"></i> <a
                    style="text-decoration: underline;color:#fff;" href="assets/doc/Voting%20Appeal%20Result.pdf"
                    target="_blank">
                    Voting Appeal Result is declared. .. </a> </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 25px"></div>
    <div class="row">
      <div class="col-sm-6">
        <div class="boxbgcolor">
          <h3 class="mb-0">ACTIVITIES OF IIC</h3>
          <div class="overview-slider mahi" style="height: 427px;overflow-y: auto;">
            <div>
              <ul class="list-six list-unstyled bg-white">
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Role of Network Enablers in driving I&am..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Role of Network Enablers in driving I&am..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Use of Market Data and Application of Ma..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Use of Market Data and Application of Ma..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Frugal Innovation and Social Entrepreneu..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Frugal Innovation and Social Entrepreneu..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Innovating Self- Screen and Identify rig..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Innovating Self- Screen and Identify rig..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Hangout with Successful Startup Founder ..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Hangout with Successful Startup Founder ..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Understanding Role and Application of Ma..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Understanding Role and Application of Ma..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Entrepreneurship, Business Idea and Busi..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Entrepreneurship, Business Idea and Busi..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Innovation Risk Diagnostic: Product Inno..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Innovation Risk Diagnostic: Product Inno..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Interaction with Student Innovators and ..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Interaction with Student Innovators and ..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="IIC National Innovation Contest-Prototyp..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    IIC National Innovation Contest-Prototyp..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Progress Monitoring and Upload of 4th Qu..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Progress Monitoring and Upload of 4th Qu..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="How to Identify Right Problem and Soluti..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    How to Identify Right Problem and Soluti..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="National Innovation and Startup Policy f..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    National Innovation and Startup Policy f..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Session on Accelerator/Incubation - Oppo..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Session on Accelerator/Incubation - Oppo..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span
                    title="Understanding Angel and Venture Capital ..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Understanding Angel and Venture Capital ..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter IV</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III,Quarter IV</h4><span
                    title="IIC National Innovation Contest-PoC Subm..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    IIC National Innovation Contest-PoC Subm..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III,Quarter IV</h4><span
                    title="Impact Lecture Series-Lecture I">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Impact Lecture Series-Lecture I
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III,Quarter IV</h4><span
                    title="Impact Lecture Series-Lecture 2">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Impact Lecture Series-Lecture 2
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III,Quarter IV</h4><span title="Science Day">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Science Day
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III,Quarter IV</h4><span
                    title="IIC National Innovation Contest-Idea Sub..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    IIC National Innovation Contest-Idea Sub..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="Idea Submission for the Launch of 5G hac..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Idea Submission for the Launch of 5G hac..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="*Progress Monitoring and Upload of 3rd Q..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    *Progress Monitoring and Upload of 3rd Q..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="Field/Exposure Visit to Incubation Unit/..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Field/Exposure Visit to Incubation Unit/..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="Organise Workshop on Business Model Canv..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Organise Workshop on Business Model Canv..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="Organise One day Session on “How to pl..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Organise One day Session on “How to pl..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="Organise One/half day Interactive/online..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Organise One/half day Interactive/online..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="Organise One Day Awareness/Mentoring Ses..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Organise One Day Awareness/Mentoring Ses..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="Demo Day – Exhibition Cum Mentorship S..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Demo Day – Exhibition Cum Mentorship S..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span
                    title="MHRD Mega Online Challenge Samadhan">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    MHRD Mega Online Challenge Samadhan
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter III</h4><span title="Leadership Talk">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Leadership Talk
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II,Quarter III</h4><span
                    title="Idea Submission in Smart India Hackathon..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Idea Submission in Smart India Hackathon..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II</h4><span
                    title="Organise a workshop/Seminar/Call for Pap..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Organise a workshop/Seminar/Call for Pap..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II</h4><span
                    title="Demo Day – Exhibition Cum Demo for PoC..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Demo Day – Exhibition Cum Demo for PoC..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II</h4><span
                    title="Engage Students for Internship at Innova..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Engage Students for Internship at Innova..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II</h4><span
                    title="Progress Monitoring and Upload of 2nd Qu..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Progress Monitoring and Upload of 2nd Qu..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II</h4><span
                    title="Product Development Phases - Story Telli..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Product Development Phases - Story Telli..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II</h4><span
                    title="Field/Exposure Visit to Design Centre/Ma..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Field/Exposure Visit to Design Centre/Ma..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II</h4><span
                    title="Organise a Product Design/ Design Thinki..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Organise a Product Design/ Design Thinki..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter II</h4><span
                    title="Workshop on National Innovation and Star..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Workshop on National Innovation and Star..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I,Quarter II</h4><span
                    title="Innovation  Day Campaign ">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Innovation Day Campaign
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span
                    title="India First Leadership Talk Series">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    India First Leadership Talk Series
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span title="InnovateToINSPIRE 2019 by EESL">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    InnovateToINSPIRE 2019 by EESL
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span
                    title="My Story - Innovator’s Life &amp; Cros..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    My Story - Innovator’s Life &amp; Cros..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span
                    title="Formulate Council, Upload of 1st Quarter..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Formulate Council, Upload of 1st Quarter..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span
                    title="My Story - Entrepreneur’s Life &amp; C..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    My Story - Entrepreneur’s Life &amp; C..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span
                    title="Field/Exposure Visit to Village/Society ..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Field/Exposure Visit to Village/Society ..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span
                    title="Organise One Day Workshop on Problem Sol..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Organise One Day Workshop on Problem Sol..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span
                    title="Organise One Day Workshop on “Entrepre..">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    Organise One Day Workshop on “Entrepre..
                    <!--</a>-->
                  </span>
                </li>
                <li>
                  <h4 style="color:#62379a;font-size: 18px;">Quarter I</h4><span title="NEAT Logo Contest">
                    <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->
                    NEAT Logo Contest
                    <!--</a>-->
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6  mt-2 events-mic borderbox3">
        <h3>ACTIVITIES @ INSTITUTES</h3>
        <ul class="list-five list-unstyled clearfix desc mahi" style="height: 441px;overflow-y: auto;">
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pep Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inspirational Story</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID19 pandemic safety measures and
                practices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Live Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E - Lecture </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Concrete Cracks & How to Prevent
                Them?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Demo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E - learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Live Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Students Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Live Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Success Story </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Skill development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CEO Talk\&#39;s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunity Tapping</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pep Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Live Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E - Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Avenue exploring talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Avenue exploring talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e -Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet The Maestro with Salman Khurshid</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/58f33f09c84570a2e08a07021caa9f75.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNICAL TEACHERS&#39; TRAINING & RESEARCH, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->employment scenario for job seekers and
                entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exclusive Virtual Power Seminar on
                Employability Skills for the Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to RFIC Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on CODING AT WORK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40d13e359a0d710cfb11750a69a015d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B V V SANGHAS BASAVESHWAR ENGINEERING COLLEGE BAGALKOT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre-Incubation for Budding Entrepreneurs and
                Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->smart india hackathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE ONE WEEK FACULTY DEVELOPMENT RESEARCH
                OPPORTUNITIES IN POWER ENGINEERING (ROPE – 2020)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on How to be an Efficient
                Architecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Webinar Session on Revit
                Architecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Workshop on Java programming concepts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fasinating Phase Singularities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Book Launch-Methodological Issues in Social
                Entreptreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Effective use of Automation Tool in customer
                service industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stock Exchange - An Digital Investment
                Journey</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The importance of supply Chain Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exploring the opportunities to expanding the
                business through online portals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Analysis for Trading</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recruiting & Labour Markets for promoting the
                business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How non-profit can use crowd funding to raise
                more money during covid-19 crisis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Role of Challenge in Innovating noval
                product </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation Culture - A need for start-up
                frostering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mental health- Online Session by Ms. Moumita
                Nandy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Corporate Disclosure and Governance for a
                Better Business understanding</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Application of Bioinformatics in Business
                Data Analysis - NGS Platform</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tap your business potential </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sahaay: Ek aur prayas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->On Demand Technology in IT Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Volleyball Tournament in TISS campus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Start-ups avenues and opportunities in
                abroad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Management Puzzle</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Corporate Culture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to achieve Goals in Food Production
                Department</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Training - Verbal
                Communication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Commercialisation of Indian Products Globally
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Scope of Innovative Software used in Fashion
                Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fashion Designer - An unexplored business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Analytics - an effective tool in
                Business development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Successful Career in Global Accounting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business opportunities in Big Data Testing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Art of Writing Project proposal for Angel
                fund generation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Professional Resume Preparation - A personal
                projection</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Export opportunities in Coir pith bricks and
                its international market demand</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur as Career Choice - Pilot Scale
                to Nation wide product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Redefine Yourself as a First generation
                entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awaken the Giant within - Self Motivation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Development of Interpersonal Skills for
                better Business communication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ethnography and Ethics in Social Media
                Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Software Testing Lifecycle Automation Testing
                - A business approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creating an Effective Business Environment in
                IT start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Ready Talk on Expectations of a
                International HR Manager</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech to Combat COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ecosystem for Social Enterprise in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Entrepreneurs in Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Enterprise for Value Creation: Values
                and Possibilities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Discussion on Social Enterprise in Neo
                Liberal Economy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Session-Jaipur Rugs-</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture- Social Values and Processes
                in Himalayan Region</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online webinar on \&#34; Probable impacts of
                pandemic crisis on small and medium business in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online webinar on \&#34; scope of industrial
                automation and digital marketing to wards startups\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f6e4d0f96f5eb01d44c44e9f5d658e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. Shyama Prasad Mukherjee International Institute of Information Technology, Naya Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DST NIMAT Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start step to Start up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON INTELLECTUAL PROPERTY RIGHTS (IPR)
                AND PATENT PRACTICES IN INDIA </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Faculty training program on Renewable
                energy resources </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning Hands on using Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Opportunities in the New Normal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online webinar on \&#34; Automated guided
                vehicle \&#34; ( towards students startups) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FACULTY FIELD VISIT TO RENAULT NISSAN PVT LTD
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sanitize World to Abolish Carona to be
                Hygiene-Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT to TVS Training and Services
                Limited, Ambathur to TVS Training and Services Limited, Ambathur to TVS Training and Services Limited,
                Ambathur to TVS Training and Services Limited, Ambathur to TVS Training and Services Limited, Ambathur
                to TVS Training and Services Limited, Ambathur to TVS Training and Services Limited, Ambathur </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World environment day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenges and Importance of E-waste
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT ON DIAMOND GROUP PVT LTD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT ON DIAMOND GROUP PVT LTD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drafting of a Patent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online webinar on \&#34;3D Printing -
                Applications in Fighting with Covid 19\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Classification of IPR\&#39;s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE Webinar on \&#34;UM4EM (Use of Mathcad
                4 Engineering Mathematics Subject)\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar Series on Design and Development of
                Cube sat</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION AND BUSINESS PLAN COMPETITION FOR
                YOUNG ENTREPRENEURS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Drone Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online webinar on \&#34; E mobility vehicles
                \&#34; - startup motivation for students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE WEBINAR ON OPPORTUNITIES AT SAE EVENTS
                FOR STUDENTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE WEBINAR ON BIO DIVERSITY : ITS TIME
                FOR NATURE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3-Day Workshop on Arduino Programming: Theory
                and Practice</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative product / project Expo </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Advances in
                Electronics Signal Processing and Communication(AESPCOM20)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE WEBINAR ON HOW TO GET PUBLISHED IN A
                SCIENTIFIC JOURNAL FROM RESEARCH TO PUBLICATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National conference on Advanced Research and
                Innovations in Information and Communication Engineering (ARICE 2020) on 06.03.2020. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87f19be1de0f3916dc8abe49084d7bdf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ERODE SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities and Challenges for Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87f19be1de0f3916dc8abe49084d7bdf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ERODE SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities and Challenges for Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5G Technology Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Business Communication skills
                development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE WEBINAR ON MECHANICAL ENGINEERING
                RECENT TRENDS & OPPORTUNITIES.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87f19be1de0f3916dc8abe49084d7bdf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ERODE SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “ENTERPRISE DATA LAKE </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to redefine business strategies in crises
                time- AIC-RNTU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE WEBINAR ON APPLICATIONS OF ARTIFICIAL
                INTELLIGENCE IN MANUFACTURING INDUSTRY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE IEI-WEBINAR ON ROBOT AUTOMATION AND
                INDUSTRIAL 4.O</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology Mentoring session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Design Thinking and
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Days workshop on proteus and aurdino
                handss on training for successful students startups </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Oneday Seminar on IOT And Machine Learning
                for Technical startups </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative product display cum EXPO
                participation at Anna University </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interrogation in MEMS, NEMS and Nano
                Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation visit for students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Recent Innovation in
                Civil Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to plan for Startup legal and ethical
                Steps </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE FDP PROGRAM ON MATERIALS FOR THERMAL
                AND RENEWABLE ENERGY RESEARCH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GPS TOTAL STATION - CONCEPTS AND APPLICATIONS
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Story telling workshop on \&#34; Innovative
                product ideas into development Phase\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Career Guidance for Engineering
                and Medicine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Webinar on The Civil Engineering Future
                opportunities ……</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON GOOD PRACTICES IN CONSTRUCTION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->1 week FDP on LaTex, R Programming and Python
                - Association with IIT Bombay</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON WOOD CONSTRUCTION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE ISTE - STTP ON INDUSTRIAL ROBOTICS AND
                MACHINE VISION TECHNOLOGY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Supporting Entrepreneurs Post Covid-19-JKEDI
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Redefining Strategy Post Covid with special
                reference to Higher Education in Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Preparing to work for business after crises
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to cultivate in your business-SEE
                organised</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session on Teaching Entrepreneurship
                Online: Challenges and Way Forward</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Social Entrepreneurship-EDFLY
                Learn</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session on Rewriting Enterprise
                Strategy Post Covid-19 for incubatees</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Waste Management </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c86061dcc537b41fca6f76a728728ae9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THASSIM BEEVI ABDUL KADER COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Quiz on Women EntrepreneurS – A Bright
                future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Empressario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->6 week Entreprenuership and Skill Development
                program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d63a3909063bc7145f18bf8cbca57ea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SATHYABAMA INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, creativity and entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fe7aecf90564fdbd71317f69cfa7fa98.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.L.E Societys J G College of Commerce">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Masterclass - A Knowledge Series on Start-Up
                & Legal and Ethical Steps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d63a3909063bc7145f18bf8cbca57ea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SATHYABAMA INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start, Run and Sustain of MSMEs in COVID 19
                SCENARIO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d63a3909063bc7145f18bf8cbca57ea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SATHYABAMA INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IMPORTANCE OF INTERNSHIPS DURING ENGINEERING
                EDUCATION </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30792f2ede95c30ac27bb6914d1fc945.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinars</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30792f2ede95c30ac27bb6914d1fc945.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinars</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aptitude Session on Time and Work</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on steps for Civil Engineer to Become
                an Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations in Artificial Intelligence & Deep
                Leaning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration social entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ground Zero Preparations for Interviews</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Post COVID 19: Challenges and
                Change Management in Engineering Acadmic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT interventions in Civil Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fe7aecf90564fdbd71317f69cfa7fa98.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.L.E Societys J G College of Commerce">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AVAKAASH - An elevating platform for Women
                Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Engineers - Unsung Heroes fighting
                COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Barclays Employability on line training
                Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1e01edb16f564a352e6accb414ee4362.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Expert Talk on “SAP ERP and Emerging
                Industry Trends” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture relay on World Environmental Day 2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar and Ideation on 5G</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Saksham</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6b94a46b5eb1b8e5d6e28b9f9ef712c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise Workshop on Business Model Canvas
                (BMC) and (or) Business Plan Competition to Invite Innovative Business Models from Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideas to Hack or Prevent Corona</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->iCMAP - Hackathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Samadhan </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fight Corona Ideathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Game Plan </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sales Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on EMOTIONAL
                INTELLIGENCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->healthcare present and future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->expert lecture on Industry readiness and
                trends in Hiring</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Half day Interactive Session with a
                successful Entrepreneurs in Campus - Dr. Celso Fernandes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdded087b6aa9a5752a63ad67ac66e7e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING MANAV RACHNA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk with Prof. D. P. Singh
                ,Chairman UGC on \&#34;Examination and Academics in Current Covid-19 Crisis\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->First Leadership talk series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid-19 Awareness Online Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on New Horizons in the Application
                of Sensors during Pandemic Situation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Tour</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on \&#34;mathematical modeling\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c86061dcc537b41fca6f76a728728ae9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THASSIM BEEVI ABDUL KADER COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Startup Ecosystems in Higher
                Education Institutions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech solution submission to Dept of Science
                and Technology for COVID 19 Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on \&#34;Evolving from graduates to
                professionals\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Communication skills at work place to get
                success</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Advances in ARTIFICIAL
                INTELLIGENCE,DATA SCIENCE and CLOUD COMPUTING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Logo design and drawing contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Submission of ideas in COVID 19 Innovation
                Team of Acropolis Group</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Talk Series 2020- Career Prospects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital learning series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Opportunities in Indian
                Stock Market</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sustainable Impact through Technology by Mr.
                Rajesh Balpande, Founder BokkMyShow</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE QUIZ FOR THE AWARENESS OF THE COVID
                -19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FOUR DAY WEB SERIES ON ONLINE SKILL
                DEVELOPMENT COURSE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Life Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Empowering yourself for a better career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in Program Management Banking
                Sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c86061dcc537b41fca6f76a728728ae9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THASSIM BEEVI ABDUL KADER COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Poster on Consumer Protection and Consumer
                Advocacy during the Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vision 2030- The India Quiz (Sky Education in
                association with E-Cell TISS Mumbai)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Level Personality Profiling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/891e643e5ce454973d262d18de385111.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND INSTITUTE OF HIGHER TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Digital Marketing for
                Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day live webinar on block chain
                governance redefining digital India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fe2984bb29a0c0e0ee333f8cee6df51.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI GURU GOBIND SINGHJI INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Planning of Motivational speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Let\&#39;s Beat Corona</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Level - Current Trends in IT
                sector </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SocioConnect (BVIC in association with TISS)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level - Role of NABARD in
                Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day National Level on innovation,
                incubation and MSME Role </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Leading in a VUCA World</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creativity and Innovation- By Puneet
                Bhatnagar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MPCST 35th Young Scientist Congress</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level - Role of MSME for
                Entrepreneurship and Incubation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National webinar - Easy Mask Making – Prevent
                COVID’19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAN PROTOCOL FOR AUTOMOTIVE APPLICATIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> World Environmental Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness about Patent filing process</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/891e643e5ce454973d262d18de385111.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND INSTITUTE OF HIGHER TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IdeaFest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agricultural Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4728d2bc5cd04b09ed38fd9bf82357a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Engineering Science and Technology- Shibpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid 19 Challenge Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Redefining Teaching and learning in COVID era
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CHALLENGES OF ENTREPRENEURSHIP POST COVID’19
                LOCKDOWN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fe2984bb29a0c0e0ee333f8cee6df51.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI GURU GOBIND SINGHJI INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“The Journey of an Entrepreneur – by Sumit
                VIjapure”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Talk Series 2020-Mind Power</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Talk Series 2020- Career Prospects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging technologies-Blockchain and its use
                cases in a pandemic like covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Science Applications for fresh
                Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WeConclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impactathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual internship orientation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7ea9910e07d360073a370d05684180c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ASSAM DOWN TOWN UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness programme on industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Movie Screening- Manthan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->State level IPR workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Extension of help to fight covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->one day worshop on Remote sensing and Machine
                learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Innovation and Incubation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Discussion cum Meeting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BUSINESS E-QUIZ 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership talk on Atma Nirbhar Bharat (Self
                Reliant India) : The Road Ahead</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two day workshop on Machine learning in
                Medical applications </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Upskill Your Life by Mr. Danish Aga,
                Co-founder, Sky Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A one week national level online fdp on
                \&#34;OVERVIEW OF AI & ITS APPLICATIONS IN SPEECH PROCESSING”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition of Products of Student
                Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The cultural Legacy of C’S in
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact of COVID’19 on information technology
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cdfb5d61ba129de76030ddcd34ac483.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahendra Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital transformation for
                effective career development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Innovation during Covid
                19 situation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Creation for Social Enterprises
                By Mr. Anuj Sharma (Founder, ALSISAR Impact Pvt Ltd)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creative Poster Making Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prior Art Search and Patent Filing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visionnare</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fe7aecf90564fdbd71317f69cfa7fa98.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.L.E Societys J G College of Commerce">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on SWAYAM : E - Learning - an
                initiative by Govt. of India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cdfb5d61ba129de76030ddcd34ac483.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahendra Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E Quiz programme on python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Strategic Self Management and PRIM by Dr.
                Kuiljeit Uppaal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python data Structures Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fe7aecf90564fdbd71317f69cfa7fa98.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.L.E Societys J G College of Commerce">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Ideation Contest 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day National Level Awareness Program On
                Schemes Of MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motion Planning in Robots</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Laws in Cyberspace
                and Intellectual Property</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fe7aecf90564fdbd71317f69cfa7fa98.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.L.E Societys J G College of Commerce">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Four-Day - Online Faculty Development Program
                on Online Learning Management System & Designing E - Content</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to plan for Incubation & Start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Crash course on Innovation and
                Entrepreneurship (Module-3)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Entrepreneur Development in Early
                Rise</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Impact with Technology </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Physical Metallurgy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intercollegiate BUSINESS PLAN PRESENTATION
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GSoC Talk </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Session on Importance of IPR in
                Startup Ecosystem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/480f29987005cb8752ac75f7ac2b4bab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola-ICAM College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre Council Meeting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Let’s Explore the Hogwarts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Software Freedom Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BUSINESS MODELS In the Information Age</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f759188888ac466518c7d4a7986ea25b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Lovely Professional University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Women entreprenureship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e37c01ee2b3adef18851a08e475767f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->StartUp Panel Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERDEPARTMENTAL BUSINESS PLAN PRESENTATION
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advances In Mechanical Sciences-AIMS 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Prepare a Business Plan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->InternationalConference on Innovations in
                Technology, Management and Design for Achieving Sustainable Development Goals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz-IPR Online Quiz in commemoration
                of World IPR Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creativity and Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e37c01ee2b3adef18851a08e475767f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pune Startup Fest 2020 - COEP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Journey Towards Entrepreneurship And
                Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on PATENT FILING AND PROSECUTION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One week FDP on Patent Filing Procedure</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to plan for Start-up, Legal and Ethical
                Steps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Water and Energy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solar System and Battery Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->entrepreneurship - Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a74b9113f8930d6d52d13faa009c230.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KINGS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KINGS SCIENCE EXPO 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart Intelligent systems Through IOT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Trends in Software Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/867c70a7d4752ba5b16236eee235d89e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAMAL MOHAMED COLLEGE (AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness lecture </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c86061dcc537b41fca6f76a728728ae9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THASSIM BEEVI ABDUL KADER COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP AND INNOVATION: VECTORSFOR
                SUCCESSFUL – CREATIVE CLUSTERS AND STRATEGIC FORMS OF COLLABORATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->InnoBiz - Innovative Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/480f29987005cb8752ac75f7ac2b4bab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola-ICAM College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on START-UP 101</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MAKING OF HAND FREE SANITIZER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->electric vehicles what\&#39;s if and
                but\&#39;s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a74b9113f8930d6d52d13faa009c230.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KINGS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Research Trends in Multirate
                signal processing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAE Keynote Talks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c5a003239446424d554cf8202d008753.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SOLAMALAI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live Webinar on Augmented and Virtual Reality
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdadbca85a616af4431daabdb01acfb0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAREER POINT UNIVERSITY, HAMIRPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ERP & JOURNEY OF SAP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RecHERsion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future Trends in Automobile</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Corporate Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a74b9113f8930d6d52d13faa009c230.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KINGS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Accelerate your Internet of Things
                vision</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Intellectual Property Day 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/352b3648bcf59b6790baece423428423.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BLDE (Deemed to be University)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RGUHS COVID19 Innovation Challange:
                Applications Invited From Faculty, Students
                https://medicaldialogues.in/news/education/medical-universities/rguhs-covid19-innovation-challange-applications-invited-from-faculty-students
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“ Hangout with successful start-ups” by Mr.
                Nitin Mishra </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Artificial Intelligence Machine Learningin
                Real world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture Series on Women Entrepreneurship
                Lecture 3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Publicis Sapient Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1e01edb16f564a352e6accb414ee4362.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Online Workshop on \&#34;Solid
                Modeling and Visualization using AutoCAD\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DONATING FACE SHIELD TO ANEKAL MUNCIPAL
                COUNCIL AND POLICE STATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture Series on Women Entrepreneurship
                Lecture 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f759188888ac466518c7d4a7986ea25b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Lovely Professional University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Ambassador Training Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1e01edb16f564a352e6accb414ee4362.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Webinar on “CLOUD SECURITY” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/efc38647d7cac55fe72350e3ae08b3cb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="All India Shri Shivaji Memorial Society College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 Start up Competition: Atmanirbharata
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EMPLOYMENT TO DEPLOYMENT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DESIGN THINKING AND FILING PROCEDURE FOR
                INDUSTRIAL DESIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World intellectual property week-quiz </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SKILLING UP FOR A COVETED CAREER IN BUSINESS
                ADMINISTRATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/867c70a7d4752ba5b16236eee235d89e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAMAL MOHAMED COLLEGE (AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Scientific awareness quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HOW RELAVANT IS AN MBA DEGREE FOR ENGINEERS
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Distinguished lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Patent Filing and Prosecution</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Developed an online Video on \&#34;Design
                Thinking - An Overview\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National webinar on IPR and Patenting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IRIS Internship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Preach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON CAREER GUIDANCE PROGRAMME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture Series on Women Entrepreneurship
                Lecture 1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intro to FPGA and Verilog</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> SUCCESS AS AN ENTREPRENEUR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Get Set Sell</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in teaching and learning </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on “Advance in Science and
                Technology: What matters to India Today?”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Computer Vision</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ARIVU-CET AWARENESS PROGRAM WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit to Nehru Science Center for
                the programme \&#34;How Indians Won the Silicon Valley\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a37ae4a7173880aad0f05a632d0fd852.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Making an Effective Research
                Proposal-The Winning Way</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Imagineer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/541ba0b1c9f6b903403f956c41917806.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJKIYA ENGINEERING COLLEGE, BANDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Use of ICT In Good Governance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SUCCESS AS AN ENTREPRENEUR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property Rights
                (IPR)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEO- Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NSS Activity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Senior talks-small talks! Big lessons</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hult Prize E-Cell </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Image Processing and Geographic
                Information System for Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative approach to create awareness on
                COVID 19 : Online Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9e5e7761301eaa9b9f9fcee2d7e5f26b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOUNT ZION COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur motivational speech.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Business Plan Competition 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One last time series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Cyber Security in Innovating</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Geo-ICT application for smart cities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent filing and publication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Developing using Raspberry PI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f40889ef9e71bd4dbf9416821f123df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Teachers are Leaders (TRL)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4ec2792da49436ca226f12f307152f6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Padmabhooshan Vasantraodada Patil Institute  of   Technology, BUDHAGAON">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation through Challenge 2 Solution (C2S)
                2K20 - Tuesday, March 3,</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How To Pitch Your Idea</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How To Prepare Business Model</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4ec2792da49436ca226f12f307152f6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Padmabhooshan Vasantraodada Patil Institute  of   Technology, BUDHAGAON">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Presentation on Innovative Projects -
                Wednessday, March 4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent filling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4ec2792da49436ca226f12f307152f6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Padmabhooshan Vasantraodada Patil Institute  of   Technology, BUDHAGAON">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Proposal Pitch on (IoT, AI, ML) in
                Automotive Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Make the Best Business Plan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a37ae4a7173880aad0f05a632d0fd852.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9e5e7761301eaa9b9f9fcee2d7e5f26b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOUNT ZION COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Advanced Trend in Mechanical
                Design Softwares</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Find a right mentor to make your startup
                successful</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4ec2792da49436ca226f12f307152f6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Padmabhooshan Vasantraodada Patil Institute  of   Technology, BUDHAGAON">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs Motivational Talk with students
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8c915d6aeb3438366aad094659df09ca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Ramrao Adik Institute of Technology ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Towards Innovative Product
                Development through Arduino Boards & I/O Devices Interfacing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Validating Market & Customers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Codethon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation and Business Strategies for Future
                Work Force</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Innovation Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT for Automotives</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Budget talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exploring the World of Arduino </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Laplace Transforms and Its
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cognitive and Behavioral Training for #Higher
                Education in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Civil Services Exam Preparation
                Strategy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->tips on internship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Load Runner Tool used in Software
                Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f46a6926ab2ebaa6b0723a8142eb873f.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL COVID-19 AWARENESS QUIZ
                PROGRAM FOR STUDENTS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Team Buliding</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47a50a95d9b1df73a8429840ff802269.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhartiya Skill Development University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Webinar on Skill Development in
                Manufacturing: A Case Study</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Aeromodelling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Discussion With Mentor</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar - Post Covid opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk(online) on The emerging trends of
                software industry </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Universal Human Values</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to Salesforce CRM and
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation and Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology Transfer for Societal Benefit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Light weight Motor cycle model </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internet of Things(NodeMCU)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Research Revealed</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Webinar On VLSI Design Tends</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MECHASSO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on HCM Integration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition on Waste Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Automation with python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON: VIRTUALIZATION IN CLOUD COMPUTING
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start-ups Meet on Minimizing Electricity
                Consumption for Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da300a7c2bab83971b6216c67c4f2e0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RajaRajeswari College of Engineering, Bangalore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Novel Ideas to Prevent Novel
                Virus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CEO Share on Food Waste Management - A
                Promising Adjusting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rotaract corona awareness event</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training on sanitizer preparation at Central
                Jail, viyyur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Machine learning With MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON: DESIGN OF STEEL COMPRESSION
                MEMBERS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Opportunities in E-Waste
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da300a7c2bab83971b6216c67c4f2e0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RajaRajeswari College of Engineering, Bangalore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Ideas to hack or Prevent Cornona
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training on sanitizer preparation at
                Sangeetha Nataka Academy Thrissur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Pitch</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training on sanitizer preparation- Women
                Prison &amp; Correctional home, Thrissur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea, Entrepreneurship Opportunities,
                Business Model and Business Plan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Patents in Fostering Innovation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Demonstration and Working Model Expo on Soil
                Composting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Transformation in Software
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Ambassador Training Program
                for ANITS Staff</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training on Vermicompost and Waste
                Management Modules</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON: IoT WITH CLOUD USING MQTT
                PROTOCAL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal and Ethical Steps - Productive
                Entrepreneurship and Startup </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Skill Development Training
                in Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Angular Js and Node Js</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;How to start a SSI unit” & “Funding
                opportunity for SSI\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pep Talk on Building business on Urban Waste
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online quiz competition on the occasion of
                world hypertension day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property (IP) Management at
                Early Stage of Innovation and Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON: TOTAL QUALITY MANAGEMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Embedded System Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MY BRAND – BUILD ON YOUR OWN </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story - Innovator’s Life & Crossroad
                Motivational Speak</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON: COGNITIVE RADIO NETWORKS:
                ENABLING NEW AGE CONNECTIVITY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program on Government Participation
                in Waste Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STARTUP-An ecosystem of
                Innovation,transformation, employment and empowerment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Funding Options for Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training on Transform the Waste into
                Wealth</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Emerging Innovator &
                Entrepreneurs Supported through MIC & AICTE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Poster Making Competition on the theme
                \&#34;Biological Diversity\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Skill Development Training
                in SEO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ACM Summer Mentorship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->entrepreneurship and skill development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CEO Talk on Food for needy - Start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Communication Skills(interacting with people)
                for Better Results</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Managerial Ethics and Leadership
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Ambassador Training </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prototype to Product Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EMERGING TRENDS AND POTENTIALS OF RENEWABLE
                ENERGY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Motivational Speech </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme on Start-ups for
                Life Saving Defense Products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Challenges & Opportunities
                in Intellectual Property Right\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Development of Protein Rich Foods -
                Food Start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Institute Interaction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz on Lifestyle Challenges of
                Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session on Creativity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sanitizer preparation and distribution at
                College</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Technical Database Management for
                Businessmen</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Brain Machine Interface\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Learning Business through
                INFLIBNET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Session on Product Innovation &
                Development using Semi Conductors & its applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Renacido</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dc3f3ef3dabb1a25f39358c790ccd3a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sikkim Manipal Institute of Technology (SMIT)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RC-Car Development Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on Opportunities in Waste
                Recycling Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Initiation Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dc3f3ef3dabb1a25f39358c790ccd3a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sikkim Manipal Institute of Technology (SMIT)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drone Modelling Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to .Net Framework</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dc3f3ef3dabb1a25f39358c790ccd3a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sikkim Manipal Institute of Technology (SMIT)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Line-Follower Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dc3f3ef3dabb1a25f39358c790ccd3a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sikkim Manipal Institute of Technology (SMIT)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aurdino Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Pep Talk on Waste Management &
                Disposal - Practices in Urban & Rural India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dc3f3ef3dabb1a25f39358c790ccd3a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sikkim Manipal Institute of Technology (SMIT)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fund-Raiser Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Sensitization Programme for
                School Childrens</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dc3f3ef3dabb1a25f39358c790ccd3a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sikkim Manipal Institute of Technology (SMIT)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Alumni Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Google aI & ML for business
                start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32511849e978e1d10bca8b0d213273a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mehr Chand Mahajan D.A.V. College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Webinar on Entrepreneurship
                Development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Designing Tools & Techniques for
                IT Start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture on the topics of Cloud Computing
                reference architecture and Deployment Models and Data Warehousing & Data Mining</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Protocol Writing - Technie\&#39;s
                approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mask Making Challenge </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Challenges as an Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Activity based learning on New Start-ups
                opportunities in Mobile application development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Agriculture,
                Environment & Natural Resource Management: Lessons from the Pandemic\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Innovation for
                affordable healthcare infrastructure in rural Bharat\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Students Skill Development Programme on
                Business Opportunities in Live Stock Trading & Demat account Opening</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training on Natural Compost
                Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Innovation and
                Entrepreneurship for economically independent Bharat\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->B Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme to train
                employees for better productivity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Development Workshop on Innovative
                Product Development from reusable waste materials</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3562529f82430a6d534d1f094577b30d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Biswanath College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Health awareness campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on MVC Architecture Over Cloud
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid 19 Craft Making Challenge-Online
                Creativity Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3562529f82430a6d534d1f094577b30d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Biswanath College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL SCIENCE DAY (ESSAY COMPETITION &
                POPULAR TALK </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Pharmacy: A Bright Career”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on harnessing the power of AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Career Opportunities in Pharmacy”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3562529f82430a6d534d1f094577b30d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Biswanath College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ESSAY COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32511849e978e1d10bca8b0d213273a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mehr Chand Mahajan D.A.V. College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Summer Internship Program 2020 on Business
                Intelligence & Data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vision Pharma 2020 : Promoting Innovation and
                Enterpreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk with Ms. Ashwini Deshpande,
                Co-Founder and Director/Elephant Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Drug Discovery Process: A
                Critical Approach” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->- IIC orientation session by President IIC
                AIPER for faculties and students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL WEBINAR on \&#34;ADVANCED I.C
                (INTERNAL COMBUSTION) ENGINES\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Artificial
                Intelligence Using Python Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->B. K. Birla Global Innovation Challenge 2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction and getting started with AWS
                through online</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Quality & Information Security -
                An Introduction to Integrated Management System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Financial literacy seminar on funding sources
                for start ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advancement in Drug Discovery and Formulation
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Best out of waste Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34a9ec311694c3d10a021e967a907217.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACADEMY OF MARITIME EDUCATION AND TRAINING DEEMED TO BE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Feeding Strays in Society</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIVE DAYS ONLINE FDP ON \&#34;CHANGING
                BUSINESS STRATEGIES POST COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal and Ethical Steps for Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout With Successful Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Combat 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c445e2a2f94e4f3325a7f3d5a16623a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pune District Education Associations Seth Govind Raghunath Sable College of Pharmacy, Saswad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19: A war of mankind against virus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d4d106d76bf7c0db0ba38bb96e879d4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S R POLYTECHNIC COLLEGE THOKKAVADI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;LED Bulb Assembling\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/541ba0b1c9f6b903403f956c41917806.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJKIYA ENGINEERING COLLEGE, BANDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on 3D printing and its role during
                COVID19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Retail and Supply Chain Management
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40d13e359a0d710cfb11750a69a015d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B V V SANGHAS BASAVESHWAR ENGINEERING COLLEGE BAGALKOT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Webinar on Advancing the
                Future to Thrive in Next Normal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to RFIC Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on Role of Materials Science in
                Biotechnology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Stay ahead through product
                Innovation and Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design and Development on Product
                for Ingress Protection</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Insight of IT Industry - Industry trip to TCS
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Seven Habits of Highly
                Effective People</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f5bf254131b7a46b2466e63ea9a460c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAGHAVENDRA INSTITUTE OF PHARMACEUATICAL EDUCATION & RESEARCH (RIPER)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on challenges and oppurtunities in
                pharma sector -Covid 19 pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Product Development in Automotive
                Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Experience Generation IV PTC
                Onshape Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on Recent Advances in Crystal
                Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning, Digital twins and Self
                Organized Methods</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on Training through webinar Arduino on
                using Tinkercad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdded087b6aa9a5752a63ad67ac66e7e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING MANAV RACHNA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property (IP) Management at
                Early Stage of Innovation and Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Webinar on Data Analytics and Business
                Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on STRUCTURAL STEEL DESIGN CONCEPTS
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on BIOINFORMATICS IN TRANSLATIONAL
                DRUG DISCOVERY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on Highway Development and planning
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Revit Architecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Microchip’s Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Firing the Next Decade of Growth</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdded087b6aa9a5752a63ad67ac66e7e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING MANAV RACHNA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea, Entrepreneurship Opportunities,
                Business Model and Business Plan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Effective way to Impart Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Procedure and Product Life Cycle for
                Mechanical Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Topic:* BE AN EMPOWERED ENTREPRENEUR
                *Date*-25.05.2020 *Time*-11 A.M *Resource person* Mr. S. VARADARAJAN, MBA,DTM CHIEF EMPOWERMENT OFFICER,
                V empoweR academy INDIA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Software Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Expectations and How to fill them as
                Fresher to establish Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Dimensional Materials for Diverse
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Portfolio Builsing using GITHUB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/646baa5a7158c7b8814192a096efbe38.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Babu Banarasi Das National Institute of Technology and Management ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Next Generation Training Modules and Ways to
                improve for Summer Internship and Project Works” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Insights into the Modern Technologies and
                Teaching Learning Processes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days #NationalSeminar On Role of
                #Biocomposites for Automobile Applications-Natural Step Forward</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Skills and Soft Skills Enhancement
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Environment Day Celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coal Based Thermal Power Plant”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level workshop on Aero Modelling
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Verification using System Verilog</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/646baa5a7158c7b8814192a096efbe38.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Babu Banarasi Das National Institute of Technology and Management ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘Sustainability in Civil Engineering – Need
                of the 21st Century’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Role of computing in business
                development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Session with an Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNAL HACKATHON FOR SMART INDIA HACKATHON
                2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e-Session on Self Development for an
                effective entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on training </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on PYTHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Technical Assesment on Online Business
                Technical Puzzle</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Skill Assesment on Start a business
                for Java Coding</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, Manufacturing and startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Days National Workshop on “Analog and Digital
                System Design Using Cadence Tool</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Tips to Improve Business
                Communication Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tree of Remembrance: Bringing change through
                Youth Creating Awareness for Traffic Rules in an innovative way</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on “Productivity
                Enhancement Program” was organised by KIT during March 4th to 11th, 2020 for KIT Staff members.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Various Prospects for Engineers in Corporate
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Expert talk on Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz Contest on Basic Facts of Covid -
                19 and Covid Pledge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Innovation Day (Quiz)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->4th International Conference on Science,
                Technology, Engineering and Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HANDS-ON ONLINE SESSION ON MACHINE LEARNING
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Social Mapping and PRA Methods for
                Business development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Workshop on Noval product making in
                Bakery & Confectionary </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HANDS-ON ONLINE SESSION ON R PROGRAMMING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series : From Scratch to Score
                :5Convert your Business Plan to Pilot Scaling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON DATA ANALYSIS USING PYTHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Awareness Quiz Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Demo on Skills to focus for Testing
                Domain</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unnat Bharat Abhiyan </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Understanding Talk on Learn To Earn
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking - Preparing the mind for
                disruption</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live Webinar on Avenues in IT sector and
                tapping the business potential</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women on Wheels attempting LIMCA BOOK OF
                RECORDS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LINKEDIN ACCOUNT CREATION AND MANAGEMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Competetion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Placement During and After
                Covid19 - Talk with Ms. Mamta Kumari”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Studying Growth, Before
                Success</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DESIGN THINKING METHODOLOGIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Knowledge Sharing on Economics of
                Mushroom Cultivation and Export Potential</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/646baa5a7158c7b8814192a096efbe38.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Babu Banarasi Das National Institute of Technology and Management ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Technical Poster PPT Competition under
                the theme of Covid-19 - Infrastructural Innovations to fight against Corona Pandemic during this
                lockdown period</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Computational Biology on
                Advancing Applied Science - A new business venture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Students Demo Session on Insights on
                Satellite Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mathematical Modeling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Internship Talk on Usefulness of
                internship for a better understanding of business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Discussion Forum on How to handle the
                personal & professional life in Hotel Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fundamentals of Intellectual Property and
                Patent Search</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session on Upskilling Career Paths in
                Finance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction of Robots in
                industrial applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30dc441642f83359dbed738b6e05e89f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANDHI INSTITUTE FOR EDUCATION & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34; Carbon emissions reduction
                during COVID-19 pandemic and its impact on the climate”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Talent Hunt Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0630492a3dac203c73caee4214c4379b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDA INSTITUTE OF PROFESSIONAL STUDIES- TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering Simulation for Aerospace and
                Defence Domains</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to identify the common issues in
                the village of erachakulam.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30dc441642f83359dbed738b6e05e89f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANDHI INSTITUTE FOR EDUCATION & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34; Machine Learning in
                Cyberpsychology Studies”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essay Writing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on The Upcoming side of Life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pep Talk on Microsoft Dot Net Framework</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar-Fashion Merchandising</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Identifying & Preventing Cyber Crime: A
                common man\&#39;s way</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Life learning Skills- “Understanding Practice
                School and its concept in UG Pharmacy Education </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The HOW? Why Transitioning to Data Science
                for non IT Professional</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational e-session on 20th Century
                Leadership Skills in the 21st Century</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30dc441642f83359dbed738b6e05e89f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANDHI INSTITUTE FOR EDUCATION & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Virtual Labs For Science And
                Engineering: A Community Effort”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Arogya Setu App</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on ICT for Digital,
                Smart, and Sustainable Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Intellectual Property Rights Day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0630492a3dac203c73caee4214c4379b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDA INSTITUTE OF PROFESSIONAL STUDIES- TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Placement Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Brand Your Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rapid Prototyping through 3D Drafting and
                Designing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session on Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live Webinar on Team Work</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Graphene – The Future Material</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Communication Tips and Tricks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bbed21877a9150231ea61ff203076cd3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PAAVAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International one day webinar on \&#34;Power
                quality issues in commercial and industrial applications\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Pep Talk through Webex - Industrial
                production of detergent Enzyme and global business opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR and patent filing issues</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LEARN FROM MASTERS WEBINAR SERIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Contemporary tools and techniques for
                teachers and researchers in higher education: Experience of using virtual classrooms </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inspirational Session on Foreign Exchange
                Mechanism in Global Market</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0630492a3dac203c73caee4214c4379b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDA INSTITUTE OF PROFESSIONAL STUDIES- TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship with Centre for the Study of
                Developing Societies (CSDS) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Pathway Session for Engineering and
                Medicine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AGRONA 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture, “Role of Informatics for Bio-
                Entrepreneurship”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bbed21877a9150231ea61ff203076cd3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PAAVAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->India First Leadership talk series with
                Ms.Ashwini Deshpande</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Business Management in lock down
                period</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Behind the screen warriors: Indian scientists
                and the COVID 19 pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session on Business : A Myth or Reality
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Session on Strategies for employee
                training </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leading Through COVID-19: Digital Life Now
                and Beyond </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Avenues in android App
                development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session on Great Depression Vs Subprime
                Crisis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bbed21877a9150231ea61ff203076cd3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PAAVAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science day celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Promoting Employability Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indian Technology Day Celebration : CORONA
                Olympiad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Professional Business plan
                Writing for better proposals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHISETZ 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Managing International Relations during
                Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Free Online WORKSHOP” Tips for participating
                in Hackathon Smart India Hackathon- Student Experience</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Construction of automatic sanitizer
                dispensing machine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MoU with Catalyst Clinical Services to
                promote learning in Clinical Research and Pharmacovigilance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->New entrepreneurial business ideas,
                opportunities and strategies to grow your business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAREER IMPUSE - An Industry Institute
                Interaction Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on An effective tool in business
                analysis - Data Structure</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Online QUIZ for +2 Students”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Intercollege Web and mobile app Design
                Competition”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Group Discussion on Novel Product Development
                for rapid Diagnosis of Covid - 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Green Energy Building Model</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Code war \&#34;The ultimate coding
                extravaganza!\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre-Placement Training workshop - About Start
                of Career in IT - Post Pandemic situation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Workshop on CYBER SECURITY by Armour
                infosec ,Indore</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Business Plan Competition 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Understanding Angel and Venture Capital
                Funding </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Poster Competition for Coronavirus
                Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Success Story of CHAI SUTTA BAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series : From Scratch to Score-4-Idea
                convertion to Business Plan </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact of COVID-19 on Businesses in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/265efb63fe40bc6015f7b2274fe19873.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Scott Christian College, Nagercoil - 629 003">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea competition-online</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEATHON 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Early Stage Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/265efb63fe40bc6015f7b2274fe19873.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Scott Christian College, Nagercoil - 629 003">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Corona awareness video by students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 Pandemic Management in Current and
                Post Scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on Strategies for Better
                Relationship & Mental Health for budding entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Pathway Session S2T Webinar on
                \&#34;Know - What\&#39;s Next? \&#34; for HSC Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“On line Quiz Contest”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future of Green Energy in Pollution Control
                Post Covid19 Lockdown</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program - a holistic
                virtual program on “Emerging Technology in Data Science\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Knowledge Series IncubateInd on AI and
                Machine Learning </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RETHINK PLASTIC :AN AWARENESS AND
                SENSITIZATION WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/24d37fd5353bd7d2a1e8e168dac075d6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.M.S.COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BMSCE Hackathon_beat covid 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3D Printing Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Progressive Development of Human Computer
                Interaction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Text Analytics using Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on RPA -
                Automation Anywhere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry 4.0 and Entrepreneurship: Today’s
                Perspective</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->talk session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FUTURE TECH - RPA - AUTOMATION ANYWHERE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lockdown Impact Lecture on Disruption is the
                New Normal - Ways to Cope Post Lockdown (Keyup)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Health Assessment of Bridges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STRATEGIES TO OVERCOME THE UPCOMING PLACEMENT
                SEASON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Career Fit – Carrier Management in
                YUCA World</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guiding session on DishaThon by DishTV </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->POST COVID – 19, DIGITAL ERA – REIMAGING
                FRESH ENGINEERING GRADUATES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Knowlwdge Series on Full Stack Web
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON: SOLAR PHOTOVOLTAIC AND
                ALTERNATIVE ENERGY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on FUTURE TRENDS IN CMOS TECHNOLOGY
                AT 5nm AND BEYOND</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, Incubation and Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI VLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference EMERGING TRENDS IN
                ENGINEERING AND MANAGEMENT -2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID TRACKER APP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR on Technology & it\&#39;s
                implications on Economy in 21 century</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Unlock Your Mind </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IEEE Webinar in association with IIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Recent Advances in Power
                Transmission </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Business Case Study Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Organic Electronics -
                Importance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f7026d4c2ffa94ba7541f4f9e1bee7c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PENDEKANTI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Ambassador Training Program
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day seminar on ‘ELECTRIC VEHICLES\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Drive - Visiting the Slums</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning using Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent trends in electrical engineering.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AAvishkaar-2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f7026d4c2ffa94ba7541f4f9e1bee7c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PENDEKANTI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Conference on \&#39;Global IP Sharing
                to Combat Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electric Vehicle Technology Seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Ideation & Pre-Incubation camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drone Club Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ANSYS WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Top 8 Technology Trends for 2020 and how do
                students prepare themselves</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship opportunities in Digital learning
                Platform Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Case studies on \&#34;Real Time Problems in
                Sathyam Company\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship awareness camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TIE Talks on Education & Role of E-learning
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business incubation and start up awareness
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Ventilators and ECMO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit for Regional Meteorological
                Center, Chennai </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quarantine Ideathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Medical Thermal imaging and its applications
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Making Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY WORKSHOP ON ANGEL FUNDING FOR EARLY
                STAGE ENTREPRENEURS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on SmartQA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Modern Cutting - Edge Research on Artificial
                Emulation of Human bone for Bio medical applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Prevalence of Novel Coronavirus
                Disease COVID -19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Pitching Innovative Ideas </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UDAAN - an online event </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dev Jam 1.0 Online Hackathon (36 Hours)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Blockchain Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Natural Language Processing in AI
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Guest Lecture on Application of
                Descriptive Statistics in Business Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Working of DRONES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training on applications of Drones
                for Advance Surveying</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days International seminar on Art of
                Writing Scientific Articles, Funding Proposal and Patent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days International seminar on Art of
                Writing Scientific Articles, Funding Proposal and Patent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Six Days Faculty Development Program on
                Computer and Communication Networks </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Demo on Ethical Hacking - A New
                Approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Peer Mentoring Session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Learning the Basics of Business
                Start-ups </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Product Development Company
                Opportunities in this Pandemic Situation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e-Workshop on Security Analysis and
                Operations of Stock Markets A Practical Business Approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electric vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Seminar on \&#34;Recent Trends in Operating
                System\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Technology Trends, Disruptions and
                Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->quaterly report</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Green Building</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Seminar on \&#34;IOT and its
                Applications\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Six Days Faculty development program on TOOLS
                EXPLORED IN DISRUPTIVE TECHNOLOGIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Lecture on Start of Career in IT - A
                Post pandemice Scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development workshop - Preparing Better
                for projection of Start-up and skill development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on career pathway for engineering and
                medicine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR and Entrepreneurship Workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WE - Women Entrepreneurship Summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unlock your “Self” - Strengthen your immunity
                and be Stress free Through Sahaja Yoga Meditation for Budding Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Pep Talk through Webex - How to
                improve Personality to fit your business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid -19 Impact on Future of Engineering and
                Innovation.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on AN INTRODUCTION TO CLOUD HOSTED
                SERVERS AND SERVICES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on Entrepreneurship career choice
                options</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Boat of Innovation can sail in
                Turbulent Economy- Post Covid\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Substrate Immobilized TiO2
                Photocatalysts For Environmental Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Various Funding Agencies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e-Workshop on ESP Techniques in Mainframe for
                budding entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Innovation Voucher Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Jeevana Vidya</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Voucher Programme-EDII-TN_Salem
                Hub SONA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID19: DETECTING THE UNWELCOME INTRUDER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cancer Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Successful Start-ups”
                (Entrepreneurs in Campus )</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of Mentor in building a Successful
                Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to virtual reality
                and augmented reality </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational speech on Productive Campus Life
                for students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research, Innovations and IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Leadership talk </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Stress Management and Time
                Management and What is Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to plan for Start-up and legal and
                Ethical Steps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on EXPECTATIONS OF ENVISIONED FUTURE
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CYBER SECURITY - SECURITY ENGINEERING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->skill development program IBM Call for Code
                Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Pen and Art Competition based on the theme
                of #LockdownDiaries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Voucher Programme, the “Flagship
                Scheme of Tamil Nadu to support Research & Innovation”. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Peer Mentoring Session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar series from 30.5.2020-4.6.2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Management Club activity to Defeat
                Corona with the Means of Painting or article-writing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A One Week Programme on \&#34;Design Thinking
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology Awareness camp for Village Farmers
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital Parenting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Banking and Finance Congress 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Udyog Aadhaar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series : From Scratch to Score:3 -
                Idea Generation - Application Support for Software Testers in developing new business tools</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IEEE Xplore - Delivering Research better than
                ever</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program on IPR & IP Management for
                innovation and Start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Riding the Sales Force Wave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Emergence of Artificial
                Intelligence in today\&#39;s Scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture by International Faculty</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IEEE Xplore- Boosting Every Aspect of YOUR
                Career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Discussion Forum - Financial Management and
                over come the crisis of Covid</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual session through Webex - Benefits of
                Free Trade in Global Business - A Global Perspective during Lock down</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Analysis on Mastering the Art of
                Effective Communication for promoting the sales</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inspirational Talk through Webex - Reprogram
                your mind for a Successful Business Start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/014f6ecdfa6e4d8c759795472a3813a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DREAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar On Effective team management for the
                students in COVID lockdown</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series : From Scratch to Score-2 -
                How to Identify the Problem in a desired sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/014f6ecdfa6e4d8c759795472a3813a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DREAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Post COVID job opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Anti-carcinogenic indigenous
                product development from Indian Herbs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Recent Methodologies for
                Start-up promotion and Publicity using Virtual Advertisements</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar On Business Governance through
                On-line Portals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/014f6ecdfa6e4d8c759795472a3813a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DREAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LATERAL THINKING & CREATIVITY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Google Hashcode</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indoor Photography Contest on Product - The
                art of impressive advertisement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b334483808283ecf4639bc02a0eef33b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sau. Sushila Danchand Ghodawat Charitable Trust Group of Institutions Faculty of Engineering, Gat No">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Reshaping Education in the New World -
                Pandemic Effect</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Business Policy - A global view
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lockdown Impact Lecture on SHINNING THROUGH
                THE COVID FOG</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Compass</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series : From Scratct to Score -
                Problem and Possibilities - A start-up initiative for you business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational e-session on Methods to reach
                the end customer the for better sales</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How Mechanical and Mechatronics Engineering
                will benefit Post Covid19 World?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Three Days Workshop on Basic Programming
                using Python Basics From 5th December to 7th December 2019 by Ms.Shameemunnisa, M SriLalitha, D Sai Ram,
                I Poojitha, K Keerthi and T Muneiah, Trainer cum developer, APSSDC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on How to train your employees for
                better productivity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Cyber Security </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Intellectual Property
                Rights in the Fourth Industrial Revolution Era – Issues and Challenges on 4th February 2020 By Mr. Siva
                Sankar Kasturi, Founder and CEO, Amcons IPR Attorneys, A.P And Mr. TR Ramesh IPR Consultant </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on New start-up opportunities in Four
                wheeler sector </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Three Days Workshop on Entrepreneurship
                Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Food Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ten Days Workshop on Advanced Technologies
                -Internet of Things (IoT) From 2nd to 11th December 2019 by APSSDC (Andhra Pradesh State Skill
                Development Corporation)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lockdown Impact lecture on Building a
                Successful Startup! Need of Investors to grow your business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Three Days Workshop on Advanced Python From
                17th to 19th February 2020 by APSSDC (Andhra Pradesh State Skill Development Corporation), Govt. of A.P
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-learning on Role of IT in Healthcare
                business start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Conference on Knowledge based
                Inventive Electronics & Telecommunication Systems (NCKIETS-2020) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Manufacture and donation of masks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A National Level Faculty Development
                Programme On VLSI Chip Design Hands-On Using Open Source EDA (16th December 2019 to 20th December 2019)
                By Electronics & IT Academy, IIT Guwahati, Government of India </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->entrepreneurship Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Research Opportunities in AI and ML
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/014f6ecdfa6e4d8c759795472a3813a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DREAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pitch Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Contest Version 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Universal Human Values and Ethics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Intellectual Property Rights on Overview</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lockdown Impact Lecture on Build your
                professional Branding through digital platform</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Automation in Process Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Impressive methods of business
                communication for Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;the journey from Campus to corporate
                and its preparation\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Training on The art of Business
                presentation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation to Patent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b334483808283ecf4639bc02a0eef33b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sau. Sushila Danchand Ghodawat Charitable Trust Group of Institutions Faculty of Engineering, Gat No">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conflicts of Interest, Confidentiality &
                Whistle blowing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/848c82596b7539a46e055dba1a616c51.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#39;Understanding knowladge for building
                truly inteligent system\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6249f9cb063c4bf27a0c8f1ab4c1dbc8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SDP on Blockchain and Ethereum</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL ONLINE QUIZ on COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on Importance of ventilators in
                covid situation and future trends in medical equipment industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Improving Research Planning Skills at
                Technical Institute</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE PROMOTION of ATAMA NIRBHAR CAMPAGIN
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Brain Busters</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SWATCH BHARAT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture on MSME.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vanmahotsava Celebration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sustainable and Renewable Energy Technology
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FAER McAfee Scholar program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT enabled Smart Living</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3D building modeling using sketchup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISTE Innovation Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology Entrepreneurship Development
                Programme (TEDP)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5146e8708610f28090975ccce056b96f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="New Horizon College, Kasturinagar, NGEF Layout, Bangalore-43">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tecnical Paper presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp (EAC)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp (EAC)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lockdown Impact Lecture on IPR and Innovation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interview Skills for Freshers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techniques to grab lucrative job post covid19
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent emerging Technologies which can place
                a big role in placements</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5146e8708610f28090975ccce056b96f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="New Horizon College, Kasturinagar, NGEF Layout, Bangalore-43">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UNLOCK your potential</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/787e086cf78de2e0f1a3874b63f6494c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAR SRR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tommorrow Be Yours </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/646baa5a7158c7b8814192a096efbe38.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Babu Banarasi Das National Institute of Technology and Management ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Technical Hackathon-2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Trends in I C Engines </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Life after Mechanical Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/787e086cf78de2e0f1a3874b63f6494c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAR SRR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Innovation & Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/787e086cf78de2e0f1a3874b63f6494c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAR SRR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STARTUP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Geospatial Applications for Civil
                Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Fire Safety and Structural
                Stability</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dbe5459b13b0cb65042fa4eaa155a4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="E.G.S. PILLAY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz on Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Awareness Program on Build the
                Enterprise of your Dreams</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Significance of Rural and Business
                Incubator in Academic Institutions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Post COVID 19: Business opportunities &
                threats</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities for students during and after
                COVID lockdown</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->one day workshop on “Start-ups by Wission
                Talks”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness and Business Plan
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WIPO distance learning Courses</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ambassador Training Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special FB Live session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day mentoring session on IPR and IP
                Management for innovation and startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Informatics in Bio- entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz programme on IPR with
                specialization of Patent Cooperation Treaty (PCT)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Five days hands on training program on IC
                Engine Assembly and Maintenance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WIPO Green Pledge online promotion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Applications
                of Mechanisms in Engineering </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Six Days Value Added Training Programme on
                \&#34;Creo Parametric 3D Modelling\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Food Start-up Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ROBOTIC REFORAMTION </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Role Play</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Techniques in Consumer Goods
                Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training & Skill Development on Business
                Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Program on ENERGY CONSERVATION AND
                MANAGEMENT PROGRAM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Seminar on \&#34;IPR and its
                activities\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea to Prototype Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Business Opportunities in Data
                Storage and analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training on SPSS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dd41073f400834708f70184f8f281a3e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING, KALAHANDI, BHAWANIPATNA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Boot Camp for Start-up Activities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Exhibition from Waste</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Four Days Live Virtual Boot Camp on
                Competitive Automobiles: Fundamentals of Vehicle Dynamics and Alternative Propulsion </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Session for First Generation
                Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two weeks online refresher course on
                Advancements in Mechanical, Production & Civil Engineering and ICT in Teaching Learning Process</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webminar on Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Introduction to Intellectual Property
                and Patents”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6249f9cb063c4bf27a0c8f1ab4c1dbc8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STTP on Renewable Energy, Smart Electronics &
                Energy Audit for Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD Innovation Cell Twitter Live series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Twitter Live Leadership Talk Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4241bc2dfc3a4d6ac9d25f29b4b8dd4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMITY UNIVERSITY,JHARKHAND">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Problem Based
                Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech TSTORM 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4241bc2dfc3a4d6ac9d25f29b4b8dd4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMITY UNIVERSITY,JHARKHAND">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Emerging
                Trends In Electric vechicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-TATA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-RELIANCE
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Greetings from Prathyusha Engineering
                College, *Department of Mechanical Engineering &PEC-EDC Jointly Organizing *Webinar* for Faculty and
                Students. *Topic:* LEADERSHIP SKILLS *Date*-26.05.2020 *Time*-10 A.M *Resource person* Mr. S. VENKATESH
                Leadership Trainer,Author & HR Manager in a Reputed Pharmaceutical company</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-PETER
                ENGLAND</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-PATANJALI
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-LIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-HERO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-Godrej</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Xcalibur season 2 - 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-Flying
                machine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-AMUL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VOCAL ABOUT LOCAL-Swadeshi Series-AIRTEL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Doodling Art Competition for graduates -Put
                your IDEAS on Paper through doodle art</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR SERIES 4-WEBINAR ON UNDERWATER
                NAVIGATION THEORY AND PRACTICE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Interaction With Experts -Changing
                Face of Economy </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR SERIES 3-WEBINAR ON DATA ENCRYPTION
                ALGORITHMS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ec079ae6dae5f61f28ec90ccd2941237.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRASAD V POTLURI SIDDHARTHA INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SLOGAN WRITING COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Distribution of Baby Care Products by
                Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR SERIES 2-WEBINAR ON HUMAN SUMERSIBLES
                INDIAN STORY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EMBRACE TECHNOLOGY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Anonymous arrays, Treaps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Psychological Approach Towards
                Employees</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Crimping tool usage in
                Ethernet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Preparation for Competitive Exam
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning Practices followed in
                Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI – An Industrial Approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Artificial Intelligence and
                its applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on CANSAT and CUBESAT Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Innovations & Creativity
                for Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Customization of Mining
                Algorithms Using R tool</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f46a6926ab2ebaa6b0723a8142eb873f.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Reimagining Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mobile Controlled Robot</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Patent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Developing mobile
                applications using Android</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CEO Talk on Soft Skills Development and
                Career Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Leadership Forum</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensitization Programme on Self Employment
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Striderz 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Code Live with Unity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HAM club inauguration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Seminar on Collection Framework in Java</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quarant N Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Technology Entrepreneurship
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Recent Advances in Materials
                Characterization </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gandiva 3.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CRYPTO-BRAIN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Start-Up Development on 13th
                May,2020 by Tatva Bodh Arya, CEO, Tatva Finess Town</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Low Power VLSI Design and Analysis
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Training Program for Becoming a
                Better Teacher by Prof. Vishwanatham, Retired Dean Faculty of Commerce, Osmania University on 6th and
                7th Dec,2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Exhibition -2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Two Day Work Shop on Life Skills and
                Personal Leadership by FinacPlus Team 6th and 7th Dec, 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp in
                Association with DST – NIMAT From 18th to 20th February, 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One week Workshop on ADX Salesforce by ICT
                Academy From 9th to 14th December, 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Land Subsidies in India : A Civil Engineering
                Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp in
                Association with DST – NIMAT On 11th to 13th December, 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to MEP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Workshop on Advanced Python 12th to 21st
                December 2019 by APSSDC (Andhra Pradesh State Skill Development Corporation), Government of Andhra
                Pradesh</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session on 5G Fighter Aircraft
                Avionics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ek-Do-Ka-Teen</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> A Motivational Session on 3rd February, 2020
                By Sri Vilasa Vigraha Dasa , Volunteer Akshya Patra Foundation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Super Capacitor and Energy Storage Systems
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4d7efd11bfc5ceb3718364d48e056d09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEPCO SCHLENK ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Shikaar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAI PROTRONICS-2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exaltan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Nurturing Innovation and Entrepreneurship
                within an Educational Organization.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Automous Robotics Project Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Program on Python Programming 29th
                January, 2020 to 14th February 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Fundamentals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e- Yantra Lab Setup Inititative(Elsi) Two
                days Teacher’s Training Workshop on 6th & 7th Mar 2020 by Mr. Vivek L. Sabanwar, Mr. Vishal Gupta, Mr.
                SaailNarvekar from III Bombay</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TRAINING PROGRAM ON C, DATA STRUCTURES AND
                ALGORITHMS 27thDecember, 2019 to 7th January 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on Career Guidance on 27th
                January, 2020 By Sri Vejendla Jaya Prasad, Co-Founder & CEO of FORSYS INC, SiliconVally, USA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c0d510119bc91983d904ec9d80768210.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNEL CHARITIES AGNEL POLYTECHNIC">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->visit to CIBA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Three days Boot Camp and Ideathon Using
                Block Chainby Pankaj Diwan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guest Lecture on MACHINE LEARNING & ITS
                APPLICATIONS IN FOOD ENGINEERING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Startup Ideathon.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7682ef75b7383fc2f85b89bdb4bee8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANDIAN SARASWATHI YADAV ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PSY Sigaram Thodu 2020 webinar series Ep - 3
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7682ef75b7383fc2f85b89bdb4bee8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANDIAN SARASWATHI YADAV ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PSY Sigaram Thodu 2020 webinar series Ep - 2
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7682ef75b7383fc2f85b89bdb4bee8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANDIAN SARASWATHI YADAV ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PSY Sigaram Thodu 2020 webinar series Ep - 1
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Data Analytics”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4d7efd11bfc5ceb3718364d48e056d09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEPCO SCHLENK ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Talk on Telecommunication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business plan Model Competiton</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Entrepreneurship in the wake of
                COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IEI Student Chapter - Advaitha </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Patent drafting and Filing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital Marketing – Career Options
                Post COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Intellectual Property rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISTE SB Webinar on Chat Box using NLP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Nation Level Skill development Workshop on
                Making 3 layered Face Mask For Covid19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->App Rush Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar on Machine Learning
                using Python with Real Time Datasets</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cybersecutiry need of the hour and future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Civil Engineers Emerging as Construction
                Managers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Civil Engineers Emerging as Construction
                Managers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Game Development Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of Data Governance in Digital
                Economy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->101-IDEA TO LAUNCH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IoT Applications for Consumer
                Electronics and Home Automation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role Play on Entrepreneurship
                Businessman/Women in various sectors</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Anveshana 3.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d12575dffa16bd474af6122508e849a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nagesh Karajagi Orchid College of Engineering and Technology, Solapur.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Five Day Faculty Development Webinar on EVs
                12-16 May 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DESIGN THINKING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Field Visit on Government Initiatives in
                Municipal Solid Waste Management.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Current Industry standards and Guidelines</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in JISCE Integrated Hackathon
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4996c72a381a62e5fb4c0c63d32e6761.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NITTE MEENAKSHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Entrepreneurship-Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Session on Special Economy Package by
                GoI RISE with SMVDU - Reasearch and Innovation for Social Empowerment\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BIRAC meet oraganized by ISBA and a session
                by CEO SMVDU TBIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Session on NIDHI PRAYAS RISE with SMVDU
                - Reasearch and Innovation for Social Empowerment\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day awareness camp organized by DIC Jammu
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/70b1e4778caddc7631f16567019eaa2d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARASARAOPETA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Online Quiz on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur Skill Development Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online webinar on III Year Industry 4.0 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/70b1e4778caddc7631f16567019eaa2d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARASARAOPETA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Awareness Quiz on Usage of Arogya Setu App
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on PYTHON Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Industry Institute
                Interaction: Post COVID-19 Challenges and opportunities\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on \&#34;Introduction to strength
                of materials\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on \&#34;Hydrograph\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property and Innovation
                management under make in India and Start-up India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start-up funding Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenges and Solutions before the Civil
                Engineers during Lockdowm / Corona/period and thereafter </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Why does concrete cracks </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Construction in coastal regions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart India Hackathon (SMVDU Ideathon)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A National Level E-Quiz on \&#34;Awareness on
                IPR for Beginners\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Online fake news” – Misinformation &
                Disinformation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Workshop on Trends in Indian Food
                Startup and Italian Scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Hackathon hosted by
                JiniHacks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Exhibition 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Digital Parenting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Success- A journey not a destination</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Program in IoT Applications
                in Business Fields</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Natural Language of Processing and Text
                Mining</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation on HackCoViT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Shuruaat-TX 1.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Awareness and Patent Procecution</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Days Workshop on “Recent Trends in
                Fluid Power Control System & its Industrial Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a3e8692e3083075954472504e64279aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mar Baselious Institute of Technology and Science, Nellimattom. P.O,Kothamangalam - 686 693">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product prototyping workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0630492a3dac203c73caee4214c4379b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDA INSTITUTE OF PROFESSIONAL STUDIES- TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Cloud Computing with AWS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Dealing with Problems in a Smart
                Way</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;SABR kar Bande\&#34; Video Prepared by
                BAF students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a3e8692e3083075954472504e64279aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mar Baselious Institute of Technology and Science, Nellimattom. P.O,Kothamangalam - 686 693">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> SKILL SHARE PROGRAM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Current Scenario in
                Business Sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0630492a3dac203c73caee4214c4379b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDA INSTITUTE OF PROFESSIONAL STUDIES- TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career in Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0630492a3dac203c73caee4214c4379b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDA INSTITUTE OF PROFESSIONAL STUDIES- TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role and Opportunities for Mechanical
                Engineers in Energy Sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Turning a bright idea into a
                successful business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future Skills for Women</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Business Analytics Using R
                Software</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Introduction to Design Thinking
                Approach </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Webinar on Recent trends in Global
                Automotive Sectors </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations on Engine Management System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0630492a3dac203c73caee4214c4379b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDA INSTITUTE OF PROFESSIONAL STUDIES- TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UNLOCKING JOBS IN LOCKDOWN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0630492a3dac203c73caee4214c4379b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDA INSTITUTE OF PROFESSIONAL STUDIES- TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Placement Talk with Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster competition on Science for Mankind</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Biotechnology for Day-to-Day Life
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Real Time Simulation of
                Industry 4.0 Eco system for a Manufacturing Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/014f6ecdfa6e4d8c759795472a3813a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DREAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar On Student Psychology and Higher
                Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Webinar on Biomedical Application
                of Fiber Bragg Grating Sensors</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vidyasagar Principal Kundnani Innovation and
                Entrepreneurship Centre </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Conquering dreams and self
                confidence dealing with COVID 19 effects </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Why you should Brand yourself</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Power Electronics and its
                application </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Holistic Development - A new focus on Higer
                Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Faculty Development Program on Recent
                technologies in 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Role of Bio medical
                Engineers on Smart city, IOT and Smart Health </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82709ebdaeb148a6aea72eba2a50cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Technology Day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on career guidance for engineering
                students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Managing Personal Cyber Securuty Risk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fundamentals of Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MATLAB Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82709ebdaeb148a6aea72eba2a50cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guide to clinical Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science day celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Opportunity in Data Science Across
                Different Countries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cisco Packet Tracer Hands on Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drones in Disaster Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82709ebdaeb148a6aea72eba2a50cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR contest on Patent and Design </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dealing With Anxiety During the Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on A Short View on Sustainability</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aeronautical and Mechanical CAD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82709ebdaeb148a6aea72eba2a50cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Quiz On National Innovation and
                Startup policy </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d920e6897b3d2f302d5c640651dec8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Deen Dayal Upadhyaya College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Finding needles in a haystack: Liquid Biopsy
                for Nucleic Acid and Protein Molecular Biomarkers for Disease Diagnosis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering Information Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d920e6897b3d2f302d5c640651dec8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Deen Dayal Upadhyaya College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Atmanirbhar Bharat Webinar Series (आत्मनिर्भर
                भारत वेबिनार श्रृंखला)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Session of NSS Programme officers to
                spread awareness against COVID-19 by various innovative ways</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NSS Online State Level Competition:
                Innovative ideas to create awareness among students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on COVID 19 :
                Challenges in Testing, Prophylaxis and Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Awareness Program: COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 Awareness Program by AIPER Alumni
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID – 19 DETECT & PROTECT CHALLENGE </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Application of nano cementitious material in
                concrete </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to move sustainably</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Freight Forwarders</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Latest trends in Big Data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->one day workshop on “BOZO ATTAIRE”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on “Aari Embroidery” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Upgrading Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar onCURRENT UI FRAMEWORKS - WEB AND
                MOBILE APPLICATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on LaTeX - Effective Writing
                on Thesis and Research Paper using LaTeX (Hands on Training)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Block chain</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Business Applications of Big Data
                Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Copyright Societies </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Defense of Fair Use</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Basics of Intellectual Property Rights </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day National Seminar on
                \&#34;Entrepreneurship, Innovation and Start-Ups\&#34; organised by UIET CSE Dept in association with
                MHRD IIC, SBBSU, Jalandhar on 28 Jan, 2020.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on ‘Recent Trends in IPR and Economic
                Development in India’ </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->wo Days (7th ,8th Feb-2020) International
                Conference on Futuristic Strategies of ICT for Digital and Sustainable Development (FSIDSD-20)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organized One Day Workshop on Plant Tissue
                Culture Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A National Level E-Quiz on \&#34;JAVA
                Programming\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A National Level E-Quiz on
                \&#34;Entrepreneurship Development and Innovation\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->organized programme on Campus to Corporate
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exploring Opportunities & Providing
                Consultancy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->New product development (NPD) Project
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to equip and build a career in these
                changing times</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Information Technology in Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Luminous side of Entrepreneurship in India
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Data Science as career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Karona mode : Reset Yourself</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Orientation of Students for Career
                Prospects in Acropolis Institute of Pharmaceutical Education and Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACKOHOLICS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Technology: A Game Changer in Higher
                Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Promotion Innovation and Enterprenuership</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact webinar on \&#34;Sustainable
                Development Goals ( SDGs) -Post-COVID19: Universities can be Game-Changers\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAREER COUNSELLING WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essay Writing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Manufacturer & User overview of critical
                laboratory equipments - Laminar Air Flow, Biosafety Cabinet and Fume Hood. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Everything Started with Nothing - Make a
                Start</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Lecture series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unleashing the power of Innovative Minds</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Latest MOU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social activities -Help provided to needy
                people during covid 19 situation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“EMOTIONAL INTELLIGENCE”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National level Symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RECENT ADVANCEMENTS IN GRAPH THEORY AND
                APPLICATIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Redesigning The MBA Program for the Future
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“FUTURE OF WORK: BEING FIT”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“INNOVATION AND STARTUP OPPORTUNITY”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Nuclear Energy is clean and Green Energy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c7cb778ce1eb0b904685c20da569b5d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.P. Poddar Institute of Management and Technology 115">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BPPIMT Ideathon (Online event)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Managers for the new normal, E-Management
                conclave dated 31st may 5.00-6.00 PM. By with Ms. Abha Prasad, world bank. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Filed Title:Novel System, Design and
                Methods of PocketQube Satellite (PQ Sat)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Filed Title:Novel System, Method and
                Design of Novel Design Heated Handkerchief to fight COVID19: An Innovative Method for Disinfection (A
                HANDKERCHIEF TO FIGHT AGAINST VIRALINFECTIONS AND A METHOD OF FABRICATING THE HANDKERCHIEF)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Filed Title:Novel System, Method and
                Design of Ambu Bag Ventilator System (ABVS)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Filed Title:Novel System, Method, Idea
                and Design of Selective Disinfectant Spray Pathway (SDSP)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design of Multifunctional Smart Construction Equipment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c7cb778ce1eb0b904685c20da569b5d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.P. Poddar Institute of Management and Technology 115">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Invitation of Technical (Scientific and
                Management oriented) Review Articles on recent innovations, research outcomes, business models and ideas
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design of Electronic Fuel Injection System (EFIS) for N-2 Stroke Engine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design and Development of Magnetic Elevator.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design and Optimization of Blades for Solar Grass Cutter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEN STEPS TO BECOME AN ENTREPRENEUR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Intellectual Property Week Activity
                (Quiz)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design of Compressed Air Generation from Suspension of Automobiles for Pneumatic Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Resources for Digital learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design of N-2 CHARGER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design of N-2 PISTON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design of Rishav Chhabra- Torque Piston (RC-T Piston)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Webinar on \&#34;IPR for Innovation
                Management\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design of Lubrication System for 2 Stroke Petrol Engine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c7cb778ce1eb0b904685c20da569b5d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.P. Poddar Institute of Management and Technology 115">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Competition on Business Plan (B Plan)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop for Progressive Farmers on the
                Employment of Science and Technology for Successful Integrated Farming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Disruptive Technologies in International
                Trade- Post COVID</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Published Title:Novel System, Method
                and Design of Swarm Detection and Beehive Monitoring System Using Auditory and Microclimatic Analysis
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Seminar on “Science and Technology
                for Insect Diversity Conservation and Agricultural Progressive”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Online FDP on \&#34;Recent Trends
                ,Advancements and Applications of Communication Technologies,RTAACT-2020\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Herbal Medicine in Health Issues of
                Day Today Life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c7cb778ce1eb0b904685c20da569b5d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.P. Poddar Institute of Management and Technology 115">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Lecture on ‘Fostering Innovation for
                Development of Science and Technology’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Plant Tissue Culture
                Techniques [Hands-on Training]</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c7cb778ce1eb0b904685c20da569b5d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.P. Poddar Institute of Management and Technology 115">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Successful Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Attitude of Winners</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> National Level Quiz on \&#34;Internet of
                Things (IoT)\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar to fight covid -19 using Robotics &
                IoT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Virtual Labs </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GEOconfluence 2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7347a538496bf5aaba83b7e7360fe581.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI G.V.G.VISALAKSHI COLLEGE FOR WOMEN (AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to image processing in
                Biomedical applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skills needed in Biomedical - Post corona
                pandameic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global conclave on Digital India 2.0 -
                Government Transforms with Emerging Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7347a538496bf5aaba83b7e7360fe581.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI G.V.G.VISALAKSHI COLLEGE FOR WOMEN (AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->College Bazaar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f46a6926ab2ebaa6b0723a8142eb873f.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Designing of low-cost face shield from OHP
                sheets Using 3D- printing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dbe5459b13b0cb65042fa4eaa155a4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="E.G.S. PILLAY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Entrepreneurship as Career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on\&#34;Arduino\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea, Business Model and Business Plan\&#34;
                in social Media</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Benefits and Aspects of Profile Buliding to
                meet international norms</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Instrumentation and Career opportunities to
                the data world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on PLC and DCS Applications in
                Process Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awarness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-session on \&#34;BLOCK CHAIN AND ACTION ON
                GOOGLE\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18526f82cdfcbdd79e98193173f11455.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS DWARKADAS J. SANGHVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Alumni to boost Internship in Media
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Applications of Embedded Programs
                in Business Development & Design using Arduino and IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live Case Analysis on Bond & Debt</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Case Analysis on Capital Marketing & Risk
                Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Business Protocol</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Entrepreneurial Skill Development
                Workshop for School Children</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on \&#34;Web Development\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Series </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensitization Programme On Energy
                Conservation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->motivational speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->S2T Webminar No.3 on E-Vehicles and Solaris
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEE 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidance for Engineering and Medicine
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on\&#34; Ethical Hacking\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Technology Behind
                BS-VI\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to stay unique and stay head of crowd</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Learning to build a Business
                Enterprise using Mathematical Modeling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Skill Enhancement Program on
                ELECTRIC VEHICLES </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pharmaceutical Applications of Next
                Generation Sequencing in Drug Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Perspectives on Vaccine Development for
                COVID-19: Race against Time</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seven Days online Faculty Development Program
                (FDP) on \&#34;ETHICAL PRACTICES IN ENGINEERING\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff176e1d25d504b6d629c45fe1abe6ee.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AJAY KUMAR GARG ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rethinking Innovation in Product Development:
                The Future of Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5b29f28cd6f9fd1f42c8519e03de6b7d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO ENGINEERING COLLEGE BANIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Aspects in Post Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff176e1d25d504b6d629c45fe1abe6ee.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AJAY KUMAR GARG ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on FUTURE OF WELDING: Journey Towards
                Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff176e1d25d504b6d629c45fe1abe6ee.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AJAY KUMAR GARG ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Additive Manufacturig</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CORE COMMITTEE#6</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Environment Day - 2020 Activity (Online
                Quiz)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff176e1d25d504b6d629c45fe1abe6ee.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AJAY KUMAR GARG ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concepts of Robotic programming & application
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff176e1d25d504b6d629c45fe1abe6ee.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AJAY KUMAR GARG ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rethinking Innovation in Product Development:
                The Future of Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Application of Mathematical
                Modelling in Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CORE COMMITTEE # 5</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar on Stem Cell
                Research-SARS-Cov-2 (COVID-19)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internet of Things - Challenges & Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Innovation Webinar on Social
                Innovation and Change Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PROJECT EXHIBITION 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD Leadership Talk with Ms. Anu Acharya,
                Founder & CEO, Mapmygenome</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/076c8690c134f458aba2e803c816cb49.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Trends in Engineering & Management
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/076c8690c134f458aba2e803c816cb49.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Operations and Recruitment in Corporates -
                Need for Innovative Practices in the Post Covid Scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar on Transgenic Plant
                Production - Theoretical & Practical Approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Intellectual Property Week Activity
                (Quiz) - 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->organized International Seminar on “The
                Contextual Perspective on Recent Global Commerce Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Oganized HANDS-ON TRAINING IN GST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organized Awareness programme for Rural
                Students on Innovation in Science and Technology (KASC INNOVATE 2020)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organized awareness programme on
                Technological Advancement for the healthcare of Rural School Students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bbed21877a9150231ea61ff203076cd3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PAAVAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Application of Light Weight Composite</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bbed21877a9150231ea61ff203076cd3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PAAVAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Qiz on COVID - 19 General Awarness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Core Committee meeting #4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design Your Destiny</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Core committee meeting#3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Use of ADmixtures in sustainable Concretes
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Session on E-cell and IIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Applications of Electronic Devices
                in Industrial Automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The symposium on water conservation as a part
                of World water day celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days National Conference on “Empowerment
                of Rural communities through Innovations in Science and Technology\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Engineering Mathematics Through
                Open Sources</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day State level workshop on
                “Biostatistical tools for Biological and Public Health Research”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online demo session of “Microsoft Teams” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to write scientific paper</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Smart Electromechanical Systems
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARENESS WORKSHOP ON BIOCOMPOSTING AND
                BIOENZYME PRODUCTION FOR RURAL COMMUNITY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5b29f28cd6f9fd1f42c8519e03de6b7d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO ENGINEERING COLLEGE BANIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future perspective for COVID-19: Mental
                Health issues</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Cloud Computing Using Amazon Web
                Services</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on
                \&#34;BUSINESS ANCHORS\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2734e153d99ce44df7df28330db41391.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Academy of Technology 169">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH 2020 Smart India hackhathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BIRAC role in creating the startup innovation
                system</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Webinar on \&#34;Data Analysis in
                Management Research\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> “Brainster 2K20- Business Plan Competition”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit To Buisiness Incubator</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“How to Plan for Start-Up and Legal and
                Ethical Steps”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Webinar on \&#34;SPANCO-SALES
                FRAMEWORK\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Core Committee meeting #1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Basics of Data Structures</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Educational visit to the Khadakwasla Dam,
                Khadakwasla Village, Pune on 13th March 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on An execution and Innovation
                In RCC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Enhancement C Programming for Beginners
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Analysis Using Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Augmented Reality and Virtual Reality
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk by Industry Expert on Basics of
                Data Integrity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Core Commitee Meeting #2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Learning by TCSiON: Career
                Edge-Knockdown the Lockdown</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skillrack Training Problem Solving</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to ATAL lab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Inustry 4.0 & IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Control valve sizing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training on Data Science - 5 week
                webinar series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->German and Germany - Exploring Opportunities
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Sea navigational aids</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership In Crisis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Python – A versatile High-Level
                Language for Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Refresher Course on Recent Trends in
                Information, Computing, Communication and Teaching Methodology Competency</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #5 -
                \&#34;E-Mobility\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aarogya Setu COVID-19 online Quiz Competition
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Pharma & Biotech Sectors in COVID-19
                Drug Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Battery Electric Vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Healthcare Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Green and Sustainable Energy Options For
                Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID’19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar on Enhancing Immune
                System through Yogic Practices to Overcome Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to make oneself productive:A key to
                unlock your skills in this Lockdown by Dr.Manpreet Singh Manna,Former Director,AICTE,Ministry of
                MHRD.Govt.of India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->a webinar on \&#34;Future of Work: Life Post
                Covid 19\&#34; on 18th April,2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship and Innovation as Career
                Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Some Defining Concepts of Management to Shape
                Personal Goals by Mr. Aritra Kundu (Currently associated with Doconline Health India Private Limited as
                General Manager) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid 19 Online Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Publication of Science Magazine-Vignana
                Pookal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill development-Orcad PSpice Editor
                workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational talk to Get Ready to face
                challenges in Career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Cybersecurity - From an
                Enterprise viewpoint\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> #WhenMenteeBecomesMentor program on 14th
                April, 2020 when a Workshop on Android Development using Android Studio was hosted by Sumit Kumar Singh
                of CSE 4th Year. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Embedded Processor related to Internet of
                Things </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Anokha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI in Telecom </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Best fit Fresher candidate post Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship display of items</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HR Intercollegiate Workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Management Development Workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Anokha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Initiate Pre startup business by our
                B.Pharmacy final year student during Covid-19 pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Webinar on \&#34;Self Motivation - the key
                to propel ahead\&#34; on 11th April,2020 from 11AM-12PM for the final year and pre final year students
                by Mr.Rahul Bose,Global Learning and Knowledge Role,IBM.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4996c72a381a62e5fb4c0c63d32e6761.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NITTE MEENAKSHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON ENTREPRENEURSHIP AND INNOVATION
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice-over Power Point
                Presentation(Competition) on COVID-19 by ISF student forum</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee13f39ce76a3ee71444735dd4a97844.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B I T SINDRI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Startup and Entrepreneurship
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘Ambassadors’ in ‘Drug Discovery Grand
                Hackathon - Innovate4NewDrugs’ organized by MHRD Innovation cell and Pharmacy Council of India.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vavdodara Start Up Fest 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Basics of getting things started</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Know Covid19 for NO Covid19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73c11e5d60a8f639ed0ed0f323827530.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stress and Time Management, Communication
                Skills for Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->360 Degree Classroom Management - Teaching
                Methodology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Robotics process Automation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f5bf254131b7a46b2466e63ea9a460c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAGHAVENDRA INSTITUTE OF PHARMACEUATICAL EDUCATION & RESEARCH (RIPER)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->covid innovation challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conclave2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73c11e5d60a8f639ed0ed0f323827530.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR-TODAY AND FUTURE </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73c11e5d60a8f639ed0ed0f323827530.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to IPR and Patent Landscaping
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73c11e5d60a8f639ed0ed0f323827530.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP AN EVERGREEN CAREER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73c11e5d60a8f639ed0ed0f323827530.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking -Opportunities and Challenges
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6f78b80c5172f3413b2506c386408a0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H.RAISONI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Session on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6f78b80c5172f3413b2506c386408a0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H.RAISONI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4996c72a381a62e5fb4c0c63d32e6761.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NITTE MEENAKSHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP/SEMINAR ON INNOVATIONS AND
                ENTREPRENEURSHIP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6f78b80c5172f3413b2506c386408a0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H.RAISONI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Interactive session on “Hang out with
                Successful Start-ups”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Continuity and Operational
                Resilience.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid 2019 Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Automation and PLC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNSMELA 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of IT technology in post Covid19 world
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Demonstration of the Virtual
                University Platform offered by LetzConnec Technologies Pvt. Ltd., Chennai.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advances in IT Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Distributing Relief Products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference On Computing,
                Communication And Control 20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCB Design and PLC programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry visit to Kolaghat Thermal Power
                Station (KTPS)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44f1d53d22443a9c8148e9b823df4694.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AAA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to plan for Start-up and legal and
                ethical steps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on “Future Trends
                and Technological Advancement in Engineering-2020” (ICFTTAE-2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44f1d53d22443a9c8148e9b823df4694.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AAA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring Session on IPR and IP Management
                for Innovation and start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ecstasy 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Project Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill development - Socio- Emotional
                intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation - E - content creation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation - career opportunity in AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f5bf254131b7a46b2466e63ea9a460c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAGHAVENDRA INSTITUTE OF PHARMACEUATICAL EDUCATION & RESEARCH (RIPER)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design thinking workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18526f82cdfcbdd79e98193173f11455.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS DWARKADAS J. SANGHVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guesstimation and Negotiations in Fintech
                Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intra Department Technical
                Symposium-Shukrayaan 2k20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One-Week National Level e-Faculty Development
                Programme\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TechFair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD Leadership Talk with Dr. Abhay Jere,
                Chief Innovation Officer, Ministry of HRD, Govt. of India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Competition Round-II (A Business Plan
                Competition)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Workshop on Intellectual
                Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID HACKATHON’2020 by SSIC, Anand Pharmacy
                College</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EEE dept lockdown webinars </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32511849e978e1d10bca8b0d213273a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mehr Chand Mahajan D.A.V. College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Business Model Canvas (BMC) and
                Business Plan Competition to Invite Innovative Business Models from Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FUTURE SKILLS FOR EMPLOYMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HANDS ON TRAINING ON UV-SPECTROPHOTOMETER
                -1900</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Societal Changes Post Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STATUTORY REQUIREMENTS FOR LABELLING OF
                PHARMACEUTICAL PRODUCTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdadbca85a616af4431daabdb01acfb0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAREER POINT UNIVERSITY, HAMIRPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdadbca85a616af4431daabdb01acfb0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAREER POINT UNIVERSITY, HAMIRPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRY EXPERT LECTURE on \&#34;ELEVATOR
                INDUSTRY POST COVID-19\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Quiz on Research Paper Writing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop attended on \&#39;Data Science using
                R Foundation\&#39; by 360 DigiTMG, Hyderabad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e04717f985f6bb46dc7f44390e549e27.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DPG Institute of Technology and Management">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Competition on \&#39;Effects of
                Covid-19 in India\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e04717f985f6bb46dc7f44390e549e27.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DPG Institute of Technology and Management">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Competition on \&#39;Effects of
                Covid-19 in India\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Effective Presentation Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRY EXPERT LECTURE on \&#34;CAREER
                OPPORTUNITY IN THE INDUSTRY ON DESIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdded087b6aa9a5752a63ad67ac66e7e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING MANAV RACHNA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Computational Electromagnetic Techniques for
                RF and Microwave Applications”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d1c9971d20cfccfc5b8dbcb2089c493.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Trinity Academy of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Quiz Activity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on impact of covid-19 on employment
                and how should students prepare for it and oracle block chain platform and applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on HEAT TRANSFER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Hands - On Training cum Workshop on
                AutoCAD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Design Thinking\&#34;on </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on FINITE ELEMENT ANALYSIS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on \&#34;Matlab Onramp\&#34; with
                Mr.Immanual R, Assistant Professor , SRIT by RVS TECHNICAL CAMPUS-COIMBATORE </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on \&#34;OPPORTUNITIES IN JAPAN!
                HOW INDIANS CAN BENEFIT FROM IT?\&#34; by Mr. VASUDEVAN DASUN JAGADEESAN, SAP ABAP Consultant Mitsubishi
                Electric, Japan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E Summit, \&#34;TEJAS 2020\&#34; on topic
                \&#34;Leading an Entrepreneurial Way</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Derivation of Empirical Formulas for Design
                procedure of usual machine components</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON “UNLOCKING THE FUTURE POTENTIAL IN
                ENGINEERS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Artificial Intelligence and Image
                Processing using MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5edefd40a4b5d3c3e4c6d541e68f9d57.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Ayyappa College for Women, Ayyappanagar, Chunkankadai - 629 807, Nagercoil - 629 807">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->national level quiz on Entrepreneurial
                guidance for economics graduates</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Data Analytics for
                Electronics Engineers using python </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Designing an Automotive Control
                System using Model – Based Design </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Electrical, Electronics &
                Communication Engineering in Automotive Industry- Current Trends& Higher Studies Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Edge Computer Version System with
                Nvidia Jetson Platform</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Analyze Data Using Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on How to become a Market ready
                Graduate post COVID-19 Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industrial Applications of
                Electric Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Edge Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on HUMAN RESOURCES 2.0 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Augmented Reality & Virtual</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Role of Artificial intelligence in
                Marking Health Care System Smarter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Electric Vehicle Modeling and
                Simulation Using MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Roadmap for entrepreneurial journey</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation 2020-Creating Vision</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sprint for success with E-Cell and IIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AAGAAZ-An orientation to be a part of change
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Episode-4:Srijan-Where Aspirations Take
                Flight with Mr.Aalekh Oza</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Angel Investment opportunity for
                early Stage entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session On Accelerators and Incubation
                opportunity for student entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert lecture on Machine Learning
                Application in Design & Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f2b86860b6980b765d3db1e7a5f273f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Geetanjali Institute of Technical Studies,Udaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Ambassador trainining
                Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f2b86860b6980b765d3db1e7a5f273f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Geetanjali Institute of Technical Studies,Udaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Change of perspective Pre & Post Covid -19
                with the new normal thought\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f2b86860b6980b765d3db1e7a5f273f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Geetanjali Institute of Technical Studies,Udaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career opportunities after Covid 19 & how to
                prepare yourself to take advantage\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PoC/Idea</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Quiz on Matlab Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Journey from Idea to Patent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Steps to startup Success</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Quiz on Internet on Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Building Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MOCKSTOCK MARKET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Skill Development Program (SDP) on
                Digital System Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Seeing the market beyond the
                borders</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LIVE Webinar on Artificial Intelligence and
                Machine Learning with emphasis on Neural Network modeling and tuning. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WATERSHED MANAGEMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6debb4348fb0f50adbc81979e59b516.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIYADARSHINI J. L. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6debb4348fb0f50adbc81979e59b516.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIYADARSHINI J. L. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Poster Presentation Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-QUIZ ON PROJECT MANAGEMENT FOR INNOVATION
                AMBASSADOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE WORKSHOP ON BASIC AUTOMATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Full Moon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Geometric Design of
                Highways</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT TO SEWAGE TREATMENT PLANT
                KABITKHEDI, INDORE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learn the Fundamentals of Entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Review of BIG DATA ANALYTICS (BDA)
                CENTER OF EXCELLENCE Projects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Government Schemes to Promote
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Review of ARTIFICIAL INTELLIGENCE
                (AI) CENTER OF EXCELLENCE Projects </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Entrepreneurship Opportunities in
                Automobile Sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Robotics and Automation-What’s in
                for you!!</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on ROADMAP TO SUCCESS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Vertical formwork design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Joy of Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Fresh Graduates Industry
                Requirements</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Productive Tools for Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Life in PSU – How to crack GATE &
                ESE with Self Study</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on How to fight this COVID using 3D
                Printing Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Insight of Oracle Agile PLM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Circuit Theory applications
                in Power Electronics by Electrical dept.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f2b86860b6980b765d3db1e7a5f273f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Geetanjali Institute of Technical Studies,Udaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship program on e -mobility</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Finite Element Methods and its
                Applications in Mechanical Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on PLM- The Solution of Confidential
                Data Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program by EE Dept. on
                Energy Management and Audit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPERT LECTURE on Renewable Energy Conversion
                by Electrical Engg. Department</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Web Designing”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Writing an Effective Research Paper – Means
                and Methods”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Trends in Artificial Intelligence: A Modern
                Perspective”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Cyber Security & Malware Analysis”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7682ef75b7383fc2f85b89bdb4bee8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANDIAN SARASWATHI YADAV ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Stay Healthy Stay Positive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Good to Great”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Quiz on Web development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Emerging Research Issues in Software and IT
                Sector”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Python Programming”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drawing contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Introduction to Data Analytics and Tools”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Opportunities Arising Due to
                COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Ensemble Learning in Machine Learning”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Reflections on Globlization</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online quiz on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e466e07e48c245acf3d190edd6941ad8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Post Graduate Government College for Girls Sector-11">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Two Day Online Program on Entrepreneurship
                Development.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Projects to Patent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovating and Identifying Right
                Oppurtunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/794b3e2a5ae57333735afa17f79a24ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GND University College, Near Govt. College of Edu., Ladowali Rd.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Money Matters with Shradha Sharma | Ep 17 |
                William Bissell - Chairman | FabIndia</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series - Season 3 -
                Talk 8</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series - Season 3 -
                Talk 7</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Supply Chain Risk Management: An
                Industry Perspective </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Friday Forenoon with Entrepreneur’ series
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series - Season 3 -
                Talk 6</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Quality and Reliability
                Improvement in New Product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Journey Post Covid 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series - Season 3 -
                Talk 5</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Increase your KASH for a better
                tomorrow</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series - Season 3 -
                Talk 4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Novel way to solve the Covid19
                Dataset using Machine Learning Algorithm</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series - Season 3 -
                Talk 3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series - Season 3 -
                Talk 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series - Season 3 -
                Talk 1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dishonest Practices in Engineering Research –
                Causes and Consequences</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on preparing competitive Research
                Proposal for funding.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career after Degree/Diploma in Civil
                Engineering\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Gap Analysis & Entrepreneurship
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Aspects of Neutral Networks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk Session- Future in this Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Driven  Systems - ​Need of 
                Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/908f94593b09f50b203c5df25a2c4103.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sagar Institute of Research & Technology,     Opp. Sagar Estates, Ayodhya Nagar, By Pass Road, Bhopa">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Identification of Neurological Disorders
                using EEG Signals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy storage and Efficiency- The Theory of
                the Ragone Representation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Development of Electric Vehicle </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Statistical Process Control & Measurement
                System Analysis </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Trends & Opportunities for Mechanical
                Engineers </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/735dbd6e041c143ae169961c6d203276.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY (MCA INSTITUTE)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IMPACT OF COVID 19 ON START-UPS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Free Summer online course \&#34; Script
                Writing\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Quiz on Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->hackathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on Android and Website
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid-19 Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/988a718d24fd0c21a1c6f5bd324338e1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.P.C. Mahalaxmi College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideate 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Essential Business skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on digital transformation in Post
                covid 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MCA Revised Accreditation framework (RAF) of
                NAAC : An awareness webinar for faculty members of Higher Education Institutions.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MCA Live Webinar Series 2 on How Artificial
                Intelligence is Changing everything</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on WHO-Hand Sanitizer Preparation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MCA Live Webinar Series 2 \&#34;Get Ready for
                the career race\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MCA Live Webinar Series 2 The Future Cloud
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LATEST TECHNOLOGY TRENDS “ LEARN@SITRAIN”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c1a0665aa9173e2e93438a071fe9ea94.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurhsip Conclave 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Challenges in Medicine”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Economic Impact of COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->6 Webinars on Knowledge and Skill Development
                during lockdown of Covid 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Of COVID 19 On Logistics Skilling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Investment Pattern and Opportunity before and
                after COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Quiz On Retailing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Celebrations at BNMIT on
                28th February 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on National Innovation Strategies &
                Policy 2019 released by MHRD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Civil Engineering department organized a
                webinar on \&#34;Chase one Rabbit\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Civil Engineering department organized a
                webinar on \&#34;Importance of detailing in structural design\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Working Smart with Revit
                Architecture\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MOCK INTERVIEWS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on \&#34;Performance Based Concrete
                Flooring for Industrial and Warehouse Applications”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->JEOPARDY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Powerful Leadership: Webinars at MBA
                Department, BNMIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Manage to Sell</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 Relief operations by BNM Institute
                of Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->face shield</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Funding ABC’s for Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Higher studies opportunities in
                abroad </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Failure analysis on Solid State
                Drives</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Latest Generation of Welding
                Process/Technologies and Career Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, Incubation and Ideation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/708c1345abd2327de1cc54d72f393bb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DON BOSCO COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cybersafety -- Be Smart to Smart Devices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of Startups and role of msme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Awakening the Engineer within you
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Software Quality Assurance and
                Project Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/708c1345abd2327de1cc54d72f393bb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DON BOSCO COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Surmounting the Cybersecurity Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Simulation and Modelling
                software\&#39;s </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Significance of IP, Design and innovation and
                Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industry 4.0 for Automotive Plant
                Maintenance </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Dance Platform: Dance to Heal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Innovative Masks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Fair Use of copyright in
                classrooms</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lock down Redesigns Campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Welding for Automobile Application
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on current manufacturing technologies
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fundamentals of Share Trading </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotics for Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Live Q & A Session on product development in
                new world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Talent Enhancement Program-I</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Formulation of sanitizer and making mask</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Students hacakathon 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Article writing competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essay competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ganson\&#39;s MOU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea, Entrepreneurship Opportunities,
                Business Model and Business Plan IIC Calendar activity Speaker Dr. Amit Dwivedi | Dr S. R. Acharya |
                Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property (IP) Management at
                Early Stage of Innovation and Start-ups IIC Calendar activity Speaker Dr Sanjeeva Kumar Majumdar | IPR
                and Technlogy Transfer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Ways of Learning by
                e-FDP-“Teaching in uncertain Times, opportunities & Challenges”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal and Ethical Steps - Productive
                Entrepreneurship and Startup IIC Calendar Activity Speaker Mr. Harit Mohan | IPR and Technlogy Transfer
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Faculty Training on Artificial
                Intelligence and Machine Learning Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship skills using Decoupage and 3
                D bottle Art</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f20b406f45ccdda51461fe288c0b4f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NESAMONY MEMORIAL CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f20b406f45ccdda51461fe288c0b4f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NESAMONY MEMORIAL CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days webinar on Research Paper, Project
                Proposal and Patent Writing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Business Model Canvas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Basics of Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e04717f985f6bb46dc7f44390e549e27.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DPG Institute of Technology and Management">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Webinars for educators and Students powered
                by Adobe Spark</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fluid-Structure Interactions from Convertible
                Car Roofs to Hydrofoils</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Distinguished Foreign Lecture_* on Topic* :
                \&#34; *Condition Monitoring of Machinery\&#34;*</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIRESIDE CHAT-During this pandemic, remember
                to take these precautions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Business Model Canvas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Career in Defence and Paramilitary Forces
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Session on Angel Investment Funding
                Opportunities for Early Stage Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7c78b0b0b65e6a6a84f48d85aac3f4c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY GOA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2 day Workshop and Talk with Succesful
                Entrepreuner </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A new normal to manufacturing sectors post
                COVID 19 and plans for Economic Recovery</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guest Lecture on Emplobility skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Work Shop on PLC & SCADA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3 day online training on IPR and Patent
                facilitation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guest Lecture on Widegap Band Power
                Electronic Devices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Making Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Field Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Work Shop on Internet of Things(IoT) by
                APSSDC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67056eb08ab3a825e3a2d54fa41e2bb1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. RAMAKRISHNAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> \&#34;Recent Trends in Automation Testing in
                Information Technology\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Seminar on career options after Graduation
                in Association with VOLTA and IOE on 05-12-2019 by Mr. A. Srinivasa Babu Manager Business development,
                TIME INSTITUTE, HYDERABAD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Dr Narla Tata Rao Thermal
                Power Plant or Vijayawada Thermal Power Plant Vijayawada, Andhra Pradesh on 4th January,2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Field Visit to WAGON WORKSHOP on 17th
                February, 2020 at Vijayawada, Andhra Pradesh</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->G Economy - A Future Job</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD\&#39;s Innovation Cell - Online Session
                14</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guest Lecture on Research Opportunities in
                Electrical Engineering in Association with VOLTA and IOE on 26-12-2019 by Dr.M.Amarendra</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Five days Skill development Training on PLC
                Programming and SCADA </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Three day Workshop on Simulation of
                Electrical systems using MATLAB and PROTEUS from 27 to 29th January 2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in New Product Development (
                Project Expo)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Stress Management”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ALUMNI TALK-JOURNEY FROM A COMPUTER SCIENCE
                ENGINEER & A DIGITAL MARKETER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The department of CSE conducted the seminar
                on Ethical Hacking on 30th January 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A webinar on \&#34;Behavior of Concrete
                Structures subjected to Fire Exposure” \&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week FDP on Python 3.4.3 in association
                with spoken tutorial IIT Bombay</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IP Strategy and Patent Protection</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FE simulation of laser welding process</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/55f49c678ea15f632e678f30a86469f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIMS ENGINEERING MANAGEMENT AND TECHINICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Telecommunication and Information
                Society Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Paper Writing for Scientific
                Journals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Webinar on \&#34;Artificial Aggregate and
                Its Behavioral Aspects in Structures\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/55f49c678ea15f632e678f30a86469f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIMS ENGINEERING MANAGEMENT AND TECHINICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on :”Innovation and Startup”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Structural Design
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9040fa558a4adcd29f48333f4d5daee7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELS INSTITUTE OF SCIENCE TECHNOLOGY AND ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Mathematical software for personalized
                learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9040fa558a4adcd29f48333f4d5daee7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELS INSTITUTE OF SCIENCE TECHNOLOGY AND ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Renaissance Management symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b6e95487aaebbd9fc07990774f576f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University, Gwalior">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Development of micro-sensors and their
                applications using artificial intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b6e95487aaebbd9fc07990774f576f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University, Gwalior">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Planning, Startup Life and Data
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Panel Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Projects & Ideas Presentation for IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/55f49c678ea15f632e678f30a86469f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIMS ENGINEERING MANAGEMENT AND TECHINICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “ How start ups’ will gear up Post
                Covid 19”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Management Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b6e95487aaebbd9fc07990774f576f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University, Gwalior">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learn to develop Android App</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Motivational Campaign for
                Prospective Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9f395669f18764fc489133a5def7ef00.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mula Education Society\'s Arts, Commerce and Science College, Sonai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->i am entrepreuneur , new entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9040fa558a4adcd29f48333f4d5daee7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELS INSTITUTE OF SCIENCE TECHNOLOGY AND ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Management and Cost management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9040fa558a4adcd29f48333f4d5daee7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELS INSTITUTE OF SCIENCE TECHNOLOGY AND ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Roboic process and automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/99e0f20f270775c14bc59fb327c176e9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S R ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Premiere League Pitching</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9040fa558a4adcd29f48333f4d5daee7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELS INSTITUTE OF SCIENCE TECHNOLOGY AND ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Crisis of covid 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9040fa558a4adcd29f48333f4d5daee7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELS INSTITUTE OF SCIENCE TECHNOLOGY AND ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of chemistry in covid 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Motivational Campaign for Cluster
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Career Building</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9f395669f18764fc489133a5def7ef00.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mula Education Society\'s Arts, Commerce and Science College, Sonai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate competition on the path breaking
                innovation and scientific achievements from india for humankinds on the occasion of national science day
                2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Process Automation
                &Instrumentation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Success Story of Chai Sutta Bar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industrial Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MEANSTACK Web Development Expert Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart Gujarat for new India Hackathon 2019-20
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Cyber security and Ethical hacking
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart City Hackathon 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar on COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Catalytic C-H bond functionalization a new
                way to make new Phosphines and Pyridines</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->JLU Pinning Ceremony</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in New Product Development (Mini
                Project Expo)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2d48843937ee6662a68df4f09a90ecb7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.N. COLLEGE OF ENGINEERING & TECHNOLOGY(BNCET)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Webinar on \&#34;Developing Winning
                Mindset during COVID19 Lockdown.\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The National Round of Louis M. Brown and
                Forrest S. Mosten International Client Consultation Competition 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2d48843937ee6662a68df4f09a90ecb7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.N. COLLEGE OF ENGINEERING & TECHNOLOGY(BNCET)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Assessment of Corona Pandemic: Preventive
                measures from COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Aerospace Laboratories (NAL)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. B. Karthik, Alumni of
                2012, Ethical Hacker, Security Researcher, Security Engineer, Security Consultant</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT TO AKSHAYA PATRA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Day Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Hands-on Session on IoT using Raspberry Pi
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. Karthik Mekole, Alumni
                of 2012, APP System Engineer Level 4 at Wells Fargo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on HIGH PERFORMANCE COMPUTING, GRID AND
                CLOUD COMPUTING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. G. Satya Sheshadri,
                Alumni of 2010, Resource Manager at Deloitte</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture and Hands on Session on
                Android Application Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Samvad 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. Ujwal Kavalipati,
                Alumni of 2018, Pursuing Masters of Data Science in Monash University, Melbourne</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Earth Day 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on INTRODUCTION TO ETHICAL HACKING
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. S. Murali Karthik,
                Alumni of 2009, Inventory Analyst at Sears Holdings India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. Sreekar Reddy
                Sudireddy, Alumni of 2015, Software Engineer at GGK Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on PCB design using eaglecad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. Kayala Ashok Kumar,
                Alumni of 2016, Software Engineer at JC PENNY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BBA/BBH Orientation Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. Santosh Kumar, Alumni
                of 2009, Software Engineer & Research Scholar at VCU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Speak - To be Share by
                Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. VSL Narasimha Rao
                Sharma, Alumni of 2011, Technical Manager at Hexagon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Mr. Kameshwar Rao Nukula,
                Alumni Of 2012, Technical Manager at Hexagon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOTshow.in, KTPO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->New Vistas in Botany</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Voice of Alumni by Ms. Sapna, Alumni of 2011,
                Lieutenant Commander (Indian Navy)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Software Quality Assurance and
                Project Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/908f94593b09f50b203c5df25a2c4103.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sagar Institute of Research & Technology,     Opp. Sagar Estates, Ayodhya Nagar, By Pass Road, Bhopa">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SOLAR ENERGY: THE NEED OF FUTURE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAREER IN MANAGEMENT SECTOR by Mr. V. AMAR,
                The Hindu BL Future India Club on 27th JANUARY 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Workshop-PART-II</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day event on problem solving techniques
                by young successful entreprenueaur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPERT TALK ON CAREER GUIDANCE by Mr.
                Jayaprasad.Vejendla , CEO Forsys Inc, Information Technology & Services , Milpitas, California on 27th
                January, 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bounce Back : Motivational Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON IP AND INNOVATION MANAGEMENT FOR
                STARTUPS & MSMEs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Anveshan Research Convention</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9040fa558a4adcd29f48333f4d5daee7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELS INSTITUTE OF SCIENCE TECHNOLOGY AND ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP- Social Skills and Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filmmaking, Animation & VFX programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TALK ON Etiquettes- workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of National Science Day 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A session on Intellectual Property Rights by
                Prof.Jayant Gidwani</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to Trident industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Software Quality Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to Dainik Jagran</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Post budget analysis 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solar Photovoltaic Technology; recent
                developments and emerging scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start-up Bootcamp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unlocking future-ready potential</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on How to set goals and archive
                career opportunities in Dream Company</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities For Higher Studies & Innovation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Experts Technical Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mobile application development using Android
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2nd SAARCLAW-JLU Moot Court Competition 2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AN EXCLUSIVE SESSION ON “UNLOCK BIG DATA WITH
                VERTICA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Essential Skill Requirements for
                Service Based Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/545eb17ed50c572b6964681243b2e433.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. B.R. AMBEDKAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop on “Image Processing ,
                Computer Vision, ML, Deep Learning and IoT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HP Centre of Excellence on Big Data and Its
                Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CYBER SECURITY AWARENESS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Introducing Splunk, Big
                Data Engine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar conducted on Learning Styles-it is
                the time to change</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Food Safety and Quality Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Write a Great Research Paper, and Get
                it Accepted by a Good Journal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 Mind support (Online
                Tele-counseling)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Yukthi V5.0 Organized by department of MCA
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar On New Perspectives on Online
                Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intercollege Techinical Symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities and Challenges of Franchise
                Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan - Intercollege Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Entrepreneurship Bazar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ALUMNI TALKs-sharing of knowledge super minds
                in Cloud Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dept of CSE Webinar on Motivation for
                Preparation and Learning Through Computers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Post COVID-19: Mitigation Strategies for
                Challenges of Online Education in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DEPARTMENT OF COMPUTER SCIENCE &ENGINEERING
                WEBINAR ON \&#39;PYTHON PROGRAMMING\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DEPARTMENT OF COMPUTER SCIENCE &ENGINEERING
                WEBINAR ON \&#39;C\&#39; THE EVERGREEN LANGUAGE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ALUMNI TALK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Impressions of Covid 19: from
                layman to Businessman</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Basics of Design Rights - IPR and Patent
                System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR & Patent Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal and Ethical Steps – Productive
                Entrepreneurship and StartUp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Covid 19 Pneumatics for critical
                Health Care</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dream@JISCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Integrated Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5edefd40a4b5d3c3e4c6d541e68f9d57.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Ayyappa College for Women, Ayyappanagar, Chunkankadai - 629 807, Nagercoil - 629 807">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National level Quiz on social Media
                Networking tools for Educationn</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5edefd40a4b5d3c3e4c6d541e68f9d57.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Ayyappa College for Women, Ayyappanagar, Chunkankadai - 629 807, Nagercoil - 629 807">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->nationa level awareness quin on design
                thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/589da4bfeff70d7d2fc3cac2069c676a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDRAPRASTHA INSTITUTE OF INFORMATION TECHNOLOGY -DELHI (IIIT-D)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Knowledge Unboxed</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5edefd40a4b5d3c3e4c6d541e68f9d57.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Ayyappa College for Women, Ayyappanagar, Chunkankadai - 629 807, Nagercoil - 629 807">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP QUIZ NATIONAL LEVEL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5edefd40a4b5d3c3e4c6d541e68f9d57.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Ayyappa College for Women, Ayyappanagar, Chunkankadai - 629 807, Nagercoil - 629 807">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->national level IPR Week Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation Session on Entrepreneurship
                Cell(E-Cell) and Institution Innovation Council(IIC)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/98d72e7999ba5bd6a36bfbe38b8de1ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA INSTITUTE OF INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 Awareness Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on COVID19: Lets break the myth with
                the analysis of a virologist </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Igniting Minds Lecture Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Journalism and Communication workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c5a003239446424d554cf8202d008753.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SOLAMALAI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #10 - \&#34;From
                Startup To Scale Up\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on title\&#34; Mathematical Modeling and
                its application in different field of study”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #9 -
                \&#34;Effective Ways Of Doing Projects\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk \&#34;Industry Expectations from
                a Professional\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Igniting Minds Lecture Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEDx JLU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3rd JLU international Festival of Media</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Right</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbac62ecc6ea91f2162948811b865aa6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AL SHIFA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Yoga Day - Awareness cum Wworkshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Start-up (Entrepreneur in
                Campus)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Workshop on Design Thinking
                for Management Professional.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entreprenuership</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International entreprenuership interaction
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International entreprenuership interaction
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/338e39a9bd4d2bebef01ce93a28d79c2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="H.V.P.MANDALS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Winter Internship 2019 and Hack-A-Thon 2019
                Session - 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Graduate Preimer League</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ECFICIO 3.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Model Canvas & Business Modelling
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop for Incubators “Ideas for New India”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Coaches of TiEGrad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2nd National Conference on Prosecution</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6a7d9f53dbe78a490bf3bb854b178ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VNR VIGNANA JYOTHI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea coaching</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Facebook Session on NEAT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E SUMMIT LEADING THE ENTREPRENEURIAL ACTIVITY
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathons</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Reality</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of IPR for the Management and
                Engineering students in the WTO Era</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Trip</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coding Competition \&#34;CODIGO 2K20\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women\&#39;s Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Artificial Intelligence ”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Collegedunia Connect</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid-19:Way forward for E-Commerce by
                Tanutejas Saraswat, Co-Founder, ShopKirana.com</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Managing resources for startups by
                Ms. Nehal Thakker, Co-Founder, SmartMoves</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Careers in the 21st Century</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Idea Generation and its techniques
                by Mr. Swapnil Garg, Asst Manager, DTIC,MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities for students in digital
                marketing field by Mr. Kunal Soni, Founder, Career Management Institute</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COGNOBLAZE’ 20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of Market Research and
                understanding Customer in entrepreneurship by Mr. Bobby Singh Parihar, Proprietor ,Sachidanand
                Enterprise</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Pharmacy Practice Services in Scandinavian
                Countries/Denmark”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My story Session by entrepreneur- Ms. Ruchi
                Singh, Founder, Rooh Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on - Schemes of MSME for student
                entrepreneurs and Startups by Mr. Nilesh Trivedi, Asst. Director, MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “Exploring Higher Education
                and Research Opportunities in USA”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Generic Pharmaceutical Product Development-
                A Bird’s Eye View”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on theme “Nano materials : Prospects
                and Application for Green Energy Harvesting ”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Five Days Faculty Development Program on
                \&#34;eSim” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Episode-3:Srijan-Where Aspirations Take
                Flight with Mr. Shreyas Padhye</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Episode-2:Srijan-Where Aspirations Take
                Flight with Ms. Devika Dubey, Founder, Purple Omre Arts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Episode-1:Srijan-Where Aspirations Take
                Flight with Ms. Shivani Bhasin</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Job Opportunities in Post -
                Pandemic World\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid-19 Awareness Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Online Faculty Development Program
                on SCILAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guest Lecture on Recent Advances in
                Composite Materials on 31st Jan, 2020 by Dr.G.Raghavendra, NIT, Warangal, Telangana</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2. “Mock Parliament “ and celebration of
                International IPR day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Two Days Works Shop on Heat Transfer
                Problems using ANSYS Fluent on 31st Jan,2002 to 1st Feb, 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A 2-Days National Conference on Current
                Trends in Advanced Manufacturing and Energy Systems on 20th and 21st March, 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IoT in Non-Automotive Industries
                with Embedded Linux </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Deep Learning in Agriculture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Managers for the new normal, Management E
                Conclave. \&#34;The virtual world\&#34; The new reality</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Careers in the 21st Century</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Managers for the new normal, Management
                E-CTrends for education and businessonclave. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Advanced Industrial Automation
                Absorbed by IT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IoT based Vehicle Vibration
                Prediction using RNN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Managers for the new normal Management
                E-Conclave. First session is on managing Blended Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on DevOps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67056eb08ab3a825e3a2d54fa41e2bb1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. RAMAKRISHNAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar on Smart Grid and
                Distributed Energy System </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/794e5e26e9a5a57668e78d5cd307410c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Providence College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lockdown Innovative Competitions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f759188888ac466518c7d4a7986ea25b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Lovely Professional University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indian Startup Summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Upscale Your Design Skills with
                New AI Features</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL EQuiz on Biodiversity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL ONLINE QUIZ On Intellectual
                Property Rights.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL EQuiz on COVID-19 awareness
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL ONLINE BIOQUIZ ON COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An insight into Novel Corona CoViD19 and its
                importance in testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on indoor environmental pollution
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Deployment of Telehealth: A
                Perspective of Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Quantum Computing </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67056eb08ab3a825e3a2d54fa41e2bb1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. RAMAKRISHNAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design and Development of Home
                Theater</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->C++ Programming Classes </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cloud computing challenges in General
                Perspective</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Securely Connect and Scale Remote
                Workforces</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Science with Matlab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> NATIONAL LEVEL ONLINE RESEARCH APTITUDE QUIZ
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2D Design By AUTOCAD Online Classes for ME
                students.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCB Design, PCB Fabrication and Circuit
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Merchandising in Apparel Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Big Data & Data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advantages of Python Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Advanced Java Framework</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Tabs Software Training </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbac62ecc6ea91f2162948811b865aa6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AL SHIFA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL SAFETY WEEK, 4th – 10th March 2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on Importance of Communication
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on spoken English and
                confidence building.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on topic \&#34;Overview of
                Machine Learning, Deep Learning, Data Science and Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->12 Days Training Program on Auto CAD </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Story telling Competition.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Water Treatment Plant Visit
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;SAVE TREE-- SAVE LIFE\&#34; Campaign
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Personality Development by
                Dr. Deepa Vanjani</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Contest for Technologies relevant in the
                COVID-19 scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->YOUR ASPIRATIONS MY EXPERIECE\&#34; - Alumini
                Interaction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CR-E-ATIVE IDEA COMPETITION to combat CORONA
                DISEASE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Annual presentation in ARDOR, an annual fest
                on innovative steps taken by Acropolis Group </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->entrepreneur talk- Change of the world order
                post COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Breaking system in Automobiles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Initiative for Development of Home-made
                3-layered masks for Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Importance of Tolerancing in
                Engineering Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Project Poster Presentation
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Docker,Git, Jenkins and Maven for
                testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on DESIGN AND MANUFACTURING
                TECHNOLOGY IN INDUSTRIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on SAP Tools for Data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Project Compitition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IOT - Idea to Products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on topic \&#34;Instrumentation &
                Applications of IR & UV Spectroscopy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Latex </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Making of UV Sanitizer Box for disinfecting
                office files</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture on Non verbal Communication by Dr.
                Garima Sanjay Dubey</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on 3D PRINTING & ADDITIVE
                MANUFACTURING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mother\&#39;s Day celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Classes on AutoCad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CORONA QUIZ ON LET US BEAT CORONA QUIZ</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FACULTY DEVELOPMENT PROGRAMME On TALLY WITH
                GST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“LEARN TO EARN” HOE TO BE SUCCESSFUL TRADER
                IN FINANCIAL MARKET.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DIGITAL MARKETING STRATEGIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Level – Online Quiz for
                Commerce Expert</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Five Days Workshop on Auto CAD\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Game changer of life(Time Management)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Development Program on Career
                Guidance </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on English Intelligence for the
                future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pursuing Academic Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Insurance Sector in COVID Pandemic
                Situation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON CURRENT SCENARIO IN e– COMMERCE
                BUSINESS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Realization of Wired and Wireless
                Networks in Network Simulator-2\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->C Programming Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to 5G and its applications </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual inauguration of rathinam centre of
                excellence for Artificial Intelligence and Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Faculty Development Programme (FDP)
                on “Block Chain Technology”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual inauguration of rathinam centre for
                robotics and automation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Is Code Crack</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online quiz on basic of civil engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Network Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c4c57f9bd770f640fa88f772281216e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dahiwadi college Dahiwadi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Webinar on “Digital Circuit Design
                Using Scilab”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on MS PROJECT Software in
                construction field</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on Internet of Things </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PROJECT EXHIBITION 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MINI PROJECT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Impact of Seismic Code Provisions
                on Seismic Design of Steel Buildings</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Earthquake Hazard Assessment - Are
                Design PGAs and Design PSA given in IS 1893 (Part1): 2016 Reasonable?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Impact of Seismic Code Provisions
                on Seismic Design And Detailing of RC Buildings</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Rationale behind Provisions of
                Indian Seismic Codes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2ND International Conference on Computational
                Science and Technology(ICCST2020)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Overview of Indian Seismic Codes
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Horizontal Formwork Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to formwork & Site
                logistics Date: 26.05.2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on General Industry Expectations and
                Effects of Covid-19and </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Efficiency Enhancement in Thermal
                Power Plant</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Bridging the Skills Gap –
                Reimagining Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industrial Oriented Product Design
                and Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Full stack Web Development using
                Angular</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future Avenues by Alumni Entreprenure</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days FDP on Role of IoT in Renewable
                energy sources</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CLOUD COMPUTING AND IT’S APPLICATIONS IN REAL
                WORLD.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6bf44e54057cc01943b3c8a715fa4ed.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSIT College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CoroQuiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Behind the screen warriors: Indian scientists
                and COVID 19 Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Thermal Spray Coating</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day seminar on Advanced Welding
                Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #8 - \&#34;Public
                Speaking\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #7 - \&#34;Future
                of Engineering in Agriculture & Food Industry\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #6 -
                \&#34;Effective Ways of Time Management\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #4 - \&#34;Live
                To Design\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8004293bc33463623bda808ada07ba1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH HIGH TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future Automotive Industry and Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Workshop on \&#34;Lean canvas\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STC on Nanomaterials and Devices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8004293bc33463623bda808ada07ba1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH HIGH TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Managing your finances and investments during
                COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Magazine Display</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STC on Climate Change, Disaster and Pandemic
                Management: Technological Interventions for Sustainable Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“THE RIGHT FIT- A ROADMAP TO COVID-19
                RECOVERY & BEYOND\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sample prep for Food Matrix</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python Programming- A Beginners Perspective
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RESEARCH & DEVELOPMENT - AN INDUSTRY
                PERSPECTIVE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Science and Artificial
                Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fight Covid-19 using Robotics & IOT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Activity session on Business Canvas Model</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AN OVERVIEW OF CLINICAL TRIALS AND ITS
                CURRENT REGULATIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on \&#34;How to Plan Start-up
                with Ethical Steps\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“CAN WE REVERSE DIABETES?” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Blockchain Technology 101</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz on Basic Electrical and Electronics
                Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Industry –
                Identifying Real Life Problems Series: Team 4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Production Planning & Control ”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online short course on PLC Basics and
                Logixpro simulation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;COVID- 19 BASIC VIROLOGY,
                DIAGNOSIS,VACCINES &THERAPEUTIC TARGETS FOR PHARMACIST”.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on “Entrepreneurship and
                Innovation as Career Opportunity”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The added Values of Innovation in
                Advance Materials</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk with Ms. Anu Acharya, Founder
                and CEO - Mapmygenome</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Industry –
                Identifying Real Life Problems Series: Team 6</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Application of Gas Turbine in
                Fighter Aircrafts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDIOMATIC EXPRESSIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR and IP Management for Innovation and
                Start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hour of Code (Coding Contest)Ideation
                Workshop/Campus Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Advanced Data Analytics and
                Business Insights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation & Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E- Workshop on MATLAB. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on *“MEMS System Design ”*</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar on “Smart
                Electrification Strategies for Future Autonomous Modes of E-Transportation”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON FRONT END VLSI DESIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PIEMR Indore Signed MoU with Wadhwani
                Foundation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ESHIP INTERACTIVE SESSION with . Manish
                Shrivastava, CEO & Founder ClecoTech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Quiz Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->POST COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drug Discovery and Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidance and How to Face An Interview
                - Mr.Rahul Teni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/efc38647d7cac55fe72350e3ae08b3cb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="All India Shri Shivaji Memorial Society College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 : Webinar Series on Start up ,
                Innovation , Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ventilator Design Perspectives</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->What next After COVID19 for IT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Skills & Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar by DIRECTOR-TIME Ghaziabad on
                \&#34;Career after Engineering\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Self-development workshop on python
                programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Are we ready for the IT Job? A Deep Dive
                session on where we are and where we want to go..!! - Mr.Manas Shirode </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Session with Mr.Ankit goyal, alumna
                IIT indore (GATE AIR 1,IN 2018 &2014</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Situation Post COVID for Placements - Prof
                Santosh Borade</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Branch of Engineering is no Barrier to your
                Career. - Mr.Avinash Shirode</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPERT LECTURE BY TNP OFFICER,PIEMR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON GRE Preparation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Practical Programming in Machine Learning -
                Prof.Pranoti Kale</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Latest Developments in
                Cryptography</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPERT SESSION with Dr. Gaurav Trivedi from
                IIT Guwahati</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectuals for Creativity (i4c 2020)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR-On Working Remote & Getting Started
                with ZOHO One</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Demystifying Block-chain - Dr.Sonali Patil
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c813d96addf8d16181ccf3e471f5975.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNEL INSTITUTE OF TECHNOLOGY AND DESIGN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webminar on Cloud fundamentals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Ecotoxicology” by Dr. S. Anbumani
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Short Term Training Program (STTP)
                “Internet of Things (IOT)”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Application of genetic and
                genomic tools in crop improvement for abiotic stress tolerance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Summer Internship 2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->De novo engineering of trans-activating
                riboswitch in E. coli and directed evolution with using Phase</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Advances In High Performance Liquid
                Chromatography And Supercritical Fluid chromatography (SFC)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Expert Webinar on “Issues In Deep
                Micron VLSI Design \&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5 Days FDP on ICT Tool for Teaching and
                Learning process</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Python 3.4.3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar - Life Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Understanding Health Communication
                in Emergencies Like Covid - 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recognize and Utilize your Talent-Personality
                Enhancement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3 Days FDP on Leadership Development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal Challenges for Emerging Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Radio Day (Tech-Rangoli Competition) &
                National Women\&#39;s Day (Debate Competition)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live Webinar on Renewable Energy System and
                Sustainability- Education, Research and Outreach (Part III)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level FDP on Artificial Intelligence
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live Webinar on Renewable Energy System and
                Sustainability- Education, Research and Outreach (Part II)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live Webinar on Renewable Energy System and
                Sustainability- Education, Research and Outreach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on INNOVATION in EDUCATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f833d37f61c575ab777d7c31d6209c13.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JB INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Group Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenges for Startups post Pandemic
                Scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Different path to success </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Arduino Simulation Workshop in COE Lab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sirius 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mini Project Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Codeathon 2.O</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Guest Lecture Series 3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Guest Lecture Series 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Guest Lecture Series 1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Academia- Industry Collaboration in Madhya
                Pradesh: New Initiative, Problems, Challenges, Opportunities and Strategies in Pharmaceuticals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“One Day National Symposium on Innovations in
                Radiotracer Techniques: Improve Nutritional Status and Health of Plants & Animals”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Traffic Awareness Program”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Career Opportunities for Civil
                Engineering Graduates & Impact of Covid19 in the Construction Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emotional Intelligence and Its role in Career
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Guidelines to Write a Resaerch
                Article to beginners</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online National Conference on Information
                Technology and Innovation Ecosystem (NCITIE-2020)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CSE Webinar - Meet out Industrial
                Expectations through AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CSE Webinar - Meet out Industrial
                Expectations through AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/338e39a9bd4d2bebef01ce93a28d79c2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="H.V.P.MANDALS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Webinar </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Export Import, Opportunities post COVID-19
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->6th Annual Competition organized by‘IMPULSE’
                an IETE Student Forum</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Initaitive to Combat with COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Incredible Power of an Oppurtunity:
                STARTUP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Two Days Online Short Term Training Program
                on \&#34;PLC & SCADA\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8004293bc33463623bda808ada07ba1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH HIGH TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online quiz on “Knowledge in COVID-19
                Self-awareness and Safety with food diet for pandemic situation”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Session on IPR & IP Management for
                Innovation and Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Venture Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NPTEL SPECIAL LECTURE Series (Animation To
                Exclaim or To Explain)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Ethical Hacking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#39;Artificial
                Intelligence\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Trends in UAV</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideathon on 5G</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#39;Machine Learning using
                Python\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NPTEL SPECIAL LECTURE Series (Career Options
                in Data and Analytics)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NPTEL SPECIAL LECTURE Series (Career Options
                in Data and Analytics)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/24d37fd5353bd7d2a1e8e168dac075d6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.M.S.COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Newsletter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to STS Transformer Pvt. Ltd
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPERT TALK on “Entrepreneur Opportunities
                and Funding Schemes”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Webinar on “Seven habits of Highly Effective
                people – A Way to ATMANIRBHAR Personality”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities lies for start-up during this
                pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Art of Creative Pitching</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creativity Challenge series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunity in Health Care sector during
                COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Placement Oriented Online Quiz Competition
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar of Redhat(Linux)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Cyber Security Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on Introduction to Jenkins
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f20b406f45ccdda51461fe288c0b4f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NESAMONY MEMORIAL CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Survey: Student Prespective</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Debate Competition on \&#34; Pandemics
                are result of human activities: For or Against\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online workshop on Getting started with AI
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online workshop on Data Analysis using Python
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aptitude Training Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Film making Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online workshop on Blockchain</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online workshop on API\&#39;s using Django
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b74fed57208dd3028992e8e8017feb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of IP in Start-up Ecosystem by
                Saurabh Trivedi (Co-founder & Director Boudhik Ventures)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b74fed57208dd3028992e8e8017feb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Pitch Your Idea to Investors by
                Priyanka Madnani (Founder and CEO – Easy to Pitch)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Android Programming using Kotlin “ for smart
                device application</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b74fed57208dd3028992e8e8017feb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities for building startup ecosystem
                in India_ by Dr. Unnat Pandit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk Show on \&#34; Mental wellness for
                college students and staff</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b74fed57208dd3028992e8e8017feb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact of COVID-19 on Tech Enable Startups
                Challenges and Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on Laravel Framework</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on DevOps (Development & Operations)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON \&#34;DATA SCIENCE & CLOUD
                COMPUTING\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/27183d52e27a9d1e7b0aef726e79f800.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF TECHNOLOGY, NIRMA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-HACKATHON-COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/27183d52e27a9d1e7b0aef726e79f800.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF TECHNOLOGY, NIRMA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AD_VER_TISE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f833d37f61c575ab777d7c31d6209c13.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JB INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Road map to get a Patent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6bf44e54057cc01943b3c8a715fa4ed.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSIT College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Beat the Covid-19 Challenge </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6bf44e54057cc01943b3c8a715fa4ed.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSIT College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hack Lockdown 1.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar for Practising Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CSE Webinar - Introduction to Full Stack
                developer - .Net Stack</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session \&#34;Challenges faced by
                MSMEs and the way forward Confirmation\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ARTIFICIAL INTELLIGENCE/MACHINE LEARNING IN
                TELECOM INDUSTRY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on 5G TECHNOLOGY AND CLOUD BASED
                APPLICATIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Robotics Process Automation-RPA
                Overview</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on MISTAKE MAKES YOU - THOUGHT SHAPES
                YOU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CSE Webinar - Robotic Process Automation
                (RPA)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Science for Electronics Engineer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CSE Live Webinar - Scalable Application
                Development for the web 3.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Practical use of Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ECE Webinar on Innovation at Speed with
                Hybrid Cloud</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ECE Webinar on Deep Learning for Computer
                Vision with MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ECE Webinar on Camera and Image sensor
                features you should know Hobbyist</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ECE Webinar on entrepreneurship Oppurtunities
                after Covid 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ECE Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Latest Technology Trends in VLSI Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ICT based learning – Innovations in education
                system</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Experience the art of Production Ready
                Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid 19 Awareness Posters</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid 19 Awareness Jingles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid 19 Awareness Slogans</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d042cb1ee3b96f1498eb7522f48c69dc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIES GRADUATE SCHOOL OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise One day Session on “How to plan for
                Start-up and legal and Ethical Steps”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Internet of Things-Hardware Design
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#39;Building Construction and
                Bye Laws\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d042cb1ee3b96f1498eb7522f48c69dc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIES GRADUATE SCHOOL OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise One Day Awareness/Mentoring Session
                on IPR & IP Management for Innovation and Start-ups or Online Session on Intellectual Property (IP)
                Management at Early Stage of Innovation and Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advances in Automobile Engineering
                Industries: EGR, SCR and Electric Vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Analysis using SPSS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Model Exhibition and Competition
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Arbitration in the era of COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Cultural Fest “LET’S DAN–SING - 2020”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar by Industry Expert on Summer
                Internship Program along with Microsoft Certification </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Time Series Analysis with EVIEWS workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Webinar on “Art of Crafting Your
                Research Idea into Winnable Research Grant Proposal” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d042cb1ee3b96f1498eb7522f48c69dc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIES GRADUATE SCHOOL OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise One/half day Interactive/online
                Session/Mentoring Session “Hangout with Successful Start-ups” (Entrepreneurs in Campus )</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Victimology : The Emerging Jurisprudence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to OMAC Automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One week Faculty Development Programme on
                Insights into Quality Research and Innovation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Internship Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Robotics and IOT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ef96a7c8e8e72b10fa275302550bc7be.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="College of Computing Sciences and Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Novel Approaches on Blockchain Technology &
                its Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Simple Home Made Recipes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/973f573917a12c542bc2ec561567c546.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sarada College for Women(Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz on Start up and Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster competition on World Earth Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Igniting Minds Lecture Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Laboratory Instructions in Testing of Soil
                and Construction Materials</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster/slogan in house competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/973f573917a12c542bc2ec561567c546.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sarada College for Women(Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz on Covid Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Simplified Vehicle Dynamics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Career Domains in the World of Law &
                Public Policy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HOT (Heroes of Tomorrow) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7682ef75b7383fc2f85b89bdb4bee8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANDIAN SARASWATHI YADAV ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days Webinar on Industrial IoT using
                LoRaWAN Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Theme: \&#34;Utilisation of online
                platform in school Teaching\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Justice and Empowerment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f2b86860b6980b765d3db1e7a5f273f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Geetanjali Institute of Technical Studies,Udaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Soft skills - Build wisdom</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Big Data</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on\&#34;Mobile Technology(2G, 3G, 4G)
                \&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alternate Career Path Post COVID19 era for
                Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Guidance for Career Planning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to web application development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6f6eb3fae8cd1dec3defc488d76f3355.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath University ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filing for Patents in India: Issues,
                Challenges and Responsibility</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2a27bbb9c13f5f402f616c9d62ba849e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DIT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Creation and Implementation of IPR
                in Universities </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HackNU 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on CFD and its applications -how to
                develop a successful career in manufacturing industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Science for core Engineering
                students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MCET-VIRTUAL ALUMNI MEET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop- SARS-CoV-2 Diagnosis by Real-Time
                PCR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covit-19 (Corona) Awareness program: Online
                \&#34;Quiz करो ना-An Awareness\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Post Covid-19 - Way Forward</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RE ENGINEERING OURSELF FOR POST COVID 19
                SEASON </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Clarity in Chaos - \&#39;I\&#39; 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Applications of Metallurgical Principles and
                Concepts used in Automotive Component Production Industries\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gearing up for the future Construction
                Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campus to Corporate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Auto CAD and CATIA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IP Awareness Workshop-PART-II</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Upskilling in the times of COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on “Knowledge sharing session ON
                RECENT TRENDS AND ITS APPLICATION IN electronics”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3 Days Webinar on“Advance in CAD and Additive
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Insight on Project Control for Budding
                Graduates</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Desirable skills for future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Oppurtunities to startup Ecosystem in the
                post covid-19 Era</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Summit 2020: Startup Boot Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Submission in Smart India Hackathon 2020
                Internal Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3-D Printer Webinar onTheme\&#34;
                Introduction to 3-D printing/Additive Manufacturing\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Why Failure is a Crucial Ingredient for
                Success</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Internet of Things (IoT)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Journey of ThinkInk Idea to StartUp - A
                Road Map </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/38f3dcb0be87593af442943bd296cf40.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Basics of Ventilator and
                Introduction to High Frequency Ventilation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/38f3dcb0be87593af442943bd296cf40.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Additive Manufacturing in
                Healthcare</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Potential job opportunities in
                Metal Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Online Coding Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learn from failures and bounce back in
                entrepreneurial Journey</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Webinar by Architect on Building Planning
                and role of Architecture in Civil Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bootcamp-Product Over Weekend 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on ” Vehicle/Product Development
                Cycle\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/908f94593b09f50b203c5df25a2c4103.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sagar Institute of Research & Technology,     Opp. Sagar Estates, Ayodhya Nagar, By Pass Road, Bhopa">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber Security law</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA Department Guest lecture on Banking
                Practices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/840782e7ab3bf87a8bff19457fcfc1f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shrimathi Devkunvar Nanalal Bhatt Vaishnav College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Free online workshop on Making Reusable
                Fabric Face Mask - For COVID19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA Department Faculty Development Program
                Draft a winning funding proposal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA Department Guest lecture on Challenges
                and opportunities in Food industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Be an Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interaction with Young Entrepreneurs </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/599e9036f747e19d185ab26a1c1758e1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kashi Naresh Government P. G. College Gyanpur Bhadohi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Problems, prospects and opportunities of
                Indian Youth with special reference to  Post Covid Changes in World Economy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce61529dadd4a5cf67f766fa0e34bfda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kamala Institute of Technology & Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on \&#34;Machine
                Learning : Algorithms, Applications and Advantages\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guidance on active Government funding &
                facilitation to become an successful Entrepreneur and incubatee</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Successful Start-ups \&#34; - By
                Ms.Richa Singh , Co-Founder & CEO , YourDOST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launching a Business Plan and Launching
                Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 AWARENESS PROGRAM- LET’S FIGHT
                CORONA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Electrical Machine Design using
                E-CAD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on the theme \&#39;The Roar of the
                Engines : COVID 19 and Beyond\&#39; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Genetic Algorithm</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Day workshop on Research Methodology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRY MEET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day webinar on DATA SCIENCE AND ML USING
                PYTHON for 3rd year ECE, CS & IT students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day webinar on DevOps for 3rd year ECE,
                CS & IT students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day webinar on Full Stack Web Development
                for 3rd year ECE, CS & IT students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day webinar on Ethical Hacking & Cyber
                Security for 3rd year ECE, CS & IT students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day webinar on DATA SCIENCE AND ML USING
                PYTHON for 2nd year ECE, CS & IT students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day webinar on Ethical Hacking & Cyber
                Security for 2nd year ECE, CS & IT students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day webinar on Full Stack Web Development
                for 2nd year ECE, CS & IT students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day webinar on DevOps for 2nd year ECE,
                CS & IT students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BMC Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Automotive Industry- Future &
                Opportunity”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> “BIOSIMILIARS –CURRENT STATUS AND FUTURE
                PERSPECTIVES”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Automotive Performance
                Development”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/735dbd6e041c143ae169961c6d203276.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY (MCA INSTITUTE)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on ANSYS Mechanical and CFD
                Capabilities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Slogan Writing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Programme on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Paper /Article/ Report writing on COVID-19
                Report writing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International webinar on topic: Qualitative
                research, blogs writing and Neuro science in outbreak of COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Best Practices in Higher Education
                Instituttes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Teach software of Electrical Engg. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAYS WORKSHOP ON DESIGN SKILL DEVELOPMENT
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industry Expectation… HR
                Perspectives – Managing Expectations and Challenges in getting Hired</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Overview on Design and Development
                of Automobile Products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Insight of PLM and Industry 4.0
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital Transformation with PLM
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Overview of PLM
                Customization-Essential for Mechanical Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Future Prospects of PLM in
                Manufacturing Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Hands-on Session on “Refining Your
                Writing Research Paper Workflow with a Collaborative Online Platform: Overleaf and ShareLaTeX”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40d13e359a0d710cfb11750a69a015d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B V V SANGHAS BASAVESHWAR ENGINEERING COLLEGE BAGALKOT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre-Incubation and Incubation facilities in
                BEC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> EFFECTIVE MANAGEMENT SKILLS IMPROVING THE
                QUALITY OF LEADERSHIP </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HOT (Heroes of Tomorrow) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8303b3879aba5ad16c00d1d0d3002239.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Competition 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/735dbd6e041c143ae169961c6d203276.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY (MCA INSTITUTE)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Referencing with Endnote Software
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Protection and Switchgear</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Home Energy Assessment & Audit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PLC’s in the world of Automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual property Rights in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/157d1883ade3ddfeb496125a9e72a808.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF TECHNOLOGY AND SCIENCE, MOHAN NAGAR, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/157d1883ade3ddfeb496125a9e72a808.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF TECHNOLOGY AND SCIENCE, MOHAN NAGAR, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e FDP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Internet of Things (IIoT)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MES Golden Lecture by Dr. Siruguri, Director,
                UGC DAe Mumbai</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty development program on Scilab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/908f94593b09f50b203c5df25a2c4103.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sagar Institute of Research & Technology,     Opp. Sagar Estates, Ayodhya Nagar, By Pass Road, Bhopa">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Network communication & Layer 2 Security with
                Practical Demonstration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE IDEA PITCHING COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9788b97016f35546e1c36502504dc924.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA INSTITUTE OF PROFESSIONAL MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An initiative to mitigate learning losses due
                to CoVid-19 pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR - Build a Start-up like a Corporate
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR - Startup Story</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9788b97016f35546e1c36502504dc924.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA INSTITUTE OF PROFESSIONAL MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship Awareness Activities durnig
                Lockdown</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Virtual Reality\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Navonmesh</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PETPS practical performance through matlab
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learning through Projects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79844c136769fd29f0d0eb5329e4651f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities for higher education and
                competitive exams</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b35e753726d76462d1a7813bdcf78b2d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.KUMARASAMY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Entrepreneurship Talk” Turn a
                crisis into an opportunity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Basic concepts of sample prep & introduction
                to SPE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture Series-Lecture 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture Series-Lecture I</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Effective Methods for Reducing
                Fault Levels in Industrial Grid Networks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE IDEAS & PATENTS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Android Widget Overview &
                Communication with cloud server and firebase</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online interactive session for Quality
                Product </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/973f573917a12c542bc2ec561567c546.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sarada College for Women(Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘Recognize your potential in Innovation and
                Design Thinking’ </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f20b406f45ccdda51461fe288c0b4f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NESAMONY MEMORIAL CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Survey</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online training pogram on Computing and
                Information Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Idea Cafe: Cafe#4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Idea Cafe: Cafe#3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Idea Cafe: Cafe#2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Idea Cafe: Cafe#1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #3 -
                \&#34;Robotic Process Automation\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #2 -
                \&#34;Personality: A Matter Of Attitude\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 3.0: Session #1 - \&#34;Rapid
                Prototyping\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Quiz Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to create an Effective Project</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 2.0: Session #6 -
                \&#34;Effective Learning: An Attitude!\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Different Learning Platform</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 2.0: Session #5 -
                \&#34;Resistance Through Persistance\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 2.0: Session #4 - \&#34;Your
                Limitations? It\&#39;s Only Your Imagination!\&#34;\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 2.0: Session #3 -
                \&#34;Breaking The Myths Of Civil Services\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Innovation & Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 2.0: Session #2 - \&#34;Design
                To Manufacture\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Photo collage </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 2.0: Session #1 - \&#34;My
                Journey With IEDC\&#34; by Gopikrishna G. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent trends of electrical drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Online Session on \&#34;LEGAL AND ETHICAL
                STEPS- PRODUCTIVE ENTERPRENEURSHIP AND STARTUP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 1.0: Session #4 -
                \&#34;E-Skills\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 1.0: Session #3 - \&#34;Social
                Innovation\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 1.0: Session #2 -
                \&#34;Industry 4.0\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series 1.0: Session #1 -
                \&#34;Perspectives Of Innovation\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Hand Sanitiser Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Q-days Challenge #5: Just Design & Share!</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Q-days Challenge #4: Just Craft & Share!</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Q-days Challenge #3: Just Build & Share!</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Q-days Challenge #2: Experience Sharing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Trends of Electric Vehicles\&#34; on
                15 May 2020 at 4.00 PM, Speaker Mr. Nitin Marathe</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Q-days Challenge #1: Just Shoot & Share!</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79844c136769fd29f0d0eb5329e4651f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2 day workshop on Scilab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/908f94593b09f50b203c5df25a2c4103.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sagar Institute of Research & Technology,     Opp. Sagar Estates, Ayodhya Nagar, By Pass Road, Bhopa">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities for Electrical Engineers in
                Automotive industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Startup and Incubator connect\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conceptual Planning & Development for
                Portable Electronic Test Bench</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID 19 Innovative Project Proposals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Solutions for Covid 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID POSTER DESIGN COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9756c6c8cebc52854414ea0cf3f06a4a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Matrusri Enginering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering Impact on Humans a Different
                Perspective</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9756c6c8cebc52854414ea0cf3f06a4a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Matrusri Enginering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Eyantra Robotics Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Cloud Computing Using AWS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9756c6c8cebc52854414ea0cf3f06a4a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Matrusri Enginering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Walk this way</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Career Choices and Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Current Trends in Digital
                Signature</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Teamwork makes the Dreamwork</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Machine Learning and Deep Neural
                Networks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Science in Medical Troops</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IoT: Connectivity to Cognition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9756c6c8cebc52854414ea0cf3f06a4a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Matrusri Enginering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on COVIDYA-(Design thinking-
                Innovation- Incubation)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34; Cyber Awareness & Digital
                Security\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->What drives you-Intrinsic motivation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR - Online Mentoring Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR - Online Mentoring Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidance For Bachelor of Engineering
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to Indian Meteorological
                Department</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Weak Forces: Backbone of
                Biological System\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR - Online Mentoring Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 AWARENESS QUIZ</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR - Online Mentoring Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR - Online Mentoring Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR - Online Mentoring Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR - Online Mentoring Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR - Online Mentoring Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Journey of a Social entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online debate competition on \&#34;Hospitals:
                Most Secured Shelters for COVID-19 Patients\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities & Schemes in MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities & Schemes in MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAD Master 5.0 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on the theme \&#39;Reliability of
                Industrial Components\&#39; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Agile Project Management “</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Service Marketing Shake-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Opportunities using ICT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c457a1cb44e91460a1f383d00c57e4d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS SCIENCE AND TECHNOLOGY UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INFACTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->20 trillion stimulus package-what\&#39;s
                there for common man?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Innovative ideas for Start-ups” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;The Journey of a Start-up Company\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40d13e359a0d710cfb11750a69a015d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B V V SANGHAS BASAVESHWAR ENGINEERING COLLEGE BAGALKOT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights (Part I)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Getting Started with Cloud</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->innovation - Cloud Comuting and Services</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industrial Cloud Practices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Docker and Kubernetes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Robotics and its
                Application</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on ENHANCING RESEARCH SKILLS THROUGH
                E-LEARNING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Importance of Self Learning
                Through MOOCS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Networking in Cloud</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Recent Trends in AI and
                Electronics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online (Faculty Development Program)FDP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on CANVAS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Troubleshooting (tips & tricks) Columns HPLC
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Web Design and Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Application of fluid
                mechanics in designing microsink\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mahatma Education Society\&#39;s Goldan
                Lecture on \&#34;Opportunities in Post Covid-19 India\&#34; by Dr. Abhay Jere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Antenna Design through Simulation Using
                TaraNG 19.1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea-Entrepreneurship-Opportunities.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “PEGA :Customer Engagement and
                Intelligent Automation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Baja SEA India 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->M BAJA - 2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Turbochargers and Necessity of
                BS-VI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->To promote IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aero KLE won first prize in SAE India
                Aerodesign Challenge 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Face Shields developed by CTIE Startups </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Face Shields developed to Combat COVID - 19
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Contact less Hand Sanitizer </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Origami Automations developing Automatic Ambu
                Bag Respirator</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Biosyl hand sanitizer Sanisyl</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on the theme \&#39;ARTIFICIAL
                INTELLIGENCE WITH MACHINE LEARNING\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Facebook Session on AICTE Initiative -
                National Educational Alliance for Technology (NEAT) - Personalized and Customized learning for
                employability skill enhancement and bridging learning gaps- Benefits for faculty and students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Kalpas Innovations Pvt Ltd</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Emerging Trends in Embedded System
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Sustainable Developments</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Cyber Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pequrel Microelectronics joins KLE CTIE
                Incubation Centre </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#39;\&#39;Diesel Particulate
                Filter: For Better Exhaust Air Quality\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Webinar on Interdisciplinary
                research-opportunity and future scope</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact of COVID-19 on Construction Industry &
                Possible Solutions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on theme\&#34; PLC & SCADA\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Big Idea Competetion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EDGE Tech2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Symposium- \&#34;Photonics -State of
                Art\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sustainable Startups- Opportunities and
                Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Origami Automations funded under TIDE 2.0,
                MeiTY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drawing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Idealogoue</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TiE Webinar - Braving New Realities Post The
                COVID-19 Pandemic </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAWACH - Centre for Augmenting War with COVID
                - 19 Health Crisis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Post Pandamic job Prospects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Analytics: Industry Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Investor Meet with Angel Investors at
                Hubballi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f2b86860b6980b765d3db1e7a5f273f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Geetanjali Institute of Technical Studies,Udaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The pursuit of entrepreneurial dream, why &
                how</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pitching @ Weizmann Forex </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TiE Sharks Pre Pitch </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TIE Sharks Pitching - Finale </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TiE Sharks Pitch Master Class</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “A Premier on Digital Forensics”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/157d1883ade3ddfeb496125a9e72a808.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF TECHNOLOGY AND SCIENCE, MOHAN NAGAR, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e FDP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online talk on Agro-based industry and
                Entrpreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->cyber security in COVID-19 pandemic and post
                pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2a27bbb9c13f5f402f616c9d62ba849e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DIT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on How to plan for Start-up and legal
                and Ethical Steps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5bef4857b86005f79d7a68853da30e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TERI SCHOOL OF ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Renewable Energy Finance and Project
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/735dbd6e041c143ae169961c6d203276.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY (MCA INSTITUTE)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Contact Less Wrist Band Fight Against
                Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HangOut with Emerging Student Innovators,
                Enterprenuers, Design thinkers and Start Ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/988a718d24fd0c21a1c6f5bd324338e1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.P.C. Mahalaxmi College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Start-Ups and Incubation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7714cef07e5da8f652b30a492f545afd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prospective and Essentiality of Agricultural
                Sectors in Post COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Research,
                Technology and Innovation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Investor Meet with Mr. Anand Sankeshwar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Investor Meet with Mr. Vivek Oberoi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A seminar on Entrepreneurial and Job
                Opportunities in VLSI Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Ambassador Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Workshop on \&#34;Entrepreneurial
                leadership\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Workshop on \&#34; Critical Thinking
                and Design Thinking\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Elevate 2020 - Government of Karnataka Pitch
                event for grants upto 50alcs. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MSME Business Incubator - Call for
                Application</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TIDE 2.0 EiR & Grant - Call for Applications
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2nd Edition of Samsung Codethon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/735dbd6e041c143ae169961c6d203276.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY (MCA INSTITUTE)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Free online internship on Data Analytics in
                Healthcare </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship on \&#34;Estimation and Quantity
                Surveying</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e466e07e48c245acf3d190edd6941ad8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Post Graduate Government College for Girls Sector-11">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar/Interactive Session on \&#34;Goal
                Setting & Motivation\&#34; and \&#34;Discussion on the impact of COVID-19 on placement
                oppurtunities\&#34;. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IPR and Patent Drafting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/735dbd6e041c143ae169961c6d203276.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY (MCA INSTITUTE)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WHO Covid-19, Courses Training for Students
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5bef4857b86005f79d7a68853da30e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TERI SCHOOL OF ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR Awareness in collaboration
                with CII and Intellectual Property India on aspects concerning different forms of IPR viz. Copyright,
                Patent, Trademark and Licensing. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5bef4857b86005f79d7a68853da30e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TERI SCHOOL OF ADVANCED STUDIES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cleantech Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/735dbd6e041c143ae169961c6d203276.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY (MCA INSTITUTE)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Covid-19 awareness quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/735dbd6e041c143ae169961c6d203276.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOIDA INSTITUTE OF ENGINEERING AND TECHNOLOGY (MCA INSTITUTE)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LEADERSHIP SERIES JOURNEY OF AN IDEA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learn the Basics of Machine Learning powered
                for tommororw</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Webinar on Exploring\&#39;start-up
                oppurtunities in Post Covid Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aatma Nirbhar Bharat Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/49854e16246ff72d9a17184dbb76b2a7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND PHARMACY COLLEGE, ANAND  205 ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID Hackathon’2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Value added cement based products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on Intellectual Property (IP) Management
                at Early Stage of Innovation and Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Emerging Innovator &
                Entreprenuers Supported through MIC & AICTE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leading Self in Corona Crises</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Filing Process in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Brand Can Do Everything</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Circular Economy Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expectation versus Reality in a Startup/Job
                and Operational Challenges during Lockdown</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Exchange Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce61529dadd4a5cf67f766fa0e34bfda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kamala Institute of Technology & Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE QUIZ ON \&#34;ARTIFICIAL INTELLIGENCE
                AND ITS UB AREAS IN FIGHTING COVID-19 PANDEMIC\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Webinar on Secrets of Successful
                Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online FDP on Possessing the two wardrobes:
                Communication and Writing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online FDP on Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online FDP on R Programming </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/546327bad3f210ac90c22b459f6d044b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="QIS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in Product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/546327bad3f210ac90c22b459f6d044b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="QIS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to make a Startup Successful</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Development of contact less digital IR
                thermometer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Development of low cost face mask</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Drafting Effective Project
                Proposals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Waste Management During Pandemic
                Condition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Values for life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Practical Aspects Of Estimation
                And Quantity Surveying</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Geotechnical Engineering And Its
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Emerging Contaminants in the
                Environment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drug Discovery: Metabolic Disorders an unmet
                medical need?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Maestro with Suresh Prabhu on
                Knowledge Based Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Entrepreneurial Skills & Small Business
                Ideas\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD\&#39;s Innovation Cell - Online Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Systematic Method DevelopmentProgram Theme
                :Innovation A practical approach for method development on the lines of QBD Approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NPTEL SPECIAL LECTURE SERIES-Career in
                Merchant Navy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4f34d9dfc32263d5c07a524989af4beb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. AMBEDKAR MEMORIAL INSTITUTE OF INFORMATION TECHNOLOGY & MANAGEMENT SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON SKILL DEVELOPMENT AVENUES DURING
                LOCK DOWN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2a27bbb9c13f5f402f616c9d62ba849e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DIT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Hangout with Successful Start-ups
                (Entrepreneurs in Campus)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff0a1148a500da8408471fdae463fc09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vignan Pharmacy College, Vadlamudi Post, Chebrolu Mandalam, PIN- 522 213(CC-AB)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->careers and employibility scenario-post
                COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “IPR and IP Management for
                Innovation and Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Driven Decision Making Using
                AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership skills </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Friday Forenoon with Entrepreneur’ series
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, Entrepreneurship and Design
                Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Course </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Analytics and Employment Opportunities
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NPTEL SPECIAL LECTURE SERIES-“What Companies
                Expect While Hiring a Candidate”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E Commerce for All Business </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation to Patenting in Academic Research-
                A walk through</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enroute to Technology Commercialization of
                Academic Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Contest - Business Opportunities in Post
                COVID world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Innovation and Entrepreneurship
                -Business Opportunities in Post Pandemic World</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session with Successful Startup founder</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenges and strategies of Restaurant
                Sector to overcome the current crisis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to plan for a startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6499599b1aeb3435b5e95941ada03331.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KNOWLEDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2a27bbb9c13f5f402f616c9d62ba849e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DIT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Value Added Cement Based Products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LEGAL & ETHICAL STEPS- PRODUCTIVE
                ENTREPRENEUERSHIP AND START UP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Entrepreneurship (A Career option
                & Startup Ecosystem)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/908f94593b09f50b203c5df25a2c4103.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sagar Institute of Research & Technology,     Opp. Sagar Estates, Ayodhya Nagar, By Pass Road, Bhopa">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advances and challenges in I.C. Engine and
                Petroleum fuel</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Beyond Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Introduction to AWS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on EXPLORING BEST PRACTICES OF
                CONCRETE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Protection Strategies in India and
                other Countries like Singapore</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NSIC - Industrial Motivational Campaign for
                youth program(IMC-Y) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Best Practices for IP/ Patent Portfolio
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on Intellectual Property
                Rights by Pandit Deendayal Petroleum University (PDPU)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal and Ethical steps-Productive
                Entrepreneurship and Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->how to plan your own startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c457a1cb44e91460a1f383d00c57e4d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS SCIENCE AND TECHNOLOGY UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/546327bad3f210ac90c22b459f6d044b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="QIS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CHALLENGES IN FILING PATENTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c457a1cb44e91460a1f383d00c57e4d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS SCIENCE AND TECHNOLOGY UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pharma model making Competition: Simulation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32c9481366a71ab002646cc51f6bea43.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ZAKIR HUSAIN COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Online Workshop on Start-Ups & IPR
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TNSI IDEATION CAMP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Risk Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MACHINE LEARNING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Strategizing Innovation in Engineering Domain
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Motor Thermal Model and Protection
                Element string</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Wireless LAN & security concepts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Advanced Automotive Embedded
                System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Static Motor
                Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Conveyor Tracking with Motion
                Control</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Offshore Wind Energy System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Intellectual
                Property Rights\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Market
                Analysis\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ROBOTICS PROCESS AUTOMATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future Proof Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT Applications, Deployment Challenges, and
                Career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Start-Up and incubation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/49854e16246ff72d9a17184dbb76b2a7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND PHARMACY COLLEGE, ANAND  205 ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking for Team Building &
                Co-Creation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training session on “Virtual Labs”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b35e753726d76462d1a7813bdcf78b2d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.KUMARASAMY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Competition on E Poster Making
                \&#34;IPR\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b35e753726d76462d1a7813bdcf78b2d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.KUMARASAMY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz on \&#34; Innovation and
                Entrepreneurship in India\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Education Science research and
                Innovation Opportunities in a post COVID World\&#34; Velidictry Keynote address by Sh. Ramanathan
                Ramanan Mission Director Atal Innovation Mission in Online Conclave organized by VGU to 5th may -10th
                may</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Clean Energy ( Pencil/Digital art )
                Competition - Lockdown Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights Quiz - Lockdown
                Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Case Study Competition - Lockdown
                Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Quotes Challenge - Lockdown
                Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Startup Storytelling Competition- Lockdown
                Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NPTEL SPECIAL LECTURE SERIES-“DATA,ANALYTICS
                AND AI”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Tech Talk Competition - Lockdown Innovation
                Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Idea Hackathon-Lockdown Innovation
                Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Leader\&#39;s Summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The students and faculty members of New
                Prince Shri Bhavani College of Engineering and Technology, Gowrivakkam, Chennai has actively
                participated in IIC online activity led by Dr.Sanjeeva Kumar Majumdar,Manager,-IPR, Start UP &
                Innovation,NRDC on 7th May 2020.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47a50a95d9b1df73a8429840ff802269.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhartiya Skill Development University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Technology Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property (IP) Management at
                Early Stage of Innovation and Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Why your subject matters feat HVAC
                & BAS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Importance of Programming
                Languages for Mechanical Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->on Inter-Personal Skill with Star Meditation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Symposium ‘Synergy 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Job Oppurtunities Post Covid 19 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program on Goal Setting for a
                Succesful Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PRAVARTAN- EBUSINESS IDEA CONTET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->scope and career opportunity on Digital
                Markrting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->offline to online marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Free online Internship on IoT Technologies
                for Embedded Computing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering as a brigth career path- online
                conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fa11d6fb3e0fe1b6a7cc831f1972f53.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAHYADRI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Latest trends in IoT and ML based Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/356897c1ecebfa80796dbb8317177b0e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shyam Lal College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on entrepreneurship by Tanya Kapoor
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Active Directory and Penetration
                Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Active Directory and Penetration
                Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on the algorithms and techniques
                learnt are actually used in real life solutions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Artificial Intelligence, Machine
                Learning, Data Science & Deep Learning and also their practical applications related to Covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Business Applications of
                Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Practical use of Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VERTECHX10.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Advanced Solar PV Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Power Factor Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Session with Young Entrepreneur
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f09c80ba654735defa51a04f7c8c22.doc"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJIV GANDHI COLLEGE OF ENGINEERING, RESEARCH AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online session 9 on \&#34;Understanding Angel
                and Venture Capital Funding - What is there for Early Stage Innovator & Entrepreneurs \&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Standards and Practices for
                Battery Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6c9a251fcd8626283e6e607f73616c24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTHAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR & IP MANAGEMENT FOR INNOVATION AND START
                UP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/546327bad3f210ac90c22b459f6d044b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="QIS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exploring Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual reality for beginners</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b5524883abe91398d93c0171cb448371.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Yenepoya (Deemed to be University)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awaken the inventor in you</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Successful Startups -
                (Entrepreneurs in Campus)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FX EDC Webinar Series on Post CoVid 19 job
                opportunities and Entrepreneurial opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->In silico Drug Discovery Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Large Molecule Discovery</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation-SPE and LC-MS for Bioanalytical
                Methods </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HILIC — A Systematic Approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A High-Throughput Workflow For Peptide
                Mapping To Monitor Post-Translational Modifications (PTMs) Of Biotherapeutic Proteins </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PK-PD Characterization of Anti Cancer Drugs
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Metabolic Regulation for Diabetic Heart
                Disease</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Nano Technological Solutions for
                Interdisplinary problems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RESTROSYNTHESIS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stem cell in cardiovascular Therapeutics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Executrix 2020 & Know Your Market</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on ERP & its importance for
                Entrepreneur </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f09c80ba654735defa51a04f7c8c22.doc"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJIV GANDHI COLLEGE OF ENGINEERING, RESEARCH AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online session 8 on \&#34;Intellectual
                Property(IP) Management at Early Stage of Innovation and Start-ups\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Kids Fashion Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b086c3d5c29c3f2cac64fee1b44a681d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The Northcap University, Gurgaon">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Basic Criteria of Patent and
                Requirements of Patentability and inventive step”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b086c3d5c29c3f2cac64fee1b44a681d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The Northcap University, Gurgaon">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to launch your idea on no Money?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b086c3d5c29c3f2cac64fee1b44a681d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The Northcap University, Gurgaon">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Session on Getting Smart with
                Investments!</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Road Safety Club in association with Regional
                Transport Office</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->industry visit and lecture on entreprenurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Seminar on \&#34;Solutions to Water
                Scarcity in Chennai City</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b086c3d5c29c3f2cac64fee1b44a681d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The Northcap University, Gurgaon">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Make You Happen”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b086c3d5c29c3f2cac64fee1b44a681d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The Northcap University, Gurgaon">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Idea Exhibition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact of Covid-19 on Employment and how
                should students prepare for it</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on LEARNING AND EARNING THROUGH
                ETHICAL HACKING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Tech talk on Android Performance
                Analysis and Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ROBO KABBADI Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training on Robotic Basics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship, Business Idae and Business
                Model Canvas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advances in Petroleum Refining Processes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Azeotrophy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“PHARMACIST UNDERSTANDING AND DELIVERABLE TO
                FIGHT AGAINST COVID-19”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creativity and Copyrights in Publications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Sustainable Manufacturing in
                Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Sustainable Manufacturing in
                Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Role of Design Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Automotive and Safety Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Trending technologies shaping healthcare and
                it\&#39;s future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on JAVA in Global Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Smart Connected World of Tomorrow
                - IIoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two day workshop
                \&#34;CAMMATHON\&#34;Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> training program on Foundation for Data
                Science, AI-ML</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electrical Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Power Plant Cycles
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Environmental Impact Assessment
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Lean Manufacturing Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Automotive NVH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital Transformation during
                COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> “Dimensions of Quality”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> “Dimensions of Quality”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Report on Biped Robot design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/598d73b344e4335d5cca78550e06b9bd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ASIAN BUSINESS SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;ALIGNING YOUR
                ENTREPRENEURIAL PATH & TURNING OBSTACLES INTO OPPORTUNITIES’ by Nick Vujicic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON ‘ADVANCED EXCEL’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SPARK CLUB ACTIVITY – ‘TECHUDBHAV’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme on ‘Finite
                Element Analysis using ANSYS’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre-conference Workshop on the 4th
                International Conference on ‘Design Innovations for 3C’s: “Compute – Communicate – Control”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Contribution of Engineers for the Sustainable
                Development of the Country’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NIC CLUB ACTIVITY “TECHACKS” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘BITCOIN -VIRTUAL MONEY’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT _ EEE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT _ ECE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HR Club activity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Forging Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEN STEPS TO BECOME AN ENTREPRENEUR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DESIGN THINKING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘EXPED MEDTECH: EXPERIMENTING MEDICAL
                TECHNOLOGIES’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT FOR INFORMATION SCIENCE
                STUDENTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘CHEMLIFE’ - QUIZ COMPETITION ON CHEMISTRY
                AROUND US </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Effect of Mineral Admixtures on
                Hydration of Cement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WELDING AND ITS APPLICATION IN PIPING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAMPUS TO CORPORATE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coding : A Pivotal Role in Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Self Empowerment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Automation using PLC and SCADA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Marine Microbial diversity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Post Covid Crisis : Challenges in Industries
                and Workforce Readiness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill sets for industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Battery Management System (BMS) in Electric
                Vehicle</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LABVIEW</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->learning tinkercad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Emerging Innovator &
                Entrepreneurs supported through MIC & AICTE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Innovation and Startup Policy for
                Students and Faculty 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online drawing competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online quiz competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Analytics using Tableau</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a74b9113f8930d6d52d13faa009c230.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KINGS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Role of Embedded systems in Data
                Acquisition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/973f573917a12c542bc2ec561567c546.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sarada College for Women(Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A talk with Chief Innovation Officer MHRD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff0a1148a500da8408471fdae463fc09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vignan Pharmacy College, Vadlamudi Post, Chebrolu Mandalam, PIN- 522 213(CC-AB)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DRAFTING A PATENT APPLICATION AND PROCEDURE
                FOR FILING A PATENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Interactive Session with Young
                Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent trends in the field of Photonics and
                fiber optic communication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The resurgence of Artificial Intelligence:
                Opportunities and Apprehensions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Validation of
                Feedback\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Implementation of
                Ideas\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Rapid Low cost
                prototyping\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Idea creation and
                Validation\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Validating a
                problem statement\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on \&#34;Finding a problem
                statement\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Building Automation Using direct
                Digital Control</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Electric Vehicle Modelling and
                simulation using Matlab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Artificial Intelligence, Machine
                Learning, Data Science and Deep Learning and their practical applications related to COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Latest Trend in big data analytics
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Engineers – Connecting the Dots
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Introduction to Robotics and its
                Application</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Data Structures and Algorithms for
                Programming Interviews</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Aim to soar - towards product
                companies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Recent Trends in Industrial
                Automation and Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Learning- Webinar Series Under Product
                life cycle Management PLM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on cybersecurity on Process
                Automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Experience of Studying Abroad and
                Research as Career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/794b3e2a5ae57333735afa17f79a24ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GND University College, Near Govt. College of Edu., Ladowali Rd.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on AI, ML & Google Assistant</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6ad02713048d84e68ae0cbdf88480b4d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Malaviya National Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop to Tinker Design Thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6ad02713048d84e68ae0cbdf88480b4d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Malaviya National Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A day workshop on Design Thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Season 2 Global Leadership Talk series 6</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inter and Intra College Sports Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days Workshop on “C Language”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Introduction to Machine Learning
                with AWS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “Building voice experience with
                Alexa”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/98d72e7999ba5bd6a36bfbe38b8de1ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA INSTITUTE OF INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fundamentals Of Deep Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Importance of Foreign Language
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness talk on “Use of Turnitin-an
                anti-plagiarism software”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“LEAD-Leadership Programme”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on Digital Marketing and its opportuniti
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sustainable Development & Road Map for future
                of Udupi – Jasmine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tips for writing technical paper</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f2cc631309d35904d71755668235f24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University College of Engineering Nagercoil">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights Day Online Quiz
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Novel ideas to prevent corona Virus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk with Ms. Anu Acharya, Serial
                Entrepreneur, Founder & CEO Mapmygenome</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hangout with Emerging Innovator &
                Entreprenuers Supported through MIC & AICTE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Analysis of steel structures through CypeCAD
                software</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre-stressed concrete</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hang out with emerging innovator &
                entrepreneur supported through MIC & AICTE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC online session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Motor Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Impact of IoT in Industrial
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk Series by Dr. Abhayjere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IoT based Ultra Low Power MCUs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Lighting Technology for
                Automoblies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Proof of concept Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Ansys Solution for Modeling and
                Simulation of Battery</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Safety Features for Battery
                Management Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IoT Application in Healthcare</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Changing scope of bussiness development post
                corona pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speech and Poster Making Competition On Water
                Conservation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Mastering Oscilloscope</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Advanced Electrical Power
                Measurements</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launch of NCIDE\&#39;s Virtual Resource and
                Incubator for Entrepreneurship, Training and Innovation – नVRIETI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Solar Thermal Heat for Industry
                Process</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Batteries for Electric Vehicles
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Converting Research and Development into
                Entrepreneurship – A Talk delivered by Dr. M K Ravi Varma and Dr. Subramanian N V for Faculties</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on Student Entrepreneurship and TBI
                operations to a group of students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5bb606ce63428433364d20e30c85944b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IK GUJRAL PUNJAB TECHINICAL UNIVERSITY, JALANDHAR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Entrepreneurship and how to start
                a business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Ideas for Women Agripreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Adriano and Its Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on PYTHON </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Innovation, Entrepreneurship and
                supports extended by TBI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Excursion Through Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Research Communication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a38b0b0be074f2e21728b74d041e37f2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ER. PERUMAL MANIMEKALAI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RC Plane MOdeling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fb978c86086a6d7236f4d6b163eb774.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS NARSEE MONJEE INSTITUTE OF MANAGEMENT STUDIES SVKMS NMIMS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inspiring Stories of Successful Entrepreneurs
                for the Students by the Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09886d07014301d1d888613d01739cfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MS Ramaiah University of Applied Sciences">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->guest lecture innovative resturant marketing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Hands on training for design & development
                of herbal cosmetics” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09886d07014301d1d888613d01739cfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MS Ramaiah University of Applied Sciences">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Design a Logo & Craft a Slogan”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->orientation on Innovation Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Learning Management Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f40f7dc01e2fadb649da0fc7cf6dea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="McGan&#39;s Ooty School of Architecture">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEAS TO HACK CORONA </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital tools for online learning
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f40f7dc01e2fadb649da0fc7cf6dea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="McGan&#39;s Ooty School of Architecture">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBMINAR & IDEATHON ON 5G SMART HOMES </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Project Demonstration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Friends of India ( Social Initiative &
                Globalisation)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6ad02713048d84e68ae0cbdf88480b4d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Malaviya National Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->he Success Mantra: A Seminar on
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Javascript</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Mock Interview</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fb978c86086a6d7236f4d6b163eb774.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS NARSEE MONJEE INSTITUTE OF MANAGEMENT STUDIES SVKMS NMIMS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Isthmus -2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Future Engineering Quiz Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fb978c86086a6d7236f4d6b163eb774.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS NARSEE MONJEE INSTITUTE OF MANAGEMENT STUDIES SVKMS NMIMS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aarogya</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Employability Skill Training in Financial
                Literacy\&#34; – A CSR initiative of Reliance Home Finance and Reliance Money, starting from 10th
                February 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Workshop on Electric Vehicle
                Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Significance of Renewable Energy
                Systems and Sustainable Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->.inSight\&#39;20 6th National Level
                Construction Tech Conference on \&#34;Recent Construction Technology Trends\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3rd National Conference on \&#34;Sustainable
                Agricultural Development for Food Security & Nutrition\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on Creativity
                and Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visited Goa Science Center</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TRENZ for School of Mechanical Engineering
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> PARTICIPATION IN 6TH INDORE MARATHON 2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative ways of Scientific Poster
                presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coding Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fa11d6fb3e0fe1b6a7cc831f1972f53.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAHYADRI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Transition from College to Corporate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->QIP/AICTE sponsored 5-days short term course
                on \&#34;Nano- biotechnology in Drug Discovery, Development and Delivery\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on \&#34;Security Aspects in Amateur
                Radio Communication Systems and Networks\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indian Science Fest-2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TRENZ for School of Biomedical and Chemical
                Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-0n-Training on ANDROID STUDIO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Novel Concept of Learning Through SAMA-BHAV
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-lead event 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Collaborative e-resource preparation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inaguration of TRENZ Forum</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2a27bbb9c13f5f402f616c9d62ba849e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DIT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World IP Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2a27bbb9c13f5f402f616c9d62ba849e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DIT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hang out with an enterpreneur with Talk on
                Innovations in Clinical Research and Pharmacovigilance </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNATIONAL CONFERENCE - FUTURE OF WORK
                WORKFORCE & WORKPLACE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Training Workshop on use of WEAS
                Portal for Programmes of SOS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d2ce45b9895bc7bda85921c9f501afb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VARDHAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Talent in Uberised World - Revolt or Evolve.
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meeting of the Innovation Club@IGNOU on
                \&#34;Innovative Ways of Teaching and Learning during Lockdown.”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Low Cost Web based Video Counselling for
                Distance Learners</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensitizing Faculty Towards
                Innovation-Innovative learning and Support Solutions inOpen and Distance Learning Systemin the Faculty
                Development Programme on Integrating Technologies in Open Distance Learning organized by STRIDE, IGNOU
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Decision Making and Creative
                thinking by Mr. Prabhat Kumar Jha (Motivator and Life coach) Ex. Deputy General Manager Maruti Suzuki
                limited.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Exhibition cum mentorship session for
                innovators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Incubation and Training Sessions on
                “Towards Being Your Own Boss”, the first one on 27th April 2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre conference Talks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Series of Hand holding of IGNOU faculty for
                Teaching Distance Learners from Home amid Covid-19 Pandemic -April 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Rangoli Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Failure analysis of automotive parts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hand holding of IGNOU faculty for Teaching
                Distance Learners from Home amid Covid-19 Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day National Seminar on “Developing
                Entrepreneurial Minds”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Entrepreneurs Meet-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit to Nitta Gelatin</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Pitching Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Training Workshop on use of
                Interactive Web Counselling tool for Mathematics Faculty of SOS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Web Application Development on Bubble.io</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Abhivyakti</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on UV Spectrophotometer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Seminar on Emerging Trends of
                Nuclear Medicines for Occupational Health and Environmental Safety</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d0573e92cd2858cdb77cb8261e554bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARMY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> WEBINAR on \&#34;ENTREPRENUERSHIP\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture on B2B Marketing During
                Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Innovation and Branding – A Case study of a
                leading B2B Brand from TATA STEEL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdded087b6aa9a5752a63ad67ac66e7e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING MANAV RACHNA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEA HACKATHON COVID-19 ----PRAYAS -2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID-19 STATUS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on REDHAT, Devops</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Awareness Programme (NLAP
                2020)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to start a startup and its challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/065c559150cb5956522cfd4da4e19b1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Bharati Vidyapeeths College of Engineering, Lavale  Address: lavale  Taluka: Mulashi District: Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship & Start Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Trending Blockchain Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> e discussion \&#39;Managing Business
                Relationships During the Global COVID-19 Health Crisis\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MASK MAKING TUTORIAL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e-discussion on \&#39;Pharma Research &
                Development Commitment to combat COVID-19\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ecentrix t20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HASTHA 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Best Practices in Higher Education &
                Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hand’s on Training CPR Cardiopulmonary
                Resuscitation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Induction Programme (Phase 2)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d0573e92cd2858cdb77cb8261e554bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARMY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CODE DOWN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e-discussion on \&#39;Artificial Intelligence
                to fight against Covid 19\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Ready ness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LAB EQUIPMENT SERVICING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Data Science with Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy Audit and Management\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solidworks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to Vijayawada thermal power plant
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start and Improve Your Business (SIYB)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to kumar pumps and motors</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Career Opportunities through GATE Exam
                and Tips to Prepare for Exam\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tinker Hub Community</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IP for Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Making of an Artificial Respirator with
                exhaled air disinfector in the mission to fight against COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AlumTalks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“International Conference on Advances and
                Developments in Electrical and Electronics Engineering” (ICADEE 2K20)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD IIC Innovation Ambassadors Training
                Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Computer-aided design (CAD)\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Medical Device Product Development and IPR
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Networking - Women Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Making of Reusable Aerosol boxes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Communication & Self-improvement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz on COVID 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on Entrepreneurship/startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NVIDIA DLI Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Topic - \&#34;How to Succeed in your 1st
                job\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5 day Faculty Development Program (FDP) on
                Entrepreneurship organized by Centre for Incubation and Business Acceleration (CIBA)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9aebba97e978f1d54d841b0350076103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KRISTU JAYANTI COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ADIOS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interractive session on Startup ideas in NE
                India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->I WEEK 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Career Opportunities on Heating,
                ventilation, and air conditioning (HVAC) for Mechanical Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fight Against Covid 19 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live session on Future of Architecture and
                Design- a entrepreneurial search for direction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TechBlitz 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Advances in
                Information Technology and Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online session on IP  Piracy  to  IP
                Compliances.... Beyond Borders </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online session on Internet Of Things -
                Innovation Opportunities And Challenges </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Upskill yourself and be Industry Ready
                in current scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->79th International Web Conference & HR
                Congress (organized jointly by University of Engineering & Management (UEM), Jaipur and Smart Society,
                USA)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f40889ef9e71bd4dbf9416821f123df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TRAINING PROGRAMME IN CARRY BAG MAKING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Season 2 Global Leadership Talk series 5</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->D2C Advanced Course on Entrepreneurship by
                Wadhwani Foundation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture on \&#34;Design Thinking in
                Entrepreneurship\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->R programming Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit - TS Transco Shankarpally
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to TS Transco</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electrical Safety Hazards and Control-Talk
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Ethical Hacking and Penetration
                Testing with Kali LINUX Testing </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electrical Safety Challenges in Distribution
                Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MSME idea Challenge-prelim</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Weschool - MITIndia Initiative Innovation
                Workshop 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WeChaar 2020 - Research Paper Presentation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation In Conversation series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Wemarsh 2020 - Case Study Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47a50a95d9b1df73a8429840ff802269.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhartiya Skill Development University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Understanding The Patent Filing
                Process</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Kalpak”- Science Exhibition organised by
                Directorate of Higher education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Infrastructure & Architecture with Dockers
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Technogies of the IT Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IT Industry and Web Application Development
                Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Goa’s Young Innovators </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agile Methodology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership talk with Ms Anu Acharya</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Quiz on World IPR Day on 26th April 2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Resurrect 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learninginnovations in Pharmacy and its
                assessment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Ambassador Training Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start up - Kite and Manjha Shop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Review Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Careers In Cyber Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fight Corona Ideathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->application development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Oil & Gas process system in FPSO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT&its Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Infertility Issues</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to Testing, Logistics & Supply
                chain management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Basic Python Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ventilators & Its Modes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entreprenuership Awareness Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enhancing user experience with IoT
                verification & Validation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Engineers in Medicine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STUDENT DEVELOPMENT PROGRAMME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6bf44e54057cc01943b3c8a715fa4ed.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSIT College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bharat Padhe Online</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STTP on Applications of Machine Learning
                Concepts using Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation: Coding Competitions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LabVIEW Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation: Coding Competitions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz - IPR Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation: Coding Competitions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT in Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation: Coding Competitions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT with Machine Learning </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber disease workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TIME TO ACT - NOW OR NEVER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEDx-GRIET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IMPACT - Motivational Speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drone Club Establishment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a787b43bca51da6a621d927156bb4e6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nowgong College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovations and Interventions:
                Ecosystem in Academic Institutions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IConCES - 2020 International Conference by
                Civil Department</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Disaster Management </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Need of Rainwater Harvesting in view of
                climate change in Urban scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Understanding Health Communication
                in Emergencies Like Covid - 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Advance Photography: Travel and
                Film Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on HVAC&R Industrial Awareness
                for Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar by Ar. D K BHOGAL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Study of Sai Sowbhagyam Complex at
                Naya Raipur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit for Site Study</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Outreach Program on Media Literacy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Site visit to Aarang </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FMAE Baja National Event</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit / AAR CEE Casting Industry
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning: A Technology for Future by
                Dr. Ramgopal Kashyap</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry 4.0 and Education for Tomorrows
                Technocrats by Prof.(Dr.) Surendra Rahamatkar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Lab Visit to ICAR-National Institute
                for Biotic Stress Management (NIBSM), Raipur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unblock the tunnel</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar by Ar.Gurunath Modak</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Strength and Fracture of Materials, Human
                Body Implants and Biological Materials</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to Government nursery,Math and
                Saragaon Pond</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Web Scrapping</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on \&#34;Drone Technology\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6debb4348fb0f50adbc81979e59b516.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIYADARSHINI J. L. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE WORKSHOP ON CADD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Motivational Talk by Social Entrepreneur
                Dr. Sachin Sharma</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit of school students to Research lab and
                Fab lab to promote innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate on “Innovative Technologies- Impact on
                Youth</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Talk on “Pharmacy-A field of Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Promote Blood Donation with Innovative Ideas
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit of school students in Fab lab to
                promote Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6499599b1aeb3435b5e95941ada03331.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KNOWLEDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Current Trends on Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Season 2 Global leadership talk series 4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Solar Project Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Future Trends in Automotive
                Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6debb4348fb0f50adbc81979e59b516.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIYADARSHINI J. L. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Computer Aided Drug Design Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on CREATIVITY AND INNOVATION FOR
                ENGINEERS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Webinar </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Celebration 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stargaze2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Exposure (Visit)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOT Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awakening The Scientist competition 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on \&#34;Grooming and Personality
                Development\&#34; by Gillette India Ltd</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on “Coronavirus-Causes, Symptoms,
                Prevention & Cure”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Development Guidance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Team Spirit and innovative strategies to help
                to won in Annual Sports Meet 2020t </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e31ddf85c83f1e6796c5a792086fce3d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur Motivational Speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b35e753726d76462d1a7813bdcf78b2d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.KUMARASAMY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Value Added Course “Entrepreneurship and
                technology outreach program (E-Top) & Global Innovation Challenge on Design Thinking and Business
                Innovation”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotic Process Automation Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CO-WORKING: SMART & SUSTAINABLE BUSINESS
                CONCEPT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Oral Presentation
                Competition @ National seminar on Recent Developments and future prospects of Clinical Research and
                Pharmacovigilance in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Team Building Activity (Deactivate Nuclear
                Zone) for B. Pharm. Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MOTIVATIONAL TALK FOR FIRST YEAR STUDENTS OF
                AIPER ON UPLIFTMENT OF SPORTS SPIRIT BY MR. MIR RANJAN NEGI </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IC Engine Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Avishkar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Samantrana</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SQL certified training course</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Keynote Address/Talk by Dr. Mayank
                Dwivedi, Director of Industry Interface and Technology Management at DRDO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Team Bonding Session “I am an Entrepreneur”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LSR-RAHAT : Socially distancing, spiritually
                together</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Probing Pandemic Pandemonium: A Real Time
                Study of Covid-19 Stress, Coping and Psychological Consequences in India A study Conducted by Dr. Kanika
                Ahuja</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6bf44e54057cc01943b3c8a715fa4ed.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSIT College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart Hacks XX-XX</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6bf44e54057cc01943b3c8a715fa4ed.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSIT College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vikram A. Sarabhai Centenary Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/546327bad3f210ac90c22b459f6d044b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="QIS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/546327bad3f210ac90c22b459f6d044b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="QIS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship in New Era</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4728d2bc5cd04b09ed38fd9bf82357a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Engineering Science and Technology- Shibpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Design Centre/Makers’
                Space/Fab Lab/Prototype Lab/Tinkering Lab etc</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f40f7dc01e2fadb649da0fc7cf6dea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="McGan&#39;s Ooty School of Architecture">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exploring Rock cut Architecture - Study Tour
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Opportunities in the Tourism
                Industry </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Women\&#39;s Day Celebration
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f2cc631309d35904d71755668235f24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University College of Engineering Nagercoil">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Industry-Academia Collaboration
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LIVE 2 LEAD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Industrial Visit & workshop at PLC,
                SCADA, HMI INTERFACE unit at DAINIK BHASKAR PLANT.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to National Remote Sensing Centre
                - Hyderabad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Avenues to Focus during National & Global
                Crisis of Covid Pandemic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Instrumentation and Health Care sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d042cb1ee3b96f1498eb7522f48c69dc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIES GRADUATE SCHOOL OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Positive Attitude and Conflict Resolution”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrialvisit to CYCLONE DETECTION RADAR
                STATION,Machilipatnam.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d042cb1ee3b96f1498eb7522f48c69dc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIES GRADUATE SCHOOL OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Free health check-up camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON “SPM Automation & Control Systems
                for Industrial Applications”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to ISRO - SATISH DHAWAN SPACE
                CENTRE - SRIHARIKOTA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Instrumentation,
                MEMS and Bio sensing Technology ICIMBT - 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SHORT TERM TRAINING PROGRAMME ON
                BIO-ENTREPRENEURSHIP AND MANAGERIAL TOOLS FOR BIO-BASED START UP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Explainable Clinical AI- A Biodesign
                Perspective</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->K.Ramasamy First Memorial Lecture on the
                topic, \&#34;Rural Upliftment through Higher Education\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Python for beginners</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->latest technology and tools for machine
                learning and artificial intelligence with in hands on </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop on IoT Using Arduino
                NWIOTA2020 Hands-On Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop on text analysis and
                optimization for social media data with hands on session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship awareness camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop on Decoding blockchain
                present and future prospects with big data </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Auto Fusion 360 software</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop on Cyber-Physical System
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Volunteers Participation in IMA
                Conclave 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hospital Pharmacy Training: Practice School
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1069f57a07f8f270cfe9edddff0bac23.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BABU SUNDER SINGH INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->APPRECIATION CERTIFICATE DESIGNING
                COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1069f57a07f8f270cfe9edddff0bac23.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BABU SUNDER SINGH INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Doodle for google challenge by BSSITM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hospital Visit by Students: Exposure to
                latest technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->On-Spot Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD’s Bharat Padhe Online Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of International Women’s Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Case Study Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Awareness Programme (NLAP)
                2020 - Ministry of MSME, Govt. of India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on PCB Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to Intellectual Property Rights
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech Mela</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Formulation and distribution of Hand
                Sanitizer to Prevent Covid – 19 infection</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Invictus </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE CERTIFICATION COURSES ON COVID -19 :
                AN INNOVATIVE WAY TO CREATE AWARENESS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Art of Fundraising for Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ROBOTIC PROCESS AUTOMATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Trends in Automobiles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Women’s Day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chandrayaana mission 2 – Technical Talk </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program on Corona (Covid-19)2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Innovation and Crisis and COVID Lockdown -
                An Efficient Way of Utilization of Time towards Combating COVID19”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stress Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VARCHASVA 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7c78b0b0b65e6a6a84f48d85aac3f4c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY GOA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ignite POC V 1.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A talk by Young Entrepreneur : Mr. Sagar Rai,
                Founder KIBI Sports</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar by Mr. Gautam Bambawale,
                Ex-India\&#39;s Ambassador to China, on how this COVID crisis offers a unique opportunity for India
                vis-à-vis China. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Ambassador Training Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar by Mr. PadmaShri Vaidya Rajesh
                Kotecha, Secretary Ministry of Ayush</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdded087b6aa9a5752a63ad67ac66e7e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING MANAV RACHNA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Uplifting the Rural India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDIA HACKATHON SERIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interview of Pharmaceutical Industry Expert
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online demonstration cum mentoring session of
                New Product Ideas and Designs by students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH- Internal HACKATHON </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdded087b6aa9a5752a63ad67ac66e7e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING MANAV RACHNA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ANTENNA DESIGN WORKSHOP ON FEKO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Post COVID Opportuinities and Challenges </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RiGathon A 48Hour, National level Hackathon
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Science and Machine Learning </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Big Data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Circuit Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidence and Autocad Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TALK on Ethical Hacking & Cyber Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e31ddf85c83f1e6796c5a792086fce3d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEN Online Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR SERIES ON RECENT TECHNOLOGIES &
                COMMUNICATION SKILLS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Web Analytics Tool: Omniture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Counseling and Career Guidance </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Innovation on “Tableau”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4728d2bc5cd04b09ed38fd9bf82357a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Engineering Science and Technology- Shibpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Uthal Puthal Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4728d2bc5cd04b09ed38fd9bf82357a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Engineering Science and Technology- Shibpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Content and Digital Marketing Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Career Paths After an Under
                Graduate Engineering Degree</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Trends in Mechanics: Bridging the
                Gap between Engineering and Medicine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Manufacturing of Combat Aircraft
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NOVA Capture the flag</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on 3D Printing Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Non Destructive Testing and ASNT
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Artificial Intelligence and
                Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Sustainable Energy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digital Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Institute Academia Collaboration:
                Distribution of Acropolis Calendars 2020 to Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Webinar on Innovate To Lead</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Braking Systems in Autonomous
                Vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Additive Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Six Sigma - The way of life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Predicting Additive Manufacturing
                Build Cracks Using XFEM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Productivity in Product Development:
                Industrial Practices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Waste to Power
                plants</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Identify Aircraft Community
                Noise Sources and Reduce Its Impact Using Simulia Fluids Solutions\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Machine Learning using Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MIT APP Inventor</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on C & C++</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Code Hunter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MAC OS Mobile App Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MIT App Inventor</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to Piping Engineering in Power
                plant</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->JETSCARA 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Blockchain Technology and its Usecases</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Avenues in Deep Learning for Natural
                Language Processing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Trends & Research Directions of Deep
                Learning in Natural Language Processing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->one day state level workshop “Python Hunters”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Awareness Programme 2020 for
                Young Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Youth Empowerment and Skills Workshop (YES +)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PRODUCTION PLANNING AND CONTROL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->OPPORTUNITY FOR OCCUPATIONAL HEALTH AND
                SAFETY </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mechanical Design Validation Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e7e3eb6eea94ea4735118bf316d683e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KGRL College(A)  - Degree  & PG Courses">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->online motivational session regarding
                innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Youth Entrepreneurship Awareness Programme
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Popular Lecture Series on Energy
                Conservation, Waste Management, Health Related Issues and Remedies (Exclusively for Women) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Training in Technology & Soft Skills -
                Cloud Infrastructure & Services</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Work at Home: Convictions and Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Machine Learning using Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UOI Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber Fence Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GOONJ- A National Level Tech-Fest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NECTECHFEST-2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Relay Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Power Converters Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT Wokshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LABVIEW WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->High Voltage Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b430725a6e40d1954321eaaea1daf68e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="D.K.T.E. SOCIETYS TEXTILE & ENGINEERING INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techsymposium2k20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation session on Pre- incubation and
                Incubation Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on angel investment / Venture capital
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on lean start up business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Visualization using Tableau</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Augmented Reality</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ERP in Modern IT Industry </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Season 2 Global leadership talk series 3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Season 2 Global leadership talk series 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Season 2 Global leadership talk series 1 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global leadership talk series Valedictory</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global leadership talk series 14 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in IIC Innovation Ambassador
                Training Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global leadership talk series 13</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global leadership talk series 12</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global leadership talk series 11</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 10</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f40f7dc01e2fadb649da0fc7cf6dea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="McGan&#39;s Ooty School of Architecture">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IGBC - INDIAN GREEN BUILDING COUNCIL WORKSHOP
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TACHYONZ 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VIGYANAM 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Important updates in SIH (Smart India
                Hackathon) 2020 with Dr. Abhay Jere, Chief Innovation Officer, MHRD’s Innovation Cell (MIC) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH 2020 INSIGHTS with Dr. Mohit Gambhir,
                Innovation Director, MHRD’s Innovation Cell (MIC) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KALAKRITI 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber Security awareness Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/701e493880429f5d15317f5b1d857a20.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE COLLEGE OF ENGINEERING 346">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> invited seminar talk on High Speed Train –
                Past, Present and Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/701e493880429f5d15317f5b1d857a20.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE COLLEGE OF ENGINEERING 346">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON PROJECT MANAGEMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/701e493880429f5d15317f5b1d857a20.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE COLLEGE OF ENGINEERING 346">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->invited seminar talk on Thermal Power Plant &
                its Performance Improvement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT TO Networkers CCIC,Noida</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BMW ENGINE WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Significance Of Method Development And
                Validation By HPLC In Drug Discovery And Development.\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Success in IELTS and TOEFL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> IPR: RESEARCH AND INVENTION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solar Power Designing and Simulation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Investment Banking and Financial
                Markets </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93c95772e881615ccf1e7ad8819bb789.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Srinagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID19 Open Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ROBOTHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fabrication of M19 Face Shield</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fabrication of N95 replacement filter masks
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Experimentation 101 and Research
                Opportunities in AI, IoT, 5G, AR/VR and Blockchain </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Talk on High Performance Computing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/598d73b344e4335d5cca78550e06b9bd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ASIAN BUSINESS SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar Series - Chetan Bhagat</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Expectations and How to crack
                interviews</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on Self development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on\&#34; COVID -19 YOUR SEIGNAL IS
                RED HERE\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Impact of COVID 19 -
                Expected change in nature of Job\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Modern Perspective of Renewable
                Energy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/168e7f441ad0bea712135781e217604b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="M.E.T. Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Dreams and Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar on Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Model and My Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MAKE-A-THON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACKATHON 3.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fa6867dfd4bf4cdead28f2dbc5165cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHANDIGARH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Facebook LIVE session on NEAT with Vice
                Chairman, AICTE, Dr. M.P. Poonia on Friday, April 10,2020 at 3 PM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering Thinking in FMCG Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ADVANCEMENT IN MACHINE SHOP ENGINE COMPONENT
                MACHINING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Write Research Proposals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stem Cell Donation, and awareness Workshop
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to \&#39;Yakult Factory\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/589da4bfeff70d7d2fc3cac2069c676a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDRAPRASTHA INSTITUTE OF INFORMATION TECHNOLOGY -DELHI (IIIT-D)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stories Unboxed</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest session on ‘Entrepreneurship
                opportunities in Singapore’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on ‘Edu-Future For
                Sustainable World Economic Order: Research, Innovation & Technology’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/49854e16246ff72d9a17184dbb76b2a7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND PHARMACY COLLEGE, ANAND  205 ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design Thinking - Strategical Tool
                to solve Complex Problems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Acceleration Factors and Life
                Predictions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Uncertainty and Managing
                Uncertainty - at Down Trends</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Math Explore 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk in lock down by Amit Singh
                founder of Head Start Network</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e31ddf85c83f1e6796c5a792086fce3d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NPTEL Lecture Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Building business with Living Legends–
                Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e31ddf85c83f1e6796c5a792086fce3d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->APPAVIN KALAM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e31ddf85c83f1e6796c5a792086fce3d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Profit Maximization - The Mandate of Business
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“NO MONEY MAKE MONEY”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e31ddf85c83f1e6796c5a792086fce3d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BUSINESS OF 21ST CENTURY </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEA VASOOL </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Say The Unsaid(Untold Stories of Brands)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Liberate the News(Happenings) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur of the Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e466e07e48c245acf3d190edd6941ad8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Post Graduate Government College for Girls Sector-11">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Creativity, Innovation & Growth
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->OPENCV & IOT WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FLUTTERZ2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webex Session on NISP 2019 by Mr. Dipan
                Sahu,National Coordinator - IIC, ARIIA, NISP Innovation Cell, Ministry of HRD, Govt. of India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Intercollegiate Meet - IGNITARA 2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Out side Industry startup visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cloud Native Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campus Hackathon 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NEW PRODUCT DEVELOPMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Computational Fluid Dynamics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->START-UP AND ENTREPRENEURSHIP IN RIGGING AND
                ANIMATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenges in becoming a Successful
                Entrepreneur </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inauguration of “Family Managed Business
                Cell”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/589da4bfeff70d7d2fc3cac2069c676a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDRAPRASTHA INSTITUTE OF INFORMATION TECHNOLOGY -DELHI (IIIT-D)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Karwaan Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Maths Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/589da4bfeff70d7d2fc3cac2069c676a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDRAPRASTHA INSTITUTE OF INFORMATION TECHNOLOGY -DELHI (IIIT-D)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Wisdom Talk - Joys of Entrepreneurship & 8
                Tips to Succeed</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/589da4bfeff70d7d2fc3cac2069c676a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDRAPRASTHA INSTITUTE OF INFORMATION TECHNOLOGY -DELHI (IIIT-D)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WISDOM TALK - Innovation & Entrepreneurship
                as a Career Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Innovation – Kitchen Compost Basket
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EMFYLIOS 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar and Ideathon on 5G</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4dcc063e4befa32a496e4cdc717ec1e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sinhgad Technical Education Societys Sinhgad Institute of Pharmacy, Pune Mumbai Bypass Highway, Narh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enterprising enterpreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Effective use of pharmacy
                education\&#34; in Practice:A Prospective Approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert tak session in lockdown by Kartik
                Mulakaluri on \&#34; Tap and nurture the inner Talent</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert tak session in lockdown by Ms. Amulya
                Nigam on \&#34;Legal perspective of covid-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/598d73b344e4335d5cca78550e06b9bd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ASIAN BUSINESS SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership & Entrepreneurship Aspirations
                Development Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WIZARC 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar- Entrepreneurship 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->M Fest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Matunga Railway workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VirtusaCodeLite</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Reverse Engineering Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Story Of Madness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Bigdata</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->36 Hours Hackathon on Game Development (GAME
                HUB)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Data Structures & Its Applications”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lean Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Process Involved in New Product Development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future of Industrial Automation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interpersonal Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design Thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to AI & ML</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Future of Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Accelerating Experiential Learning
                in Engineering - A Mathworks Approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on VMWARE CLOUD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Robotic Process and Automation:
                Basics and Beyond</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Future of Artificial Intelligence
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert tak session by Prof. Arya Kumar
                \&#34;What it takes to be Entrepreneurial- Seeing Opportunities in challenging times</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5bb606ce63428433364d20e30c85944b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IK GUJRAL PUNJAB TECHINICAL UNIVERSITY, JALANDHAR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk with Er. Navdeepak Sandhu, President,
                IIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Reassuring growth in times of
                COVD-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Solar Heat and Electricity: PVT
                Solution for Building and Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Modelling and Simulation of
                Transmission Lines</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Modelling and Simulation of
                Transmission Lines</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Coal to Pole to Achieve Goal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on STSPIN Designing for small Motor
                with 1.8V Driver ICs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Fundamental of PCB Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent trends in biomedical engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career opportunities for an instrumentation
                engineer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skilling up & Industry Expectations from
                fresh engineering graduates</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conducting effective virtual training and
                presentations confirmation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering steels widely used in capital
                goods industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Industrial Opportunities and Focus Areas –
                Post COVID19”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3D printing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MATLAB workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOT workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXODIA2k20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Intelligence & Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cloud Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Fundamental of OFFLINE LED Drives
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Scope Microsoft - Azure Cloud in
                Business Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Reaching those Niche Sales Targets
                of Corporate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Will Digital Marketing be the
                answer in Post COVID-ERA?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#39;Managing Sales after
                Disasters - a Case approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Impact of Covid-19 on B-Schools &
                Career Guidance for the Post Covid Period</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Digitalization and Design Thinking
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Art of Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on e-Content Development Webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Academic Writing: Webinar 2020:
                Plagiarism Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Entrepreneurial Journey</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Strategies for Student
                Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Leadership and soft skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18526f82cdfcbdd79e98193173f11455.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS DWARKADAS J. SANGHVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning Boot camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18526f82cdfcbdd79e98193173f11455.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS DWARKADAS J. SANGHVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python Bootcamp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Getting Started with AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Introduction to Angular Framework”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Introduction to Angular Framework”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Lock Down - A golden opportunity
                for placement preparation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Women Entrepreneurs: Strength in
                Numbers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Startup in global crisis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Fast EV Chargers using three phase
                LLC Converter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Companies Fresh Start Schemes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18526f82cdfcbdd79e98193173f11455.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS DWARKADAS J. SANGHVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Coaching Strategies for Leaders
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Leadership in Extraordinary Times
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Nominal Concrete Mix</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Innovations in Supply Chain
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON PROMOTION OF APPRENTICE IN LIFE
                SCIENCES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Education matters </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Art of Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Deep Learning for RADAR & Wireless
                communications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to Deep Learning for
                Audio & Speech Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Semiconductor Power Validation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Agile Process in Software Project
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Artificial Intelligence for
                Business Transformation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Data Science in Digital Marketing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TieCON Kolkata 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of IPR for Academic- Industrial
                Collaboration De</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Entrepreneurship Traits</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on ANSYS LS-DYNA Overview</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Tips and Tricks for Welding</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IoT in Automotive Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Real Time Applications of Data
                Structures</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Introduction to 3D Modelling using
                Fusion 360</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Aircraft Instruments</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Project Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on How to attain success in
                Mechtronics Field</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT to Bisazza India pvt. Ltd.
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT to CIPET: IPT , Ahmedabad
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT to CIPET: IPT , Ahmedabad
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT to Amul Dairy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT to Pashupanti Cotspin Ltd.
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT to Zydus Hospira Oncology
                Pvt. Ltd.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT to Toyota Form Pvt. Ltd. &
                Fuji Silvertech Pvt. Ltd.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->code auction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PROJECT ON FIRE ALARM SYSTEM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PROJECT ON SOLAR CHARGER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Paricharcha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bazaar on Campus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Our future in Mechatronics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Vision system</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d9f98444431ad6d15bece12ef8d99f01.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE FOR DEGREE AND PG COURSES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID =19 Pandemic Epidemic disease sanitizer
                preparation at our college laboratory</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Vehicle Diagnostics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industrial Automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Campus to Corporate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industrial Expectations from Fresh
                Engineering Graduates</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Fundamentals of Offline LED
                Drivers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Enhancing your product design with
                simulation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Business Development and
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Design Thinking in Automation
                System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON ELECTRONIC PRODUCT DESIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on AR & VR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->android application development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technotuber</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Career Leadership skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;The Art and Craft of
                Teaching Entrepreneurship\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session by Mr. R K Chopra on Unleash
                New In You</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87f19be1de0f3916dc8abe49084d7bdf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ERODE SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAYS NATIONAL LEVEL WORKSHOP on HOME
                AUTOMATION USING ARDUINO UNO R3 on 06.02.2020 & 07.02.2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34; Innovation and Start-up
                Ecosystem in India : How global corporates can tap into this ecosystem\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Intellectual Property Rights(IPR)
                and Standardization</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Musical Motivation session by Mr. Vineet
                Tandon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3107a7c77c6ce6de1b0b198a34c0982.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Muthayammal Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->24 Hour Hackathon contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3107a7c77c6ce6de1b0b198a34c0982.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Muthayammal Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Elocution Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3107a7c77c6ce6de1b0b198a34c0982.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Muthayammal Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Animation contest </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Scope of CAE in Core Industries
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Meeting/workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on “The future of Making” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Entering a New Era Using
                Simulation Technology for Aircraft Electrification</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Change Investment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Angel Investment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87f19be1de0f3916dc8abe49084d7bdf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ERODE SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAYS NATIONAL LEVEL WORKSHOP on HOME
                AUTOMATION USING ARDUINO UNO R3 on 06.02.2020 & 07.02.2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online session on Innovation opportunities to
                tackle COVID 19 using Robotics and DATA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on Productivity through energy
                conservation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Techniques of Molecular Biology
                and Cell Culture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SOFTWARE DEFINED NETWORKING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Workshop On Cell Proliferation Assay
                Technique</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e466e07e48c245acf3d190edd6941ad8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Post Graduate Government College for Girls Sector-11">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Signing of Memorandum</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar in IPR and Patent Drafting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Foundry 4.0 – Opportunities and
                Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Industrial IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93c95772e881615ccf1e7ad8819bb789.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Srinagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COVID19 Open Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Leadership Skills – How to be an
                inspiring leader</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Innovation and startup- How Does
                an Idea Become a Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Spirit of Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Design Thinking – Method,
                not Magic\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on JAVA Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on Innovation and Start-up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Expert Talk on Entrepreneurship
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Talk on Aircraft Icing Effects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit to Water Treatment Plant</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENtrepreneurship Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Expert Talk on CAM/CAE/CAD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Session on Product Development,
                Technological Entrepreneurship &Technology readiness level</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Web Development Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 9</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 8</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 7</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial perception of Engineering Students
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk in Lock Down. Novel COVID 2019
                Boon & Disguise. A Journey within Lockdown</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Conclave 1.0 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON “ENTREPRENEURSHIP AS AN ALTERNATE
                CAREER MODE”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entreproficiency Bootcamp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32511849e978e1d10bca8b0d213273a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mehr Chand Mahajan D.A.V. College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on NEAT by Prof M.P. Poonia,
                Hon\&#39;ble Vice-Chairman of AICTE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 6</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 5</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Address by Dr. Abhay Jere, Chief Innovation
                Officer/MHRD\&#39;s Innovation cell</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Address by Dr. Abhay Jere, Chief Innovation
                Officer/MHRD\&#39;s Innovation cell</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on NEAT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Workshop on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fostering a culture of Innovation: A workshop
                cum competition on Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d0279fc95da3b1b0cb5f5c16fb98ac95.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SCHOOL OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virinchi 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a66d099f46cecaee1dd9f8372218d22.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology, Design and Manufacturing Kancheepuram">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Making the invisible visible</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial journey </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HINDUSTAN INNOVATION CHALLENGE V4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d03b49c16a000e8e4f44105fe280466.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="L. M. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Kolkata Kreate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5th Annual Symposium on Advanced Techniques
                in Biotechnology: Applications & Prospects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CONQUER COVID-19 HACKATHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Leadership Talk Series 1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Placement Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Roadshow BIG BIRAC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INK@WASH participation by CIIC Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Co incubation Agreement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Incubation Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chief Innovation Officer Training Workshop
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interaction with Keiretsu Org</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Incubation Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bio Cluster Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CIIC Formal Inauguration Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation of New Start Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation of New Start Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->interactive Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Co incubation Agreement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IVCA Conclave Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Co incubation Agreement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation of New Start Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Incubation Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation of New Start Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5G Panel Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation of New Start Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Roadshow of Medtech Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Why Siri will Murder us All: The Ethics and
                Politics of Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Role of Mathematics in Sustainable
                Development of Economies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Is India Too Ambitious For a $5 Trillion
                Economy?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Presentation Women in Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on \&#39;Fast Fashion and need for
                Green Clothes Culture\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Ambassador Training Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership talk with Mr. Abhishek Suryavanshi
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Hackathon at Massachusetts Institute
                of Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Android Application Development
                Fundamentals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techno India Online Master Class Series -
                Event 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techno India Online Materclass Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A TALK ON ANGEL INVESTORS AND PRIVATE EQUITY
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation of New Start Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/840782e7ab3bf87a8bff19457fcfc1f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shrimathi Devkunvar Nanalal Bhatt Vaishnav College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Jute products training </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87f19be1de0f3916dc8abe49084d7bdf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ERODE SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy Audit and Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87f19be1de0f3916dc8abe49084d7bdf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ERODE SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Disinfection Tunnel</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b5ef4e00f3f0f9064cf2a0c6a3c0ee3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RVS College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideas to hack corona - Ideathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special Session on latest technology in
                computer science </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f3f28c683287054a5cf8ee6a19b9a29.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Presentation on Design Thinking and
                Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STARTUP ENTREPRENEUR EXHIBITION-2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE-DAY WORKSHOP ON Prior art search – Patent
                and non-patent literature</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE-DAY SEMINAR ON Protection of artistic and
                musical work in India – Copyright Law </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/840782e7ab3bf87a8bff19457fcfc1f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shrimathi Devkunvar Nanalal Bhatt Vaishnav College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Beautician course</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Demonstartion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FORGE Ideathon - Fighting Corona </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dr. Abhay Jere, Chief Innovation Officer,
                Ministry of HRD, Govt of India.National Coordinator-Project \&#39;Manav\&#39;-Human Atlas Initiative</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Marathi Bhasha Diwas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dr. Abhay Jere - Talk - Dr. Abhay Jere, Chief
                Innovation Officer, MHRD’s Innovation Cell on 30th March 2020 at 11 AM.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The AI Conclave 2020 – Redefining the Future
                of Enterprise</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Outcomes- Based Education </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indian Academy of Management’s research
                workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Trends on Advances in Materials
                Science” (ETAMS2020)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b228d8fcef2ec3ee13a3afe3121f944.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee University of Information Technology Waknaghat">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology Incubation Entrepreneurship &
                Development Cell: Recruitment Drive 2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cancer Awareness Programme </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical and nontechnical event-Biohipster
                4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on Workshop on Critical care Equipments
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCB Layout Designing, Etching And Soldering
                the Circuits</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a787b43bca51da6a621d927156bb4e6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nowgong College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARENESS ACTIVITY FOR COVID-19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bio-Economy & IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Field Visit to Chips Manufacturing Unit
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plant Tissue Culture Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->New Product Development - VENTILATOR
                SPLITTERS AND FACE MASK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Promoting Business Ethics and management
                lessons from Movie Screening</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Income Tax E-filling </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on Union Budget 2020-2021</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Effective Management Skills
                for Success</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d0279fc95da3b1b0cb5f5c16fb98ac95.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SCHOOL OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bio and Tech Hackathon- 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Best Out of Waste</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DISRUPT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Communicating to Connect</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Marketing for a Succesful Enterprise
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IENx-The Untrodden Path</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Road to Ideas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Stock Market & Investment
                Decisions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stock War - Bulls & Bears Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ethical Hacking - Decrypt Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Hackathon - Demand Prompt
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Failed Start-up & Crisis Management
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Co-Founders / Best Entrepreneurship Team
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pitchers - Shark Tank Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session with Mr. Mickey Bedi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart India Hackathon Internal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Silicon d\&#39;Art 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Elektro Vakbeurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Case Study Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Pitching Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/664cef67d00bc37c7f190c77176cf2c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIIT University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fundamentals to prepare a Business Plan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d0573e92cd2858cdb77cb8261e554bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARMY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on the topic \&#34;Channelising
                Research: The Illustration \&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INSECT - QUIZ</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electronic Banking Awarness Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d58a832b7dcdf90d05e6f31cb19606ca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="College of HomeScience Nirmala Niketan">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Balancing roles for women in business and
                social work with personal life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Quad - Copter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Incubation Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, water conservation and
                environmental sustainability</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation using Phython </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk sessions by Alumni of the campus
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->REPUBLIC DAY CELEBRATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09033c64d9932ba8e16df1ead47ade68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inauguration of BMESI Students’ Chapter &
                Seminar on Significance of Non-Linear Biosignals in Diagnosis & Prognosis of Human Diseases</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->New Product Development - Hand Sanitizer for
                Covid - 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Remote Learning of R programming in
                association with IIT Bombay, Spoken Tutorial </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f46a6926ab2ebaa6b0723a8142eb873f.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->R-Expo 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f46a6926ab2ebaa6b0723a8142eb873f.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PROJECT EXPO 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f46a6926ab2ebaa6b0723a8142eb873f.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH2020 _INSTITUTION LEVEL INTERNAL HACKATHON
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Toyota Technical Education Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Box Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Electronic Hardware
                Designing for Industrial Needs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Hunger Games</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Critical Thinking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent It Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lean Canvas Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MACHINE LEARNING AND TENSORFLOW</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/708c1345abd2327de1cc54d72f393bb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DON BOSCO COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International conference on Emerging Global
                Trends in Engineering and Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Inter Collegiate Management
                Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Accenture Hack Diva</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E – preneur Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Challenge Event</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Opportunities in Fermentation
                Food Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Workshop- Machine Learning
                Using Python Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon for SIH 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Experience Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CSR Activity: National Girls child day-Beti
                Bachao Beti Padhao</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Village visit for identification of societal
                problems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Dynamics of Indian Taxation system
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on ‘Firmware Update over the
                Air’ (FOTA)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FOURTH INDUSTRY ACADEMIA MEET -2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities in U.S.A for higher studies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on ‘Pathway for pursuing Higher
                Education Abroad’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on ‘International Banking and
                Personality Development’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on ‘A Perspective on Higher
                Studies’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-WEEK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d12575dffa16bd474af6122508e849a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nagesh Karajagi Orchid College of Engineering and Technology, Solapur.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Technical Symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days workshop on Artificial
                Intellligences & Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Seminar on Energy and Environment
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f7cb79aafaddb85590db758b19d6827c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. Abdul Hakeem College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Certificate Program in Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit 5</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Envoyage</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit 4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit 3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring Seminar for Entrepreneurship and
                Innovators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit 1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Final round of 14th Avishkar Research
                Convention (2019-20) (Medicine and Pharmacy)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture Series 4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture Series 3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/55f49c678ea15f632e678f30a86469f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIMS ENGINEERING MANAGEMENT AND TECHINICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz on Startups & Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture Series 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture Series 1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry - Institute connect</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart ANTENNA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MINI MAKEATHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideathon : Business Plan Competition to
                Invite Innovative Business Models </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Quality Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on Training on Leaf Art</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Honey Extraction and Fumigation in the Honey
                Hive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/55f49c678ea15f632e678f30a86469f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIMS ENGINEERING MANAGEMENT AND TECHINICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation for Entrepreneurs & Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sustainable Design and Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Seminar on Digital Operations and Data
                Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneurship opportunities in
                FRP products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Empowerment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “Real Time Data Processing
                and Analytics”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/840782e7ab3bf87a8bff19457fcfc1f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shrimathi Devkunvar Nanalal Bhatt Vaishnav College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Artificial intelligence club</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Entrepreneurs Association Conference
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a680848122c94b65a3866d61f809816d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGAN INSTITUTE OF MANAGEMENT STUDIES TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Founder series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a680848122c94b65a3866d61f809816d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGAN INSTITUTE OF MANAGEMENT STUDIES TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Founder Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/55f49c678ea15f632e678f30a86469f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIMS ENGINEERING MANAGEMENT AND TECHINICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Issues in IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/253aefa1dc52c4599320f7913cbeb07a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Banaras Hindu University Varanasi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->test</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Cyber Security in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty visit to Incubation center</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CII Southern Region - Annual Regional Meeting
                & Summit on ‘Changing face of Business’ “Staying Ahead in Disruptive Times”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty development program on Innovation and
                Entreprenuership</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Anveshana-Sceince Engineering Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a680848122c94b65a3866d61f809816d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGAN INSTITUTE OF MANAGEMENT STUDIES TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Founder Series Event</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Two day workshop on Incubation and Startups
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IFEES / GEDC / IUCEE Global Webinars for
                On-Line Engineering Education \&#34;Call for Webinars\&#34; Responding to the Challenge !</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference: Advances in Networking,
                Computing and Security (NCANCS-20) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop: From Campus to Corporate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on:How to Develop Innovative
                Idea & Entrepreneurship Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lone wolf Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Paper Presentation : Recent Trend in IT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Code Blasters</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/33c1f26188cc5bbf9a4fe3a32ecf6ee9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shaheed Rajguru College of Applied Sciences for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67056eb08ab3a825e3a2d54fa41e2bb1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. RAMAKRISHNAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SCIENCE EXPO- 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f7cb79aafaddb85590db758b19d6827c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. Abdul Hakeem College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f7cb79aafaddb85590db758b19d6827c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. Abdul Hakeem College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f7cb79aafaddb85590db758b19d6827c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. Abdul Hakeem College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Entrepreneurship Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d8e935567c066a4aadb90a591154000.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TAGORE INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWAKEN - THE ENTREPRENEUR IN YOU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UIF Regional Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON 3D PRINTING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Solar Energy and
                conservation of Solar Eenergy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“BITCOIN & BLOCKCHAIN: CURRENT AND FUTURE
                DIRECTIONS\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Workshop & Zonal Championship competition”
                in Association with Fest E-Cell( IIT Bombay)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Communications
                and Cyber- Physical Engineering (ICCCE-2020)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Learnathon: Robotic Process Automation
                (RPA)”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk and workshop on Entrepreneurship and
                Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on IPR and Copyright For M.A</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on IPR and Copyrights For Msc </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BEYOND THE WALLS: USING THE OPEN ENVIRONMENT
                FOR DESIGN THINKING AN EXERCISE IN BLENDED LEARNING FOR ARCHITECTURAL DESIGN STUDIO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to KAynes Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “New venture creation and
                business opportunity identification for millennial entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a7356e57b83980329521f601fed4387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two week workshop on Virtual Reality and
                Augumented Reality</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEATHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International conference INNOVATIONS IN
                BUSINESS ENVIRONMENT: ECONOMIC GROWTH AND SUSTAINABLE DEVELOPMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Lakshmi Machine Works</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PANEL DISCUSSION ON CHANGING LANDSCAPE OF
                ENTREPRENEURSHIP: OPPORTUNITIES AND CHALLENGES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start Up to Scale Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Varma Ready Mix Concrete plant
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to MSME Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Role of Gas-Phase Air Filtration for
                Improving IAQ</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to TNSCB Construction site</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Five day STTP on “Advance Analysis of
                Wireless Communication and Soft Computing”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Summit,IIT Bhubaneswar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Sri Vari Ekantam (Luxury
                Villas)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy Conservation Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Sree Daksha \&#34;ARCIS\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Need for Awareness and Employability skills
                in Non-Destructive Evaluation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Application of Artificial Neural Networks in
                Medical Imaging”` </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “Electromagnetic Fields and
                Waves”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on “Electronic Circuit
                Analysis”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A 2 Day Workshop on AI & ML</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on ‘Data Science (AI)
                Applications’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“INDUSTRIAL VISIT TO GRIP STRAPPING
                TECHNOLOGIES LIMITED Nuthankal Village, Medchal IDA, Hyderabad </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d86cf408d3f7dc523e3cfe7eb5b1352c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Law University Odisha">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Worshop on IP Rights, Management and
                Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vaniga Vaibhav\&#39; 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Womens Day celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Handloom Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Bazaar 3.0”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09033c64d9932ba8e16df1ead47ade68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Seminar on Design Thinking Tools and
                Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Centre for sustainable development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Grand Challenge 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture on “HOW TO PLAN FOR STARTUPS AND THE
                LEGAL AND ETHICAL STEPS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Term Course on \&#34;Advanced
                Measurement Systems and Its Applications\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop On “Non Destructive Testing
                (NDT)–Hands on Training”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Engineering and Operations
                Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Talk on “Air Conditioning: Concepts
                and Applications”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Week Of Learning cum Webathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DSC NIT Jalandhar in association with GDG
                Jalandhar organized a Firebase Study Jam 2020.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on \&#34;Chemical,
                Bio & Environmental Engineering\&#34; (CHEMBIOEN-2020) organized by the Department of Chemical
                Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essay Writing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SCience Day Song</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women\&#39;s Day celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Impact of Automation in
                Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Electric Vehicle Dynamics &
                Smart Connected Automations in Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Advances in Automotive
                Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Logo Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Paper Presentation : Innovative India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Presentation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Instrupreneur’20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Prakash Gears</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Paper Presentation: Recent Innovations in
                Biomedical Instrumentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations & Opportunities in IT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Adhya Fabricators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent innovations in sensor technology </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Karivaradhan Training
                Institute</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to Aqua Sub Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Lakshmi Machine Works</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAUR URJA VEHICLE CHAMPIONSHIP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CEA FEST 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Elsevier Author Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Trends in Electrical Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovate Global 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Applications of Mathematics in Engineering
                Field</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Three Day Boot Camp on “Application
                Development & Deployment”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on New Product Development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Code Champion </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Zoho Creator Certified Associate Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Preparing \&#34;Industry
                Ready\&#34; Engineers.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop on Network Simualtor-3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON SOFTWARE PROJECT TO PRODUCT
                DEVELOPMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/55f49c678ea15f632e678f30a86469f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIMS ENGINEERING MANAGEMENT AND TECHINICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quality Management System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Exploration of Electric Vehicle and its
                Components\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An equal world is an enabled world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz & Talk on Innovation in Tourism</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Woman Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Woman as Manager & Innovator</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation & Oppurtunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a46490aa92c9ea820b294fe1cd0ff7c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Avanthi Institute of Engineering Technology, Chirukupally(V), Bhogapuram(M), Near Tagarapuvalasa Bri">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9788b97016f35546e1c36502504dc924.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA INSTITUTE OF PROFESSIONAL MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essentials of Vastu-Shastra for Civil
                Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a941a88daf5861aabaec1d5590e2ed08.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Young Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation day celebration - second half</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Industrial Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Image and Video Documentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Building Information Modelling
                (BIM)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Technical Symposium
                TECHCIV2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cube Crash</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Presentation on Case Studies / Articles /
                Blogs regarding INNOVATION & SCIENTIFIC ACHEIVEMENTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAD PRO 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Outreach Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mitsubishi Electric Cup 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2a27bbb9c13f5f402f616c9d62ba849e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DIT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneurship & Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fe907ba6e7a0bc84ab8023095f7c078.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pyramid College of Business And Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mock Parliament Session on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Confluencia 2020- Annual Business Summit- IIT
                Roorkee</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VIT HACK 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Convention 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Lean and Six Sigma</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon - Devhack 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Presentation - Visai 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solve4Bharath - Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level IDEATHON contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical event - KCT Yugam 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on ‘Career prospects for Electrical
                Engineers in Automotive Electronics’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HR Convention</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Sustainable Environment
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science day \&#34;Women in
                Science\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Journey \&#39; 20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2a27bbb9c13f5f402f616c9d62ba849e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DIT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5e87cc11aaef9d36b47b6125133a92be.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Chaitanya Institute of Technological Sciences">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal College Syllabus App</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL TECHNOLOGY EXHIBITION 2020 CUM
                TRAINING PROGRAMME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->377th Newton’s Birthday Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Green Day for Sustainable Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f40f7dc01e2fadb649da0fc7cf6dea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="McGan&#39;s Ooty School of Architecture">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tanjore arts painting -Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop and Career Guidance on Electric
                Vehicle</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOT workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ignition Invention</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation and Design thinking workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Program on “IPR search, Filing,
                FER and Granting possibilities”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to Edgetech Air System
                Private Limited</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CEANS -2020 Technical Symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->OnLoad 2.0 Hackathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Devices, Circuits
                and Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> INDCON 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon - AIC RAISE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in REWOP\&#39;20 - Technical
                Symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IEEE - Student Branch Membership Initiation
                Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Air Compressor with CFD
                Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CHALLENGES AND OPPORTUNITIES IN ELECTRIC
                VEHICLE DESIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology based Entrepreneurship development
                Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a2b94a11e1fd84a1bca1f34fae54fa03.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR BABASAHEB AMBEDKAR TECHNOLOGICAL UNIVERSITY LONERE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Scince Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Idea Tinkering and Product Conceptualization
                Workshop”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day: Herbal Plant Exhibition
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day:Awareness Program On
                Iron Deficiency Anemia</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/99e0f20f270775c14bc59fb327c176e9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S R ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Camp Agri-Food Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day: Nutrition for Cancer
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day:Session on Introduction
                to Gene </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Ambassador Training Programme
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Foreign Delegation Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on How to start a New Business
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Earn while you learn</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day: Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day: Promotion Music</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->project expo 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->mini project expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conclave on Advances on Solar System ,
                Electric Vehicle and awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on “Recent Trends in pipe
                Industries”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Talk on “Air Conditioning: Concepts
                and Applications”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/563e736aabc83009d62a1be364b35176.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IFET COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internet of Things (IoT): A Vision and Future
                Developments</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KEC Spark Fund</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09886d07014301d1d888613d01739cfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MS Ramaiah University of Applied Sciences">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women\&#39;s day celebration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09886d07014301d1d888613d01739cfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MS Ramaiah University of Applied Sciences">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three day workshop on Pharmaceutical Modeling
                and Simulation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09886d07014301d1d888613d01739cfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MS Ramaiah University of Applied Sciences">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Natrional seminar on Ethics in Acdemia
                and Research </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09886d07014301d1d888613d01739cfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MS Ramaiah University of Applied Sciences">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Sceince Day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbac62ecc6ea91f2162948811b865aa6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AL SHIFA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Celebration 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights - Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training/Workshop on Embedded System Design
                and Internet of Things at L & T Corporate-Technology and Engineering Academy, Madh Campus.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights -
                Biotechnologist</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Eureka Hackathon\&#34; - A National
                Level Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on Training on Python Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day : Math Modeling
                Exhibition - 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/563e736aabc83009d62a1be364b35176.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IFET COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Impact of engineering design in
                industry\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day: Intra Departmental
                Science Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days women empowerment on CSWIP Visual
                Inspection</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/623610f64a1a59445fc5d3060929266c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chevalier T.Thomas Elizabeth College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CALM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/623610f64a1a59445fc5d3060929266c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chevalier T.Thomas Elizabeth College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture series on Innovation Entrepreneurship
                IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/623610f64a1a59445fc5d3060929266c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chevalier T.Thomas Elizabeth College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Promotion in Innovation and Entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Series-Pro Talks-The Innovative
                Management Principles: Work 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fe907ba6e7a0bc84ab8023095f7c078.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pyramid College of Business And Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hack PCBT 1.0 Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Series-Pro Talks-Web Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Series-Pro Talks-Network Software
                Security and Malware Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Series-Pro Talks-Cloud Computing
                using Artificial Intelligence and Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day : Bhauthika 2020 State
                Level Technical Symposium</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Series-Pro Talks-Artificial
                Intelligence in Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Inter Departmental
                Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Series-Pro Talks-Automotive
                Electronics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY NATIONAL LEVEL WORKSHOP ON
                DEVELOPMENT OF IOT APPLICATIONS USING CC3200</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Series-Pro Talks-Industry Internet of
                Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Exhibition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/86d12341d55a48204d72859433bde388.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KSR INSTITUTE FOR ENGINEERING AND TECHNOLOGY, NAMAKKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on “ Secret Manthra for
                Successful Entrepreneur”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Experience in Startup’s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Initiative for the Development of Innovative
                Business Models</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ec58cb047f0dda99a6275dd2e93af604.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology Kalyani">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essay writing competition on \&#39;Innovators
                in India\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/973f573917a12c542bc2ec561567c546.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sarada College for Women(Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational session on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on \&#34;Automotive Skill
                Enhancement for Women\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3rd INTERNATIONAL CONFERENCE ON CURRENT
                SCENARIO IN PURE AND APPLIED MATHEMATICS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on LaTeX - Effective Writing
                on Thesis and Research Paper using LaTeX (Hands on Training)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Symposium MEGNAZ 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6499599b1aeb3435b5e95941ada03331.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KNOWLEDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Technical Symposium of
                “ECSTASY - 20”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c122eb7052f12292f9897a9b731f0262.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JYOTHY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essay and Poster Design Competition for
                Science Day 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A NATIONAL LEVEL PROJECT CONTEST FOR
                POLYTECHNIC STUDENTS ‘INNOVATIVE 2K20’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EAC Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WOMEN EMPOWERMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Conference On Business Models
                For Sustainability And Transformation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Workshop on Computational
                Intelligence and Automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/24d37fd5353bd7d2a1e8e168dac075d6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.M.S.COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EMI/EMC -oppurtunities in industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->State Hackhathon Winner</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Path-breaking Innovations /scientific
                achievements for India for mankind: Essay writing ; National Science Day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Path-breaking Innovations /scientific
                achievements- A boon or curse: Debate ; National Science Day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Path-breaking Innovations /scientific
                achievements for India for mankind: Poster Designing ; National Science Day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Analysis using EXCEL and SPSS for
                application of Biological Science Research - 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARENESS WORKSHOP ON BIOCOMPOSTING AND
                BIOENZYME PRODUCTION FOR RURAL COMMUNITY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition cum Sale on “Indian Tribal Art and
                Culture” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6c9a251fcd8626283e6e607f73616c24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTHAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Seminar On “Entrepreneurship
                With Mind Management Strategies”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Contest-Women Wafety Mobile App</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Krishinnov - 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e42bdc99577982c525609ff137df33b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARISHI MARKANDESHWAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Day Celebration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8c7cf896871eb0afa4ffe1c52b974691.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LAKSHMI NARAIN COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unmanned Aerial vehicles and Industrial
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Hacks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9635d3013ecd6014b56ef2be526f2fc4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on \&#34;Recent Growth of Cloud
                Technology Solutions for Global IT Industry\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensitization Program of “Innosphere’’–
                Electronics Incubation Business Facility of PSGSTEP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f2cc631309d35904d71755668235f24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University College of Engineering Nagercoil">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL SCIENCE DAY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c4c57f9bd770f640fa88f772281216e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dahiwadi college Dahiwadi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creativity and Idea Generation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Innovation Ambassador
                Training Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Innovation Ambassador
                Training Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Innovation Ambassador
                Training Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Innovation Ambassador
                Training Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National science Day: Competitions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National science Day: School Linkage Program
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d78202f4497c780c9142a17315aec486.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mount Carmel College, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conduct a competition for students on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Day: Slogan Writing Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->industry 4.0 & IoT life Cycle</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women in Data Science</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c5a003239446424d554cf8202d008753.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SOLAMALAI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Viyugam 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDTP on PERES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ef96a7c8e8e72b10fa275302550bc7be.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="College of Computing Sciences and Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Symposium 1.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b343fd8f2aca870b555c963a3bb803dd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SANT LONGOWAL INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Atmospheric challenges and
                satellite technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c4c57f9bd770f640fa88f772281216e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dahiwadi college Dahiwadi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Idea competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a9d3dbeaf35844501e9da60acee859a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SILICON INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on Training on 3D Embroidery Painting
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student\&#39;s Participation in Hands-on
                Workshop, Nellai Bookfair 2020, Tirunelveli</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student\&#39;s Participation in Innovation
                Festival 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9394b906f85a6a9a99f7c463663aaf31.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tamilnadu College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAYS TRAINING PROGRAM ON ADVANCED
                INDUSTRIAL ROBOTICS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/55f49c678ea15f632e678f30a86469f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIMS ENGINEERING MANAGEMENT AND TECHINICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Understanding Creativity and Innovation-
                Applicability to Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Wings2Vision 2020: Training based Business
                Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster release of \&#34;IDEATHON 2020,IIC
                National Innovation Contest\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to ONGC, </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program titled
                \&#39;Essentials of Entrepreneurship\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE-DAY SEMINAR ON CAREER OPTIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Product and Business Development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Spring Framework</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Guest Lecture on Spring Boot
                Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a02d49565454d7181cded39f3b82b7ad.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bishop Heber College (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a02d49565454d7181cded39f3b82b7ad.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bishop Heber College (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a24e7600358ae749541247c1ba5b38b9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GCRG MEMORIAL TRUSTS GROUP OF INSTITUTIONS FACULTY OF MANAGEMENT, LUCKNOW ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a02d49565454d7181cded39f3b82b7ad.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bishop Heber College (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Idead -Plan Contest 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs Story</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/38f3dcb0be87593af442943bd296cf40.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Technology Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Display</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Overhead Tank at Karaikal for
                the Dept of Civil Engg. Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STARTUP CONCLAVE & INNOVATION AWARDS –ERODE
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to build effective presentations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TRAINING PROGRAMME on SOFTWARE APPLICATION IN
                CIVIL ENGINEERING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Costing & Revenue Model Building</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lean Manufacturing in Automobile Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf629ae62fd48adf21d5a0aff5a2a64b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAUTILYA INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Opine Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SMART INDIA HACKATHON-2020 FB LIVE SESSION
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Waste To Wealth Expo 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a66d099f46cecaee1dd9f8372218d22.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology, Design and Manufacturing Kancheepuram">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day - Research Scholars Day
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise One Day Workshop on Problem
                Solving/Design Thinking/Ideation Workshop/ Campus Hackathon etc.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7682ef75b7383fc2f85b89bdb4bee8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANDIAN SARASWATHI YADAV ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Quiz Competition - Innovation and
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHXIBIT2020 -miniproject</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IC3IOT 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop On Digital Forensics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit 2020 to ISRO, Bengaluru and
                Satish Dhawan Space Centre, Shri Hari Kota</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to Redhat Linux</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on Workshop on AWS and Docker</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MATLAB fundamentals with Arduino Integration
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Impact lecture series on \&#34;Executing a
                successful startup- Entrepreneurship\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patenting your inventions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One DAY WORKSHOP on “Intellectual Property
                Rights & Innovations” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur skill Development program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Winter Coding Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prometeo 2020 - Technical Festival of IIT
                Jodhpur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MSME SCHEMES AND ACTIVITIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Literacy Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GENERATE YOUR BUSINESS IDEA WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECH FEST-TANTRAZ\&#39;2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4af388008f53e1491fc08ce2ce4e0299.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DON BOSCO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on Innovation and Product Development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->All-terrain Vehicle Design for SAE BAJA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Competition and Exhibition
                -VISAI 2020.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Neuromorhic Computing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Blockchain-based protocols in IoT
                systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b10577bc294f1cb0f8568a1b0340cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHREE RAMCHANDRA COLLEGE OF ENGINNERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development and Guidance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My story: Motivational Speech by
                Entrepreneurs </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Desh ki Mitti</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbac62ecc6ea91f2162948811b865aa6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AL SHIFA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women in Science and Education (WISE): Module
                1 – Meet the WISE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Communication Skills for Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Social Network Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Business Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a787b43bca51da6a621d927156bb4e6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nowgong College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY NATIONAL WORKSHOP ON CREATIVE WRITING
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->College Bazaar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Woman Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“INTERNSHALA INTERNSHIP AWARENESS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The power of positive expectations in
                entrepreneurs life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual property rights workshop (Patent
                search, filing, and drafting for beginners)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tamil Nadu Student Innovators (TNSI)-2019
                BOOT CAMP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Workshop on “Application of
                Artificial Intelligence/Machine Learning in Healthcare”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2-day Study Tour (Munnar, Kerala)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COMBUST 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Celebaration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f7c4771e6123dfbe6b03e81b5ea84fee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The Institute of Chartered Financial Analysts of India University, Sikkim">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration on the occasion of National
                Science Day- 28th Feb 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d0573e92cd2858cdb77cb8261e554bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARMY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cceef3efd9636e66a07901060ea2e9ac.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R. College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Nuclear Technologies for better
                quality of life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/58f33f09c84570a2e08a07021caa9f75.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNICAL TEACHERS&#39; TRAINING & RESEARCH, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/58f33f09c84570a2e08a07021caa9f75.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNICAL TEACHERS&#39; TRAINING & RESEARCH, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to technology training centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/58f33f09c84570a2e08a07021caa9f75.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNICAL TEACHERS&#39; TRAINING & RESEARCH, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EAC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Intellectual Rights </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAEISS STUDENT CONVENTION TIER II-2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NSTEDB DST NIMAT EAC </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NSTEDB DST NIMAT EAC </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAMPARK- a Venture by Verizon and KMIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->changing faces of cyber security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level awareness program (NLAP) -2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4057c2dc70893ae24a623cba1134122c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARAT INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT TO VILLAGE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d557650b821acb80a02d6a08fe6cd0a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pravara Institute of Medical Sciences, Ahmednagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Can Doctors become Scientists? Making a
                career in Medical Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dbfb13e64435ff18de1af12de2ea283d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRM INSTITUTE OF SCIENCE AND TECHNOLOGY RAMAPURAM CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideas for New India 2020 - Preliminary round
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e04717f985f6bb46dc7f44390e549e27.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DPG Institute of Technology and Management">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field trip to Auto Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership talk by Dr.Abhay Jere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Experts Panel Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACKATHON’20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8c7cf896871eb0afa4ffe1c52b974691.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LAKSHMI NARAIN COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->paper presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->helping local schools -NSS activity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inaugural of Satellite Club</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on switching circuits</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition by budding entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days workshop on Entrepreneurship
                Awareness Camp (EAC)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Six days workshop on PCB design Using Orcad
                PSPICE and Applications of MATLAB </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days skill development program on testing
                and servicing measuring equipments for technical staff members</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP AWARENESS CAMP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Program for
                Women</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intercollegiate Innovative Idea Presentation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c4c57f9bd770f640fa88f772281216e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dahiwadi college Dahiwadi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to SIGNAL AND
                TELECOMMUNICATION WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3f6732992f4c9e04417b057a82c76af.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GUDLAVALLERU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An expert talk on Entrepreneurial skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5fc707f20c3be01eb8b9ce69fa3c8b13.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIT Silchar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INVITED TALK ON DEEP LEARNING </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackthon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI Boot Camp & Ideathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LOGO Competition for Fire & Safety</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5edefd40a4b5d3c3e4c6d541e68f9d57.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Ayyappa College for Women, Ayyappanagar, Chunkankadai - 629 807, Nagercoil - 629 807">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->international conference on innovative trends
                in technology and educational research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/502ae28cbbee442893a543d3cb73f6fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.V.C. College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights & Patent Filing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Environmental Protection</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IOT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chem-a-thon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Carnival IC 3.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be000e56b2481f434ddf61fd23e66cf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAMMAL COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISRO EXPO 2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of Patent Drafting for Innovation
                and Commercialization</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The resurgence of Artificial Intelligence:
                Opportunities and Apprehensions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on “Radio Astronomy”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Artificial Intelligence: Concepts, Technology
                and Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NON CONTACT VOLTAGE TESTER PROJECT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->project on laser security system</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launch X Budding Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63e094b6d714dc2c8c5fccc485bfe06a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LUTHFAA POLYTECHNIC INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->scopes on automatic power factor
                controller,energy conservation building code and familiarisation with boiler & turbine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Road map for summer Internship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Back to Campus </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Clinical aspectes for Biologics in Drug
                Discovery</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/988a718d24fd0c21a1c6f5bd324338e1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.P.C. Mahalaxmi College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovate & Inspire</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Facebook Live Session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Session With an Entrepreneur
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Seminar on Entrepreneurship
                Development in Establishing Nutri-Cereals and Food Processing Industries </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Go-Kart</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Trends in Security Market</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to start a learning center which caters
                to the specific learning needs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Customs Day 2020- Cargo
                Consolidation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecturer on Skill to Crack IT
                Placements</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Career Planning and Development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b5524883abe91398d93c0171cb448371.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Yenepoya (Deemed to be University)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science Day-2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/794b3e2a5ae57333735afa17f79a24ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GND University College, Near Govt. College of Edu., Ladowali Rd.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on \&#34;Disrupt To Surge\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/794b3e2a5ae57333735afa17f79a24ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GND University College, Near Govt. College of Edu., Ladowali Rd.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPERT TALK ON \&#34;MEDIA OWNERSHIP IN
                DIGITAL ERA\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4cb6caa808618bb732ec19e163d8d17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNEHACHARYA INSTITUTE OF MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->iNNOVATION AMBASSADOR TRAINING </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Overview of International Trade</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on \&#34;Entrepreneurship as a way of
                life\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/093349a2a279222fcb5a2ac5566f91e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GEETHANJALI COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in YIP 2019 MAESTRO CHALLENGE
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Natural language processing for
                Students” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship- On Job Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Workshop on Spinning Khadi
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial: Exhibition & Promotion of
                Khadi- The fabric of Indian Independence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/229dbe738fe07005ac88aff1a3f3db6c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Velalar College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive session on Career Planning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Understanding Rural Economy: Bio gas
                Preparation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6a1605bd379fa1d02f48f4d10afc8b2f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="OM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Employability skills development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Cafe #2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Technical Symposium 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Voters Day 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d88a232028b9cb30c7d717d41c5f199.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PARALA MAHARAJA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National seminar on Natural Resources,
                Environment and Climate Change</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b228d8fcef2ec3ee13a3afe3121f944.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee University of Information Technology Waknaghat">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Session with Dr. Abhay Jere</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Retail Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aptitude Test (Additional Skill Acquisition
                Program)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->of Meditation is important for balance life.
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->New Education Policy and the Support for
                Entrepreneurial and Managerial Development of MSMEs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI & ML for Product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6499599b1aeb3435b5e95941ada03331.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KNOWLEDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EST MEET 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Industrial Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdded087b6aa9a5752a63ad67ac66e7e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING MANAV RACHNA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Yash 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Diagnostic tools for consulting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e7e3eb6eea94ea4735118bf316d683e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KGRL College(A)  - Degree  & PG Courses">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Trends in Space Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Unmanned Vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b343fd8f2aca870b555c963a3bb803dd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SANT LONGOWAL INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days workshop on IOT Using Arduino</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a787b43bca51da6a621d927156bb4e6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nowgong College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8004293bc33463623bda808ada07ba1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH HIGH TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Food Carnival </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8004293bc33463623bda808ada07ba1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH HIGH TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart India Hackathon 2020 Updates </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on AI and Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drone for specific application</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->seminar on education abroad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive session of Greatness in
                Innovative Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->career guidance programme on higher education
                in australia</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Quest 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur\&#39;s Experience & Software
                Design Thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal Enlightment, Startup & YUVA </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->30 Hours Certificate Course on
                Entrepreneurial Skills - Turn Your Passion into Profession - 25th to 30.11.19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Copyrights and IPR in Maths</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Are you Ready for Industry 4.0 - Today\&#39;s
                and Tomorrow\&#39;s Technology?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/794b3e2a5ae57333735afa17f79a24ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GND University College, Near Govt. College of Edu., Ladowali Rd.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Co-creation workshop on Sustainable Solutions
                For Electronic Waste Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/965acc40303e14bef2fdc4ef0d64e207.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NADAR SARASWATHI COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit - Engineering Products in
                Agriculture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/965acc40303e14bef2fdc4ef0d64e207.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NADAR SARASWATHI COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Design - Making Artificial Ornaments
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Teaching for Understanding in Engineering
                Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->APPLICATIONS OF ARTIFICAL INTELLIGENCE NAD
                MACHINE LEARNING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> MACHINE LEARNING AND APPLICATIONS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/848c82596b7539a46e055dba1a616c51.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->City Champion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/076c8690c134f458aba2e803c816cb49.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in Product Designing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WOKSHOP ON FORMATION OF SELF-HELP GROUP AND
                FINANCIAL ASSISTANCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Campaigns on Electric Vehicle and
                its Benefit for Society</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar \&#39; Entrepreneurship by learning
                Design Software\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session by PROWESS Expert</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session by CRISIL Research Experts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session by CRISIL Research Experts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->In VLSI Design workshop students are trained
                with HDL languages alongwith simulaton and synthesis.Students are able to simulate and design different
                circuist at different levels.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Womennovator</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Session on Science of Well Being</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/22e95ee52796ee0b5da65b3bab79fd58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAPATLA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Managing Difficult Conversations and
                Influencing </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf44cd61cd0a225725769b86d6ee8210.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Gnanamani College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation ambassador training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/848c82596b7539a46e055dba1a616c51.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ESCAPE TO LEARN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring Session by the ISTC Old Students
                Association (ISTCOSA)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NCC Pan-India Swachhta Pakhwada Relay Cycle
                Rally, </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-store inauguration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotics Workshop on \&#34;Arduino based
                Voice Controlled Pick and Place Robot\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vadodara Start-Up Festival</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4ef6a377baa808adf4bb89acc6a7f8a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vadodara Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring Session for ATL-DPS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee35441b809f4b85240cddb8bff2bafa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Global University Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training session on GST for startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campus Comapny - 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campus Company - I</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Practical Session on Business plan
                preparation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ATTITUDE BUILDING TOWARDS ENTREPRENEURSHIP
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Designing Facial Filter using
                Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Summit NITK GC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Ambassador Training Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Personality Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lateral Thinking & Creativity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->QUIZPICABLE ME- DO U HAVE THE ‘M’ FACTOR” :
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Smart policies for an Eco smart City”
                Intercollegiate Powerpoint Presentation Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interaction with Innovators - For School
                Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inernational Seminar </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations and research opportunities in
                Semiconductors & VLSI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day 2019 Campaign </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CTF League - Web Club</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Artificial Intelligence and Workshop on Deep
                learning Artificial Intelligence and Workshop on </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c733984fbacb5850c48c632716fa9da.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S. B. PATIL COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Smart India Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d73ae23ba9b54a409415dbbc2904056.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JABALPUR ENGINEERING COLLEGE,JABALPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Offline Idea Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Herbal Medicine and Beauty Products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Minor Millet Value Added Products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Banana Value Added Products</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee13f39ce76a3ee71444735dd4a97844.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B I T SINDRI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Patent Filing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Ambassador Training Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Roadmap Ahead through Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Science and Big data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Personality Development Program on
                Entrepreneurial Skill Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Management Development Program on
                \&#34;Leadership & Strategic thinking\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interfacing with Leading Institutions for
                Business Plan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Expo for school students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Board Room Discussion </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Business Plan </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Startups and Venture Development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Problem Solving using C & Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Winter Training Programme in Cyber Security
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPORT MARKETING INNOVATION PARADIGMS IN
                DIGITAL ECONOMY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->awareness on say no to plastic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->awareness on energy conservation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days Entrepreneurship Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63fc9d91e3f11e8f04a88836ef94b3e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. MARY&#39;S  COLLEGE, THRISSUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Udhbhava Parakh Yojana (Meet the Scholor)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on BigData Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c366917ed5d1c902a08b0c14f3c8a4d7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TADIPATRI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Ambassador training program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->OSAX Utsav 19-20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Smart India Hackathon 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Back to Roots: A Workshop on Manure
                Processing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Taxation: Various taxes applicable to MSME
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Data Science</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEAS FOR NEW INDIA CHALLENGE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training on Product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b228d8fcef2ec3ee13a3afe3121f944.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee University of Information Technology Waknaghat">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interaction with Additional Chief Secretary-
                Home & Industries Himachal Pradesh </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b228d8fcef2ec3ee13a3afe3121f944.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee University of Information Technology Waknaghat">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Linkedin‐MTV GET A JOB Internship Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b228d8fcef2ec3ee13a3afe3121f944.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee University of Information Technology Waknaghat">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Adventum 1.0 Internal round of Smart India
                Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e42bdc99577982c525609ff137df33b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARISHI MARKANDESHWAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Workshop On Entrepreneurship and
                Self Employment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Series of Sessions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Opportunity for Mechanical Engineers
                in Nautical Science </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c733984fbacb5850c48c632716fa9da.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S. B. PATIL COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on \&#34; Recent trends in IT\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation A key to Entrepreneurial Success
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on workshop on “PCB designing”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b561843ab31fdf0336cfd1551f90562.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI G.S.INSTITUTE OF TECH. & SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->two week long boot camp \&#34;IDEATHON\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ebff57219a05708154e0c2288b2c4ee0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days national level short term program on
                \&#34;machine learning applications using python\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH 2020 Insights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Copy Rights, Trade Mark and Other Legal
                Formalities in an Enterprise</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6274fd54c0cae02676317f79f712f5a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KPR INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Presentation of applicant Start-up under
                AICTE-Samriddhi Scheme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Space Carnival</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b343fd8f2aca870b555c963a3bb803dd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SANT LONGOWAL INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advance 3d designing printing workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Cafe #1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->6th Technical Symposium JISTech2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ace9dd1e1ea47b9e9a7571f0a68e45a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAR BASELIOS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship as a Career Option</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CNC Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ccf4481258d3cb2994ed93e6f35021d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A One Day Session on \&#34;Innovation &
                Startup policy for Students and Faculty\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7682ef75b7383fc2f85b89bdb4bee8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANDIAN SARASWATHI YADAV ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NSE Investor Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Seminar on \&#34;Solutions to Water
                Scarcity in Chennai City</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“SEEK” Workshop /Activity On Digital Circuit
                Design & Optimization using CAD Tools</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Marketing Management Product Promotion &
                Sales</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Preparation of Detail Project Report</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidance program for School Students
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Crossblade Music Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar: Introduction to Machine Learning
                with AWS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Story Telling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Way Ahead for Women in Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Story Telling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNODHYA - National Entrepreneurship Summit
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NEW AND INNOVATIVE TEACHING TECHNIQUES </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a32c50f0b56e0196f856b7d7910158d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES Keveeyam College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in MCC IDEA FAIR CONTEST at
                ERANAD Knowledge City, Manjeri, Kerala</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Workshop on ICT Tools</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IKGPTU Entrepreneruship Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day workshop on Creative Career Conclave
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Workshop for Civil Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day workshop on Research & innovation in
                Civil Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7da229f2ce7c3d00fb6983ec5a8308bf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indo Global College of Engg. Abhipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day workshop on Research & innovation in
                Civil Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SheTalks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cash Flow & Balance Sheet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on Research Methodology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Building Engaging Voice Experiences with
                Alexa</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal smart India Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaking Minds Youth Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT with Cloud Computing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON PYTHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BASICS OF MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MATLAB workshop in digital communicaton</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electronic Design and fabrication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WHAT IT WILL TAKE TO EXCEL AS AN ENTREPRENEUR
                IN A START UP? </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2618a5659d897d6a2e832dba9654acdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G K M COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on computer technologies (for school
                students)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cost Consciousness & Break Event Point</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation and Creativity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Valuation Methods Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National communication skills challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f2cc631309d35904d71755668235f24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University College of Engineering Nagercoil">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stroy Telling Innovators in Campus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Privacy Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Exposure to Database System Internals
                (Columnar Databases, Indexing and PostGreSQL)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->StartUps in Students Life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ffe89798d908df02063ac099c203155.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREEPATHY INSTITUTE OF MANAGEMENT & TECHNOLOGY, VAVANOOR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solar Yatra</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Working Capital Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/711bc9f4f504d1934d7baecc5a2cf277.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY COLLEGE OF ENGINGEERING KAKINADA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovation and Design Thinking
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d09fd48dfa2aa765a319ea674a9deed.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on \&#34;Information
                Security Awareness\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3562529f82430a6d534d1f094577b30d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Biswanath College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Motivation Campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3562529f82430a6d534d1f094577b30d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Biswanath College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship and Career Options</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python training for III year EEE, ECE & CSE
                students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on \&#34;DYNAMICS OF MACHINERY
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MECHANICAL ANSYS AND FLUENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Fundamentals of Automotive
                friction materials </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Brake system design and
                latest advancement </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Production Planning & Purchase Technique in
                MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pariksha Pe Charcha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rotaract Installation Ceremony</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IoT, Big Data & AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INSTRUCTIONAL DESIGN & DELIVERY SYSTEM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68d0b25180227a15de451d5782e3923b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING,BARGUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LOGO Design Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HOW TO PLAN A START-UP AND LEGAL AND ETHICAL
                STEPS INVOLVED</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/beadb1342fcc959f801dfc5bcb6c79d6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.B.K.R.INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARENESS WORKSHOP ON “Entrepreneurship and
                Skill Development Opportunities in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design and Fabrication of Electronic Circuits
                using CAD Tools</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/294aeb76b5d031e3440fefe0d0bf7f46.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. J. SOMAIYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HackIt 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6499599b1aeb3435b5e95941ada03331.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KNOWLEDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ELECTRICO-T20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7ea9910e07d360073a370d05684180c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ASSAM DOWN TOWN UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->pplication of Satellite Based Technology in
                Disaster Preparedness and Recovery – A Global Perspective”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68d0b25180227a15de451d5782e3923b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING,BARGUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/294aeb76b5d031e3440fefe0d0bf7f46.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. J. SOMAIYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on IT Industrial Applications
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FLAREZ 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abd376de3f8daf676931d147553d7ed2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Presentation on Development and Present
                Scenario of Electric Vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mini Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gaming Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1959f2bb8d494015310e34b2e710aa11.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY OF ENGINEERING AND MANAGEMENT KOLKATA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotic and Automation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interviewing Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Seminar on Social Innovation and
                Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Catch them young</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Catch them young</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Catch them young</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Catch them young</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Catch them young</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c6b50be843af40f1328f73b4429a2c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAGI RAMAKRISHNAM RAJU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Humorous Speech Contest and Evaluation
                Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/22e95ee52796ee0b5da65b3bab79fd58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAPATLA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert lecture on Data science projects in
                civil engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/22e95ee52796ee0b5da65b3bab79fd58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAPATLA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenging Avenues And Research On Fuel Cell
                Operated Electric Vehicle Retro Fitting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c6b50be843af40f1328f73b4429a2c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAGI RAMAKRISHNAM RAJU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CISCO Women Rock IT Women Empowerment in IT –
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c6b50be843af40f1328f73b4429a2c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAGI RAMAKRISHNAM RAJU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Spardha-2019 24 Hours Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68d0b25180227a15de451d5782e3923b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING,BARGUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Presentation on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Beach Cleaning Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creative skills and writing Skills in Tamil
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on skill development for
                construction women workers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Ambassador Training </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->REVOITZ 2K20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->field visit toshiba </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill development - CMOS IC design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c356dd16c09ade6747c7ce32d569cac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tawi Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campus Hackathon First Phase </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story – Entrepreneurs Life & Crossroad
                Motivational Speak</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c356dd16c09ade6747c7ce32d569cac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tawi Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Nearby Place for Startup
                Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->innovation seminar on IoT-what if things
                start to think</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Knowledge and Skill Development Workshop on
                Solar Power Sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Group discussion on students role in
                promoting innovation and entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Modern tool usage </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d920e6897b3d2f302d5c640651dec8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Deen Dayal Upadhyaya College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty interaction with Distinguished
                Experts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dissemination workshop on National Start up
                Policy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition and demo for POC\&#39;s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership talk on New product design
                thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational speech on importance of start
                ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational speech on Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational talk on Innovation concepts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e37c01ee2b3adef18851a08e475767f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BPlan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of National Press Day and
                Launching of ‘AUC News’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Industrial Visit to B.E.C. Foods
                Ltd., Chingri Balod </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9c45321b379f045700d9acecdb8d4fcd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON IoT- PATHWAY TO INDUSTRIAL
                REVOLUTION 4.O</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Present Bank Mergers: The
                consequences for the Indian Economy </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start-up training at Industry </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Mandala Art</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UNDERSTANDING GENDER FOR EMPOWERMENT AND
                CHANGE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Field Innovation Training at
                industry </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Hands on Training in CNC Machine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d920e6897b3d2f302d5c640651dec8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Deen Dayal Upadhyaya College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture Series with Distinguished Experts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Quality Control / Amity
                University Chhattisgarh</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Programme against Human Trafficking
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indo- US Workshop on “Extremophiles in
                Biotechnology”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One-day workshop on Cake icing & Decoration
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Kalasalingam Global Conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship filed visit to company </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial exposure Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start-up Filed visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation & Start-up Internship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e57869380f9338f8467d810f1d807094.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY POLYTECHNIC BIT MESRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Solar Yatrafor solar lamp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e57869380f9338f8467d810f1d807094.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY POLYTECHNIC BIT MESRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advanced cad/cam technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enterpreneur Skill Development-Smart Phone
                servicing and Troubleshooting\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations using Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/98c9cbf927a59e0a3f009ba5a7ab321a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="POONA COLLEGE OF PHARMACY, ERANDWANE, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Men\&#39;s Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Youth talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Developing Innovative Solutions Using Laravel
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/25ce07e93565c21db80aced989401325.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAJAJ INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition Cum Demo for PoCs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plastic Eradication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Peer to peer teaching</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on Social Network Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Talk by Young Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/00f9cb2fc0cea0382bd558b39c33fda7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS Academy of Higher Education & Research">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Nano Jatha 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Innovation and IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cloud Literacy Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Artificial Intelligence and Machine
                Learning in 20th Century</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d920e6897b3d2f302d5c640651dec8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Deen Dayal Upadhyaya College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on-Training Workshop on Office Software
                Package tools for NCWEB DDUC Centre Students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CIRCUITRIX</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HR Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations in Atomic Energy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea and Model Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TERRAIN TRADER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Funding Opportunities for Startups
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National level workshop on MATLAB based
                System Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Winners of Business plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KHET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->South Asia NICE 2k19 Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MACHINE LEARNING </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Gallery: Biotechnolog - The Weapon
                to Combat Climate Change</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on Design
                Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ALQUORA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6091319f0fefb5d8ade51472659b8db.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. MGR Educational and Research Institute">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet the Grads</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E- Talk - Innovator’s Life & Crossroad
                Motivational Speak</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/644ccd9149885c675302f635c9e0c991.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROGRESSIVE EDUCATION SOCIETYS MODERN COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project on Waste Extraction and Skill
                Development for Homely Environment.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creative Cash Cows</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VIGYAAN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Current Trends in IT Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python Programming #FDP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->T-Hub MoU with MRCET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KLEOS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FACULTY DEVELOPMENT PROGRAM ON #AUTOMATION
                FOR SMART INDUSTRY& MANUFACTURING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/00f9cb2fc0cea0382bd558b39c33fda7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS Academy of Higher Education & Research">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop in Life Sciences</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI WINTER INTERNSHIP 2019-Learn How to
                Prototype and build an industrial product</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Village/Society
                /School/Industry/Market – Identity real Life Problem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Identify Brands in Market</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d755f0fdcbca3b2ad817371ac89f41df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Talks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inter-colligate/Institute/Department Avishkar
                Research Convention </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agile Methodology for Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on \&#34;Interdisciplinary approach to
                Engineering applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COCO Cola industry visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISHRAE Inauguration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Tamilnadu student Innovators
                2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Certificate Programme on Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CRT training for Mechanical Engineering
                Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CIIL Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6091319f0fefb5d8ade51472659b8db.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. MGR Educational and Research Institute">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE PRODUCT BASED LEARNING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->I Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d920e6897b3d2f302d5c640651dec8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Deen Dayal Upadhyaya College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Program for School Students on HTML,
                CSS and Java Script For Class XI and XII Students of Government Co-Ed Senior Secondary School, Baprola,
                New Delhi 110043 (DoE School Code: 1617030) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on VLSI Chip design using open source EDA
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Soldering Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Scope for Startups in Gaming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c86061dcc537b41fca6f76a728728ae9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THASSIM BEEVI ABDUL KADER COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program on Student Start-Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Two-Day Workshop On
                \&#39;Intellectual Property Rights And Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in The Ideators’19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->October Sky</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week FDP on Natural Language Processing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gravity Control</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineers Day </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Opportunities in Aviation for
                Innovators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technomic Quotient 8.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAD-alyst</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Electromagnetic and Microwave
                Engineering Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f759188888ac466518c7d4a7986ea25b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Lovely Professional University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GEEK Fiesta- A Hackathone</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/33079c50db447edb3d08f26aea95235e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Sankara Vidyapeetom College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT TO MILMA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campus Level Ethical Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/682ba087f4be78b89234cd89cd2286f5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTIONS TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research & Development “Opportunities for
                Students\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coding course </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/682ba087f4be78b89234cd89cd2286f5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTIONS TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon – Phase I</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Six Days Faculty Development Training Program
                on Working Culture for Professionals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Seminar on Block Chain Technology
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agile Way of Developing Innovative Solutions
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7488bd20222ae9e59b16615ad0c25f44.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anna University Regional Campus Coimbatore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Pollution Control Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f759188888ac466518c7d4a7986ea25b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Lovely Professional University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on Entreprenureship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/682ba087f4be78b89234cd89cd2286f5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTIONS TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation & Product Development Centres
                Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/682ba087f4be78b89234cd89cd2286f5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTIONS TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/682ba087f4be78b89234cd89cd2286f5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTIONS TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EDC & Incubation Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Amrita Quiz Awards 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/682ba087f4be78b89234cd89cd2286f5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTIONS TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on 21st Century Managers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Design Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/25ce07e93565c21db80aced989401325.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAJAJ INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Wolf of Wall Street</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Blockchain for Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial exposure Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/682ba087f4be78b89234cd89cd2286f5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTIONS TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on Campus to Corporate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Department of CSE in association with
                Computer Society of India and IIC has organised a 2 day workshop on Machine Learning with Python </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Computer Aided Design for VLSI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Outreach Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training on Python Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f759188888ac466518c7d4a7986ea25b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Lovely Professional University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/25ce07e93565c21db80aced989401325.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAJAJ INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPL Auction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech Talk on AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to NGRI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training on Python Programming for Skill
                Development of Teachers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Signed MOU with Ganson Limited Thane West
                Mumbai Maharashtra</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project exhibition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/891e643e5ce454973d262d18de385111.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND INSTITUTE OF HIGHER TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INFYTQ Infosys</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start-up Filed visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->App Development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inspire Program - Science & technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RGI- PHOTOGRAPHY COMPETITION- 2019, Pune
                Campus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Methods for Teachers on
                Futureskills platform</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Awareness for Ladies Health Issues”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship filed visit to company </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Applied Biology
                (ICAB) and first annual convention of the society of Biologists</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation-Machine Learning and Data
                Manipulation using R and SQL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5377c06b26cef88fd360be8e34814300.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna Mission Vidyalaya College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intensive Training Programme on “Internet of
                Things Phase - I”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Compaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5377c06b26cef88fd360be8e34814300.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna Mission Vidyalaya College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SELF-LEARNING with practical session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Trip</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Regional Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techno Coding Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational talk on start up </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk for Students and Faculty
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Artificial Intelligence & Big Data
                – Knowledge Series </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f2b86860b6980b765d3db1e7a5f273f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Geetanjali Institute of Technical Studies,Udaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Thinking & Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAITECH S2T 2.O</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on “Entrepreneurship in Sweden”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STC on Engineering Optimization</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vetripadigal Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/891e643e5ce454973d262d18de385111.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND INSTITUTE OF HIGHER TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days workshop on Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1f8c049dadd49b6f934f003a8905a04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR N.G.P.INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on The Future Trends & Emerging
                Technologies Disrupting the Future of Education& Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->4 th National Conference in RTMS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Voice of Social Conscious \&#34;SATKAARYA -
                A Social Unite\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Healthcare industry 4.0: integrating
                innovation - the round table conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational talk on Innovation and
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Energy Simulation”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Template Design Workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed0d5f8f3163dfe559ffb3d1d6c3b758.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUZAFFARPUR INSTITUTE OF TECHNOLOGY, MUZAFFARPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon for Smart India Hackathon
                2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/43d3acbefa4ae6ec42b07a33460ede2c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Development workshop series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/43d3acbefa4ae6ec42b07a33460ede2c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fedora Womens\&#39; Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/43d3acbefa4ae6ec42b07a33460ede2c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Biz Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Presentation Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field FIELD VISIT TO SYNKROMAX BIOTECH PVT
                LTD BY BIOTECHNOLOGY STUDENTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/005f3f36464065d29e231be28cf9ae28.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHNU INSTITUTE OF PHARMACEUTICAL EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational speech on Leadership Skills ans
                Entrepreneuship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Team and Concentration Building Activity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Ambassadors Training Series
                conducted by MHRD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->training at Toyota</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start up talks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI Benifits for Society </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->intellectual property rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TIFR visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Cognitive
                Skills,Design Thinking & Critical Thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Pharmacy Practice
                and Therapeutics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PHARMA EXPO 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->OSWASP Top Tools for Security Innovators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da300a7c2bab83971b6216c67c4f2e0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RajaRajeswari College of Engineering, Bangalore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aligning Innovstion Eco System Strategies
                with Academic R & D</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1182108f07d29279128026688d863435.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING TRIVANDRUM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Brain Hustle</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Young Scientist Award Competition 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovating using Big Data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Club and Team</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Club and Team</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea generation campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Strategies for Leaflet Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3557732838abf7a3d41adb14954af7e0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SCOPE COLLEGE OF ENGINEERING, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP AND ROLE OF BANKS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leveraging Startup Ecosystem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Engineers in Design Field</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0425e5e3eb96a071b1c27e947e534def.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHHATRAPATI SHIVAJI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Adventure of a Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56b28e6467a4a22f92dbb630a3731c1d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SHAKTHI INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Talk on Entrepreneurship in Cyber Security
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56b28e6467a4a22f92dbb630a3731c1d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SHAKTHI INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Software Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56b28e6467a4a22f92dbb630a3731c1d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SHAKTHI INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Machine learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership talk on SIH by Dr Mohit Gambhir
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4ec2792da49436ca226f12f307152f6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Padmabhooshan Vasantraodada Patil Institute  of   Technology, BUDHAGAON">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enterpreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/78a12643c41998fd10d38edf57640897.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.D. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7831363951a5b21d3572c37aa6f98e7c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University of Rajasthan">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Sophisticated Instumentation
                Facility</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz Competition on Start-up & Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE PEDAGOGIES FOR QUALITY ENHANCEMENT
                IN ENGINEERING EDUCATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4ec2792da49436ca226f12f307152f6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Padmabhooshan Vasantraodada Patil Institute  of   Technology, BUDHAGAON">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit to ISRO Mega Exhibition at NSC
                Nehru Science Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7831363951a5b21d3572c37aa6f98e7c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University of Rajasthan">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar Indian Science Congress</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7831363951a5b21d3572c37aa6f98e7c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University of Rajasthan">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar and Lecture Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7ce78a1aca7122dec45e16f7bc277a9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Khalsa College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovator Suggests</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7ce78a1aca7122dec45e16f7bc277a9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Khalsa College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guru Nanak Dev Ji,Science and Religion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7ce78a1aca7122dec45e16f7bc277a9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Khalsa College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Lectures</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Day Workshop on Ethical Hacking & Cyber
                Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MOU between Narula Institute of Technology
                and Jadavpur University for collaborative exchanges of Knowledge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67056eb08ab3a825e3a2d54fa41e2bb1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. RAMAKRISHNAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KRCE and NITT-SIEMENS Signing MOU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Design Methodology and Opportunities
                at DRDO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/644ccd9149885c675302f635c9e0c991.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROGRESSIVE EDUCATION SOCIETYS MODERN COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FB Live Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3e8517764e1eac3f91659fb8ffa2ab04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING,SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Manvasanai - Innovative Projects to help
                Farmers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EDP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e8acb159132ac2d17c8f34da9ef7abe.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CT University, Ludhiana">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MDP (Management Development Programme)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to Organic Farming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/644ccd9149885c675302f635c9e0c991.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROGRESSIVE EDUCATION SOCIETYS MODERN COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pro-Start2K20 Internal Hackathon for SIH2K20
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning for Image, Audio, Video
                Analysis & IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/147f47818eff8d7bb1d1652c38503e87.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMAL JYOTHI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two day Hands-on workshop \&#34;FOSS BASICS
                and PYTHON” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pariksha pe charcha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pariksha pe charcha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afea1371e760819e3042363e0965c9f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.O.Chidambaram College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Training Programme on Pickle
                Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E Summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Driving Innovation Through DevOps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Web development Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Drone Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in Teaching Pedagogy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/646baa5a7158c7b8814192a096efbe38.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Babu Banarasi Das National Institute of Technology and Management ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NAtional Level Hackathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SPARK 2020 - Project Display competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6499599b1aeb3435b5e95941ada03331.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KNOWLEDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FB Live session on insights and important
                information on SIH 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit at Waste Mangament Plant,
                Bhanpur, Bhopal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on INDUSTRIAL EMBEDDED
                SYSTEMS & IOT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b5ef4e00f3f0f9064cf2a0c6a3c0ee3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RVS College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rapid Prototyping</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup project visit at B-Nest, Bhopal
                Smartcity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Rocket Propellant Preparation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring session to teams selected after an
                initial idea pitching</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d12575dffa16bd474af6122508e849a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nagesh Karajagi Orchid College of Engineering and Technology, Solapur.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inovatar in the campus on Water Management
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivation speech - Placement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9e5e7761301eaa9b9f9fcee2d7e5f26b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOUNT ZION COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on “Dynamic Web Control
                Generation”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup project visit at ICCC, Bhopal
                Smartcity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit cum Tour (Chandigarh –
                Shimla)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two-Day Teacher Training Workshops</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of India Constitution Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT TO ALT-TC, GHAZIABAD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON THALASSEMIA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/71e70a183a0aa9d2bfae412b0954d48b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MGM Institute of Health Sciences, Navi  Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Yoga as a tool for health Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Condition Monitoring and
                Prognosis of Railway Tracks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/71e70a183a0aa9d2bfae412b0954d48b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MGM Institute of Health Sciences, Navi  Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Modern Mathematical Methods and High
                Performance Computing in Science & Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tatva 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9f395669f18764fc489133a5def7ef00.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mula Education Society\'s Arts, Commerce and Science College, Sonai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR for Students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9e5e7761301eaa9b9f9fcee2d7e5f26b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOUNT ZION COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Seminar on “Micro Electro Mechanical
                Systems (MEMS)”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5db4b61d6942c050a9ad4a3e2978566e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU ENGINEERING COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internet Of Things Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Creating Brands with Purpose</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion: Waste to Wealth:
                Socio-Economic Impact of Waste Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6499599b1aeb3435b5e95941ada03331.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KNOWLEDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UiPath RAP Champ 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7148e4b310c125def0ca6f921025c638.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.I.S MOHAMMED HAJI SABOO SIDDIK POLYTECHNIC">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MEGA JOB FAIR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Women Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Power of AI: Redefining Social Impact</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Disruptive Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82709ebdaeb148a6aea72eba2a50cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARENESS WORKSHOP ON NATIONAL INNOVATION AND
                STARTUP POLICY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Discussion on the book Farming Futures:
                Emerging Social Entrepreneurs in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82709ebdaeb148a6aea72eba2a50cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MODEL MAKING- DEMO DAY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/77c3f962ecf3f4dccc1d6a24774b6c66.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tata Institute of Social Sciences, Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker Session on Shaping Policies and
                Ecosystem to Foster Innovation and Entrepreneurship.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9e5e7761301eaa9b9f9fcee2d7e5f26b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOUNT ZION COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Seminar on \&#34;Development of
                Prototype Models\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Computer Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8667eff852aa8b160f47bdb52eea90df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr.N.G.P. Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Connect</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Model Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8667eff852aa8b160f47bdb52eea90df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr.N.G.P. Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8667eff852aa8b160f47bdb52eea90df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr.N.G.P. Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring session on SIH 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7148e4b310c125def0ca6f921025c638.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.I.S MOHAMMED HAJI SABOO SIDDIK POLYTECHNIC">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GANDHI JAYANTI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Term Training Program on Case
                Methodology and Case Writing Workshop sponsored by AICTE, New Delhi. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Developing Entrepreneurial Competencies &
                Motivational Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CYBER CRIME- USE OF MOBILES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on opportunities for rural outreach
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Outdoor Group Activity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7148e4b310c125def0ca6f921025c638.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.I.S MOHAMMED HAJI SABOO SIDDIK POLYTECHNIC">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plantation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative training in TV Enterprises</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Identification of Entrepreneurship
                Opportunities in Rural Areas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project work at Zoho corporation PVT LTD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WOMEN ENTREPRENEURSHIP DEVELOPMENT PROGRAMME
                (WEDP)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop at VI MIcrosystems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d63a3909063bc7145f18bf8cbca57ea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SATHYABAMA INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Be a successful entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP AWARNESS CAMP 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE IDEAS INTO PRODUCT DEVELOPMENT
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d63a3909063bc7145f18bf8cbca57ea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SATHYABAMA INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Insight into Data Analytic Tools - Microsoft
                Business Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Department visit at Schneider Electric</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a4b4e92e416df375907cc265e6a801c4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI ESHWAR COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enterperneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5db4b61d6942c050a9ad4a3e2978566e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU ENGINEERING COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drone Building and Flying Control</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5db4b61d6942c050a9ad4a3e2978566e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU ENGINEERING COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drone Building and Flying Control</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ART OF WRITING RESEARCH PAPER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e42bdc99577982c525609ff137df33b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARISHI MARKANDESHWAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pharmacy Week - Poster Making Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy Conservation Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Case study of Startup by Alumni Entrepreneurs
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Club activity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interview of Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Call for Startups - Pitching Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/98d72e7999ba5bd6a36bfbe38b8de1ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA INSTITUTE OF INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rags to Riches</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e861dca24cde91c1d38596676bc986e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BANNARI AMMAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International conference on “Advances in
                Materials research\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e861dca24cde91c1d38596676bc986e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BANNARI AMMAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UiPath RPA Hackathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->In Pursuit of Innovation, Product
                development, IPR & EDC </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93146d8ccda3e7c1b5d9fcc11675f40d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Rally on Usage of Antibiotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Space Research Colloboration with Serbia</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Champions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Workshop on Design thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on Different Market Structure
                Based on Indian Economy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93146d8ccda3e7c1b5d9fcc11675f40d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cure from home</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MoU with Internshala</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Multimedia in Teaching Learning
                Process</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vortex The ChemFest 7.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93146d8ccda3e7c1b5d9fcc11675f40d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learn, Educate & Implement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IMA Student Skill Enhancement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Studopreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Human Values</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Institute Interaction with the Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The 2019 CanSat / Rocketry International
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to Micro Turners, Baddi </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93146d8ccda3e7c1b5d9fcc11675f40d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Eco Friendly Ganesh Idol Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Guidance Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Industrial Visit to Signum Electowave, Baddi
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Constitution Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->4th Innovative QC competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on AFM & FTIR based material testing for
                research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fb978c86086a6d7236f4d6b163eb774.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS NARSEE MONJEE INSTITUTE OF MANAGEMENT STUDIES SVKMS NMIMS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inaguration of Diabetes Prevention and Care
                Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to Business Standard,
                Panchkula</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Reconnect Expert Session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Science Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EC UTSAV2K19 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e42bdc99577982c525609ff137df33b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARISHI MARKANDESHWAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pharmacy Awareness Rally</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Computer Literacy Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Business 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fbced7b700f7e67b524a0489f42a354.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="I.T.S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Staff Development Programme on
                \&#34;Emotional Intelligence and Interpersonal Effectiveness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, Product Design & Thinking and
                Motivational Talk Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Group Discussion on Startup Policies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/787e086cf78de2e0f1a3874b63f6494c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAR SRR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ecofriendly Innovations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ecofriendly Innovations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/787e086cf78de2e0f1a3874b63f6494c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAR SRR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/787e086cf78de2e0f1a3874b63f6494c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAR SRR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Design /Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chai pe Charcha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chai pe Charcha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideathon using Internet of Things </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Work Shop on Managerial Solutions
                through Tally and GST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b343fd8f2aca870b555c963a3bb803dd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SANT LONGOWAL INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on 3D Designing and 3D Printing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Investor Meet </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Conference on\&#34; Smart
                Technologies for Occupational Safety, Health and Environment\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Trends in Power Converters for
                E-Mobility</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy Audit for Industrial Plants</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Counselling Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/819ef31bc207892f636e3072cc2d4ddf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhanalakshmi Srinivasan College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Enhancement in Hybrid
                Electric Vehicle </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6499599b1aeb3435b5e95941ada03331.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KNOWLEDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHFETE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Excel Machine Learning Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5G Technology in Mobiles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Pitching Session held for a group of
                young innovative students from across the State with Energy as the theme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Kaagazgiri</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Liveness with Motors</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Thedal and Adukalam Programmes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coffee with CEO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67789dea1787ed3dc5a70786f34ad108.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Adichunchanagiri College of Pharmacy">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Empowerment and Excellence
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture by practicing Entrepreneur’s Success
                Story(Awareness camp for Students)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Symposium for Faculty</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Voices in Technology summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prayog Sharat</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on “Integration and its Application
                in Engineering Field\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation towards Technology Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Reach your crest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BEC orientation programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Hamster for Faculty</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEDx KLE TECH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop-BEConIIT on 10th and 11th
                Jan 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Waste water treatment Sewer Plant Demo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Modeling Analysis &
                Simulation: Exploring the Nonlinear world of Mathematics (MAS-2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VIDYUTH 5.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4fe9365458b2316ae86a2ead81d7187.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJIV GANDHI COLLEGE OF ENGINEERING AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness regarding Patent </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50beb4996750fe8fb5e617565b64cda.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vaagdevi Intitute of Technology and Science, Proddatur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Presentation on \&#34;Entrepreneurs -
                Creators of Best from Waste\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop On Funding Oppurtutinities For
                Innovation And Entrepreneurship Development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6efa2e46d9cf95da416252d2ee2c5b69.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhagwan Parshuram Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in project competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6efa2e46d9cf95da416252d2ee2c5b69.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhagwan Parshuram Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FOSTERING AND ENHANCING THINKING SKILLS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->7TH NATIONAL CONFERENCE ON COMPUTER VISION,
                PATTERN RECOGNITION, IMAGE PROCESSING AND GRAPHICS (NCVPRIPG 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Motivation Campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/02c0c6eb85b5836d44bccb3d46129ba9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDO-SWISS TRAINING CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Training Program On Basic
                and Advance Skill for Water Testing and Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0573a24f2d0911ba80e530608fe32853.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chandigarh Business school of Administration, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Industry Expectations from
                Freshers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TRYDANZ 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on 5G Communication technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC AMBASSADOR TRAINING SERIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Regional Research Symposium on PBL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technorian</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visits</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart Transformation to E-Mobility</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert lecture </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR QUIZ</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8ed57ed000a41afb7f4b568d4819cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St.Teresa's College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Entrepreneurship Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Open Day: Project exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Consumer Dispute and Redresal Agencies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Live a lifeand How to Practise
                Profession</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/229dbe738fe07005ac88aff1a3f3db6c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Velalar College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP AWARENESS CAMP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women\&#39;s Economic Empowerment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensitization For IIC Co-ordinators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Scrutinize Innovative Product Proposal
                Submitted by Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk to submit innovative
                product proposal idea by students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techtransfrom 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Apiculture , Vermicompost</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on PHP Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk to Apply for Summer
                Internship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Program Employee Engagement</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67789dea1787ed3dc5a70786f34ad108.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Adichunchanagiri College of Pharmacy">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent trends in research across the world
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4fe9365458b2316ae86a2ead81d7187.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJIV GANDHI COLLEGE OF ENGINEERING AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensetisation Program of EDC Coordinators
                organised by Mharashtra Centre forEntrepreurship Development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT & It\&#39;s Application</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Addressed to 1st Year MBA Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2060029db2c9548b4f45d50003701e68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA GOVERNMENT ENGINEERING COLLEGE, CHANDKHEDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert session on Venture Capital Funding by
                Ms. Nin Desai, CEO Nin Ventures</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2060029db2c9548b4f45d50003701e68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA GOVERNMENT ENGINEERING COLLEGE, CHANDKHEDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert session by Innovator Mr. Rachit Oza
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SOLAR MOVEMNT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2060029db2c9548b4f45d50003701e68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA GOVERNMENT ENGINEERING COLLEGE, CHANDKHEDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Design Innovation Lab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b5f63d4998fe90dfa307f87868e41ef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAIRAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->8th INTERNATIONAL CONFERENCE I7C – 2019 ON
                “CHIP, CIRCUITRY, CURRENT, CODING, COMBUSTION AND COMPOSITES” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Birth Anniversary</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CM Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CERTIFICATION COURSE ON DEVELOPING SOFT
                SKILLS AND PERSONALITY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit at Incubation Center</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seed Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Samvidhan Diwas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CERTIFICATION COURSE ON ECOLOGY AND
                ENVIRONMENT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65b3a41cb26417bf6da295ed3bdfeaa1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMANBHAI PATEL COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Enabled- A SEMINAR FOR ENABLING THE IPR
                RELATED SKILLS AND TEMPERAMENT IN RESEARCH FOR EFFECTIVE TECHNOLOGY TRANSFER AND COMMERCIALIZATION OF IP
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE NATION ONE CONSTITUTION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCB DESIGNING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH 2020 Insights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Develop Students Chapter of IMA in our
                Institute </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->From Project to Product- Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcb8e5c5030658b3c202dd523075ed61.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University of Jammu">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“DRAFTING FOR PATENT FILING” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONLINE CERTIFICATION COURSE ON FUNCTIONAL
                FOODS AND NUTRACEUTICALS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online certification course on creating a
                Happy and Meaningful career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Certification course and FDP on Computer
                aided drug design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Certification course on Analytical Techniques
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Transforming leadership & Creativity in
                Action- Leadership Talk”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pinkathon-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e861dca24cde91c1d38596676bc986e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BANNARI AMMAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training session on Research based
                Observational Study</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e861dca24cde91c1d38596676bc986e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BANNARI AMMAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GUEST LECTURE ON “ART OF WRITING SCIENTIFIC
                ARTICLES”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c86061dcc537b41fca6f76a728728ae9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THASSIM BEEVI ABDUL KADER COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program on Student Start-Up</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two-Day Workshop on “ Synergy of the two B’s
                – Brain & Body” 5th & 8th November 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PRAKALP PRADARSHAN 2019 PROJECT EXPO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0d5dcb71381806b3733e29f8dc6edaf5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ATHARVA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart Internal Hackathon 2020 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPERT TALK ON MODERN CHALLENGES IN CHEMISTRY
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Workshop to treat PCOD and PMS by YOGA - An
                innovative approach </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4fe9365458b2316ae86a2ead81d7187.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJIV GANDHI COLLEGE OF ENGINEERING AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coding Competetion_Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3610a13ac89f8c43200803e3e6f63eba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SHANMUGHA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Assembly Programming Stuff to Improve Program
                Logic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“ONLINE CERTIFICATION COURSE ON INDUSTRIAL
                PHARMACY </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON MAINTENANCE AND CALIBRATION OF
                RESEARCH AND INNOVATION BASED INSTRUMENTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE WAYS TO DISPOSE CHEMICAL WASTE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plastic Pollution Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur Orientation Session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Orientation Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da300a7c2bab83971b6216c67c4f2e0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RajaRajeswari College of Engineering, Bangalore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Innovator participation in Start Up
                Summit in IIT Madras</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Library Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special lecture on ‘Integrity - a way of
                life’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vigilance Awareness Week 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Environmental
                Sustainability, Water Resource and Management (ESWRM-2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cluster Level </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Practical Knowledge Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be000e56b2481f434ddf61fd23e66cf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAMMAL COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Innovation Ambassador Training Series
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering Projects in Community Services
                (EPICS) Orientation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Competition on \&#34;Innovative
                Intervention for Better Health Care\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0b9ce7aa3beed7d53d67685305633641.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIMT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Linking Business Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Programme for ITI
                students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TNSI 2019 Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights and
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LED bulb, Emergency Lights & Candles
                production</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34a9ec311694c3d10a021e967a907217.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACADEMY OF MARITIME EDUCATION AND TRAINING DEEMED TO BE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Food DAy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ED Cell Work shed & Sales-Point</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->a talk on medical emergency management </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Declamation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->a expert talk on safety, fire & disaster
                management </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cleaning Material Preparation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->training at retech solutions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf44cd61cd0a225725769b86d6ee8210.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Gnanamani College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Camp _ External</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->training on networking at redback it
                solutions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop by Expert</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on National Institute of Ocean
                technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Matilda Movie Screening- Danny De Vito</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->oracle training at kaashiv infotech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/75abf80ea830a529332daa0f71015bca.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.Narayanamma Institute of Technology & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IETE National invited speaker’s conference on
                IoT for Real World applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a9d3dbeaf35844501e9da60acee859a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SILICON INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs Fest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACK & ROLL 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop at infoziant</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Joy Fest-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plogging Rally</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drug Discovery : A Tool For Start Ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/75abf80ea830a529332daa0f71015bca.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.Narayanamma Institute of Technology & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women in Entrepreneurship & Career
                Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be000e56b2481f434ddf61fd23e66cf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAMMAL COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ELGI - Technology Day 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Novel training at Hyoristic Innovations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative training at Foreview Technologies
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative training at Dreamfort Interiors
                and Constructions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A round table meetup of business experts in
                Calicut</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days workshop on \&#34;Design &
                Installation of Solar Photo voltaic System\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Eclipse Watch_26 December 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A guest lecture on Motivation :A key to be a
                successful entreprenerur and innovative tool for healthy living</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Invited talk on “Innovations in Artificial
                Intelligence”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training on Design Thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Automobile Engineering and IC
                Engine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->flying for future careers(Drones)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Role of Emotional Motivator in
                Leadership</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on “Big Bang and The Expanding
                Universe”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two day workshop on Swachhatha Action
                Plan-2019 & Rural Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION AMBASSADOR TRAINING SERIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f759188888ac466518c7d4a7986ea25b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Lovely Professional University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk regarding Leadership</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Block-Chain Technolgy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f9b1254ef693dc4ba5055d7de4c404e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJALAKSHMI ENGINEERING COLLEGE (ENGINEERING & TECHNOLOGY)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE PRODUCT DESIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AZURA 2K20 Conducted on 09th- 10th January
                2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ICT Applications in Project Management </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Signing of MoU and Center of Excellence with
                TVS Harita Techserv Ltd.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f9b1254ef693dc4ba5055d7de4c404e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJALAKSHMI ENGINEERING COLLEGE (ENGINEERING & TECHNOLOGY)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIMPLE LEARNING TO MACHINE LEARNING </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOT and Its Applications </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Feedback, Discussion & Evaluation of PPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f9b1254ef693dc4ba5055d7de4c404e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJALAKSHMI ENGINEERING COLLEGE (ENGINEERING & TECHNOLOGY)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> WORKSHOP ON RECENT TECHNOLOGIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Oriented Guest Lecture cum Hands on
                Training on Aero Modelling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chatra Vishwakarma Awards - Regional
                Convention</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3610a13ac89f8c43200803e3e6f63eba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SHANMUGHA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Arduino Programming for Innovative Product
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inter Collegiate Innovative Cake Competition
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Nano Crib Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTER_ DEPARTMENTAL INNOVATIVE CAKE
                COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Star Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interaction with Chief Innovation Officer
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations in New Product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart India Hackathon Awareness Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Discussion on Sustainable Development Goals
                (SDG) - Innovation and Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNSHIP FEST 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31c9262772ca5280fc2a45f0f6ffd595.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AHALIA SCHOOL OF ENGINEERING & TECHNOLOGY, KOZHIPPARA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEATE 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31c9262772ca5280fc2a45f0f6ffd595.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AHALIA SCHOOL OF ENGINEERING & TECHNOLOGY, KOZHIPPARA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on RF and microwave equipment
                demonstration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCB Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4d7efd11bfc5ceb3718364d48e056d09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEPCO SCHLENK ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business incubation and technology transfer
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4d7efd11bfc5ceb3718364d48e056d09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEPCO SCHLENK ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Promoting innovation and technology among
                school students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4d7efd11bfc5ceb3718364d48e056d09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEPCO SCHLENK ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Innovation-Innovation and its
                importance, and students career development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> A One Day Intercollegiate Work Shop entitled
                on \&#34;Indian Constitution\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44614978c35e11ad100fdf948c85ea4b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KTHM College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on \&#34;Innovative
                Technologies for Startups\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Empowerment and Technology Transfer
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Programme </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI and Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on
                ENTREPRENEURSHIP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44614978c35e11ad100fdf948c85ea4b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KTHM College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44614978c35e11ad100fdf948c85ea4b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KTHM College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Cluster Level i-2-e
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4cb6caa808618bb732ec19e163d8d17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNEHACHARYA INSTITUTE OF MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Carving Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4cb6caa808618bb732ec19e163d8d17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNEHACHARYA INSTITUTE OF MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Carving Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Practical training at Codebind Technologies
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44614978c35e11ad100fdf948c85ea4b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KTHM College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Solar Ambassador Workshop 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vocational training at Chennai Port Trust</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project work at Bahwan Cybertek</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT Basics using Node-MCU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6f78b80c5172f3413b2506c386408a0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H.RAISONI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Promotional Activities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Assembly and use of foldscope as research
                tools</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on “Rock Mechanics”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit on Bharat Eletronics Limited</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A workshop on Design Thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training program on Networking Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6f78b80c5172f3413b2506c386408a0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H.RAISONI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Funding Proposal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Media and Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gate Preparation & Student Career Guidance
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Entrepreneurship Development in
                Pharmaceuticals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal Matters: Structure, IP, Copyright,
                Patents</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship on AAI CARGO LOGISTICS & ALLIED
                SERVICE COMPANY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GOVERNMENTAL SUPPORT FOR ENTREPRENEURS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training on Rooftop Solar Grid Engineer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GUEST LECTURE On PERSONALITY DEVELOPMENT AND
                SOFT SKILL TRAINING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f8458ab582769a1246283bdd5cbbe17d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.S.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking by Panchatantra</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f03751fe5ceab433b41af3d15b32933.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Konkan Gyanpeeths Rahul Dharkar College of Pharmacy and Research Institute Vengaon Road Dahivali Kar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week National Workshop on “Maintenance
                and Operation of Laboratory Equipment”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training program on Mobile service</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f8458ab582769a1246283bdd5cbbe17d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.S.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Latest trends</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f8458ab582769a1246283bdd5cbbe17d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.S.M. COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Community outreach program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ICT Standards and Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a9d3dbeaf35844501e9da60acee859a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SILICON INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Riemann’s Contributions to Mathematics”.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/139d8c7030c1ebc1588d3120bbc361b6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL TECHNICAL SYMPOSIUM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Ramanujan’s Contributions in Mathematics”.
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0095ba7bb5bfb068a24d8f31e26f1094.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SUPREME KNOWLEDGE FOUNDATION GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Introduction to Angular\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0095ba7bb5bfb068a24d8f31e26f1094.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SUPREME KNOWLEDGE FOUNDATION GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to PHP and MYSQL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Annual Alumni Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXPERT TALK ON \&#34;INDUSTRY EXPECTATIONS
                FROM PHARMACY ASPIRANTS\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->University Level Technical Quiz under ICI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d03e9a43ec7d62b31d100f3ee3cc935e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Smt. Chandaben Mohanbhai Patel Institute of Computer Applications">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->9th Project Innovation Contest -2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d03e9a43ec7d62b31d100f3ee3cc935e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Smt. Chandaben Mohanbhai Patel Institute of Computer Applications">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->9th Project Innovation Contest -2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring session for Hackathon Participants
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineers innovative learning tool for
                mathematical computation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74fcddc8572a452c70d0f18efbaf55b7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="POORNIMA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational talk by Mr. Rishabh Jain.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d76aaa0e680e15ec623a4873f392ca81.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GSSS INSTITUTE OF ENGINEERING & TECHNOLOGY FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Talk about \&#34;Internship and its
                importance\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6249f9cb063c4bf27a0c8f1ab4c1dbc8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STTP on IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d76aaa0e680e15ec623a4873f392ca81.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GSSS INSTITUTE OF ENGINEERING & TECHNOLOGY FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day SDP on “Journey from College life to
                Professional Life” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e861dca24cde91c1d38596676bc986e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BANNARI AMMAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National conference on “Recent Trends in
                Chemistry of Materials” (NCRTCM-2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d76aaa0e680e15ec623a4873f392ca81.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GSSS INSTITUTE OF ENGINEERING & TECHNOLOGY FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“EXOUSIA 2019” - An Inter Collegiate
                Technical Fest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8c7cf896871eb0afa4ffe1c52b974691.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LAKSHMI NARAIN COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->expert talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Puraskaara 2k19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4961ff07e1da868976754b2a785b3c5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Holy Cross College (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation - Smart India Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/988a718d24fd0c21a1c6f5bd324338e1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.P.C. Mahalaxmi College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Applied
                mathematics and Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Work Shop on SAP on 5th December,2019
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Session On Carrier Planning And
                Employment Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Session with an Expert, Mr.Venkat
                Ikkurthi Data Scientist , SAS Trainer/Consultant from configure technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3-Days Work Shop on Industrial Mechtronics by
                Indwell Company</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Work Shop on Management Skills on
                28/10/2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Terza Annuale Day, An Internal Technical Fest
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon using Internet of
                Things(IoT)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3-Day Work Shop on VLSI Design using Mentor
                Graphics from 7th to 9th Nov,2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNATIONAL LECTURE ON RADIO WAVE RECEIVER
                DEDICATED TO 160th BIRTHDAY OF ALEXANDER STEPANOVICH POPOV</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on Intellectual property right</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Opportunity using Renewable
                Energy Sources</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC 2.0- INNOVATION AMBASSDOR TRAINING SERIES
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNATIONAL CONFERENCE ON RECENT TRENDS AND
                INNOVATION IN CIVIL ENGINEERING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TNSI Ideation Camp (2019-20)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visits, Student Internships </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TNSI-TAMILNADU STUDENT INNOVATORS - AWARENESS
                CAMP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Deep Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FESTIVAL OF MOONSHOT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert lecture </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6249f9cb063c4bf27a0c8f1ab4c1dbc8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SDP Open Source Tools</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on NLP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> ENTREPRENURSHIP AWARENESS CAMP(EAC)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/75abf80ea830a529332daa0f71015bca.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.Narayanamma Institute of Technology & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Web Development using PHP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/256d273c36549ce5e4570bda25f85076.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAMBRIDGE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->தொழில்நுட்பமும் சமுதாயம்</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->coding activiy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pathway Towards Product Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ab5ed52e5271236d582dac2db67c116f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="J.K.K.NATTRAJA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON INTELLECTUAL PROPERTY RIGHTS AND
                ENTREPRENEURSHIP </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY WORKSHOP ON INNOVATION VOUCHER
                PROGRAMME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIX-DAY FACULTY DEVELOPMENT TRAINING
                PROGRAMME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/294aeb76b5d031e3440fefe0d0bf7f46.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. J. SOMAIYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->German Renewable Energy Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Social Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/294aeb76b5d031e3440fefe0d0bf7f46.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. J. SOMAIYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Session - Entrepreneurship, Engineering
                and Innovation - the pillars of a successful startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conducted training from EDII Incubation
                centre hub SASTRA University.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/61a13b5c1b8d21f65d147e206854b347.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KASEGAON EDUCATION SOCIETYS RAJARAMBAPU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on Entrepreneurship
                Development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy Ambassador Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to IC Centre Jadavpur University</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry visit to MSME Tool Room and Habbtech
                Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Smart India Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Entrepreneurship (13-25 January)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Week on SIH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAREER_2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MEMTECHATION 2020- A Memory Technique and
                Ideation approach</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4a3b238de504b7adafdbc18a484136e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.M.S. SERMATHAI VASAN COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Energy Conservation Day Celebration
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Feild Work on Market Survey and Data
                Collection</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cultural Immersion Programme </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a15134405267383e6683d33a155921aa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CGC College of Engineering, Landran">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Innocations in
                Computing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD Training for Ambassador </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2c1abe83afc3f077288452d6d600634.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="EXCEL COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science and Tech Expo </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical talk on Fundamentals of
                instrumentation, control and automation systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WOMAN ENTREPRENEUR BAZAAR </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Back to Campus : A session with an alumni
                Entrepreneur </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/49854e16246ff72d9a17184dbb76b2a7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND PHARMACY COLLEGE, ANAND  205 ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day National Seminar on Startups &
                Entrepreneurs: Trends and Lessons</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day talk on society for automobile
                engineers (SAE)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day motivational talk on
                entrepreneurship- Sunsez solar CEO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea competition in KMEA “NOVUS 2K19”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e04717f985f6bb46dc7f44390e549e27.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DPG Institute of Technology and Management">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Ambassador Training Series for the NWRO </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Role of Women in Current Indian
                scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Innovation in Engineering
                Safety Devices for Girl</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Administrative Essentials for New Admins in
                Lightning Experience (ADX 201)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Datathon- A Story Telling Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Term Course On Internet of Things and
                Wireless Sensor Networks </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Short Term Course On “Advancements
                in Manufacturing and Material Processing – AMMP” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Term Course on Advanced Functional
                Materials.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to
                microcontrollers,microprocessor,sensor their interfacing and display of LFR and Wireless Controlled
                bots.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Short Term Course/Faculty
                Development Program Artificial Intelligence using Deep Learning and Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Explore ML Session by DSC and Coding Club NIT
                Jalandhar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One week FDP on AI and Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b44f954d1bd642960977151b019d48b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Patna">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electronics System Development &
                Manufacturing: Product Development Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b44f954d1bd642960977151b019d48b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Patna">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hack O Latte- Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b44f954d1bd642960977151b019d48b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Patna">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur 101 - Expert talk series </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/973f573917a12c542bc2ec561567c546.html"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sarada College for Women(Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introductory mentoring on IIC Units</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Success Story Telling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mission: To identify and close all the
                abandoned borewells </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be876773ee7d27d208a3a835909a4320.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI MANAKULA VINAYAGAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational speech Entrepreneurship/Startup
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be876773ee7d27d208a3a835909a4320.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI MANAKULA VINAYAGAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea submission for internal Hackathon for
                SIH2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be876773ee7d27d208a3a835909a4320.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI MANAKULA VINAYAGAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathan for Smart India Hackathon
                2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Skill Based Training Project on Plant
                Propagation, Nursery development, Plantation and Environment conservation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d557650b821acb80a02d6a08fe6cd0a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pravara Institute of Medical Sciences, Ahmednagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EUSIM Simulation instructor course</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f7cb79aafaddb85590db758b19d6827c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. Abdul Hakeem College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->solar ambassador workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f7cb79aafaddb85590db758b19d6827c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. Abdul Hakeem College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Arduino in Proteus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hult Prize 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on TECHNOLOGICAL
                INNOVATION ON CLEAN ENERGY GENERATION AND ENVIRONMENTAL REMEDIATION </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SKILL DEVELOPMENT WORKSHOP ON TERRARIUM,
                BOTTLE/DISH GARDEN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0d5dcb71381806b3733e29f8dc6edaf5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ATHARVA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AICTE-ISTE approved 2 Weeks STTP on
                \&#34;Machine Learning & IoT\&#34; (Beneficial under Career Advancement Scheme of AICTE)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of youth in mitigation of corruption</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9b1a0ffe2babd980af98da30cb084476.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROF RAM MEGHE COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One-day Workshop on Intellectual Property
                Rights and Patenting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9b1a0ffe2babd980af98da30cb084476.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROF RAM MEGHE COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Amravati Startup Business Plan
                Competition-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9b1a0ffe2babd980af98da30cb084476.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROF RAM MEGHE COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on
                ‘Entrepreneurship’ sponsored by NSTEDB, DST-GOI, New Delhi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship on Estimating and Costing
                (Building Planning)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/922ed2a673a7f2efd2f8f38df6a909f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITIUTE OF ADVANCED MANAGEMENT AND RESEARCH, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON START-UPS & DIGITAL MARKETING:
                ROUTE TO GROWTH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/922ed2a673a7f2efd2f8f38df6a909f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITIUTE OF ADVANCED MANAGEMENT AND RESEARCH, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IAMR ALUMNUS INTERACTIVE SESSION ON
                COMPLEXITY & SUSTAINABILITY IN 21ST CENTURY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/922ed2a673a7f2efd2f8f38df6a909f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITIUTE OF ADVANCED MANAGEMENT AND RESEARCH, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT TO WASME-NOIDA-16A</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prabandhan Activity on Debate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prabandhan Activity on Group Discussion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise a workshop/Seminar/Call for Paper
                presentation on Innovation/Social Innovation & Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise a workshop/Seminar/Call for Paper
                presentation on Innovation/Social Innovation & Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Demo Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4961ff07e1da868976754b2a785b3c5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Holy Cross College (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days State Level Workshop on
                \&#39;Intellectual Property Rights\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Day workshop on Design process of Nano
                Satelite</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18ab02ad72454ee849b40bc9b0515812.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C.V.RAMAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robust Startup Ecosystem In Odisha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff51f222c42257aef29779584113e64a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R. C. PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH 2020 Campaigning / Promotion </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff51f222c42257aef29779584113e64a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R. C. PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campaigning of Startup India Upgrade Free
                Courses</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Soft Skill Development: Communication &
                Information Seeking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff51f222c42257aef29779584113e64a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R. C. PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Competition ( Second Call)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3rd International Conference on Innovations
                in Mechanical Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3rd International Conference on Innovations
                in Mechanical Engineering - Pre Conference Tutorial</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar - \&#39;Employment scope for Diploma
                Engineers\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk - Medha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Workshop on Advances in
                Evidence Based Medicine-Learning and Teaching: From Benchside to Bedside</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Engineering Mathematics Tips &
                Tricks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Universal Human Values</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical paper Writing and Buisness
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH2020_Internal Hackathon@ABESIT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5edefd40a4b5d3c3e4c6d541e68f9d57.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Ayyappa College for Women, Ayyappanagar, Chunkankadai - 629 807, Nagercoil - 629 807">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Factory visit and experience sharing with
                entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Web Application Development on Bubble.io</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1069f57a07f8f270cfe9edddff0bac23.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BABU SUNDER SINGH INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNICAL POSTER COMPETITION </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->High Performance computing and Big data
                Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit of 100 MLD Water Treatment Plant
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Literary Club Inauguration and Logo Launching
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate on English language</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advance Workshop on “IP, Technology Transfer
                & Licensing” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intel - IOT Devfest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY SEMINAR “THE STUDENTPRENEURS THE STEP
                TOWARDS IDEA HUNT”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT TO VILLAGE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/39a584f8cf9ae2f4faf5b8916967556e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jayoti Vidyapeeth Women's University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Health Awerness & checkup Program-Yoga Camp
                (Any Innovative Asan )</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/39a584f8cf9ae2f4faf5b8916967556e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jayoti Vidyapeeth Women's University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Exhibition-2019 Chart and Poster
                Making for Innovative Idea dealing with Social, Health and Environmental Issues</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/39a584f8cf9ae2f4faf5b8916967556e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jayoti Vidyapeeth Women's University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Idea Contest-to identify
                innovative ideas to solve the real life problems. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organize Training Programme on \&#34; TIME
                MANAGEMENT\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel discussion on industry expectations in
                the new age with respect to innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#39;CII National Debate Competition\&#39;
                on the theme \&#39;The India We Want\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP/ Workshop on Artificial Intelligence,
                Machine Learning & Analytics with ‘R’ was conducted at DR VN BRIMS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Youth Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BMA INNOTHON Competition 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1069f57a07f8f270cfe9edddff0bac23.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BABU SUNDER SINGH INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LOGO DESIGNING COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to MARG PORT, Karaikal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on Intellectual
                Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction of Avishkaar & its products
                related to IoT and Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guiding Talk for Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d88a232028b9cb30c7d717d41c5f199.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PARALA MAHARAJA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on recent trends and challenges in smart
                grid environment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d88a232028b9cb30c7d717d41c5f199.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PARALA MAHARAJA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Seminar on Recent Trends on
                Smart-grid Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63fc9d91e3f11e8f04a88836ef94b3e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. MARY&#39;S  COLLEGE, THRISSUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on workshop in Electric bulb assembling
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/75672dbf7e4a0c0f700a4c1e6f0f2f30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Savitribai Phule Pune University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IP Workshop & Innovation Solutions for
                Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Welding Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty development programme on
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Video Presentation on \&#34;Students start up
                for Sustainability\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ef96a7c8e8e72b10fa275302550bc7be.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="College of Computing Sciences and Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Trip to village and other rural areas
                for problem identification and technology awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on \&#39;Finances of Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A session \&#34;Is college right time for
                start-ups?\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre-feasibility of a Project: Prepare PPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Marketing 5.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Workshop on “Transformative Leadership &
                Creativity in Action”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PITCHING WORKSHOP AND PITCHING COMPETITION
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/58f33f09c84570a2e08a07021caa9f75.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNICAL TEACHERS&#39; TRAINING & RESEARCH, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Capacity building of entrepreneurial managers
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on \&#39;Job Vs
                Entrepreneurship Vs Higher Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Emerging trends
                in Information Technology and Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Additional Skill Acquisition Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2bc3f564af44eebbd5434b8ccb3c199d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY COLLEGE OF ENGINEERING AND TECHNOLOGY (UCET)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Outreach & Skill Development Program
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meeting of InnovationClub@IGNOU, December
                2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d066a99f61a7def0f7a300bd9a2cf1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mangalam College of Engineering, Mangalam Hills, Ettumanoor P.O, Kottayam-686 631">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mangalam Hackathon 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d066a99f61a7def0f7a300bd9a2cf1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mangalam College of Engineering, Mangalam Hills, Ettumanoor P.O, Kottayam-686 631">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Days Hands on Workshop on “Decoding
                Hackathon” – A demystifying initiative</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d066a99f61a7def0f7a300bd9a2cf1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mangalam College of Engineering, Mangalam Hills, Ettumanoor P.O, Kottayam-686 631">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz on Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d066a99f61a7def0f7a300bd9a2cf1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mangalam College of Engineering, Mangalam Hills, Ettumanoor P.O, Kottayam-686 631">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Solar Ambassador Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d066a99f61a7def0f7a300bd9a2cf1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mangalam College of Engineering, Mangalam Hills, Ettumanoor P.O, Kottayam-686 631">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Competition for School and College
                Students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d066a99f61a7def0f7a300bd9a2cf1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mangalam College of Engineering, Mangalam Hills, Ettumanoor P.O, Kottayam-686 631">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Literacy (Financial) Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d066a99f61a7def0f7a300bd9a2cf1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mangalam College of Engineering, Mangalam Hills, Ettumanoor P.O, Kottayam-686 631">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pre Placement Training Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launch of WEAS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IOT with Machine learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Innovation Award 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Open Session on ‘Importance of Time
                Management for Effective Studies’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Soft Skill Development for Industry Grounding
                An innovative initiative by IGNOU Study Centre </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Open session on ‘Domestic Environmentalism –
                Approaches and Strategies’. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Innovation and Start up policy 2019
                for students & faculty</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture of Dr. Sekhar Bhattacharyya</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Conduct Market Survey: Tools,
                Techniques & Guidelines</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Saturday Video Classes at IGNOU Regional
                Centre Port Blair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture of Professor Robert Finkelman,
                University of Texas, Dallas,USA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive sesssion on “Entrepreneurship and
                Innovation as Career Opportunity”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Opportunity Identification</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH - 2020 Hardware Edition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pencil Sketching Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->about entrepreneurship and business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Multi - Disciplinary Application Perspective
                in IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days National Workshop on Entrepreneurial
                skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WE ENABLE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3461469ffd53eb377c3c5420f5685410.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY HYDERABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3461469ffd53eb377c3c5420f5685410.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY HYDERABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2bc3f564af44eebbd5434b8ccb3c199d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY COLLEGE OF ENGINEERING AND TECHNOLOGY (UCET)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UCET Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Seminar on typical pavement
                distresses</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Cloud Computing and Hadoop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GUEST LECTURE On Self-Motivation and
                Self-Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2aa0ddc20c0a0015df7b4f5890502314.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF PHARMACY, DHARMSINH DESAI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Submission for SIH 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ae15bedf419b2cf9cc6a392ad65008fb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajadhani Institute of Engineering and Technology, Alamcode, Attingal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Paper Presentation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ae15bedf419b2cf9cc6a392ad65008fb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajadhani Institute of Engineering and Technology, Alamcode, Attingal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Auto Quiz Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ae15bedf419b2cf9cc6a392ad65008fb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajadhani Institute of Engineering and Technology, Alamcode, Attingal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Treading and Taper Turning Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ae15bedf419b2cf9cc6a392ad65008fb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajadhani Institute of Engineering and Technology, Alamcode, Attingal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Group Discussion Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/571182f8fc549f55a92fbc09972a0856.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.G.REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start and Improve Your Business (SIYB) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f818bb370fc58141718cfa0799e31488.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SARADA COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internshala Student Partner Talk Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DST-NIMAT Sponsored Entrepreneurship
                Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Speak Your Mind\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Awareness/Mentoring Session on IPR &
                IP Management for Innovation and Start-ups-Prof. Dr. Anirban mazumdar, The WBNUJS.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30792f2ede95c30ac27bb6914d1fc945.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Immersion Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30792f2ede95c30ac27bb6914d1fc945.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sharp 19 - 20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneurship and family
                Business –SAMISH DALAL, Associate Professor In charge of the Global Family Managed Business Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Navigated Learning: Pedagogy for a connected
                world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b155b54590f89b7a2b1ad6252c85cbf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Evotech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b155b54590f89b7a2b1ad6252c85cbf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b155b54590f89b7a2b1ad6252c85cbf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Salesman Of The Year</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quantum dot-based deep tissue imaging in the
                second optical window</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovate idea challenge </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Novel: A Taste of Chocolate by its Own
                Author: Its Writing process, Elements and writing techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e42bdc99577982c525609ff137df33b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARISHI MARKANDESHWAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SLOGAN & DECLAMATION pharmacy week activity
                report</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6c9a251fcd8626283e6e607f73616c24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTHAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EDC- Why should become an Entrepreneur?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENGINEERIA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Winter school cum skill development program
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b561843ab31fdf0336cfd1551f90562.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI G.S.INSTITUTE OF TECH. & SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HackIndore 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6249f9cb063c4bf27a0c8f1ab4c1dbc8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YESHWANTRAO CHAVAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf6382261b2885cc4fbe6eb969499767.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATIBHA INSTITUTE OF BUSINESS MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Idea Generation Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f70e67976bdcf71f2225dc662b1fc6d7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LORDS INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Current Awareness on Mechanical Engineering
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f70e67976bdcf71f2225dc662b1fc6d7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LORDS INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SOCIAL MEDIA OPTIMIZATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f70e67976bdcf71f2225dc662b1fc6d7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LORDS INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on “Material Science and
                Metallurgy\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Communication Skills and Professional Report
                Writing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MSME, Government of India Certified Lean Six
                Sigma Green Belt Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7aaed46cb988ad2b4e63c7d39b1ad066.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SWVSMS TATYASAHEB KORE INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Speaker serie</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7aaed46cb988ad2b4e63c7d39b1ad066.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SWVSMS TATYASAHEB KORE INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship awareness camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e7e3eb6eea94ea4735118bf316d683e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KGRL College(A)  - Degree  & PG Courses">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1f8c049dadd49b6f934f003a8905a04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR N.G.P.INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Pollution Control Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1f8c049dadd49b6f934f003a8905a04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR N.G.P.INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2Days Workshop on Cyber Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1f8c049dadd49b6f934f003a8905a04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR N.G.P.INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Expert Lecture on Design of RCC Slab
                as per Is:456 - 2000</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1f8c049dadd49b6f934f003a8905a04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR N.G.P.INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Skill Development Hands on Training
                Programme on IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1f8c049dadd49b6f934f003a8905a04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR N.G.P.INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Club Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1f8c049dadd49b6f934f003a8905a04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR N.G.P.INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/988a718d24fd0c21a1c6f5bd324338e1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.P.C. Mahalaxmi College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tamil Nadu Innovators Ideation Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Youth Conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An overview of the world famous
                \&#34;Artificial heart project\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on innovations in civil engineering
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f6e4d0f96f5eb01d44c44e9f5d658e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. Shyama Prasad Mukherjee International Institute of Information Technology, Naya Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Deshpande Startups and Incubation Center at
                Hubli </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship cum Training </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b1c49128e69fdd393464b4fa17ed0a0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW DELHI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interaction with Policy Makers & CEOs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/701e493880429f5d15317f5b1d857a20.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE COLLEGE OF ENGINEERING 346">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON ADVANCE AUTOMOBILE TECHNOLOGY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/701e493880429f5d15317f5b1d857a20.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE COLLEGE OF ENGINEERING 346">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit at National Small Industries
                Corporation Ltd.(NSIC)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8a1f7ae2c8908a1c2a56b234997ea80.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IMS ENGINEERING COLLEGE, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organize One Day Workshop on Problem
                Solving/Design Thinking/Ideation Workshop/Campus Hackathon etc.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MOU WITH NTTF</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARDS FUNCTION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Wild life preservation -Walkathon 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BITES ANNUAL CONVENTION 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aarohan - 2019 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Embedded Programming and IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on PCB Milling and Embedded Systems
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech Fest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Program on Employability Training Program on
                Financial Literacy </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->अप्रतिम प्रज्ञा प्रतिस्पर्धा – 2019
                Innovative Projects/Model Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patents and IPR - A brain Storming Session
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6a642a537bc950221447ba69197fca75.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Devki Devi Jain Memorial College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of World Students Day in the
                Memory of former president of India Dr. APJ Abdul Kalam</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Plant </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on
                \&#34;Innovative ways for Classroom teaching and Stress Management”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Marketing Choupal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Goods & Services Tax Management Program
                (GSTMP)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking and its global relevance
                seminar 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Certification Program on Digital Marketing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PYTHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Enhancement through MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f70e67976bdcf71f2225dc662b1fc6d7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LORDS INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ef96a7c8e8e72b10fa275302550bc7be.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="College of Computing Sciences and Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82709ebdaeb148a6aea72eba2a50cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Scheme of Assistance for Agencies for MSMEs
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Workshop- Beautician Course
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6debb4348fb0f50adbc81979e59b516.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIYADARSHINI J. L. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of 150 yrs of Modern Periodic
                Table </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Launching vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Workshop-Digital Marketing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Broadcasting Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/502ae28cbbee442893a543d3cb73f6fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.V.C. College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Latest Technology enabled ICT Tool in
                Engineering Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6debb4348fb0f50adbc81979e59b516.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIYADARSHINI J. L. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Surgical Laboratory Techniques in
                Pre-Clinical Pharmacology </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Multimedia and 3-D Graphics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IETE Inauguration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WebPageDesign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8425d42248583e9d4da298b234bf7199.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Banarasi Das Chandiwala Institute of Professional Studies">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop – ‘Foldscope Microscopy’
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Release of Innobuzz: A Quarterly newsletter
                of AIPER IIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in Photography Startup-Selfi Booth
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Teaching Pedagogy and Modern Teaching Tools
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Constitution day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship awareness camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivation Talk on Innovation by Alumni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Public Speaking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tinkering lab visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8303b3879aba5ad16c00d1d0d3002239.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon for Smart India Hackathon
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on ‘Conflict Management’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on “Creating a Social Impact
                with Profitable Business”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intership & its Benefits</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enhancing Industrial Interaction with TCS and
                MCED</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar Conducted by TCS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> One day workshop on 3D Printing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property: Know your rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of Indian Constitution Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Approach for Thesis & Technical
                Paper</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campaign : One Student One Tree</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on placements and career
                guidance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Big data analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session delivered by Dr. Lisa Sreejith to
                students and Teacher from Nayarkuzhy school</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on Training on 3D printing and PCB
                design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->University Level Project Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR Awarness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global learning program Neatherlands </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The 10th International Conference on
                Technology for Education (T4e) 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Understanding Crowd Funding through Project
                Library</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill, Employability and Entrepreneurship
                Fair </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chem Jobbers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Edge Tutorials Academy and counseling center®
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/076c8690c134f458aba2e803c816cb49.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Day to Day Economics and Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to Internal Hackathon - Facebook
                Live session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e0f04b97c8d5b8542548951ee1fdd0f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA & B M REDDY COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni interaction and Industrial Experience
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e0f04b97c8d5b8542548951ee1fdd0f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA & B M REDDY COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni interaction and Industrial Experience
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e0f04b97c8d5b8542548951ee1fdd0f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA & B M REDDY COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Medical Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e0f04b97c8d5b8542548951ee1fdd0f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA & B M REDDY COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Human rights day celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon for SIH 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/86d12341d55a48204d72859433bde388.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KSR INSTITUTE FOR ENGINEERING AND TECHNOLOGY, NAMAKKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days FDP on Outcome Based Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6b6f523bab50c07953d3f7b3e6616b8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MADANAPALLE INSTITUTE OF TECHNOLOGY & SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Agripreneurship Orientation Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vibrant Start Up and Technology Summit 2019
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1182108f07d29279128026688d863435.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING TRIVANDRUM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days Workshop on Artificial
                Intelligence & High performance computing phase - II</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rehabilitation Engineering and Assistive
                Technology Devices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Nuclear power plants</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Governance & National knowledge network</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARENESS PROGRAM ON FUNDED PROJECTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Display </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Logo Creators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SKCT Codathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hour of Code</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learnathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Clean and Beautiful Surroundings innovations
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Employability Training and Upskilling
                Programmes through CSR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WESchool Alumni Meet & Entrepreneurial Awards
                2019 - “Pride of Welingkar”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Govt.Funding Opportunities for start-up &
                Significance of \&#39;Udhog Aadhar\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4af388008f53e1491fc08ce2ce4e0299.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DON BOSCO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring sessions for start-ups with Mrs.
                Aditi Tiwari on Go to Market Strategy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/baced9287d4f171c3bd5e5529253ca94.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MVJ COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gandhi Global Solar Yatra – Student Solar
                Amabassador Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Practical experience of managing a business
                with market research and proving innovative solutions to boost sales.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IMPORTANCE ON SOFT SKILLS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“IOT USING PYTHON”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RECENT APPLICATIONS IN COGNITIVE LEARNING
                WITH DATA SCIENCE”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09886d07014301d1d888613d01739cfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MS Ramaiah University of Applied Sciences">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Danta Kavacha </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ethical responsibilities of an engineer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f5bf254131b7a46b2466e63ea9a460c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAGHAVENDRA INSTITUTE OF PHARMACEUATICAL EDUCATION & RESEARCH (RIPER)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on toxicity prediction tool in drug
                development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotic Process Automation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f5bf254131b7a46b2466e63ea9a460c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAGHAVENDRA INSTITUTE OF PHARMACEUATICAL EDUCATION & RESEARCH (RIPER)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->motivational talk on women entreprenuership
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TQIP workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3bb4d813d8707b44925d6a7d30279a8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SPHOORTHY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL HACKATHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3bb4d813d8707b44925d6a7d30279a8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SPHOORTHY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR TALKS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3bb4d813d8707b44925d6a7d30279a8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SPHOORTHY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEAM BUILDING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Enhancement Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f40889ef9e71bd4dbf9416821f123df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in teaching learning in Higher
                Technical Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f40889ef9e71bd4dbf9416821f123df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training programme on solidworks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f40889ef9e71bd4dbf9416821f123df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training programme on Machine learnings</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation and startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/86d12341d55a48204d72859433bde388.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KSR INSTITUTE FOR ENGINEERING AND TECHNOLOGY, NAMAKKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E Talk by Captain Raghuram</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5G Based RF Front End Design Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCB Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Optical communication workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Black & White Pencil Drawing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Photography Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Best Out of Waste Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR CONFERENCE 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coding Challenge - Code Bite</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participated in Innovesta 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CENTRALIZED INDUSTRIAL VISIT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd83aa1cde0b7fab8e1f6cf479e014cb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology, Dharwad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology Venture Creation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pitchers Phase 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation and Startup Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Jr. Hostel Cultural Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Speech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent Dissemination Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c50a5d45fbbffdb73eb901a1be982923.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="The M.D.T. Hindu College, Pettai, Tirunelveli - 627 008">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inaugural Function and Motivational Speech
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ALL ABOUT THE \&#34;GST\&#34; IN INDIA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Zest-Innvation & Design thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Start-up meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b5ef4e00f3f0f9064cf2a0c6a3c0ee3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RVS College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RVS Internal Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation and Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Manual Robotics Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/205db477bf0ed9e0d4a503f29e0793a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Youth Parliament</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c4c57f9bd770f640fa88f772281216e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dahiwadi college Dahiwadi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->faculty development programme in
                entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Data Science
                and Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field / Exposure Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f5bf254131b7a46b2466e63ea9a460c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAGHAVENDRA INSTITUTE OF PHARMACEUATICAL EDUCATION & RESEARCH (RIPER)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual property rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Paper Presentation in the International
                Conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1dca480cf161261de85c08dd5d993ae1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUPPAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEA COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c733984fbacb5850c48c632716fa9da.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S. B. PATIL COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart India Hackathon Awareness Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HEMCE 2019 - 12th International High Energy
                Materials Conference & Exhibit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bb9771a9b6032b870a8da157a85b821d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF CHEMICAL TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Competition for medical products development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bb9771a9b6032b870a8da157a85b821d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF CHEMICAL TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on building business model</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bb9771a9b6032b870a8da157a85b821d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF CHEMICAL TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student visit to campus: NGO students visit
                to ICT Campus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5de72eaa1196448c2e4c03f50d29ca0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jamia Hamdard">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Technical Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indian Constitution Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vigilance Awareness Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creativity in Teaching Learning Process-
                Assessments & Ideologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->What it Needs to be a Data Scientist –
                Roadmap Explained</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to Angular JS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Requirements of Internet of Things
                (IoT) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Computing Paradigms for Emerging IoT
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal Hackathon@ABESIT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5bb606ce63428433364d20e30c85944b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IK GUJRAL PUNJAB TECHINICAL UNIVERSITY, JALANDHAR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Term Training Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e-Governance Outreach Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to near by Village</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit to School</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Market, Market Research Survey
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/598d73b344e4335d5cca78550e06b9bd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ASIAN BUSINESS SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6c9a251fcd8626283e6e607f73616c24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTHAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Software Conceptual Design”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unnat Bharat Abiyan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->handson Workshop on Microcontroller
                programming Skill Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Suryamitra Skill Development programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International conference RESE 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Making Washing Powder</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Week Training Programme on HVAC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Departmental Project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on “Technology-Aids for Innovative and
                Effective Teaching and Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8670d3cff4bc21cb05a869dcbfd185f1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Santhiram College of Pharmacy, Nandyal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Trip to I Year B.Pharmacy Students and
                induction program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on “Web
                Programming for IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Data Analytic
                Tools</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8670d3cff4bc21cb05a869dcbfd185f1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Santhiram College of Pharmacy, Nandyal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on skill development and
                placement training program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FACULTY VISIT TO INCUBATION CENTER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8670d3cff4bc21cb05a869dcbfd185f1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Santhiram College of Pharmacy, Nandyal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship awareness student camp at
                industrial environment </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship exposure to students through
                industry interaction </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b561843ab31fdf0336cfd1551f90562.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI G.S.INSTITUTE OF TECH. & SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One week STTP on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on \&#34;Offshore Analysis using
                Abaqus\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Alumni Interaction programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Alumni Interaction programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An entrepreneurial talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands-on training on “ADX-201 Administrator”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Clinic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to industries </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on \&#34;OPPORTUNITIES IN THE
                FIELD OF MARKETING AND MANAGEMENT\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Alumni Interaction programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Clinic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Journal Writing, IPR and Patent
                Drafting”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “Search Engine Optimization”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Alumni Interaction programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on “Energy Conservation\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RECENT TRENDS IN BIG DATA AND CLOUD COMPUTING
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation in Engineering (Project EXPO)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Latest Tools in Matlab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Tour - Industry Interaction </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1f8c049dadd49b6f934f003a8905a04.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR N.G.P.INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indian Constitution Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Interaction – Seminar on research
                opportunities by Shri T V S Nagaraj Kumar, Dy. Manager, BHEL R & D</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solar Ambassador Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Short Term Training Program on R
                Programming and Hadoop in Big Data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy Conservation Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNSHIP ON ELECTRIC FORMULA RACE CAR
                DESIGN & DEVELOPMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Optimization with PLM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Plexim’s Simulation tools:
                Advanced Modeling and Simulation of Power Electronics Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Behaviour of Elasticity and Plasticity of
                Structures</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Program on Typhoon HIL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Trends in Transportation Geotechnics
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Interaction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical hands on training – “Hour of Code!”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on the title “Advanced
                Industrial Robotics and Automations“</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on “Introduction to Angular JS ”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d557650b821acb80a02d6a08fe6cd0a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pravara Institute of Medical Sciences, Ahmednagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION - A KEY TO SUSTAINABILITY </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology Incubation Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start-up Summit 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Aspiring Puducherry - Innovation & Start - up
                Policy 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f70e67976bdcf71f2225dc662b1fc6d7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LORDS INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/75abf80ea830a529332daa0f71015bca.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.Narayanamma Institute of Technology & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart Search with IEEE Xplore Hands-on</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on IPR and patenting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme under
                AICTE-Margdarshan Scheme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/623610f64a1a59445fc5d3060929266c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chevalier T.Thomas Elizabeth College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Students Exam stress and techniques to
                resolve it </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Congress on Renewable Energy
                (ICORE) 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Revolution in Pharmaceutical Technology via
                Computational Intelligence.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Paper Writing Skills in IEEE
                Standers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Xillinx design tool</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MOU Meet - Digital Wellbeing in Association
                with End Now</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IEI Student Chapter Inauguration Ceremony</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Complex Stresses</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design of Keys & cotter joints</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Faculty Development Program on AI & Machine
                Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Promoting Research and Innovation among
                faculty members through RM Workshop “Hands on SPSS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to CSIR- NAL </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on User Interface Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Design and Development of
                UAV\&#39;s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days Workshop on Design Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->iDEX 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47a50a95d9b1df73a8429840ff802269.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhartiya Skill Development University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Day Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A World In Motion (AWIM) 12TH NATIONAL
                OLYMPICS 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Online Trading
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL EXPOSURE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->entrepreneur exposure</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Retail Employees Day 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational talk on Entrepreneurship by Dr.
                Urimindi N Reddy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A training program on mushroom cultivation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day student activities on Bamboo crafting
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Spreading awareness about the innovative
                technologies developed by Tripura University</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Adopted Village </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Advancements in Electrical Drives and
                Control</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Renewable Energy Systems-Hands on Training
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6bf44e54057cc01943b3c8a715fa4ed.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSIT College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CodeX-404</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Trainning Programme on “Pedagogy And
                Active Learning” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coffee with CEO </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fabrication/ Prototype lab Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Blockchain and its Applications”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Four days workshop on \&#34;How to Teach on
                Integrated CS Course</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Pitch your Idea”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Internet of Things”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Ornithopter-Mechanisms,Electronic
                Assembly & Real Time Flying</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c54dcacbbe73ac974bb266de4746c0ac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACS College of Engineering, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Innovate and Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Workshop for School Teachers
                on \&#34;Enhancing the Teaching Skills using 3D Printing Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creativity and Innovation workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COMPUTER NETWORKS AND DATA FLOW IN THE
                INTERNET WORLD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ec079ae6dae5f61f28ec90ccd2941237.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRASAD V POTLURI SIDDHARTHA INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> INNOVATE-O-THON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/22e95ee52796ee0b5da65b3bab79fd58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAPATLA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day national level Techno Carnival -
                Techkshetra 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2-Weeks Work Shop on Auto Desk Revit
                Architecture fro 9th to 21st September,2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Solar Ambassador Work Shop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Food Safety Training Program on Hygiene and
                Sanitary Practices for Anganwadi Center Workers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MOU signing ceremony with Export promotion
                center , Madurai </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Art of Living</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster presentation on Medical
                Instrumentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AP STATE LEVEL ISTE STUDENT CONVENTION on
                “Current and Emerging Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bb9771a9b6032b870a8da157a85b821d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF CHEMICAL TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bb9771a9b6032b870a8da157a85b821d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF CHEMICAL TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Foundation Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE NATURAL PRODUCT ISOLATION PROCESS
                DESIGN COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bb9771a9b6032b870a8da157a85b821d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF CHEMICAL TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vortex IDP - Problem Solving Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in 14th Avishkar Research
                Convention (2019-20)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Food Festival for young Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Design & Analysis of
                Algorithms</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Design & Analysis of
                Algorithms</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c173c8afc3eb0fec8d9b5d4bc8ff932.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dempo Charities Trust Dhempe College of Arts & Science Miramar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chiasma Science Exhibition cum innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->English Vinglish</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Quantity Surveying by National
                Academy of Construction (NAC) Hyderabad </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on training on Data Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9a3213d4e2253d96408f90c35a797b7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY ANANTAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme (FDP) on
                “Training of Mentor (Train the Trainers)”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9a3213d4e2253d96408f90c35a797b7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY ANANTAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One-Day Workshop on Entrepreneurs Awareness
                Camp / Innovation Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9a3213d4e2253d96408f90c35a797b7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY ANANTAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One-Day Workshop on Intellectual Property
                Rights and Company Registration Compliance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/46571fd4110447ef5aeafbac01095f67.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VASAVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to a nerby incubaltion cell T-Hub
                Hyderabad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->D2C Entrepreneurship Course</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> “Patent – Fantastic Ideas and How to Protect
                Them?” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on advanced aspects of spectroscopy
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Getting Started into Tech World</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HTML and CSS Training program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on \&#34;Computational
                Mathematics\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->seminar on Innovative Technologies </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d1c9971d20cfccfc5b8dbcb2089c493.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Trinity Academy of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovations in Data Science under
                PMMMNMTT ,Funded by MHRD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d1c9971d20cfccfc5b8dbcb2089c493.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Trinity Academy of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Zensar FDP for Innovation in AI and ML</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Constitution Day celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop at \&#34;Systango\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->thesynapse #ilead</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campus Ke Superstar </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Amazon Alexa Skills Workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotics Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Anandam Senior Citizens Group </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->corporate training in Soft Skills, Verbal
                ability and Aptitude </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PHOTON 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sawacch Traffic</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Capacity building</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Traffic Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sawacch Traffic </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Uraan IT Solutions </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on workshop on \&#34;Java
                Technology\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Five Days Hand on workshop on \&#34;Solid
                Edge 2019\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Closed Campus Recruitment Drive of SGS
                Technical Services </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e58aea2d0d6fb40ca5f29586ba4b2532.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMBEDKAR INSTITUTE OF ADVANCED COMMUNICATION TECHNOLOGIES AND RESEARCH , GEETA COLONY DELHI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Automated Waste Segregation Bin</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Design I, II, III</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY HANDS ON WORKSHOP ON \&#34;ROBOTICS
                AND ITS REAL TIME APPLICATION USING LEGO MINDSTORM EV3\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Visit to MSME development Institute,
                kolkata</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->7 Days Workshop on PCB Design, Component
                Assembly & Testing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Digital Insecurities in
                Digital World</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9756c6c8cebc52854414ea0cf3f06a4a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Matrusri Enginering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Problem Based Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CSR an integral part of business/Project
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day PV Booster Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities in Futuristic IT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Art of Defining Research
                Problem/Questions and Awareness on Intellectual Properties Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE NUTRACEUTICAL PRODUCT COMPETITION
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Professional Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit Bhopal Smart City B-nest </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Cyber Crime and Cyber Safety
                awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Latest Trends in Fibre Optics and
                FTTx </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SOLAR AMBASSADOR WORKSHOP 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -guest lecture - ROLE OF BANKS IN THE
                SOCIETY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -Guest lecture -Price war</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA- Workshop -STRATEGIES FOR FUTURE BUSINESS
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -Guest lecture - Preparing for
                Competitive exams</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -Workshop - Lingua skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA-Guest lecture -QUALITATIVE DATA ANALYSIS
                IN RESEARCH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -Guest lecture -DO YOU WANT TO BE AN
                IAS/IPS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -Guest lecture - LEADERSHIP SKILL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual property :copyright, Trademarks
                and patents </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Group Discussion on National INNOVATION and
                STARTUP Policy 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->L&T TECHgium Challenges </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start With Why: by Simon Sinek.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship skills exposure </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UML Global Entrepreneurship Series: Social
                Media: Creating New Possibilities for Entrepreneurs – Dr. Spencer Ross Confirmation.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship talk </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Four Weeks Entrepreneurship development
                programme Hapur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Four Weeks Entrepreneurship development
                programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation and Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp (EAC) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Four Weeks Women Entrepreneurship development
                programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93af762bac666ae55e415bf9cabdeaf7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BIRLA INSTITUTE OF MANAGEMENT TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Management Cases
                2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/12c1ce9aaff472378947b74249811a3e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on “GRE and TOEFL:
                Opportunities Abroad”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/12c1ce9aaff472378947b74249811a3e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “Cardiovascular Consequences
                of Eicosanoids”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/10949f76de640d28b374ddcdf917bcc2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Maharaja Agrasen Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship at Innovation & Start-up
                Centre/Start-ups/Incubation Unit etc.during Semester Break </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ec079ae6dae5f61f28ec90ccd2941237.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRASAD V POTLURI SIDDHARTHA INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCB design workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PROJECT MANAGEMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IEEE Xtreme13.0 Programming Competition 19th
                October 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Addressing global trends and challenges in
                drug product development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d03b49c16a000e8e4f44105fe280466.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="L. M. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup and Investment Opportunities in
                Healthcare and Pharmaceuticals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c733984fbacb5850c48c632716fa9da.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S. B. PATIL COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->School Students on Campus for Technology
                Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f4cb6caa808618bb732ec19e163d8d17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNEHACHARYA INSTITUTE OF MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Council Formation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40d13e359a0d710cfb11750a69a015d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B V V SANGHAS BASAVESHWAR ENGINEERING COLLEGE BAGALKOT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Presentation Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovation & Startup </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneurship Development in
                rural areas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -Guest lecture on Services Marketing –A
                Global Perspective</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -Guest lecture on \&#39;AN INTRODUCTION
                TO ‘ANAOVA’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Over Pressured Layer Chromatography</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MMBA -guest lecture on motivation - CHOOSE
                YOUR PATH –JOB or CAREER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA-Workshop on \&#39;A SEARCH FOR MY BRAND
                IN THE MARKET\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA -Guest lecture on \&#39;HOLISTIC
                WELLNESS’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on \&#39;SALES AS A
                CAREER\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORK SHOP ON CASE STUDY ANALYSIS – A
                PRACTICAL APPROACH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneur/Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Entrepreneur/Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6f3e3e9050fb84505c471b890d0ef6bd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.O.C.College of Education (Govt.Aided)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on QR Code</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6f3e3e9050fb84505c471b890d0ef6bd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.O.C.College of Education (Govt.Aided)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Self Development: Yoga</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6f3e3e9050fb84505c471b890d0ef6bd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.O.C.College of Education (Govt.Aided)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Student One Tree</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b1c49128e69fdd393464b4fa17ed0a0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW DELHI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Funding Options for Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35cf37cbe17da54d9c0c879b028c609a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Marathwada Shikshan Prasarak Mandal&#39;s  Shiv Chhatrapati Arts, Science & Commerce College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Ideas presentation Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e466e07e48c245acf3d190edd6941ad8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Post Graduate Government College for Girls Sector-11">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Street Play on Drugs and Alcohol Addiction
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Placement Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Tour</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Cell Inauguration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Deep Learning and its Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Innovative Systems for Energy and Water
                Conservation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Version Control and Introduction to Jenkins
                by CEO of SMIDER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82a8f228cc4c35d164da4799ef0c2fe1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESHWARA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Mini Project </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Family Business & Mentoring</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Story telling :life of alumni Entrepreneur
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Seminar on \&#34;Entrepreneurship
                Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Rural Area Exposure Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Program using Big
                Data by SitaRam Tadepalli, TCS on 22nd September, 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE APPROACHES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indian Technology Innovation &
                Entrepreneurship Conclave (I-TEC)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47a50a95d9b1df73a8429840ff802269.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhartiya Skill Development University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mobility with Erasmus Plus</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47a50a95d9b1df73a8429840ff802269.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhartiya Skill Development University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit to Raj Bhawan of Rajasthan
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Banner Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneur/Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit-Helping Hands</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Seminar on Recent Trends in Research &
                Development on 15th Nov,2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon on Artificial Intelligence on 23rd
                and 24th September, 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation work shop as Ideathon on 14th
                September,2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Boot Camp on Internet of Things </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->6 Days Work Shop on Problem Solving Skills By
                APSSDC Team </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NSS DAY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit- Blood Donation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial trip</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Motivational Campaign for Youth
                and Prospective Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Campus Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Motivation to Become an entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Projects </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWS Cloud Architect Associate Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotics Process Automation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NVIDIA AI Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture \&#34;Digital Marketing\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARENESS ABOUT DRUG ABUSE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur Startup Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Talks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Talks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Talks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to The National Institute of
                Rural Development and Panchayati Raj (NIRDPR)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f1e57f82f9f20b2337ddc66f108a270.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MODEL INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Capacity Building & Mentorship Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Whats Next</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Samarthanam Trust for the
                disabled</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Making Money From Money </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> My Story - Innovator’s Life History</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Development of Entrepreneurs Skill </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Innovations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story – Innovator’s Life History </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AN EFFECTIVE METHOD OF SWITCHING ACTIVITY
                REDUCTION IN MULTIPLEXER USING CANONIC SIGNED DIGIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Free Medical Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FOMeC-Fundamentals of Medical Coding</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b1c49128e69fdd393464b4fa17ed0a0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW DELHI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Tips for Young Women</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b1c49128e69fdd393464b4fa17ed0a0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW DELHI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Winter Carnival</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Knowledge Sharing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PATENT FILING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensitization on Innovation to School
                Children</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Boot Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR awareness session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PoC Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/551aff87b8f1c10503f41efeb8a7d7c7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI LAAKSHMAIAH WOMEN&#39;S ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Improvement Program on IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Big Data - Machine Learning & its
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7595e957f2108e40c6f4a0cc84a7bfc3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UTTAR PRADESH TEXTILE TECHNOLOGY INSTITUTE KANPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship and Innovation as Career
                Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7595e957f2108e40c6f4a0cc84a7bfc3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UTTAR PRADESH TEXTILE TECHNOLOGY INSTITUTE KANPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit to Inovation & Incubation
                centre </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f57db11833c26a9abdf296d9911dc16.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nadimpalli Satyanarayana Raju Institute of Technology(NSRIT),Sontyam, PIN-531173(CC-NU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech weekend</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d042cb1ee3b96f1498eb7522f48c69dc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIES GRADUATE SCHOOL OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week AICTE-ISTE and IEEE BS MTTS Chapter
                Approved STTP on “Antennas and RF Components Design, Fabrication and Testing” (From 16th Dec. 2019 to
                21st Dec. 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7595e957f2108e40c6f4a0cc84a7bfc3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UTTAR PRADESH TEXTILE TECHNOLOGY INSTITUTE KANPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Academia Meet-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d042cb1ee3b96f1498eb7522f48c69dc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIES GRADUATE SCHOOL OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Short Term Training Program On Food
                Packaging and Shelf-Life Estimation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Novel Research & Projects implementation
                through IEEE Xplore</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Term Training Programme on Innovations
                and Research Challenges in IoT Applications Smart Manufacturing and Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->11th National Conference on Emerging Trends
                in Instrumentation Systems for Health Care and Smart Cities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->THE PATENT WEEK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Discover the Engineer in You</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Degree with Dignity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Basics of Neurotoxicology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Applications of Mathematical Principles in
                Pharmaceutical Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story in Clinical Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of excipients in drug delivery</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/12c9d6603474fc6b2eaed0158b045616.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="EXCEL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->State Level Project Exhibition and
                Mathematics Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engage Students for Internship at Innovation
                & Start-up Centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Six Days Faculty Development Program on FEA
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on Entrepreneurship\&#34;The Process of
                Excellence\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fb978c86086a6d7236f4d6b163eb774.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS NARSEE MONJEE INSTITUTE OF MANAGEMENT STUDIES SVKMS NMIMS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internal hackathon on \&#34;Combating
                anti-biotic resistance in underprivileged students living in ashrams and orphanages\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6c9a251fcd8626283e6e607f73616c24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTHAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on “Methodology involved in
                Conceptual Design\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Development Phases Story telling-
                (Innovators in Campus)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Shalla</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Five Day Training (Value Added Course) on
                Redhat(open source) Technology & Industry Adaptation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Seminar on implementation of
                SUSTAINABLE DEVELOPMENT GOALS in educational institutions </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c8ec9497c92cf385bedc6b0d7e9e068.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAGAR INSTITUTE OF RESEARCH & TECHNOLOGY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Exhibition and Competition 2k19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Symposium on Research,Innovation and IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NPTEL COURSE ON PATENT LAW FOR ENGINEERS &
                SCIENTIST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Workshop on Promoting Academic
                Research for Journal Publication, Patent and Funding Proposals </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Kuch Tum Socho</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Think Change Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/563e736aabc83009d62a1be364b35176.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IFET COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-sim workshop for students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Writing Research Project Proposal to
                Funding Agencies\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenge your limits!’- Student
                entrepreneurial event</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SECURITY IN CLOUD COMPUTING </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop 03</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop 02</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop 01</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs’ Business Summit 2019 – Key
                Focus – The Big Pitch Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry and Academia National Meet Cum
                Exhibition Waste Management and Social Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2c1abe83afc3f077288452d6d600634.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="EXCEL COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Seminar on Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Value Added Course on \&#34;Connect with
                Internet of Things (IoT)\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inauguration of Aero Modelling Club</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/58f33f09c84570a2e08a07021caa9f75.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNICAL TEACHERS&#39; TRAINING & RESEARCH, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations in Teaching</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/58f33f09c84570a2e08a07021caa9f75.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL INSTITUTE OF TECHNICAL TEACHERS&#39; TRAINING & RESEARCH, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NABARD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start up Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Take Off Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEDxSNSCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme (FDP) on
                “Machine Learning using Python”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture \&#34;Real time applications
                using Artificial Intelligence and Machine Learning\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Utkrisht Sansthan Vishwakarma Awards 2019
                (USVA)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PMKVY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mind excellence towards innovation and
                research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9788b97016f35546e1c36502504dc924.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA INSTITUTE OF PROFESSIONAL MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Session on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Extension Lecture on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Logo Making Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Candle Making Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Design of Stand Alone PV System
                for Industrial Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Introduction to Thermography</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Thermal Analysis of Lead Acid
                Batteries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Life Time Estimation of Lithium Ion Batteries
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internet of Things : Concepts &
                Implementation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->skill, dedication, perseverance and right
                level of confidence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SCIENCE OF THE FOCUSED MIND</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on UV Spectrophotometer </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Seminar on Surrounding GATE Si
                Nanowire Transistors by DR M Karthigai Pandian, ASP/ICE, on 19th December 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TechBlitz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launch Of Pushpak </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness program on innovations in
                Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week FDP on Deep Learning and
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launch of TECHNOVANZA 2020 Hackathon </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Vision & Its Industrial Applications
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Expectations from Fresh Engineering
                Graduates</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Issues in Control Valve and
                Characteristics </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training on Industrial
                Instrumentation and Process Control</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HOME AUTOMATION USING IoT AND INDUSTRIAL
                REVOLUTION 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Programme on Positive Attitude
                towards life</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KIT-Kalaignarkarunanidhi Institute of
                Technology signed a Memorandum of Understanding with Lincoln University College, Malaysia</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit @ SWELECT Energy Systems
                Limited, Coimbatore for KIT First Year EEE students.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KIT-EEE DEPARTMENT AND Dr.VIDHYA INCUBATION
                CENTRE ORGANIES\&#34;COMMUNITY SERVICE LEARNING FOR POWERING VILLAGES USING LED LAMP ASSEMBLY\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISKCON Inter and Intra College Elocution
                Competition-Semi finals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON POWER QUALITY ISSUES AND
                CHALLENGES IN INDUSTRY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f456ac4360bcd31b76b33e7239c53e91.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KIT-KALAIGNARKARUNANIDHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON ROLE OF ENGINEERS IN INDUSTRY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Design and Analysis of
                Algorithm</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on “PYTHON
                PROGRAMMING AN INDUSTRY PERSPECTIVE “ </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWS cloud Literacy Day-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start up Proposals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ADX -201 Salesforce Administrator</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf44cd61cd0a225725769b86d6ee8210.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Gnanamani College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Robots & Its applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNET OF THINGS & ITS APPLICATIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry 4.O – Industrial Internet of Things
                (IIoT)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/22e95ee52796ee0b5da65b3bab79fd58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAPATLA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“TEKHACK-2019, 1.5 DAY BOOT CAMP & 24 HOURS
                HACKATHON on IOT”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40d13e359a0d710cfb11750a69a015d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B V V SANGHAS BASAVESHWAR ENGINEERING COLLEGE BAGALKOT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on National Startup Awards 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expo for farmers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5b7a5659fd11729f8474d3f2aa73192.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BML MUNJAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->propel-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7003956fff459de6c8ab5335f64912b2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Eternal University, Baru Sahib Sirmour">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Commercialization of Innovation and issues of
                technology transfers in industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7003956fff459de6c8ab5335f64912b2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Eternal University, Baru Sahib Sirmour">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patenting system in India </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7003956fff459de6c8ab5335f64912b2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Eternal University, Baru Sahib Sirmour">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of Intellectual Property Rights
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chhatra Vishwakarma Awards 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs Speak</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1b0b136a29ef158decc5f974467e4812.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW PRINCE SHRI BHAVANI COLLEGE OF ENGG & TECH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Safety Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c86061dcc537b41fca6f76a728728ae9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THASSIM BEEVI ABDUL KADER COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technology and Softskills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->XAV CARE Herbal Products Workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f6e4d0f96f5eb01d44c44e9f5d658e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. Shyama Prasad Mukherjee International Institute of Information Technology, Naya Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#39;Darpan\&#39;-The Life Designing
                Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f6e4d0f96f5eb01d44c44e9f5d658e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. Shyama Prasad Mukherjee International Institute of Information Technology, Naya Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DST-NIMAT funded Entrepreneurship Awareness
                Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PURE EARTH ENVIRONMENT SUMMIT (PEES)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme for Masons on
                Quality Aspects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f6e4d0f96f5eb01d44c44e9f5d658e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. Shyama Prasad Mukherjee International Institute of Information Technology, Naya Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program (FDP) on “DATA
                SCIENCE”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Execute 19.2 (2K19)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concrete Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/563e736aabc83009d62a1be364b35176.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IFET COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Writing and publishing a Research paper in
                Peer Reviewed Conferences and Journals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture on Google AI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation by Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->six days FDTP programme on CS6004-Cyber
                Forensics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/10949f76de640d28b374ddcdf917bcc2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Maharaja Agrasen Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->What is Open Source? How to Contribute?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->training session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e60399e63445a1c193e92da9be995f41.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Smt Kishoritai Bhoyar College of Pharmacy, Kamptee">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDIA INNOVATION CHALLENGE DESIGN CONTEST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e60399e63445a1c193e92da9be995f41.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Smt Kishoritai Bhoyar College of Pharmacy, Kamptee">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VISHWAKARMA AWARD 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Seminar on women in cyberspace</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Reparo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Education Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e60399e63445a1c193e92da9be995f41.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Smt Kishoritai Bhoyar College of Pharmacy, Kamptee">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My story- Entrupruner’s life and cross roads”
                Motivational Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/231e64b10766cedf26efbb459764e8ce.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGRAN LAKECITY UNIVERSITY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three months Internship for practical
                Exposure in Schools </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT using TIVA C Launch pad and CC3100
                Booster Pack</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Workshop on Thesis Writing/ Presentation
                making and Paper/Article writing using LATEX</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Lecture on “My Career as
                Entrepreneur”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Workshop on “Intellectual Property
                Rights”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6c9a251fcd8626283e6e607f73616c24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTHAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate on \&#34;Need of the hour - Job
                Creators or Job Seekers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a9d3dbeaf35844501e9da60acee859a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SILICON INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Entrepreneurial Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/571182f8fc549f55a92fbc09972a0856.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.G.REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT TO TECHNOLOGY TRANSFER CENTRE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference Impact of Changing
                Energy Mix in the Power Sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff4b0bb312b1c8d76c9c5783df7a2fea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PDPM Indian Institute of Information Technology, Design and Manufacturing, Jabalpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Design on Societal Needs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Labs Design Center</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop of Issues and challenges in Large
                diameter pile foundation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Professional Bodies in campus development
                initiative</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotic Programming Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Intellectual Property Rights
                in the field of Jewellery Designing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b6274fd54c0cae02676317f79f712f5a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KPR INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Workshop on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Soft skill development programme for
                Engineering Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT to “MAILASANDRA WATER SUPPLY AND
                SEWERAGE TREATMENT PLANT”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rajasthan Innovation & Startup Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3eafaf928728e13001846c215fc52b7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Padmasri Dr. B.V Raju Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IEEE Digital Library & project literacy
                program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9aebba97e978f1d54d841b0350076103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KRISTU JAYANTI COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Store II</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9aebba97e978f1d54d841b0350076103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KRISTU JAYANTI COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Olympics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Programme on Employability Skill
                Training in Technology and Soft Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6091319f0fefb5d8ade51472659b8db.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. MGR Educational and Research Institute">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online session on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber Security Awareness- Fighting with
                Threats</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ACtivity based Learning-My Environment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on ‘Maximizing Performance of Health
                Care Professionals’ along with Extempore Competition on 23 October 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93af762bac666ae55e415bf9cabdeaf7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BIRLA INSTITUTE OF MANAGEMENT TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Young Entrepreneur Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->POC Funding Screening Session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry oriented faculty development
                programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68bd51db23f3cf168d2ca2a71bf487c3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT ACADEMY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Practice Venture Booster</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project based Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Leanring course on Women Safety</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6c9a251fcd8626283e6e607f73616c24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTHAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Novelty Research Beyond your Ideas through
                IEEE Xplore.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a74b9113f8930d6d52d13faa009c230.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KINGS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Develop the Written Communication
                skill</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2b4b0a2477e285a3d174f1804b7b1a05.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARAIKAL POLYTECHNIC COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awarness programme on cashless transaction -
                Digital India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RPA Challenge Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business 4.0 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Day handson Training on Programming
                Skills using E Box</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotics and Computer Vision</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e31ddf85c83f1e6796c5a792086fce3d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SENGUNTHAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TechPhoenix 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Worshop on J2EE Framework</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A session by Internal Quality Assurance
                Cell(IQAC) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rashtria Ekta Diwas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days hands-on training on Data Analytics
                using MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Induction Programme for B. Tech I Year
                Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ba15bbada643337db8a2aa8e144bd6e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENGINEERING COLLEGE BIKANER">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Patent and Intellectual Property Right (IPR)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UoM Minor Research Project Exibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on \&#34;Robo Analyzer
                Software\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IBM Learnnovate 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AICTE – MARGDARSHAN Scheme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning with R Programming </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Commemoration of Constitutional Day - 26 Nov
                2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Term Training Programme </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SANDHAI-A Field Market Event</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Industrial Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d557650b821acb80a02d6a08fe6cd0a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pravara Institute of Medical Sciences, Ahmednagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->JAM </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d557650b821acb80a02d6a08fe6cd0a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pravara Institute of Medical Sciences, Ahmednagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit by the faculty to nearest startup
                centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Woman Entrepreneur Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/10949f76de640d28b374ddcdf917bcc2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Maharaja Agrasen Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visits</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Education Utilization Program on
                Standardization work in the field of Electrical Engineering.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/24d37fd5353bd7d2a1e8e168dac075d6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.M.S.COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->product devlopment_experience</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Artificial Intelligence,
                Automated Driving and Model-Based Design Using MATLAB and Simulink</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber Security Awareness - Fighting with
                Threats</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Block Chain & Crypto currency </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Programme on Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development by Skill Sector</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Paper Bag Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Chocolate Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> “One day Hands-on training in Banana tissue
                culture propagation”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISCA, Coimbatore Chapter sponsored One day
                Hands-on training on Transfer of Plant tissue culture technology to the stakeholders of farming
                community</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->India-UK Extension Workshop on Knowledge
                transfer on the sustainability of innovative wastewater treatment technologies to India-IV </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Texas Instruments E-Cell IIC Assessment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day State level workshop on ALGAL
                BIOTECHNOLOGY TECHNIQUES AND PROSPECTS FOR THE SUSTAINABLE BIOECONOMY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c36c0b14094eb03d27bb54a2f550840f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kongunadu Arts and Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ascertaining the present rising in the arena
                of Biotechnology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40d13e359a0d710cfb11750a69a015d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B V V SANGHAS BASAVESHWAR ENGINEERING COLLEGE BAGALKOT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enhancing the Spirit of Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research in Pharmacoepidemology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9aebba97e978f1d54d841b0350076103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KRISTU JAYANTI COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Jayantian Alumni Entrepreneurs\&#39; Meet
                2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vidyut- A wisdom for project design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Amazon Alexa</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTERNSHIP AWARENESS PROGRAM AT KSIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cauvery Calling-Action Now</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech Talks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SRM Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/99e0f20f270775c14bc59fb327c176e9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S R ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on
                Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Funding Opportunities for R&D Projects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Entrepreneurship Development
                and Innovation Institute, Tamilnadu</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Savidhan Diwas Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/891e643e5ce454973d262d18de385111.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND INSTITUTE OF HIGHER TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Week Faculty Development Programme on
                Soft Computing Techniques and its Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Half Day Workshop on \&#39;Learning
                Paradigms for Outcome Based Education in Digital Era\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Embedded-IoT Product Development –Demystified
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to SOLAR POWER
                PLANT,KARNATAKA POWER CORPORATION LTD (KPCL) AND CAUVERY HYDRO ENERGY LTD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Electric Motorcycle Development
                (Wulkin Motorcycles)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNICAL TALK ON “VERIFICATION OF DIGITAL
                CIRCUITS USING SYSTEM VERILOG</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to HAL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical talk on IOT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Internet of Things and Single
                Board Computer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Technology Innovations and
                Intellectual Property Rights for Start-Ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3145e3e4f69b7abc5e386a087a37776a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH RANGARAJAN DR.SAGUNTHALA R AND D INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Applications of Microbial Strain Engineering
                and Green Synthesis: In Biochemical Prospective </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Capture the Flag (CTS)\&#34; Contest
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Robotic Champonship - \&#34;Fastest
                Line Follower Robot\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/545eb17ed50c572b6964681243b2e433.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. B.R. AMBEDKAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon-November\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/646baa5a7158c7b8814192a096efbe38.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Babu Banarasi Das National Institute of Technology and Management ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill based activities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IFA Hackathon\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/646baa5a7158c7b8814192a096efbe38.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Babu Banarasi Das National Institute of Technology and Management ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Campaign </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8edcc7f03b2f892479f6267be5e3bf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techstars Startup Weekend Coimbatore’19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to Doordarshan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Survey on Business hours of Cart Vendors</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Light of the world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93af762bac666ae55e415bf9cabdeaf7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BIRLA INSTITUTE OF MANAGEMENT TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E Leader workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IGNITE 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> INTERNSHIP ON ROBOTICS & AUTOMATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Presentation on Effective Paper Writing
                Skills </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Life Science Entrepreneurship
                Awareness Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Incubation and Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee13f39ce76a3ee71444735dd4a97844.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B I T SINDRI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackatron 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launch of Startup Tamilnadu</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82709ebdaeb148a6aea72eba2a50cc95.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONs RESEARCH FOUNDATION, SALEM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meet JIIT Alumni Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fa0fb0507247a1d56f201ce63fb4111.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St.Anne&#39;s College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two day workshop on IPR and Patenting in
                India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIH 2020 PRELIMS - HITS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar\&#34;The Foundational Elements of
                Data Science\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> product development workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in International Innovation
                Fair-2019 by India Innovators Association (IIA) at National Small Industries Corporation (NSIC),
                Hyderabad. On 01, December, 2019.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY SEMINAR ON INDUSTRY ACADEMIA
                INTERFACE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4728d2bc5cd04b09ed38fd9bf82357a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Engineering Science and Technology- Shibpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4728d2bc5cd04b09ed38fd9bf82357a3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Engineering Science and Technology- Shibpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR: Awareness, Practice and Challenges in
                Academia</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Indian Pharmaceutical Association (IPA)
                students forum membership drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field demonstration of technologies for
                farmers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Workshop MLDC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/edf9831a65238e90fc6083e65c9c8c47.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIPAT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MODULE CAMP ON\&#34;DRUG ABUSE AND DRUG
                MENACE\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Water Recourses Management and Practices</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VIT Biosummit 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6b6f523bab50c07953d3f7b3e6616b8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MADANAPALLE INSTITUTE OF TECHNOLOGY & SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop and Hackathon on AI and Deep
                Learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stem On wheels </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop On Innovation and
                Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Advances in
                Computing, Communication and Control (ICAC3-2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advanced Deep Learning Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ICNAN 2019- 2nd International conference on
                Nanoscience and Nanotechnology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ace9dd1e1ea47b9e9a7571f0a68e45a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAR BASELIOS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inceptra 19-My Story</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ace9dd1e1ea47b9e9a7571f0a68e45a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAR BASELIOS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inceptra 19-Talk Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ace9dd1e1ea47b9e9a7571f0a68e45a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAR BASELIOS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inceptra 19-Power Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Computational Fluid Dynamics in Chemical
                Process Industries - Innovative Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AICTE Margdarshan FDP on Outcome Based
                Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Empowerment of Differently abled people</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PANEL DISCUSSION ON ORIGINATING A STARTUP - A
                SKILL DEVELOPMENT PROGRAMME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE IDEAS AND PRACTICES FOR AN
                ENTERPRENEUR </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BRAINSTORMING SESSION ON GOAL SETTING </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d1a3ccea41e102cc2f219b22d6482c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA ARTS AND SCIENCE COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON IDEA GENERATION FROM CONCEPT TO
                PROTOTYPE MODELING </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Artificial Intelligence </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Critical Thinking </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit to air cargo complex </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2- Day Conclave On Engineering Education for
                Digital Transformation & Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GST and E filling </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Export Import Documentation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee13f39ce76a3ee71444735dd4a97844.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B I T SINDRI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities for Start-up in India </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9ba355c41f0bc2d7bc9f778878c8a226.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESA PERUMAL COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIX DAYs hands on workshop On PLC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inauguration of the Golden Jubilee
                Celebrations & Inspiring Entrepreneurs Award Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GCP crash course</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6f6812ca0842108fa6d56fcef5fecfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NSHM Knowledge Campus, Kolkata  Group of Institutions 277">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation week celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Basic Introduction to Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACKFEST 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5b29f28cd6f9fd1f42c8519e03de6b7d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO ENGINEERING COLLEGE BANIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Oneday workshop on Smart India Hackathon 2020
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5b29f28cd6f9fd1f42c8519e03de6b7d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO ENGINEERING COLLEGE BANIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Oneday Field Visit to DVC DSTPS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Solar Ambassador Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Health Awareness Programmed on Diabetes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d2eff04ef5371232898d005b94973872.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAMCO INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Training Camp for Students
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organic farming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Big Idea Hunter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Idea Competition for Faculty</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72cb1a43b1443b9fe92c9e89f8026328.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N.S.N COLLEGE OF ENGINEERING AND TECHNOLOGY, KARUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HELPING THE SOCIETY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TRAFFIC AWARENESS PROGRAMME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Go Green EVENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Guest Lecture on “Strategic Management -
                SUPERB-37.5 ”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT to CSIR-NGRI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Industrial Visit to LEDchip Indus Private.
                Limited</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “Challenges in design and
                construction of world tallest bridge on railway tracks”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Site Visit to Sundilla Barrage, Lower Manair
                Dam and Ranganayakulu Sagar Dam</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d73ae23ba9b54a409415dbbc2904056.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JABALPUR ENGINEERING COLLEGE,JABALPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Carnival</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed63cc4904924a9457b5bbc42dd34149.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEDI-CAPS UNIVERSITY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture for students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUCTION PROGRAM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Advanced Programming skill
                Development” under TEQUIP-III,</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to BSNL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3bb4d813d8707b44925d6a7d30279a8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SPHOORTHY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAMSYA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3bb4d813d8707b44925d6a7d30279a8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SPHOORTHY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FUTURE TRENDS IN BUSSINESS AND INDUSTRIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e3cd38ac85be9e3bd3e6ab6a4e260e58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Martin&#39;s Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to BSNL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Roving Seminar on Patent Cooperation
                Treaty</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on System design using ASM Charts
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Modeling of Communication using SImulink</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Applications of Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/201e5c936bd77b0fcbdd7da981787078.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Universal College of Engineering & Technology, Dokiparru(V), Medikondur(M),PIN-522438  (CC-NF)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ROBCAD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/201e5c936bd77b0fcbdd7da981787078.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Universal College of Engineering & Technology, Dokiparru(V), Medikondur(M),PIN-522438  (CC-NF)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IOT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Hypermesh</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e9a9883c0d000c67c83a01e867fb35e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THE ICFAI FOUNDATION FOR HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IFHE Entrepreneurship Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9ba355c41f0bc2d7bc9f778878c8a226.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESA PERUMAL COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->three DAYs hands on workshop On PCB Design
                fundamentals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9ba355c41f0bc2d7bc9f778878c8a226.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESA PERUMAL COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->THREE DAY hands on workshop On “BASICS OF
                PYTHON”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5 Days workshop on \&#34;ADX-201 Salesforce
                Administartion\&#34; in association with ICT Academy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->I Transform - India Transform</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Android App Development
                entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Data Science</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cb7f04d1bdad2481e37cebd278ab66b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARSIMHAREDDY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Cyber Defense Mechanisms</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prototype Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Workshop on “Web Design and
                Development for dummies”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1bcc98c0b3a4850509a6370960a3e495.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JK LAKSHMIPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation on Social Entrepreneurship
                Efforts by CII-Yi Yuva</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Solid Works</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Blockchain Technology and IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Current Opportunities For A Graduate
                Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy Auditing & Effective Usage of Energy
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Accelerating EMI/EMC Testing using Simulation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e99015a02677ae4b1877cb5bc365ba1f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Oriental College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Talk “E-Talks”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Microprocessor in Electrical Power
                System Sector - An Industrial Perspective</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BEML industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering Memories and concentration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7695bbde69d6f006986432b3c3fef242.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Central University of Punjab">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Azure Machine Learning Competition and AI
                Gaming Tournament</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visiting slum area Discovering problems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HUMAN RIGHTS TRIAL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7695bbde69d6f006986432b3c3fef242.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Central University of Punjab">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Importance of Intellectual
                Property Management in Academic and Research Institutes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcf9dceb9082d54ba909c6964cf29628.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DEVINENI VENKATA RAMANA & DR.HIMA SEKHAR MIC COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online App Development Boot Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcf9dceb9082d54ba909c6964cf29628.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DEVINENI VENKATA RAMANA & DR.HIMA SEKHAR MIC COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start up Summit Series I How to raise funds
                for your startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcf9dceb9082d54ba909c6964cf29628.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DEVINENI VENKATA RAMANA & DR.HIMA SEKHAR MIC COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Awareness Program on Importance of
                Intellectual Property Rights and Patents</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivation & beyond: from the perspective of
                studying overseas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Corporate Social Responsibility Activity by
                Startup students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Journey </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion with Women Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8eaf7f8738cca37db4fb1d3d22e6ccd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="V.V.VANNIAPERUMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Workshop- Advertising</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/502ae28cbbee442893a543d3cb73f6fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.V.C. College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Educational Utilization of Standards</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on E-Waste Management Issues around
                the country</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Water Management and Rain Water
                Harvesting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cceef3efd9636e66a07901060ea2e9ac.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.R. College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Annular Solar Eclipse</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Constitution day celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Are you Jugaadu Enough?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PITINCRIT- Pitch.Invest.Criticise</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7695bbde69d6f006986432b3c3fef242.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Central University of Punjab">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Academics, Innovation and Entrepreneurship:
                The Path from the Idea to Reality</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CISCO Packet Tracer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Why is Story telling important?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conclave on Electrifying Indian
                Mobility</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNICAL TALK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ecotips: Ecofriendly Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->celebration of Indian constitution day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf44cd61cd0a225725769b86d6ee8210.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Gnanamani College of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ignite 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->industry visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c8ec9497c92cf385bedc6b0d7e9e068.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAGAR INSTITUTE OF RESEARCH & TECHNOLOGY, INDORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Innovative Model and technical poster
                competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ecofriendly Innovations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Presentation and Live Demonstration on
                the \&#39;Innovation of the College\&#39;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORK SHOP ON BUSINESS PROPOSAL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Virtual Lab conduction </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Constitution day celebration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ELECTRONEX 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd4a199fabbb7883d12ad534edc8126f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NOORUL ISLAM COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Slogan Writing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44f1d53d22443a9c8148e9b823df4694.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AAA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property RIghts</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY INTERNATIONAL CONFERENCE ON NEW
                HORIZONS IN PHARMACEUTICAL AND BIOMEDICAL SCIENCES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d63a3909063bc7145f18bf8cbca57ea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SATHYABAMA INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FACULTY DEVELOPMENT PROGRAM ON LOGISTICAL
                PROCESS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da300a7c2bab83971b6216c67c4f2e0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RajaRajeswari College of Engineering, Bangalore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NECSES Startup Pitch Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f40f7dc01e2fadb649da0fc7cf6dea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="McGan&#39;s Ooty School of Architecture">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KARAIKAL - AN URBAN STUDIO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7695bbde69d6f006986432b3c3fef242.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Central University of Punjab">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Laboratory-Based Innovative Approaches to
                learn Biotechnology Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f48da2077e9aaa9c06b447d76b43b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="STANLEY  COLLEGE OF ENGINEERING AND TECHNOLOGY FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MoU Signing Ceremony - Incubation Center
                under IIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DISRUPT 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d6bc27e34f73a06fc8ab92a4dbac058d.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on “Employability to Deploy-ability”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme on
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Digital Land Survey Using Total
                Station</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8a1f7ae2c8908a1c2a56b234997ea80.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IMS ENGINEERING COLLEGE, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on \&#34;Online Programming
                Tutorial\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2d48843937ee6662a68df4f09a90ecb7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.N. COLLEGE OF ENGINEERING & TECHNOLOGY(BNCET)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A National Conference On Emerging Innovations
                in Agriculture, Biotechnology and Health Sciences</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Precast & Prestressed Concrete Structural
                Systems- an overview</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/502ae28cbbee442893a543d3cb73f6fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.V.C. College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->College Bazaar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/502ae28cbbee442893a543d3cb73f6fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.V.C. College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solar Vehicle Project</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8fcace3737f4053d0544cc69f683980.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJKIYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon participation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on effective teaching learning</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship startup launcher programme
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->20 th foundation day celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NSS DAY </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8fcace3737f4053d0544cc69f683980.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJKIYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship and Business Model</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8fcace3737f4053d0544cc69f683980.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJKIYA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovate-2-Inspire (Aim to Nurture startup/
                Entrepreneurial Skills in Budding Engineers)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b5ef4e00f3f0f9064cf2a0c6a3c0ee3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RVS College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NEC Idea Challenge - 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Quiz on Innovations and
                Healthcare</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON “THE LEVERAGE MODEL” RKSHOP ON
                “THE LEVERAGE MODEL” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Piyush Dutta Innovation and Invention Prize
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackverse National-level Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81cf1e6f0fc9aa829c28d4f3a2d8b316.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Karnataka, Surathkal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackverse National-level Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TALENT SHOW</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4651bada6e55e9036e7299084693aacb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSNA COLLEGE OF ENGINEERING AND TECHNOLOGY , DINDIGUL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship - AI Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c40aa7cf6c735316c6b8d9d38fe9f8cf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S R College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Millennial: Social Challenges in Real and
                Virtual World</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f6e4d0f96f5eb01d44c44e9f5d658e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr. Shyama Prasad Mukherjee International Institute of Information Technology, Naya Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launch of Adivasi Radio App</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/708c1345abd2327de1cc54d72f393bb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DON BOSCO COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development from Telcocrats</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Mini Project Expo by ECE Final Year
                Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Day on climate action by Prist
                Green Cell</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbcc782f98496dc4e54d12349676951d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA ENGINEERING COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mini Project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Development Lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d0573e92cd2858cdb77cb8261e554bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARMY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three days FDP at AIT,Pune</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level Seminar on Filing of
                Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Faculty Development Programme in
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORLD WOMEN ENTREPRENEURSHIP DAY-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->18 Steps to become Successful Entrepreneur
                and Schemes of MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93146d8ccda3e7c1b5d9fcc11675f40d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cleanliness of Surroundings</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Young student leader</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Startup Showcase</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93146d8ccda3e7c1b5d9fcc11675f40d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Dengue</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/005f3f36464065d29e231be28cf9ae28.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHNU INSTITUTE OF PHARMACEUTICAL EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Fight Against fake Drugs” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Encouraging Entrepreneurship as a
                career option among youth\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Easyport</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ea52c83ad6b1130734be31e3b9b76375.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Oriental Institute of Science & Technology, Thakral Nagar, Opp Patel Nagar, Raisen Road, Bhopal-4620">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Spot Wash</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meeting with Incubators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/93146d8ccda3e7c1b5d9fcc11675f40d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIKAS INSTITUTE OF PHARMACEUTICAL SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness : Causes of Typhoid</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d5ac6de5c4bfbb3afd901f75e9e62d30.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VALLIAMMAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Entrepreneurship Development Programme
                (WEDP)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Funding Opportunities for
                Innovation and Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Institute Interaction
                event-Singapore Team visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IUCEE Webseries-Global Entrteprenureship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->URJAVARAN event by ISHRAE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar on IPR-Rules for Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Talent search exam-MOU with ASSOCHAM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interacion with Jayaesh Ranjan,Principal
                secretary,Govt of Telengana</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/005f3f36464065d29e231be28cf9ae28.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHNU INSTITUTE OF PHARMACEUTICAL EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#39;\&#39;KILL CANCER - INTER & INTRA
                COLLEGE ELOCUTION COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Institute Interaction event of Asia
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development prog on Entreprenuership
                by NSTEDB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Test by DesignTech</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Family Business & Sucession Planning ,Wealth
                Management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/33c1f26188cc5bbf9a4fe3a32ecf6ee9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shaheed Rajguru College of Applied Sciences for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->certificate program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/33c1f26188cc5bbf9a4fe3a32ecf6ee9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shaheed Rajguru College of Applied Sciences for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->field visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MODEL EXHIBITION and POSTER PRESENTATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Entrepreneurial Mindset
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Imparting computer basics to the younger
                generations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74144a414d966b90fe679e8e3d80db50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Albert\'s College, Ernakulam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->#ieTalks </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5b29f28cd6f9fd1f42c8519e03de6b7d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO ENGINEERING COLLEGE BANIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC TECB Logo Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Delegates from Rwanda visits I-HUB- Ahmedabad
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HR Conclave 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NS2 Workshop - Research & Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3f3f463b631116798bc1ed92b7e526f0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHAH AND ANCHOR KUTCHHI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enhancing Academic Patenting with IEEE IQP
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR & PATENT PROCESS FOR ICT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘CAFÉ LA PITTURA’/INNOVATIVE PAINTING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->I QUEST/INNOVATION IN RESERACH</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e465cca7c07b2088972ececa3e4bd62.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Xavier&#39;s College for Women, Aluva - 683 101">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit/ Entrepreneurial Pokkali
                cultivation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d920e6897b3d2f302d5c640651dec8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Deen Dayal Upadhyaya College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sixth Hands-on Workshop “VHDL Programming &
                Digital Circuit Designing with Implementation on FPGA” jointly organized by MHRD – INSTITUTION’S
                INNOVATION COUNCIL (IIC) - DDUC CHAPTER, National Academy of Sciences India, Allahabad –Delhi Chapter,
                IEEE Electron Device Society (EDS) – Delhi Chapter Under the Aegis of DBT Star College Program,
                ELECTRONICS from October 21 – 24, 2019 in collaboration with CoreEL Technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Solution of Smart Grid Problems
                using Software</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Soft Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9e87e3dd258156c52d4ea418c9085e9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING ROORKEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON MACHINE LEARNING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property (IP) awareness workshop
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INICIO\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bbed21877a9150231ea61ff203076cd3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PAAVAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->POSTERVAGANZA: A POSTER EXHIBITION ON
                TECHNICAL INNOVATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ELOCUTION: SPEECH COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Scholar’s Spectrum: PowerPoint
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5435aa5b01647db124eb38cc40af4aa7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RUNGTA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innopreneurs Startup Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Student One Tree Initiatives</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MODEL PRESENTATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Advances in Electronics and Communication
                Engineering </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/42e20af9290f1f4a39b245282f48fa12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. T.THIMMAIAH INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotic Process Automation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9788b97016f35546e1c36502504dc924.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA INSTITUTE OF PROFESSIONAL MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->India\&#39;s Biggst Recruitment Fair
                Awareness Drive- Internshala</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to NAL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on \&#34; Sustainable and green
                manifacturing using Nano Cutting Fluids\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One-day workshop on “Arduino\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7695bbde69d6f006986432b3c3fef242.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Central University of Punjab">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->General Awareness on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Learn Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Management of Stress and Depression - A need
                of the day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f9b1254ef693dc4ba5055d7de4c404e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJALAKSHMI ENGINEERING COLLEGE (ENGINEERING & TECHNOLOGY)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vidhai</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Week-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on \&#34;Public
                Speaking\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c2ba95cbc313994db52c0f7084a7245a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Loyola College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Networking an Ecosystem for Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Management Games to learn Innovative
                Marketing Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship awareness camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sentinel Hack</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mushroom Cultivation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b74fed57208dd3028992e8e8017feb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness session on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation session on Institution’s
                Innovation Council 2.0 and Atal Ranking of Institutions on Innovation Achievements (ARIIA) 2020</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NEHRU SCIENCE INNOVATION AND TECHNOLOGY EXPO
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dr APJ ABDUL KALAM Energy quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c6b50be843af40f1328f73b4429a2c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAGI RAMAKRISHNAM RAJU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->S.R.K.R. Toastmasters International Club &
                Master Orator Championship Season 4 to improve Communication, Public speaking and Leadership skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c6b50be843af40f1328f73b4429a2c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAGI RAMAKRISHNAM RAJU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stanford University - University Innovation
                Fellows Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c6b50be843af40f1328f73b4429a2c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAGI RAMAKRISHNAM RAJU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Novus -2019 ,Innovation Competetion –
                Ideation Bootcamp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b74fed57208dd3028992e8e8017feb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP 1O1 Phase I</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b343fd8f2aca870b555c963a3bb803dd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SANT LONGOWAL INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->webinar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee13f39ce76a3ee71444735dd4a97844.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B I T SINDRI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship SUMMIT-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Healthcare Opportunities for
                Pharmacy Graduates in Pharmacovigilance & Artificial Intelligence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Stargazing Night</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a9d3dbeaf35844501e9da60acee859a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SILICON INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c4c57f9bd770f640fa88f772281216e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dahiwadi college Dahiwadi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Model Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c08d5a406bac6b0b2e39043e81b0330.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Punjab College of Technical Education, Baddowal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/33079c50db447edb3d08f26aea95235e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Sankara Vidyapeetom College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition and Sale of Student made products
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coding and Computing based workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on \&#34;Social Entrepreneurship
                and your rights\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovation and Incubation center
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit for Innovation and learn New
                Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP Programme on Technical Communication for
                Faculty</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5435aa5b01647db124eb38cc40af4aa7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RUNGTA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAE Aerodesign Challenge Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Climate Action Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Serve One\&#39;s Country through Civil
                Service</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f40889ef9e71bd4dbf9416821f123df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Incubation centre</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gandhian Global Solar Yatra (GGSY Workshop)
                for Solar Urja Lamp (SoULs) Assembly </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Thrive in the gig economy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Khadi Exhibition Khadi from Udyog Bharti –
                Gondal, Rajkot</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Value stream mapping</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Book Reading titled “my experiments with
                truth”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c08d5a406bac6b0b2e39043e81b0330.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Punjab College of Technical Education, Baddowal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> My story Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c08d5a406bac6b0b2e39043e81b0330.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Punjab College of Technical Education, Baddowal">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start Up master Class</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b1c49128e69fdd393464b4fa17ed0a0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW DELHI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Trucup workshop-the vagina dialogue</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP Programme on Developing Interpersonal
                Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DRAFT NATIONAL EDUCATION POLICY-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IGNUS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Robowars</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DAKSHA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SMARA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIX DAYS AICTE SPONSORED STTP ON RESEARCH
                AREAS IN SMART ANTENNA DESIGN FOR CUBESAT APPLICATIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ROBOTICS AND EMBEDDED SYSTEMS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SWACCHATA HI SEWA CAMPAIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f40889ef9e71bd4dbf9416821f123df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATI VIDYAPEETH COLLEGE OF ENGINEERING, NAVI MUMBAI">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mini Project Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRY VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION FESTIVAL </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CODE IGNITER-2019.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUCTION PROGRAM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1afe07e88b70cbca3c6a93bea9e5e661.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW HORIZON COLLEGE OF ENGINEERING (E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story - Entrepreneur’s Life & Crossroad –
                Motivational Speech Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Death Race</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Flag Your Win</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Academia Meet Cum Exhibition
                Sponsored by MHRD & MGNCRE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit on Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Road to Entrepreneurship in Science &
                Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rendezvous with Lori Beer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Design Thinking, Business Plan &
                Branding</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/22e95ee52796ee0b5da65b3bab79fd58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAPATLA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BEC IIC Logo competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/860b2474ba58472f35806b0299fc91d5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Atmiya University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days workshop SSIP BOOT CAMP of Welding
                Inspection (WPS, WPQ & PQR) ASME Sec. IX</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/76ecaf001d9f05c8a58c39f1638bb860.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TKM INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Assessing Entrepreneurial skills of First
                Semester MBA participants</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/76ecaf001d9f05c8a58c39f1638bb860.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TKM INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DEVOPS BY TECH MAHENDRA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AWARENESS PROGRAM ON CYBER SECURITY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop on GI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8648c7592d124d745b7ad543a86bb6f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chettinad Academy of Research and Education">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Opportunities for Life Science
                Students in Clinical Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8648c7592d124d745b7ad543a86bb6f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chettinad Academy of Research and Education">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A One Day Workshop on New Innovations in
                Pharma Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Innovation & IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8a1f7ae2c8908a1c2a56b234997ea80.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IMS ENGINEERING COLLEGE, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on \&#34;Machine
                Learning\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8a1f7ae2c8908a1c2a56b234997ea80.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IMS ENGINEERING COLLEGE, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Embedded System & AVR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CODEATHON 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A conceptualized youth-based event titled
                DEMOCRACY WALL </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5ece8155eebbd24265f8fe5771ef0121.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amjad Ali Khan College of Business Administration">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to study innovation at a garment
                factory</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67056eb08ab3a825e3a2d54fa41e2bb1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. RAMAKRISHNAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineers Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/499c0990710a82e6e54cb6f5c6bb4c32.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Social Innovation & Rural
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Manufacturing of Automotive Brake liners</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HVAC-R&BEYOND</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DESIGN OF HVAC SYSTEMS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8648c7592d124d745b7ad543a86bb6f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chettinad Academy of Research and Education">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR_olympiad examination</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f70e67976bdcf71f2225dc662b1fc6d7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LORDS INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Information Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ELEXPLORE\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Soft skills and Grooming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f70e67976bdcf71f2225dc662b1fc6d7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LORDS INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Internet of Things</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days faculty development Program on
                \&#34; Creative thinking\&#34; </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A One Day Industrial Visit/Field Exposure to
                ACC Limited Ready Mix Concrete Plant</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on collective intelligence by
                Prof.Benjamin Gratton</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Idea Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture Series on Patent drafting Resource:
                NPTEL Videos</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3deabbc5fe8922656ccce2566698d987.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->msme start and improve your business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bd58c6347ebd180e1589c0f2bf71ba70.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MUFFAKHAM JAH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;INCEPTION\&#34; Spreading Awareness
                among new Students regarding ENTREPRENEURSHIP and STARTUPs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Funding Opportunities for Innovation and
                Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bbabfe7d7f60c33c8ccf65a79c1fd387.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIDDHARTH INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Art of Living</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Poster Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ALEXA WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LINUX AND OPEN CV WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a0e67cd2a2835bab615548bbbc132f6.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KESHAV MEMORIAL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Google Firebase Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f2d41f982fdbbd468576fac51b533988.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parul University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Importance of IPR in Start up world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Site Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> “Computer Security and Ethical Hacking”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plenary Talk on Teaching Methodologies and
                Research work </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Extreme Propulsion 47</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Online Lecture Through Spoken Tutorial
                Project of IIT Bombay on \&#34;QCAD\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cleanliness Drive “Swachhata Hi Seva-2019:”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/57d18284fd38dc6e6eed56ac31aeca5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. SUDHIR CHANDRA SUR DEGREE ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Idea Competition 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One-day Workshop on “Filing of Indian Patent
                Applications and Patent Search”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d8004293bc33463623bda808ada07ba1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH HIGH TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Engineering in Production
                Techniques (IEPT) – 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Logo Design Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73c11e5d60a8f639ed0ed0f323827530.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Modern High Power Broadcast Stations”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/33c1f26188cc5bbf9a4fe3a32ecf6ee9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shaheed Rajguru College of Applied Sciences for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->orientation program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5435aa5b01647db124eb38cc40af4aa7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RUNGTA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEA BOX </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON DURABILITY OF CONCRETE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT TO POONDI RESERVOIR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GUEST LECTURE ON BUILDING INFORMATION
                MODELLING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5435aa5b01647db124eb38cc40af4aa7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RUNGTA COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RUBI Innovation Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b561843ab31fdf0336cfd1551f90562.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI G.S.INSTITUTE OF TECH. & SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SGSITS-IIC logo competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d373aa48a67c26ad426cd0550ef8a9f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BMS INSTITUTE OF TECHNOLOGY & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TechTransform</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b1f31e680ee7b6292a8eb5be14b4d2f4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GVVR INSTITUTE OF TECHNOLOGY, Vempa Road, Tundurru, Bhimavaram,  PIN- 534207.(CC-JH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine Learning with PYTHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b1f31e680ee7b6292a8eb5be14b4d2f4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GVVR INSTITUTE OF TECHNOLOGY, Vempa Road, Tundurru, Bhimavaram,  PIN- 534207.(CC-JH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AUTOMOBILE DESIGN CONCEPTS USING AUTOCAD &
                CATIA V5</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/589da4bfeff70d7d2fc3cac2069c676a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDRAPRASTHA INSTITUTE OF INFORMATION TECHNOLOGY -DELHI (IIIT-D)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WISDOM TALK - Innovative Ideas to Market
                Place : What it takes.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Building Information And
                modelling </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Full stack Java web
                development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Empower Yourself on Data
                Analytics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Next Generation Computing
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Advanced java programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on skill Training on Technology and
                Soft Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Beam Enroute</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a9a939a9790735baeddf8c8dd327316.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Passport to Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b228d8fcef2ec3ee13a3afe3121f944.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee University of Information Technology Waknaghat">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Entrepreneurship Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff0a1148a500da8408471fdae463fc09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vignan Pharmacy College, Vadlamudi Post, Chebrolu Mandalam, PIN- 522 213(CC-AB)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NIRF – NEED & ROAD AHEAD FOR INSTITUTIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1ef7eaf6115d9a77097f7080f6845c0b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Pharmaceutical Education And Research, Ahmedabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Workshop on Mammalian Cell Culture,
                Cell-based staining, Imaging & Molecular Expression Techniques</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hardware: Business Idea Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A focus towards software: Business Idea
                Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Online Money Making Using The Power of
                Internet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66d17ca693f505b5e55d01a37f760f0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sadakathullah Appa College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on
                Entrepreneurshipin Microbiology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1ef7eaf6115d9a77097f7080f6845c0b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Pharmaceutical Education And Research, Ahmedabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Application of LC-MS/MS based dereplication
                tool in Natural Products Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67056eb08ab3a825e3a2d54fa41e2bb1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. RAMAKRISHNAN COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KRCE and TIDITSSIA MoU Signing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skilling India Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47a50a95d9b1df73a8429840ff802269.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhartiya Skill Development University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Live with Spark Ride: Fight Against
                Depression</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Contest Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1ef7eaf6115d9a77097f7080f6845c0b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Pharmaceutical Education And Research, Ahmedabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference on Neurological
                Disorders and Therapeutics (ICNDT) — 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Pitching Contest in Schools</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1cbeb57e5abf3d948a611484bdf42dfb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNI COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->300 Startup Inducement Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dc3f3ef3dabb1a25f39358c790ccd3a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sikkim Manipal Institute of Technology (SMIT)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->1st Cohort for Pre-Incubation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66d17ca693f505b5e55d01a37f760f0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sadakathullah Appa College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Seminar Indian Business and
                Economic Transition in the Global Scenario</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4961ff07e1da868976754b2a785b3c5c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Holy Cross College (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sales Exhibition of student\&#39;s hand-made
                items</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days workshop on Artificial Intelligence
                and Deep Learning u</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/825022e4d8ff8026dc01c650fa84ebfd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Datta Meghe Institute of Engineering, technology and Research, Sawangi meghe">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Innovation & Technology
                Transfer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Patent Filing Procedures for
                Students and Small Scale Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Medical Camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8188b08913c2b485cfcd6d0643cc9972.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->School Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs- Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8a1f7ae2c8908a1c2a56b234997ea80.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IMS ENGINEERING COLLEGE, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technovation_2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->THREE DAYS WORKSHOP ON AUTOMATION USING
                ARDUINO BY NOSF</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAYS WORKSHOP ON R WEB DESIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b343fd8f2aca870b555c963a3bb803dd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SANT LONGOWAL INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION WALK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Programme for Herbal Napkin Making
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GUEST LECTURE ON AN INSIGHT INTO
                ENTREPRENEURSHIP </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNICAL EXPO 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNICAL EXPO 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Blood Donation camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Days STTP on Robotics and Applications
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->APPlyML Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Business Opportunities and
                Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical talk on \&#34;Project
                Management\&#34; and INSTALLATION OF ISHRAE STUDENT CHAPTER </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/81e0ac6f0367fb692ccd52d70fe06084.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H. RAISONI INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Basics of Air conditioning”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of National Unity Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/19f17368d7ea7b179d7a795f897352c9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B G S Inst., of Technology, Mandya Dist">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STEP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ec079ae6dae5f61f28ec90ccd2941237.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRASAD V POTLURI SIDDHARTHA INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SWOT-IIC-Council Meeting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Requirements for filing patent for
                innovations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ec079ae6dae5f61f28ec90ccd2941237.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRASAD V POTLURI SIDDHARTHA INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Renewable Energy Working Group</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Moral Values, Ethics, Good
                governance and Ill effects of corruption</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ec079ae6dae5f61f28ec90ccd2941237.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRASAD V POTLURI SIDDHARTHA INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Brain Storming Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ec079ae6dae5f61f28ec90ccd2941237.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRASAD V POTLURI SIDDHARTHA INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sales and Marketing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepeneurship-Student Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sales day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Successful Innovators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Innovative Project
                Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d920e6897b3d2f302d5c640651dec8e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Deen Dayal Upadhyaya College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Colloquium on \&#34;Indian Space Programme:
                India\&#39;s Incredible Journey\&#34; as part of 100th Birth Centenary (1919 - 2019) Celebration Tribute
                to Father of Indian Space Programme - Dr. Vikram A Sarabhai</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/023ed26169c269680578cc5bf2f8f894.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GAYATRI VIDYA PARISHAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start and Improve Your Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/43d3acbefa4ae6ec42b07a33460ede2c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Workshop and Lecture on Startup by
                Teun Mentzel from PUM Netherlands</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GATE Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical skill development test</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filing of Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Guest Lecture on overview of IT
                industry for Engineering graduates</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Guest Lecture on Current
                Technologies and Industrial Opportunities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/80d0ff0e3eb217176fd56a4167703241.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIT Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Legal Advice and Assistance for Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Academic Guest Lecture on Automotive Systems
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a6e4b7f1e4d5022124aeac654358cdea.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jeppiaar Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Days Entrepreneurship Awareness camp
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74144a414d966b90fe679e8e3d80db50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Albert\'s College, Ernakulam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HANDS-ON TRAINING ON VALUE ADDED FISHERY
                PRODUCTS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/54c09fbdcea2904df9d7ae4b2e5b1981.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FRANCIS XAVIER ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essay competition on how to improve economy
                in village</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Soft Skill Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Guest Lecture on Computational
                methods in Geo Technical Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73c11e5d60a8f639ed0ed0f323827530.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Guest Lecture on Power quality
                improvement techniques in modern power systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Education policy - a seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Guest Lecture on Overview of Power
                Plant Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/644ccd9149885c675302f635c9e0c991.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROGRESSIVE EDUCATION SOCIETYS MODERN COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Book Exhibition on occasion of Innovation Day
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc7e22290260430711939315ee45d112.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IILM University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IILM Young Citizen Award 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e96d2bf3991a1c3884ef04f8eba5c6d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="NASHIK DISTRICT MARATHA VIDYA PRASARAK SAMAJS KARMAVEER BABURAO GANPATRAO THAKARE COLLEGE OF ENGINEE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Awareness Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GATE Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2-day Customer Development Camp for product
                innovators & early-stage startup founders</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Principle of Industrial Design, Standards, &
                Compliance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/78a12643c41998fd10d38edf57640897.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.D. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PROJECT DESIGN IDEA CONTEST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Immersion Workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/541ba0b1c9f6b903403f956c41917806.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJKIYA ENGINEERING COLLEGE, BANDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->a journey from idea to product</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/98d72e7999ba5bd6a36bfbe38b8de1ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA INSTITUTE OF INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAMASYA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18526f82cdfcbdd79e98193173f11455.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS DWARKADAS J. SANGHVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill upgrade Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY WORKSHOP ON MACHINE LEARNING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Developing better Cognitive ability of
                Technical Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Team-20 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5474314e558eb389bc616060125a11ea.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHIVRABAI SAWANT POLYTECHNIC WAGHOLI PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Summity-2k19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d88a232028b9cb30c7d717d41c5f199.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PARALA MAHARAJA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day seminar on Energy Crises and novel
                approach towards its solution</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SCHOOL VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fbd29011974fa52d207ca2dc18712049.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREENIDHI INSTITUTE OF SCIENCE & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bb595fe5b093c1d1eabef427be583761.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAHER\'s Shri B.M. Kankanawadi Ayurved Mahavidyalaya, Belagavi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBINAR ON COMPETITIVE RESEARCH GRANTS BY DBT
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Village/Society
                /School/Industry/Market – Identity real Life Problem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Village/Society
                /School/Industry/Market – Identity real Life Problem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/682ba087f4be78b89234cd89cd2286f5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTIONS TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Campus Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Village/Society
                /School/Industry/Market – Identity real Life Problem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enrollment of students in EDC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:The General Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Soft Alight</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/348a069e58948dc26b092ce0972f20d2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber Security and Professional ethics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Deployment & Training of UBA project
                \&#34;Gram Vyaapar APP\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3f6732992f4c9e04417b057a82c76af.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GUDLAVALLERU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->First start-up in GEC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:CAD Crafians</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e861dca24cde91c1d38596676bc986e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BANNARI AMMAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Intellectual Property Rights”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3f6732992f4c9e04417b057a82c76af.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GUDLAVALLERU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program on Indian start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Rock The Wagon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/545eb17ed50c572b6964681243b2e433.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. B.R. AMBEDKAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Workshop on Analysis & Design of RC
                Buildings in Seismically Sensitive Zones: Present & Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30792f2ede95c30ac27bb6914d1fc945.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day International Seminar on “Bio VLSI
                Signal Processing”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Turn Your Passion Into Profession</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30792f2ede95c30ac27bb6914d1fc945.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL CONFERENCE ON CONVERGENCE
                TECHNOLOGIES IN BIOMEDICAL ENGINEERING </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Haul’n Fall</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Lab animal research & ethics
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30792f2ede95c30ac27bb6914d1fc945.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two days National level workshop on
                \&#34;BIPED / HUMANOID ROBOTICS”.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Finding Chandler</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bbed21877a9150231ea61ff203076cd3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PAAVAI ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEATION WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Induction Program for 1st Year students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Print Your Prototype Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9ecd7d638aaeb22ba1b4418077c4177c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RCC INSTITUTE OF INFORMATION TECHNOLOGY 117">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to Publish Good Quality Journals and
                Submit Patents </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Pirates of Terrain</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sky Program and Master Classes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->D2C Entrepreneurship Course by Wadhwani
                Foundation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1872653142ca7f37f27a978c8d98b94e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="College of Agriculture Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9ecd7d638aaeb22ba1b4418077c4177c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RCC INSTITUTE OF INFORMATION TECHNOLOGY 117">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Art of Publishing: Publications and Patents
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IGBC STUDENT CHAPTER ANNUAL MEET 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION CONTEST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rakhi program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/500b66ce807e15aefa4a8d05976342c1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tagore Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEC CLINIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:The Baffling Planet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Diya making competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World river day poster competiotion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/930eb28ea722fd514f26245fab1aa53c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PUNJAB ENGINEERING COLLEGE DEEMED TO BE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Daily Sessions on Entrepreneurship by Mr Amit
                Hans</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Social Entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c356dd16c09ade6747c7ce32d569cac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tawi Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Nearby Industry ( ICE FACTORY)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Seminar on Ethical Decision
                making for Leaders</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19:Industrial Design Problem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MoU with Wadhwani Foundation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to Srisailam Power Plant</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill based workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19: Buffet Money</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c356dd16c09ade6747c7ce32d569cac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tawi Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Session and Field Visit to HT
                Towers </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Winners of state-level Vortex 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Beach Cleanup Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fd8b95e15d758adb2d23f6b50802eaaa.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Netaji Subhash Engineering College 109">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->• Orientation Program with Prototype
                Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/13827c319b1cc00a916b8409a6339b4a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GIRIJANANDA CHOWDHURY INSTITUTE OF MANAGEMENT AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Astitva – 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2040b86c5a1c4af8da71206cc55eda56.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Haritha Haram</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/13827c319b1cc00a916b8409a6339b4a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GIRIJANANDA CHOWDHURY INSTITUTE OF MANAGEMENT AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENOVA ENTREPRENEURS MEET </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Elite E cell activity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b334483808283ecf4639bc02a0eef33b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sau. Sushila Danchand Ghodawat Charitable Trust Group of Institutions Faculty of Engineering, Gat No">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcf9dceb9082d54ba909c6964cf29628.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DEVINENI VENKATA RAMANA & DR.HIMA SEKHAR MIC COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness for Water Conservation \&#34;Jal
                Diwas\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Demonstration of Electrical Power System
                Simulating Software</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->What\&#39;s in store in future for retail -
                Lecture by Mr. Kirit J. Maganlal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19: Craft A Vane</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/09033c64d9932ba8e16df1ead47ade68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Medical Device Quality Management Program
                with Ventilators Hands-on Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit - 2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Concetto-19: Embitrex</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Tour</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unlock Data Science using R</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bizathon: SHARK TANK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on AI & ML</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/786ddf2924b3d3973dc79667668a327e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HOLY CROSS COLLEGE(AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Renewable Energy Resources</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Analytics will Shape Your Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/78a12643c41998fd10d38edf57640897.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.D. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar: Low Pressure Measurement Techniques
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Webinar & Project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bizathon:PRODUCT HACKATHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rashtriya Ekta Diwas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213f67280cf3346d4bb01d7833e06785.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALBERTIAN INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CAMPUS AMBASSADOR </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Workshop on PCP Design and
                Fabrication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dr.N.Seshagiri memorial Lecture 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Climate Strike</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31e9839c9737e9635216f40841d6b90b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALASALINGAM ACADEMY OF RESEARCH AND EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agni-Enabling Technology Commercialization
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d86cf408d3f7dc523e3cfe7eb5b1352c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Law University Odisha">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GUEST LECTURE ON A.I. AND LAW</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4fcfe29ef856d096664b7743d776953d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUMARAGURU COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Build PoCs to test Value for real world
                challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->B QUIZ</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WALLSTREET</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensitizing Innovation in Engineering
                Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a2d8f532c1b9e213556a063acba4ceb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VAAGDEVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION AWARENESS ADMINISTERING PROGRAM
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CASE STUDIES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/59d86945a68bbfd22657f9877507beee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Jaywant Shikshan Prarsark Mandals Rajarshi Shahu College of Education Sr.No.80-2, Pune Mumbai Bypass">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session By Dr.Vijayshri Mehtha on Startup
                India Schemes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Modern Trends in Science & Engineering for
                future technologies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Investor Pitching Round</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Swach Bharat Mission</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f81a05a731b1b1a9581ee667bc4c51ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ROORKEE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5G Wireless Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d8e0affbc9668ddd935faa957b2ca0b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cc9e8e8a95e33440a67ae9e9d6441b0e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PUNE INSTITUTE OF BUSINESS MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MSME Innovation Lab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b66d6805f89de40f95f3bcd041b067e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH MULTI TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to Infosys Mysore</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on problem solving, Design
                Thinking and Ideation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1dca480cf161261de85c08dd5d993ae1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUPPAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internship awareness program on\&#34;IoT in
                Agriculture\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7ea9910e07d360073a370d05684180c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ASSAM DOWN TOWN UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A special lecture on Entrepreneurship
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b66d6805f89de40f95f3bcd041b067e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH MULTI TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Data Science and its Use in innovation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9e62fd8c99e39c483acb51c9eac25d1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. D. Y. PATIL INSTITUTE OF TECHNOLOGY.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AIC Pinnacle Project Showcase</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plastic Campagin</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a37ae4a7173880aad0f05a632d0fd852.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vivekananda Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Out reach Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sustain Development of India through
                Confederation of Indian Industry (CII)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Explore Visit to RBI Monetary Museum </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in \&#34;IDEATORS 19\&#34; All
                India Startup Business Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9cebf66d4a7ef44ae3266cc6854c43b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Patna Women&#39;s College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition on designing book cover using
                innovation and creativity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/090d4e10b98fc27eb40474f721ec9d50.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Vidya Prasarak Mandals Dr V N Bedekar Institute of Management Studies Chendani Bunder Road Thane (W)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Explore Visit to Bombay Stock Exchange </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f37e6088f6044c6f0d75e1a8a8fefc6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sensitization program on Technology Business
                Incubator (TBI) to students and Faculty members</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Startup Business </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9cebf66d4a7ef44ae3266cc6854c43b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Patna Women&#39;s College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech Fest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a585721774165b7f49aca9c173f0c97d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JIS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea 2 Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/184f76c4ed9de4b81509b3b68049c720.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMS College Kottayam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Prototype Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/071ead07007ff76b6e17a38b3a91420d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C K College of  Engineering & Technology (Formerly  Sri Jayaram Engineering College)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IGL on Automotive Radars</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ML/AutoCAD Workshop at NIT Jalandhar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WEBSITE CREATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/184f76c4ed9de4b81509b3b68049c720.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMS College Kottayam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Responsible Tourism</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Punjab Agricultural University</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Village/Society
                /School/Industry/Market – Identity real Life Problem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Building Portfolio with react</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CLOUD COMPUTING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/891e643e5ce454973d262d18de385111.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANAND INSTITUTE OF HIGHER TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Short Term Training Program on Realization of
                Internet of Things for Smart Cities 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Django Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DIGITIZED RECYCLING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to RSRTC Jodhpur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fa6867dfd4bf4cdead28f2dbc5165cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHANDIGARH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sub station visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to win a Hackathon?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on High performance Computation
                Servers </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISBA Con</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Weekend katra</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inspirational Innovation talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/aed35a2a78f0e12601dc2e28bee6fe2a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI MATA VAISHNO DEVI UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Proof of Concept Competetion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Session -Leader\&#39;s Inspiring
                Journey by Mr. Gopi Thangavel, VP, Reliance Industries</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6b6f523bab50c07953d3f7b3e6616b8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MADANAPALLE INSTITUTE OF TECHNOLOGY & SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Annual Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e0f04b97c8d5b8542548951ee1fdd0f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA & B M REDDY COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Current Trends in HPLC Technique and
                its applications”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c813d96addf8d16181ccf3e471f5975.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNEL INSTITUTE OF TECHNOLOGY AND DESIGN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Self Motivation, Conversation
                skills and time management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3fc9d508495c6a8beb3bed3c1cbdaa0c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PATRICIAN COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION DAY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->New Frontiers of Drug Discovery-Innovation :
                Current Status of Pharma & Biotech in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/589da4bfeff70d7d2fc3cac2069c676a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDRAPRASTHA INSTITUTE OF INFORMATION TECHNOLOGY -DELHI (IIIT-D)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pitch Cafe 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/545eb17ed50c572b6964681243b2e433.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. B.R. AMBEDKAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Presentation on National Innovation and
                Startup Policy 2019 for students and faculty</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/18526f82cdfcbdd79e98193173f11455.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS DWARKADAS J. SANGHVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Collaboration with Navayuvak</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b430725a6e40d1954321eaaea1daf68e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="D.K.T.E. SOCIETYS TEXTILE & ENGINEERING INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mini-Project competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e23a0645490f5d5e88872d55d75cfb70.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Raipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Mantha 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TRAINING PROGRAM ON HERPETOLOGY </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a74b9113f8930d6d52d13faa009c230.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KINGS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days FDP on Research Issues and Solutions
                in Sustainable Energy Conversion and Renewable Energy Sources</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/545eb17ed50c572b6964681243b2e433.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. B.R. AMBEDKAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACKATHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pesticides: Our daily dose of Poison -
                Lecture by Prof. Bernard Paul</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7ce78a1aca7122dec45e16f7bc277a9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Khalsa College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lets Talk Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Goods and Service Tax : Present Scenario,
                Challenges and Opportunities - Lecture by CMA Shiba Prasad Padhi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/589da4bfeff70d7d2fc3cac2069c676a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDRAPRASTHA INSTITUTE OF INFORMATION TECHNOLOGY -DELHI (IIIT-D)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INCEPTION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Family run businesses in Goa - Lecture by
                Shri Manoj Caculo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b66d6805f89de40f95f3bcd041b067e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VEL TECH MULTI TECH DR.RANGARAJAN DR.SAKUNTHALA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Interaction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking and Industry 4.0 - Lecture by
                Dr. Charles M. Wood</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Literature Survey Paper Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/85dd76804b25caf76b9310e597b5a9e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Goa University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emergence from Exponential Future - Lecture
                by Samir Soni</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pupa Product Buildup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->uC Mania (Techyon 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GCP Crash Course by Developer Student Clubs
                NITJ</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RoboSoccer (Techyon 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT REPORT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4241bc2dfc3a4d6ac9d25f29b4b8dd4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMITY UNIVERSITY,JHARKHAND">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HUNAR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Annual State Level Technical Event – Pixels
                (Techyon 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHFEST GOA 2019 organized by ISHRAE (Indian
                Society of Heating, Refrigerating and Air Conditioning Engineers ) Goa chapter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Exhibition (Techyon 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEATE & LOGO DESIGN (Techyon 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Code-A-Thon (Techyon 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Weave the Web (Techyon 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar \&#34;To set up a Small Scale
                Industry\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IETE Poster presentation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Ideation, Innovation,
                Communication and Leadership conducted by E-CELL PCCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rockesta|Innovesta 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d524e0ed33dd17dd3313d209c990018f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr B R Ambedkar National Institute of Technology Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chemical Constellation Cheminar – 2019 (C3
                -2019) (An International Conference)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python 3 Workshop conducted by S.U.I.T. of
                PCCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python and Selenium Hands-On Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/623610f64a1a59445fc5d3060929266c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chevalier T.Thomas Elizabeth College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VEHICLE PASS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Compiler Construction</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk by Dr. Amitalok Budkuley for ETC
                students at PCCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE is awarded the BEST college for
                INTERNSHIPS at the AICTE head Quarters in New Delhi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Life Story of an Entrepreneur.\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lab innovation Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/965acc40303e14bef2fdc4ef0d64e207.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NADAR SARASWATHI COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Project</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Talk on Platform Strategies and
                Innovative Techniques for selecting a good Project.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Synopsis and In plant Training Report
                Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE organizes workshop on Drones and combat
                robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE organizes Internship workshop on Machine
                Learning using Tensor Flow</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AARUSH 2019 - 5th National Level
                Industry-Institute convention held at PCCE.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/965acc40303e14bef2fdc4ef0d64e207.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NADAR SARASWATHI COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Kalam Awards</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BOOTCAMP ON HTML</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e0f04b97c8d5b8542548951ee1fdd0f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACHARYA & B M REDDY COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP’s “Teaching – learning methodologies”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ETC dept.of PCCE organized a one week ISTE
                STTP on \&#34;Machine learning and Deep learning using Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PUTTING ROBOTICS ON THE GOAN MAP – The
                Incredible PCCE TEAM BHP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE MECHANICAL STUDENT builds their own
                Ceramic 3D Printer and wins the 1st place at kshitiji 2019.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LIGHTER THAN AIR – PCCE STUDENTS WORK ON AN
                AIRSHIP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE STUDENTS BUILTS INNOVATIVE PROJECT
                \&#34;SMART URBAN AGRICULTURE MODULE FOR HOMES\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/965acc40303e14bef2fdc4ef0d64e207.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NADAR SARASWATHI COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE Students fabricate ‘IRIS’ – A Smart
                stick for navigation for the visually impaired</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pupa Orientation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT TO BOSCH, VERNA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD TRIP TO SIEMENS LTD, Verna</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE conducts industrial visit to OPTEL GROUP
                in Verna( A Canadian origin company)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD TRIP for PCCE students TO PERSISTENT
                SYSTEMS LTD. (PSL) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/623610f64a1a59445fc5d3060929266c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chevalier T.Thomas Elizabeth College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LOGO DESIGN COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a4d3f0699082ca959a0f6bd8b7f19ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="N. B. NAVALE SINHGAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Avishkar University Level innovative Project
                Poster Competition 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Life in Art</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAY WORKSHOP ON ROBOTICS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/287c9e722c6121f7349f7eb9cf8e93ee.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bombay College of Pharmacy ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenges and Opportunities Of Developing
                Generic And Specialty Inhalation Products For Global Markets</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4e090491d6b9d4fb6f9684ffb4ce0fdc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF MANAGEMENT STUDIES, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Water Recycling Plant in Singapore
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Goa Management Association (GMA) signs MoU
                with CIBA, Incubation Centre at PCCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Induction Program for I year students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/563e736aabc83009d62a1be364b35176.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IFET COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP DEVELOPMENT WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The 2019 CanSat / Rocketry International
                Competition\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/563e736aabc83009d62a1be364b35176.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IFET COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INDUSTRIAL VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE in association with Citrix Bangalore
                conducts ‘Train to Hire’ programme for Engineering students at PCCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL MACHINE LEARNING APPLICATION
                DEVELOPMENT Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Overview of Software Industry Expectations
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on INTRODUCTION TO MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on INTRODUCTION TO SOLID MODELING
                USING SOLIDWORKS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/65e75df1ace3f0c01f2d72b703a60b92.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADRE CONCEICAO COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCCE E-CELL ignites entrepreneurship
                activities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of Entrepreneurs in Economic Development
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ADX-201 Salesforce administrator </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Display of FDM 3D Printer from Jadavpur
                University at CII Innovation Club projects in Tata Steel, Kolkata held on 2nd August, 2019. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in the Climate Launchpad Boot
                Camp 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Jontro Tontro Innovision\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/184f76c4ed9de4b81509b3b68049c720.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMS College Kottayam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation Classes on Innovation by IIC CMS
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9d17b9e6d7013e0758ab2538553a11cb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JADAVPUR UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meeting with the MUMA College of Business,
                University of South Florida,USA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f20b406f45ccdda51461fe288c0b4f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NESAMONY MEMORIAL CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Yuva 2k19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5f20b406f45ccdda51461fe288c0b4f9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NESAMONY MEMORIAL CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gleams</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/184f76c4ed9de4b81509b3b68049c720.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMS College Kottayam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech Fest: Zerone\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/184f76c4ed9de4b81509b3b68049c720.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMS College Kottayam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->State Level Oreintation Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/076c8690c134f458aba2e803c816cb49.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fb978c86086a6d7236f4d6b163eb774.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VILE PARLE KELAVANI MANDALS NARSEE MONJEE INSTITUTE OF MANAGEMENT STUDIES SVKMS NMIMS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in inter-collegiate innovation
                fests (Vortex 2019 by Institute of Chemical Technology)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/184f76c4ed9de4b81509b3b68049c720.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMS College Kottayam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->State Level Mentoring and Boot Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/184f76c4ed9de4b81509b3b68049c720.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMS College Kottayam (Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea/PoC Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d88a232028b9cb30c7d717d41c5f199.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PARALA MAHARAJA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Regional Orientation Session on IIC 2.0
                ,ARIIA 2020 and PoC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to the world of Machine Learning
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be876773ee7d27d208a3a835909a4320.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI MANAKULA VINAYAGAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL DIGITAL SECURITY CONFERENCE\&#39; 19
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/99e0f20f270775c14bc59fb327c176e9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S R ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be876773ee7d27d208a3a835909a4320.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI MANAKULA VINAYAGAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACKATHON in cyber security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/46571fd4110447ef5aeafbac01095f67.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VASAVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Graduation Ceremony of Technology
                Entrepreneurship Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/46571fd4110447ef5aeafbac01095f67.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VASAVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TiE Grad Orientation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d76aaa0e680e15ec623a4873f392ca81.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GSSS INSTITUTE OF ENGINEERING & TECHNOLOGY FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk for Women Empowerment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f833d37f61c575ab777d7c31d6209c13.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JB INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Speech to Improve Research,
                Innovation, entrepreneurship & startup & Inter-disciplinary research etc </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5db4b61d6942c050a9ad4a3e2978566e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU ENGINEERING COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Young Manager</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inspection Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on \&#34;Conceptual
                Designs\&#34;. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/24d37fd5353bd7d2a1e8e168dac075d6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.M.S.COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MACHINE LEARNING WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Virago\&#39;19- The Women Summit\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->5-Day Internship Training Program on
                \&#34;E-Vehicles\&#34;.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tanana-Art Mela (Entrepreneurial Awareness
                Camp)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creative Likhitha -One day workshop on
                Creative Designing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INSTAGRAM PHOTO CONTEST</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f619a580ed88968660328ae2800f2bfc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DAYALBAGH EDUCATIONAL INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pitchers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Palm Springs and MNR Ginning
                Mills on 22nd August, 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;I-innovate\&#34;( idea) competition in
                Intellect concern</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Robotics process automation seminar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Trends in Aero Science and Engineering
                & Technologies\&#34; (Techzilla \&#39;19)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2402e1c839e8cbbf6afe0936fe22a07d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JEPPIAAR INSTITUTE OF TECHNOLOGY, JEPPIAAR NAGAR, KUNNAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->cyber security awareness program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5db4b61d6942c050a9ad4a3e2978566e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU ENGINEERING COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PCB Designing and Manufacturing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->UEM CODE STORM 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chalk piece and Candles Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bf7b4e3422b12c9a70f8f019c9049c68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING AND MANAGEMENT JAIPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BEACON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/be876773ee7d27d208a3a835909a4320.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI MANAKULA VINAYAGAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c7379ac8253431287594c9eac397402d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KCG COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Solar Ambassador workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7148e4b310c125def0ca6f921025c638.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.I.S MOHAMMED HAJI SABOO SIDDIK POLYTECHNIC">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SPARK 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7148e4b310c125def0ca6f921025c638.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.I.S MOHAMMED HAJI SABOO SIDDIK POLYTECHNIC">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SWACHHATA HI SEWA CAMPAIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e9a9883c0d000c67c83a01e867fb35e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THE ICFAI FOUNDATION FOR HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Innovation Day Celebrated on October
                15 at the Institution Innovation Cell (IIC), ICFAI Foundation for Higher Education (IFHE)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7148e4b310c125def0ca6f921025c638.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="A.I.S MOHAMMED HAJI SABOO SIDDIK POLYTECHNIC">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENGINEERS DAY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a15f996374eb2786619bf25fa7f67129.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI PERUMALLU EDUCATIONAL SOCIETY&#39;S GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ESSAY WRITING COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a15f996374eb2786619bf25fa7f67129.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI PERUMALLU EDUCATIONAL SOCIETY&#39;S GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inauguration of NSS CELL </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a15f996374eb2786619bf25fa7f67129.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALINENI PERUMALLU EDUCATIONAL SOCIETY&#39;S GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Freshers day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0ffd2f6122b5c964c4e3b9f2a1e458a1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MES COLLEGE MARAMPALLY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Transform class rooms in to Hightech learning
                spaces</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on Learning Management System </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Hands on Training in CNC Machine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Hands on Training on Design and
                Analysis of Mechanisms</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit /BEC Fertilizer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WATER CONSERVATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on global distributions of games and
                Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31c9262772ca5280fc2a45f0f6ffd595.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AHALIA SCHOOL OF ENGINEERING & TECHNOLOGY, KOZHIPPARA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Patenting and Procedures</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IGNOU IIC Participates in the First Festival
                of Innovations </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31c9262772ca5280fc2a45f0f6ffd595.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AHALIA SCHOOL OF ENGINEERING & TECHNOLOGY, KOZHIPPARA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Quad copter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31c9262772ca5280fc2a45f0f6ffd595.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AHALIA SCHOOL OF ENGINEERING & TECHNOLOGY, KOZHIPPARA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Total Station</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Club@IGNOU : Presentation on ‘An
                innovative Programme on Urban Planning and Development’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Self Awareness: How you become
                who you want to be </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two-Day Training Workshop on Creation and
                Integration of Video and Snippets in web-based Instruction organised by the NCIDE, IGNOU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit (The Navabharat and The
                Central Chronicle)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fb700d9ef0b2ea71bfec259a1b6ffe9b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALLAM HARANADHAREDDY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3 Day Workshop on PROBLEM SOLVING AND
                PROGRAMMING IN PYTHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Club@Regional Centre, Cochin :
                Bridging the Gap between Customer Potential and Sale Potential in Banking Sector: An Innovative Approach
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meeting of the InnovationClub@IGNOU :
                Presentation on ‘Innovation in Assessment of Core Competencies of ODL Professionals’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to MY FM and The Haribhoomi
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Meeting of the InnovationClub@IGNOU -
                Presentation on ‘Innovative Approaches for Grievance Redressal in ODL’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on “Fashion House as a Pioneer
                Step for a Designer”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cf7e17184ab30eeb7fcc648755d64439.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indira Gandhi National Open University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->हिंदी यूनिकोड में कंप्यूटर पर कार्य करने के
                लिए प्रशिक्षण कार्यशाला </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enrichment Lecture on Specialized Reporting &
                Writing in Print Media</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit at Solar Park (Solar Power
                Plant), Naya Raipur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/31c9262772ca5280fc2a45f0f6ffd595.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AHALIA SCHOOL OF ENGINEERING & TECHNOLOGY, KOZHIPPARA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on HVAC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f5bf254131b7a46b2466e63ea9a460c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAGHAVENDRA INSTITUTE OF PHARMACEUATICAL EDUCATION & RESEARCH (RIPER)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enreprenuership awareness camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INTER-SCHOOL FRENCH STORY WRITING COMPETITION
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f5bf254131b7a46b2466e63ea9a460c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAGHAVENDRA INSTITUTE OF PHARMACEUATICAL EDUCATION & RESEARCH (RIPER)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideathon contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fb700d9ef0b2ea71bfec259a1b6ffe9b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALLAM HARANADHAREDDY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PROBLEM SOLVING AND PROGRAMMING IN PYTHON</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PRAYANA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fb700d9ef0b2ea71bfec259a1b6ffe9b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALLAM HARANADHAREDDY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CLOUD COMPUTING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EETA day celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Essay Competition: Let\&#39;s explore
                your writing skills\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/458dd436429ce6cefc07646612a8c96a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Palakkad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation Program on Green Initatives</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Nature Walk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Comquest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conclave of Circular Economy of Plastic Waste
                and Livelihood Opportunities </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mental Health Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hindi Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a66609c626b586bbc46563e73e5fe99.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LADY SHRI RAM COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Machine Learning in R</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1182108f07d29279128026688d863435.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING TRIVANDRUM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6f78b80c5172f3413b2506c386408a0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H.RAISONI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Methodology Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6da71727ce6f2eb5470928245d7b829.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Siddaganga Institute of Technology, TUMKUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interaction with an entrepreneur </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc7e22290260430711939315ee45d112.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IILM University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Magic Box Innovation Contest </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e04717f985f6bb46dc7f44390e549e27.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DPG Institute of Technology and Management">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Technology Day Celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit to Network Bulls</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b85b56683d6c8acea150e0ff081100e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DRONACHARYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;Pot -a -Plant\&#34;: Go Green
                Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7eb3430499553bc52deb5a18899d51ab.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MYSORE COLLEGE OF ENGINEERING AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Of Steel Structures And Hydraulic
                Structures</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d48ae02e2354cc153ca5b9a61efbc05a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shri Madhwa Vadiraja Institute of Technology  & Management, BANTAKAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6f78b80c5172f3413b2506c386408a0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.H.RAISONI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Trident 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Talk on “Smart India Hackathon-2019
                Informative Talk” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcf9dceb9082d54ba909c6964cf29628.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DEVINENI VENKATA RAMANA & DR.HIMA SEKHAR MIC COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Deep Learning for Image and Text Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->1 day workshop on Multisim using NI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fb700d9ef0b2ea71bfec259a1b6ffe9b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALLAM HARANADHAREDDY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Automotive Four wheeler </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fb700d9ef0b2ea71bfec259a1b6ffe9b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KALLAM HARANADHAREDDY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->APSSDCL – AUTOCAD - ONE WEEK WORKSHOP </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9f395669f18764fc489133a5def7ef00.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mula Education Society\'s Arts, Commerce and Science College, Sonai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop for Teachers on IPR - Patent
                Application</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9f395669f18764fc489133a5def7ef00.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mula Education Society\'s Arts, Commerce and Science College, Sonai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop for Students on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, Motivation & Entrepreneurship in
                Foundry Industry”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0476c7c40c0e3da0fdac3cd8685bde34.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K S Institute of Technology, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Networking and overview on Networking
                Certification Track: CCNA, CCNP, and CCIE </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9f395669f18764fc489133a5def7ef00.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mula Education Society\'s Arts, Commerce and Science College, Sonai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Campaign </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/164ac7cefe1e08d0e1719cb759b40b34.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Guru Gobind Singh College of Pharmacy, Yamunanagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/848c82596b7539a46e055dba1a616c51.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pitch your IDEA to Nature</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8425d42248583e9d4da298b234bf7199.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Banarasi Das Chandiwala Institute of Professional Studies">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP AWARENESS CAMPAIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6b17d850cd38d1d9cc8ed8d9eb5a7387.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNDT Women's University Mumbai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Granthotsav</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->If they can why cant we</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organization of a Global Event, Students
                Solar Ambassador Workshop at Govt. College of Engineering Nagpur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NEN - MOU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start up mentoring session by Dr Vandana
                Mathur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start up mentoring session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business model and business plan workshop for
                start-up mentors</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start up mentoring session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->orientation - Session Report</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5a5be8a038cd92c9c6fed4c74d1fbad1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT, NOIDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation - data collection </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67789dea1787ed3dc5a70786f34ad108.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Adichunchanagiri College of Pharmacy">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STEP-StartUp Acceleration Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Internet of Things in Health Care
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture on Research Methodology –
                Demystifying Method of Writing Effective Research Articles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Could Computing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Fundamentals of C</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Idea Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abd376de3f8daf676931d147553d7ed2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Development Phase - Story telling</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Boot Camp on Innovation & Entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Month Programme on SIYB (Start &amp;
                Improve Your Business) by MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ad7f8a8faf70c0cf4d111068d7cff471.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PACE INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#39;Innovation on wheels\&#39;, The Festo
                Mobile Expotainer on Latest Product application of Pneumatic Technology on Industrial Automation in
                Association with Govt of Andhra Pradesh</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/294aeb76b5d031e3440fefe0d0bf7f46.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. J. SOMAIYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Abhiyantriki 2019 : Tech Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/294aeb76b5d031e3440fefe0d0bf7f46.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. J. SOMAIYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KJSCE Hack</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a24e7600358ae749541247c1ba5b38b9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GCRG MEMORIAL TRUSTS GROUP OF INSTITUTIONS FACULTY OF MANAGEMENT, LUCKNOW ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship training program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENGINEERS DAY\&#39;19 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TED TALK ON \&#34;“Ideas Worth
                Spreading\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on \&#34;Entrepreneurial Skills\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a787b43bca51da6a621d927156bb4e6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nowgong College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SHARE THE STORY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2060029db2c9548b4f45d50003701e68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA GOVERNMENT ENGINEERING COLLEGE, CHANDKHEDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk by Alumni Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->1st International Conference Proceedings
                ‘AES-2019’ organized by School of electronics & Communication Engineering, Shri Mata Vaishno Devi
                University, Jammu.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->POWER POINT PRESENTATIONon “Latest
                Technologies used in engineering world” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CONSERVATION OF WATER TECHNIQUES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Lecture on “Harnessing Conventional Wisdom
                and Innovating for Business Success: A Paradigm in Waiting” organized by University of Jammu on 13th
                Septem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inception 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop through ICT </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/778ece1fdd5af18d95f3f9023ffcfad9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YOGANANDA COLLEGE OF ENGINEERING AND TECHNOLOGY, PURKHOO ,JAMMU">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Swyam NPTEL</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Cloud Computing with AZURE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Java Fundamentals”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Seminar on Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Computer Networks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Harvard Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on DATA ANALYTICS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One week Hands on Training course on
                Fabrication of Fiber Reinforced Polymer Composite Laminates and Structures </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cameron INNOVAY\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days workshop on Data Mining and Its
                Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Guest Lecture on Data Structures</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property Rights </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Advanced Concrete Mix Design &
                Technical Presenation on VAC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAE WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/278d2447485693ccc9ee736b4b2a6418.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Management Education & Research Institute">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E Talk and workshop on opportunities in
                Health and wellness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAE WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Advance Programming Concepts
                using C and Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/278d2447485693ccc9ee736b4b2a6418.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Management Education & Research Institute">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E Talk and workshop on starting startup in
                tourism</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/278d2447485693ccc9ee736b4b2a6418.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Management Education & Research Institute">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E- Talk and Workshop Building Brand using E-
                cOMMERCE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Chakravyuha: The business Idea Competion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE DESIGN CHALLENGE CONTEST 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation walk & Idea Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Students Innovation FEST-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da2d43c737186f6684089e3f066bdaeb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TEST INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->checking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2060029db2c9548b4f45d50003701e68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA GOVERNMENT ENGINEERING COLLEGE, CHANDKHEDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR and Patent Drafting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2060029db2c9548b4f45d50003701e68.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VISHWAKARMA GOVERNMENT ENGINEERING COLLEGE, CHANDKHEDA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk on Startups and SSIP Orientation
                session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/eb14c53810f3eb0bfa3df8510473a451.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RK UNIVERSITY SCHOOL OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on e-Business</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster & Slogan Making Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on ISRO launch vehicle
                development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Drawing, Essay and Rangoli Competitions</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on quality in engineering </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOT Hands on with Case Study Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/294aeb76b5d031e3440fefe0d0bf7f46.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. J. SOMAIYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Abhiyantriki 2019 Speaker Session : Dr.
                Bikash Chandra Sinha</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Pitch Workshop Bootcamp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Pitch</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c05277b98e33232891f329c0bf9c32c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->BIO PRINTING IN LIFE SCIENCES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2c05277b98e33232891f329c0bf9c32c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“Recent trends in Phytochemistry” Organised
                by Dept. of Pharmacognosy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conference Tracks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Student Startups -
                Current Trends and Challenges</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coincubation and Collaboration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Wind turbine Technology
                towards mitigating the climatic change</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Thought Fest 2019 – Make in India with
                Innovative Idea – A National Level Project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Hands on Workshop on Raspberry Pi
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on GL on Raspbeery Pi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Electric Vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on E-Mobility in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NEC Idea Challenge for School Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to make your first video game</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on ‘The Art of Photography’ </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NEC Startup Entrepreneurship Summit (NECSES)
                2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Iot and Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b0bc0eff41bee4a267ac644f1bd6b21.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Rf Circuit Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Autonomous Robot Challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Capacity Buiding workshop on \&#34;Basics of
                3D Printing\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Week</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Slogan on Innovation Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Days Entrepreneurship Awareness Camp at
                SBBSU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities in Clinical research </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a5db6f1abfba757fb89b35f5fdb70536.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="C. U. SHAH COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Opportunities in Pharmacy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c813d96addf8d16181ccf3e471f5975.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNEL INSTITUTE OF TECHNOLOGY AND DESIGN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c813d96addf8d16181ccf3e471f5975.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNEL INSTITUTE OF TECHNOLOGY AND DESIGN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Talk by Mr.Jonathan Joshi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9b1a0ffe2babd980af98da30cb084476.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROF RAM MEGHE COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship & Personality Development
                Programme for Final year Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9b1a0ffe2babd980af98da30cb084476.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROF RAM MEGHE COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship & Personality Development
                Programme for 3rd year Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9b1a0ffe2babd980af98da30cb084476.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROF RAM MEGHE COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship & Personality Development
                Programme for 2nd year Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9b1a0ffe2babd980af98da30cb084476.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROF RAM MEGHE COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship & Personality Development
                Programme for 1st year Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Filed Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Signal
                Processing and Optimization Techniques Sponsored by MeitY, Govt of India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fa11d6fb3e0fe1b6a7cc831f1972f53.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAHYADRI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Best Opportunity to Learn Data Science (BOLD)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special talk on \&#34;Open Innovation\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Godrej Disha Technical Excellence Center
                Inauguration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0e7e3eb6eea94ea4735118bf316d683e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KGRL College(A)  - Degree  & PG Courses">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->International Conference</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2054b256ace48453bb9a5f7dee8b2a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADMASHRI DR. V.B. KOLTE COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tree Plantation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/67a84d43376763dcc12543fcdd326cd9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TECHNO INDIA UNIVERSITY, WEST BENGAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation workshop on Innovation &
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Roadmap for Software Professionals & Recent
                Innovations in Industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3f0d3d9904ba734ec8e02f34f3f53f5.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY ENGINEERING COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EAV</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Presentation on National Innovation and
                Startup Policy 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Counseling and Career Guidance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Boot Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2054b256ace48453bb9a5f7dee8b2a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADMASHRI DR. V.B. KOLTE COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Yog Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2054b256ace48453bb9a5f7dee8b2a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PADMASHRI DR. V.B. KOLTE COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Contest and Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d0573e92cd2858cdb77cb8261e554bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARMY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7347a538496bf5aaba83b7e7360fe581.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI G.V.G.VISALAKSHI COLLEGE FOR WOMEN (AUTONOMOUS)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guidance for POC Meeting</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1258f440c75a953da59f382bd1da9bdb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Master Class (SMC) IN jklu</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1258f440c75a953da59f382bd1da9bdb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->High Flyer activity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/585bbc04a90ddac489035f851f2f2287.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Technology Jodhpur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->School Student Visit at IIT Jodhpur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5edefd40a4b5d3c3e4c6d541e68f9d57.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sree Ayyappa College for Women, Ayyappanagar, Chunkankadai - 629 807, Nagercoil - 629 807">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation programme </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Training Programme for women
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6efa2e46d9cf95da416252d2ee2c5b69.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhagwan Parshuram Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inauguration of Innovation Hub</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44f1d53d22443a9c8148e9b823df4694.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AAA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineer\&#39;s Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Planathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a368db275e03101a8bb9b28c8f31ca1b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chebrolu Engineering College, Near Power Station, Chebrolu (P.O.&Mandal), PIN-522212  (CC-HU)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on “Developing and Managing Energy
                Resources in a Sustainable Way” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->School Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->New ways of finding a startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Capacity Building workshop on IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Industry perspective of Innovation
                and Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENtrepreneurs Story -Motivation and
                crossroads</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Amity Alunmi Entrepreneurs -From Student to
                CEO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VISIT TO SAFE A&T TECHNOLOGY PVT.LTD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup BootCamp and Industrial Visit at
                SP-TBI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Panel Discussion on Startup Vs Job Vs Higher
                studies</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Finances of Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e391b7c7e8dde3245110ffcb3c2357f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHARATIYA VIDYA BHAVANS SARDAR PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Entrepreneurship Drive In
                Collaboration with shelter Don Bosco</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Self Awareness: How you become
                who you want to be </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop Series on Machine Learning: First
                Module </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Teacher\&#39;s Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mini Anvenshna 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rangoli on Save Girl Child</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f6debb4348fb0f50adbc81979e59b516.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIYADARSHINI J. L. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HANDS ON TRAINING OF MOLECULAR DOCKING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Imaginarea</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Think Execute Analyze </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Making Competition on Science And
                Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Robotics & IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Industrial Instrumentation &Coal To Pole To
                Achieve Goal</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Higher Studies Awareness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Placement Training</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Assessment of Industry Training Presentation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d0573e92cd2858cdb77cb8261e554bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARMY INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Half Day workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e98f71a1da7c9ac2d01c3dc97f3edcf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SUBHARTI INSTITUTE OF TECHNOLOGY AND ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Open air Debate Competition on Innovation day
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68bd51db23f3cf168d2ca2a71bf487c3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT ACADEMY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lets watch a movie!</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unnat Bharat Abhiyan (Visit to Villages to
                identify the problems))</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Idea Generation and Product
                Development Process - 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Idea Generation and Product
                Development Process - 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1258f440c75a953da59f382bd1da9bdb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CASHLESS TO CEO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1258f440c75a953da59f382bd1da9bdb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAIPURIA INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEA GENERATION </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Illuminate - A pre-eureka Business Model
                Workshop on Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fa11d6fb3e0fe1b6a7cc831f1972f53.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAHYADRI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Build with your family Eco-Friendly Diwali
                Lamps Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a319315d7cc6069bee0d4774ee78d834.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NATIONAL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENTREPRENEURSHIP AWARENESS CAMP 1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘Taking the Next Step’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->COSTRUZIONE\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FDP on accessing of Red Hat Portal for
                Registration & Lab Setup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Image Processing using Matlab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Social Outreach Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on New Variation and
                Techniques of Machine Stitching</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Making Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty development workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design thinking workshop -4</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design thinking workshop -3</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design thinking workshop -2</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design thinking workshop-1</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Value and Ethics in
                Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Making Contest on the occasion of
                “World Mental Health Day”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Patent Filing workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project management by panchatantra</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineers Day Celebrations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative HR management </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MBA Orientation Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative management </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on connect Mechanical & Electrical
                to MEP.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation on the way</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Team Building and Cohesiveness</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIT Bombay esim Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Powerstation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Day National Workshop on AICTE Mandate
                - Examinations Reforms-Outcome based Teaching, Learning and Assessment.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to TIRR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAY NO TO PLASTIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Visit to Kuwargarh Handloom Cluster
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Spread The Great Ideas of Bapu to Inspire
                Everyone</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gandhi Global Solar Yatra GGSY workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f24eccdbff6701c252517c75f408f35d.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JOGINPALLY B.R. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ICT Academy BRIDGE \&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to countree house</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Congress Grass</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurs- Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/af818a95d371ae00736b3b588ddb166d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovate with Karuna</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Science and Technology fair 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->poster Presentation on innovation,
                Entrepreneruship and incubation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/623610f64a1a59445fc5d3060929266c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Chevalier T.Thomas Elizabeth College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop Based on \&#34;Innovative
                Thinking\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Out Reach Activity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Review Based On Psychological Theme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plastic Awareness Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7dee5a7c65e17ea0e49f43e4f11fb9fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA UNIVERSITY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day Campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1dca480cf161261de85c08dd5d993ae1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUPPAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Programme On Incubation and MSME
                Schemes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VISIT TO ADVANTEK AIR SYSTEMS PVT LTD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Generation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Career Opportunities for Engineers in Defence
                Services</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/294aeb76b5d031e3440fefe0d0bf7f46.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K. J. SOMAIYA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HULT Prize Somaiya Vidyavihar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4dcc063e4befa32a496e4cdc717ec1e5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Sinhgad Technical Education Societys Sinhgad Institute of Pharmacy, Pune Mumbai Bypass Highway, Narh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Entrepreneurship Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/563e736aabc83009d62a1be364b35176.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IFET COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IFET MIND SPARKATHON\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->POSTER COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Slogan Writing Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcf9dceb9082d54ba909c6964cf29628.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DEVINENI VENKATA RAMANA & DR.HIMA SEKHAR MIC COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIONS AND CAREEER OPPORTUNITIES IN
                HEALTH CARE INDUSTRY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Biodiversity
                Conservation for Sustainable Future</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9788b97016f35546e1c36502504dc924.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA INSTITUTE OF PROFESSIONAL MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->State level Hackthon 2019-20 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d12575dffa16bd474af6122508e849a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nagesh Karajagi Orchid College of Engineering and Technology, Solapur.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Session on \&#34;Intellectual
                Property Rights\&#34;-IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63fc9d91e3f11e8f04a88836ef94b3e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. MARY&#39;S  COLLEGE, THRISSUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop Mental Strengthening Methods </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/43ef7c5daee83c0a7b601a9cdf5dbefc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANNASAHEB DANGE COLLEGE OF ENGINEERING AND TECHNOLOGY, ASHTA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a24e7600358ae749541247c1ba5b38b9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GCRG MEMORIAL TRUSTS GROUP OF INSTITUTIONS FACULTY OF MANAGEMENT, LUCKNOW ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dr.APJ Kalam Birthday Celebration (
                Innovation Day)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Collaboration with MIC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Skill Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agreement Collaboration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agreement Collaboration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agreement Collaboration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agreement Collaboration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agreement Collaboration with AIC-CCMB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Partnership</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agreement Collaboration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Agreement Collaboration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bba99786f64a280bd29773ce660003b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JODHPUR INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACK-DAY@JGI</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bba99786f64a280bd29773ce660003b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JODHPUR INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Open House</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert talk on
                \&#34;INNOVATION-INCUBATION-ENTREPRENUERSHIP\&#34; as part of Innovation Day Campaign,2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d12575dffa16bd474af6122508e849a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Nagesh Karajagi Orchid College of Engineering and Technology, Solapur.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SOLAR AMBASSADOR WORKSHOP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Intellectual Property Online
                Courses-Discussion and workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ffd3928e20290c643050e8b22a528edb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Aravind Gavali College of Engineering, Gat, Panmalewadi,">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Motivation Campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e466e07e48c245acf3d190edd6941ad8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Post Graduate Government College for Girls Sector-11">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Slogan Writing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/75026ad3e1a5d011fe107d4b2514430a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAHAKAR MAHARSHI SHANKARRAO MOHITE PATIL INSTITUTE OF TECHNOLOGY AND RESEARCH, SHANKARNAGAR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Solar Ambassador Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Finance Mantras for Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b034f65149ee0b62f9a40ad852fb2f91.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B S A CRESCENT INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TIECON 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c83e8930c3bb4406c92e5b09e466a189.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Acropolis Institute of Pharmaceutical Education & Research, Khasra No. 283/3, Indore Bypass Road, Ne">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Jal Shakti Abhiyan under IIC activity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Block Chain</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A session on Cracking the code for a career
                at the intersection of tech, policy & management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Entrepreneurial Expedition to Jaipur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electronics Enabling Lab (EEL) opening</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentor Me MENTOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Recent Trends in
                Biochemical Engineering and Biotechnology (RTBEB 2019) </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9df67ebd32f0b1e7e838d1e986e5baf9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KLE TECHNOLOGICAL UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights & Patent Law</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e466e07e48c245acf3d190edd6941ad8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Post Graduate Government College for Girls Sector-11">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Making Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74c1cf6870fc8adb4641a4068c00bace.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHARAJA AGRASEN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techno-Web</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cd0e37c03475816fc572f97cbe1400c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahatma Education Societys Pillai college of Engineering, New Panvel">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Makerthon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness & Development
                Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness camp </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry 4.0 Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d557650b821acb80a02d6a08fe6cd0a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Pravara Institute of Medical Sciences, Ahmednagar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION DAY CELEBRATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation for the development of competent
                Professionals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISBA BioNEST Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of Hindi Diwas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Eat Right Mela 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3092a8319a772adb6285119ef357e5e8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Institute of Management Studies & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Work shop on enterpernuership</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION DAY 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAYS HANDS-ON TRAINING ON “GRID AND CLOUD
                INFRASTRUCTURE SETUP”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SYNSARA 2k19 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Hands on Workshop on Internet of
                Things in Association with Cloudchip Technologies </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY WORKSHOP ON 3D PRINTING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4bea18ddba74b9afa401b640d0a2ebda.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S.R. M INSTITUTE OF SCIENCE AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SRM DSC X</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Debate Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical meet & ICT Quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abd376de3f8daf676931d147553d7ed2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day - Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abd376de3f8daf676931d147553d7ed2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day - Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4fe9365458b2316ae86a2ead81d7187.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJIV GANDHI COLLEGE OF ENGINEERING AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACKATHON 2.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4fe9365458b2316ae86a2ead81d7187.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJIV GANDHI COLLEGE OF ENGINEERING AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Solar Student Ambassador</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dc85de40f2c8e9122fdc2123815b6103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ONE DAY WORKSHOP ON INTELLECTUAL PROPERTY
                RIGHTS (IPR)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MoU Signing Ceremony with M/s. Salzer
                Electronics Ltd</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DESIGN OF BATTERY MANAGEMENT SYSTEM FOR
                RENEWABLE ENERGY APPLICATIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c356dd16c09ade6747c7ce32d569cac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Tawi Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Day Workshop on \&#34;Intellectual
                Property Rights\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hacknovate 1.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9787daf4090dd5be74309c59268ea344.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Calicut">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NLP (Neuro Linguistics Programming)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e6979e6d4fc5aef4f658646f08de0238.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Safe Surfing-Cyber Security Awareness Program
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->WORKSHOP ON “IoT IN AUTOMATION”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RECENT TRENDS IN IT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->D.O.P.E FEST\&#39; 19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/30fbf0eed6a6243a938ecd6bd33ea3ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Sankara Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunities for Women Entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HACKATHON2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineeer\&#39;s Day celebration </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on \&#34; Career development
                and personality grooming\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on \&#34;Recent
                Innovations in Biotechnology\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->College to Corporate – C2C </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on \&#34;Patent Filing -
                Challenges and solutions\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ZENISTA 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill Training in Technology & Soft Skills
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e2efb24193f4ba3a2b9e5cb10c448a93.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AARUPADAI VEEDU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on \&#34;How to start a SSI
                Unit\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNO-BIZZ( Business plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> IETE ZONAL (SOUTH) SEMINAR AND ISF CONGRESS
                on “SMART ENGINEERING FOR SUSTAINABLE DEVELOPMENT\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sampark -The Business Connect</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Mela</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->NATIONAL LEVEL WORKSHOP ON IOT BASED EMBEDDED
                AND ROBOTIC SYSTEM DESIGN USING TEXAS PROCESSOR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/febb0ce721980b34399749e7ccd9e56c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI SAI RAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Story telling-Entrepreneurs life and cross
                road-motivational talk </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Workshop on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/644ccd9149885c675302f635c9e0c991.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROGRESSIVE EDUCATION SOCIETYS MODERN COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Story Telling Activity from Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/644ccd9149885c675302f635c9e0c991.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROGRESSIVE EDUCATION SOCIETYS MODERN COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ProStart</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/644ccd9149885c675302f635c9e0c991.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PROGRESSIVE EDUCATION SOCIETYS MODERN COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Empowerment by creating employability option
                to convert Waste to product (Charcoal)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Samrudhi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coffee Painting Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Candle</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/666da3a5e193db2eb0c399062d2443bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ALAGAPPA CHETTIAR GOVERNMENT COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “SIH & Meta Cognitive skills in
                Problem solving”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8c313b88777e07de2cf7039e5b70f64e.docx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PIMPRI CHINCHWAD COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovate for Smart Pimpri Chinchwad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> VISITING KRISHI VIGYAN KENDRA </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0b9ce7aa3beed7d53d67685305633641.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIMT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION WEEK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Orientation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Digital Marketing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Induction Programme on “Entrepreneurship and
                Career</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual property rights </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15b4ffffac4e4627f0fd8766503b0c0a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONERU LAKSHMAIAH EDUCATION FOUNDATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup Rendezvous\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit to Industry - Pharmacy Students
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0d5dcb71381806b3733e29f8dc6edaf5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ATHARVA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on “SMART  HACKATHON”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0d5dcb71381806b3733e29f8dc6edaf5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ATHARVA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Field Visit at “GODREJ”, Vikhroli</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0d5dcb71381806b3733e29f8dc6edaf5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ATHARVA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fundametals of Computer Vision: Certified by
                Nvedia</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->6th International Conference on Energy and
                City of Future organized by ECAM, France and MITADTU, Pune</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start-up opportunity in Biotechnology sector
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/827614456cf46063e615ab3bd493219b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MIT Art, Design and Technology University, Pune">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->6th International Conference on Energy and
                City of the Future, Joiintly organized by ECAM University, France and MITADT University, Pune </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation on Wheels: A road show by Festo
                Mobile Expotainer (Volvo)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Instilling Innovation among young minds</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A session on Awareness of Govt. Support for
                student\&#39;s startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Dollar Venture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a8a1f7ae2c8908a1c2a56b234997ea80.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IMS ENGINEERING COLLEGE, GHAZIABAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Spark_2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual property rights </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR and patents prospects and challenges </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/db16dc2abb3b4544410b1b1eaa0d5aa8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MEERUT INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAP Women Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7b65070480b0bd0a7d2fb2ed9df9a280.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Parvatibai Chowgule College of Arts and Science, Autonomous">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->faculty development program, conducted by the
                Goa state innovation council on “Innovation, creativity and startups” supported by Center for
                innovation, Incubation and Entrepreneurship(CIIE) at BITS Pi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/43d3acbefa4ae6ec42b07a33460ede2c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition of projects</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights Talk Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit to National Atmospheric
                Research Laboratory (NARL), Tirupati, Andhra Pradesh.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit To Amar Mission (Orphanage), Govt
                Registered: S/2l/943</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“The Science & Art of Omni channel Retail”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->THREE DAYS WORKSHOP ON AUTOMATION USING
                ARDUINO BY NOSF</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tie & Die</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAYS WORKSHOP ON R WEB DESIGN</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fdf692f3fe603faf5a8f197a3c607c5.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TWO DAYS WORKSHOP ON RASBERY Pi BY IEEE
                CHAPTER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fd75c49b22c340f41b4e38d29878b6b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARULA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3D STRUCTURAL MODELLING BY NARULA AND FEST
                SHAASTRA (IIT MADRAS)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop onDevelopment of IoT Based Device
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3ebe7c2c54cb607207ca759009e10fe0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOVERNMENT COLLEGE OF ENGINEERING NAGPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Firebird V Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organize one day workshop on Entrepreneurship
                and innovation as career opportunity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organize one day workshop on Entrepreneurship
                and innovation as career opportunity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEP (Technology Entreprenuer Program) on
                ewaste management</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Expert Interaction - Clinical
                research in Bioinformatics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit - About Pile foundation work in
                site and foundation marking using total station</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GREEN BUILDINGS & GREEN ENVIRONMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop On “DATA SCIENCE:A DEEP DIVE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3970ff2b56d1e6412bec525c4285fdd0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SINHGAD TECHNICAL EDUCATION SOCIETYS SINHGAD COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Right</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c36a8e1c08729c35a0accdadc346777.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AMRITA VISWA VIDYAPEETHAM">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->AI Smart Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introduction to LATEX</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Hands-on Training on Machine Learning
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0d5dcb71381806b3733e29f8dc6edaf5.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ATHARVA COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD - A Govt. Of India Inititative, PMMMNMTT
                Sponsored One Week FDP on “CYBER SECURITY”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->“ Innovation : Contemporary Perspective On
                innovations, Societal Harmony and Economic Growth</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcc920c4611baf988119fb496807b2b4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity Institute of Pharmacy">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sheros- Celebrating Power of Women
                Pharmacists on Wolrd Pharmacist day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/08e8f88a315499041b857bcb1fbe2ce7.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="YMCA UNIVERSITY OF SCIENCE & TECH (FORMERELY YMCA INSTITUTE OF ENGINEERING)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on innovative thinking and
                problem solving</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Synergy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0fa11d6fb3e0fe1b6a7cc831f1972f53.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SAHYADRI COLLEGE OF ENGINEERING & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hacktober Fest 2019 (Hack Night)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/338e39a9bd4d2bebef01ce93a28d79c2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="H.V.P.MANDALS COLLEGE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Vidarbha Security Summit – 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/506e5bad09096ea818bbe200b9be0ca2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="THAKUR INSTITUTE OF MANAGEMENT STUDIES, CAREER DEVELOPMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Synapse-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pitch Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/35e9f8817ffd8f86447b4e601fb53c33.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI VISHNU COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Devolopment of Communication, innovative and
                employbility skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a0c957dc0d26991aaf6313e0379c034a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PVKK Institute of Technology, Anantapur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guest Lecture on Advanced Trends in
                Communications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34a9ec311694c3d10a021e967a907217.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACADEMY OF MARITIME EDUCATION AND TRAINING DEEMED TO BE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical workshop on Intellectual property
                rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34a9ec311694c3d10a021e967a907217.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACADEMY OF MARITIME EDUCATION AND TRAINING DEEMED TO BE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Role of Surveillance robots in
                smart factories</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e37c01ee2b3adef18851a08e475767f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Company Failure Analysis</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b1c49128e69fdd393464b4fa17ed0a0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW DELHI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inter College E-Leader Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Insights 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6e18ccd65a03f9de1e0f2686ed0ae4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIN. L.N. WELINGKAR INSTITUTE OF MANAGEMENT DEVELOPMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Design Summit ‘19 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3 Day Entrepreneurship Awareness Camp (EAC)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Retail Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6a642a537bc950221447ba69197fca75.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Devki Devi Jain Memorial College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creative approaches to design process-Fashion
                Project</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6a642a537bc950221447ba69197fca75.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Devki Devi Jain Memorial College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c733984fbacb5850c48c632716fa9da.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S. B. PATIL COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SBPCOE Knowledge Fair</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5c7a2c6a64b80ea459ddf82c98e50179.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHENNAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineering Excellence Award - 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Khanan-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c733984fbacb5850c48c632716fa9da.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S. B. PATIL COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SBPCOE III Summit </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Student Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rally on Plastic Waste Free Campaign</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Academia Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mosquito Coil and Liquid Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Herbal Bath Soap Making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Day Workshop on Ethical Hacking & Cyber
                Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Future of Drones - The Prorotype
                Model Making and Live Streaming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ESSAY WRITING COMPETITION ON TECHNOLOGICAL
                INNOVATION IS KEY TO WORLDWIDE EVOLUTION AND PROSPERITY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Capacity Building workshop on Android App
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My story :The real Pad man </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/68365b33f700efa56df7edb4cec777d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BVRIT Hyderabad">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Android Masterclass</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Launch of student campus ventures </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MOU signing ceremony with FICCI FLO</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation exhibition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/443f935e300df9c6f094fa50fa8567f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VINAYAKA MISSIONS KIRUPANANDA VARIYAR ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->iPharmBuds Expo 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Ready Details for Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45f25efcd330ecb227d41576dac2ef06.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PODDAR MANAGEMENT AND TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Waste To Wow</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Craft Bazaar</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation quiz</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Basic crochet </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a24e7600358ae749541247c1ba5b38b9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GCRG MEMORIAL TRUSTS GROUP OF INSTITUTIONS FACULTY OF MANAGEMENT, LUCKNOW ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Dr.Kalam Startup Parikrama</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->visit the #IncubationCenter</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Action On Google</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Education & Industrialization: Two pillars of
                modern India </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d953efe167e048d2d79181a67916cb4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bharati Vidyapeeth (Deemed to be University) College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Build like Women: An Interactive session with
                aspiring Women Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC cell LOGO contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Science Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Relevance of Gandhian principles in
                today\&#39;s world</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise One Day Workshop On
                \&#34;Entrepreneurship and Innovation Career Opportunity\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2fb2d252f79feaca86bfc7af336bd549.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSG COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Organise One Day Workshop On
                \&#34;Entrepreneurship and Innovation Career Opportunity\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/213c77bb5ade18da97c3edca978db854.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SREE VIDYANIKETHAN ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness and Interactive Session on
                “Start-ups & Leadership”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STARTUPS IN WELDING TECHNOLOGY </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development program on
                Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Metrics, Statistical Tools and IPR
                for Quality Research</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7acd5e42da5acddf1dfd5daa185beb1d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Assam Royal Global University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Talk by Industrial Person</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Solar Ambassador Workshop on the
                150th Birth Anniversary of Mahatma Gandhi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/16c86da8b05e7f0a779ce0ee6f02296d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PTVAS INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture Series of Incubatees</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bb9771a9b6032b870a8da157a85b821d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF CHEMICAL TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurial Mindset and design Thinking
                for Growth</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Celebration of International year of Periodic
                table</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6ad02713048d84e68ae0cbdf88480b4d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Malaviya National Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Rajasthan Climate Launchpad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7dee5a7c65e17ea0e49f43e4f11fb9fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA UNIVERSITY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Sponsored Research IPR & Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/82403aded1ca91a2097c649d31623d51.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Thomas College ( Autonomous)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Festival</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6ad02713048d84e68ae0cbdf88480b4d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Malaviya National Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Interactive Session with Mr. Pratap Raju</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7dee5a7c65e17ea0e49f43e4f11fb9fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA UNIVERSITY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lectureRoyal charter day celebrations
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6ad02713048d84e68ae0cbdf88480b4d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Malaviya National Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PRODUCT MARKET FIT SESSION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/bdadbca85a616af4431daabdb01acfb0.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CAREER POINT UNIVERSITY, HAMIRPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Motivation Campaign and
                Entrepreneurship Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7dee5a7c65e17ea0e49f43e4f11fb9fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA UNIVERSITY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Induction Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f9b1254ef693dc4ba5055d7de4c404e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJALAKSHMI ENGINEERING COLLEGE (ENGINEERING & TECHNOLOGY)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Big data techniques and data aggregation
                statergies on wireless sensor networks</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD IIC Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PARTICIPATION IN HACKTECH -NATIONAL LEVEL
                HACKATHON AT REC BANDA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3b1c49128e69fdd393464b4fa17ed0a0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NEW DELHI INSTITUTE OF MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SAHYOG \&#34;CENT CONCEPT CHALLENGE\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Go Kart Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5099d814cfb13e72d79fdb83b5f4b080.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GNA University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->x-Kernel 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Intellectual Property
                Rights (IPR)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1714a444c6820026e26ba464019ef01c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GOKARAJU RANGARAJU INSTITUTE OF ENGINEERING & TECHNOLOGY(E&T)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech-Thon Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/965acc40303e14bef2fdc4ef0d64e207.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NADAR SARASWATHI COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Product Design</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to Village</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Challenges using IoT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Team Selection for IIC and E-Cell through
                General Enterprise Tendency Test</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a38b0b0be074f2e21728b74d041e37f2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ER. PERUMAL MANIMEKALAI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Session on IPR & IP Management for
                Innovation and Start-ups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ace9dd1e1ea47b9e9a7571f0a68e45a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAR BASELIOS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a38b0b0be074f2e21728b74d041e37f2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ER. PERUMAL MANIMEKALAI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEA Collection Camps</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a38b0b0be074f2e21728b74d041e37f2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ER. PERUMAL MANIMEKALAI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness program on the Impact of Innovation
                and Entrepreneurship for Socio-economic development </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0988d1f5f75855b1073ca50314f762cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SFS SANDIP INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Idea Generation and its Validation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0988d1f5f75855b1073ca50314f762cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SFS SANDIP INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Women Empowerment in Rural Area
                on \&#34;Idea Generation and its Presentation\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0988d1f5f75855b1073ca50314f762cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SFS SANDIP INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Recent Technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0988d1f5f75855b1073ca50314f762cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SFS SANDIP INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Innovation Opportunities for
                Rural Area</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0988d1f5f75855b1073ca50314f762cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SFS SANDIP INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program on MHRD Institution\&#39;s
                Innovation Council for upcoming innovators</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A One Day Seminar on “Innovation Startup’s
                and Entrepreneurship”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2655535931e8db9172082568f14715f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NETAJI SUBHAS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creating Innovation and Entrepreneurship
                Culture among Faculty and Staff</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Nutrition Innovation Programme: New
                approaches to tackle Malnutrition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness programme on Leprosy using
                Innovative Infographics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0988d1f5f75855b1073ca50314f762cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SFS SANDIP INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Half day workshop on Programs run by
                government i.e. Swayam Yojana </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SOFT SKILLS TRAINING PROGRAM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2655535931e8db9172082568f14715f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NETAJI SUBHAS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Idea Generation and Psychology of
                an Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A One Day Seminar on “PHARMA INNOVATIONS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0988d1f5f75855b1073ca50314f762cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SFS SANDIP INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PRADHAN MANTRI KAUSHAL VIKAS YOJANA (PMKVY)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2655535931e8db9172082568f14715f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NETAJI SUBHAS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creating Innovation and Entrepreneurship
                Culture among Students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0988d1f5f75855b1073ca50314f762cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SFS SANDIP INSTITUTE OF TECHNOLOGY AND RESEARCH CENTRE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Design Thinking and Critical
                Thinking using various resources</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation mentoring session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8a9b21fc85795b4b23a46a1c64ee6305.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NIRMALA COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A One Day Seminar on “Innovative Teaching
                Methodologies”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2655535931e8db9172082568f14715f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NETAJI SUBHAS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Gamut of EDC for market strategy for
                entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2655535931e8db9172082568f14715f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NETAJI SUBHAS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Idea Generation and Psychology of
                an Entrepreneur</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->poster making competition on Innovation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e411e62493c59451f9e222484e19c475.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHANDIGARH ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT Hands on Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Life Lesson Motivational Speak by Chacha
                chatore</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative web design workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Under 25 summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/de67a0b58594fabc24e6295805ffa26f.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BNM INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATIVE PROJECT LAB COMPETITIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7831363951a5b21d3572c37aa6f98e7c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University of Rajasthan">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovation and Entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cdfb5d61ba129de76030ddcd34ac483.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahendra Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Village visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cdfb5d61ba129de76030ddcd34ac483.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahendra Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk - Innovation in Science</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day Guest lecture on “EFFECTIVE
                COMMUNICATION SKILLS”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cdfb5d61ba129de76030ddcd34ac483.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahendra Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SEMINAR ON “USA -THE LAND OF OPPORTUNITIES?”
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cdfb5d61ba129de76030ddcd34ac483.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahendra Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field Visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6cdfb5d61ba129de76030ddcd34ac483.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mahendra Arts & Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk - Innovation in Science</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seed funding Assistance</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/821f017bfbe756f3b7acdc30cb37f85a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MALLA REDDY COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Elocution Competition on KILL CANCER</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights and Art of
                Thesis Writing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7903795e3886e45b406617d35d6d3dd8.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRIST SCHOOL OF ENGINEERING AND TECHNOLOGY THANJAVUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineers Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f51c87c01f922fd03c3cff0396b57d37.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR. VISHWANATH KARAD MIT WORLD PEACE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MITWPU Innovators Projects Exhibition 2019
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6df6fb29aaeae2c2b98663ce8137d212.pptx"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sri Ramakrishna College of Arts & Science for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Nature Camp @ Muthumalai Forest </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Strategies to conserve the biodiversity of
                Native Cattle Breed in India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47a50a95d9b1df73a8429840ff802269.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Bhartiya Skill Development University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Participation in Scaling Rajasthan Conclave
                2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ENNANGALUM VANNANGALUM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b3fd05609f8558cb2ad55a1e3c99439d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Outcome Based Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exhibition on Development of Smart Systems
                Prototype</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Hardware Creativity for Social
                Cause</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special lecture on Business Opportunities –
                Criteria of Selection & Sources of Information </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32e389e5558e5605b00c610a79ec112a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAHENDRA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovative Technologies on
                Embedded systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special lecture on Developing Entrepreneurial
                Competencies </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Intellectural Property Rights </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special lecture on Creativity and Innovation
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Future of Artificial Intelligence in
                Education</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness cum placement program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7595e957f2108e40c6f4a0cc84a7bfc3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UTTAR PRADESH TEXTILE TECHNOLOGY INSTITUTE KANPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Smart Textile Hackathon 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/88eee2588a036e94733afd082153d06e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.PETERS INSTITUTE OF HIGHER EDUCATION AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Village and Household Survey</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation talk about Industrial Robotics</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->EXCON 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#34;What Next?</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/44e770ba88acb0058171ca8b5856ad58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF ENGINEERING MANAGEMENT & RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness on Open Source</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a74b9113f8930d6d52d13faa009c230.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KINGS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Project Expo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff51f222c42257aef29779584113e64a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R. C. PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Generation and Validation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff51f222c42257aef29779584113e64a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R. C. PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Workshop on Business Ideation and
                Startup</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff51f222c42257aef29779584113e64a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R. C. PATEL INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Orientation Session</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8f9b1254ef693dc4ba5055d7de4c404e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJALAKSHMI ENGINEERING COLLEGE (ENGINEERING & TECHNOLOGY)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tech-A-Thon 2k19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Website Development and its Applications</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Seminar on \&#34;Construction Material\&#34;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c813d96addf8d16181ccf3e471f5975.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNEL INSTITUTE OF TECHNOLOGY AND DESIGN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Bootcamp on Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0c7cb778ce1eb0b904685c20da569b5d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B.P. Poddar Institute of Management and Technology 115">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Competition on Business Plan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7dee5a7c65e17ea0e49f43e4f11fb9fc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA UNIVERSITY COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essentials of Python Programming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6ee9432ba761e72ad3c6c08e2e91c5a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Rajiv Gandhi College of Engineering and Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->India First Leadership Talk Series</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fcbfeecc28aedf249a4510c8460be42.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PANIMALAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PATENT AWARENESS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Software Design Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Igniting young minds towards successful
                entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Blood donation camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Fight Against Alzheimer\&#39;s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ce565103af959c8b79519d539ea6ae79.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KMEA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->idea competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill development:HYDROPONICS /organic
                farming</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest lecture on Entrepreneurship by
                Mr.Pratik Hinduja from ISME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skill development in terrarium making/ dish
                garden</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1d6324c9a33051da7f99f38332bea023.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.D. & S. H. National College of Arts and Commerce and S.W.A Science College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Skilldevelopment workshop on herbal cosmetics
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tribute to Dr. Vikram Sarabhai</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial training at CIPET, Amritsar,
                Punjab</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/343ec23f4872e12e5b0aab516067599c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Kanya Maha Vidyalaya Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial visit at Gujarat Borosil Ltd.,
                Bharuch Gujarat</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Tree Plantation Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Product Development and
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dc3f3ef3dabb1a25f39358c790ccd3a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sikkim Manipal Institute of Technology (SMIT)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Kalam Drishti</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d043cfe660a20441e3c2f8ca1a06683.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURU NANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Creative Art and Craft</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Take-Off Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed74e76bb295af7e8d9bfc42c0bd502e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr Babasaheb Ambedkar Marathwada University ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan : How to get it Right</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed74e76bb295af7e8d9bfc42c0bd502e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr Babasaheb Ambedkar Marathwada University ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opprotunity in Social Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed74e76bb295af7e8d9bfc42c0bd502e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr Babasaheb Ambedkar Marathwada University ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Asserative Communication Skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9788b97016f35546e1c36502504dc924.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA INSTITUTE OF PROFESSIONAL MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Demonstration of PoC\&#39;s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed74e76bb295af7e8d9bfc42c0bd502e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr Babasaheb Ambedkar Marathwada University ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Importance of IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9fffa50a738de5742c1eb7efe50b6def.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANANDHA COLLEGE OF ENGINEERING FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MyIdea Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNOTHON 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon-ACM Hour of Code</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fcc231edb2101703338bea5dd3af355c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Challenges in Gender Equality & Women
                Empowerment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role of IPR </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training at Siemen\&#39;s centre of exellence
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b04bc42d8ad6be165f74acbddcbc44d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two day workshop on “Python for Beginners;
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IUCEE-Artificial Intelligence Program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RMKEC Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f3990707b6c5b4567778cb9dd2ec9847.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS ACADEMY OF TECHNICAL EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Start Up Charcha - Ojaswini</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Boot Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/01b2b1ecc9b2c061c1b03d8717f7d52a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARPAGAM ACADEMY OF HIGHER EDUCATION">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PITCH YOUR IDEA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Preparation on Government Exams & Gate</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Unlock Data Science using R</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IoT Based Speed Control of Motor & Puzzle of
                Motivation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Eco Friendly Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Django web development with Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyber security and Blockchain</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/72c40df1e55104cbd4641314a9ef5079.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Journey of Cloud to Mobile APP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c93e6ca2e9baa6b69e84958bfb2dc3e7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KAZIRANGA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Recent Challenges in Smart Grid</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Photography Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ICON2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEATHON </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineer\&#39;s Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3273b674c31e91ab98f48fac7b38a7a4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Acropolis Faculty of Management & Research, Manglia Chouraha, Indore">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring Mela </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ICARE-2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SIEMENS-Ideathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Essence</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->VIT Hack</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation Program On Innovative Hardware
                Prototypes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dad645a010cc37cc0f6e7f5ac1dbc93b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="REVA UNIVERSITY, BANGALORE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d1ebb0ca8e804ed4bff191ce6a89e570.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DR.MAHALINGAM COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNOFETE 2019-Debate on IC Engine vs
                Electrical Vehicles</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ff0a1148a500da8408471fdae463fc09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vignan Pharmacy College, Vadlamudi Post, Chebrolu Mandalam, PIN- 522 213(CC-AB)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR & PATENTS- ROLE IN RESEARCH AND
                DEVELOPMENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mind Your Business: Startup Ideation Contest
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c1a0665aa9173e2e93438a071fe9ea94.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->\&#39;Feed the future India - Linking of
                Farmers to Market\&#39; By Mr.Yogesh Thorat, MD, MahaFPC</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/10949f76de640d28b374ddcdf917bcc2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Maharaja Agrasen Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop Version Control Systems</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->my start up story</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c1a0665aa9173e2e93438a071fe9ea94.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITYA INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->3D model & Prototype Activity By Prof.
                Sulekha Munshi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Artyificial
                Intelligence and Big data</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Paper presentation competition for
                Innovative ideas</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4b98715e60c1fa0b5a4e82bce32ce732.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ARYA COLLEGE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Three Day Workshop on 3D Modelling and
                Addictive Rapid Prototype</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34f48da2077e9aaa9c06b447d76b43b2.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="STANLEY  COLLEGE OF ENGINEERING AND TECHNOLOGY FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Research Articles Writing and Publication</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/10949f76de640d28b374ddcdf917bcc2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Maharaja Agrasen Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOT using Python & Rpi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ce4c9ed402b238f42b50024f71c08eb.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jaypee Institute of Information Technology, Noida">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR, Patents & Innovations</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIELD VISIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FREELANCING AND ALTERNATIVE ENTREPRENEURIAL
                CARRIER OPTIONS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/667d0f5d26e016545310232a7f3119ba.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INSTITUTE OF ENGINEERING & TECHNOLOGY,AGRA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FRESHMEN ORIENTATION PROGRAMME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ed74e76bb295af7e8d9bfc42c0bd502e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dr Babasaheb Ambedkar Marathwada University ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR with MAGIC,CMIA, Aurangabad
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c802a9ba5fd5f578b37baa0fd78bcbdd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SJB INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation on smart city project</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guest Lecture on Big Data and Data Science
                by Mr.Suresh Gilkampalli,TCS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on Power Management of Solar PV
                Powered Micro Grid on 3rd August,2019 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ecofriendly Product Demo</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/99e0f20f270775c14bc59fb327c176e9.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S R ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intinta Innovator</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f833d37f61c575ab777d7c31d6209c13.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JB INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideation Workshop on Game Development using
                Unity Game Engine</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Guest Lecture on Supervisory Control & Data
                Acquisition on 5th July,2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Week Work Shop On Industrial Automation &
                Entrepreneurship</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/708c1345abd2327de1cc54d72f393bb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DON BOSCO COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Opportunity Japan</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on VLSI Front end & Back end
                Design Flow by N.Sivaji Sayta Ganesh, Veda IIT, Amaravathi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on VLSI chip
                Design using Open Source EDA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TOTAL QUQLITY MANAGEMENT AND INDUSTRY 4.0</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9a3213d4e2253d96408f90c35a797b7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY ANANTAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Visit to AIC-SKU</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My story - innovator\&#39;s life and
                crossroad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IUCEE-Student Leadership Course</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fa79856fd91a037922aee6376dfc02f2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SNS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Design Thinking Leadership Boot Camp for
                Facult</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4567b73899535dfc3ac04f601b7d7c7a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ANIL NEERUKONDA INSTITUTE OF TECHNOLOGY & SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ANITS IIC Logo Design Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on AUTO DESK AUTO
                CAD by ICT Academy from 10th to 16th July,2019 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on WLANs & Cyber Security by
                Dr.HL Guru Raj, Vidya Vardhaka College of Engineering, Mysore, India </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Speak - Talk by Padmashree Dr. S
                Natrajan, Chairman Aditya Jyoy Foundation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8303b3879aba5ad16c00d1d0d3002239.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF MANAGEMENT STUDIES AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7a46490aa92c9ea820b294fe1cd0ff7c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Avanthi Institute of Engineering Technology, Chirukupally(V), Bhogapuram(M), Near Tagarapuvalasa Bri">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Special Lecture on “Guidelines on
                Presentation Skills”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Presentation Contest on “Technology for
                Rural Development”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/10949f76de640d28b374ddcdf917bcc2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Maharaja Agrasen Institute of Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Energy, Environment and Sustainability</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9aebba97e978f1d54d841b0350076103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KRISTU JAYANTI COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-Store</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2e2d841b33142f28c7ea71e586365df8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ENTREPRENEURSHIP DEVELOPMENT INSTITUTE OF INDIA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Women Power 2019 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Plan de Negocio Business Plan Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Innovation - Deep Learning using
                Python</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Lecture on “Entrepreneurship and its
                Significance\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Seminar on “Employability & Entrepreneurial
                Skills – Need of an Hour”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->field visit to industry</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9777780bf66c883ad6afd0e6d0742eb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Manipal Academy of Higher Education">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pharmaceutics Idea Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d53ad57c942b8fc659696f0f36b3ec80.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MANAKULA VINAYAGAR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on “Professional Skills
                Development – Born to Win” </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f833d37f61c575ab777d7c31d6209c13.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JB INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Motivational Speak on Entrepreneur\&#39;s
                Life & Cross Road</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISB Interaction with SPOCs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ISB Hackthon day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3d7129e549305946d1965c02b4b7da35.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRATHYUSHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TEACH GIRLS BRAVERY WITH PERFECTION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/34b5c4dab70d6d48356bc5f2d03d0411.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRINCE SHRI VENKATESHWARA PADMAVATHY ENGINERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEA COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b54d16cbd7b82be9d681015ac4689495.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST.JOSEPHS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cyberthon 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/361704967b029d1a0b8c14d32e6412bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University of Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Course on \&#34;Visual Art and
                Aesthetics\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/361704967b029d1a0b8c14d32e6412bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University of Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Course on \&#34;Business, Entrepreneurship
                and Innovation Management\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/361704967b029d1a0b8c14d32e6412bc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="University of Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Poster Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FOUR HAND ASSEMBLING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->e-Poster (on-Line) Making Compition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Structural Design on STAAD pro. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Guest Lecture on IPR</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Web Designing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Business Plan Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Python Coding Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Talent search exam </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Treasure Hunt Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Summit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Lecture series 5
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Lecture series 4
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Lecture series 3
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Discussion on Union Budget 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Lecture series 2
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Paper Presentation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Lecture series 1
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b6d380de5f6eb0162cfb8d94e80e384.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.S.RANGASAMY COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Lecture series 1
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Memory Bingo Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1c22634e1556e734a0fbdafd0da92f17.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KONGU ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->STARTUP MANIA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A 3-Days hands on Workshop on Lab view in
                Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LOGO Quiz Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Activities 2019-20</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/32511849e978e1d10bca8b0d213273a2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Mehr Chand Mahajan D.A.V. College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Intellectual Property Rights Awareness
                Program for Women Institutes</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CII-MSME Investors Meet</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63fc9d91e3f11e8f04a88836ef94b3e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. MARY&#39;S  COLLEGE, THRISSUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->plant a tree challenge</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOT Based Design Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ade8162b307d2c3fa91ff9a7c4b6c2ba.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDIAN INSTITUTE OF TECHNOLOGY (INDIAN SCHOOL OF MINES), DHANBAD">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Businesses in Innovation Era- leveraging
                Intellectual Property System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/ee3437c4adf7834290e5a79f197750df.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G. H. RAISONI COLLEGE OF ENGINEERING, NAGPUR.">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Blind Race Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Entrepreneur\&#39;s Day-Chat with
                Mr.Mohan Reddy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1eb8df82f66fbfedb0c0bedbb4c342f1.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHAITANYA BHARATHI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day work Entrepreneurship and Innovation
                as Career Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/889e888c7a6b9fa2a825cdf3d138d1c6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BIRLA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two day Bodytalk Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/889e888c7a6b9fa2a825cdf3d138d1c6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BIRLA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Internet of Things BOOTCAMP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/62a1d3934ce498cd90decfc4628ec658.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="WOMEN'S CHRISTIAN COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Creative Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> ENTREPRENEURSHIP IDEA GENERATION ON FOOD
                PROCESSING UNIT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘Design Thinking & Lithe Canvas ’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program for Freshers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b4a06f9268fc6472cb58eee33a535a2b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Vasireddy Venkatadri Institute of Technology, Nambur (V), Pedakakani(M), PIN-522508(CC-BQ)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->World Entreprenuers Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6e64722d43fd3d82ab6d6d750563424a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KG College of Arts and Science">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECH EXPO 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Electrofusion</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innowah - Innovation Challenge Competition
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/45634b4f214238a9e939f12f41dd471c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KITS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Ideation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9a3213d4e2253d96408f90c35a797b7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY ANANTAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One-Day Workshop on Startups</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/a9a3213d4e2253d96408f90c35a797b7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAWAHARLAL NEHRU TECHNOLOGICAL UNIVERSITY ANANTAPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two-Day Workshop on Entrepreneurship and
                Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/889e888c7a6b9fa2a825cdf3d138d1c6.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BIRLA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on \&#34;Intellectual
                Property Rights\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Startup Creation by Edutech
                Innovation Labs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Boot camp on Machine Learning </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Faculty Development Program on Java by Oracle
                Academy</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Envent </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->D.I.S.R.U.P.T – Idea Triggering Game</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e1bc6a8eead0c2bf3e65a37c68d0156b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MLR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Makerspace Accelerator Program on Artificial
                Intelligence and Machine Learning </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/43d3acbefa4ae6ec42b07a33460ede2c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7595e957f2108e40c6f4a0cc84a7bfc3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UTTAR PRADESH TEXTILE TECHNOLOGY INSTITUTE KANPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story - Innovator’s Life & Crossroad</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineers Day - Ideas, Skills,Success</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Parichay </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/70b1e4778caddc7631f16567019eaa2d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NARASARAOPETA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Role Intellectual Property Rights in
                Innovation Management and Academia Industry Collaboration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day student Development program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8e37c01ee2b3adef18851a08e475767f.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING, PUNE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Budgeting Activity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c457a1cb44e91460a1f383d00c57e4d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS SCIENCE AND TECHNOLOGY UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Know Your Problem (KYP) 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8c7cf896871eb0afa4ffe1c52b974691.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LAKSHMI NARAIN COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->one day industry visit</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Paper bag making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d56be974ba2ef2ced69d4743d75bbcac.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Anjalai Ammal Mahalingam Engineering College">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->LED bulb making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9777780bf66c883ad6afd0e6d0742eb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Manipal Academy of Higher Education">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KMC Mangaluru Idea Competition Slide</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9777780bf66c883ad6afd0e6d0742eb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Manipal Academy of Higher Education">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->KMC Manipal Idea Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ADX - 201 Workshop by ICTACT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f9460dd79877bbc71703cdf49d7cfaa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VVM's Shree Damodar College of Commerce & Economics">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Effective Business Report Writing</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7f9460dd79877bbc71703cdf49d7cfaa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VVM's Shree Damodar College of Commerce & Economics">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Lecture series by entrepreneurs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/366adc9426f7d0715e2d32e9fe136de9.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IPS ACADEMY, INSTITUTE OF ENGINEERING & SCIENCE, INDORE (MP)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Enterpernanumship Awarness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/51cf0d1d8a6b3bae1feec835567ad1cc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INDERPRASTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Design Thinking</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8bd87cc6d79afb8e6eba18df00f7beca.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GULZAR GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation & Launch of ROBOशाला</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/63fc9d91e3f11e8f04a88836ef94b3e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. MARY&#39;S  COLLEGE, THRISSUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E - waste Upgradation Competition &
                Exhibition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e9bbc1ee09536d9ca11589b378108353.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Gayatri Vidya Parishad College of Engineering for Women, Madhurawada, PIN-530048(CC-JG)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation, Intellectual Property Rights, and
                Startup Formulation by Young Women Engineers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7595e957f2108e40c6f4a0cc84a7bfc3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UTTAR PRADESH TEXTILE TECHNOLOGY INSTITUTE KANPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story - Entrepreneur’s Life & Crossroad
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7595e957f2108e40c6f4a0cc84a7bfc3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UTTAR PRADESH TEXTILE TECHNOLOGY INSTITUTE KANPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship and Innovation as Career
                Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7595e957f2108e40c6f4a0cc84a7bfc3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UTTAR PRADESH TEXTILE TECHNOLOGY INSTITUTE KANPUR">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field/Exposure Visit to Village/Society
                /School/Industry/Market – Identity real Life Problem</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a9d3dbeaf35844501e9da60acee859a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SILICON INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a9d3dbeaf35844501e9da60acee859a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SILICON INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Induction Programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Movie Screening</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1a9d3dbeaf35844501e9da60acee859a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SILICON INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Awareness Camp</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c26d62490e8e8661e6340e3928e433ed.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="INTERNATIONAL SCHOOL OF INFORMATICS & MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d03b49c16a000e8e4f44105fe280466.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="L. M. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pharma and Healthcare Pre-Accelerator
                Program.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d03b49c16a000e8e4f44105fe280466.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="L. M. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pharma and Healthcare Pre-Accelerator
                Program.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d03b49c16a000e8e4f44105fe280466.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="L. M. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Pharma and Healthcare Pre-Accelerator
                Program.</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6fa6867dfd4bf4cdead28f2dbc5165cd.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CHANDIGARH COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Product Design (I.P.D) Projects
                Exhibition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Coffee With Entrepreneur (My Story -
                Entrepreneur’s Life & Crossroad – Motivational Speak - To be Share by Entrepreneurs)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d03b49c16a000e8e4f44105fe280466.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="L. M. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup and Investments opportunities in
                Pharma and Healthcare - Panel discussion/Roundtable</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d03b49c16a000e8e4f44105fe280466.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="L. M. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Conference on Technology IP and
                Innovation for Building New India, Bhavnagar, Gujarat</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d03b49c16a000e8e4f44105fe280466.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="L. M. COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Startup and Investments opportunities in
                Pharma and Healthcare - Panel discussion/Roundtable</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ace9dd1e1ea47b9e9a7571f0a68e45a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAR BASELIOS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Raspberry Pi</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2ace9dd1e1ea47b9e9a7571f0a68e45a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MAR BASELIOS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->The Maker Station - 3D Printing Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/043b5e6d2f7ec536ed42ec81da4a863e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p
                title="Indore Institute of Science & Technology,  Village Dehri, Opp IIM, Pithampur Road, Indore  453 331">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Introductory session on Global Volunteer and
                Global Entrepreneur program by M.S. Vividha Patni, AISEC indore</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Opportunities in Networks and
                Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c78a22b870bbb2555e8ef62b957eb4a3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SONA COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ecell Leaders workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->techblitz 2k19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->technofilia\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->RYLA</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9760ba394c51f0a0b9b65c2878d83d6a.pdf"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIDYA PRASARAK MANDALS POLYTECHNIC, THANE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My story -Entrepreneur\&#39;s life& cross
                roads</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->B4- Battle between the bulls and bears</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Five day Business Plan Workshop for MBA
                Students under Skill Progression through Rapid Intensive and Innovative Training (SPRINT)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Rakhi making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on “Plant Secondary Metabolites and
                Application of Nano biotechnology”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Work Shop on \&#34; Remote Sensing
                and GIS\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/87b2815d17eabfc8edf5504da4c063aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KKR & KSR INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> Guest Lecture on Mesh Free Methods in Finite
                Element Methods</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Rakhi making</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f833d37f61c575ab777d7c31d6209c13.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JB INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->InnovateToINSPIRE</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative ideas presentation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c606e66052907105aaefb3f0f03fda33.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ADITHYA INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->PALS ANALYZE EVENT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9a74b9113f8930d6d52d13faa009c230.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KINGS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Ethical Hacking and Cyber
                Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/12c9d6603474fc6b2eaed0158b045616.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="EXCEL ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industrial Explorer</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story - Entrepreneur’s Life & Crossroad –
                Motivational Speak - Talk by Padmashree Dr. S Natrajan, Chairman Aditya Jyoy Foundation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e4639ad2422eacc572ae8fabb2230b8a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jagannath International Management School, Vasant Kunj, New Delhi">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni Start Up Promotion </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0095ba7bb5bfb068a24d8f31e26f1094.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SUPREME KNOWLEDGE FOUNDATION GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Finishng School Program on Cyber Security</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on: Machine Learning and Healthcare
                by Dr. Flechure, MIT labs</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MoU signing for VESIT-ADITYA Jyot Eye
                innovation cell</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/46571fd4110447ef5aeafbac01095f67.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VASAVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->2 Day E Leaders\&#39; Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/46571fd4110447ef5aeafbac01095f67.gif"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VASAVI COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Orientation - Entrepreneurship Development
                Cell</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/abe5d949fec40d9d761082b6fa652c6d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PSGR KRISHNAMMAL COLLEGE FOR WOMEN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Social Networking Advantages and Benefits</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An eight hour AI hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Synergy Hackathon (Campus) 1 day (12 hours)
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Strategies for Filing Copyright</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->POC Mentoring and ARIIA 2020/IIC 2.0
                Orientation </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->GUEST LECTURE ON AN INSIGHT INTO
                ENTREPRENEURSHIP </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNICAL EXPO 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNICAL EXPO 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/22e95ee52796ee0b5da65b3bab79fd58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAPATLA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->A Two Day Seminar on Entrepreneurship
                Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7efcada2a91fe36311d133e4f95338d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING & TECHNOLOGY, IILM ACADEMY OF HIGHER LEARNING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovate with IILM Engineering</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0095ba7bb5bfb068a24d8f31e26f1094.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SUPREME KNOWLEDGE FOUNDATION GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My story-Entrepreneur\&#39;s life and
                crossroad- Motivational talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Induction: Odyssey 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Mentoring Mela </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/66787dd6b5ce9808fb86b8b966e9cd4a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ACROPOLIS INSTITUTE OF TECHNOLOGY AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Human Library - Talking Books </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40d13e359a0d710cfb11750a69a015d0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="B V V SANGHAS BASAVESHWAR ENGINEERING COLLEGE BAGALKOT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ESTEP BOOT CAMP</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/39a584f8cf9ae2f4faf5b8916967556e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jayoti Vidyapeeth Women's University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Demonstration on innovative ideas for soil
                health to farmers</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/39a584f8cf9ae2f4faf5b8916967556e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jayoti Vidyapeeth Women's University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Health Awerness & checkup Program-Health
                Camp(Any Innovative Treatment & medical preparation )</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/39a584f8cf9ae2f4faf5b8916967556e.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jayoti Vidyapeeth Women's University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Conncet With Community to identify problems
                -To identify the issues and challenges of daily life of nearby villages </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->i-Next Contest( idea pitching for students
                and startups)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/50181aee399baffbe4c38c68c0d85a4c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ABES INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Future of technology</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f833d37f61c575ab777d7c31d6209c13.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JB INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INSPIRE\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Ideathon 2019 - </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/cd1e91bd3f32dcce686634dc810de358.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VIVEKANAND EDUCATION SOCIETYS INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership talk: Innovation for Resurgent
                India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f833d37f61c575ab777d7c31d6209c13.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JB INSTITUTE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Emerging Trends in Power & Energy-A Research
                perspective</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da2d43c737186f6684089e3f066bdaeb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TEST INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->test</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SLIPPER SHOTS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHIE TALK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c813d96addf8d16181ccf3e471f5975.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="AGNEL INSTITUTE OF TECHNOLOGY AND DESIGN">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->E-swachta</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/56166be113507911ef00fcf6d8808edf.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PERIYAR MANIAMMAI INSTITUTE OF SCIENCE AND TECHNOLOGY PMIST">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MHRD - IIC Leadership talk with Hon\'ble HRD
                minister</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HITSATHON - Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop cum Training programme on
                Intellectual Property Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Green Revolution (Action against Climate
                Change 2019)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1fd30388ef82e700fa6d3c51a7d73d10.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GURUNANAK INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Walk to Save Water</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b7efcada2a91fe36311d133e4f95338d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COLLEGE OF ENGINEERING & TECHNOLOGY, IILM ACADEMY OF HIGHER LEARNING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Discuss a Business Idea</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/006af3e61d86087b249d5dd8adb1c1e4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="R.M.K. ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNICAL EXPO 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2508b80ccd72f21347f5f515019b3e12.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Jerusalem College of Engineering">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Council Activity </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Alumni \&#34;Face to Face\&#34; Open Your
                Eyes for Innovation & Entrepreneurship </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovations Day</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Machine learning and artificial Intelligence
                followed by 24Hrs Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e49021f8cf566107f528f682498d9c1e.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELAGAPUDI RAMAKRISHNA SIDDHARTHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IOT applications and advanced </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/60112eaec49b2818f82e922ab8d223f4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI VENKATESWARA COLLEGE OF ENGINEERING, Chennai">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two-days Boot camp on Social Entrepreneurship
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship and Innovation as Career
                Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/47dbe5e3bad0a4243e3b5d23ffda7491.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IES COLLEGE OF TECHNOLOGY, BHOPAL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day problem solving/design
                thinking/ideation workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on \&#34;IPR, Startup
                Challenges and Innovation with special reference to Women Entrepreneurs\&#34;</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/4a6c5d02d97e51f2e97289207af35aef.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SULTAN-UL-ULOOM COLLEGE OF PHARMACY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Healthy Mind Healthy India</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/76391dc749e9fc4816afedcf9f0e2f87.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="National Institute of Technology Kurukshetra ">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Techspardha\&#39;19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74766b18eb2b8572c2c33f51cd4bcfe1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="K.J. SOMAIYA INSTITUTE OF ENGINEERING & INFORMATION TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Renaissance 2k19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->How to start a start up -Case studies and
                Activities</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b343fd8f2aca870b555c963a3bb803dd.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SANT LONGOWAL INSTITUTE OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION WALK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->We can take a punch ( Basic Introduction)</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->‘Problem Solving with Design Thinking’</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/15677d3521944207a2d5e1cdda29e2b4.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JAGANNATH INTERNATIONAL MANAGEMENT SCHOOL">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MEET THE INCUBATEES</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IDEA COMPETITION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/f9030c0f6781b36e01a7fffd457fe727.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RAJ KUMAR GOEL INSTITUE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->FIRST EPISODE OF “INDIA FIRST LEADERSHIP TALK
                SERIES”</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Dollar Venture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c6782f6f0019f524d48628a7cd91a5e8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shree Guru Gobind Singh Tricentenary University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Inspirational Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HARDWARE AND NETWORKING</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">--> WORKSHOP ON ENTREPRENEURSHIP CREATIVE
                BUSINESS IDEAS </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcf9dceb9082d54ba909c6964cf29628.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="DEVINENI VENKATA RAMANA & DR.HIMA SEKHAR MIC COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHNOMIC 2K19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/fc98e82d591d1311e7a85b577118845a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Sant Baba Bhag Singh University, Jalandhar">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Expert lecture</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Engineers Day Celebration</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0f5e20cb08c4e6dd31ebeec7dbd156e3.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Uttar Pradesh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IPR Awareness programme</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/843a94ec00704af160dd47cf29453c4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FR. CONCEICAO RODRIGUES COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea validation, Business Opportunity
                Identification and Business Model Development</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d35c0f0a19a5e616818cf923600c0153.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ELITTE INSTITUTE OF ENGINEERING AND MANAGEMENT (POLYTECHNIC) NORTH 24 PARGANAS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Exposure Visit to a Village </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1e62c39eae6c0af5fac374a62abb9562.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KARUNYA INSTITUTE OF TECHNOLOGY AND SCIENCES">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Web Master 2019 </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c457a1cb44e91460a1f383d00c57e4d2.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="JSS SCIENCE AND TECHNOLOGY UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC 2 Recruitment Drive</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0095ba7bb5bfb068a24d8f31e26f1094.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SUPREME KNOWLEDGE FOUNDATION GROUP OF INSTITUTIONS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship and Innovation as Carrier
                Option</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Assessing the impact of biofertilizer
                application on scented rice grain quality: hands on training program</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on training on natural fiber quality
                assessment</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7ff12f23bf69a756870a69db8332777d.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TRIPURA UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative approach for pollution
                minimization during Natural Fiber Processing: hands on workshop </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/761f905cfcaf655c7d7b521572a2cbdf.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="ST. JOSEPHS COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Empresario 2k19</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/d042cb1ee3b96f1498eb7522f48c69dc.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SIES GRADUATE SCHOOL OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story - Entrepreneur’s Life & Crossroad –
                Motivational Speak</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hands on Training in IOTRONICS Project Design
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/992ef7206b5c5afdadc1a4688b732349.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="RATHINAM TECHNICAL CAMPUS">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->National Level workshop on MATLAB</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8c7cf896871eb0afa4ffe1c52b974691.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="LAKSHMI NARAIN COLLEGE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->workshop on entrepreneurship and innovation
                as career opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/74d29221da76d1967e3ad9a504dadc3c.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="IIS (Deemed to be University), Jaipur">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on Personality Development and Soft
                skills</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1209b15c9100a9c9cb3ab263649853f7.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity University Chhattisgarh">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Days Hands on Training on Design and
                Analysis of Mechanisms </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e0a892472ca1c704554d2d83a723c85c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="PRESTIGE INSTITUTE OF INSTITUTE OF MANAGEMENT AND RESEARCH">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MY STORY, INNOVATORS LIFE AND CROSSROAD -
                MOTIVATIONAL SPEAK</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/22e95ee52796ee0b5da65b3bab79fd58.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BAPATLA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Idea Competition for Mechanical
                Engineering Students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SPEECH ON CHARACTER BUILDING & SENSE OF
                RESPONSIBILITY</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6d9645f98c170db2c3becda49f9c204a.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI AKILANDESWARI WOMEN'S COLLEGE - WANDIWASH TINDIVANAM HIGHWAY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Training Programme for Herbal Napkin Making
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9788b97016f35546e1c36502504dc924.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SHRI SHANKARACHARYA INSTITUTE OF PROFESSIONAL MANAGEMENT & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->An Expert Talk on Intellectual Property
                Rights</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2dbe5459b13b0cb65042fa4eaa155a4b.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="E.G.S. PILLAY ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation & Entrepreneurship Opportunities
                on the Themes of Sustainable Development Goals</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7fadf3a22e78066931d70530a914eb87.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="FACULTY OF TECHNOLOGY & ENGINEERING, THE MAHARAJA SAYAJIRAO UNIVERSITY OF BARODA">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story - Entrepreneur’s Life & Crossroad –
                Motivational Session </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Field visit for Problem identification</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Awareness Program for the budding Engineers
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on 3D Printing and MIT
                Scratch Programming for school students</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Two Day Workshop on entrepreneurship and
                innovation as career opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on IPR </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b74fed57208dd3028992e8e8017feb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship 101 - Phase I</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8b74fed57208dd3028992e8e8017feb4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="GANPAT UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SUPPORT FOR ENTREPRENEURIAL & MANAGERIAL
                DEVELOPMENT OF MSME THROUGH INCUBATORS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneur Experience and Expertise</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/b198513955cbcd3ed5bc4493eed07a9a.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA COLLEGE OF ARTS AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IOT</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bafb2e3adde88c7f71c05fa6e061782.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="NANDHA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Diagnosis & Troubleshooting of Automotive
                System</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f394621a01b3aad372deeed9ac1af96.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SCMS SCHOOL OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Pitching Competition </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/e8276001a46e9169a2aedba891d61879.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="MOHANDAS COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One Day Workshop on Creative Thinking -
                \&#39;Out Of Syllabus 3.0\&#39;. </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Global Talent Hunt for MS Aspirants</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->DST & Texas Instruments India Innovation
                Challenge Design Contest 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f394621a01b3aad372deeed9ac1af96.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SCMS SCHOOL OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story -Innovator’s Life &
                Crossroad-Motivational Speak -To be Share by Innovators </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1f394621a01b3aad372deeed9ac1af96.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SCMS SCHOOL OF ENGINEERING & TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship and Innovation as Career
                Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/2bc3f564af44eebbd5434b8ccb3c199d.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="UNIVERSITY COLLEGE OF ENGINEERING AND TECHNOLOGY (UCET)">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->five days workshop on nurturing
                start-up/employability and IPR skills within budding engineers </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/613e96166052b0e9082d1d41c6dcd8f3.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Dhote Bandhu Science College, Gondia">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovation Parv 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/40c84587705c2758005952d7c9354224.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="St. Pious X Degree & PG College for Women">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Innovative Teaching Learning Program with
                Live Experiments</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/9aebba97e978f1d54d841b0350076103.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KRISTU JAYANTI COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Idea Cluster POD\&#39;s</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/0306da1cf1f0cd80bcad1f4a1aef88d8.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI RAMAKRISHNA ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Workshop on IPR and Innovation</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Seminar on Scope of startup projects through
                MSME</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcc920c4611baf988119fb496807b2b4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity Institute of Pharmacy">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Cell Streaking and Platting Workshop</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/dcc920c4611baf988119fb496807b2b4.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Amity Institute of Pharmacy">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Great Innovation Challenge 2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/8d8ef626fa022788b5717752a5211c09.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SASTRA DEEMED UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Catch Them Young - Entrepreneurship for STEM
                students </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/1dca480cf161261de85c08dd5d993ae1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="KUPPAM ENGINEERING COLLEGE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->INNOVATION & ENTREPRENUERSHIP AWARENESS
                PROGRAM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CREATIVITY AND ENTREPRENEURIAL INNOVATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SPINNING GEARS</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CIVIL ENGINEERING ON FIELD</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/73e28ca5d247937f8d42f7e5f3bbeea1.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="CMR INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Srushti2019</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->MAYVI 2K19 - Make Your Vimana</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IIC Logo Design Competition</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7d359ffcfb2c3eebfda77fbbd4673a68.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="VELLORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Academic Conclave</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/43d3acbefa4ae6ec42b07a33460ede2c.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Indian Institute of Information Technology">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/caf22adf9e1deb56259a203f3af41efe.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SETHU INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Student Design Contest</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->One day workshop on Entrepreneurship and
                Innovation as Career Opportunity</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->CLOUD COMPUTING & PROGRAMMING WITH CLOUDISM
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->SSIP AWARENESS PROGRAM AND INDUSTRY 4.0 SMART
                MANUFACTURING SYSTEM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->THINKING OUT OF THE BOX AND INNOVATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ADVANCES IN CIVIL ENGINEERING: POST
                TENSIONING SYSTEM</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/978e7c73f87f02e749bf1d467351c3dc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BHAILALBHAI & BHIKHABHAI INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->IMPLEMENTATION OF INTERNET OF THINGS FOR
                STUDENTS STARTUP & INNOVATION</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/c4ebd91f5766f4944a7ceee220c51752.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="SRI KRISHNA COLLEGE OF ENGINEERING AND TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Leadership Talk</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/79246f5e9cd32af9fda2ac521616eedc.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="Shoolini University">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->My Story - Entrepreneur\&#39;s Life and
                Crossroad - Motivational Speak by Dr. PK Khosla</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da2d43c737186f6684089e3f066bdaeb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TEST INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->check</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/3c78da21ae9ac23c8ae95f490b0708aa.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="BRAINWARE UNIVERSITY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Industry Visit- Identify real life problem
              </p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/da2d43c737186f6684089e3f066bdaeb.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="TEST INSTITUTE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->ss</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7c733984fbacb5850c48c632716fa9da.png"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="S. B. PATIL COLLEGE OF ENGINEERING">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Session on Intellectual Property Right</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/7e0c0abf6eebfd8968dd0b463cbacf02.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTAN INSTITUTE OF TECHNOLOGY AND SCIENCE">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->HITSATHON - Hackathon</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6c9a251fcd8626283e6e607f73616c24.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="HINDUSTHAN INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Entrepreneurship Development Training for
                Repair and Maintenance of Solar Based Power Supply, Inverter and UPS Among Rural Women</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/6bc6b5e2ed220244bd5d2e0e1b6ca92b.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="G.L. BAJAJ INSTITUTE OF TECHNOLOGY AND MANAGEMENT">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->Technical Expos (health care,
                Agriculture,Energy and Environment))</p>
              <!--</a>-->
            </span> </li>
          <li><img
              src="https://api.mic.gov.in/uploads/institutes/institute/images/logo/5d3c7036d211ae826d1e57489a1a4fa0.jpg"
              class="img-fluid" style="height: 60px;"> <span>
              <h4></h4>
              <p title="COIMBATORE INSTITUTE OF TECHNOLOGY">
                <!--<a href="#" style="color:#ff5502; font-size: 18px;">-->TECHIE TALK</p>
              <!--</a>-->
            </span> </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- Footer -->
<app-inner-footer></app-inner-footer>