import { FileService } from 'src/app/Services/file.service';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';

@Component({
  selector: 'app-institute-social-media-dialog',
  templateUrl: './institute-social-media-dialog.component.html',
  styleUrls: ['./institute-social-media-dialog.component.css'],
})
export class InstituteSocialMediaDialogComponent implements OnInit {
  public socialForm: FormGroup;

  fbl: any;

  ts1: any;
  ts2: any;

  logoFile: FileList | null;
  facebookLink: any;
  instagramLink: any;
  totalFacultyCount: any;
  totalStudentCount: any;
  twitterLink: any;
  boys: any;
  girls: any;
  male: any;
  female: any;
  facebook: any;
  twitter: any;
  instagram: any;
  instituteID: any;

  logoUploaded: boolean;

  constructor(
    private fb: FormBuilder,
    private myCouncilService: InstituteMyCouncilService,
    private fileService: FileService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteSocialMediaDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fbl = data.pageValue;

  }

  ngOnInit(): void {
    this.socialForm = this.fb.group({
      facebook: [null, Validators.required],
      instagram: [null, Validators.required],
      twitter: [null, Validators.required],
      totalFacultyCount: [null, Validators.required],
      totalStudentCount: [null, Validators.required],
      boys: [null, Validators.required],
      girls: [null, Validators.required],
      male: [null, Validators.required],
      female: [null, Validators.required],
      instituteID: [null, Validators.required],
    });


    // * for update
    if (this.fbl.length > 0) {
      this.facebook = this.fbl['0']['facebookLink'];
      this.twitter = this.fbl['0']['twitterLink'];
      this.instagram = this.fbl['0']['instagramLink'];
      this.boys = this.fbl['0']['boys'];
      this.girls = this.fbl['0']['girls'];
      this.male = this.fbl['0']['male'];
      this.female = this.fbl['0']['female'];
      this.totalFacultyCount = this.fbl['0']['totalFacultyCount'];
      this.totalStudentCount = this.fbl['0']['totalStudentCount'];
      this.instituteID = this.fbl['0']['id'];

      if (this.fbl['0']['collegeLogo'] != null) {
        this.logoUploaded = true;
      }

      this.socialForm.controls['facebook'].setValue(this.facebook);
      this.socialForm.controls['twitter'].setValue(this.twitter);
      this.socialForm.controls['instagram'].setValue(this.instagram);
      this.socialForm.controls['boys'].setValue(this.boys);
      this.socialForm.controls['girls'].setValue(this.girls);
      this.socialForm.controls['totalFacultyCount'].setValue(this.totalFacultyCount);
      this.socialForm.controls['totalStudentCount'].setValue(this.totalStudentCount);
      this.socialForm.controls['female'].setValue(this.female);
      this.socialForm.controls['male'].setValue(this.male);
      this.socialForm.controls['instituteID'].setValue(this.instituteID);
    }

  }

  onSubmit(values) {

    var logoFile =
      this.logoFile && this.logoFile.length
        ? this.logoFile[0]
        : null;

    var formData = new FormData();

    formData.append('logoAttachmentsubfile', logoFile);
    formData.append('facebook', this.socialForm.get('facebook').value);
    formData.append('instagram', this.socialForm.get('instagram').value);
    formData.append('twitter', this.socialForm.get('twitter').value);
    formData.append('boys', this.socialForm.get('boys').value);
    formData.append('girls', this.socialForm.get('girls').value);
    formData.append('male', this.socialForm.get('male').value);
    formData.append('female', this.socialForm.get('female').value);
    formData.append('totalFacultyCount', this.socialForm.get('totalFacultyCount').value);
    formData.append('totalStudentCount', this.socialForm.get('totalStudentCount').value);
    formData.append('instituteID', this.socialForm.get('instituteID').value);

    // alert(this.fbl.length); return false;

    if (this.fbl.length > 0) {
      this.myCouncilService
        .updateSocial(formData)
        .subscribe((data) => this.handleResponse(data));
    } else {
      // if (!this.fileService.checkIfImage(logoFile)) {
        this.myCouncilService
          .submitSocial(formData)
          .subscribe((data) => this.handleResponse(data));
      // } else {
      //   this.toastr.error('Kindly upload File!');
      // }
    }
  }

  handleResponse(data) {
    if (data['status'] == "1") {
      this.toastr.success("Updated Successfully!");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['MSG']);
    }
    this.myCouncilService.dataUpdated.next(true);
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }


  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
