import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { InstituteAnnouncementService } from 'src/app/Services/institute-announcement.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { IicRatingDialogComponent } from './iic-rating-dialog/iic-rating-dialog.component';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-iic-ratings2021-details',
  templateUrl: './iic-ratings2021-details.component.html',
  styleUrls: ['./iic-ratings2021-details.component.css']
})
export class IicRatings2021DetailsComponent implements OnInit {

  public baseurl = myGlobals.baseUrl1;
  public innovationCourse: FormGroup;
  type:any;
  instituteType: any;
  instituteTypeId: any;
  state: any;
  instituteCourses: any;
  changeData: any;
  currentPageNo: number = 1;
  total_pages: number;
  firstPage = true;
  lastPage: boolean = false;
  theme: any[];
  data1: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  frontCurrentPageNo: number = 0;
  StateModel2 = "";

  title = 'Angular Charts';
  view: any[] = [600, 400];
  public showLegend = true;
  public gradient = true;
  public colorScheme = {
    domain: ['#586B7D', '#5F9EDA', '#9E9492', '#0096A6', '#F47B00', '#606060']
  };
  public showLabels = true;
  public explodeSlices = false;
  public doughnut = false;
  public trimLabels = false;
  public showPieLegend = false;
  public legendPosition: string = 'below';

  //bar
  public multi: any[];
  public showXAxis = true;
  public showYAxis = true;
  public barGradient = false;
  public showBarLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Academic Year';
  public showYAxisLabel = false;
  public yAxisLabel = 'Rating';
  public barColorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#91d494', '#0096A6', '#F47B00', '#606060']
  };
  public autoScale = true;

  //Stacked bar
  public multi2: any[];
  public xAxisStackedLabel = 'Events';
  public showYAxisStackedLabel = true;
  public yAxisStackedLabel = 'Participants';
  public stackedColorScheme = {
    domain: ['#378D3B', '#0096A6']
  };

  public single = [
    {
      "name": "China",
      "value": 2243772
    },
    {
      "name": "USA",
      "value": 1126000
    },
    {
      "name": "Norway",
      "value": 296215
    },
    {
      "name": "Japan",
      "value": 257363
    },
    {
      "name": "Germany",
      "value": 196750
    },
    {
      "name": "France",
      "value": 204617
    }
  ];

  public ratingChart1: any[];
  public evThemeChart1: any[];

  constructor(private fb: FormBuilder,
    private activityService: InstituteActivityService,
     public dialog: MatDialog,
     private instituteannouncementservice: InstituteAnnouncementService,
     private innovationService: InstituteInnovationContestService,
   private toastr: ToastrService, private route: ActivatedRoute,
   private router: Router) {  }

  ngOnInit(): void {

    this.innovationCourse = this.fb.group({
      instituteCourses: [null],
      instituteName: [null],
      courseState: [null],
    });

    this.getInstituteCourses();

    // this.type='overall';

    this.route.params.subscribe(params => {
      this.type = params['type'];
    });

    if(this.type=="overall")      { this.instituteType=""; }
    if(this.type=="university")   { this.instituteType="1"; }
    if(this.type=="college")      { this.instituteType="2"; }
    if(this.type=="standalone")   { this.instituteType="3"; }

    const data = {
      applicationStatus: 'Approved',
      instituteType: this.instituteType
    };

    this.instituteannouncementservice.getApprovedInstituteRankingDetails(data, this.currentPageNo, this.per_page)
      .subscribe((data) => this.handleResponse(data));

      this.instituteannouncementservice.getinstituteRating('IC201810783')
      .subscribe((data) => this.handleResponse1(data));

  }

  getInstituteCourses() {
    this.instituteannouncementservice
      .getInstituteCoursesDetails()
      .subscribe((data) => this.setInstituteCourses(data));
  }

  // setInstituteCourses(data) {
  //   this.instituteCourses = [];
  //   for (let i = 0; i < (data.length-1); i++) {
  //     this.instituteCourses.push(data[i]);
  //   }
  // }

  setInstituteCourses(data) {
    this.instituteCourses = [];
    for (let i = 0; i < data['zoneData'].length; i++) {
      this.instituteCourses.push(data['zoneData'][i]);
    }
  }

  handleResponse1(data)
  {

    this.ratingChart1   = data['rating'];
    this.evThemeChart1  = data['evtheme'];

    // Object.assign(this.ratingChart1);

  }



  handleResponse(data) {
    this.theme = [];
    for (let i = 0; i < data['instituteDetails']['data'].length; i++) {
      this.theme.push(data['instituteDetails']['data'][i]);
    }
    this.total_pages = data.instituteDetails.meta.pagination.total;
    if (
      data['instituteDetails']['meta']['pagination']['current_page'] ==
      data['instituteDetails']['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['instituteDetails']['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {

    // var instituteType = this.innovation.get('instituteType').value;
    // var typeState = this.innovation.get('typeState').value;
    var instituteCourses = this.innovationCourse.get('instituteCourses').value;
    var courseState = this.innovationCourse.get('courseState').value;
    var instituteName = this.innovationCourse.get('instituteName').value;

    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    const data = {
      applicationStatus: 'Approved',
      instituteType: this.instituteType,
      // instituteType: instituteType,
      // typeState: typeState,
      instituteCourses: instituteCourses,
      courseState: courseState,
      instituteName: instituteName,
    };
    this.getApprInstitute(data);
  }

  previousPage() {

    // var instituteType = this.innovation.get('instituteType').value;
    // var typeState = this.innovation.get('typeState').value;
    var instituteCourses = this.innovationCourse.get('instituteCourses').value;
    var courseState = this.innovationCourse.get('courseState').value;
    var instituteName = this.innovationCourse.get('instituteName').value;

    this.currentPageNo--;

    const data = {
      applicationStatus: 'Approved',
      instituteType: this.instituteType,
      // instituteType: instituteType,
      // typeState: typeState,
      instituteCourses: instituteCourses,
      courseState: courseState,
      instituteName: instituteName,
    };

    this.getApprInstitute(data);

  }

  getApprInstitute(data) {
    this.changeData = data;
    this.instituteannouncementservice.getApprovedInstituteRankingDetails(this.changeData, this.currentPageNo, this.per_page)
      .subscribe((data) => this.handleResponse(data));
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(IicRatingDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  getCourseState() {

    this.instituteTypeId = this.innovationCourse.get('instituteCourses').value;
    if (this.instituteTypeId == "") {
      this.StateModel2 = ""
    } else {
      if (this.instituteTypeId != null || this.instituteTypeId > 0) {
        this.instituteannouncementservice.getStateZoneDetails(this.instituteTypeId).subscribe(
          data => this.setState(data)
        );
      }
    }

  }
  setState(data) {
    this.state = [];
    for (let i = 0; i < data.length; i++) {
      this.state.push(data[i]);
    }
  }

  onSubmitCourse(data) {
    data['applicationStatus'] = 'Approved';
    this.currentPageNo = 1;
    this.getApprInstitute(data);
  }

}
