import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class InstituteAmbassadorService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  getInstituteAmbassadorList(){
    return this.http.get(
      `${this.baseUrl}/institute-ambassador-details`
    );
  }

  getNonRegInstituteAmbassadorService() {
    return this.http.get(
      `${this.baseUrl}/institute-ambassador-nonreg`
    );
  }

  getReportForDownload(endPoint) {
    return this.http.get(`${this.baseUrl}/${endPoint}`, { responseType: 'arraybuffer' });
  }

}
