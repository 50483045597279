<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Mentor Mentee Program</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Mentor Mentee Program</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item" aria-current="page">Major Activities</li>
    <li class="breadcrumb-item active" aria-current="page">Mentor Mentee Program</li>
  </ul>
</nav>

<section class="mentorMenteeBox">
	<div class="container">
		<div class="vc_row">
			<div class="vc_col-md-12">
				<div class="headingAmbassador">
					<h2 class="incubTabHeading">Mentor - Mentee Program</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="vc_col-md-12 iicActiTop">
				<p class="iicCalFirstText1">The top-performing IIC Institutions will be enrolled as mentor IIC and aspirational IICs will be enrolled as mentee IIC. The financial and orientation support will be provided to the mentor IICs as per the annual budget provision made in IIC-MIC under the Mentor-Mentee program for the IIC calendar year 2020-21. </p>
				<p class="iicCalFirstText">Under the Mentor-Mentee Program, the Mentor IIC institutions will do the following suggestive activities (but not limited in scope).</p>
			</div>
		</div>
		<div class="row">
			<div class="vc_col-md-6 iicActiTop">
				<ul>
					<li>The mentor Institute will conduct the orientation session for mentee Institute.</li> 
					<li>An expert from the Mentor IIC Institute should be the part of IIC Council constituted at Mentee Institutes, and will take part in their quarterly council meetings.</li>
					<li>The Mentor IIC Institute will deliver at least 2 sessions to be conducted during particular IIC Calendar year.</li>
					<li>The Mentor IIC Institute will organize an exposure visit cum training program for all mentee institute representatives to understand the Innovation ecosystem and best practices at mentor IIC. </li>
				</ul>
			</div>
			<div class="vc_col-md-6">
				<div class="menteeImgBox">
					<img src="assets/assets/images/mentee-program-img.jpg" class="img-responsive">
				</div>
			</div>
		</div>
	</div>
</section>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>