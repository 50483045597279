import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  isLoading;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((data) => (this.isLoading = data));
  }
}
