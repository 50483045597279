<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>
<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">

<!-- <div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">About MIC</h1>							
		</div>
	</div>
</div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Poster Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Poster Gallery</li>
  </ul>
</nav>


<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador">
				<h2>Poster Gallery</h2>
			</div>
		</div>
		</div>	  
		  
	</div>	
 </div>

<div id="" class="about">
	<div class="container">
		<div class="row margin-lg-30t">
			<div class="gallery col-md-12">
				<div class="row">
					<!-- Regional meet Chennai -->
					<div class="col-md-4 mb-3">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="../poster-chennai">
								<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/poster/chennai/chennai-pp-1.jpeg" alt="image">
								<p class="newsParaBottom">Chennai</p>
							</a>
						</div>
					</div>

					<div class="col-md-4 mb-3">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="../poster-bengaluru">
								<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/poster/bengaluru/bengaluru-pp-1.JPG" alt="image">
								<p class="newsParaBottom">Bengaluru</p>
							</a>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="../poster-chandigarh">
								<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/poster/chandigarh/chandigarh-pp-1.jpg" alt="image">
								<p class="newsParaBottom">Chandigarh</p>
							</a>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="../poster-dindigul">
								<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/poster/dindigul/dindigul-pp-6.jpg" alt="image">
								<p class="newsParaBottom">Dindigul</p>
							</a>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="../poster-jaipur">
								<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/poster/jaipur/jaipur-pp-1.jpg" alt="image">
								<p class="newsParaBottom">Jaipur</p>
							</a>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="../poster-kochi">
								<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/poster/kochi/kochi-pp-1.jpg" alt="image">
								<p class="newsParaBottom">Kochi</p>
							</a>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="../poster-lucknow">
								<img class="sih-gallery-img" src="{{imageBasePath}}assets/wp_images/poster/lucknow/lucknow-pp-1.jpg" alt="image">
								<p class="newsParaBottom">Lucknow</p>
							</a>
						</div>
					</div>				
									
				</div>
			</div>
			
		</div>
	</div>	
</div>

<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>