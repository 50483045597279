<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Testimonials</h2>
    </div>

	<div class="card">
        <div class="card-title"><p>Details of Testimonials submitted</p></div>
	</div>
<div class="refrredBox">
 <div class="row rws-listpage">
	<div class="col-md-12">
            <p *ngIf="totalyearcount!=0"><a class="btn btn-primary referralBut" routerLink='/institute/activity/testimonial-add/edit'>Update</a></p>
            <p *ngIf="totalyearcount==0"><a class="btn btn-primary referralBut" routerLink='/institute/activity/testimonial-add/add'>Add New</a></p>         
        <div class="tableBody">
				<div class="table-responsive">
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th scope="col">S.No.</th>
            <th scope="col">What do you like most about IIC? </th>
            <th scope="col">What do you like least about IIC? </th>
            <th scope="col">What could we improve on? </th>            
            <th scope="col">Video URL</th>
            <th scope="col">Uploaded File </th>
            <th scope="col">Academic Year </th>
            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let nomination of iictestimonial; index as i" >
            <td>{{i+1}}</td>
            <td>{{nomination?.feedback}}</td>
            <td>{{nomination?.improvement_room}}</td>
            <td>{{nomination?.suggestions}}</td>
            <td>{{nomination?.video_url}}</td>
            <!-- <td>https://api.mic.gov.in{{nomination?.thumbnail}}</td> -->
            <td>
                <a href ="https://api.mic.gov.in{{nomination?.thumbnail}}" target="_blank">View File</a>
              </td>
            <td>{{nomination?.academic_year}}</td>      
            <td *ngIf="nomination?.verify_status!=1" class="color: #004085;
            background-color: #cce5ff; border-color: #b8daff;">

            <!-- <p *ngIf="nomination?.verify_status==0">Pending</p>
            <p *ngIf="nomination?.verify_status==3">Re-open</p>
            <p *ngIf="nomination?.remark!=''">Remarks: {{nomination?.remark}}</p>
            <p *ngIf="nomination?.verify_status==2" style="font-weight: bold; color:#FF0000;">Disapproved</p> -->

            <p *ngIf="nomination?.academic_year == '2021-22'"><button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn" class="btn-tbl-edit" >
                <mat-icon>edit</mat-icon>
                </button></p>
            <p *ngIf="nomination?.academic_year != '2021-22'"><button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn" class="btn-tbl-edit" disabled>
                <mat-icon>edit</mat-icon>
                </button></p>

            <p *ngIf="nomination?.academic_year == '2021-22'"><button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')"   color="warn" class="btn-tbl-delete">
                  <mat-icon>delete</mat-icon>
                  </button></p>
            <p *ngIf="nomination?.academic_year != '2021-22'"><button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')"   color="warn" class="btn-tbl-delete" disabled>
                    <mat-icon>delete</mat-icon>
                    </button></p>

            </td>

            <!-- <td *ngIf="nomination?.verify_status==1" style="color: #155724;
            background-color: #d4edda; border-color: #c3e6cb;">

              Approved

            </td> -->
        </tr>
        <tr *ngIf="iictestimonial.length== 0" col="4">
            <h3 style="text-align: center">No Data Found</h3>
        </tr>
    </tbody>
</table>
</div>
</div>



 </div>
</div>
</div>
</div>
