import { CheckPerformanceCardService } from './Services/check-performance-card.service';
import { InstituteDetailsSubmittedService } from './Services/institute-details-submitted.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { AtlSignupComponent } from './components/atl-signup/atl-signup.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { BeforeLoginService } from './Services/before-login.service';
import { AfterLoginService } from './Services/after-login.service';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { InstituteComponent } from './components/users/institute/institute.component';
import { InstituteMyCouncilComponent } from './components/users/institute/institute-my-council/institute-my-council.component';
import { InstituteIncubationComponent } from './components/users/institute/institute-incubation/institute-incubation.component';
import { InstituteInnovationContestComponent } from './components/users/institute/institute-innovation-contest/institute-innovation-contest.component';
import { InstituteActivitySecondFormComponent } from './components/users/institute/institute-activity/institute-my-activity/institute-activity-second-form/institute-activity-second-form.component';

import { ReportFormComponent } from './components/users/institute/institute-activity/institute-my-activity/institute-activity-report-form/report-form.component';
import { InstituteActivityComponent } from './components/users/institute/institute-activity/institute-activity.component';
import { InstituteIicImpactComponent } from './components/users/institute/institute-iic-impact/institute-iic-impact.component';
import { InstituteDownloadComponent } from './components/users/institute/institute-download/institute-download.component';
import { InstituteNewsCornerComponent } from './components/users/institute/institute-news-corner/institute-news-corner.component';
import { InstituteAnnouncementComponent } from './components/users/institute/institute-announcement/institute-announcement.component';
import { CoordinatorComponent } from './components/users/institute/institute-announcement/coordinator/coordinator.component';
import { NominateCoordinatorComponent } from './components/users/institute/institute-announcement/nominate-coordinator/nominate-coordinator.component';
import { CoordinatorAddComponent } from './components/users/institute/institute-announcement/coordinator/coordinator-add/coordinator-add.component';
import { InstituteSuggestionComponent } from './components/users/institute/institute-suggestion/institute-suggestion.component';
import { InstituteRequestLogoComponent } from './components/users/institute/institute-request-logo/institute-request-logo.component';
import { IicComponent } from './components/pages/innovation-council/iic/iic.component';
import { WhyHeisComponent } from './components/pages/innovation-council/why-heis/why-heis.component';
import { IicAdvisoryCommitteeComponent } from './components/pages/innovation-council/iic-advisory-committee/iic-advisory-committee.component';
import { InnovationcontestComponent } from './components/pages/innovation-council/innovationcontest/innovationcontest.component';
import { InnovationAmbassadorComponent } from './components/pages/innovation-council/innovation-ambassador/innovation-ambassador.component';
import { IicRatingComponent } from './components/pages/innovation-council/iic-rating/iic-rating.component';
import { IicTeamComponent } from './components/pages/innovation-council/iic-team/iic-team.component';
import { IicwebinarComponent } from './components/pages/innovation-council/iicwebinar/iicwebinar.component';

import { InnovationCouncilComponent } from './components/pages/innovation-council/innovation-council.component';
import { MicOutcomeComponent } from './components/pages/mic-outcome/mic-outcome.component';
import { ContactUsComponent } from './components/pages/about-us/contact-us/contact-us.component';
import { LeadershipTeamComponent } from './components/pages/about-us/leadership-team/leadership-team.component';
import { AboutMessageComponent } from './components/pages/about-us/about-message/about-message.component';
import { InnovationRankingComponent } from './components/pages/innovation-ranking/innovation-ranking.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AboutMHRDnnovationComponent } from './components/pages/about-us/about-mhrdnnovation/about-mhrdnnovation.component';
import { HackathonComponent } from './components/pages/hackathon/hackathon.component';
import { SihComponent } from './components/pages/hackathon/sih/sih.component';
import { SingaporeIndiaHackathonComponent } from './components/pages/hackathon/singapore-india-hackathon/singapore-india-hackathon.component';
import { StartUpPolicyComponent } from './components/pages/start-up-policy/start-up-policy.component';
import { StartupPolicyCommitteeComponent } from './components/pages/start-up-policy/startup-policy-committee/startup-policy-committee.component';
import { StartupApplicationComponent } from './components/pages/start-up-policy/startup-application/startup-application.component';
import { NominateFacultyForNispComponent } from './components/pages/start-up-policy/nominate-faculty-for-nisp/nominate-faculty-for-nisp.component';
import { LeadershipTalkComponent } from './components/pages/leadership-talk/leadership-talk.component';
import { IfltsUpcomingComponent } from './components/pages/leadership-talk/iflts-upcoming/iflts-upcoming.component';
import { IfltsPastComponent } from './components/pages/leadership-talk/iflts-past/iflts-past.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { GalleryVideoComponent } from './components/pages/gallery/gallery-video/gallery-video.component';
import { GalleryPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo.component';
import { NewsComponent } from './components/pages/gallery/news/news.component';
import { StartupPolicyComponent } from './components/pages/start-up-policy/startup-policy/startup-policy.component';
import { InstituteProfileComponent } from './components/users/institute/institute-profile/institute-profile.component';
import { InstituteContactUsComponent } from './components/users/institute/institute-contact-us/institute-contact-us.component';
import { InstituteDashboardComponent } from './components/users/institute/institute-dashboard/institute-dashboard.component';
import { Dashboard1819ActivityListComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-activity-list/dashboard1819-activity-list.component';
import { Dashboard1819AnnouncementsComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-announcements/dashboard1819-announcements.component';
import { Dashboard1819MyRankingComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-my-ranking/dashboard1819-my-ranking.component';
import { Dashboard1819NotificationsComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-notifications/dashboard1819-notifications.component';
import { Dashboard1819StarPerformerComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-star-performer/dashboard1819-star-performer.component';
import { BifurcationScoreComponent } from './components/users/institute/institute-profile/bifurcation-score/bifurcation-score.component';
import { CertificatesComponent } from './components/users/institute/institute-profile/certificates/certificates.component';
import { InstituteDashboard1819Component } from './components/users/institute/institute-dashboard1819/institute-dashboard1819.component';
import { DashboardActivityListComponent } from './components/users/institute/institute-dashboard/dashboard-activity-list/dashboard-activity-list.component';
import { InstituteAmbassadorComponent } from './components/users/institute/institute-ambassador/institute-ambassador.component';
import { OnlineRegistrationComponent } from './components/pages/online-registration/online-registration.component';
import { RegistrationOnlineSessionComponent } from './components/pages/registration-online-session/registration-online-session.component';
import { OnlineSessionLoginComponent } from './components/pages/online-session-login/online-session-login.component';
import { OnlineSessionDetailsComponent } from './components/pages/online-session-details/online-session-details.component';
import { OnlineSessionVideoComponent } from './components/pages/online-session-video/online-session-video.component';
import { Iic202021CalenderComponent } from './components/pages/iic202021-calender/iic202021-calender.component';
import { Iic201920Component } from './components/pages/impact-lecture-series/iic201920/iic201920.component';
import { Iic202021Component } from './components/pages/impact-lecture-series/iic202021/iic202021.component';
import { InternationalExposureComponent } from './components/pages/international-exposure/international-exposure.component';
import { IndiaCanadaComponent } from './components/pages/international-exposure/india-canada/india-canada.component';
import { IndiaSouthKoreaComponent } from './components/pages/international-exposure/india-south-korea/india-south-korea.component';
import { IicDownloadComponent } from './components/pages/iic-download/iic-download.component';
import { IicNotificationComponent } from './components/pages/iic-notification/iic-notification.component';
import { IicRatings1819Component } from './components/pages/iic-ratings1819/iic-ratings1819.component';
import { MentorMenteeProgramComponent } from './components/pages/mentor-mentee-program/mentor-mentee-program.component';
import { PreincubatorsComponent } from './components/pages/iic-incubatorstab/preincubators/preincubators.component';
import { IncubatorsComponent } from './components/pages/iic-incubatorstab/incubators/incubators.component';
import { IicaboutMicComponent } from './components/pages/about-us/iicabout-mic/iicabout-mic.component';
import { IicadvisoryCommitteeComponent } from './components/pages/about-us/iicadvisory-committee/iicadvisory-committee.component';
import { IicImplementationTeamComponent } from './components/pages/about-us/iic-implementation-team/iic-implementation-team.component';
import { IicDashboard1819Component } from './components/pages/iic-dashboard1819/iic-dashboard1819.component';
import { IicDashboard1819ChartsComponent } from './components/pages/iic-dashboard1819/iic-dashboard1819-charts/iic-dashboard1819-charts.component';
import { IicDashboard1819CardsComponent } from './components/pages/iic-dashboard1819/iic-dashboard1819-cards/iic-dashboard1819-cards.component';
import { IicDashboard1920Component } from './components/pages/iic-dashboard1920/iic-dashboard1920.component';
import { IicDashboard1920ChartsComponent } from './components/pages/iic-dashboard1920/iic-dashboard1920-charts/iic-dashboard1920-charts.component';
import { IicInnovationAmbassadorComponent } from './components/pages/iic-innovation-ambassador/iic-innovation-ambassador.component';
import { IicAmbassadorProfileComponent } from './components/pages/iic-ambassador-profile/iic-ambassador-profile.component';
import { IicOnlineSessionsComponent } from './components/pages/iic-online-sessions/iic-online-sessions.component';
import { IicOnlineSessionsDetailsComponent } from './components/pages/iic-online-sessions-details/iic-online-sessions-details.component';
import { PocContest2019Component } from './components/pages/iic-national-innovation-contest/poc-contest2019/poc-contest2019.component';
import { InnovationConest2020Component } from './components/pages/iic-national-innovation-contest/innovation-conest2020/innovation-conest2020.component';
import { IicIncubatorstabComponent } from './components/pages/iic-incubatorstab/iic-incubatorstab.component';
import { IicRatings1920Component } from './components/pages/iic-ratings1920/iic-ratings1920.component';
import { IicRatings1920ListComponent } from './components/pages/iic-ratings1920-list/iic-ratings1920-list.component';
import { OsiaRequestResetComponent } from './components/password/osia-request-reset/osia-request-reset.component';
import { MemberCertificateListComponent } from './components/users/institute/institute-profile/certificates/member-certificate-list/member-certificate-list.component';
import { Members1819Component } from './components/users/institute/institute-profile/certificates/members1819/members1819.component';
import { MembersCertificates1819Component } from './components/shared/members-certificates1819/members-certificates1819.component';
import { InstituteListComponent } from './components/pages/institute-list/institute-list.component';
import { CouncilDetailComponent } from './components/pages/institute-list/council-detail/council-detail.component';
import { SesssionListComponent } from './components/pages/sesssion-list/sesssion-list.component';
import { NominationsComponent } from './components/users/institute/institute-schemes/nominations/nominations.component';
import { TrainingStatusComponent } from './components/users/institute/institute-schemes/training-status/training-status.component';
import { StudentTrainingStatusComponent } from './components/users/institute/student-training-status/student-training-status.component';
import { ImpactLecturesComponent } from './components/users/institute/institute-schemes/impact-lectures/impact-lectures.component';
import { InstituteMentorMenteeComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/institute-mentor-mentee.component';
import { MentorNavbarComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/mentor-navbar.component';
import { InstituteSuggestionNewComponent } from './components/users/institute/institute-suggestion-new/institute-suggestion-new.component';
import { InstituteDashboard2021Component } from './components/users/institute/institute-dashboard2021/institute-dashboard2021.component';
import { Downloads2021Component } from './components/users/institute/downloads2021/downloads2021.component';
import { Downloads2020Component } from './components/users/institute/downloads2020/downloads2020.component';
import { MentorMenteeProgramNewComponent } from './components/pages/mentor-mentee-program-new/mentor-mentee-program-new.component';
import { IicInnovationAmbassadorNewComponent } from './components/pages/iic-innovation-ambassador-new/iic-innovation-ambassador-new.component';
import { IicImpactLectureSeriesNewComponent } from './components/pages/iic-impact-lecture-series-new/iic-impact-lecture-series-new.component'
import { InstituteYic2021Component } from './components/users/institute/institute-yic2021/institute-yic2021.component';
import { InstituteBankFormComponent } from './components/users/institute/institute-bank-form/institute-bank-form.component';
import { InstitutesListComponent } from './components/users/institute/institutes-list/institutes-list.component';
import { NewIaRequestComponent } from './components/users/institute/institute-schemes/new-ia-request/new-ia-request.component';
import { OnlineSessionListComponent } from './components/users/institute/online-session-list/online-session-list.component';
import { JoinUsWhatsappComponent } from './components/users/institute/join-us-whatsapp/join-us-whatsapp.component';
import { InstituteIicSubmissionComponent } from './components/users/institute/institute-iic-submission/institute-iic-submission.component';
import{ImpactLecturesReportFormComponent} from './components/users/institute/institute-schemes/impact-lectures/impact-lectures-report-form/impact-lectures-report-form.component';
import { IicOutcomeComponent } from './components/pages/iic-outcome/iic-outcome.component';
import { IicImpactDashboardComponent } from './components/pages/iic-impact-dashboard/iic-impact-dashboard.component';
import { IicImpactDashboardStateWiseComponent } from './components/pages/iic-impact-dashboard-statewise/iic-impact-dashboard-statewise.component';
import { Iic202122CalenderComponent } from './components/pages/iic202122-calender/iic202122-calender.component';
import {
  InstituteAtalSchoolComponent
} from './components/users/institute/institute-atal-school/institute-atal-school.component';
import { RepositoryComponent } from './components/users/institute/repository/repository.component';
import { RepositoryAddComponent } from './components/users/institute/repository/repository-add/repository-add.component';
import { YuktiChallengesComponent } from './components/users/institute/yukti-challenges/yukti-challenges.component';
import { ViewSubmissionComponent } from './components/users/institute/yukti-challenges/view-submission/view-submission.component';
import { YuktiChallengesAddComponent } from './components/users/institute/yukti-challenges/yukti-challenges-add/yukti-challenges-add.component';
import { InnovationContestComponent } from './components/users/institute/yukti-challenges/innovation-contest/innovation-contest.component';
import { InstituteTestimonialComponent } from './components/users/institute/testimonial/testimonial.component';
import { InstituteActivityTestimonialComponent } from './components/users/institute/testimonial/institute-testimonial-add/institute-testimonial-add.component';
import { InstituteEvaluatorAddComponent } from './components/users/institute/institute-yic2021/evaluator/evaluator-add/evaluator-add.component';

import { IicRatings2021Component } from './components/pages/iic-ratings2021/iic-ratings2021.component';
import { IicRatings2022Component } from './components/pages/iic-ratings2022/iic-ratings2022.component';
import { IicRatings2023Component } from './components/pages/iic-ratings2023/iic-ratings2023.component';
import { IicRatings2021DetailsComponent } from './components/pages/iic-ratings2021-details/iic-ratings2021-details.component';
import { IicRepositoryListComponent } from './components/pages/iic-repository-list/iic-repository-list.component';
import { IicMentorMenteeListComponent } from './components/pages/iic-mentor-mentee-list/iic-mentor-mentee-list.component';
import { AtlVerificationComponent } from './components/atlverification/atlverification.component';
import {
  InstituteAtalMentorScheduleComponent
} from './components/users/institute/institute-atal-mentor-schedule/institute-atal-mentor-schedule.component';
import { InstituteReferalProgramComponent } from './components/users/institute/institute-referal-program/institute-referal-program.component';
import { InstituteReferalProgramAddComponent } from './components/users/institute/institute-referal-program/institute-referal-program-add/institute-referal-program-add.component';
import { InstituteReferalProgramEditComponent } from './components/users/institute/institute-referal-program/institute-referal-program-edit/institute-referal-program-edit.component';

import { InstituteAffiliateProgramComponent } from './components/users/institute/institute-affiliate-program/institute-affiliate-program.component';
import { InstituteAffiliateProgramAddComponent } from './components/users/institute/institute-affiliate-program/institute-affiliate-program-add/institute-affiliate-program-add.component';
import { InstituteAffiliateProgramEditComponent } from './components/users/institute/institute-affiliate-program/institute-affiliate-program-edit/institute-affiliate-program-edit.component';
import { IicVolunteerMentorMenteeListComponent } from './components/pages/iic-volunteer-mentor-mentee-list/iic-volunteer-mentor-mentee-list.component';
import { InstituteIicAkamComponent } from './components/users/institute/institute-iic-akam/institute-iic-akam.component';
import { InstituteInnovationAddComponent } from './components/users/institute/institute-yic2021/institute-innovation-add/institute-innovation-add.component';
import { InstituteInnovationTeamDetailsComponent } from './components/users/institute/institute-yic2021/institute-innovation-team-details/institute-innovation-team-details.component';
import {InstituteDashboard2022Component} from './components/users/institute/institute-dashboard2022/institute-dashboard2022.component';
import {InstituteDashboard2023Component} from './components/users/institute/institute-dashboard2023/institute-dashboard2023.component';
import {InstituteDashboard2024Component} from './components/users/institute/institute-dashboard2024/institute-dashboard2024.component';
import {InstituteDashboard2025Component} from './components/users/institute/institute-dashboard2025/institute-dashboard2025.component';
import {InternshipComponent} from './components/users/institute/Internship/internship/internship.component';

import { InstituteMmDashboardComponent } from './components/users/institute/institute-schemes/institute-mm-dashboard/institute-mm-dashboard.component';
import { InstituteAffiliateProgramPendingComponent } from './components/users/institute/institute-affiliate-program-pending/institute-affiliate-program-pending.component';

import { InstituteIdeaEvaluationComponent } from './components/users/institute/institute-yic2021/institute-idea-evaluation/institute-idea-evaluation.component';
import { InstituteInnovationEvaluationComponent } from './components/users/institute/institute-yic2021/institute-innovation-evaluation/institute-innovation-evaluation.component';
import { InstituteStartupEvaluationComponent } from './components/users/institute/institute-yic2021/institute-startup-evaluation/institute-startup-evaluation.component';
import { SelfDriveActivityAddComponent } from './components/users/institute/institute-activity/institute-my-activity/self-drive-activity-add/self-drive-activity-add.component';
import { SihGalleryPhotoComponent } from './components/pages/gallery/gallery-photo/sih-gallery-photo/sih-gallery-photo.component';
import { PhotoGalleryAhmedabadComponent } from './components/pages/gallery/gallery-photo/photo-gallery-ahmedabad/photo-gallery-ahmedabad.component';
import { PhotoGalleryBangaloreComponent } from './components/pages/gallery/gallery-photo/photo-gallery-bangalore/photo-gallery-bangalore.component';
import { PhotoGalleryChandigarhComponent } from './components/pages/gallery/gallery-photo/photo-gallery-chandigarh/photo-gallery-chandigarh.component';
import { PhotoGalleryGuwahatiComponent } from './components/pages/gallery/gallery-photo/photo-gallery-guwahati/photo-gallery-guwahati.component';
import { PhotoGalleryHyderabadComponent } from './components/pages/gallery/gallery-photo/photo-gallery-hyderabad/photo-gallery-hyderabad.component';
import { PhotoGalleryJaipurComponent } from './components/pages/gallery/gallery-photo/photo-gallery-jaipur/photo-gallery-jaipur.component';
import { PhotoGalleryKolkataComponent } from './components/pages/gallery/gallery-photo/photo-gallery-kolkata/photo-gallery-kolkata.component';
import { PhotoGalleryKochiComponent } from './components/pages/gallery/gallery-photo/photo-gallery-kochi/photo-gallery-kochi.component';
import { PhotoGalleryLucknowComponent } from './components/pages/gallery/gallery-photo/photo-gallery-lucknow/photo-gallery-lucknow.component';
import { PhotoGalleryMaduraiComponent } from './components/pages/gallery/gallery-photo/photo-gallery-madurai/photo-gallery-madurai.component';
import { PhotoGalleryNagpurComponent } from './components/pages/gallery/gallery-photo/photo-gallery-nagpur/photo-gallery-nagpur.component';
import { PhotoGalleryPuneComponent } from './components/pages/gallery/gallery-photo/photo-gallery-pune/photo-gallery-pune.component';
import { PhotoGalleryVisakhapatnamComponent } from './components/pages/gallery/gallery-photo/photo-gallery-visakhapatnam/photo-gallery-visakhapatnam.component';
import { IaActivityReportComponent } from './components/users/institute/ia-activity-report/ia-activity-report.component';
import { PhotoGalleryBhopalComponent } from './components/pages/gallery/gallery-photo/photo-gallery-bhopal/photo-gallery-bhopal.component';
import { EvaluatorEditComponent } from './components/users/institute/institute-yic2021/evaluator/evaluator-edit/evaluator-edit.component';
import { IicAchievementComponent } from './components/pages/iic-achievement/iic-achievement.component';
import { PosterComponent } from './components/pages/poster/poster.component';
import { PosterChennaiComponent } from './components/pages/poster/poster-chennai/poster-chennai.component';
import { PosterBengaluruComponent } from './components/pages/poster/poster-bengaluru/poster-bengaluru.component';
import { PosterChandigarhComponent } from './components/pages/poster/poster-chandigarh/poster-chandigarh.component';
import { PosterDindigulComponent } from './components/pages/poster/poster-dindigul/poster-dindigul.component';
import { PosterJaipurComponent } from './components/pages/poster/poster-jaipur/poster-jaipur.component';
import { PosterKochiComponent } from './components/pages/poster/poster-kochi/poster-kochi.component';
import { PosterLucknowComponent } from './components/pages/poster/poster-lucknow/poster-lucknow.component';
import { PosterGalleryComponent } from './components/pages/poster/poster-gallery/poster-gallery.component';
import { AtlUserDashboardComponent } from './components/users/institute/atl-user-dashboard/atl-user-dashboard.component';
import { ActivityMenteeDialogComponent } from './components/users/institute/atl-user-dashboard/atl-mentee-activity/activity-mentee-dialog/activity-mentee-dialog.component';
import { AtlMentorDetailComponent } from './components/users/institute/atl-mentor-detail/atl-mentor-detail.component';
import { StudentInnovationVideoComponent } from './components/pages/student-innovation-video/student-innovation-video.component';
import { AtlHomeDashboardComponent } from './components/users/institute/atl-home-dashboard/atl-home-dashboard.component';
import { AtlMentorOfChangeComponent } from './components/users/institute/atl-mentor-of-change/atl-mentor-of-change.component';
import { AtlYuktiSubmissionComponent } from './components/users/institute/atl-yukti-submission/atl-yukti-submission.component';
import { AtlYuktiSubmissionAddComponent } from './components/users/institute/atl-yukti-submission/atl-yukti-submission-add/atl-yukti-submission-add.component';
import { InstituteStateIicListComponent } from './components/users/institute/institute-state-iic-list/institute-state-iic-list.component';
import { SubiicComponent } from './components/users/institute/subiic/subiic.component';
import { SubiicAddComponent } from './components/users/institute/subiic/subiic-add/subiic-add.component';
import { SubiicExistingComponent } from './components/users/institute/subiic/subiic-existing/subiic-existing.component';
import { Iic202223CalenderComponent } from './components/pages/iic202223-calender/iic202223-calender.component';
import { CollaborationComponent } from './components/users/institute/collaboration/collaboration.component';
import { TeachingLearningComponent } from './components/users/institute/teaching-learning/teaching-learning.component';
import { TeachingLearning2Component } from './components/users/institute/teaching-learning2/teaching-learning2.component';
import { CollaborationAddComponent } from './components/users/institute/collaboration/collaboration-add/collaboration-add.component';
import { TeachingLearningAddComponent } from './components/users/institute/teaching-learning/teaching-learning-add/teaching-learning-add.component';
import { TeachingNonacademicAddComponent } from './components/users/institute/teaching-learning/teaching-nonacademic-add/teaching-nonacademic-add.component';
import { Downloads2022Component } from './components/users/institute/downloads2022/downloads2022.component';
import { Downloads2023Component } from './components/users/institute/downloads2023/downloads2023.component';
import { AtlProfileDetailComponent } from './components/users/institute/atl-profile-detail/atl-profile-detail.component';
import { AtlContactUsComponent } from './components/users/institute/atl-contact-us/atl-contact-us.component';
import { AtlAnnouncementDetailComponent } from './components/users/institute/atl-announcement-detail/atl-announcement-detail.component';
import { AtlIaMemberNominationListComponent } from './components/users/institute/atl-ia-member-nomination-list/atl-ia-member-nomination-list.component';
import { AddNominatedCoordinatorComponent } from './components/users/institute/nominated-atl-coordinator/add-nominated-coordinator/add-nominated-coordinator.component';
import { NominatedAtlCoordinatorComponent } from './components/users/institute/nominated-atl-coordinator/nominated-atl-coordinator.component';
import { NominatedIicAtlCoordinatorListComponent } from './components/users/institute/nominated-iic-atl-coordinator-list/nominated-iic-atl-coordinator-list.component';
import { IicReportDownloadComponent } from './components/users/institute/iic-report-download/iic-report-download.component';
import { AtlActivityViewSubmissionComponent } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atl-activity-view-submission/atl-activity-view-submission.component';
import { AtlInviteIicComponent } from './components/users/institute/atl-invite-iic/atl-invite-iic.component';
import { YuktiNominatedChallengesComponent } from './components/users/institute/yukti-nominated-challenges/yukti-nominated-challenges.component';
import { YirDashboardComponent } from './components/users/institute/yir-dashboard/yir-dashboard.component';
import { Ntw2023Component } from './components/pages/gallery/gallery-photo/ntw2023/ntw2023.component';
import { NationalTechnologyWeekComponent } from './components/pages/national-technology-week/national-technology-week.component';
import { InstituteChallengeNominationComponent } from './components/users/institute/institute-challenge-nomination/institute-challenge-nomination.component';
import { YuktiInstituteChallengesComponent } from './components/users/institute/yukti-challenges/yukti-institute-challenges/yukti-institute-challenges.component';
import { IicImpactLectureSeries2023Component } from './components/pages/iic-impact-lecture-series2023/iic-impact-lecture-series2023.component';
import { AtlInstituteListComponent } from './components/users/institute/atl-institute-list/atl-institute-list.component';
import { YuktiInnovatorFormComponent } from './components/users/institute/yukti-innovator-form/yukti-innovator-form.component';
import { IprInnovatorFormComponent } from './components/users/institute/ipr-innovator-form/ipr-innovator-form.component';
import { TransferExistingInnovatorFormComponent } from './components/users/institute/transfer-existing-innovator-form/transfer-existing-innovator-form.component';
import { AltInviteVerficationInstituteComponent } from './components/alt-invite-verfication-institute/alt-invite-verfication-institute.component';
import { YuktiInstituteNominateChallengesComponent } from './components/users/institute/yukti-challenges/yukti-institute-challenges/yukti-institute-nominate-challenges/yukti-institute-nominate-challenges.component';
import { YuktiInstituteNominateChallengesNewComponent } from './components/users/institute/yukti-challenges/yukti-institute-challenges/yukti-institute-nominate-challenges-new/yukti-institute-nominate-challenges-new.component';
import { TransferExistingInnovatorListComponent } from './components/users/institute/transfer-existing-innovator-list/transfer-existing-innovator-list.component';
import { TransferInnovationWithRegistrationComponent } from './components/users/institute/transfer-innovation-with-registration/transfer-innovation-with-registration.component';
import { YuktiRmNominationComponent } from './components/users/institute/yukti-rm-nomination/yukti-rm-nomination.component';
import { HandholdingInstituteRequestComponent } from './components/handholding-institute-request/handholding-institute-request.component';
import { InstituteAssignerDetailComponent } from './components/users/institute/institute-referal-program/institute-assigner-detail/institute-assigner-detail.component';
import { InstituteInactiveIicDetailComponent } from './components/users/institute/institute-referal-program/institute-inactive-iic-detail/institute-inactive-iic-detail.component';
import { GalleryVideo2023Component } from './components/pages/gallery/gallery-video2023/gallery-video2023.component';
import { GalleryPhoto2023Component } from './components/pages/gallery/gallery-photo/gallery-photo2023/gallery-photo2023.component';
import { ChennaiPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/chennai-photo/chennai-photo.component';
import { HublliPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/hublli-photo/hublli-photo.component';
import { IndorePhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/indore-photo/indore-photo.component';
import { MumbaiPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/mumbai-photo/mumbai-photo.component';
import { TirunelveliPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/tirunelveli-photo/tirunelveli-photo.component';
import { VaranasiPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/varanasi-photo/varanasi-photo.component';
import { KolkataPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/kolkata-photo/kolkata-photo.component';
import { GuwahatiPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/guwahati-photo/guwahati-photo.component';
import { JaipurPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/jaipur-photo/jaipur-photo.component';
import { AhmedabadPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/ahmedabad-photo/ahmedabad-photo.component';
import { BhubaneswarPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/bhubaneswar-photo/bhubaneswar-photo.component';
import { PunePhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/pune-photo/pune-photo.component';
import { NagpurPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/nagpur-photo/nagpur-photo.component';
import { BangalorePhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/bangalore-photo/bangalore-photo.component';
import { CoimbatorePhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/coimbatore-photo/coimbatore-photo.component';
import { NoidaPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/noida-photo/noida-photo.component';
import { News2023Component } from './components/pages/gallery/news2023/news2023.component';
import { ManthanUserComponent } from './components/users/institute/manthan-user/manthan-user.component';
import { ActivityScheduledComponent } from './components/users/institute/activity-scheduled/activity-scheduled.component';
import { IicCalendar202425Component } from './components/pages/iic-calendar202425/iic-calendar202425.component';
import {AllActivityScheduledComponent} from './components/users/institute/all-activity-scheduled/all-activity-scheduled.component';
import { EditActivityAdminComponent } from './components/users/institute/edit-activity-admin/edit-activity-admin.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  // {path:'innovation',
  // component: InnovationComponent,

  // },
  {
    path: 'news-2023',
    component: News2023Component,
  },
  {
    path: 'noida-photo-2023',
    component: NoidaPhotoComponent,
  },
  {
    path: 'coimbatore-photo-2023',
    component: CoimbatorePhotoComponent,
  },
  {
    path: 'bangalore-photo-2023',
    component: BangalorePhotoComponent,
  },
  {
    path: 'nagpur-photo-2023',
    component: NagpurPhotoComponent,
  },
  {
    path: 'pune-photo-2023',
    component: PunePhotoComponent,
  },
  {
    path: 'bhubaneswar-photo-2023',
    component: BhubaneswarPhotoComponent,
  },
  {
    path: 'ahmedabad-photo-2023',
    component: AhmedabadPhotoComponent,
  },
  {
    path: 'jaipur-photo-2023',
    component: JaipurPhotoComponent,
  },
  {
    path: 'guwahati-photo-2023',
    component: GuwahatiPhotoComponent,
  },
  {
    path: 'kolkata-photo-2023',
    component: KolkataPhotoComponent,
  },
  {
    path: 'varanasi-photo-2023',
    component: VaranasiPhotoComponent,
  },
  {
    path: 'tirunelveli-photo-2023',
    component: TirunelveliPhotoComponent,
  },
  {
    path: 'mumbai-photo-2023',
    component: MumbaiPhotoComponent,
  },
  {
    path: 'indore-photo-2023',
    component: IndorePhotoComponent,
  },
  {
    path: 'hublli-photo-2023',
    component: HublliPhotoComponent,
  },
  {
    path: 'chennai-photo-2023',
    component: ChennaiPhotoComponent,
  },
  {
    path: 'gallery-photo-2023',
    component: GalleryPhoto2023Component,
  },
  {
    path: 'gallery-video-2023',
    component: GalleryVideo2023Component,
  },
  {
    path: 'login',
    component: LoginComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'atlVerification',
    component: AtlVerificationComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'atl-institute-verification',
    component: AltInviteVerficationInstituteComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'signup',
    component: SignupComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'atl-signup',
    component: AtlSignupComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'elearning-resources-repository',
    component: IicRepositoryListComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'mentor-mentee-2021',
    component: IicMentorMenteeListComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'volunteer-mentor-mentee-2021',
    component: IicVolunteerMentorMenteeListComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'members1819_orientationDetails',
    component: MembersCertificates1819Component,
    //canActivate: [BeforeLoginService],
  }, {
    path: 'institute-list',
    component: InstituteListComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'council-detail/:iicId',
    component: CouncilDetailComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iicabout-mic',
    component: IicaboutMicComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-outcome',
    component: IicOutcomeComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-impact-dashboard',
    component: IicImpactDashboardComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iicadvisory-committee',
    component: IicadvisoryCommitteeComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-implementation-team',
    component: IicImplementationTeamComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-dashboard1819',
    component: IicDashboard1819Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-dashboard1920',
    component: IicDashboard1920Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-dashboard1819-charts',
    component: IicDashboard1819ChartsComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-dashboard1920-charts',
    component: IicDashboard1920ChartsComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-dashboard1819-cards',
    component: IicDashboard1819CardsComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-innovation-ambassador',
    component: IicInnovationAmbassadorComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-innovation-ambassador2021',
    component: IicInnovationAmbassadorNewComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic202021-calender',
    component: Iic202021CalenderComponent,
    //canActivate: [BeforeLoginService],
  },
  {
	path: 'iic-ratings2021',
	component: IicRatings2021Component,
  },
  {
    path: 'iic-ratings2022',
    component: IicRatings2022Component,
  },  
  {
    path: 'iic-ratings2023',
    component: IicRatings2023Component,
  }, 
  {
	path: 'iic_calendar_2024-25',
	component: IicCalendar202425Component,
  },  
  {
	path: 'iic-ratings2021-details/:type',
	component: IicRatings2021DetailsComponent,
  },
  {
  path: 'iic-impact-statewise-details/:type',
	component: IicImpactDashboardStateWiseComponent,
  },

  {
    path: 'iic202122-calender',
    component: Iic202122CalenderComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic202223-calender',
    component: Iic202223CalenderComponent,
  },
  

  {
    path: 'iic-ambassador-profile',
    component: IicAmbassadorProfileComponent,
    // canActivate: [BeforeLoginService],
  },
  {
    path: 'incubatorstab',
    component: IicIncubatorstabComponent,
  },
  {
    path: 'session-list',
    component: SesssionListComponent,
  },

  {
    path: 'iic-online-sessions',
    component: IicOnlineSessionsComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-online-sessions-details/:id',
    component: IicOnlineSessionsDetailsComponent,
    //canActivate: [BeforeLoginService],
  },

  {
    path: 'poc-contest2019',
    component: PocContest2019Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'innovation-conest2020',
    component: InnovationConest2020Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'request-password-reset',
    component: RequestResetComponent,
    // canActivate: [BeforeLoginService],
  },
  {
    path: 'forgot-password-response',
    component: ResponseResetComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'verify-email',
    component: EmailVerificationComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'verify-otp',
    component: VerifyOtpComponent,
    //canActivate: [BeforeLoginService],
  },  
  {
    path: 'yir-dashboard',
    component: YirDashboardComponent,
    //canActivate: [BeforeLoginService],
  },  
  {
    path: 'handholding-institute-request',
    component: HandholdingInstituteRequestComponent,
    //canActivate: [BeforeLoginService],
  },  
  {
    path: 'institute',
    component: InstituteComponent,
    canActivate: [AfterLoginService],
    children: [
      {
        path: 'request-ambassador',
        component: NewIaRequestComponent,
      },
      {
        path: 'my-council',
        component: InstituteMyCouncilComponent,
      },
      {
        path: 'atlsic-dashboard',
        component: AtlUserDashboardComponent,
      },
      {
        path: 'atl-activity-report/:activityType/:activityId',
        component: ActivityMenteeDialogComponent,
      },
      {
        path: 'atl-mentor-detail',
        component: AtlMentorDetailComponent,
      },
      {
        path: 'atl-profile-detail',
        component: AtlProfileDetailComponent,
      },
      {
        path: 'atl-contact-detail',
        component: AtlContactUsComponent,
      },
      {
        path: 'atl-announcement-detail',
        component: AtlAnnouncementDetailComponent,
      },
      {
        path: 'atl-nominate-member',
        component: NominatedAtlCoordinatorComponent,
      },
      {
        path: 'atl-add-nominate-coordinator',
        component: AddNominatedCoordinatorComponent,
      },
      {
        path: 'atl-nominate-coordinator',
        component: NominatedAtlCoordinatorComponent,
      },
      {
        path: 'atl-institute-list',
        component: AtlInstituteListComponent,
      },
      {
        path: 'atlsic-home',
        component: AtlHomeDashboardComponent,
      },
      {
        path: 'atlsic-mentor-of-change',
        component: AtlMentorOfChangeComponent,
      },
      {
        path: 'atlsic-yukti-submission',
        component: AtlYuktiSubmissionComponent,
      }, 
      {
        path: 'atlsic-yukti-submission-add/:rid',
        component: AtlYuktiSubmissionAddComponent,
      },       
      {
        path: 'iic-report-download',
        component: IicReportDownloadComponent,
      },       
      {
        path: 'incubation',
        component: InstituteIncubationComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'innovation-contest',
        component: InstituteInnovationContestComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'activity',
        component: InstituteActivityComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'iic-impact',
        component: InstituteIicImpactComponent,
        //canActivate: [InstituteDetailsSubmittedService],
      },

      {
        path: 'iic-impact-submission',
        component: InstituteIicSubmissionComponent,
        //canActivate: [BeforeLoginService],
      },

      {
        path: 'join-us',
        component: JoinUsWhatsappComponent,
        //canActivate: [InstituteDetailsSubmittedService],
      },

      {
        path: 'activity/monthly-report-of-council/:event_id',
        component: InstituteActivitySecondFormComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },

      {
        path: 'edit-activity/:event_id',
        component: EditActivityAdminComponent,
      },

      {
        path: 'collaboration',
        component: CollaborationComponent,
        //canActivate: [BeforeLoginService],
      },
      {
        path: 'teaching-learning',
        component: TeachingLearningComponent,
        //canActivate: [BeforeLoginService],
      },
      {
        path: 'collaboration-add/:rid',
        component: CollaborationAddComponent,
        //canActivate: [BeforeLoginService],
      },
      {
        path: 'teaching-learning-add/:rid',
        component: TeachingLearningAddComponent,
        //canActivate: [BeforeLoginService],
      },
      {
        path: 'teaching-nonacademic-add/:rid',
        component: TeachingNonacademicAddComponent,
        //canActivate: [BeforeLoginService],
      },      
      {
        path: 'teaching-learning2',
        component: TeachingLearning2Component,
        //canActivate: [BeforeLoginService],
      },

      {
        path: 'activity/monthly_report/:event_id',
        component: ReportFormComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'impactLecture/report/:session_id/:type/:session',
        component: ImpactLecturesReportFormComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      // {
      //   path: 'activity/monthly_report',
      //   component: ReportFormComponent,
      //   canActivate: [InstituteDetailsSubmittedService],
      // },

      {
        path: 'bank-form/:id',
        component: InstituteBankFormComponent,
      },
      {
        path: 'download',
        component: InstituteDownloadComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'downloads-2022',
        component: Downloads2022Component,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'downloads-2023',
        component: Downloads2023Component,
        canActivate: [InstituteDetailsSubmittedService],
      },
      
      {
        path: 'ia-activity-progress',
        component: IaActivityReportComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'institutes-list',
        component: InstitutesListComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'institute-mm-dashboard/:rid',
        component: InstituteMmDashboardComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'innovation-repository',
        component: InstituteYic2021Component,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'innovation-repository-add/:rid',
        component: InstituteInnovationAddComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'innovation-repository-team-details/:teamid',
        component: InstituteInnovationTeamDetailsComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'news-corner',
        component: InstituteNewsCornerComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },

      {
        path: 'announcement',
        component: InstituteAnnouncementComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'announcement/nomination',
        component: CoordinatorComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'announcement/nominate-representative',
        component: NominateCoordinatorComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },

      {
        path: 'announcement/nomination-add/:rid',
        component: CoordinatorAddComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'request-logo',
        component: InstituteRequestLogoComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'suggestion',
        component: InstituteSuggestionComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },

      {
        path: 'suggestion-new',
        component: InstituteSuggestionNewComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'dashboard2020-21',
        component: InstituteDashboard2021Component,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'dashboard2021-22',
        component: InstituteDashboard2022Component,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'dashboard2022-23',
        component: InstituteDashboard2023Component,
        // canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'dashboard2023-24',
        component: InstituteDashboard2024Component,
        // canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'dashboard2024-25',
        component: InstituteDashboard2025Component,
        // canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'Internship',
        component: InternshipComponent,
        // canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'atl-school-list',
        component: InstituteAtalSchoolComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'selected-schools',
        component: InstituteAtalMentorScheduleComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'atl-activity-view-submission/:activity_id',
        component: AtlActivityViewSubmissionComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'atl-invite-iic-activity',
        component: AtlInviteIicComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'profile',
        component: InstituteProfileComponent,
      },
	    {
        path: 'repository',
        component: RepositoryComponent,
      },
      {
        path: 'repository-add/:rid',
        component: RepositoryAddComponent,
      },
      {
        path: 'yukti-challenges',
        component: YuktiChallengesComponent,
      },
      {
        path: 'yukti-challenges-add/:rid',
        component: YuktiChallengesAddComponent,
      },
      {
        path: 'view-submission/:rid',
        component: ViewSubmissionComponent,
      },
      {
        path: 'yukti-innovation-contest',
        component: InnovationContestComponent,
      },
      {
        path: 'yukti-innovator-form',
        component: YuktiInnovatorFormComponent,
      },
      {
        path: 'ipr-innovator-form',
        component: IprInnovatorFormComponent,
      },
      {
        path: 'transfer-existing-innovator',
        component: TransferExistingInnovatorFormComponent,
      },
      {
        path: 'subiic',
        component: SubiicComponent,
      },
      {
        path: 'subiic-add/:rid',
        component: SubiicAddComponent,
      },
      {
        path: 'subiic-existing/:rid',
        component: SubiicExistingComponent,
      },
      {
        path: 'iic-atl-nominated-coordinator',
        component: NominatedIicAtlCoordinatorListComponent,
      },
      
      {
        path: 'evaluator-add/:rid',
        component: InstituteEvaluatorAddComponent,
      },
      {
        path: 'evaluator-edit/:rid',
        component: EvaluatorEditComponent,
      },
	  {
        path: 'iic-achievement',
        component: IicAchievementComponent,
      },
      {
        path: 'activity/testimonial',
        component: InstituteTestimonialComponent,
      },
      {
        path: 'activity/testimonial-add/:rid',
        component: InstituteActivityTestimonialComponent,
      },

      {
        path: 'referral-institutes',
        component: InstituteReferalProgramComponent,
      },
      {
        path: 'add-referral-institute/:rid',
        component: InstituteReferalProgramAddComponent,
      },
      {
        path: 'edit-referral-institute/:rid',
        component: InstituteReferalProgramEditComponent,
      },
      {
        path: 'affiliate-institutes',
        component: InstituteAffiliateProgramComponent,
      },
      {
        path: 'affiliate-institutes-pending',
        component: InstituteAffiliateProgramPendingComponent,
      },
      {
        path: 'add-affiliate-institute/:rid',
        component: InstituteAffiliateProgramAddComponent,
      },
      {
        path: 'edit-affiliate-institute/:rid',
        component: InstituteAffiliateProgramEditComponent,
      },
      {
        path: 'akam-activity-report/:rid',
        component: InstituteIicAkamComponent,
      },
      {
        path: 'institute-idea-evaluation/:rid',
        component: InstituteIdeaEvaluationComponent,
      },
      {
        path: 'institute-innovation-evaluation/:rid',
        component: InstituteInnovationEvaluationComponent,
      },
      {
        path: 'institute-startup-evaluation/:rid',
        component: InstituteStartupEvaluationComponent,
      },
	   {
        path: 'self-driven-activity-add',
        component: SelfDriveActivityAddComponent,
      },
      {
        path: 'memberCertificateList',
        component: MemberCertificateListComponent,
      },
      {
        path: 'contact-us',
        component: InstituteContactUsComponent,
      },
      {
        path: 'activity-scheduled',
        component: AllActivityScheduledComponent,
      },
      {
        path: 'dashboard',
        component: InstituteDashboardComponent,
      },
      {
        path: 'dashboard-downloads',
        component: DashboardActivityListComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },

      {
        path: 'downloads-2021',
        component: Downloads2021Component,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'downloads-2020',
        component: Downloads2020Component,
        canActivate: [InstituteDetailsSubmittedService],
      },


      {
        path: 'dashboard/18-19',
        component: InstituteDashboard1819Component,
        canActivate: [CheckPerformanceCardService],
      },
      {
        path: 'activity-report/18-19',
        component: Dashboard1819ActivityListComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'announcements/18-19',
        component: Dashboard1819AnnouncementsComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'myRanking/18-19',
        component: Dashboard1819MyRankingComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'notification/18-19',
        component: Dashboard1819NotificationsComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'star-performers/18-19',
        component: Dashboard1819StarPerformerComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'bifurcation',
        component: BifurcationScoreComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'online-session-list',
        component: OnlineSessionListComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'certificates',
        component: CertificatesComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      // {
      //   path: 'members1819_orientationDetails',
      //   component: Members1819Component,
      // },
      {
        path: 'ambassador',
        component: InstituteAmbassadorComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'training-status',
        component: NominationsComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'nomination',
        component: TrainingStatusComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'studentnomination',
        component: StudentTrainingStatusComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'impactLectures',
        component: ImpactLecturesComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'mentor-mentee',
        component: MentorNavbarComponent,
        canActivate: [InstituteDetailsSubmittedService],
      },
      {
        path: 'state-council',
        component: InstituteStateIicListComponent
      },
      {
        path: 'yukti-nominated-challenges',
        component: YuktiNominatedChallengesComponent
      },
      {
        path: 'institute-nominated-challenges',
        component: InstituteChallengeNominationComponent
      },
      {
        path: 'yukti-institute-challenges',
        component: YuktiInstituteChallengesComponent
      },
      {
        path: 'yukti-institute-nominate-challenges',
        component: YuktiInstituteNominateChallengesComponent
      },
      {
        path: 'yukti-institute-nominate-challengesNew',
        component: YuktiInstituteNominateChallengesNewComponent
      },
      {
        path: 'transfered-innovation-list',
        component: TransferExistingInnovatorListComponent
      },
      {
        path: 'transfered-innovation-with-existing',
        component: TransferInnovationWithRegistrationComponent
      },
      {
        path: 'yukti-rm-nomination',
        component: YuktiRmNominationComponent
      },
      {
        path: 'manthan-user',
        component: ManthanUserComponent
      },
      
    ],
  },
  {
    path: 'registration-for-IA',
    component: OnlineRegistrationComponent,
  },
  {
  path: 'nomination-for-IA',
  component: RegistrationOnlineSessionComponent,
  },
  {
    path: 'for-IA-nomination',
    component: RegistrationOnlineSessionComponent,
  },
  {
    path: 'onlinetraining-for-IA',
    component: OnlineSessionLoginComponent,
  },
  {
    path: 'incubators',
    component: IncubatorsComponent,
  },
  {
    path: 'micoutcome',
    component: MicOutcomeComponent,
  },
  {
    path: 'details-page-IA',
    component: OnlineSessionDetailsComponent,
  },
  {
    path: 'osia-request-reset',
    component: OsiaRequestResetComponent,
  },
  {
    path: 'preincubators',
    component: PreincubatorsComponent,
  },
  {
    path: 'video-page-IA/:ses_id',
    component: OnlineSessionVideoComponent,
  },
  {
    path: 'iic202021-calender',
    component: Iic202021CalenderComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic201920',
    component: Iic201920Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic202021',
    component: Iic202021Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'impect-lecture-series2021',
    component: IicImpactLectureSeriesNewComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'impect-lecture-series2023',
    component: IicImpactLectureSeries2023Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'international-exposure',
    component: InternationalExposureComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'india-canada',
    component: IndiaCanadaComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'india-south-korea',
    component: IndiaSouthKoreaComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-download',
    component: IicDownloadComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic-notification',
    component: IicNotificationComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic_rating_2018_19',
    component: IicRatings1819Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic_rating_2019_20',
    component: IicRatings1920Component,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'iic_rating_2019_20-list/:zone',
    component: IicRatings1920ListComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'mentor-mentee-program-old',
    component: MentorMenteeProgramComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'mentor-mentee-program',
    component: MentorMenteeProgramNewComponent,
    //canActivate: [BeforeLoginService],
  },
  {
    path: 'micoutcome',
    component: MicOutcomeComponent,
  },
  {
    path: 'student-innovation-video',
    component: StudentInnovationVideoComponent,
  },
  
  {
    path: 'poster',
    component: PosterComponent,
    children: [
      {
        path: 'poster-chennai',
		component: PosterChennaiComponent,
      },
	  {
        path: 'poster-bengaluru',
		component: PosterBengaluruComponent,
      },
	  {
        path: 'poster-chandigarh',
		component: PosterChandigarhComponent,
      },
	  {
        path: 'poster-dindigul',
		component: PosterDindigulComponent,
      },
	  {
        path: 'poster-jaipur',
		component: PosterJaipurComponent,
      },
	  {
        path: 'poster-kochi',
		component: PosterKochiComponent,
      },
	  {
        path: 'poster-lucknow',
		component: PosterLucknowComponent,
      },
	  {
        path: 'poster-gallery',
		component: PosterGalleryComponent,
      },
	 
	  
      
    ],
  },
  {
    path: 'innovation-council',
    component: InnovationCouncilComponent,
    children: [
      {
        path: 'iic',
        component: IicComponent,
      },
      {
        path: 'Why_heis',
        component: WhyHeisComponent,
      },
      {
        path: 'iic_advisory_committee',
        component: IicAdvisoryCommitteeComponent,
      },
      {
        path: 'innovationcontest',
        component: InnovationcontestComponent,
      },
      {
        path: 'innovation_ambassador',
        component: InnovationAmbassadorComponent,
      },
      {
        path: 'iic_rating_2018_19',
        component: IicRatingComponent,
      },
      {
        path: 'iic_team',
        component: IicTeamComponent,
      },
      {
        path: 'iicwebinar',
        component: IicwebinarComponent,
      },
    ],
  },
  {
    path: 'InnovationRanking',
    component: InnovationRankingComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    children: [
      {
        path: 'About_MHRDnnovation',
        component: AboutMHRDnnovationComponent,
      },
      {
        path: 'About_Message',
        component: AboutMessageComponent,
      },
      {
        path: 'leadership_team',
        component: LeadershipTeamComponent,
      },
      {
        path: 'contact_us',
        component: ContactUsComponent,
      },
    ],
  },

  {
    path: 'hackathon',
    component: HackathonComponent,
    children: [
      {
        path: 'sih',
        component: SihComponent,
      },
      {
        path: 'singapore_india_hackathon_2019',
        component: SingaporeIndiaHackathonComponent,
      },
    ],
  },
  {
    path: 'start-up-policy',
    component: StartUpPolicyComponent,
    children: [
      {
        path: 'startup_policy_committee',
        component: StartupPolicyCommitteeComponent,
      },
      {
        path: 'startup_application',
        component: StartupApplicationComponent,
      },
      {
        path: 'nominate_faculty_for_nisp',
        component: NominateFacultyForNispComponent,
      },
      {
        path: 'startup_policy',
        component: StartupPolicyComponent,
      },
    ],
  },
  {
    path: 'leadership-talk',
    component: LeadershipTalkComponent,
    children: [
      {
        path: 'iflts_upcoming',
        component: IfltsUpcomingComponent,
      },
      {
        path: 'iflts_past',
        component: IfltsPastComponent,
      },
    ],
  },

  /*{
    path: 'gallery',
    component: GalleryComponent,
    children: [
      {
        path: 'video-gallery',
        component: GalleryVideoComponent,
      },
	  {
        path: 'photo-gallery',
        component: GalleryPhotoComponent,
      },
	  {
		path: 'sih-gallery-photo',
		component: SihGalleryPhotoComponent,
	  },
	  {
		path: 'ahmedabad-photo-gallery',
		component: PhotoGalleryAhmedabadComponent,
	  },
	  {
		path: 'bangalore-photo-gallery',
		component: PhotoGalleryBangaloreComponent,
	  },
	  {
		path: 'chandigarh-photo-gallery',
		component: PhotoGalleryChandigarhComponent,
	  },
	  {
		path: 'guwahati-photo-gallery',
		component: PhotoGalleryGuwahatiComponent,
	  },
	  {
		path: 'hyderabad-photo-gallery',
		component: PhotoGalleryHyderabadComponent,
	  },
	  {
		path: 'jaipur-photo-gallery',
		component: PhotoGalleryJaipurComponent,
	  },
	  {
		path: 'kolkata-photo-gallery',
		component: PhotoGalleryKolkataComponent,
	  },
	  {
		path: 'kochi-photo-gallery',
		component: PhotoGalleryKochiComponent,
	  },
	  {
		path: 'lucknow-photo-gallery',
		component: PhotoGalleryLucknowComponent,
	  },
	  {
		path: 'madurai-photo-gallery',
		component: PhotoGalleryMaduraiComponent,
	  },
	  {
		path: 'nagpur-photo-gallery',
		component: PhotoGalleryNagpurComponent,
	  },
	  {
		path: 'pune-photo-gallery',
		component: PhotoGalleryPuneComponent,
	  },
	  {
		path: 'visakhapatnam-photo-gallery',
		component: PhotoGalleryVisakhapatnamComponent,
	  },
	  {
		path: 'bhopal-photo-gallery',
		component: PhotoGalleryBhopalComponent,
	  },
	  {
		path: 'ntw-2023',
		component: Ntw2023Component,
	  },
	  
      {
        path: 'media',
        component: NewsComponent,
      },
    ],
  },*/
  
  {
		path: 'gallery/',
		component: GalleryPhotoComponent,
	},
    {
        path: 'photo-gallery',
        component: GalleryPhotoComponent,	
    },
	{
		path: 'bhopal-photo-gallery',
		component: PhotoGalleryBhopalComponent,
	},
	{
		path: 'chandigarh-photo-gallery',
		component: PhotoGalleryChandigarhComponent,
	},
	{
		path: 'guwahati-photo-gallery',
		component: PhotoGalleryGuwahatiComponent,
	},
	{
		path: 'ahmedabad-photo-gallery',
		component: PhotoGalleryAhmedabadComponent,
	},
	{
		path: 'bangalore-photo-gallery',
		component: PhotoGalleryBangaloreComponent,
	},
	{
		path: 'hyderabad-photo-gallery',
		component: PhotoGalleryHyderabadComponent,
	},
	{
		path: 'jaipur-photo-gallery',
		component: PhotoGalleryJaipurComponent,
	},
	{
		path: 'nagpur-photo-gallery',
		component: PhotoGalleryNagpurComponent,
	},
	{
		path: 'kolkata-photo-gallery',
		component: PhotoGalleryKolkataComponent,
	},
	{
		path: 'pune-photo-gallery',
		component: PhotoGalleryPuneComponent,
	},
	{
		path: 'lucknow-photo-gallery',
		component: PhotoGalleryLucknowComponent,
	},
	{
		path: 'ahmedabad-photo-gallery',
		component: PhotoGalleryAhmedabadComponent,
	},
	{
		path: 'bangalore-photo-gallery',
		component: PhotoGalleryBangaloreComponent,
	},
	{
		path: 'madurai-photo-gallery',
		component: PhotoGalleryMaduraiComponent,
	},
	{
		path: 'kochi-photo-gallery',
		component: PhotoGalleryKochiComponent,
	},
    {
        path: 'video-gallery',
        component: GalleryVideoComponent,
    },
	{
		path: 'visakhapatnam-photo-gallery',
		component: PhotoGalleryVisakhapatnamComponent,
	},
	{
		path: 'ntw-2023',
		component: Ntw2023Component,
	},
	
	{
        path: 'media',
        component: NewsComponent,
    },
	
	{
		path: 'gallery',
		component: GalleryComponent,
		children: [
		  
		  {
			path: 'photo-gallery',
			component: GalleryPhotoComponent,
		  },
		 
		],
	},
  
	{
    path: 'national-technology-week',
    component: NationalTechnologyWeekComponent,
  },
  {
    path: 'home',
    component: LandingComponent,
  },
  {
    path: '**',
    component: LandingComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes)],
})
export class AppRoutingModule { }
