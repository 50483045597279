<h2 mat-dialog-title class="last-login-heading">Last Login</h2>
<div mat-dialog-content class="lastLoginMatBox">
  <table class="table table-hover" style="margin-bottom: 20px !important;">
    <tbody>
        <tr *ngFor="let item of list; let i = index">
            <td>{{i + 1}}</td>
            <td>{{item.created_on}}</td>
        </tr>
    </tbody>
</table>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close class="btn btn-danger instituteBtnClose">Close</button>
</div>