<div class="container">
    <mat-dialog-content class="mat-typography">
        <div class="mt-3">
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <td colspan="7" allign="left" class="danger " style="text-align:center;color:blue;">
                            <h1 style="text-align:center;color:blue;">Reply List</h1>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" style="text-align: center">Sr. No.</th>
                        <th scope="col" style="text-align: center">Message</th>
                        <th scope="col" style="text-align: center">Remark</th>
                        <th scope="col" style="text-align: center">Remark Date</th>
                        <th scope="col" style="text-align: center">Subject</th>
                        <th scope="col" style="text-align: center">Submission Date</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let message of replyList; let i = index;">
                        <td style="text-align: center">{{i+1}}</td>
                        <td style="text-align: center">{{message?.messege}}</td>
                        <td style="text-align: center">{{message?.remark}}</td>
                        <td style="text-align: center">{{message?.remarkdate}}</td>
                        <td style="text-align: center">{{message?.subject}}</td>
                        <td style="text-align: center">{{message?.submission_date}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="row text-center" *ngIf="replyList.length<1">
                <p class="text-center"><i>No records added yet!</i></p>
            </div>
        </div>

        <div style="text-align: center !important">
            <button type="button" class="btn btn-danger instituteBtnClose" mat-dialog-close>Close</button>
        </div>
    </mat-dialog-content>

</div>