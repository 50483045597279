<div class="container">
  <div class="row" color="primary">
    <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;"> Inactive-IIC Institute</h2>
  </div>

  <!-- <div class="card">
    <div class="card-title"><p>Inactive-IIC Institute</p></div>
  </div> -->

  <form [formGroup]="form2" (ngSubmit)="onSubmit(form2.value)" >
    <div class="row" style=" margin-bottom: 20px; ">
      <div fxFlex="40" class="m-2">
          <mat-label style="margin-left: 28px; margin-right: 102px;">Preference Dropdown from your institute: </mat-label>
        <mat-form-field class="example-full-width nominateMentorBox" appearance="outline">
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <mat-label>Select Preference</mat-label>

              <mat-select formControlName="prefrence" [(ngModel)]="preferenceModel" placeholder="Select Preference" required>
                <mat-option value="1">Mentors within state</mat-option>
                <mat-option value="2">Mentors within zone</mat-option>
                <mat-option value="3">Mentors in other zone</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="20" class="m-2">   <button style="margin-left: 193px;" mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='form2.invalid'>Submit</button></div>
    </div>
  </form>

  <div class="row rws-listpage">
    <div class="col-md-12">
      <!-- <p><a class="btn btn-primary" routerLink='/institute/add-affiliate-institute/add'>Add New</a></p> -->
      <div class="tableBody">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead class="facultyTable">
              <tr>
                <th scope="col">S.No.</th>
                <th scope="col">IIC ID</th>
                <th scope="col">Institute Name</th>
                <!-- <th scope="col">Address</th> -->
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col">President Name & Designation</th>
                <th scope="col">President Email & Mobile </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let nomination of iicrepository; index as i" >
                <td>{{i+1}}</td>
                <td>{{nomination?.userId}}</td>
                <td>{{nomination?.instituteName}}</td>
                <!-- <td>{{nomination?.instituteCity}} {{nomination?.stateName}}</td> -->
                <td>{{nomination?.instituteCity}}</td>
                <td>{{nomination?.name}}</td>
                <!-- <td> -->
                  <!-- <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_email_verification_token!='Verified'">Pending</span>
                  <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_email_verification_token=='Verified'">Accepted</span> -->
                <!-- </td>
                <td>
                  <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='New'">Pending</span>
                  <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='Reopened'">In Process</span>
                  <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='Disapproved'">Disapproved</span>
                  <span *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='Approved'">Approved</span>
                </td> -->
                <td>{{nomination?.spoc_name}}<br>{{nomination?.spoc_designation}}</td>
                <td>{{nomination?.spoc_email}}<br>{{nomination?.spoc_contact}}</td>
                <td>
                  <!-- <a class="btn btn-success" *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='Approved'" target="_blank" style="color: black;"
                  href="institute/institute-mm-dashboard/{{nomination?.reg_userId}}">
                      View Institute Performance
                    </a> -->
                  <a class="btn btn-success" *ngIf="nomination?.userId!='' && nomination?.nominationStatus == 'Nominated'" target="_blank" style="color: black;"
                  href="institute/institute-mm-dashboard/{{nomination?.userId}}">
                      View Institute Performance
                  </a>
                  <p *ngIf="nomination?.nominationStatus">
                    <button mat-raised-button color="warn">
                      {{ nomination?.nominationStatus }}</button>
                  </p> 
                  <p *ngIf="!nomination?.nominationStatus">
                    <button mat-raised-button (click)="onClickSend(nomination)"   color="warn">
                      Send Invitation</button>
                  </p> 
                  <p *ngIf="nomination?.nominationStatus == 'Nominated' || nomination?.nominationStatus == 'Pending'">
                    <button mat-raised-button (click)="onClickRemove(nomination)"   color="warn">
                      Remove Invitation</button>
                  </p> 
                  <!-- <p *ngIf="nomination?.reg_userId!='' &&  nomination?.reg_applicationStatus=='New' && nomination?.reg_email_verification_token!='Verified'">
                    <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn">
                      <mat-icon>edit</mat-icon>
                      Edit</button>
                  </p>
                  <p *ngIf="nomination?.reg_userId!='' && nomination?.reg_applicationStatus=='New' && nomination?.reg_email_verification_token!='Verified'">
                    <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')"   color="warn">
                      <mat-icon>delete</mat-icon>
                      Delete</button>
                  </p>

                  <p *ngIf="nomination?.reg_userId==''">
                    <button mat-raised-button (click)="onClickAddEvent(nomination)"   color="warn">
                      <mat-icon>add</mat-icon>
                      Send Invitation</button>
                  </p> -->

                </td>
              </tr>
              <tr *ngIf="iicrepository?.length== 0" >
                <td colspan="9"> <h3 style="text-align: center">Since you are not a part of Mentor-Mentee / Impact Lecture Scheme this section is not applicable for your Institute.</h3></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>