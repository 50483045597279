import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poc-contest2019',
  templateUrl: './poc-contest2019.component.html',
  styleUrls: ['./poc-contest2019.component.css']
})
export class PocContest2019Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
