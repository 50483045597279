import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IicOnlineSessionsService } from 'src/app/Services/iic-online-sessions.service';
import {MatPaginatorModule} from '@angular/material/paginator';

@Component({
  selector: 'app-iic-ratings1920-list',
  templateUrl: './iic-ratings1920-list.component.html',
  styleUrls: ['./iic-ratings1920-list.component.css']
})
export class IicRatings1920ListComponent implements OnInit {
   currentPageNo: number = 1;
   firstPage = true;
   total_pages : number;
   lastPage: boolean = false;
   zone :any;
   zonalDetails :any;
   pageSizeOptions: number[] = [10, 25,50, 100];
   per_page : number = 10;
   frontCurrentPageNo: number = 0;
   changeData: any;
   data: any;
  constructor(private route: ActivatedRoute,private iicOnlineSessionsService: IicOnlineSessionsService) { 
  this.route.params.subscribe((params) => {
      this.zone = params['zone'];
    });
  }

  ngOnInit(): void {
	  this.getRating1920Data(this.data);
  }
  getRating1920Data(data) {

      this.changeData=data;
      this.iicOnlineSessionsService.get201920Rating(this.zone,this.changeData,this.currentPageNo,this.per_page)
      .subscribe((data) => this.handleResponse(data));
  }
handleResponse(data) {
      this.zonalDetails = [];
    for (let i = 0; i < data['data'].length; i++) {
      this.zonalDetails.push(data['data'][i]);
    }
this.total_pages = data.meta.pagination.total;  
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }
  nextPage(e: any) {
    if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1); 		
	  } else {
		this.currentPageNo = (e.pageIndex+1);  
	  }
	this.per_page = e.pageSize;
    this.getRating1920Data(this.data);
  }
}
