<h1 mat-dialog-title class="text-center instStuDetailsTitle">Progress monitoring cum feedback & Mentoring session</h1>
<div mat-dialog-content>
  <div class="container">
    <form [formGroup]="addActivityForm5" (submit)="onSubmit(addActivityForm5.value)">
	<div class="row">
		<div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">


    <mat-form-field appearance="outline" class="form1Seassion">
      <mat-label>Select Mentee institution</mat-label>
      <mat-select placeholder="mentee" formControlName="mentee_id" required >
        <mat-option *ngFor="let mentee of mentees" [value]="mentee?.mentee_userId">{{mentee?.instituteName}}</mat-option>
        </mat-select>
      <mat-error *ngIf="addActivityForm5.controls.mentee_id.errors?.required">Mentee is required
      </mat-error>

    </mat-form-field>

  </div>
  
  <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
        <mat-form-field appearance="outline" class="form1Seassion">
          <mat-label>Title: Progress monitoring cum feedback & Mentoring</mat-label>
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <input matInput placeholder="Title" formControlName="title" required (keypress)=isCharKey($event)>
          <mat-error *ngIf="addActivityForm5.controls.title.errors?.required">Title is required</mat-error>
        </mat-form-field>
      </div>
	</div>
	
	<div class="row">
		<div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">

        <mat-form-field appearance="outline" class="form1Seassion">
            <mat-label> Meeting Date</mat-label>
            <input matInput readonly [matDatepicker]="picker1" formControlName="session_date" >
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
		
		<div  fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
        <mat-label> Meeting Time</mat-label>
        <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>

    <input readonly [ngxTimepicker]="picker" formControlName="session_time" >
<ngx-material-timepicker #picker placeholder="Session Time"  ></ngx-material-timepicker>
</div>
	</div>
	
      <div class="row">
        

       <div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
          <mat-form-field appearance="outline" class="form1Seassion">
            <!-- <mat-label>Speaker Name</mat-label> -->
            <mat-label>Guest from the Mentor Institution</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input matInput placeholder="Speaker Name" formControlName="speaker_name" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="addActivityForm5.controls.speaker_name.errors?.required">Speaker Name is required</mat-error>
          </mat-form-field>
        </div> 

<div fxFlex="50" fxFlex.lt-sm="100" class="col-md-6">
        <mat-form-field appearance="outline" class="form1Seassion">
          <mat-label>Designation</mat-label>
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
          <input matInput placeholder="Designation" formControlName="designation" required (keypress)=isCharKey($event)>
          <mat-error *ngIf="addActivityForm5.controls.designation.errors?.required">Designation is required</mat-error>
        </mat-form-field>
      </div>		
      </div>


<div class="row">
    
<div fxFlex="50" fxFlex.lt-sm="100" class="col-md-12">
    <mat-form-field appearance="outline" class="form1Seassion">
      <!-- <mat-label>Meeting ID</mat-label> -->
      <mat-label>Online session link</mat-label>
      <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
      <input matInput placeholder="Meeting ID" formControlName="meeting_id" required>
      <mat-error *ngIf="addActivityForm5.controls.meeting_id.errors?.required">Meeting ID is required</mat-error>
    </mat-form-field>
  </div>


  


</div>


    <div class="row">
        <div class="col-lg-4 col-md-10">
            <label>Upload Activity Schedule/Plan:</label>
            <mat-error>PDF(max 2MB)</mat-error>
        </div>

        <div class="col-lg-8 col-md-2 float-right"  *ngIf="!reportStatus">
            <label class="custom-file-label float-right" for="customFile" *ngIf="!report">Choose
                file</label>
            <label class="custom-file-label float-right" for="customFile"
                *ngIf="report">{{report[0]['name']}}</label>
            <input #reportRef type="file" accept="application/pdf" name="report"
                (change)="( report = reportRef.files )" class="custom-file-input float-right" >
        </div>
        <div class="col-lg-8 col-md-2 float-right"  *ngIf="reportStatus">
          <a href="{{baseImageUrl + file_upload}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2">Download Report</button>
              <button type="button" mat-raised-button color="warn" class="ml-3"
                  (click)="reportStatus = !reportStatus">Upload
                  Report</button>
          </a>
      </div>
    </div>


<!-- <div *ngIf="reportStatus"> -->
 
<!-- </div> -->

<div class="row">
      <!-- <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btn btn-danger instituteBtnClose">Close</button> -->
      <!-- <button *ngIf="!edit" mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='addActivityForm.invalid'>Submit</button> -->
      <button mat-raised-button color='primary' class="btn btn-success form1But"
        [disabled]='addActivityForm5.invalid'>Submit</button>
      </div>
    </form>
  </div>

</div>
