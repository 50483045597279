<div class="container-fluid">
  <div class="row" color="primary">
    <h2 class="ss" style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;margin: 0 15px 20px 15px;width:100%;"> YUKTI Innovator's Login Creation / Transfer of Innovation from Old Innovator to new YUKTI User</h2>
  </div>

	<div class="text-right" style="margin-bottom: 1%;">
    <a [routerLink]="['/institute/innovation-repository']" class="btn btn-success">Back</a>
	</div>

  <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
    <table class="table table-hover" style="box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);">
      <!-- <tr>
        <td>
          User Status
        </td>
        <td>
          <mat-radio-group formControlName="user_type" required aria-label="Type of User Status:" (change)="radioChange($event.value)">
            <mat-radio-button value="new_user" class="radioButSignUp" >New User Registered in YUKTI</mat-radio-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="existing_user" class="radioButSignUp">Innovator Registered in YUKTI</mat-radio-button>
            <mat-error *ngIf="iicImpactQuestions.controls.type.errors?.required">
              Type is required
            </mat-error>
          </mat-radio-group>
        </td>
      </tr> -->
      <!-- <tr *ngIf="isRegister">
        <td>
          New Team Lead
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>New Team Lead</mat-label>
            <mat-select autocomplete="off" formControlName="new_team_lead_id" (selectionChange)="onSelectBoxChange($event.value)">
              <mat-option value="{{value.id}}" *ngFor="let value of new_team_lead_data">{{value.team_lead_name}}, {{value.team_lead_email}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr> -->
      
      <tr>
        <td>
          Create/Choose Innovator
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Create/Choose Innovator</mat-label>
            <mat-select formControlName="prefer_type" placeholder="Create/Choose Innovator" (selectionChange)="changeForPreferType($event.value)">
              <mat-option value="old">Choose the Existing Innovator</mat-option>
              <mat-option value="new">Create New Innovator/YUKTI Login</mat-option>
            </mat-select>
            <mat-error *ngIf="iicImpactQuestions.controls.prefer_type.errors?.required">Create/Choose Innovator is required </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="existingInnovation">
        <td>
          Choose the Innovator
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Choose the Innovator</mat-label>
            <mat-select formControlName="innovator" placeholder="Choose the Innovator" (selectionChange)="changeForInnovationUser($event.value)">
              <mat-option value="{{value?.id}}" *ngFor="let value of innovationUserData">{{value?.team_lead_name}} - {{value?.team_lead_email}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isAll">
        <td>
          Name
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input matInput autocomplete="off" placeholder="Name" formControlName="name" type="text" [readonly]="isReadOnly">
            <mat-error *ngIf="iicImpactQuestions.controls.name.errors?.required">Name
              is required
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isAll">
        <td>
          Email Id
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email Id</mat-label>
            <input matInput autocomplete="off" placeholder="Email Id" formControlName="email" type="text" [readonly]="isReadOnly">
            <mat-error *ngIf="iicImpactQuestions.controls.email.errors?.required">Email Id
              is required
            </mat-error>
            <mat-error *ngIf="iicImpactQuestions.controls.email.hasError('invalidEmail')">Invalid
              email address</mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isAll">
        <td>
          Mobile Number
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Mobile Number</mat-label>
            <input matInput autocomplete="off" placeholder="Mobile Number" formControlName="mobile_number" type="text" minlength="10" maxlength="10" (keypress)="isNumKey($event)" [readonly]="isReadOnly">
            <mat-error *ngIf="iicImpactQuestions.controls.mobile_number.errors?.required">Mobile Number is required </mat-error>
            <mat-error *ngIf="iicImpactQuestions.controls.mobile_number.errors?.minlength">Mobile Number should be of 10 digits </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isAll">
        <td>
          Gender
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender" [disabled]="isReadOnly">
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error *ngIf="iicImpactQuestions.controls.gender.errors?.required"> Gender is required </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <tr *ngIf="isAll">
        <td>
          Current Engagement of the Innovator with your Institute
        </td>
        <td>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Current Engagement of the Innovator with your Institute</mat-label>
            <mat-select formControlName="user_type" placeholder="Current Engagement of the Innovator with your Institute" [disabled]="isReadOnly">
              <mat-option value="Student">Student</mat-option>
              <mat-option value="Faculty">Faculty/Staff</mat-option>
              <mat-option value="Alumni">Graduated/Alumni Member</mat-option>
              <mat-option value="Entrepreneur">Entrepreneur at Incubation Unit/Institute</mat-option>
            </mat-select>
            <mat-error *ngIf="iicImpactQuestions.controls.user_type.errors?.required"> Current Engagement of the Innovator with your Institute is required </mat-error>
          </mat-form-field>
        </td>
      </tr>
      <!-- <tr>
        <td>
          Current Engagement with the Institute
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label> Current Engagement with the Institute</mat-label>
            <mat-select autocomplete="off" placeholder="Current Engagement with the Institute" formControlName="user_type">
            <mat-option value="Student">Student</mat-option>
            <mat-option value="Faculty">Faculty/Staff</mat-option>
            <mat-option value="Alumni">Graduated/Alumni Member</mat-option>
            <mat-option value="Entrepreneur">Entrepreneur at Incubation Unit/Institute</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr> -->
      <!-- <tr>
        <td>
          Choose the Innovations that are all needed to be transferred to the above mentioned Innovator
        </td>
        <td>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Choose the Innovations for transfer</mat-label>
            <mat-select autocomplete="off" formControlName="prototype" multiple>
              <mat-option value="{{value.id}}" *ngFor="let value of protoTypeList1">{{value.team_id}} - {{value.idea_title}}, {{value.submission_type}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr> -->
      <tr *ngIf="isAll">
        <td colspan="2">
          <div class="addApplicantBox">
            <div class="applicantHeader">
              <h4>Add/Choose Innovations/ IPR</h4>
              <button class="addBtn" type="button" (click)="addRowforcopyRightCoinventors()">Add More Innovations</button>

            </div>
            <table mat-table [dataSource]="dataSource2" formArrayName="copyright_coinventors">

              <!-- Row definitions -->

              <tr mat-header-row *matHeaderRowDef="displayColumns2"></tr>

              <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns2;"></tr>



              <!-- Column definitions -->
              <ng-container matColumnDef="institute_type">
                <th mat-header-cell *matHeaderCellDef> *New/Existing Innovation</th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
                  <mat-select formControlName="institute_type" placeholder="New/Existing Innovation" (selectionChange)="changeForType(index,$event.value)">
                    <mat-option value="old">Choose Existing Innovation</mat-option>
                    <mat-option value="new">Add New Innovation/IPR</mat-option>
                  </mat-select>
                </td>
              </ng-container>
              <ng-container matColumnDef="prototype">
                <th mat-header-cell *matHeaderCellDef> *Choose the Innovations for transfer</th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" id="prototype-{{index}}" style="display: none;">
                  <mat-select formControlName="prototype" placeholder="Choose the Innovations for transfer" (selectionChange)="changeForProtoType(index,$event.value)">
                    <!-- <mat-option>Select</mat-option> -->
                    <mat-option value="{{value.id}}" *ngFor="let value of protoTypeList1">{{value.team_id}} - {{value.idea_title}}, {{value.submission_type}}</mat-option>
                  </mat-select>
                </td>
              </ng-container>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> *Innovation Title</th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" id="title-{{index}}" style="display: none;" >
                  <input matInput type="text" placeholder="Innovation Title" value="" formControlName="title">
                </td>
              </ng-container>
              <ng-container matColumnDef="type" >
                <th mat-header-cell *matHeaderCellDef> *Innovation Type</th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" id="type-{{index}}" style="display: none;">
                  <mat-select formControlName="type" placeholder="Innovation Type">
                    <mat-option value="1">Idea/PoC</mat-option>
                    <mat-option value="2">Innovation/Prototype</mat-option>
                    <mat-option value="3">Business Model/Startup</mat-option>
                    <mat-option value="4">Intellectual Property Rights (IPR)</mat-option>
                  </mat-select>
                </td>
              </ng-container>

              <ng-container matColumnDef="year_of_development">
                <th mat-header-cell *matHeaderCellDef> *Innovation Development Year </th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index" id="year-{{index}}" style="display: none;">
                  <!-- <input matInput type="text" placeholder="Innovation Development Year" value="" formControlName="year_of_development"> -->
                    <mat-select formControlName="year_of_development" placeholder="Innovation Development Year">
                      <mat-option value="2016-17">2016-17</mat-option>
                      <mat-option value="2017-18">2017-18</mat-option>
                      <mat-option value="2018-19">2018-19</mat-option>
                      <mat-option value="2019-20">2019-20</mat-option>
                      <mat-option value="2020-21">2020-21</mat-option>
                      <mat-option value="2021-22">2021-22</mat-option>
                      <mat-option value="2022-23">2022-23</mat-option>
                      <mat-option value="2023-24">2023-24</mat-option>
                    </mat-select>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
                  <button *ngIf="index!=0" class="deleteBtn"
                    (click)="onDeleteRowforCopyrightCoinventors(index)"><mat-icon>delete</mat-icon>Delete</button>
                </td>
              </ng-container>


            </table>


          </div>
        </td>
      </tr>
      <tr *ngIf="isAll">
        <td>
          &nbsp;
        </td>
        <td>
          <button mat-raised-button color='primary' class="btn btn-success float-right" [disabled]='iicImpactQuestions.invalid'>Submit</button>
        </td>
      </tr>
    </table>
	</form>
</div>
