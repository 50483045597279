import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ActivityProposalDialogComponent } from '../activity-proposal-dialog/activity-proposal-dialog.component';
import * as myGlobals from '../../../../../../../../app.component';
@Component({
  selector: 'app-activity-form5',
  templateUrl: './activity-form5.component.html',
  styleUrls: ['./activity-form5.component.css']
})
export class ActivityForm5Component implements OnInit {

  edit: boolean;
  editData: any;
  id: any;
  report: FileList | null;
  public addActivityForm5: FormGroup;
  date1:any;
  file_upload: any;
  reportStatus: boolean;
  mentees:any[];
  baseImageUrl = myGlobals.baseImageUrl2;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ActivityProposalDialogComponent>,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public activity: InstituteActivityService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.edit = false;

      if (this.data['activity_id']!= 'undefined' || this.data['activity_id']!= null) {
        this.edit = true;
        
        this.id = this.data['activity_id'];
        this.getSchedule();
      }
      else {
        this.edit = false;
      }
     }

  ngOnInit(): void {
   
     this.addActivityForm5 = this.fb.group({
      'session_date': [null,Validators.required],
      'session_time': [null,Validators.required],
      'title':[null],
      'speaker_name': [null],
      'designation':[null],
      'meeting_id':[null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      'mentee_id':[null],
     });
   
    
    
     this.activity.getMenteeTable().subscribe(data=>this.setMentee(data));



  }
  getSchedule(){
    this.activity.getSchedule(this.id).subscribe(data=>this.setData(data));
  }
  setMentee(data){
    
    this.mentees=[];
    for(let i=0;i<data['data'].length;i++){
      this.mentees.push(data['data'][i])
    }    
  }
  setData(data){
    this.editData=data;
    this.addActivityForm5.controls['session_date'].setValue(new Date(data['Activity']['scheduled_date']));
    this.addActivityForm5.controls['session_time'].setValue(data['Activity']['scheduled_time']);
   this.addActivityForm5.controls['speaker_name'].setValue(data['Activity']['speaker_name']);
   this.addActivityForm5.controls['designation'].setValue(data['Activity']['speaker_designation']);   
    this.addActivityForm5.controls['title'].setValue(data['Activity']['title']);
    this.addActivityForm5.controls['meeting_id'].setValue(data['Activity']['meeting_id']);
    this.addActivityForm5.controls['mentee_id'].setValue(data['Activity']['mentee_id']);
    this.file_upload=data['Activity']['file_upload'];
if(this.file_upload!=null){
  this.reportStatus=true;
}
 }
 
 

  onSubmit(data){
   

    var cvFile =
      this.report && this.report.length ? this.report[0] : null;

    var formData = new FormData();

    formData.append('file', cvFile);
    formData.append('activityid', this.id);
    formData.append('session_date',this.changeDate(this.addActivityForm5.get('session_date').value));
    formData.append('speaker_name',this.addActivityForm5.get('speaker_name').value);
    formData.append('session_time',this.addActivityForm5.get('session_time').value);
    formData.append('designation',this.addActivityForm5.get('designation').value);
    formData.append('title',this.addActivityForm5.get('title').value);
    formData.append('meeting_id',this.addActivityForm5.get('meeting_id').value);
    formData.append('mentee',this.addActivityForm5.get('mentee_id').value);
    formData.append('id','5');
    
     if (!this.edit) {
      this.activity
        .schedule(formData)
        .subscribe((data) => this.handleResponse(data));}
    
    else {
      
    formData.append('file_upload_hidden',this.editData['Activity']['file_upload']);
      this.activity
        .editSchedule(formData)
        .subscribe((data) => this.handleResponse(data));
    }

  }
  handleResponse(data){
    if(data['message']=="Success"){
      this.toastr.success("Successfully Submitted");
      this.dialogRef.close();
    } 
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }


}
