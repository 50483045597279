import { Component, OnInit } from '@angular/core';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-institutes-list',
  templateUrl: './institutes-list.component.html',
  styleUrls: ['./institutes-list.component.css']
})
export class InstitutesListComponent implements OnInit {
  instituteList: any[];

  constructor(private innovationService: InstituteInnovationContestService) { }

  ngOnInit(): void {
    this.getData();
  }

getData(){
  this.innovationService
  .getIARegStatus()
  .subscribe((data) => this.handleResponse(data));
}

handleResponse(data){
  this.instituteList=[];
for(let i=0;i<data.length;i++){
  this.instituteList.push(data[i])
}
}
}
