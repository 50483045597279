<!--title-->
   <title>

    Start up | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>


<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Start Up Policy</a>
      </li>
      <li class="aht-breadcrumbs__item">
        Startup Policy 2019
      </li>
    </ul>
  </div>
	
	<section class="paddsection">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="titleheadertop">

                  <h1 class="inner-theme-heading">National Innovation and Startup Policy 2019 for Students & Faculty</h1>

                </div>

            </div>

        </div>



        <div class="row rowmar">

            <div class="col-md-6">

                <div class="imagesbox rankingimg">

                  <img src="{{imageBasePath}}assets/wp_images/startup1.jpg">

                </div>

            </div>



            <div class="col-md-6">

                <div class="textboxsih rankingmar">

                    <div class="grediyant">

                  <h4>A Guiding Framework for Higher Educational Institutions</h4>

              </div>

                </div>



                <div class="rankingtext">

                    <p><strong>Background:</strong></p>
<p>Indian higher education system is considered as one of the largest in the world in terms of numbers of higher educational institutions (HEIs) and stands among top three of largest start up ecosystem.  In last 10 years, Indian higher education system has grown by 79%. Presently, India has more than 41435 HEIs with annual enrolment of 28484746 students.</p>

                </div>

            </div>

        </div>



        <div class="row">

            <div class="col-md-12">

                <div class="rankingtextwidt">

                <p>When India aspires to become 5 trillion GDP economy by 2024, it is very important to achieve excellency in both quantitative and qualitative growth of education system by making it globally relevant, competitive and industry fit while simultaneously focusing on self-employment through sheer emphasis on innovation, skills and entrepreneurship among students and faculty. This can be achieved by taking innovation, technology and start up as key instruments to gear up the socio-economic development process and adopting reformative policy approaches to get out of the Job Seeker tag and making HEIs innovative and entrepreneurial in function. This will certainly help to produce more innovators and entrepreneurs, further, improving its positioning in global ranking for quality of higher education from the current 24th and also moving up in the Global Innovation Index from current position of 52nd in 2019 to among top 30.<br />
<strong><br />
Introduction:</strong><br />
Entrepreneurship and Start-up Policies play a vital role in the economic and social development of a nation. In fast developing economics like India, these polices are critical and very important for innovators, entrepreneurs and startups in overcoming barriers while trying to market their innovation and growth of startups from scratch to scale. Higher Educational institutes in general and technical institutes in particular, play an imperative role in shaping the startup movement for a country like India where the large section of young talents are in their academics.<br />
Ministry of Human Resource Development and apex regulatory bodies such as University Grant Commission (UGC) and All India Council of Technical Education (AICTE) together have designed a more inclusive version with detailed guidelines on the ‘National Innovation and Start-up Policy 2019 for students and faculty’ for implementation and adoption in both technical and non-technical HEIs across the country. Hon’ble minister of Human Resource Development has launched the ‘National Innovation and Start-up Policy 2019 for students and faculty’ on 11th September 2019 at AICTE, New Delhi. The Policy will be implemented by MHRD’s Innovation Cell and in coordination AICTE, UGC, state governments &amp; UTs and universities, policy will be taken to ground for quick adoption by HEIs.<br />
This journey began on 16th November 2016, when AICTE launched the first version of policy in the hands of the then Hon’ble President of India Shri Pranab Mukherjee aiming at 10,000 AICTE approved technical institutions spread in almost 600+ districts of the country and approximately 80 lakh technical students and 7 lakh faculty members in accordance with Government of India’s “Start-up India” Action plan launched on 16th January 2016. The present policy is a way forward to the earlier version of AICTE’s Start-up Policy being implemented by the AICTE Start-up Policy Implementation Committee. The present policy highlights various important and practical aspects of promoting and supporting innovation, technology commercialisation and startup in academic setup. It delineates pre-incubation and incubation support system in campus, incentives for faculty and students involved in innovation and startup, and suggests mechanisms in the areas of IP ownership, revenue sharing, equity sharing between institutes and incubated start-ups.</p>

                </div>

            </div>

        </div>



        <div class="row">

            <div class="col-md-6">

              <div class="rankingtextwidt">

                <p><strong>Policy Objectives:</strong><br />
The National Innovation and Start-up Policy 2019 intends to guide HEIs to promote student driven innovations &amp; start-ups and to engage the students and faculty in innovation and start up activities in campus. The policy aims at enabling HEIs to build, streamline and strengthen the innovation and entrepreneurial ecosystem in campus and will be instrumental in leveraging the potential of science, student’s creative problem solving and entrepreneurial mind-set, and promoting a strong intra and inter-institutional partnerships with ecosystem enablers and different stakeholders at regional, national and international level. The entrepreneurial ecosystem in HEIs will play key role in identifying, mentoring, nurturing innovative and entrepreneurial potential of students, faculty and staff and transforming them into start-up entrepreneurs by provided avenues of funding, investment opportunities and networking support to make the innovation and venture successful.</p>

                </div>

            </div>

                <div class="col-md-6">

                    <div class="textranking2">

                    <img src="{{imageBasePath}}assets/wp_images/Picture1.png">

                </div>

                </div>

            </div>

        </div>

</section>



<section class="sectionbutton">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="textbotton">

                    <h4>National Innovation and Start up Policy 2019 for students and faculties <a href="assets/doc/startup_policy_2019.pdf">Download Here</a></h4>

                </div>

            </div>

        </div>

    </div>

</section>


<!-- Footer -->
<app-inner-footer></app-inner-footer>
