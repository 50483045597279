<div class="container">
	<h1 class="announcementHeadingMain">IIC Impact Registration</h1>
  <form [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
    <table class="table table-hover">

      <tr>
        <th><h3>1. Institute has established the Incubation/Preincubation facility after consitution of IIC (Post 2018)?</h3></th>
        <td colspan="3"><select name="" id="" formControlName="que1" required class="formControl">
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select></td>
      </tr>
      <tr><td colspan="4"><h3>2. Funding allotment for innovation & Enterpreneuship related programs and activities</h3></td></tr>
      <tr>
            <th class="thSub">From Institute budget (Amount in Rupees)</th>
            <td>2018-19
              <input type="text" formControlName="funding_allot_from_inst_bud_fy_18_19" (keypress)=isNumberKey($event) required class="formControl">
            </td>
            <td>2019-20
              <input type="text" formControlName="funding_allot_from_inst_bud_fy_19_20" (keypress)=isNumberKey($event) required class="formControl">
            </td>
            <td>2020-21
              <input type="text" formControlName="funding_allot_from_inst_bud_fy_20_21" (keypress)=isNumberKey($event) required class="formControl">
            </td>
        </tr>
        <tr>
          <th class="thSub">From External source (Amount in Rupees)</th>
          <td>2018-19
            <input type="text" formControlName="funding_allot_from_ext_source_fy_18_19" (keypress)=isNumberKey($event) required class="formControl">
          </td>
          <td>2019-20
            <input type="text" formControlName="funding_allot_from_ext_source_fy_19_20" (keypress)=isNumberKey($event) required class="formControl">
          </td>
          <td>2020-21
            <input type="text" formControlName="funding_allot_from_ext_source_fy_20_21" (keypress)=isNumberKey($event) required class="formControl">
          </td>
      </tr>

      <tr><td colspan="4"><h3>3. Number of Startups established</h3></td></tr>
        <tr>
            <th class="thSub">Registered</th>
            <td colspan="3">
              <input type="text" formControlName="no_of_startup_register" (keypress)=isNumberKey($event) required class="formControl">
            </td>

        </tr>
        <tr>
          <th class="thSub">Yet to be Registered</th>
          <td colspan="3">
            <input type="text" formControlName="no_of_startup_yet_to_be_register" (keypress)=isNumberKey($event) required class="formControl">
          </td>

      </tr>
      <tr>
        <th class="thSub">Revenue generated by startup (Amount in Rupees)</th>
        <td>2018-19
          <input type="text" formControlName="no_of_startup_revenue_generated_fy_18_19" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>
          2019-20
          <input type="text" formControlName="no_of_startup_revenue_generated_fy_19_20" (keypress)=isNumberKey($event) required class="formControl">
        </td>
        <td>2020-21
          <input type="text" formControlName="no_of_startup_revenue_generated_fy_20_21" (keypress)=isNumberKey($event) required class="formControl">
        </td>

    </tr>
    <tr>
      <th class="thSub">Funding/Inverstment Received by startup(From all sources) (Amount in Rupees)</th>
      <td>2018-19
        <input type="text" formControlName="no_of_startup_fund_receive_fy_18_19" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>
        2019-20
        <input type="text" formControlName="no_of_startup_fund_receive_fy_19_20" (keypress)=isNumberKey($event) required class="formControl">
      </td>
      <td>2020-21
        <input type="text" formControlName="no_of_startup_fund_receive_fy_20_21" (keypress)=isNumberKey($event) required class="formControl">
      </td>
  </tr>
  <tr><td colspan="4"><h3>4. Number of patents</h3></td></tr>
      <tr>
        <td class="thSub">Filed</td>
        <td colspan="3"><input type="text" formControlName="no_of_patent_failed" (keypress)=isNumberKey($event) required class="formControl"></td>
      </tr>
      <tr>
        <td class="thSub">Granted</td>
        <td colspan="3"><input type="text" formControlName="no_of_patent_granted" (keypress)=isNumberKey($event) required class="formControl"></td>
      </tr>

      <tr><td colspan="4"><h3>5. Number of copyright</h3></td></tr>
      <tr>
        <td class="thSub">No. of copyrights filed</td>
        <td colspan="3"><input type="text" formControlName="no_of_copyright_filed" (keypress)=isNumberKey($event) required class="formControl"></td>
      </tr>
      <tr>
        <td class="thSub">No. of copyrights Granted</td>
        <td colspan="3"><input type="text" formControlName="no_of_copyright_granted" (keypress)=isNumberKey($event) required class="formControl"></td>
      </tr>



      <tr>
        <th><h3>6. IIC created I&E ecosystem in your campus since 2018</h3></th>
        <td>Strongly Agree
          <input type="radio" formControlName="iic_createdi_e_campus_2018" value="StronglyAgree" required>
        </td>
        <td>
          Agree
          <input type="radio" formControlName="iic_createdi_e_campus_2018" value="Agree" required>
        </td>
        <td>Disagree
          <input type="radio" formControlName="iic_createdi_e_campus_2018" value="Disagree" required>
        </td>
    </tr>

    <tr>
      <th><h3>7. Started Offering Academic Credit Core/Elective Subject on Entrepreneurship/Innovation/IPR</h3></th>
      <td>Strongly Agree
        <input type="radio" formControlName="que7" value="StronglyAgree" required>
      </td>
      <td>
        Agree
        <input type="radio" formControlName="que7" value="Agree" required>
      </td>
      <td>Disagree
        <input type="radio" formControlName="que7" value="Disgree" required>
      </td>
  </tr>

  <tr>
    <th><h3>8. IIC Network benefited us to collaborate with other Institute in terms of knowledge and infra</h3></th>
    <td>Strongly Agree
      <input type="radio" formControlName="que8" value="StronglyAgree" required>
    </td>
    <td>
      Agree
      <input type="radio" formControlName="que8" value="Agree" required>
    </td>
    <td>Disagree
      <input type="radio" formControlName="que8" value="Disagree" required>
    </td>
</tr>
<tr>
  <th><h3>9. Institute have realized the importance the role of dedicated infrastructure & facilities<p class="thSub">(Pre-Incubation, Incubation etc in campus to Promote Innovation & Entrepreneurship and IPR through IIC activities)</p></h3></th>
  <td>Strongly Agree
    <input type="radio" formControlName="que9" value="StronglyAgree" required>
  </td>
  <td>
    Agree
    <input type="radio" formControlName="que9" value="Agree" required >
  </td>
  <td>Disagree
    <input type="radio" formControlName="que9" value="Disagree" required>
  </td>
</tr>

<tr><td colspan="4"><h3>10. Participated in National/International level hackathon/Any competition</h3></td></tr>
      <tr>
        <td class="thSub">Number of Ideas Submitted</td>
        <td colspan="3"><input type="text" formControlName="participeted_no_idea_submit" (keypress)=isNumberKey($event) required class="formControl"></td>
      </tr>
      <tr>
        <td class="thSub">Number of Students Participated</td>
        <td colspan="3"><input type="text" formControlName="participeted_no_of_student" (keypress)=isNumberKey($event) required class="formControl"></td>
      </tr>
      <tr>
        <td class="thSub">Number of Winners/Awardees</td>
        <td colspan="3"><input type="text" formControlName="participeted_no_of_winer" (keypress)=isNumberKey($event) required class="formControl"></td>
      </tr>
      <tr>
        <th><h3>11. Have you participated in ARIIA Ranking?</h3></th>
        <td colspan="3"><select name="" id="" formControlName="que11" required class="formControl">
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select></td>
      </tr>
      <tr>
        <th><h3>12.	Have you participated in NISP?</h3></th>
        <td colspan="3"><select name="" id="" formControlName="que12" required class="formControl">
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select></td>
      </tr>
      <tr>
        <th><h3>13. Testimonial for the IIC program</h3></th>
        <td colspan="3"><textarea formControlName="testimonials" id="testimonials" cols="30" rows="10" required class="formControl"></textarea></td>
      </tr>
      <tr>
        <th><h3>14. Suggestions, if any</h3></th>
        <td colspan="3"><textarea formControlName="suggestions" id="suggestions" cols="30" rows="10" required class="formControl"></textarea></td>
      </tr>
      <tr>
      <td colspan="4">
      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-2">
          <div fxFlex="50" class="m-2">   <button style="
              margin-left: 603px;
          " mat-raised-button color='primary' class="btn btn-success float-right"
              [disabled]='iicImpactQuestions.invalid'>Submit</button></div>
        </div>
        <div class="col-md-5"></div>

          </div>
	</td>
    </tr>
    </table>
    </form>
</div>
<p>&nbsp;</p><p>&nbsp;</p>
