<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">Innovation Ambassador</h1>	 -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Innovation Ambassador</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Major Activities</a></li>
    <li class="breadcrumb-item active" aria-current="page">Innovation Ambassador</li>
  </ul>
</nav>

<div class="limiter limiterAmbasador">
    <div class="container">
        <div class="row">
            <div class="container-login100">
                <div class="tab-box" id="newContentIncubation">

                    <div class="col-md-4">
                        <a href="https://ia.mic.gov.in/ambassador/sign-up" target="_blank">
						<!--<a> -->
                            <div class="incub-round2">
                                <img src="assets/assets/images/ia-resigster-profile.png" />
                                <p>Register Your Profile</p>
                           </div> 
						   </a>
                        <!-- </a> -->
                    </div>
                    <div class="col-md-4">
                        <a href="/iic-ambassador-profile">
                            <div class="incub-round2">
                                <img src="assets/assets/images/ia-view-profile.png" />
                                <p>View IA Profile</p>
                            </div>
                        </a>
                    </div>
					<div class="col-md-4">
                        <a href="https://ia.mic.gov.in" target="_blank"> 
						<!-- <a> -->
                            <div class="incub-round2">
                                <img src="assets/assets/images/ia-portal-image.png" />
                                <p>Innovation Ambassador Portal</p>
                            </div>
							</a>
                        <!-- </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="cbp-row wh-content innoAmbassador">
			<div class="cbp-container wh-padding">
				
				<div class="vc_row innovationAmbassadorBox">
					<div class="vc_col-md-12">
						<div class="headingAmbassador">
							<h2>IIC Innovation Ambassador Training Series</h2>
						</div>
					</div>
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>Objective</h3>
							<p>IICs at institutes are envisioned an instrumental role to drive innovation and startup ecosystem at campus while connecting with ecosystem enablers at regional and national level. For this, the role of faculty being a member of IIC is very important to play as a mentor or guide young minds in their pursuit of Innovation and entrepreneurship and equally crucial for mentors to have the adequate mentoring skills. Considering the fact that Indian HEIs are lacking in innovation and start-up mentor capacity to guide students on IPR, Innovation and Entrepreneurship, Institutions Innovation council of MoE’s Innovation Cell aims to build mentorship capacity of IIC members through a series of training programs and designate the trained IIC members as "IIC - Innovation Ambassador". IIC-MoE's Innovation Cell has taken a target to train more than 5000 faculties to develop mentoring skills on above four themes by end of 2019-2020 IIC calendar year (by June 2020).</p>
							
							<p>The beneficiaries of these training programs will join the network as "IIC-Innovation Ambassador" and perform the role of mentor in their respective IICs, will provide support to other IICs as resource person in organising various programs related to innovation and startup and spread the message of innovation & start-up among the students and faculties. Further, there will be some incentive opportunities for Innovation ambassadors for the services offered during post training period and to connect directly with MoE's Innovation cell and also reward points for IICs to take part in this process.</p>
							
							<!--<p class="ambassadorBlue">MoE's Innovation Cell invite all IICs, those have upgraded/completed their council as per the IIC2.0 norms to login and take part in pre-training assessment and nominate IIC members for each type of training with city of preference by 25th of November 2019. Upon obtain of nominations, city wise training schedules will be declared by the 2nd week of December 2019 and training programs will be rolled out by the last week of December 2019. Trainings will be organised in different cities on zone wise and in two phases.</p> -->
						</div>
					</div>
				</div>
				<div class="vc_row innovationAmbassadorBox ambPadding">
					<div class="vc_col-md-12">
						<div class="headingAmbassadorContent">
							<h3>Theme</h3>
						</div>
					</div>
					<div class="vc_col-md-3">
						<div class="themeImage1">
							<img src="assets/assets/images/designthinking.png">
						</div>
					</div>
					<div class="vc_col-md-3">
						<div class="themeImage2">
							<img src="assets/assets/images/iprandtt.png">
						</div>
					</div>
					<div class="vc_col-md-3">
						<div class="themeImage3">
							<img src="assets/assets/images/incubationmgmt.png">
						</div>
					</div>
					<div class="vc_col-md-3">
						<div class="themeImage4">
							<img src="assets/assets/images/EDPnew.png">
						</div>
					</div>
				</div>
				
				<div class="vc_row innovationAmbassadorBox ambPadding1">
					<div class="vc_col-md-4">
						<div class="ambBoxesBottom">
							<p>To train 5000</p>
							<p>Innovation ambassador</p>
						</div>
					</div>
					<div class="vc_col-md-4">
						<div class="ambBoxesBottom">
							<p>Increasing in-house</p>
							<p>mentoring capacity</p>
						</div>
					</div>
					<div class="vc_col-md-4">
						<div class="ambBoxesBottom">
							<p> Training on four thrust areas </p>							
						</div>
					</div>
				</div>
				
				
				
				<div class="vc_row-full-width vc_clearfix"></div>
				


			</div>
		</div>


<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>