import { Component, OnInit } from '@angular/core';
import { InstituteAnnouncementService } from 'src/app/Services/institute-announcement.service';
import * as myGlobals from '../../../../app.component';

@Component({
  selector: 'app-institute-announcement',
  templateUrl: './institute-announcement.component.html',
  styleUrls: ['./institute-announcement.component.css'],
})
export class InstituteAnnouncementComponent implements OnInit {
  public downloadAvailable: boolean = true;

  currentPageNo = 1;
  firstPage = true;
  lastPage = false;

  announcements = [];

  baseImageUrl = myGlobals.baseImageUrl;

  images = [];

  page: any;

  constructor(
    private instituteAnnouncementService: InstituteAnnouncementService
  ) { }

  ngOnInit(): void {
    this.getAnnouncements();
  }

  getAnnouncements() {
    this.instituteAnnouncementService
      .getInstituteAnnouncements(this.currentPageNo)
      .subscribe((data) => this.handleResponse(data));
  }

  nextPage() {
    this.currentPageNo++;
    this.getAnnouncements();
  }

  previousPage() {
    this.currentPageNo--;
    this.getAnnouncements();
  }

  handleResponse(data) {
    this.announcements = data['data'];

    this.images = [];

    data['data'].forEach(element => {
      if (element.announcementType == 'Image') {
        this.images.push(element.announcement);
      }
    });

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  public onPageChanged(event) {
    this.page = event;
  }
}
