import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-repository-add',
  templateUrl: './repository-add.component.html',
  styleUrls: ['./repository-add.component.css']
})
export class RepositoryAddComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });



  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'title': [null, Validators.required],
      'description':[null, Validators.required,],
      'theme': [null, Validators.required],
      'organised_on': [null, Validators.required],
      'iic_activity': [null, Validators.required],
      'expert_name': [null, Validators.required],
      'expert_email': [null, Validators.required],
      'expert_contact': [null, Validators.required],
      'expert_designation': [null, Validators.required],
      'expert_org': [null, Validators.required],
      'activity_id': [],
      'tags': [null, Validators.required],
      'video': [null, Validators.required],
      'agree' : [null, Validators.required]
    });

    this.requireField=0;
    this.activity_id=0;

    this.instituteActivityService.getIicActivityList("IIC").subscribe(
      (data) => this.handleResponseList(data)
    );

    this.instituteActivityService.getIicRepositoryDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

    // var parm = JSON.stringify(["program_theme"]);
    // this.instituteMaster
    //   .getMasterDetails(parm)
    //   .subscribe((data) => this.handleResponseForAutoPopulate(data));



  }

  handleResponseForAutoPopulate(data) {

    // this.programTheme = data['data'];
    // if (data['data'][0]) {
    //   data['data'][0].forEach((element) => {
    //     this.programTheme.push(element.data.data.name);
    //   });
    // }

    this.programTheme = "";

  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }


  handleResponse(data) {

    if(data['iic_activity']=="SELF")
    {
      this.requireField=0;
    }
    else
    {
      if(data['iic_activity']=="IIC" || data['iic_activity']=="MIC" || data['iic_activity']=="Celebration")
      {
        this.requireField=1;
        this.instituteActivityService.getIicActivityList(data['iic_activity']).subscribe(
          (data) => this.handleResponseList(data)
        );
      }
    }

    this.activity_id = data['activity_id'];

    if(data['totalcount'] > 0){
      //  alert(data['activity_id']);
    this.iicImpactQuestions.patchValue({
      title: data['title'],
      description: data['description'],
      theme: data['theme'],
      iic_activity: data['iic_activity'],
      activity_id: data['activity_id'],
      tags: data['tags'],
      video: data['video'],
      organised_on: data['organised_on'],
      expert_name: data['expert_name'],
      expert_email: data['expert_email'],
      expert_contact: data['expert_contact'],
      expert_designation: data['expert_designation'],
      expert_org: data['expert_org'],
      agree: data['agree']
    });

    //alert(this.iicImpactQuestions.get('activity_id').value);

    this.document = data['thumbnail'];

  }
    this.totalcount = data['totalcount'];

  }

  handleResponseList(data) {
    this.iicActivity = data['data'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('rid', this.rid);
    formData.append('title', this.iicImpactQuestions.get('title').value);
    formData.append('description', this.iicImpactQuestions.get('description').value);
    formData.append('theme', this.iicImpactQuestions.get('theme').value);
    formData.append('iic_activity', this.iicImpactQuestions.get('iic_activity').value);
    formData.append('activity_id', this.iicImpactQuestions.get('activity_id').value);
    formData.append('tags', this.iicImpactQuestions.get('tags').value);
    formData.append('video', this.iicImpactQuestions.get('video').value);
    formData.append('agree', this.iicImpactQuestions.get('agree').value);
    formData.append('organised_on', this.changeDate(this.iicImpactQuestions.get('organised_on').value));
    formData.append('expert_name', this.iicImpactQuestions.get('expert_name').value);
    formData.append('expert_email', this.iicImpactQuestions.get('expert_email').value);
    formData.append('expert_contact', this.iicImpactQuestions.get('expert_contact').value);
    formData.append('expert_designation', this.iicImpactQuestions.get('expert_designation').value);
    formData.append('expert_org', this.iicImpactQuestions.get('expert_org').value);

    var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    if(this.totalcount>0){

      formData.append('document', cvFile);
      formData.append('document_old', this.document);

    }
    else
    {
      if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
      formData.append('document', cvFile);
    }

    this.instituteActivityService.submitIicRepository(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/repository';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

  onIICStatusChange(ob) {
    let iicStatus = ob.value;
    if(iicStatus == 'SELF') {
      this.requireField = 0;
    } else {
      this.requireField = 1;


      this.instituteActivityService.getIicActivityList(ob.value).subscribe(
        (data) => this.handleResponseList(data)
      );

    }

  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

}
