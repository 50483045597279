import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-india-south-korea',
  templateUrl: './india-south-korea.component.html',
  styleUrls: ['./india-south-korea.component.css']
})
export class IndiaSouthKoreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
