import { Component, OnInit } from '@angular/core';
import { IicDashboard1819Service } from 'src/app/Services/iic-dashboard1819.service';

@Component({
  selector: 'app-iic-dashboard1819-cards',
  templateUrl: './iic-dashboard1819-cards.component.html',
  styleUrls: ['./iic-dashboard1819-cards.component.css']
})
export class IicDashboard1819CardsComponent implements OnInit {
cards: any;
  constructor(private dashboardService: IicDashboard1819Service) { }

  ngOnInit(): void {
	  this.dashboardService.getCardsData().subscribe(
      data => this.cards = data['data']      
    )
  }

}
