import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { saveAs as importedSaveAs } from 'file-saver';


@Component({
  selector: 'app-member-certificate-list',
  templateUrl: './member-certificate-list.component.html',
  styleUrls: ['./member-certificate-list.component.css']
})
export class MemberCertificateListComponent implements OnInit {
  certificate: any[];
  baseurl=''
  encrypted: any;

  constructor(private mycouncilService: InstituteMyCouncilService,) { }

  ngOnInit(): void {
    this.mycouncilService.getCertificateList().subscribe(
      data => this.setCertificateList(data)
    )
  }
  setCertificateList(data) {
    this.certificate = [];
    for (let i = 0; i < data['data'].length; i++) {
      this.certificate.push(data['data'][i])
    }
  }


  getMembertCertificate(data) {
    this.encrypted=data.encrypted;
    this.mycouncilService.getmemberCertificate(this.encrypted).subscribe(
      data => this.downloadFile(data, 'Certificate.pdf')
    );
  }
  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }


}
