import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-institute-mm-dashboard',
  templateUrl: './institute-mm-dashboard.component.html',
  styleUrls: ['./institute-mm-dashboard.component.css']
})
export class InstituteMmDashboardComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  iicD:any;
  rid:any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute,
    private instituteMaster: InstituteInnovationContestService,
    private instituteActivityService :InstituteActivityService,
    private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });
  }


  ngOnInit(): void {

    this.instituteActivityService.getIicInsMMDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );

  }

  handleResponse(data) {
    this.iicD = data['iicDashboard'];
  }

}
