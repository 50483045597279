import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { FileService } from 'src/app/Services/file.service';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../../../../app.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-institute-council-meeting-dialog',
  templateUrl: './institute-council-meeting-dialog.component.html',
  styleUrls: ['./institute-council-meeting-dialog.component.css'],
})

export class InstituteCouncilMeetingDialogComponent implements OnInit {
  @Input() text = 'Upload';
  baseImageUrl = myGlobals.baseImageUrl;
  imageBasePath = myGlobals.imageBasePath;

  public form: {
    date_conducted_meeting: any;
    quarter: string;
    same_as: string;
    resolution: FileList | null;
    quarterly_plan: FileList | null;
    minutes_of_meeting: FileList | null;
    photo_upload: FileList | null;
  };

  fbl: any;
  date_conducted_meeting: any;
  quarter: any;
  id1: any;
  show1: boolean;
  show: boolean;
  photo_upload: any;
  quarterly_plan: any;
  same_as: any;
  minutes_of_meeting: any;
  resolution: any;
  showMinutesofMeeting:boolean;
  showResolution:boolean;
  showCouncilMeeting:boolean;
  showQuaterlyPlan:boolean;
  // minDate = new Date();
  minDate = '2023-01-01';
  maxDate = '2026-12-31';
  requireField: any;
  requireField2:any;
  quartername:any;




  constructor(
    private fb: FormBuilder,
    private myCouncilService: InstituteMyCouncilService,
    private fileService: FileService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteCouncilMeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {


    if ((this.data == undefined) || (this.data == null)) {

      this.form = {
        date_conducted_meeting: '',
        quarter: null,
        resolution: null,
        quarterly_plan: null,
        minutes_of_meeting: null,
        photo_upload: null,
        same_as: null,
      };
      this.show1 = true;
    }
    else {
      this.form = {
        date_conducted_meeting: new Date(this.data['date_conducted_meeting']),
        quarter: this.data['quarter'],
        resolution: null,
        quarterly_plan: null,
        minutes_of_meeting: null,
        photo_upload: null,
        same_as: null,
      };

      this.show = true;
      this.showQuaterlyPlan = true;
      this.showCouncilMeeting = true;
      this.showResolution = true;
      this.showMinutesofMeeting = true;
    }


  }

  public submitApplication(): void {
    var date_conducted_meeting = this.form.date_conducted_meeting;
    var quarter = this.form.quarter;

    var resolution =
      this.form.resolution && this.form.resolution.length
        ? this.form.resolution[0]
        : null;
    var quarterly_plan =
      this.form.quarterly_plan && this.form.quarterly_plan.length
        ? this.form.quarterly_plan[0]
        : null;
      var same_as =
        this.form.same_as && this.form.same_as.length
          ? this.form.same_as[0]
          : null;
    var minutes_of_meeting =
      this.form.minutes_of_meeting && this.form.minutes_of_meeting.length
        ? this.form.minutes_of_meeting[0]
        : null;
    var photo_upload =
      this.form.photo_upload && this.form.photo_upload.length
        ? this.form.photo_upload[0]
        : null;

    this.submitData({
      date_conducted_meeting: date_conducted_meeting,
      quarter: quarter,
      resolution: resolution,
      quarterly_plan: quarterly_plan,
      minutes_of_meeting: minutes_of_meeting,
      photo_upload: photo_upload,
      same_as: same_as,
    });
  }

  ngOnInit(): void {

    this.requireField2 = 0;

    this.fbl = this.data;
    this.form.date_conducted_meeting = new Date(this.data['date_conducted_meeting']);
    this.quarter = this.data['quarter'];
    this.photo_upload = this.data['photo_upload'];
    this.minutes_of_meeting = this.data['minutes_of_meeting'];
    this.quarterly_plan = this.data['quarterly_plan'];
    this.resolution = this.data['resolution'];
    this.same_as = this.data['same_as'];
    this.id1 = this.data['id'];
    // this.qualification1 = this.data['qualification'];
  }

  submitData(data) {
    var formData = new FormData();

    formData.append(
      'date_conducted_meeting',
      this.formatDate(data.date_conducted_meeting)
    );
    formData.append('quarter', data.quarter);
    formData.append('same_as', this.requireField2);

    data.resolution && formData.append('resolution', data.resolution);
    data.quarterly_plan && formData.append('quarterly_plan', data.quarterly_plan);
    data.minutes_of_meeting && formData.append('minutes_of_meeting', data.minutes_of_meeting);
    data.photo_upload && formData.append('photo_upload', data.photo_upload);


    if (this.show1 == true) {
      this.myCouncilService
        .submitCouncil(formData)
        .subscribe((data) => this.handleResponse(data));
    }
    else {

      this.myCouncilService
        .updateCouncil(formData, this.id1)
        .subscribe((data) => this.handleResponse1(data));

    }



  }
  handleResponse1(data) {
    if (data['status'] == "1") {
      this.toastr.success("Details Updated Successfully !");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }

    this.myCouncilService.dataUpdated.next(true);
  }

  onIICQuarterChange(ob) {
    let iicStatus = ob.value;

    if(iicStatus == 'Quarter II') {
      this.requireField = 1;
      this.quartername = "Plan for Quarter2 has been discussed in Quarter 1 Council Meeting";
    }
    else if(iicStatus == 'Quarter IV') {
      this.requireField = 1;
      this.quartername = "Plan for Quarter4 has been discussed in Quarter 3 Council Meeting";
    }
    else {
      this.requireField = 0;
      this.requireField2 = 0;

    }

  }

onIICSameAsChange(ob: MatCheckboxChange) {
  if (ob.checked) {
    this.requireField2 = 1;
  }
  else
  {
    this.requireField2 = 0;
  }
}



  handleResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success('Added Successfully !');
      this.dialogRef.close();
    } else {
      this.toastr.error(data['MSG']);
    }

    this.myCouncilService.dataUpdated.next(true);
  }

  setFileFlags() {
  }


  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
}
