<app-online-session-navbar></app-online-session-navbar>
<nav aria-label="breadcrumb">
  <ul class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/details-page-IA">Home</a></li>
    <li class="breadcrumb-item active" aria-current="page">Online Session Video</li>
  </ul>
</nav>

<section class="videoSessionBox">
<div class="container">
  <div class="row">
  <div class="col-md-8">
<youtube-player
 videoId="{{this.video}}"
HideProgressBar="2"
Hide="0"
 [height]="550"
 [width]="800"
 startSeconds="{{this.startTime}}"
 (ready)="savePlayer($event)"
 (change)="onPlayerStateChange($event)"
 (stateChange)="onStateChange($event)"></youtube-player>
</div>
<div class="col-md-4">
<div class="butHomePage">
  <a routerLink="/details-page-IA" class="btn btn-primary btnDownbackHome">Back to Home</a>
</div>
 <div *ngIf=show class="butVideoPage">
  <a href={{this.assessment}} target="_blank" class="btn btn-success btnDownSuccess">Assessment Link</a>
</div>

<div *ngIf=!show class="butVideoPage1">
  <button class="btn btn-success btnDownSuccess1" disabled="true">Assessment Link</button>
</div>
</div>
</div>

</div>
</section>

<section class="topCard">
	<div class="container">
		<div class="row bottomTextVideo">
			<div class="col-md-12">
			  <mat-card class="sessionVideoCardTop">
				<p>Watch the session in this window only. Do not watch the session in YouTube.</p>
				<p>Assessment link will be enabled once you have watched entire session.</p>
				<p>Once all the assessment is completed, update the <strong>Assessment Status</strong> by
clicking the checkbox</p>
				<!-- <p>Last date to complete the sessions and assessment is 15th November 2020</p> -->
				<p><strong>You will receive an email for the Innovation Ambassador’s Public Profile Registration
from MIC.</strong></p>
			</mat-card>
			</div>
		</div>
	</div>
</section>



<app-iic-web-footer></app-iic-web-footer>
