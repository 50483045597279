import { Dashboard1819ActivityListComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-activity-list/dashboard1819-activity-list.component';
import { AuthService } from 'src/app/Services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit {
  title = 'MIC';
  currentURL: string;

  constructor(private auth: AuthService, private router: Router) {

  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    if (token) {
      this.auth.getToken(token).subscribe(
        data => {
          this.handleResponse(data)
        }
      );
    }

  }

  handleResponse(data) {
    if (data['status'] = "1") {
      // this.currentURL = window.location.href;
      // this.router.navigateByUrl('/institute/dashboard');
    } else {
      localStorage.removeItem('token');
      this.router.navigateByUrl('/home')
    }
  }

}

export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

'use strict';
// export const baseUrl = 'http://localhost:8000/api';
// New QA api
// export const baseUrl = 'https://qaiicapi.mic.gov.in/api';
//till here
// export const baseUrlOSIA = 'https://qaiicapi.mic.gov.in/api/osia';
// export const baseImageUrl = 'https://qaiicapi.mic.gov.in/uploads/institutes';
// export const baseImageUrl1 = 'https://qaiicapi.mic.gov.in/uploads';
// export const baseImageUrl2 = 'https://qaiicapi.mic.gov.in/';
// export const baseImageUrlIA = 'https://qaiicapi.mic.gov.in/uploads/ia';

// // New prod api
// export const baseUrl = 'https://iicapi.mic.gov.in/api';
// export const baseUrl1 = 'https://iicapi.mic.gov.in';
// export const baseUrl1 = 'https://miciicsta01.blob.core.windows.net/miciiccontainer1';

// export const baseUrlOSIA = 'https://iicapi.mic.gov.in/api/osia';
// export const baseImageUrl = 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads/institutes';
// export const baseImageUrl1 = 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads';
// export const baseImageUrl2 = 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/';
// export const baseImageUrlIA = 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads/ia';

// export const manthanUrl = 'https://manthan-uat-api.ideabridge.co';
// export const manthanHost = 'manthan-uat.ideabridge.co';
// export const manthanOrigin = 'https://manthan-uat.ideabridge.co';
// export const manthanSubdomain = 'manthan-uat.ideabridge.co';

// export const manthanUrl = 'https://kafka.manthan.gov.in';
// export const manthanHost = 'kafka.manthan.gov.in';
// export const manthanOrigin = 'https://manthan.gov.in';
// export const manthanSubdomain = 'manthan.gov.in';


/// old image url ////

// export const baseUrlOSIA = 'https://iicapi.mic.gov.in/api/osia';
// export const baseImageUrl = 'https://iicapi.mic.gov.in/uploads/institutes';
// export const baseImageUrl1 = 'https://iicapi.mic.gov.in/uploads';
// export const baseImageUrl2 = 'https://iicapi.mic.gov.in/';
// export const baseImageUrlIA = 'https://iicapi.mic.gov.in/uploads/ia';

/// old image url ////


//prod api

// export const baseUrl = 'https://api.mic.gov.in/api';


// export const baseUrl = 'https://api.mic.gov.in/api';
// export const baseUrl = 'http://localhost:8000/api';

// export const baseUrl = 'https://api.mic.gov.in/api';
// export const baseUrl = 'http://localhost:8000/api';

// export const baseUrl = 'https://qamicapi.aicte-india.org/api';

// export const baseUrlOSIA = 'https://api.mic.gov.in/api/osia';
// export const baseImageUrl = 'https://api.mic.gov.in/uploads/institutes';
// export const baseImageUrl1 = 'https://api.mic.gov.in/uploads';
// export const baseImageUrl2 = 'https://api.mic.gov.in/';
// export const baseImageUrlIA = 'https://api.mic.gov.in/uploads/ia';

//QA api
// export const baseUrl = 'https://qamic2api.mic.gov.in/api';
//export const baseUrl = 'https://qamicapi.aicte-india.org/api';
//export const baseUrl = 'http://localhost:8000/api'; //  localhost
//  export const baseUrlOSIA = 'https://qamicapi.aicte-india.org/api/osia';
//  export const baseImageUrl = 'https://qamicapi.aicte-india.org/uploads/institutes';
//  export const baseImageUrl1 = 'https://qamicapi.aicte-india.org/uploads';
//  export const baseImageUrl2 = 'https://qamicapi.aicte-india.org/';
// export const baseImageUrlIA = 'https://qamicapi.aicte-india.org/uploads/ia';



export const baseUrl = environment.baseUrl;
export const baseUrl1 = environment.baseUrl1;

// export const baseImageUrl = environment.baseImageUrl;
// export const baseImageUrl1 = environment.baseImageUrl1;
// export const baseImageUrl2 = environment.baseImageUrl2;
export const imageBasePath = 'https://iic.mic.gov.in/uploads/';
export const baseUrlOSIA = environment.baseUrlOSIA;
export const baseImageUrl = environment.baseImageUrl;
export const baseImageUrl1 = environment.baseImageUrl1;
export const baseImageUrl2 = environment.baseImageUrl2;
export const baseImageUrlIA = environment.baseImageUrlIA;

export const manthanUrl = environment.manthanUrl;
export const manthanHost = environment.manthanHost;
export const manthanOrigin = environment.manthanOrigin;
export const manthanSubdomain = environment.manthanSubdomain;
