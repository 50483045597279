<div class="container">
    <mat-dialog-content class="mat-typography instiFactDialoge">

      <form [formGroup]="impactAnalysis" (ngSubmit)="onSubmit(impactAnalysis.value)">


        <div class="row">
		<div class="col-md-12">
			<h1 class="text-center instStuDetailsTitle">Impact Lecture Session</h1>
		</div>

          <div fxFlex="50" class="col-md-12">
            <mat-form-field class="example-full-width seassionNominate" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon>
              <mat-label>Session</mat-label>
              <mat-select placeholder="Session" formControlName="session">
                <mat-option value="Session 1">Impact Lecture Session</mat-option>
                <!-- <mat-option *ngIf="this.two==1" value="Session 2">Session 2</mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- [disabled]="sessionModel" -->
        <div class="row">
            <div fxFlex="50" class="col-md-6">
            <mat-form-field class="example-full-width fullWidthNominateDialoge" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">assignment_turned_in
              </mat-icon>
              <mat-label>Title of lecture1</mat-label>
              <input matInput formControlName="title" placeholder="Title" required>
              <mat-error *ngIf="impactAnalysis.controls['title'].hasError('required')">Title is
                required</mat-error>
            </mat-form-field>
          </div>



          <div fxFlex="50" class="col-md-6" *ngIf="disable_fields==0">
            <mat-form-field class="example-full-width fullWidthNominateDialoge" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Theme of lecture 1<span class="requiredRed">*</span></mat-label>
              <mat-select formControlName="theme" [(ngModel)]="themeModel">
                <mat-option *ngFor="let role of theme" [value]="role?.id">
                  {{role?.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="impactAnalysis.controls['theme'].hasError('required')">Theme is
                required</mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" class="col-md-6" *ngIf="disable_fields!=0">
            <mat-form-field class="example-full-width fullWidthNominateDialoge" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Theme of lecture 1 S<span class="requiredRed">*</span></mat-label>
              <mat-select formControlName="theme" [(ngModel)]="themeModel">
                <!-- [disabled]="true" -->
                <mat-option *ngFor="let role of theme" [value]="role?.id">
                  {{role?.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="impactAnalysis.controls['theme'].hasError('required')">Theme is
                required</mat-error>
            </mat-form-field>
          </div>


        </div>


        <div class="row">
          <div fxFlex="50" class="col-md-6">
          <mat-form-field class="example-full-width fullWidthNominateDialoge" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">assignment_turned_in
            </mat-icon>
            <mat-label>Title of lecture 2</mat-label>
            <input matInput formControlName="title2" placeholder="Title" required>
            <mat-error *ngIf="impactAnalysis.controls['title2'].hasError('required')">Title 2 is
              required</mat-error>
          </mat-form-field>
        </div>



        <div fxFlex="50" class="col-md-6">
          <mat-form-field class="example-full-width fullWidthNominateDialoge" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Theme of lecture 2<span class="requiredRed">*</span></mat-label>
            <mat-select formControlName="theme2" [(ngModel)]="theme2Model">

              <!-- [disabled]="true" -->
              <mat-option *ngFor="let role of theme" [value]="role?.id">
                {{role?.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="impactAnalysis.controls['theme2'].hasError('required')">Theme 2 is
              required</mat-error>
          </mat-form-field>
        </div>


      </div>

<div class="row">

  <!-- <div fxFlex="50" class="col-md-6">

    <mat-form-field color="accent" appearance="outline" class="example-full-width fullWidthNominateDialoge">
      <mat-label>Tentative date</mat-label>
      <input matInput [matDatepicker]="picker2" readonly formControlName="date"  [min]="minDate" [max]="maxDate" >
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2 color="primary"></mat-datepicker>
    </mat-form-field> </div> -->

      <div fxFlex="50" class="col-md-6">
        <mat-form-field class="example-full-width fullWidthNominateDialoge" appearance="outline">
          <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon>
          <mat-label>Mode of the session</mat-label>
          <mat-select placeholder="Mode of the session" formControlName="mode" required
            [(ngModel)]="sessionModeModel">
            <!-- <mat-option value="Online">Online</mat-option> -->
            <mat-option value="Face to Face">Offline</mat-option>
          </mat-select>
          <mat-error *ngIf="impactAnalysis.controls['mode'].hasError('required')">
            Mode of the Session is
            required</mat-error>
        </mat-form-field>
      </div>
</div>
        <div mat-dialog-actions>
          <button type="button" mat-raised-button color='warn' (click)='onNoClick()' style="margin-right: 520px;
              " class="btn btn-danger instituteBtnClose">Close</button>
          <button *ngIf="show1" mat-raised-button color='primary' class="btn btn-success float-right"
            [disabled]='impactAnalysis.invalid'>Submit</button>
          <button *ngIf="show" mat-raised-button color='primary' class="btn btn-success float-right"
            [disabled]='impactAnalysis.invalid'>Update</button>
        </div>
      </form>
    </mat-dialog-content>
  </div>






