<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Add new yukti challenge</h2>
    </div>

	<div class="card mb-2">
		<div class="text-right"><a routerLink="/institute/yukti-challenges"><button class="btn btn-primary">Back</button></a></div>
        <div class="card-title"><p>Details of yukti challenge to be organized</p></div>
	</div>

    <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
       <table class="table table-hover">
			<tr>
				<td>
					Challenge Title
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Challenge Title</mat-label>
					<input matInput autocomplete="off" placeholder="Challenge Title" formControlName="title" type="text" required>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Description
				</td>
				<td>
					<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Description</mat-label>

					<textarea rows="2" maxlength="1000" matInput placeholder="Description" formControlName="description" required></textarea>
					</mat-form-field>
				</td>
			</tr>

      <tr>
				<td>
				 Brochure
				</td>
				<td>
					<div class="col-12">
            <label class="custom-file-label float-right" for="customFile"
                              *ngIf="!ipr_document">Choose file</label>

            <label class="custom-file-label float-right" for="customFile"
                *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>

            <input #ipr_documentRef type="file" accept="application/pdf"  name="ipr_document"
                (change)="( ipr_document = ipr_documentRef.files )"
                class="custom-file-input float-right" required>
              </div>

				</td>
			</tr>

      <tr *ngIf="document">
        <th>&nbsp;</th>
        <td colspan="3">
            <a href="https://api.mic.gov.in{{document}}" target="_blank">View File</a>
        </td>
      </tr>

			<tr>
				<td>
					Challenge Theme
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">

                  <mat-label> Challenge Theme</mat-label>

                  <mat-select autocomplete="off" placeholder="Challenge Theme" formControlName="theme"  required multiple>
                    <mat-option>Select</mat-option>
                    <mat-option value="{{theme.id}}" *ngFor="let theme of programTheme">{{theme.name}}</mat-option>
                  </mat-select>
              </mat-form-field>

				</td>
			</tr>

			

			<tr>
				<td>
					Theme Other (If Any)
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Theme Other (If Any)</mat-label>
						<input matInput autocomplete="off" placeholder="Theme Other (If Any)" formControlName="theme_other" type="text">
					</mat-form-field>					
				</td>
			</tr>

			<tr>
				<td>
					Website (If Any)
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Website (If Any)</mat-label>
						<input matInput autocomplete="off" placeholder="Website (If Any)" formControlName="website" type="text" >
					</mat-form-field>					
				</td>
			</tr>

			<tr>
				<td>
					Level of Challenge
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">	
						<mat-label> Level</mat-label>	
						<mat-select autocomplete="off" placeholder="" formControlName="level"  required multiple>
						<mat-option value="1">Idea</mat-option>
						<mat-option value="2">Innovation</mat-option>
						<mat-option value="3">Startup</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					User Type
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
	
					<mat-label> User Type</mat-label>
	
					<mat-select autocomplete="off" placeholder="" formControlName="user_type"  required multiple>
					<mat-option value="Student">Student</mat-option>
					<mat-option value="Faculty">Faculty/Staff</mat-option>
					<mat-option value="Alumni">Graduated/Alumni Member</mat-option>
					<mat-option value="Entrepreneur">Entrepreneur at Incubation Unit/Institute</mat-option>
					</mat-select>
				</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Nominate From
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">	
						<mat-label> Nominate From</mat-label>	
						<mat-select autocomplete="off" formControlName="nominate_from"  required >
						<mat-option value="1">Institute</mat-option>
						<mat-option value="2">Innovator</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			</tr>
			<tr>
				<td>
					Region
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">	
						<mat-label> Region</mat-label>	
						<mat-select autocomplete="off" placeholder="" formControlName="region"  required (selectionChange)="changeRegion($event)">
						<mat-option value="Within Institute">Within Institute</mat-option>
						<mat-option value="Within State">Within State</mat-option>
						<mat-option value="Within Region">Within Region</mat-option>
						<mat-option value="Other">Other</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
			</tr>
			<tr id="otherState" class="otherState">
				<td>
					Other State
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">

                  <mat-label> Other State</mat-label>

                  <mat-select autocomplete="off" placeholder="Other State" formControlName="other_state" multiple>
                    <mat-option>Select</mat-option>
                    <mat-option value="{{state.id}}" *ngFor="let state of stateList">{{state.name}}</mat-option>
                  </mat-select>
              </mat-form-field>

				</td>
			</tr>

      <tr>
				<td>
					Submission Start Date
				</td>
				<td>

          <mat-form-field class="example-full-width" style="width: 100%;">
            <mat-label>Submission Start Date <span class="redMendate">*</span></mat-label>
            <input matInput [matDatepicker]="submission_start_date" readonly formControlName="submission_start_date" [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="submission_start_date"></mat-datepicker-toggle>
            <mat-datepicker #submission_start_date color="primary"></mat-datepicker>
          </mat-form-field>

				</td>
			</tr>


			<tr>
				<td>
					Submission End Date
				</td>
				<td>

          <mat-form-field class="example-full-width" style="width: 100%;">
            <mat-label>Submission End Date <span class="redMendate">*</span></mat-label>
            <input matInput [matDatepicker]="submission_end_date" readonly formControlName="submission_end_date" [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="submission_end_date"></mat-datepicker-toggle>
            <mat-datepicker #submission_end_date color="primary"></mat-datepicker>
          </mat-form-field>

				</td>
			</tr>

			<tr>
				<td>
					Event Start Date
				</td>
				<td>

          <mat-form-field class="example-full-width" style="width: 100%;">
            <mat-label>Event Start Date <span class="redMendate">*</span></mat-label>
            <input matInput [matDatepicker]="event_start_date" readonly formControlName="event_start_date" [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="event_start_date"></mat-datepicker-toggle>
            <mat-datepicker #event_start_date color="primary"></mat-datepicker>
          </mat-form-field>

				</td>
			</tr>

			<tr>
				<td>
					Event End Date
				</td>
				<td>

          <mat-form-field class="example-full-width" style="width: 100%;">
            <mat-label>Event End Date<span class="redMendate">*</span></mat-label>
            <input matInput [matDatepicker]="event_end_date" readonly formControlName="event_end_date" [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="event_end_date"></mat-datepicker-toggle>
            <mat-datepicker #event_end_date color="primary"></mat-datepicker>
          </mat-form-field>

				</td>
			</tr>

      	<tr>
				<td>
					Co-ordinator's Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Co-ordinator's Name</mat-label>
						<input matInput autocomplete="off" placeholder="Co-ordinator's Name" formControlName="coordinator_name" type="text"   required>
            		</mat-form-field>
				</td>
		</tr>

      	<tr>
				<td>
					Co-ordinator's Email
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
            			<mat-label>Co-ordinator's Email</mat-label>
            			<input matInput type="email" autocomplete="off" placeholder="Co-ordinator's Email" formControlName="coordinator_email" type="text" required>
            		</mat-form-field>
				</td>
			</tr>

      <tr>
				<td>
					Co-ordinator's Contact
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
            <mat-label>Co-ordinator's Contact</mat-label>

            <input matInput autocomplete="off" placeholder="Co-ordinator's Contact" formControlName="coordinator_phone" type="text"   required></mat-form-field>
				</td>
			</tr>

      <tr>
				<td>
					&nbsp;
				</td>
				<td>
					<button mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicImpactQuestions.invalid'>Submit</button>
				</td>
			</tr>


	   </table>
	</form>
</div>
