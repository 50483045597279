import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, EmailValidator } from '@angular/forms';
import { emailValidator } from 'src/app/app.component';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';
import { Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-ia-activity-report-training-dialog',
  templateUrl: './ia-activity-report-training-dialog.component.html',
  styleUrls: ['./ia-activity-report-training-dialog.component.css']
})
export class IaActivityReportTrainingDialogComponent implements OnInit {

  showData:any;
  public facultyForm: FormGroup;

  constructor(private fb: FormBuilder,
    private jarwis: JarwisService,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService, private instituteMaster: InstituteInnovationContestService,
    public dialogRef: MatDialogRef<IaActivityReportTrainingDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  )
  {
    this.showData = this.data;
  }


  ngOnInit(): void {

    this.facultyForm = this.fb.group({
      'status': [null, Validators.required]
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) {
    values['id'] = this.showData["tid"];
    values['type'] = "Training";
    this.instituteMaster.submitIaActivityProgressStatus(values).subscribe(
      (data) => this.handleResponse1(data)
    );
  }

  handleResponse1(data) {
    if (data['status'] == "1") {
      this.toastr.success("Verification status has been updated successfully!");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
  }

}
