<!--title-->
   <title>

    About MHRD’S Innovation Cell | MHRD
    </title>
    <!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>



<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="index.html">Home</a>
      </li>
      <li class="aht-breadcrumbs__item">
        <a class="aht-breadcrumbs__link" href="#">Innovation Ranking</a>
      </li>
      
    </ul>
  </div>
  
<section class="paddsection">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="titleheadertop">

                  <h1 class="inner-theme-heading">Atal Ranking of Institutions on Innovation Achievements</h1>

                </div>

            </div>

        </div>



        <div class="row rowmar">

            <div class="col-md-6">

                <div class="imagesbox rankingimg">

                  <img src="{{imageBasePath}}assets/wp_images/rankingimage.png">

                </div>

            </div>



            <div class="col-md-6">

                <div class="textboxsih rankingmar">

                    <div class="grediyant">

                  <h4>India has already been improving on global stage in terms of Innovation ranking from 86th place, 5 years ago, to 52nd place this year.</h4>

              </div>

                </div>



                <div class="rankingtext">

                   <p>The buzzword across the globe for the 21st century is ‘Innovation’. In the simplest term, Innovation could be defined as converting ideas into new or improved products, processes and services. Undoubtedly, Innovation is about taking inventions to marketplace by translating scientific knowledge into products, services and offerings for economic growth and social development. Even, Hon’ble Prime Minister of India has declared decade 2010-20 as the ‘Decade of Innovation’, to unleash the creative potential of every Indian.</p>

                </div>

            </div>

        </div>



        <div class="row">

            <div class="col-md-12">

                <div class="rankingtextwidt">
				<p>For India to emerge as a global innovation hub, the youth of our country, especially in higher education institutions (HEIs) need to play a crucial role to create a sustainable innovation ecosystem. Hence, ideally all HEIs should have a comprehensive and functional mechanism to convert research into innovations. This ecosystem will encourage, inspire and nurture young students by exposing them to new ideas and processes resulting in innovative activities in their formative years.</p>
<p>Atal Ranking of Institutions on Innovation Achievements (ARIIA) is an initiative of Ministry of Human Resource Development (MHRD), Govt. of India to systematically rank all major higher educational institutions and universities in India on indicators related to “Innovation and Entrepreneurship Development” amongst students and faculties.</p>

                

                </div>

            </div>

        </div>



        <div class="row">

            <div class="col-md-6">

              <div class="rankingtextwidt">

                <p>ARIIA ranking will certainly inspire Indian institutions to reorient their mind-set and build ecosystems to encourage high quality research, innovation and entrepreneurship. More than quantity, ARIIA will focus on quality of innovations and will try to measure the real impact created by these innovations nationally and internationally. Moreover, ARIIA will set tone and direction for institutions for future development for making them globally competitive and in forefront of innovation .</p>

                </div>

            </div>

                <div class="col-md-6">

                    <div class="textranking2">

                    <img src="{{imageBasePath}}assets/wp_images/ranking2.png">

                </div>

                </div>

            </div>

        </div>

</section>



<section class="sectionbutton">

    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="textbotton">

                    <h4>Atal Ranking of Institutions on Innovation Achievements  <a href="https://ariia.gov.in/">Vist the website</a></h4>

                </div>

            </div>

        </div>

    </div>

</section>



<!-- Footer -->
<app-inner-footer></app-inner-footer>