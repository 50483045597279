import { Component, OnInit } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-referral-institutes-dialog',
  templateUrl: './referral-institutes-dialog.component.html',
  styleUrls: ['./referral-institutes-dialog.component.css']
})
export class ReferralInstitutesDialogComponent implements OnInit {

  public resetForm: FormGroup
  activity: any;
  data: any;

  constructor(private activityService: InstituteActivityService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialogRef2: MatDialogRef<ReferralInstitutesDialogComponent>) { }

    ngOnInit(): void {
      this.activityService.getReferralDetails().subscribe(
        this.activity = this.data['activity']
      );
    }


  onNoClick(): void {
    this.dialogRef2.close();
  }

}
