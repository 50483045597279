import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ia-activity-report',
  templateUrl: './ia-activity-report.component.html',
  styleUrls: ['./ia-activity-report.component.css']
})
export class IaActivityReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
