import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-yukti-challenges',
  templateUrl: './yukti-challenges.component.html',
  styleUrls: ['./yukti-challenges.component.css']
})
export class YuktiChallengesComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  baseUrl = myGlobals.baseUrl1;
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicrepository:any;

  constructor(private fb: FormBuilder, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.requireField=0;

    this.instituteActivityService.getYuktiChallenges('all').subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {

    this.iicrepository = data['data'];

  }

  onClickEvent(data,type){

    if(type=="Edit")
    {
      window.location.href="/institute/yukti-challenges-add/"+data;
    }
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.DeleteYuktiChallenges(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }

  }

  handleResponse1(data)
  {

  }


}
