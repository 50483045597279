import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-institute',
  templateUrl: './institute.component.html',
  styleUrls: ['./institute.component.css'],
})
export class InstituteComponent implements OnInit {
  userId;

  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue;
  institute_name: any;
  rewardPoints: any;
  cumulativeScore: any;

  toggle;



  constructor(private instituteMyCouncilService: InstituteMyCouncilService,
    public dialog: MatDialog, private toastr: ToastrService, private router: Router) {
    this.instituteMyCouncilService
      .getInstituteRating()
      .subscribe((data) => this.handleResponse(data));
  }

  ngOnInit(): void {

    this.instituteMyCouncilService
      .toggle.subscribe((data) => this.toggle = data);

    this.checkRole();
  }

  handleResponse(data) {
    this.userId = data['rating']['userId'];
    this.selectedValue = data['rating']['rating'];
    this.institute_name = data['rating']['instituteName'];
    this.rewardPoints = data['rating']['rewards'];
    this.cumulativeScore = data['rating']['total_marks'];
  }

  countStar(star) {
    this.selectedValue = star;
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteDialog);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  checkRole() {
    this.instituteMyCouncilService.checkRole().subscribe(
      data => this.handleResponseForRole(data)
    );
  }

  handleResponseForRole(data) {
    if (data['type'] != 'Institute') {
      this.logout();
    }
  }

  logout() {
    localStorage.removeItem('token');
    this.toastr.error('Invalid User');
    this.router.navigateByUrl('/login');
  }
}


@Component({
  selector: 'institute-dialog',
  templateUrl: 'institute-dialog.html',
})
export class InstituteDialog implements OnInit {
  formData: any;

  constructor(private instituteMyCouncilService: InstituteMyCouncilService,
    public dialog: MatDialog) {
    this.instituteMyCouncilService
      .getRewardPoints()
      .subscribe((data) => this.handleResponse(data));
  }

  ngOnInit(): void { }

  handleResponse(data) {
    this.formData = data['data'];
  }

}
