import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InstituteDashboardService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }


  getPieData(token) {
    return this.http.get(
      `${this.baseUrl}/institute-dashboard-pie/details?token=`+token
    );
  }

  getQuarterwiseBarData() {
    return this.http.get(
      `${this.baseUrl}/institute-dashboard-bar-qtr/details`
    );
  }
  getEventWiseBarData(data){
    return this.http.get(
      `${this.baseUrl}/institute-dashborad-bar?type=`+data
    );
  }

  getEventWiseGaugeData(data){
    return this.http.get(
      `${this.baseUrl}/institute-dashboard-gauge?type=`+data
    );
  }

  getActivityData(token) {
    return this.http.get(
      `${this.baseUrl}/institute-dashboard-activity/details?token=`+token
    );
  }




  getdashboardDownloadList(){
    return this.http.get(
      `${this.baseUrl}/dashboard-download-list`
    );
  }

  getMonthlyReportPdf(data){
    return this.http.get(
      `${this.baseUrl}/activity/monthlyReportPdf/`+data, { responseType: 'arraybuffer' }

    );
  }

  getMonthlyReportCouncilPdf(data){
    return this.http.get(
      `${this.baseUrl}/activity/monthlyReportCouncelPdf/`+data, { responseType: 'arraybuffer' }
    );
  }

  getAnnouncements(token){
      return this.http.get(
        `${this.baseUrl}/announcement/details?per_page=8&token=` + token
      );
  }

  getAllNewsCornerDetails(token) {
    return this.http.get(
      `${this.baseUrl}/get-allnewscorner-details?per_page=8&page=` + token
    );
  }

  getInstituteDashboardRating() {
    return this.http.get(`${this.baseUrl}/institute-dashboard-ratings/details`);
  }

  getOverAllImapact(){
    return this.http.get(`${this.baseUrl}/getOverAllImapact`);

  }



//Aishe Code api

  getAisheCode(){
      return this.http.get(`${this.baseUrl}/get-rating`)
  }

  getUserIic4oStatus(){
    return this.http.get(`${this.baseUrl}/get-user-iic4o`)
  }

  getUserAisheUStatus(){
    return this.http.get(`${this.baseUrl}/get-user-iic-aishe`)
  }

  submitUserIic4oStatus(data){
    return this.http.post(`${this.baseUrl}/submit-user-iic4o`,data)
  }

  getCorrectStatus(data){
const data1={
btn_status:data
}

    return this.http.post(`${this.baseUrl}/aishe-popup-status`,data1)
}


updateAisheCode(data){
const data1={
aishe:data
}

  return this.http.post(`${this.baseUrl}/aishe-popup-update`,data1)
}
verifyAisheCode(data){
  return this.http.post(`${this.baseUrl}/verify-aishe-code`,data)
}


// Download

getDownloadsData(){
  return this.http.get(`${this.baseUrl}/iic-download`)
}










// Dashboard 2021

getActivityData2021(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-activity2021/details2021?token=`+token
  );
}



getAnnouncements2021(token){
  return this.http.get(
    `${this.baseUrl}/details2021?per_page=8&token=` + token
  );
}
getAllNewsCornerDetails2021(token) {
  return this.http.get(
    `${this.baseUrl}/get-allnewscorner-details2021?per_page=8&page=` + token
  );
}
getInstituteDashboardRating2021() {
  return this.http.get(`${this.baseUrl}/institute-dashboard-ratings/details2021`);
}

getPieData2021(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-pie/details2021?token=`+token
  );
}

getOverAllImapact2021(){
  return this.http.get(`${this.baseUrl}/getOverAllImapact2021`);

}

getQuarterwiseBarData2021() {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-bar-qtr/details2021`
  );
}
getEventWiseBarData2021(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashborad-bar2021?type=`+data
  );
}

getEventWiseGaugeData2021(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-gauge2021?type=`+data
  );
}

getdashboardDownloadList2021(){
  return this.http.get(
    `${this.baseUrl}/dashboard-download-list2021`
  );
}
getdashboardDownloadList2020(){
  return this.http.get(
    `${this.baseUrl}/dashboard-download-list2020`
  );
}

// Dashboard 2022

getActivityData2022(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-activity2022/details2022?token=`+token
  );
}

getAnnouncements2022(token){
  return this.http.get(
    `${this.baseUrl}/details2022?per_page=8&token=` + token
  );
}
getAllNewsCornerDetails2022(token) {
  return this.http.get(
    `${this.baseUrl}/get-allnewscorner-details2021?per_page=8&page=` + token
  );
}
getInstituteDashboardRating2022() {
  return this.http.get(`${this.baseUrl}/institute-dashboard-ratings/details2022`);
}

getPieData2022(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-pie/details2022?token=`+token
  );
}

getOverAllImapact2022(){
  return this.http.get(`${this.baseUrl}/getOverAllImapact2022`);

}

getQuarterwiseBarData2022() {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-bar-qtr/details2022`
  );
}
getEventWiseBarData2022(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashborad-bar2022?type=`+data
  );
}

getEventWiseGaugeData2022(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-gauge2022?type=`+data
  );
}

getdashboardDownloadList2022(){
  return this.http.get(
    `${this.baseUrl}/dashboard-download-list2022`
  );
}

getActivityData2023(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-activity2023/details2023?token=`+token
  );
}

getAnnouncements2023(token){
  return this.http.get(
    `${this.baseUrl}/details2023?per_page=8&token=` + token
  );
}
getAllNewsCornerDetails2023(token) {
  return this.http.get(
    `${this.baseUrl}/get-allnewscorner-details2021?per_page=8&page=` + token
  );
}
getInstituteDashboardRating2023() {
  return this.http.get(`${this.baseUrl}/institute-dashboard-ratings/details2023`);
}

getPieData2023(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-pie/details2023?token=`+token
  );
}

getOverAllImapact2023(){
  return this.http.get(`${this.baseUrl}/getOverAllImapact2023`);

}

getQuarterwiseBarData2023() {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-bar-qtr/details2023`
  );
}
getEventWiseBarData2023(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashborad-bar2023?type=`+data
  );
}

getEventWiseGaugeData2023(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-gauge2023?type=`+data
  );
}

getdashboardDownloadList2023(){
  return this.http.get(
    `${this.baseUrl}/dashboard-download-list2023`
  );
}
/// Dashbaord 2023-24
getActivityData2024(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-activity2024/details2024?token=`+token
  );
}

getAnnouncements2024(token){
  return this.http.get(
    `${this.baseUrl}/details2024?per_page=8&token=` + token
  );
}
getAllNewsCornerDetails2024(token) {
  return this.http.get(
    `${this.baseUrl}/get-allnewscorner-details2021?per_page=8&page=` + token
  );
}
getInstituteDashboardRating2024() {
  return this.http.get(`${this.baseUrl}/institute-dashboard-ratings/details2024`);
}

getPieData2024(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-pie/details2024?token=`+token
  );
}

getOverAllImapact2024(){
  return this.http.get(`${this.baseUrl}/getOverAllImapact2024`);

}

getQuarterwiseBarData2024() {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-bar-qtr/details2024`
  );
}
getEventWiseBarData2024(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashborad-bar2024?type=`+data
  );
}

getEventWiseGaugeData2024(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-gauge2024?type=`+data
  );
}

getdashboardDownloadList2024(){
  return this.http.get(
    `${this.baseUrl}/dashboard-download-list2024`
  );
}
/// Dashbaord 2024-25
getActivityData2025(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-activity2025/details2025?token=`+token
  );
}

getAnnouncements2025(token){
  return this.http.get(
    `${this.baseUrl}/details2025?per_page=8&token=` + token
  );
}
getAllNewsCornerDetails2025(token) {
  return this.http.get(
    `${this.baseUrl}/get-allnewscorner-details2021?per_page=8&page=` + token
  );
}
getInstituteDashboardRating2025() {
  return this.http.get(`${this.baseUrl}/institute-dashboard-ratings/details2025`);
}

getPieData2025(token) {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-pie/details2025?token=`+token
  );
}

getOverAllImapact2025(){
  return this.http.get(`${this.baseUrl}/getOverAllImapact2025`);

}

getQuarterwiseBarData2025() {
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-bar-qtr/details2025`
  );
}
getEventWiseBarData2025(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashborad-bar2025?type=`+data
  );
}

getEventWiseGaugeData2025(data){
  return this.http.get(
    `${this.baseUrl}/institute-dashboard-gauge2025?type=`+data
  );
}

getdashboardDownloadList2025(){
  return this.http.get(
    `${this.baseUrl}/dashboard-download-list2025`
  );
}

getDashboardTT(){
  return this.http.get(
    `${this.baseUrl}/dashboard-tt-data`
  );
}
submitDashboardTT(data){
  return this.http.post(
    `${this.baseUrl}/dashboard-tt`,data
  );
}

}
