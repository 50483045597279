<div class="container">
    <div class="row" color="primary">
        <h2 class="ss heading-box">
            Innovation Ambassador - Submitted Upskilling/Reskilling Trainings</h2>
            <p style="padding:0 15px; text-align:center; font-size:22px; font-weight: bold; color: #FF0000; display: block;
            width: 100%;">
       <!--  Approval process will open after launch of IIC 5.0 -->
    </p>
    </div>
  <div class="refrredBox">
  <div class="row rws-listpage">
  <div class="col-md-12">
  <div class="tableBody">
  <div class="table-responsive">
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th scope="col">S.No.</th>
            <th scope="col">IA Details</th>
            <th scope="col">Activity Details</th>
            <th scope="col">Info</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let ia of activities| paginate: { itemsPerPage: 20, currentPage: page }; index as i" >
            <td>{{i+1}}</td>
            <td>{{ia?.name}}<br/>{{ia?.email}}<br/>{{ia?.contact}}</td>
            <td>{{ia?.training_title}}<br/>{{ia?.theme}}<br/>{{ia?.duration1}} Hour</td>
            <td>Type: {{ia?.training_type}}<br/>Sponsered: {{ia?.sponsered}}</td>
            <td>{{ia?.tstatus}}</td>
            <!-- <td *ngIf="!ia?.verify_status" class="status-primary">Pending</td>
            <td *ngIf="ia?.verify_status=='Approved'" class="status-success">Approved</td>
            <td *ngIf="ia?.verify_status=='Disapproved'" class="status-danger">Disapproved</td> -->
            <td>
              <button class="btn btn-primary" (click)="openEditDialog(ia)">View & Verify Details</button>
              <!-- <button *ngIf="ia?.verify_status" class="btn btn-primary" (click)="openEditDialog(ia)">View Details</button> -->
            </td>
        </tr>
        <tr *ngIf="totalCount==0" col="6">
            <h3 style="text-align: center">There is no activity found.</h3>
        </tr>
    </tbody>
  </table>
  <pagination-controls class="annular-pagination float-right" maxSize="6"
                    (pageChange)="onPageChanged($event)"></pagination-controls>
  </div>
  </div>
  
  
  
  </div>
  </div>
  </div>
  </div>
  
  