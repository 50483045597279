<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Innovation Repository - Team Details</h2>
    </div>

	<div class="card">
        <div class="card-title"><p>Innovation Repository (For institutes/colleges)</p></div>
	</div>

    <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">


    <mat-card style="overflow: hidden;">
      <div class="rws-teamdetails">
        <h3>Team Member Details</h3>

        <div class="row rws-filedsection">
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Name</mat-label>
              <input matInput autocomplete="off" placeholder="Member Name" formControlName="member_name_1" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Email</mat-label>
              <input matInput autocomplete="off" placeholder="Member Email" formControlName="member_email_1" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Mobile No.</mat-label>
              <input matInput autocomplete="off" placeholder="Member Mobile No." formControlName="member_mobile_1" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Current Engagement</mat-label>
              <mat-select autocomplete="off" placeholder="Current Engagement" formControlName="member_engagement_1"  required>
                <mat-option>Select</mat-option>
                <mat-option value="Studying">Studying</mat-option>
                <mat-option value="Graduated">Graduated</mat-option>
                <mat-option value="Incubated">Incubated</mat-option>
                <mat-option value="Employed">Employed</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Gender</mat-label>
              <mat-select autocomplete="off" placeholder="Gender" formControlName="member_gender_1"  required>
                <mat-option>Select</mat-option>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Category</mat-label>
              <mat-select autocomplete="off" placeholder="Member Category" formControlName="member_category_1" required>
                <mat-option>Select</mat-option>
                <mat-option value="General">General</mat-option>
                <mat-option value="OBC">OBC</mat-option>
                <mat-option value="ST">ST</mat-option>
                <mat-option value="SC">SC</mat-option>
                <mat-option value="EWS">EWS</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

        </div>
        <!-- ROW End  -->

        <div class="row rws-filedsection">
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Name</mat-label>
              <input matInput autocomplete="off" placeholder="Member Name" formControlName="member_name_2" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Email</mat-label>
              <input matInput autocomplete="off" placeholder="Member Email" formControlName="member_email_2" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Mobile No.</mat-label>
              <input matInput autocomplete="off" placeholder="Member Mobile No." formControlName="member_mobile_2" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Current Engagement</mat-label>
              <mat-select autocomplete="off" placeholder="Current Engagement" formControlName="member_engagement_2">
                <mat-option>Select</mat-option>
                <mat-option value="Studying">Studying</mat-option>
                <mat-option value="Graduated">Graduated</mat-option>
                <mat-option value="Incubated">Incubated</mat-option>
                <mat-option value="Employed">Employed</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Gender</mat-label>
              <mat-select autocomplete="off" placeholder="Gender" formControlName="member_gender_2">
                <mat-option>Select</mat-option>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Category</mat-label>
              <mat-select autocomplete="off" placeholder="Member Category" formControlName="member_category_2">
                <mat-option>Select</mat-option>
                <mat-option value="General">General</mat-option>
                <mat-option value="OBC">OBC</mat-option>
                <mat-option value="ST">ST</mat-option>
                <mat-option value="SC">SC</mat-option>
                <mat-option value="EWS">EWS</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

        </div>

        <!-- Row Ends  -->
        <div class="row rws-filedsection">
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Name</mat-label>
              <input matInput autocomplete="off" placeholder="Member Name" formControlName="member_name_3" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Email</mat-label>
              <input matInput autocomplete="off" placeholder="Member Email" formControlName="member_email_3" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Mobile No.</mat-label>
              <input matInput autocomplete="off" placeholder="Member Mobile No." formControlName="member_mobile_3" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Current Engagement</mat-label>
              <mat-select autocomplete="off" placeholder="Current Engagement" formControlName="member_engagement_3">
                <mat-option>Select</mat-option>
                <mat-option value="Studying">Studying</mat-option>
                <mat-option value="Graduated">Graduated</mat-option>
                <mat-option value="Incubated">Incubated</mat-option>
                <mat-option value="Employed">Employed</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Gender</mat-label>
              <mat-select autocomplete="off" placeholder="Gender" formControlName="member_gender_3">
                <mat-option>Select</mat-option>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Category</mat-label>
              <mat-select autocomplete="off" placeholder="Member Category" formControlName="member_category_3">
                <mat-option>Select</mat-option>
                <mat-option value="General">General</mat-option>
                <mat-option value="OBC">OBC</mat-option>
                <mat-option value="ST">ST</mat-option>
                <mat-option value="SC">SC</mat-option>
                <mat-option value="EWS">EWS</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

        </div>

        <!-- Row Ends  -->
        <div class="row rws-filedsection">
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Name</mat-label>
              <input matInput autocomplete="off" placeholder="Member Name" formControlName="member_name_4" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Email</mat-label>
              <input matInput autocomplete="off" placeholder="Member Email" formControlName="member_email_4" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Mobile No.</mat-label>
              <input matInput autocomplete="off" placeholder="Member Mobile No." formControlName="member_mobile_4" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Current Engagement</mat-label>
              <mat-select autocomplete="off" placeholder="Current Engagement" formControlName="member_engagement_4">
                <mat-option>Select</mat-option>
                <mat-option value="Studying">Studying</mat-option>
                <mat-option value="Graduated">Graduated</mat-option>
                <mat-option value="Incubated">Incubated</mat-option>
                <mat-option value="Employed">Employed</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Gender</mat-label>
              <mat-select autocomplete="off" placeholder="Gender" formControlName="member_gender_4">
                <mat-option>Select</mat-option>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Category</mat-label>
              <mat-select autocomplete="off" placeholder="Member Category" formControlName="member_category_4">
                <mat-option>Select</mat-option>
                <mat-option value="General">General</mat-option>
                <mat-option value="OBC">OBC</mat-option>
                <mat-option value="ST">ST</mat-option>
                <mat-option value="SC">SC</mat-option>
                <mat-option value="EWS">EWS</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

        </div>

        <!-- Row Ends  -->

        <div class="row rws-filedsection">
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Name</mat-label>
              <input matInput autocomplete="off" placeholder="Member Name" formControlName="member_name_5" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Email</mat-label>
              <input matInput autocomplete="off" placeholder="Member Email" formControlName="member_email_5" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Member Mobile No.</mat-label>
              <input matInput autocomplete="off" placeholder="Member Mobile No." formControlName="member_mobile_5" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Current Engagement</mat-label>
              <mat-select autocomplete="off" placeholder="Current Engagement" formControlName="member_engagement_5">
                <mat-option>Select</mat-option>
                <mat-option value="Studying">Studying</mat-option>
                <mat-option value="Graduated">Graduated</mat-option>
                <mat-option value="Incubated">Incubated</mat-option>
                <mat-option value="Employed">Employed</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Gender</mat-label>
              <mat-select autocomplete="off" placeholder="Gender" formControlName="member_gender_5">
                <mat-option>Select</mat-option>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Member Category</mat-label>
              <mat-select autocomplete="off" placeholder="Member Category" formControlName="member_category_5">
                <mat-option>Select</mat-option>
                <mat-option value="General">General</mat-option>
                <mat-option value="OBC">OBC</mat-option>
                <mat-option value="ST">ST</mat-option>
                <mat-option value="SC">SC</mat-option>
                <mat-option value="EWS">EWS</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

        </div>

        <!-- Row Ends  -->

      </div>
    </mat-card>


    <mat-card style="overflow: hidden;">
      <div class="rws-teamdetails">
          <h3>Team Mentor Details</h3>
        <div class="row rws-filedsection">
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mentor Name</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Name" formControlName="mentor_name_1" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mentor Email</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Email" formControlName="mentor_email_1" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mentor Mobile No.</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Mobile No." formControlName="mentor_mobile_1" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mentor Position</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Position" formControlName="mentor_pos_1" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Mentor Organization</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Organization" formControlName="mentor_org_1" type="text"   required>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Mentor Type</mat-label>
              <mat-select autocomplete="off" placeholder="Mentor Type" formControlName="mentor_type_1"  required>
                <mat-option>Select</mat-option>
                <mat-option value="External">External</mat-option>
                <mat-option value="Internal">Internal</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

        </div>

        <!-- Row Ends  -->

        <div class="row rws-filedsection">
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mentor Name 2</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Name" formControlName="mentor_name_2" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mentor Email 2</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Email" formControlName="mentor_email_2" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mentor Mobile No. 2</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Mobile No." formControlName="mentor_mobile_2" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mentor Position 2</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Position" formControlName="mentor_pos_2" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
              </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Mentor Organization 2</mat-label>
              <input matInput autocomplete="off" placeholder="Mentor Organization" formControlName="mentor_org_2" type="text">
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

          <div class="col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Mentor Type 2</mat-label>
              <mat-select autocomplete="off" placeholder="Mentor Type" formControlName="mentor_type_2">
                <mat-option>Select</mat-option>
                <mat-option value="External">External</mat-option>
                <mat-option value="Internal">Internal</mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
          </mat-form-field>
          </div>

        </div>

        <!-- Row Ends  -->


      </div>
    </mat-card>


    <p>
      <button mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicImpactQuestions.invalid'>Submit</button>
    </p>

	</form>
</div>
