<div class="container">
    <h2 style="text-align: center">Reset Password</h2>
    <form [formGroup]="resetForm" ngSubmit="onSubmit(resetForm.value)">
        <div class="row" style="text-align: center">
            <mat-form-field appearance="outline" style="width:300px !important">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" readonly>
            </mat-form-field>
        </div>
        <div class="row" style="text-align: center">
            <mat-form-field appearance="outline" style="width:300px !important">
                <mat-label>Current Password</mat-label>
                <input type="password" matInput formControlName="old_password" placeholder="Current Password" required>
                <mat-error *ngIf="resetForm.controls.old_password.errors?.required">Current  Password is required
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row" style="text-align: center">
            <mat-form-field appearance="outline" style="width:300px !important">
                <mat-label>New Password</mat-label>
                <input type="password" matInput formControlName="new_password" placeholder="New Password" required>
                <mat-error *ngIf="resetForm.controls.new_password.errors?.required">New Password is required
                </mat-error>
				
            </mat-form-field>
			
        </div>
		<div class="row" style="text-align: center">
            <p class="mat-error" role="alert" id="mat-error-0">Password must contain one uppercase, one lowercase <br>and minimum length should be 8 characters</p>
			
        </div>
        <div class="row" style="text-align: center">
            <mat-form-field appearance="outline" style="width:300px !important">
                <mat-label>Confirm Password</mat-label>
                <input type="password" matInput formControlName="confirm_password" placeholder="Confirm Password" required>
                <mat-error *ngIf="resetForm.controls.confirm_password.errors?.required">Confirm Password is required
                </mat-error>
            </mat-form-field>
        </div>
        <div mat-dialog-actions align="center">
            <button mat-raised-button mat-dialog-close type="button" class="btn btn-danger instituteBtnClose" color="warn">Close</button>
            <button mat-raised-button type="submit" class="btn btn-success btnDownSuccess resetPassInstiProfile" color="primary" [disabled]="resetForm.invalid"
                (click)=onSubmit(resetForm.value)>Submit</button>
        </div>
    </form>
</div>