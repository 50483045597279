
<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>
<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">

<!-- <div class="cbp-row wh-page-title-bar">
	<div class="cbp-container">
		<div class="one whole breadcrumbPadding">
			<h1 class="page-title">About MIC</h1>							
		</div>
	</div>
</div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Photo Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Gallery</a></li>
	<li class="breadcrumb-item"><a href="gallery/photo-gallery">Photo Gallery</a></li>
    <li class="breadcrumb-item active" aria-current="page">Regional Meet Dindigul 2022</li>
  </ul>
</nav>


<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Regional Meet held on 8<sup>th</sup> Aug, 2022 at Dindigul</h2>
				<div>
					<a class="backButton" href="gallery/photo-gallery">Back to Gallery</a>
				</div>
			</div>
		</div>
		</div>	  
		  
	</div>	
 </div>

<div class="container">
		<div class="row">
			<div class="gallery col-md-12">
				<div class="row">
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-1.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-1.JPG" alt="First image">
						</a>
						<!-- <p class="newsParaBottom">Regional Meet 2022</p> -->
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-4.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-4.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-31.jpg" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-31.jpg" alt="First image">
						</a>
					</div>
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-6.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-6.JPG" alt="First image">
						</a>
					</div> -->
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-7.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-7.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-12.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-12.JPG" alt="First image">
						</a>
					</div> -->	


					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-22.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-22.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-23.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-23.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-24.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-24.JPG" alt="First image">
						</a>
					</div>
					
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-25.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-25.JPG" alt="First image">
						</a>
					</div> -->
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-26.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-26.JPG" alt="First image">
						</a>
					</div>	
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-27.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-27.JPG" alt="First image">
						</a>
					</div> -->
					<!-- <div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-28.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-28.JPG" alt="First image">
						</a>
					</div>
					<div class="col-md-4">
						<a class="lightbox" href="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-29.JPG" data-caption="Regional Meet">
							<img src="{{imageBasePath}}assets/wp_images/regional-meet/madurai/md-29.JPG" alt="First image">
						</a>
					</div> -->

				</div>
			</div>
			
		</div>
	</div> 

<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
