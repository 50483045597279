<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
<!-- <div class="cbp-container"> -->
<!-- <div class="one whole breadcrumbPadding"> -->
<!-- <h1 class="page-title">IA Ambassador Profile</h1>	-->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Institute List</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
	<ul class="breadcrumb iicWebBreadcrumb">
		<li class="breadcrumb-item"><a routerLink="/">Home</a></li>
		<!-- <li class="breadcrumb-item"><a>Resources</a></li> -->
		<li class="breadcrumb-item active" aria-current="page">Institute List</li>
	</ul>
</nav>

<section class="iaAmbassadorProfileBox">
	<div class="container">
		<div class="row">
			<div class="container-login100">
				<div class="col-xs-12 ">
					<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
						<div class="tab-pane fade show active" id="nav-faculty" role="tabpanel"
							aria-labelledby="nav-home-tab">
							<div class="container">
								<div class="row drop-sub-box">
									<div class="col-md-12">
										<h3 class="tableHeadingIIC">Institute List</h3>
									</div>
									<div class="col-md-12">
										<div class="row">
										<form [formGroup]="innovation" (ngSubmit)="onSubmit(innovation.value)"
										class="formIICInnoFaculty ng-pristine ng-valid ng-touched" novalidate="">

										<div class="col-md-3">
											<mat-form-field class="col-12 example-full-width" appearance="outline"
												style="margin: auto;">
												<!-- <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon> -->
												<mat-label>Institute Type</mat-label>
												<mat-select placeholder="Institute Type" formControlName="instituteType"
													[(ngModel)]="TypeModel" (selectionChange)="getState()">

													<mat-option value="">All</mat-option>
													<mat-option *ngFor="let instituteTypeItems of instituteType"
														[value]="instituteTypeItems.id">
														{{instituteTypeItems.type_subtype}}</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
                  <!--   <div class="col-md-3" style="display: none;">
                      <mat-form-field class="col-10 example-full-width" appearance="outline"
												style="margin: auto;">
												<mat-label>Institute Course</mat-label>
												<mat-select placeholder="Institute Course"
													formControlName="instituteCourses" [(ngModel)]="CourseModel"
													(selectionChange)="getCourseState()">
													<mat-option value="">All</mat-option>

													<mat-option *ngFor="let instituteCoursesItems of instituteCourses"
														[value]="instituteCoursesItems.courses">
														{{instituteCoursesItems.courses}}</mat-option>
												</mat-select>
											</mat-form-field>
                    </div> -->
										<div class="col-md-3">
											<mat-form-field class="col-12 example-full-width" appearance="outline"
												style="margin: auto;">
												<mat-label>Select State</mat-label>
												<mat-select formControlName="typeState" [(ngModel)]="StateModel">
													<mat-option value="">All</mat-option>
													<mat-option *ngFor="let stateItems of state"
														[value]="stateItems.id">
														{{stateItems.stateName}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<div class="col-md-3">
											<mat-form-field class="col-12 example-full-width" appearance="outline"
												style="margin: auto;">
												<mat-label>Select Zone</mat-label>
												<mat-select formControlName="typeRegion" [(ngModel)]="RegionModel">
													<mat-option value="">All</mat-option>
													<mat-option *ngFor="let regionItems of region"
														[value]="regionItems.regional_zone">
														{{regionItems.regional_zone}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<div class="col-md-2">
											<div class="form-group">
												<button _ngcontent-pfa-c3="" class="sub-but" id="submit" name="Submit"
													type="submit">Submit</button>
											</div>
										</div>
									</form>
									</div>
									</div>
									
									<div class="col-md-12"> 
										<div class="row">
										<form [formGroup]="innovationCourse"
										(ngSubmit)="onSubmitCourse(innovationCourse.value)"
										class="formIICInnoFaculty ng-pristine ng-valid ng-touched" novalidate="">
										<div class="col-md-4">
											<!-- <mat-form-field class="col-10 example-full-width" appearance="outline"
												style="margin: auto;"> -->
												<!-- <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">check_cirle</mat-icon> -->
												<!-- <mat-label>Institute Course</mat-label>
												<mat-select placeholder="Institute Course"
													formControlName="instituteCourses" [(ngModel)]="CourseModel"
													(selectionChange)="getCourseState()">
													<mat-option value="">All</mat-option>

													<mat-option *ngFor="let instituteCoursesItems of instituteCourses"
														[value]="instituteCoursesItems.courses">
														{{instituteCoursesItems.courses}}</mat-option>
												</mat-select>
											</mat-form-field> -->
										</div>
										<div class="col-md-4">
											<!-- <mat-form-field class="col-10 example-full-width" appearance="outline"
												style="margin: auto;">
												<mat-label>Select State</mat-label>
												<mat-select formControlName="courseState" [(ngModel)]="StateModel2">
													<mat-option value="">All</mat-option>
													<mat-option *ngFor="let stateItems of state"
														[value]="stateItems.id">
														{{stateItems.stateName}}
													</mat-option>
												</mat-select>
											</mat-form-field> -->
										</div>
										<!-- <div class="col-md-2">
											<div class="form-group">
												<button _ngcontent-pfa-c3="" class="sub-but" id="submitCourse"
													name="SubmitCourse" type="submit">Submit</button>
											</div>
										</div> -->
									</form>
									</div>
									</div>
									<div class="col-md-12">
										<form [formGroup]="innovationCourse"
										(ngSubmit)="onSubmitCourse(innovationCourse.value)"
										class="formIICInnoFaculty ng-pristine ng-valid ng-touched" novalidate="">		
										<div class="col-md-12">
											<mat-form-field class="example-full-width instiListSearch" style="width: 100%;">
											<mat-label>Search by Institute Name</mat-label>
											<input matInput formControlName="instituteName" placeholder="Search by Institute Name">
											<!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
										</mat-form-field>
										</div>
									</form>
									</div>									
								</div>
							</div>

							<div class="container-fluid">
								<div class="row">
									<div class="col-md-12">
										<div class="tab-box" id="newContentIaEventReport">
											<div class="dataTables_wrapper no-footer" id="myTable_ia_wrapper">
												<div class="table-responsive">
												<table
													class="table table-bordered dataTable no-footer newTablePagiIncub"
													style="color: #000;" id="myTable_ia" role="grid"
													aria-describedby="myTable_ia_info">
													<thead>
														<tr role="row">
															<th width="5%" class="sorting_asc" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-sort="ascending"
																aria-label="S.No.: activate to sort column descending">
																S.No.</th>
															<th width="10%" class="sorting_asc" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-sort="ascending"
																aria-label="IIC ID: activate to sort column descending">
																IIC ID</th>
															<th width="70%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="Institute Name: activate to sort column ascending">
																Institute Name</th>
															<th width="3%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="City:: activate to sort column ascending">
																City</th>
															<th width="5%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="State:: activate to sort column ascending">
																State</th>
															<th width="5%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="Zone:: activate to sort column ascending">
																Zone</th>
															<th width="20%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="Council Details:: activate to sort column ascending">
																Council Details</th>
                                <!-- <th width="10%" class="sorting" tabindex="0"
																aria-controls="myTable_ia" rowspan="1" colspan="1"
																aria-label="State:: activate to sort column ascending">
																Annual Report</th> -->
														</tr>
													</thead>
													<tbody>
                            <tr role="row" class="odd"
															*ngFor="let details of theme; let i = index;">
															<td class="sorting_1" style="
														text-align: center;
													">{{(i + 1)+ (10 * (currentPageNo-1))}}</td>
															<td>
																{{details?.userId}}
															</td>
															<td>
																{{details?.instituteName}}
															</td>
															<td>
																{{details?.instituteCity}}
															</td>
															<td>
																{{details?.stateName}}
															</td>
															<td>{{details?.instituteZone}}</td>
															<td>
																<p >
																  <a target="_blank" class="btn btn-info instiListBut" href="/council-detail/{{details?.userId}}">
																	View Council Detail
																  </a>
																</p>
															  </td>
                              <!-- <td>
                                <p *ngIf="details?.annual_report">
                                  <a target="_blank" class="btn btn-info" href="https://api.mic.gov.in{{details?.annual_report}}">
                                    View Report
                                  </a>
                                </p>
                              </td> -->

														</tr>
													</tbody>
												</table>
											</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12" style="
										margin-left: 347px;
										margin-top: 21px;">
											<mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)"
												[length]="total_pages" [pageIndex]="frontCurrentPageNo"
												[pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
											</mat-paginator>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
