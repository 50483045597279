import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';

import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-institute-atal-mentor-schedule',
  templateUrl: './institute-atal-mentor-schedule.component.html',
  styleUrls: ['./institute-atal-mentor-schedule.component.css'],
})
export class InstituteAtalMentorScheduleComponent implements OnInit {
  selectionForm: FormGroup;
  currentPageNo: number = 1;
  currentPageNo1: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  myActivity: [];
  reportsClosed: boolean = true;
  quarter_filter: string = '';
  activity_filter:string='';
  total_pages: any;
  total_pages1: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  title1 = "";
  searched: string;
  search_keyword: string;
  eventId: any;
  cityList: any;
  showStatus1: any;
  showStatus2: any;
  districtList: any;
  accept_status: any;
  MyActivityannualReport: number = 0;
  actiontype: any;
  totalActive:any;
  iic_mem:any;
  ia_evnt:any;
  atlSchoolRequest:any;
  checkUserAccepted:number = 0;

  constructor(
    private formBuilder:FormBuilder,
    private activityService: InstituteActivityService,
    public dialog: MatDialog,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {

    this.showStatus1=0;
    this.showStatus2=0;
    this.totalActive=0;
    this.getMyActivityDetails(this.currentPageNo);
    this.getScheduleTable();
    this.activityService.getAtlCityDistrictList('all').subscribe(
      (data) => this.handleResponseList(data, 'all')
    );
    this.activityService.getATLSchoolRequest().subscribe(
      (data) => this.handleResponseATLSchool(data)
    );
    this.getIACoordinator(this.currentPageNo1);
    this.selectionForm=this.formBuilder.group({
      'member':[null, Validators.required],
    });
  }
  handleResponseListIA(data){
    this.ia_evnt = data.result.data;
    this.total_pages1 = data.total;

    if (data.result.current_page ==
      data.result.total) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data.result.current_page == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }
  handleResponseATLSchool(data){
      this.atlSchoolRequest = data.data;
      this.checkUserAccepted = data.checkUserAccepted;
  }
  getIACoordinator(IAPage){
    this.activityService.getAtlIACoordinator(this.per_page, IAPage).subscribe(
      (data) => this.handleResponseListIA(data)
    );
  }
  getScheduleTable(){
    this.activityService.AtalSchedules().subscribe(
      data => this.setScheduleTable(data)
    );
  }

  setScheduleTable(data){
    this.iic_mem = data['Schedules6']['member'];
    // this.ia_evnt = data['Schedules6']['ia'];
  }

   handleResponseList(data,type) {
    if(type=="city")
    {
      this.cityList       = data['cities'];
    }
    else
    {
      this.cityList       = data['cities'];
      this.districtList   = data['districts'];
    }
  }

  onSubmitClick(data, type){
    this.activityService.mentorAtalSelection(data.atl_id, type).subscribe(
      data => this.handleResponse2(data)
    );
  }

  handleResponse2(data){
    if(data['status']=='1'){
      alert(data['message']);
      window.location.href='/institute/selected-schools';
    }else{
      this.toastr.error(data['message'])

    }
  }

  // nextPage() {
  //   this.currentPageNo++;
  //   this.getMyActivityDetails(this.currentPageNo);
  // }

  // previousPage() {
  //   this.currentPageNo--;
  //   this.getMyActivityDetails(this.currentPageNo);
  // }

  nextPage(e: any) {
    if(this.per_page != e.pageSize) {
  
        this.currentPageNo1 = (e.pageIndex+1);
      } else {
      this.currentPageNo1 = (e.pageIndex+1);
      }
    this.per_page = e.pageSize;
    this.getIACoordinator(this.currentPageNo1);
  }
  // nextPage() {
  //   this.currentPageNo1++;
  //   this.getIACoordinator(this.currentPageNo1);
  // }

  previousPage() {
    this.currentPageNo1--;
    this.getIACoordinator(this.currentPageNo1);
  }
  

  getMyActivityDetails(pageNo) {
    this.activityService
      .getIicAtalSchoolList2(this.per_page, pageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22','selected')
      .subscribe((data) => this.handleResponse(data));
  }
  getMyActivityDetails1() {
    this.activityService
      .getIicAtalSchoolList2(this.per_page, this.currentPageNo, this.quarter_filter, this.title1,this.activity_filter,'2021-22','selected')
      .subscribe((data) => this.handleResponse(data));
  }
  nextPage1(e: any) {
    if (this.per_page != e.pageSize) {

      this.currentPageNo1 = (e.pageIndex + 1);
    } else {
      this.currentPageNo1 = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    // this.getMyActivityDetails1();
    this.getIACoordinator(this.currentPageNo1);
  }

  reviewRequest(data){
    this.eventId=data;

    this.activityService
    .submitReviewRequest(this.eventId)
    .subscribe((data) => this.reviewResponse(data));
  }

  reviewResponse(data) {
    if(data['status']=='1'){
      this.toastr.success(data['msg']);
      this.getMyActivityDetails(this.currentPageNo);
    }else{
      this.toastr.error(data['msg']);
    }
  }

  onDeleteClick(data){
    this.eventId=data['eventId'];

    if (confirm('Are you sure you want to delete?')) {
      // Save it!
    this.activityService
    .deleteMyActivity(this.eventId)
    .subscribe((data) => this.deleteResponse(data));
    } else {
      // Do nothing!
      console.log('not confirm');
    }
  }

  deleteResponse(data){
if(data['status']=='1'){
  this.toastr.success(data['msg']);
  this.getMyActivityDetails(this.currentPageNo);
}else{
  this.toastr.error(data['msg']);
}
  }

  quarterFilter() {
    this.currentPageNo = 1;
    this.getMyActivityDetails(this.currentPageNo);
  }

  activityFilter(){
    this.currentPageNo = 1;
    this.getMyActivityDetails(this.currentPageNo);
  }

  onDistrictChange(ob){

    let district = ob.value;

    if(district!="")
    {
      this.activityService.getAtlCityDistrictList(district).subscribe(
        (data) => this.handleResponseList(data,'city')
      );
    }
    else
    {
      this.activityService.getAtlCityDistrictList("all").subscribe(
        (data) => this.handleResponseList(data,'all'));
    }

  }

  downloadReport(endPoint, event_id) {
    this.activityService
      .getReportForDownload(endPoint, event_id)
      .subscribe((data) => this.downloadFile(data, endPoint + '.pdf'));
  }

  downloadFile(data, fileName) {
    let file = new Blob([data], { type: 'application/pdf' });
    importedSaveAs.saveAs(file, fileName);
  }


  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searched = filterValue;
  }

  search() {
    this.search_keyword = this.searched;
    this.activityService.getIicAtalSchoolList2(this.per_page, this.currentPageNo, this.quarter_filter, this.search_keyword,this.activity_filter,'2021-22','selected').subscribe(
      data => this.handleResponse(data)
    )
  }

  handleResponse(data) {
    this.myActivity = data.data.data;
    this.total_pages = data.meta.pagination.total;

    this.totalActive = data.meta.pagination.totalActive; 

    if(this.totalActive<1)
    {
      this.showStatus1=1;
    }
    else
    {
      this.showStatus1=0;
    }


    if(this.totalActive>=1)
    {
      this.showStatus2=1;
    }
    else
    {
      this.showStatus2=0;
    }

    this.MyActivityannualReport= data.meta.annualReport;
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

  }

  onNominateClick(type,id){
    var data = {type:type,id:id};
    this.activityService.submitNominateMember(data).subscribe(
      data => this.changeNominate(data)
    );

  }
  changeNominate(data){
    if(data['status'] == "Success"){
      document.getElementById('nominateSuccess').style.display = 'none';
      document.getElementById('nominateRemove').style.display = 'block';
      this.getIACoordinator(this.currentPageNo1);
    }
  }
  changeNominate1(data){
    if(data['status'] == "Success"){
      document.getElementById('nominateSuccess').style.display = 'block';
      document.getElementById('nominateRemove').style.display = 'none';
      this.getIACoordinator(this.currentPageNo1);
    }
  }

  onNominateRemoveClick(type,id){
    var data = {type:type,id:id,is_remove:1};
    this.activityService.submitNominateMember(data).subscribe(
      data => this.changeNominate1(data)
    );
  }

  nominateIICCoordinator(value,atlId){
    var data = {type:1,id:value,atlId : atlId};
    this.activityService.submitNominateMember(data).subscribe(
      data => this.changeNominate2(data)
    );
  }

  changeNominate2(data){
    window.location.href="/institute/selected-schools";
    // this.getMyActivityDetails(this.currentPageNo);
  }

  onSubmitAtlRequest(data,type){
      var postData = {
        'mapping_id' : data,
        'type' : type,
      };
      this.activityService.updateATLRequestStatus(postData).subscribe(
        data => this.handleRequestStatus(data)
      );
  }
  handleRequestStatus(data){
    if(data['status'] == 1){
      this.toastr.success(data['msg']);
      this.activityService.getATLSchoolRequest().subscribe(
        (data) => this.handleResponseATLSchool(data)
      );
    }else{
      this.toastr.error('Something went wrong');
    }
  }

}

