import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IicDashboard1819Service {
  private baseUrl = myGlobals.baseUrl;
  constructor(private http: HttpClient) { }
  
  getCardsData() {
  return this.http.get(
    `${this.baseUrl}/iic-2018-19Dasboard`
  );
}

}
