import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as myGlobals from 'src/app/app.component';
import { HttpClient,HttpHeaders,HttpBackend,HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-institute-mm-dashboard',
  templateUrl: './institute-mm-dashboard.component.html',
  styleUrls: ['./institute-mm-dashboard.component.css']
})
export class InstituteMmDashboardComponent implements OnInit {
  imageBasePath = myGlobals.imageBasePath;
  iicD:any;
  rid:any;
  aishe_code:any;
  
  private kapilaUrl = myGlobals.kapilaApiPath;
  kapilaToken:any;
  kapilaResponse:any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute,
    private instituteMaster: InstituteInnovationContestService,
    private instituteActivityService :InstituteActivityService,
    private toastr: ToastrService,private http: HttpClient,handler: HttpBackend)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });

    this.http = new HttpClient(handler);
  }


  ngOnInit(): void {

    this.instituteActivityService.getIicInsMMDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );
    
  }

  handleResponse(data) {
    this.iicD = data['iicDashboard'];
    this.aishe_code = data['iicDashboard']['aishe_code'];
    this.login();
  }
  login(){
    // const formData = {
    //   'email' : 'iic.mhrd@aicte-india.org',
    //   'password' : 'iic@Aicte!123'
    // };

    const formData =new HttpParams().set('email', 'iic.mhrd@aicte-india.org')    // Ensure correct field names
        .set('password', 'iic@Aicte!123');
    
    let headers = new HttpHeaders({
      'Host': this.kapilaUrl,
      'Origin': 'https://iic.mic.gov.in',
      // 'Origin': this.manthanOrigin,
      // 'X-Frame-Options' : 'ALLOW-FROM',
      'Content-Type' : 'application/x-www-form-urlencoded',
      // 'Access-Control-Allow-Origin' : this.manthanOrigin
      // "Access-Control-Allow-Origin" : "*",
      // "Access-Control-Allow-Headers" : "Origin, X-Requested-With, Content-Type, Accept"
    });
    let options = { headers: headers };
    var dataResponse = this.loginUrl(formData,options).subscribe(
    data => this.handleResponseData(data));
  }
  loginUrl (data,options){
    return this.http.post(`${this.kapilaUrl}/api/auth/login.php`,data,options);
  }
  
  handleResponseData(data){
    if(data['token']){
      this.kapilaToken = data['token'];
      this.addstatsKapila(data['token']);
      // this.addUserManthan(data['Token']);
      // window.location.href='/institute/repository';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }
  
    
  
  addstatsKapila(token){
      // var formData =  {'aishe' : this.aishe_code};
    
    
    let headers = new HttpHeaders({
      'Host': this.kapilaUrl,
      'Origin': 'https://iic.mic.gov.in',
      'Content-Type':'application/x-www-form-urlencoded',
      'Authorization': token
    });
    let options = { headers: headers };
    var dataResponse = this.iicStatsUrl(options).subscribe(
      data => this.handleResponseUserAddInstituteData(data));
  }
  
  iicStatsUrl (options){
    // return this.http.get(`${this.kapilaUrl}iic/stats_of_application.php?aishe=`+this.aishe_code);
    return this.http.get(`${this.kapilaUrl}/api/iic/stats_of_application.php?aishe=`+this.aishe_code,options);
  }
  
  handleResponseUserAddInstituteData(data){
    if(data['status'] == 'Success'){
      this.kapilaResponse = data['data'];
    }else{
      this.toastr.error(data['error']);
    }
  }
}
