import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
@Component({
  selector: 'app-student-training-status',
  templateUrl: './student-training-status.component.html',
  styleUrls: ['./student-training-status.component.css']
})
export class StudentTrainingStatusComponent implements OnInit {
//1
k=1;
l=1;
  checkFlag: boolean;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  nomination = [];
  nominationArray = [];
  nominationArray2 = [];
  nominationArray3 = [];
  nominationArray4 = [];
  acceptedNomination = [];
  count: any;
  frontCurrentPageNo: number = 0;
  total_pages: number;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  nomination_type: any;
  teacher_type: any;
  faculty_type:any;
  public nominationForm: FormGroup;
  teacher: any[];
  id: any;
  delete_id: any;
  edit_id:any;
  basicCount: any;
  advanceCount: any;

  mySelections: string[];
  showbasic: number;
  showdiasablebuttton: boolean;
  fac_type: any;
  showadvance: number;
  showStatus: any;
  concatfaculty:any;
  type_id:any;

  constructor(private fb: FormBuilder, 
    private activityService: InstituteActivityService,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialog: MatDialog,) {
      this.getNomination();
      this.eligibility();
     }

  ngOnInit(): void {
    this.nominationForm = this.fb.group({
      faculty_type: [null, Validators.required],
      faculty_name: [null, Validators.required],
    });

    this.nominationForm.get('faculty_type')
      .valueChanges.subscribe((data) => this.getFacultyName(data));
    this.getCount();

  }

  eligibility() {
    this.activityService.getHOI().subscribe(
      data => this.instituteData(data)
    );
  }

  instituteData(data) {
    if (data) {
      var institute_id = data['hoiDetails']['institute_id'];
      this.activityService.checkActivityButton(institute_id).subscribe(
        data => this.showActivityButton(data)
      );
    }
  }
  showActivityButton(data){
    this.showStatus=data['status'];
    // this.showStatus=0;
  }

  getCount(){
    this.innovationService.getNominateCount().subscribe(
      data => this.setNominateData2(data)
    );
  }

  setNominateData2(data){
    this.basicCount=data['data']['basic_count'];
    // this.advanceCount=data['data']['advance_count'];

    this.showbasic=Number(20 - this.basicCount);
    // this.showadvance=Number(20-this.advanceCount)
  }

  getFacultyName(data) {
    if(data==1)
    {
      this.innovationService.getBasicStudents().subscribe(
      data => this.setBasicData(data)
    );
  }
  // if(data==2){
  //   this.innovationService.getAdvancedFaculties().subscribe(
  //     data => this.setBasicData(data)
  //   );
  // }
  }

  setBasicData(data) {
    this.teacher=[];
    for(let i=0;i<data.length;i++){
      this.teacher.push(data[i])
    }
  }

  onSubmit(data) {
    if(data['faculty_type']==1){
      this.innovationService.SubmitBasicstudent(data).subscribe(
        data => this.handleResponse1(data)
      );
    }
    // if(data['faculty_type']==2){
    //   this.innovationService.SubmitAdvancefaculty(data).subscribe(
    //     data => this.handleResponse1(data)
    //   );
    // }
    }

  handleResponse1(data){
    if(data['message']=="Success"){
      this.toastr.success(data['comment'])
      this.getNomination();
      this.getCount();
      this.reset();
      //document.location.href='/institute/nomination';

    }
    else{
      this.toastr.error(data['message'])
    }
  }

  reset() {
      this.teacher_type = '';
      this.faculty_type='';
    }
  getNomination() {
    this.innovationService.getNominatedIA().subscribe(
      data => this.handleResponse(data)
    );
  }


  changed() {
  this.fac_type=this.nominationForm.get('faculty_type').value;
    if(this.fac_type==1)
  {
    if(this.nominationForm.get('faculty_name').value?.length > this.showbasic ){
      this.showdiasablebuttton=true;
      this.toastr.error("Maximum limit for basic can not be exceed from 10")
        }else{
          this.showdiasablebuttton=false;
        }
  }
  else if(this.fac_type==2){
  
    // if(this.nominationForm.get('faculty_name').value?.length > this.showadvance ){
    //   this.showdiasablebuttton=true;
    //   this.toastr.error("Maximum limit for advanced can not be exceed from 20")
    //     }else{
    //       this.showdiasablebuttton=false;
    //     }
  }  
}

  // nextPage(e: any) {
  //   if (this.per_page != e.pageSize) {

  //     this.currentPageNo = (e.pageIndex + 1);
  //   } else {
  //     this.currentPageNo = (e.pageIndex + 1);
  //   }
  //   this.per_page = e.pageSize;
  //   this.getNomination();
  // }

  // previousPage() {
  //   this.currentPageNo--;
  //   this.getNomination();
  // }

  handleResponse(data) {

    // this.nominationArray = [];
    // this.nominationArray2 = [];
    // for(let i=0;i<data['data'].length;i++){

    //   if(data['data'][i].training_type == 1){
    //     this.nominationArray.push(data['data'][i])
    //   }
    //   else {
    //     this.nominationArray2.push(data['data'][i])
    //   }
    // }
    // data['data'].forEach((element) => {
    //   this.nominationArray.push(element);
    // });
  }
//end 1

}
