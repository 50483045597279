
<div class="row" color="primary" >
    <h2 class="ss"
        style="text-align: center;background-color: #545460;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
        Impact Lecture Session Details</h2>
</div>



    <div class="">
        <div class="">
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">Session</th>
                        <th scope="col">Title of lecture1</th>
                        <th scope="col">Theme of lecture 1</th>
                        <th scope="col">Report Upload For Lecture 1</th>
                        <th scope="col">Title of lecture 2</th>
                        <th scope="col">Theme of lecture 2</th>
                        <th scope="col">Report Upload For Lecture 2</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nomination of sessionList; index as i">
                        <td>{{i+1}}</td>
                        <td>{{nomination?.session}}</td>
                        <td>{{nomination?.title}}</td>
                        <td>{{nomination?.theme}}</td>
                        <td>
                            <button mat-raised-button (click)="onClickOpenForm(nomination?.id, 1, i+1)" class="btn btn-primary" *ngIf="nomination?.isUpload == 0"><mat-icon>create</mat-icon>Upload Report</button>
                            <button mat-raised-button (click)="onClickOpenForm(nomination?.id, 1, i+1)" class="btn btn-primary" *ngIf="nomination?.isUpload"><mat-icon>create</mat-icon>Uploaded Report</button>
                        </td>
                        <td>{{nomination?.title2}}</td>
                        <td>{{nomination?.theme2}}</td>



                        <td>
                            <button mat-raised-button (click)="onClickOpenForm(nomination?.id, 2, i+1)" class="btn btn-primary" *ngIf="nomination?.isUpload == 0"><mat-icon>create</mat-icon>Upload Report</button>
                            <button mat-raised-button (click)="onClickOpenForm(nomination?.id, 2, i+1)" class="btn btn-primary" *ngIf="nomination?.isUpload"><mat-icon>create</mat-icon>Uploaded Report</button>
                        </td>


                    </tr>
                    <tr *ngIf="sessionList?.length < 0" col="6">
                                No Data Found
                    </tr>

                    <!-- <tr *ngIf="convenor_name=='0'" col="6">
                        No Data Found -->
            <!-- </tr> -->


                </tbody>
            </table>
        </div>
    </div>
