<section class="py-8">
  <div class="container cont">
    <div class="px-4 py-4 pb-4 mb-6 bg-white rounded shadow">
      <div class="table-responsive">
        <table class="table mb-0 mt-4 table-bordered table-responsive">
          <thead class="tbhead">
            <tr class="">
              <th class="pt-2 pb-2 px-6">Sno</th>
              <th class="pt-2 pb-2 px-6">Event ID</th>
              <th class="pt-2 pb-2 px-6">Event Title</th>
              <th class="pt-2 pb-2 px-6">Event Type</th>
              <th class="pt-2 pb-2 px-6">Show Status</th>
              <th class="pt-2 pb-2 px-6 ">File</th>
              <th class="pt-2 pb-2 px-6">Date Created</th>
              <th class="pt-2 pb-2 px-6">Action</th>
          </tr>
          </thead>
          <tbody>
            <tr class="border" *ngFor="let event of events;let i = index">
              <td class="py-5 px-6">{{i+1}}</td>
              
              <td class="py-5 px-6">{{event.eventId}}</td>
              <td class="py-5 px-6">
                <span class="">{{event.title}}</span>
              </td>
              <td class="py-5 px-6">
                <span class="">{{event.type}}</span>
              </td>
              <td class="py-5 px-6 sstatus tx-green" *ngIf = "event.show_status == 1">
                <span style="font-weight: bolder;">Active</span>
              </td>
              <td class="py-5 px-6 sstatus tx-red" *ngIf = "event.show_status == 0">
                <span style="font-weight: bolder;">Not Active</span>
              </td>
              <td class="py-5 px-6">
                <a href="{{baseUrl+'/uploads-2024-25/institutes/mic/images/events/'+ event?.attachmentPath}}" target="_blank">
                  <span class="btn1">View
                  </span></a>
                </td>
              <td class="py-5 px-6">
                  <span class="">{{event.dateCreated}}</span>
                </td>
              <td class="py-5 px-6">
                  <a target="_blank"
                  href="/institute/edit-activity/{{event.eventId}}">
                  <span type="button" class="btn2">Edit
                  </span>
                  </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>