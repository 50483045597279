<style type="text/css">

</style>

<div class="container">

  <mat-dialog-content class="mat-typography">

	<div class="row">
  <div class="col-12 dialogBoxMob">
        <h3><span class="dialogSpan">1</span> Registered IIC should upgrade their IIC "My Council" as per IIC 3.0. </h3>
		<h3><span class="dialogSpan">2</span> Update the President and Convenor whatsapp mobile numbers if you wish to become part of IIC Zonal Whatsapp Groups. </h3>
		<h3><span class="dialogSpan">3</span> IIC Calendar and Self Driven Activity reports can be uploaded on IIC portal after upgradation of My Council of existing IICs only.</h3>
		<h3><span class="dialogSpan">4</span> You can download IIC Calendar under Notification Tab. </h3>
		<h3><span class="dialogSpan">5</span> Keep Visiting Notification Tab for future updates.</h3>
  </div>

  <div class="col-12">
      <button type="button" class="btn btn-danger-video video" mat-dialog-close >Close</button>
  </div>
  <!-- <div class="col-6">
      <button type="button" class="btn btn-success" >No</button>
  </div> -->
<!--
  <mat-dialog-actions align="center">
      <button mat-button mat-dialog-close>No</button>
      <button mat-button (click)="deleteDialog()">Yes</button>
  </mat-dialog-actions> -->

</div>

</mat-dialog-content>
</div>

