import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteExternalDialogComponent } from './institute-external-dialog/institute-external-dialog.component';

import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { InstituteExternal } from './institute-external';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../../../../app/app.component';

@Component({
  selector: 'app-institute-external',
  templateUrl: './institute-external.component.html',
  styleUrls: ['./institute-external.component.css']
})
export class InstituteExternalComponent implements OnInit {

  //@ViewChild(InstituteExternalDialogComponent,{static:false}) iec;
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  external: InstituteExternal[] = [];
  baseUrl = myGlobals.baseImageUrl;
  readMore: boolean = true;
  filterData;
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25,50, 100];
  per_page : number = 10;

  constructor(
    public dialog: MatDialog,
    private instituteMyCouncilService: InstituteMyCouncilService
  ) { }

  ngOnInit(): void {
    this.getExternal();
  }

  nextPage() {
    this.currentPageNo++;
    this.getExternal();
  }

  previousPage() {
    this.currentPageNo--;
    this.getExternal();
  }
search(event) {
    if (this.filterData) {
      this.instituteMyCouncilService.searchExternal(this.filterData).subscribe(
        data => this.handleResponse(data)
      );
    } else {
      this.getExternal();
    }
  }
  getExternal() {
    this.instituteMyCouncilService
      .getExternal(this.per_page,this.currentPageNo)
      .subscribe((data) => this.handleResponse(data));

  }
  nextPage1(e: any) {
	  if(this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex+1);
	  } else {
		this.currentPageNo = (e.pageIndex+1);
	  }
	this.per_page = e.pageSize;
    this.getExternal();
  }

  handleResponse(data) {
    this.external = [];

    data['data'].forEach((element) => {
      this.external.push(element);
    });
    this.total_pages = data.meta.pagination.total;

    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }


  openEditDialog(data) {
    const dialogRef = this.dialog.open(InstituteExternalDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getExternal();
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteExternalDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      this.getExternal();

    });
  }

  getInstituteDetails() {
    this.openDialog();
  }

  deleteDialog(data) {
    const dialogRef = this.dialog.open(InstituteExternalDeleteComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getExternal();
    });
  }


  deleteInstituteDetails() {
    alert('Are you sure you want to delete Member Details');
  }



}



@Component({
  selector: 'app-institute-external-delete',
  templateUrl: './institute-external-delete.html',
  // styleUrls: ['./institute-declare-semesters.component.css']
})

export class InstituteExternalDeleteComponent implements OnInit {
  id: any;

  constructor(public dialog: MatDialog,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteExternalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.id = this.data['id'];
    // this.getDeclareSemesters(this.currentPageNo);
  }

  deleteDialog() {
    this.myCouncilService.deleteExternal(this.id).subscribe(
      data => this.handleData(data)
    );
  }

  handleData(data) {
    if (data['status'] = "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Not able to delete !');
    }
    this.myCouncilService.dataUpdated.next(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}






















