<app-iic-web-navbar></app-iic-web-navbar>
<link rel="stylesheet" href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css">
<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">Photo Gallery</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>
<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>Gallery</a></li>
    <li class="breadcrumb-item active" aria-current="page">Regional Meet 2023 Photo Gallery</li>
  </ul>
</nav>
<div id="about" class="about">
	<div class="container">
	   <div class="vc_row flexcss gh-main">
	   <div class="vc_col-md-12 gh-middle">
			<div class="headingAmbassador head-gh-outer">
				<h2>Regional Meet 2023 Photo Gallery</h2>
				<div>
					<a class="backButton" href="/gallery">Back to Gallery</a>
				</div>
			</div>
		</div>
		</div>	  
		  
	</div>	
 </div>
<div id="" class="about">
	<div class="container">
		<div class="row margin-lg-30t">
			<div class="gallery col-md-12">
				<div class="row">
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/chennai-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/chnnai/Pic-33.jpeg" alt="image">
								<p class="newsParaBottom">Regional Meet at Chennai 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>	
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/hublli-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/hublli/IMG_4879.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Hublli 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/indore-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/indore/DIP04189.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Indore 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/mumbai-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/mumbai/1.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Mumbai 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/tirunelveli-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/tirunelveli/IMG_4508.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Tirunelveli 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/varanasi-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/varanasi/DSC_0440.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Varansi 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/kolkata-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/kolkata/DSC09837.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Kolkata 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/guwahati-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/guwahati/1.jpeg" alt="image">
								<p class="newsParaBottom">Regional Meet at Guwahati 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/jaipur-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/jaipur/1.jpeg" alt="image">
								<p class="newsParaBottom">Regional Meet at Jaipur 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/ahmedabad-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/ahmedabad/1.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Ahmedabad 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/bhubaneswar-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/bhubaneswar/1.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Bhubaneswar 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/pune-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/pune/1.jpeg" alt="image">
								<p class="newsParaBottom">Regional Meet at Pune 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/nagpur-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/nagpur/1.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Nagpur 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/bangalore-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/bangalore/1.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Bangalore 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/coimbatore-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/coimbatore/1.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Coimbatore 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
					<div class="col-md-4">
						<div class="gallery-img-outer">
							<a class="gallery-text" routerLink="/noida-photo-2023">
								<img class="sih-gallery-img" src="{{baseurl}}/Regional-Meet-Photo-2023/noida/1.JPG" alt="image">
								<p class="newsParaBottom">Regional Meet at Noida 
									<!-- <br> 22<sup>nd</sup> July 2022 -->
								</p>
							</a>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>	
</div>
<script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>
<app-iic-web-footer></app-iic-web-footer>
