<mat-dialog-content class="assignEvalutDialog mat-typography">
  <div class="container">
      <h3 class="ccHeadingMForAllDolouge" style="margin-bottom:10px !important; color:blueviolet;text-align:center; font-weight: bold;font-size:20px;">Technology Transfer Details</h3>
      <div class="row">
            <form [formGroup]="inchargeForm" (ngSubmit)="onSubmit(inchargeForm.value)" class="irEvaluFor">
              <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="col-md-12 example-full-width" appearance="outline">
                      <mat-label>No. of inventions/innovations ready fotr technology transfer:</mat-label>
                      <input matInput formControlName="number_of_innovation_ready" (keypress)=isNumberKey($event)>

                    </mat-form-field>
                  </div>
              </div>

              <div class="row ">
                  <div class="col-md-12">
                    <mat-label style="margin-bottom:15px;">Has your institute successfully transferred any technology?:</mat-label>
                    <select class="form-control" id="is_transferred" formControlName="is_transferred" style="margin-top:15px;margin-bottom:15px">
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="col-md-12 example-full-width" appearance="outline">
                    <mat-label>No. of innovations that have undergone technology transfer:</mat-label>
                    <input matInput formControlName="number_of_innovation_undergone" (keypress)=isNumberKey($event)>
                  </mat-form-field>
                </div>
              </div>
              <div mat-dialog-actions align="end" class="mt-3 irEvaluaFor">
                  <button type="button" mat-raised-button color="warn" (click)="closeDialog()"
                      class="btn btn-danger instituteBtnClose" style="color: #ffffff;height: 40px !important;border-radius:0 !important;margin-top:0 !important;">
                      Close
                  </button>
                  <button type="submit" mat-raised-button class="btn btn-success" color="primary" style="background-color: green !important;color: #ffffff !important;height: 40px !important;border-radius:0 !important;margin-top:0 !important;">
                      Submit
                  </button>
              </div>
          </form>



      </div>
  </div>
</mat-dialog-content>
