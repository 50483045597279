import { state } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';

@Component({
  selector: 'app-institute-dashboard',
  templateUrl: './institute-dashboard.component.html',
  styleUrls: ['./institute-dashboard.component.css']
})
export class InstituteDashboardComponent implements OnInit {
  aishe_popup_status: any;

  constructor(public dialog: MatDialog,
    private dashboardService: InstituteDashboardService,
    ) { 

   
  }

  ngOnInit(): void {
    this.dashboardService.getAisheCode().subscribe(
      data => this.setDialogStaus(data)
    );
  }

  setDialogStaus(data){
    this.aishe_popup_status=data['rating']['aishe_popup_status']
    if(this.aishe_popup_status==0){
      this.handle();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DashboardDialogComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  handle() {
    this.openDialog();
  }
}

///Dialog Component



@Component({
  selector: 'app-dashboard-dialog',
  templateUrl: './dashboard-dialog.html',
})
export class DashboardDialogComponent implements OnInit {
  input: boolean;
  public aisheForm: FormGroup;
  
  public verifyForm: FormGroup;

  aishe_code: any;
  city: any;
  instituteName: string[];
  state: string[];
  sub_type: string[];
  type: string[];
  showVerifyBox: boolean=false;
  aishe: any;
  status: number;


  constructor(public dialog: MatDialog,private fb: FormBuilder,
    private router: Router,
    private dashboardService: InstituteDashboardService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    
     }
  ngOnInit(): void {

    this.aisheForm = this.fb.group({
      'aishe': [null, Validators.required],
    });
    this.verifyForm = this.fb.group({
      'aishe': [null, Validators.required],
    });
    this.dashboardService.getAisheCode().subscribe(
      data => this.setAisheCode(data)
    );

  }


  setAisheCode(data){
    this.aishe_code=data['rating']['aishe']
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  inputfield() {
    this.input = true;
  }
  correct(){
    this.status=1;
    this.dashboardService.getCorrectStatus(this.status).subscribe(
      data => this.handleResponse(data)
    );   
  }
  
  
  handleResponse(data) {
    if (data['status'] == "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
  }

  onSubmit(data){
 
  }
  onSubmit1(data){
      
    this.dashboardService.verifyAisheCode(data).subscribe(
      data => this.setVerifyData(data)
    ); 
  }
  setVerifyData(data){
if(data['status']==0){
  this.toastr.error(data['msg'])
  this.showVerifyBox=false
} 
else{
this.setshowVerifydata(data);
}
 }
 setshowVerifydata(data){
  this.showVerifyBox=true;

    this.city=data['city'];
    this.instituteName=data['instituteName'];
    this.state=data['state'];
    this.sub_type=data['sub_type'];
    this.type=data['type'];
 }
  
  submitResponse(data) {
    if (data['status'] == "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }
  }
  YesToastr(){
    this.aishe=this.verifyForm.get('aishe').value;
    this.dashboardService.updateAisheCode(this.aishe).subscribe(
      data => this.submitResponse(data)
    ); 
  }

  noToastr(){
    this.status=2
    this.dashboardService.getCorrectStatus(this.status).subscribe(
      data => this.handleResponse4()
    );  

  }
  handleResponse4(){
    this.toastr.success("Please contact your zonal coordinator");
    this.dialogRef.close();
    this.router.navigateByUrl('/institute/contact-us')
  }

}
