import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nominate-faculty-for-nisp',
  templateUrl: './nominate-faculty-for-nisp.component.html',
  styleUrls: ['./nominate-faculty-for-nisp.component.css']
})
export class NominateFacultyForNispComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
