<div class="container" style="margin-top: 30px;">	
  <div class="row" color="primary">
		<div class="col-md-12">
      <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
        Mentor IIC Institute Details
      </h2>
    </div>
	</div>
	<mat-card class="mat-elevation-z6 proInstCardMat">
	  <div class="row">
	    <div class="col-md-12">
        <div class="tableBody">
		      <div class="table-responsive">
            <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">IIC Institute Name</th>
                    <th width="10%">State Name</th>
                    <th width="20%">City</th>
                    <th width="20%">IIC President</th>
                    <th width="15%">IIC Convener</th>
                  </tr>
                </thead>
                <tbody>
                <tr *ngFor="let activity of mentorDetail;let i = index">
                  <td>{{i+1}}</td>
                  <td>{{activity?.instituteName}}</td>
                  <td>{{activity?.name}}</td>
                  <td>{{activity?.instituteCity}}</td>
                  <td>
                    <span>{{activity?.spoc_name}}</span><br />
                    <span>{{activity?.spoc_email}}</span><br />
                    <span>{{activity?.spoc_contact}}</span><br />
                    <span>{{activity?.spoc_designation}}</span><br />
                  </td>
                  <td>
                    <span>{{activity?.cname}}</span><br />
                    <span>{{activity?.email}}</span><br />
                    <span>{{activity?.mobile}}</span><br />
                    <span>{{activity?.department}}</span><br />
                    <span>{{activity?.experience_years}}</span><br />
                  </td>
                <!-- <td>
                <button mat-button routerLink="/institute/atl-activity-report/1/{{ activity?.id }}" style="background-color: green; color: white;">
                    Submit Report
                </button>
            
                </td> -->
                </tr>
                <tr *ngIf="mentorDetail.length==0"><td colspan="8" style=" font-size:18px; text-align: center; color: #721c24;
                background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                There is no activity added yet.
                </td></tr>
            
                </tbody>
            </table>
	        </div>
	      </div>
	    </div>
	  </div>
	</mat-card>
  <div class="row" color="primary">
		<div class="col-md-12">
      <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
        IIC Coordinator for ATL
      </h2>
    </div>
	</div>
	<mat-card class="mat-elevation-z6 proInstCardMat">
	  <div class="row">
	    <div class="col-md-12">
        <div class="tableBody">
		      <div class="table-responsive">
            <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">Name</th>
                    <th width="10%">Email</th>
                    <th width="10%">Mobile</th>
                  </tr>
                </thead>
                <tbody>
                <tr *ngFor="let activity of memberCoordinator;let i = index">
                  <td>{{i+1}}</td>
                <td>{{activity?.name}}</td>
                <td>{{activity?.email}}</td>
                <td>{{activity?.mobile}}</td>
                <!-- <td>
                <button mat-button routerLink="/institute/atl-activity-report/1/{{ activity?.id }}" style="background-color: green; color: white;">
                    Submit Report
                </button>
            
                </td> -->
                </tr>
                <tr *ngIf="mentorDetail.length==0"><td colspan="4" style=" font-size:18px; text-align: center; color: #721c24;
                background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                There is no activity added yet.
                </td></tr>
            
                </tbody>
            </table>
	        </div>
	      </div>
	    </div>
	  </div>
	</mat-card>
  <div class="row" color="primary">
		<div class="col-md-12">
      <h2 class="ss" style="text-align: center;background-color: #116b74;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
        Assigned Mentors for ATL
      </h2>
    </div>
	</div>
	<mat-card class="mat-elevation-z6 proInstCardMat">
	  <div class="row">
	    <div class="col-md-12">
        <div class="tableBody">
		      <div class="table-responsive">
            <table class="table">
                <thead class="facultyTable">
                  <tr>
                    <th width="5%">S.No.</th>
                    <th width="10%">Name</th>
                    <th width="10%">Email</th>
                    <th width="10%">Mobile</th>
                  </tr>
                </thead>
                <tbody>
                <tr *ngFor="let activity of iaCoordinator;let i = index">
                  <td>{{i+1}}</td>
                <td>{{activity?.name}}</td>
                <td>{{activity?.email}}</td>
                <td>{{activity?.mobile}}</td>
                <!-- <td>
                <button mat-button routerLink="/institute/atl-activity-report/1/{{ activity?.id }}" style="background-color: green; color: white;">
                    Submit Report
                </button>
            
                </td> -->
                </tr>
                <tr *ngIf="mentorDetail.length==0"><td colspan="4" style=" font-size:18px; text-align: center; color: #721c24;
                background-color: #f8d7da; border-color: #f5c6cb; font-weight: bold;">
                There is no activity added yet.
                </td></tr>
            
                </tbody>
            </table>
	        </div>
	      </div>
	    </div>
	  </div>
	</mat-card>
</div>
