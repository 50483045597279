  <mat-dialog-content class="mat-typography">

    <div class="container">

        <div class="row perInformation2">
            <div class="col-md-12">
                <h3 class="pInfor" style="font-weight: bold;">I give my confirmation to participate in IIC 4.0</h3>
            </div>
        </div>

        <form [formGroup]="signupForm" (ngSubmit)="onSubmit(signupForm.value)">

          <div class="form-group">
            <mat-form-field class="w-100 ml-3" appearance="outline">
              <mat-label>Institute Sub Category</mat-label>
              <mat-select formControlName="institute_type" required>
                <mat-option value="An Affiliating University">An Affiliating University</mat-option>
                <mat-option value="Institute of National Importance & Central Universities / CFTIs">Institute of National Importance & Central Universities / CFTIs</mat-option>
                <mat-option value="University & Deemed to be University (Govt. & Govt. Aided)">University & Deemed to be University (Govt. & Govt. Aided)</mat-option>
                <mat-option value="University & Deemed to be University (Private / Self Financed)">University & Deemed to be University (Private / Self Financed)</mat-option>
                <mat-option value="Colleges / Institutes (Govt. & Govt. Aided)">Colleges / Institutes (Govt. & Govt. Aided)</mat-option>
                <mat-option value="Colleges / Institutes (Private / Self Financed)">Colleges / Institutes (Private / Self Financed)</mat-option>
              </mat-select>
              <mat-error *ngIf="signupForm.controls.institute_type.errors?.required">
                Institute Sub Category is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-radio-group formControlName="institute_subtype" required aria-label="Type of Courses Offered by the Institute/University:">
              <mat-radio-button value="Technical / Non Technical Both" class="radioButSignUp">Technical / Non Technical : HEI falls under any one of the five sub-categories is offering any technical programs and also non-technical programs from the campus at Diploma/Polytechnic and UG and/or PG and or PhD level</mat-radio-button>
              <mat-radio-button value="Non Technical Only" class="radioButSignUp">Non Technical Only : HEI falls under any one of the five sub-categories is offering only non-technical programs from the campus at UG and/or PG and/or PhD level.</mat-radio-button>
              <mat-error *ngIf="signupForm.controls.institute_subtype.errors?.required">
                Type of Courses Offered by the Institute/University is required
              </mat-error>
            </mat-radio-group>
          </div>


          <mat-dialog-actions>
            <button mat-raised-button color='primary' class="float-right"
              [disabled]='signupForm.invalid'>Submit</button>
          </mat-dialog-actions>

        </form>
    </div>
    <!-- </mat-card> -->
</mat-dialog-content>
<!-- </section> -->
