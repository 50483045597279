
<!-- <div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>IIC Star Rating 2020-21 - University</h2>
				</div>
			</div>
		</div>
	</div>
</div> -->

<div class="container">
  <div class="row">
    <form [formGroup]="innovationCourse"
      (ngSubmit)="onSubmitCourse(innovationCourse.value)"
      class="formIICInnoFaculty ng-pristine ng-valid ng-touched" novalidate="">
      
      <div class="col-md-3">
        <mat-form-field class="ratingDetails1 example-full-width" appearance="outline"
          style="margin: auto;">
          <mat-label>Zone</mat-label>
          <mat-select placeholder="Institute Course"
            formControlName="instituteCourses" (selectionChange)="getCourseState()">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let instituteCoursesItems of instituteCourses"
              [value]="instituteCoursesItems.id">
              {{instituteCoursesItems.regional_zone}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="ratingDetails1 example-full-width" appearance="outline"
          style="margin: auto;">
          <mat-label>Select State</mat-label>
          <mat-select formControlName="courseState" [(ngModel)]="StateModel2">
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let stateItems of state"
              [value]="stateItems.id">
              {{stateItems.stateName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="example-full-width" style="width: 100%;">
          <mat-label>Institute Name / IIC ID</mat-label>
          <input matInput formControlName="instituteName" placeholder="Institute Name / IIC ID">          
      </mat-form-field>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <button _ngcontent-pfa-c3="" class="sub-but" id="submitCourse"
            name="SubmitCourse" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>

<section class="ratingsTable">
	<div class="container">
		<div class="row">
			<div class="col-md-12"><h3 class="tableHeadingIIC">An Affiliating University</h3></div>
			<div class="col-md-12">
				<div class="tab-box" id="newContentIaEventReport">
					<div class="dataTables_wrapper no-footer" id="myTable_ia_wrapper">
						<table class="table table-bordered dataTable no-footer newTablePagiIncub table-responsive" style="color: #000;" role="grid" aria-describedby="myTable_ia_info">
			<thead>
			  <tr role="row">
				  <th class="sorting_asc" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" aria-sort="ascending" style="width: 74px;">S.No.</th>
				  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 70px;">IIC ID</th>
          <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 70px;">Aishe Code</th>
				  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 180px;">Name</th>
				  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 50px;">Reports</th>
				  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 60px;">City</th>
          <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 90px;">State</th>
          <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 150px;">Zone</th>
				  <!-- <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 55px;">Score</th>
				  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 55px;">Rewards</th> -->
				  <th class="sorting" tabindex="0" aria-controls="myTable_ia" rowspan="1" colspan="1" style="width: 150px;">Star Ratings</th>
			  </tr>
			</thead>
			<tbody>

        <tr role="row" class="odd" *ngFor="let details of theme; let i = index;">
					<td class="sorting_1">{{(i + 1)+ (10 * (currentPageNo-1))}}</td>
					<td>{{details?.userId}}</td>
          <td>{{details?.aishe_code}}</td>
					<td>{{details?.instituteName}}</td>
					<td>
            <div class="graphBox" style="clear: both; width:100%;">
              <a href="javascript:void(0);" (click)="openDialog(details?.userId)"><img src="assets/assets/images/graphIcon.png"></a>
              <!-- &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://api.mic.gov.in{{details?.AnnualReport}}" target="_blank"><img src="assets/assets/images/pdfIcon.png"></a> -->
            </div>
        </td>
					<td>{{details?.instituteCity}}</td>
          <td>{{details?.stateName}}</td>
          <td>{{details?.instituteZone}}</td>
					<!-- <td>{{details?.Score}}</td>
					<td>{{details?.Reward}}</td> -->
					<td>
            <ul *ngIf="details?.final_star == 5" class="list-inline rating-list"  style="display: inline-block">
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
            </ul>
            <ul *ngIf="details?.final_star < 5 && details?.final_star > 4" class="list-inline rating-list"  style="display: inline-block">

              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/half-star.png" >
            </ul>
            <ul *ngIf="details?.final_star == 4" class="list-inline rating-list"  style="display: inline-block">

              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="details?.final_star < 4 && details?.final_star > 3" class="list-inline rating-list"  style="display: inline-block">


              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/half-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="details?.final_star == 3" class="list-inline rating-list"  style="display: inline-block">

              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="details?.final_star < 3 && details?.final_star > 2" class="list-inline rating-list"  style="display: inline-block">


              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/half-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="details?.final_star == 2" class="list-inline rating-list"  style="display: inline-block">

              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="details?.final_star < 2 && details?.final_star > 1" class="list-inline rating-list"  style="display: inline-block">


              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/half-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="details?.final_star == 1" class="list-inline rating-list"  style="display: inline-block">

              <img src="{{baseurl}}/uploads/institutes/stars/full-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="details?.final_star < 1 && details?.final_star > 0" class="list-inline rating-list"  style="display: inline-block">

              <img src="{{baseurl}}/uploads/institutes/stars/half-star.png" >
			  <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>
            <ul *ngIf="details?.final_star == 0" class="list-inline rating-list"  style="display: inline-block">
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
              <img src="{{baseurl}}/uploads/institutes/stars/empty-star.png" >
            </ul>

          </td>
				</tr>
				</tbody>
		</table>
					</div>
				</div>
			</div>
			<div class="row">
        <div class="col-md-12" style="margin-top: 0; margin-left: 315px;">
          <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)"
            [length]="total_pages" [pageIndex]="frontCurrentPageNo"
            [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
          </mat-paginator>
        </div>
      </div>
		</div>
	</div>
</section>
