<!-- <div class="container">
  <div class="row" color="primary">
      <h2 class="ss"
          style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;">
          Impact Lecture Series</h2>
  </div>

  <mat-card style="margin-bottom: 60px;">
    <p>Detail of Impact Lecture Program for the academic year 2022-23 will be updated shortly.</p>
  </mat-card>
</div> -->
<div class="container" >
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;padding: 10px 0;color: #fff;border-radius: 4px;">
            Impact Lecture Series</h2>
    </div>
    <mat-card >
      <p>It will be announced soon.</p>
    </mat-card>
    <!-- <mat-card >
      <p>Impact Lecture Nomination has been Closed.</p>
    </mat-card> -->
    <!-- <mat-card style="margin-bottom: 60px;">
      <p>Under the Impact lecture series scheme, IIC institutions can organise up to two sessions(four expert lectures) with the financial support of MIC/AICTE in the field of innovation, IPR, and start up by inviting successful innovators, patent experts and entrepreneurs and resource persons from innovation and start-up ecosystems.</p>
      <p><strong>Number of Sessions:</strong> 2</p>
      <p><strong>Session:</strong> Two lectures per session by different experts</p>
      <p><strong>Mode of Conduct:</strong> Online</p>
      <p><strong>Funding support(Reimbursement Mode):</strong> Rs. 12000 for two sessions (Rs. 3000 per lecture/per expert/per day)</p>
      <p>Result of Impact lecture scheme Phase-I and Phase-II already declared. We will notify when next application call for applying in this scheme will open.</p>
      <-- <p>Sessions must be organised after the declaration of result for beneficiaries under Impact Lecture Scheme - Phase 2.</p> ->
    </mat-card> -->

    <!-- <mat-card style="margin-bottom: 60px;">
      <p>List of institutions selected for Impact Lecture Series funding support</p>
	    <p class="listAnchor"><a href="assets/announcements/impact-result-publication-list-2.pdf" target="_blank">List of HEIs Selected for Organising Impact Lectures in Online Mode (Other than N-E States, J&K, Ladakh, other Himalayan States & Aspirational Districts of Country)</a> </p>
	    <p class="listAnchor"><a href="assets/announcements/impact-result-publication-list-1.pdf" target="_blank">List of HEIs Selected for Organising Impact Lectures in online mode (N-E States, J&K, Ladakh, other Himalayan States & Aspirational Districts of Country)</a> </p>
	    <p class="listAnchor">View Impact Lecture <a href="assets/announcements/Impact-Lecture-Scheme.pdf" target="_blank">Scheme Document</a></p>
    </mat-card> -->




<!-- </div> -->




<!-- Navbar starts -->
<mat-tab-group mat-align-tabs="center"  class="impactLectBoxNav" *ngIf="isEnable==1">
  <!--  -->
  <mat-tab *ngIf="MandateStatus==1 && rating==1 && sessionShow==5">
    <ng-template mat-tab-label>
      Upload Mandate Form
    </ng-template>
    <div style="margin-top: 30px;">
      <app-institute-bank-form></app-institute-bank-form>
</div>
  </mat-tab>

  <!-- <mat-tab *ngIf="rating==1 && reports==1 && myCouncil == 1"> -->
  <mat-tab *ngIf="rating==1">
    <!-- && sessionShow==1 -->
    <ng-template mat-tab-label>
      Nominate a coordinator
    </ng-template>
    <div style="margin-top: 30px;">
<app-nominate-form1></app-nominate-form1>
</div>
  </mat-tab>
  <!-- && isEnable==1 -->
  <mat-tab  *ngIf="checkNomination==1 && rating==1 && sessionShow==0" >
    <ng-template mat-tab-label>
      Add Session Details
    </ng-template>
    <div style="margin-top: 30px;">
    <app-nominate-form2></app-nominate-form2>
    </div>
  </mat-tab>


  <!-- <mat-tab  *ngIf="rating==1" >
    <ng-template mat-tab-label>
      Add Session Details
    </ng-template>
    <div style="margin-top: 30px;">
    <app-nominate-form2></app-nominate-form2>
    </div>
  </mat-tab> -->



  <mat-tab  *ngIf="MandateStatus==1 && rating==1 && sessionShow==0" >
    <ng-template mat-tab-label>
      Session Report
    </ng-template>
    <div style="margin-top: 30px;">
    <app-nominate-form3></app-nominate-form3>
    </div>
  </mat-tab>
  <!-- && isEnable==1 -->
  <mat-tab *ngIf="MandateStatus==1">
    <ng-template mat-tab-label>
      Statement of Expenditure
    </ng-template>
    <div style="margin-top: 30px;">
      <app-impact-soe-form></app-impact-soe-form>
</div>
  </mat-tab>

</mat-tab-group>

<!-- Navbar Ends -->




<!-- Convener Details -->

<!--
<div class="row" color="primary" >
    <h2 class="ss"
        style="text-align: center;background-color: darkgrey;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
       Convenor Details</h2>
</div> -->


<!-- <div class="form-group">
    <div class="m-4">
        <table class="table table-hover">
            <thead class="facultyTable">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Designation</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="convenor_name!='0'">
                    <td>
                       {{this.convenor_name}}
                    </td>
                    <td>
                       {{convenor_email}}

                    </td>
                    <td>
                     {{convenor_contact}}
                    </td>
                    <td>
                    {{convenor_designation}}

                    </td>

                </tr>
                <tr *ngIf="convenor_name=='0'" col="6">
                            No Data Found
                </tr>
            </tbody>
        </table>
    </div>
</div>  -->





    <!-- List starts -->

    <!-- <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
           Applications for Impact Lecture Session</h2>
    </div> -->


    <!-- <div class="form-group">
        <div class="m-4">
            <table class="table table-hover">
                <thead class="facultyTable">
                    <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Session</th>
                        <th scope="col">Title</th>
                        <th scope="col">Tentative date</th>
                        <th scope="col">Theme</th>
                        <th scope="col">Mode of the session</th>
                        <th scope="col">Report</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let nomination of sessionList; index as i">
                        <td>{{i+1}}</td>
                        <td>{{nomination?.faculty_name}}</td>
                        <td>{{nomination?.session}}</td>
                        <td>{{nomination?.title}}</td>
                        <td>{{nomination?.date}}</td>
                        <td>{{nomination?.theme}}</td>
                        <td>{{nomination?.mode}}</td>
<td>
  <a href="{{baseImageUrl + nomination?.file_upload}}" target="_blank">
  <button type="button" class="btn btn-danger mt-2 mb-2">Download Report</button>
</a></td>

                        <td>
                        	<button mat-raised-button (click)="onEditClick(nomination)" color="primary">
                                <mat-icon>create</mat-icon>
                                Edit</button>
                                  <button mat-raised-button (click)="onDeleteClick(nomination)" color="warn">
                                <mat-icon>delete</mat-icon>
                                Delete</button>
                        </td>
                    </tr>
                    <tr *ngIf="sessionList.length <= 0" col="6">
                                No Data Found
                    </tr>
                </tbody>
            </table>
        </div>
    </div>  -->


    <mat-card *ngIf="this.success=='1' && isEnable==1" >
      <p>Your institute is eligible to apply in Impact Lecture Scheme.</p>
  
        <p>If your institute is willing to participate, please submit the details.<br/>
          <strong>Eligibility Criteria:</strong> <em>(Green tick and Red cross mark shows your status for that criteria)</em>
          </p>
  
        <!-- <p>
        1. The IIC Institution must have given consent to migrate to IIC 4.0 (2021-22).
        <mat-icon style="color: green;"  *ngIf="this.iic40=='1'">done</mat-icon>
        <mat-icon style="color: red;"  *ngIf="this.iic40=='0'">clear</mat-icon>
      </p> -->
      <p>
        1. IIC institutes that have secured 3 star or less during IIC Star Ratings during IIC 4.0 Calendar Year 2021-22 Or Newly joined IIC institutions.
        <mat-icon style="color: green;"  *ngIf="this.rating=='1'">done</mat-icon>
        <mat-icon style="color: red;"  *ngIf="this.rating=='0'">clear</mat-icon>
      </p>
     
      
    <br>
    <!-- <p>
      3. Granted :
      <mat-icon style="color: green;"  *ngIf="this.granted=='1'">done</mat-icon>
      <mat-icon style="color: red;"  *ngIf="this.granted=='0'">clear</mat-icon>
    </p>   <br> -->
    <p>
    2. The IIC Institution must have completed MY COUNIL for the current IIC Calendar year.
    <a href="/institute/activity">Click Here</a>
    <mat-icon style="color: green;"  *ngIf="this.myCouncil=='1'">done</mat-icon>
    <mat-icon style="color: red;"  *ngIf="this.myCouncil=='0'">clear</mat-icon>
  </p>
  <p *ngIf="this.myCouncil=='0'">Please complete the council meeting, you can refer to the guide https://iic.mic.gov.in/assets/html/Newtopic20.html  <a href="/institute/my-council">To go to the My Council page press here</a></p>
   
    <!-- <p>
      <strong>NOTE:</strong> Your application will be complete only after the completion of MY COUNCIL on or before 31st March 2022 which is the mandatory criteria for the consideration of your application.
    </p> -->
     <p>
      3. The IIC Institution must have submitted at least 1 activity report during the Q1 – Q4 of IIC 5.0 Calendar year 2022-23.
      <mat-icon style="color: green;"  *ngIf="this.reports=='1'">done</mat-icon>
      <mat-icon style="color: red;"  *ngIf="this.reports=='0'">clear</mat-icon>
    </p>
    <p *ngIf="this.reports=='0'">To attain eligibility for this scheme you have to upload at least one activity report.  
      If you have not conducted any activity before in your institution you can conduct an activity to celebrate the "successful landing of Chandrayan-III on the South pole of the Moon". <a href="/institute/self-driven-activity-add">Press here to submit the report </a></p>
  
    <!--<p>
      6. Types of Institutions(For College and Standalone only):
      <mat-icon style="color: green;"  *ngIf="this.type=='1'">done</mat-icon>
      <mat-icon style="color: red;"  *ngIf="this.type=='0'">clear</mat-icon>
    </p> -->
  
  </mat-card>
  
  <mat-card *ngIf="this.success=='4' && isEnable==1" >
      <p>Your institute is not eligible to join in Impact Lecture Series Program.</p>
  
      <p>If your institute is willing to participate, please submit the details.<br/>
        <strong>Eligibility Criteria:</strong> <em>(Green tick and Red cross mark shows your status for that criteria)</em>
        </p>
  
      <!-- <p>
        1. The IIC Institution must have given consent to migrate to IIC 4.0 (2021-22).
        <mat-icon style="color: green;"  *ngIf="this.iic40=='1'">done</mat-icon>
        <mat-icon style="color: red;"  *ngIf="this.iic40=='0'">clear</mat-icon>
      </p> -->
      <p>
        1. IIC institutes that have secured 3 star or less during IIC Star Ratings during IIC 4.0 Calendar Year 2021-22 Or Newly joined IIC institutions.
        <mat-icon style="color: green;"  *ngIf="this.rating=='1'">done</mat-icon>
        <mat-icon style="color: red;"  *ngIf="this.rating=='0'">clear</mat-icon>
      </p>
      
    <!-- <p>
      <strong>NOTE:</strong> Please complete the My Council Section to enable the Impact Lecture nomination.
    </p> -->
    <!-- <br> -->
    <!-- <p>
      3. Granted :
      <mat-icon style="color: green;"  *ngIf="this.granted=='1'">done</mat-icon>
      <mat-icon style="color: red;"  *ngIf="this.granted=='0'">clear</mat-icon>
    </p>   <br> -->
    <p>
      2. The IIC Institution must have completed MY COUNIL for the current IIC Calendar year.
      <a href="/institute/activity">Click Here</a>
      <mat-icon style="color: green;"  *ngIf="this.myCouncil=='1'">done</mat-icon>
      <mat-icon style="color: red;"  *ngIf="this.myCouncil=='0'">clear</mat-icon>
    </p>
    <p *ngIf="this.myCouncil=='0'">Please complete the council meeting, you can refer to the guide https://iic.mic.gov.in/assets/html/Newtopic20.html  <a href="/institute/my-council">To go to the My Council page press here</a></p>
    <!-- <p>
      <strong>NOTE:</strong> Please complete the My Council Section to enable the Impact Lecture nomination.
    </p> -->
  
     <p>
      3. The IIC Institution must have submitted at least 1 activity report during the Q1 – Q4 of IIC 5.0 Calendar year 2022-23.
      <mat-icon style="color: green;"  *ngIf="this.reports=='1'">done</mat-icon>
      <mat-icon style="color: red;"  *ngIf="this.reports=='0'">clear</mat-icon>
      
    </p>
    <p *ngIf="this.reports=='0'">To attain eligibility for this scheme you have to upload at least one activity report.  
      If you have not conducted any activity before in your institution you can conduct an activity to celebrate the "successful landing of Chandrayan-III on the South pole of the Moon". <a href="/institute/self-driven-activity-add">Press here to submit the report </a></p>
    <!--<p>
      6. Types of Institutions(For College and Standalone only):
      <mat-icon style="color: green;"  *ngIf="this.type=='1'">done</mat-icon>
      <mat-icon style="color: red;"  *ngIf="this.type=='0'">clear</mat-icon>
    </p> -->
  
  
  </mat-card>
      </div>
