import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { ActivityReportForm1Component } from '../activity-report-form1/activity-report-form1.component';
import * as myGlobals from 'src/app/app.component';
@Component({
  selector: 'app-activity-report-form-qpm',
  templateUrl: './activity-report-form-qpm.component.html',
  styleUrls: ['./activity-report-form-qpm.component.css']
})
export class ActivityReportFormQpmComponent implements OnInit {

  public addActivityForm2: FormGroup;
  public addActivityForm5: FormGroup;
  public addActivityForm2_2: FormGroup;
  public addActivityForm2_3: FormGroup;
  public addActivityForm2_4: FormGroup;
  baseUrl = myGlobals.baseUrl1;

  Status:any;
  activity: any[];
  schedules:any[];
  schedules2:any;
  schedules3:any[];
  schedules4:any[];
  Schedules2_2:[];
  Schedules2_3:[];
  Schedules2_4:[];
  schedules5:any;
  activityid: any;
  activity1: boolean;
  activity2: boolean;
  activity3: boolean;
  activity5: boolean;
  activity4: boolean;
  guest_name_1_2_1:any;
  guest_name_2_2_1:any;
  guest_name_3_2_1:any;
  guest_name_4_2_1:any;
  guest_name_5_2_1:any;
  guest_name_1_3_1:any;
  guest_name_2_3_1:any;
  guest_name_3_3_1:any;
  guest_name_4_3_1:any;
  guest_name_5_3_1:any;
  guest_name_1_4_1:any;
  guest_name_2_4_1:any;
  guest_name_3_4_1:any;
  guest_name_4_4_1:any;
  guest_name_5_4_1:any;
  guest_name_6_2_1:any;
  guest_name_7_2_1:any;
  guest_name_8_2_1:any;
  guest_name_9_2_1:any;
  guest_name_10_2_1:any;

guest_name_6_3_1:any;
  guest_name_7_3_1:any;
  guest_name_8_3_1:any;
  guest_name_9_3_1:any;
  guest_name_10_3_1:any;

  RQ_21_file:any;
  RQ_22_file:any;
  RQ_23_file:any;
  RQ_24_file:any;

guest_name_6_4_1:any;
  guest_name_7_4_1:any;
  guest_name_8_4_1:any;
  guest_name_9_4_1:any;
  guest_name_10_4_1:any;
  TMentee:any;

  Mentee:any;

  minDate = "2023-09-01";
  maxDate = "2024-12-31";

  numbers:any=[];
  newVar:any=[];

  constructor(private activityService: InstituteActivityService,private router:Router,
    public dialog: MatDialog, private toastr: ToastrService,private fb: FormBuilder) { }

  ngOnInit(): void {
    this.newVar = ['mentee_1_4_1'];
    this.getDataTable();
    this.getScheduleTable();
    this.ActivityStatus();

    this.Schedules2_2=[];
    this.Schedules2_3=[];
    this.Schedules2_4=[];

    this.TMentee=0;

    this.addActivityForm2= this.fb.group({
      'mentee_1_1': [null,Validators.required],
      'date_1_1': [null,Validators.required],
      'time_1_1': [null,Validators.required],
      'guest_1_1':[null,Validators.required],
      'guest_1_2':[null],
      'session_1_1':[null],
      'mentee_2_1': [null],
      'date_2_1': [null],
      'time_2_1': [null],
      'guest_2_1':[null],
      'guest_2_2':[null],
      'session_2_1':[null],
      'mentee_3_1': [null],
      'date_3_1': [null],
      'time_3_1': [null],
      'guest_3_1':[null],
      'guest_3_2':[null],
      'session_3_1':[null],
      'mentee_4_1': [null],
      'date_4_1': [null],
      'time_4_1': [null],
      'guest_4_1':[null],
      'guest_4_2':[null],
      'session_4_1':[null],
      'mentee_5_1': [null],
      'date_5_1': [null],
      'time_5_1': [null],
      'guest_5_1':[null],
      'guest_5_2':[null],
      'session_5_1':[null],
      'mentee_6_1': [null],
      'date_6_1': [null],
      'time_6_1': [null],
      'guest_6_1':[null],
      'guest_6_2':[null],
      'session_6_1':[null],
      'mentee_7_1': [null],
      'date_7_1': [null],
      'time_7_1': [null],
      'guest_7_1':[null],
      'guest_7_2':[null],
      'session_7_1':[null],
      'mentee_8_1': [null],
      'date_8_1': [null],
      'time_8_1': [null],
      'guest_8_1':[null],
      'guest_8_2':[null],
      'session_8_1':[null],
      'mentee_9_1': [null],
      'date_9_1': [null],
      'time_9_1': [null],
      'guest_9_1':[null],
      'guest_9_2':[null],
      'session_9_1':[null],
      'mentee_10_1': [null],
      'date_10_1': [null],
      'time_10_1': [null],
      'guest_10_1':[null],
      'guest_10_2':[null],
      'session_10_1':[null]
     });

     this.addActivityForm2_2= this.fb.group({
      'mentee_1_2_1': [null,Validators.required],
      'date_1_2_1': [null,Validators.required],
      'time_1_2_1': [null,Validators.required],
      'guest_1_2_1':[null,Validators.required],
      'session_1_2_1':[null],
      'mentee_2_2_1': [null],
      'date_2_2_1': [null],
      'time_2_2_1': [null],
      'guest_2_2_1':[null],
      'session_2_2_1':[null],
      'mentee_3_2_1': [null],
      'date_3_2_1': [null],
      'time_3_2_1': [null],
      'guest_3_2_1':[null],
      'session_3_2_1':[null],
      'mentee_4_2_1': [null],
      'date_4_2_1': [null],
      'time_4_2_1': [null],
      'guest_4_2_1':[null],
      'session_4_2_1':[null],
      'mentee_5_2_1': [null],
      'date_5_2_1': [null],
      'time_5_2_1': [null],
      'guest_5_2_1':[null],
      'session_5_2_1':[null],
      'mentee_6_2_1': [null],
      'date_6_2_1': [null],
      'time_6_2_1': [null],
      'guest_6_2_1':[null],
      'session_6_2_1':[null],
      'mentee_7_2_1': [null],
      'date_7_2_1': [null],
      'time_7_2_1': [null],
      'guest_7_2_1':[null],
      'session_7_2_1':[null],
      'mentee_8_2_1': [null],
      'date_8_2_1': [null],
      'time_8_2_1': [null],
      'guest_8_2_1':[null],
      'session_8_2_1':[null],
      'mentee_9_2_1': [null],
      'date_9_2_1': [null],
      'time_9_2_1': [null],
      'guest_9_2_1':[null],
      'session_9_2_1':[null],
      'mentee_10_2_1': [null],
      'date_10_2_1': [null],
      'time_10_2_1': [null],
      'guest_10_2_1':[null],
      'session_10_2_1':[null],
     });

     this.addActivityForm2_3= this.fb.group({
      'mentee_1_3_1': [null,Validators.required],
      'date_1_3_1': [null,Validators.required],
      'time_1_3_1': [null,Validators.required],
      'guest_1_3_1':[null,Validators.required],
      'session_1_3_1':[null,Validators.required],
      'mentee_2_3_1': [null],
      'date_2_3_1': [null],
      'time_2_3_1': [null],
      'guest_2_3_1':[null],
      'session_2_3_1':[null],
      'mentee_3_3_1': [null],
      'date_3_3_1': [null],
      'time_3_3_1': [null],
      'guest_3_3_1':[null],
      'session_3_3_1':[null],
      'mentee_4_3_1': [null],
      'date_4_3_1': [null],
      'time_4_3_1': [null],
      'guest_4_3_1':[null],
      'session_4_3_1':[null],
      'mentee_5_3_1': [null],
      'date_5_3_1': [null],
      'time_5_3_1': [null],
      'guest_5_3_1':[null],
      'session_5_3_1':[null],
      'mentee_6_3_1': [null],
      'date_6_3_1': [null],
      'time_6_3_1': [null],
      'guest_6_3_1':[null],
      'session_6_3_1':[null],
      'mentee_7_3_1': [null],
      'date_7_3_1': [null],
      'time_7_3_1': [null],
      'guest_7_3_1':[null],
      'session_7_3_1':[null],
      'mentee_8_3_1': [null],
      'date_8_3_1': [null],
      'time_8_3_1': [null],
      'guest_8_3_1':[null],
      'session_8_3_1':[null],
      'mentee_9_3_1': [null],
      'date_9_3_1': [null],
      'time_9_3_1': [null],
      'guest_9_3_1':[null],
      'session_9_3_1':[null],
      'mentee_10_3_1': [null],
      'date_10_3_1': [null],
      'time_10_3_1': [null],
      'guest_10_3_1':[null],
      'session_10_3_1':[null],
     });

     this.addActivityForm2_4= this.fb.group({
      'mentee_1_4_1': [null,Validators.required],
      'date_1_4_1': [null,Validators.required],
      'time_1_4_1': [null,Validators.required],
      'guest_1_4_1':[null,Validators.required],
      'session_1_4_1':[null,Validators.required],
      'mentee_2_4_1': [null],
      'date_2_4_1': [null],
      'time_2_4_1': [null],
      'guest_2_4_1':[null],
      'session_2_4_1':[null],
      'mentee_3_4_1': [null],
      'date_3_4_1': [null],
      'time_3_4_1': [null],
      'guest_3_4_1':[null],
      'session_3_4_1':[null],
      'mentee_4_4_1': [null],
      'date_4_4_1': [null],
      'time_4_4_1': [null],
      'guest_4_4_1':[null],
      'session_4_4_1':[null],
      'mentee_5_4_1': [null],
      'date_5_4_1': [null],
      'time_5_4_1': [null],
      'guest_5_4_1':[null],
      'session_5_4_1':[null],
      'mentee_6_4_1': [null],
      'date_6_4_1': [null],
      'time_6_4_1': [null],
      'guest_6_4_1':[null],
      'session_6_4_1':[null],
      'mentee_7_4_1': [null],
      'date_7_4_1': [null],
      'time_7_4_1': [null],
      'guest_7_4_1':[null],
      'session_7_4_1':[null],
      'mentee_8_4_1': [null],
      'date_8_4_1': [null],
      'time_8_4_1': [null],
      'guest_8_4_1':[null],
      'session_8_4_1':[null],
      'mentee_9_4_1': [null],
      'date_9_4_1': [null],
      'time_9_4_1': [null],
      'guest_9_4_1':[null],
      'session_9_4_1':[null],
      'mentee_10_4_1': [null],
      'date_10_4_1': [null],
      'time_10_4_1': [null],
      'guest_10_4_1':[null],
      'session_10_4_1':[null],
     });


  }

  giveConnect(){
    return 'addActivityForm2_4.controls.mentee_1_4_1.errors?.required';
  }

  guestNameValue(i){
    return this.Schedules2_4['guest_name_'+i+'_4_1'];
  }
  guestNameValue3(i){
    return this.Schedules2_3['guest_name_'+i+'_3_1'];
  }
  guestNameValue2(i){
    return this.Schedules2_2['guest_name_'+i+'_2_1'];
  }
  guestNameValue1(i){
    return this.schedules2['guest_name_'+i+'_1'];
  }
  quaterparticipationstatus(i){
    
    return this.schedules2['status_'+i+'_1'];
  }
  quater2participationstatus(i){
    return this.Schedules2_2['status_'+i+'_2_1'];
  }
  quater3participationstatus(i){
    return this.Schedules2_3['status_'+i+'_3_1'];
  }
  quater4participationstatus(i){
    return this.Schedules2_4['status_'+i+'_4_1'];
  }

  guestNameCondition1(i){
    return (this.schedules2['guest_name_'+i+'_1']!='') ? true : false;
  }
  dateButtonCondition1(q,type,i){
    if(q == 1){
      if(type == 1)
        return (this.schedules2['date_'+i+'_1']=='') ? true : false;
      if(type == 2)
        return (this.schedules2['date_'+i+'_1']!='') ? true : false;
    }
    else if(q == 2){
      if(type == 1)
        return (this.schedules2['date_'+i+'_2_1']=='') ? true : false;
      if(type == 2)
        return (this.schedules2['date_'+i+'_2_1']!='') ? true : false;
    }
    else if(q == 3){
      if(type == 1)
        return (this.schedules2['date_'+i+'_3_1']=='') ? true : false;
      if(type == 2)
        return (this.schedules2['date_'+i+'_3_1']!='') ? true : false;
    }
    else if(q == 4){
      if(type == 1)
        return (this.schedules2['date_'+i+'_4_1']=='') ? true : false;
      if(type == 2)
        return (this.schedules2['date_'+i+'_4_1']!='') ? true : false;
    }
  }

  getActivityStatus(){
    this.activityService.getActivityStatus().subscribe(
      data => this.setStatus(data)
    );
  }
    ActivityStatus(){
      this.activityService.activityStatus().subscribe(
        data => this.setactivityStatus(data)
      );
    }
    setactivityStatus(data){

  if(data['1']==1){
    this.activity1=true
  }else{
    this.activity1=false
  }

  if(data['2']==1){
    this.activity2=true
  }else{
    this.activity2=false
  }

  if(data['3']==1){
    this.activity3=true
  }else{
    this.activity3=false
  }

  if(data['4']==1){
    this.activity4=true
  }else{
    this.activity4=false
  }

  if(data['5']==1){
    this.activity5=true
  }else{
    this.activity5=false
  }

    }

  getScheduleTable(){
    this.activityService.schedules().subscribe(
      data => this.setScheduleTable(data)
    );
  }
  getDataTable(){
    this.activityService.activities().subscribe(
      data => this.setDataTable(data)
    );
  }

  openReportDialog(data){

      const dialogRef = this.dialog.open(ActivityReportForm1Component, {
        data: data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.getScheduleTable();
      });

  }

  setStatus(data){
    this.Status=data;
  }
  setScheduleTable(data){
    this.schedules=[];
    for(let i=0;i<data['Schedules'].length;i++){
      this.schedules.push(data['Schedules'][i])
    }

    this.schedules3=[];
    for(let i=0;i<data['Schedules3'].length;i++){
      this.schedules3.push(data['Schedules3'][i])
    }

    this.schedules4=[];
    for(let i=0;i<data['Schedules4'].length;i++){
      this.schedules4.push(data['Schedules4'][i])
    }

    this.Mentee = data["Mentee"];

    this.TMentee=data["TMentee"];
    // this.numbers = Array(this.TMentee).fill(1,0).map((x,i)=>i);
    this.numbers =Array.from({length: this.TMentee}, (_, i) => i + 1);
    this.RQ_21_file=data["RQ_21_file"];
    this.RQ_22_file=data["RQ_22_file"];
    this.RQ_23_file=data["RQ_23_file"];
    this.RQ_24_file=data["RQ_24_file"];

    var kk = 0;
    for(var jj=0; jj < this.TMentee; jj++){
      if(this.TMentee>=kk++)
      {
        if(this.Mentee[jj]?.mentee_userId!="")
        {
          var p1 = 'mentee_'+kk+'_1';
          this.addActivityForm2.patchValue({ [p1]:this.Mentee[jj]?.mentee_userId });
          var p2 = 'mentee_'+kk+'_2_1';
          this.addActivityForm2_2.patchValue({ [p2]:this.Mentee[jj]?.mentee_userId });
          var p3 = 'mentee_'+kk+'_3_1';
          this.addActivityForm2_3.patchValue({ [p3]:this.Mentee[jj]?.mentee_userId });
          var p4 = 'mentee_'+kk+'_4_1';
          this.addActivityForm2_4.patchValue({ [p4]:this.Mentee[jj]?.mentee_userId });
        }
      }
    }
    // if(this.TMentee>=1)
    // {
    //   if(this.Mentee[0].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_1_1':this.Mentee[0].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_1_2_1':this.Mentee[0].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_1_3_1':this.Mentee[0].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_1_4_1':this.Mentee[0].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=2)
    // {
    //   if(this.Mentee[1].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_2_1':this.Mentee[1].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_2_2_1':this.Mentee[1].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_2_3_1':this.Mentee[1].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_2_4_1':this.Mentee[1].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=3)
    // {
    //   if(this.Mentee[2].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_3_1':this.Mentee[2].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_3_2_1':this.Mentee[2].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_3_3_1':this.Mentee[2].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_3_4_1':this.Mentee[2].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=4)
    // {
    //   if(this.Mentee[3].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_4_1':this.Mentee[3].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_4_2_1':this.Mentee[3].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_4_3_1':this.Mentee[3].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_4_4_1':this.Mentee[3].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=5)
    // {
    //   if(this.Mentee[4].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_5_1':this.Mentee[4].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_5_2_1':this.Mentee[4].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_5_3_1':this.Mentee[4].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_5_4_1':this.Mentee[4].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=6)
    // {
    //   if(this.Mentee[5].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_6_1':this.Mentee[5].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_6_2_1':this.Mentee[5].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_6_3_1':this.Mentee[5].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_6_4_1':this.Mentee[5].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=7)
    // {
    //   if(this.Mentee[6].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_7_1':this.Mentee[6].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_7_2_1':this.Mentee[6].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_7_3_1':this.Mentee[6].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_7_4_1':this.Mentee[6].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=8)
    // {
    //   if(this.Mentee[7].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_8_1':this.Mentee[7].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_8_2_1':this.Mentee[7].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_8_3_1':this.Mentee[7].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_8_4_1':this.Mentee[7].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=9  )
    // {
    //   if(this.Mentee[8].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_9_1':this.Mentee[8].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_9_2_1':this.Mentee[8].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_9_3_1':this.Mentee[8].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_9_4_1':this.Mentee[8].mentee_userId });
    //   }
    // }

    // if(this.TMentee>=10)
    // {
    //   if(this.Mentee[9].mentee_userId!="")
    //   {
    //     this.addActivityForm2.patchValue({ 'mentee_10_1':this.Mentee[9].mentee_userId });
    //     this.addActivityForm2_2.patchValue({ 'mentee_10_2_1':this.Mentee[9].mentee_userId });
    //     this.addActivityForm2_3.patchValue({ 'mentee_10_3_1':this.Mentee[9].mentee_userId });
    //     this.addActivityForm2_4.patchValue({ 'mentee_10_4_1':this.Mentee[9].mentee_userId });
    //   }
    // }
    // return false;
    this.schedules2=data['Schedules2'];
    this.schedules5=data['Schedules5'];

    this.Schedules2_2=[];
    this.Schedules2_3=[];
    this.Schedules2_4=[];

    this.Schedules2_2=data['Schedules2_2'];
    this.Schedules2_3=data['Schedules2_3'];
    this.Schedules2_4=data['Schedules2_4'];
    
    // this.guest_name_1_2_1 = data['Schedules2_2']['guest_name_1_2_1'];
    // this.guest_name_2_2_1 = data['Schedules2_2']['guest_name_2_2_1'];
    // this.guest_name_3_2_1 = data['Schedules2_2']['guest_name_3_2_1'];
    // this.guest_name_4_2_1 = data['Schedules2_2']['guest_name_4_2_1'];
    // this.guest_name_5_2_1 = data['Schedules2_2']['guest_name_5_2_1'];
    // this.guest_name_6_2_1 = data['Schedules2_2']['guest_name_6_2_1'];
    // this.guest_name_7_2_1 = data['Schedules2_2']['guest_name_7_2_1'];
    // this.guest_name_8_2_1 = data['Schedules2_2']['guest_name_8_2_1'];
    // this.guest_name_9_2_1 = data['Schedules2_2']['guest_name_9_2_1'];
    // this.guest_name_10_2_1 = data['Schedules2_2']['guest_name_10_2_1'];

    // this.guest_name_1_3_1 = data['Schedules2_3']['guest_name_1_3_1'];
    // this.guest_name_2_3_1 = data['Schedules2_3']['guest_name_2_3_1'];
    // this.guest_name_3_3_1 = data['Schedules2_3']['guest_name_3_3_1'];
    // this.guest_name_4_3_1 = data['Schedules2_3']['guest_name_4_3_1'];
    // this.guest_name_5_3_1 = data['Schedules2_3']['guest_name_5_3_1'];
    // this.guest_name_6_3_1 = data['Schedules2_2']['guest_name_6_3_1'];
    // this.guest_name_7_3_1 = data['Schedules2_2']['guest_name_7_3_1'];
    // this.guest_name_8_3_1 = data['Schedules2_2']['guest_name_8_3_1'];
    // this.guest_name_9_3_1 = data['Schedules2_2']['guest_name_9_3_1'];
    // this.guest_name_10_3_1 = data['Schedules2_2']['guest_name_10_3_1'];

    // this.guest_name_1_4_1 = data['Schedules2_4']['guest_name_1_4_1'];
    // this.guest_name_2_4_1 = data['Schedules2_4']['guest_name_2_4_1'];
    // this.guest_name_3_4_1 = data['Schedules2_4']['guest_name_3_4_1'];
    // this.guest_name_4_4_1 = data['Schedules2_4']['guest_name_4_4_1'];
    // this.guest_name_5_4_1 = data['Schedules2_4']['guest_name_5_4_1'];

    // this.guest_name_6_4_1 = data['Schedules2_2']['guest_name_6_4_1'];
    // this.guest_name_7_4_1 = data['Schedules2_2']['guest_name_7_4_1'];
    // this.guest_name_8_4_1 = data['Schedules2_2']['guest_name_8_4_1'];
    // this.guest_name_9_4_1 = data['Schedules2_2']['guest_name_9_4_1'];
    // this.guest_name_10_4_1 = data['Schedules2_2']['guest_name_10_4_1'];

    var kk1 = 0;
    for(var jj1=0; jj1 <= this.TMentee; jj1++){
      if(this.TMentee>=kk1++)
      {
        if(this.Mentee[jj1]?.mentee_userId!="")
        {
          var p1 = 'mentee_'+kk1+'_1';
          var p2 = 'date_'+kk1+'_1';
          var p3 = 'time_'+kk1+'_1';
          var p4 = 'guest_'+kk1+'_1';
          var p5 = 'guest_'+kk1+'_2';
          var p6 = 'session_'+kk1+'_1';
          var p7 = 'status_'+kk1+'_1';
          this.addActivityForm2.patchValue({
            [p1]		: this.Mentee[jj1]?.mentee_userId,
            [p2]		: data['Schedules2'][p2],
            [p3]		: data['Schedules2'][p3],
            [p4]		: data['Schedules2'][p4],
            [p5]		: data['Schedules2'][p5],
            [p6]		: data['Schedules2'][p6],
            [p7]		: data['Schedules2'][p6],
          });
        }
      }
    }
  //   if(this.TMentee>=1)
  //   {
  //     if(this.Mentee[0].mentee_userId!="")
  //     {
  //       this.addActivityForm2.patchValue({
  //         'mentee_1_1'		: this.Mentee[0].mentee_userId,
  //         'date_1_1'		  : data['Schedules2']['date_1_1'],
  //         'time_1_1'		  : data['Schedules2']['time_1_1'],
  //         'guest_1_1'		  : data['Schedules2']['guest_1_1'],
  //         'guest_1_2'		  : data['Schedules2']['guest_1_2'],
  //         'session_1_1'		: data['Schedules2']['session_1_1'],
  //       });
	//     }
	//   }

	//   if(this.TMentee>=2)
  //   {
  //     if(this.Mentee[1].mentee_userId!="")
  //     {
	// 	this.addActivityForm2.patchValue({
	// 	  'mentee_2_1'		: this.Mentee[1].mentee_userId,
	// 	  'date_2_1'		  : data['Schedules2']['date_2_1'],
	// 	  'time_2_1'		  : data['Schedules2']['time_2_1'],
	// 	  'guest_2_1'		  : data['Schedules2']['guest_2_1'],
	// 	  'guest_2_2'		  : data['Schedules2']['guest_2_2'],
	// 	  'session_2_1'		: data['Schedules2']['session_2_1'],
	// 	 });
	//   }
	// }

	// if(this.TMentee>=3)
  //   {
  //     if(this.Mentee[2].mentee_userId!="")
  //     {
	// 	this.addActivityForm2.patchValue({
	// 	  'mentee_3_1'		: this.Mentee[2].mentee_userId,
	// 	  'date_3_1'		  : data['Schedules2']['date_3_1'],
	// 	  'time_3_1'		  : data['Schedules2']['time_3_1'],
	// 	  'guest_3_1'		  : data['Schedules2']['guest_3_1'],
	// 	  'guest_3_2'		  : data['Schedules2']['guest_3_2'],
	// 	  'session_3_1'		: data['Schedules2']['session_3_1'],
	// 	 });
	//   }
	// }

	// if(this.TMentee>=4)
  //   {
  //     if(this.Mentee[3].mentee_userId!="")
  //     {
	// 	this.addActivityForm2.patchValue({
	// 	  'mentee_4_1'		: this.Mentee[3].mentee_userId,
	// 	  'date_4_1'		  : data['Schedules2']['date_4_1'],
	// 	  'time_4_1'		  : data['Schedules2']['time_4_1'],
	// 	  'guest_4_1'		  : data['Schedules2']['guest_4_1'],
	// 	  'guest_4_2'		  : data['Schedules2']['guest_4_2'],
	// 	  'session_4_1'		: data['Schedules2']['session_4_1'],
	// 	 });
	//   }
	// }

	// if(this.TMentee>=5)
  //   {
  //     if(this.Mentee[4].mentee_userId!="")
  //     {
	// 	this.addActivityForm2.patchValue({
	// 	  'mentee_5_1'		: this.Mentee[4].mentee_userId,
	// 	  'date_5_1'		  : data['Schedules2']['date_5_1'],
	// 	  'time_5_1'		  : data['Schedules2']['time_5_1'],
	// 	  'guest_5_1'		  : data['Schedules2']['guest_5_1'],
	// 	  'guest_5_2'		  : data['Schedules2']['guest_5_2'],
	// 	  'session_5_1'		: data['Schedules2']['session_5_1'],
	// 	 });
	//   }
	// }

	// if(this.TMentee>=6)
  //   {
  //     if(this.Mentee[5].mentee_userId!="")
  //     {
	// 	this.addActivityForm2.patchValue({
	// 	  'mentee_6_1'		: this.Mentee[5].mentee_userId,
	// 	  'date_6_1'		  : data['Schedules2']['date_6_1'],
	// 	  'time_6_1'		  : data['Schedules2']['time_6_1'],
	// 	  'guest_6_1'		  : data['Schedules2']['guest_6_1'],
	// 	  'guest_6_2'		  : data['Schedules2']['guest_5_2'],
	// 	  'session_6_1'		: data['Schedules2']['session_6_1'],
	// 	 });
	//   }
	// }

	// if(this.TMentee>=7)
  //   {
  //     if(this.Mentee[6].mentee_userId!="")
  //     {
	//   this.addActivityForm2.patchValue({
	//     'mentee_7_1'		: this.Mentee[6].mentee_userId,
	// 	  'date_7_1'		  : data['Schedules2']['date_7_1'],
	// 	  'time_7_1'		  : data['Schedules2']['time_7_1'],
	// 	  'guest_7_1'		  : data['Schedules2']['guest_7_1'],
	// 	  'guest_7_2'		  : data['Schedules2']['guest_5_2'],
	// 	  'session_7_1'		: data['Schedules2']['session_7_1'],
	// 	 });
	//   }
	// }

	// if(this.TMentee>=8)
  //   {
  //     if(this.Mentee[7].mentee_userId!="")
  //     {
	// 	this.addActivityForm2.patchValue({
	//     'mentee_8_1'		: this.Mentee[7].mentee_userId,
	// 	  'date_8_1'		  : data['Schedules2']['date_8_1'],
	// 	  'time_8_1'		  : data['Schedules2']['time_8_1'],
	// 	  'guest_8_1'		  : data['Schedules2']['guest_8_1'],
	// 	  'guest_8_2'		  : data['Schedules2']['guest_5_2'],
	// 	  'session_8_1'		: data['Schedules2']['session_8_1'],
	// 	 });
	//   }
	// }

	// if(this.TMentee>=9)
  //   {
  //     if(this.Mentee[8].mentee_userId!="")
  //     {
	// 	this.addActivityForm2.patchValue({
	//     'mentee_9_1'		: this.Mentee[8].mentee_userId,
	// 	  'date_9_1'		  : data['Schedules2']['date_9_1'],
	// 	  'time_9_1'		  : data['Schedules2']['time_9_1'],
	// 	  'guest_9_1'		  : data['Schedules2']['guest_9_1'],
	// 	  'guest_9_2'		  : data['Schedules2']['guest_5_2'],
	// 	  'session_9_1'		: data['Schedules2']['session_9_1'],
	// 	 });
	//   }
	// }

	// if(this.TMentee>=10)
  //   {
  //     if(this.Mentee[9].mentee_userId!="")
  //     {
	// 	this.addActivityForm2.patchValue({
	//     'mentee_10_1'		: this.Mentee[9].mentee_userId,
	// 	  'date_10_1'		  : data['Schedules2']['date_10_1'],
	// 	  'time_10_1'		  : data['Schedules2']['time_10_1'],
	// 	  'guest_10_1'		  : data['Schedules2']['guest_10_1'],
	// 	  'guest_10_2'		  : data['Schedules2']['guest_5_2'],
	// 	  'session_10_1'		: data['Schedules2']['session_10_1'],
	// 	 });
	//   }
	// }
  var kk2 = 0;
  for(var jj2=0; jj2 <= this.TMentee; jj2++){
    if(this.TMentee>=kk2++)
    {
      
      if(this.Mentee[jj2]?.mentee_userId!="")
      {
        var p1 = 'mentee_'+kk2+'_2_1';
        var p2 = 'date_'+kk2+'_2_1';
        var p3 = 'time_'+kk2+'_2_1';
        var p4 = 'guest_'+kk2+'_2_1';
        var p5 = 'session_'+kk2+'_2_1';
        //9-7-24
        var p6 = 'status_'+kk2+'_2_1';

        this.addActivityForm2_2.patchValue({
          [p1]		: this.Mentee[jj2]?.mentee_userId,
          [p2]		  : data['Schedules2_2'][p2],
          [p3]		  : data['Schedules2_2'][p3],
          [p4]		  : data['Schedules2_2'][p4],
          [p5]		: data['Schedules2_2'][p5],
          //9-7-24
          [p6]      :data['Schedules2_2'][p6],
        });
      }
    }
  }
//   if(this.TMentee>=1)
//   {
//     if(this.Mentee[0].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_1_2_1'		: this.Mentee[0].mentee_userId,
//     'date_1_2_1'		  : data['Schedules2_2']['date_1_2_1'],
//     'time_1_2_1'		  : data['Schedules2_2']['time_1_2_1'],
//     'guest_1_2_1'		  : data['Schedules2_2']['guest_1_2_1'],
//     'session_1_2_1'		: data['Schedules2_2']['session_1_2_1'],
//    });
//   }
      // }

  // if(this.TMentee>=2)
  //   {
  //     if(this.Mentee[1].mentee_userId!="")
  //     {
  //   this.addActivityForm2_2.patchValue({
  //     'mentee_2_2_1'		: this.Mentee[1].mentee_userId,
  //     'date_2_2_1'		  : data['Schedules2_2']['date_2_2_1'],
  //     'time_2_2_1'		  : data['Schedules2_2']['time_2_2_1'],
  //     'guest_2_2_1'		  : data['Schedules2_2']['guest_2_2_1'],
  //     'session_2_2_1'		: data['Schedules2_2']['session_2_2_1'],
  //    });
  //   }
  // }

// if(this.TMentee>=3)
//   {
//     if(this.Mentee[2].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_3_2_1'		: this.Mentee[2].mentee_userId,
//     'date_3_2_1'		  : data['Schedules2_2']['date_3_2_1'],
//     'time_3_2_1'		  : data['Schedules2_2']['time_3_2_1'],
//     'guest_3_2_1'		  : data['Schedules2_2']['guest_3_2_1'],
//     'session_3_2_1'		: data['Schedules2_2']['session_3_2_1'],
//    });
//   }
// }

// if(this.TMentee>=4)
//   {
//     if(this.Mentee[3].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_4_2_1'		: this.Mentee[3].mentee_userId,
//     'date_4_2_1'		  : data['Schedules2_2']['date_4_2_1'],
//     'time_4_2_1'		  : data['Schedules2_2']['time_4_2_1'],
//     'guest_4_2_1'		  : data['Schedules2_2']['guest_4_2_1'],
//     'session_4_2_1'		: data['Schedules2_2']['session_4_2_1'],
//    });
//   }
// }

// if(this.TMentee>=5)
//   {
//     if(this.Mentee[4].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_5_2_1'		: this.Mentee[4].mentee_userId,
//     'date_5_2_1'		  : data['Schedules2_2']['date_5_2_1'],
//     'time_5_2_1'		  : data['Schedules2_2']['time_5_2_1'],
//     'guest_5_2_1'		  : data['Schedules2_2']['guest_5_2_1'],
//     'session_5_2_1'		: data['Schedules2_2']['session_5_2_1'],
//    });
//   }
// }

// if(this.TMentee>=6)
//   {
//     if(this.Mentee[5].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_6_2_1'		: this.Mentee[5].mentee_userId,
//     'date_6_2_1'		  : data['Schedules2_2']['date_6_2_1'],
//     'time_6_2_1'		  : data['Schedules2_2']['time_6_2_1'],
//     'guest_6_2_1'		  : data['Schedules2_2']['guest_6_2_1'],
//     'session_6_2_1'		: data['Schedules2_2']['session_6_2_1'],
//    });
//   }
// }

// if(this.TMentee>=7)
//   {
//     if(this.Mentee[6].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_7_2_1'		: this.Mentee[6].mentee_userId,
//     'date_7_2_1'		  : data['Schedules2_2']['date_7_2_1'],
//     'time_7_2_1'		  : data['Schedules2_2']['time_7_2_1'],
//     'guest_7_2_1'		  : data['Schedules2_2']['guest_7_2_1'],
//     'session_7_2_1'		: data['Schedules2_2']['session_7_2_1'],
//    });
//   }
// }

// if(this.TMentee>=8)
//   {
//     if(this.Mentee[7].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_8_2_1'		: this.Mentee[7].mentee_userId,
//       'date_8_2_1'		  : data['Schedules2_2']['date_8_2_1'],
//       'time_8_2_1'		  : data['Schedules2_2']['time_8_2_1'],
//       'guest_8_2_1'		  : data['Schedules2_2']['guest_8_2_1'],
//       'session_8_2_1'		: data['Schedules2_2']['session_8_2_1'],
//    });
//   }
// }

// if(this.TMentee>=9)
//   {
//     if(this.Mentee[8].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_9_2_1'		: this.Mentee[8].mentee_userId,
//     'date_9_2_1'		  : data['Schedules2_2']['date_9_2_1'],
//     'time_9_2_1'		  : data['Schedules2_2']['time_9_2_1'],
//     'guest_9_2_1'		  : data['Schedules2_2']['guest_9_2_1'],
//     'session_9_2_1'		: data['Schedules2_2']['session_9_2_1'],
//    });
//   }
// }

// if(this.TMentee>=10)
//   {
//     if(this.Mentee[9].mentee_userId!="")
//     {
//   this.addActivityForm2_2.patchValue({
//     'mentee_10_2_1'		: this.Mentee[9].mentee_userId,
//     'date_10_2_1'		  : data['Schedules2_2']['date_10_2_1'],
//     'time_10_2_1'		  : data['Schedules2_2']['time_10_2_1'],
//     'guest_10_2_1'		  : data['Schedules2_2']['guest_10_2_1'],
//     'session_10_2_1'		: data['Schedules2_2']['session_10_2_1'],
//    });
//   }
// }

  var kk3 = 0;
  for(var jj3=0; jj3 < this.TMentee; jj3++){
    if(this.TMentee>=kk3++)
    {
      if(this.Mentee[jj3]?.mentee_userId!="")
      {
        var p1 = 'mentee_'+kk3+'_3_1';
        var p2 = 'date_'+kk3+'_3_1';
        var p3 = 'time_'+kk3+'_3_1';
        var p4 = 'guest_'+kk3+'_3_1';
        var p5 = 'session_'+kk3+'_3_1';
        var p6 = 'status_'+kk3+'_3_1'

        this.addActivityForm2_3.patchValue({
          [p1]		: this.Mentee[jj3]?.mentee_userId,
          [p2]		  : data['Schedules2_3'][p2],
          [p3]		  : data['Schedules2_3'][p3],
          [p4]		  : data['Schedules2_3'][p4],
          [p5]		: data['Schedules2_3'][p5],
          [p6]    : data['Schedules2_3'][p6]
        });

      }
    }
  }
// if(this.TMentee>=1)
// {
//   if(this.Mentee[0].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_1_3_1'		: this.Mentee[0].mentee_userId,
//   'date_1_3_1'		  : data['Schedules2_3']['date_1_3_1'],
//   'time_1_3_1'		  : data['Schedules2_3']['time_1_3_1'],
//   'guest_1_3_1'		  : data['Schedules2_3']['guest_1_3_1'],
//   'session_1_3_1'		: data['Schedules2_3']['session_1_3_1'],
//  });
// }
// }

// if(this.TMentee>=2)
// {
//   if(this.Mentee[1].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_2_3_1'		: this.Mentee[1].mentee_userId,
//   'date_2_3_1'		  : data['Schedules2_3']['date_2_3_1'],
//   'time_2_3_1'		  : data['Schedules2_3']['time_2_3_1'],
//   'guest_2_3_1'		  : data['Schedules2_3']['guest_2_3_1'],
//   'session_2_3_1'		: data['Schedules2_3']['session_2_3_1'],
//  });
// }
// }

// if(this.TMentee>=3)
// {
//   if(this.Mentee[2].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_3_3_1'		: this.Mentee[2].mentee_userId,
//   'date_3_3_1'		  : data['Schedules2_3']['date_3_3_1'],
//   'time_3_3_1'		  : data['Schedules2_3']['time_3_3_1'],
//   'guest_3_3_1'		  : data['Schedules2_3']['guest_3_3_1'],
//   'session_3_3_1'		: data['Schedules2_3']['session_3_3_1'],
//  });
// }
// }

// if(this.TMentee>=4)
// {
//   if(this.Mentee[3].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_4_3_1'		: this.Mentee[3].mentee_userId,
//   'date_4_3_1'		  : data['Schedules2_3']['date_4_3_1'],
//   'time_4_3_1'		  : data['Schedules2_3']['time_4_3_1'],
//   'guest_4_3_1'		  : data['Schedules2_3']['guest_4_3_1'],
//   'session_4_3_1'		: data['Schedules2_3']['session_4_3_1'],
//  });
// }
// }

// if(this.TMentee>=5)
// {
//   if(this.Mentee[4].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_5_3_1'		: this.Mentee[4].mentee_userId,
//   'date_5_3_1'		  : data['Schedules2_3']['date_5_3_1'],
//   'time_5_3_1'		  : data['Schedules2_3']['time_5_3_1'],
//   'guest_5_3_1'		  : data['Schedules2_3']['guest_5_3_1'],
//   'session_5_3_1'		: data['Schedules2_3']['session_5_3_1'],
//  });
// }
// }

// if(this.TMentee>=6)
// {
//   if(this.Mentee[5].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_6_3_1'		: this.Mentee[5].mentee_userId,
//   'date_6_3_1'		  : data['Schedules2_3']['date_6_3_1'],
//   'time_6_3_1'		  : data['Schedules2_3']['time_6_3_1'],
//   'guest_6_3_1'		  : data['Schedules2_3']['guest_6_3_1'],
//   'session_6_3_1'		: data['Schedules2_3']['session_6_3_1'],
//  });
// }
// }

// if(this.TMentee>=7)
// {
//   if(this.Mentee[6].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_7_3_1'		: this.Mentee[6].mentee_userId,
//   'date_7_3_1'		  : data['Schedules2_3']['date_7_3_1'],
//   'time_7_3_1'		  : data['Schedules2_3']['time_7_3_1'],
//   'guest_7_3_1'		  : data['Schedules2_3']['guest_7_3_1'],
//   'session_7_3_1'		: data['Schedules2_3']['session_7_3_1'],
//  });
// }
// }

// if(this.TMentee>=8)
// {
//   if(this.Mentee[7].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_8_3_1'		: this.Mentee[7].mentee_userId,
//     'date_8_3_1'		  : data['Schedules2_3']['date_8_3_1'],
//     'time_8_3_1'		  : data['Schedules2_3']['time_8_3_1'],
//     'guest_8_3_1'		  : data['Schedules2_3']['guest_8_3_1'],
//     'session_8_3_1'		: data['Schedules2_3']['session_8_3_1'],
//  });
// }
// }

// if(this.TMentee>=9)
// {
//   if(this.Mentee[8].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_9_3_1'		: this.Mentee[8].mentee_userId,
//   'date_9_3_1'		  : data['Schedules2_3']['date_9_3_1'],
//   'time_9_3_1'		  : data['Schedules2_3']['time_9_3_1'],
//   'guest_9_3_1'		  : data['Schedules2_3']['guest_9_3_1'],
//   'session_9_3_1'		: data['Schedules2_3']['session_9_3_1'],
//  });
// }
// }

// if(this.TMentee>=10)
// {
//   if(this.Mentee[9].mentee_userId!="")
//   {
// this.addActivityForm2_3.patchValue({
//   'mentee_10_3_1'		: this.Mentee[9].mentee_userId,
//   'date_10_3_1'		  : data['Schedules2_3']['date_10_3_1'],
//   'time_10_3_1'		  : data['Schedules2_3']['time_10_3_1'],
//   'guest_10_3_1'		  : data['Schedules2_3']['guest_10_3_1'],
//   'session_10_3_1'		: data['Schedules2_3']['session_10_3_1'],
//  });
// }
// }
    var kk4 = 0;
    for(var jj4=0; jj4 < this.TMentee; jj4++){
      if(this.TMentee>=kk4++)
      {
        if(this.Mentee[jj4]?.mentee_userId!="")
        {
          var p1 = 'mentee_'+kk4+'_4_1';
          var p2 = 'date_'+kk4+'_4_1';
          var p3 = 'time_'+kk4+'_4_1';
          var p4 = 'guest_'+kk4+'_4_1';
          var p5 = 'session_'+kk4+'_4_1';

          this.addActivityForm2_4.patchValue({
            [p1]		: this.Mentee[jj4].mentee_userId,
            [p2]		  : data['Schedules2_4'][p2],
            [p3]		  : data['Schedules2_4'][p3],
            [p4]		  : data['Schedules2_4'][p4],
            [p5]		: data['Schedules2_4'][p5],
          });

        }
      }
    }
// if(this.TMentee>=1)
// {
//   if(this.Mentee[0].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_1_4_1'		: this.Mentee[0].mentee_userId,
//   'date_1_4_1'		  : data['Schedules2_4']['date_1_4_1'],
//   'time_1_4_1'		  : data['Schedules2_4']['time_1_4_1'],
//   'guest_1_4_1'		  : data['Schedules2_4']['guest_1_4_1'],
//   'session_1_4_1'		: data['Schedules2_4']['session_1_4_1'],
//  });
// }
// }

// if(this.TMentee>=2)
// {
//   if(this.Mentee[1].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_2_4_1'		: this.Mentee[1].mentee_userId,
//   'date_2_4_1'		  : data['Schedules2_4']['date_2_4_1'],
//   'time_2_4_1'		  : data['Schedules2_4']['time_2_4_1'],
//   'guest_2_4_1'		  : data['Schedules2_4']['guest_2_4_1'],
//   'session_2_4_1'		: data['Schedules2_4']['session_2_4_1'],
//  });
// }
// }

// if(this.TMentee>=3)
// {
//   if(this.Mentee[2].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_3_4_1'		: this.Mentee[2].mentee_userId,
//   'date_3_4_1'		  : data['Schedules2_4']['date_3_4_1'],
//   'time_3_4_1'		  : data['Schedules2_4']['time_3_4_1'],
//   'guest_3_4_1'		  : data['Schedules2_4']['guest_3_4_1'],
//   'session_3_4_1'		: data['Schedules2_4']['session_3_4_1'],
//  });
// }
// }

// if(this.TMentee>=4)
// {
//   if(this.Mentee[3].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_4_4_1'		: this.Mentee[3].mentee_userId,
//   'date_4_4_1'		  : data['Schedules2_4']['date_4_4_1'],
//   'time_4_4_1'		  : data['Schedules2_4']['time_4_4_1'],
//   'guest_4_4_1'		  : data['Schedules2_4']['guest_4_4_1'],
//   'session_4_4_1'		: data['Schedules2_4']['session_4_4_1'],
//  });
// }
// }

// if(this.TMentee>=5)
// {
//   if(this.Mentee[4].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_5_4_1'		: this.Mentee[4].mentee_userId,
//   'date_5_4_1'		  : data['Schedules2_4']['date_5_4_1'],
//   'time_5_4_1'		  : data['Schedules2_4']['time_5_4_1'],
//   'guest_5_4_1'		  : data['Schedules2_4']['guest_5_4_1'],
//   'session_5_4_1'		: data['Schedules2_4']['session_5_4_1'],
//  });
// }
// }

// if(this.TMentee>=6)
// {
//   if(this.Mentee[5].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_6_4_1'		: this.Mentee[5].mentee_userId,
//   'date_6_4_1'		  : data['Schedules2_4']['date_6_4_1'],
//   'time_6_4_1'		  : data['Schedules2_4']['time_6_4_1'],
//   'guest_6_4_1'		  : data['Schedules2_4']['guest_6_4_1'],
//   'session_6_4_1'		: data['Schedules2_4']['session_6_4_1'],
//  });
// }
// }

// if(this.TMentee>=7)
// {
//   if(this.Mentee[6].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_7_4_1'		: this.Mentee[6].mentee_userId,
//   'date_7_4_1'		  : data['Schedules2_4']['date_7_4_1'],
//   'time_7_4_1'		  : data['Schedules2_4']['time_7_4_1'],
//   'guest_7_4_1'		  : data['Schedules2_4']['guest_7_4_1'],
//   'session_7_4_1'		: data['Schedules2_4']['session_7_4_1'],
//  });
// }
// }

// if(this.TMentee>=8)
// {
//   if(this.Mentee[7].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_8_4_1'		: this.Mentee[7].mentee_userId,
//     'date_8_4_1'		  : data['Schedules2_4']['date_8_4_1'],
//     'time_8_4_1'		  : data['Schedules2_4']['time_8_4_1'],
//     'guest_8_4_1'		  : data['Schedules2_4']['guest_8_4_1'],
//     'session_8_4_1'		: data['Schedules2_4']['session_8_4_1'],
//  });
// }
// }

// if(this.TMentee>=9)
// {
//   if(this.Mentee[8].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_9_4_1'		: this.Mentee[8].mentee_userId,
//   'date_9_4_1'		  : data['Schedules2_4']['date_9_4_1'],
//   'time_9_4_1'		  : data['Schedules2_4']['time_9_4_1'],
//   'guest_9_4_1'		  : data['Schedules2_4']['guest_9_4_1'],
//   'session_9_4_1'		: data['Schedules2_4']['session_9_4_1'],
//  });
// }
// }

// if(this.TMentee>=10)
// {
//   if(this.Mentee[9].mentee_userId!="")
//   {
// this.addActivityForm2_4.patchValue({
//   'mentee_10_4_1'		: this.Mentee[9].mentee_userId,
//   'date_10_4_1'		  : data['Schedules2_4']['date_10_4_1'],
//   'time_10_4_1'		  : data['Schedules2_4']['time_10_4_1'],
//   'guest_10_4_1'		  : data['Schedules2_4']['guest_10_4_1'],
//   'session_10_4_1'		: data['Schedules2_4']['session_10_4_1'],
//  });
// }
// }



  }

  setDataTable(data){
    this.activity=[];
    for(let i=0;i<data['Activities'].length;i++){
      this.activity.push(data['Activities'][i])
    }
  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }

  onSubmit(data, quarter){
    data["formType"]=2;
    const TMantee = this.TMentee;
    data["quarter_1_1"]=quarter;
    data["quarter_2_1"]=quarter;
    data["quarter_3_1"]=quarter;
    data["quarter_4_1"]=quarter;
    data["quarter_5_1"]=quarter;
    data["quarter_6_1"]=quarter;
    data["quarter_7_1"]=quarter;
    data["quarter_8_1"]=quarter;
    data["quarter_9_1"]=quarter;
    data["quarter_10_1"]=quarter;
    for(var jj=2; jj<=TMantee; jj++){
      if(this.addActivityForm2.get('session_'+jj+'_1').value!="" && this.addActivityForm2.get('session_'+jj+'_1').value!= undefined  && this.addActivityForm2.get('session_'+jj+'_1').value!= null)
      {
        if(this.addActivityForm2.get('date_'+jj+'_1').value== undefined  || this.addActivityForm2.get('date_'+jj+'_1').value== null)
        {
          alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_'+jj+'_1').value);
          return false;
        }
        if(this.addActivityForm2.get('time_'+jj+'_1').value== undefined  || this.addActivityForm2.get('time_'+jj+'_1').value== null)
        {
          alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_'+jj+'_1').value);
          return false;
        }
        if(this.addActivityForm2.get('guest_'+jj+'_1').value== undefined  || this.addActivityForm2.get('guest_'+jj+'_1').value== null)
        {
          alert("Please fill the guest for "+this.addActivityForm2.get('mentee_'+jj+'_1').value);
          return false;
        }

        data["date_"+jj+"_1"]=this.changeDate(this.addActivityForm2.get('date_'+jj+'_1').value);

      }
    }
    // if(this.addActivityForm2.get('session_2_1').value!="" && this.addActivityForm2.get('session_2_1').value!= undefined  && this.addActivityForm2.get('session_2_1').value!= null)
	  // {
		//   if(this.addActivityForm2.get('date_2_1').value== undefined  || this.addActivityForm2.get('date_2_1').value== null)
		//   {
		// 	  alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_2_1').value);
		// 	  return false;
		//   }
    //   if(this.addActivityForm2.get('time_2_1').value== undefined  || this.addActivityForm2.get('time_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_2_1').value== undefined  || this.addActivityForm2.get('guest_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_2_1').value);
    //     return false;
    //   }

    //   data["date_2_1"]=this.changeDate(this.addActivityForm2.get('date_2_1').value);

    // }

    // if(this.addActivityForm2.get('session_3_1').value!="" && this.addActivityForm2.get('session_3_1').value!= undefined  && this.addActivityForm2.get('session_3_1').value!= null)
    // {
    //   if(this.addActivityForm2.get('date_3_1').value== undefined  || this.addActivityForm2.get('date_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('time_3_1').value== undefined  || this.addActivityForm2.get('time_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_3_1').value== undefined  || this.addActivityForm2.get('guest_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_3_1').value);
    //     return false;
    //   }

    //   data["date_3_1"]=this.changeDate(this.addActivityForm2.get('date_3_1').value);

    // }

    // if(this.addActivityForm2.get('session_4_1').value!="" && this.addActivityForm2.get('session_4_1').value!= undefined  && this.addActivityForm2.get('session_4_1').value!= null)
    // {
    //   if(this.addActivityForm2.get('date_4_1').value== undefined  || this.addActivityForm2.get('date_4_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('time_4_1').value== undefined  || this.addActivityForm2.get('time_4_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_4_1').value== undefined  || this.addActivityForm2.get('guest_4_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_4_1').value);
    //     return false;
    //   }

    //   data["date_4_1"]=this.changeDate(this.addActivityForm2.get('date_4_1').value);

    // }

    // if(this.addActivityForm2.get('session_5_1').value!="" && this.addActivityForm2.get('session_5_1').value!= undefined  && this.addActivityForm2.get('session_5_1').value!= null)
    // {
    //   if(this.addActivityForm2.get('date_5_1').value== undefined  || this.addActivityForm2.get('date_5_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_5_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('time_5_1').value== undefined  || this.addActivityForm2.get('time_5_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_5_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_5_1').value== undefined  || this.addActivityForm2.get('guest_5_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_5_1').value);
    //     return false;
    //   }

    //   data["date_5_1"]=this.changeDate(this.addActivityForm2.get('date_5_1').value);

    // }

    // if(this.addActivityForm2.get('session_6_1').value!="" && this.addActivityForm2.get('session_6_1').value!= undefined  && this.addActivityForm2.get('session_6_1').value!= null)
    // {
    //   if(this.addActivityForm2.get('date_6_1').value== undefined  || this.addActivityForm2.get('date_6_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_6_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('time_6_1').value== undefined  || this.addActivityForm2.get('time_6_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_6_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_6_1').value== undefined  || this.addActivityForm2.get('guest_6_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_6_1').value);
    //     return false;
    //   }

    //   data["date_6_1"]=this.changeDate(this.addActivityForm2.get('date_6_1').value);

    // }

    // if(this.addActivityForm2.get('session_7_1').value!="" && this.addActivityForm2.get('session_7_1').value!= undefined  && this.addActivityForm2.get('session_7_1').value!= null)
    // {
    //   if(this.addActivityForm2.get('date_7_1').value== undefined  || this.addActivityForm2.get('date_7_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_7_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('time_7_1').value== undefined  || this.addActivityForm2.get('time_7_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_7_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_7_1').value== undefined  || this.addActivityForm2.get('guest_7_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_7_1').value);
    //     return false;
    //   }

    //   data["date_7_1"]=this.changeDate(this.addActivityForm2.get('date_7_1').value);

    // }

    // if(this.addActivityForm2.get('session_8_1').value!="" && this.addActivityForm2.get('session_8_1').value!= undefined  && this.addActivityForm2.get('session_8_1').value!= null)
    // {
    //   if(this.addActivityForm2.get('date_8_1').value== undefined  || this.addActivityForm2.get('date_8_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_8_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('time_8_1').value== undefined  || this.addActivityForm2.get('time_8_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_8_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_8_1').value== undefined  || this.addActivityForm2.get('guest_8_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_8_1').value);
    //     return false;
    //   }

    //   data["date_8_1"]=this.changeDate(this.addActivityForm2.get('date_8_1').value);

    // }

    // if(this.addActivityForm2.get('session_9_1').value!="" && this.addActivityForm2.get('session_9_1').value!= undefined  && this.addActivityForm2.get('session_9_1').value!= null)
    // {
    //   if(this.addActivityForm2.get('date_9_1').value== undefined  || this.addActivityForm2.get('date_9_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_9_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('time_9_1').value== undefined  || this.addActivityForm2.get('time_9_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_9_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_9_1').value== undefined  || this.addActivityForm2.get('guest_9_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_9_1').value);
    //     return false;
    //   }

    //   data["date_9_1"]=this.changeDate(this.addActivityForm2.get('date_9_1').value);

    // }

    // if(this.addActivityForm2.get('session_10_1').value!="" && this.addActivityForm2.get('session_10_1').value!= undefined  && this.addActivityForm2.get('session_10_1').value!= null)
    // {
    //   if(this.addActivityForm2.get('date_10_1').value== undefined  || this.addActivityForm2.get('date_10_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2.get('mentee_10_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('time_10_1').value== undefined  || this.addActivityForm2.get('time_10_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2.get('mentee_10_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2.get('guest_10_1').value== undefined  || this.addActivityForm2.get('guest_10_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2.get('mentee_10_1').value);
    //     return false;
    //   }

    //   data["date_10_1"]=this.changeDate(this.addActivityForm2.get('date_10_1').value);

    // }

    data["date_1_1"]=this.changeDate(this.addActivityForm2.get('date_1_1').value);

    this.activityService
    .submitSchedule25(data)
    .subscribe((data) => this.handleResponse(data));
  }




  onSubmit_2_2(data, quarter){
    const TMantee = this.TMentee;
    data["formType"]=2_2;

    data["quarter_1_2_1"]=quarter;
    data["quarter_2_2_1"]=quarter;
    data["quarter_3_2_1"]=quarter;
    data["quarter_4_2_1"]=quarter;
    data["quarter_5_2_1"]=quarter;
    data["quarter_6_2_1"]=quarter;
    data["quarter_7_2_1"]=quarter;
    data["quarter_8_2_1"]=quarter;
    data["quarter_9_2_1"]=quarter;
    data["quarter_10_2_1"]=quarter;

    
    for(var jj=2; jj<=TMantee; jj++){
      if(this.addActivityForm2_2.get('session_'+jj+'_2_1').value!="" && this.addActivityForm2_2.get('session_'+jj+'_2_1').value!= undefined  && this.addActivityForm2_2.get('session_'+jj+'_2_1').value!= null)
      {
        if(this.addActivityForm2_2.get('date_'+jj+'_2_1').value== undefined  || this.addActivityForm2_2.get('date_'+jj+'_2_1').value== null)
        {
          alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_'+jj+'_2_1').value);
          return false;
        }
        if(this.addActivityForm2_2.get('time_'+jj+'_2_1').value== undefined  || this.addActivityForm2_2.get('time_'+jj+'_2_1').value== null)
        {
          alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_'+jj+'_2_1').value);
          return false;
        }
        if(this.addActivityForm2_2.get('guest_'+jj+'_2_1').value== undefined  || this.addActivityForm2_2.get('guest_'+jj+'_2_1').value== null)
        {
          alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_'+jj+'_2_1').value);
          return false;
        }

        data["date_"+jj+"_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_'+jj+'_2_1').value);

      }
    }
    // if(this.addActivityForm2_2.get('session_2_2_1').value!="" && this.addActivityForm2_2.get('session_2_2_1').value!= undefined  && this.addActivityForm2_2.get('session_2_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_2_2_1').value== undefined  || this.addActivityForm2_2.get('date_2_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_2_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_2_2_1').value== undefined  || this.addActivityForm2_2.get('time_2_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_2_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_2_2_1').value== undefined  || this.addActivityForm2_2.get('guest_2_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_2_2_1').value);
    //     return false;
    //   }

    //   data["date_2_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_2_2_1').value);

    // }

    // if(this.addActivityForm2_2.get('session_3_2_1').value!="" && this.addActivityForm2_2.get('session_3_2_1').value!= undefined  && this.addActivityForm2_2.get('session_3_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_3_2_1').value== undefined  || this.addActivityForm2_2.get('date_3_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_3_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_3_2_1').value== undefined  || this.addActivityForm2_2.get('time_3_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_3_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_3_2_1').value== undefined  || this.addActivityForm2_2.get('guest_3_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_3_2_1').value);
    //     return false;
    //   }

    //   data["date_3_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_3_2_1').value);

    // }

    // if(this.addActivityForm2_2.get('session_4_2_1').value!="" && this.addActivityForm2_2.get('session_4_2_1').value!= undefined  && this.addActivityForm2_2.get('session_4_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_4_2_1').value== undefined  || this.addActivityForm2_2.get('date_4_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_4_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_4_2_1').value== undefined  || this.addActivityForm2_2.get('time_4_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_4_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_4_2_1').value== undefined  || this.addActivityForm2_2.get('guest_4_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_4_2_1').value);
    //     return false;
    //   }

    //   data["date_4_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_4_2_1').value);

    // }

    // if(this.addActivityForm2_2.get('session_5_2_1').value!="" && this.addActivityForm2_2.get('session_5_2_1').value!= undefined  && this.addActivityForm2_2.get('session_5_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_5_2_1').value== undefined  || this.addActivityForm2_2.get('date_5_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_5_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_5_2_1').value== undefined  || this.addActivityForm2_2.get('time_5_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_5_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_5_2_1').value== undefined  || this.addActivityForm2_2.get('guest_5_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_5_2_1').value);
    //     return false;
    //   }

    //   data["date_5_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_5_2_1').value);

    // }

    // if(this.addActivityForm2_2.get('session_6_2_1').value!="" && this.addActivityForm2_2.get('session_6_2_1').value!= undefined  && this.addActivityForm2_2.get('session_6_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_6_2_1').value== undefined  || this.addActivityForm2_2.get('date_6_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_6_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_6_2_1').value== undefined  || this.addActivityForm2_2.get('time_6_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_6_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_6_2_1').value== undefined  || this.addActivityForm2_2.get('guest_6_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_6_2_1').value);
    //     return false;
    //   }

    //   data["date_6_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_6_2_1').value);

    // }

    // if(this.addActivityForm2_2.get('session_7_2_1').value!="" && this.addActivityForm2_2.get('session_7_2_1').value!= undefined  && this.addActivityForm2_2.get('session_7_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_7_2_1').value== undefined  || this.addActivityForm2_2.get('date_7_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_7_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_7_2_1').value== undefined  || this.addActivityForm2_2.get('time_7_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_7_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_7_2_1').value== undefined  || this.addActivityForm2_2.get('guest_7_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_7_2_1').value);
    //     return false;
    //   }

    //   data["date_7_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_7_2_1').value);

    // }

    // if(this.addActivityForm2_2.get('session_8_2_1').value!="" && this.addActivityForm2_2.get('session_8_2_1').value!= undefined  && this.addActivityForm2_2.get('session_8_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_8_2_1').value== undefined  || this.addActivityForm2_2.get('date_8_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_8_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_8_2_1').value== undefined  || this.addActivityForm2_2.get('time_8_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_8_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_8_2_1').value== undefined  || this.addActivityForm2_2.get('guest_8_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_8_2_1').value);
    //     return false;
    //   }

    //   data["date_8_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_8_2_1').value);

    // }


    // if(this.addActivityForm2_2.get('session_9_2_1').value!="" && this.addActivityForm2_2.get('session_9_2_1').value!= undefined  && this.addActivityForm2_2.get('session_9_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_9_2_1').value== undefined  || this.addActivityForm2_2.get('date_9_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_9_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_9_2_1').value== undefined  || this.addActivityForm2_2.get('time_9_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_9_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_9_2_1').value== undefined  || this.addActivityForm2_2.get('guest_9_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_9_2_1').value);
    //     return false;
    //   }

    //   data["date_9_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_9_2_1').value);

    // }

    // if(this.addActivityForm2_2.get('session_10_2_1').value!="" && this.addActivityForm2_2.get('session_10_2_1').value!= undefined  && this.addActivityForm2_2.get('session_10_2_1').value!= null)
    // {
    //   if(this.addActivityForm2_2.get('date_10_2_1').value== undefined  || this.addActivityForm2_2.get('date_10_2_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_2.get('mentee_10_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('time_10_2_1').value== undefined  || this.addActivityForm2_2.get('time_10_2_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_2.get('mentee_10_2_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_2.get('guest_10_2_1').value== undefined  || this.addActivityForm2_2.get('guest_10_2_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_2.get('mentee_10_2_1').value);
    //     return false;
    //   }

    //   data["date_10_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_10_2_1').value);

    // }

    data["date_1_2_1"]=this.changeDate(this.addActivityForm2_2.get('date_1_2_1').value);

    this.activityService
    .submitSchedule25(data)
    .subscribe((data) => this.handleResponse(data));
  }

  onSubmit_2_3(data, quarter){

    data["formType"]=2_3;

    data["quarter_1_3_1"]=quarter;
    data["quarter_2_3_1"]=quarter;
    data["quarter_3_3_1"]=quarter;
    data["quarter_4_3_1"]=quarter;
    data["quarter_5_3_1"]=quarter;
    data["quarter_6_3_1"]=quarter;
    data["quarter_7_3_1"]=quarter;
    data["quarter_8_3_1"]=quarter;
    data["quarter_9_3_1"]=quarter;
    data["quarter_10_3_1"]=quarter;
    const TMantee = this.TMentee;
    for(var jj=2; jj<=TMantee; jj++){
      if(this.addActivityForm2_3.get('session_'+jj+'_3_1').value!="" && this.addActivityForm2_3.get('session_'+jj+'_3_1').value!= undefined  && this.addActivityForm2_3.get('session_'+jj+'_3_1').value!= null)
      {
        if(this.addActivityForm2_3.get('date_'+jj+'_3_1').value== undefined  || this.addActivityForm2_3.get('date_'+jj+'_3_1').value== null)
        {
          alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_'+jj+'_3_1').value);
          return false;
        }
        if(this.addActivityForm2_3.get('time_'+jj+'_3_1').value== undefined  || this.addActivityForm2_3.get('time_'+jj+'_3_1').value== null)
        {
          alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_'+jj+'_3_1').value);
          return false;
        }
        if(this.addActivityForm2_3.get('guest_'+jj+'_3_1').value== undefined  || this.addActivityForm2_3.get('guest_'+jj+'_3_1').value== null)
        {
          alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_'+jj+'_3_1').value);
          return false;
        }

        data["date_"+jj+"_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_'+jj+'_3_1').value);

      }
    }

    // if(this.addActivityForm2_3.get('session_2_3_1').value!="" && this.addActivityForm2_3.get('session_2_3_1').value!= undefined  && this.addActivityForm2_3.get('session_2_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_2_3_1').value== undefined  || this.addActivityForm2_3.get('date_2_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_2_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_2_3_1').value== undefined  || this.addActivityForm2_3.get('time_2_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_2_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_2_3_1').value== undefined  || this.addActivityForm2_3.get('guest_2_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_2_3_1').value);
    //     return false;
    //   }

    //   data["date_2_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_2_3_1').value);

    // }

    // if(this.addActivityForm2_3.get('session_3_3_1').value!="" && this.addActivityForm2_3.get('session_3_3_1').value!= undefined  && this.addActivityForm2_3.get('session_3_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_3_3_1').value== undefined  || this.addActivityForm2_3.get('date_3_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_3_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_3_3_1').value== undefined  || this.addActivityForm2_3.get('time_3_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_3_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_3_3_1').value== undefined  || this.addActivityForm2_3.get('guest_3_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_3_3_1').value);
    //     return false;
    //   }

    //   data["date_3_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_3_3_1').value);

    // }

    // if(this.addActivityForm2_3.get('session_4_3_1').value!="" && this.addActivityForm2_3.get('session_4_3_1').value!= undefined  && this.addActivityForm2_3.get('session_4_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_4_3_1').value== undefined  || this.addActivityForm2_3.get('date_4_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_4_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_4_3_1').value== undefined  || this.addActivityForm2_3.get('time_4_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_4_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_4_3_1').value== undefined  || this.addActivityForm2_3.get('guest_4_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_4_3_1').value);
    //     return false;
    //   }

    //   data["date_4_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_4_3_1').value);

    // }

    // if(this.addActivityForm2_3.get('session_5_3_1').value!="" && this.addActivityForm2_3.get('session_5_3_1').value!= undefined  && this.addActivityForm2_3.get('session_5_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_5_3_1').value== undefined  || this.addActivityForm2_3.get('date_5_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_5_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_5_3_1').value== undefined  || this.addActivityForm2_3.get('time_5_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_5_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_5_3_1').value== undefined  || this.addActivityForm2_3.get('guest_5_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_5_3_1').value);
    //     return false;
    //   }

    //   data["date_5_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_5_3_1').value);

    // }

    // if(this.addActivityForm2_3.get('session_6_3_1').value!="" && this.addActivityForm2_3.get('session_6_3_1').value!= undefined  && this.addActivityForm2_3.get('session_6_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_6_3_1').value== undefined  || this.addActivityForm2_3.get('date_6_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_6_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_6_3_1').value== undefined  || this.addActivityForm2_3.get('time_6_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_6_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_6_3_1').value== undefined  || this.addActivityForm2_3.get('guest_6_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_6_3_1').value);
    //     return false;
    //   }

    //   data["date_6_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_6_3_1').value);

    // }

    // if(this.addActivityForm2_3.get('session_7_3_1').value!="" && this.addActivityForm2_3.get('session_7_3_1').value!= undefined  && this.addActivityForm2_3.get('session_7_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_7_3_1').value== undefined  || this.addActivityForm2_3.get('date_7_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_7_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_7_3_1').value== undefined  || this.addActivityForm2_3.get('time_7_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_7_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_7_3_1').value== undefined  || this.addActivityForm2_3.get('guest_7_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_7_3_1').value);
    //     return false;
    //   }

    //   data["date_7_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_7_3_1').value);

    // }

    // if(this.addActivityForm2_3.get('session_8_3_1').value!="" && this.addActivityForm2_3.get('session_8_3_1').value!= undefined  && this.addActivityForm2_3.get('session_8_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_8_3_1').value== undefined  || this.addActivityForm2_3.get('date_8_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_8_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_8_3_1').value== undefined  || this.addActivityForm2_3.get('time_8_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_8_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_8_3_1').value== undefined  || this.addActivityForm2_3.get('guest_8_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_8_3_1').value);
    //     return false;
    //   }

    //   data["date_8_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_8_3_1').value);

    // }


    // if(this.addActivityForm2_3.get('session_9_3_1').value!="" && this.addActivityForm2_3.get('session_9_3_1').value!= undefined  && this.addActivityForm2_3.get('session_9_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_9_3_1').value== undefined  || this.addActivityForm2_3.get('date_9_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_9_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_9_3_1').value== undefined  || this.addActivityForm2_3.get('time_9_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_9_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_9_3_1').value== undefined  || this.addActivityForm2_3.get('guest_9_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_9_3_1').value);
    //     return false;
    //   }

    //   data["date_9_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_9_3_1').value);

    // }

    // if(this.addActivityForm2_3.get('session_10_3_1').value!="" && this.addActivityForm2_3.get('session_10_3_1').value!= undefined  && this.addActivityForm2_3.get('session_10_3_1').value!= null)
    // {
    //   if(this.addActivityForm2_3.get('date_10_3_1').value== undefined  || this.addActivityForm2_3.get('date_10_3_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_3.get('mentee_10_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('time_10_3_1').value== undefined  || this.addActivityForm2_3.get('time_10_3_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_3.get('mentee_10_3_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_3.get('guest_10_3_1').value== undefined  || this.addActivityForm2_3.get('guest_10_3_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_3.get('mentee_10_3_1').value);
    //     return false;
    //   }

    //   data["date_10_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_10_3_1').value);

    // }

    data["date_1_3_1"]=this.changeDate(this.addActivityForm2_3.get('date_1_3_1').value);

    this.activityService
    .submitSchedule25(data)
    .subscribe((data) => this.handleResponse(data));
  }

  onSubmit_2_4(data, quarter){

    data["formType"]=2_4;

    data["quarter_1_4_1"]=quarter;
    data["quarter_2_4_1"]=quarter;
    data["quarter_3_4_1"]=quarter;
    data["quarter_4_4_1"]=quarter;
    data["quarter_5_4_1"]=quarter;
    data["quarter_6_4_1"]=quarter;
    data["quarter_7_4_1"]=quarter;
    data["quarter_8_4_1"]=quarter;
    data["quarter_9_4_1"]=quarter;
    data["quarter_10_4_1"]=quarter;
    const TMantee = this.TMentee;
    for(var jj=2; jj<=TMantee; jj++){
      if(this.addActivityForm2_4.get('session_'+jj+'_4_1').value!="" && this.addActivityForm2_4.get('session_'+jj+'_4_1').value!= undefined  && this.addActivityForm2_4.get('session_'+jj+'_4_1').value!= null)
      {
        if(this.addActivityForm2_4.get('date_'+jj+'_4_1').value== undefined  || this.addActivityForm2_4.get('date_'+jj+'_4_1').value== null)
        {
          alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_'+jj+'_4_1').value);
          return false;
        }
        if(this.addActivityForm2_4.get('time_'+jj+'_4_1').value== undefined  || this.addActivityForm2_4.get('time_'+jj+'_4_1').value== null)
        {
          alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_'+jj+'_4_1').value);
          return false;
        }
        if(this.addActivityForm2_4.get('guest_'+jj+'_4_1').value== undefined  || this.addActivityForm2_4.get('guest_'+jj+'_4_1').value== null)
        {
          alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_'+jj+'_4_1').value);
          return false;
        }

        data["date_"+jj+"_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_'+jj+'_4_1').value);

      }
    }

    // if(this.addActivityForm2_4.get('session_2_4_1').value!="" && this.addActivityForm2_4.get('session_2_4_1').value!= undefined  && this.addActivityForm2_4.get('session_2_4_1').value!= null)
    // {
    //   if(this.addActivityForm2_4.get('date_2_4_1').value== undefined  || this.addActivityForm2_4.get('date_2_4_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_2_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_4.get('time_2_4_1').value== undefined  || this.addActivityForm2_4.get('time_2_4_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_2_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_4.get('guest_2_4_1').value== undefined  || this.addActivityForm2_4.get('guest_2_4_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_2_4_1').value);
    //     return false;
    //   }

    //   data["date_2_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_2_4_1').value);

    // }

    // if(this.addActivityForm2_4.get('session_3_4_1').value!="" && this.addActivityForm2_4.get('session_3_4_1').value!= undefined  && this.addActivityForm2_4.get('session_3_4_1').value!= null)
    // {
    //   if(this.addActivityForm2_4.get('date_3_4_1').value== undefined  || this.addActivityForm2_4.get('date_3_4_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_3_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_4.get('time_3_4_1').value== undefined  || this.addActivityForm2_4.get('time_3_4_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_3_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_4.get('guest_3_4_1').value== undefined  || this.addActivityForm2_4.get('guest_3_4_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_3_4_1').value);
    //     return false;
    //   }

    //   data["date_3_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_3_4_1').value);

    // }

    // if(this.addActivityForm2_4.get('session_4_4_1').value!="" && this.addActivityForm2_4.get('session_4_4_1').value!= undefined  && this.addActivityForm2_4.get('session_4_4_1').value!= null)
    // {
    //   if(this.addActivityForm2_4.get('date_4_4_1').value== undefined  || this.addActivityForm2_4.get('date_4_4_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_4_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_4.get('time_4_4_1').value== undefined  || this.addActivityForm2_4.get('time_4_4_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_4_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_4.get('guest_4_4_1').value== undefined  || this.addActivityForm2_4.get('guest_4_4_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_4_4_1').value);
    //     return false;
    //   }

    //   data["date_4_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_4_4_1').value);

    // }

    // if(this.addActivityForm2_4.get('session_5_4_1').value!="" && this.addActivityForm2_4.get('session_5_4_1').value!= undefined  && this.addActivityForm2_4.get('session_5_4_1').value!= null)
    // {
    //   if(this.addActivityForm2_4.get('date_5_4_1').value== undefined  || this.addActivityForm2_4.get('date_5_4_1').value== null)
    //   {
    //     alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_5_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_4.get('time_5_4_1').value== undefined  || this.addActivityForm2_4.get('time_5_4_1').value== null)
    //   {
    //     alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_5_4_1').value);
    //     return false;
    //   }
    //   if(this.addActivityForm2_4.get('guest_5_4_1').value== undefined  || this.addActivityForm2_4.get('guest_5_4_1').value== null)
    //   {
    //     alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_5_4_1').value);
    //     return false;
    //   }

    //   data["date_5_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_5_4_1').value);

    // }

    data["date_1_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_1_4_1').value);

    this.activityService
    .submitSchedule25(data)
    .subscribe((data) => this.handleResponse(data));
  }

  onSubmit2(data){

    data["formType"]=5;

    if(this.addActivityForm5.get('date_2_5_1').value!="" && this.addActivityForm5.get('date_2_5_1').value!= undefined  && this.addActivityForm5.get('date_2_5_1').value!= null)
	{
		if(this.addActivityForm5.get('time_2_5_1').value== undefined  || this.addActivityForm5.get('time_2_5_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_2_5_1').value);
			return false;
		}
		if(this.addActivityForm5.get('guest_2_5_1').value== undefined  || this.addActivityForm5.get('guest_2_5_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm5.get('mentee_2_5_1').value);
			return false;
		}

    data["date_2_5_1"]=this.changeDate(this.addActivityForm5.get('date_2_5_1').value);

    }

	if(this.addActivityForm5.get('date_3_5_1').value!="" && this.addActivityForm5.get('date_3_5_1').value!= undefined  && this.addActivityForm5.get('date_3_5_1').value!= null)
	{
		if(this.addActivityForm5.get('time_3_5_1').value== undefined  || this.addActivityForm5.get('time_3_5_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_3_5_1').value);
			return false;
		}
		if(this.addActivityForm5.get('guest_3_5_1').value== undefined  || this.addActivityForm5.get('guest_3_5_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm5.get('mentee_3_5_1').value);
			return false;
		}

    data["date_3_5_1"]=this.changeDate(this.addActivityForm5.get('date_3_5_1').value);

    }

	if(this.addActivityForm5.get('date_4_5_1').value!="" && this.addActivityForm5.get('date_4_5_1').value!= undefined  && this.addActivityForm5.get('date_4_5_1').value!= null)
	{
		if(this.addActivityForm5.get('time_4_5_1').value== undefined  || this.addActivityForm5.get('time_4_5_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_4_5_1').value);
			return false;
		}
		if(this.addActivityForm5.get('guest_4_5_1').value== undefined  || this.addActivityForm5.get('guest_4_5_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm5.get('mentee_4_5_1').value);
			return false;
		}

    data["date_4_5_1"]=this.changeDate(this.addActivityForm5.get('date_4_5_1').value);

    }

	if(this.addActivityForm5.get('date_5_5_1').value!="" && this.addActivityForm5.get('date_5_5_1').value!= undefined  && this.addActivityForm5.get('date_5_5_1').value!= null)
	{
		if(this.addActivityForm5.get('time_5_5_1').value== undefined  || this.addActivityForm5.get('time_5_5_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm5.get('mentee_5_5_1').value);
			return false;
		}
		if(this.addActivityForm5.get('guest_5_5_1').value== undefined  || this.addActivityForm5.get('guest_5_5_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm5.get('mentee_5_5_1').value);
			return false;
		}

    data["date_5_5_1"]=this.changeDate(this.addActivityForm5.get('date_5_5_1').value);

    }

    if(this.addActivityForm2_4.get('session_6_4_1').value!="" && this.addActivityForm2_4.get('session_6_4_1').value!= undefined  && this.addActivityForm2_4.get('session_6_4_1').value!= null)
	{
		if(this.addActivityForm2_4.get('date_6_4_1').value== undefined  || this.addActivityForm2_4.get('date_6_4_1').value== null)
		{
			alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_6_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('time_6_4_1').value== undefined  || this.addActivityForm2_4.get('time_6_4_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_6_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('guest_6_4_1').value== undefined  || this.addActivityForm2_4.get('guest_6_4_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_6_4_1').value);
			return false;
		}

    data["date_6_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_6_4_1').value);

    }

	if(this.addActivityForm2_4.get('session_7_4_1').value!="" && this.addActivityForm2_4.get('session_7_4_1').value!= undefined  && this.addActivityForm2_4.get('session_7_4_1').value!= null)
	{
		if(this.addActivityForm2_4.get('date_7_4_1').value== undefined  || this.addActivityForm2_4.get('date_7_4_1').value== null)
		{
			alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_7_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('time_7_4_1').value== undefined  || this.addActivityForm2_4.get('time_7_4_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_7_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('guest_7_4_1').value== undefined  || this.addActivityForm2_4.get('guest_7_4_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_7_4_1').value);
			return false;
		}

    data["date_7_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_7_4_1').value);

    }

	if(this.addActivityForm2_4.get('session_8_4_1').value!="" && this.addActivityForm2_4.get('session_8_4_1').value!= undefined  && this.addActivityForm2_4.get('session_8_4_1').value!= null)
	{
		if(this.addActivityForm2_4.get('date_8_4_1').value== undefined  || this.addActivityForm2_4.get('date_8_4_1').value== null)
		{
			alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_8_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('time_8_4_1').value== undefined  || this.addActivityForm2_4.get('time_8_4_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_8_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('guest_8_4_1').value== undefined  || this.addActivityForm2_4.get('guest_8_4_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_8_4_1').value);
			return false;
		}

    data["date_8_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_8_4_1').value);

    }


	if(this.addActivityForm2_4.get('session_9_4_1').value!="" && this.addActivityForm2_4.get('session_9_4_1').value!= undefined  && this.addActivityForm2_4.get('session_9_4_1').value!= null)
	{
		if(this.addActivityForm2_4.get('date_9_4_1').value== undefined  || this.addActivityForm2_4.get('date_9_4_1').value== null)
		{
			alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_9_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('time_9_4_1').value== undefined  || this.addActivityForm2_4.get('time_9_4_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_9_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('guest_9_4_1').value== undefined  || this.addActivityForm2_4.get('guest_9_4_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_9_4_1').value);
			return false;
		}

    data["date_9_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_9_4_1').value);

    }

	if(this.addActivityForm2_4.get('session_10_4_1').value!="" && this.addActivityForm2_4.get('session_10_4_1').value!= undefined  && this.addActivityForm2_4.get('session_10_4_1').value!= null)
	{
		if(this.addActivityForm2_4.get('date_10_4_1').value== undefined  || this.addActivityForm2_4.get('date_10_4_1').value== null)
		{
			alert("Please fill the tentative date for "+this.addActivityForm2_4.get('mentee_10_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('time_10_4_1').value== undefined  || this.addActivityForm2_4.get('time_10_4_1').value== null)
		{
			alert("Please fill the tentative time for "+this.addActivityForm2_4.get('mentee_10_4_1').value);
			return false;
		}
		if(this.addActivityForm2_4.get('guest_10_4_1').value== undefined  || this.addActivityForm2_4.get('guest_10_4_1').value== null)
		{
			alert("Please fill the guest for "+this.addActivityForm2_4.get('mentee_10_4_1').value);
			return false;
		}

    data["date_10_4_1"]=this.changeDate(this.addActivityForm2_4.get('date_10_4_1').value);

    }

    data["date_1_5_1"]=this.changeDate(this.addActivityForm5.get('date_1_5_1').value);

    data["quarter_1_5_1"]="Quarter 4";
    data["quarter_2_5_1"]="Quarter 4";
    data["quarter_3_5_1"]="Quarter 4";
    data["quarter_4_5_1"]="Quarter 4";
    data["quarter_5_5_1"]="Quarter 4";
    data["quarter_6_5_1"]="Quarter 4";
    data["quarter_7_5_1"]="Quarter 4";
    data["quarter_8_5_1"]="Quarter 4";
    data["quarter_9_5_1"]="Quarter 4";
    data["quarter_10_5_1"]="Quarter 4";

    this.activityService
    .submitSchedule25(data)
    .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data){
    if(data['message']=="Success"){
      this.toastr.success("Successfully Submitted");
    }

  }


}
