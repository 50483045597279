<div class="form-group">
    <div class="">

      <mat-tab-group class="matGroupActionPlan">
        <mat-tab label="Orientation & Mentoring sessions">

          <h3 style="padding-top: 15px; text-align:center; font-size: 16px; font-weight: bold; margin-bottom: 10px;">Orientation & Mentoring sessions</h3>
          <p>Conduct an orientation cum mentoring session for all the key functionaries of IIC members at mentee institutions.</p>
          <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline1" role="button" aria-expanded="false" aria-controls="rws-guideline1">View Guidlines</a></p>
          <div class="collapse multi-collapse" id="rws-guideline1">
            <div class="card card-body">
              <ul>
                <li>At least two orientations cum mentoring sessions for all mentee institutions or separate sessions for each mentee institution may be planned and conducted.</li>
                <li>Orientation session shall be conducted through online mode using video conferencing platforms.</li>
                <li>Key functionaries of IIC at the mentor IIC institute shall join as mentor experts and will take the session and explain the best practices, cases of their institute on how they are driving the I&E ecosystem highlighting resource mobilization strategies.</li>
                <li>Mentor IIC institute may spend expenses towards honorarium to the experts. The honorarium of Rs. 3000/- per expert per session and two such orientation cum mentoring sessions to be conducted by the mentor institute during the IIC calendar year.</li>
              </ul>
            </div>
          </div>

          <form [formGroup]="addActivityForm1" (submit)="onSubmit1(addActivityForm1.value)">
          <table class="table table-hover">
            <thead class="facultyTable">
                <tr>
                    <th scope="col">Sr.No.</th>
                    <th scope="col">Session No.</th>
                    <th scope="col">Session Date & Time</th>
                    <th scope="col">Meeting Link</th>
                    <th scope="col">Expert Name</th>
                    <th scope="col">Expert Designation</th>
                    <th scope="col">Contact info</th>
                    <!-- <th scope="col">IIC Activity Id</th> -->
                    <th scope="col">Activity Brochure / Schedule</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let schedule of schedules; index as i"  >
                    <td >{{i+1}}</td>
                    <td>
                      <p *ngIf="schedule?.activity!=null">Session {{schedule?.activity}}</p>
                    </td>
                    <td>{{schedule?.scheduled_date}}<br/>{{schedule?.scheduled_time}}</td>
                    <td *ngIf="schedule?.meeting_id!=null">{{schedule?.meeting_id}}</td>
                    <td *ngIf="schedule?.meeting_id==null">N/A</td>
                    <td *ngIf="schedule?.speaker_name!=null"><p>{{schedule?.speaker_name}}</p>
                        <p *ngIf="schedule?.speaker_profile!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.speaker_profile}}" target="_blank">View Speaker Profile</a></p>
                    </td>
                    <td *ngIf="schedule?.speaker_name==null">N/A</td>
                    <td *ngIf="schedule?.speaker_designation!=null">{{schedule?.speaker_designation}}</td>
                    <td *ngIf="schedule?.speaker_designation==null">N/A</td>
                    <td *ngIf="schedule?.contact!=null">{{schedule?.contact}}</td>
                    <td *ngIf="schedule?.contact==null">N/A</td>
                    <!-- <td *ngIf="schedule?.iic_activity!=null">{{schedule?.iic_activity}}</td>
                    <td *ngIf="schedule?.iic_activity==null">N/A</td> -->
                    <td *ngIf="schedule?.file_upload!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.file_upload}}" target="_blank">Preview</a></td>
                    <td *ngIf="schedule?.file_upload==null">N/A</td>

                    <td>
                      <input [hidden]="true" [ngModel]="schedule?.activity_id" formControlName="activity_id">
                      <input [hidden]="true" [ngModel]="schedule?.activity" formControlName="session_value1" *ngIf="i==0">
                      <input [hidden]="true" [ngModel]="schedule?.activity" formControlName="session_value2" *ngIf="i==1">
                      <mat-form-field appearance="outline" class="form1Seassion" *ngIf="i==0">
                        <mat-label>Participation Status</mat-label>
                        <mat-select placeholder="Participation Status" formControlName="status_1" required>
                          <mat-option value="Not Participated">Not Participated</mat-option>
                          <mat-option value="Participated">Participated</mat-option>
                          <mat-option value="Upcoming">Upcoming</mat-option>
                        </mat-select>
                        <mat-error *ngIf="addActivityForm1.controls.status_1.errors?.required">Participation Status is required
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="form1Seassion"  *ngIf="i==1">
                        <mat-label>Participation Status</mat-label>
                        <mat-select placeholder="Participation Status" formControlName="status_2" required>
                          <mat-option value="Not Participated">Not Participated</mat-option>
                          <mat-option value="Participated">Participated</mat-option>
                          <mat-option value="Upcoming">Upcoming</mat-option>
                        </mat-select>
                        <mat-error *ngIf="addActivityForm1.controls.status_2.errors?.required">Participation Status is required
                        </mat-error>
                      </mat-form-field>

                    </td>
                </tr>
                <tr *ngIf="schedules?.length>0">
                  <td colspan="8"></td>
                  <td><button mat-raised-button color='primary' class="btn btn-success form1But"
                    [disabled]='addActivityForm1.invalid'>Submit</button></td>
                </tr>

                <tr *ngIf="schedules?.length<1">
                    <td colspan="9"><h3 style="text-align: center">No Data Found</h3></td>
                </tr>
            </tbody>
        </table>
      </form>
      </mat-tab>



      <mat-tab label="Quarterly Progress Meetings">
        <h3 style="padding-top: 15px; text-align:center; font-size: 16px; font-weight: bold; margin-bottom: 10px;">Quarterly Progress Meetings</h3>
          <p>Take part in the quarterly progress meeting of mentee IIC institutions and provide guidance on planning, progress assessment, action plan preparation and improvisation of I&E activities to be conducted in the mentee institutions.</p>
          <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline2" role="button" aria-expanded="false" aria-controls="rws-guideline2">View Guidlines</a></p>
          <div class="collapse multi-collapse" id="rws-guideline2">
            <div class="card card-body">
              <ul>
                <li>A key functionary from the IIC of the mentor institute will join as an expert member of IIC at the mentee institute.</li>

                <li>President of mentor IIC institute will nominate the member and he/she will take part in quarterly IIC meetings of IIC mentee institute.</li>

                <li>Once the member is nominated for the mentee institute, the same member is required to continue till the end of the IIC calendar year.</li>

                <li>Each nominated member needs to take part in 3 such quarterly/semester meetings during the IIC calendar year.</li>

                <li>Quarterly meetings shall be conducted on online mode using video conferencing platforms.</li>

                <li>Role of a member in the mentee IIC institute is to actively participate in quarterly progress meetings of mentee IIC institutions and provide guidance on planning, action plan preparation, progress assessment and improvisation of I&E activities to be conducted in the mentee institutions.</li>

                <li>Mentor IIC institute may spend expenses towards honorarium to the nominated members for completing the above tasks to support the mentee institution. Provision of honorarium amount of Rs. 1000/- per member per quarterly meeting action plan finalization for mentee institute.</li>

              </ul>
            </div>
          </div>

        <form [formGroup]="addActivityForm2" (submit)="onSubmit2(addActivityForm2.value)">
        <table class="table table-hover">
          <thead class="facultyTable">
              <tr>
                  <th scope="col"> Sr.No.</th>
                  <th scope="col">Session Date & Time</th>
                  <th scope="col">Meeting Link</th>
                  <th scope="col">Nominee from Mentor Institute</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Contact info</th>
                  <!-- <th scope="col">IIC Activity Id</th>
                  <th scope="col">Activity Report</th> -->
                  <th scope="col">Action</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let schedule of schedules2; index as i"  >
                  <td >{{i+1}}</td>
                  <td>{{schedule?.scheduled_date}}<br/>{{schedule?.scheduled_time}}</td>
                  <td *ngIf="schedule?.meeting_id!=null">{{schedule?.meeting_id}}</td>
                  <td *ngIf="schedule?.meeting_id==null">N/A</td>
                  <td *ngIf="schedule?.speaker_name!=null"><p>{{schedule?.speaker_name}}</p>
                      <p *ngIf="schedule?.speaker_profile!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.speaker_profile}}" target="_blank">View Speaker Profile</a></p>
                  </td>
                  <td *ngIf="schedule?.speaker_name==null">N/A</td>
                  <td *ngIf="schedule?.speaker_designation!=null">{{schedule?.speaker_designation}}</td>
                  <td *ngIf="schedule?.speaker_designation==null">N/A</td>
                  <td *ngIf="schedule?.contact!=null">{{schedule?.contact}}</td>
                  <td *ngIf="schedule?.contact==null">N/A</td>
                  <!-- <td *ngIf="schedule?.iic_activity!=null">{{schedule?.iic_activity}}</td>
                  <td *ngIf="schedule?.iic_activity==null">N/A</td>
                  <td *ngIf="schedule?.file_upload!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.file_upload}}" target="_blank">Preview</a></td>
                  <td *ngIf="schedule?.file_upload==null">N/A</td> -->
                  <td>
                    <input [hidden]="true" [ngModel]="schedule?.activity_id" formControlName="activity_id">
                    <mat-form-field appearance="outline" class="form1Seassion" *ngIf="i==0">
                      <mat-label>Participation Status</mat-label>
                      <mat-select placeholder="Participation Status" formControlName="status_1" required>
                          <mat-option value="Not Participated">Not Participated</mat-option>
                          <mat-option value="Participated">Participated</mat-option>
                          <mat-option value="Upcoming">Upcoming</mat-option>
                      </mat-select>
                      <mat-error *ngIf="addActivityForm2.controls.status_1.errors?.required">Participation Status is required
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="form1Seassion"  *ngIf="i==1">
                      <mat-label>Participation Status</mat-label>
                      <mat-select placeholder="Participation Status" formControlName="status_2" required>
                        <mat-option value="Not Participated">Not Participated</mat-option>
                          <mat-option value="Participated">Participated</mat-option>
                          <mat-option value="Upcoming">Upcoming</mat-option>
                      </mat-select>
                      <mat-error *ngIf="addActivityForm2.controls.status_2.errors?.required">Participation Status is required
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="form1Seassion"  *ngIf="i==2">
                      <mat-label>Participation Status</mat-label>
                      <mat-select placeholder="Participation Status" formControlName="status_3" required>
                        <mat-option value="Not Participated">Not Participated</mat-option>
                          <mat-option value="Participated">Participated</mat-option>
                          <mat-option value="Upcoming">Upcoming</mat-option>
                      </mat-select>
                      <mat-error *ngIf="addActivityForm2.controls.status_3.errors?.required">Participation Status is required
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="form1Seassion"  *ngIf="i==3">
                      <mat-label>Participation Status</mat-label>
                      <mat-select placeholder="Participation Status" formControlName="status_4" required>
                        <mat-option value="Not Participated">Not Participated</mat-option>
                          <mat-option value="Participated">Participated</mat-option>
                          <mat-option value="Upcoming">Upcoming</mat-option>
                      </mat-select>
                      <mat-error *ngIf="addActivityForm2.controls.status_4.errors?.required">Participation Status is required
                      </mat-error>
                    </mat-form-field>

                  </td>

              </tr>
              <tr *ngIf="schedules2length>0">
                <td colspan="6"></td>
                <td><button mat-raised-button color='primary' class="btn btn-success form1But"
                  [disabled]='addActivityForm2.invalid'>Submit</button></td>
              </tr>

              <tr *ngIf="schedules2length<1">
                  <td colspan="7"><h3 style="text-align: center">No Data Found</h3></td>
              </tr>
          </tbody>
      </table>
    </form>
    </mat-tab>



    <mat-tab label="Organise IIC calendar activity">
      <h3 style="padding-top: 15px; text-align:center; font-size: 16px; font-weight: bold; margin-bottom: 10px;">Organise IIC calendar activity</h3>
          <p>Plan and deliver two IIC calendar activity for each mentee IIC through an external experts identified by the mentor institute</p>
          <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline3" role="button" aria-expanded="false" aria-controls="rws-guideline3">View Guidlines</a></p>
          <div class="collapse multi-collapse" id="rws-guideline3">
            <div class="card card-body">
              <ul>
                <li>Mentor institute in coordination with the mentee institute will identify two IIC calendar activities to be conducted in the mentee IIC institute.</li>

                <li>Mentor institute will prepare the session plan and identify the external resource persons to deliver the session.</li>

                <li>Activities shall be conducted in online mode using video conferencing platforms.</li>

                <li>Each session/activity should accommodate at least two external experts to deliver the session for the participants of the mentee institute.</li>

                <li>Mentee institute will ensure a minimum participation of 200 nos including IIC members, students and faculties and staff.</li>

                <li>Mentor IIC institute may spend expenses towards honorarium to the external experts. The honorarium of Rs. 3000/- per expert, two experts per session and two sessions per IIC calendar year to be conducted in the mentee institute by the mentor institute.</li>
              </ul>
            </div>
          </div>

      <form [formGroup]="addActivityForm3" (submit)="onSubmit3(addActivityForm3.value)">
      <table class="table table-hover">
        <thead class="facultyTable">
            <tr>
                <th scope="col"> Sr.No.</th>
                <th scope="col">Session Details</th>
                <th scope="col">Session Date & Time</th>
                <th scope="col">Meeting Link</th>
                <th scope="col">Expert Name</th>
                <th scope="col">Expert Designation</th>
                <th scope="col">Contact Info</th>
                <th scope="col">IIC Activity Name</th>
                <th scope="col">Activity Brochure / Schedule</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let schedule of schedules3; index as i"  >
                <td >{{i+1}}</td>
                <td>
                  <p *ngIf="schedule?.activity!=null">Session {{schedule?.activity}}</p>
                </td>
                <td>{{schedule?.scheduled_date}}<br/>{{schedule?.scheduled_time}}</td>
                <td *ngIf="schedule?.meeting_id!=null">{{schedule?.meeting_id}}</td>
                <td *ngIf="schedule?.meeting_id==null">N/A</td>
                <td *ngIf="schedule?.speaker_name!=null"><p>{{schedule?.speaker_name}}</p>
                    <p *ngIf="schedule?.speaker_profile!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.speaker_profile}}" target="_blank">View Speaker Profile</a></p>
                </td>
                <td *ngIf="schedule?.speaker_name==null">N/A</td>
                <td *ngIf="schedule?.speaker_designation!=null">{{schedule?.speaker_designation}}</td>
                <td *ngIf="schedule?.speaker_designation==null">N/A</td>
                <td *ngIf="schedule?.contact!=null">{{schedule?.contact}}</td>
                <td *ngIf="schedule?.contact==null">N/A</td>
                <td *ngIf="schedule?.iic_activity!=null">{{schedule?.iic_activity}}</td>
                <td *ngIf="schedule?.iic_activity==null">N/A</td>
                <td *ngIf="schedule?.file_upload!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.file_upload}}" target="_blank">Preview</a></td>
                <td *ngIf="schedule?.file_upload==null">N/A</td>

                <td>
                  <input [hidden]="true" [ngModel]="schedule?.activity_id" formControlName="activity_id">
                  <mat-form-field appearance="outline" class="form1Seassion" *ngIf="i==0">
                    <mat-label>Participation Status</mat-label>
                    <mat-select placeholder="Participation Status" formControlName="status_1" required>
                      <mat-option value="Not Participated">Not Participated</mat-option>
                          <mat-option value="Participated">Participated</mat-option>
                          <mat-option value="Upcoming">Upcoming</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addActivityForm3.controls.status_1.errors?.required">Participation Status is required
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="form1Seassion"  *ngIf="i==1">
                    <mat-label>Participation Status</mat-label>
                    <mat-select placeholder="Participation Status" formControlName="status_2" required>
                      <mat-option value="Not Participated">Not Participated</mat-option>
                          <mat-option value="Participated">Participated</mat-option>
                          <mat-option value="Upcoming">Upcoming</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addActivityForm3.controls.status_2.errors?.required">Participation Status is required
                    </mat-error>
                  </mat-form-field>

                </td>

            </tr>

            <tr *ngIf="schedules3length>0">
              <td colspan="9"></td>
              <td><button mat-raised-button color='primary' class="btn btn-success form1But"
                [disabled]='addActivityForm3.invalid'>Submit</button></td>
            </tr>

            <tr *ngIf="schedules3length<1">
                <td colspan="10"><h3 style="text-align: center">No Data Found</h3></td>
            </tr>

        </tbody>
    </table>
  </form>
  </mat-tab>


  <mat-tab label="I&E Training & Exposure Visit">

    <h3 style="padding-top: 15px; text-align:center; font-size: 16px; font-weight: bold; margin-bottom: 10px;">I&E Training & Exposure Visit</h3>
          <p>Organize a 2 days long I&E exposure visit cum training program on I&E related theme such as pre-incubation and Incubation facility creation, IPR filing & management etc. for mentee institute representatives.</p>
          <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline3" role="button" aria-expanded="false" aria-controls="rws-guideline3">View Guidlines</a></p>
          <div class="collapse multi-collapse" id="rws-guideline3">
            <div class="card card-body">
              <ul>

                <li>Mentor institute will plan and organize a 2-day long exposure cum training program for representatives from mentee institutions to be delivered at the mentor institute.</li>

                <li>Mentee IIC institution can nominate up to two key functionaries of IIC to take part in the program.</li>

                <li>Maximum 10 participants from the 5 mentee institutions shall comprise the one cohort of training.</li>

                <li>The travel cost of the nominee/representative from the mentee institutions to be borne by the respective mentee IIC institutions.</li>

                <li>Mentor institute will take care of the venue related expenses to deliver the training program and expenses may include expert’s fee/honorarium, food, site visits, accommodation and training and communication material cost for the participants.</li>

                <li>The total funding support from MIC/AICTE for organising such a program is Rs. 1,25,000/-. Mentor institutes may bear the additional cost if incurred.</li>

              </ul>
            </div>
          </div>

    <form [formGroup]="addActivityForm4" (submit)="onSubmit4(addActivityForm4.value)">
    <table class="table table-hover">
      <thead class="facultyTable">
          <tr>
              <th scope="col"> Sr.No.</th>
              <!-- <th scope="col">Session Details</th> -->
              <th scope="col">Exposure Visit Schedule</th>
              <!-- <th scope="col">Meeting Id</th> -->
              <th scope="col">Mentor Institute Coordinator</th>
              <th scope="col">Designation</th>
              <th scope="col">Contact info</th>
              <!-- <th scope="col">IIC Activity Id</th> -->
              <th scope="col">Activity Brochure / Schedule</th>
              <th scope="col">Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let schedule of schedules4; index as i"  >
              <td >{{i+1}}</td>
              <!-- <td>
                <p *ngIf="schedule?.activity!=null">Session {{schedule?.activity}}</p>
              </td> -->
              <td>{{schedule?.scheduled_date}}<br/>{{schedule?.scheduled_time}}</td>
              <!-- <td *ngIf="schedule?.meeting_id!=null">{{schedule?.meeting_id}}</td>
              <td *ngIf="schedule?.meeting_id==null">N/A</td> -->
              <td *ngIf="schedule?.speaker_name!=null"><p>{{schedule?.speaker_name}}</p>
                  <p *ngIf="schedule?.speaker_profile!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.speaker_profile}}" target="_blank">View Speaker Profile</a></p>
              </td>
              <td *ngIf="schedule?.speaker_name==null">N/A</td>
              <td *ngIf="schedule?.speaker_designation!=null">{{schedule?.speaker_designation}}</td>
              <td *ngIf="schedule?.speaker_designation==null">N/A</td>
              <td *ngIf="schedule?.contact!=null">{{schedule?.contact}}</td>
              <td *ngIf="schedule?.contact==null">N/A</td>
              <!-- <td *ngIf="schedule?.iic_activity!=null">{{schedule?.iic_activity}}</td>
              <td *ngIf="schedule?.iic_activity==null">N/A</td> -->
              <td *ngIf="schedule?.file_upload!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.file_upload}}" target="_blank">Preview</a></td>
              <td *ngIf="schedule?.file_upload==null">N/A</td>

              <td>
                <input [hidden]="true" [ngModel]="schedule?.activity_id" formControlName="activity_id">
                <mat-form-field appearance="outline" class="form1Seassion" *ngIf="i==0">
                  <mat-label>Participation Status</mat-label>
                  <mat-select placeholder="Participation Status" formControlName="status_1" required>
                    <mat-option value="Not Participated">Not Participated</mat-option>
                    <mat-option value="Participated">Participated</mat-option>
                    <mat-option value="Upcoming">Upcoming</mat-option>
                  </mat-select>
                  <mat-error *ngIf="addActivityForm4.controls.status_1.errors?.required">Participation Status is required
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="form1Seassion"  *ngIf="i==1">
                  <mat-label>Participation Status</mat-label>
                  <mat-select placeholder="Participation Status" formControlName="status_2" required>
                    <mat-option value="Not Participated">Not Participated</mat-option>
                    <mat-option value="Participated">Participated</mat-option>
                    <mat-option value="Upcoming">Upcoming</mat-option>
                  </mat-select>
                  <mat-error *ngIf="addActivityForm4.controls.status_2.errors?.required">Participation Status is required
                  </mat-error>
                </mat-form-field>

              </td>

          </tr>

          <tr *ngIf="schedules4length>0">
            <td colspan="9"></td>
            <td><button mat-raised-button color='primary' class="btn btn-success form1But"
              [disabled]='addActivityForm4.invalid'>Submit</button></td>
          </tr>

          <tr *ngIf="schedules4length<1">
              <td colspan="10"><h3 style="text-align: center">No Data Found</h3></td>
          </tr>
      </tbody>
  </table>
</form>
</mat-tab>


<mat-tab label="Progress Monitoring & Impact Measurement">

  <h3 style="padding-top: 15px; text-align:center; font-size: 16px; font-weight: bold; margin-bottom: 10px;">Progress Monitoring & Impact Measurement</h3>
          <p>Conduct a visit to the mentee institute for progress monitoring cum feedback/mentoring sessions in each mentee institute.</p>
          <p><a href="javascript:void(0);"  data-toggle="collapse" href="#rws-guideline3" role="button" aria-expanded="false" aria-controls="rws-guideline3">View Guidlines</a></p>
          <div class="collapse multi-collapse" id="rws-guideline3">
            <div class="card card-body">
              <ul>
                <li>One key functionary of IIC at the mentor institute shall make a one-day visit to the campus of</li>

                <li>mentee institute to observe the facility and functional process of IIC and conduct round table discussion with the IIC members.</li>

                <li>Mentor expert will check the progress and provide feedback and on spot mentoring support to the mentee institute on improvisation of I&E ecosystem, strategies and how to improve the score and star of the mentee institute.</li>

                <li>Mentor institute will take care of the TA and honorarium of mentor experts whereas mentee institute will provide local stay and organize the round table meeting for progress monitoring cum feedback/mentoring.</li>
              </ul>
            </div>
          </div>

  <form [formGroup]="addActivityForm5" (submit)="onSubmit5(addActivityForm5.value)">
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th scope="col"> Sr.No.</th>
            <!-- <th scope="col">Session Details</th> -->
            <th scope="col">Session Date & Time</th>
            <!-- <th scope="col">Meeting Id</th> -->
            <th scope="col">Nominee from Mentor Institute</th>
            <th scope="col">Designation</th>
            <th scope="col">Contact info</th>
            <!-- <th scope="col">IIC Activity Id</th>
            <th scope="col">Activity Report</th> -->
            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let schedule of schedules5; index as i"  >
            <td >{{i+1}}</td>
            <!-- <td>
              <p *ngIf="schedule?.activity!=null">Session {{schedule?.activity}}</p>
            </td> -->
            <td>{{schedule?.scheduled_date}}<br/>{{schedule?.scheduled_time}}</td>
            <!-- <td *ngIf="schedule?.meeting_id!=null">{{schedule?.meeting_id}}</td>
            <td *ngIf="schedule?.meeting_id==null">N/A</td> -->
            <td *ngIf="schedule?.speaker_name!=null"><p>{{schedule?.speaker_name}}</p>
                <p *ngIf="schedule?.speaker_profile!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.speaker_profile}}" target="_blank">View Speaker Profile</a></p>
            </td>
            <td *ngIf="schedule?.speaker_name==null">N/A</td>
            <td *ngIf="schedule?.speaker_designation!=null">{{schedule?.speaker_designation}}</td>
            <td *ngIf="schedule?.speaker_designation==null">N/A</td>
            <td *ngIf="schedule?.contact!=null">{{schedule?.contact}}</td>
            <td *ngIf="schedule?.contact==null">N/A</td>
            <!-- <td *ngIf="schedule?.iic_activity!=null">{{schedule?.iic_activity}}</td>
            <td *ngIf="schedule?.iic_activity==null">N/A</td>
            <td *ngIf="schedule?.file_upload!=null"><a type="button" class="btn btn-info" href="{{ baseUrl + schedule?.file_upload}}" target="_blank">Preview</a></td>
            <td *ngIf="schedule?.file_upload==null">N/A</td> -->

            <td>
              <input [hidden]="true" [ngModel]="schedule?.activity_id" formControlName="activity_id">
              <mat-form-field appearance="outline" class="form1Seassion" *ngIf="i==0">
                <mat-label>Participation Status</mat-label>
                <mat-select placeholder="Participation Status" formControlName="status_1" required>
                  <mat-option value="Not Participated">Not Participated</mat-option>
                  <mat-option value="Participated">Participated</mat-option>
                  <mat-option value="Upcoming">Upcoming</mat-option>
                </mat-select>
                <mat-error *ngIf="addActivityForm5.controls.status_1.errors?.required">Participation Status is required
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="form1Seassion"  *ngIf="i==1">
                <mat-label>Participation Status</mat-label>
                <mat-select placeholder="Participation Status" formControlName="status_2" required>
                  <mat-option value="Not Participated">Not Participated</mat-option>
                  <mat-option value="Participated">Participated</mat-option>
                  <mat-option value="Upcoming">Upcoming</mat-option>
                </mat-select>
                <mat-error *ngIf="addActivityForm5.controls.status_2.errors?.required">Participation Status is required
                </mat-error>
              </mat-form-field>

            </td>

        </tr>
        <tr *ngIf="schedules5length>0">
          <td colspan="9"></td>
          <td><button mat-raised-button color='primary' class="btn btn-success form1But"
            [disabled]='addActivityForm5.invalid'>Submit</button></td>
        </tr>

        <tr *ngIf="schedules5length<1">
            <td colspan="10"><h3 style="text-align: center">No Data Found</h3></td>
        </tr>
    </tbody>
</table>
</form>
</mat-tab>

<mat-tab label="Guidance">

  <h3 style="padding-top: 15px; text-align:center; font-size: 16px; font-weight: bold; margin-bottom: 10px;">Guidance : NISP, ARIIA, HACKATHON</h3>
  <p>The mentor institution is expected to encourage, guide and handhold mentee institutions in participating various innovation and entrepreneurship initiatives of MoE such as adoption of NISP, formulation of I&E policy at the institute level, Participation in ARIIA</p>

  <form [formGroup]="addActivityForm6" (submit)="onSubmit6(addActivityForm6.value)">

    <!-- <h4>Select your Guidance:</h4> -->
    <h4>Select the activity conducted to your mentee institutions:</h4>

    <table class="table table-hover table-response tableGuidence">
		  <thead class="facultyTable">
			  <tr>
				  <th scope="col" style="width: 2%;">Sr. No.</th>
				  <th scope="col" style="width: 10%;">Guidance</th>
          <th scope="col" style="width: 10%;">Activity Status By Mentor</th>
				  <th scope="col" style="width: 13%;">Action by Mentee</th>
			  </tr>
		  </thead>
		  <tbody>
			<tr>
				<td>1</td>
				<td><p>NISP</p></td>
				<td>
          <p *ngIf="MmStatus_total==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.nisp_status==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.nisp_status==1">Completed</p>
        </td>
        <td>
          <p *ngIf="MmStatus?.nisp_status==1" style="display: block; padding-bottom: 15px;"><mat-select placeholder="NISP" formControlName="nisp_status" class="guidenceMentee">
            <mat-option value="Pending">Pending</mat-option>
            <mat-option value="Completed">Completed</mat-option>
          </mat-select></p>
        </td>
			</tr>
			<tr>
				<td>2</td>
				<td><p>Formulation of I&E policy at the institute level</p></td>
				<td>
          <p *ngIf="MmStatus_total==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.iepolicy_status==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.iepolicy_status==1">Completed</p>
        </td>
          <td>
            <p *ngIf="MmStatus?.iepolicy_status==1" style="display: block; padding-bottom: 15px;"><mat-select placeholder="Formulation of I&E policy at the institute level" formControlName="iepolicy_status" class="guidenceMentee">
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Completed">Completed</mat-option>
            </mat-select>
          </p>

          </td>
        </tr>
			<tr>
				<td>3</td>
				<td><p>Participation in ARIIA</p></td>
				<td>
          <p *ngIf="MmStatus_total==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.ariia_status==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.ariia_status==1">Completed</p>
        </td>
        <td>
            <p *ngIf="MmStatus?.ariia_status==1" style="display: block; padding-bottom: 15px;"><mat-select placeholder="Participation in ARIIA" formControlName="ariia_status" class="guidenceMentee">
              <mat-option value="Pending">Pending</mat-option>
              <mat-option value="Completed">Completed</mat-option>
            </mat-select></p>

          </td>
			</tr>

		  </tbody>
	  </table>

    <div fxFlex="50" class="m-2">   <button style="
      margin-left: 193px;
    " mat-raised-button color='primary' class="btn btn-success float-right"
      [disabled]='addActivityForm6.invalid'>Submit</button>
    </div>

  </form>

</mat-tab>

<mat-tab label="Handholding Support">

  <h3 style="padding-top: 15px; text-align:center; font-size: 16px; font-weight: bold; margin-bottom: 10px;">Handholding Support: Pre-Incubation & Incubation Facility Management</h3>
  <p>Training of Innovation Ambassadors, Mentoring support to students and establishment of pre-incubation and incubation facilities etc.</p>


  <form [formGroup]="addActivityForm7" (submit)="onSubmit7(addActivityForm7.value)">

    <h4>Select your Handholding Support Status:</h4>

    <table class="table table-hover table-response tableGuidence">
		  <thead class="facultyTable">
			  <tr>
				  <th scope="col" style="width: 2%;">Sr. No.</th>
				  <th scope="col" style="width: 10%;">Guidance</th>
          <th scope="col" style="width: 10%;">Activity Status By Mentor</th>
				  <th scope="col" style="width: 13%;">Action by Mentee</th>
			  </tr>
		  </thead>
		  <tbody>
			<tr>
				<td>1</td>
				<td><p>Training of Innovation Ambassadors</p></td>
				<td>
          <p *ngIf="MmStatus_total==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.ia_status==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.ia_status==1">Completed</p>
        </td>
        <td>
          <p *ngIf="MmStatus?.ia_status==1" style="display: block; padding-bottom: 15px;">
            <mat-select placeholder="Training of Innovation Ambassadors" formControlName="ia_status" class="guidenceMentee">
            <mat-option value="Pending">Pending</mat-option>
            <mat-option value="Completed">Completed</mat-option>
          </mat-select></p>
        </td>
			</tr>
			<tr>
				<td>2</td>
				<td><p>Mentoring support to students</p></td>
				<td>
          <p *ngIf="MmStatus_total==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.mentoring_support==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.mentoring_support==1">Completed</p>
        </td>
          <td>
            <p *ngIf="MmStatus?.mentoring_support==1" style="display: block; padding-bottom: 15px;">
              <mat-select placeholder="Mentoring support to students" formControlName="mentoring_support" class="guidenceMentee">
                  <mat-option value="Pending">Pending</mat-option>
                  <mat-option value="Completed">Completed</mat-option>
                </mat-select></p>
          </td>
        </tr>
			<tr>
				<td>3</td>
				<td><p>Establishment of pre-incubation and incubation facilities etc.</p></td>
				<td>
          <p *ngIf="MmStatus_total==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.incubation_status==0">Pending</p>
          <p *ngIf="MmStatus_total>0 && MmStatus?.incubation_status==1">Completed</p>
        </td>
        <td>
          <p *ngIf="MmStatus?.incubation_status==1" style="display: block; padding-bottom: 15px;">
            <mat-select placeholder="Establishment of pre-incubation and incubation facilities etc." formControlName="incubation_status" class="guidenceMentee">
                <mat-option value="Pending">Pending</mat-option>
                <mat-option value="Completed">Completed</mat-option>
              </mat-select></p>
          </td>
			</tr>

		  </tbody>
	  </table>

    <div fxFlex="50" class="m-2">   <button style="
      margin-left: 193px;
    " mat-raised-button color='primary' class="btn btn-success float-right"
      [disabled]='addActivityForm6.invalid'>Submit</button>
    </div>

  </form>


</mat-tab>

      </mat-tab-group>


    </div>
