import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/Services/file.service';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import * as myGlobals from '../../../../../../app/app.component';

@Component({
  selector: 'app-institute-self-driven',
  templateUrl: './institute-self-driven.component.html',
  styleUrls: ['./institute-self-driven.component.css']
})

export class InstituteSelfDrivenComponent implements OnInit {

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  selfActivity: InstitueSelfDrivenActivity[] = [];
  imagePath: any;
  baseUrl = "https://mic.gov.in/images/events/";
  quarter_filter: string = "";
  quarter_flg: boolean = false;
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  title1 = "";
  searched: string;
  search_keyword: string;
  event: any;
  id: any;

  constructor(private activityService: InstituteActivityService,
    public dialog: MatDialog, private toastr: ToastrService) { }
  showAddCalendar: boolean;
  showRemoveCalendar: boolean;
  allowRemove: boolean;
  ngOnInit(): void {
    this.getSelfDetails(this.currentPageNo);
    this.activityFormButton();
  }

  nextPage() {
    this.currentPageNo++;
    this.getSelfDetails(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getSelfDetails(this.currentPageNo);
  }

  getSelfDetails(pageNo) {
    this.activityService.getSelfDrivenDetails(this.per_page, pageNo, this.quarter_filter, this.title1).subscribe(
      data => this.handleResponse(data)
    );
  }

  getSelfDetails1() {
    this.activityService.getSelfDrivenDetails(this.per_page, this.currentPageNo, this.quarter_filter, this.title1).subscribe(
      data => this.handleResponse(data)
    );
  }

  nextPage1(e: any) {
    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    this.getSelfDetails1();
  }

  activityFormButton() {

    this.activityService.getHOI().subscribe(
      data => this.instituteData(data)
    );


  }

  instituteData(data) {
    if (data) {

      var institute_id = data['hoiDetails']['institute_id'];
      this.activityService.checkActivityButton(institute_id).subscribe(
        data => this.showActivityButton(data)
      );
    }

  }


  quarterFilter() {
    this.currentPageNo = 1;
    this.getSelfDetails(this.currentPageNo);
  }


  removeCalendar(eventId, status) {
    if (status !== "Submitted") {
      this.activityService.removeMicCalendar(eventId).subscribe(
        data => this.ngOnInit()
      );
      alert('Are you sure you want to delete ?')
    }
    else alert('The activity is already submitted, hence it cannot be deleted!')
    this.toastr.success(" Activity Removed Successfully");
  }

  addCalendar(eventId) {
    this.activityService.addMicCalendar(eventId).subscribe(
      data => this.ngOnInit()
    );
    alert('Are you sure you want to add ?')
    this.toastr.success(" Activity Added Successfully");
  }

  deleteCalendar(data){
    // alert('Are you sure you want to delete?')
    if (confirm('Are you sure you want to delete this activity permanently?')) {
      // Save it!
this.deleteSelfDrivenActivity(data);
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }


  }

  deleteSelfDrivenActivity(data){
    this.event=data['eventId'];
    this.id=data['id']
    this.activityService.deleteSelfDrivenActivity1(this.event,this.id).subscribe(
      data => this.handle(data));
  }
  handle(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg']);
      this.getSelfDetails(this.currentPageNo);

    }else{
      this.toastr.error(data['msg'])
    }
  }
  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searched = filterValue;
  }

  search() {
    this.search_keyword = this.searched;
    this.activityService.getSelfDrivenDetails(this.per_page, this.currentPageNo, this.quarter_filter, this.search_keyword).subscribe(
      data => this.handleResponse(data)
    )
  }




  handleResponse(data) {
    this.selfActivity = [];

    data['data'].forEach((element) => {
      this.selfActivity.push(element);
    });
    this.total_pages = data.meta.pagination.total;


    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  showActivityButton(data) {

    if (data && data != undefined) {
      if (data.status == 0) {
        this.quarter_flg = false;
      } else {
        this.quarter_flg = true;
      }
      //this.quarter_flg = true;
    }

  }

  openDialog(row) {
    const dialogRef = this.dialog.open(InstituteSelfDrivenDialog, {
      width: '100%',
      data: { activity: row }
    });

    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  onEditClick(data){
    const dialogRef = this.dialog.open(InstituteActivitySelfEditForm, {
      width: '100%',
      data:data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.currentPageNo = 1;
      this.getSelfDetails(this.currentPageNo)
    });
  }

  openSelfDrivenForm() {
    const dialogRef = this.dialog.open(InstituteActivitySelfAnnouncmentForm, {
      width: '100%',
      data: {}
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.currentPageNo = 1;
      this.getSelfDetails(this.currentPageNo)
    });
  }

}


@Component({
  selector: 'app-institute-self-driven-dialog',
  templateUrl: 'institute-self-driven-dialog.html',
})

export class InstituteSelfDrivenDialog implements OnInit {

  brochure:any;
  id: any;
  activity: any;
  // baseUrl = "https://mic.gov.in/images/events/"
  baseUrl = myGlobals.baseImageUrl;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InstituteSelfDrivenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.activity = this.data['activity'];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) {

  }
}


@Component({
  selector: 'app-institute-self-driven-announcment-form-dialog',
  templateUrl: 'institute-self-driven-announcement-form-dialog.html',
})

export class InstituteActivitySelfAnnouncmentForm implements OnInit {
  brochure:any;
  id: any;
  public announcementDrivenForm: FormGroup;
  public programTheme: string[] = [];
  minDate: any;
  wordCount: any;
  descriptionModel: any;
  show1: boolean = false;
  others: any[];
  quarter1:any;
  quarter2:any;
  quarter3:any;
  quarter4:any;
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<InstituteSelfDrivenComponent>,
    private fileService: FileService,
    private instituteMaster: InstituteInnovationContestService,
    private toastr: ToastrService,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    var parm = JSON.stringify(["program_theme"]);
    this.instituteMaster
      .getMasterDetails(parm)
      .subscribe((data) => this.handleResponseForAutoPopulate(data));

      this.instituteMaster
      .getQuarterDetails()
      .subscribe((data) => this.handleResponseQuarter(data));

  }


  ngOnInit() {

    this.announcementDrivenForm = this.fb.group({
      event_title: [null, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      event_theme: [null, Validators.required],
      brochure: [null, Validators.required],
      brochure_file: [null, Validators.required],
      event_url: [null, Validators.pattern('https?://.+')],
      description: [null],
      quarter: [null],
      event_theme_others: [null]
    });


    this.announcementDrivenForm.get('start_date').valueChanges.subscribe(
      data => this.minDate = data
    )

    this.announcementDrivenForm
      .get('event_theme')
      .valueChanges.subscribe((data) => this.changeValidation(data));

  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.announcementDrivenForm.patchValue({
        brochure_file: file
      });
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  handleResponseForAutoPopulate(data) {
    if (data['data'][0]) {
      data['data'][0].forEach((element) => {
        this.programTheme.push(element.data.name);
      });
    }
  }

  handleResponseQuarter(data) {
    this.quarter1 = data['quater1'];
    this.quarter2 = data['quater2'];
    this.quarter3 = data['quater3'];
    this.quarter4 = data['quater4'];
    }


  changeValidation(checked) {
    const others = this.announcementDrivenForm.get('event_theme_others');
    if (checked == "Other") {
      this.show1 = true;
      others.setValidators([Validators.required]);
    } else {
      others.clearValidators();
      others.reset();
      this.show1 = false;
    }
    others.updateValueAndValidity();
  }
  
  handleSubmitResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Kindly check the details!');
    }
  }

  handleResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success('Added Successfully !');
      this.dialogRef.close();
    } else {
      this.toastr.error(data['MSG']);
    }
  }

  handleResponse1(data) {
    if (data['status'] == "1") {
      this.toastr.success("Details Updated Successfully !");
      this.dialogRef.close();
    } else {
      this.toastr.error(data['msg']);
    }

  }

  submitForm() {

    if (this.announcementDrivenForm.get('description').value.split(' ').length < 2000) {

      if (this.announcementDrivenForm.valid) {
        var data = this.announcementDrivenForm.value;

        var formData = new FormData();

        formData.append('event_title', data.event_title);
        formData.append('fromDate', this.formatDate(data.start_date));
        formData.append('toDate', this.formatDate(data.end_date));
        formData.append('event_theme', data.event_theme);
        formData.append('event_url', data.event_url);
        formData.append('description', data.description);
        formData.append('quarter', data.quarter);
        formData.append('event_theme_others', data.event_theme_others);

        var brochure = this.announcementDrivenForm.get('brochure_file').value

        data.brochure && formData.append('bannerUpload', brochure);

        if (brochure != null) {
          this.instituteMaster
            .selfDrivenAnnouncement(formData)
            .subscribe((data) => this.handleSubmitResponse(data));
        } else {
          this.toastr.error('Kindly upload brochure file!')
        }

      } else {
        if (brochure != null) {
          this.toastr.error('Kindly fill all details')
        } else {
          this.toastr.error('Kindly upload brochure file!')
        }

      }
    } else {
      this.toastr.error('Kindly fill only 50 words in Description!')
    }

    if (this.show1 == true) {
      this.instituteMaster
        .selfDrivenAnnouncement(formData)
        .subscribe((data) => this.handleResponse(data));
    }
    else {

      this.instituteMaster
        .updateSelfDrivenActivity(formData, this.id)
        .subscribe((data) => this.handleResponse1(data));

    }
  }


  countWords(event) {
    var regex = /\s+/gi;
    this.wordCount = this.descriptionModel.trim().replace(regex, ' ').split(' ').length;
    if (this.wordCount > 1000) {
      return false;
    } else {
      return true;
    }
  }
}


export class InstitueSelfDrivenActivity {
  id: number;
  title: string;
  description: string;
  attachmentPath: string;
  quarter: string;
  endDate: string;
  startDate: string;
  event_url: string;
}

@Component({
  selector: 'app-institute-self-driven-edit-form-dialog',
  templateUrl: 'institute-self-driven-edit-form-dialog.html',
})

export class InstituteActivitySelfEditForm implements OnInit {
  brochure:any;

  public announcementDrivenForm: FormGroup;
  public programTheme: string[] = [];
  minDate: any;
  wordCount: any;
  descriptionModel: any;
  show1: boolean = false;
  others: any[];
  quarter1:any;
  quarter2:any;
  quarter3:any;
  quarter4:any;
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<InstituteSelfDrivenComponent>,
    private fileService: FileService,
    private instituteMaster: InstituteInnovationContestService,
    private toastr: ToastrService,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    var parm = JSON.stringify(["program_theme"]);
    this.instituteMaster
      .getMasterDetails(parm)
      .subscribe((data) => this.handleResponseForAutoPopulate(data));

      this.instituteMaster
      .getQuarterDetails()
      .subscribe((data) => this.handleResponseQuarter(data));

  }


  ngOnInit() {

    this.announcementDrivenForm = this.fb.group({
      event_title: [null, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      event_theme: [null, Validators.required],
      brochure: [null, Validators.required],
      brochure_file: [null, Validators.required],
      event_url: [null, Validators.pattern('https?://.+')],
      description: [null],
      quarter: [null],
      event_theme_others: [null]
    });


    this.announcementDrivenForm.get('start_date').valueChanges.subscribe(
      data => this.minDate = data
    )

    this.announcementDrivenForm
      .get('event_theme')
      .valueChanges.subscribe((data) => this.changeValidation(data));

  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.announcementDrivenForm.patchValue({
        brochure_file: file
      });
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  handleSubmitResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Kindly check the details!');
    }
  }

  handleResponseForAutoPopulate(data) {
    if (data['data'][0]) {
      data['data'][0].forEach((element) => {
        this.programTheme.push(element.data.name);
      });
    }
  }

  handleResponseQuarter(data) {
    this.quarter1 = data['quater1'];
    this.quarter2 = data['quater2'];
    this.quarter3 = data['quater3'];
    this.quarter4 = data['quater4'];
    }


  changeValidation(checked) {
    const others = this.announcementDrivenForm.get('event_theme_others');
    if (checked == "Other") {
      this.show1 = true;
      others.setValidators([Validators.required]);
    } else {
      others.clearValidators();
      others.reset();
      this.show1 = false;
    }
    others.updateValueAndValidity();
  }


  submitForm() {

    if (this.announcementDrivenForm.get('description').value.split(' ').length < 2000) {

      if (this.announcementDrivenForm.valid) {
        var data = this.announcementDrivenForm.value;

        var formData = new FormData();

        formData.append('event_title', data.event_title);
        formData.append('fromDate', this.formatDate(data.start_date));
        formData.append('toDate', this.formatDate(data.end_date));
        formData.append('event_theme', data.event_theme);
        formData.append('event_url', data.event_url);
        formData.append('description', data.description);
        formData.append('quarter', data.quarter);
        formData.append('event_theme_others', data.event_theme_others);

        var brochure = this.announcementDrivenForm.get('brochure_file').value

        data.brochure && formData.append('bannerUpload', brochure);

        if (brochure != null) {
          this.instituteMaster
            .selfDrivenAnnouncement(formData)
            .subscribe((data) => this.handleSubmitResponse(data));
        } else {
          this.toastr.error('Kindly upload brochure file!')
        }
        
      } else {
        if (brochure != null) {
          this.toastr.error('Kindly fill all details')
        } else {
          this.toastr.error('Kindly upload brochure file!')
        }

      }

    } else {
      this.toastr.error('Kindly fill only 50 words in Description!')
    }


  }

  countWords(event) {
    var regex = /\s+/gi;
    this.wordCount = this.descriptionModel.trim().replace(regex, ' ').split(' ').length;
    if (this.wordCount > 1000) {
      return false;
    } else {
      return true;
    }
  }
}

