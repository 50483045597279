<div class="container">
<mat-dialog-content class="mat-typography instiFactDialoge">
<h3>IA Internal Activity Details</h3>
<table class="table">
  <tbody>
    <tr>
      <td colspan="4"><h4>Promotion in social media</h4></td>
    </tr>
    <tr>
      <td><strong>Linkedin</strong><br/>
        {{showData?.linkedin}}</td>
      <td><strong>Twitter</strong><br/>
        {{showData?.twitter}}</td>
      <td><strong>Facebook</strong><br/>
        {{showData?.facebook}}</td>
      <td><strong>Instagram</strong><br/>
        {{showData?.instagram}}</td>
    </tr>
    <tr>
      <td colspan="4"><h4>Program Information</h4></td>
    </tr>
    <tr>
      <td><strong>Academic Year</strong><br/>
        {{showData?.year}}</td>
      <td><strong>Program Driven By</strong><br/>
        {{showData?.program_driven}}</td>
      <td><strong>Quarter</strong><br/>
        {{showData?.quarter}}</td>
      <td><strong>Title</strong><br/>
        {{showData?.title}}</td>
    </tr>
    <tr>
      <td><strong>Program Type</strong><br/>
        {{showData?.program_type}}</td>
      <td><strong>If Other</strong><br/>
        {{showData?.other}}</td>
      <td><strong>Program Theme</strong><br/>
        {{showData?.theme}}</td>
      <td><strong>Duration(in Minutes)</strong><br/>
        {{showData?.duration}}</td>
    </tr>
    <tr>
      <td><strong>Start Date</strong><br/>
        {{showData?.workshop_date}}</td>
      <td><strong>End Date</strong><br/>
        {{showData?.end_date}}</td>
      <td><strong>Student Participated</strong><br/>
        {{showData?.student_participation}}</td>
      <td><strong>Faculty Participated</strong><br/>
        {{showData?.faculty_participation}}</td>
    </tr>
    <tr>
      <td><strong>External Participated</strong><br/>
        {{showData?.external_participation}}</td>
      <td><strong>Expenditure Amount</strong><br/>
        {{showData?.expenditure_amount}}</td>
      <td><strong>Session Type</strong><br/>
        {{showData?.session_type}}</td>
      <td><strong>Remark</strong><br/>
        {{showData?.remark}}</td>
    </tr>
    <tr>
      <td colspan="2"><strong>Overview</strong><br/>
        {{showData?.objective}}</td>
      <td colspan="2"><strong>Benefits</strong><br/>
        {{showData?.benefit}}</td>
    </tr>
    <tr>
      <td colspan="4"><h4>Attachments</h4></td>
    </tr>
    <tr>
      <td><strong>Video URL</strong><br/>
        {{showData?.video_url}}</td>
    </tr>
    <tr>
      <td><a target="_blank" href="{{imageBasePath}}{{showData?.photo1}}"><button class="btn btn-primary mb-2" type="button">Photo 1</button></a></td>
      <td><a target="_blank" href="{{imageBasePath}}{{showData?.photo2}}"><button class="btn btn-primary mb-2" type="button">Photo 2</button></a></td>
      <td><a target="_blank" href="{{imageBasePath}}{{showData?.report}}"><button class="btn btn-primary mb-2" type="button">Report</button></a></td>
      <td>&nbsp;</td>
    </tr>
  </tbody>
</table>

<div class="iicformsection">
  <form [formGroup]="facultyForm" (ngSubmit)="onSubmit(facultyForm.value)">

    <div class="row">

      <div class="col-sm-6">
        <mat-form-field class="gender example-full-width" appearance="outline">
          <mat-label>Verification Status</mat-label>
          <mat-select placeholder="Verification Status" formControlName="verify_status" required>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Disapproved">Disapproved</mat-option>
          </mat-select>
          <mat-error *ngIf="facultyForm.controls['verify_status'].hasError('required')">Verification Status is required</mat-error>

        </mat-form-field>
      </div>

      <div class="col-sm-3">
        <button mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='facultyForm.invalid'>Submit</button>
      </div>

      <div class="col-sm-3">
        <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btn btn-danger instituteBtnClose">Close</button>
      </div>

      </div>

  </form>
</div>

</mat-dialog-content>
</div>
