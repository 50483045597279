<section class="yuktiInnovation">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>YUKTI Innovation Challenge 2023 (YIC 2023)</h3>
        <ul class="innovContestList">
          <li>IIC Institute can nominate recommended innovations/prototypes</li>
          <li>Innovations/prototypes that are recommended by verifiers are eligible for nomination</li>
          <li>Institute can nominate up to 10 innovations/prototypes only</li>
          <li>The last date for nomination is April 30, 2023</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group" style="box-shadow:0 0px 10px rgb(0 0 0 / 20%) !important">
        <div class="mt-4 table-responsive" style="margin-top:0 !important;">
          <table class="table table-hover ideaTableHover table-bordered" style="margin-top: 0px !important;">
            <thead class="facultyTable">
              <tr>
                <th colspan="10" style="text-align:center;background-color: #0d133d;padding-top:5px !important;">
                  <h4 class="innovTitle">List of recommended Innovations/Prototypes</h4>
                </th>
              </tr>
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Innovation ID</th>
                <!-- <th scope="col">Date of Submission</th> -->
                <th scope="col">Innovation Title</th>
                <!-- <th scope="col">FY of Development</th>
                <th scope="col">Developed as part of</th> -->
                <!-- <th scope="col">TRL Stage</th> -->
                <th scope="col">Team Lead Details</th>
                <!-- <th scope="col">Team Lead Type</th> -->
                <!-- <th scope="col">View Innovation Details</th> -->
                <!-- <th scope="col">Verification</th> -->
                <!-- <th scope="col">Avg. Score</th>
                <th scope="col">Status</th>
                <th scope="col">Nominate Status</th> -->
                <th scope="col">Submitted Status</th>
                <th scope="col">Round 2 Result</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let idea of prototypeSubmission; let i = index">
                <td>{{ i+1 }}</td>
                <td>{{ idea?.team_id }}</td>
                <!-- <td>{{ idea?.created_date }}</td> -->
                <td>{{ idea?.idea_title }}<br><b>TRL Title - {{ idea?.trl_level }}</b></td>
                <!-- <td>{{ idea?.academic_year }}</td>
                  <td>{{ idea?.developed_as_part_of }}</td> -->
                  <!-- <td>{{ idea?.trl_level }}</td> -->
                  
                <td>{{ idea?.team_lead_name }}<br/>{{ idea?.team_lead_email }}<br/>{{ idea?.team_lead_phone }}<br/>{{ idea?.user_type }}<br>
                    {{ idea?.enrollment_id }}
                    
                    <!-- <br>
                      <button type="button" (click)="openEvaluationDialogTeamData(idea)" class="btn btn-success viewBtn">
                        View & Verify ID
                      </button> -->
                </td>
                    <!-- <td>{{ idea?.user_type }}<br>
                      {{ idea?.enrollment_id }}<br>
                      <a class="viewidinfo" href="{{ idea?.enrollment_copy }}" target="_blank" title="">View ID</a>
                    </td> -->  
                    <!-- <td>
                      {{idea?.totalScoreAvg}}
                    </td>
                    <td>
                      {{idea?.mRecommendStatus}}
                    </td> -->
                    <!-- <td>
                      <button type="button" (click)="nominateChallenge(idea?.idea_id)" class="btn btn-success verify-btn" id="nominateSuccess"  [ngStyle]="{'display': (idea?.is_nominate==0) ? 'block' : 'none'}">
                        Nominate
                      </button>
                      <button type="button" (click)="RemoveChallenge(idea?.idea_id)" class="btn btn-danger verify-btn" id="nominateRemove" [ngStyle]="{'display': (idea?.is_nominate==1) ? 'block' : 'none'}">
                        Remove Nominated
                      </button>
                    </td> -->
                    <!-- <td><span [ngStyle]="{'display': (idea?.is_nominate==0) ? 'block' : 'none', 'color' : 'blue'}">Not Nominate</span>
                      <span [ngStyle]="{'display': (idea?.is_nominate==1) ? 'block' : 'none', 'color' : 'blue'}">Nominated</span></td> -->
                <td><span [ngStyle]="{'display': (idea?.isSubmittedDocumnet==0) ? 'block' : 'none', 'color' : 'blue'}">Not Submitted</span>
                        <span [ngStyle]="{'display': (idea?.isSubmittedDocumnet==1) ? 'block' : 'none', 'color' : 'blue'}">Submitted</span></td>
                <td>{{ idea?.round2 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-paginator #paginator showFirstLastButtons (page)="nextPage($event)" [length]="total_pages" [pageIndex]="frontCurrentPageNo" [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                          </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>