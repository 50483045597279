<div *ngIf="ForWhom=='Institute'" class="container">

    <div class="card-deck instiCardDeckDashboard">
      <!-- <div class="card text-center asd" style="background-color: #b6bcbd75 !important;"> -->
	  <div class="card text-center asd" style="background-color: #f2f2f2 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><mat-icon>account_box</mat-icon>Activity</h3>
            <div class="dashCardSeprator">
				<p class="card-text">IIC Activities Announced: {{activity?.iic_calender_activities}}</p>
				<p class="card-text">IIC Activities Submitted: {{activity?.iic_calender_submitted}}</p>
				<p class="card-text">IIC Activities Approved: {{activity?.iic_calender_approved}}</p>
			</div>
			<div class="dashCardSeprator">
				<p class="card-text">MIC Activities Announced: {{activity?.mic_calender_activities}}</p>
				<p class="card-text">MIC Activities Submitted: {{activity?.mic_calender_submitted}}</p>
				<p class="card-text">MIC Activities Approved: {{activity?.mic_calender_approved}}</p>
			</div>
            <p class="card-text">Self Activities Announced: {{activity?.self_calender_activities}}</p>
            <p class="card-text">Self Activities Submitted: {{activity?.self_calender_submitted}}</p>
            <p class="card-text">Self Activities Approved: {{activity?.self_calender_approved}}</p>
            <!-- <p class="card-text" title="Accepted Activities: {{activity?.accepted_activities}}"><mat-icon>check_box</mat-icon>Accepted Activities: {{activity?.accepted_activities}}</p>
            <p  class="card-text" title="Report Uploaded: {{activity?.report_uploaded}}"><mat-icon>thumb_up_alt</mat-icon>Report Uploaded: {{activity?.report_uploaded}}</p>
            <p  class="card-text" title="Report Not Uploaded: {{activity?.report_not_uploaded}}"><mat-icon>thumb_down_alt</mat-icon>Report Not Uploaded: {{activity?.report_not_uploaded}}</p> -->
           
            <div class="card-footer">
              <a routerLink="/institute/downloads-2021" class="btn btn-success btnDashboardCard">More info
                <!-- <mat-icon>save_alt</mat-icon> --> <mat-icon>arrow_forward</mat-icon>
            </a>
            </div>
          </div>
      </div>
      <!--<div class="card text-center asd" style="background-color: #b6bcbd75 !important;"> -->
	  <div class="card text-center asd" style="background-color: #f2f2f2 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><mat-icon>announcement</mat-icon>Notification</h3>
            <p *ngFor="let announcement of announcements" class="card-text" title="{{announcement}}"><mat-icon>near_me</mat-icon>{{announcement}}</p>
          <div class="card-footer">
            <a routerLink="/institute/announcement" class="btn btn-success btnDashboardCard">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div>
      <!-- <div class="card text-center asd" style="background-color: #b6bcbd75 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><mat-icon>trending_up</mat-icon>Performance Report</h3>
            <p class="card-text" title="Quarter 1 Score: {{quarter1}}"><mat-icon>looks_one</mat-icon>Quarter 1 Score: {{quarter1?.total_marks}}</p>
            <p class="card-text" title="Quarter 1 Rewards: {{quarter1}}"><mat-icon>looks_one</mat-icon>Quarter 1 Rewards: {{quarter1?.rewards}}</p>
            <p class="card-text" title="Quarter 2 Score: {{quarter2}}"><mat-icon>looks_two</mat-icon>Quarter 2 Score: {{quarter2?.total_marks}}</p>
            <p class="card-text" title="Quarter 2 Rewards: {{quarter2}}"><mat-icon>looks_two</mat-icon>Quarter 2 Rewards: {{quarter2?.rewards}}</p>
            <p class="card-text" title="Cumulative Score: {{cumulative}}"><mat-icon>format_list_numbered</mat-icon>Cumulative Score: {{cumulative_score}}</p>
            <p class="card-text" title="Cumulative Rewards: {{cumulative}}"><mat-icon>format_list_numbered</mat-icon>Cumulative Rewards: {{rewards_cumlative}}</p>
            <div class="card-footer">
            <a routerLink="/institute/bifurcation" class="btn btn-success btnDashboardCard">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div> -->
      <!--<div class="card text-center asd" style="background-color: #b6bcbd75 !important;"> -->
	  <div class="card text-center asd" style="background-color: #f2f2f2 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><mat-icon>notifications_active</mat-icon>News Corner</h3>
          <p *ngFor="let newscorner of newsCorners" class="card-text" title="{{newscorner}}"><mat-icon>near_me</mat-icon>{{newscorner}}</p>
          <div class="card-footer">
            <a routerLink="/institute/news-corner" class="btn btn-success btnDashboardCard">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div>  
    </div>
	</div>
	
	<div *ngIf="ForWhom=='School'" class="container-fluid schoolCardFluid">
	<div class="row">
	<div class="card-deck instiCardDeckDashboard col-md-12">
	<div class="col-md-4">
      <!-- <div class="card text-center asd" style="background-color: #b6bcbd75 !important;"> -->
	  <div class="card text-center asd dashCardMain" style="background-color: #fff !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity schoolActivity"><mat-icon>account_box</mat-icon>Activity</h3>
            <div class="dashCardSeprator">
				<p class="card-text">IIC Activities Announced: {{activity?.iic_calender_activities}}</p>
				<p class="card-text">IIC Activities Submitted: {{activity?.iic_calender_submitted}}</p>
				<p class="card-text">IIC Activities Approved: {{activity?.iic_calender_approved}}</p>
			</div>
			<div class="dashCardSeprator">
				<p class="card-text">MIC Activities Announced: {{activity?.mic_calender_activities}}</p>
				<p class="card-text">MIC Activities Submitted: {{activity?.mic_calender_submitted}}</p>
				<p class="card-text">MIC Activities Approved: {{activity?.mic_calender_approved}}</p>
			</div>
            <p class="card-text">Self Activities Announced: {{activity?.self_calender_activities}}</p>
            <p class="card-text">Self Activities Submitted: {{activity?.self_calender_submitted}}</p>
            <p class="card-text">Self Activities Approved: {{activity?.self_calender_approved}}</p>
            <!-- <p class="card-text" title="Accepted Activities: {{activity?.accepted_activities}}"><mat-icon>check_box</mat-icon>Accepted Activities: {{activity?.accepted_activities}}</p>
            <p  class="card-text" title="Report Uploaded: {{activity?.report_uploaded}}"><mat-icon>thumb_up_alt</mat-icon>Report Uploaded: {{activity?.report_uploaded}}</p>
            <p  class="card-text" title="Report Not Uploaded: {{activity?.report_not_uploaded}}"><mat-icon>thumb_down_alt</mat-icon>Report Not Uploaded: {{activity?.report_not_uploaded}}</p> -->
           
            <div class="card-footer">
              <a routerLink="/institute/downloads-2021" class="btn btn-success btnDashboardCard1">More info
                <!-- <mat-icon>save_alt</mat-icon> --> <mat-icon>arrow_forward</mat-icon>
            </a>
            </div>
          </div>
      </div>
	  </div>
	  
	  <div class="col-md-4">
      <!--<div class="card text-center asd" style="background-color: #b6bcbd75 !important;"> -->
	  <div class="card text-center asd dashCardMain" style="background-color: #fff !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity schoolActivity2"><mat-icon>announcement</mat-icon>Notification</h3>
            <p *ngFor="let announcement of announcements" class="card-text" title="{{announcement}}"><mat-icon>near_me</mat-icon>{{announcement}}</p>
          <div class="card-footer">
            <a routerLink="/institute/announcement" class="btn btn-success btnDashboardCard2">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div>
	  </div>
      <!-- <div class="card text-center asd" style="background-color: #b6bcbd75 !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity"><mat-icon>trending_up</mat-icon>Performance Report</h3>
            <p class="card-text" title="Quarter 1 Score: {{quarter1}}"><mat-icon>looks_one</mat-icon>Quarter 1 Score: {{quarter1?.total_marks}}</p>
            <p class="card-text" title="Quarter 1 Rewards: {{quarter1}}"><mat-icon>looks_one</mat-icon>Quarter 1 Rewards: {{quarter1?.rewards}}</p>
            <p class="card-text" title="Quarter 2 Score: {{quarter2}}"><mat-icon>looks_two</mat-icon>Quarter 2 Score: {{quarter2?.total_marks}}</p>
            <p class="card-text" title="Quarter 2 Rewards: {{quarter2}}"><mat-icon>looks_two</mat-icon>Quarter 2 Rewards: {{quarter2?.rewards}}</p>
            <p class="card-text" title="Cumulative Score: {{cumulative}}"><mat-icon>format_list_numbered</mat-icon>Cumulative Score: {{cumulative_score}}</p>
            <p class="card-text" title="Cumulative Rewards: {{cumulative}}"><mat-icon>format_list_numbered</mat-icon>Cumulative Rewards: {{rewards_cumlative}}</p>
            <div class="card-footer">
            <a routerLink="/institute/bifurcation" class="btn btn-success btnDashboardCard">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div> -->
	  <div class="col-md-4">
      <!--<div class="card text-center asd" style="background-color: #b6bcbd75 !important;"> -->
	  <div class="card text-center asd dashCardMain" style="background-color: #fff !important;">
        <div class="card-body instiDashboardCardBody">
            <h3 class="card-header card-title cardActivity schoolActivity3"><mat-icon>notifications_active</mat-icon>News Corner</h3>
          <p *ngFor="let newscorner of newsCorners" class="card-text" title="{{newscorner}}"><mat-icon>near_me</mat-icon>{{newscorner}}</p>
          <div class="card-footer">
            <a routerLink="/institute/news-corner" class="btn btn-success btnDashboardCard3">More info
              <mat-icon>arrow_forward</mat-icon>
          </a>
          </div>
        </div>
      </div>  
    </div>
	</div>
	
  </div>
 </div>