import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CheckPerformanceCardService {
  check201819data: boolean;

  constructor(private myCouncilService: InstituteMyCouncilService) {
    this.myCouncilService.checkPerformance201819().subscribe((data) => {
      if (data['check201819data'] == '0') {
        this.check201819data = false;
      }
      if (data['check201819data'] == '1') {
        this.check201819data = true;
      }
    });
  }

  canActivate(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ):
    | boolean
    | import('@angular/router').UrlTree
    | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    return this.check201819data;
  }
}
