import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-institute-testimonial-add',
  templateUrl: './institute-testimonial-add.component.html',
  styleUrls: ['./institute-testimonial-add.component.css']
})
export class InstituteActivityTestimonialComponent implements OnInit {

  public iicTestimonialQuestions: FormGroup;

  ipr_document: FileList | null;
  document:any;
  programFeedback:any;
  improvementRoom:any;
  requireField:any;
  rid:any;
  totalcount = 0;
  totalyearcount: any;
  feedback_status:any;
  improvement_room:any;
  sendid:any;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.rid = "edit";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });


  }

  ngOnInit(): void {

    this.totalyearcount=0;
    this.sendid=0;

    this.iicTestimonialQuestions = this.fb.group({
      'video_url': [null, Validators.required],
      'feedback_status':[null, Validators.required],
      'improvement_room': [null, Validators.required],
      'suggestions': [null, Validators.required]

    });
    this.requireField=0;

    this.instituteActivityService.getIicTestimonialDetails(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    if(data['totalcount'] > 0){

     this.iicTestimonialQuestions.patchValue({
      video_url: data['video_url'],
      feedback_status: data['feedback_status'],
      improvement_room:  data['improvement_room'],
      suggestions: data['suggestions']
    });

    this.sendid=data['send_id'];

    this.document = data['academic_year'];
    this.document = data['thumbnail'];
  }
  this.totalcount = data['totalcount'];
  this.totalyearcount = data['totalyearcount'];

  this.programFeedback = data['feedback'];
  this.improvementRoom = data['feedback'];

  }

  // isNumberKey(evt) {
  //   var charCode = evt.which ? evt.which : evt.keyCode;
  //   if (charCode >= 48 && charCode <= 57) return true;
  //   return false;
  // }

  onSubmit(data) {

    this.totalyearcount=0;
    var formData = new FormData();
    if(this.sendid==0)
    {
    formData.append('rid', this.rid);
  }
  else
  {
    formData.append('rid', this.sendid);
  }
    formData.append('video_url', this.iicTestimonialQuestions.get('video_url').value);
    formData.append('feedback_status', this.iicTestimonialQuestions.get('feedback_status').value);
    formData.append('improvement_room', this.iicTestimonialQuestions.get('improvement_room').value);
    formData.append('suggestions', this.iicTestimonialQuestions.get('suggestions').value);

    var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    if(this.totalcount>0){

      formData.append('document', cvFile);
      formData.append('document_old', this.document);

    }
    else
    {
      if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
      formData.append('document', cvFile);
    }

    this.instituteActivityService.submitTestimonialDetails(formData).subscribe(
     (data) => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      this.toastr.success(data['msg']);
      window.location.href='/institute/activity/testimonial';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }


}
