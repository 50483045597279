import { InstituteActivityService } from './../../../../../Services/institute-activity.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-institute-activity-count-dialog',
  templateUrl: './institute-activity-count-dialog.component.html',
  styleUrls: ['./institute-activity-count-dialog.component.css']
})
export class InstituteActivityCountDialogComponent implements OnInit {
  activityCount = [];
  idea = 0;
  nominated = 0;
  poc = 0;
  prototype = 0;

  constructor(
    public dialogRef: MatDialogRef<InstituteActivityCountDialogComponent>,
    private instituteActivityService: InstituteActivityService) { }

  ngOnInit(): void {
    this.instituteActivityService.getActivityCount().subscribe(
      data => {
        this.activityCount = data['activity count'];
        this.idea = data['idea'];
        this.nominated = data['nominated'];
        this.poc = data['poc'];
        this.prototype = data['prototype'];
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
