<!--title-->
<title>

    Leadership Team | MHRD
</title>
<!--end of title--->

<!-- Header -->
<app-inner-navbar></app-inner-navbar>

<div class="aht-breadcrumbs aht-breadcrumbs--with-border">
    <ul class="aht-breadcrumbs__list">
        <li class="aht-breadcrumbs__item">
            <a class="aht-breadcrumbs__link" href="index.html">Home</a>
        </li>
        <li class="aht-breadcrumbs__item">
            <a class="aht-breadcrumbs__link" href="#">About Us</a>
        </li>
        <li class="aht-breadcrumbs__item">
            Leadership Team
        </li>
    </ul>
</div>
<div class="row">
    <div class="col-md-6">
        <section class="paddsection bgsectioncolor3">

            <div class="container">

                <div class="row">

                    <div class="col-md-12">

                        <div class="titleheadertop text-center">

                            <h1 class="inner-theme-heading">Leadership Team </h1>

                        </div>

                    </div>

                </div>

                <div class="row marteam3">

                    <div class="col-md-6 offset-md-3">

                        <div class="aboutteam">

                            <img class="team_img" src="{{imageBasePath}}assets/wp_images/m1_new.png">

                            <div class="teamtitle">

                                <h4>Dr. Ramesh Pokhriyal &#8216;Nishank&#8217;</h4>

                                <p> Hon'ble Minister, MHRD</p>

                            </div>

                        </div>

                    </div>

                </div>



                <div class="row marteam">
                    <div class="col-md-4 offset-md-4">

                        <div class="aboutteam">

                            <img class="team_img" src="{{imageBasePath}}assets/wp_images/team-2.jpg">

                            <div class="teamtitle">

                                <h4>Prof Anil Sahasrabudhe</h4>

                                <p> Chairman, AICTE</p>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </section>
    </div>

    <div class="col-sm-6">

        <section class="paddsection">

            <div class="container">

                <div class="row">

                    <div class="col-md-12">

                        <div class="titleheadertop text-center">

                            <h1 class="inner-theme-heading">Office of MHRD Innovation Cell </h1>

                        </div>

                    </div>

                </div>







                <div class="row marteam3">

                    <div class="col-md-4 offset-md-4">

                        <div class="aboutteam">

                            <img class="team_img" src="{{imageBasePath}}assets/wp_images/cio.jpg">

                            <div class="teamtitle">

                                <h4>Dr. Abhay Jere</h4>

                                <p> Chief Innovation Officer</p>

                            </div>

                        </div>

                    </div>

                </div>





                <div class="row marteam4">
                    <div class="col-md-3">

                        <div class="aboutteam bornone">

                            <img class="team_img" src="{{imageBasePath}}assets/wp_images/t-1.jpg">

                            <div class="teamtitle">

                                <h4>Dr. Mohit Gambhir</h4>

                                <p> Innovation Director</p>

                            </div>

                        </div>

                    </div>
                    <div class="col-md-3">

                        <div class="aboutteam bornone">

                            <img class="team_img" src="{{imageBasePath}}assets/wp_images/t-2.jpg">

                            <div class="teamtitle">

                                <h4>Dr. K. Elangovan</h4>

                                <p>Assistant Innovation Director</p>

                            </div>

                        </div>

                    </div>
                    <div class="col-md-3">

                        <div class="aboutteam bornone">

                            <img class="team_img" src="{{imageBasePath}}assets/wp_images/t-3.jpg">

                            <div class="teamtitle">

                                <h4>Dr. Papri Banerjee</h4>

                                <p>Assistant Innovation Director</p>

                            </div>

                        </div>

                    </div>
                    <div class="col-md-3">

                        <div class="aboutteam bornone">

                            <img class="team_img" src="{{imageBasePath}}assets/wp_images/t-4-1.jpg">

                            <div class="teamtitle">

                                <h4>Mr. Dipan Kumar Sahu</h4>

                                <p>Assistant Innovation Director</p>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </section>

	</div>

<table class="table table-striped" style="margin-bottom: 50px">
    <thead>
        <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Email</th>
            <th>Phone Number</th>
        </tr>
    </thead>
    <tbody>


        <tr>
            <td>1</td>
            <td>Dr. Abhay Jere</td>
            <td>Chief Innovation Officer</td>
            <td>cio-mhrd@gov.in</td>
            <td>011 29581316</td>
        </tr>





        <tr>
            <td>2</td>
            <td>Dr. Mohit Gambhir</td>
            <td>Innovation Director</td>
            <td>mohit.gambhir@gov.in</td>
            <td>011 29581322</td>
        </tr>


        <tr>
            <td>3</td>
            <td>Dr. K. Elangovan</td>
            <td>Assistant Innovation Director</td>
            <td>elangovan.kariappan@gov.in</td>
            <td>011 29581332</td>
        </tr>


        <tr>
            <td>4</td>
            <td>Dr. Papri Banerjee</td>
            <td>Assistant Innovation Director</td>
            <td>papri.banerjee@gov.in</td>
            <td>011 29581319</td>
        </tr>


        <tr>
            <td>5</td>
            <td>Mr. Dipan Kumar Sahu</td>
            <td>Assistant Innovation Director</td>
            <td>dipan.sahu@gov.in</td>
            <td>011 29581226</td>
        </tr>




        <tr>
            <td>6</td>
            <td>Dr. Pooja Rawat</td>
            <td>Innovation Officer</td>
            <td> pooja.rawat1@gov.in</td>
            <td>011 29581509</td>
        </tr>





        <tr>
            <td>7</td>
            <td>Mr. Sarim Moin</td>
            <td>Innovation Officer</td>
            <td>sarim.moin@gov.in</td>
            <td>011 29581241</td>
        </tr>


        <tr>
            <td>8</td>
            <td>Mr. Ankush Prakash Sharma</td>
            <td>Innovation Officer</td>
            <td>ank.sharma@gov.in</td>
            <td>011 29581223</td>
        </tr>


        <tr>
            <td>9</td>
            <td>Ms. Selvarani</td>
            <td>Innovation Officer</td>
            <td>selva.rani@aicte-india.org</td>
            <td>011 29581513</td>
        </tr>


        <tr>
            <td>10</td>
            <td>Mr. Sadasiba Behera</td>
            <td>Startup Fellow</td>
            <td>sadasiba@aicte-india.org</td>
            <td>011 29581336</td>
        </tr>


        <tr>
            <td>11</td>
            <td>Mr. Pankaj Pandey</td>
            <td>Startup Fellow</td>
            <td>pankajpandey@aicte-india.org</td>
            <td>011 29581239</td>
        </tr>
        <tr>
            <td>12</td>
            <td>Mr. Ajay Singh Rajawat</td>
            <td>Start Up Fellow</td>
            <td>Startupfellow2@aicte-india.org</td>
            <td>011 29581337</td>
        </tr>
        <tr>
            <td>13</td>
            <td>Mr. Baljeet Singh Sinwar</td>
            <td>Start Up Fellow</td>
            <td>Startupfellow5@aicte-india.org</td>
            <td>N/A</td>
        </tr>
        <tr>
            <td>14</td>
            <td>Mr. Sunny Atwal</td>
            <td>Young Professional</td>
            <td>Yp.mic@aicte-india.org</td>
            <td>011 29581240</td>
        </tr>
		<tr>
            <td>15</td>
            <td>Mr. Udyan Mauriaya</td>
            <td>Startup Fellow</td>
            <td>startupfellow3@aicte-india.org</td>
            <td>N/A</td>
        </tr>

    </tbody>

</table>
</div>

<!-- Footer -->
<app-inner-footer></app-inner-footer>