import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-atl-mentor-detail',
  templateUrl: './atl-mentor-detail.component.html',
  styleUrls: ['./atl-mentor-detail.component.css']
})
export class AtlMentorDetailComponent implements OnInit {

  public iicImpactQuestions: FormGroup;


  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  activityType:any;
  activityId:any;
  activity_id:any;
  mentorDetail:any;
  memberCoordinator:any;
  iaCoordinator:any;

  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.activityId = "add";
    this.activityType = "add";
    this.route.params.subscribe((params) => {
      this.activityId = params.activityId;
      this.activityType = params.activityType;
    });



  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'participation_status': [null, Validators.required],
      'remark': [null, Validators.required]
    });

    this.requireField=0;
    this.activity_id=0;
    // this.mentorDetail=[];

    this.instituteActivityService.getMentorList().subscribe(
      (data) => this.handleResponseList(data)
    );

    // this.instituteActivityService.getAtlYuktiRepositoryDetails(this.activityId).subscribe(
    //   (data) => this.handleResponse(data)
    // );

    // var parm = JSON.stringify(["program_theme"]);
    // this.instituteMaster
    //   .getMasterDetails(parm)
    //   .subscribe((data) => this.handleResponseForAutoPopulate(data));



  }

  handleResponseList(data) {
    this.mentorDetail = data.mentor_detail;
    this.memberCoordinator = data.memberCoordinator;
    this.iaCoordinator = data.iaCoordinator;
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('activity', this.activityType);
    formData.append('activity_id', this.activityId);
    formData.append('participation_status', this.iicImpactQuestions.get('participation_status').value);
    formData.append('remark', this.iicImpactQuestions.get('remark').value);

    this.instituteActivityService.submitAtlActivityRepository(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      window.location.href='/institute/atlsic-dashboard';
      this.toastr.success(data['message']);
    }
    else{
      this.toastr.error(data['message']);

    }
  }

  onIICStatusChange(ob) {
    let iicStatus = ob.value;
    if(iicStatus == 'SELF') {
      this.requireField = 0;
    } else {
      this.requireField = 1;


      this.instituteActivityService.getIicActivityList(ob.value).subscribe(
        (data) => this.handleResponseList(data)
      );

    }

  }

}
