<h1 mat-dialog-title class="text-center instStuDetailsTitle">Add Student Details</h1>
<div mat-dialog-content>
  <div class="container">
    <form [formGroup]="studentForm" (submit)="onSubmit(studentForm.value)">
      <div class="row">
        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Name</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input matInput placeholder="Name" formControlName="name" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="studentForm.controls.name.errors?.required">Name is required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Email</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">email</mat-icon>
            <input matInput placeholder="Email" formControlName="email" required (keypress)=isSpace($event)>
            <mat-error *ngIf="studentForm.controls.email.errors?.required">Email is required</mat-error>
            <mat-error *ngIf="studentForm.controls.email.errors?.email">Invalid email address</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Mobile</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">phonelink_ring</mat-icon>
            <input matInput placeholder="Mobile" formControlName="mobile" minlength="10" maxlength="10" required
              (keypress)=isNumberKey($event)>
            <mat-error *ngIf="studentForm.controls.mobile.errors?.required">Contact is required</mat-error>
            <mat-error *ngIf="studentForm.controls.mobile.errors?.minlength">10 digits mobile number only</mat-error>
            <!-- <mat-error *ngIf="studentForm.controls.mobile.errors?.maxlength">10 digits mobile number only</mat-error> -->
            <mat-error *ngIf="studentForm.controls.mobile.errors?.pattern">Enter numbers only</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Stream</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input matInput placeholder="Stream" formControlName="stream" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="studentForm.controls.stream.errors?.required">Stream is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Current Academic Year</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">school</mat-icon>
            <mat-select formControlName="year" required>
              <mat-option>Select</mat-option>
              <mat-option value="1">First Year</mat-option>
              <mat-option value="2">Second Year</mat-option>
              <mat-option value="3">Third Year</mat-option>
              <mat-option value="4">Forth Year</mat-option>
              <mat-option value="5">Fifth Year</mat-option>

            </mat-select>
            <mat-error *ngIf="studentForm.controls.year.errors?.required">Current Academic Year is required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Semester</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">school</mat-icon>
            <mat-select formControlName="semester" required>
              <mat-option>Select</mat-option>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="9">9</mat-option>
              <mat-option value="10">10</mat-option>
            </mat-select>
            <mat-error *ngIf="studentForm.controls.semester.errors?.required">Semester is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Discipline</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <input matInput placeholder="Discipline" formControlName="dicipline" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="studentForm.controls.dicipline.errors?.required">Discipline is required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" fxFlex.lt-sm="100" *ngIf="showMember">
          <!-- <mat-form-field appearance="outline" class="col-12">
            <mat-label>Current association with</mat-label>
            <mat-icon matSuffix>star</mat-icon>
            <input matInput placeholder="Current association with" formControlName="is_member" required>
            <mat-error *ngIf="studentForm.controls.is_member.errors?.required">Current association is required
            </mat-error>
          </mat-form-field> -->
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Association With</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">star</mat-icon>
            <mat-select formControlName="is_member" >
              <mat-option>Select</mat-option>
              <mat-option *ngFor="let association of currentAssociationWith" [value]="association">{{association}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="studentForm.controls.is_member.errors?.required">Member is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div fxFlex="100" fxFlex.lt-sm="100">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Role</mat-label>
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">star</mat-icon>
            <!-- <mat-select formControlName="role" required>
              <mat-option>Select</mat-option>
              <app-select-check-all [model]="studentForm.get('role')" [values]="ApprovalType">
              </app-select-check-all>
              <mat-option value="Innovation Coordinator">Innovation Coordinator</mat-option>
              <mat-option value="Startup Coordinator">Startup Coordinator</mat-option>
              <mat-option value="Internship Coordinator">Internship Coordinator</mat-option>
              <mat-option value="Social Media Coordinator">Social Media Coordinator</mat-option>
              <mat-option value="IPR Coordinator">IPR Coordinator</mat-option>
              <mat-option value="Members">Members</mat-option>
            </mat-select> -->



            <mat-select formControlName="role" multiple [(ngModel)]="roleModel">
              <app-select-check-all [model]="studentForm.get('role')" [values]="Role" [(ngModel)]="role1">
              </app-select-check-all>
              <mat-option *ngFor="let Role of Role" [value]="Role">
                {{Role}}
              </mat-option>
            </mat-select>


            <!-- <mat-select formControlName="PROGRAMME" multiple [(ngModel)]="ProgrammeModel"
              (selectionChange)="getProgramWiseData()">
              <app-select-check-all [model]="selectionForm.get('PROGRAMME')" [values]="Programme">
              </app-select-check-all>
              <mat-option *ngFor="let Programme of Programme" [value]="Programme">
                {{Programme}}
              </mat-option>
            </mat-select> -->




            <mat-error *ngIf="studentForm.controls.role.errors?.required">Role is required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">
          <mat-form-field class="gender example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Gender</mat-label>
            <mat-select placeholder="Gender" formControlName="gender" required>
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error *ngIf="studentForm.controls['gender'].hasError('required')">Faculty gender is
              required</mat-error>

          </mat-form-field>
        </div>
      </div>

      <button type="button" mat-raised-button color='warn' (click)='onNoClick()' class="btn btn-danger instituteBtnClose">Close</button>
      <button *ngIf="show1" mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='studentForm.invalid'>Submit</button>
      <button *ngIf="show" mat-raised-button color='primary' class="btn btn-success float-right"
        [disabled]='studentForm.invalid'>Update</button>

    </form>
  </div>

</div>
