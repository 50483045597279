import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteCouncilMeetingDialogComponent } from './institute-council-meeting-dialog/institute-council-meeting-dialog.component';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { InstituteCouncil } from './institute-council';
import * as myGlobals from '../../../../../app.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-institute-council-meeting',
  templateUrl: './institute-council-meeting.component.html',
  styleUrls: ['./institute-council-meeting.component.css']
})
export class InstituteCouncilMeetingComponent implements OnInit {

  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  council: InstituteCouncil[] = [];
  readMore: boolean = true;
  baseUrl = myGlobals.baseImageUrl;
  data:any;
  ForWhom :any;
  // filterData:any;
  constructor(
    public dialog: MatDialog,
    private instituteMyCouncilService: InstituteMyCouncilService
  ) { }
  
  
  ngOnInit(): void {
    this.ForWhom=localStorage.getItem('ForWhom');
    this.getCouncil(this.currentPageNo);
    this.checkMyCouncil();
  }

  nextPage() {
    this.currentPageNo++;
    this.getCouncil(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getCouncil(this.currentPageNo);
  }
//23-2-24
checkMyCouncil() {
  // this.instituteMyCouncilService.checkMyCouncil().subscribe(
  //   data => this.data = data
  // );
  // console.log(this.data);
  this.instituteMyCouncilService.checkMyCouncil().subscribe(
    data => {
      this.data = data;
      console.log(this.data); // Log inside the subscription callback
    }
  );
  
}
//23-2-24
  getCouncil(pageNo) {
    this.instituteMyCouncilService
      .getCouncil(pageNo)
      .subscribe((data) => this.handleResponse(data));
  }

  handleResponse(data) {
    this.council = [];

    data['data'].forEach((element) => {
      this.council.push(element);
    });
    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }

    this.instituteMyCouncilService.dataUpdated.next(true);
  }

  openDialog() {
    const dialogRef = this.dialog.open(InstituteCouncilMeetingDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      this.getCouncil(this.currentPageNo);
    });
  }

  getInstituteDetails() {
    this.openDialog();
  }

  openEditDialog(data) {
    const dialogRef = this.dialog.open(InstituteCouncilMeetingDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getCouncil(this.currentPageNo);
    });
  }

  deleteDialog(data) {
    const dialogRef = this.dialog.open(InstituteCouncilDeleteComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getCouncil(this.currentPageNo);
    });
  }

  deleteInstituteDetails() {
    alert('Are you sure you want to delete Council Meeting Details');
  }

}



@Component({
  selector: 'app-institute-council-delete',
  templateUrl: './institute-council-delete.html',
  // styleUrls: ['./institute-declare-semesters.component.css']
})

export class InstituteCouncilDeleteComponent implements OnInit {
  id: any;

  constructor(public dialog: MatDialog,
    private myCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteCouncilMeetingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.id = this.data['id'];
    // this.getDeclareSemesters(this.currentPageNo);
  }

  deleteDialog() {
    this.myCouncilService.deleteCouncil(this.id).subscribe(
      data => this.handleData(data)
    );
  }

  handleData(data) {
    if (data['status'] = "1") {
      this.toastr.success(data['msg']);
      this.dialogRef.close();
    } else {
      this.toastr.error('Not able to delete !');
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}












