import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as myGlobals from '../../../app.component';
import { InstituteAnnouncementService } from 'src/app/Services/institute-announcement.service';
import { MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-institute-list',
  templateUrl: './institute-list.component.html',
  styleUrls: ['./institute-list.component.css']
})
export class InstituteListComponent implements OnInit {

  public innovation: FormGroup;
  public innovationCourse: FormGroup;
  allvalues: any[];
  StateModel = "";
  TypeModel = "";
  CourseModel = "";
  StateModel2 = "";
  RegionModel = " ";

  instituteType: any;
  instituteTypeId: any;
  state: any;
  region:any;
  instituteCourses: any;
  changeData: any;
  currentPageNo: number = 1;
  total_pages: number;
  firstPage = true;
  lastPage: boolean = false;
  theme: any[];
  nominationArray: any[];
  nominationArray2: any[];
  data1: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  per_page: number = 10;
  frontCurrentPageNo: number = 0;
  //pageEvent: PageEvent;
  baseImageUrl = myGlobals.baseImageUrlIA;
  searched: string;
  search_keyword: string;
  constructor(private instituteannouncementservice: InstituteAnnouncementService, private fb: FormBuilder,) {
    this.StateModel = "";
    this.RegionModel = "";

  }

  ngOnInit(): void {
    this.innovation = this.fb.group({
      instituteType: [null],
      typeState: [null],
      typeRegion:[null]
    });
    this.innovationCourse = this.fb.group({
      instituteName: [null],
      instituteCourses: [null],
      courseState: [null],

    });
    const data = {
      applicationStatus: 'Approved',
      instituteType: this.instituteType,
      region: this.region
    };
    this.instituteannouncementservice.getApprovedInstituteDetails(data, this.currentPageNo, this.per_page)
      .subscribe((data) => this.handleResponse(data));

    this.getInstituteType();
    this.getInstituteCourses();
    this.getRegion();
    this.getState();
  }
  getApprInstitute(data) {
    this.changeData = data;
    this.instituteannouncementservice.getApprovedInstituteDetails(this.changeData, this.currentPageNo, this.per_page)
      .subscribe((data) => this.handleResponse(data));
  }
  getInstituteType() {
    this.instituteannouncementservice
      .getInstituteTypeDetails()
      .subscribe((data) => this.setInstituteType(data));
  }

  setInstituteType(data) {
    this.instituteType = [];
    for (let i = 0; i < data.length; i++) {
      this.instituteType.push(data[i]);
    }
  }

  getInstituteCourses() {
    this.instituteannouncementservice
      .getInstituteCoursesDetails()
      .subscribe((data) => this.setInstituteCourses(data));
  }

  setInstituteCourses(data) {
    this.instituteCourses = [];
    for (let i = 0; i < data.length; i++) {
      this.instituteCourses.push(data[i]);
    }
  }
  getState() {

    this.instituteTypeId = this.innovation.get('instituteType').value;
    if (this.instituteTypeId == "") {
      this.StateModel = ""
    } else {

      if (this.instituteTypeId != null || this.instituteTypeId > 0) {
        this.instituteannouncementservice.getStateDetails(this.instituteTypeId).subscribe(
          data => this.setState(data)
        );
      }
    }
  }

  // getRegion(){
  //   this.instituteTypeId = this.innovation.get('instituteType').value;
  //   if (this.instituteTypeId == "") {
  //     this.RegionModel = ""
  //   } else {

  //     if (this.instituteTypeId != null || this.instituteTypeId > 0) {
  //       this.instituteannouncementservice.getZone().subscribe(
  //         data => this.setRegion(data)
  //       );
  //     }
  //   }
  // }

  getRegion() {
    this.instituteannouncementservice
      .getZone()
      .subscribe((data) => this.setRegion(data));
  }

  setRegion(data){
    this.region = [];
    for (let i = 0; i < data.length; i++) {
      this.region.push(data[i]);
    }
  }

  getCourseState() {

    this.instituteTypeId = this.innovationCourse.get('instituteCourses').value;
    if (this.instituteTypeId == "") {
      this.StateModel2 = ""
    } else {
      if (this.instituteTypeId != null || this.instituteTypeId > 0) {
        this.instituteannouncementservice.getStateDetails(this.instituteTypeId).subscribe(
          data => this.setState(data)
        );
      }
    }

  }
  setState(data) {
    this.state = [];
    for (let i = 0; i < data.length; i++) {
      this.state.push(data[i]);
    }
  }
  handleResponse(data) {
    this.theme = [];
    for (let i = 0; i < data['instituteDetails']['data'].length; i++) {
      this.theme.push(data['instituteDetails']['data'][i]);
    }
    this.total_pages = data.instituteDetails.meta.pagination.total;
    if (
      data['instituteDetails']['meta']['pagination']['current_page'] ==
      data['instituteDetails']['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['instituteDetails']['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  nextPage(e: any) {

    var instituteType = this.innovation.get('instituteType').value;
    var typeState = this.innovation.get('typeState').value;
    var instituteCourses = this.innovationCourse.get('instituteCourses').value;
    var courseState = this.innovationCourse.get('courseState').value;
    var instituteName = this.innovationCourse.get('instituteName').value;
    var typeRegion = this.innovation.get('typeRegion').value;

    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    const data = {
      applicationStatus: 'Approved',
      instituteType: instituteType,
      typeState: typeState,
      instituteCourses: instituteCourses,
      courseState: courseState,
      instituteName: instituteName,
      typeRegion: typeRegion,
    };
    this.getApprInstitute(data);
  }

  previousPage() {

    var instituteType = this.innovation.get('instituteType').value;
    var typeState = this.innovation.get('typeState').value;
    var instituteCourses = this.innovationCourse.get('instituteCourses').value;
    var courseState = this.innovationCourse.get('courseState').value;
    var instituteName = this.innovationCourse.get('instituteName').value;
    var typeRegion = this.innovation.get('typeRegion').value;

    this.currentPageNo--;

    const data = {
      applicationStatus: 'Approved',
      instituteType: instituteType,
      typeState: typeState,
      instituteCourses: instituteCourses,
      courseState: courseState,
      instituteName: instituteName,
      typeRegion: typeRegion,
    };

    this.getApprInstitute(data);

  }

  onSubmit(data) {
    data['applicationStatus'] = 'Approved';
    this.currentPageNo = 1;

    this.getApprInstitute(data);
  }
  onSubmitCourse(data) {
    data['applicationStatus'] = 'Approved';
    this.currentPageNo = 1;

    this.getApprInstitute(data);
  }



  ///Filter

  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searched = filterValue;
  }

  search() {
    // this.search_keyword = this.searched;
    // this.activityService.getIICDetails(this.per_page, this.currentPageNo, this.quarter_filter, this.search_keyword).subscribe(
    //   data => this.handleResponse(data)
    // )
  }

}
