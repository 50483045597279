import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-coordinator-add',
  templateUrl: './coordinator-add.component.html',
  styleUrls: ['./coordinator-add.component.css']
})
export class CoordinatorAddComponent implements OnInit {

  public iicCoordinatorAddition: FormGroup;

  coordinator_type:any;
  submission_type:any;
  name: any = '';
  email: any = '';
  contact: any = '';
  gender: any = '';
  city: any = '';
  show_field_2:any;
  show_field_3:any;
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  requireField1:any;
  requireField2:any;
  programTheme:any;
  iicActivity:any;
  iicCoordinatorCity:any;
  rid:any;
  type:any;
  activity_id:any;
  Role: any;
  standing: any;
  poster_count: any;


  minDate = "2018-01-01";
  maxDate = "2022-03-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute,     private myCouncilService: InstituteMyCouncilService, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
      // this.type = params.type;
    });
  }

  ngOnInit(): void {

    this.poster_count=0;

    this.iicCoordinatorAddition = this.fb.group({
      'coordinator_type': [null, Validators.required],
      'submission_type': [null, Validators.required],
      'name': [null, Validators.required],
      'email': [null, Validators.required],
      'contact': [null, Validators.required],
      'gender': [null, Validators.required],
      'city': [null, Validators.required],
      'poster': [],
      'showcase': [null, Validators.required],
      'innovation_count': [],
    });

    this.requireField=0;
    this.activity_id=0;
    this.requireField2=0;
    this.getRole();

    this.instituteActivityService.getIicCoordinatorCityDetails().subscribe(
      (data) => this.handleResponse1(data)
    );

  }

  getRole() {
    // if (this.data) {
    //   this.myCouncilService.getRoleCoordinator(this.data['id']).subscribe(
    //     data => this.handleResponse5(data)
    //   )
    // } else {
      this.myCouncilService.getCoordinatorRoles().subscribe((data) => this.handleResponse5(data));
    //}

  }
  handleResponseForAutoPopulate(data) {
    this.programTheme = "";
  }

  handleResponse1(data) {
    this.iicCoordinatorCity = data['data'];
  }

  handleResponse5(data) {
    this.Role = data['rolesCoordinator'];
  }

  handleResponse(data) {
    if(data['totalcount'] > 0){
      this.iicCoordinatorAddition.patchValue({
        coordinator_type: data['nomination_type'],
        submission_type: data['userType'],
        name: data['name'],
        email: data['email'],
        contact: data['contact'],
        gender: data['gender'],
        city: data['city'],
      });
    }
    this.totalcount = data['totalcount'];
  }

  handleResponseList(data) {
    this.activity_id = data['name'];
    this.iicActivity = data['IIC_Members'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('coordinator_type', this.iicCoordinatorAddition.get('coordinator_type').value);
    formData.append('submission_type', this.iicCoordinatorAddition.get('submission_type').value);
    formData.append('name', this.iicCoordinatorAddition.get('name').value);
    formData.append('email', this.iicCoordinatorAddition.get('email').value);
    formData.append('contact', this.iicCoordinatorAddition.get('contact').value);
    formData.append('gender', this.iicCoordinatorAddition.get('gender').value);
    formData.append('city', this.iicCoordinatorAddition.get('city').value);
    formData.append('poster', this.iicCoordinatorAddition.get('poster').value);
    formData.append('showcase', this.iicCoordinatorAddition.get('showcase').value);
    formData.append('innovation_count', this.iicCoordinatorAddition.get('innovation_count').value);

    if(this.iicCoordinatorAddition.get('showcase').value=="Yes"){
      if(this.iicCoordinatorAddition.get('innovation_count').value>0)
      {

      }
      else
      {
        this.toastr.error("No. of Innovation(s)");
        return false;
      }
    }

    this.instituteMaster.submitIicCoordinator(formData).subscribe(
     data => this.handleResponseData(data));
  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/announcement/nomination';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

  onStatusChange(ob) {
    let nbaStatus = ob.value;
    if(nbaStatus == 'IIC Members') {
      this.show_field_2 = 1;
      this.requireField = 1;
      this.instituteActivityService.getIicCoordinatorList(ob.value,'NONE','NONE').subscribe(
        (data) => this.handleResponseList(data)
      );
      this.instituteActivityService.getIicCoordinatorCityDetails().subscribe(
        (data) => this.handleResponse1(data)
      );
    } else {
      this.requireField = 0;
      // this.show_field_2 = 2;
      // this.requireField = 1;
      // this.instituteActivityService.getIicEvaluatorList(ob.value,'NONE','NONE').subscribe(
      //   (data) => this.handleResponseList(data)
      // );
    }

  }

  onNbaStatusChange(ob) {
    let nbaStatus = ob.value;
    this.instituteActivityService.getIicEvaluatorList(ob.value,nbaStatus,this.show_field_2).subscribe(
      (data) => this.handleResponsePatch(data)
    );

  }

  handleResponsePatch(data) {
    this.iicCoordinatorAddition.patchValue({
      name: data['data']['name'],
      email: data['data']['email'],
      contact: data['data']['mobile']
    });
  }

  onCityChange(ob) {
    let iicCity = ob.value;
     if(iicCity == 'Bengaluru' || iicCity == 'Bhopal' || iicCity == 'Chandigarh ' || iicCity == 'Chennai' || iicCity == 'Guwahati' || iicCity == 'Hyderabad' || iicCity == 'Jaipur' || iicCity == 'Kochi' || iicCity == 'Kolkata' || iicCity == 'Madurai' || iicCity == 'Nagpur' || iicCity == 'Pune') {
      this.requireField1 = 1;
      this.instituteActivityService.getIicCoordinatorCityList(iicCity).subscribe(
        (data) => this.handleResponsePatch1(data)
      );
    }else {
      this.requireField1 = 0;
    }
  }

  onInnovationChange(ob) {
    let iicCity = ob.value;
    if(iicCity == 'Yes') {
      this.requireField2 = 1;
    }else {
      this.requireField2 = 0;
    }
  }

  handleResponsePatch1(data) {
    this.iicCoordinatorAddition.patchValue({
      ins_name: data['data']['host_institute_name'],
      session_date: data['data']['mentoring_session_date']
    });
  }


}
