import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { AtalActivityProposalDialogComponent } from '../atal-activity-proposal-dialog/atal-activity-proposal-dialog.component';
import * as myGlobals from '../../../../../../app.component';

@Component({
  selector: 'app-atal-activity-form2',
  templateUrl: './atal-activity-form2.component.html',
  styleUrls: ['./atal-activity-form2.component.css']
})
export class AtalActivityForm2Component implements OnInit {

  edit: boolean;
  editData: any;
  id: any;
  report: FileList | null;
  public addActivityForm1: FormGroup;
  public addActivityForm2: FormGroup;
  public addActivityForm5: FormGroup;
  date1:any;
  file_upload: any;
  reportStatus: boolean;
  minDate = "2024-01-01";
  maxDate = "2026-03-31";

  show_field_1:any;
  show_field_2:any;
  show_field_3:any;
  show_field_4:any;
  show_field_5:any;
  show_field_6:any;
  show_field_7:any;
  show_field_8:any;
  show_field_9:any;
  show_field_10:any;
  schools:any;
  members:any;

  editDataform:any;

  baseImageUrl = myGlobals.baseImageUrl2;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AtalActivityProposalDialogComponent>,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public activity: InstituteActivityService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.edit = false;

      if (this.data['act2_id']!= undefined || this.data['act2_id']!= null || this.data['act2_id']!='')
      {
        this.edit = true;
        this.id = this.data['act2_id'];
        this.editDataform = this.data;
      }
      else
      {
        this.id = "Add";
        this.edit = false;
      }

     }

  ngOnInit(): void {

    if(this.id=="")
    {
      this.id = "Add";
    }

    alert(this.id);

    this.show_field_1=0;
    this.show_field_2=0;
    this.show_field_3=0;
    this.show_field_4=0;
    this.show_field_5=0;
    this.show_field_6=0;
    this.show_field_7=0;
    this.show_field_8=0;
    this.show_field_9=0;
    this.show_field_10=0;

     this.addActivityForm1 = this.fb.group({
      'session_mode': [null,Validators.required],
      'title': [null,Validators.required],
      'session_date': [null,Validators.required],
      'session_time':[null,Validators.required],
      'meeting_url':[],
      'venue_details':[],
      'coordinator':[null,Validators.required]
     });


     this.activity.getMembersDataValue("ALL").subscribe(data=>this.setDropdwonData(data));

     this.addActivityForm1.patchValue({
      title: "Mentoring guidance to School Students on their innovations and projects"
    });

  }

  setData(data){

      this.editData = data;

      if(data['session_mode']=="Online")
      {
        this.show_field_2 = "1";
        this.addActivityForm1.patchValue({
          meeting_url: data['meeting_url']
        });
      }
      else
      {
        this.show_field_2 = "0";
      }

      if(data['session_mode']=="Offline")
      {
        this.show_field_3 = "1";
        this.addActivityForm1.patchValue({
          venue_details: data['venue_details']
        });
      }
      else
      {
        this.show_field_3 = "0";
      }

      alert(data['scheduled_date']);

      this.addActivityForm1.patchValue({
        scheduled_date: data['scheduled_date'],
        scheduled_time: data['scheduled_time'],
        session_mode: data['session_mode'],
        coordinator: data['coordinator']
      });
 }

 setDropdwonData(data)
 {
  this.schools = data["selectedSchool"];
  this.members = data["IA_Members"];

  if(this.id!="Add")
  {
    this.setData(this.editDataform)
  }

 }

 onSubmitClick()
 {

 }

 changeDate(values) {
  var value = '';
  value = value + new Date(values).getFullYear().toString();
  value = value + '-' + (new Date(values).getMonth() + 1);
  value = value + '-' + new Date(values).getDate().toString();

  values = value;

  return values;
}


  onSubmit(data){

    var formData = new FormData();

    if(this.addActivityForm1.get('session_mode').value=="Offline")
    {
      if(this.addActivityForm1.get('venue_details').value=="" || this.addActivityForm1.get('venue_details').value==null || this.addActivityForm1.get('venue_details').value==undefined)
      {
        this.toastr.error("Please fill venue details.");
        return false;
      }
      formData.append('venue_details',this.addActivityForm1.get('venue_details').value);
    }
    else
    {
      formData.append('venue_details',"");
    }

    if(this.addActivityForm1.get('session_mode').value=="Online")
    {
      if(this.addActivityForm1.get('meeting_url').value=="" || this.addActivityForm1.get('meeting_url').value==null || this.addActivityForm1.get('meeting_url').value==undefined)
      {
        this.toastr.error("Please fill online session link.");
        return false;
      }

      formData.append('meeting_url',this.addActivityForm1.get('meeting_url').value);

    }
    else{
      formData.append('meeting_url',"");
    }

    // alert(this.id);

    formData.append('as_id', this.id);
    formData.append('activity', '2');

    formData.append('session_date',this.changeDate(this.addActivityForm1.get('session_date').value));

    formData.append('conducted_for',"");

    formData.append('session_time',this.addActivityForm1.get('session_time').value);

    formData.append('session_mode',this.addActivityForm1.get('session_mode').value);
    formData.append('quarter',"");

    formData.append('activity_type',"");
    formData.append('title',"Mentoring guidance to School Students on their innovations and projects");

    formData.append('atl_userId',this.editDataform['mentee_userId']);
    formData.append('coordinator',this.addActivityForm1.get('coordinator').value);

    formData.append('session',"");

    this.activity
    .AtlSchedule(formData)
    .subscribe((data) => this.handleResponse(data));


  }


  handleResponse(data){
    if(data['status']==1){
        this.toastr.success(data['message']);
        this.dialogRef.close();
    }
    else
    {
      this.toastr.error(data['message']);
    }

  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }


  onStatusChange3(ob,type) {
    let nbaStatus = ob.value;
    if(type==1)
    {
      if(nbaStatus == 'Individual') {
        this.show_field_1 = 1;
      } else {
        this.show_field_1 = 0;
      }
    }

    if(type==2)
    {
      if(nbaStatus == 'Online') {
        this.show_field_2 = 1;
        this.show_field_3 = 0;
      }
      else if(nbaStatus == 'Offline') {
        this.show_field_2 = 0;
        this.show_field_3 = 1;
      } else {
        this.show_field_2 = 0;
        this.show_field_3 = 0;
      }
    }


  }

}
