import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import * as myGlobals from 'src/app/app.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activity-scheduled',
  templateUrl: './activity-scheduled.component.html',
  styleUrls: ['./activity-scheduled.component.css']
})
export class ActivityScheduledComponent implements OnInit {
  public activitySubmit: FormGroup;
  baseUrl = myGlobals.baseUrl1;
  imageBasePath = myGlobals.imageBasePath;
  admin: boolean = false; 
  selectedFile: File | null = null;

  constructor(
    private fb: FormBuilder,
    private myCouncilService: InstituteMyCouncilService,
    private instituteActivityService: InstituteActivityService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.activitySubmit = this.fb.group({
      type: ['', Validators.required],
      quarter: ['', Validators.required],
      title: ['', Validators.required],
      theme: ['', Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.myCouncilService.getUser().subscribe((data) => {
      this.admin = data['institute_id'] === 'IC201810001';
    });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const label = document.querySelector(`label[for="fileUpload"]`);
  
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.selectedFile = file;
      label.textContent = file.name;
    } else {
      this.selectedFile = null;
      label.textContent = 'Choose file';
    }
  }

  onSubmit(): void {
    const value = this.activitySubmit.value;
    console.log("Form Value: ", value);
    console.log("Form Validity: ", this.activitySubmit.valid);
    console.log("Selected File: ", this.selectedFile);
    if (this.activitySubmit.invalid || !this.selectedFile) {
      this.toastr.error('Please fill in all required fields and upload a file.');
      return;
    }

    const formData = new FormData();
    Object.keys(this.activitySubmit.value).forEach(key => {
      formData.append(key, this.activitySubmit.value[key]);
    });
    formData.append('file', this.selectedFile);
  
    this.submitActivity(formData);
  }

  submitActivity(formData: FormData): void {
    this.instituteActivityService.submitActivity(formData).subscribe(
      data => this.handleResponseData(data),
      error => {
        console.error('There was an error submitting the activity!', error);
        this.toastr.error('There was an error submitting the activity.');
      }
    );
  }

  handleResponseData(data: any): void {
    if (data['status'] === "1") {
      this.activitySubmit.reset();
      this.selectedFile = null;
      this.toastr.success("Activity Submitted Successfully");
      this.router.navigate(['/institute/activity']); 
    } else {
      this.toastr.error(data['msg']);
    }
  }
}
